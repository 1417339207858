import React, { Fragment } from 'react'

export interface TextAreaProps {
  value: string
  required?: string | boolean
  placeholder?: string
  customStyled?: string
  name?: string
  maxlength?: number
  readOnly?: boolean
  noresize?: boolean
  isChanged?: boolean
  error?: boolean
  controlFunc(e: any): void
}

export interface TextAreaState {
  charCount: number
}

const maxColor = '#b21e28'
const minColor = 'gray'

class TextArea extends React.Component<TextAreaProps, TextAreaState> {
  constructor(props: TextAreaProps) {
    super(props)
    this.state = {
      charCount: 0
    }
  }

  public renderMaxLength = (): JSX.Element => {
    const { value, maxlength } = this.props
    const setLabelStyle = (): { color: string; display: string } => {
      return {
        color: value.length < maxlength ? minColor : maxColor,
        display: 'inline'
      }
    }
    return value ? (
      <label className='form-group__max-length' style={setLabelStyle()}>
        character max. ({value.length}/{maxlength})
      </label>
    ) : null
  }

  public render() {
    const {
      name,
      value,
      placeholder,
      readOnly,
      controlFunc,
      isChanged,
      maxlength,
      noresize,
      required,
      error,
      customStyled
    } = this.props
    const cssStylesArray: string[] = ['form-input']
    isChanged && cssStylesArray.push('form-input__changed')
    customStyled && cssStylesArray.push(customStyled)
    noresize && cssStylesArray.push('form-input__no-resize')
    return (
      <Fragment>
        <textarea
          className={cssStylesArray.join(' ').trim()}
          name={name}
          value={value || ''}
          onChange={controlFunc}
          placeholder={placeholder || value}
          readOnly={readOnly}
          maxLength={maxlength}
        />
        <div className='form-group__error-box'>
          <span className='form-input-Error form-input-Error__message'>
            {error ? required : null}
          </span>
          {maxlength ? this.renderMaxLength() : null}
        </div>
      </Fragment>
    )
  }
}

export default TextArea
