import React, { Component } from 'react'
import _ from 'lodash'
import { connect, Dispatch } from 'react-redux'

import {
  updatePortfolioBuilder,
  getPortfolioBuilderAccounts
} from '../../../../actions/portfolioBuilder'
import { addToast } from '../../../../actions/toasts'
import { GlobalState } from '../../../../reducers'
import Button from '../../../components/button'
import Modal from '../../../components/layout/modal'
import Input from '../../../components/Input'

import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'
import { ReactComponent as AddIcon } from '../../../assets/images/icons/plus.svg'

interface TargetAllocationModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  portfolioBuilderId: string
  targetAllocationData: any
  modalType: string
  closeModal(): void
  handleChange(inputVals: any): void
}

interface TargetAllocationModalState {
  targetEquityPercent: number | null
  targetFixedPercent: number | null
  targetCashPercent: number | null
  targetAltPercent: number | null
  percentTotal: number
}

class TargetAllocationModal extends Component<
  TargetAllocationModalProps,
  TargetAllocationModalState
> {
  constructor(props: TargetAllocationModalProps) {
    super(props)
    const {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    } = this.props.targetAllocationData
    this.state = {
      targetEquityPercent: targetEquityPercent || null,
      targetFixedPercent: targetFixedPercent || null,
      targetCashPercent: targetCashPercent || null,
      targetAltPercent: targetAltPercent || null,
      percentTotal: 0
    }
  }

  public renderButtons = (details: object) => {
    return (
      <div className='data-right'>
        <Button onClick={this.cancelOverride}>Cancel</Button>
        <Button
          primary={this.verifyPercent(details) === 100}
          onClick={this.saveOverride(details)}>
          Save
        </Button>
      </div>
    )
  }

  public verifyPercent = (details: object) => {
    let sum = 0
    Object.keys(details).forEach((e) => {
      const val = Number(details[e])
      sum += val
    })
    return sum
  }

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    const name: string = event.currentTarget.name
    this.setState({ ...this.state, [name]: value })
  }

  public cancelOverride = () => {
    const {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    } = this.props.targetAllocationData
    this.setState({
      targetEquityPercent: targetEquityPercent || null,
      targetFixedPercent: targetFixedPercent || null,
      targetCashPercent: targetCashPercent || null,
      targetAltPercent: targetAltPercent || null
    })
    this.props.closeModal()
  }

  public saveOverride = (details: object) => async () => {
    Object.keys(details).forEach((e) => (details[e] = Number(details[e])))
    const {
      dispatch,
      householdFinId,
      portfolioBuilderId,
      closeModal
    } = this.props
    if (this.verifyPercent(details) === 100) {
      await dispatch(
        updatePortfolioBuilder(householdFinId, portfolioBuilderId, details)
      )
      dispatch(getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId))
      this.props.handleChange(this.state)
      this.props.dispatch(
        addToast({
          message: 'Successfully added target allocation',
          backgroundColor: '#D9F2B6'
        })
      )
      closeModal()
    }
  }

  public inputValidation = (details: object) => {
    const percent = this.verifyPercent(details)
    return !(percent === 0 || percent === 100)
  }

  public render() {
    const {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    } = this.state
    const details = {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    }
    const { closeModal } = this.props
    return (
      <Modal
        icon={this.props.modalType === 'edit' ? EditIcon : AddIcon}
        title={`${
          this.props.modalType === 'add' ? 'ADD' : 'EDIT'
        } TARGET ALLOCATION`}
        closeModal={closeModal}>
        <div className='modal-body'>
          <div className='form-inline'>
            <Input
              title='Equity'
              percentageSign={true}
              name='targetEquityPercent'
              inputType='number'
              controlFunc={this.handleChange}
              classNames={this.inputValidation(details) && 'form-input-Error'}
              content={targetEquityPercent}
              min={0}
              max={100}
              placeholder='0'
            />
            <Input
              title='Fixed Income'
              percentageSign={true}
              name='targetFixedPercent'
              inputType='number'
              controlFunc={this.handleChange}
              classNames={this.inputValidation(details) && 'form-input-Error'}
              content={targetFixedPercent}
              min={0}
              max={100}
              placeholder='0'
            />
          </div>
          <div className='form-inline'>
            <Input
              title='Cash'
              percentageSign={true}
              name='targetCashPercent'
              inputType='number'
              controlFunc={this.handleChange}
              classNames={this.inputValidation(details) && 'form-input-Error'}
              content={targetCashPercent}
              min={0}
              max={100}
              placeholder='0'
            />
            <Input
              title='Alternatives'
              percentageSign={true}
              name='targetAltPercent'
              inputType='number'
              controlFunc={this.handleChange}
              classNames={this.inputValidation(details) && 'form-input-Error'}
              content={targetAltPercent}
              min={0}
              max={100}
              placeholder='0'
            />
          </div>
          {this.inputValidation(details) && (
            <span className='form-input-Error__message'>
              * Total Allocation must equal 100%.
              {` Currently ${this.verifyPercent(details)}%.`}
            </span>
          )}
          {this.renderButtons(details)}
        </div>
      </Modal>
    )
  }
}

export default connect()(TargetAllocationModal)
