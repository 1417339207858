import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'

import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router-dom'

import {
  FinancialGoalsInterface,
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../objects/financialGoal'
import { GoalRequestObjState } from '../../../objects/goalRequests'
import { HouseholdPreferencesObj } from '../../../objects/householdPreferences'
import { getFinancialGoals } from '../../../actions/financialGoals'
import { getGoalRequests } from '../../../actions/goalRequests'
import { getIndividualHousehold } from '../../../actions/households'

import GoalTiles from './goalTiles/index'
import FundedScoreTile from './fundedScoreTile'
import ContentHeader from '../../components/layout/contentHeader'
import InnerContainer from '../../components/layout/innerContainer'

import { ReactComponent as FinancialGoalsIcon } from '../../assets/images/icons/score.svg'

interface FinancialGoalsProps {
  householdFinId: string
  goalId: string
  userId: string
  dispatch: Dispatch<GlobalState>
  goals: FinancialGoalsInterface
  requests: GoalRequestObjState
  fundedScores: FundedScoreObj[]
  planningSoftware: PlanningSoftwareType
  preferences: HouseholdPreferencesObj
}
interface FinancialGoalsState {
  goalId: string
}
class FinancialGoals extends Component<
  FinancialGoalsProps,
  FinancialGoalsState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      goalId: this.props.goalId || null
    }
  }
  public async componentDidMount() {
    const { householdFinId, dispatch, goalId } = this.props
    if (goalId) {
      this.setState({ goalId })
    }
    await dispatch(getIndividualHousehold(householdFinId))
    await dispatch(getGoalRequests(householdFinId))
    dispatch(getFinancialGoals(householdFinId))
  }
  public componentDidUpdate(prevProps: FinancialGoalsProps) {
    if (prevProps.goalId !== this.props.goalId) {
      this.setState({ goalId: this.props.goalId })
    }
  }
  public render() {
    const {
      planningSoftware,
      fundedScores,
      userId,
      goals,
      householdFinId,
      requests,
      dispatch,
      preferences
    } = this.props
    const { goalId } = this.state
    return (
      <InnerContainer>
        <ContentHeader Icon={FinancialGoalsIcon} title='Financial Plan' />
        <FundedScoreTile
          planningSoftware={planningSoftware}
          fundedScore={fundedScores}
        />
        <div className='financial-goals__goals-w'>
          {goals && requests && (
            <GoalTiles
              userId={userId}
              goals={goals}
              householdFinId={householdFinId}
              planningSoftware={planningSoftware}
              requests={requests}
              goalId={goalId}
              dispatch={dispatch}
              preferences={preferences}
            />
          )}
        </div>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    userId: store.user.userId,
    goals:
      store.financialGoals[match.params.householdFinId] &&
      store.financialGoals[match.params.householdFinId].goals,
    householdFinId: match.params.householdFinId,
    goalId: match.params.goalRequestId,
    requests:
      store.goalRequests[match.params.householdFinId] &&
      store.goalRequests[match.params.householdFinId].requests,
    planningSoftware: store.households[match.params.householdFinId]
      ? store.households[match.params.householdFinId].planningSoftware
      : 'Manual',
    preferences:
      store.householdPreferences[match.params.householdFinId] &&
      store.householdPreferences[match.params.householdFinId].preferences
  }
}

export default withRouter(connect(mapStateToProps)(FinancialGoals))
