import React from 'react'
import Modal from '../../../components/layout/modal'
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'

interface CreateTeamModalProps {
  closeModal(): void
  saveModal(name: string): void
}

interface CreateTeamModalState {
  name: string
}

class CreateTeamModal extends React.Component<
  CreateTeamModalProps,
  CreateTeamModalState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: ''
    }
  }

  public handleInputChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      name: event.currentTarget.value
    })
  }

  public addTeam = () => {
    if (this.state.name.trim()) {
      this.props.saveModal(this.state.name)
    }
  }

  public render() {
    const { closeModal } = this.props
    const { name } = this.state
    return (
      <Modal icon={PlusIcon} title='Create Team' closeModal={closeModal}>
        <div className='team-management__modal-body'>
          <div className='team-management__modal-body__input'>
            <p>Team Name</p>
            <input
              onChange={this.handleInputChange}
              className='input'
              value={name}
            />
          </div>
        </div>
        <div className='team-management__modal-btns-w'>
          <span className='btn btn__clear' onClick={closeModal}>
            Cancel
          </span>
          <span className='btn btn__prime' onClick={this.addTeam}>
            Add Team
          </span>
        </div>
      </Modal>
    )
  }
}

export default CreateTeamModal
