import React, { Component } from 'react'

import { ClientAccountPositionsObj } from '../../../../objects/clientAccount'
import { dollarFormat } from '../../../helpers'

import Button from '../../../components/button'
import Tooltip from '../../../components/tooltip'
import AllocationGraph from '../../investment/allocationGraph'

import overrideIcon from '../../../assets/images/icons/png/ic_updates.png'

interface HoldingsRowProps {
  position: ClientAccountPositionsObj
  setSelectedPosition(position: ClientAccountPositionsObj): void
  handleAllocationRevert(position: ClientAccountPositionsObj): void
}

class HoldingsRow extends Component<HoldingsRowProps> {
  public handleSelectPosition = () => {
    return this.props.setSelectedPosition(this.props.position)
  }

  public handleSelectRevert = () => {
    const { handleAllocationRevert, position } = this.props
    return handleAllocationRevert(position)
  }

  public render(): JSX.Element {
    const {
      equityPercent,
      fixedPercent,
      cashPercent,
      altPercent,
      unclassifiedPercent,
      tickerSymbol,
      securityDescription,
      value,
      allocationOverridden
    } = this.props.position
    return (
      <div className='holdings-row__w'>
        <div className='holdings-row__position holdings-row__position-symbol'>
          <p>{tickerSymbol}</p>
        </div>
        <div className='holdings-row__position holdings-row__position-security'>
          <p>{securityDescription}</p>
        </div>
        <div className='holdings-row__position-allocation'>
          <AllocationGraph
            equity={equityPercent || 0}
            fix={fixedPercent || 0}
            cash={cashPercent || 0}
            alt={altPercent || 0}
            unclassified={unclassifiedPercent || 0}
          />
          {allocationOverridden ? (
            <Tooltip
              message='The allocation of this position is overridden'
              width={150}
              position='bottom'
              multiLine={true}>
              <img
                src={overrideIcon}
                alt='Override Icon'
                className='inv-accounts__override-icon'
              />
            </Tooltip>
          ) : null}
        </div>
        <div className='holdings-row__position holdings-row__position-value'>
          <p>{dollarFormat(value, 0)}</p>
        </div>
        <div className='holdings-row__position holdings-row__position-actions'>
          {allocationOverridden ? (
            <Button
              style={{ minWidth: 90 }}
              onClick={this.handleSelectRevert}
              header={true}>
              Revert
            </Button>
          ) : (
            <Button
              style={{ minWidth: 90 }}
              onClick={this.handleSelectPosition}
              header={true}>
              Override
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default HoldingsRow
