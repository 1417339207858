import * as React from 'react'
import { dollarFormat } from '../../../helpers'

export const FeeScheduleItem: React.StatelessComponent<{
  name: string
  value: string | number
  format: string
  last?: boolean
}> = ({ name, value, format, last }) =>
  name && value ? (
    <div
      className={
        last
          ? ' billing__summary--col-item billing__summary--col-item-last'
          : 'billing__summary--col-item'
      }>
      <div className='billing__summary--col-item-name'>{name}</div>
      <div className='billing__summary--col-item-feevalue'>
        {(() => {
          const valueNumber: number = value as number
          const addParantheses =
            ['Management Cost Adjustment:'].indexOf(name) >= 0 ||
            valueNumber < 0

          if (format === 'dollar') {
            return dollarFormat(
              parseInt(value.toString(), 0),
              0,
              addParantheses
            )
          } else {
            return addParantheses
              ? `(${value.toString().replace('-', '')}%)`
              : `${value}%`
          }
        })()}
      </div>
    </div>
  ) : null

export const SatisfactionCopy: React.StatelessComponent<{
  title: string
  notes: string
}> = ({ title, notes }) =>
  title && notes ? (
    <div className='billing__summary--col-title billing__summary--col-notes'>
      <div className='billing__summary--col-notes-title'>{title}</div>
      <div className='billing__summary--col-notes-copy'>{notes}</div>
    </div>
  ) : null

export const SignatureBox: React.StatelessComponent<{
  firstName: string
  lastName: string
  className: string
}> = ({ firstName, lastName, className }) =>
  firstName && lastName ? (
    <div className={className}>
      <div
        className='billing__summary--col-clientname'
        style={{ borderBottom: 'solid 1px #d2d2d2' }}>
        {firstName + ' ' + lastName}
      </div>
      <div className='billing__summary--col-sub'>Print Client Name</div>
      <div
        className='billing__summary--col-clientname billing__summary--col-clientname-blank'
        style={{ borderBottom: 'solid 1px #d2d2d2' }}
      />
      <div className='billing__summary--row'>
        <div className='billing__summary--col-sub'>Client Signature</div>
        <div className=' billing__summary--col-sub billing__summary--col-sub-date'>
          Date
        </div>
      </div>
    </div>
  ) : null

export const CostAdjustmentOption: React.StatelessComponent<{
  title: string
  children: JSX.Element | JSX.Element[] | React.ReactNode
}> = ({ title, children }) => (
  <React.Fragment>
    <div className='form-group' style={{ marginBottom: '0px' }}>
      <label className='form-label'>{title}</label>
    </div>
    <div className='c-settings__billing-inputs-adjust c-settings__billing-inputs-adjust-cost-option'>
      {children}
    </div>
  </React.Fragment>
)
