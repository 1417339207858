import React from 'react'
import Tile from '../../../components/layout/tile'
import Select from 'react-select'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/institution'
import { BenchmarkObj } from '../../../../objects/institution'
import * as householdActions from '../../../../actions/households'
import { HouseholdObj } from '../../../../objects/household'
import { getSelectOptionByValue } from '../../../helpers'

interface BenchmarkProps {
  household: HouseholdObj
  householdFinId: string
  benchmarks: BenchmarkObj[]
  sendPositionData: boolean
  dispatch: Dispatch<GlobalState>
}

class Benchmark extends React.Component<BenchmarkProps> {
  public componentDidMount() {
    this.props.dispatch(actions.getBenchmarks())
  }
  public getBenchmarkOptions = () => {
    const { benchmarks } = this.props
    return benchmarks.map((benchmark) => {
      return { value: benchmark.id, label: benchmark.name }
    })
  }
  public updateBenchmark = (e: any) => {
    const { dispatch, householdFinId } = this.props
    dispatch(householdActions.setBenchmark(householdFinId, e.value))
  }
  public render() {
    const { household, sendPositionData } = this.props
    const options = this.getBenchmarkOptions()
    return (
      !sendPositionData && (
        <div className='financial-planning__tile-w'>
          <Tile
            leftHeader={'CONFIRM UCFA BENCHMARK'}
            headerBorder={true}
            rightHeader={''}
            headerStyle={{
              backgroundColor: '#FAFAFA',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}>
            <div className='financial-planning__tile-content financial-planning__tile-content--row'>
              <div className='financial-planning__software'>
                <p className='financial-planning__software-label'>
                  UCFA Benchmark
                </p>
                {options.length > 0 && (
                  <Select
                    options={options}
                    onChange={this.updateBenchmark}
                    defaultValue={getSelectOptionByValue(
                      options,
                      household.benchmarkId
                    )}
                  />
                )}
              </div>
            </div>
          </Tile>
        </div>
      )
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    benchmarks: store.institution.benchmarks,
    sendPositionData: store.institution.sendPositionData
  }
}

export default connect(mapStateToProps)(Benchmark)
