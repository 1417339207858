import React, { Component, CSSProperties, ReactNode } from 'react'

export interface ButtonProps {
  children: ReactNode
  primary?: boolean
  clear?: boolean
  remove?: boolean
  header?: boolean
  link?: boolean
  disabled?: boolean
  style?: CSSProperties
  href?: string
  onClick?(e?: any): void
}

export class Button extends Component<ButtonProps> {
  public onClick = (e?: any) => {
    e?.stopPropagation()
    const { onClick, disabled } = this.props
    if (!disabled && onClick) {
      onClick()
    }
  }
  public render() {
    const {
      children,
      primary,
      clear,
      remove,
      style,
      href,
      link,
      disabled,
      header
    } = this.props
    let buttonClass = primary
      ? 'btn__prime'
      : clear
      ? 'btn__clear'
      : remove
      ? 'btn__remove'
      : header
      ? 'btn__header'
      : link
      ? 'btn__link'
      : 'btn__default'
    buttonClass += disabled ? ` ${buttonClass}--disabled` : ''
    return (
      <a
        className={`btn ${buttonClass}`}
        style={style}
        onClick={this.onClick}
        href={href}>
        {children}
      </a>
    )
  }
}

export default Button
