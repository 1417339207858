import React, { Component } from 'react'
import BookIcon from '../../assets/images/icons/png/il-book.png'
import Tile from '../../components/layout/tile'

interface ProductInfoProps {
  quantity: number
  quantityError: boolean
  updatedQuantity(value: any): void
}

class ProductInfo extends Component<ProductInfoProps> {
  public render() {
    const { quantity, updatedQuantity, quantityError } = this.props
    return (
      <div className='mimeo__section-w'>
        <Tile
          iconPng={null}
          leftHeader={'PRODUCT INFO'}
          style={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '12px'
          }}>
          <div className='mimeo__section mimeo-productinfo'>
            <div className='mimeo__section-left'>
              <img src={BookIcon} alt='book icon' />
              Guidebook
            </div>
            <div className='mimeo__section-right'>
              <div className='mimeo__label mimeo-productinfo__input'>
                <span>Quantity</span>
                <input
                  onChange={updatedQuantity}
                  value={quantity}
                  type='text'
                  className={
                    quantityError
                      ? 'mimeo__input  mimeo-productinfo__input mimeo__input--error'
                      : 'mimeo__input  mimeo-productinfo__input'
                  }
                />
              </div>
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

export default ProductInfo
