import React, { Component } from 'react'
import { history } from '../../../../store'

interface HcPageFooterProps {
  householdFinId: string
}

class HcPageFooter extends Component<HcPageFooterProps> {
  public navigateToHistory = () => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/honestConversations/meetings`)
  }

  public launch = () => {
    const { householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/v3/exercise/new`
    )
  }

  public render = () => {
    return (
      <div className='hc-meeting__footer'>
        <div className='hc-meeting__footer-links'>
          The only constant is change.{' '}
          <a onClick={this.launch}>Let's do the exercise again.</a>
        </div>
        <div className='hc-meeting__footer-links'>
          Feeling nostalgic?{' '}
          <a onClick={this.navigateToHistory}>
            Click to see previous exercise records.
          </a>
        </div>
      </div>
    )
  }
}

export default HcPageFooter
