import React, { Component, Fragment } from 'react'
import ActionDropdown from '../../components/actionDropdown'
import { MoneyMindObj } from '../../../objects/moneymind'

import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/protection.svg'
import { ReactComponent as CommitmentIcon } from '../../assets/images/icons/commitment.svg'
import { ReactComponent as HappinessIcon } from '../../assets/images/icons/happiness.svg'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg'

interface MoneyMindHistoryRowProps {
  moneymind: MoneyMindObj
  active: boolean
  editFn(moneymind: MoneyMindObj): void
  activeFn(id: string): void
  deleteFn(id: string): void
}

export class MoneyMindHistoryRow extends Component<MoneyMindHistoryRowProps> {
  constructor(props: MoneyMindHistoryRowProps) {
    super(props)
  }

  public ResultChart = (title: string, percent: number) => {
    const titleColor = percent > 0 ? '' : 'ymm-history__disabled'
    const percentColor =
      percent > 0
        ? 'ymm-history__' + title.toLowerCase()
        : 'ymm-history__disabled'
    return (
      <Fragment>
        <span className={'ymm-history__graph-name ' + titleColor}>
          {title}:
        </span>
        <span className={'ymm-history__graph-score ' + percentColor}>
          {percent}%
        </span>
      </Fragment>
    )
  }

  public ResultIcon = (
    title: string,
    percent: number,
    Icon: any,
    addPlus: boolean
  ) => {
    const plus = addPlus && percent > 0 ? '+' : ''
    return (
      <Fragment>
        {plus ? <span className='ymm-history__plus'>{plus}</span> : null}
        {percent > 0 ? (
          <span className={'ymm-history__' + title.toLowerCase()}>
            {' '}
            <Icon />
          </span>
        ) : null}
      </Fragment>
    )
  }

  public editMoneyMind() {
    return (
      <Fragment>
        <EditIcon />
        <span className='action-dropdown__item-label'>Edit Record</span>
      </Fragment>
    )
  }
  public setActive() {
    return (
      <Fragment>
        <CheckIcon />
        <span className='action-dropdown__item-label'>Set as Active</span>
      </Fragment>
    )
  }
  public deleteRecord() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete Record</span>
      </Fragment>
    )
  }

  public toggleEditMoneyMind = () => {
    this.props.editFn(this.props.moneymind)
  }

  public setActiveRecord = async () => {
    this.props.activeFn(this.props.moneymind.id)
  }

  public deleteYMMRecord = async () => {
    this.props.deleteFn(this.props.moneymind.id)
  }
  public render() {
    const dropdownActions = [
      {
        element: this.editMoneyMind(),
        onClick: this.toggleEditMoneyMind
      },
      {
        element: this.setActive(),
        onClick: this.setActiveRecord
      },
      {
        element: this.deleteRecord(),
        onClick: this.deleteYMMRecord
      }
    ]

    const { active, moneymind } = this.props
    const date = new Date(moneymind.date)
    return (
      <div className='ymm-history__results-w'>
        <span className='ymm-history__results-updated'>
          <span className='ymm-history__results-updated-date'>
            {date.getUTCMonth() + 1}/{date.getUTCDate()}/{date.getUTCFullYear()}
          </span>
          <span
            className={
              active
                ? 'ymm-history__active-badge ymm-history__active-badge--active'
                : 'ymm-history__active-badge'
            }
          />
        </span>
        <span className='ymm-history__results-icon'>
          {this.ResultIcon(
            'Commitment',
            moneymind.commitment,
            CommitmentIcon,
            false
          )}
          {this.ResultIcon(
            'Protection',
            moneymind.protection,
            ProtectionIcon,
            moneymind.commitment > 0
          )}
          {this.ResultIcon(
            'Happiness',
            moneymind.happiness,
            HappinessIcon,
            moneymind.protection > 0 || moneymind.commitment > 0
          )}
        </span>
        <span className='ymm-history__results-results'>
          {this.ResultChart('Commitment', moneymind.commitment)}
          {this.ResultChart('Protection', moneymind.protection)}
          {this.ResultChart('Happiness', moneymind.happiness)}
          <ActionDropdown actions={dropdownActions} />
        </span>
      </div>
    )
  }
}

export default MoneyMindHistoryRow
