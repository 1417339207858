import React from 'react'
import chart from '../../assets/images/icons/ic_chart.png'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { HouseholdObj } from '../../../objects/household'
import { withRouter } from 'react-router'
import InvestmentIcon from '../../assets/images/icons/ic_investments.png'
import DeleteIcon from '../../assets/images/icons/ic_delete.png'
import DownloadIcon from '../../assets/images/icons/ic_download.png'
import PreviewIcon from '../../assets/images/icons/ic_preview.png'
import PrintIconBlue from '../../assets/images/icons/ic_print_blue.png'
import VaultIcon from '../../assets/images/icons/ic_vault.png'
// import CloseIcon from '../../assets/images/icons/ic_close.png'
import DocumentName from './documentName'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import Modal from '../../components/layout/modal'
import { history } from '../../../store'
import hamburgerMenuIcon from '../../assets/images/icons/general/hamburger-menu.png'
import { Link } from 'react-router-dom'
import Button from '../../components/button'

interface HeadersProps {
  match: any
  household: HouseholdObj
  proposalStatus: boolean
  dispatch: Dispatch<GlobalState>
  contacts: ContactsInterface
  contact: ContactObj
  handleStrategyHeaderClick(): void
}

interface State {
  isActivateModel: boolean
}

class Header extends React.Component<HeadersProps, State> {
  constructor(props: HeadersProps, context: {}) {
    super(props, context)
    this.state = {
      isActivateModel: false
    }
  }

  public handleFinishClick = () => {
    this.setState({ isActivateModel: true })
    history.push(`/households/${this.props.household.id}/investments`)
  }

  public saveModal = () => {
    this.setState({ isActivateModel: false })
  }

  public saveAsActiveModel = () => {
    this.setState({ isActivateModel: false })
    history.push(`/households/${this.props.household.id}/investments`)
  }

  public renderFinishModel = () => {
    return (
      <Modal closeModal={this.saveModal} title='Activate Proposal'>
        <div className='modal-body'>
          <div className='finish-font'>
            You have created a new proposal. Do you want to set it as active?
          </div>
          <div className='data-right'>
            <a onClick={this.saveModal} className='finsih-save-button'>
              Save
            </a>
            <button
              type='submit'
              onClick={this.saveAsActiveModel}
              className='form-active-btn'>
              Save as Active
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  public returnToInvestments = () => {
    const { household } = this.props
    history.push(`/households/${household.id}/investments`)
  }

  public headerBar = () => {
    const { proposalStatus, contacts } = this.props
    return !proposalStatus ? (
      <div className='Header_Base'>
        <div className='header__left'>
          <img src={chart} className='ic-chart' alt='logo' />
          <div className='page-title pd'>Portfolio Builder</div>
          <DocumentName />
        </div>
        <div className='header__right'>
          <Link
            to={this.props.match.url + '/PortfolioProposal/history'}
            className='ppb-header__item-w'>
            <img src={InvestmentIcon} alt='no-img' />
            Build Proposal
          </Link>
          <Button header={true} onClick={this.returnToInvestments}>
            Finish
          </Button>
        </div>
      </div>
    ) : (
      <div className='Header_Base'>
        <div className='page-title pd'>
          <img src={InvestmentIcon} alt='no-img' /> Portfolio Proposal Builder
        </div>
        {contacts && (
          <div className='profile-name'>
            {contacts.primary.firstName && (
              <span>
                {contacts.primary.firstName}
                {contacts.secondary.firstName && (
                  <span>and {contacts.secondary.firstName}</span>
                )}
              </span>
            )}
          </div>
        )}
        <div className='header__right-alt'>
          <div>
            <a>
              <img src={PreviewIcon} alt='no' />
              Switch Preview
            </a>
          </div>
          <div>
            <img src={DeleteIcon} alt='no' />
          </div>
          <div>
            <img src={VaultIcon} alt='no' />
          </div>
          <div>
            <img src={DownloadIcon} alt='no' />
          </div>
          <div>
            <img src={PrintIconBlue} alt='no' />
          </div>
          <div className='header__right-alt__divider' />
          {/* <div onClick={this.handleClick}>
            <img src={CloseIcon} alt='no' />
          </div> */}
        </div>
      </div>
    )
  }

  public render() {
    return (
      <div>
        {this.state.isActivateModel && this.renderFinishModel()}
        <div className='header__detail-w '>{this.headerBar()}</div>
        <div className='header__detail-w-sub-header '>
          <div className='header__detail-w-sub-header--right '>
            <img
              className='header__detail-w-sub-header-img'
              src={hamburgerMenuIcon}
              alt='open-close strategy menu'
              onClick={this.props.handleStrategyHeaderClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId],
    contacts: store.contact && store.contact[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(Header))
