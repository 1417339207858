import React from 'react'
import Modal from '../../components/layout/modal'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import { ReactComponent as SendIcon } from '../../assets/images/icons/send.svg'
import Button from '../../components/button'
interface SendMoneyMindModalProps {
  contacts: ContactsInterface
  emailModal(type: string): void
  closeModal(): void
}

class SendMoneyMindModal extends React.Component<SendMoneyMindModalProps> {
  constructor(props: any) {
    super(props)
  }

  public handlePrimarySend = () => {
    this.props.closeModal()
    this.props.emailModal('primary')
  }

  public handleSecondarySend = () => {
    this.props.closeModal()
    this.props.emailModal('secondary')
  }

  public sendAccountBtn = (isPrimary: boolean, contact: ContactObj) => {
    return (
      <div className='moneymind-modal__btn-w'>
        <Button
          primary={true}
          onClick={
            isPrimary ? this.handlePrimarySend : this.handleSecondarySend
          }>
          <span className='moneymind-modal__btn-text'>
            Send {contact.firstName}'s MoneyMind&reg;
          </span>
        </Button>
      </div>
    )
  }

  public render() {
    const { contacts, closeModal } = this.props
    return (
      <Modal
        icon={SendIcon}
        title='SEND MONEYMIND&reg;'
        closeModal={closeModal}>
        <div className='moneymind-modal'>
          <div className='moneymind-modal__content'>
            <div className='moneymind-modal__account-type'>
              <div className='moneymind-modal__btn'>
                {contacts && contacts.primary
                  ? this.sendAccountBtn(true, contacts.primary)
                  : null}
                {contacts && contacts.secondary
                  ? this.sendAccountBtn(false, contacts.secondary)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default SendMoneyMindModal
