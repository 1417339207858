import { CardRankingObj } from '../../../../../objects/honestConversations'
import { ContactsInterface } from '../../../../../objects/contact'
import { CardObj } from '../../../../../objects/HonestConversations/card'

/**
 *
 * @param comment = string
 * @param cardList = Array of selected card
 * @param index = key of the card for which comment is changed
 */
export const getCardForComment = ({
  comment,
  cardList,
  index
}: {
  comment: string
  cardList: CardRankingObj[]
  index: number
}) => {
  const activeCard = cardList[index]
  if (!activeCard.isChanged) {
    activeCard.isChanged = true
  }
  activeCard.comment = comment
  return cardList
}

/**
 *
 * @param contacts = having the data for both primary and secondary
 * @param prevState = previous state of the card
 * @param contactType = data changes for the type of Contact
 * @param index = the index of the card
 * @param card = the currently modified card
 */
export const getCardForAutoSave = ({
  contacts,
  prevState,
  contactType,
  index,
  card
}: {
  index: number
  contactType: string
  card: CardObj
  prevState: any
  contacts: ContactsInterface
}) => {
  const newState = { ...prevState }
  let priorityCardExist: boolean
  let combinedCardExist: boolean

  newState[contactType][index].card = card
  newState[contactType][index].cardId = card.id
  // single HH feature ONLY- priority cards should default to combined cards when combined card is selected
  if (!contacts.secondary) {
    if (contactType === 'combinedCards') {
      newState.primaryContactCards[index].card = card
      newState.primaryContactCards[index].cardId = card.id
      priorityCardExist = Boolean(newState.primaryContactCards[index].id)
    } else if (contactType === 'primaryContactCards') {
      newState.combinedCards[index].card = card
      newState.combinedCards[index].cardId = card.id
      combinedCardExist = Boolean(newState.combinedCards[index].id)
    }
  }
  const cardExist = Boolean(newState[contactType][index]?.id)
  return {
    newState,
    cardExist,
    priorityCardExist,
    combinedCardExist
  }
}
