import React from 'react'
import Modal from '../../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'
import { OfficeTeamObj } from '../../../../objects/officeTeams'

interface DeleteTeamModalProps {
  team: OfficeTeamObj
  deleteModal(team: OfficeTeamObj): void
  closeModal(): void
}

class DeleteTeamMemberModal extends React.Component<DeleteTeamModalProps> {
  public deleteTeam = () => {
    this.props.deleteModal(this.props.team)
  }
  public render() {
    const { closeModal, team } = this.props
    return (
      <Modal icon={DeleteIcon} title='Remove Team' closeModal={closeModal}>
        <div className='team-management__modal-body'>
          <p className='team-management__modal-text'>{`Are you sure you want to remove ${team.name}? They won’t be able to access clients assigned to them.`}</p>
        </div>

        <div className='team-management__modal-btns-w'>
          <span className='btn btn__clear' onClick={closeModal}>
            Cancel
          </span>
          <span className='btn btn__prime' onClick={this.deleteTeam}>
            Remove Team
          </span>
        </div>
      </Modal>
    )
  }
}

export default DeleteTeamMemberModal
