import Axios from 'axios'
import api from './api'
import moment from 'moment'
import { Buffer } from 'buffer/'
import { GlobalState } from '../../reducers'
import { Dispatch } from 'react-redux'
import { addTimedLoader } from '../../actions/timedLoader'
import * as actions from '../../actions/documentVault'
export const getLogo = async (imageUrl: string) => {
  let logoData: string | null
  if (imageUrl) {
    logoData = await Axios.get(imageUrl, {
      responseType: 'arraybuffer'
    })
      .then((response: any) => {
        return new Buffer(response.data, 'binary').toString('base64')
      })
      .catch(() => {
        return null
      })
  } else {
    logoData = null
  }
  return logoData
}

export const getVaultFolderIdByName = async ({
  vaultFolderName,
  householdId
}: {
  vaultFolderName: string
  householdId: string
}) => {
  const folderCollection = await actions
    ?.getFolders(householdId)
    .payload.then((data) => {
      return data.data
    })

  let vaultFolderId = ''
  Object.keys(folderCollection).forEach((itemKey) => {
    if (folderCollection[itemKey].name === vaultFolderName)
      vaultFolderId = folderCollection[itemKey].id
  })

  if (!vaultFolderId && vaultFolderName && householdId) {
    const response = await actions?.createFolder(vaultFolderName, householdId)
    try {
      await response.payload?.then((data) => (vaultFolderId = data?.data?.id))
    } catch (e) {}
  }
  return vaultFolderId
}

export const getTimeStampFormat = (timeStamp: string) => {
  return timeStamp
    ? moment(timeStamp)
        .toISOString()
        .replace(/[^\w]/g, '')
        .replace('T', '_')
        .replace(/\d{5}Z/, '')
    : ''
}

export const downloadAndUploadFile = ({
  fileName = '',
  timeStamp,
  fileUrl,
  householdFinId,
  vaultFolderId,
  dispatch
}: {
  fileName: string
  timeStamp: string
  fileUrl: string
  householdFinId: string
  vaultFolderId: string
  dispatch: Dispatch<GlobalState>
}) => {
  const isVaultRemodeled = window?._env_?.REACT_APP_VAULT_V2
  const maxVaultFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
  const suffix = getTimeStampFormat(timeStamp)
  const name = `${fileName}_${suffix}.pdf`

  const fileSuccess = () => {
    // Show success message on successful upload
    dispatch(
      addTimedLoader({
        iconType: 'success',
        loadingMessage: 'File successfully uploaded to the vault.',
        loadingType: 'Success'
      })
    )
  }
  const fileFailure = () => {
    // Show error message in case if upload failed
    dispatch(
      addTimedLoader({
        iconType: 'error',
        loadingMessage: `Unable to upload. File exceeds ${maxVaultFileSize} MB in size.`,
        loadingType: 'Error'
      })
    )
  }

  // Call to download the file
  // Check the required fileUrl and vaultFolderId if exist
  fileUrl &&
    vaultFolderId &&
    Axios.get(fileUrl, {
      responseType: 'blob',
      timeout: 0
    }).then((response) => {
      if (response && response.data) {
        if (isVaultRemodeled) {
          // Call to upload the file via Multipart
          const formData = new FormData()
          formData.append('file', response.data, name)
          api()
            .post(
              `/households/${householdFinId}/pdv/v2/folders/${vaultFolderId}/files`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                timeout: 120000
              }
            )
            .then(fileSuccess, fileFailure)
        } else {
          // Call to upload the file via Filereader
          const fileReader = new FileReader()
          fileReader.addEventListener('loadend', () => {
            const result = fileReader.result as string
            const file = result.split('base64,')?.[1]
            const body = {
              name,
              content: file
            }
            return {
              payload: api()
                .post(
                  `/households/${householdFinId}/pdv/folders/${vaultFolderId}/files`,
                  body,
                  {
                    timeout: 0
                  }
                )
                .then(fileSuccess, fileFailure)
            }
          })
          fileReader.readAsDataURL(response.data)
        }
      }
    }, fileFailure)
}
