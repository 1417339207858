import api from '../v3/helpers/api'

export const GET_MARKETPLACE_OFFERS = 'GET_MARKETPLACE_OFFERS'
export const GET_MARKETPLACE_OFFERS_PENDING = 'GET_MARKETPLACE_OFFERS_PENDING'
export const GET_MARKETPLACE_OFFERS_FULFILLED =
  'GET_MARKETPLACE_OFFERS_FULFILLED'
export const GET_MARKETPLACE_OFFERS_REJECTED = 'GET_MARKETPLACE_OFFERS_REJECTED'
export const UPDATE_MARKETPLACE_OFFERS = 'UPDATE_MARKETPLACE_OFFERS'
export const UPDATE_MARKETPLACE_OFFERS_PENDING =
  'UPDATE_MARKETPLACE_OFFERS_PENDING'
export const UPDATE_MARKETPLACE_OFFERS_FULFILLED =
  'UPDATE_MARKETPLACE_OFFERS_FULFILLED'
export const UPDATE_MARKETPLACE_OFFERS_REJECTED =
  'UPDATE_MARKETPLACE_OFFERS_REJECTED'

export const getMarketplaceOffers = (householdFinId: string) => {
  return {
    householdFinId,
    type: GET_MARKETPLACE_OFFERS,
    payload: api()
      .get(`/households/${householdFinId}/marketplaceoffers`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response.data
      })
  }
}

export const updateMarketplaceOffers = (
  householdFinId: string,
  marketplaceOfferId: string,
  offerState: boolean
) => {
  return {
    householdFinId,
    type: UPDATE_MARKETPLACE_OFFERS,
    payload: api()
      .put(
        `/households/${householdFinId}/marketplaceoffers/${marketplaceOfferId}`,
        {
          enabled: offerState
        }
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response.data
      })
  }
}
