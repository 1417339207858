import api from '../v3/helpers/api'

export const GET_HONEST_CONVERSATIONS_CARDS = 'GET_CLIENT_CARDS'

export const getHCCards = (householdFindId: string) => {
  return {
    type: GET_HONEST_CONVERSATIONS_CARDS,
    payload: api()
      .get(`/households/${householdFindId}/honestconversations/meetings/cards`)
      .then((response: any) => {
        return response
      })
  }
}
