import React, { Component } from 'react'

interface HeadingProps {
  icon: React.ReactNode
  headingText: React.ReactNode | string
}

export class Heading extends Component<HeadingProps> {
  public render() {
    return (
      <div className='c-dash__heading'>
        <span className='c-dash__heading-icon'>{this.props.icon}</span>
        <h4 className='c-dash__heading-label'>{this.props.headingText}</h4>
      </div>
    )
  }
}

export default Heading
