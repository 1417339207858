import { isObjEmpty } from './index'
import {
  TotalAllocatedCalculated,
  ClientAccountObjState,
  ClientAccountObj,
  AllocationKeyType,
  CalculatedAllocationAssetObj
} from '../../objects/clientAccount'
import { HouseholdObj } from '../../objects/household'

const filterAccounts = (accounts: any) => {
  return Object.keys(accounts)
    .map((key) => {
      const account = accounts[key]
      if (account && account.pssSelected && account.pssSelected !== null) {
        const totalAllocation = Object.values(
          account.discreteAllocation
        ).reduce((sum: number, curr: any) => {
          return (sum += curr)
        }, 0)
        return Math.round(totalAllocation) !== 100 ? false : account
      } else return false
    })
    .filter((e) => e !== false)
}

export const calculateAccountTotal = (clientAccounts: any) => {
  if (clientAccounts && !isObjEmpty(clientAccounts)) {
    const filteredAccounts = filterAccounts(clientAccounts)
    const total = Object.keys(filteredAccounts).reduce((sum, key) => {
      return filteredAccounts[key].category === 'Short-Term Liabilities' ||
        filteredAccounts[key].category === 'Long-Term Liabilities'
        ? (sum -= filteredAccounts[key].totalValue)
        : (sum += filteredAccounts[key].totalValue)
    }, 0)
    const rawTotal = Object.keys(filteredAccounts).reduce((sum, key) => {
      return (sum += filteredAccounts[key].totalValue)
    }, 0)
    return { total, rawTotal }
  } else return { total: 0, rawTotal: 0 }
}

export const householdTotalTargetAllocation = (
  household: HouseholdObj
): number => {
  if (!household) return 0

  const {
    targetCashAllocation,
    targetEquityAllocation,
    targetFixedAllocation,
    targetOtherAllocation
  } = household
  // manually sort to an array
  return [
    targetCashAllocation,
    targetEquityAllocation,
    targetFixedAllocation,
    targetOtherAllocation
  ].reduce((a: number, b: number | null) => a + b, 0)
}

export const currentAllocationSum = (
  keyType: AllocationKeyType,
  totalAllocationObj: TotalAllocatedCalculated
): number => {
  if (!keyType || !totalAllocationObj) {
    return 0
  }
  const valueArray: number[] = []
  const allocationMap = new Map(Object.entries(totalAllocationObj))
  allocationMap.forEach((allocation: CalculatedAllocationAssetObj) => {
    valueArray.push(allocation[keyType])
  })
  return valueArray.reduce((a: number, b: number) => a + b, 0)
}

export const calculateAssetAllocation = (
  clientAccounts: any,
  individual?: boolean
): TotalAllocatedCalculated => {
  const calculatedAllocations: TotalAllocatedCalculated = {
    equity: { percent: 0, amount: 0, rawAmount: 0 },
    fixed: { percent: 0, amount: 0, rawAmount: 0 },
    cash: { percent: 0, amount: 0, rawAmount: 0 },
    alt: { percent: 0, amount: 0, rawAmount: 0 },
    unclassified: { percent: 0, amount: 0, rawAmount: 0 }
  }

  if (clientAccounts && !isObjEmpty(clientAccounts)) {
    let accountTotal = { total: 0, rawTotal: 0 }
    let filteredAccounts: object[] = []
    if (!individual) {
      accountTotal = calculateAccountTotal(clientAccounts)
      filteredAccounts = filterAccounts(clientAccounts)
    } else {
      accountTotal = {
        total: clientAccounts.totalValue,
        rawTotal: clientAccounts.totalValue
      }
      filteredAccounts.push(clientAccounts)
    }
    if (
      (accountTotal.total || accountTotal.rawTotal) &&
      filterAccounts.length >= 1
    ) {
      Object.keys(filteredAccounts).forEach((key) => {
        const account: ClientAccountObj = filteredAccounts[key]
        if (
          account.discreteAllocation &&
          isObjEmpty(account.discreteAllocation)
        ) {
          if (
            account.category === 'Short-Term Liabilities' ||
            account.category === 'Long-Term Liabilities'
          ) {
            calculatedAllocations.unclassified.amount -= account.totalValue
          } else {
            calculatedAllocations.unclassified.amount += account.totalValue
            calculatedAllocations.unclassified.rawAmount += account.totalValue
          }
        } else {
          Object.keys(account.discreteAllocation).forEach((allocation) => {
            const calc = Number(
              account.totalValue *
                (account.discreteAllocation[allocation] / 100)
            )
            if (
              account.category === 'Short-Term Liabilities' ||
              account.category === 'Long-Term Liabilities'
            ) {
              calculatedAllocations[allocation].amount -= calc
            } else {
              calculatedAllocations[allocation].amount += calc
              calculatedAllocations[allocation].rawAmount += calc
            }
          })
        }

        Object.keys(calculatedAllocations).forEach(
          (rollupKey) =>
            (calculatedAllocations[rollupKey].percent = Math.round(
              (calculatedAllocations[rollupKey].rawAmount /
                accountTotal.rawTotal) *
                100
            ))
        )
      })
    }
  }
  return calculatedAllocations
}

export const mapOwnerIds = (household: HouseholdObj): any => {
  const accountOwnerList = {
    Primary: { value: 'Primary', label: 'Primary', extValue: '' },
    Secondary: { value: 'Secondary', label: 'Secondary', extValue: '' },
    Joint: { value: 'Joint', label: 'Joint', extValue: '' }
  }
  accountOwnerList.Primary.extValue = household.primaryContact
  accountOwnerList.Secondary.extValue = household.secondaryContact
  accountOwnerList.Joint.extValue =
    !household.primaryContact === false && !household.secondaryContact === false
      ? household.primaryContact.concat(',', household.secondaryContact)
      : ''
  Object.keys(accountOwnerList).map((accountOwner: string) => {
    if (!accountOwnerList[accountOwner].extValue === true) {
      delete accountOwnerList[accountOwner]
    }
  })
  return accountOwnerList
}

export const excludeUndefined = (unfilteredObj: any): any => {
  return ((filteredObj) => {
    const isUndefined = (key: string) => {
      if (
        (filteredObj[key] === 'undefined' || filteredObj[key] === 'null') &&
        key !== 'balanceDate'
      ) {
        delete filteredObj[key]
      }
      if (
        (filteredObj[key] === undefined || filteredObj[key] === null) &&
        key !== 'balanceDate'
      ) {
        delete filteredObj[key]
      }
    }
    Object.keys(filteredObj).filter(isUndefined)
    return filteredObj
  })(unfilteredObj)
}

export const validateStringInput = (valueString: string): boolean => {
  return true // (valueString && valueString.length > 0) ? true : false;
}

export const validateStringInputChars = (valueString: string): boolean => {
  const allow = new RegExp('^[0-9 A-Za-z_,.-/-]+$')
  return valueString && valueString.length > 0 ? allow.test(valueString) : true
}

export const filterAccountsByCategory = (
  clientAccounts: ClientAccountObjState,
  category: string | string[] | null
): {} => {
  const filteredAccounts = {}
  if (clientAccounts) {
    Object.keys(clientAccounts)
      .filter((key: string) => {
        const clientAccount = clientAccounts[key]
        if (category === null) {
          return clientAccount.category === null
        } else {
          return (
            clientAccount.category && category.includes(clientAccount.category)
          )
        }
      })
      .forEach((key: string) => {
        filteredAccounts[key] = clientAccounts[key]
      })
    return filteredAccounts
  }

  return {}
}
