import React, { FC, CSSProperties } from 'react'

const style: CSSProperties = {
  background: 'rgba(91, 208, 179, 0.4)',
  color: '#22263f',
  lineHeight: '16px',
  fontSize: '12px',
  padding: '4px 8px',
  marginLeft: '10px'
}

export const GSExclusiveTag: FC<{ exclusiveName: string }> = (props) =>
  props.exclusiveName ? <span style={style}>{props.exclusiveName}</span> : null
