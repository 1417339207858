import React from 'react'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../../reducers'
import { connect } from 'react-redux'
import qs from 'query-string'
import { MSDynamicsCRM } from '../../../../objects/institution'

interface TamaracAuthProps {
  crmLoginUrl: string
  crmSource: MSDynamicsCRM
}

class TamaracAuth extends React.Component<TamaracAuthProps> {
  public componentDidMount() {
    const { crmSource } = this.props
    const url: string = `${window.location.protocol}//${window.location.host}/auth/crm/ms`
    const authQS = qs.stringify({
      client_id: window._env_.REACT_APP_TAMARAC_CLIENT_ID,
      response_type: 'code',
      redirect_uri: url,
      scope: window._env_.REACT_APP_TAMARAC_SCOPE,
      resource: this.props.crmLoginUrl,
      state: crmSource
    })

    const tamaracLoginUrl = `${window._env_.REACT_APP_TAMARAC_AUTH_URL}/common/oauth2/authorize?${authQS}`

    window.open(tamaracLoginUrl, '_self')
  }

  public render() {
    return <div />
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    crmLoginUrl: store.institution.crmLoginUrl || ''
  }
}

export default withRouter(connect(mapStateToProps)(TamaracAuth))
