import * as React from 'react'

export interface IntRadioButton {
  underlineTextOnHover?: boolean
  id: string
  name: string
  value: string | number
  type?: string
  labelDisplay: string | number
  className?: string
  checked?: boolean
  changeFunc?(
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>
  ): void
}

export interface IntRadioGroup {
  radios: IntRadioButton[]
}

export const RadioButton: React.FunctionComponent<IntRadioButton> = ({
  id,
  name,
  value,
  checked,
  labelDisplay,
  type,
  changeFunc,
  underlineTextOnHover
}) => {
  return (
    <div>
      <input
        id={id}
        className={underlineTextOnHover && 'radio__hover'}
        name={name}
        value={value}
        onChange={changeFunc}
        role={type || 'radio'}
        type={type || 'radio'}
        aria-checked={checked}
        checked={checked}
      />
      <label
        className={`radio__label ${underlineTextOnHover && 'radio__hover'}`}
        htmlFor={id}>
        {labelDisplay}
      </label>
    </div>
  )
}

const radioButtonList = (radioGroup: IntRadioButton[]) => {
  const buttonList: any[] = []
  if (!radioGroup || radioGroup.length === 0) return buttonList
  radioGroup.forEach((radioButton, index) => {
    buttonList.push(<RadioButton key={index} {...radioButton} />)
  })

  return buttonList
}

export const RadioGroup: React.FunctionComponent<IntRadioGroup> = ({
  radios
}) => <div>{radioButtonList(radios)}</div>
