import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'

import * as householdsActions from '../../../actions/households'
import { GlobalState } from '../../../reducers'
import { HouseholdObj } from '../../../objects/household'
import ActionDropdown from '../../components/actionDropdown'

import viewFinderIcon from '../../assets/images/icons/png/viewfinder.png'
import viewFinderGrayIcon from '../../assets/images/icons/png/viewfinder_gray.png'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg'
// hide delete button from UI until action endpoint is implemented
// import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { InvestmentViewfinderExerciseObj } from '../../../objects/investmentViewfinder'
import TableRow from '../../components/layout/tableRow'

interface InvestmentRowPorps {
  dispatch?: Dispatch<GlobalState>
  viewfinderExercise: InvestmentViewfinderExerciseObj
  household: HouseholdObj
  index: number
  householdFinId: string
}

class InvestmentHistoryRow extends Component<InvestmentRowPorps> {
  public toggleSetActive = () => {
    const { householdFinId, viewfinderExercise } = this.props
    this.props.dispatch(
      householdsActions.setActiveInvestmentPriority(
        householdFinId,
        viewfinderExercise.id
      )
    )
  }

  // hide delete from UI until endpoint for delete action is implemented
  /*
  public toggleDeleteRecord = () => {
    const { householdFinId, investment } = this.props;
    this.props.dispatch(actions.deleteInvestmentPriority(householdFinId, investment.id));
  }
  */

  public setActive() {
    return (
      <Fragment>
        <CheckIcon />
        <span className='action-dropdown__item-label'>Set as Active</span>
      </Fragment>
    )
  }

  public deleteRecord() {
    return {
      // hide delete button from UI until action endpoint is implemented
      /*
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete Priority</span>
      </Fragment>
      */
    }
  }

  public ResultChart = (
    name: string,
    scoregBgColor: string,
    idealScoreBgColor: string,
    score: number,
    idealScore: number
  ) => {
    return (
      <div className='inv-history__graph-w'>
        <div className='inv-history__graph'>
          <span
            className='inv-history__graph-ideal'
            style={{
              width: (idealScore / 14) * 100 + '%',
              backgroundColor: idealScoreBgColor
            }}
          />
          <span
            className='inv-history__graph-score'
            style={{ width: score + '%', backgroundColor: scoregBgColor }}
          />
        </div>
        <span className='inv-history__graph-label'>{name}</span>
      </div>
    )
  }

  public render() {
    const { viewfinderExercise, index, householdFinId } = this.props
    const dropdownActions = [
      {
        element: this.setActive(),
        onClick: this.toggleSetActive
      }
      // hide delete button from UI until action endpoint is implemented
      /*
      ,{
        element: this.deleteRecord(),
        onClick: this.toggleDeleteRecord
      }
      */
    ]

    if (
      viewfinderExercise &&
      (viewfinderExercise.taxes ||
        viewfinderExercise.performance ||
        viewfinderExercise.protection ||
        viewfinderExercise.cost)
    ) {
      const activeIdStatus =
        viewfinderExercise.id === this.props.household.invresponseId
      const idFormatted = viewfinderExercise.id
        .substr(viewfinderExercise.id.length - 4, 4)
        .toUpperCase()
      const createdDate = viewfinderExercise.createdDate
        .toString()
        .split('T')[0]

      return (
        <TableRow
          key={index}
          to={`/households/${householdFinId}/investmentViewfinder/${viewfinderExercise.id}`}>
          <span className='inv-history__results-name'>
            {
              <img
                src={activeIdStatus ? viewFinderIcon : viewFinderGrayIcon}
                className='inv-history__active-img'
                alt=''
                role='presentation'
              />
            }
            <h4>{`IV-${idFormatted}`}</h4>
            {
              <span
                className={
                  activeIdStatus
                    ? 'inv-history__active-badge inv-history__active-badge--active'
                    : 'inv-history__active-badge'
                }
              />
            }
          </span>
          <span className='inv-history__results-results'>
            {this.ResultChart(
              'Performance',
              '#9DD47D',
              '#DAEBD1',
              0,
              viewfinderExercise.performance
            )}
            {this.ResultChart(
              'Protection',
              '#3386BE',
              '#A6C9DE',
              0,
              viewfinderExercise.protection
            )}
            {this.ResultChart(
              'Costs',
              '#F6AA33',
              '#F5DEBA',
              0,
              viewfinderExercise.cost
            )}
            {this.ResultChart(
              'Taxes',
              '#AD6A99',
              '#E7D3E1',
              0,
              viewfinderExercise.taxes
            )}
          </span>
          <span className='inv-history__results-updated'>
            <span className='inv-history__results-updated-date'>
              {createdDate}
            </span>
            <ActionDropdown actions={dropdownActions} />
          </span>
        </TableRow>
      )
    } else {
      return null
    }
  }
}

export default connect()(InvestmentHistoryRow)
