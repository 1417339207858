import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'

import InnerContainer from '../../components/layout/innerContainer'
import ContentHeader from '../../components/layout/contentHeader'
import QuickActionLinks from '../../components/quickActionLinks'
import Tile from '../../components/layout/tile'
import { dollarFormat } from '../../helpers'
import { calculateAccountTotal } from '../../helpers/netWorth'
import AssetAllocation from './assetAllocation'
import PartnerLogin from './partnerLogin'
import ClientAccounts from './clientAccounts'
import ExerciseGraph from './exerciseGraph'
import PortfolioBuilderModal from './portfolioBuilderModal'
import DisabledModal from './disabledModal'

import * as clientAccounts from '../../../actions/clientAccounts'
import * as viewfinderActions from '../../../actions/investmentViewfinder'
import * as portfolioBuilderActions from '../../../actions/portfolioBuilder'
import * as householdActions from '../../../actions/households'
import { InvestmentViewfinderInterface } from '../../../objects/investmentViewfinder'
import { ClientAccountObjState } from '../../../objects/clientAccount'
import { HouseholdObj } from '../../../objects/household'

import { ReactComponent as InvestmentsIconSVG } from '../../assets/images/icons/investments.svg'
import EditIcon from '../../assets/images/icons/general/edit-blue.png'
import InvestmentsIcon from '../../assets/images/icons/png/viewfinder.png'
import DisabledInvestmentsIcon from '../../assets/images/icons/png/viewfinder_gray.png'
import ActivityIcon from '../../assets/images/icons/ic_activity.svg'
import DownArrowIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { history } from '../../../store'

import { HouseholdPreferencesObj } from '../../../objects/householdPreferences'
import { generateNewPreferences } from '../../helpers/householdPreferences'

export interface InvestmentProps {
  dispatch?: Dispatch<GlobalState>
  preferences: HouseholdPreferencesObj
  investmentViewfinder: InvestmentViewfinderInterface
  clientAccounts: ClientAccountObjState
  household: HouseholdObj
  householdFinId: string
  showInvestments: boolean
}

interface InvestmentState {
  showPortfolioBuilder: boolean
  showAddPartnerModal: boolean
  showInvestingPriorities: boolean
  showDisabledModal: boolean
}

export class Investment extends Component<InvestmentProps, InvestmentState> {
  constructor(props: InvestmentProps) {
    super(props)
    this.state = {
      showPortfolioBuilder: false,
      showAddPartnerModal: false,
      showInvestingPriorities: this.props.showInvestments,
      showDisabledModal: false
    }
  }

  public componentDidMount() {
    const { householdFinId, dispatch } = this.props
    dispatch(householdActions.getIndividualHousehold(householdFinId))
    dispatch(clientAccounts.getClientAccounts(householdFinId))
    dispatch(
      viewfinderActions.getViewfinderExercises(this.props.householdFinId)
    )
    dispatch(portfolioBuilderActions.getPortfolioBuilders(householdFinId))
  }

  public toggleDisableModal = () => {
    this.setState({ showDisabledModal: !this.state.showDisabledModal })
  }

  public toggleAddPartnerModal = () => {
    this.setState({ showAddPartnerModal: !this.state.showAddPartnerModal })
  }

  public togglePortfolioBuilder = () => {
    this.setState({ showPortfolioBuilder: !this.state.showPortfolioBuilder })
  }

  public accountsAmount = () => {
    const accountTotal = calculateAccountTotal(this.props.clientAccounts).total
    return (
      <span className='investment__allocation-amount'>
        {this.props.clientAccounts ? dollarFormat(accountTotal, 0) : ' '}
      </span>
    )
  }

  public populateExerciseGraph = () => {
    const { investmentViewfinder, household } = this.props
    const activeViewfinderExercise =
      investmentViewfinder[household.invresponseId]
    return <ExerciseGraph viewfinderExercise={activeViewfinderExercise} />
  }

  public launchViewfinder = async () => {
    const { household } = this.props
    await this.props.dispatch(
      viewfinderActions.createViewfinderExercise(household.id)
    )
    history.push(
      `/households/${household.id}/investmentViewfinder/${this.props.investmentViewfinder.newViewfinder.id}`
    )
  }

  public investingPrioritiesToggle = () => {
    return (
      <div
        onClick={
          this.props.showInvestments ? this.toggleInvestingPriorities : null
        }
        className='investment__inv-priorities-toggle-w'>
        <img
          src={DownArrowIcon}
          alt=''
          role='presentation'
          className={
            'investment__inv-priorities-toggle-arrow ' +
            (this.state.showInvestingPriorities
              ? 'investment__inv-priorities-toggle-arrow--down'
              : 'investment__inv-priorities-toggle-arrow--up')
          }
        />
      </div>
    )
  }

  public toggleInvestingPriorities = () => {
    this.setState({
      showInvestingPriorities: !this.state.showInvestingPriorities
    })
  }

  public showArrow = () => {
    return (
      <div onClick={this.toggleInvestingPriorities} className='toggle-arrow'>
        <img
          src={DownArrowIcon}
          alt=''
          role='presentation'
          className={
            this.state.showInvestingPriorities
              ? 'toggle-arrow--down'
              : 'toggle-arrow--up'
          }
        />
      </div>
    )
  }

  public headerLinks = () => {
    return (
      <QuickActionLinks portfolioBuilderAction={this.togglePortfolioBuilder} />
    )
  }

  public render() {
    const { showInvestingPriorities } = this.state
    const {
      investmentViewfinder,
      household,
      householdFinId,
      preferences,
      showInvestments
    } = this.props

    const currentPreferences = preferences || generateNewPreferences()
    const exerciseQuickActions = [
      {
        text: 'History',
        icon: ActivityIcon,
        to: `/households/${this.props.householdFinId}/investmentHistory`,
        isDisabledText: false
      },
      {
        text: 'InvestmentViewfinder',
        icon: showInvestments ? InvestmentsIcon : DisabledInvestmentsIcon,
        onClick: showInvestments
          ? this.launchViewfinder
          : this.toggleDisableModal,
        isDisabledText: !showInvestments
      }
    ]

    const partnerQuickActions = [
      {
        text: 'Edit Partners',
        icon: EditIcon,
        onClick: this.toggleAddPartnerModal
      }
    ]

    return (
      <InnerContainer>
        <ContentHeader
          Icon={InvestmentsIconSVG}
          title='Investments'
          rightHeader={this.headerLinks()}
        />
        <div className='investment__inv-priorities-w'>
          <Tile
            leftHeader={'INVESTING PRIORITIES'}
            headerBorder={true}
            rightHeader={
              investmentViewfinder ? (
                <div className='investment__inv-priorities-w'>
                  <QuickActionLinks customQuickAction={exerciseQuickActions} />
                  {this.investingPrioritiesToggle()}
                </div>
              ) : null
            }
            contentStyle={showInvestingPriorities ? null : { display: 'none' }}>
            {investmentViewfinder && household && this.populateExerciseGraph()}
          </Tile>
        </div>
        <div className='investment__allocation-w'>
          <Tile
            headerStyle={{ backgroundColor: '#FAFAFA' }}
            leftHeader={'ASSET ALLOCATION'}
            headerBorder={true}
            rightHeader={this.accountsAmount()}>
            {this.props.clientAccounts && household ? (
              <AssetAllocation
                clientAccounts={this.props.clientAccounts}
                household={household}
              />
            ) : null}
          </Tile>
        </div>
        <div className='investment__accounts-w'>
          <ClientAccounts
            householdFinId={householdFinId}
            clientAccounts={this.props.clientAccounts}
            persistedSortBy={currentPreferences.investmentsInvestmentsSortBy}
            persistedGroupBy={currentPreferences.investmentsInvestmentsGroupBy}
            persistedSortingOrder={
              currentPreferences.investmentsInvestmentsSortingOrder
            }
            preferences={preferences}
            showPreferencesToggle={true}
          />
        </div>
        <div className='investment__partner-w'>
          <Tile
            headerStyle={{ backgroundColor: '#FAFAFA' }}
            leftHeader={'PARTNER LOGIN'}
            headerBorder={true}
            rightHeader={
              <QuickActionLinks customQuickAction={partnerQuickActions} />
            }>
            <PartnerLogin
              householdFinId={householdFinId}
              onlinePortals={household.onlinePortals}
              showAddPartnerModal={this.state.showAddPartnerModal}
              toggleAddPartnerModal={this.toggleAddPartnerModal}
            />
          </Tile>
        </div>
        {this.state.showPortfolioBuilder ? (
          <PortfolioBuilderModal
            closeModal={this.togglePortfolioBuilder}
            householdFinId={householdFinId}
          />
        ) : null}
        {this.state.showDisabledModal ? (
          <DisabledModal
            closeModal={this.toggleDisableModal}
            householdFinId={householdFinId}
          />
        ) : null}
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    clientAccounts:
      store.clientAccount[match.params.householdFinId] &&
      store.clientAccount[match.params.householdFinId].clientAccounts,
    investmentViewfinder:
      store.investmentViewfinder.exercises[match.params.householdFinId],
    householdFinId: match.params.householdFinId,
    household: store.households[match.params.householdFinId],
    preferences:
      store.householdPreferences[match.params.householdFinId] &&
      store.householdPreferences[match.params.householdFinId].preferences,
    showInvestments:
      store.households[match.params.householdFinId].showInvestments
  }
}

export default withRouter(connect(mapStateToProps)(Investment))
