import React, { CSSProperties, ReactNode, FunctionComponent } from 'react'
import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  HcParagraph,
  HcWelcomeContainer,
  HcWelcomeDiv,
  HcWelcomeTitle
} from './hcWelcomeHelper'

export const StartContent: FunctionComponent<{
  tileStyle: CSSProperties
  children?: ReactNode
  clickFunc(): void
}> = ({ tileStyle, clickFunc, children }) => (
  <Tile tileStyle={tileStyle}>
    <HcWelcomeContainer>
      <HcWelcomeDiv className='hcw__welcome hcw__welcome-image' />
      <HcWelcomeDiv className='hcw__welcome_messege-row'>
        <HcWelcomeDiv className='hcw__welcome_messege-col'>
          <HcWelcomeTitle className='hcw__welcome_messege-title-m'>
            To get started...
          </HcWelcomeTitle>
          <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-goto'>
            Click on the button below to select individual priorities
          </HcParagraph>
          {children}
        </HcWelcomeDiv>
      </HcWelcomeDiv>
    </HcWelcomeContainer>
  </Tile>
)
