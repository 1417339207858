import { ContactsInterface } from '../objects/contact'
import * as actions from '../actions/contacts'
import { store } from '../store'

export interface ContactState {
  [key: string]: ContactsInterface
}

const initState: ContactState = {}

const mapContact = (contact: any) => {
  if (contact) {
    return {
      id: contact.id,
      firstName: contact.firstName ? contact.firstName : '',
      lastName: contact.lastName ? contact.lastName : '',
      photo: contact.photoUrl,
      email: contact.email,
      mobile: contact.mobilePhone,
      home: contact.homePhone,
      work: contact.workPhone,
      street: contact.mailingStreet,
      city: contact.mailingCity,
      state: contact.mailingState,
      zip: contact.mailingPostalCode,
      benchmarkClientPortal: contact.benchmarkClientPortal,
      activeYmmId: contact.activeYmmResponseId,
      dateOfBirth: contact.birthdate,
      gender: contact.gender,
      maritalStatus: contact.maritalStatus,
      employmentStatus: contact.employmentStatus,
      enabled: contact.guidecenterEnabled,
      guidecenterUserId: contact.guidecenterUserId,
      username: contact.email,
      userEmail: contact.email,
      hasError: false,
      accountErrorEmailDate: contact.accountErrorEmailDate,
      moneymindEmailDate: contact.moneymindEmailDate
    }
  } else return null
}

const mapContacts = (contacts: any, newState: ContactState): ContactState => {
  if (contacts) {
    const obj = {}
    contacts.forEach((contact: any) => {
      const household = store.getState().households[contact.household]
      if (household) {
        if (!obj[contact.household]) {
          obj[contact.household] = {}
        }
        const type =
          household.primaryContact === contact.id
            ? 'primary'
            : household.secondaryContact === contact.id
            ? 'secondary'
            : 'tertiary'
        obj[contact.household][type] = newState[contact.household]
          ? { ...newState[contact.household][type], ...mapContact(contact) }
          : mapContact(contact)
      }
    })
    return obj
  } else return {}
}

const Contacts = (state = initState, action: any): ContactState => {
  let contact
  let household
  let householdId
  let type
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_CONTACTS_FULFILLED:
      const contacts = mapContacts(action.payload.data, newState)
      return { ...state, ...contacts }
    case actions.UPDATE_CONTACT_FULFILLED:
    case actions.FETCH_CONTACT_FULFILLED:
      if (!state[action.payload.household]) {
        newState[action.payload.household] = {}
      }
      contact = action.payload.data[0]
      household = store.getState().households[contact.household]
      type =
        household && household.primaryContact === contact.id
          ? 'primary'
          : household.secondaryContact === contact.id
          ? 'secondary'
          : 'tertiary'
      newState[contact.household][type] = {
        ...newState[contact.household][type],
        ...mapContact(contact),
        jitFetched: true,
        photo: newState[contact.household][type].photo
      }
      return newState
    case actions.ENABLE_GC_FULFILLED:
      const contactId = action.payload.data.contactId
      householdId = action.payload.data.householdId
      household = store.getState().households[householdId]
      type =
        household.primaryContact === contactId
          ? 'primary'
          : household.secondaryContact === contactId
          ? 'secondary'
          : 'tertiary'
      if (!action.payload.data.hasError) {
        newState[householdId][type] = {
          ...newState[householdId][type],
          enabled: true,
          hasError: false
        }
      } else {
        newState[householdId][type] = {
          ...newState[householdId][type],
          enabled: false,
          hasError: true
        }
      }
      return newState
    case actions.GET_USERNAME_FULFILLED:
      const result = action.payload.data
      const ignoredApiFields = result?.ignoredApiFields || []
      if (action.payload.status === 200 && result) {
        household = store.getState().households[result?.householdId]
        contact = store.getState().contact[result?.householdId]
        type =
          household?.primaryContact === result?.contactId
            ? 'primary'
            : household?.secondaryContact === result?.contactId
            ? 'secondary'
            : 'tertiary'
        const {
          accountStatus = newState?.[result?.householdId]?.[type]?.[
            'accountStatus'
          ],
          username,
          email,
          lastLoginDate,
          lastResendWelcomeSent = '',
          lastResetPasswordSent = ''
        } = result
        newState[result.householdId][type] = {
          ...newState[result.householdId][type],
          username: username || contact?.[type]?.userEmail,
          accountStatus: ignoredApiFields?.includes('accountStatus')
            ? newState?.[result?.householdId]?.[type]?.['accountStatus']
            : accountStatus,
          userEmail: email || contact?.[type]?.userEmail,
          lastLogin: lastLoginDate,
          lastResetPasswordSent,
          lastResendWelcomeSent
        }
      }
      return newState
    case `${actions.UPDATE_IDEAL_ZONE}_FULFILLED`:
      contact = action.payload.data
      household = store.getState().households[contact.household]
      type =
        household.primaryContact === contact.id
          ? 'primary'
          : household.secondaryContact === contact.id
          ? 'secondary'
          : 'tertiary'
      newState[contact.household][type].benchmarkClientPortal = action.payload
        .data
        ? action.payload.data.benchmarkClientPortal
        : newState[contact.household][type].benchmarkClientPortal
      return newState
    default:
      return state
  }
}
export default Contacts
