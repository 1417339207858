import React, { Component, FormEvent } from 'react'
import Select from 'react-select'

import { formatCurrencyInput } from '../../../helpers'
import {
  FinancialGoalObj,
  NeedsWantsWishesType
} from '../../../../objects/financialGoal'
import Modal from '../../../components/layout/modal'
import Button from '../../../components/button'
import Tooltip from '../../../components/tooltip'
import { GoalRequestObj } from '../../../../objects/goalRequests'

import { ReactComponent as GoalIcon } from '../../../assets/images/icons/goals.svg'

interface GoalModalProps {
  title: string
  submitText: string
  goal?: FinancialGoalObj
  goalObj?: any
  submit(
    goal: FinancialGoalObj,
    request: GoalRequestObj | FinancialGoalObj
  ): void
  closeModal(): void
}

interface GoalModalState {
  id: string
  existingId?: string
  name: string
  type: string
  owner: string
  startDate: string
  endDate: string
  amount: string
  amountType: string
  annualIncrease: number | string
  nameErr: boolean
  amountErr: boolean
  typeErr: boolean
  amountTypeErr: boolean
  startDateErr: boolean
  endDateErr: boolean
  needsWantsWishes: NeedsWantsWishesType
}

class GoalModal extends Component<GoalModalProps, GoalModalState> {
  public types = [
    'Spending Goal',
    'Savings Goal',
    'Legacy Goal',
    'Retirement/Other Income'
  ]
  constructor(props: GoalModalProps) {
    super(props)
    const { goal, goalObj } = this.props
    this.state = {
      id: goal?.goalRequest ? goal?.id : goalObj?.id ? goalObj.id : '',
      existingId: goalObj?.existingId || null,
      name: goalObj?.name || '',
      type: goalObj?.type || '',
      owner: goalObj?.owner || '',
      startDate: goalObj?.startDate || '',
      endDate: goalObj?.endDate || '',
      amount: goalObj?.amount || '',
      amountType: goalObj?.amountType || '',
      annualIncrease: goalObj?.annualIncrease || '',
      needsWantsWishes: goalObj?.needsWantsWishes,
      nameErr: false,
      amountErr: false,
      typeErr: false,
      amountTypeErr: false,
      startDateErr: false,
      endDateErr: false
    }
  }

  public handleSelectChange = (type: string) => (e: any) => {
    this.setState({ ...this.state, [type]: e.value })
  }

  public handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    if (name === 'amount') this.setState({ amount: formatCurrencyInput(value) })
    else if (name === 'annualIncrease')
      this.setState({ annualIncrease: formatCurrencyInput(value) })
    else this.setState({ ...this.state, [name]: value })
  }

  public handleSelectInput = (name: string, shouldMarkError: any) => {
    const currencyOptions = {
      Dollar: { value: 'Dollar', label: '$' },
      Percent: { value: 'Percent', label: '%' }
    }
    const typeOptions = {
      'Spending Goal': { value: 'Spending Goal', label: 'Spending' },
      'Savings Goal': { value: 'Savings Goal', label: 'Savings' },
      'Legacy Goal': { value: 'Legacy Goal', label: 'Legacy' },
      'Timing Control': { value: 'Timing Control', label: 'Timing' },
      'Retirement/Other Income': {
        value: 'Retirement/Other Income',
        label: 'Retirement Income'
      }
    }
    if (name === 'type') {
      return (
        <Select
          className={
            shouldMarkError('type')
              ? 'goal-modal__select--error'
              : 'goal-modal__select'
          }
          classNamePrefix='goal-modal'
          options={Object.keys(typeOptions).map((key) => typeOptions[key])}
          defaultValue={typeOptions[this.state.type]}
          name='type'
          placeholder='Select Type'
          onBlur={this.handleBlur('typeErr')}
          onChange={this.handleSelectChange('type')}
        />
      )
    }
    if (name === 'amountType') {
      return (
        <Select
          className={
            shouldMarkError('amountType')
              ? 'goal-modal__select--error'
              : 'goal-modal__select'
          }
          classNamePrefix='goal-modal'
          placeholder=''
          options={Object.keys(currencyOptions).map(
            (key) => currencyOptions[key]
          )}
          defaultValue={currencyOptions[this.state.amountType]}
          name='amountType'
          onBlur={this.handleBlur('amountTypeErr')}
          onChange={this.handleSelectChange('amountType')}
        />
      )
    } else return null
  }

  public submit = () => {
    if (!this.canBeSubmitted()) {
      return
    }
    const request: GoalRequestObj | FinancialGoalObj =
      this.props.goal?.goalRequest || this.props.goal
    this.props.submit(
      {
        id: this.state.id,
        name: this.state.name,
        type: this.state.type,
        owner: this.state.owner,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        amount: this.state.amount,
        amountType: this.state.amountType,
        annualIncrease: this.state.annualIncrease,
        needsWantsWishes: this.state.needsWantsWishes
      },
      request
    )
  }

  public canBeSubmitted = () => {
    const { name, amount, amountType, startDate, endDate, type } = this.state
    const errors = this.validate(
      name,
      type,
      amount,
      amountType,
      startDate,
      endDate
    )
    const isDisabled = Object.keys(errors).some((x) => errors[x])
    return !isDisabled
  }

  public validate = (
    name: string,
    type: string,
    amount?: string,
    amountType?: string,
    startDate?: string,
    endDate?: string
  ) => {
    const allVal = {
      name: name.length === 0,
      type: type.length === 0
    }
    if (type === 'Timing Control') {
      return {
        ...allVal,
        startDate: startDate.length === 0,
        endDate: endDate.length === 0
      }
    } else if (this.types.indexOf(type) !== -1) {
      return {
        ...allVal,
        amount: amount.length === 0,
        amountType: amountType.length === 0
      }
    } else return allVal
  }

  public validateForm = (errors: object) => () => {
    const newState = this.state
    Object.keys(errors).forEach((e) => (newState[`${e}Err`] = true))
    this.setState(newState)
  }

  public handleBlur = (field: any) => () => {
    this.setState({ ...this.state, [field]: true })
  }

  public shouldMarkError = (field: string) => {
    const { name, amount, amountType, startDate, endDate, type } = this.state
    const errors = this.validate(
      name,
      type,
      amount,
      amountType,
      startDate,
      endDate
    )
    const hasError = errors[field]
    const shouldShow = this.state[`${field}Err`]
    return hasError ? shouldShow : false
  }

  public formatInput = (
    errors: object,
    labelName: string,
    inputName: string,
    placeholder?: string,
    inputType?: string
  ) => {
    let amountSign = null
    if (inputName === 'amount') {
      if (this.state.amountType === 'Dollar') {
        amountSign = `$${this.state.amount}`
      }
      if (this.state.amountType === 'Percent') {
        amountSign = `${this.state.amount}%`
      }
    }
    return (
      <label className='goal-modal__form-label'>
        {Object.keys(errors).indexOf(inputName) !== -1 ? (
          <Tooltip
            target='*'
            labelName={labelName}
            message='This is a mandatory field'
            position='bottom'
          />
        ) : (
          labelName
        )}
        {inputName === 'amountType' || inputName === 'type' ? (
          this.handleSelectInput(inputName, this.shouldMarkError)
        ) : (
          <input
            tabIndex={1}
            type={inputType || 'text'}
            className={
              this.shouldMarkError(inputName)
                ? 'folder-modal__content--error'
                : 'folder-modal__content--input'
            }
            name={inputName}
            placeholder={placeholder}
            onBlur={this.handleBlur(`${inputName}Err`)}
            onChange={this.handleInputChange}
            value={amountSign || this.state[inputName]}
          />
        )}
        {this.shouldMarkError(inputName) ? (
          <div className='folder-modal__error-message'>
            {labelName} is required
          </div>
        ) : (
          ''
        )}
      </label>
    )
  }

  public render() {
    const { closeModal, submitText, title } = this.props
    const { name, amount, amountType, startDate, endDate, type } = this.state
    const errors = this.validate(
      name,
      type,
      amount,
      amountType,
      startDate,
      endDate
    )
    const isDisabled = Object.keys(errors).some((x) => errors[x])
    return (
      <Modal icon={GoalIcon} title={title} closeModal={closeModal}>
        <div className='modal__content'>
          <div className='goal-modal__form-row'>
            <div className='goal-modal__form-column'>
              <div className='goal-modal__form-row'>
                {this.formatInput(errors, 'Name ', 'name', 'e.g Vacation Home')}
              </div>
              <div className='goal-modal__form-row'>
                {this.formatInput(
                  errors,
                  'Owner ',
                  'owner',
                  'e.g. Jonathan Doe'
                )}
              </div>
              <div className='goal-modal__form-row'>
                {this.formatInput(errors, 'Amount ', 'amount', 'e.g. $100,000')}
                {this.formatInput(
                  errors,
                  'Amount Type ',
                  'amountType',
                  'e.g. $100,000'
                )}
              </div>
            </div>
            <div className='goal-modal__form-column'>
              <div className='goal-modal__form-row'>
                {this.formatInput(errors, 'Type ', 'type')}
              </div>
              <div className='goal-modal__form-row'>
                {this.formatInput(
                  errors,
                  'Start Date ',
                  'startDate',
                  'e.g. 2/2/18'
                )}
                {this.formatInput(
                  errors,
                  'End Date ',
                  'endDate',
                  'e.g. 2/2/20'
                )}
              </div>
              <div className='goal-modal__form-row'>
                {this.formatInput(
                  errors,
                  'Annual Increase ',
                  'annualIncrease',
                  'e.g. 2%',
                  'number'
                )}
              </div>
            </div>
          </div>
          <div className='modal__buttons'>
            <div>
              <Button clear={true} onClick={closeModal}>
                Cancel
              </Button>
              <Button
                primary={!isDisabled}
                onClick={!isDisabled ? this.submit : this.validateForm(errors)}>
                {submitText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default GoalModal
