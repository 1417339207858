import React, { ChangeEvent } from 'react'
import Modal from '../../components/layout/modal'
import ProfilePicture from '../../components/layout/profilePicture'
import Button from '../../components/button'
import CheckBox from '../../components/checkbox'
import GQLClient from '../../helpers/gqlClient'
import {
  CreateMessageThreadQuery,
  GetMessageThreadQuery,
  GetAdvisorMessageThreadQuery
} from '../../helpers/queries'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { ContactState } from '../../../reducers/contacts'
import { HouseholdsState } from '../../../reducers/households'
import { HouseholdObj } from '../../../objects/household'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import plusBlueIcon from '../../assets/images/icons/png/ic_plus_blue.png'
import closeBlueIcon from '../../assets/images/icons/png/close-blue.png'
import {
  OfficeTeamMemberObj,
  OfficeTeamsInterface
} from '../../../objects/officeTeams'

export interface NewAdvisorMessageModalProps {
  households: HouseholdsState
  allContacts: ContactState
  officeTeams: OfficeTeamsInterface
  userId: string
  closeModal(): void
  updateMessageThread?(threadId: string, householdFindId: string): void
}

interface NewAdvisorMessageModalState {
  subject: string
  message: string
  to: string
  recipientsQueue: string[]
  recipients: string[]
  showRecipientsDropdown: boolean
}

export class NewAdvisorMessageModal extends React.Component<
  NewAdvisorMessageModalProps,
  NewAdvisorMessageModalState
> {
  constructor(props: NewAdvisorMessageModalProps) {
    super(props)
    this.state = {
      subject: '',
      message: '',
      to: '',
      recipientsQueue: [],
      recipients: [],
      showRecipientsDropdown: false
    }
  }

  public createThreads = async () => {
    const { userId, closeModal } = this.props
    const { message, subject, recipients } = this.state
    if (recipients.length) {
      await Promise.all(
        recipients.map((householdFinId: string, index: number) => {
          // if not last household id in array, create message only
          if (index + 1 !== recipients.length) {
            GQLClient.getClient()?.mutate({
              mutation: CreateMessageThreadQuery,
              variables: { message, householdFinId, subject, userId }
            })
            // if last household id in array, create message, refetch messageThread queries, and update active thread
          } else {
            GQLClient.getClient()
              ?.mutate({
                mutation: CreateMessageThreadQuery,
                variables: { message, householdFinId, subject, userId },
                refetchQueries: [
                  {
                    query: GetMessageThreadQuery,
                    variables: { householdFinId }
                  },
                  {
                    query: GetAdvisorMessageThreadQuery,
                    variables: { userId }
                  }
                ]
              })
              .then((res: any) => {
                const { updateMessageThread } = this.props
                updateMessageThread &&
                  updateMessageThread(
                    res.data.createMessage.message.id,
                    householdFinId
                  )
              })
          }
        })
      ).then(() => closeModal())
    }
  }

  public setSubject = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ subject: e.currentTarget.value })
  }
  public setTo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ to: e.currentTarget.value })
  }
  public setMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: e.currentTarget.value })
  }
  public openRecipientsDropdown = () => {
    this.setState({ showRecipientsDropdown: true })
  }
  public cancelRecipientsDropdown = () => {
    this.setState({
      showRecipientsDropdown: false,
      recipientsQueue: [...this.state.recipients],
      to: ''
    })
  }

  public removeRecipient = (householdId: string) => {
    const recipients = [...this.state.recipients]
    const index = recipients.indexOf(householdId)
    recipients.splice(index, 1)
    this.setState({ recipients, recipientsQueue: recipients })
  }

  public addRecipients = () => {
    this.setState({
      recipients: this.state.recipientsQueue,
      showRecipientsDropdown: false,
      to: ''
    })
  }

  public toggleRecipient = (householdId: string, check: boolean) => {
    const recipientsQueue = [...this.state.recipientsQueue]
    // if check is true, add recipient to queue
    if (check) {
      recipientsQueue.push(householdId)
    } else {
      // else remove recipient from queue
      const index = recipientsQueue.indexOf(householdId)
      recipientsQueue.splice(index, 1)
    }
    this.setState({ recipientsQueue })
  }

  // function that checks if to input matches any chars in household names and returns true
  public isToInputMatching = () => {
    const { to } = this.state
    const { households } = this.props
    if (to.length && households) {
      return Object.values(households).some((household: HouseholdObj) => {
        return household.name.toLowerCase().includes(to.toLowerCase())
      })
    } else return false
  }

  public renderRecipientsLabel = () => {
    const { recipients } = this.state
    const { households } = this.props
    return recipients.map((householdId: string) => {
      return (
        <div
          key={householdId}
          className='new-advisor-message-modal__recipients-label-w'>
          <p className='new-advisor-message-modal__recipient-label'>
            {households[householdId].name}
          </p>
          <img
            src={closeBlueIcon}
            onClick={() => this.removeRecipient(householdId)}
            className='new-advisor-message-modal__remove-icon'
            alt='remove icon'
          />
        </div>
      )
    })
  }

  public renderRecipientsDropdown = () => {
    const { households, allContacts, officeTeams, userId } = this.props
    const { recipientsQueue, to } = this.state

    // Grab Office Teams belonging to Advisor
    const userOfficeTeamsArray: string[] = []
    Object.keys(officeTeams).forEach((key) => {
      const officeTeam = officeTeams[key]
      const isOfficeTeamMember = officeTeam.members.find(
        (member: OfficeTeamMemberObj) => member.userId === userId
      )
      if (isOfficeTeamMember) userOfficeTeamsArray.push(officeTeam.id)
    })

    // filter households based on advisor officeTeams
    let householdsOnAdvisorTeam = Object.values(
      households
    ).filter((household) => userOfficeTeamsArray.includes(household.officeTeam))

    // sort households in ascending order
    householdsOnAdvisorTeam.sort((a: HouseholdObj, b: HouseholdObj) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    )
    // filter households down based on to input
    if (to) {
      householdsOnAdvisorTeam = householdsOnAdvisorTeam.filter(
        (household: HouseholdObj) =>
          household.name.toLowerCase().includes(to.toLowerCase())
      )
    }
    return (
      <div className='new-advisor-message-modal__recipients-dropdown-w'>
        <div className='new-advisor-message-modal__recipients-dropdown-list'>
          {householdsOnAdvisorTeam.map((household: HouseholdObj) => {
            const contact = allContacts[household.id]
            const { primary, secondary } = contact
            return (
              <div
                key={household.id}
                className='new-advisor-message-modal__recipients-dropdown-item'>
                <CheckBox
                  id={household.id}
                  style={{ width: 'auto' }}
                  checkedBorder={true}
                  checked={recipientsQueue.includes(household.id)}
                  onChange={this.toggleRecipient}
                />
                <div className='new-advisor-message-modal__recipients-dropdown-profile-w'>
                  <ProfilePicture
                    firstName={primary?.firstName}
                    lastName={primary?.lastName}
                    photo={primary?.photo}
                    isPrimary={true}
                    pictureSize={36}
                  />
                  {secondary && (
                    <ProfilePicture
                      firstName={secondary?.firstName}
                      lastName={secondary?.lastName}
                      photo={secondary?.photo}
                      isPrimary={false}
                      pictureSize={36}
                    />
                  )}
                </div>
                <div className='new-advisor-message-modal__household-name'>
                  {household.name}
                </div>
              </div>
            )
          })}
        </div>
        <div className='new-advisor-message-modal__recipients-dropdown-btn-w'>
          <div>
            <Button clear onClick={this.cancelRecipientsDropdown}>
              Cancel
            </Button>
            <Button primary onClick={this.addRecipients}>
              Done
            </Button>
          </div>
        </div>
      </div>
    )
  }

  public adjustToInputSize = () => {
    const { to } = this.state
    const toInput = document.getElementById('toInput')
    if (to.length < 1) {
      toInput.style.width = '100px'
    } else if (toInput?.style) {
      toInput.style.width = to.length + 5 + 'ch'
    }
  }

  public componentDidUpdate(
    prevProps: NewAdvisorMessageModalProps,
    prevState: NewAdvisorMessageModalState
  ) {
    if (this.state.to !== prevState.to) {
      this.adjustToInputSize()
    }
  }

  public render() {
    const { closeModal } = this.props
    const {
      subject,
      message,
      to,
      recipients,
      showRecipientsDropdown
    } = this.state
    return (
      <Modal
        title='NEW MESSAGE'
        icon={PlusIcon}
        closeModal={closeModal}
        size='L'>
        <div className='new-advisor-message-modal__header-w'>
          <div className='new-advisor-message-modal__subject-w'>
            <div className='new-advisor-message-modal__label'>Subject:</div>
            <input
              className='new-advisor-message-modal__subject-input'
              placeholder='Enter the subject of the message'
              value={subject}
              onChange={this.setSubject}
            />
          </div>
          <div className='new-advisor-message-modal__to-w'>
            <div className='new-advisor-message-modal__label'>To:</div>
            <div className='new-advisor-message-modal__to-input-w'>
              {recipients.length ? this.renderRecipientsLabel() : null}
              <input
                id='toInput'
                className='new-advisor-message-modal__to-input'
                value={to}
                onChange={this.setTo}
              />
            </div>
            <div className='new-advisor-message-modal__add-plus-btn-w'>
              <img
                className='new-advisor-message-modal__add-plus-btn'
                src={plusBlueIcon}
                alt='blue plus icon'
                onClick={this.openRecipientsDropdown}
              />
            </div>
          </div>
        </div>
        <div className='new-advisor-message-modal__content-w'>
          {(this.isToInputMatching() || showRecipientsDropdown) &&
            this.renderRecipientsDropdown()}
          <div className='new-advisor-message-modal__message-label'>
            Message
          </div>
          <textarea
            className='new-advisor-message-modal__message-text-area'
            placeholder='Add a message to start the thread'
            value={message}
            onChange={this.setMessage}></textarea>
          <div className='new-advisor-message-modal__disclaimer'>
            All interactions are subject to recordkeeping and monitoring. We are
            unable to accept any trading or transaction requests, nor do we
            provide any legal, tax or investment advice through this tool. Do
            not share Personal Identifiable Information (PII).
          </div>
          <div className='new-advisor-message-modal__btn-w'>
            <div>
              <Button clear onClick={closeModal}>
                Cancel
              </Button>
              <Button primary onClick={this.createThreads}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    households: store.households,
    allContacts: store.contact,
    officeTeams: store.officeTeams
  }
}

export default connect(mapStateToProps)(NewAdvisorMessageModal)
