import React from 'react'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

export interface ToastProps {
  id: string
  message: string
  icon: any
  backgroundColor?: string
  removeToast(id: string): void
}

class Toast extends React.Component<ToastProps> {
  public removeToast = () => {
    this.props.removeToast(this.props.id)
  }
  public render() {
    const { backgroundColor, message } = this.props
    const Icon = this.props.icon
    return (
      <div
        className='toast__overlay'
        style={{
          backgroundColor: backgroundColor || '#D9F2B6'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          {Icon && <Icon />}
          <span style={{ marginLeft: 8 }}>{message}</span>
        </div>
        <CloseIcon onClick={this.removeToast} style={{ cursor: 'pointer' }} />
      </div>
    )
  }
}

export default Toast
