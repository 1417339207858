export interface PortfolioAnalyticsModalProps {
  active?: boolean
}

export interface PortfolioAnalyticsStateKeys {
  [key: string]: any
}

export interface PortfolioAnalyticsFunc {
  addAccount?<T>(arg: T): T
  deleteAccount?<T>(arg: T): T
  toggleModal?<T>(stateKeys: T): void
  uploadPositions?(clientAccountId: string, data: any): void
  createAccount?(newClientAccount: any): void
}

export interface AssetAllocationGraphData {
  [graphType: string]: {
    cashAllocation?: number
    fixedAllocation?: number
    equityAllocation?: number
    otherAllocation?: number
  }
}

export interface PerformanceReturnGraphData {
  [performanceType: string]: {
    ytd?: number
    oneYear?: number
    threeYear?: number
    fiveYear?: number
  }
}

export const donutColors = {
  cashAllocation: { color: '#e4e6f5', displayName: 'Cash' },
  equityAllocation: { color: '#3b98e2', displayName: 'Public Equity' },
  fixedAllocation: { color: '#61b75e', displayName: 'Fixed Income' },
  alternativeAllocation: {
    color: '#f4a83e',
    displayName: 'Alternative Investments'
  },
  otherAllocation: { color: '#89579d', displayName: 'Other Investments' }
}
