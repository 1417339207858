import * as actions from '../actions/strategySearch'
import {
  StrategySearchState,
  custodiansFilterKeys
} from '../objects/strategySearch'
import { IMStrategiesObj, CollateralObj } from '../objects/strategies'

export const initState: StrategySearchState = {
  loading: false,
  search: '',
  filter: {
    manager: [],
    investmentMinimum: [],
    taxStatus: [],
    assetClassL2: [],
    managedStrategies: [],
    custodianSelection: [],
    esg: []
  },
  strategies: []
}

const mapStrategyCollaterals = (collaterals: CollateralObj[]) => {
  return (
    (collaterals &&
      collaterals.map((collateral: CollateralObj) => {
        const {
          id,
          strategyId,
          name,
          filename,
          url,
          collateralUrl,
          rankOrder
        } = collateral
        return {
          id,
          strategyId,
          name: name || undefined,
          filename,
          url,
          collateralUrl,
          rankOrder
        }
      })) ||
    null
  )
}

const mapStrategies = (responseArray: IMStrategiesObj[]): IMStrategiesObj[] => {
  const assignedCustodian = (strategyObj: IMStrategiesObj) => {
    return Object.keys(custodiansFilterKeys).filter((custodian) => {
      if (strategyObj[custodiansFilterKeys[custodian]]) {
        return custodiansFilterKeys[custodian]
      }
    })
  }

  return responseArray.map((strategyObj: IMStrategiesObj) => {
    const custodianArray = assignedCustodian(strategyObj)
    const collateralArray = mapStrategyCollaterals(strategyObj.collateral)
    /**
     * account type is saved as ';' seperated values which is not a valid filter type
     * filter requires an array
     */

    const taxStatus = strategyObj.taxStatus?.split(';')

    return {
      ...strategyObj,
      taxStatus,

      collateral: collateralArray,
      custodianSelection: custodianArray
    }
  })
}

const StrategySearch = (
  state = initState,
  action: any
): StrategySearchState => {
  const newState = JSON.parse(JSON.stringify(state))
  const { payload = {} } = action
  const { filterValues = [], filterType = [] } = payload
  switch (action.type) {
    case `${actions.GET_IM_STRATEGIES}_PENDING`:
      return { ...state, loading: true }
    case `${actions.GET_IM_STRATEGIES}_FULFILLED`:
      const responseArray = action.payload?.data || []
      const mappedStrategies = mapStrategies(responseArray)
      return {
        ...state,
        strategies: mappedStrategies,
        loading: false
      }
    case actions.UPDATE_IM_STRATEGY_FILTER:
      newState.filter[filterType] = filterValues
      if (filterType === 'managers') {
        newState.filter.manager = filterValues
      }
      if (filterType === 'investmentMinimum') {
        newState.filter.investmentMinimum = filterValues
      }
      if (filterType === 'taxStatus') {
        newState.filter.taxStatus = filterValues
      }
      if (filterType === 'custodianSelection') {
        newState.filter.custodianSelection = filterValues
      }
      if (filterType === 'esg') {
        // For Yes | No values set respective booleans to map the values with api response
        newState.filter.esg = filterValues.map((x: string|boolean) => (typeof x === 'string' ? x === 'Yes' : x ))
      }
      return { ...newState }
    case actions.DELETE_ALL_STRATEGY_FILTERS:
      newState.filter = { ...initState.filter }
      return { ...newState }
    case actions.UPDATE_SEARCH_TEXT:
      return { ...state, search: action.searchText }
    default:
      return state
  }
}

export default StrategySearch
