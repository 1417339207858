import React, { Component, Fragment, SyntheticEvent } from 'react'
import { Range } from 'rc-slider'

import { FilterFields } from './clientStrategies'
import Tile from '../../../components/layout/tile'
import Button from '../../../components/button'

import FilterIcon from '../../../assets/images/icons/ic_filter.svg'

interface StrategyFilterProps {
  filterFields: FilterFields
  resetFilter(): void
  isFilterFieldChecked(type: string): boolean
  onAllocationSlideChange(value: any): void
  onAllocationTextChange(
    type: string,
    pos: string
  ): (evt: SyntheticEvent) => void
  onSlideChange(field: string): (value: any) => void
  onTextRangeChange(
    property: string,
    subProperty: string
  ): (e: React.ChangeEvent<HTMLInputElement>) => void
  onCheckboxChange(type: string): () => void
  filterData(): void
  tooltipHeader(title: string, icon: any): JSX.Element
  onFilterPopupClose(): void
}

class StrategyFilter extends Component<StrategyFilterProps> {
  public filterAllocation = () => {
    const {
      min,
      max,
      defaultMin,
      defaultMax,
      leftEquity,
      leftFixed,
      rightEquity,
      rightFixed
    } = this.props.filterFields.allocation
    const { onAllocationSlideChange, onAllocationTextChange } = this.props
    return (
      <div className='client-strategies__filter-wrapper'>
        <div className='client-strategies__filter-heading'>Allocation</div>
        <div className='client-strategies__filter-slider'>
          <div className='rc-slider__w'>
            <Range
              key={min + max}
              min={defaultMin}
              max={defaultMax}
              defaultValue={[min, max]}
              allowCross={false}
              onAfterChange={onAllocationSlideChange}
            />
          </div>
          <div className='client-strategies__filter-allocation'>
            <div className='client-strategies__filter-allocation-wrapper'>
              <div>
                <input
                  type='text'
                  value={`${leftEquity} %`}
                  onChange={onAllocationTextChange('equity', 'left')}
                />
                EQUITY
              </div>
              <div className='divider' />
              <div>
                <input
                  type='text'
                  value={`${leftFixed} %`}
                  onChange={onAllocationTextChange('fixed', 'left')}
                />
                FIXED
              </div>
            </div>
            <div className='client-strategies__filter-allocation-wrapper'>
              <div>
                <input
                  type='text'
                  value={`${rightEquity} %`}
                  onChange={onAllocationTextChange('equity', 'right')}
                />
                EQUITY
              </div>
              <div className='divider' />
              <div>
                <input
                  type='text'
                  value={`${rightFixed} %`}
                  onChange={onAllocationTextChange('fixed', 'right')}
                />
                FIXED
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  public filterMinimum = () => {
    const { filterFields, onSlideChange, onTextRangeChange } = this.props
    return (
      <div className='client-strategies__filter-wrapper'>
        <div className='client-strategies__filter-heading'>Minimum</div>
        <div className='client-strategies__filter-slider'>
          <div className='rc-slider__w'>
            <Range
              key={`${filterFields.minimum.min + filterFields.minimum.max}`}
              allowCross={false}
              min={filterFields.minimum.defaultMin}
              max={filterFields.minimum.defaultMax}
              defaultValue={[
                filterFields.minimum.min,
                filterFields.minimum.max
              ]}
              onAfterChange={onSlideChange('minimum')}
            />
          </div>
        </div>
        <div className='client-strategies__filter-minimum'>
          <input
            type='text'
            value={`$ ${Number(filterFields.minimum.min).toLocaleString()}`}
            onChange={onTextRangeChange('minimum', 'min')}
          />
          <input
            type='text'
            value={`$ ${Number(filterFields.minimum.max).toLocaleString()}`}
            onChange={onTextRangeChange('minimum', 'max')}
          />
        </div>
      </div>
    )
  }

  public filterYield = () => {
    const { filterFields, onSlideChange, onTextRangeChange } = this.props
    return (
      <div className='client-strategies__filter-wrapper'>
        <div className='client-strategies__filter-heading'>Yield</div>
        <div className='client-strategies__filter-slider'>
          <div className='rc-slider__w'>
            <Range
              key={`${filterFields.yield.min + filterFields.yield.max}`}
              allowCross={false}
              step={0.01}
              min={filterFields.yield.defaultMin}
              max={filterFields.yield.defaultMax}
              defaultValue={[filterFields.yield.min, filterFields.yield.max]}
              onAfterChange={onSlideChange('yield')}
            />
          </div>
        </div>
        <div className='client-strategies__filter-yield'>
          <input
            type='text'
            value={`${filterFields.yield.min} %`}
            onChange={onTextRangeChange('yield', 'min')}
          />
          <input
            type='text'
            value={`${filterFields.yield.max} %`}
            onChange={onTextRangeChange('yield', 'max')}
          />
        </div>
      </div>
    )
  }

  public filterFee = () => {
    const { filterFields, onSlideChange, onTextRangeChange } = this.props
    return (
      <div className='client-strategies__filter-wrapper'>
        <div className='client-strategies__filter-heading'>Fee</div>
        <div className='client-strategies__filter-slider'>
          <div className='rc-slider__w'>
            <Range
              key={`${filterFields.fees.min + filterFields.fees.max}`}
              allowCross={false}
              step={0.01}
              min={filterFields.fees.defaultMin}
              max={filterFields.fees.defaultMax}
              defaultValue={[filterFields.fees.min, filterFields.fees.max]}
              onAfterChange={onSlideChange('fees')}
            />
          </div>
        </div>
        <div className='client-strategies__filter-yield'>
          <input
            type='text'
            value={`${(filterFields.fees.min / 100).toFixed(2)} %`}
            onChange={onTextRangeChange('fees', 'min')}
          />
          <input
            type='text'
            value={`${(filterFields.fees.max / 100).toFixed(2)} %`}
            onChange={onTextRangeChange('fees', 'max')}
          />
        </div>
      </div>
    )
  }

  public filterPreference = () => {
    const { filterFields, onCheckboxChange } = this.props
    const checkStyle = { height: 13 }
    return (
      <Fragment>
        <div className='client-strategies__filter-heading'>Preference</div>
        <div className='client-strategies__filter-check-wrapper'>
          <div className='client-strategies__filter-check-wrapper-row'>
            <span className='client-strategies__filter-check-wrapper-row-half'>
              <input
                style={checkStyle}
                id='domestic'
                type='checkbox'
                className='checkbox'
                aria-checked={filterFields.attributeTypes.domesticCheck}
                checked={filterFields.attributeTypes.domesticCheck}
                onChange={onCheckboxChange('domesticCheck')}
              />
              <label htmlFor='Domestic'>Domestic</label>
            </span>
          </div>
          <div className='client-strategies__filter-check-wrapper-row'>
            <span className='client-strategies__filter-check-wrapper-row-half'>
              <input
                style={checkStyle}
                id='global'
                type='checkbox'
                className='checkbox'
                aria-checked={filterFields.attributeTypes.globalCheck}
                checked={filterFields.attributeTypes.globalCheck}
                onChange={onCheckboxChange('globalCheck')}
              />
              <label htmlFor='Global'>Global</label>
            </span>
          </div>
        </div>
      </Fragment>
    )
  }

  public rightHeader = () => {
    return (
      <a className='client-strategies__reset' onClick={this.props.resetFilter}>
        Reset
      </a>
    )
  }

  public render() {
    const { filterData, tooltipHeader, onFilterPopupClose } = this.props
    return (
      <Tile
        headerBorder={true}
        style={{ border: '0px' }}
        leftHeader={tooltipHeader('FILTER', FilterIcon)}
        rightHeader={this.rightHeader()}>
        <div className='client-strategies__filter'>
          {this.filterAllocation()}
          {this.filterMinimum()}
          {this.filterYield()}
          {this.filterFee()}
          {this.filterPreference()}
          <div className='client-strategies__sort-btns'>
            <Button onClick={onFilterPopupClose}>Cancel</Button>
            <Button primary={true} onClick={filterData}>
              Save
            </Button>
          </div>
        </div>
      </Tile>
    )
  }
}

export default StrategyFilter
