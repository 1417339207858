import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'

export const FETCH_FOLDERS = 'FETCH_FOLDERS'
export const FETCH_FOLDERS_PENDING = 'FETCH_FOLDERS_PENDING'
export const FETCH_FOLDERS_REJECTED = 'FETCH_FOLDERS_REJECTED'
export const FETCH_FOLDERS_FULFILLED = 'FETCH_FOLDERS_FULFILLED'
export const FETCH_FOLDER = 'FETCH_FOLDER'
export const FETCH_FOLDER_PENDING = 'FETCH_FOLDER_PENDING'
export const FETCH_FOLDER_REJECTED = 'FETCH_FOLDER_REJECTED'
export const FETCH_FOLDER_FULFILLED = 'FETCH_FOLDER_FULFILLED'
export const CREATE_FOLDER = 'CREATE_FOLDER'
export const CREATE_FOLDER_PENDING = 'CREATE_FOLDER_PENDING'
export const CREATE_FOLDER_REJECTED = 'CREATE_FOLDER_REJECTED'
export const CREATE_FOLDER_FULFILLED = 'CREATE_FOLDER_FULFILLED'
export const EDIT_FOLDER = 'EDIT_FOLDER'
export const EDIT_FOLDER_PENDING = 'EDIT_FOLDER_PENDING'
export const EDIT_FOLDER_REJECTED = 'EDIT_FOLDER_REJECTED'
export const EDIT_FOLDER_FULFILLED = 'EDIT_FOLDER_FULFILLED'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const DELETE_FOLDER_PENDING = 'DELETE_FOLDER_PENDING'
export const DELETE_FOLDER_REJECTED = 'DELETE_FOLDER_REJECTED'
export const DELETE_FOLDER_FULFILLED = 'DELETE_FOLDER_FULFILLED'
export const HARD_DELETE = 'HARD_DELETE'
export const HARD_DELETE_PENDING = 'HARD_DELETE_PENDING'
export const HARD_DELETE_REJECTED = 'HARD_DELETE_REJECTED'
export const HARD_DELETE_FULFILLED = 'HARD_DELETE_FULFILLED'
export const FETCH_FILES = 'FETCH_FILES'
export const FETCH_FILES_PENDING = 'FETCH_FILES_PENDING'
export const FETCH_FILES_REJECTED = 'FETCH_FILES_REJECTED'
export const FETCH_FILES_FULFILLED = 'FETCH_FILES_FULFILLED'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING'
export const UPLOAD_FILE_REJECTED = 'UPLOAD_FILE_REJECTED'
export const UPLOAD_FILE_FULFILLED = 'UPLOAD_FILE_FULFILLED'
export const EDIT_FILE = 'EDIT_FILE'
export const EDIT_FILE_PENDING = 'EDIT_FILE_PENDING'
export const EDIT_FILE_REJECTED = 'EDIT_FILE_REJECTED'
export const EDIT_FILE_FULFILLED = 'EDIT_FILE_FULFILLED'
export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_FILE_PENDING = 'DELETE_FILE_PENDING'
export const DELETE_FILE_REJECTED = 'DELETE_FILE_REJECTED'
export const DELETE_FILE_FULFILLED = 'DELETE_FILE_FULFILLED'
export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER'
export const CLEAR_NEW_FOLDERS = 'CLEAR_NEW_FOLDERS'
export const EMPTY_TRASH = 'EMPTY_TRASH'
export const EMPTY_TRASH_PENDING = 'EMPTY_TRASH_PENDING'
export const EMPTY_TRASH_REJECTED = 'EMPTY_TRASH_REJECTED'
export const EMPTY_TRASH_FULFILLED = 'EMPTY_TRASH_FULFILLED'

export const getFolders = (householdFinId: string) => {
  return {
    type: FETCH_FOLDERS,
    payload: api()
      .get(`/households/${householdFinId}/pdv/folders`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const getFolder = (householdFinId: string, folderId: string) => {
  return {
    type: FETCH_FOLDER,
    payload: api()
      .get(`/households/${householdFinId}/pdv/folders/${folderId}`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const getFiles = (
  householdFinId: string,
  folderId: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: FETCH_FILES,
    payload: api()
      .get(`/households/${householdFinId}/pdv/folders/${folderId}/files`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        response.data.folderId = folderId
        return response
      })
  }
}

export const deleteFolder = (
  householdFinId: string,
  folderId: string,
  archived: boolean
) => {
  return {
    type: DELETE_FOLDER,
    payload: api()
      .put(`/households/${householdFinId}/pdv/folders/${folderId}`, {
        id: folderId,
        archived
      })
      .then((response) => {
        response.data = {}
        response.data.folderId = folderId
        response.data.householdFinId = householdFinId
        return response
      })
      .catch((err) => {
        throw err
      })
  }
}

export const updateArchivedFile = (
  householdFinId: string,
  folderId: string,
  fileId: string,
  archived: boolean
) => {
  return {
    type: DELETE_FILE,
    payload: api()
      .put(
        `/households/${householdFinId}/pdv/folders/${folderId}/files/${fileId}`,
        { fileId, archived }
      )
      .then((response) => {
        response.data = {}
        response.data.fileId = fileId
        response.data.folderId = folderId
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createFolder = (folderName: string, householdFinId: string) => {
  const postRequest = {
    name: folderName
  }
  return {
    type: CREATE_FOLDER,
    payload: api()
      .post(`/households/${householdFinId}/pdv/folders`, postRequest)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const editFolder = (
  householdFinId: string,
  folderId: string,
  name: string
) => {
  return {
    type: EDIT_FOLDER,
    payload: api()
      .put(`/households/${householdFinId}/pdv/folders/${folderId}`, {
        id: folderId,
        name
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const hardDeleteFile = (
  householdFinId: string,
  folderId: string,
  fileId: string
) => {
  return {
    type: HARD_DELETE,
    payload: api()
      .delete(
        `/households/${householdFinId}/pdv/folders/${folderId}/files/${fileId}`
      )
      .then((response) => {
        return response
      })
  }
}

export const uploadFile = (
  householdFinId: string,
  folderId: string,
  fileName: string,
  fileContent: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  const body = {
    name: fileName,
    content: fileContent
  }
  // Axios config is in bytes, Salesforce API limits to 36MB
  const uploadMaxBytes = 36 * 1024 * 1024
  return {
    type: UPLOAD_FILE,
    payload: api()
      .post(
        `/households/${householdFinId}/pdv/folders/${folderId}/files`,
        body,
        { maxContentLength: uploadMaxBytes }
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        response.data.folderId = folderId
        return response
      })
  }
}

export const editFile = (
  householdFinId: string,
  folderId: string,
  id: string,
  name: string
) => {
  return {
    type: EDIT_FILE,
    payload: api()
      .put(
        `/households/${householdFinId}/pdv/folders/${folderId}/files/${id}`,
        { id, name }
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const changeFileFolder = (
  householdFinId: string,
  folderId: string,
  id: string,
  folder: string
) => {
  return {
    type: EDIT_FILE,
    payload: api()
      .put(
        `/households/${householdFinId}/pdv/folders/${folderId}/files/${id}`,
        { id, folder }
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const emptyTrash = (householdFinId: string) => {
  return {
    type: EMPTY_TRASH,
    payload: api()
      .delete(`/households/${householdFinId}/pdv/emptytrash`)
      .then((response) => {
        return response
      })
  }
}

export const addNewFolder = (folderId: string) => {
  return {
    type: ADD_NEW_FOLDER,
    payload: folderId
  }
}

export const clearNewFolders = () => {
  return {
    type: CLEAR_NEW_FOLDERS
  }
}
