import React, {
  Component,
  RefObject,
  Fragment,
  createRef,
  CSSProperties
} from 'react'
import { Line } from 'react-chartjs-2'

// const priorityGraphId: string = 'hc-priority-graph'

interface PriorityGraphProps {
  graphDataSet: {}
  graphDataOptions: {}
  graphId?: string
  graphStyles?: CSSProperties
}

interface PriorityGraphState {
  data: {}
  options: {}
  chartBase64: string
}

class PriorityGraph extends Component<PriorityGraphProps, PriorityGraphState> {
  public priorityGraphRef: RefObject<HTMLCanvasElement> = createRef()

  constructor(props: PriorityGraphProps) {
    super(props)
    this.state = {
      data: props.graphDataSet,
      options: props.graphDataOptions,
      chartBase64: null
    }
  }

  public componentDidMount() {
    this.getBase64()
  }

  public getBase64 = () => {
    const graphtInstance: string = 'chartInstance'
    const thisWindow: any = window as any
    thisWindow.devicePixelRatio = 2
    if (this.priorityGraphRef.current && !this.state.chartBase64) {
      const canvas: HTMLCanvasElement = this.priorityGraphRef.current[
        graphtInstance
      ].canvas as HTMLCanvasElement
      this.setState({ chartBase64: canvas.toDataURL('image/png', 1) })
    }
  }

  public render() {
    const { graphDataSet, graphDataOptions, graphStyles } = this.props
    return (
      <Fragment>
        <div
          style={
            graphStyles || {
              width: '100%',
              paddingBottom: '20px',
              height: '300px'
            }
          }
          className='pdf-exclude'>
          <Line
            ref={this.priorityGraphRef}
            id={this.props.graphId}
            data={graphDataSet}
            options={graphDataOptions}
          />
        </div>
        <img
          src={this.state.chartBase64}
          role='presentation'
          alt=''
          className='pdf-only'
        />
      </Fragment>
    )
  }
}

export default PriorityGraph
