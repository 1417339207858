import api from '../v3/helpers/api'

export const GET_STRATEGIES = 'GET_STRATEGIES'
export const GET_STRATEGIES_PENDING = 'GET_STRATEGIES_PENDING'
export const GET_STRATEGIES_FULFILLED = 'GET_STRATEGIES_FULFILLED'
export const GET_STRATEGIES_REJECTED = 'GET_STRATEGIES_REJECTED'
export const ADD_STRATEGIES = 'ADD_STRATEGIES'
export const REMOVE_STRATEGIES = 'REMOVE_STRATEGIES'

export const addStrategy = (
  id: number,
  name: string,
  value: number,
  type: string
) => ({
  type: ADD_STRATEGIES,
  payload: {
    id,
    name,
    value,
    type
  }
})

export const removeStrategy = (id: number) => ({
  type: REMOVE_STRATEGIES,
  id
})

export const getStrategies = (householdFinId: string) => {
  return {
    householdFinId,
    type: GET_STRATEGIES,
    payload: api()
      .get(`/households/${householdFinId}/strategies`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}
