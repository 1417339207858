import React, { Component } from 'react'
import InnerContainer from '../../components/layout/innerContainer'
import Tile from '../../components/layout/tile'
import TableHeader from '../../components/layout/tableHeader'
import BackToLink from '../../components/layout/headerBackToLink'
import HonestConversationsHistoryRow from './hcHistoryRow'

import { HonestConversationsExerciseInterface } from '../../../objects/honestConversations'
import { HouseholdObj } from '../../../objects/household'

import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import * as hcExerciseActions from '../../../actions/hcExercises'
import Button from '../../components/button'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { history } from '../../../store'
import { isObjEmpty } from '../../helpers'

interface HonestConversationsHistoryProps {
  dispatch?: Dispatch<GlobalState>
  match: any
  householdFinId: string
  hcExercises: HonestConversationsExerciseInterface
  household: HouseholdObj
}

class HonestConversationsHistory extends Component<
  HonestConversationsHistoryProps
> {
  public componentDidMount() {
    if (isObjEmpty(this.props.hcExercises)) {
      this.props.dispatch(
        hcExerciseActions.getHonestConversationExercises(
          this.props.householdFinId
        )
      )
    }
  }

  public previousMeetingList = () => {
    const { hcExercises, household, householdFinId } = this.props
    if (hcExercises) {
      const sortedHCExercises = Object.keys(hcExercises)
        .filter((key) => key !== 'new')
        .map((e) => hcExercises[e])
        .sort((a: any, b: any) => {
          a = new Date(a.createdDate)
          b = new Date(b.createdDate)
          return b - a
        })
      return sortedHCExercises.splice(0, 50).map((exercise, index) => {
        const active = household.hcExerciseId === exercise.id
        return (
          <HonestConversationsHistoryRow
            key={index}
            householdFinId={householdFinId}
            active={active}
            honestConversation={exercise}
          />
        )
      })
    } else return null
  }
  public addMeeting = () => {
    const { householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/meetings/new`
    )
  }
  public render() {
    const { householdFinId } = this.props
    return (
      <InnerContainer>
        <div className='hc-meeting__container-w'>
          <BackToLink to={`/households/${householdFinId}/honestConversations`}>
            Back to HonestConversations<sup key='reg'>&reg;</sup>
          </BackToLink>
          <div className='hc-meeting__quick-action-w'>
            <Button primary={true} onClick={this.addMeeting}>
              <PlusIcon /> New record
            </Button>
          </div>
        </div>
        <Tile>
          <TableHeader>
            <span className='hc-history__results-active' />
            <span className='hc-history__results-results'>COMBINED CARDS</span>
            <span className='hc-history__results-updated'>CREATED DATE</span>
            <span className='hc-history__results-actions' />
          </TableHeader>
          <div className='hc-history-w'>{this.previousMeetingList()}</div>
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    hcExercises:
      store.hcExercises[match.params.householdFinId] &&
      store.hcExercises[match.params.householdFinId],
    householdFinId: match.params.householdFinId,
    household: store.households[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(HonestConversationsHistory))
