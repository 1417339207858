import React, { Component } from 'react'
import FinancialCharts from '../../../../components/graphs/financialCharts'
import { dotOvalList } from '../../dotOval'

interface CreditWeightingsGraphProps {
  creditWeightings: any
  graphColors: string[]
  chartOptions: any
}

class CreditWeightingsGraph extends Component<CreditWeightingsGraphProps> {
  // tslint:disable-next-line:max-func-body-length
  public render() {
    const { chartOptions, graphColors } = this.props
    const { graphData } = this.props.creditWeightings
    const filterKeys = (arr: any, query: string) => {
      return arr.filter(
        (key: string) =>
          key.includes(query) && key !== 'NR/NA' && key != 'Below B'
      )
    }
    const aKeys = filterKeys(Object.keys(graphData), 'A')
      .sort()
      .reverse()
    const bKeys = filterKeys(Object.keys(graphData), 'B')
      .sort()
      .reverse()
    const otherKeys = Object.keys(graphData)
      .filter((value) => value === 'Below B' || value === 'NR/NA')
      .sort()
    const sortedGraphData = aKeys.concat(bKeys).concat(otherKeys)
    const graphValues = sortedGraphData.map((percent: any, key: number) => {
      return {
        value: graphData[percent],
        color: graphColors[key]
      }
    })

    !graphValues.length &&
      graphValues.push({
        value: 100,
        color: 'grey'
      })

    const dataValues = graphValues.map((item: any) => {
      return parseFloat(item.value)
    })

    const dataColors = graphValues.map((item: any) => {
      return item.color
    })

    const columnOne = dotOvalList(graphColors, graphData)
    const columnTwo =
      columnOne.length > 7 ? columnOne.splice(6, columnOne.length) : null

    return (
      <div className='proposed-portfolio__graph-wrapper'>
        <div className='proposed-portfolio__graph'>
          <div className='proposed-portfolio__graph-relative'>
            <FinancialCharts
              canvasId='proposed-credit-weighting-graph'
              chartData={{
                datasets: [
                  {
                    data: dataValues,
                    backgroundColor: dataColors
                  }
                ]
              }}
              chartOptions={chartOptions}
              dimensions={{ width: 110, height: 110 }}
            />

            <div className='proposed-portfolio__graph-target' />
          </div>
          <div className='proposed-portfolio__graph-data-point'>
            <div className='proposed-portfolio__graph-data-point-flex'>
              <div className='proposed-portfolio__graph-data-point-flex__column'>
                {columnOne}
              </div>
              <div>{columnTwo}</div>
              <div />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CreditWeightingsGraph
