import React from 'react'
import Modal from '../../../components/layout/modal'
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'
import { ReactComponent as NoresultIcon } from '../../../assets/images/icons/noresults.svg'
import Search from '../../../components/search'
import { UsersObj, UserObj } from '../../../../objects/user'
import { OfficeTeamObj } from '../../../../objects/officeTeams'
import ProfilePicture from '../../../components/layout/profilePicture'

interface AddToTeamModalProps {
  users: UsersObj
  team: OfficeTeamObj
  saveModal(id: string, addIds: string[], deleteIds: string[]): void
  closeModal(): void
}

interface AddToTeamModalState {
  searched: string
  memberIds: string[]
}

class AddToTeamModal extends React.Component<
  AddToTeamModalProps,
  AddToTeamModalState
> {
  constructor(props: AddToTeamModalProps) {
    super(props)
    const ids = this.props.team.members.map((member) => {
      return member.userId
    })
    this.state = {
      searched: '',
      memberIds: ids
    }
  }

  public handleFilter = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({
      searched: event.currentTarget.value
    })
  }

  public onUserChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const userId = event.currentTarget.id
    const { memberIds } = this.state
    const index = memberIds.indexOf(userId)
    if (index !== -1) {
      memberIds.splice(index, 1)
    }
    if (index === -1) {
      memberIds.push(userId)
    }
    this.setState({ memberIds })
  }

  public updateTeamMembers = () => {
    const currentIds = this.props.team.members.map((member) => {
      return member.userId
    })
    const { memberIds } = this.state
    const deleteIds = currentIds.filter(
      (id: string) => memberIds.indexOf(id) === -1
    )
    const addIds = memberIds.filter(
      (id: string) => currentIds.indexOf(id) === -1
    )
    this.props.saveModal(this.props.team.id, addIds, deleteIds)
  }

  public usersList = (users: UsersObj, memberIds: string[]) => {
    const { searched } = this.state
    let filteredUsers: UserObj[] = users
      ? Object.keys(users).map((key) => users[key])
      : []
    if (filteredUsers) {
      if (searched && searched.trim()) {
        filteredUsers = filteredUsers.filter(
          (user: UserObj) =>
            (user.firstName &&
              user.firstName
                .toLowerCase()
                .indexOf(this.state.searched.toLowerCase()) !== -1) ||
            (user.lastName &&
              user.lastName
                .toLowerCase()
                .indexOf(this.state.searched.toLowerCase()) !== -1)
        )
      } else {
        filteredUsers = filteredUsers.filter(
          (user: UserObj) => user.firstName || user.lastName
        )
      }
    }
    if (filteredUsers.length > 0) {
      return filteredUsers.map((user: UserObj, index: number) => {
        const isMember = memberIds.indexOf(user.id) !== -1
        if (user) {
          return (
            <div
              key={user.id}
              className='admin-settings__row  admin-settings__row--col  add-team__modal-body-table-row'
              style={{ padding: '16px 0' }}>
              <div className='admin-settings__team-split'>
                <input
                  type='checkbox'
                  id={user.id}
                  aria-checked={isMember}
                  checked={isMember}
                  className='add-team__modal-body__checkbox'
                  onChange={this.onUserChange}
                />
                <ProfilePicture
                  firstName={user.firstName}
                  lastName={user.lastName}
                  photo={user.photo}
                  isPrimary={true}
                  pictureSize={32}
                />
                <span>
                  <span>
                    <h5 className='admin-settings__team-name'>
                      {user.firstName ? user.firstName + ' ' : ''}
                      {user.lastName ? user.lastName : ''}
                    </h5>
                    <div className='admin-settings__team-email'>
                      {user.email}
                    </div>
                  </span>
                </span>
              </div>
              <div className='admin-settings__team-split'>
                <span className='admin-settings__team-title'>{user.title}</span>
              </div>
            </div>
          )
        } else return null
      })
    } else {
      return (
        <div className='team-management__modal-noresult add-team__modal-body-noresult'>
          <NoresultIcon />
          <p className='team-management__modal-noresult-text team-management__modal-noresult-text--blue'>
            There are no users with this name
          </p>
          <p className='team-management__modal-noresult-text--gray'>
            Try searching for just the parts of the user's name and don’t forget
            to spellcheck.
          </p>
        </div>
      )
    }
  }

  public render() {
    const { closeModal } = this.props
    return (
      <Modal icon={PlusIcon} title='ADD TO TEAM' closeModal={closeModal}>
        <div className='add-team__modal-body'>
          <div className='add-team__modal-body-search'>
            <Search onChange={this.handleFilter} value={this.state.searched} />
          </div>
          <div className='admin-settings__row  admin-settings__row--col admin-settings__row--col-h'>
            <span className='add-team__modal-body-table-name'>NAME</span>
            <span className='add-team__modal-body-table-title'>TITLE</span>
          </div>
          <div className='add-team__modal-body-row'>
            {this.usersList(this.props.users, this.state.memberIds)}
          </div>
        </div>
        <div className='add-team__modal-body-btns-w'>
          <span className='btn btn__clear' onClick={closeModal}>
            Cancel
          </span>
          <span className='btn btn__prime' onClick={this.updateTeamMembers}>
            Update
          </span>
        </div>
      </Modal>
    )
  }
}

export default AddToTeamModal
