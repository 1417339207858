import React, { FC } from 'react'
import { history } from '../../../store'
import Button from '../../components/button'
import NullHeader from '../../assets/images/honestConversations/disabled-header.png'

interface DisabledModalProps {
  householdFinId: string
}

export const DisabledView: FC<DisabledModalProps> = ({ householdFinId }) => {
  return (
    <div className='disabled-view__w'>
      <img src={NullHeader} role='presentation' alt='header' />
      <div className='disabled-view__text-container'>
        <h2 className='disabled-view__text-header'>
          Honest Conversations is disabled.
        </h2>
        <p className='disabled-view__text-body'>
          If you have permission from your supervisor, click below to change
          client settings
        </p>
        <Button
          style={{ background: '#F2F2F2', color: '#255EBA' }}
          onClick={() =>
            history.push(`/households/${householdFinId}/clientSettings`, {
              fromDisabledLink: true
            })
          }>
          Enable Honest Conversations
        </Button>
      </div>
    </div>
  )
}

export default DisabledView
