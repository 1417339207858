import { isObjEmpty } from '../helpers'
import {
  PortfolioBuilderInterface,
  PortfolioBuilderAccountInterface
} from '../../objects/portfolioBuilder'

export const portfolioBuilderTitle = (
  portfolioBuilderList: PortfolioBuilderInterface,
  portfolioBuilderId: string
) => {
  let title = 'Untitled Document'
  if (!isObjEmpty(portfolioBuilderList)) {
    Object.keys(portfolioBuilderList).forEach((key) => {
      const portfolioBuilderItem = portfolioBuilderList[key]
      if (portfolioBuilderItem.id === portfolioBuilderId) {
        title = portfolioBuilderItem.name
      }
    })
  }
  return title
}

export const portfolioBuilderProposedAllocationMap = (
  portfolioBuilderAccount: PortfolioBuilderAccountInterface
) => {
  const proposedAllocation = {
    totaleProposedAllocation: 0,
    equity: {
      value: 0,
      percentage: 0
    },
    fixed: {
      value: 0,
      percentage: 0
    },
    cash: {
      value: 0,
      percentage: 0
    },
    other: {
      value: 0,
      percentage: 0
    },
    unclassified: {
      value: 0,
      percentage: 0
    }
  }
  Object.keys(portfolioBuilderAccount).map((key) => {
    const account: any = portfolioBuilderAccount[key]

    // totaleProposedAllocation Value
    if (account.accountValue) {
      proposedAllocation.totaleProposedAllocation += account.accountValue
    }

    // Individual Allocation Value
    proposedAllocation.equity.value += account.currentEquityValue
      ? account.currentEquityValue
      : 0
    proposedAllocation.fixed.value += account.currentFixedValue
      ? account.currentFixedValue
      : 0
    proposedAllocation.cash.value += account.currentCashValue
      ? account.currentCashValue
      : 0
    proposedAllocation.other.value += account.currentOtherValue
      ? account.currentOtherValue
      : 0
    proposedAllocation.unclassified.value += account.currentUnclassifiedValue
      ? account.currentUnclassifiedValue
      : 0
  })

  proposedAllocation.equity.percentage =
    Math.round(
      (proposedAllocation.equity.value /
        proposedAllocation.totaleProposedAllocation) *
        100
    ) || 0
  proposedAllocation.fixed.percentage =
    Math.round(
      (proposedAllocation.fixed.value /
        proposedAllocation.totaleProposedAllocation) *
        100
    ) || 0
  proposedAllocation.cash.percentage =
    Math.round(
      (proposedAllocation.cash.value /
        proposedAllocation.totaleProposedAllocation) *
        100
    ) || 0
  proposedAllocation.other.percentage =
    Math.round(
      (proposedAllocation.other.value /
        proposedAllocation.totaleProposedAllocation) *
        100
    ) || 0
  proposedAllocation.unclassified.percentage =
    Math.round(
      (proposedAllocation.unclassified.value /
        proposedAllocation.totaleProposedAllocation) *
        100
    ) || 0

  return proposedAllocation
}

export const generateData = (currentAllocationData: any = {}) => {
  let { portfolioValue = '' } = currentAllocationData
  const {
    overrideEquityPercent,
    overrideCashPercent,
    overrideFixedPercent,
    overrideOtherPercent,
    overrideTotalPercent,
    currentAccounts = {}
  } = currentAllocationData

  Object.keys(currentAccounts)
    .filter((key: string) => {
      return currentAccounts[key].type === 'Current'
    })
    .forEach((key: string) => {
      currentAllocationData.currentAccounts[key] = currentAccounts[key]
    })
  currentAllocationData.portfolioValue = Object.keys(
    currentAllocationData.currentAccounts
  ).reduce((previous: number, key: string) => {
    return previous + currentAllocationData.currentAccounts[key].accountValue
  }, 0)
  portfolioValue = currentAllocationData.portfolioValue

  currentAllocationData.yield = Object.keys(
    currentAllocationData.currentAccounts
  ).reduce((previous: number, key: string) => {
    return (
      previous +
      currentAllocationData.currentAccounts[key].yield *
        (currentAllocationData.currentAccounts[key].accountValue /
          portfolioValue)
    )
  }, 0)
  currentAllocationData.yield =
    Math.round(currentAllocationData.yield * 100) / 100

  currentAllocationData.expenseRatio = Object.keys(
    currentAllocationData.currentAccounts
  ).reduce((previous: number, key: string) => {
    return (
      previous +
      currentAllocationData.currentAccounts[key].expenseRatio *
        (currentAllocationData.currentAccounts[key].accountValue /
          portfolioValue)
    )
  }, 0)
  currentAllocationData.expenseRatio =
    Math.round(currentAllocationData.expenseRatio * 100) / 100

  currentAllocationData.fees = Object.keys(
    currentAllocationData.currentAccounts
  ).reduce((previous: number, key: string) => {
    return (
      previous +
      currentAllocationData.currentAccounts[key].strategyFee *
        (currentAllocationData.currentAccounts[key].accountValue /
          portfolioValue)
    )
  }, 0)
  currentAllocationData.fees =
    Math.round(currentAllocationData.fees * 100) / 100

  if (overrideEquityPercent || overrideEquityPercent === 0) {
    currentAllocationData.equity = overrideEquityPercent
  } else if (currentAllocationData.currentAccounts) {
    const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
      (sum: number, key: string) => {
        return (
          (sum || 0) +
          currentAllocationData.currentAccounts[key].currentEquityValue
        )
      },
      0
    )
    currentAllocationData.equity =
      parseFloat(((temp / portfolioValue) * 100).toFixed(2)) || 0
  }

  if (overrideCashPercent || overrideCashPercent === 0) {
    currentAllocationData.cash = overrideCashPercent
  } else if (currentAllocationData.currentAccounts) {
    const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
      (sum: number, key: string) => {
        return sum + currentAllocationData.currentAccounts[key].currentCashValue
      },
      0
    )
    currentAllocationData.cash =
      parseFloat(((temp / portfolioValue) * 100).toFixed(2)) || 0
  }

  if (overrideOtherPercent || overrideOtherPercent === 0) {
    currentAllocationData.otherValue = overrideOtherPercent
  } else if (currentAllocationData.currentAccounts) {
    const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
      (sum: number, key: string) => {
        return (
          sum + currentAllocationData.currentAccounts[key].currentOtherValue
        )
      },
      0
    )
    currentAllocationData.otherValue = temp
      ? parseFloat(((temp / portfolioValue) * 100).toFixed(2))
      : 0
  }

  if (overrideTotalPercent || overrideTotalPercent === 0) {
    currentAllocationData.Unclassified = overrideTotalPercent
  } else if (currentAllocationData.currentAccounts) {
    const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
      (sum: number, key: string) => {
        return (
          sum +
          currentAllocationData.currentAccounts[key].currentUnclassifiedValue
        )
      },
      0
    )
    currentAllocationData.Unclassified = temp
      ? parseFloat(((temp / portfolioValue) * 100).toFixed(2))
      : 0
  }

  if (overrideFixedPercent || overrideFixedPercent === 0) {
    currentAllocationData.fixed = overrideFixedPercent
  } else if (currentAllocationData.currentAccounts) {
    const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
      (sum: number, key: string) => {
        return (
          sum + currentAllocationData.currentAccounts[key].currentFixedValue
        )
      },
      0
    )
    currentAllocationData.fixed = temp
      ? parseFloat(((temp / portfolioValue) * 100).toFixed(2))
      : 0
  }
  return currentAllocationData
}
