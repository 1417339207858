import React, { Component } from 'react'
import InnerContainer from '../../components/layout/innerContainer'
import Tile from '../../components/layout/tile'
import BackToLink from '../../components/layout/headerBackToLink'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  HonestConversationsExerciseInterface,
  HonestConversationsExerciseObj
} from '../../../objects/honestConversations'
import { ContactsInterface } from '../../../objects/contact'
import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'
import { HouseholdObj } from '../../../objects/household'
import RecordForm from './recordForm'
import * as hcExerciseActions from '../../../actions/hcExercises'
import { history } from '../../../store'
import { getGoalActions } from '../../../actions/financialGoalActions'
import { getContacts } from '../../../actions/contacts'
import {
  getScoringSession,
  isPrimaryScoringSession
} from '../../helpers/honestConversations'
import goalActionsSelector from '../../../selectors/v3/goals'

interface EditScoreProps {
  location: any
  dispatch?: Dispatch<GlobalState>
  match: any
  householdFinId: string
  scoreId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  household: HouseholdObj
  goals: FinancialGoalActionObj[]
}

class EditScore extends Component<EditScoreProps> {
  public componentDidMount() {
    const { dispatch, householdFinId } = this.props
    dispatch(getContacts())
    dispatch(hcExerciseActions.getHonestConversationExercises(householdFinId))
    dispatch(getGoalActions(householdFinId))
  }

  public goBack = () => {
    history.goBack()
  }

  public render() {
    const {
      honestConversation,
      contacts,
      householdFinId,
      household,
      goals,
      scoreId
    } = this.props

    const isPrimary = isPrimaryScoringSession(
      honestConversation,
      contacts,
      scoreId
    )

    return (
      <InnerContainer>
        <BackToLink onClick={this.goBack}>Back</BackToLink>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          rightHeader='EDIT SCORES'
          headerBorder={true}>
          {honestConversation && honestConversation.householdCards ? (
            <RecordForm
              edit={true}
              goals={goals}
              household={household}
              honestConversation={honestConversation}
              contacts={contacts}
              householdFinId={householdFinId}
              isPrimary={isPrimary}
              scoringSession={getScoringSession(honestConversation, scoreId)}
              scoringSessionId={scoreId}
            />
          ) : null}
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  const household = store.households[householdFinId]
  const honestConversations: HonestConversationsExerciseInterface =
    store.hcExercises[householdFinId]
  const honestConversation: HonestConversationsExerciseObj = honestConversations
    ? honestConversations[household.hcExerciseId]
    : null
  return {
    householdFinId,
    goals: goalActionsSelector(store, householdFinId),
    household,
    contacts: store.contact[householdFinId],
    honestConversation,
    scoreId: match.params.scoreId
  }
}

export default connect(mapStateToProps)(EditScore)
