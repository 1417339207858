import {
  HouseholdObj,
  HouseholdPartialObj,
  HouseholdPartialApiObject,
  HouseholdInstitution
} from '../objects/household'
import { LoginHistoryObj } from '../objects/loginHistory'
import * as actions from '../actions/households'
import * as hcActions from '../actions/honestConversationsV1'
import * as contactActions from '../actions/contacts'
import * as paActions from '../actions/portfolioAnalytics'
import { OfficeTeamObj, OfficeTeamMemberObj } from '../objects/officeTeams'
import { UserObj } from '../objects/user'
import {
  CREATE_EXERCISE_WITH_CARDS_AND_SCORES,
  CREATE_HONEST_CONVERSATION_EXERCISE
} from '../actions/hcExercises'

export interface HouseholdsState {
  [key: string]: HouseholdObj
}

interface ExtendedUserObj extends UserObj {
  address?: {
    street: string
    city: string
    state: string
    postalCode: string
  }
  postalCode?: string
}

interface OtherSourcesHousehold {
  ownerId: string
  source: string
  fieldName: string
  fieldValue: any
}

interface Institution extends HouseholdInstitution {
  photoUrl: string
}

const initState: HouseholdsState = {}

const getValueFromSource = (
  sources: OtherSourcesHousehold[],
  source: string,
  fieldName: string
) => {
  const foundSource =
    sources &&
    sources.find((it) => it.source === source && it.fieldName === fieldName)
  return foundSource && foundSource.fieldValue
}

const mapHouseholdPartial = (
  household: HouseholdPartialApiObject
): HouseholdPartialObj => ({
  id: household.id,
  name: household.name,
  primaryContact: household.primaryContact,
  institutionId: household.institutionId,
  secondaryContact: household.secondaryContact,
  latestFundedScore: household?.fundedScores?.[0]?.score || null,
  ivProgress: household.viewfinderProgress?.toLowerCase() ?? 'not started',
  invresponseId: household.invresponseId,
  palProgress: household.palProgress?.toLowerCase() ?? 'not started',
  // We don't need to set this to not started because we want this to be null if the version is wrong.
  // The BE already checks version to set it to null vs not started
  goalsProgress: household?.goalsProgress?.toLowerCase(),
  netWorth: household.netWorth,
  hcProgress: household.hcProgress?.toLowerCase() ?? 'not started',
  hcProgress2: household.hcProgress2?.toLowerCase() ?? 'not started',
  mmProgress: household.mmProgress?.toLowerCase() ?? 'not started',
  taskCount: household.taskCount || 0,
  crmId: household?.crmId,
  cxId: household?.cxId,
  hasClientAccountErrors: household?.hasClientAccountErrors,
  aggregationProvider: household?.aggregationProvider,
  disableManualAccount: household?.disableManualAccount,
  primaryMoneymind: household?.primaryMoneymind,
  secondaryMoneymind: household?.secondaryMoneymind,
  officeTeam: household?.officeTeam,
  officeTeams: household?.officeTeams
})

const mapHouseholdsPartial = (
  households: HouseholdPartialApiObject[],
  householdsState: Readonly<HouseholdsState>
): HouseholdsState => {
  if (households) {
    const obj = {}
    households.forEach((household: any) => {
      if (householdsState && householdsState[household.id]) {
        obj[household.id] = {
          ...householdsState[household.id],
          ...mapHouseholdPartial({
            ...householdsState[household.id],
            ...household
          })
        }
      } else {
        obj[household.id] = mapHouseholdPartial(household)
      }
    })

    return obj
  } else return {}
}

const mapHousehold = (household: any): HouseholdObj => {
  return {
    id: household.id,
    name: household.name ? household.name : '',
    guidecenterOptions: household.guidecenterOptions
      ? household.guidecenterOptions
      : null,
    onlinePortals: household.onlinePortal
      ? household.onlinePortal.split(';')
      : null,
    planningSoftware: household.planningSoftware,
    primaryContact: household.primaryContact,
    secondaryContact: household.secondaryContact,
    institutionId: household.institutionId,
    hcExerciseId: household.hcExerciseId,
    hcMeetingId: household.hcMeetingId,
    latestFundedScore:
      household.fundedScores &&
      household.fundedScores[0] &&
      household.fundedScores[0].score,
    invresponseId: household.invresponseId,
    ivProgress: household?.viewfinderProgress?.toLowerCase(),
    palProgress: household?.palProgress?.toLowerCase(),
    goalsProgress: household?.goalsProgress?.toLowerCase(),
    netWorth: household.netWorth,
    hcProgress: household?.hcProgress?.toLowerCase(),
    hcProgress2: household?.hcProgress2?.toLowerCase(),
    mmProgress: household?.mmProgress?.toLowerCase(),
    guidebookTargetAllocation: household.guidebookTargetAllocation,
    targetCashAllocation: household.targetCashAllocation,
    targetEquityAllocation: household.targetEquityAllocation,
    targetFixedAllocation: household.targetFixedAllocation,
    targetOtherAllocation: household.targetOtherAllocation,
    targetUnclassifiedAllocation: household.targetUnclassifiedAllocation,
    officeTeam: household.officeTeam,
    taskCount: !household.taskCount === false ? household.taskCount : 0,
    planningSoftwareId: household.planningSoftwareId,
    benchmarkId: household.benchmarkId,
    cxId: household.cxId,
    emoneyId: getValueFromSource(household.otherSources, 'emoney', 'id'),
    crmId: household?.crmId,
    institution: household?.institution,
    hasClientAccountErrors: household?.hasClientAccountErrors,
    aggregationProvider: household?.aggregationProvider,
    disableManualAccount: household?.disableManualAccount,
    showHonestConversations: household?.showHonestConversations,
    showInvestments: household?.showInvestments,
    showEmoneyClientPortal: household?.showEmoneyClientPortal,
    primaryMoneymind: household?.primaryMoneymind,
    secondaryMoneymind: household?.secondaryMoneymind,
    officeTeams: household?.officeTeams,
    showAllStrategies: household?.showAllStrategies
  }
}

const mapLastLogin = (
  lastLogins: LoginHistoryObj[],
  householdsState: HouseholdsState
): HouseholdsState => {
  if (lastLogins?.length) {
    lastLogins.forEach((login) => {
      householdsState[login.householdId].lastLogin = login.loginDate
    })
  }
  return householdsState
}

const updateHouseholdInstitution = (
  householdObj: HouseholdObj,
  householdInstitution: Institution
): HouseholdObj => {
  householdInstitution.imageUrl = householdInstitution.photoUrl
  const updatedInstitution: Institution = {
    ...householdObj.institution,
    ...householdInstitution
  }

  return { ...householdObj, institution: updatedInstitution }
}

const mapOfficeTeamMembers = (members: OfficeTeamMemberObj[]) => {
  const teamMembers: OfficeTeamMemberObj[] = []
  members.forEach((member) => {
    const { user } = member
    const teamMember = { ...member }
    const extendedUser: ExtendedUserObj = user
    if (extendedUser) {
      const updatedTeamMember: ExtendedUserObj = {
        ...user,
        ...extendedUser.address,
        zip: extendedUser.address && extendedUser.address.postalCode
      }
      delete updatedTeamMember.address
      delete updatedTeamMember.postalCode
      teamMember.user = updatedTeamMember
      teamMembers.push(teamMember)
    }
  })
  return teamMembers
}
/* eslint-disable complexity */
const Households = (state = initState, action: any): HouseholdsState => {
  const obj = {}
  let households
  let household
  const data = action?.payload?.data

  switch (action.type) {
    case `${paActions.FETCH_HOUSEHOLD_BENCHMARKS}_FULFILLED`:
      return {
        ...state,
        [action.payload.householdId]: {
          ...state[action.payload.householdId],
          benchmarks: data
        }
      }
    case `${hcActions.CREATE_AND_SET_ACTIVE_HONEST_CONVERSATION_MEETING}_FULFILLED`:
      return {
        ...state,
        [data.householdId]: { ...state[data.householdId], hcMeetingId: data.id }
      }
    case `${actions.FETCH_HOUSEHOLDS}_FULFILLED`:
      households = mapHouseholdsPartial(action.payload.data, state)
      return { ...state, ...households }
    case `${actions.FETCH_INDIVIDUAL_HOUSEHOLD}_FULFILLED`: {
      const { institution, ...householdData } = action.payload.data
      const { id } = householdData

      const updatedHouseholdInstitution = updateHouseholdInstitution(
        state[id] || action.payload.data,
        institution
      )

      const updatedHousehold = mapHousehold(householdData)

      return {
        ...state,
        ...{
          [data.id]: { ...updatedHousehold, ...updatedHouseholdInstitution }
        }
      }
    }
    case `${actions.UPDATE_EMONEY_FIELD}_FULFILLED`:
      const { householdFinId, fieldValue, fieldName } = action.payload.data
      const householdData = state[householdFinId]
      if (fieldName === 'id') {
        householdData.emoneyId = fieldValue
      }
      return {
        ...state,
        ...{
          [householdFinId]: { ...householdData }
        }
      }
    case `${actions.FETCH_HOUSEHOLD_INSTITUTION}_FULFILLED`:
      if (data) {
        household = {
          ...state[data.householdFinId],
          ...mapHousehold(
            updateHouseholdInstitution(state[data.householdFinId], data)
          )
        }

        return { ...state, ...{ [household.id]: household } }
      }
      return state
    case `${actions.FETCH_HOUSEHOLD_OFFICE_TEAM}_FULFILLED`:
      const { teams } = action.payload.data
      teams.forEach((team: OfficeTeamObj, index: number) => {
        team.members = mapOfficeTeamMembers(team.members)
      })
      return {
        ...state,
        [action.payload.data.householdFinId]: {
          ...state[action.payload.data.householdFinId],
          officeTeams: teams
        }
      }
    case contactActions.CONTACTS_LAST_LOGIN_FULFILLED:
      households = mapLastLogin(
        action.payload,
        JSON.parse(JSON.stringify(state))
      )
      return { ...state, ...households }
    case `${actions.SET_ACTIVE_HONEST_CONVERSATION_MEETING}_FULFILLED`:
    case `${actions.SET_ACTIVE_INVESTMENT_PRIORITY}_FULFILLED`:
    case `${actions.SET_ACTIVE_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
    case `${actions.SET_TARGET_ALLOCATION}_FULFILLED`:
      obj[action.payload.data.id] = {
        ...state[action.payload.data.id],
        ...mapHousehold({ ...state[data.id], ...action.payload.data })
      }
      return { ...state, ...obj }
    case `${actions.SET_ACTIVE_OFFICE_TEAM}_FULFILLED`:
      return {
        ...state,
        [action.payload.data.id]: {
          ...state[action.payload.data.id],
          ...mapHousehold({ ...state[data.id], ...action.payload.data })
        }
      }
    case `${actions.SET_HOUSEHOLD_INFORMATION}_FULFILLED`:
    case `${actions.SET_AGGREGATION_PROVIDER}_FULFILLED`:
    case `${actions.SET_PLANNING_SOFTWARE}_FULFILLED`:
    case `${actions.SET_BENCHMARK}_FULFILLED`:
    case `${actions.TOGGLE_OPTIONAL_FEATURE}_FULFILLED`:
      // Merge the existing household with the new data coming in.
      // We have to merge the data before mapping and during mapping
      // because certain fields get set inside the mapping and certain
      // fields get set outside the mapping
      obj[action.payload.data.id] = {
        ...state[action.payload.data.id],
        ...mapHousehold({
          ...state[action.payload.data.id],
          ...action.payload.data
        })
      }
      return { ...state, ...obj }
    case `${CREATE_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
    case `${CREATE_EXERCISE_WITH_CARDS_AND_SCORES}_FULFILLED`:
      obj[action.payload.data.householdId] = {
        ...state[action.payload.data.householdId],
        hcExerciseId: action.payload.data.id
      }
      return { ...state, ...obj }
    default:
      return state
  }
}

export default Households
