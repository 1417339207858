import React from 'react'
import moment from 'moment'
import { dateFormat, timeFormat } from '../../helpers'
import { HouseholdObj } from '../../../objects/household'

export interface MessageThreadListItemProps {
  subject: string
  date: string
  householdId: string
  dashMessage?: boolean
  household: HouseholdObj
  threadId: string
  sentBy?: string
  activeThreadId?: string
  onClick(threadId: string, householdFinId?: string): void
}

export class MessageThreadListItem extends React.Component<
  MessageThreadListItemProps
> {
  public onClick = () => {
    const { onClick, threadId, householdId } = this.props
    onClick(threadId, householdId)
  }
  public render() {
    const {
      subject,
      date,
      household,
      dashMessage,
      sentBy,
      threadId,
      activeThreadId
    } = this.props
    const today = moment()
    const activeThreadCssClass =
      threadId === activeThreadId ? 'message-thread-list-item--selected' : ''
    const threadDate = today.isSame(date, 'day')
      ? timeFormat(date)
      : dateFormat(date)
    if (dashMessage) {
      return (
        <div
          className='message-thread-list-item-w message-thread-list-item-w--dashboard'
          onClick={this.onClick}>
          <h5 className='message-thread-list-item__subject message-thread-list-item__subject--dashboard'>
            {subject}
          </h5>
          <div className='message-thread-list-item__row'>
            <div className='message-thread-list-item__household message-thread-list-item__household--dashboard'>
              Sent by{' '}
              <span className='message-thread-list-item__household message-thread-list-item__household--emphesis message-thread-list-item__household--dashboard'>
                {sentBy}
              </span>
            </div>
            <p className='message-thread-list-item__dot'> • </p>
            <div className='message-thread-list-item__date message-thread-list-item__date--dashboard'>
              {threadDate}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`message-thread-list-item-w ${activeThreadCssClass}`}
          style={{ paddingRight: 5 }}
          onClick={this.onClick}>
          <div className='message-thread-list-item__row message-thread-list-item__row--multItem'>
            <h5 className='message-thread-list-item__subject'>{subject}</h5>
            <div
              className='message-thread-list-item__date'
              style={{ marginTop: 3 }}>
              {threadDate}
            </div>
          </div>
          <div className='message-thread-list-item__row'>
            <div className='message-thread-list-item__household'>
              <span className='message-thread-list-item__household message-thread-list-item__household'>
                {household?.name}
              </span>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default MessageThreadListItem
