import React from 'react'
import { ContactObj } from '../../../../objects/contact'
import { OfficeTeamMemberObj } from '../../../../objects/officeTeams'

import { AssigneePhotos } from './assigneePhotos'

// custom option look, photo(+count) + name
export const AssigneeLabel: React.FC<{
  id: string
  name: string
  members: OfficeTeamMemberObj[]
  contacts: ContactObj[]
  assigneeType: 'primary' | 'secondary' | 'joint' | 'user'
  isLast: boolean
}> = (props) => {
  const { members, contacts, id, isLast, name } = props

  const photoCount = members ? members.length : contacts.length

  return (
    <div
      key={id}
      className='assign-team__modal__body__row assign-team__modal__body__row--col'
      style={{
        height: '51px',
        padding: '0px 0px 0px 10px',
        borderBottom: `${isLast ? 'none' : 'solid 1px #c2c2c2'}`
      }}>
      <AssigneePhotos members={members} contacts={contacts} />
      <div style={{ marginLeft: `${photoCount}px` }}>{name}</div>
    </div>
  )
}
