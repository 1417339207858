import React, { Component } from 'react'
import { GlobalState } from '../../../../../reducers'
import { connect, Dispatch } from 'react-redux'

import { getIndividualHousehold } from '../../../../../actions/households'
import { getHouseholdBenchmarks } from '../../../../../actions/portfolioAnalytics'
import { HouseholdObj } from '../../../../../objects/household'
import { BenchmarkObj } from '../../../../../objects/institution'
import { PortfolioBuilderInterface } from '../../../../../objects/portfolioBuilder'
import { isObjEmpty } from '../../../../helpers'
import {
  generatePortfolioBuilderData,
  portfolioProposalsBenchmarks
} from '../../../../helpers/positions'

import {
  getPortfolioBuilders,
  getPortfolioBuilderAccounts
} from '../../../../../actions/portfolioBuilder'

export interface BenchmarksProps {
  householdFinId: string
  selectedId: string
  selectedOption: string
  household: HouseholdObj
  portfolioBuilder: PortfolioBuilderInterface
  selectionIdSetter(e: string): void
  dispatch: Dispatch<GlobalState>
}

interface BenchmarksState {
  benchmarkType: string
  proposals: any[]
}

export class Benchmarks extends Component<BenchmarksProps, BenchmarksState> {
  constructor(props: BenchmarksProps) {
    super(props)
    const { selectedOption } = props
    this.state = {
      benchmarkType: selectedOption || 'isg',
      proposals: null
    }
  }

  public componentDidMount = async () => {
    await this.setPortfolioBuilderData()
  }

  public setPortfolioBuilderData = async () => {
    const { household, householdFinId, dispatch } = this.props
    !household && (await dispatch(getIndividualHousehold(householdFinId)))
    await dispatch(getPortfolioBuilders(householdFinId))
    await this.getPbAccounts()
    await dispatch(getHouseholdBenchmarks(householdFinId, true))
  }

  public getPbAccounts = () => {
    const { portfolioBuilder, householdFinId, dispatch } = this.props
    !isObjEmpty(portfolioBuilder) &&
      Object.keys(portfolioBuilder).forEach((id: string) => {
        dispatch(getPortfolioBuilderAccounts(householdFinId, id))
      })
  }

  public toggleBenchMarks = async (e: string) => {
    if (e !== 'isg') {
      await this.setPortfolioBuilderData()
    }
    this.setState({
      benchmarkType: e,
      proposals: this.getComparisonBenchmark(e)
    })
  }

  public getProposals = () => {
    const { portfolioBuilder } = this.props
    const portfolioProposals = generatePortfolioBuilderData(portfolioBuilder)
    return portfolioProposalsBenchmarks(portfolioProposals)
  }

  public getComparisonBenchmark = (type: string) => {
    const {
      household: { benchmarks = [] }
    } = this.props

    // hard filter based on isgBenchmarks
    const comparisionBenchmark = {
      isg: benchmarks.filter(
        (benchmark: BenchmarkObj) =>
          benchmark.equityAllocation || benchmark.fixedAllocation
      ),
      proposed: this.getProposals()
    }

    return comparisionBenchmark[type]
  }

  public render = () => {
    return <div className='im-portfolio__main-w'></div>
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  const { households = {}, portfolioBuilder } = store
  return {
    householdFinId,
    portfolioBuilder:
      !isObjEmpty(portfolioBuilder) && portfolioBuilder[householdFinId],
    household: !isObjEmpty(households) && households[householdFinId]
  }
}

export default connect(mapStateToProps)(Benchmarks)
