import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../../../reducers'
import {
  Tile,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  DragDropContext,
  DraggableLocation,
  DropResult
} from 'react-beautiful-dnd'
import CombinedCardsTable from './combinedCardsTable'
import Instructions from './instructions'
import { ContactsInterface } from '../../../../../objects/contact'
import PageHeading from '../pageHeading'
import ArrowIcon from '../../../../assets/images/icons/png/arrow_right_blue.png'
import firstStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-1.gif'
import secondStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-2.gif'
import thirdStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-3.gif'
import fourthStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-4.gif'
import {
  HonestConversationsExerciseObj,
  ClientCardObj,
  HouseholdCardObj,
  ScoringSessionsObj
} from '../../../../../objects/honestConversations'
import {
  getTopFiveCards,
  sortHouseholdCards
} from '../../../../helpers/honestConversations'
import {
  createScoringSessionsAndSetAsActive,
  updateHonestConversationHouseholdCards,
  getHonestConversationExercise,
  updateClientCards
} from '../../../../../actions/hcExercises'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import { isEqual } from '../../../../helpers'
import goalActionsSelector from '../../../../../selectors/v3/goals'

const COMBINED_CARDS_TABLE_ID = 'CombinedCardsTable'

const instructions = [
  {
    gif: firstStepInstruction,
    text:
      'With your personal priorities defined, now let’s pick your shared priorities'
  },
  {
    gif: secondStepInstruction,
    text: 'We’ll score how well you’re doing'
  },
  {
    gif: thirdStepInstruction,
    text: 'And discuss goals you can set to improve'
  },
  {
    gif: fourthStepInstruction,
    text: 'We’ll repeat this for each priority'
  }
]

interface PickCombinedCardsSingleFlowProps {
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  hcExercise: HonestConversationsExerciseObj
  contacts: ContactsInterface
  goalActions: FinancialGoalActionObj[]
  back(): void
  next(): void
}

interface PickCombinedCardsSingleFlowState {
  createScoringSessionInitiated: boolean
  householdCards: HouseholdCardObj[]
  instructionsInProgress: boolean
  completedSelectingCards: boolean
  isDroppingDisabled: boolean
}

export class PickCombinedCardsSingleFlow extends Component<
  PickCombinedCardsSingleFlowProps,
  PickCombinedCardsSingleFlowState
> {
  constructor(props: PickCombinedCardsSingleFlowProps) {
    super(props)
    this.state = {
      createScoringSessionInitiated: false,
      householdCards: [],
      instructionsInProgress: true,
      completedSelectingCards: false,
      isDroppingDisabled: false
    }
  }

  public componentDidMount() {
    this.setStateCardArrays()
  }

  public async componentDidUpdate(prevProps: PickCombinedCardsSingleFlowProps) {
    const { hcExercise, contacts } = this.props
    const { createScoringSessionInitiated } = this.state
    if (
      hcExercise &&
      contacts &&
      (!prevProps.hcExercise ||
        !isEqual(
          prevProps.hcExercise.householdCards,
          this.props.hcExercise.householdCards
        ))
    ) {
      this.setStateCardArrays()
    }

    if (hcExercise && !createScoringSessionInitiated) {
      if (!hcExercise.activeScoringSessionPrimary && contacts?.primary?.id) {
        this.setCreateClientScoringSession(contacts?.primary?.id)
      }
    }
  }

  public setStateCardArrays = () => {
    const { hcExercise } = this.props
    if (!hcExercise) {
    } else {
      this.setState({
        householdCards: hcExercise
          ? sortHouseholdCards(hcExercise.householdCards)
          : [],
        completedSelectingCards:
          sortHouseholdCards(hcExercise.householdCards).length === 5
      })
    }
  }

  public setCreateClientScoringSession = (clientId: string) => {
    const { dispatch, householdFinId, hcExercise, contacts } = this.props
    const scoringSession: ScoringSessionsObj = {
      scoredWithAdvisor: true,
      clientId
    }

    clientId &&
      this.setState({ createScoringSessionInitiated: true }, () => {
        dispatch(
          createScoringSessionsAndSetAsActive(
            householdFinId,
            hcExercise.id,
            scoringSession,
            contacts?.primary?.id === clientId
          )
        )
      })
  }

  public onDragEnd = async (result: DropResult) => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    // Rank cards in combined table
    if (
      source.droppableId === COMBINED_CARDS_TABLE_ID &&
      destination.droppableId === COMBINED_CARDS_TABLE_ID
    ) {
      this.rankCombinedCardTable(source, destination)
    }
  }

  public rankCombinedCardTable = async (
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    await this.reOrder(
      this.state.householdCards,
      droppableSource.index,
      droppableDestination.index
    )
  }

  public reOrder = async (
    householdCards: HouseholdCardObj[],
    startIndex: number,
    endIndex: number
  ) => {
    const results = Array.from(householdCards)
    const [removed] = results.splice(startIndex, 1)
    results.splice(endIndex, 0, removed)
    this.setState({ householdCards: results })
    await this.updateHouseholdCards(results)
    // update primary client card to the same as household cards
    this.updatePrimaryCards()
  }

  public updateHouseholdCards = async (householdCards: HouseholdCardObj[]) => {
    const { dispatch, hcExercise, householdFinId } = this.props
    const updatedHouseholdCards: HouseholdCardObj[] = householdCards.map(
      (hcCard, index) => {
        return {
          id: hcCard.id,
          exerciseId: hcExercise.id,
          commentPrimary: hcCard.commentPrimary || null,
          commentSecondary: hcCard.commentSecondary || null,
          ranking: index + 1,
          cardId: hcCard.card.id,
          card: hcCard.card
        }
      }
    )
    await dispatch(
      updateHonestConversationHouseholdCards(
        householdFinId,
        hcExercise.id,
        updatedHouseholdCards
      )
    )
  }

  public updatePrimaryCards = () => {
    const { householdFinId, hcExercise, contacts, dispatch } = this.props
    const clientCards: ClientCardObj[] = getTopFiveCards(
      hcExercise.clientCards[contacts.primary.id]
    )
    const householdCards: HouseholdCardObj[] = sortHouseholdCards(
      hcExercise.householdCards
    )

    clientCards.forEach((clientCard) => {
      householdCards.forEach((householdCard) => {
        if (clientCard.cardId === householdCard.cardId) {
          clientCard.ranking = householdCard.ranking
        }
      })
    })
    dispatch(
      updateClientCards(
        householdFinId,
        hcExercise.id,
        contacts.primary.id,
        clientCards
      )
    )
  }

  public goToResults = async () => {
    const { dispatch, householdFinId, next, hcExercise } = this.props
    await dispatch(getHonestConversationExercise(householdFinId, hcExercise.id))
    next()
  }

  public tileHeader = () => {
    const { completedSelectingCards } = this.state
    const { back } = this.props
    return (
      <Fragment>
        <Button
          className='tile__btn-back'
          btnStyle={{
            backgroundColor: 'transparent',
            padding: 0,
            color: '#255eba'
          }}
          onClick={back}>
          <img
            className='transform__rotate-180'
            src={ArrowIcon}
            alt='arrow icon'
          />
          Back
        </Button>
        <Button
          type={completedSelectingCards ? null : 'disabled'}
          onClick={completedSelectingCards ? this.goToResults : null}>
          Next
        </Button>
      </Fragment>
    )
  }

  public toggleInstructionsInProgress = () => {
    this.setState({
      instructionsInProgress: false
    })
  }

  public render() {
    const { contacts, householdFinId, hcExercise, goalActions } = this.props
    const {
      householdCards,
      completedSelectingCards,
      instructionsInProgress,
      isDroppingDisabled
    } = this.state
    // check the actual count in the reducer
    const householdCardCount =
      hcExercise?.householdCards &&
      Object.keys(hcExercise.householdCards).length

    return (
      <Fragment>
        <PageHeading number='3' text='Plan' />
        <Tile header={this.tileHeader()} contentStyle={{ padding: 0 }}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className='combined-card__w'>
              <CombinedCardsTable
                id={COMBINED_CARDS_TABLE_ID}
                hcExercise={hcExercise}
                householdFinId={householdFinId}
                cards={householdCards}
                contacts={contacts}
                goalActions={goalActions}
                completedSelectingCards={
                  completedSelectingCards && householdCardCount === 5
                }
                instructionsInProgress={instructionsInProgress}
                isDroppingDisabled={isDroppingDisabled}
              />
            </div>
          </DragDropContext>
        </Tile>
        {instructionsInProgress && (
          <Instructions
            contacts={contacts}
            closeModal={this.toggleInstructionsInProgress}
            data={instructions}
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, exerciseId } = match.params
  return {
    householdFinId,
    contacts: store.contact[householdFinId],
    hcExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][exerciseId],
    goalActions: goalActionsSelector(store, householdFinId)
  }
}

export default withRouter(connect(mapStateToProps)(PickCombinedCardsSingleFlow))
