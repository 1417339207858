import React, { Component } from 'react'
import { timeFormat } from '../../helpers'
import Modal from '../../components/layout/modal'
import ProfilePicture from '../../components/layout/profilePicture'
import SpinnerLoader from '../../assets/images/Loader_Spinner.gif'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { VideoMessageData, messageMediaType } from '../../../objects/messages'
interface MessageBodyItemProps {
  householdFinId: string
  threadId: string
  messageId: string
  authorName: string
  message: string
  createdDate: string
  photo: string
  isPrimary?: boolean
  isVideoMessage: boolean
  isOldVideoMessage: boolean
  videoMessages: VideoMessageData
  getVideoUrl(): void
  getThumbnailUrl(): void
}

interface MessageBodyItemState {
  showVideoModal: boolean
}

class MessageBodyItem extends Component<
  MessageBodyItemProps,
  MessageBodyItemState
> {
  public constructor(props: MessageBodyItemProps) {
    super(props)
    this.state = {
      showVideoModal: false
    }
  }

  componentDidMount = () => {
    const { getThumbnailUrl } = this.props
    getThumbnailUrl && getThumbnailUrl()
  }

  public toggleVideoModal = (event?: any) => {
    event && event.preventDefault()
    const { getVideoUrl } = this.props
    // Set the modal state true
    this.setState({ showVideoModal: !this.state.showVideoModal }, () => {
      if (this.state.showVideoModal) {
        //Fetch the video url
        getVideoUrl && getVideoUrl()
      }
    })
    return false
  }

  public showLoader = (type: messageMediaType = 'thumbnail') => {
    return (
      <div className={`message-body__${type}-loader`}>
        <img src={SpinnerLoader} width='41' height='41' alt='loader' />
      </div>
    )
  }

  public videoMessageView = () => {
    const {
      householdFinId,
      message,
      isOldVideoMessage,
      threadId,
      messageId,
      videoMessages
    } = this.props
    // Pick the video url and thumbnailUrl from the videoMessages reducer state
    const {
      videoUrl = '',
      thumbnailUrl = '',
      showvideoLoader = false,
      showthumbnailLoader = false
    } = videoMessages?.[householdFinId]?.[threadId]?.[messageId] || {}

    // Extracting message from the messsage (old way)
    const oldMessageTxt = message?.substring(0, message?.indexOf('<br/>'))

    // Modal popup title based on old way or new way
    const modalTitle = isOldVideoMessage ? oldMessageTxt : message

    // Modal popup logic for the video based on old way or new way
    const videoModalContent = (videoTitle: string) => (
      <Modal title={videoTitle} closeModal={this.toggleVideoModal}>
        <div className='message-body__video-media'>
          {showvideoLoader ? (
            this.showLoader('video')
          ) : videoUrl ? (
            <video
              width='385'
              height='200'
              preload=''
              controlsList='nodownload'
              controls
              disablePictureInPicture
              src={videoUrl}
            />
          ) : null}
        </div>
      </Modal>
    )
    return (
      <>
        {/* Message body with video thumbnail  */}
        <div className='message-body__video' onClick={this.toggleVideoModal}>
          <div
            className='message-body__video-thumbnail'
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {!isOldVideoMessage ? (
            <>
              {showthumbnailLoader ? (
                this.showLoader()
              ) : thumbnailUrl ? (
                <div className='message-body__video-thumbnail-wrapper'>
                  <img
                    className='message-body__video-thumbnail'
                    src={thumbnailUrl}
                    alt='thumbnail'
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        {/* Show the video modal when state is true */}
        {this.state.showVideoModal && videoModalContent(modalTitle)}
      </>
    )
  }

  public render() {
    const {
      message,
      authorName,
      createdDate,
      photo,
      isPrimary,
      isVideoMessage,
      isOldVideoMessage
    } = this.props
    return (
      <div className='message-body-item-w'>
        <div>
          <ProfilePicture
            firstName={authorName ? authorName[0] : null}
            lastName={
              authorName ? authorName[authorName.lastIndexOf(' ') + 1] : null
            }
            photo={photo}
            isPrimary={isPrimary}
            pictureSize={36}
          />
        </div>
        <div className='message-body-container'>
          <div className='message-body-heading-w'>
            <div className='message-body-heading__name'>{authorName}</div>
            <div className='message-body-heading__dot'>•</div>
            <div className='message-body-heading__date'>
              {timeFormat(createdDate)}
            </div>
          </div>
          <div className='message-body__message'>
            {/* Show the video message view if the message is a video message */}
            {isVideoMessage || isOldVideoMessage
              ? this.videoMessageView()
              : message}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    videoMessages: store.messages?.videoMessages || {}
  }
}
const connector = connect(mapStateToProps)
export default connector(MessageBodyItem)
