import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'

export interface NavLinkProps {
  icon: string
  iconActive: string
  title: string
  to: string
  activeRoute: string
}

export class NavLink extends React.Component<NavLinkProps> {
  public render() {
    const { title, to, activeRoute, iconActive, icon } = this.props
    const isActive = activeRoute && activeRoute.includes(to)
    return (
      <Link
        className={
          isActive ? 'nav-menu__link nav-menu__link--active' : 'nav-menu__link'
        }
        to={to}>
        <img
          src={isActive ? iconActive : icon}
          className='nav-menu__link-icon'
          alt={icon}
        />
        <span className='nav-menu__title nav-menu__title--open'>{title}</span>
      </Link>
    )
  }
}

export default connect((store: GlobalState) => {
  return {
    activeRoute:
      store.routerReducer.location && store.routerReducer.location.pathname
  }
})(NavLink)
