import React from 'react'
import Select from 'react-select'
import { ValueType } from 'react-select/lib/types'
import { TaskStatusSelection, TaskObj } from '../../../../objects/tasks'

import {
  selectStyle,
  SelectOptionType,
  IndicatorSeparator,
  DropdownIndicator
} from './taskAssigneeSelect'

export const StatusSelect: React.FC<{
  headerTitle: string
  onChangeFunc(value: ValueType<SelectOptionType>): void
  selectedOption?: ValueType<SelectOptionType>
}> = (props) => {
  const { selectedOption, onChangeFunc } = props
  const defaultValue: ValueType<SelectOptionType> =
    selectedOption || TaskStatusSelection[0]

  return (
    <React.Fragment>
      <div className='task-add__label'>{props.headerTitle}</div>
      <Select
        isSearchable={false}
        styles={selectStyle()}
        components={{ IndicatorSeparator, DropdownIndicator }}
        onChange={onChangeFunc}
        options={TaskStatusSelection}
        placeholder={'Select status'}
        value={defaultValue}
      />
    </React.Fragment>
  )
}
