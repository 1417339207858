import React, { Component } from 'react'
import { ClientAccountPositionsObj } from '../../../../../../objects/clientAccount'
import { dollarFormat, isObjEmpty } from '../../../../../helpers'

export interface HoldingsProps {
  position?: ClientAccountPositionsObj
  index?: number
}

export class Holdings extends Component<HoldingsProps> {
  public nullDisplay = () => {
    const { index } = this.props

    const backgroundColor = {
      backgroundColor: `rgba(174, 182, 190, ${Math.round(100 / index + 1) *
        0.01})`
    }

    return (
      <div className='im-holding__item'>
        <span className='im-holding__label' style={backgroundColor} />
        <span
          className='im-holding__label-w im-holding__label-w--empty'
          style={backgroundColor}
        />
        <span className='im-holding__label-right' />
        <span
          className='im-holding__label-right im-holding__label-right--total'
          style={backgroundColor}
        />
      </div>
    )
  }

  public display = () => {
    const { position } = this.props

    const { tickerSymbol, securityDescription, quantity, value } = position
    const displayValue = value ? dollarFormat(value, 0) : null

    return (
      <div
        className={
          !tickerSymbol || !quantity || !value || !quantity
            ? 'im-holding__item im-holding__item--error'
            : 'im-holding__item'
        }>
        <span className='im-holding__label'>{tickerSymbol || null}</span>
        <span className='im-holding__label-w'>{securityDescription}</span>

        <span className='im-holding__label-right im-holding__label-right--tight'>
          {quantity || null}
        </span>
        <span className='im-holding__label-right'>{displayValue || null}</span>
      </div>
    )
  }

  public render = () => {
    const { position } = this.props
    return !isObjEmpty(position) ? this.display() : this.nullDisplay()
  }
}

export default Holdings
