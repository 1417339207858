import React, { Component } from 'react'
import { history } from '../../../../store'
import ContentHeader from '../../../components/layout/contentHeader'
import QuickActionLinks, {
  QuickActionObj
} from '../../../components/quickActionLinks'

import LaunchIcon from '../../../assets/images/icons/png/launch.png'
import ActivityIcon from '../../../assets/images/icons/png/activity.png'

import { ReactComponent as HonestConversationsIcon } from '../../../assets/images/icons/honestconversations.svg'
import { ReactComponent as MoneyMindIcon } from '../../../assets/images/icons/moneymind.svg'
import { GlobalState } from '../../../../reducers'
import { Dispatch, connect } from 'react-redux'

interface HcPageHeaderProps {
  dispatch: Dispatch<GlobalState>
  showHonestConversations: boolean
  householdFinId: string
  exerciseId: string
}

class HcPageHeader extends Component<HcPageHeaderProps> {
  public goLaunch = async () => {
    const { householdFinId } = this.props
    return history.push(
      `/households/${householdFinId}/honestConversations/v3/exercise/new`
    )
  }

  public navigateToHistory = () => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/honestConversations/meetings`)
  }

  public goGuidebookPreview = () => {
    //
  }

  public goPickCombined = () => {
    const { householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/v3/exercise/pick`
    )
  }

  public resume = () => {
    const { householdFinId, exerciseId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/v3/exercise/${exerciseId}`
    )
  }

  public render() {
    const { exerciseId, showHonestConversations } = this.props
    const headerActions: QuickActionObj[] = [
      {
        text: 'History',
        icon: ActivityIcon,
        onClick: this.navigateToHistory
      },
      {
        text: 'Launch',
        icon: LaunchIcon,
        onClick: this.goLaunch
      }
    ]
    if (exerciseId) {
      headerActions.push({
        text: 'Resume',
        icon: LaunchIcon,
        onClick: this.resume
      })
    }
    return (
      <div className='hc-meeting__container-w'>
        <ContentHeader
          Icon={
            showHonestConversations ? HonestConversationsIcon : MoneyMindIcon
          }
          title={
            showHonestConversations ? 'Your Priorities' : 'HonestConversations®'
          }
          rightHeader={null}
        />
        <div className='hc-meeting__quick-action-w'>
          <div className='hc-meeting__quick-actions'>
            {showHonestConversations && (
              <QuickActionLinks customQuickAction={headerActions} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(HcPageHeader)
