import React, { Fragment } from 'react'
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg'
import TableRow from '../../components/layout/tableRow'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { ReactComponent as RestoreIcon } from '../../assets/images/icons/refresh.svg'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import api from '../../../v3/helpers/api'
import ActionDropdown from '../../components/actionDropdown'
import moment from 'moment'
import { FileObj } from '../../../objects/folder'

export interface FileProps {
  file: FileObj
  userId: string
  householdFinId: string
  folderId?: string
  inTrash?: boolean
  dispatch: Dispatch<GlobalState>
  deleteModal?(id: string): void
  restoreModal?(file: FileObj): void
  editModal?(id: string, name: string): void
  hardDeleteModal?(file: FileObj): void
}

interface FileState {
  downloading: boolean
  percent: number
}

export class File extends React.Component<FileProps, FileState> {
  constructor(props: FileProps) {
    super(props)
    this.state = {
      downloading: false,
      percent: 0
    }
  }

  public editFileFn = () => {
    const { file, editModal } = this.props
    editModal(file.id, file.name)
  }
  public deleteFileFn = () => {
    const { file, deleteModal } = this.props
    deleteModal(file.id)
  }

  public hardDeleteFileFn = () => {
    const { file, hardDeleteModal } = this.props
    hardDeleteModal(file)
  }

  public restoreFileFn = () => {
    const { file, restoreModal } = this.props
    restoreModal(file)
  }
  public downloadFile = () => {
    const { householdFinId, folderId, file } = this.props

    return {
      payload: api()
        .get(
          `/households/${householdFinId}/pdv/v2/folders/${folderId}/files/${file.id}`,
          {
            onDownloadProgress: (progressEvent) => {
              const percentCompleted = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              if (percentCompleted < 100) {
                this.setState({
                  downloading: true,
                  percent: percentCompleted
                })
              } else {
                this.setState({
                  downloading: false,
                  percent: 0
                })
              }
            },
            timeout: 12000000,
            responseType: 'blob'
          }
        )
        .then((response) => {
          if (response && response.data) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              return window.navigator.msSaveOrOpenBlob(response.data, file.name)
            }
            const url = window.URL.createObjectURL(response.data)
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = url
            tempLink.setAttribute('download', file.name)

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (tempLink.download === 'undefined') {
              tempLink.setAttribute('target', '_blank')
            }

            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(url)
            return null
          } else {
            return null
          }
        })
    }
  }

  public editFile() {
    return (
      <Fragment>
        <EditIcon />
        <span className='action-dropdown__item-label'>Edit File</span>
      </Fragment>
    )
  }
  public deleteFile() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete File</span>
      </Fragment>
    )
  }
  public hardDeleteFile() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>
          Delete File Permanently
        </span>
      </Fragment>
    )
  }
  public renderRestoreFile() {
    return (
      <Fragment>
        <RestoreIcon />
        <span className='action-dropdown__item-label'>Restore File</span>
      </Fragment>
    )
  }
  public downloadBar = () => {
    const fullActions = [
      {
        element: this.editFile(),
        onClick: this.editFileFn
      },
      {
        element: this.deleteFile(),
        onClick: this.deleteFileFn
      }
    ]
    const restoreActions = [
      {
        element: this.renderRestoreFile(),
        onClick: this.restoreFileFn
      },
      {
        element: this.hardDeleteFile(),
        onClick: this.hardDeleteFileFn
      }
    ]
    const { file, inTrash } = this.props
    const { downloading, percent } = this.state
    if (downloading) {
      return (
        <div className='file__download-w'>
          <div>Downloading...</div>
          <div className='file__download-bar'>
            <div
              className='file__download-bar--active'
              style={{ width: `${percent}%` }}
            />
          </div>
        </div>
      )
    } else {
      return (
        <Fragment>
          <div className='file__created-date'>
            {moment().format('M/DD/YYYY') ===
            moment(file.createdDate).format('M/DD/YYYY')
              ? 'Today'
              : moment(file.createdDate).format('M/DD/YYYY')}
          </div>
          <div className='folder__column-actions folder__column-actions--delete'>
            <ActionDropdown actions={inTrash ? restoreActions : fullActions} />
          </div>
        </Fragment>
      )
    }
  }

  public render() {
    const { file, inTrash } = this.props
    return (
      <TableRow onClick={inTrash ? null : this.downloadFile}>
        <div className='folder__column--name'>
          <div className='folder__icon--w'>
            <FileIcon className='folder__icon' />
          </div>
          <div>{file.name}</div>
        </div>
        {this.downloadBar()}
      </TableRow>
    )
  }
}

const mapStateToProps = (store: GlobalState, { folderId }: any) => {
  return {
    userId: store.user.userId,
    folderId
  }
}

export default connect(mapStateToProps)(File)
