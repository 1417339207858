import React, { Component, Fragment } from 'react'
import Tile from '../../components/layout/tile'
import Heading from './heading'

import { ContactsInterface } from '../../../objects/contact'
import { MoneyMindInterface } from '../../../objects/moneymind'

import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { ReactComponent as MoneymindIcon } from '../../assets/images/icons/moneymind.svg'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/protection.svg'
import { ReactComponent as CommitmentIcon } from '../../assets/images/icons/commitment.svg'
import { ReactComponent as HappinessIcon } from '../../assets/images/icons/happiness.svg'

interface DashMoneymindProps {
  moneyminds: MoneyMindInterface
  contacts: ContactsInterface
  householdFinId: string
}

export class DashMoneymind extends Component<DashMoneymindProps> {
  public activeMM = (contact: any, contactType: string) => {
    const { moneyminds } = this.props
    return moneyminds &&
      moneyminds[contactType] &&
      contact.activeYmmId &&
      moneyminds[contactType][contact.activeYmmId]
      ? moneyminds[contactType][contact.activeYmmId]
      : { protection: 0, commitment: 0, happiness: 0 }
  }

  public renderJointMoneyMind = (): JSX.Element => {
    const { contacts } = this.props
    const mmContentDisplay = (
      contactData: any,
      contactMM: any
    ): JSX.Element => {
      if (!contactMM) return null
      return (
        <div className='c-dash__mm-score-client'>
          <span>{contactData && contactData.firstName}</span>
          <span>{contactMM.commitment}%</span>
          <span>{contactMM.protection}%</span>
          <span>{contactMM.happiness}%</span>
        </div>
      )
    }

    return (
      <Fragment>
        {mmContentDisplay(
          contacts.primary,
          contacts.primary && this.activeMM(contacts.primary, 'primary')
        )}
        {mmContentDisplay(
          contacts.secondary,
          contacts.secondary && this.activeMM(contacts.secondary, 'secondary')
        )}
      </Fragment>
    )
  }

  public mmResults = () => {
    const { contacts, moneyminds } = this.props
    if (contacts) {
      return (
        <Fragment>
          <div className='c-dash__mm'>
            <div className='c-dash__mm-icon'>
              <div className='c-dash__mm-icon-commitment'>
                <CommitmentIcon />
                <span>Commitment</span>
              </div>
              <div className='c-dash__mm-icon-protection'>
                <ProtectionIcon />
                <span>Protection</span>
              </div>
              <div className='c-dash__mm-icon-happiness'>
                <HappinessIcon />
                <span>Happiness</span>
              </div>
            </div>
          </div>
          <div className='c-dash__mm-score'>{this.renderJointMoneyMind()}</div>
        </Fragment>
      )
    } else if (
      contacts == null &&
      moneyminds.primary == null &&
      moneyminds.secondary == null
    ) {
      return (
        <Fragment>
          <div className='c-dash__mm  filtered__bottom'>
            <div className='c-dash__mm-icon'>
              <div className='c-dash__mm-icon-commitment'>
                <CommitmentIcon />
                <span>Commitment</span>
              </div>
              <div className='c-dash__mm-icon-protection'>
                <ProtectionIcon />
                <span>Protection</span>
              </div>

              <div className='c-dash__mm-icon-happiness'>
                <HappinessIcon />
                <span>Happiness</span>
              </div>
            </div>
          </div>
          <div className='c-dash__mm-score'>
            <div className='c-dash__mm-score-client'>
              <span>
                {contacts.primary &&
                  contacts.primary.firstName &&
                  contacts.primary.firstName}
              </span>
              <span>{moneyminds.primary.commitment}%</span>
              <span>{moneyminds.primary.protection}%</span>
              <span>{moneyminds.primary.happiness}%</span>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <div className='c-dash__mm-null'>
          <div className='c-dash__null--description'>
            Send MoneyMind® to the client and see the results here.
          </div>
        </div>
      )
    }
  }

  public render() {
    return (
      <Tile
        to={`/households/${this.props.householdFinId}/moneyMind`}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <Heading icon={<MoneymindIcon />} headingText={'MoneyMind®'} />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={true}
        anchorBorder={true}>
        <div className='c-dash__row-content'>{this.mmResults()}</div>
      </Tile>
    )
  }
}

export default DashMoneymind
