import React from 'react'
import { connect, Dispatch } from 'react-redux'
import * as userActions from '../../../actions/user'
import * as syncDisplayActions from '../../../actions/syncDisplay'
import SyncHelper from '../../helpers/sync'

import { GlobalState } from '../../../reducers'
import { HouseholdObj } from '../../../objects/household'

import { Loader } from '../../components/loader/loader'

interface SpinningProps {
  dispatch: Dispatch<GlobalState>
  household: HouseholdObj
  loadingType?: string
  loadingDescription?: string
  showSync: { showSync: boolean }
  closeFunc?(): void
  stacked: boolean
}

interface SpinningState {
  processing: boolean
  refreshTimer: any
  displaySync: boolean
}

class SyncLoader extends React.Component<SpinningProps, SpinningState> {
  constructor(props: SpinningProps) {
    super(props)
    this.state = {
      processing: false,
      refreshTimer: null,
      displaySync: true
    }
  }

  public syncHouseholdsTimer = () => {
    const { refreshTimer } = this.state
    const { household } = this.props
    const timerCountDown = setTimeout(async () => {
      SyncHelper(this.props.dispatch).getUserHouseholds(household.id)
      clearTimeout(refreshTimer)
      await this.setState({
        refreshTimer: null,
        processing: false
      })
      this.closeFunc()
    }, 10000)

    this.setState({
      refreshTimer: timerCountDown
    })
  }

  public dispatchSync = () => {
    const { household, dispatch, showSync } = this.props
    if (household) {
      this.setState({ processing: showSync.showSync })
      dispatch(userActions.syncHouseholds([household.crmId]))
      this.syncHouseholdsTimer()
    }
  }

  public closeFunc = () => {
    const { dispatch } = this.props
    dispatch(syncDisplayActions.hideSyncModal())
  }
  public hideSync = () => {
    this.setState({ displaySync: false })
  }
  public componentDidUpdate(nextProps: any, prevProps: any) {
    const { showSync } = this.props.showSync
    const { processing } = this.state

    if (!prevProps.refreshTimer && showSync && !processing) {
      this.dispatchSync()
    }
  }

  public componentDidMount() {
    this.dispatchSync()
  }

  public render() {
    const { stacked } = this.props
    const { showSync } = this.props.showSync
    const { refreshTimer, displaySync } = this.state

    return showSync || (!showSync && refreshTimer) ? (
      <div style={{ display: displaySync ? 'block' : 'none' }}>
        <Loader closeFunc={this.hideSync} stacked={stacked}>
          Refreshing your data
        </Loader>
      </div>
    ) : null
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    showSync: store.showSync
  }
}

export default connect(mapStateToProps)(SyncLoader)
