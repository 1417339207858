import React, { RefObject } from 'react'

interface PrioritySliderContent {
  color: string
  label: string
  description: string
}

export const CENTER_VALUE = 3

export const priorityContent = (
  content: PrioritySliderContent
): PrioritySliderContent => {
  return (
    content || {
      color: '#D2D2D2',
      label: '',
      description: ''
    }
  )
}

export const sliderChange = (
  numberValue: number,
  priorityChange: any,
  priority1Label: string,
  priority2Label: string
) => {
  if (numberValue % 2 !== 0) {
    return null
  }

  priorityChange[priority1Label] =
    numberValue > CENTER_VALUE
      ? Math.floor((CENTER_VALUE - numberValue) / 2)
      : Math.ceil((CENTER_VALUE - numberValue) / 2)
  priorityChange[priority2Label] =
    numberValue > CENTER_VALUE
      ? Math.ceil((numberValue - CENTER_VALUE) / 2)
      : Math.floor((numberValue - CENTER_VALUE) / 2)

  return {
    sliderValue: numberValue,
    priorityChange
  }
}

export const PrioritySlider: React.StatelessComponent<{
  modalSize: boolean
  inputRef: RefObject<HTMLInputElement>
  sliderValue: number
  leftPriority: PrioritySliderContent
  rightPriority: PrioritySliderContent
  onChangeFunc?(): any
}> = ({
  modalSize,
  inputRef,
  sliderValue,
  leftPriority,
  rightPriority,
  onChangeFunc
}) => (
  <div className='ivf-priorities__slider-w'>
    <div
      className='ivf-priorities__slider-hack'
      style={modalSize ? { marginTop: '0px' } : {}}>
      <input
        ref={inputRef}
        type='Range'
        min={0}
        max={6}
        value={sliderValue}
        aria-valuemax={6}
        aria-valuemin={0}
        aria-valuenow={sliderValue}
        onChange={onChangeFunc}
        step='1'
        className='ivf-priorities__slider'
        style={{
          background: `linear-gradient(90deg, ${
            priorityContent(leftPriority).color
          } 0%, #BCBFCC 49.58%, ${priorityContent(rightPriority).color} 100%)`
        }}
      />
    </div>
    <div className='ivf-priorities__slider-label-w'>
      <div
        className='ivf-priorities__slider-label ivf-priorities__slider-label--left'
        style={{ left: '14px' }}>
        Strongly
      </div>
      <div
        className='ivf-priorities__slider-label ivf-priorities__slider-label--center'
        style={{ left: modalSize ? '178px' : '133px' }}>
        Somewhat
      </div>
      <div
        className='ivf-priorities__slider-label ivf-priorities__slider-label--center'
        style={{ left: modalSize ? '375px' : '283px' }}>
        Somewhat
      </div>
      <div
        className='ivf-priorities__slider-label ivf-priorities__slider-label--right'
        style={{ right: '11px' }}>
        Strongly
      </div>
    </div>
  </div>
)
