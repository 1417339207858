import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import {
  PortfolioBuilder,
  PortfolioBuilderAccounts
} from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

import { PortfolioBuilderAccountObj } from '../objects/portfolioBuilder'

export const FETCH_PORTFOLIO_BUILDER = 'FETCH_PORTFOLIO_BUILDER'
export const CREATE_PORTFOLIO_BUILDER = 'CREATE_PORTFOLIO_BUILDER'

export const CREATE_PORTFOLIO_BUILDER_FULFILLED =
  'CREATE_PORTFOLIO_BUILDER_FULFILLED'
export const CREATE_PORTFOLIO_BUILDER_REJECTED =
  'CREATE_PORTFOLIO_BUILDER_REJECTED'
export const CREATE_PORTFOLIO_BUILDER_PENDING =
  'CREATE_PORTFOLIO_BUILDER_PENDING'

export const UPDATE_PORTFOLIO_BUILDER = 'UPDATE_PORTFOLIO_BUILDER'
export const UPDATE_PORTFOLIO_BUILDER_FULFILLED =
  'UPDATE_PORTFOLIO_BUILDER_FULFILLED'
export const UPDATE_PORTFOLIO_BUILDER_REJECTED =
  'UPDATE_PORTFOLIO_BUILDER_REJECTED'
export const UPDATE_PORTFOLIO_BUILDER_PENDING =
  'UPDATE_PORTFOLIO_BUILDER_PENDING'

export const FETCH_PORTFOLIO_BUILDER_ACCOUNTS =
  'FETCH_PORTFOLIO_BUILDER_ACCOUNTS'
export const CREATE_PORTFOLIO_BUILDER_ACCOUNT =
  'CREATE_PORTFOLIO_BUILDER_ACCOUNT'
export const DELETE_PORTFOLIO_BUILDER_ACCOUNT =
  'DELETE_PORTFOLIO_BUILDER_ACCOUNT'
export const UPDATE_PORTFOLIO_BUILDER_ACCOUNT =
  'UPDATE_PORTFOLIO_BUILDER_ACCOUNT'
export const UPDATE_PORTFOLIO_BUILDER_ACCOUNT_PENDING =
  'UPDATE_PORTFOLIO_BUILDER_ACCOUNT_PENDING'
export const UPDATE_PORTFOLIO_BUILDER_ACCOUNT_FULFILLED =
  'UPDATE_PORTFOLIO_BUILDER_ACCOUNT_FULFILLED'
export const UPDATE_PORTFOLIO_BUILDER_ACCOUNT_REJECTED =
  'UPDATE_PORTFOLIO_BUILDER_ACCOUNT_REJECTED'

interface PortfolioBuilderResponse extends PortfolioBuilder.Response {
  householdFinId: string
  portfolioBuilderFinId?: string
}

interface PortfolioBuilderAccountsResponse {
  householdFinId: string
  portfolioBuilderFinId: string
  accounts: PortfolioBuilderAccounts.Response[]
}

export const getPortfolioBuilders = (householdFinId: string) => {
  return {
    type: FETCH_PORTFOLIO_BUILDER,
    payload: api()
      .get(`/households/${householdFinId}/portfoliobuilder`)
      .then((response: AxiosResponse<PortfolioBuilderResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createPortfolioBuilder = (
  householdFinId: string,
  name: string
) => {
  const requestBody = {
    name,
    proposal: new Date()
  }
  return {
    type: CREATE_PORTFOLIO_BUILDER,
    payload: api()
      .post(`/households/${householdFinId}/portfoliobuilder`, requestBody)
      .then((response: AxiosResponse<PortfolioBuilderResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updatePortfolioBuilder = (
  householdFinId: string,
  portfolioBuilderFinId: string,
  requestBody: any
) => {
  return {
    type: UPDATE_PORTFOLIO_BUILDER,
    payload: api()
      .put(
        `/households/${householdFinId}/portfoliobuilder/${portfolioBuilderFinId}`,
        requestBody
      )
      .then((response: AxiosResponse<PortfolioBuilderResponse>) => {
        response.data.householdFinId = householdFinId
        response.data.portfolioBuilderFinId = portfolioBuilderFinId
        return response
      })
  }
}

export const getPortfolioBuilderAccounts = (
  householdFinId: string,
  portfolioBuilderFinId: string
) => {
  return {
    type: FETCH_PORTFOLIO_BUILDER_ACCOUNTS,
    payload: api()
      .get(
        `/households/${householdFinId}/portfoliobuilder/${portfolioBuilderFinId}/accounts`
      )
      .then((response: AxiosResponse<PortfolioBuilderAccountsResponse>) => {
        response.data.householdFinId = householdFinId
        response.data.portfolioBuilderFinId = portfolioBuilderFinId
        return response
      })
  }
}

export const createPortfolioBuilderAccount = (
  householdFinId: string,
  portfolioBuilderFinId: string,
  pbAccount: PortfolioBuilderAccountObj
) => {
  return {
    type: CREATE_PORTFOLIO_BUILDER_ACCOUNT,
    payload: api()
      .post(
        `/households/${householdFinId}/portfoliobuilder/${portfolioBuilderFinId}/accounts`,
        pbAccount
      )
      .then((response: AxiosResponse<PortfolioBuilderAccountsResponse>) => {
        return {
          data: {
            accounts: response.data,
            householdFinId,
            portfolioBuilderFinId
          },
          status: response.status
        }
      })
  }
}

export const updatePortfolioBuilderAccount = (
  householdFinId: string,
  portfolioBuilderFinId: string,
  portfolioBuilderAccountId: string,
  pbAccount: PortfolioBuilderAccountObj
) => {
  return {
    type: UPDATE_PORTFOLIO_BUILDER_ACCOUNT,
    payload: api()
      .put(
        `/households/${householdFinId}/portfoliobuilder/${portfolioBuilderFinId}/accounts/${portfolioBuilderAccountId}`,
        pbAccount
      )
      .then((response: AxiosResponse<PortfolioBuilderAccountsResponse>) => {
        response.data.householdFinId = householdFinId
        response.data.portfolioBuilderFinId = portfolioBuilderFinId
        return response
      })
  }
}

export const deletePortfolioBuilderAccount = (
  householdFinId: string,
  portfolioBuilderFinId: string,
  portfolioBuilderAccountId: string
) => {
  return {
    type: DELETE_PORTFOLIO_BUILDER_ACCOUNT,
    payload: api()
      .delete(
        `/households/${householdFinId}/portfoliobuilder/${portfolioBuilderFinId}/accounts/${portfolioBuilderAccountId}`
      )
      .then((response) => {
        if (!response.data) {
          response.data = {}
        }
        response.data.householdFinId = householdFinId
        response.data.portfolioBuilderFinId = portfolioBuilderFinId
        response.data.portfolioBuilderAccountId = portfolioBuilderAccountId
        return response
      })
  }
}
