import React from 'react'
import { Dispatch } from 'redux'
import SuggestedGoalCards from '../components/suggestedGoalCards'

import {
  GoalTemplatesResponse,
  FinancialGoalActionObj
} from '../../../../objects/financialGoalActions'
import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'
import { CardObj } from '../../../../objects/HonestConversations/card'
import rightArrow from '../../../assets/images/icons/goals/goal-action-arrow-blue.png'
import { getFinancialGoalsTemplates } from '../../../../actions/financialGoalActions'
import { sortHouseholdCards } from '../../../helpers/honestConversations'
import { GlobalState } from '../../../../reducers'
import _ from 'lodash'

export interface GoalView1Props {
  setViewKey(id: number): void
  honestConversation: HonestConversationsExerciseObj
  suggestedGoals: GoalTemplatesResponse[]
  setSuggestedGoal(id: string): void
  goalCls: string
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  archivedGoals: FinancialGoalActionObj[]
  goalsFromSelector: FinancialGoalActionObj[]
  toggleRestoreModal(): void
  setActiveArchivedGoal(archivedGoal: FinancialGoalActionObj): void
}

const WEIGHT = {
  0: 30,
  1: 25,
  2: 20,
  3: 15,
  4: 10
}

export default class GoalView1 extends React.Component<GoalView1Props, {}> {
  componentDidMount = () => {
    const { dispatch, householdFinId } = this.props
    dispatch(getFinancialGoalsTemplates(householdFinId))
  }
  public setCustomGoalView = () => {
    this.props.setViewKey(2)
  }

  public setSuggestedGoalView = (id: string) => {
    this.props.setViewKey(2)
    this.props.setSuggestedGoal(id)
  }

  public handleSuggestedGoalView = (goalId: string) => {
    const {
      archivedGoals,
      toggleRestoreModal,
      setActiveArchivedGoal
    } = this.props
    // Check if current selected goalId exists in archived goals
    const activeArchivedGoal = archivedGoals.find((goal) => {
      return goal.palItemId === goalId
    })
    if (activeArchivedGoal) {
      // If true, show Restore Goal Modal
      toggleRestoreModal()
      setActiveArchivedGoal(activeArchivedGoal)
    } else {
      // else, transition to add goal view
      this.setSuggestedGoalView(goalId)
    }
  }

  public getGoalsByWeight = () => {
    const {
      honestConversation: { householdCards = {} } = {},
      suggestedGoals = [],
      goalsFromSelector = []
    } = this.props

    const sgCards: any = []

    // Filter out the suggested goals which are already created
    // Follow this ticket DAS-61140 for more information
    const filterSuggestedGoals = suggestedGoals.filter(
      (suggestedGoal: GoalTemplatesResponse) => {
        return !Boolean(
          goalsFromSelector.find(
            (goalAction: FinancialGoalActionObj) =>
              goalAction.palItemId === suggestedGoal.id
          )
        )
      }
    )

    filterSuggestedGoals.map((sGoal) => {
      const cards = sGoal.hcCards
      const relevantCards: CardObj[] = []
      let totalWeight: number = 0

      const palCardTitleArray =
        cards && Object.keys(cards).map((key) => cards[key].title.toLowerCase())

      if (householdCards) {
        sortHouseholdCards(householdCards).forEach((card: any, index) => {
          if (
            card?.card?.title &&
            palCardTitleArray &&
            palCardTitleArray.indexOf(card.card.title.toLowerCase()) !== -1
          ) {
            totalWeight = totalWeight + WEIGHT[index]
            relevantCards.push(card.card)
          }
        })
        sgCards.push({
          weight: totalWeight,
          hcCards: [...relevantCards],
          id: sGoal.id,
          name: sGoal.name
        })
      }
    })

    return _.sortBy(sgCards, 'weight').reverse()
  }

  public render() {
    const { goalCls = '' } = this.props
    const goals = this.getGoalsByWeight()
    return (
      <div>
        <a
          className='side-bar-body__custom-goal'
          onClick={this.setCustomGoalView}>
          <span>Custom Goal</span>
          <img src={rightArrow} width='20' height='20' alt='right-arrow' />
        </a>
        <div className='side-bar-body__suggested-goal'>Suggested</div>
        <div className='side-bar-body__priorities-body'>
          {goals.map((goal) => {
            return (
              <a
                onClick={() => this.handleSuggestedGoalView(goal.id)}
                key={goal.id}
                className='side-bar-body__suggested-goal-view'>
                <div className='side-bar-body__suggested-goal-view-item'>
                  <span>{goal.name}</span>
                  <SuggestedGoalCards
                    classnames={goalCls}
                    cards={goal.hcCards}
                    view={1}
                  />
                </div>
                <img
                  src={rightArrow}
                  width='20'
                  height='20'
                  alt='right-arrow'
                />
              </a>
            )
          })}
        </div>
      </div>
    )
  }
}
