import React, { Component, CSSProperties } from 'react'
import { history } from '../../../../../store'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import {
  ClientAccountInterface,
  ClientAccountObj
} from '../../../../../objects/clientAccount'

import ImFooter from '../../../../components/im/footer'

interface ProgressTrackerProps {
  selectedId: string
  householdFinId: string
  pathname: string
  url: string
  clientAccounts: ClientAccountInterface
  runAnalysis(): void
}

const buttonStyle: CSSProperties = {
  marginTop: '5px',
  height: '44px',
  minHeight: '44px',
  minWidth: '69px',
  fontSize: '14px'
}

export class ProgressTracker extends Component<ProgressTrackerProps> {
  public isStepCurrent = (stepName: string) => {
    const { pathname } = this.props
    const nodes = pathname.split('/').filter((node) => node)
    return nodes[nodes.length - 1] === stepName
  }

  public hasPositionsSum = () => {
    const { clientAccounts } = this.props
    let sum = 0
    Object.values(clientAccounts).forEach((account: ClientAccountObj) => {
      const { positions } = account
      const positionsSum = !Object.is(positions, null)
        ? Object.values(positions).reduce(
            (total, { value }) => total + value,
            0
          )
        : 0
      sum = positionsSum + sum
    })

    return sum > 0
  }

  public processSteps = () => {
    return <div style={{ width: '760px' }}></div>
  }

  public goStart = () => {
    history.goBack()
  }

  public goNext = () => {
    const { url } = this.props
    history.push(`${url}/benchmark`)
  }

  public runAnalysis = () => {
    const { runAnalysis } = this.props

    runAnalysis()
  }

  public nextStepButton = () => {
    const { selectedId } = this.props

    if (this.isStepCurrent('benchmark')) {
      let runStyle: CSSProperties = { ...buttonStyle, minWidth: '120px' }
      if (!selectedId) {
        runStyle = {
          ...runStyle,
          background: 'white',
          border: 'solid 1px rgba(33, 120, 196, 0.5)'
        }
      }

      return <div></div>
    }

    return null
  }

  public render = () => {
    return !this.isStepCurrent('analysis') ? (
      <ImFooter>
        {this.processSteps()}
        <div className='im-portfolio__footer-right'>
          {this.isStepCurrent('benchmark') && null}
          {this.nextStepButton()}
        </div>
      </ImFooter>
    ) : null
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId, url }: any) => {
  return {
    householdFinId,
    url
  }
}

export default connect(mapStateToProps)(ProgressTracker)
