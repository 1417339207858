import * as actions from '../actions/contacts'

export interface SettingsContactInfoLoaderState {
  loadingUsername: boolean
  loadingContact: boolean
}

export const initState: SettingsContactInfoLoaderState = {
  loadingUsername: true,
  loadingContact: true
}

const SettingsContactInfoLoader = (
  state = initState,
  action: any
): SettingsContactInfoLoaderState => {
  switch (action.type) {
    case actions.GET_USERNAME_PENDING:
      return { ...state, loadingUsername: true }
    case actions.GET_USERNAME_FULFILLED:
      return { ...state, loadingUsername: false }
    case actions.FETCH_CONTACT_PENDING:
      return { ...state, loadingContact: true }
    case actions.FETCH_CONTACT_FULFILLED:
      return { ...state, loadingContact: false }
    default:
      return state
  }
}

export default SettingsContactInfoLoader
