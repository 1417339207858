import React from 'react'
import { Dispatch, connect } from 'react-redux'
import { map } from 'lodash'
import Toast from './toast'
import { GlobalState } from '../../../reducers'
import { ToastState } from '../../../reducers/toasts'
import { ToastObj } from '../../../objects/toasts'
import { removeToast } from '../../../actions/toasts'

export interface ToastsProps {
  toasts: ToastState
  dispatch: Dispatch<GlobalState>
}

export class Toasts extends React.Component<ToastsProps> {
  public removeToast = (id: string) => {
    this.props.dispatch(removeToast(id))
  }

  public toastArray = (): any[] => {
    const { toasts } = this.props
    return map(toasts, (notification: ToastObj, id) => {
      return (
        <Toast
          key={id}
          id={id}
          message={notification.message}
          icon={notification.icon}
          backgroundColor={notification.backgroundColor}
          removeToast={this.removeToast}
        />
      )
    })
  }

  public render() {
    const toastArray = this.toastArray()

    return (
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '90%',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 900
        }}>
        {toastArray}
      </div>
    )
  }
}

export default connect(
  (store: GlobalState, dispatch: Dispatch<GlobalState>) => {
    return {
      toasts: store.toasts,
      dispatch
    }
  }
)(Toasts)
