import React, { Component, Fragment } from 'react'
import Button from '../../../components/button'
import Search from '../../../components/search'

import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'
import { ReactComponent as PreviousArrow } from '../../../assets/images/icons/previous.svg'
import { ReactComponent as NoresultIcon } from '../../../assets/images/icons/noresults.svg'
import editIcon from '../../../assets/images/icons/png/edit.png'
import noTeams from '../../../assets/images/icons/png/noTeams.png'
import {
  OfficeTeamsInterface,
  OfficeTeamObj,
  OfficeTeamMemberObj
} from '../../../../objects/officeTeams'

import ActionDropdown from '../../../components/actionDropdown'
import CreateTeamModal from './createTeamModal'
import DeleteTeamModal from './deleteTeamModal'
import DeleteTeamMemberModal from './deleteTeamMemberModal'
import EditModal from './editModal'
import AddToTeamModal from './addToTeamModal'
import TeamMemberRow from './teamMemberRow'
import Tooltip from '../../../components/tooltip'
import { ReactComponent as HelpIcon } from '../../../assets/images/icons/help.svg'
import { staticText } from '../../../helpers/resources'
import ProfilePicture from '../../../components/layout/profilePicture'

interface TeamManagementProps {
  officeTeams: OfficeTeamsInterface
  officeAdmin: boolean
  createTeam(name: string): void
  setTeam(id: string): void
  editTeam(id: string, name: string): void
  editTeamMembers(id: string, addIds: string[], deleteIds: string[]): void
  editTeamMember(id: string, member: OfficeTeamMemberObj): void
  deleteTeam(team: OfficeTeamObj): void
  deleteTeamMember(id: string, memberId: string): void
}

interface TeamManagementState {
  showCreateEditModal: boolean
  searched: string
  showDeleteTeamModal: boolean
  openEditScreen: boolean
  img: string
  showEditModal: boolean
  currentTeamId: string
  currentTeamMemberId: string
  showAddToTeamModal: boolean
  showDeleteMemberModal: boolean
}

export class TeamManagement extends Component<
  TeamManagementProps,
  TeamManagementState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      showCreateEditModal: false,
      searched: '',
      showDeleteTeamModal: false,
      openEditScreen: false,
      img: '',
      showEditModal: false,
      currentTeamId: '',
      currentTeamMemberId: '',
      showAddToTeamModal: false,
      showDeleteMemberModal: false
    }
  }

  public handleFilter = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({ searched: event.currentTarget.value })
  }

  public openCreateTeamModal = () => {
    this.setState({ showCreateEditModal: !this.state.showCreateEditModal })
  }
  public saveCreateTeamModal = (name: string) => {
    this.props.createTeam(name)
    this.openCreateTeamModal()
  }

  public openEditTeamModal = () => {
    this.setState({ showEditModal: !this.state.showEditModal })
  }
  public saveEditTeamModal = (id: string, name: string) => {
    this.props.editTeam(id, name)
    this.openEditTeamModal()
  }

  public openDeleteTeamModal = () => {
    this.setState({ showDeleteTeamModal: !this.state.showDeleteTeamModal })
  }
  public saveDeleteTeamModal = (team: OfficeTeamObj) => {
    this.props.deleteTeam(team)
    this.openDeleteTeamModal()
  }

  public openEditTeamScreen = () => {
    this.setState({ openEditScreen: !this.state.openEditScreen })
  }

  public setCurrentTeam = (team: any) => {
    this.props.setTeam(team.id)
    this.setState({ currentTeamId: team.id })
  }

  public openAddToTeamModal = () => {
    this.setState({ showAddToTeamModal: !this.state.showAddToTeamModal })
  }
  public saveAddToTeamModal = (
    id: string,
    addIds: string[],
    deleteIds: string[]
  ) => {
    this.props.editTeamMembers(id, addIds, deleteIds)
    this.openAddToTeamModal()
  }

  public editTeamMemberFn = (id: string, member: OfficeTeamMemberObj) => {
    this.props.editTeamMember(id, member)
  }
  public openDeleteMemberModal = (userId: string) => {
    this.setState({
      showDeleteMemberModal: !this.state.showDeleteMemberModal,
      currentTeamMemberId: userId
    })
  }
  public closeDeleteMemberModal = () => {
    this.setState({ showDeleteMemberModal: !this.state.showDeleteMemberModal })
  }
  public saveDeleteTeamMemberModal = (id: string, memberId: string) => {
    this.props.deleteTeamMember(id, memberId)
    this.closeDeleteMemberModal()
  }

  public editTeam() {
    return (
      <Fragment>
        <EditIcon />
        <span className='action-dropdown__item-label'>
          {this.props.officeAdmin ? 'Edit Team' : 'View Team'}
        </span>
      </Fragment>
    )
  }

  public deleteTeam() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Remove Team</span>
      </Fragment>
    )
  }

  public renderTeamMembers = (members: OfficeTeamMemberObj[]) => {
    const position: number = 25
    const remainingMembers = members.length - 4
    return members.map((member: OfficeTeamMemberObj, index: number) => {
      const user = member.userId && member.user ? member.user : null
      if (user && index < 3) {
        return (
          <Fragment key={index}>
            <div
              className='admin-settings__teams'
              style={{ top: 0, left: `${index * position}px`, zIndex: index }}>
              <ProfilePicture
                firstName={user?.firstName}
                lastName={user?.lastName}
                photo={user?.photoUrl}
                isPrimary={true}
                pictureSize={32}
              />
            </div>
          </Fragment>
        )
      } else if (user && index === 3) {
        return (
          <Fragment key={index}>
            <div
              className='admin-settings__teams'
              style={{ top: 0, left: `${index * position}px`, zIndex: index }}>
              <ProfilePicture
                firstName={user?.firstName}
                lastName={user?.lastName}
                photo={user?.photoUrl}
                isPrimary={true}
                pictureSize={32}
              />
            </div>
            {remainingMembers > 0 ? (
              <div
                className='admin-settings__teams'
                style={{
                  top: 0,
                  left: `${(index + 1) * position}px`,
                  zIndex: index
                }}>
                <span className='admin-settings__team-member-photo more'>
                  +{remainingMembers}
                </span>
              </div>
            ) : null}
          </Fragment>
        )
      } else return null
    })
  }

  public teamList = (team: OfficeTeamObj) => {
    return team.members.map((member: OfficeTeamMemberObj, index: number) => {
      const user = member.userId && member.user ? member.user : null
      if (user) {
        return (
          <TeamMemberRow
            key={index}
            user={user}
            officeAdmin={this.props.officeAdmin}
            member={member}
            currentTeamId={team.id}
            editFn={this.editTeamMemberFn}
            deleteFn={this.openDeleteMemberModal}
          />
        )
      } else return null
    })
  }

  public nullTeam = () => {
    return (
      <div className='team-management__modal-noresult'>
        <img src={noTeams} alt='noteams' />
        <p className='team-management__modal-noresult-text team-management__modal-noresult-text--blue'>
          No members yet
        </p>
        <p className='team-management__modal-noresult-text--gray'>
          You should add members to your team.
        </p>
      </div>
    )
  }

  public renderTeamEditScreen = (team: any) => {
    const { name } = team
    return (
      <div className='edit-team'>
        <p className='edit-team__back-btn' onClick={this.openEditTeamScreen}>
          <PreviousArrow /> Back to Teams
        </p>
        <div className='edit-team__team-profile'>
          <p>
            {name}
            {this.props.officeAdmin && (
              <img
                src={editIcon}
                onClick={this.openEditTeamModal}
                alt='editIcon'
              />
            )}
          </p>
        </div>
        {this.props.officeAdmin && (
          <div className='edit-team__add-btn'>
            <Button primary={true} onClick={this.openAddToTeamModal}>
              <PlusIcon /> Add to Team
            </Button>
          </div>
        )}
        <div className='admin-settings__row  admin-settings__row--cols admin-settings__row--cols-h'>
          <span>NAME</span>
          <span>TITLE</span>
          <span>
            SHOW/HIDE
            <Tooltip
              message={staticText.includeInGc}
              width={160}
              position='bottom'
              multiLine={true}>
              <HelpIcon />
            </Tooltip>
          </span>
        </div>
        {team.members.length === 0 ? this.nullTeam() : this.teamList(team)}
        {this.state.showEditModal ? (
          <EditModal
            saveModal={this.saveEditTeamModal}
            closeModal={this.openEditTeamModal}
            team={this.props.officeTeams[this.state.currentTeamId]}
          />
        ) : null}
        {this.state.showAddToTeamModal ? (
          <AddToTeamModal
            saveModal={this.saveAddToTeamModal}
            closeModal={this.openAddToTeamModal}
            team={this.props.officeTeams[this.state.currentTeamId]}
            users={
              this.props.officeTeams[this.state.currentTeamId].eligibleUsers
            }
          />
        ) : null}
        {this.state.showDeleteMemberModal ? (
          <DeleteTeamMemberModal
            member={
              this.props.officeTeams[this.state.currentTeamId].eligibleUsers[
                this.state.currentTeamMemberId
              ]
            }
            deleteModal={this.saveDeleteTeamMemberModal}
            closeModal={this.closeDeleteMemberModal}
            team={this.props.officeTeams[this.state.currentTeamId]}
          />
        ) : null}
      </div>
    )
  }

  public renderTable = (filteredTeams: OfficeTeamObj[]) => {
    const dropdownActions = [
      {
        element: this.editTeam(),
        onClick: this.openEditTeamScreen
      },
      {
        element: this.deleteTeam(),
        onClick: this.openDeleteTeamModal
      }
    ]
    const limitedActions = [
      {
        element: this.editTeam(),
        onClick: this.openEditTeamScreen
      }
    ]

    if (filteredTeams.length === 0) {
      return (
        <div className='team-management__modal-noresult'>
          <img src={noTeams} alt='None' />
          <p className='team-management__modal-noresult-text team-management__modal-noresult-text--blue'>
            No teams yet
          </p>
          <p className='team-management__modal-noresult-text--gray'>
            Create your first team by adding members to it.
          </p>
        </div>
      )
    } else {
      return filteredTeams.map((team: any, index: number) => {
        return (
          <div
            key={index}
            className='admin-settings__row  admin-settings__row--col'>
            <div>
              <h5 style={{ margin: 0 }}>{team.name}</h5>
            </div>
            <div>
              <div className='admin-settings__teams-w'>
                {this.renderTeamMembers(team.members)}
              </div>
              <ActionDropdown
                actions={
                  this.props.officeAdmin ? dropdownActions : limitedActions
                }
                team={team}
                setTeam={this.setCurrentTeam}
              />
            </div>
          </div>
        )
      })
    }
  }

  public renderFilteredTeams() {
    const { officeTeams } = this.props
    const { searched } = this.state
    let filteredTeams: OfficeTeamObj[] = officeTeams
      ? Object.keys(officeTeams).map((key) => officeTeams[key])
      : []
    if (filteredTeams) {
      if (searched && searched.trim()) {
        filteredTeams = filteredTeams.filter(
          (team: any) =>
            team.name
              .toLowerCase()
              .indexOf(this.state.searched.toLowerCase()) !== -1
        )
      }
    }
    if (filteredTeams.length > 0) {
      return this.renderTable(filteredTeams)
    } else {
      return (
        <div className='team-management__modal-noresult'>
          <NoresultIcon />
          <p className='team-management__modal-noresult-text team-management__modal-noresult-text--blue'>
            There are no teams with this name
          </p>
          <p className='team-management__modal-noresult-text--gray'>
            Try searching for just the parts of the team name and don’t forget
            to spellcheck.
          </p>
        </div>
      )
    }
  }

  public render() {
    if (!this.state.openEditScreen) {
      return (
        <Fragment>
          <div>
            <div className='admin-settings__row'>
              <h2 className='admin-settings__heading'>Team Management</h2>
            </div>
            <div className='admin-settings__row'>
              <Search
                onChange={this.handleFilter}
                value={this.state.searched}
              />
              {this.props.officeAdmin && (
                <Button primary={true} onClick={this.openCreateTeamModal}>
                  <PlusIcon /> Create Team
                </Button>
              )}
            </div>
            <div className='admin-settings__row  admin-settings__row--col admin-settings__row--col-h'>
              <span>NAME</span>
              <span>MEMBERS</span>
            </div>
            {this.renderFilteredTeams()}
            {this.state.showDeleteTeamModal ? (
              <DeleteTeamModal
                closeModal={this.openDeleteTeamModal}
                deleteModal={this.saveDeleteTeamModal}
                team={this.props.officeTeams[this.state.currentTeamId]}
              />
            ) : null}
            {this.state.showCreateEditModal ? (
              <CreateTeamModal
                closeModal={this.openCreateTeamModal}
                saveModal={this.saveCreateTeamModal}
              />
            ) : null}
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {this.renderTeamEditScreen(
            this.props.officeTeams[this.state.currentTeamId]
          )}
        </Fragment>
      )
    }
  }
}

export default TeamManagement
