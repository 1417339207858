import React from 'react'
import moment from 'moment'
import { connect, Dispatch } from 'react-redux'

import { GlobalState } from '../../../reducers'
import { acceptTOSandPrivacyPolicy } from '../../../actions/user'
import { revokeAndLogout } from '../../helpers'

import { Checkbox } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import Tooltip from '../../components/tooltip'

export interface TosModalProps {
  termsOfServiceAccepted: boolean
  dispatch: Dispatch<GlobalState>
}

interface TosModalState {
  viewPP: boolean
  viewTOS: boolean
  checked: boolean
  showTooltip: boolean
}

export class TosModal extends React.Component<TosModalProps, TosModalState> {
  constructor(props: TosModalProps) {
    super(props)
    this.state = {
      viewPP: false,
      viewTOS: false,
      checked: false,
      showTooltip: false
    }
  }

  public accept = () => {
    const todayDate = moment().format('YYYY-MM-DD')
    this.props.dispatch(acceptTOSandPrivacyPolicy(todayDate))
  }

  public decline = async () => {
    revokeAndLogout()
  }

  public handleCheckboxToggle = () => {
    return this.setState({ checked: !this.state.checked })
  }

  public handleLinkClick = (title: string): void => {
    return title === 'Privacy Policy'
      ? this.setState({ viewPP: true })
      : this.setState({ viewTOS: true })
  }

  public buttonValidation = () => {
    const { checked, viewPP, viewTOS } = this.state
    return Boolean(checked && viewPP && viewTOS)
  }

  public tooltipMessage = () => {
    const { viewPP, viewTOS } = this.state
    const viewPoliciesText =
      !viewPP && !viewTOS
        ? 'Please click on both links to continue'
        : viewPP && !viewTOS
        ? 'Please click on the Terms of Service link to continue'
        : !viewPP && viewTOS
        ? 'Please click on Privacy Policy link to continue'
        : 'Please confirm by selecting the check box'
    return `${viewPoliciesText}`
  }

  public renderUrlLink = (title: string, destination: string) => {
    const handleClick = () => this.handleLinkClick(title)
    return (
      <a
        href={destination}
        target='_blank'
        rel='noopener noreferrer'
        className='tos-modal__body-text--link'
        onClick={handleClick}>
        {title}
      </a>
    )
  }

  public render() {
    return (
      <Modal
        centerModal={false}
        closeModal={this.decline}
        title='TERMS OF SERVICE AND PRIVACY POLICY'
        modalOverlayClass='modal__overlay--gray-light-bg'>
        <section className='tos-modal__body'>
          <Checkbox
            checked={this.state.checked}
            onChange={this.handleCheckboxToggle}
            checkedBorder={true}
            style={{ width: '5%' }}
          />
          <span className='tos-modal__body-text'>
            I confirm that I have read and agree to the{' '}
            {this.renderUrlLink(
              'Terms of Service',
              window._env_.REACT_APP_TERMS_OF_SERVICE_URL
            )}{' '}
            and{' '}
            {this.renderUrlLink(
              'Privacy Policy',
              window._env_.REACT_APP_PRIVACY_POLICY_URL
            )}
          </span>
        </section>
        <section className='tos-modal__btn'>
          <Button clear={true} onClick={this.decline}>
            <span className='tos-modal__btn-text'>Cancel</span>
          </Button>
          <Tooltip
            position='bottom'
            hideTooltip={this.buttonValidation()}
            message={this.tooltipMessage()}
            width={200}>
            <Button
              primary={true}
              onClick={this.accept}
              disabled={!this.buttonValidation()}>
              <span className='tos-modal__btn-text'>Save & Continue</span>
            </Button>
          </Tooltip>
        </section>
      </Modal>
    )
  }
}

export default connect(
  (store: GlobalState, dispatch: Dispatch<GlobalState>): TosModalProps => {
    return {
      termsOfServiceAccepted: store.user.termsOfServiceAccepted,
      dispatch
    }
  }
)(TosModal)
