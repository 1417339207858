import React, { Component } from 'react'

import { ContactObj } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'
import { ClientAccountsErrorEmail } from '../../../objects/clientAccount'

import EmailPreviewModal from '../../components/layout/emailPreviewModal'

export interface ClientAccountsErrorEmailModalProps {
  contact: ContactObj
  user: UserObj
  clientAccountsErrorEmail: ClientAccountsErrorEmail
  emailSentDate: string
  saveModal(id: string): void
  closeModal(): void
}

class ClientAccountsErrorEmailModal extends Component<
  ClientAccountsErrorEmailModalProps
> {
  public render() {
    const {
      contact,
      user,
      saveModal,
      closeModal,
      emailSentDate,
      clientAccountsErrorEmail
    } = this.props
    const email = {
      __html: clientAccountsErrorEmail?.html
    }
    return (
      <EmailPreviewModal
        user={user}
        contact={contact}
        saveModal={saveModal}
        closeModal={closeModal}
        showCopyLink={false}
        subject={'Some accounts require your attention'}
        emailSentDate={emailSentDate}>
        <div
          className='client-accounts-error-email-modal__email-html'
          dangerouslySetInnerHTML={email}
        />
      </EmailPreviewModal>
    )
  }
}

export default ClientAccountsErrorEmailModal
