import React from 'react'

class AcctNullState extends React.Component {
  public render() {
    return (
      <div className='acc-null'>
        <div className='acc-null__name'>
          <div className='acc-null__name-text' />
          <div className='acc-null__name-bal' />
        </div>
        <div className='acc-null__acc-number' />
        <div className='acc-null__reg-type' />
        <div className='acc-null__yield' />
        <div className='acc-null__custodian'>
          <div className='acc-null__custodian-text' />
          <div className='acc-null__custodian-ratio' />
        </div>
      </div>
    )
  }
}
export default AcctNullState
