import {
  SortSettingProperty,
  SortType
} from '../../objects/preferenceSortSetting'
import { ClientAccountObjState } from '../../objects/clientAccount'
import * as SortHelper from './householdPreferences'

export const groupByOptions: SortSettingProperty[] = [
  {
    value: 'category',
    label: 'Classification'
  },
  {
    value: 'registrationType',
    label: 'Registration Type'
  }
]

export const sortByOptions: SortSettingProperty[] = [
  {
    value: 'accountNickname',
    label: 'Account Name'
  },
  {
    value: 'productName',
    label: 'Strategy'
  },
  {
    value: 'discreteAllocation',
    label: 'Allocation'
  },
  {
    value: 'totalValue',
    label: 'Amount'
  },
  {
    value: 'pssSelected',
    label: 'Show/Hide'
  },
  {
    value: 'guidebookClassification',
    label: 'Classification'
  },
  {
    value: 'registrationType',
    label: 'Registration Type'
  }
]

export const sortOrderOptions: SortSettingProperty[] = [
  {
    value: 'ascending',
    label: 'Ascending'
  },
  {
    value: 'descending',
    label: 'Descending'
  }
]

export const sortInvestmentAccounts = (
  clientAccounts: ClientAccountObjState,
  optionValues: SortType
) => {
  const groupBy = SortHelper.mapValueToOption(
    groupByOptions,
    optionValues.groupBy
  )
  const groupByVal = groupBy && groupBy.value ? groupBy.value : ''
  const sortBy = SortHelper.mapValueToOption(sortByOptions, optionValues.sortBy)
  const sortByVal = sortBy && sortBy.value ? sortBy.value : ''
  const sortingOrder = SortHelper.mapValueToOption(
    sortOrderOptions,
    optionValues.sortingOrder
  )
  const sortingOrderVal =
    sortingOrder && sortingOrder.value ? sortingOrder.value : ''
  const desc = sortingOrderVal === 'descending'
  const accountsToSort = SortHelper.sanitizeSortFieldValues(clientAccounts, {
    groupBy: groupByVal,
    sortBy: sortByVal
  })
  const sortedAccounts = {}
  if (accountsToSort) {
    Object.keys(accountsToSort)
      .sort((a, b) => {
        const groupA = accountsToSort[a][groupByVal]
        const groupB = accountsToSort[b][groupByVal]
        const sortA = accountsToSort[a][sortByVal]
        const sortB = accountsToSort[b][sortByVal]
        let grouped
        let sorted
        if (groupByVal === 'category' || groupByVal === 'registrationType') {
          grouped = SortHelper.compareStringField(groupA, groupB, false)
        }
        if (sortByVal === 'totalValue' || sortByVal === 'pssSelected') {
          sorted = SortHelper.compareNumericField(sortA, sortB, desc)
        }
        if (sortByVal === 'discreteAllocation') {
          sorted = SortHelper.compareAllocationField(sortA, sortB, desc)
        }
        if (
          sortByVal === 'accountNickname' ||
          sortByVal === 'productName' ||
          sortByVal === 'accountNumber' ||
          sortByVal === 'guidebookClassification' ||
          sortByVal === 'registrationType'
        ) {
          sorted = SortHelper.compareStringField(sortA, sortB, desc)
        }
        return grouped || sorted
      })
      .forEach((key: string) => {
        sortedAccounts[key] = accountsToSort[key]
      })
  }
  return sortedAccounts
}
