import * as actions from '../actions/globalSettings'

export interface GlobalSettingsState {
  globalNavigation: {
    collapsed: boolean
  }
}

const initState: GlobalSettingsState = {
  globalNavigation: { collapsed: false }
}

const globalSettings = (state = initState, action: any) => {
  const { type, payload: { data = {} } = {} } = action || {}
  const newState = JSON.parse(JSON.stringify(state))
  switch (type) {
    case `${actions.UPDATE_GLOBALNAVIGATION_ACTIONS}`:
      newState.globalNavigation.collapsed = data.toggleState
      return newState
    default:
      return state
  }
}
export default globalSettings
