import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as GuidebookIcon } from '../assets/images/icons/guidebook.svg'
import { ReactComponent as LaunchIcon } from '../assets/images/icons/launch.svg'
import { ReactComponent as SendIcon } from '../assets/images/icons/send.svg'
import { ReactComponent as PreviewIcon } from '../assets/images/icons/preview.svg'
import { ReactComponent as LinkIcon } from '../assets/images/icons/link.svg'
import { ReactComponent as HistoryIcon } from '../assets/images/icons/ic_activity.svg'

import ChartIcon from '../assets/images/icons/png/chart.png'

export interface QuickActionObj {
  text: string
  icon?: string
  to?: string
  onClick?(): void
  isDisabledText?: boolean
}

export interface QuickActionLinksProps {
  customQuickAction?: QuickActionObj[]
  launchAction?(): void
  sendAction?(): void
  guideBookAction?(): void
  previewAction?(): void
  portfolioAccountingAction?(): void
  platformExplorerAction?(): void
  portfolioBuilderAction?(): void
  historyAction?(): void
}

export class QuickActionLinks extends Component<QuickActionLinksProps> {
  public renderIcon = (action: QuickActionObj) => {
    return (
      <span className='quick-action-links__item-img-w'>
        <img src={action.icon} alt='' role='presentation' />
      </span>
    )
  }

  public customQuickAction = () => {
    return this.props?.customQuickAction?.map((action, index) => {
      if (!action.to) {
        return (
          <div
            key={index}
            onClick={action.onClick}
            className='quick-action-links__item'>
            {action.icon ? this.renderIcon(action) : null}
            <span
              className={`quick-action-links__item-label${
                action.isDisabledText ? '--disabled' : ''
              }`}>
              {action.text}
            </span>
          </div>
        )
      } else {
        return (
          <Link key={index} to={action.to} className='quick-action-links__item'>
            {action.icon ? this.renderIcon(action) : null}
            <span
              className={`quick-action-links__item-label${
                action.isDisabledText ? '--disabled' : ''
              }`}>
              {action.text}
            </span>
          </Link>
        )
      }
    })
  }

  public historyAction = () => {
    return (
      <div
        onClick={this.props.historyAction}
        className='quick-action-links__item'>
        <HistoryIcon />
        <span className='quick-action-links__item-label'>History</span>
      </div>
    )
  }

  public guidebook = () => {
    return (
      <div
        onClick={this.props.guideBookAction}
        className='quick-action-links__item'>
        <GuidebookIcon />
        <span className='quick-action-links__item-label'>
          Guidebook Preview
        </span>
      </div>
    )
  }

  public launch = () => {
    return (
      <div
        onClick={this.props.launchAction}
        className='quick-action-links__item'>
        <LaunchIcon />
        <span className='quick-action-links__item-label'>Launch</span>
      </div>
    )
  }

  public send = () => {
    return (
      <div onClick={this.props.sendAction} className='quick-action-links__item'>
        <SendIcon />
        <span className='quick-action-links__item-label'>Send</span>
      </div>
    )
  }

  public preview = () => {
    return (
      <div
        onClick={this.props.previewAction}
        className='quick-action-links__item'>
        <PreviewIcon />
        <span className='quick-action-links__item-label'>Preview</span>
      </div>
    )
  }

  public portfolioAccounting = () => {
    return (
      <div
        onClick={this.props.portfolioAccountingAction}
        className='quick-action-links__item'>
        <LinkIcon />
        <span className='quick-action-links__item-label'>
          Portfolio Accounting
        </span>
      </div>
    )
  }

  public platformExplorer = () => {
    return (
      <div
        onClick={this.props.platformExplorerAction}
        className='quick-action-links__item'>
        <LaunchIcon />
        <span className='quick-action-links__item-label'>
          Platform Explorer
        </span>
      </div>
    )
  }

  public portfolioBuilder = () => {
    return (
      <div
        onClick={this.props.portfolioBuilderAction}
        className='quick-action-links__item'>
        <span className='quick-action-links__item-img-w'>
          <img src={ChartIcon} alt='' role='presentation' />
        </span>
        <span className='quick-action-links__item-label'>
          Portfolio Builder
        </span>
      </div>
    )
  }

  public render() {
    return (
      <div className='quick-action-links'>
        {this.props.historyAction ? this.historyAction() : null}
        {this.props.guideBookAction ? this.guidebook() : null}
        {this.props.launchAction ? this.launch() : null}
        {this.props.sendAction ? this.send() : null}
        {this.props.previewAction ? this.preview() : null}
        {this.props.portfolioAccountingAction
          ? this.portfolioAccounting()
          : null}
        {this.props.platformExplorerAction ? this.platformExplorer() : null}
        {this.props.portfolioBuilderAction ? this.portfolioBuilder() : null}
        {this.props.customQuickAction ? this.customQuickAction() : null}
      </div>
    )
  }
}

export default QuickActionLinks
