import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { PssSummary } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
export const FETCH_PSS_SUMMARY = 'FETCH_PSS_SUMMARY'
export const FETCH_PSS_SUMMARY_PENDING = 'FETCH_PSS_SUMMARY_PENDING'
export const FETCH_PSS_SUMMARY_FULFILLED = 'FETCH_PSS_SUMMARY_FULFILLED'
export const FETCH_PSS_SUMMARY_REJECTED = 'FETCH_PSS_SUMMARY_REJECTED'

interface PssSummaryResponse extends PssSummary.Response {
  householdFinId: string
}

export const getPssSummary = (
  householdFinId: string
): { type: string; payload: Promise<AxiosResponse<PssSummaryResponse>> } => {
  return {
    type: FETCH_PSS_SUMMARY,
    payload: api()
      .get(`/households/${householdFinId}/psssummary`)
      .then((response: AxiosResponse<PssSummaryResponse>) => {
        if (typeof response.data === 'object')
          response.data.householdFinId = householdFinId
        return response
      })
  }
}
