import React, { ChangeEvent } from 'react'
import { ReactComponent as SearchIcon } from '../assets/images/icons/search.svg'

interface SearchProps {
  value?: string
  className?: string
  placeholder?: string
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

class Search extends React.Component<SearchProps> {
  public render() {
    const { onChange, className } = this.props
    const placeholder = this.props.placeholder
      ? this.props.placeholder
      : 'Search'
    return (
      <div className={`search__w ${className}`}>
        <SearchIcon className='search__icon' />
        <input
          value={this.props.value}
          placeholder={placeholder}
          className='search__input'
          onChange={onChange}
        />
      </div>
    )
  }
}

export default Search
