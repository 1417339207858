import React from 'react'
import { DropTarget } from 'react-dnd'
import { Dispatch, connect } from 'react-redux'
import { withRouter } from 'react-router'

import { dollarFormat, numberFormat } from '../../../helpers'
import { calcProposedTotal } from '../../../helpers/portfolioBuilder'
import {
  PortfolioBuilderAccountObj,
  PortfolioBuilderObj
} from '../../../../objects/portfolioBuilder'
import {
  StrategiesInterface,
  accountType,
  StrategiesObj
} from '../../../../objects/strategies'
import {
  updatePortfolioBuilderAccount,
  getPortfolioBuilderAccounts
} from '../../../../actions/portfolioBuilder'
import { AccountMapObj } from '../../../../reducers/accountMap'
import { GlobalState } from '../../../../reducers'
import Input from '../../../components/Input'
import Tooltip from '../../../components/tooltip'

import strategy_icon from '../../../assets/images/icons/ic_strategy.png'
import ic_close from '../../../assets/images/icons/ic_close.png'
import view_performance from '../../../assets/images/icons/view_performance.svg'
import view_cost from '../../../assets/images/icons/view_cost.svg'
import view_tax from '../../../assets/images/icons/view_tax.svg'
import view_protection from '../../../assets/images/icons/view_protection.svg'
import combineIcon from '../../../assets/images/icons/ic_combine.png'
import blueCombineIcon from '../../../assets/images/icons/ic_unsplit_blue.png'
import warning_icon from '../../../assets/images/icons/warning_icon.png'
import AlternativeStrategyModal from '../../../components/alternativeStrategyModal'

interface StratProps {
  currentAccounts: any
  errStatus?: boolean
  strategies: StrategiesInterface
  dispatch?: Dispatch<GlobalState>
  stratCount: number
  totalStratCount: number
  connectDropTarget?: any
  isOver?: boolean
  canDrop?: boolean
  index: number
  householdFinId: string
  proposedValue: AccountMapObj
  proposedAccount: PortfolioBuilderAccountObj
  portfolioBuilderData: PortfolioBuilderObj
  showStrat: boolean
  strategy: string
  portfolioBuilderId: string
  regType: string
  value: number
  investmentMinimum: number
  splitStatus: boolean
  onStratSelect(errStatus: boolean): void
  handleAccountIconClick(
    selectedAccount: PortfolioBuilderAccountObj,
    type: string
  ): () => void
  restrictedFromQualifiedAccounts: boolean
}

interface StratState {
  id: string
  propAmount: number
  propPercentage: number
  errStatus: boolean
  currentAcctTotal: number
  showAltStrategyModal: boolean
}

const dropStrategy = {
  canDrop(props: StratProps, monitor: any) {
    return true
  },
  drop(props: StratProps, monitor: any, component: AccStrategy) {
    const { id } = monitor.getItem()
    const {
      householdFinId,
      portfolioBuilderId,
      proposedAccount,
      currentAccounts,
      dispatch,
      value
    } = props
    const { proposedAccounts } = props.portfolioBuilderData
    const strategy: StrategiesObj = props.strategies[id]

    component.setState({ id })
    const promiseCollection: Promise<boolean>[] = []
    const propPercentage = 100
    // show the alt warning modal for GS alternative products
    if (strategy && strategy.altWarning === true) {
      component.setState({ showAltStrategyModal: true })
    }

    // Combined Accounts
    if (currentAccounts.length > 1) {
      currentAccounts.map((currAcct: PortfolioBuilderAccountObj) => {
        const hdnId = Object.keys(proposedAccounts).find((id: string) => {
          return (
            proposedAccounts[id].unsplitId === proposedAccount.unsplitId &&
            currAcct.clientAccount === proposedAccounts[id].clientAccount
          )
        })
        promiseCollection.push(
          new Promise(async (resolve) => {
            if (currAcct.accountValue > 0) {
              const newProposed: PortfolioBuilderAccountObj =
                proposedAccounts[hdnId]
              if (newProposed) {
                newProposed.strategy = id
                newProposed.strategyFee = strategy.fees
                newProposed.yield = strategy.yield
                newProposed.currentCashValue =
                  (strategy.cash * currAcct.accountValue) / 100
                newProposed.currentEquityValue =
                  (strategy.equity * currAcct.accountValue) / 100
                newProposed.currentFixedValue =
                  (strategy.fixed * currAcct.accountValue) / 100
                newProposed.currentOtherValue =
                  (strategy.alt * currAcct.accountValue) / 100
                newProposed.currentUnclassifiedValue = 0
                await props.dispatch(
                  updatePortfolioBuilderAccount(
                    props.householdFinId,
                    props.portfolioBuilderId,
                    newProposed.id,
                    newProposed
                  )
                )
              }
            }

            resolve(true)
          })
        )
      })
      // Split Or Single Accounts
    } else {
      const newProposed = { ...proposedAccount }
      newProposed.strategy = id
      newProposed.strategyFee = strategy.fees
      newProposed.yield = strategy.yield
      newProposed.accountValue = value
      if (props.splitStatus) {
        newProposed.currentCashValue = (strategy.cash * value) / 100
        newProposed.currentEquityValue = (strategy.equity * value) / 100
        newProposed.currentFixedValue = (strategy.fixed * value) / 100
        newProposed.currentOtherValue = (strategy.alt * value) / 100
        newProposed.currentUnclassifiedValue = 0
      } else {
        newProposed.currentCashValue = (strategy.cash / 100) * value
        newProposed.currentEquityValue = (strategy.equity / 100) * value
        newProposed.currentFixedValue = (strategy.fixed / 100) * value
        newProposed.currentOtherValue = (strategy.alt / 100) * value
        newProposed.currentUnclassifiedValue = 0
      }
      promiseCollection.push(
        new Promise(async (resolve) => {
          await dispatch(
            updatePortfolioBuilderAccount(
              householdFinId,
              portfolioBuilderId,
              newProposed.id,
              newProposed
            )
          )
          resolve(true)
        })
      )
    }
    Promise.all(promiseCollection).then(async () => {
      await dispatch(
        getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
      )
      currentAccounts.length === 1 &&
        component.setState({ propAmount: value, propPercentage })
    })
    props.onStratSelect(value < strategy.investmentMinimum)
  }
}

const collect = (connectDrop: any, monitor: any) => {
  return {
    connectDropTarget: connectDrop.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  }
}

class AccStrategy extends React.Component<StratProps, StratState> {
  constructor(props: StratProps) {
    super(props)
    this.state = {
      id: null,
      propPercentage: 0,
      propAmount: 0,
      errStatus: false,
      currentAcctTotal: null,
      showAltStrategyModal: null
    }
  }

  public componentDidMount() {
    const { currentAccounts, proposedAccount } = this.props
    const { proposedAccounts } = this.props.portfolioBuilderData
    let propAmount = 0
    let propPercentage = 0
    let currentAcctTotal = 0
    if (currentAccounts.length) {
      propAmount = calcProposedTotal(
        currentAccounts,
        proposedAccounts,
        proposedAccount.unsplitId
      )
      currentAcctTotal = currentAccounts.reduce(
        (sum: number, data: PortfolioBuilderAccountObj) => {
          return (sum += data.accountValue)
        },
        0
      )
      propPercentage = parseFloat(
        ((propAmount / currentAcctTotal) * 100).toFixed(2)
      )
    } else {
      if (proposedAccount) {
        propAmount = proposedAccount.accountValue
        currentAcctTotal = currentAccounts.accountValue
        propPercentage = (propAmount / currentAcctTotal) * 100
      }
    }
    this.verifyStrategyMin(propAmount)
    this.setState({ propAmount, propPercentage, currentAcctTotal })
  }

  public componentDidUpdate(prevProps: StratProps) {
    const { showStrat, currentAccounts, proposedAccount } = this.props
    if (prevProps.showStrat !== showStrat) {
      this.verifyStrategyMin(this.state.propAmount)
    }
    if (
      prevProps.proposedAccount.accountValue !== proposedAccount.accountValue
    ) {
      const { proposedAccounts } = this.props.portfolioBuilderData
      let propAmount = 0
      let propPercentage = 0
      let currentAcctTotal = 0
      if (currentAccounts.length) {
        propAmount = calcProposedTotal(
          currentAccounts,
          proposedAccounts,
          proposedAccount.unsplitId
        )
        currentAcctTotal = currentAccounts.reduce(
          (sum: number, data: PortfolioBuilderAccountObj) => {
            return (sum += data.accountValue)
          },
          0
        )
        propPercentage = parseFloat(
          ((propAmount / currentAcctTotal) * 100).toFixed(2)
        )
      } else {
        propAmount = proposedAccount.accountValue
        currentAcctTotal = currentAccounts.accountValue
        propPercentage =
          parseFloat(((propAmount / currentAcctTotal) * 100).toFixed(2)) || 0
      }
      this.verifyStrategyMin(propAmount)
      this.setState({
        propAmount,
        propPercentage,
        currentAcctTotal
      })
    }
  }

  public verifyStrategyMin = (propAmount: number) => {
    const { strategy, strategies, onStratSelect } = this.props
    const stratMin =
      strategies &&
      strategies[strategy] &&
      strategies[strategy].investmentMinimum
        ? strategies[strategy].investmentMinimum
        : null
    const errStatus = stratMin && propAmount < stratMin
    this.setState({ errStatus })
    onStratSelect(errStatus)
  }

  public onIconEnter = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    return (e.currentTarget.src = blueCombineIcon)
  }

  public onIconLeave = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    return (e.currentTarget.src = combineIcon)
  }

  public setPropValue = (input: number) => {
    const { strategies, strategy } = this.props
    const accountStrategy = strategies[strategy]
    const { value } = accountStrategy
    if (isNaN(input)) {
      input = 0
    }
    this.setState({ propAmount: input })
    if (input < value) {
      this.setState({ errStatus: true })
    } else {
      this.setState({ errStatus: false })
    }
  }

  public setPercentageValue = (input: number) => {
    const { strategies, strategy } = this.props
    const accountStrategy = strategies[strategy]
    const { value } = accountStrategy
    input = isNaN(input) ? null : input
    if (input <= 100) {
      this.setState({
        propPercentage: input
      })
      if ((value * input) / 100 < value) {
        this.setState({ errStatus: true })
      } else {
        this.setState({ errStatus: false })
      }
    }
  }

  public handleChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const input = event.currentTarget.value
    const name = event.currentTarget.name
    if (name === 'propAmount') {
      this.setPropValue(parseInt(input.replace(/,/g, ''), 0))
    } else if (name === 'propPercentage') {
      this.setPercentageValue(parseFloat(input))
    }
  }

  public normalText = () => {
    return (
      <div>
        <span>Drag and drop a strategy here</span>
      </div>
    )
  }

  public hoverText = () => {
    return (
      <div>
        <span>Drop Strategy</span>
      </div>
    )
  }

  public unavailableStratText = () => {
    return (
      <div>
        <span>
          The strategy that was assigned is not recommended for this household.
          Please consider new strategy or change optionality settings
        </span>
      </div>
    )
  }
  public defaultStrat = () => {
    const {
      isOver,
      currentAccounts,
      proposedAccount,
      handleAccountIconClick
    } = this.props

    if (!isOver) {
      if (proposedAccount.unavailableStrategy) {
        return (
          <div className='client-accounts__strategy client-accounts__strategy-unavailable'>
            <div onClick={this.removeStrategy} className='strategy__data-image'>
              <img src={ic_close} alt='no-image' />
            </div>
            {this.unavailableStratText()}
          </div>
        )
      }
      return (
        <div className='client-accounts__strategy client-accounts__strategy-default'>
          <div className='client-accounts__strategy-icon'>
            <Tooltip position='top' width={80} message='Un-Split'>
              <img
                src={combineIcon}
                width={20}
                height={20}
                onMouseOver={this.onIconEnter}
                onMouseOut={this.onIconLeave}
                alt='split'
                onClick={handleAccountIconClick(currentAccounts, 'unSplit')}
              />
            </Tooltip>
          </div>
          <div>
            <img src={strategy_icon} alt='no-image' />
          </div>
          {this.normalText()}
        </div>
      )
    } else {
      return (
        <div className='client-accounts__strategy client-accounts__strategy-default client-accounts__strategy-over'>
          {this.hoverText()}
        </div>
      )
    }
  }

  public getIcon = (value: string) => {
    if (value === 'view_protection') {
      return 'protection'
    } else if (value === 'view_performance') {
      return 'performance'
    } else if (value === 'view_tax') {
      return 'tax'
    } else if (value === 'view_cost') {
      return 'cost'
    }
    return null
  }

  public removeStrategy = async () => {
    const { householdFinId, portfolioBuilderId } = this.props
    const { proposedAccounts } = this.props.portfolioBuilderData
    const {
      proposedAccount: { unsplitId }
    } = this.props
    Object.keys(proposedAccounts)
      .filter((key: string) => {
        return proposedAccounts[key].unsplitId === unsplitId
      })
      .forEach(async (key1: string) => {
        const newProposed: PortfolioBuilderAccountObj = proposedAccounts[key1]
        newProposed.strategy = null
        newProposed.strategyFee = 0
        newProposed.yield = 0
        newProposed.currentCashValue = 0
        newProposed.currentEquityValue = 0
        newProposed.currentFixedValue = 0
        newProposed.currentOtherValue = 0
        newProposed.currentUnclassifiedValue = 0
        newProposed.accountValue = 0
        newProposed.unavailableStrategy = null
        await this.props.dispatch(
          updatePortfolioBuilderAccount(
            householdFinId,
            portfolioBuilderId,
            newProposed.id,
            newProposed
          )
        )
      })
  }

  public gettype = (value: string) => {
    if (value === accountType.protection) {
      return view_protection
    } else if (value === accountType.performance) {
      return view_performance
    } else if (value === accountType.taxMinimization) {
      return view_tax
    } else if (value === accountType.lowCostTracking) {
      return view_cost
    } else {
      return null
    }
  }

  public splitPush = async (amount: number) => {
    const {
      dispatch,
      proposedAccount,
      householdFinId,
      portfolioBuilderId
    } = this.props
    const newProposed = { ...proposedAccount }
    const getValue = (val: number) =>
      (((val / proposedAccount?.accountValue) * 100) / 100) * amount
    newProposed.currentCashValue = getValue(proposedAccount?.currentCashValue)
    newProposed.currentEquityValue = getValue(
      proposedAccount?.currentEquityValue
    )
    newProposed.currentFixedValue = getValue(proposedAccount?.currentFixedValue)
    newProposed.currentOtherValue = getValue(proposedAccount?.currentOtherValue)
    newProposed.currentUnclassifiedValue = getValue(
      proposedAccount?.currentUnclassifiedValue
    )
    newProposed.accountValue = amount
    await dispatch(
      updatePortfolioBuilderAccount(
        householdFinId,
        portfolioBuilderId,
        newProposed.id,
        newProposed
      )
    )
    dispatch(getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId))
  }

  public combinePush = (amount: number, unsplitId: string) => {
    const { householdFinId, portfolioBuilderId, currentAccounts } = this.props
    const { proposedAccounts } = this.props.portfolioBuilderData
    const partialTotal = amount / (currentAccounts && currentAccounts.length)
    const promiseCollection: Promise<boolean>[] = []
    currentAccounts.map((key: PortfolioBuilderAccountObj, i: number) => {
      promiseCollection.push(
        new Promise(async (resolve) => {
          let newProposed: PortfolioBuilderAccountObj
          Object.keys(proposedAccounts)
            .filter((key1: string) => {
              return (
                key.clientAccount === proposedAccounts[key1].clientAccount &&
                unsplitId === proposedAccounts[key1].unsplitId
              )
            })
            .forEach((key1: string) => {
              newProposed = Object.assign({}, proposedAccounts[key1])
            })
          newProposed.currentCashValue =
            (((newProposed.currentCashValue / newProposed.accountValue) * 100) /
              100) *
            partialTotal
          newProposed.currentEquityValue =
            (((newProposed.currentEquityValue / newProposed.accountValue) *
              100) /
              100) *
            partialTotal
          newProposed.currentFixedValue =
            (((newProposed.currentFixedValue / newProposed.accountValue) *
              100) /
              100) *
            partialTotal
          newProposed.currentOtherValue =
            (((newProposed.currentOtherValue / newProposed.accountValue) *
              100) /
              100) *
            partialTotal
          newProposed.currentUnclassifiedValue =
            (((newProposed.currentUnclassifiedValue /
              newProposed.accountValue) *
              100) /
              100) *
            partialTotal
          newProposed.accountValue = partialTotal
          await this.props.dispatch(
            updatePortfolioBuilderAccount(
              householdFinId,
              portfolioBuilderId,
              newProposed.id,
              newProposed
            )
          )
          resolve(true)
        })
      )
    })
    Promise.all(promiseCollection).then((_) => {
      this.props.dispatch(
        getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
      )
    })
  }

  public handleBlur = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget
    const {
      currentAccounts,
      proposedAccount: { unsplitId }
    } = this.props
    const { currentAcctTotal } = this.state
    if (target.name === 'propAmount') {
      const amount = target.value
        ? parseFloat(target.value.replace(/,/g, ''))
        : 0
      if (currentAccounts.length) {
        this.combinePush(amount, unsplitId)
      } else {
        this.splitPush(amount)
      }
      const propPercentage =
        parseFloat(((amount / currentAcctTotal) * 100).toFixed(2)) || 0
      this.setState({ propPercentage })
    } else if (target.name === 'propPercentage') {
      const amount = parseFloat(target.value)
      const totalAmount = (amount / 100) * currentAcctTotal
      if (currentAccounts.length) {
        this.combinePush(totalAmount, unsplitId)
      } else {
        this.splitPush(totalAmount)
      }
      const propAmount = totalAmount
      this.setState({ propAmount })
    }
  }

  public stratData = () => {
    const { propAmount, propPercentage } = this.state
    const { strategies, strategy, regType, stratCount } = this.props
    const errStatus =
      this.props.errStatus && stratCount === 1
        ? this.props.errStatus
        : this.state.errStatus
    if (strategies && strategy && strategies[strategy]) {
      const accountStrategy = strategies[strategy]
      const {
        name,
        investmentMinimum,
        restrictedFromQualifiedAccounts
      } = accountStrategy
      return (
        <div
          style={{
            border: errStatus ? '1px solid #f12938' : '1px solid #d2d2d2'
          }}
          className='client-accounts__strategy client-accounts__strategy-selected'>
          <div onClick={this.removeStrategy} className='strategy__data-image'>
            <img src={ic_close} alt='no-image' />
          </div>
          <div>
            <div className='strategy__data-header'>
              <div
                style={{
                  display: 'flex',
                  padding: '5px 0',
                  alignItems: 'center'
                }}>
                {restrictedFromQualifiedAccounts ? (
                  <Tooltip
                    position='bottom'
                    width={200}
                    message='Not available for any retirement accounts or similar tax-qualified accounts (i.e., IRAs, tax-qualified plans, Keogh plans, ERISA Accounts, Coverdell ESAs)'>
                    <img
                      src={warning_icon}
                      width={25}
                      alt='$'
                      style={{ marginLeft: '6px' }}
                    />
                  </Tooltip>
                ) : null}
                <div className='strategy__data-header-name'>
                  <span> {name}</span>
                </div>
              </div>
              <div className='strategy__data-header-cash__wrapper'>
                <p>{dollarFormat(investmentMinimum, 0)}</p>
                <div className='client-strategies__ratio-wrapper'>
                  <div className='client-strategies__favorite-text-ratio'>
                    Yield {accountStrategy.yield}%
                  </div>
                  <div className='client-strategies__favorite-text-ratio'>
                    Fee {accountStrategy.fees ?? 0}%
                  </div>
                </div>
              </div>
            </div>
            <div className='strategy__data'>
              <div className='strategy__data-reg'>
                <Input
                  title='Registration type'
                  name='regType'
                  inputType='text'
                  controlFunc={this.handleChange}
                  content={regType || ''}
                />
              </div>
              <div className='strategy__data-amount'>
                <Input
                  dollarSign={true}
                  title='Proposed Amount'
                  name='propAmount'
                  inputType='text'
                  controlFunc={this.handleChange}
                  content={propAmount ? numberFormat(propAmount, 0) : ''}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className='strategy__data-perc'>
                <Input
                  percentageSign={true}
                  title='Proposed %'
                  name='propPercentage'
                  inputType='text'
                  controlFunc={this.handleChange}
                  onBlur={this.handleBlur}
                  content={propPercentage ? Math.round(propPercentage) : 0}
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return this.defaultStrat()
    }
  }

  public generateBorderTop = () => {
    const { currentAccounts, stratCount, index, totalStratCount } = this.props
    const len = currentAccounts.length
    let borderClass = ''
    if (totalStratCount > 1 && index > 0 && len < totalStratCount) {
      borderClass = 'border-left'
    }
    if (stratCount === 1) {
      return `${borderClass}  border-bottom`
    } else if (stratCount >= 2 && len >= 2) {
      if (index === 0 || (index >= 1 && index < len)) {
        return ''
      } else return `border-left`
    } else if (stratCount >= 2 && index >= 1) {
      return `border-left`
    } else return ''
  }

  public generateBorderBottom = () => {
    const { currentAccounts, stratCount, index, totalStratCount } = this.props
    const len = currentAccounts.length
    const last = stratCount - 1
    let borderClass = ''
    if (
      totalStratCount > 1 &&
      index < totalStratCount - 1 &&
      len < totalStratCount
    ) {
      borderClass = 'border-left'
    }
    if (stratCount >= 2 && len >= 2) {
      if (index === last || (index <= last && index < len - 1)) {
        return 'border-top'
      } else return `border-top border-left`
    } else if (stratCount >= 2) {
      if (index === last) {
        return `border-top`
      } else return `border-top border-left`
    } else return `${borderClass} `
  }

  public toggleAltStrategyModal = () => {
    this.setState({
      showAltStrategyModal: !this.state.showAltStrategyModal
    })
  }

  public closeOrCancelModal = () => {
    this.removeStrategy()
    this.toggleAltStrategyModal()
  }

  public acknowledgeAltStrategy = () => {
    this.toggleAltStrategyModal()
  }

  public render() {
    const { connectDropTarget, index, showStrat } = this.props

    return connectDropTarget(
      <div key={index} className='client-accounts__strategy-wrapper'>
        <div className='client-accounts__line'>
          <div className={this.generateBorderTop()} />
          <div className={this.generateBorderBottom()} />
        </div>
        {!showStrat ? this.defaultStrat() : this.stratData()}
        {this.state.showAltStrategyModal ? (
          <AlternativeStrategyModal
            closeModal={this.closeOrCancelModal}
            acknowledgeAltStrategy={this.acknowledgeAltStrategy}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    proposedValue: store.accountMap[match.params.householdFinId],
    portfolioBuilderId: match.params.portfolioBuilderId,
    strategies: store.strategies[match.params.householdFinId],
    portfolioBuilderData:
      store.portfolioBuilder[match.params.householdFinId] &&
      store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ]
  }
}

export default withRouter(
  connect(mapStateToProps)(
    DropTarget('item', dropStrategy, collect)(AccStrategy)
  )
)
