import api from '../v3/helpers/api'
import { GoalRequestObj } from '../objects/goalRequests'
export const FETCH_GOAL_REQUESTS = 'FETCH_GOAL_REQUESTS'
export const UPDATE_GOAL_REQUEST = 'UPDATE_GOAL_REQUEST'

export const getGoalRequests = (householdFinId: string) => {
  return {
    type: FETCH_GOAL_REQUESTS,
    payload: api()
      .get(`/households/${householdFinId}/goals/requests`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateGoalRequest = (
  householdFinId: string,
  id: string,
  goalRequest: GoalRequestObj
) => {
  return {
    type: UPDATE_GOAL_REQUEST,
    payload: api()
      .put(`/households/${householdFinId}/goals/requests/${id}`, goalRequest)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}
