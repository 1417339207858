import React, { Component } from 'react'
import { history } from '../../../store'
import Tile from '../../components/layout/tile'
import Heading from './heading'

import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { ReactComponent as HonestConversationsIcon } from '../../assets/images/icons/honestconversations.svg'
import Button from '../../components/button'
import { ContactsInterface } from '../../../objects/contact'

export interface NullHonestConversationsProps {
  contacts: ContactsInterface
  householdFinId: string
}

export class NullHonestConversations extends Component<
  NullHonestConversationsProps
> {
  public goToHcMainPage = () => {
    history.push(`/households/${this.props.householdFinId}/honestConversations`)
  }
  public render() {
    return (
      <Tile
        to={`/households/${this.props.householdFinId}/honestConversations`}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <Heading
            icon={<HonestConversationsIcon />}
            headingText={'HonestConversations®'}
          />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={true}
        anchorBorder={true}>
        <div className='c-dash__row-content filtered__bottom'>
          <div className='c-dash__hc'>
            <div className='c-dash__hc-icon-w' />
          </div>
          <div className='c-dash__hc-score'>
            <div className='c-dash__hc-score-client'>
              <span className='c-dash__hc-score-item'>
                Julian <span>90</span>
              </span>
            </div>
            <div className='c-dash__hc-score-client'>
              <span className='c-dash__hc-score-item'>
                Amy<span>0</span>
              </span>
            </div>
          </div>
        </div>
        <div
          className='c-dash__null--stateBackground'
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <div className='c-dash__null--description'>
            Start HonestConversations® exercise with the client and see results
            here.
          </div>
          <Button primary={true} onClick={this.goToHcMainPage}>
            Start Exercise
          </Button>
        </div>
      </Tile>
    )
  }
}

export default NullHonestConversations
