import React from 'react'
import Modal from '../../components/layout/modal'
import CustomSelect from '../../components/CustomSelect'
import Input from '../../components/Input'
import {
  InsuranceObj,
  insuranceTypes,
  formatTypes,
  premiumFrequencyOptionList,
  InsuranceSelectInterface,
  insuranceTypesOptionList,
  requiredInsuranceFields
} from '../../../objects/insurance'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import * as actions from '../../../actions/insurance'
import { formatCurrencyInput, sanitizeCurrency } from '../../helpers'
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { addToast } from '../../../actions/toasts'

interface AddAccountModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  insurances: InsuranceObj[]
  insurance: InsuranceObj
  closeModal(): void
}

interface AddAccountModalState {
  toggleEnableSaveButton: boolean
  insuranceFieldErrors: string[]
  showAccountDetails: boolean
  insuranceType: string
  id: string
  // input
  typeName: string
  policyNumber: string
  owner: string
  beneficiary: string
  insured: string
  deathBenefit: number
  deathBenefitInput: string
  premiumFrequency: string
  premiumAmount: number
  premiumAmountInput: string
  surrenderPeriod: number | string
  duration: number
  durationInput: string
  monthlyBenefit: number
  monthlyBenefitInput: string
  yearlyBenefit: number
  yearlyBenefitInput: string
  eliminationPeriod: number
  errorLifeInsurance: boolean
  name: string
}

class AddEditAccountModal extends React.Component<
  AddAccountModalProps,
  AddAccountModalState
> {
  constructor(props: any) {
    super(props)

    const { insurance } = this.props
    this.state = {
      insuranceFieldErrors: [],
      toggleEnableSaveButton: false,
      errorLifeInsurance: false,
      showAccountDetails: true,
      insuranceType: '',
      // input state
      id: insurance ? insurance.id : '',
      typeName: insurance ? insurance.recordTypeName : '',
      policyNumber: insurance ? insurance.benefit.policyNumber : '',
      owner: insurance ? insurance.benefit.ownerName : '',
      beneficiary: insurance ? insurance.benefit.beneficiaryName : '',
      insured: insurance ? insurance.insuredName : '',
      deathBenefit: insurance ? insurance.deathBenefit : null,
      deathBenefitInput:
        insurance && insurance.deathBenefit
          ? insurance.deathBenefit.toString()
          : '',
      premiumFrequency:
        insurance &&
        this.isValidOptionValue(
          insurance.benefit.premiumFrequency,
          premiumFrequencyOptionList
        )
          ? insurance.benefit.premiumFrequency
          : 'Monthly',
      premiumAmount: insurance ? insurance.benefit.premiumAmount : null,
      premiumAmountInput:
        insurance &&
        (insurance.benefit.premiumAmount ||
          insurance.benefit.premiumAmount === 0) &&
        // checks if premiumAmount is NaN
        insurance.benefit.premiumAmount === insurance.benefit.premiumAmount
          ? insurance.benefit.premiumAmount.toString()
          : '',
      surrenderPeriod: insurance ? insurance.benefit.surrenderPeriod : null,
      duration: insurance ? insurance.benefit.benefitPeriodYears : null,
      durationInput:
        insurance && insurance.benefit.benefitPeriodYears
          ? insurance.benefit.benefitPeriodYears.toString()
          : '',
      monthlyBenefit: insurance ? insurance.benefit.monthlyBenefitAmount : null,
      monthlyBenefitInput:
        insurance && insurance.benefit.monthlyBenefitAmount
          ? insurance.benefit.monthlyBenefitAmount.toString()
          : '',
      yearlyBenefit: insurance ? insurance.benefit.yearlyBenefit : null,
      yearlyBenefitInput:
        insurance && insurance.benefit.yearlyBenefit
          ? insurance.benefit.yearlyBenefit.toString()
          : '',
      eliminationPeriod: insurance ? insurance.benefit.eliminationPeriod : null,
      name: insurance ? insurance.name : ''
    }
  }

  public handleInputChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget
    const newState = this.state
    const doNewSetState = (): boolean => {
      if (formatTypes.includes(name)) {
        const formatVal = formatCurrencyInput(value)
        const inputKey = name + 'Input'

        newState[inputKey] = formatVal
        newState[name] = sanitizeCurrency(formatVal)
        this.setState(newState)
      } else {
        newState[name] = value
        this.setState(newState)
      }
      return true
    }
    if (doNewSetState()) {
      this.validateInput(event)
    }
  }

  public handleCustomSelectChange = (event: InsuranceSelectInterface) => {
    const { value, fieldName } = event
    const newState = this.state
    newState[fieldName] = value
    this.setState(newState)

    const newEvent = {
      ...event,
      currentTarget: { name: fieldName, value }
    }
    this.validateInput(newEvent)
  }

  public isValidOptionValue = (option: string, optionSet: object) => {
    return this.validOptionValues(optionSet).includes(option)
  }

  public validOptionValues = (optionSet: object) => {
    return Object.keys(optionSet).map((optionKey) => {
      return optionSet[optionKey].value
    })
  }

  public renderTextInputs = (
    type: string,
    name: string,
    placeholder: string,
    value: string | number,
    label: string,
    inputCss?: string
  ) => {
    const { typeName, insuranceFieldErrors } = this.state
    const valueString = () => {
      if (!value || value === 'null') return ''
      if (formatTypes.includes(name)) {
        return formatCurrencyInput(value.toString())
      }
      return value
    }
    const requireFields =
      requiredInsuranceFields[typeName.replace(/\s/g, '').toLocaleLowerCase()]
    const isRequired = requireFields.includes(name)
    const dollarSign = name !== 'duration' && formatTypes.includes(name)
    const placeholderText = placeholder || 'Enter ' + label

    const errorInfo = () => {
      return insuranceFieldErrors.includes(name)
        ? { style: 'form-input-Error', message: 'Enter a ' + label }
        : { style: '', message: '' }
    }

    return (
      <div>
        <Input
          controlFunc={this.handleInputChange}
          onBlur={this.handleInputChange}
          title={label}
          inputType='text'
          name={name}
          mandatory={isRequired}
          classNames={errorInfo().style}
          error={errorInfo().message}
          content={valueString()}
          placeholder={placeholderText}
          dollarSign={dollarSign}
        />
      </div>
    )
  }

  public lifeInsuranceAccount = () => {
    const {
      policyNumber,
      owner,
      beneficiary,
      insured,
      deathBenefitInput,
      premiumAmountInput,
      premiumFrequency,
      name
    } = this.state
    return (
      <div className='insurance-policy-modal__account-details'>
        {this.renderTextInputs(
          'text',
          'policyNumber',
          'Enter policy number',
          policyNumber,
          'Policy Number'
        )}
        {this.renderTextInputs('text', 'owner', 'Enter name', owner, 'Owner')}
        {this.renderTextInputs(
          'text',
          'beneficiary',
          'Enter Name',
          beneficiary,
          'Beneficiary'
        )}
        {this.renderTextInputs(
          'text',
          'insured',
          'Enter Name',
          insured,
          'Insured'
        )}
        {this.renderTextInputs(
          'text',
          'deathBenefit',
          '',
          deathBenefitInput,
          'Death Benefit'
        )}
        <CustomSelect
          classes='insurance-policy-modal__account-details-customselect'
          titleName='Premium Mode'
          contents={premiumFrequency}
          dataList={premiumFrequencyOptionList}
          controlFunc={this.handleCustomSelectChange}
          selectChanged={false}
          inputFieldName='premiumFrequency'
        />
        {this.renderTextInputs(
          'text',
          'premiumAmount',
          '',
          premiumAmountInput,
          'Premium Amount'
        )}
        {this.renderTextInputs(
          'text',
          'name',
          'Enter Company Name',
          name,
          'Company Name'
        )}
      </div>
    )
  }

  public annuitiesAccount = () => {
    const {
      policyNumber,
      owner,
      yearlyBenefitInput,
      surrenderPeriod,
      premiumAmountInput,
      premiumFrequency,
      name
    } = this.state
    return (
      <div className='insurance-policy-modal__account-details'>
        {this.renderTextInputs(
          'text',
          'policyNumber',
          'Enter policy number',
          policyNumber,
          'Policy Number'
        )}
        {this.renderTextInputs('text', 'owner', 'Enter name', owner, 'Owner')}
        {this.renderTextInputs(
          'text',
          'yearlyBenefit',
          '',
          yearlyBenefitInput,
          'Yearly Benefit'
        )}
        {this.renderTextInputs(
          'text',
          'surrenderPeriod',
          'e.g. 5 years',
          surrenderPeriod,
          'Surrender Period'
        )}
        <CustomSelect
          classes='insurance-policy-modal__account-details-customselect'
          titleName='Premium Mode'
          contents={premiumFrequency}
          dataList={premiumFrequencyOptionList}
          controlFunc={this.handleCustomSelectChange}
          selectChanged={false}
          inputFieldName='premiumFrequency'
        />
        {this.renderTextInputs(
          'text',
          'premiumAmount',
          '',
          premiumAmountInput,
          'Premium Amount',
          'insurance-policy-modal__input-money'
        )}
        {this.renderTextInputs(
          'text',
          'name',
          'Enter Company Name',
          name,
          'Company Name'
        )}
      </div>
    )
  }

  public disabilityAccount = () => {
    const {
      policyNumber,
      durationInput,
      insured,
      monthlyBenefitInput,
      eliminationPeriod,
      premiumFrequency,
      premiumAmountInput,
      name
    } = this.state
    return (
      <div className='insurance-policy-modal__account-details'>
        {this.renderTextInputs(
          'text',
          'policyNumber',
          'Enter policy number',
          policyNumber,
          'Policy Number'
        )}
        {this.renderTextInputs(
          'text',
          'insured',
          'Enter Name',
          insured,
          'Insured'
        )}
        {this.renderTextInputs(
          'number',
          'duration',
          'e.g 5',
          durationInput,
          'Duration (Years)'
        )}
        {this.renderTextInputs(
          'text',
          'monthlyBenefit',
          '',
          monthlyBenefitInput,
          'Monthly Benefit'
        )}
        {this.renderTextInputs(
          'text',
          'eliminationPeriod',
          'e.g. 90 days',
          eliminationPeriod,
          'Elimination Period'
        )}
        <CustomSelect
          classes='insurance-policy-modal__account-details-customselect'
          titleName='Premium Mode'
          contents={premiumFrequency}
          dataList={premiumFrequencyOptionList}
          controlFunc={this.handleCustomSelectChange}
          selectChanged={false}
          inputFieldName='premiumFrequency'
        />
        {this.renderTextInputs(
          'text',
          'premiumAmount',
          '',
          premiumAmountInput,
          'Premium Amount'
        )}
        {this.renderTextInputs(
          'text',
          'name',
          'Enter Company Name',
          name,
          'Company Name'
        )}
      </div>
    )
  }

  public longTermAccount = () => {
    const {
      policyNumber,
      insured,
      duration,
      monthlyBenefitInput,
      eliminationPeriod,
      premiumFrequency,
      premiumAmountInput,
      name
    } = this.state
    return (
      <div className='insurance-policy-modal__account-details'>
        {this.renderTextInputs(
          'text',
          'policyNumber',
          'Enter policy number',
          policyNumber,
          'Policy Number'
        )}
        {this.renderTextInputs(
          'text',
          'insured',
          'Enter name',
          insured,
          'Insured'
        )}
        {this.renderTextInputs(
          'number',
          'duration',
          'e.g 5',
          duration,
          'Duration (Years)'
        )}
        {this.renderTextInputs(
          'text',
          'monthlyBenefit',
          '',
          monthlyBenefitInput,
          'Monthly Benefit'
        )}
        {this.renderTextInputs(
          'text',
          'eliminationPeriod',
          'e.g. 90 days',
          eliminationPeriod,
          'Elimination Period'
        )}
        <CustomSelect
          classes='insurance-policy-modal__account-details-customselect'
          titleName='Premium Mode'
          contents={premiumFrequency}
          dataList={premiumFrequencyOptionList}
          controlFunc={this.handleCustomSelectChange}
          selectChanged={false}
          inputFieldName='premiumFrequency'
        />
        {this.renderTextInputs(
          'text',
          'premiumAmount',
          '',
          premiumAmountInput,
          'Premium Amount'
        )}
        {this.renderTextInputs(
          'text',
          'name',
          'Enter Company Name',
          name,
          'Company Name'
        )}
      </div>
    )
  }

  public handleInsuranceType = (event: InsuranceSelectInterface) => {
    const { value } = event
    this.setState({
      insuranceFieldErrors: [],
      typeName: value,
      // reset input state
      policyNumber: '',
      owner: '',
      beneficiary: '',
      insured: '',
      deathBenefit: null,
      premiumFrequency: 'Monthly',
      premiumAmount: null,
      surrenderPeriod: null,
      duration: null,
      monthlyBenefit: null,
      yearlyBenefit: null,
      eliminationPeriod: null,
      name: ''
    })
  }

  public handleInsuranceAccountAction = async () => {
    const { typeName, toggleEnableSaveButton } = this.state
    const { insurance } = this.props
    if (typeName) {
      const errors = this.validateForm()
      this.setState({
        insuranceFieldErrors: errors
      })

      if (toggleEnableSaveButton && errors.length === 0) {
        if (insurance) {
          await this.props.dispatch(
            actions.editInsurance(this.props.householdFinId, this.state)
          )
          this.props.dispatch(
            addToast({
              icon: PlusIcon,
              message: 'Policy Updated.',
              backgroundColor: '#D9F2B6'
            })
          )
        } else {
          await this.props.dispatch(
            actions.addInsurance(this.props.householdFinId, this.state)
          )
          this.props.dispatch(
            addToast({
              icon: PlusIcon,
              message: 'Policy Added.',
              backgroundColor: '#D9F2B6'
            })
          )
        }
        this.props.closeModal()
      } else {
        this.setState({
          errorLifeInsurance: !this.state.errorLifeInsurance,
          toggleEnableSaveButton: false
        })
      }
    }
  }

  public trackPolicyChanges = (): string[] => {
    const { insurance } = this.props
    const { deathBenefit, benefit, insuredName, name } = insurance
    const insuranceProps = { ...benefit, deathBenefit, insuredName, name }

    return Object.keys(insuranceProps).filter((key) => {
      // TODO: extract to a mapping function
      let stateKey: string
      switch (key) {
        case 'beneficiaryName':
          stateKey = 'beneficiary'
          break
        case 'ownerName':
          stateKey = 'owner'
          break
        case 'insuredName':
          stateKey = 'insured'
          break
        case 'benefitPeriodYears':
          stateKey = 'duration'
          break
        case 'monthlyBenefitAmount':
          stateKey = 'monthlyBenefit'
          break
        default:
          stateKey = key
      }
      if (Object.keys(this.state).includes(stateKey)) {
        return this.state[stateKey] !== insuranceProps[key]
      }
      return false
    })
  }

  public validateInput = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | InsuranceSelectInterface
  ) => {
    const { typeName, insuranceFieldErrors } = this.state
    const { insurance } = this.props
    const requireFields =
      requiredInsuranceFields[typeName.replace(/\s/g, '').toLocaleLowerCase()]
    const errors = insuranceFieldErrors
    const { name, value } = event.currentTarget
    const index = errors.indexOf(name)

    let requirementsFilled = []
    let enableSave = false

    // check if form field is a required field
    if (requireFields.includes(name)) {
      if (value.length === 0 && index < 0) {
        errors.push(name)
      }
      if (value.length && index >= 0) {
        errors.splice(index, 1)
      }
      if (errors.length === 0) {
        requirementsFilled = requireFields.filter((fieldName: string) => {
          return this.state[fieldName] || this.state[fieldName] === 0
        })
      }

      if (insurance && insurance.id) {
        enableSave =
          requirementsFilled.length === requireFields.length &&
          this.trackPolicyChanges().length > 0 &&
          errors.length === 0
      } else {
        enableSave =
          requirementsFilled.length === requireFields.length &&
          errors.length === 0
      }

      this.setState({
        insuranceFieldErrors: errors,
        toggleEnableSaveButton: enableSave
      })
    } else {
      // if not required
      if (insurance) {
        // if insurance (editing policy) we validate changes props vs state.
        enableSave = errors.length === 0 && this.trackPolicyChanges().length > 0
        this.setState({
          toggleEnableSaveButton: enableSave
        })
      }
    }
  }

  public validateForm = (): string[] => {
    const { typeName } = this.state
    const requireFields =
      requiredInsuranceFields[typeName.replace(/\s/g, '').toLocaleLowerCase()]
    const errors = requireFields.filter((fieldName: string) => {
      return (
        this.state[fieldName] === null || this.state[fieldName].length === 0
      )
    })

    this.setState({
      insuranceFieldErrors: errors,
      toggleEnableSaveButton: errors.length === 0
    })

    return errors
  }

  public insuranceOptions = (): JSX.Element => {
    const { insurance } = this.props
    const { typeName } = this.state
    if (insurance) {
      return (
        <div className='insurance-policy-modal__account-type-edit'>
          <label>Insurance Type</label>
          <h4 className='insurance-policy-modal__account-type-h'>
            {insurance.recordTypeName}
          </h4>
        </div>
      )
    } else {
      return (
        <div className='insurance-policy-modal__account-type'>
          <CustomSelect
            classes='insurance-policy-modal__account-type-customselect'
            titleName='Insurance Type'
            contents={typeName}
            dataList={insuranceTypesOptionList}
            controlFunc={this.handleInsuranceType}
            selectChanged={false}
            inputFieldName='typeName'
          />
        </div>
      )
    }
  }

  public render() {
    const { closeModal, insurance } = this.props
    const { typeName, toggleEnableSaveButton } = this.state

    let displayTypeName = typeName
    let modalTitle = 'ADD POLICY'
    if (insurance) {
      displayTypeName = insurance.recordTypeName
      modalTitle = 'EDIT POLICY'
    }

    return (
      <Modal icon={FileIcon} title={modalTitle} closeModal={closeModal}>
        <div className='insurance-policy-modal'>
          <div className='insurance-policy-modal__content'>
            {this.insuranceOptions()}
            {displayTypeName === insuranceTypes.lifeInsurance
              ? this.lifeInsuranceAccount()
              : null}
            {displayTypeName === insuranceTypes.annuity
              ? this.annuitiesAccount()
              : null}
            {displayTypeName === insuranceTypes.disability
              ? this.disabilityAccount()
              : null}
            {displayTypeName === insuranceTypes.longTerm
              ? this.longTermAccount()
              : null}
          </div>
          <div className='insurance-policy-modal__buttons'>
            <span className='btn btn__clear' onClick={closeModal}>
              Cancel
            </span>
            <span
              className={
                toggleEnableSaveButton ? 'btn btn__prime' : 'btn btn__default'
              }
              onClick={this.handleInsuranceAccountAction}>
              {insurance ? 'Save' : 'Add'}
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    insurances: store.insurances[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(AddEditAccountModal))
