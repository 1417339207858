import React, { Component } from 'react'
import FinancialCharts from '../../../../components/graphs/financialCharts'
import { dotOvalList } from '../../dotOval'

interface GeographicGraphProps {
  chartOptions: any
  graphColors: string[]
  geographicWeightings: any
}

class GeographicGraph extends Component<GeographicGraphProps> {
  public getValueList = (keyType: string): any[] => {
    const { graphData } = this.props.geographicWeightings
    const { graphColors } = this.props

    return Object.keys(graphData)
      .sort()
      .map((valueItem: any, idx: number) => {
        return keyType === 'percent'
          ? parseFloat(graphData[valueItem])
          : graphColors[idx]
      })
  }

  public render() {
    const { graphData } = this.props.geographicWeightings
    const { graphColors, chartOptions } = this.props
    const graphValues = this.getValueList('percent')
    const graphValuesColors = this.getValueList('colors')

    if (graphValues.length === 0) {
      graphValues.push(100) && graphValuesColors.push('grey')
    } else {
      graphValues.reduce((sum, item) => {
        return sum + item
      })
    }

    const columnOne = dotOvalList(graphColors, graphData)
    const columnTwo =
      columnOne.length > 8 ? columnOne.splice(7, columnOne.length) : null

    return (
      <div className='proposed-portfolio__graph-wrapper'>
        <div className='proposed-portfolio__graph'>
          <div className='proposed-portfolio__graph-relative'>
            <FinancialCharts
              chartData={{
                datasets: [
                  {
                    data: graphValues,
                    backgroundColor: graphValuesColors
                  }
                ]
              }}
              chartOptions={chartOptions}
              dimensions={{ width: 100, height: 100 }}
            />
            <div className='proposed-portfolio__graph-target' />
          </div>
          <div className='proposed-portfolio__graph-data-point'>
            <div className='proposed-portfolio__graph-data-point-flex'>
              <div className='proposed-portfolio__graph-data-point-flex__column'>
                {columnOne}
              </div>
              <div>{columnTwo}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GeographicGraph
