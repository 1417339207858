import React, { Component } from 'react'

import Tile from '../../components/layout/tile'
import Button from '../../components/button'
import Heading from './heading'
import { history } from '../../../store'

import { ReactComponent as TasksIcon } from '../../assets/images/icons/ic_tasks.svg'
import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import CheckIcon from '../../assets/images/icons/png/task_check.png'
import TasksIconPng from '../../assets/images/icons/png/il-tasks.png'

interface NullTaskProps {
  householdFinId: string
}

class NullTask extends Component<NullTaskProps> {
  public nullclientTask = () => {
    const nonCompleteStyle = { backgroundColor: '#d2d2d2' }
    return (
      <div
        className='filtered__bottom'
        style={{
          display: 'flex',
          alignItems: 'left',
          flexDirection: 'column',
          justifyContent: 'left'
        }}>
        <div className='c-dash-task'>
          <div className='c-dash-task__row-completed'>
            <span style={nonCompleteStyle}>
              <img src={CheckIcon} role='presentation' alt='' />
            </span>
          </div>
          <div className='c-dash-task-name__row'>
            <h5 className='c-dash-task__row-name'>
              Make Contributions to 401K
            </h5>
            <div className='c-dash-task__row-w'>
              <div className='c-dash-task__row-date'>Clarence</div>
              <div className='message-thread-list-item__dot'> • </div>
              <div className='c-dash-task__row-date'>9/4/18</div>
              <span className='c-dash-task__row-status c-dash-task__row-status--completed'>
                Completed
              </span>
            </div>
          </div>
        </div>
        <div className='c-dash-task'>
          <div className='c-dash-task__row-completed'>
            <span style={nonCompleteStyle}>
              <img src={CheckIcon} role='presentation' alt='' />
            </span>
          </div>
          <div className='c-dash-task-name__row'>
            <h5 className='c-dash-task__row-name'>
              Meet with John one time per week
            </h5>
            <div className='c-dash-task__row-w'>
              <div className='c-dash-task__row-date'>Clarence</div>
              <div className='message-thread-list-item__dot'> • </div>
              <div className='c-dash-task__row-date'>9/4/18</div>
              <span className='c-dash-task__row-status c-dash-task__row-status--notcompleted'>
                Not Completed
              </span>
            </div>
          </div>
        </div>
        <div className='c-dash-task'>
          <div className='c-dash-task__row-completed'>
            <span style={nonCompleteStyle}>
              <img src={CheckIcon} role='presentation' alt='' />
            </span>
          </div>
          <div className='c-dash-task-name__row'>
            <h5 className='c-dash-task__row-name'>
              Develop a schedule to attend an activity with Kathy
            </h5>
            <div className='c-dash-task__row-w'>
              <div className='c-dash-task__row-date'>Clarence</div>
              <div className='message-thread-list-item__dot'> • </div>
              <div className='c-dash-task__row-date'>9/4/18</div>
              <span className='c-dash-task__row-status c-dash-task__row-status--notcompleted'>
                Not Completed
              </span>
            </div>
          </div>
        </div>
        <div className='c-dash-task'>
          <div className='c-dash-task__row-completed'>
            <span style={nonCompleteStyle}>
              <img src={CheckIcon} role='presentation' alt='' />
            </span>
          </div>
          <div className='c-dash-task-name__row'>
            <h5 className='c-dash-task__row-name'>
              Send Umbrella policy for review
            </h5>
            <div className='c-dash-task__row-w'>
              <div className='c-dash-task__row-date'>Clarence</div>
              <div className='message-thread-list-item__dot'> • </div>
              <div className='c-dash-task__row-date'>9/4/18</div>
              <span className='c-dash-task__row-status c-dash-task__row-status--duesoon'>
                Due Soon
              </span>
            </div>
          </div>
        </div>
        <div className='c-dash-task'>
          <div className='c-dash-task__row-completed'>
            <span style={nonCompleteStyle}>
              <img src={CheckIcon} role='presentation' alt='' />
            </span>
          </div>
          <div className='c-dash-task-name__row'>
            <h5 className='c-dash-task__row-name'>
              Load important documents to our new document vault
            </h5>
            <div className='c-dash-task__row-w'>
              <div className='c-dash-task__row-date'>Clarence</div>
              <div className='message-thread-list-item__dot'> • </div>
              <div className='c-dash-task__row-date'>9/4/18</div>
              <span className='c-dash-task__row-status c-dash-task__row-status--new'>
                New
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  public renderNoTasksView = () => {
    return (
      <div
        className='c-dash__null--stateBackground--largeDivs'
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <img src={TasksIconPng} role='presentation' alt='' />
        <div className='c-dash__null--title'>No tasks yet</div>
        <div className='c-dash__null--description'>
          Click to assign your client their first task.
        </div>
        <Button onClick={this.navigateToTasks} primary={true}>
          Create a Task
        </Button>
      </div>
    )
  }

  public navigateToTasks = () => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/tasks`)
  }

  public render() {
    return (
      <Tile
        to={`/households/${this.props.householdFinId}/tasks`}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <Heading icon={<TasksIcon />} headingText={'CLIENT TASKS'} />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={true}
        anchorBorder={true}>
        <div className='c-dash__row-content c-dash-task-w'>
          {this.nullclientTask()}
        </div>
        {this.renderNoTasksView()}
      </Tile>
    )
  }
}

export default NullTask
