import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as RefreshIcon } from '../../assets/images/icons/refresh.svg'
import Button from '../../components/button'

interface RefreshConfirmationProps {
  closeFunc(): void
  refreshFunc(): void
}

class RefreshConfirmationModal extends React.Component<
  RefreshConfirmationProps
> {
  public refreshFunc = () => {
    this.props.refreshFunc()
  }

  public render() {
    const { closeFunc } = this.props
    return (
      <Modal
        icon={RefreshIcon}
        title='Refresh Financial Plan and Inputs'
        closeModal={closeFunc}>
        <div className='select-planning-software-modal__body'>
          <p className='select-planning-software-modal__text select-planning-software-modal--text-bold'>
            You are about to run and update your client's Financial Plan Score
            and Inputs based on current account values and assumptions in the
            latest plan of record. This will update in AdvisorCenter and in your
            client's GuideCenter automatically. Please note that any changes
            made to goals will be lost- if you would like your changes to be
            saved, please make all changes in the planning software prior to
            refresh.
          </p>

          <p className='select-planning-software-modal__text select-planning-software-modal--text-top-spacing'>
            Would you like to verify your client's account information before
            proceeding?
          </p>
        </div>
        <div className='select-planning-software-modal__btns-w'>
          <Button clear={true} onClick={closeFunc}>
            No, Verify Accounts
          </Button>
          <Button disabled={false} primary={true} onClick={this.refreshFunc}>
            Yes, Update Plan
          </Button>
        </div>
      </Modal>
    )
  }
}

export default RefreshConfirmationModal
