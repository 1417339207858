import React, { Component } from 'react'

import {
  PortfolioBuilderAccountObj,
  PortfolioBuilderAccountInterface
} from '../../../../objects/portfolioBuilder'
import CurrentAccount from './currentAccount'
import AcctNullState from './acctNullState'

interface AccountListProps {
  filteredAccounts: PortfolioBuilderAccountObj[]
  stratDetails: PortfolioBuilderAccountInterface
  onErrorSelect(value: 0 | 1 | 2): void
  handleAccountIconClick(
    selectedAccount: PortfolioBuilderAccountObj,
    type: string
  ): () => void
}

class AccountList extends Component<AccountListProps> {
  public render() {
    const {
      filteredAccounts,
      stratDetails,
      handleAccountIconClick,
      onErrorSelect
    } = this.props
    if (filteredAccounts && filteredAccounts.length > 0) {
      return filteredAccounts.map((key: any, i: number) => {
        if (key) {
          const strategies =
            key.length > 1 && stratDetails && stratDetails[key[i] && key[i].id]
              ? stratDetails[key[i].id]
              : stratDetails && stratDetails[key && key.id]
              ? stratDetails[key.id]
              : null
          const acctKey = key.length > 1 ? key[0].id : key.id
          return (
            <CurrentAccount
              key={acctKey}
              strategies={strategies}
              clientAccount={key}
              onAccountError={onErrorSelect}
              handleAccountIconClick={handleAccountIconClick}
            />
          )
        } else return null
      })
    } else {
      return [1, 2, 3].map((key: number) => {
        return <AcctNullState key={key} />
      })
    }
  }
}

export default AccountList
