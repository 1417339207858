import React, { Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import BackButton from '../../components/backButton'
import Button from '../../components/button'
import { history } from '../../../store'
import AllocationDescription from './allocationDescription'
import * as actions from '../../../actions/investmentViewfinder'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderAllocationInterface,
  InvestmentViewfinderInterface
} from '../../../objects/investmentViewfinder'

interface AllocationProps {
  householdFinId: string
  exerciseId: string
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  allocations: InvestmentViewfinderAllocationInterface
  dispatch: Dispatch<GlobalState>
}

interface AllocationState {
  active: string
  hovering: string
  showDisclosures: boolean
}

class TargetAllocation extends React.Component<
  AllocationProps,
  AllocationState
> {
  constructor(props: AllocationProps) {
    super(props)
    const { exercises, exerciseId, householdFinId } = this.props
    const selectedAllocationName =
      exercises[householdFinId][exerciseId].selectedAllocation
    this.state = {
      active: selectedAllocationName || null,
      hovering: null,
      showDisclosures: false
    }
  }
  public rightHeader = () => {
    const { active } = this.state
    return (
      <Button
        primary={true}
        onClick={this.navigateToResults}
        disabled={!active}>
        Next
      </Button>
    )
  }
  public navigateBack = () => {
    const { exerciseId, householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/geography`
    )
  }
  public navigateToResults = async () => {
    const { active } = this.state
    const { allocations, householdFinId, exerciseId } = this.props
    const activeAllocation = allocations[active]
    await this.props.dispatch(
      actions.updateViewfinderAllocation(
        householdFinId,
        exerciseId,
        active,
        activeAllocation.stocks,
        activeAllocation.bonds
      )
    )
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/results`
    )
  }
  public setActive = (name: string) => {
    this.setState({ active: name })
  }
  public onHover = (name: string) => {
    this.setState({ hovering: name })
  }
  public allocationList = () => {
    const { allocations } = this.props
    const { hovering, active } = this.state
    return Object.keys(allocations).map((allocationName) => {
      const allocation = allocations[allocationName]
      return (
        <AllocationDescription
          key={allocationName}
          name={allocationName}
          allocation={allocation}
          checked={active === allocationName}
          hovering={allocationName === hovering}
          opaque={
            (hovering && allocationName !== hovering) ||
            (active && allocationName !== active)
          }
          onHover={this.onHover}
          onClick={this.setActive}
        />
      )
    })
  }
  public toggleDisclosures = () => {
    this.setState({ showDisclosures: !this.state.showDisclosures })
  }
  public disclosures = () => {
    return (
      <ul className='ivf-target-allocation__disclosure'>
        <li>Spans the time period 12/31/1992 through 03/31/2020.</li>
        <li>
          Past performance doesn't guarantee future results. It is not possible
          to invest directly into an index.
        </li>
        <li>1 Year Max Gain: Highest rolling annual return</li>
        <li>
          Avg Return: Factor-based total return, including both income (in the
          form of dividends or interest payments) and capital gains or losses
          (the increase or decrease in the value of the index)
        </li>
        <li>
          1 Year Max Loss: Lowest rolling annual return Max Drawdown: The peak
          to trough decline during a specific record period of an investment or
          fund. It is usually quoted as the percentage between the peak to the
          trough.
        </li>
        <li>
          Standard Deviation: A statistical measurement of dispersion about an
          average, which, for a portfolio, depicts how widely the returns varied
          over a certain period of time. When a portfolio has a high standard
          deviation, the range of performance is wide. The figure shown is a
          factor-based projection of future standard deviation.
        </li>
        <li>
          Bonds defined as the Bloomberg Barclays U.S. Aggregate Bond Index
        </li>
        <li>Stocks defined as the S&P 500</li>
        <li>All portfolios are rebalanced monthly at zero cost</li>
      </ul>
    )
  }
  public render() {
    const { showDisclosures } = this.state
    return (
      <Fragment>
        <ContentHeader title='Choose Allocation' />
        <Tile
          leftHeader={<BackButton onClick={this.navigateBack} />}
          rightHeader={this.rightHeader()}
          headerBorder={true}
          headerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#FAFAFA'
          }}>
          <div className='ivf-target-allocation-w'>{this.allocationList()}</div>
        </Tile>
        <Tile style={{ marginTop: 10 }}>
          <div
            className='ivf-target-allocation__disclosure-w'
            onClick={this.toggleDisclosures}>
            Historical data spans the time period 12/31/1992 through 03/31/2020.
            Past performance doesn't guarantee future results. It is not
            possible to invest directly into an index.
            <div className='ivf-target-allocation__disclosure-callout'>
              Click for more information and an explanation of the calculation
              methodology.
            </div>
            {showDisclosures ? this.disclosures() : null}
          </div>
        </Tile>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    exercises: store.investmentViewfinder.exercises,
    allocations: store.investmentViewfinder.allocations
  }
}

export default connect(mapStateToProps)(TargetAllocation)
