import React, { Component } from 'react'
import HcCard from './hcCard'
import { CardObj } from '../../../objects/HonestConversations/card'

interface HonestConverstionCardWrapperProps {
  card: CardObj
  displayIndex?: number
}

class HonestConversationCardWrapper extends Component<
  HonestConverstionCardWrapperProps
> {
  public renderNullCard = () => {
    return (
      <div className='hc-new-meeting__card'>
        <div className='hc-new-meeting__card-index'>
          {this.props.displayIndex}
        </div>
        <div className='hc-new-meeting__card-select-text' />
      </div>
    )
  }

  public render() {
    const { card } = this.props
    return card && card.title ? (
      <HcCard {...this.props} />
    ) : (
      this.renderNullCard()
    )
  }
}

export default HonestConversationCardWrapper
