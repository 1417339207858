import React, { Fragment } from 'react'
import Tile from '../../components/layout/tile'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import * as actions from '../../../actions/investmentViewfinder'

import ContentHeader from '../../components/layout/contentHeader'
import BackButton from '../../components/backButton'
import Button from '../../components/button'
import { history } from '../../../store'
import {
  InvestmentViewfinderInterface,
  InvestmentViewfinderPriorityInterface,
  InvestmentViewfinderQuestionObj,
  InvestmentViewfinderAnswerObj
} from '../../../objects/investmentViewfinder'
import { getPriorityChanges, sliderMapValue } from '../../helpers/viewfinder'
import PriorityAnswerItem from './priorityAnswer/priorityAnswerItem'
import PriorityAnswerUpdateModal from './priorityAnswer/priorityAnswerUpdateModal'
import { setActiveInvestmentPriority } from '../../../actions/households'

interface PriorityQuestionsSummaryProps {
  priorities: InvestmentViewfinderPriorityInterface
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  householdFinId: string
  exerciseId: string
  questions: {
    [questionNumber: number]: InvestmentViewfinderQuestionObj
  }
  dispatch: Dispatch<GlobalState>
}

interface PriorityQuestionsSummaryState {
  showUpdateAnswer: boolean
  updateQuestionObj: InvestmentViewfinderQuestionObj
  updateAnswerObj: InvestmentViewfinderAnswerObj
}

class PriorityQuestionSummary extends React.Component<
  PriorityQuestionsSummaryProps,
  PriorityQuestionsSummaryState
> {
  constructor(props: PriorityQuestionsSummaryProps) {
    super(props)
    this.state = {
      showUpdateAnswer: false,
      updateQuestionObj: null,
      updateAnswerObj: null
    }
  }

  public navigation = async (direction: string) => {
    const { householdFinId, exerciseId, questions } = this.props
    const questionNumbers = Object.keys(questions)
    const exercisePath = `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/`
    let path: string = null
    switch (direction) {
      case 'back':
        path = `${exercisePath}${questionNumbers[questionNumbers.length - 1]}`
        break
      case 'next':
        await this.props.dispatch(
          setActiveInvestmentPriority(householdFinId, exerciseId)
        )

        path = `${exercisePath}results`
        break
      default:
        path = null
    }

    path && path.length > 0 && history.push(path)
  }

  public navigateBack = () => {
    this.navigation('back')
  }

  public navigateNext = () => {
    this.navigation('next')
  }

  public middleHeader = () => {
    return (
      <div className='ivf-priorities__middle-header'>
        <div className='ivf-priorities__middle-header-text'>Answer Summary</div>
      </div>
    )
  }

  public rightHeader = () => (
    <Button primary={true} onClick={this.navigateNext}>
      Next
    </Button>
  )

  public getPriorityChange = (
    exercises: InvestmentViewfinderInterface,
    questionNumber: string | number
  ) => {
    const { exerciseId, questions } = this.props
    const exercise = exercises && exercises[exerciseId]
    const answer: InvestmentViewfinderAnswerObj =
      exercise.answers[questions[questionNumber].id]

    return getPriorityChanges(answer)
  }

  // tslint:disable-next-line:max-func-body-length
  public doUpdateAnswer = async (
    newAnswerValues: {
      sliderValue: number
      priorityChange: {
        performance: number
        protection: number
        taxes: number
        cost: number
      }
    },
    questionAnswer: {
      answer: InvestmentViewfinderAnswerObj
      question: InvestmentViewfinderQuestionObj
    }
  ) => {
    const { householdFinId, exerciseId, exercises } = this.props
    const {
      taxes,
      performance,
      protection,
      cost
    } = newAnswerValues.priorityChange

    const answerResponse = sliderMapValue(newAnswerValues.sliderValue)
    const { answer, question } = questionAnswer
    const answerId = answer.id
    const questionId = question.id

    const {
      taxPreference,
      performancePreference,
      protectionPreference,
      costPreference
    } = (() => {
      const changeSum = {
        taxes: 0,
        performance: 0,
        protection: 0,
        cost: 0
      }

      // Total up with the exception of the currently edited question+answer
      for (let i = 1; i <= 6; i += 1) {
        if (i !== question.questionNumber) {
          const priorityChange = this.getPriorityChange(
            exercises[householdFinId],
            i
          )

          changeSum.taxes = changeSum.taxes + Number(priorityChange.taxes)

          changeSum.performance =
            changeSum.performance + Number(priorityChange.performance)

          changeSum.protection =
            changeSum.protection + Number(priorityChange.protection)

          changeSum.cost = changeSum.cost + Number(priorityChange.cost)
        }
      }
      return {
        taxPreference: changeSum.taxes + taxes + 8,
        performancePreference: changeSum.performance + performance + 8,
        protectionPreference: changeSum.protection + protection + 8,
        costPreference: changeSum.cost + cost + 8
      }
    })()

    await this.props.dispatch(
      actions.updateViewfinderAnswer(
        householdFinId,
        exerciseId,
        answerId,
        questionId,
        taxes,
        performance,
        protection,
        cost,
        answerResponse
      )
    )
    await this.props.dispatch(
      actions.updateViewfinderPriority(
        householdFinId,
        exerciseId,
        taxPreference,
        performancePreference,
        protectionPreference,
        costPreference
      )
    )
    if (this.state.showUpdateAnswer) {
      await this.showUpdateAnswerModal(null)
    }
  }

  public showUpdateAnswerModal = async (question?: any) => {
    const { exerciseId, exercises, householdFinId } = this.props
    const answer = question
      ? exercises[householdFinId][exerciseId].answers[question.id]
      : null
    await this.setState({
      updateQuestionObj: question || null,
      updateAnswerObj: answer || null
    })
    this.setState({
      showUpdateAnswer: !this.state.showUpdateAnswer
    })
  }

  public answerDisplay = () => {
    const { questions, exercises, householdFinId, exerciseId } = this.props
    const questionsMap = Object.keys(questions)
    const priorityAnswerList: any[] = []

    const answerToQuestion = (questionId: string) => {
      return exercises[householdFinId][exerciseId].answers[questionId]
    }

    const preferenceAdverb = (adverb: string) => {
      return adverb.toLowerCase() === 'agree' ||
        adverb.toLowerCase() === 'disagree'
        ? 'somewhat'
        : 'strongly'
    }

    // which priority is greater
    const preferencePriority = (
      answer: any,
      priority1: string,
      priority2: string
    ) => {
      const priorityTypes: string[] = [
        'taxes',
        'performance',
        'protection',
        'cost'
      ]

      const priorityValues = answer.value.split(',').map((item: string) => {
        return parseInt(item, 0)
      })

      return priorityValues[priorityTypes.indexOf(priority1)] <
        priorityValues[priorityTypes.indexOf(priority2)]
        ? { prefer: priority2, over: priority1 }
        : { prefer: priority1, over: priority2 }
    }

    questionsMap.map((key, index) => {
      const question: InvestmentViewfinderQuestionObj = questions[key]
      const answer = answerToQuestion(question.id)
      const priorityValue = preferencePriority(
        answer,
        question.priority1,
        question.priority2
      )

      const answerProps = {
        index: index + 1,
        id: question.id,
        questionNumber: key,
        adverb: preferenceAdverb(answer.response),
        prefer: priorityValue.prefer,
        over: priorityValue.over,
        question
      }

      priorityAnswerList.push(
        <PriorityAnswerItem
          key={key}
          {...answerProps}
          updateFunc={this.showUpdateAnswerModal}
        />
      )
    })

    return priorityAnswerList
  }

  public render() {
    return (
      <Fragment>
        <ContentHeader title='Priorities' />
        <Tile
          leftHeader={<BackButton onClick={this.navigateBack} />}
          middleHeader={this.middleHeader()}
          rightHeader={this.rightHeader()}
          headerBorder={true}
          headerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#FAFAFA'
          }}>
          <div className='ivf-container'>
            <div className='ivf-priorities__instructions'>
              <p>
                This is the summary of all the priority choices you have made
                thus far.
              </p>
              <p>
                Review your answers, and modify them if needed before proceeding
                to the next step.
              </p>
            </div>
            <div className='ivf-priorities__question-w'>
              <div className='ivf-summary'>{this.answerDisplay()}</div>
            </div>
          </div>
        </Tile>
        {this.state.showUpdateAnswer ? (
          <PriorityAnswerUpdateModal
            householdFinId={this.props.householdFinId}
            exerciseId={this.props.exerciseId}
            exercises={this.props.exercises}
            updateAnswerFunc={this.doUpdateAnswer}
            closeModal={this.showUpdateAnswerModal}
            answer={this.state.updateAnswerObj}
            question={this.state.updateQuestionObj}
            priorities={this.props.priorities}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    priorities: store.investmentViewfinder.priorities,
    exercises: store.investmentViewfinder.exercises,
    questions: store.investmentViewfinder.questions
  }
}

export default connect(mapStateToProps)(PriorityQuestionSummary)
