import React, { Component } from 'react'

import sortIconIdle from '../../../assets/images/strategySearch/ic-sort.png'
import sortIconDescending from '../../../assets/images/strategySearch/ic-sort-up.png'
import sortIconAscending from '../../../assets/images/strategySearch/ic-sort-down.png'

export interface StrategySortOptionProp {
  id: string
  displayName: string
  className: string
  option: string
  ascending: boolean
  sortFunc(e: React.MouseEvent<HTMLDivElement>): void
}

interface StrategySortOptionState {
  id: string
}

export class StrategySortOption extends Component<
  StrategySortOptionProp,
  StrategySortOptionState
> {
  constructor(props: StrategySortOptionProp) {
    super(props)
    this.state = {
      id: props.id
    }
  }
  public getArrows = () => {
    const { option, ascending } = this.props
    const { id } = this.state
    if (id === option) {
      return ascending ? sortIconAscending : sortIconDescending
    }
    return sortIconIdle
  }

  public render = () => {
    const { displayName, className, sortFunc } = this.props
    const { id } = this.state
    const sortIcon = this.getArrows()

    return (
      <div className={className}>
        <div id={id} onClick={sortFunc}>
          {displayName}
          {this.props.children}
          <img
            src={sortIcon}
            alt='Sort by Strategy Name'
            className='strategy-search__sort-arrow'
          />
        </div>
      </div>
    )
  }
}

export default StrategySortOption
