export const positionsRequestFields = [
  'id',
  'asOfDate',
  'clientAccount',
  'name',
  'price',
  'quantity',
  'value',
  'symbol',
  'securityDescription',
  'securityStyle',
  'allocationOverridden',
  'equityPercent',
  'fixedPercent',
  'cashPercent',
  'altPercent',
  'unclassifiedPercent',
  'totalCost'
]

export const taxStatusList = {
  Taxable: { value: 'Taxable', label: 'Taxable' },
  'Tax-Deferred': { value: 'Tax Deferred', label: 'Tax Deferred' },
  'Tax-Exempt': { value: 'Tax Exempt', label: 'Tax Exempt' }
}

export const accountOwnerList = {
  Primary: { value: 'Primary', label: 'Primary', extValue: '' },
  Secondary: { value: 'Secondary', label: 'Secondary', extValue: '' },
  Joint: { value: 'Joint', label: 'Joint', extValue: '' }
}

export const classificationOptionList = {
  'Cash & Equivalents': {
    value: 'Cash & Equivalents',
    label: 'Cash & Equivalents'
  },
  'Taxable Investments': {
    value: 'Taxable Investments',
    label: 'Taxable Investments'
  },
  'Tax-Deferred Investments': {
    value: 'Tax-Deferred Investments',
    label: 'Tax-Deferred Investments'
  },
  'Tax-Exempt Investments': {
    value: 'Tax-Exempt Investments',
    label: 'Tax-Exempt Investments'
  },
  'Personal Assets': { value: 'Personal Assets', label: 'Personal Assets' },
  'Real Estate': { value: 'Real Estate', label: 'Real Estate' },
  'Business Assets': { value: 'Business Assets', label: 'Business Assets' },
  'Short-Term Liabilities': {
    value: 'Short-Term Liabilities',
    label: 'Short-Term Liabilities'
  },
  'Long-Term Liabilities': {
    value: 'Long-Term Liabilities',
    label: 'Long-Term Liabilities'
  }
}

export const staleBalanceClassifications = [
  'Cash & Equivalents',
  'Taxable Investments',
  'Tax-Deferred Investments',
  'Tax-Exempt Investments'
]

export const investmentDetailStatusList = {
  Open: { value: 'Open', label: 'Open' },
  Closed: { value: 'Closed', label: 'Closed' }
}

export type AllocationKeyType = 'percent' | 'amount' | 'rawAmount'

export const emptyDiscreteAllocation = {
  equity: 0,
  fixed: 0,
  cash: 0,
  alt: 0,
  unclassified: 0
}

export interface DiscreteAllocation {
  equity: number
  fixed: number
  cash: number
  alt: number
  unclassified: number
}

export interface StrategyPosture {
  equity: number
  fixed: number
  alt: number
  cash: number
}

export interface ClientAccountStrategy {
  id: string
  sfId: string
  name: string
  shortName: string
  type: string
  bulletPoint?: string
  factSheetBullets?: string
  disclaimer: string
  attribute: string
  yield: number
  duration: number
  expenseRatio: number
  maturity: number
  equity: number
  cash: number
  fixed: number
  alt: number
  investmentMinimum: number
  taxStatus: string
  createdDate: string
  scores?: {
    tax: number
    cost: number
    performance: number
    protection: number
    global: number
    domestic: number
    stocks: number
    bonds: number
  }
  maxDefensivePosture?: StrategyPosture
  maxOffensivePosture?: StrategyPosture
  costBasis?: number
  positions?: any[]
  restrictedFromQualifiedAccounts: boolean
}

export interface ClientAccountObj {
  id: string
  name: string
  balanceDate: string
  accountNickname: string
  accountNumberFull: string
  totalValue: number
  balance: number
  accountType: string
  registrationType: string
  category: string
  investment: boolean
  includeInGuidebook: boolean
  isDeletedConnected: boolean
  pssSelected: boolean
  sendToPlanningSoftware: boolean
  accountReporting: boolean
  accountNumber: string
  description: string
  date: string
  available: boolean
  managed: boolean
  status: string
  source: string
  providerAccountId: string
  errorCode: string
  errorCodeDescription: string
  classification: string
  type: string
  mgpOwner: string
  costBasis: number
  textCustodian: string
  accountYield: number
  strategyReporting: boolean
  strategyId: string
  strategy?: ClientAccountStrategy
  productName: string
  unclassified: number
  guidebookClassification: string
  taxStatus: string
  lastRunDate: string
  expenseRatio: string
  discreteAllocation: DiscreteAllocation
  allocations: {
    stocks: number
    bonds: number
    other: number
    unclassified: number
  }
  totalValueManual: number
  ownerIds: string[]
  positions: ClientAccountPositionsInterface
  allocationOverridden: boolean
  visibleAccountId: string
  isDeduped?: boolean
}
export interface ClientAccountObjState {
  [key: string]: ClientAccountObj
}
export interface ClientAccountInterface {
  clientAccounts: ClientAccountObjState
  manual?: {
    accountType: string
    source: string
    name: string
    balance: string
  }
  errorMessage?: string
  saved?: boolean
  successMessage?: string
  clientAccountsErrorEmail?: ClientAccountsErrorEmail
  yodleeSyncAttempted?: boolean
}

export const emptyAccount: ClientAccountObj = {
  name: '',
  id: '',
  accountNumberFull: '',
  category: '',
  managed: false,
  isDeletedConnected: false,
  available: false,
  providerAccountId: '',
  errorCode: '',
  errorCodeDescription: '',
  textCustodian: '',
  strategyReporting: null,
  lastRunDate: '',
  strategy: {
    id: '',
    sfId: '',
    name: '',
    shortName: '',
    type: '',
    bulletPoint: '',
    attribute: '',
    yield: 0,
    disclaimer: '',
    duration: 0,
    expenseRatio: 0,
    maturity: 0,
    equity: 0,
    cash: 0,
    fixed: 0,
    alt: 0,
    investmentMinimum: 0,
    taxStatus: '',
    createdDate: '',
    scores: {
      tax: 0,
      cost: 0,
      performance: 0,
      protection: 0,
      global: 0,
      domestic: 0,
      stocks: 0,
      bonds: 0
    },
    restrictedFromQualifiedAccounts: false
  },
  expenseRatio: '',
  accountYield: 0,
  accountNickname: '',
  accountNumber: '',
  totalValue: 0,
  totalValueManual: 0,
  balance: 0,
  balanceDate: '',
  accountType: '',
  status: '',
  pssSelected: false,
  investment: false,
  includeInGuidebook: false,
  sendToPlanningSoftware: false,
  accountReporting: false,
  description: '',
  date: '',
  classification: '',
  type: '',
  registrationType: '',
  costBasis: 0,
  mgpOwner: '',
  strategyId: '',
  productName: '',
  unclassified: 0,
  discreteAllocation: emptyDiscreteAllocation,
  allocations: { stocks: 0, bonds: 0, other: 0, unclassified: 0 },
  guidebookClassification: '',
  taxStatus: '',
  source: 'null',
  ownerIds: [''],
  positions: null,
  allocationOverridden: null,
  visibleAccountId: null
}

export interface CalculatedAllocationAssetObj {
  percent: number
  amount: number
  rawAmount?: number
}

export interface TotalAllocatedCalculated {
  alt: CalculatedAllocationAssetObj
  cash: CalculatedAllocationAssetObj
  equity: CalculatedAllocationAssetObj
  fixed: CalculatedAllocationAssetObj
  unclassified: CalculatedAllocationAssetObj
}

export interface ClientAccountInvestmentDetails {
  productName: string
  strategyId: string
  isDeletedConnected: boolean
  accountYield: number
  expenseRatio: string
  textCustodian: string
  managed: boolean
  source: string
}

export interface ClientAccountPositionsInterface {
  [key: string]: ClientAccountPositionsObj
}

export interface ClientAccountPositionsObj {
  clientAccount: string
  createdDate: string
  asOfDate: string
  id: string
  isSleeve: boolean
  name: string
  price: number
  quantity: number
  rawValue: number
  totalCost: number
  value: number
  altPercent: number
  cashPercent: number
  equityPercent: number
  fixedPercent: number
  unclassifiedPercent: number
  securityDescription: string
  securityId: string
  securityStyle: string
  securityType: string
  tickerSymbol: string
  allocationOverridden: boolean
  symbol?: string
}

export interface PositionAllocationObj {
  equityPercent: number
  fixedPercent: number
  altPercent: number
  cashPercent: number
  unclassifiedPercent: number
}

export interface ClientAccountsErrorEmail {
  to: {
    name: string
    email: string
  }
  from: {
    email: string
    name: string
  }
  bbc: string
  subject: string
  text: string
  html: string
  householdFinId: string
}
export interface NewClientAccountObj {
  accountNickname: string
  accountNumberFull: string
  ownerIds: string
  guidebookClassification: string
  totalValueManual: number
  balanceDate?: string
  accountType?: string
  taxStatus?: string
  type?: string
}
