import uuid from 'uuid'
import { Dispatch } from 'react-redux'
import { GlobalState } from '../reducers'
import { ToastObj } from '../objects/toasts'

export const REMOVE_TOAST: string = 'REMOVE_TOAST'
export const ADD_TOAST: string = 'ADD_TOAST'
export const TOAST_TIMEOUT = 5000

export const removeToast = (id: string) => {
  return {
    type: REMOVE_TOAST,
    payload: id
  }
}

export const addToast = (toast: ToastObj) => (
  dispatch: Dispatch<GlobalState>
) => {
  dispatch(addToastWithTimeout(toast))
}

const addToastWithTimeout = (toast: ToastObj) => (
  dispatch: Dispatch<GlobalState>
) => {
  const id = uuid.v4()
  dispatch(addToastById(id, toast))

  setTimeout(() => {
    dispatch(removeToast(id))
  }, TOAST_TIMEOUT)
}

const addToastById = (id: string, toast: ToastObj) => {
  return {
    type: ADD_TOAST,
    payload: {
      ...toast,
      id
    }
  }
}
