import * as userActions from '../actions/user'
import { logout } from '../helpers'

export const authentication = (store: any) => (next: any) => async (
  action: any
) => {
  const { type, payload } = action

  if (
    type.includes('_REJECTED') &&
    !type.includes(userActions.REDTAIL_LOGIN) &&
    payload
  ) {
    const { response, data } = payload
    const trueStatus =
      (data && data.meta && data.meta.status) || (response && response.status)

    if (trueStatus === 401) {
      logout()
    } else if (trueStatus === 403) {
      store.dispatch(userActions.requestCRMLink())
    }
  }
  return next(action)
}
