import React, { Component } from 'react'

import {
  AssetAllocationGraphData,
  donutColors
} from '../../../../../objects/PortfolioAnalytics/analytics'

interface AnalysisSummaryProps {
  headerName: string
  fields: string[]
  donutData: AssetAllocationGraphData
}

class AllocationSummary extends Component<AnalysisSummaryProps> {
  public setDonutData = (data: AssetAllocationGraphData) => {
    const returnObj: any = {}
    Object.keys(data).forEach((metricName: string) => {
      returnObj[metricName] = Object.keys(data[metricName]).map(
        (fieldName: string) => {
          return {
            value: data[metricName][fieldName],
            fill: donutColors[fieldName].color
          }
        }
      )
    })

    return returnObj
  }

  public setLabelData = (data: AssetAllocationGraphData) => {
    const returnObj: any = {}
    Object.keys(data).forEach((metricName: string) => {
      returnObj[metricName] = Object.keys(data[metricName]).map(
        (fieldName: string) => {
          return {
            displayName: donutColors[fieldName].displayName,
            fill: donutColors[fieldName].color,
            value: data[metricName][fieldName]
          }
        }
      )
    })

    return returnObj
  }

  public renderLegend = () => {
    const { donutData } = this.props
    const metricData = this.setLabelData(donutData)
    const metricLineItems = {}
    Object.keys(metricData).forEach((metricName: string, index: number) => {
      metricData[metricName].forEach(
        (item: { displayName: string; fill: string; value: number }) => {
          const { displayName, fill, value } = item
          if (!metricLineItems[displayName]) {
            metricLineItems[displayName] = { value1: value, fill }
          }
          if (index > 0) {
            metricLineItems[displayName] = {
              ...metricLineItems[displayName],
              value2: value
            }
          }
        }
      )
    })

    return <div></div>
  }

  public renderGraphs = () => {
    const { donutData, fields } = this.props
    const graphData = this.setDonutData(donutData)
    const displayFields = Array.from(fields)
    displayFields.shift()
    const graphs = displayFields.map((fieldName: string, index: number) => {
      const values: number[] = []
      graphData[fieldName].forEach((dataElement: any) => {
        const { value } = dataElement
        values.push(value)
      })

      const nameArray = fieldName.split(' ')
      const multiLineStyle = nameArray.length > 1 && 'im-donut__label-center'
      const topText = nameArray[0]
      const bottomText = !Object.is(nameArray[1], null) && (
        <span>{nameArray[1]}</span>
      )

      return values.reduce((sum: number, num: number) => {
        return sum + num
      }) > 0 ? (
        <div
          key={index}
          className='im-donut__wrapper'
          style={{
            marginLeft: `${
              index + 1 === displayFields.length ? '55px' : '25px'
            }`
          }}>
          <div className={`im-donut__label ${multiLineStyle}`}>
            {topText}
            {bottomText}
          </div>
        </div>
      ) : null
    })

    return graphs.filter((graphItem: any) => !Object.is(graphItem, null))
      .length > 0 ? (
      <div></div>
    ) : null
  }

  public render = () => {
    return <div></div>
  }
}

export default AllocationSummary
