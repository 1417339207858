import React, { FunctionComponent, SVGProps } from 'react'
import * as userActions from '../../../../actions/user'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import ContentHeader from '../../../components/layout/contentHeader'
import { ReactComponent as SalesforceIcon } from '../../../assets/images/logos/salesforce.svg'
import HouseholdSync from './householdsSync'
import InnerContainer from '../../../components/layout/innerContainer'
import Disclosure from '../../../components/layout/disclosure'

interface SalesforceSyncProps {
  dispatch: Dispatch<GlobalState>
  fetchHouseholdsFailed: boolean
  fetchHouseholdsSuccessful: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  iconPng?: string
  crm: string
}

class SalesforceSync extends React.Component<SalesforceSyncProps> {
  public async componentDidMount() {
    await this.props.dispatch(userActions.previewHouseholdSync())
  }
  public componentDidUpdate() {
    if (this.props.fetchHouseholdsFailed) {
      this.props.dispatch(userActions.showLinkCRMModal(true))
    }
  }
  public render() {
    const {
      fetchHouseholdsSuccessful,
      icon,
      iconPng,
      crm,
      fetchHouseholdsFailed
    } = this.props
    const modalIcon = !icon && !iconPng ? SalesforceIcon : icon

    return (
      <div className='sync-screen--w'>
        <InnerContainer>
          <ContentHeader title='Connect Your Clients' Icon={icon} />
          {fetchHouseholdsSuccessful ? (
            <HouseholdSync crm={crm} icon={modalIcon} iconPng={iconPng} />
          ) : fetchHouseholdsFailed ? (
            <div>Unable to Sync Clients</div>
          ) : (
            <div>Loading Clients</div>
          )}
          <Disclosure />
        </InnerContainer>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    fetchHouseholdsSuccessful: store.user.fetchHouseholdsSuccessful,
    fetchHouseholdsFailed: store.user.fetchHouseholdsFailed
  }
}

export default connect(mapStateToProps)(SalesforceSync)
