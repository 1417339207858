import React from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import QuickActionLinks from '../../components/quickActionLinks'
import Tile from '../../components/layout/tile'
import InnerContainer from '../../components/layout/innerContainer'
import BackToLink from '../../components/layout/headerBackToLink'

import { MoneyMindResult } from './result'
import { MoneyMindDetails } from './detail'
import { MoneyMindPlaceholder } from './placeholder'
import PrintMoneyMindModal from './printModal'
import SendMoneyMindModal from './sendModal'
import MoneyMindEmailModal from './emailModal'

import { ContactsInterface, ContactObj } from '../../../objects/contact'
import {
  MoneyMindInterface,
  MoneyMindState,
  MoneyMindObj
} from '../../../objects/moneymind'
import { HouseholdObj } from '../../../objects/household'
import { InstitutionObj } from '../../../objects/institution'
import { UserInterface } from '../../../objects/user'
import * as moneymindActions from '../../../actions/moneymind'
import * as contactActions from '../../../actions/contacts'

import { ReactComponent as MoneyMindIcon } from '../../assets/images/icons/moneymind.svg'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import ActivityIcon from '../../assets/images/icons/png/activity.png'
import PrintIcon from '../../assets/images/icons/png/print.png'
import SendIcon from '../../assets/images/icons/png/send.png'

import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import Button from '../../components/button'
import AddMoneyMind from './add'
import { addToast } from '../../../actions/toasts'

interface MoneyMindIndexProps {
  household: HouseholdObj
  contacts: ContactsInterface
  userId: string
  users: UserInterface
  institution: InstitutionObj
  moneyminds: MoneyMindInterface
  dispatch: Dispatch<GlobalState>
}

interface MoneyMindIndexState {
  showPrintModal: boolean
  showSendModal: boolean
  showEmailModal: boolean
  addMoneyMind: boolean
  emailType: string
}

class MoneyMind extends React.Component<
  MoneyMindIndexProps,
  MoneyMindIndexState
> {
  constructor(props: MoneyMindIndexProps) {
    super(props)
    this.state = {
      showPrintModal: false,
      showSendModal: false,
      showEmailModal: false,
      addMoneyMind: false,
      emailType: ''
    }
  }

  public componentDidMount() {
    // Get Contact and YMM Data here
    const { dispatch } = this.props
    const { id, primaryContact, secondaryContact } = this.props.household
    if (primaryContact) {
      dispatch(moneymindActions.getMoneyMind(primaryContact, id, true))
    }
    if (secondaryContact) {
      dispatch(moneymindActions.getMoneyMind(secondaryContact, id, false))
    }
  }

  public loadMoneyMind = (
    moneyminds: MoneyMindState,
    contact: ContactObj,
    type: string
  ) => {
    if (contact) {
      const moneymind =
        moneyminds && contact.activeYmmId
          ? moneyminds[contact.activeYmmId]
          : null
      if (
        moneymind &&
        (moneymind.protection > 0 ||
          moneymind.commitment > 0 ||
          moneymind.happiness > 0)
      ) {
        return <MoneyMindResult moneymind={moneymind} contact={contact} />
      } else {
        return (
          <MoneyMindPlaceholder
            contact={contact}
            emailModal={this.showEmailView}
            type={type}
          />
        )
      }
    } else {
      return null
    }
  }

  public loadMoneyMindDescription = (
    moneyminds: MoneyMindState,
    contact: ContactObj
  ) => {
    if (contact) {
      const moneymind =
        moneyminds && contact.activeYmmId
          ? moneyminds[contact.activeYmmId]
          : null
      if (
        moneymind &&
        (moneymind.protection > 0 ||
          moneymind.commitment > 0 ||
          moneymind.happiness > 0)
      ) {
        return <MoneyMindDetails moneymind={moneymind} />
      } else {
        return (
          <div style={{ float: 'left', width: '50%', padding: '5px' }}>
            &nbsp;
          </div>
        )
      }
    } else {
      return null
    }
  }

  public showPrintView = () => {
    this.setState({ showPrintModal: true })
  }

  public showSendView = () => {
    this.setState({ showSendModal: true })
  }

  public showEmailView = (emailType: string) => {
    this.setState({ showEmailModal: true, emailType })
  }

  public emailMoneyMind = async (clientId: string, token: string) => {
    const { dispatch } = this.props
    await dispatch(moneymindActions.sendMoneyMindEmail(clientId, token))
    dispatch(contactActions.getContact(clientId))
  }

  public closeView = () => {
    this.setState({
      showPrintModal: false,
      showSendModal: false,
      showEmailModal: false
    })
  }

  public addMoneyMindBtn = () => {
    return (
      <Button primary={true} onClick={this.toggleAddMoneyMind}>
        <PlusIcon />
        <span className='ymm-history__btn--text'>New Record</span>
      </Button>
    )
  }

  public toggleAddMoneyMind = () => {
    this.setState({ addMoneyMind: !this.state.addMoneyMind })
  }

  public addMoneyMind = async (request: any, moneymind: MoneyMindObj) => {
    const { dispatch } = this.props
    const { id, primaryContact, secondaryContact } = this.props.household
    if (primaryContact === request.clientId) {
      await dispatch(
        moneymindActions.addMoneyMind(request, primaryContact, id, true)
      )
      await dispatch(contactActions.getContact(primaryContact))
    }
    if (secondaryContact === request.clientId) {
      await dispatch(
        moneymindActions.addMoneyMind(request, secondaryContact, id, false)
      )
      await dispatch(contactActions.getContact(secondaryContact))
    }
    dispatch(
      addToast({
        icon: MoneyMindIcon,
        message: 'Record added.',
        backgroundColor: '#D9F2B6'
      })
    )
    this.toggleAddMoneyMind()
  }

  public render() {
    const {
      contacts,
      household,
      moneyminds,
      institution,
      users,
      userId
    } = this.props
    const {
      addMoneyMind,
      showPrintModal,
      showSendModal,
      showEmailModal,
      emailType
    } = this.state
    const exerciseQuickActions = [
      {
        text: 'Print',
        icon: PrintIcon,
        onClick: this.showPrintView
      },
      {
        text: 'History',
        icon: ActivityIcon,
        to: `/households/${household.id}/moneyMindHistory`
      },
      {
        text: 'Send',
        icon: SendIcon,
        onClick: this.showSendView
      }
    ]

    if (addMoneyMind) {
      return (
        <InnerContainer>
          <BackToLink onClick={this.toggleAddMoneyMind}>
            Back to MoneyMind&reg;
          </BackToLink>
          <Tile>
            <AddMoneyMind
              moneymind={null}
              contacts={contacts}
              saveFn={this.addMoneyMind}
              cancelFn={this.toggleAddMoneyMind}
              initialSelection={'primary'}
            />
          </Tile>
        </InnerContainer>
      )
    } else {
      return (
        <InnerContainer>
          <ContentHeader
            Icon={MoneyMindIcon}
            title={['MoneyMind', <sup key='reg'>&reg;</sup>]}
            rightHeader={this.addMoneyMindBtn()}
          />
          <Tile
            headerStyle={{ backgroundColor: '#fafafa' }}
            leftHeader={'EXERCISE RESULTS'}
            headerBorder={true}
            rightHeader={
              <QuickActionLinks customQuickAction={exerciseQuickActions} />
            }>
            <div className='moneymind__results-w'>
              <div className='moneymind__results'>
                {contacts &&
                  moneyminds &&
                  this.loadMoneyMind(
                    moneyminds.primary,
                    contacts.primary,
                    'primary'
                  )}
              </div>
              <div className='moneymind__results'>
                {contacts &&
                  moneyminds &&
                  this.loadMoneyMind(
                    moneyminds.secondary,
                    contacts.secondary,
                    'secondary'
                  )}
              </div>
            </div>
          </Tile>
          <div className='moneymind-detail'>
            {contacts &&
              moneyminds &&
              this.loadMoneyMindDescription(
                moneyminds.primary,
                contacts.primary
              )}
            {contacts &&
              moneyminds &&
              this.loadMoneyMindDescription(
                moneyminds.secondary,
                contacts.secondary
              )}
          </div>
          {showPrintModal ? (
            <PrintMoneyMindModal
              contacts={contacts}
              moneyminds={moneyminds}
              closeModal={this.closeView}
            />
          ) : null}
          {showSendModal ? (
            <SendMoneyMindModal
              contacts={contacts}
              closeModal={this.closeView}
              emailModal={this.showEmailView}
            />
          ) : null}
          {showEmailModal && emailType === 'primary' ? (
            <MoneyMindEmailModal
              institution={institution}
              user={users[userId]}
              contact={contacts.primary}
              saveModal={this.emailMoneyMind}
              closeModal={this.closeView}
            />
          ) : null}
          {showEmailModal && emailType === 'secondary' ? (
            <MoneyMindEmailModal
              institution={institution}
              user={users[userId]}
              contact={contacts.secondary}
              saveModal={this.emailMoneyMind}
              closeModal={this.closeView}
            />
          ) : null}
        </InnerContainer>
      )
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  return {
    household: store.households[householdFinId],
    contacts: store.contact[householdFinId],
    userId: store.user.userId,
    users: store.user.users,
    institution: store.institution,
    moneyminds: store.moneymind[householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(MoneyMind))
