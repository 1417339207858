import React, { Fragment } from 'react'
import Checkmark from './checkmark'
import {
  InvestmentViewfinderAllocationObj,
  InvestmentViewfinderAllocationPerformanceMetric
} from '../../../objects/investmentViewfinder'
import AllocationGraph from './allocationGraph'

interface AllocationDescriptionProps {
  name: string
  allocation: InvestmentViewfinderAllocationObj
  checked: boolean
  hovering: boolean
  opaque: boolean
  onHover(name: string): void
  onClick(name: string): void
}

class AllocationDescription extends React.Component<
  AllocationDescriptionProps
> {
  public setHovering = () => {
    const { name, onHover } = this.props
    onHover(name)
  }
  public setNotHovering = () => {
    const { onHover } = this.props
    onHover(null)
  }
  public onClick = () => {
    const { onClick, name } = this.props
    onClick(name)
  }
  public attribute = ({
    name,
    value,
    unit,
    quality
  }: InvestmentViewfinderAllocationPerformanceMetric) => {
    return (
      <div className='ivf-allocation-description__attribute'>
        <div className='ivf-allocation-description__attribute-title'>
          {name}
        </div>
        <div
          className={`ivf-allocation-description__attribute-value ivf-allocation-description__attribute-value--${quality}`}>
          {value}
          {unit}
        </div>
      </div>
    )
  }
  public renderHoverList = () => {
    const { allocation } = this.props
    return (
      <Fragment>
        {this.attribute(allocation.performance['1 Year Max Gain'])}
        {this.attribute(allocation.performance['1 Year Max Loss'])}
        {this.attribute(allocation.performance['Max Drawdown'])}
      </Fragment>
    )
  }
  public render() {
    const { name, allocation, checked, opaque, hovering } = this.props
    const customClass = checked
      ? 'ivf-allocation-description-w--active'
      : hovering
      ? 'ivf-allocation-description-w--hovering'
      : opaque
      ? 'ivf-allocation-description-w--opaque'
      : ''
    return (
      <div
        className={`ivf-allocation-description-w ${customClass}`}
        onClick={this.onClick}
        onMouseEnter={this.setHovering}
        onMouseLeave={this.setNotHovering}>
        <Checkmark active={checked} />
        <AllocationGraph stocks={allocation.stocks} bonds={allocation.bonds} />
        <div className='ivf-allocation-description__title'>{name}</div>
        <div className='ivf-allocation-description__divider' />
        <div className='ivf-allocation-description__attribute-w'>
          {this.attribute(allocation.performance['Avg Return'])}
          {this.attribute(allocation.performance['Std Deviation'])}
          {hovering || checked ? this.renderHoverList() : null}
        </div>
      </div>
    )
  }
}

export default AllocationDescription
