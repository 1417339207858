import React, { Fragment } from 'react'
import InnerContainer from '../../components/layout/innerContainer'
import ContentHeader from '../../components/layout/contentHeader'
import { ReactComponent as GuidebookIcon } from '../../assets/images/icons/guidebook.svg'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import api from '../../helpers/api'

import { withRouter } from 'react-router'
import Button from '../../components/button'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { ContactsInterface } from '../../../objects/contact'
import NullState from '../../components/nullState'
import Tile from '../../components/layout/tile'
import BookIcon from '../../assets/images/icons/png/il-book.png'

import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'
import { ClipLoader } from 'react-spinners'
import { history } from '../../../store'
import { isObjEmpty } from '../../helpers'
import { calculateAssetAllocation } from '../../helpers/netWorth'
import { FinancialGoalsInterface } from '../../../objects/financialGoal'
import { InsuranceInterface } from '../../../objects/insurance'
import { ClientAccountObjState } from '../../../objects/clientAccount'
import { TaskObjState } from '../../../objects/tasks'
import { InvestmentsInterface } from '../../../objects/investment'
import { InvestmentViewfinderInterface } from '../../../objects/investmentViewfinder'
import { InstitutionObj } from '../../../objects/institution'
import { UserObj } from '../../../objects/user'
import { GuidebookObj } from '../../../objects/guidebook'
import { getGuidebooks } from '../../../actions/guidebook'
import * as contactsActions from '../../../actions/contacts'
import { getMoneyMind } from '../../../actions/moneymind'
import GuidebookItem from './guidebookItem'
import { HouseholdObj } from '../../../objects/household'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'
import { FolderInterface } from '../../../objects/folder'
import { getVaultFolderIdByName } from '../../helpers/guidebook'
import { MoneyMindObj } from '../../../objects/moneymind'

interface GuidebookProps {
  dispatch: Dispatch<GlobalState>
  user: UserObj
  contacts: ContactsInterface
  goals: FinancialGoalsInterface
  insurances: InsuranceInterface
  clientAccounts: ClientAccountObjState
  tasks: TaskObjState
  goalsActions: FinancialGoalActionObj[]
  investments: InvestmentsInterface
  investmentViewfinder: InvestmentViewfinderInterface
  household: HouseholdObj
  users: any
  userId: any
  institution: InstitutionObj
  guidebooks: GuidebookObj[]
  honestConversation: HonestConversationsExerciseObj
  folders: FolderInterface
  MoneyMind: MoneyMindObj
  redux: GlobalState
  routerMatch: {}
}

interface GuidebookState {
  isEnabled: boolean
  showMimeoModal: boolean
  logo: string
  vaultFolderId: string
}

class Guidebook extends React.Component<GuidebookProps, GuidebookState> {
  constructor(props: GuidebookProps) {
    super(props)
    this.state = {
      isEnabled: false,
      showMimeoModal: true,
      logo: '',
      vaultFolderId: ''
    }
  }

  public dataCheck = () => {
    const {
      goals,
      clientAccounts,
      insurances,
      tasks,
      goalsActions,
      investmentViewfinder,
      household,
      honestConversation
    } = this.props

    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = household
    const totalTargetAllocation: number =
      targetCashAllocation +
      targetEquityAllocation +
      targetFixedAllocation +
      targetOtherAllocation
    const totalAllocatedObj = clientAccounts
      ? calculateAssetAllocation(clientAccounts)
      : null
    const totalCurrentAllocation: number =
      totalAllocatedObj.alt.percent +
      totalAllocatedObj.cash.percent +
      totalAllocatedObj.equity.percent +
      totalAllocatedObj.fixed.percent +
      totalAllocatedObj.unclassified.percent

    return {
      FinancialGoals: goals && Object.keys(goals).length > 0,
      NetWorth: clientAccounts && Object.keys(clientAccounts).length > 0,
      Insurance: insurances && Object.keys(insurances).length > 0,
      Tasks: tasks && Object.keys(tasks).length > 0,
      Goals: goalsActions.length > 0,
      Investments:
        (clientAccounts && !isObjEmpty(clientAccounts)) ||
        (investmentViewfinder &&
          !isObjEmpty(
            investmentViewfinder[this.props.household.invresponseId]
          )) ||
        totalTargetAllocation ||
        totalCurrentAllocation,
      MoneyMind: true,
      HonestConversations: honestConversation?.id?.length > 0
    }
  }

  public async componentDidMount() {
    // When the page is mounted the files are created on the GBG server
    // and the Create New button shows a loading state.
    this.setState({
      isEnabled: false
    })
    const { household, dispatch } = this.props

    const householdFinId = household.id

    dispatch(getGuidebooks(householdFinId))

    const vaultFolderId = await getVaultFolderIdByName({
      vaultFolderName: 'Guidebooks',
      householdId: householdFinId
    })
    this.setState({ vaultFolderId })

    api()
      .post(`/households/${householdFinId}/guidebookgenerator/html`, {
        householdId: householdFinId
      })
      .then(() => {
        // Once the files are on the GBG server, the Create New
        // button is enabled.
        this.setState({ isEnabled: true })
      })
  }

  public getContactsAndYmm = (
    household: HouseholdObj,
    dispatch: Dispatch<GlobalState>
  ) => {
    if (household?.primaryContact) {
      dispatch(contactsActions.getContact(household.primaryContact))
      dispatch(
        contactsActions.getUsername(household.id, household.primaryContact)
      )
      dispatch(getMoneyMind(household.primaryContact, household.id, true))
    }
    if (household?.secondaryContact) {
      dispatch(contactsActions.getContact(household.secondaryContact))
      dispatch(getMoneyMind(household.secondaryContact, household.id, false))
    }
  }

  public newGuidebook = async () => {
    const { household } = this.props
    history.push(`/households/${household.id}/guideBookPage`)
  }

  public nullState = () => {
    return (
      <NullState
        style={{ padding: '80px 0' }}
        imgSrc={BookIcon}
        heading='No Guidebooks yet'
        text='Create a new Guidebook by clicking on the button in the top right.'
      />
    )
  }

  public addGuidebookBtn = () => {
    const createNew = (
      <Fragment>
        <PlusIcon />
        Create New
      </Fragment>
    )

    const loadingCreateNew = (
      <Fragment>
        <ClipLoader sizeUnit={'px'} size={20} color={'#fff'} loading={true} />
        <span style={{ marginRight: '10px' }} />
        Loading
      </Fragment>
    )

    return (
      <Button
        primary={true}
        onClick={!this.state.isEnabled ? null : this.newGuidebook}
        style={{
          minWidth: '130px',
          pointerEvents: !this.state.isEnabled ? 'none' : 'auto',
          background: !this.state.isEnabled ? 'grey' : '#4a78cf'
        }}>
        {this.state.isEnabled ? createNew : loadingCreateNew}
      </Button>
    )
  }

  public guidebookItems = () => {
    const { contacts, guidebooks, household, dispatch, user } = this.props
    return Object.keys(guidebooks).map((key) => {
      const guidebook = guidebooks[key]
      return (
        <GuidebookItem
          contacts={contacts}
          vaultFolderId={this.state.vaultFolderId}
          user={user}
          guidebook={guidebook}
          householdFinId={household.id}
          dispatch={dispatch}
          key={key}
        />
      )
    })
  }

  public toggleMimeoModal = () => {
    this.setState({ showMimeoModal: !this.state.showMimeoModal })
  }

  public render() {
    return (
      <InnerContainer>
        <ContentHeader
          Icon={GuidebookIcon}
          title='Guidebook'
          rightHeader={this.addGuidebookBtn()}
        />
        <Tile
          leftHeader='GUIDEBOOK VERSIONS'
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          headerBorder={true}>
          {!isObjEmpty(this.props.guidebooks)
            ? this.guidebookItems()
            : this.nullState()}
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  const household: HouseholdObj =
    store.households && store.households[householdFinId]
  return {
    user: store.user.userId ? store.user.users[store.user.userId] : null,
    contacts: store.contact[householdFinId] || {},
    household,
    guidebooks:
      store.guidebook && store.guidebook[householdFinId]
        ? store.guidebook[householdFinId].guidebooks
        : null
  }
}

export default withRouter(connect(mapStateToProps)(Guidebook))
