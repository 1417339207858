import React, { Component, FormEvent, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'

import { dollarFormat } from '../../../helpers'
import { getClientAccounts } from '../../../../actions/clientAccounts'
import { GlobalState } from '../../../../reducers'
import {
  ClientAccountObjState,
  ClientAccountObj
} from '../../../../objects/clientAccount'

import Tile from '../../../components/layout/tile'
import BackToLink from '../../../components/layout/headerBackToLink'
import InnerContainer from '../../../components/layout/innerContainer'
import Button from '../../../components/button'
import Tooltip from '../../../components/tooltip'
import OverrideAllocation from '../editOverrideAllocation'
import AssetAllocation from '../assetAllocation'
import Holdings from './holdings'
import Pagination from './pagination'

import overrideIcon from '../../../assets/images/icons/png/ic_updates.png'

interface PositionsProps {
  dispatch: Dispatch<GlobalState>
  componentReferrer?: string
  clientAccounts: ClientAccountObjState
  clientAccountId: string
  householdFinId: string
}

interface PositionsState {
  showOverrideAllocation: boolean
  currentPage: number
  showAll: boolean
  account: ClientAccountObj
  filteredPositions: any
  resetLabelsFlag: boolean
}
class Positions extends Component<PositionsProps, PositionsState> {
  private PAGINATION_MAX = 28
  constructor(props: PositionsProps) {
    super(props)
    this.state = {
      showOverrideAllocation: false,
      currentPage: 1,
      showAll: false,
      account: null,
      filteredPositions: null,
      resetLabelsFlag: false
    }
  }

  public async componentDidUpdate(prevProps: PositionsProps) {
    const { clientAccounts } = this.props
    if (prevProps.clientAccounts !== clientAccounts) {
      this.renderPositionState()
    }
  }

  public async componentDidMount() {
    const { dispatch, householdFinId } = this.props
    await dispatch(getClientAccounts(householdFinId))
    this.renderPositionState()
  }

  public renderPositionState = () => {
    const { clientAccounts, clientAccountId } = this.props
    if (clientAccounts && clientAccountId) {
      const account: ClientAccountObj =
        Object.values(clientAccounts).filter(
          (acc) => acc.id === clientAccountId
        )[0] || null
      this.setState({ account }, () => {
        this.createFilteredPositions()
      })
    }
  }

  public createFilteredPositions = () => {
    this.setState({ filteredPositions: this.state.account.positions }, () => {
      this.sortByStringField(false, 'tickerSymbol')
    })
  }

  public countPageNumbers = (): number[] => {
    const pageNumbers: number[] = []
    const len: number = Number(this.state.filteredPositions.length)
    for (let i = 1; i <= Math.ceil(len / this.PAGINATION_MAX); i += 1) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }

  public toggleShowAll = (): void => {
    this.setState({ showAll: !this.state.showAll })
  }

  public handlePageArrowRight = (): void => {
    const { currentPage } = this.state
    if (currentPage < this.countPageNumbers().length) {
      this.setState({
        currentPage: currentPage + 1
      })
    }
  }

  public showOverrideAllocation = (): void => {
    this.setState({
      showOverrideAllocation: !this.state.showOverrideAllocation
    })
  }

  public handlePageArrowLeft = (): void => {
    const { currentPage } = this.state
    if (currentPage >= 2) {
      this.setState({
        currentPage: currentPage - 1
      })
    }
  }

  public handlePaginationClick = (event: FormEvent<HTMLElement>): void => {
    this.setState({
      currentPage: Number(event.currentTarget.id)
    })
  }

  public backToLink = (): JSX.Element => {
    const { componentReferrer, clientAccountId } = this.props
    const referrer =
      componentReferrer === 'investments' ? 'investments' : 'networth'
    return (
      <BackToLink
        to={`/households/${this.props.householdFinId}/clientAccount/${clientAccountId}/${referrer}`}>
        Back to Account Details
      </BackToLink>
    )
  }

  public assetAllocationRightHeader = (value: number): JSX.Element => {
    return (
      <div className='client-account-details__right-header'>
        <Button onClick={this.showOverrideAllocation} header={true}>
          Override Allocation
        </Button>
        <span className='client-account-details__right-header--value'>
          {value ? dollarFormat(value, 0) : ' '}
        </span>
      </div>
    )
  }

  public sortByStringField = (sortByDescending: boolean, field: string) => {
    const { filteredPositions, resetLabelsFlag } = this.state
    if (filteredPositions) {
      const sortedPositions: any = Object.keys(filteredPositions)
        .sort((a, b) => {
          const va = !filteredPositions[a][field]
            ? ''
            : String(filteredPositions[a][field])
          const vb = !filteredPositions[b][field]
            ? ''
            : String(filteredPositions[b][field])
          return sortByDescending ? vb.localeCompare(va) : va.localeCompare(vb)
        })
        .map((key) => {
          return filteredPositions[key]
        })
      this.setState({
        filteredPositions: sortedPositions,
        resetLabelsFlag: !resetLabelsFlag
      })
    }
  }

  public sortByNumericField = (sortByDescending: boolean, field: string) => {
    const { filteredPositions, resetLabelsFlag } = this.state
    const sortedPositions: any = Object.keys(filteredPositions)
      .sort((a, b) => {
        const aScore = filteredPositions[a][field] || 0
        const bScore = filteredPositions[b][field] || 0
        return sortByDescending ? bScore - aScore : aScore - bScore
      })
      .map((key) => {
        return filteredPositions[key]
      })
    this.setState({
      filteredPositions: sortedPositions,
      resetLabelsFlag: !resetLabelsFlag
    })
  }

  public sortByAllocation = (sortByDescending: boolean) => {
    const { filteredPositions, resetLabelsFlag } = this.state
    const sortedPositions: any = Object.keys(filteredPositions)
      .sort((a, b) => {
        const {
          equityPercent: equityA,
          fixedPercent: fixedA,
          cashPercent: cashA,
          altPercent: altA,
          unclassifiedPercent: unclassifiedA
        } = filteredPositions[a]
        const {
          equityPercent: equityB,
          fixedPercent: fixedB,
          cashPercent: cashB,
          altPercent: altB,
          unclassifiedPercent: unclassifiedB
        } = filteredPositions[b]
        return sortByDescending
          ? equityB - equityA ||
              fixedB - fixedA ||
              cashB - cashA ||
              altB - altA ||
              unclassifiedB - unclassifiedA
          : equityA - equityB ||
              fixedA - fixedB ||
              cashA - cashB ||
              altA - altB ||
              unclassifiedA - unclassifiedB
      })
      .map((key) => {
        return filteredPositions[key]
      })
    this.setState({
      filteredPositions: sortedPositions,
      resetLabelsFlag: !resetLabelsFlag
    })
  }

  public sortByAOverride = (sortByDescending: boolean) => {
    const { filteredPositions, resetLabelsFlag } = this.state
    const sortedPositions: any = Object.keys(filteredPositions)
      .sort((a, b) => {
        const aVal = filteredPositions[a].allocationOverridden ? 1 : -1
        const bVal = filteredPositions[b].allocationOverridden ? 1 : -1
        return sortByDescending ? bVal - aVal : aVal - bVal
      })
      .map((key) => {
        return filteredPositions[key]
      })
    this.setState({
      filteredPositions: sortedPositions,
      resetLabelsFlag: !resetLabelsFlag
    })
  }

  public renderLeftHeader = (account: ClientAccountObj) => {
    return account && account.allocationOverridden ? (
      <Fragment>
        ASSET ALLOCATION
        <Tooltip
          message='Account allocation has been overridden'
          width={120}
          position='bottom'
          multiLine={true}>
          <img
            src={overrideIcon}
            alt='Override Icon'
            className='inv-accounts__override-icon'
          />
        </Tooltip>
      </Fragment>
    ) : (
      'ASSET ALLOCATION'
    )
  }

  public render(): JSX.Element {
    const {
      showAll,
      resetLabelsFlag,
      filteredPositions,
      account,
      currentPage
    } = this.state
    const { clientAccountId, householdFinId } = this.props
    if (account && filteredPositions) {
      const indexOfLastItem: number = currentPage * this.PAGINATION_MAX
      const indexOfFirstItem: number = indexOfLastItem - this.PAGINATION_MAX
      const finalPositions = showAll
        ? filteredPositions
        : Object.values(filteredPositions).slice(
            indexOfFirstItem,
            indexOfLastItem
          )
      const totalPositionCount: number = Object.keys(filteredPositions).length
      return (
        <InnerContainer>
          {this.backToLink()}
          <Tile
            headerStyle={{ backgroundColor: '#FAFAFA' }}
            leftHeader={this.renderLeftHeader(account)}
            headerBorder={true}
            rightHeader={this.assetAllocationRightHeader(account.totalValue)}>
            <AssetAllocation
              householdFinId={householdFinId}
              clientAccountId={clientAccountId}
              clientAccount={account}
            />
          </Tile>
          <Holdings
            clientAccount={account}
            positions={finalPositions}
            resetLabelsFlag={resetLabelsFlag}
            sortByStringField={this.sortByStringField}
            sortByNumericField={this.sortByNumericField}
            sortByAllocation={this.sortByAllocation}
            sortByAOverride={this.sortByAOverride}
          />
          {this.state.showOverrideAllocation ? (
            <OverrideAllocation
              householdFinId={householdFinId}
              clientAccountId={clientAccountId}
              closeModal={this.showOverrideAllocation}
              clientAccount={account}
              discreteAllocation={account.discreteAllocation}
              showTile={true}
            />
          ) : null}
          <Pagination
            currentPage={this.state.currentPage}
            positions={finalPositions}
            householdFinId={householdFinId}
            handlePaginationClick={this.handlePaginationClick}
            handlePageArrowLeft={this.handlePageArrowLeft}
            handlePageArrowRight={this.handlePageArrowRight}
            countPageNumbers={this.countPageNumbers}
            totalPositionCount={totalPositionCount}
            toggleShowAll={this.toggleShowAll}
            showAll={showAll}
          />
        </InnerContainer>
      )
    } else return null
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    clientAccounts:
      store.clientAccount[match.params.householdFinId] &&
      store.clientAccount[match.params.householdFinId].clientAccounts,
    componentReferrer: match.params.componentReferrer,
    clientAccountId: match.params.clientAccountId,
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(Positions))
