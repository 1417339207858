import React, { Component, CSSProperties } from 'react'
import arrowIcon from '../../assets/images/icons/ic_down.png'

export interface CollapsableTileProps {
  title?: string
  children?: React.ReactNode | string
  style?: CSSProperties
  headerStyle?: CSSProperties
  headerBorder?: boolean
  contentStyle?: CSSProperties
  collapsed?: boolean
}

interface CollapsableTileState {
  collapsed: boolean
}

class CollapsableTile extends Component<
  CollapsableTileProps,
  CollapsableTileState
> {
  constructor(props: CollapsableTileProps) {
    super(props)
    this.state = {
      collapsed: this.props.collapsed ? this.props.collapsed : false
    }
  }

  public toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  public render() {
    const {
      style,
      headerStyle,
      contentStyle,
      headerBorder,
      title,
      children
    } = this.props
    const { collapsed } = this.state
    return (
      <div className='collapsable' style={style}>
        <div
          className='collapsable__header'
          style={headerStyle}
          onClick={this.toggleCollapse}>
          <p className='collapsable__title'>{title}</p>
          <img
            className={
              collapsed
                ? 'collapsable__arrow'
                : 'collapsable__arrow collapsable__arrow--rotate'
            }
            src={arrowIcon}
            alt='arrow'
          />
        </div>
        {!collapsed && (
          <div
            className={headerBorder && 'collapsable__header-border'}
            style={contentStyle}>
            {children}
          </div>
        )}
      </div>
    )
  }
}

export default CollapsableTile
