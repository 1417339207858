import React, { Component, CSSProperties } from 'react'
import { ReactNode } from 'react-redux'
import { HouseholdCardObj } from '../../../../objects/honestConversations'
import { ContactsInterface } from '../../../../objects/contact'

interface CardWrapperProps {
  householdCard: HouseholdCardObj
  contacts?: ContactsInterface
  active: boolean
  primaryScore: number
  secondaryScore: number
  children?: ReactNode | ReactNode[]
  onClick(householdCard: HouseholdCardObj): void
}

const dimension: string = '20px'
const circleStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 10,
  fontWeight: 700,
  width: dimension,
  height: dimension,
  borderRadius: '50%',
  fontSize: '12px',
  top: '108px',
  color: 'white',
  lineHeight: dimension,
  textAlign: 'center',
  marginLeft: '8px',
  background: 'rgba(255,255,255,0.4)'
}

const wrapperStyle: CSSProperties = {
  cursor: 'pointer',
  paddingBottom: '20px',
  transition: 'box-shadow 300ms'
}

class CardWrapper extends Component<CardWrapperProps> {
  constructor(props: CardWrapperProps) {
    super(props)
    this.state = {
      borderBottom: 'white'
    }
  }

  public doClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClick, householdCard } = this.props
    onClick(householdCard)
  }

  public scores = () => {
    const { primaryScore, secondaryScore, contacts } = this.props
    const secondaryScoreStyle = circleStyle
    const primaryScoreStyle =
      contacts && contacts.secondary
        ? { ...circleStyle, marginLeft: '36px' }
        : circleStyle

    return (
      <div>
        <div style={primaryScoreStyle}>{primaryScore || 0}</div>

        {contacts && contacts.secondary && (
          <div style={secondaryScoreStyle}>{secondaryScore || 0}</div>
        )}
      </div>
    )
  }

  public render() {
    const { active } = this.props
    const boxShadow = active
      ? 'inset 0px -3px 0px 0px rgb(0, 104, 174)'
      : 'inset 0px 0px 0px 0px rgb(0, 104, 174)'
    const opacityAmount = active ? '' : '.6'
    return (
      <div
        onClick={this.doClick}
        style={{ ...wrapperStyle, boxShadow, opacity: opacityAmount }}>
        {this.scores()}
        {this.props.children}
      </div>
    )
  }
}

export default CardWrapper
