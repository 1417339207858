import * as React from 'react'

const paragraphOne = (
  <p>
    Investing involves risk and investments may lose value. Please consider your
    objectives before investing. Investments are not FDIC insured. Past
    performance does not guarantee future results. Investment outcomes and
    projections are forward-looking statements and hypothetical in nature.
  </p>
)

const paragraphTwo = (
  <p>
    All information and recommendations provided “as is” based on information
    provided by clients. We have treated the information provided as reliable,
    have not independently verified any information and make no warranty or
    guaranty as to the accuracy or completeness of the information provided.
  </p>
)

const paragraphThree = (
  <p>
    See the back of the book for more important information regarding how your
    score was calculated
  </p>
)

export const FinancialGoalsDisclosures: React.StatelessComponent = () => (
  <div className='disclosure-footer pdf-only'>
    {paragraphOne}
    {paragraphTwo}
    {paragraphThree}
  </div>
)
