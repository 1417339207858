import React, { Component, CSSProperties } from 'react'
import {
  Tile,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../reducers'
import { history } from '../../../store'
import {
  HonestConversationsExerciseObj,
  HouseholdCardObj
} from '../../../objects/honestConversations'

import SharedPriorityCards from './sharedPriorityCards'
import SharedPriorityGraphs from './sharedPriorityGraphs'
import SharedPriorityActions from './lifeActions'
import { ContactsInterface } from '../../../objects/contact'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { isEqual, isObjEmpty } from '../../helpers'
import { sortHouseholdCards } from '../../helpers/honestConversations'

export interface SharedPrioritiesProps {
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
}

interface SharedPrioritiesState {
  selectedCard: HouseholdCardObj
}

const tileHeaderStyle: CSSProperties = {
  background: 'none',
  backgroundColor: '#FAFAFA',
  borderBottom: 'none'
}
const btnStyle: CSSProperties = {
  padding: '0 2px',
  color: '#255eba'
}

export class SharedPriorities extends Component<
  SharedPrioritiesProps,
  SharedPrioritiesState
> {
  constructor(props: SharedPrioritiesProps) {
    super(props)
    this.state = {
      selectedCard:
        props.honestConversation?.householdCards &&
        this.setInitialSelectedCard()
    }
  }

  public componentDidUpdate(prevProps: any) {
    const { honestConversation } = this.props
    if (
      this.props?.honestConversation &&
      !isEqual(
        honestConversation.householdCards,
        prevProps.honestConversation?.householdCards
      )
    ) {
      this.setState({
        selectedCard: this.setInitialSelectedCard()
      })
    }
  }

  public setInitialSelectedCard = () => {
    const { householdCards } = this.props.honestConversation
    if (!householdCards || isObjEmpty(householdCards)) {
      return null
    } else {
      const sortedHouseholdCards = sortHouseholdCards(householdCards)
      return sortedHouseholdCards[0]
    }
  }

  public selectCard = (selectedCard: HouseholdCardObj) => {
    this.setState({
      selectedCard
    })
  }

  public editHonestConversation = () => {
    const { householdFinId, honestConversation } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/meetings/${honestConversation.id}`
    )
  }

  public tileHeader = () => {
    return (
      <div className='hc-header'>
        <div>SHARED PRIORITIES</div>
        <Button
          type='clear'
          btnStyle={btnStyle}
          onClick={this.editHonestConversation}>
          <EditIcon /> Edit
        </Button>
      </div>
    )
  }

  public render() {
    const { honestConversation, contacts } = this.props
    const { selectedCard } = this.state
    return (
      <Tile
        header={this.tileHeader()}
        headerStyle={tileHeaderStyle}
        contentStyle={{ padding: '17px 0px 0px 0px' }}>
        <div className='hc-components__col'>
          <SharedPriorityCards
            selectedCard={selectedCard}
            selectCard={this.selectCard}
            householdCards={honestConversation?.householdCards}
            honestConversation={honestConversation}
            contacts={contacts}
          />
          <SharedPriorityGraphs
            selectedCard={selectedCard}
            contacts={contacts}
            honestConversation={honestConversation}
          />
          <SharedPriorityActions
            selectedCardId={selectedCard?.card?.id}
            selectedCard={selectedCard}
            contacts={contacts}
          />
        </div>
      </Tile>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdFinId = match && match.params.householdFinId
  return {
    householdFinId,
    honestConversations: store.hcExercises[householdFinId],
    lifeActions: store.lifeActions[householdFinId],
    contacts: store.contact[householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(SharedPriorities))
