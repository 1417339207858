import React from 'react'
import TableLabel from '../../components/tableLabel'

interface TableHeaderProps {
  sortBy: string
  sortDescending: boolean
  setSort(): (sortDescending: boolean, sortBy: string) => void
}

const TableHeader = (props: TableHeaderProps) => {
  const { sortBy, sortDescending, setSort } = props
  return (
    <div className='task__grid-header'>
      <div className='task__grid-col-left'>
        <TableLabel
          title='DESCRIPTION'
          onClick={setSort()}
          defaultArrow={
            sortBy !== 'DESCRIPTION' ? true : sortDescending !== true
          }
        />
      </div>
      <div className='task__grid-col-left'>
        <TableLabel
          title='STATUS'
          onClick={setSort()}
          defaultArrow={sortBy !== 'STATUS' ? true : sortDescending !== true}
        />
      </div>
      <div className='task__grid-col-left'>
        <TableLabel
          title='DUE DATE'
          onClick={setSort()}
          defaultArrow={sortBy !== 'DUE DATE' ? true : sortDescending !== true}
        />
      </div>
      <div className='task__grid-col-left'>
        <TableLabel
          title='ASSIGNED'
          onClick={setSort()}
          defaultArrow={sortBy !== 'ASSIGNED' ? true : sortDescending !== true}
        />
      </div>
      <div className='task__grid-col-right'>
        <TableLabel
          title='SHOW/HIDE'
          toolTipWidth={110}
          onClick={setSort()}
          defaultArrow={sortBy !== 'SHOW/HIDE' ? true : sortDescending !== true}
        />
      </div>
    </div>
  )
}

export default TableHeader
