import React, { ChangeEvent } from 'react'
import Input from '../../../components/Input'
import Button from '../../../components/button'
import * as actions from '../../../../actions/user'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import Modal from '../../../components/layout/modal'
import RedtailIcon from '../../../assets/images/logos/redtail-logo.png'

interface RedtailLoginProps {
  loginFailed: boolean
  dispatch: Dispatch<GlobalState>
  closeModal?(): void
}

interface RedtailLoginState {
  username: string
  password: string
}

class RedtailLogin extends React.Component<
  RedtailLoginProps,
  RedtailLoginState
> {
  constructor(props: RedtailLoginProps) {
    super(props)
    this.state = {
      username: null,
      password: null
    }
  }
  public logIn = async () => {
    const { username, password } = this.state
    await this.props.dispatch(actions.loginToRedtail(username, password))
    this.props.dispatch(actions.previewHouseholdSync())
    this.props.closeModal()
  }
  public updateUsername = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: e.currentTarget.value })
  }
  public updatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value })
  }
  public checkInput = (): boolean => {
    const { username, password } = this.state
    return !(username && password)
  }
  public render() {
    const { loginFailed, closeModal } = this.props
    const { username, password } = this.state
    return (
      <Modal
        title='Login to Redtail'
        iconPng={RedtailIcon}
        closeModal={closeModal}>
        <div className='redtail-login-w'>
          <Input
            inputType='text'
            title='Username'
            name='username'
            content={username}
            controlFunc={this.updateUsername}
            mandatory={true}
          />
          <Input
            inputType='password'
            title='Password'
            name='password'
            content={password}
            controlFunc={this.updatePassword}
            mandatory={true}
          />
          <div className='redtail-login__error'>
            {loginFailed ? 'Login Failed' : ''}
          </div>
          <div className='modal__buttons modal__buttons--w modal__buttons--center'>
            <Button
              onClick={this.logIn}
              primary={true}
              disabled={this.checkInput()}>
              Log In
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    loginFailed: store.user.crmLoginFailed
  }
}

export default connect(mapStateToProps)(RedtailLogin)
