export const setPointRadius = (pointArray: number[]) => {
  const pointRadius: number[] = []
  pointArray.forEach((point, index) => {
    pointRadius.push(index === 0 && pointArray.length > 3 ? 0 : 4)
  })

  return pointRadius
}

interface TooltipStyle {
  opacity?: string
  position?: string
  width?: string
  height?: string
}

const onHoverPoint = <E, T>(event: any, chartElement: T[]) => {
  event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
}

const getGraphScales = () => {
  return {
    xAxes: [
      {
        position: 'bottom',
        gridLines: {
          display: false,
          zeroLineColor: 'rgba(255,0,0,1)',
          tickMarkLength: -10
        },
        scaleLabel: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        position: 'left',
        gridLines: {
          display: true,
          drawBorder: false,
          tickMarkLength: 5
        },
        scaleLabel: {
          display: false
        },
        ticks: {
          lineHeight: 0,
          padding: 0,
          reverse: false,
          max: 10,
          min: 0,
          stepSize: 2
        }
      }
    ]
  }
}

const reload = () => {
  //
}

const customToolTip = () =>
  function(this: any, tooltipModel: any) {
    let tooltipEl = document.getElementById('chartjs-tooltip')
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      document.body.appendChild(tooltipEl)
    }

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform')
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign)
    } else {
      tooltipEl.classList.add('no-transform')
    }

    const getBody = (bodyItem: any) => {
      return bodyItem.lines
    }
    if (tooltipModel.body) {
      tooltipEl.innerText = ''

      tooltipModel.body.map(getBody).forEach((line: string) => {
        if (!line[0]) {
          return
        }

        const lineArray = line[0].split(':')

        if (!lineArray[0]) {
          return
        }

        const nameString = lineArray[0]
        const dateString = lineArray[1]
        const score = lineArray[2]

        const divBox = document.createElement('div')
        divBox.className = 'client-score-history-graph__chart-tooltip-row'
        const nameBox = document.createElement('div')
        nameBox.className = 'client-score-history-graph__chart-tooltip-label'

        const displayName = document.createElement('div')
        displayName.className = 'client-score-history-graph__chart-tooltip-name'
        displayName.innerText = nameString
        nameBox.appendChild(displayName)
        const displayDate = document.createElement('div')
        displayDate.className = 'client-score-history-graph__chart-tooltip-date'
        displayDate.innerText = dateString
        nameBox.appendChild(displayDate)
        divBox.appendChild(nameBox)

        const scoreCircle = document.createElement('div')
        scoreCircle.className =
          'client-score-history-graph__score-number client-score-history-graph__chart-tooltip-number'
        scoreCircle.style.backgroundColor =
          parseInt(score, 0) >= 7 ? '#a2d134' : '#ffbe1a'
        scoreCircle.innerText = score
        divBox.appendChild(scoreCircle)
        tooltipEl.appendChild(divBox)

        const caretDisplay = document.createElement('div')
        caretDisplay.id = 'caretDisplay'
        caretDisplay.className =
          'client-score-history-graph__chart-tooltip--right'

        tooltipEl.appendChild(caretDisplay)
      })
    }

    const chartCanvas = this._chart.canvas.getBoundingClientRect()

    const toolTipStyle: TooltipStyle = {
      opacity: '1',
      position: 'absolute',
      width: 'auto',
      height: 'auto'
    }

    let styleString = ''
    Object.keys(toolTipStyle).forEach((key) => {
      styleString = `${key}: ${toolTipStyle[key]};` + styleString
    })
    tooltipEl.setAttribute('style', styleString)
    tooltipEl.className = 'client-score-history-graph__chart-tooltip'

    // set the position of the tooltip x and y
    const tooltipPositioner = (
      isXPosi: boolean,
      caretPosition: number,
      maxPosition: number,
      normalPosition: number,
      nudge: number
    ): number => {
      const positionDifference = caretPosition - maxPosition
      if (caretPosition >= maxPosition) {
        return normalPosition - positionDifference / 1.05
      }

      if (normalPosition - nudge < 220) {
        return normalPosition - 110
      }

      if (isXPosi) {
        return normalPosition - (nudge * 2.4) / 2.8
      } else {
        return tooltipModel.height > 42
          ? normalPosition - nudge * 1.2
          : normalPosition - nudge
      }
    }
    tooltipEl.style.left = `${tooltipPositioner(
      true,
      tooltipModel.caretX,
      chartCanvas.width - tooltipModel.width,
      tooltipModel.caretX + chartCanvas.left,
      tooltipModel.width
    )}px`
    tooltipEl.style.top = `${tooltipPositioner(
      false,
      tooltipModel.caretY,
      chartCanvas.width - tooltipModel.height,
      tooltipModel.caretY + chartCanvas.top,
      110
    )}px`
  }

export const printGraphOptions = () => {
  const graphOptions = getGraphOptions()

  return { ...graphOptions, animation: { duration: 0 } }
}

export const getGraphOptions = () => {
  const graphOptions = {
    scaleShowVerticalLines: false,
    pointDotRadius: 5,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 5,
        top: 10,
        bottom: 5
      }
    },
    legend: {
      display: false
    },
    border: 0,
    scales: getGraphScales(),
    tooltips: {
      enabled: false,
      custom: customToolTip(),
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const { datasetIndex, index } = tooltipItem
          const dataSet = data.datasets[datasetIndex]

          if (!dataSet.labels) {
            return ''
          }

          const labelName = dataSet.labels[index]
          return `${labelName}:${tooltipItem.value}`
        }
      }
    },
    hover: {
      onHover: onHoverPoint
    }
  }
  return {
    ...graphOptions,
    animation: {
      onComplete: reload()
    },
    annotation: {
      annotations: [
        {
          id: 'hc-score-history',
          drawTime: 'afterDraw',
          type: 'box',
          xScaleID: 'x-axis-0',
          yScaleID: 'y-axis-0',
          yMin: 0,
          yMax: 10,
          borderColor: 'rgba(255,255,255,0)',
          backgroundColor: 'rgba(255,255,255,0)',
          borderWidth: 0,
          borderDash: [2, 2],
          borderDashOffset: 1
        }
      ]
    }
  }
}
