import defaultAvatarIcon from '../../../../assets/images/icons/general/avatar-gray.png'

import TaskDisplay from './TaskDisplay'
import { CardObj } from '../../../../../objects/HonestConversations/card'
import React, { Component, CSSProperties, ReactNode } from 'react'

import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { ContactsInterface } from '../../../../../objects/contact'
import { LifeActionObj } from '../../../../../objects/lifeActions'

interface ActionsProps {
  index: number
  card: CardObj
  contacts: ContactsInterface
  householdFinId: string
  lifeActions: LifeActionObj[][]
  modalFunc?(card: CardObj): void
}

class Actions extends Component<ActionsProps> {
  public getContactIcon = (
    hasIcon: boolean,
    name: string,
    style: CSSProperties
  ): ReactNode => {
    return (
      <div className='results-summary__contact-avatar' style={style}>
        {hasIcon ? <img src={defaultAvatarIcon} alt='contact avatar' /> : name}
      </div>
    )
  }

  public getActionTasks = (index: number): ReactNode => {
    const { card, modalFunc, contacts, lifeActions } = this.props

    const contactAvatars: {
      id: string
      source: string
      firstName: string
      lastName: string
      isPrimary: boolean
    }[] = []
    if (contacts && contacts.primary) {
      contactAvatars.push({
        id: contacts.primary.id,
        source: contacts && contacts.primary.photo,
        firstName: contacts && `${contacts.primary.firstName}`,
        lastName: contacts && `${contacts.primary.lastName}`,
        isPrimary: true
      })
    }

    if (contacts && contacts.secondary) {
      contactAvatars.push({
        id: contacts.secondary.id,
        source: contacts && contacts.secondary.photo,
        firstName: contacts && `${contacts.secondary.firstName}`,
        lastName: contacts && `${contacts.secondary.lastName}`,
        isPrimary: false
      })
    }

    const listOfActions: LifeActionObj[] = lifeActions[index]

    return listOfActions ? (
      <TaskDisplay
        card={card}
        lifeActions={listOfActions}
        index={index}
        modalFunc={modalFunc}
        contactAvatars={contactAvatars}
      />
    ) : null
  }

  public getActions = (): ReactNode => {
    const { index } = this.props
    return this.getActionTasks(index)
  }

  public render() {
    return <div className='results-summary__actions-w'>{this.getActions()}</div>
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    household: store.households[householdFinId]
  }
}

export default connect(mapStateToProps)(Actions)
