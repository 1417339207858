import React, { MouseEvent } from 'react'
import FinancialCharts from '../../components/graphs/financialCharts'
import { Progress } from '../../../objects/household'
import { history } from '../../../store'

interface HouseholdTotalProgressProps {
  fundedScore: number
  palProgress: Progress
  goalsProgress: Progress
  ivProgress: Progress
  netWorth: number
  hcProgress: Progress
  mmProgress: Progress
  lastLogin: string
  className?: string
  to?: string
}
class HouseholdTotalProgress extends React.Component<
  HouseholdTotalProgressProps
> {
  public onClick = (e: MouseEvent<HTMLDivElement>) => {
    const { to } = this.props
    history.push(to)
    if (e.stopPropagation) {
      e.stopPropagation()
    }
  }
  public render() {
    const {
      fundedScore,
      palProgress,
      goalsProgress,
      ivProgress,
      netWorth,
      hcProgress,
      mmProgress,
      lastLogin,
      className
    } = this.props
    let progress = 0
    progress = fundedScore > 0 ? progress + 1 : progress
    progress = netWorth > 0 ? progress + 1 : progress
    progress = hcProgress === 'completed' ? progress + 1 : progress
    if (goalsProgress) {
      progress = goalsProgress === 'completed' ? progress + 1 : progress
    } else {
      progress = palProgress === 'completed' ? progress + 1 : progress
    }
    progress = mmProgress === 'completed' ? progress + 1 : progress
    progress = ivProgress === 'completed' ? progress + 1 : progress
    progress = lastLogin ? progress + 1 : progress
    progress = Math.round((progress / 7) * 100)
    const remaining = 100 - progress

    const chartData = {
      datasets: [
        {
          data: [remaining, progress],
          backgroundColor: ['#e5e5e5', '#255eba'],
          borderColor: ['#e5e5e5', '#255eba'],
          borderWidth: 0.1
        }
      ]
    }

    const chartOptions = {
      animation: {
        duration: 0
      },
      responsiveAnimationDuration: 0,
      animateRotate: false,
      reverse: true,
      showTooltips: false,
      responsive: false,
      cutoutPercentage: 75,
      segmentShowStroke: false,
      tooltips: {
        enabled: false
      }
    }

    return (
      <div className={`households__column ${className}`} onClick={this.onClick}>
        <div className='household-total-progress-w'>
          <FinancialCharts
            chartData={chartData}
            chartOptions={chartOptions}
            dimensions={{ width: 60, height: 60 }}
          />
          <div className='household-total-progress__label'>{progress}%</div>
        </div>
      </div>
    )
  }
}

export default HouseholdTotalProgress
