// import * as React from 'react'

import React, { FunctionComponent, SVGProps } from 'react'

import { InvestmentViewfinderPriorityObj } from '../../../../objects/investmentViewfinder'
import { getPriorityIcon } from '../../../components/priorityIcons'

const getIcon = (label: string) => {
  const PriorityIcon1: FunctionComponent<SVGProps<
    SVGSVGElement
  >> = getPriorityIcon(label.toLowerCase())
  return <PriorityIcon1 />
}

export const PriorityQuestionDescription: React.StatelessComponent<InvestmentViewfinderPriorityObj> = ({
  description,
  label
}) => (
  <div className='ivf-update-answer-modal__priority-descriptions'>
    <div className='ivf-update-answer-modal__priority-descriptions-icon'>
      {getIcon(label)}
      <span style={{ marginLeft: '10px' }}>{label}</span>
    </div>
    <div className='ivf-update-answer-modal__priority-descriptions-copy'>
      {description}
    </div>
  </div>
)
