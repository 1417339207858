import React, { FunctionComponent, SVGProps } from 'react'
import { AttributeDescription } from '../../../objects/investmentViewfinder'

interface InvestmentAttributeProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  barColor: string
  title: AttributeDescription
  description: string
  setActiveDescription(description: AttributeDescription): void
}

interface InvestmentAttributeState {
  hover: boolean
}

class InvestmentAttribute extends React.Component<
  InvestmentAttributeProps,
  InvestmentAttributeState
> {
  constructor(props: InvestmentAttributeProps) {
    super(props)
    this.state = {
      hover: false
    }
  }
  public setHovering = () => {
    const { setActiveDescription, title } = this.props
    this.setState({ hover: true })
    setActiveDescription(title)
  }
  public setNotHovering = () => {
    const { setActiveDescription } = this.props
    this.setState({ hover: false })
    setActiveDescription(null)
  }
  public render() {
    const { barColor, title, Icon, description } = this.props
    const { hover } = this.state
    return (
      <div className='ivf-attributes-line-w'>
        <div
          className='ivf-attributes'
          onMouseEnter={this.setHovering}
          onMouseLeave={this.setNotHovering}>
          <Icon />
          <div
            className='ivf-attributes__bar'
            style={{ backgroundColor: barColor }}
          />
          <div className='ivf-attributes__title'>{title}</div>
          <div className='ivf-attributes__description'>{description}</div>
        </div>
        <div
          className='ivf-attributes-line'
          style={{ visibility: hover ? 'visible' : 'hidden' }}
        />
      </div>
    )
  }
}

export default InvestmentAttribute
