import React, { Component, Fragment } from 'react'

import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../../../reducers'

import {
  Tile,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  DragDropContext,
  DraggableLocation,
  DropResult
} from 'react-beautiful-dnd'

import ClientCardTable from './clientCardTable'
import CombinedCardsTable from './combinedCardsTable'
import Instructions from './instructions'

import { ContactsInterface } from '../../../../../objects/contact'

import PageHeading from '../pageHeading'

import ArrowIcon from '../../../../assets/images/icons/png/arrow_right_blue.png'
import firstStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-1.gif'
import secondStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-2.gif'
import thirdStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-3.gif'
import fourthStepInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-4.gif'
import {
  HonestConversationsExerciseObj,
  ClientCardObj,
  HouseholdCardObj,
  CardRankingObj,
  ScoringSessionsObj
} from '../../../../../objects/honestConversations'
import { getTopFiveCards } from '../../../../helpers/honestConversations'
import {
  createScoringSessionsAndSetAsActive,
  updateHonestConversationHouseholdCards,
  getHonestConversationExercise,
  updateClientCards,
  deleteHonestConversationHouseholdCards
} from '../../../../../actions/hcExercises'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import goalActionsSelector from '../../../../../selectors/v3/goals'

const PRIMARY_CARDS_TABLE_ID = 'PrimaryCardTable'
const SECONDARY_CARDS_TABLE_ID = 'SecondaryCardTable'
const COMBINED_CARDS_TABLE_ID = 'CombinedCardsTable'

const COMPLETED_SELECTING_CARDS: string = 'completedSelectingCards'

const instructions = [
  {
    gif: firstStepInstruction,
    text:
      'With your personal priorities defined, now let’s pick your shared priorities'
  },
  {
    gif: secondStepInstruction,
    text: 'We’ll score how well you’re doing'
  },
  {
    gif: thirdStepInstruction,
    text: 'And discuss goals you can set to improve'
  },
  {
    gif: fourthStepInstruction,
    text: 'We’ll repeat this for each priority'
  }
]

interface PickCombinedCardsProps {
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  hcExercise: HonestConversationsExerciseObj
  contacts: ContactsInterface
  goalActions: FinancialGoalActionObj[]
  back(): void
  next(): void
}

interface PickCombinedCardsState {
  createScoringSessionInitiated: boolean
  householdCards: HouseholdCardObj[]
  instructionsInProgress: boolean
  completedSelectingCards: boolean
  isDroppingDisabled: boolean
}

export class PickCombinedCards extends Component<
  PickCombinedCardsProps,
  PickCombinedCardsState
> {
  constructor(props: PickCombinedCardsProps) {
    super(props)
    const { householdCards } = this.getCardArrays()
    this.state = {
      createScoringSessionInitiated: false,
      householdCards,
      instructionsInProgress: true,
      completedSelectingCards: householdCards.length === 5,
      isDroppingDisabled: false
    }
  }

  public toggleInstructionsInProgress = () => {
    this.setState({
      instructionsInProgress: false
    })
  }

  public setHouseholdCards = () => {
    const { householdCards } = this.getCardArrays()
    this.setState({
      householdCards,
      completedSelectingCards: householdCards.length === 5
    })
  }

  public setCreateClientScoringSession = (clientId: string) => {
    const { dispatch, householdFinId, hcExercise, contacts } = this.props

    const scoringSession: ScoringSessionsObj = {
      scoredWithAdvisor: true,
      clientId
    }

    clientId &&
      this.setState({ createScoringSessionInitiated: true }, () => {
        dispatch(
          createScoringSessionsAndSetAsActive(
            householdFinId,
            hcExercise.id,
            scoringSession,
            contacts?.primary?.id === clientId
          )
        )
      })
  }

  public async componentDidUpdate(prevProps: PickCombinedCardsProps) {
    const { hcExercise, contacts } = this.props
    const { createScoringSessionInitiated } = this.state

    // Compare household cards and update only when there is a change in cards data
    if (this.state?.householdCards?.length) {
      this.compareStateHouseholdCards(prevProps)
    }

    if (hcExercise && !createScoringSessionInitiated) {
      if (!hcExercise.activeScoringSessionPrimary && contacts?.primary?.id) {
        this.setCreateClientScoringSession(contacts?.primary?.id)
      }
      if (
        !hcExercise.activeScoringSessionSecondary &&
        contacts?.secondary?.id
      ) {
        this.setCreateClientScoringSession(contacts?.secondary?.id)
      }
    }
  }

  /**
   * Case: single household
   * Compare prop and state household cards
   * If a prop card does not exists in state household cards, update state
   */
  public compareStateHouseholdCards = (prevProps: PickCombinedCardsProps) => {
    const { hcExercise } = this.props

    // Optimistic values for household cards + client cards that have been moved in - household cards that have been moved out
    const stateCardIds = this?.state?.householdCards
      ?.sort((a, b) => a.ranking - b.ranking)
      ?.map((stateCard) => stateCard.card.id)
      .toString()

    // household cards that we have retrieved from the database
    const propCards =
      hcExercise?.householdCards &&
      Object.values(hcExercise?.householdCards).sort(
        (a, b) => a.ranking - b.ranking
      )

    const propCardIds = propCards
      ?.map((propCard) => propCard.card.id)
      .toString()

    const prevCardIds =
      prevProps?.hcExercise?.householdCards &&
      Object.values(prevProps?.hcExercise?.householdCards)
        ?.sort((a, b) => a.ranking - b.ranking)
        ?.map((propCard) => propCard.card.id)
        .toString()

    // Check that the propCards are different than what we currently have in state so we're not caught in an infinite loop
    // Check that the new cards are different than what we previously had so that we don't overwrite the state prematurely
    // causing cards to teleport back and forth
    // Note: If multiple cards are moved around quickly enough then we might still see some cards teleporting
    if (propCardIds !== stateCardIds && prevCardIds !== propCardIds) {
      this.setState({
        householdCards: propCards
      })
    }
  }

  // Returns an object with clientCards and householdCards
  public getCardArrays = () => {
    const { hcExercise, contacts } = this.props
    if (!hcExercise) {
      return {
        primaryCards: [],
        secondaryCards: [],
        householdCards: []
      }
    }

    const clientCardMap = (contactId: string) => {
      return Object.keys(hcExercise.clientCards[contactId]).map(
        (key) => hcExercise.clientCards[contactId][key]
      )
    }

    const primaryCards =
      contacts?.primary?.id && clientCardMap(contacts.primary.id)
    const secondaryCards =
      contacts?.secondary?.id && clientCardMap(contacts.secondary.id)

    const householdCards = Object.keys(hcExercise.householdCards)
      .map((key) => hcExercise?.householdCards[key])
      .sort((a, b) => a.ranking - b.ranking)

    const topPrimaryCards = getTopFiveCards(primaryCards)
    const topSecondaryCards = getTopFiveCards(secondaryCards)
    if (secondaryCards) {
      topPrimaryCards.forEach((card) => {
        topSecondaryCards.forEach((card2) => {
          if (card.card.id === card2.card.id) {
            card.sharedCard = true
            card2.sharedCard = true
          }
        })
      })
    }

    return {
      primaryCards: topPrimaryCards,
      secondaryCards: topSecondaryCards,
      householdCards
    }
  }

  public onDragStart = async (result: DropResult) => {
    const { source } = result
    this.setState({
      isDroppingDisabled: Boolean(
        this.state.householdCards.length >= 5 &&
          source.droppableId !== COMBINED_CARDS_TABLE_ID
      )
    })
  }

  public onDragEnd = async (result: DropResult) => {
    const { destination, source } = result
    if (!destination) {
      return
    }

    // Add cards to combined table
    if (destination.droppableId === COMBINED_CARDS_TABLE_ID) {
      this.addToCombinedCards(source, destination)
    }
    // Remove from combined table
    if (source.droppableId === COMBINED_CARDS_TABLE_ID) {
      this.removeFromCombinedCards(source, destination)
    }
    // Rank cards in combined table
    if (
      source.droppableId === COMBINED_CARDS_TABLE_ID &&
      destination.droppableId === COMBINED_CARDS_TABLE_ID
    ) {
      this.rankCombinedCardTable(source, destination)
    }
  }

  // add to combined cards table
  public addToCombinedCards = (
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const newState = { ...this.state }
    const cardArrayKey: string =
      droppableSource.droppableId !== PRIMARY_CARDS_TABLE_ID
        ? 'secondaryCards'
        : 'primaryCards'
    const householdCardsKey: string = 'householdCards'
    if (droppableSource.droppableId === COMBINED_CARDS_TABLE_ID) {
      return
    }
    const clientCards = this.getAvailableCards()

    this.moveToHouseholdCards(
      clientCards[cardArrayKey],
      newState.householdCards,
      droppableSource,
      droppableDestination
    ).then((promise) => {
      const { sourceCards, destinationCards } = promise
      newState[cardArrayKey] = sourceCards
      newState[householdCardsKey] = destinationCards
      newState[COMPLETED_SELECTING_CARDS] = destinationCards.length === 5
      this.setState(newState)
    })
  }

  // remove from combined, add back to individual
  public removeFromCombinedCards = (
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const newState = { ...this.state }

    const householdCardsKey: string = 'householdCards'

    if (droppableDestination.droppableId === COMBINED_CARDS_TABLE_ID) {
      return
    }

    const { sourceCards } = this.moveToIndividualCards(
      newState.householdCards,
      droppableSource
    )
    newState[householdCardsKey] = sourceCards
    newState[COMPLETED_SELECTING_CARDS] = sourceCards.length === 5
    this.setState(newState)
  }

  public rankCombinedCardTable = async (
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const { householdCards } = this.state
    const updatedhouseholdCards = await this.reOrder(
      householdCards,
      droppableSource.index,
      droppableDestination.index
    )
    this.setState({ householdCards: updatedhouseholdCards.destinationCards })
  }

  public moveToHouseholdCards = async (
    source: any,
    destination: any,
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const householdCard: CardRankingObj = source[droppableSource.index]
    householdCard.ranking = droppableDestination.index + 1
    const householdCards = destination && [...destination]
    // add the new card
    householdCards?.splice(droppableDestination.index, 0, householdCard)
    // remove the new card from the source
    source?.splice(droppableSource.index, 1)

    this.updateHouseholdCards(householdCards)
    return {
      sourceCards: source,
      destinationCards: householdCards
    }
  }

  public deleteHouseholdCard = (householdCardId: string) => {
    const { dispatch, hcExercise, householdFinId } = this.props
    dispatch(
      deleteHonestConversationHouseholdCards(
        householdFinId,
        hcExercise.id,
        householdCardId
      )
    )
  }

  public updateHouseholdCards = (householdCards: HouseholdCardObj[]) => {
    const { dispatch, hcExercise, householdFinId } = this.props
    // fix card id and ranking
    const updatedHouseholdCards: HouseholdCardObj[] = householdCards.map(
      (hcCard, index) => {
        return {
          id: hcCard.id,
          exerciseId: hcExercise.id,
          commentPrimary: hcCard.commentPrimary || null,
          commentSecondary: hcCard.commentSecondary || null,
          ranking: index + 1,
          cardId: hcCard.card.id,
          card: hcCard.card
        }
      }
    )
    dispatch(
      updateHonestConversationHouseholdCards(
        householdFinId,
        hcExercise.id,
        updatedHouseholdCards
      )
    )
    return updatedHouseholdCards
  }

  public moveToIndividualCards = (
    source: any,
    droppableSource: DraggableLocation
  ) => {
    const householdCard: CardRankingObj = source[droppableSource.index]
    source.splice(droppableSource.index, 1)
    if (source.length > 0) {
      this.updateHouseholdCards([...source])
    } else {
      this.deleteHouseholdCard(householdCard.id)
    }
    return {
      sourceCards: source
    }
  }

  public reOrder = async (
    householdCards: HouseholdCardObj[],
    startIndex: number,
    endIndex: number
  ) => {
    const { contacts } = this.props
    const results = Array.from(householdCards)
    const [removed] = results.splice(startIndex, 1)
    results.splice(endIndex, 0, removed)
    this.updateHouseholdCards(results)

    // update primary client card
    if (!contacts?.secondary?.id) {
      this.updatePrimaryCards()
    }
    return { destinationCards: results }
  }

  public updatePrimaryCards = () => {
    const { householdFinId, hcExercise, contacts, dispatch } = this.props
    const clientCards: ClientCardObj[] = Object.keys(
      hcExercise.clientCards[contacts.primary.id]
    )
      .filter(
        (cardKey) =>
          hcExercise.clientCards[contacts.primary.id][cardKey].rankedWithin ===
          'All'
      )
      .map((index) => hcExercise.clientCards[contacts.primary.id][index])
      .sort((a, b) => a.ranking - b.ranking)
    const householdCards: HouseholdCardObj[] = Object.keys(
      hcExercise.householdCards
    )
      .map((householdCardKey) => hcExercise.householdCards[householdCardKey])
      .sort((a, b) => a.ranking - b.ranking)

    clientCards.forEach((clientCard) => {
      householdCards.forEach((householdCard) => {
        if (clientCard.cardId === householdCard.cardId) {
          clientCard.ranking = householdCard.ranking
        }
      })
    })
    dispatch(
      updateClientCards(
        householdFinId,
        hcExercise.id,
        contacts.primary.id,
        clientCards
      )
    )
  }

  public goToResults = async () => {
    const { dispatch, householdFinId, next, hcExercise } = this.props
    await dispatch(getHonestConversationExercise(householdFinId, hcExercise.id))
    next()
  }

  public tileHeader = () => {
    const { completedSelectingCards } = this.state
    const { back } = this.props
    return (
      <Fragment>
        <Button
          className='tile__btn-back'
          btnStyle={{
            backgroundColor: 'transparent',
            padding: 0,
            color: '#255eba'
          }}
          onClick={back}>
          <img
            className='transform__rotate-180'
            src={ArrowIcon}
            alt='arrow icon'
          />
          Back
        </Button>
        <Button
          type={completedSelectingCards ? null : 'disabled'}
          onClick={completedSelectingCards ? this.goToResults : null}>
          Next
        </Button>
      </Fragment>
    )
  }

  // Get the primary and secondary cards that aren't in the household cards list
  public getAvailableCards = () => {
    const { householdCards } = this.state
    const { primaryCards, secondaryCards } = this.getCardArrays()

    const hhCardIds = Object.values(householdCards).map(
      (hhCard) => hhCard.card.id
    )
    const availableSecondaryCards = secondaryCards.filter(
      (secondaryCard) => !hhCardIds.includes(secondaryCard.cardId)
    )
    const availablePrimaryCards = primaryCards.filter(
      (primaryCard) => !hhCardIds.includes(primaryCard.cardId)
    )

    return {
      primaryCards: availablePrimaryCards,
      secondaryCards: availableSecondaryCards
    }
  }

  public render() {
    const { contacts, householdFinId, hcExercise, goalActions } = this.props
    const {
      householdCards,
      completedSelectingCards,
      instructionsInProgress,
      isDroppingDisabled
    } = this.state

    // check the actual count in the reducer
    const householdCardCount =
      hcExercise?.householdCards &&
      Object.keys(hcExercise.householdCards).length

    const { primaryCards, secondaryCards } = this.getAvailableCards()
    return (
      <Fragment>
        <PageHeading number='3' text='Plan' />
        <Tile header={this.tileHeader()} contentStyle={{ padding: 0 }}>
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}>
            <div className='combined-card__w'>
              {contacts && contacts.secondary && (
                <ClientCardTable
                  cards={secondaryCards}
                  firstName={contacts.secondary.firstName}
                  id={SECONDARY_CARDS_TABLE_ID}
                  contactType='Secondary'
                />
              )}
              <CombinedCardsTable
                id={COMBINED_CARDS_TABLE_ID}
                hcExercise={hcExercise}
                householdFinId={householdFinId}
                cards={householdCards}
                contacts={contacts}
                goalActions={goalActions}
                completedSelectingCards={
                  completedSelectingCards && householdCardCount === 5
                }
                instructionsInProgress={instructionsInProgress}
                isDroppingDisabled={isDroppingDisabled}
              />

              {contacts && contacts.primary && (
                <ClientCardTable
                  cards={primaryCards}
                  firstName={contacts.primary.firstName}
                  id={PRIMARY_CARDS_TABLE_ID}
                  contactType='Primary'
                />
              )}
            </div>
          </DragDropContext>
        </Tile>
        {instructionsInProgress ? (
          <Instructions
            contacts={contacts}
            closeModal={this.toggleInstructionsInProgress}
            data={instructions}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, exerciseId } = match.params
  return {
    householdFinId,
    hcExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][exerciseId],
    goalActions: goalActionsSelector(store, householdFinId)
  }
}

export default withRouter(connect(mapStateToProps)(PickCombinedCards))
