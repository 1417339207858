import React, { FunctionComponent, SVGProps, RefObject, Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import BackButton from '../../components/backButton'
import { history } from '../../../store'
import BarGraph from './barGraph'
import { getPriorityIcon } from '../../components/priorityIcons'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import Button from '../../components/button'
import {
  InvestmentViewfinderPriorityInterface,
  InvestmentViewfinderQuestionObj,
  InvestmentViewfinderInterface,
  InvestmentViewfinderAnswer
} from '../../../objects/investmentViewfinder'
import * as actions from '../../../actions/investmentViewfinder'
import { getPriorityChanges, sliderMapValue } from '../../helpers/viewfinder'
import {
  PrioritySlider,
  CENTER_VALUE,
  sliderChange,
  priorityContent
} from './prioritySlider'

interface PriorityQuestionProps {
  householdFinId: string
  exerciseId: string
  questionNumber: string
  questions: {
    [questionNumber: string]: InvestmentViewfinderQuestionObj
  }
  question: InvestmentViewfinderQuestionObj
  nextQuestion: InvestmentViewfinderQuestionObj
  priority1: string
  priority2: string
  priorities: InvestmentViewfinderPriorityInterface
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  dispatch: Dispatch<GlobalState>
}

interface PrioriryQuestionState {
  leftPriorityHeight: number
  rightPriorityHeight: number
  sliderValue: number
  answer: InvestmentViewfinderAnswer
  priorityChange: {
    performance: number
    protection: number
    taxes: number
    cost: number
  }
}

class PriorityQuestion extends React.Component<
  PriorityQuestionProps,
  PrioriryQuestionState
> {
  public inputSliderRef: RefObject<HTMLInputElement> = React.createRef()

  constructor(props: PriorityQuestionProps) {
    super(props)
    this.state = this.initialState()
  }

  public componentDidUpdate(prevProps: PriorityQuestionProps) {
    if (prevProps.questionNumber !== this.props.questionNumber) {
      const initialState = this.initialState()
      this.setState(initialState)
    }
  }
  public initialState = () => {
    const { leftPriorityHeight, rightPriorityHeight } = this.getHeight(
      this.props.exercises[this.props.householdFinId],
      true
    )
    const {
      exercises,
      exerciseId,
      question,
      householdFinId,
      questionNumber
    } = this.props
    const answer =
      exercises[householdFinId] &&
      exercises[householdFinId][exerciseId] &&
      exercises[householdFinId][exerciseId].answers &&
      exercises[householdFinId][exerciseId].answers[question.id]
        ? exercises[householdFinId][exerciseId].answers[question.id].response
        : null
    return {
      sliderValue: this.getSliderFromResponse(answer),
      priorityChange: this.getPriorityChange(
        exercises[householdFinId],
        questionNumber
      ),
      answer,
      leftPriorityHeight,
      rightPriorityHeight
    }
  }
  public getPriorityChange = (
    exercises: InvestmentViewfinderInterface,
    questionNumber: string | number
  ) => {
    const { exerciseId, questions } = this.props
    const exercise = exercises && exercises[exerciseId]
    const answer = exercise.answers[questions[questionNumber].id]

    return getPriorityChanges(answer)
  }

  public getHeight = (
    exercises: InvestmentViewfinderInterface,
    initial?: boolean
  ): { leftPriorityHeight: number; rightPriorityHeight: number } => {
    const { questionNumber, priority1, priority2 } = this.props
    const changeSum = {
      taxes: 0,
      performance: 0,
      protection: 0,
      cost: 0
    }
    // Get the sum of changes to accurately show the current question's height
    // If its the initial fetch then include the current question otherwise exclude it so that the changed values are correct.
    if (initial) {
      for (let i = 1; i <= Number(questionNumber); i += 1) {
        const priorityChange = this.getPriorityChange(exercises, i)
        changeSum.taxes = changeSum.taxes + Number(priorityChange.taxes)
        changeSum.performance =
          changeSum.performance + Number(priorityChange.performance)
        changeSum.protection =
          changeSum.protection + Number(priorityChange.protection)
        changeSum.cost = changeSum.cost + Number(priorityChange.cost)
      }
    } else {
      for (let i = 1; i < Number(questionNumber); i += 1) {
        const priorityChange = this.getPriorityChange(exercises, i)
        changeSum.taxes = changeSum.taxes + Number(priorityChange.taxes)
        changeSum.performance =
          changeSum.performance + Number(priorityChange.performance)
        changeSum.protection =
          changeSum.protection + Number(priorityChange.protection)
        changeSum.cost = changeSum.cost + Number(priorityChange.cost)
      }
    }

    // Take the initial value and add the sum of all changes to get the actual value
    const leftPriorityHeight = ((8 + changeSum[priority1]) / 16) * 100
    const rightPriorityHeight = ((8 + changeSum[priority2]) / 16) * 100
    return {
      leftPriorityHeight: leftPriorityHeight || null,
      rightPriorityHeight: rightPriorityHeight || null
    }
  }

  public navigateBack = () => {
    const { exerciseId, householdFinId, questionNumber } = this.props
    if (Number(questionNumber) > 1) {
      history.push(
        `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/${Number(
          questionNumber
        ) - 1}`
      )
    } else {
      history.push(
        `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/`
      )
    }
  }
  public getAnswerFromSlider = (): InvestmentViewfinderAnswer => {
    const { sliderValue } = this.state
    return sliderMapValue(sliderValue)
  }
  public getSliderFromResponse = (
    response: InvestmentViewfinderAnswer
  ): number => {
    return sliderMapValue(response) ? sliderMapValue(response) : CENTER_VALUE
  }

  // Total up all the answers to the other questions and then add the answers to this question to the base score
  public getTotalPriority = () => {
    const { householdFinId, exercises, questionNumber } = this.props
    const { taxes, cost, performance, protection } = this.state.priorityChange
    const householdExercises = exercises[householdFinId]
    const changeSum = {
      taxes: 0,
      performance: 0,
      protection: 0,
      cost: 0
    }
    for (let i = 1; i < Number(questionNumber); i += 1) {
      const priorityChange = this.getPriorityChange(householdExercises, i)
      changeSum.taxes = changeSum.taxes + Number(priorityChange.taxes)
      changeSum.performance =
        changeSum.performance + Number(priorityChange.performance)
      changeSum.protection =
        changeSum.protection + Number(priorityChange.protection)
      changeSum.cost = changeSum.cost + Number(priorityChange.cost)
    }
    const taxPreference = changeSum.taxes + taxes + 8
    const performancePreference = changeSum.performance + performance + 8
    const protectionPreference = changeSum.protection + protection + 8
    const costPreference = changeSum.cost + cost + 8
    return {
      taxPreference,
      performancePreference,
      protectionPreference,
      costPreference
    }
  }
  public nextQuestion = async () => {
    const {
      exerciseId,
      householdFinId,
      nextQuestion,
      question,
      exercises,
      dispatch
    } = this.props
    const { taxes, cost, performance, protection } = this.state.priorityChange
    const answer = this.getAnswerFromSlider()
    const {
      taxPreference,
      performancePreference,
      protectionPreference,
      costPreference
    } = this.getTotalPriority()
    // If we already have an answer to this question update it, otherwise create a new answer
    if (
      exercises[householdFinId][exerciseId] &&
      exercises[householdFinId][exerciseId].answers &&
      exercises[householdFinId][exerciseId].answers[question.id]
    ) {
      const answerId =
        exercises[householdFinId][exerciseId].answers[question.id].id
      await dispatch(
        actions.updateViewfinderAnswer(
          householdFinId,
          exerciseId,
          answerId,
          question.id,
          taxes,
          performance,
          protection,
          cost,
          answer
        )
      )
    } else {
      await dispatch(
        actions.createViewfinderAnswer(
          householdFinId,
          exerciseId,
          question.id,
          taxes,
          performance,
          protection,
          cost,
          answer
        )
      )
    }
    await dispatch(
      actions.updateViewfinderPriority(
        householdFinId,
        exerciseId,
        taxPreference,
        performancePreference,
        protectionPreference,
        costPreference
      )
    )

    if (nextQuestion) {
      history.push(
        `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/${nextQuestion.questionNumber}`
      )
    } else {
      // When we finish all priority questions mark the record as active.
      history.push(
        `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/summary`
      )
    }
  }
  public middleHeader = () => {
    const { questionNumber } = this.props
    return (
      <div className='ivf-priorities__middle-header'>
        <div className='ivf-priorities__middle-header-text'>
          Question {questionNumber} of 6
        </div>
      </div>
    )
  }
  public rightHeader = () => {
    return (
      <Button
        primary={true}
        onClick={this.nextQuestion}
        disabled={this.state.sliderValue === CENTER_VALUE}>
        Next
      </Button>
    )
  }
  public question = (
    barColor: string,
    barHeight: number,
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
    title: string,
    description: string
  ) => {
    return (
      <div className='ivf-priorities__priority-w'>
        <BarGraph color={barColor} heightPercent={barHeight} />
        <div className='ivf-priorities__priority'>
          {Icon && <Icon className='ivf-priorities__icon' />}
          {title}
        </div>
        <div className='ivf-priorities__description-divider' />
        <div className='ivf-priorities__description'>{description}</div>
      </div>
    )
  }

  public onChange = () => {
    const numberValue = Number(this.inputSliderRef.current.value)
    const { exercises, householdFinId, priority1, priority2 } = this.props
    const priorityChange = this.state.priorityChange

    if (numberValue % 2 === 0) {
      const { leftPriorityHeight, rightPriorityHeight } = this.getHeight(
        exercises[householdFinId]
      )
      const sliderValueChange = sliderChange(
        numberValue,
        priorityChange,
        priority1,
        priority2
      )
      const leftHeight =
        leftPriorityHeight +
        (sliderValueChange.priorityChange[priority1] / 16) * 100
      const rightHeight =
        rightPriorityHeight +
        (sliderValueChange.priorityChange[priority2] / 16) * 100
      this.setState({
        sliderValue: numberValue,
        leftPriorityHeight: leftHeight,
        rightPriorityHeight: rightHeight,
        priorityChange
      })
    }
  }

  // tslint:disable-next-line:max-func-body-length
  public render() {
    const { sliderValue, leftPriorityHeight, rightPriorityHeight } = this.state
    const { priority1, priority2, priorities } = this.props
    const leftPriority = priorityContent(priorities[priority1])
    const leftPriorityIcon = priority1 && getPriorityIcon(priority1) // priorityIcons[priority1].icon

    const rightPriority = priorityContent(priorities[priority2])
    const rightPriorityIcon = priority2 && getPriorityIcon(priority2) // priorityIcons[priority2].icon

    return (
      <Fragment>
        <ContentHeader title='Priorities' />
        <Tile
          leftHeader={<BackButton onClick={this.navigateBack} />}
          middleHeader={this.middleHeader()}
          rightHeader={this.rightHeader()}
          headerBorder={true}
          headerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#FAFAFA'
          }}>
          <div className='ivf-container'>
            <div className='ivf-priorities__instructions'>
              Choose which one of the shown attributes has more priority to you.
              <br />
              If you think the one on the left should have more priority, move
              the handle to the left, otherwise, move the handle to the right.
              The handle can’t be left in the middle.
            </div>
            <div className='ivf-priorities__question-w'>
              {this.question(
                leftPriority.color,
                leftPriorityHeight,
                leftPriorityIcon,
                leftPriority.label,
                leftPriority.description
              )}

              <PrioritySlider
                modalSize={false}
                inputRef={this.inputSliderRef}
                sliderValue={sliderValue}
                leftPriority={(leftPriority && leftPriority) || null}
                rightPriority={(rightPriority && rightPriority) || null}
                onChangeFunc={this.onChange}
              />

              {this.question(
                rightPriority.color,
                rightPriorityHeight,
                rightPriorityIcon,
                rightPriority.label,
                rightPriority.description
              )}
            </div>
          </div>
        </Tile>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { questionNumber } = match.params
  return {
    questionNumber,
    questions: store.investmentViewfinder.questions,
    question: store.investmentViewfinder.questions[questionNumber],
    nextQuestion:
      store.investmentViewfinder.questions[Number(questionNumber) + 1],
    priority1:
      store.investmentViewfinder.questions[questionNumber] &&
      store.investmentViewfinder.questions[questionNumber].priority1,
    priority2:
      store.investmentViewfinder.questions[questionNumber] &&
      store.investmentViewfinder.questions[questionNumber].priority2,
    priorities: store.investmentViewfinder.priorities,
    exercises: store.investmentViewfinder.exercises
  }
}

export default withRouter(connect(mapStateToProps)(PriorityQuestion))
