import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import Button from '../../components/button'

interface DeleteModalProps {
  id: string
  type: string
  deleteFn(id: string, archived: boolean, folderId?: string): void
  closeModal(id: string): void
}

class DeleteModal extends React.Component<DeleteModalProps> {
  constructor(props: any) {
    super(props)
  }

  public deleteFn = () => {
    const { id, deleteFn } = this.props
    deleteFn(id, true)
  }

  public closeFn = () => {
    this.props.closeModal('')
  }

  public render() {
    return (
      <Modal
        icon={DeleteIcon}
        title={this.props.type === 'folder' ? 'DELETE FOLDER' : 'DELETE FILE'}
        closeModal={this.closeFn}>
        <div className='vault-modal__content'>
          {this.props.type === 'folder'
            ? 'Are you sure you want to delete this Folder? This action cannot be undone.'
            : 'Deleting this file will move it to the trash section, from where it can be restored. Are you sure you want to delete this file?'}
          <div className='vault-modal__buttons'>
            <Button clear={true} onClick={this.closeFn}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.deleteFn}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default DeleteModal
