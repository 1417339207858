import React, { Component, Fragment } from 'react'
import NullState from '../../components/nullState'

import { ReactComponent as TargetIcon } from '../../assets/images/icons/goals.svg'
import preformIcon from '../../assets/images/icons/png/inv-performance.png'
import protectIcon from '../../assets/images/icons/png/inv-protection.png'
import costIcon from '../../assets/images/icons/png/inv-cost.png'
import taxesIcon from '../../assets/images/icons/png/inv-taxes.png'
import accountsIcon from '../../assets/images/icons/null/accounts.png'
import { InvestmentViewfinderExerciseObj } from '../../../objects/investmentViewfinder'

interface ExerciseGraphProps {
  viewfinderExercise: InvestmentViewfinderExerciseObj
}

export class ExerciseGraph extends Component<ExerciseGraphProps> {
  // public primaryScore = () => {
  //   return (
  //     <span className='inv-exercise__score-priority'>Primary</span>
  //   );
  // }

  // public secondaryScore = () => {
  //   return (
  //     <span className='inv-exercise__score-priority'>Secondary</span>
  //   );
  // }

  public graphRender = () => {
    const { viewfinderExercise } = this.props
    return (
      <Fragment>
        <div className='inv-exercise__key-w'>
          {/* <div className='inv-exercise__key'>
            <span /><span /><span /><span /> Current
          </div> */}
          <div className='inv-exercise__key inv-exercise__key--current'>
            <span />
            <span />
            <span />
            <span /> Preference
          </div>
        </div>
        <div className='inv-exercise__graph-w'>
          <div>
            {/* {this.primaryScore()} */}
            <div className='inv-exercise__graph inv-exercise__graph--pref'>
              <span
                className='inv-exercise__graph--target'
                style={{
                  height: (viewfinderExercise.performance / 14) * 100 + '%'
                }}>
                <TargetIcon />
              </span>
              <span
                className='inv-exercise__graph--current'
                style={{ backgroundColor: '#9DD47D', height: 0 + '%' }}
              />
            </div>
            <h5>
              <img src={preformIcon} alt='' role='presentation' /> Performance
            </h5>
          </div>
          <div>
            {/* {this.secondaryScore()} */}
            <div className='inv-exercise__graph inv-exercise__graph--pro'>
              <span
                className='inv-exercise__graph--target'
                style={{
                  height: (viewfinderExercise.protection / 14) * 100 + '%'
                }}>
                <TargetIcon />
              </span>
              <span
                className='inv-exercise__graph--current'
                style={{ backgroundColor: '#3386BE', height: 0 + '%' }}
              />
            </div>
            <h5>
              <img src={protectIcon} alt='' role='presentation' /> Protection
            </h5>
          </div>
          <div>
            <div className='inv-exercise__graph inv-exercise__graph--cost'>
              <span
                className='inv-exercise__graph--target'
                style={{ height: (viewfinderExercise.cost / 14) * 100 + '%' }}>
                <TargetIcon />
              </span>
              <span
                className='inv-exercise__graph--current'
                style={{ backgroundColor: '#F6AA33', height: 0 + '%' }}
              />
            </div>
            <h5>
              <img src={costIcon} alt='' role='presentation' /> Costs
            </h5>
          </div>
          <div>
            <div className='inv-exercise__graph inv-exercise__graph--tax'>
              <span
                className='inv-exercise__graph--target'
                style={{ height: (viewfinderExercise.taxes / 14) * 100 + '%' }}>
                <TargetIcon />
              </span>
              <span
                className='inv-exercise__graph--current'
                style={{ backgroundColor: '#AD6A99', height: 0 + '%' }}
              />
            </div>
            <h5>
              <img src={taxesIcon} alt='' role='presentation' /> Taxes
            </h5>
          </div>
        </div>
      </Fragment>
    )
  }

  public graphNull = () => {
    return (
      <NullState
        style={{ padding: '80px 0' }}
        imgSrc={accountsIcon}
        heading='No results yet'
        text='Understand your investing preferences, and see how your current portfolio stacks up.'
      />
    )
  }

  public render(): any {
    const { viewfinderExercise } = this.props
    return (
      <div className='inv-exercise'>
        {viewfinderExercise &&
        (viewfinderExercise.performance ||
          viewfinderExercise.protection ||
          viewfinderExercise.cost ||
          viewfinderExercise.taxes)
          ? this.graphRender()
          : this.graphNull()}
      </div>
    )
  }
}

export default ExerciseGraph
