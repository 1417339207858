import { GlobalState } from '../../reducers'
import { createDeepEqualSelector } from './selectorCreator'
import { FileObj, FolderInterface, FolderObj } from '../../objects/folder'

export const getAllFolders = (
  store: GlobalState,
  householdFinId: string
): FolderInterface => {
  return store?.documentVault.folders[householdFinId]
}

export const getOneFolder = (
  store: GlobalState,
  householdFinId: string,
  folderId: string
): FolderObj => {
  return (
    store?.documentVault.folders[householdFinId] &&
    store.documentVault.folders[householdFinId][folderId]
  )
}

export const archivedFilesSelector = createDeepEqualSelector(
  [getAllFolders],
  (folders): FileObj[] => {
    const archivedFiles: FileObj[] = []
    folders &&
      Object.keys(folders).forEach((key) => {
        folders[key]?.files?.forEach((file: FileObj) => {
          if (file.archived) {
            file.folderId = key
            archivedFiles.push(file)
          }
        })
      })
    return archivedFiles
  }
)

export const noneArchivedFilesSelector = createDeepEqualSelector(
  [getOneFolder],
  (folder): FolderObj => {
    const folderObj = { ...folder }
    const noneArchivedFiles =
      folder?.files &&
      folder.files.filter((file) => {
        return !file.archived
      })
    folderObj.files = noneArchivedFiles
    return folderObj
  }
)
