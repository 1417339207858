import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { StrategySearchState } from '../../../../objects/strategySearch'
import stopIcon from '../../../assets/images/strategySearch/stop.png'
import { updateIMStrategyFilter } from '../../../../actions/strategySearch'

export interface StrategyFilterTagsProps {
  dispatch: Dispatch<GlobalState>
  strategySearch: StrategySearchState
}

export class StrategyFilterTags extends Component<StrategyFilterTagsProps> {
  public removeFilter = (e: React.MouseEvent<HTMLDivElement>) => {
    const { dispatch, strategySearch } = this.props
    const { filter } = strategySearch
    const appliedFilter = e.currentTarget.id.split('_')
    const keepFilters = filter[appliedFilter[0]].filter(
      // to avoid issues with boolean type - toString is added to filterValue
      (filterValue: string) => filterValue.toString() !== appliedFilter[1]
    )
    dispatch(updateIMStrategyFilter(appliedFilter[0], keepFilters))
  }

  public tagElement = (filterKey: string) => {
    const { filter } = this.props.strategySearch
    return (
      Array.isArray(filter[filterKey]) &&
      filter[filterKey]?.map((filterValue: string, index: number) => {
        const displayFilterLabel =
          filterKey === 'esg' ? (filterValue ? 'Yes' : 'No') : filterValue
        return (
          <div
            key={`${filterKey}_${index}`}
            id={`${filterKey}_${filterValue}`}
            className='strategy-search__applied'
            onClick={this.removeFilter}>
            {displayFilterLabel}
            <img
              src={stopIcon}
              alt='Remove Filter'
              className='strategy-search__remove'
            />
          </div>
        )
      })
    )
  }

  public render = () => {
    const { filter } = this.props.strategySearch
    return (
      <div className='strategy-search__tags'>
        {filter &&
          Object.keys(filter).map((key) => {
            return this.tagElement(key)
          })}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    strategySearch: store.strategySearch
  }
}

export default connect(mapStateToProps)(StrategyFilterTags)
