import React from 'react'
import Modal from '../../components/layout/modal'
import TextArea from '../../components/TextArea'
import FundedScoreGraph from '../../components/fundedScoreGraph'
import { ReactComponent as FinancialGoalsIcon } from '../../assets/images/icons/score.svg'
import moment from 'moment'

import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../objects/financialGoal'

interface FundedScoreDetailsProp {
  fundedScoreObj: FundedScoreObj
  rangeHigh?: number
  rangeLow?: number
  planningSoftware: PlanningSoftwareType
  saveFunc(fundedScoreObj: FundedScoreObj): void
  closeModal(): void
}

interface FundedScoreDetailsState {
  notes: string
  isBlank: boolean
}

class FundedScoreDetails extends React.Component<
  FundedScoreDetailsProp,
  FundedScoreDetailsState
> {
  constructor(props: FundedScoreDetailsProp) {
    super(props)
    const notes: string =
      props.fundedScoreObj && props.fundedScoreObj.notes
        ? props.fundedScoreObj.notes
        : ``
    this.state = {
      notes,
      isBlank: false
    }
  }

  public saveScoreComment = () => {
    const { fundedScoreObj } = this.props
    this.props.saveFunc({ ...fundedScoreObj, notes: this.state.notes })
  }

  public deleteScoreComment = () => {
    this.props.saveFunc(null)
  }

  public setInputState = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget
    this.setState({
      notes: value
    })
  }

  public render() {
    const { closeModal, fundedScoreObj, rangeHigh, rangeLow } = this.props
    const { isBlank } = this.state
    const lastUpdated = moment(fundedScoreObj.lastUpdated).format('M/DD/YY')

    return (
      <Modal
        icon={FinancialGoalsIcon}
        title='SCORE DETAILS'
        closeModal={closeModal}>
        <div className='funded-score-edit-comment'>
          <div className='funded-score-edit-comment__graph'>
            <div>Score updated on {lastUpdated}</div>
            <FundedScoreGraph
              rangeLow={rangeLow}
              rangeHigh={rangeHigh}
              score={fundedScoreObj.score}
              planningSoftware={this.props.planningSoftware}
              style={{ margin: '20px 0px' }}
            />
          </div>

          <div className='funded-score-edit-comment__content'>
            <p>
              <label>Notes</label>
            </p>
            <TextArea
              controlFunc={this.setInputState}
              value={this.state.notes}
              name='notes'
              maxlength={300}
              noresize={true}
              error={isBlank}
            />
          </div>

          <div className='funded-score-edit-comment__buttons'>
            <div />

            <div>
              <span className='btn btn__clear' onClick={closeModal}>
                Cancel
              </span>
              <span className='btn btn__prime' onClick={this.saveScoreComment}>
                Save
              </span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default FundedScoreDetails
