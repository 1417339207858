import * as React from 'react'
import AvatarEditor from 'react-avatar-editor'

import { ReactComponent as profileIcon } from '../../assets/images/icons/avatar.svg'
import { ReactComponent as uploadIcon } from '../../assets/images/icons/upload.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import uploadCloudIcon from '../../assets/images/icons/uploadcloud.svg'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import { UserObj } from '../../../objects/user'

interface ProfilePhotoModalProps {
  user: UserObj
  showEditModal: boolean
  showUploadModal: boolean
  toggleEditModal(): void
  toggleUploadModal(): void
  uploadFn(fileName: string, photo: string): void
  deleteFn(): void
}

interface ProfilePhotoModalState {
  photo: string
  fileName: string
  editor: any
}

class ProfilePhotoModal extends React.Component<
  ProfilePhotoModalProps,
  ProfilePhotoModalState
> {
  constructor(props: ProfilePhotoModalProps) {
    super(props)
    this.state = {
      photo: props.user.photo,
      fileName: props.user.fileName,
      editor: null
    }
  }

  public setEditorRef = (editor: any) => this.setState({ editor })

  public componentDidUpdate(nextProps: ProfilePhotoModalProps) {
    if (this.props.user.photo !== nextProps.user.photo) {
      this.setState({
        photo: this.props.user.photo,
        fileName: this.props.user.fileName
      })
    }
  }

  public setPhoto = (event: any) => {
    this.setState(
      {
        photo: URL.createObjectURL(event.target.files[0]),
        fileName: event.target.files[0].name
      },
      () => {
        this.props.toggleUploadModal()
        this.props.toggleEditModal()
      }
    )
  }

  public deletePhoto = () => {
    this.props.deleteFn()
    this.props.toggleEditModal()
    this.props.toggleUploadModal()
  }

  public uploadPhoto = () => {
    const canvas = this.state.editor.getImageScaledToCanvas()
    const image = canvas.toDataURL().split('base64,')[1]
    this.props.uploadFn(this.state.fileName, image)
  }

  public cancleButton = () => {
    this.props.toggleEditModal()
  }

  public renderEditModal = () => {
    return (
      <React.Fragment>
        <Modal
          title='EDIT PROFILE IMAGE'
          closeModal={this.props.toggleEditModal}
          icon={profileIcon}>
          <div className='edit-modal'>
            <AvatarEditor
              ref={this.setEditorRef}
              image={this.state.photo}
              width={300}
              height={300}
              border={[150, 0]}
              borderRadius={600}
              color={[255, 255, 255, 0.6]}
              scale={1.2}
              crossOrigin=''
            />
            <div className='edit-modal__overlay'>
              <hr className='edit-modal__overlay--horizontal-line' />
              <hr className='edit-modal__overlay--horizontal-line2' />
              <hr className='edit-modal__overlay--vertical-line' />
              <hr className='edit-modal__overlay--vertical-line2' />
            </div>
            <p className='edit-modal__message'>
              Drag the image to reposition it
            </p>
          </div>
          <div className='edit-modal__controls'>
            <button
              className='edit-modal__delete-btn'
              onClick={this.deletePhoto}>
              <span className='edit-modal__delete-icon'>
                <DeleteIcon />
              </span>
              <span className='edit-modal__delete-text'>Delete Image</span>
            </button>
            <div className='edit-modal__btn-w'>
              <Button onClick={this.cancleButton} clear={true}>
                Cancel
              </Button>
              <Button onClick={this.uploadPhoto} primary={true}>
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }

  public renderUploadModal = () => {
    const { toggleUploadModal } = this.props
    return (
      <Modal
        title='UPLOAD PROFILE IMAGE'
        icon={uploadIcon}
        closeModal={toggleUploadModal}>
        <div className='upload-modal'>
          <img
            className='upload-modal__icon'
            src={uploadCloudIcon}
            alt='upload cloud icon'
          />
          <p className='upload-modal__text'>Drag & Drop an Image</p>
          <input
            className='upload-modal__input'
            type='file'
            title=' '
            onChange={this.setPhoto}
          />
        </div>
        <div className='upload-modal__divider-text'>
          <div className='upload-modal__line' /> or{' '}
          <div className='upload-modal__line' />
        </div>
        <div className='upload-modal__button-w'>
          <input
            className='upload-modal__button'
            type='file'
            title=' '
            onChange={this.setPhoto}
          />
          Browse Image
        </div>
      </Modal>
    )
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.showEditModal ? this.renderEditModal() : null}
        {this.props.showUploadModal ? this.renderUploadModal() : null}
      </React.Fragment>
    )
  }
}

export default ProfilePhotoModal
