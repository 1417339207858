import React from 'react'
import TableRow from '../../components/layout/tableRow'
import Tooltip from '../../components/tooltip'
import HouseholdDisplay from './householdDisplay'
import { ContactsInterface } from '../../../objects/contact'
import HouseholdProgress from './householdProgress'
import HouseholdTextDisplay from './householdTextDisplay'
import HouseholdTotalProgress from './householdTotalProgress'
import { Progress } from '../../../objects/household'
import { dollarFormat, timeFormat, dateFormat } from '../../helpers'
import { history } from '../../../store'

export interface HouseholdRowProps {
  id: string
  name: string
  contacts: ContactsInterface
  fundedScore: number
  goalsProgress: Progress
  palProgress: Progress
  ivProgress: Progress
  netWorth: number
  hcProgress: Progress
  hcProgress2: Progress
  mmProgress: Progress
  taskCount: number
  lastLogin: string
  hasNetworthAccountErrors: boolean
}

class HouseholdRow extends React.Component<HouseholdRowProps> {
  public onClick = () => {
    const { id } = this.props
    history.push(`/households/${id}/dashboard`)
  }
  public render() {
    const {
      id,
      name,
      contacts,
      fundedScore,
      ivProgress,
      palProgress,
      goalsProgress,
      netWorth,
      hcProgress,
      hcProgress2,
      mmProgress,
      lastLogin,
      taskCount,
      hasNetworthAccountErrors
    } = this.props
    const primaryPhoto = contacts?.primary?.photo
    const secondaryPhoto = contacts?.secondary?.photo
    const roundedScore = fundedScore && Math.round(fundedScore)

    return (
      <TableRow key={id} onClick={this.onClick}>
        <div className='households__column households__column--name'>
          <HouseholdDisplay
            name={name}
            primaryFirstName={contacts?.primary?.firstName}
            primaryLastName={contacts?.primary?.lastName}
            secondaryFirstName={contacts?.secondary?.firstName}
            secondaryLastName={contacts?.secondary?.lastName}
            primaryPhoto={primaryPhoto}
            secondaryPhoto={secondaryPhoto}
            hasSecondary={Boolean(contacts?.secondary)}
          />
        </div>
        <HouseholdProgress
          to={`/households/${id}/moneyMind`}
          progress={mmProgress}
          className='households__column--mm'
        />
        <HouseholdProgress
          to={`/households/${id}/honestConversations`}
          progress={hcProgress2 || hcProgress}
          className='households__column--hc'
        />
        <HouseholdProgress
          to={`/households/${id}/investments`}
          progress={ivProgress}
          className='households__column households__column--iv'
        />
        <HouseholdProgress
          to={`/households/${id}/priorityActions`}
          progress={goalsProgress || palProgress}
          className='households__column--goals'
        />
        <HouseholdTextDisplay
          to={`/households/${id}/netWorth`}
          text={dollarFormat(netWorth, 0)}
          className='households__column--net-worth households__column--right-aligned'>
          {hasNetworthAccountErrors ? (
            <Tooltip
              message='Attention needed.'
              width={124}
              position='right'
              style={{ top: '4px', right: '-7px', position: 'absolute' }}>
              <div className='households__column__warning' />
            </Tooltip>
          ) : null}
        </HouseholdTextDisplay>
        <HouseholdTextDisplay
          text={roundedScore}
          to={`/households/${id}/financialGoals`}
          className='households__column--score'
        />
        <HouseholdTextDisplay
          text={taskCount}
          to={`/households/${id}/tasks`}
          className='households__column--tasks'
        />
        <HouseholdTextDisplay
          to={`/households/${id}/clientSettings`}
          className='households__column--last-login'
          text={
            lastLogin ? dateFormat(lastLogin) + ' ' + timeFormat(lastLogin) : ''
          }
        />
        <HouseholdTotalProgress
          to={`/households/${id}/dashboard`}
          className='households__column--progress'
          fundedScore={roundedScore}
          ivProgress={ivProgress}
          palProgress={palProgress}
          goalsProgress={goalsProgress}
          netWorth={netWorth}
          hcProgress={hcProgress2 || hcProgress}
          mmProgress={mmProgress}
          lastLogin={lastLogin}
        />
      </TableRow>
    )
  }
}

export default HouseholdRow
