import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'

export const FETCH_CONTACTS = 'FETCH_CONTACTS'
export const FETCH_CONTACTS_PENDING = 'FETCH_CONTACTS_PENDING'
export const FETCH_CONTACTS_REJECTED = 'FETCH_CONTACTS_REJECTED'
export const FETCH_CONTACTS_FULFILLED = 'FETCH_CONTACTS_FULFILLED'
export const FETCH_CONTACT = 'FETCH_CONTACT'
export const FETCH_CONTACT_PENDING = 'FETCH_CONTACT_PENDING'
export const FETCH_CONTACT_REJECTED = 'FETCH_CONTACT_REJECTED'
export const FETCH_CONTACT_FULFILLED = 'FETCH_CONTACT_FULFILLED'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_CONTACT_PENDING = 'UPDATE_CONTACT_PENDING'
export const UPDATE_CONTACT_REJECTED = 'UPDATE_CONTACT_REJECTED'
export const UPDATE_CONTACT_FULFILLED = 'UPDATE_CONTACT_FULFILLED'
export const ENABLE_GC = 'ENABLE_GC'
export const ENABLE_GC_PENDING = 'ENABLE_GC_PENDING'
export const ENABLE_GC_REJECTED = 'ENABLE_GC_REJECTED'
export const ENABLE_GC_FULFILLED = 'ENABLE_GC_FULFILLED'
export const SEND_WELCOME_EMAIL = 'SEND_WELCOME_EMAIL'
export const SEND_WELCOME_EMAIL_PENDING = 'SEND_WELCOME_EMAIL_PENDING'
export const SEND_WELCOME_EMAIL_REJECTED = 'SEND_WELCOME_EMAIL_REJECTED'
export const SEND_WELCOME_EMAIL_FULFILLED = 'SEND_WELCOME_EMAIL_FULFILLED'
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD'
export const SEND_RESET_PASSWORD_PENDING = 'SEND_RESET_PASSWORD_PENDING'
export const SEND_RESET_PASSWORD_REJECTED = 'SEND_RESET_PASSWORD_REJECTED'
export const SEND_RESET_PASSWORD_FULFILLED = 'SEND_RESET_PASSWORD_FULFILLED'
export const GET_USERNAME: string = 'GET_USERNAME'
export const GET_USERNAME_PENDING: string = 'GET_USERNAME_PENDING'
export const GET_USERNAME_FULFILLED: string = 'GET_USERNAME_FULFILLED'
export const GET_USERNAME_REJECTED: string = 'GET_USERNAME_REJECTED'
export const SET_ACTIVE_MONEY_MIND: string = 'SET_ACTIVE_MONEY_MIND'
export const SET_ACTIVE_MONEY_MIND_PENDING: string =
  'SET_ACTIVE_MONEY_MIND_PENDING'
export const SET_ACTIVE_MONEY_MIND_FULFILLED: string =
  'SET_ACTIVE_MONEY_MIND_FULFILLED'
export const SET_ACTIVE_MONEY_MIND_REJECTED: string =
  'SET_ACTIVE_MONEY_MIND_REJECTED'
export const UPDATE_IDEAL_ZONE: string = 'UPDATE_IDEAL_ZONE'
export const CONTACTS_LAST_LOGIN = 'CONTACTS_LAST_LOGIN'
export const CONTACTS_LAST_LOGIN_PENDING = 'CONTACTS_LAST_LOGIN_PENDING'
export const CONTACTS_LAST_LOGIN_REJECTED = 'CONTACTS_LAST_LOGIN_REJECTED'
export const CONTACTS_LAST_LOGIN_FULFILLED = 'CONTACTS_LAST_LOGIN_FULFILLED'
export const UNLOCK_ACCOUNT = 'UNLOCK_ACCOUNT'
export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT'
export const REACTIVATE_ACCOUNT = 'REACTIVATE_ACCOUNT'
export const FETCH_CONTACT_LOGIN_HISTORY = 'FETCH_CONTACT_LOGIN_HISTORY'
interface ContactResponse {
  data: ContactResponseData
}

interface ContactResponseData {
  householdId?: string
  contactId?: string
  hasError?: boolean
}

export const getContacts = () => {
  return {
    type: FETCH_CONTACTS,
    payload: api()
      .get('/clients')
      .then((response) => {
        const { meta, errors } = response.data || {}
        const trueStatus = meta?.status ?? response.status
        if (trueStatus >= 400) {
          console.log('Household fetch error', errors)
          throw new Error('Unable to fetch households')
        }
        return response
      })
  }
}

export const getContact = (contactId: string) => {
  return {
    type: FETCH_CONTACT,
    payload: api()
      .get('/clients/' + contactId)
      .then((response) => {
        return response
      })
  }
}

export const impersonateContact = (contactId: string, accessToken: string) => {
  return {
    type: 'test',
    payload: api()
      .get(
        `${window._env_.REACT_APP_GUIDECENTER_URL}?token=${accessToken}&contact=${contactId}`
      )
      .then((response) => {
        console.log(response)
      })
  }
}

export const enableGC = (
  householdId: string,
  contactId: string,
  email: string,
  username: string
) => {
  const postBody = { email, username, experience: 'CX' }
  return {
    type: ENABLE_GC,
    payload: api()
      .post(`/clients/${contactId}/gcEnable`, postBody)
      .then((response) => {
        response.data = {}
        response.data.householdId = householdId
        response.data.contactId = contactId
        response.data.hasError = false
        return response
      })
      .catch((e) => {
        const response: ContactResponse = { data: {} }
        response.data.householdId = householdId
        response.data.contactId = contactId
        response.data.hasError = true
        return response
      })
  }
}

export const sendWelcomeEmail = (contactId: string) => {
  return {
    type: SEND_WELCOME_EMAIL,
    payload: api()
      .post(`/clients/${contactId}/welcomeEmail`)
      .then((response) => {
        return response
      })
  }
}

export const sendResetPassword = (contactId: string) => {
  return {
    type: SEND_RESET_PASSWORD,
    payload: api()
      .post(`/clients/${contactId}/resetPassword`)
      .then((response) => {
        return response
      })
  }
}

export const unlockAccount = (contactId: string) => {
  return {
    type: UNLOCK_ACCOUNT,
    payload: api()
      .post(`/clients/${contactId}/unlock`)
      .then((response) => {
        return response
      })
  }
}

export const deactivateAccount = (contactId: string) => {
  return {
    type: DEACTIVATE_ACCOUNT,
    payload: api()
      .post(`/clients/${contactId}/deactivate`)
      .then((response) => {
        return response
      })
  }
}

export const reactivateAccount = (contactId: string) => {
  return {
    type: REACTIVATE_ACCOUNT,
    payload: api()
      .post(`/clients/${contactId}/reactivate`)
      .then((response) => {
        return response
      })
  }
}

export const getUsername = (
  householdId: string,
  contactId: string,
  ignoredApiFields: string[] = []
) => {
  return {
    type: GET_USERNAME,
    payload: api()
      .get(`/clients/${contactId}/info`)
      .then((response) => {
        response.data.householdId = householdId
        response.data.contactId = contactId
        response.data.ignoredApiFields = ignoredApiFields
        return response
      })
  }
}

export const setActiveMoneyMind = (
  moneymindId: string,
  contactId: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: SET_ACTIVE_MONEY_MIND,
    payload: api()
      .put('/clients/' + contactId, { activeYmmResponseId: moneymindId })
      .then((response) => {
        return response
      })
  }
}

export const updateContact = (contactId: string, request: any) => {
  return {
    type: UPDATE_CONTACT,
    payload: api()
      .put('/clients/' + contactId, request)
      .then((response) => {
        return response
      })
  }
}

export const updateIdealZone = (contactId: string, score: number) => {
  const requestBody = { benchmarkClientPortal: score * 10 }
  return {
    type: UPDATE_IDEAL_ZONE,
    payload: api()
      .put(`/clients/${contactId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const getContactsLastLogin = () => {
  return {
    type: CONTACTS_LAST_LOGIN,
    payload: api()
      .get(`/loginhistory?latest=true`)
      .then((response) => {
        return response.data
      })
  }
}

export const getContactLoginHistory = (
  householdIds: string,
  clientId: string
) => {
  return {
    type: FETCH_CONTACT_LOGIN_HISTORY,
    payload: api()
      .get(`/loginhistory?householdIds=${householdIds}&clientId=${clientId}`)
      .then((response) => {
        return response.data
      })
  }
}
