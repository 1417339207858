import React, { Component, Fragment } from 'react'
import AddPartnerModal from './partnerLoginAddPartnerModal'
import * as householdActions from '../../../actions/households'

import { onlinePortalObj } from '../../helpers'
import { GlobalState } from '../../../reducers'
import { Dispatch, connect } from 'react-redux'
import partnerNullImg from '../../assets/images/icons/null/accountsLock.png'

interface PartnerLoginProps {
  dispatch: Dispatch<GlobalState>
  onlinePortals: string[]
  householdFinId: string
  showAddPartnerModal: boolean
  toggleAddPartnerModal(): void
}

interface PartnerLoginState {
  onlinePortals: string[]
}

export class PartnerLogin extends Component<
  PartnerLoginProps,
  PartnerLoginState
> {
  constructor(props: PartnerLoginProps) {
    super(props)
    this.state = {
      onlinePortals: this.props.onlinePortals || []
    }
  }

  public componentDidUpdate(prevProps: any) {
    if (this.props && prevProps.onlinePortals !== this.props.onlinePortals) {
      this.setState({ onlinePortals: this.props.onlinePortals })
    }
  }

  public onlinePortalItem = (onlinePortals: string[]) => {
    return onlinePortalObj(onlinePortals).map(
      (onlinePortal: { name: string; img: string }, index: number) => {
        if (onlinePortal !== null) {
          return (
            <div key={index} className='partner__item'>
              <img src={onlinePortal.img} alt={onlinePortal.name} />
            </div>
          )
        } else {
          return null
        }
      }
    )
  }

  public onlinePortalNull = () => {
    return (
      <div className='partner__null'>
        <img src={partnerNullImg} alt='No partner image' />
        <h4>No partner logins</h4>
        <p>Click Edit Partner to add a partner.</p>
      </div>
    )
  }

  public editOnlinePortals = (onlinePortals: any) => {
    this.setState({ onlinePortals })
    const onlinePortalFormatted = onlinePortals.join(';')
    this.props.dispatch(
      householdActions.setOnlinePortals(
        this.props.householdFinId,
        onlinePortalFormatted
      )
    )
  }

  public render() {
    const { onlinePortals } = this.state
    return (
      <Fragment>
        <div className='partner-w'>
          {(onlinePortals?.length === 1 &&
            onlinePortals.indexOf('GuideCenter') !== -1) ||
          onlinePortals?.length === 0
            ? this.onlinePortalNull()
            : this.onlinePortalItem(onlinePortals)}
        </div>
        {this.props.showAddPartnerModal ? (
          <AddPartnerModal
            editOnlinePortals={this.editOnlinePortals}
            onlinePortals={onlinePortals}
            householdFinId={this.props.householdFinId}
            closeModal={this.props.toggleAddPartnerModal}
          />
        ) : null}
      </Fragment>
    )
  }
}

export default connect()(PartnerLogin)
