import React from 'react'
import Tile from '../../components/layout/tile'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderPriorityInterface,
  InvestmentViewfinderExerciseObj
} from '../../../objects/investmentViewfinder'
import { ReactComponent as PrioritiesIcon } from '../../assets/images/icons/ic_priorities.svg'
import { ReactComponent as PerformanceIcon } from '../../assets/images/icons/viewfinder_performance.svg'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/viewfinder_protection.svg'
import { ReactComponent as TaxesIcon } from '../../assets/images/icons/viewfinder_taxes.svg'
import { ReactComponent as CostsIcon } from '../../assets/images/icons/viewfinder_costs.svg'
import PriorityBar from './priorityBar'

interface PriorityResultsProps {
  priorities: InvestmentViewfinderPriorityInterface
  exercise: InvestmentViewfinderExerciseObj
}

class PriorityResults extends React.Component<PriorityResultsProps> {
  public render() {
    const { priorities, exercise } = this.props
    const priorityValues = {
      cost: exercise.cost,
      taxes: exercise.taxes,
      performance: exercise.performance,
      protection: exercise.protection
    }
    const sortedPriorityKeys = Object.keys(priorityValues).sort((a, b) => {
      const aPriority = priorityValues[a]
      const bPriority = priorityValues[b]
      return bPriority - aPriority
    })
    const primary = sortedPriorityKeys[0]
    const secondary = sortedPriorityKeys[1]
    return (
      <Tile
        icon={PrioritiesIcon}
        leftHeader='YOUR PRIORITIES'
        headerBorder={true}
        headerStyle={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: '#FAFAFA',
          justifyContent: 'left'
        }}>
        <div className='results__priorities-w'>
          <PriorityBar
            color={priorities.performance.color}
            Icon={PerformanceIcon}
            name={priorities.performance.label}
            score={exercise.performance}
            primary={primary === 'performance'}
            secondary={secondary === 'performance'}
          />
          <PriorityBar
            color={priorities.protection.color}
            Icon={ProtectionIcon}
            name={priorities.protection.label}
            score={exercise.protection}
            primary={primary === 'protection'}
            secondary={secondary === 'protection'}
          />
          <PriorityBar
            color={priorities.cost.color}
            Icon={CostsIcon}
            name={priorities.cost.label}
            score={exercise.cost}
            primary={primary === 'cost'}
            secondary={secondary === 'cost'}
          />
          <PriorityBar
            color={priorities.taxes.color}
            Icon={TaxesIcon}
            name={priorities.taxes.label}
            score={exercise.taxes}
            primary={primary === 'taxes'}
            secondary={secondary === 'taxes'}
          />
        </div>
      </Tile>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    priorities: store.investmentViewfinder.priorities
  }
}

export default connect(mapStateToProps)(PriorityResults)
