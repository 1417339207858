import React from 'react'
import TableRow from '../../components/layout/tableRow'

class ClientAccountRowNull extends React.Component<{}> {
  public nullRow = () => {
    return (
      <TableRow>
        <div className='client-accounts-tile__column--name ca-tile-null'>
          <div className='client-accounts-tile__account-name ca-tile-null__name' />
          <div className='client-accounts-tile__category-label ca-tile-null__label' />
        </div>
        <div className='client-accounts-tile__column--type client-accounts-tile__account-type ca-tile-null'>
          <div className='client-accounts-tile__category-label ca-tile-null__account-type' />
        </div>
        <div className='client-accounts-tile__column--amount ca-tile-null'>
          <div className='client-accounts-tile__amount ca-tile-null__amount' />
          <div className='client-accounts-tile__amount ca-tile-null__date' />
        </div>
        <div className='client-accounts-tile__column--actions ca-tile-null' />
        <div className='client-accounts-tile__column--show-hide ca-tile-null'>
          <div className='client-accounts-tile__amount ca-tile-null__actions' />
        </div>
        {/* <div className='client-accounts-tile__column--actions ca-tile-null' /> */}
      </TableRow>
    )
  }

  public render() {
    return (
      <div>
        {this.nullRow()}
        {this.nullRow()}
        {this.nullRow()}
        {this.nullRow()}
        {this.nullRow()}
      </div>
    )
  }
}

export default ClientAccountRowNull
