import React, { Component } from 'react'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'
import { UserObj } from '../../../../objects/user'
import { OfficeTeamMemberObj } from '../../../../objects/officeTeams'
import ToggleSwitch from '../../../components/toggleSwitch'
import ProfilePicture from '../../../components/layout/profilePicture'

interface TeamMemberRowProps {
  user: UserObj
  member: OfficeTeamMemberObj
  currentTeamId: string
  officeAdmin: boolean
  editFn(id: string, member: OfficeTeamMemberObj): void
  deleteFn(id: string): void
}

interface TeamMemberRowState {
  show: boolean
}

export class TeamMemberRow extends Component<
  TeamMemberRowProps,
  TeamMemberRowState
> {
  constructor(props: TeamMemberRowProps) {
    super(props)
    this.state = {
      show: this.props.member.showInGuidecenter
    }
  }

  public editMember = () => {
    const { currentTeamId, member, officeAdmin } = this.props
    if (officeAdmin) {
      member.showInGuidecenter = !member.showInGuidecenter
      this.props.editFn(currentTeamId, member)
      this.setState({ show: member.showInGuidecenter })
    }
  }

  public deleteMember = () => {
    this.props.deleteFn(this.props.user.id)
  }

  public render() {
    const { user } = this.props
    return (
      <div className='admin-settings__row  admin-settings__row--cols'>
        <div>
          <span
            className={
              user.photoUrl
                ? 'admin-settings__team-member-photo'
                : 'admin-settings__team-photo'
            }>
            <ProfilePicture
              firstName={user?.firstName}
              lastName={user?.lastName}
              photo={user?.photoUrl}
              isPrimary={true}
              pictureSize={32}
            />
          </span>
          <span>
            <h5 className='admin-settings__team-name'>
              {user.firstName + ' ' + user.lastName}
            </h5>
            <div className='admin-settings__team-email'>{user.email}</div>
          </span>
        </div>
        <div>
          <span className='admin-settings__team-title'>{user.title}</span>
        </div>
        <div>
          <div className='client-accounts-tile__column--show-hide'>
            <ToggleSwitch
              active={this.state.show}
              handleToggleActive={this.editMember}
            />
          </div>
          {this.props.officeAdmin && (
            <span
              className='admin-settings__team-delete'
              onClick={this.deleteMember}>
              <DeleteIcon />
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default TeamMemberRow
