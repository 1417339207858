import React, { Component } from 'react'
import Tile from '../../components/layout/tile'
import SubContainer from '../../components/layout/subContainer'

import Integrations from './integrations'
import { ReactComponent as SettingsIcons } from '../../assets/images/icons/settings.svg'
import { ReactComponent as LockIcon } from '../../assets/images/icons/ic_lock.svg'
import TeamManagement from './teams/teamManagement'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router'
import {
  OfficeTeamsInterface,
  OfficeTeamMemberObj,
  OfficeTeamObj
} from '../../../objects/officeTeams'
import * as officeTeamsActions from '../../../actions/officeTeams'
import Disclosure from '../../components/layout/disclosure'

import SalesforceSync from './integrations/salesforceSync'
import RedtailSync from './integrations/redtailSync'
import JunxureSync from './integrations/junxureSync'
import TamaracSync from './integrations/tamaracSync'
import WealthboxSync from './integrations/wealthboxSync'

import SalesforceIcon from '../../assets/images/logos/salesforce-logo.png'
import SalenticaIcon from '../../assets/images/logos/salentica-logo.png'
import Xlr8Icon from '../../assets/images/logos/xlr8.png'

import TamaracIcon from '../../assets/images/logos/tamarac-logo.png'
import SalenticaEngageIcon from '../../assets/images/logos/salentica-engage-logo.png'

interface AdminSettingsProps {
  match: any
  dispatch?: Dispatch<GlobalState>
  officeTeams: OfficeTeamsInterface
  officeAdmin: boolean
}

interface AdminSettingsState {
  activeTab: string
}

const activeTabOptions = {
  companyInformation: 'companyInformation',
  StaffInformation: 'StaffInformation',
  DueDiligence: 'DueDiligence',
  Documents: 'Documents',
  Disclosures: 'Disclosures',
  Integrations: 'Integrations',
  FinlifeBilling: 'FinlifeBilling',
  TeamManagement: 'TeamManagement'
}

export class Administration extends Component<
  AdminSettingsProps,
  AdminSettingsState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      activeTab: activeTabOptions.Integrations
    }
  }
  public componentDidMount() {
    this.props.dispatch(officeTeamsActions.getOfficeTeams())
  }
  public toggleIntegrations = () => {
    this.setState({ activeTab: activeTabOptions.Integrations })
  }
  public toggleTeamManagement = () => {
    this.setState({ activeTab: activeTabOptions.TeamManagement })
  }
  public createTeam = (name: string) => {
    this.props.dispatch(officeTeamsActions.createOfficeTeam(name))
  }
  public setTeam = (id: string) => {
    this.props.dispatch(officeTeamsActions.getEligibleUsers(id))
  }
  public editTeam = (id: string, name: string) => {
    this.props.dispatch(officeTeamsActions.editOfficeTeam(id, name))
  }
  public editTeamMembers = (
    officeTeamId: string,
    addIds: string[],
    deleteIds: string[]
  ) => {
    if (addIds.length > 0) {
      const members: OfficeTeamMemberObj[] = []
      addIds.forEach((userId) => {
        const member: OfficeTeamMemberObj = {
          userId,
          officeTeamId,
          showInGuidecenter: true,
          sendNotifications: true
        }
        members.push(member)
      })
      this.props.dispatch(
        officeTeamsActions.addOfficeTeamMembers(officeTeamId, members)
      )
    }
    if (deleteIds.length > 0) {
      this.props.dispatch(
        officeTeamsActions.deleteOfficeTeamMembers(officeTeamId, deleteIds)
      )
    }
  }
  public editTeamMember = (
    officeTeamId: string,
    member: OfficeTeamMemberObj
  ) => {
    this.props.dispatch(
      officeTeamsActions.editOfficeTeamMember(officeTeamId, member)
    )
  }
  public deleteTeam = async (team: OfficeTeamObj) => {
    const memberIds = team.members.map((member) => {
      return member.userId
    })
    await this.props.dispatch(
      officeTeamsActions.deleteOfficeTeamMembers(team.id, memberIds)
    )
    this.props.dispatch(officeTeamsActions.deleteOfficeTeam(team.id))
  }
  public deleteTeamMember = (id: string, memberId: string) => {
    this.props.dispatch(
      officeTeamsActions.deleteOfficeTeamMembers(id, [memberId])
    )
  }

  public adminSettingsRender = () => {
    const { activeTab } = this.state
    const { officeAdmin, officeTeams } = this.props
    return (
      <Tile
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <span className='tile__header-label'>
            <SettingsIcons /> ADMINISTRATION{' '}
          </span>
        }
        headerBorder={true}>
        <div className='c-settings-w'>
          <aside className='c-settings__menu'>
            <ul>
              <li
                onClick={this.toggleIntegrations}
                className={
                  activeTab === activeTabOptions.Integrations
                    ? 'c-settings__menu-item c-settings__menu-item--active'
                    : 'c-settings__menu-item'
                }>
                Integrations{' '}
              </li>
              <li
                onClick={this.toggleTeamManagement}
                className={
                  activeTab === activeTabOptions.TeamManagement
                    ? 'c-settings__menu-item c-settings__menu-item--active'
                    : 'c-settings__menu-item'
                }>
                Team Management {!officeAdmin && <LockIcon />}
              </li>
            </ul>
          </aside>
          <main className='c-settings a-settings__row-w'>
            {activeTab === activeTabOptions.Integrations ? (
              <Integrations />
            ) : null}
            {activeTab === activeTabOptions.TeamManagement ? (
              <TeamManagement
                officeAdmin={officeAdmin}
                officeTeams={officeTeams}
                createTeam={this.createTeam}
                setTeam={this.setTeam}
                editTeam={this.editTeam}
                deleteTeam={this.deleteTeam}
                deleteTeamMember={this.deleteTeamMember}
                editTeamMembers={this.editTeamMembers}
                editTeamMember={this.editTeamMember}
              />
            ) : null}
          </main>
        </div>
      </Tile>
    )
  }

  public render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={`${match.url}/integrations/salesforce`}>
          <SalesforceSync iconPng={SalesforceIcon} crm='salesforce' />
        </Route>
        <Route path={`${match.url}/integrations/xlr8`}>
          <SalesforceSync iconPng={Xlr8Icon} crm='xlr8' />
        </Route>
        <Route path={`${match.url}/integrations/salentica`}>
          <SalesforceSync iconPng={SalenticaIcon} crm='salentica' />
        </Route>
        <Route path={`${match.url}/integrations/redtail`}>
          <RedtailSync crm='redtail' />
        </Route>
        <Route path={`${match.url}/integrations/junxure`}>
          <JunxureSync crm='junxure' />
        </Route>
        <Route path={`${match.url}/integrations/tamarac`}>
          <TamaracSync crm='tamarac' iconPng={TamaracIcon} />
        </Route>
        <Route path={`${match.url}/integrations/ms_salentica_engage`}>
          <TamaracSync
            crm='ms_salentica_engage'
            iconPng={SalenticaEngageIcon}
          />
        </Route>
        <Route path={`${match.url}/integrations/wealthbox`}>
          <WealthboxSync crm='wealthbox' />
        </Route>
        <Route path={`/:integration`}>
          <SubContainer>
            {this.adminSettingsRender()}
            <Disclosure />
          </SubContainer>
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    match,
    officeTeams: store.officeTeams,
    officeAdmin: store.user.officeAdmin
  }
}

export default withRouter(connect(mapStateToProps)(Administration))
