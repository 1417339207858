import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'

export interface MeetingModeProps {
  meetingMode: boolean
  include?: boolean
  children: string | React.ReactNode | React.ReactNode[]
}

export class MeetingMode extends Component<MeetingModeProps> {
  public render() {
    const { children, include, meetingMode } = this.props

    if (include) {
      return <Fragment>{meetingMode ? children : null}</Fragment>
    } else {
      return <Fragment>{meetingMode ? null : children}</Fragment>
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    meetingMode: store.meetingMode.meetingMode
  }
}

export default connect(mapStateToProps)(MeetingMode)
