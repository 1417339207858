import { AxiosResponse } from 'axios'
import api from '../v3/helpers/api'
import { mapNewTaskObj } from '../v3/helpers/tasks'
import { ClientTask } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
import { TaskObj, TaskResponse } from '../objects/tasks'

export const SYNC_TASKS: string = 'SYNC_TASKS'
export const FETCH_ALL_TASKS: string = 'FETCH_ALL_TASKS'
export const FETCH_ALL_TASKS_V2: string = 'FETCH_ALL_TASKS_V2'
export const SET_TASKS: string = 'SET_TASKS'
export const FETCH_TASK: string = 'FETCH_TASK'
export const UPDATE_TASK: string = 'UPDATE_TASK'
export const CREATE_TASK: string = 'CREATE_TASK'
export const DELETE_TASK: string = 'DELETE_TASK'
export const UPDATE_TASK_BY_ID: string = 'UPDATE_TASK_BY_ID'

export const updateTaskObj = (request: TaskObj) => {
  const { primaryAssignee, secondaryAssignee, advisorAssignee } = request

  return {
    ...request,
    id: request.id,
    dueDate: request.dueDate ? `${request.dueDate}` : null,
    subject: request.subject ? `${request.subject}` : null,
    assigneeNames: request.assigneeNames ? `${request.assigneeNames}` : null,
    advisorAssignee:
      !primaryAssignee && !secondaryAssignee ? advisorAssignee : null,
    primaryAssignee: primaryAssignee ?? secondaryAssignee,
    secondaryAssignee:
      !primaryAssignee && secondaryAssignee ? null : secondaryAssignee,
    includeInGuidebook: request.includeInGuidebook
      ? request.includeInGuidebook
      : false,
    completedDate: request.completedDate ? request.completedDate : null
  }
}

export const syncTasks = (
  householdId: string
): { type: string; payload: Promise<AxiosResponse<ClientTask.Response>> } => {
  return {
    type: SYNC_TASKS,
    payload: api()
      .post(`/households/${householdId}/tasks/sync`)
      .then((response: AxiosResponse<TaskResponse>) => response)
  }
}

export const getTasks = (
  householdId: string
): { type: string; payload: Promise<AxiosResponse<ClientTask.Response>> } => {
  return {
    type: FETCH_ALL_TASKS,
    payload: api()
      .get(`/households/${householdId}/tasks?householdIds=${householdId}`)
      .then((response: AxiosResponse<TaskResponse>) => {
        response.data.householdId = householdId
        return response
      })
  }
}

export const setTasks = (payload: any): { type: string; payload: any } => {
  return {
    type: SET_TASKS,
    payload
  }
}

export const getTaskById = (
  householdId: string,
  clientTaskId: string
): { type: string; payload: Promise<AxiosResponse<ClientTask.Response>> } => {
  return {
    type: FETCH_TASK,
    payload: api()
      .get(`/households/${householdId}/tasks/${clientTaskId}`)
      .then((response: AxiosResponse<TaskResponse>) => {
        response.data.householdId = householdId
        return response
      })
  }
}

export const updateTask = (
  householdId: string,
  taskId: string,
  request: TaskObj,
  toggleShowHideReq?: boolean
): { type: string; payload: Promise<AxiosResponse<ClientTask.Response>> } => {
  // If toggleShowHide request and its a CRM task, use request as is, else use updateTaskObj mapper
  const updatedClientTask =
    toggleShowHideReq && request.assigneeType === 'user'
      ? request
      : updateTaskObj(request)
  return {
    type: UPDATE_TASK,
    payload: api()
      .put(`/households/${householdId}/tasks/${taskId}`, updatedClientTask)
      .then((response: AxiosResponse<TaskResponse>) => {
        response.data.householdId = householdId
        return response
      })
  }
}

export const createTaskById = (
  householdId: string,
  request: TaskObj
): { type: string; payload: Promise<AxiosResponse<ClientTask.Response>> } => {
  const newTaskObj = mapNewTaskObj(request, householdId)
  return {
    type: CREATE_TASK,
    payload: api()
      .post(`/households/${householdId}/tasks`, newTaskObj)
      .then((response: AxiosResponse<TaskResponse>) => {
        response.data.householdId = householdId
        return response
      })
  }
}

export const deleteTask = (
  householdId: string,
  clientTaskId: string
): { type: string; payload: Promise<AxiosResponse<ClientTask.Response>> } => {
  return {
    type: DELETE_TASK,
    payload: api()
      .delete(`/households/${householdId}/tasks/${clientTaskId}`)
      .then((response: AxiosResponse<TaskResponse>) => {
        return { ...response, householdId, clientTaskId }
      })
  }
}

/**
 * EDIT TASK RECORD BY ID
 * @param task : task new data for to edit/update the tasks
 */
export const updateTaskById = (task: any, householdFinId: string) => {
  return {
    type: UPDATE_TASK_BY_ID,
    payload: {
      data: { ...task, ...{ householdId: householdFinId } }
    }
  }
}
