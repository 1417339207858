export const SHOW_ALERT_MODAL: string = 'SHOW_ALERT_MODAL'
export const HIDE_ALERT_MODAL: string = 'HIDE_ALERT_MODAL'

export const showSyncModal = (message: string, isSuccess?: boolean) => {
  return {
    type: SHOW_ALERT_MODAL,
    payload: {
      show: true,
      message,
      isSuccess
    }
  }
}

export const hideSyncModal = () => {
  return {
    type: HIDE_ALERT_MODAL,
    payload: {
      show: false
    }
  }
}
