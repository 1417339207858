import React from 'react'
import Modal from './layout/modal'
import Button from './button'
import { getLoginUrl } from '../helpers/integrations'

export interface LinkCRMModalProps {
  crm: string
  v2ACL: boolean
  isIntegrations?: boolean
  title?: string
  closeModal?(): void
}

class LinkCRMModal extends React.Component<LinkCRMModalProps> {
  public onClick = () => {
    const { crm, v2ACL } = this.props
    sessionStorage.setItem('originalRoute', window.location.pathname)
    window.open(getLoginUrl(crm, v2ACL), '_self')
  }
  public render() {
    const { isIntegrations, title, closeModal } = this.props
    return (
      <Modal title={title} closeModal={closeModal}>
        <div className='modal__content'>
          {!isIntegrations ? (
            <div>
              It looks like your user is not linked to your CRM. Please click
              the button below to link.
            </div>
          ) : (
            <div>Please click the button below to link your CRM.</div>
          )}
          <div className='modal__buttons'>
            <Button primary={true} onClick={this.onClick}>
              Link CRM
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default LinkCRMModal
