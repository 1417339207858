import React from 'react'
import { HouseholdObj } from '../../../objects/household'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import Button from '../../components/button'
import { withRouter } from 'react-router'
import { ReactComponent as ViewfinderLogo } from '../../assets/images/logos/logo_iv.svg'
import { history } from '../../../store'

interface HeaderProps {
  household: HouseholdObj
}

class Header extends React.Component<HeaderProps> {
  public navigateToInvestments = () => {
    const { household } = this.props
    history.push(`/households/${household.id}/investments`)
  }
  public render() {
    const { household } = this.props
    return (
      <div className='ivf-header__w'>
        <div className='ivf-header__title-w'>
          <ViewfinderLogo className='ivf-header__logo' />
          <div className='ivf-header__title'>Investment Viewfinder</div>
          <div className='ivf-header__household-name'>
            {household && household.name}
          </div>
        </div>
        <div className='ivf-header__buttons'>
          {household && (
            <Button
              onClick={this.navigateToInvestments}
              style={{ backgroundColor: '#F2F2F2', color: '#255EBA' }}>
              Finish
            </Button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(Header))
