import React, {
  FunctionComponent,
  CSSProperties,
  ReactNode,
  SVGProps
} from 'react'
import Tile from './tile'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

export interface ModalProps {
  modalStyle?: CSSProperties
  className?: string
  iconPng?: string
  style?: CSSProperties
  headerStyle?: CSSProperties
  contentStyle?: CSSProperties
  modalOverlayClass?: string
  centerModal?: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  title?: string | React.ReactNode[] | React.ReactNode
  children: ReactNode | JSX.Element | JSX.Element[] | string
  size?: 'S' | 'M' | 'L'
  largeHeader?: boolean
  closeModal?(): void
}

class Modal extends React.Component<ModalProps> {
  public close = (e: any) => {
    e.stopPropagation()
    this.props.closeModal()
  }
  public closeButton = (): JSX.Element => {
    const { closeModal, className } = this.props
    const closeButton = (
      <div
        onClick={this.close}
        className={className ? `${className} modal__icon` : 'modal__icon'}>
        <CloseIcon style={{ pointerEvents: 'none' }} />
      </div>
    )
    return (
      <div className='modal__btn--close'>{closeModal ? closeButton : null}</div>
    )
  }
  public getWidth = () => {
    const { size } = this.props
    switch (size) {
      case 'S':
        return 430
      case 'M':
        return 600
      case 'L':
        return 820
      default:
        return 600
    }
  }
  public render() {
    const {
      icon,
      iconPng,
      title,
      children,
      style,
      contentStyle,
      centerModal,
      modalOverlayClass,
      modalStyle,
      largeHeader,
      className,
      headerStyle
    } = this.props

    const overlayClass = modalOverlayClass
      ? 'modal__overlay ' + modalOverlayClass
      : 'modal__overlay'
    const modalBoxW = centerModal
      ? 'modal__box-w modal__box-w--center'
      : 'modal__box-w'

    return (
      <div
        className={className ? `${className} ${overlayClass}` : overlayClass}
        style={modalStyle || null}>
        <div
          className={className ? `${className} ${modalBoxW}` : modalBoxW}
          style={{ maxWidth: this.getWidth() }}>
          <Tile
            largeHeader={largeHeader}
            icon={icon}
            iconPng={iconPng}
            leftHeader={title}
            style={style}
            contentStyle={contentStyle}
            headerStyle={headerStyle}
            headerBorder={true}
            rightHeader={this.closeButton()}>
            {children}
          </Tile>
        </div>
      </div>
    )
  }
}

export default Modal
