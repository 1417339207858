import {
  SortSettingProperty,
  SortType
} from '../../../objects/preferenceSortSetting'
import {
  GoalSortingType,
  FinancialGoalObj
} from '../../../objects/financialGoal'
import * as SortHelper from '../../helpers/householdPreferences'
import { GoalRequestObj } from '../../../objects/goalRequests'

export const sortOrderOptions: SortSettingProperty[] = [
  {
    value: 'ascending',
    label: 'Ascending'
  },
  {
    value: 'descending',
    label: 'Descending'
  }
]

export const sortByOptions: SortSettingProperty[] = [
  {
    value: 'name',
    label: 'Name'
  },
  {
    value: 'startDate',
    label: 'Period'
  },
  {
    value: 'amount',
    label: 'Amount'
  },
  {
    value: 'needsWantsWishes',
    label: 'Priority'
  }
]

export const translateSortTitle = (title: GoalSortingType) => {
  switch (title) {
    case 'name':
      return 'Name'
    case 'startDate':
      return 'Period'
    case 'amount':
      return 'Amount'
    case 'needsWantsWishes':
      return 'Priority'
    default:
      return null
  }
}

export const mapGoalPreferences = (
  assetSubType: string,
  preferences: SortType
) => {
  const mappedPrefs = {}
  const fieldNames = {
    'Spending Goal': {
      sortBy: 'goalsSpendingSortBy',
      sortingOrder: 'goalsSpendingSortingOrder'
    },
    'Retirement/Other Income': {
      sortBy: 'goalsRetirementSortBy',
      sortingOrder: 'goalsRetirementSortingOrder'
    },
    'Savings Goal': {
      sortBy: 'goalsSavingsSortBy',
      sortingOrder: 'goalsSavingsSortingOrder'
    },
    'Timing Control': {
      sortBy: 'goalsTimingSortBy',
      sortingOrder: 'goalsTimingSortingOrder'
    },
    'Portfolio Goal': {
      sortBy: 'goalsPortfoliosSortBy',
      sortingOrder: 'goalsPortfoliosSortingOrder'
    },
    'Legacy Goal': {
      sortBy: 'goalsLegacySortBy',
      sortingOrder: 'goalsLegacySortingOrder'
    }
  }
  Object.keys(preferences).forEach((prefType) => {
    const fieldName = fieldNames[assetSubType][prefType]
    mappedPrefs[fieldName] = preferences[prefType]
  })
  return mappedPrefs
}

export const findDateValue = (val: any) => {
  const valHasRequest =
    val.goalRequest && val.goalRequest.proposedStartOfGoal
      ? val.goalRequest.proposedStartOfGoal
      : null
  return (
    valHasRequest ||
    (val.startDate
      ? val.startDate
      : val.proposedStartOfGoal
      ? val.proposedStartOfGoal
      : null)
  )
}

export const sortGoals = (
  goals: (FinancialGoalObj | GoalRequestObj)[],
  optionValues: SortType
) => {
  const periodType = optionValues.type ? optionValues.type : null
  const sortBy = SortHelper.mapValueToOption(sortByOptions, optionValues.sortBy)
  const sortByVal = sortBy && sortBy.value ? sortBy.value : ''
  const sortingOrder = SortHelper.mapValueToOption(
    sortOrderOptions,
    optionValues.sortingOrder
  )
  const descending = sortingOrder && sortingOrder.value === 'descending'
  return Object.keys(goals)
    .sort((a, b) => {
      let acctSortA = goals[a][sortByVal] ? goals[a][sortByVal] : ''
      let acctSortB = goals[b][sortByVal] ? goals[b][sortByVal] : ''
      if (sortByVal === 'name') {
        acctSortA = goals[a][sortByVal]
          ? goals[a][sortByVal]
          : goals[a].proposedName
          ? goals[a].proposedName
          : ''
        acctSortB = goals[b][sortByVal]
          ? goals[b][sortByVal]
          : goals[b].proposedName
          ? goals[b].proposedName
          : ''
        return SortHelper.compareStringField(acctSortA, acctSortB, descending)
      }
      if (sortByVal === 'needsWantsWishes') {
        return SortHelper.compareStringField(acctSortA, acctSortB, descending)
      }
      if (sortByVal === 'startDate') {
        acctSortA = goals[a][sortByVal]
          ? goals[a][sortByVal]
          : goals[a].proposedStartOfGoal
          ? goals[a].proposedStartOfGoal
          : ''
        acctSortB = goals[b][sortByVal]
          ? goals[b][sortByVal]
          : goals[b].proposedStartOfGoal
          ? goals[b].proposedStartOfGoal
          : ''
        if (periodType) {
          acctSortA = findDateValue(goals[a])
          acctSortB = findDateValue(goals[b])
          return SortHelper.compareDates(acctSortA, acctSortB, descending)
        } else {
          return SortHelper.compareStringField(acctSortA, acctSortB, descending)
        }
      }
      if (sortByVal === 'amount') {
        // Regex: Remove special characters and whitespace
        acctSortA = goals[a][sortByVal]
          ? Number(goals[a][sortByVal].replace(/[^\w\s]/gi, ''))
          : goals[a].proposedAmount
        acctSortB = goals[b][sortByVal]
          ? Number(goals[b][sortByVal].replace(/[^\w\s]/gi, ''))
          : goals[b].proposedAmount
        return SortHelper.compareNumericField(acctSortA, acctSortB, descending)
      }
      return null
    })
    .map((key: string) => {
      return goals[key]
    })
}
