import React from 'react'
import finLifeLogo from '../../assets/images/logos/fin-life-partners-logo.png'

const Footer: React.StatelessComponent = () => (
  <div className='footer'>
    <div className='footer__wrapper'>
      <img className='footer__logo' src={finLifeLogo} alt='logo' />
      <div className='footer__links'>
        <p className='footer__links__united-capital'>United Capital</p>
        <div className='footer__divider' />
        <a
          className='footer__links__site-discloser'
          target='_blank'
          href='https://www.unitedcapitalwealth.com/disclosures/'
          rel='noopener noreferrer'>
          Site Disclosures
        </a>
        <div className='footer__divider' />
        <a
          className='footer__links__glba-privacy-policy'
          target='_blank'
          href='https://unitedcapitalwealth.com/wp-content/uploads/documents/UCPrivacyNotice2023.pdf'
          rel='noopener noreferrer'>
          GLBA Privacy Policy
        </a>
        <div className='footer__divider' />
        <a
          className='footer__links__privacy-policy'
          target='_blank'
          href='https://www.unitedcapitalwealth.com/privacy-policy/'
          rel='noopener noreferrer'>
          Privacy Policy
        </a>
        <div className='footer__divider' />
        <a
          className='footer__links__terms-use'
          target='_blank'
          href='https://www.unitedcapitalwealth.com/wp-content/uploads/documents/United-Capital-Form-ADV-Part-2A.pdf'
          rel='noopener noreferrer'>
          Form ADV
        </a>
        <div className='footer__divider' />
        <a
          className='footer__links__terms-use'
          target='_blank'
          href='https://www.unitedcapitalwealth.com/terms/'
          rel='noopener noreferrer'>
          Terms of Use
        </a>
      </div>
      <div className='footer__copyright'>
        © {new Date().getFullYear()}. All Rights Reserved
      </div>
    </div>
  </div>
)

export default Footer
