import React, { Component, CSSProperties, Fragment } from 'react'
import Tooltip from '../components/tooltip'

interface CheckBoxProps {
  id?: string
  checked: boolean
  noToggleAllowed?: boolean
  label?: string
  style?: CSSProperties
  checkedBorder?: boolean
  toolTip?: boolean
  toolTipIcon?: string
  toolTipText?: string
  rounded?: boolean
  onChange(data?: string, checked?: boolean): void
  grayedOut?: boolean
}

export class CheckBox extends Component<CheckBoxProps> {
  public toggleChecked = () => {
    const { onChange, id, checked } = this.props
    onChange && onChange(id, !checked)
  }

  public borderCheckBox = () => {
    const {
      style,
      label,
      id,
      checked,
      noToggleAllowed,
      toolTip,
      toolTipIcon,
      toolTipText,
      rounded = false
    } = this.props
    const clsName = rounded ? '-rounded' : ''
    const optionalToggle = (): any => {
      const grayedClass = this.props.grayedOut
        ? `checkbox__check-rounded--grayed`
        : ''
      return (
        <span
          className={
            checked
              ? `checkbox__check${clsName} checkbox__check${clsName}--checked ${grayedClass}`
              : `checkbox__check${clsName} ${grayedClass}`
          }
        />
      )
    }
    const manditoryNoToggleAllowed = (): any => {
      const grayedClass = this.props.grayedOut
        ? `checkbox__check-rounded--grayed`
        : ''
      return (
        <span
          className={
            checked
              ? `checkbox__check${clsName} checkbox__check${clsName}--checked checkbox__check--no-toggle-allowed ${grayedClass}`
              : `checkbox__check${clsName} ${grayedClass}`
          }
        />
      )
    }
    //
    // noToggleAllowed check is required
    // for defaultChecked property warning
    //
    if (!noToggleAllowed) {
      return (
        <label className='checkbox-w' style={style} htmlFor={id}>
          {optionalToggle()}
          <input
            id={id}
            type='checkbox'
            onChange={this.toggleChecked}
            checked={checked}
            aria-checked={checked}
            className='checkbox__input'
          />
          {label ? <span className='checkbox__label'>{label}</span> : null}
          {toolTip ? (
            <div className='checkbox__tooltip'>
              <Tooltip position='left' message={toolTipText} width={200}>
                <img src={toolTipIcon} alt='tool tip icon' />
              </Tooltip>
            </div>
          ) : null}
        </label>
      )
    } else {
      return (
        <label
          className='checkbox-w checkbox-w--no-toggle-allowed'
          style={style}
          htmlFor={id}>
          {manditoryNoToggleAllowed}
          <input
            id={id}
            type='checkbox'
            defaultChecked={true}
            aria-checked={checked}
            className='checkbox__input'
          />
          {label ? <span className='checkbox__label'>{label}</span> : null}
          {toolTip ? (
            <div className='checkbox__tooltip'>
              <Tooltip position='left' message={toolTipText} width={200}>
                <img src={toolTipIcon} alt='tool tip icon' />
              </Tooltip>
            </div>
          ) : null}
        </label>
      )
    }
  }

  public noBorderCheckBox = () => {
    const { style, label, id, checked } = this.props
    return (
      <label className='checkbox-w' style={style} htmlFor={id}>
        {label ? (
          <span
            className={
              !checked
                ? 'checkbox__label'
                : 'checkbox__label checkbox__label--blue'
            }>
            {label}
          </span>
        ) : null}
        <input
          id={id}
          type='checkbox'
          onChange={this.toggleChecked}
          checked={checked}
          aria-checked={checked}
          className='checkbox__input'
        />
        <span
          className={
            checked
              ? 'checkbox__check checkbox__check--checked checkbox__check--no-border'
              : 'checkbox__check checkbox__check--no-border'
          }
        />
      </label>
    )
  }

  public render() {
    const { checkedBorder } = this.props
    return (
      <Fragment>
        {checkedBorder ? this.borderCheckBox() : this.noBorderCheckBox()}
      </Fragment>
    )
  }
}

export default CheckBox
