import { IMStrategiesObj } from './strategies'

export const assetStructureMap = {
  'Public Equity': [
    'Global Equity',
    'Non-US Equity',
    'US Equity',
    'Other Equity'
  ],
  'Fixed Income': ['Investment Grade Fixed Income', 'Other Fixed Income'],
  'Cash, Deposits & Money Market Funds': [
    'Cash',
    'Deposits & Money Market Funds'
  ],
  'Alternative Investments': [
    'Hedge Funds',
    'Other Alternative Investments',
    'Private Equity',
    'Real Estate'
  ],
  'Other Investments': ['Asset Allocation Investments'],
  'Managed Strategies': [
    'Strategic Core',
    'Active Managed',
    'Personalized Series'
  ]
}

export const accountMinimumMap = {
  '$0 - $10,000': {
    min: 0,
    max: 10000
  },
  '$10,000 - $25,000': {
    min: 10000,
    max: 25000
  },
  '$25,000 - $50,000': {
    min: 25000,
    max: 50000
  },
  '$50,000 - $100,000': {
    min: 50000,
    max: 100000
  },
  '$100,000 - $250,000': {
    min: 100000,
    max: 250000
  },
  '$250,000+': {
    min: 250000,
    max: 999999999
  }
}

export const taxStatusMap = {
  'Tax Deferred Accounts': 'Tax Deferred Accounts',
  'Taxable Accounts': 'Taxable Accounts'
}

export const esgFilterMap = {
  Yes: true,
  No: false
}

export const custodiansSelectMap = {
  Fidelity: 'Fidelity',
  'Charles Schwab': 'Charles Schwab',
  'TD Ameritrade': 'TD Ameritrade'
}

export const custodiansFilterKeys = {
  Fidelity: 'fidelity',
  'Charles Schwab': 'charlesSchwab',
  'TD Ameritrade': 'tdAmeritrade'
}

export type FilterDropdownTypes =
  | 'Select Custodians'
  | 'Select Strategies'
  | 'Select Managers'
  | 'Account Minimum'
  | 'Account Type'
  | 'ESG'

export interface StrategiesFilterObj {
  assetClassL2: string[]
  managedStrategies: string[]
}

export interface StrategySearchFilterObj {
  manager: string[]
  investmentMinimum: string[]
  taxStatus: string[]
  assetClassL2: string[]
  managedStrategies: string[]
  custodianSelection: string[]
  esg: string[]
}

export interface StrategySearchState {
  search: string
  filter: StrategySearchFilterObj
  strategies: IMStrategiesObj[]
  loading?: boolean
}
