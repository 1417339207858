import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { GlobalState } from '../../../../reducers'
import FundedScoreHistoryTile from './fundedScoreHistoryTile'
import InnerContainer from '../../../components/layout/innerContainer'

import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../../objects/financialGoal'
import BackToLink from '../../../components/layout/headerBackToLink'

interface FinancialGoalsHistoryProps {
  householdFinId: string
  fundedScores: FundedScoreObj[]
  planningSoftware: PlanningSoftwareType
}

class FinancialGoalsHistory extends React.Component<
  FinancialGoalsHistoryProps
> {
  public render() {
    const { householdFinId } = this.props

    return (
      <InnerContainer>
        <BackToLink
          to={`/households/${this.props.householdFinId}/financialGoals`}>
          Back to Financial Plan
        </BackToLink>
        <FundedScoreHistoryTile householdFinId={householdFinId} />
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    planningSoftware: store.households[match.params.householdFinId]
      ? store.households[match.params.householdFinId].planningSoftware
      : 'Manual'
  }
}

export default withRouter(connect(mapStateToProps)(FinancialGoalsHistory))
