import React, { ReactNode } from 'react'
import { Loader } from './loader'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { TimedLoaderInterface } from '../../../objects/timedLoader'
import { removeTimedLoader } from '../../../actions/timedLoader'
import { goalLoadersSelector } from '../../../selectors/v3/timedLoader'
interface TimedLoaderProps {
  children?: ReactNode
  closeFunc?(): void
  stacked?: boolean
  loaders: TimedLoaderInterface[]
  dispatch: Dispatch<GlobalState>
}

interface TimedLoaderState {
  timeouts: { [id: string]: NodeJS.Timer }[]
}

class TimedLoader extends React.Component<TimedLoaderProps, TimedLoaderState> {
  constructor(props: any) {
    super(props)
    this.state = {
      timeouts: []
    }
  }

  public setLoaderInstance = (loaderObj: TimedLoaderInterface) => {
    const { timer = 5000, id } = loaderObj
    const { timeouts } = this.state
    const isLoaderExist = timeouts.some((loader) => loader?.[id])
    if (!isLoaderExist && timer > 0) {
      const timeout = setTimeout(() => {
        this.unsetLoaderInstance({ id })
      }, timer)
      this.setState({
        timeouts: [...timeouts, ...[{ [id]: timeout }]]
      })
    }
  }

  public componentWillUnmount() {
    this.state.timeouts.forEach((instance) =>
      clearTimeout(Object.values(instance)?.[0])
    )
  }

  public unsetLoaderInstance = ({ id }: Pick<TimedLoaderInterface, 'id'>) => {
    const { dispatch } = this.props
    dispatch(removeTimedLoader(id))
  }

  public render() {
    const { loaders } = this.props
    return loaders.map((loader) => {
      this.setLoaderInstance(loader)
      const { id } = loader
      return (
        <Loader
          key={id}
          {...{ ...loader, ...{ stacked: true } }}
          closeFunc={() => this.unsetLoaderInstance({ id })}
        />
      )
    })
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    loaders: goalLoadersSelector(store)
  }
}

export default connect(mapStateToProps)(TimedLoader)
