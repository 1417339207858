import * as actions from '../actions/priorityActions'
import {
  PriorityActionInterface,
  PriorityActionObj,
  PalNoteTemplatesInterface,
  PalNoteTemplateObj
} from '../objects/priorityActions'
import { CardInterface } from '../objects/honestConversations'
import { CardType } from '@unitedcapitalfinancialadvisors/finlife-component-library'

export interface PriorityActionsState {
  [key: string]: PriorityActionInterface
}

const initState: PriorityActionsState = {}

const mapNoteTemplateList = (palItem: any): PalNoteTemplatesInterface => {
  return palItem && palItem.palNoteTemplates
    ? palItem.palNoteTemplates.map((action: PalNoteTemplateObj) =>
        mapNoteTemplate(action)
      )
    : null
}

const mapNoteTemplate = (action: PalNoteTemplateObj) => {
  return action
    ? {
        institutionId: action.institutionId,
        note: action.note,
        palItemId: action.palItemId,
        status: action.status
      }
    : null
}

const mapPalItem = (action: any): PriorityActionObj => {
  const status =
    action.status === 'None' ? 'NA | Pending Review' : action.status
  if (action) {
    return {
      id: action.id ? action.id : null,
      checkInDate: action.checkInDate ? action.checkInDate.split('T')[0] : null,
      notes: action.notes ? action.notes : null,
      palItem: action.palItem ? action.palItem : null,
      palNoteTemplates: mapNoteTemplateList(action),
      masterId: action.masterId ? action.masterId : null,
      name: action.name ? action.name : null,
      householdId: action.householdId ? action.householdId : null,
      toBeDiscussed: action.toBeDiscussed ? action.toBeDiscussed : null,
      createdDate: action.createdDate ? action.createdDate.split('T')[0] : null,
      status: status || null,
      cards: mapPalCards(action)
    }
  }
  return null
}

const mapPriorityActionList = (
  householdFinId: string,
  priorityActions: any[]
) => {
  const priorityActionList: PriorityActionInterface = {}
  priorityActions.forEach((action) => {
    priorityActionList[action.id] = mapPalItem(action)
  })
  const obj = {}
  obj[householdFinId] = priorityActionList
  return obj
}

const mapPalCards = (action: any) => {
  const obj: CardInterface = {}
  if (action && action.palItem) {
    action.palItem.hcCards.forEach(
      (hcCard: { id: string; title: string; type: string }) => {
        obj[hcCard.id] = {
          id: hcCard.id,
          title: hcCard.title,
          category: hcCard.type as CardType
        }
      }
    )
  }
  return obj
}

const PriorityActions = (
  state = initState,
  action: any
): PriorityActionsState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_PRIORITY_ACTION_LIST}_FULFILLED`:
      const pal = mapPriorityActionList(
        action.payload.data.householdFinId,
        action.payload.data
      )
      return { ...state, ...pal }
    case `${actions.UPDATE_PAL_STATUS}_FULFILLED`:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][
        action.payload.data.id
      ] = mapPalItem(action.payload.data)
      return newState
    case `${actions.UPDATE_PAL_NOTE}_FULFILLED`:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][
        action.payload.data.id
      ] = mapPalItem(action.payload.data)
      return newState
    case `${actions.UPDATE_PAL_ACTIVE}_FULFILLED`:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][
        action.payload.data.id
      ] = mapPalItem(action.payload.data)
      return newState
    case `${actions.UPDATE_PAL_DATE}_FULFILLED`:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][action.payload.data.id] = {
        ...state[action.payload.data.householdFinId][action.payload.data.id],
        ...mapPalItem(action.payload.data)
      }
      return newState
    case `${actions.CREATE_PRIORITY_ACTION_LIST}_FULFILLED`:
      return {
        ...state,
        ...mapPriorityActionList(
          action.payload.data.householdFinId,
          action.payload.data
        )
      }
    case `${actions.CREATE_NOTE_TEMPLATE}_FULFILLED`:
      newState[action.payload.data.householdFinId][
        action.payload.data.priorityActionId
      ].palNoteTemplates[action.payload.data.id] = mapNoteTemplate(
        action.payload.data
      )
      return newState
    default:
      return state
  }
}

export default PriorityActions
