import React, { Fragment, ReactEventHandler, KeyboardEvent } from 'react'
import Button from '../../components/button'
import { ReactComponent as AddIcon } from '../../assets/images/icons/plus.svg'
import { ReactComponent as VideoIcon } from '../../assets/images/icons/video.svg'
import GQLClient from '../../helpers/gqlClient'
import {
  MessageBodyQuery,
  CreateMessageReplyQuery
} from '../../helpers/queries'
import ActionDropdown from '../../components/actionDropdown'
import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'

interface MessageReplyProps {
  householdFinId: string
  disableVideoMessenger: boolean
  threadId: string
  userId: string
  userEmail: string
  onReply(): void
}

interface MessageReplyState {
  value: string
  showVideo: boolean
  rows: number
}

class MessageReply extends React.Component<
  MessageReplyProps,
  MessageReplyState
> {
  constructor(props: MessageReplyProps) {
    super(props)
    this.state = {
      value: '',
      showVideo: false,
      rows: 1
    }
  }

  public onChange = (e: any) => {
    if (!e.currentTarget.value.trim() && !this.state.value.trim()) {
      this.setState({ rows: 1, value: '' })
    } else {
      const oldRows = e.currentTarget.rows
      e.currentTarget.rows = 1
      const newRows = e.currentTarget.scrollHeight / 16
      if (newRows === oldRows) e.currentTarget.rows = newRows
      this.setState({ rows: newRows, value: e.currentTarget.value })
    }
  }

  public onKeyDown: ReactEventHandler = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    if (this.state.value && e.keyCode === 13 && !e.shiftKey) {
      this.sendMessage()
    }
  }

  public sendMessage = (videoMessage?: string) => {
    const { householdFinId, threadId, userId, onReply } = this.props
    const { value } = this.state
    const message =
      videoMessage && typeof videoMessage === 'string' ? videoMessage : value
    GQLClient.getClient()
      ?.mutate({
        mutation: CreateMessageReplyQuery,
        variables: { value: message, householdFinId, threadId, userId },
        refetchQueries: [
          {
            query: MessageBodyQuery,
            variables: { householdFinId, threadId }
          }
        ]
      })
      .then(() => {
        this.setState({ value: '', rows: 1 })
        setTimeout(onReply, 1000)
      })
  }

  public loadVideoAction = () => {
    return (
      <Fragment>
        <VideoIcon />
        <span className='action-dropdown__item-label'>Record a Video</span>
      </Fragment>
    )
  }

  public toggleCamera = () => {
    this.setState({ showVideo: !this.state.showVideo })
  }

  public closeCamera = () => {
    this.toggleCamera()
  }

  public saveVideo = (message: string) => {
    this.sendMessage(message)
    this.toggleCamera()
  }

  public additionalOptions = () => {
    const { disableVideoMessenger } = this.props
    const dropdownActions = []
    if (!disableVideoMessenger) {
      dropdownActions.push({
        element: this.loadVideoAction(),
        onClick: this.toggleCamera
      })
    }
    return (
      <Fragment>
        {dropdownActions.length > 0 ? (
          <ActionDropdown
            Icon={AddIcon}
            actions={dropdownActions}
            left={true}
          />
        ) : null}
      </Fragment>
    )
  }
  public render() {
    const { value, rows } = this.state
    return (
      <div className='message-reply-w'>
        <div className='message-reply-field-w'>
          {!window._env_.REACT_APP_DISABLE_CAMERA_TAG &&
            this.additionalOptions()}
          <textarea
            rows={rows}
            value={value}
            placeholder='Write a reply...'
            className='message-reply-field__input'
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
        </div>
        <Button
          primary={Boolean(value)}
          style={{ minWidth: '14%', padding: 9 }}
          onClick={value ? this.sendMessage : null}>
          {' '}
          Send{' '}
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    disableVideoMessenger:
      store.institution && store.institution.disableVideoMessenger
  }
}

export default connect(mapStateToProps)(MessageReply)
