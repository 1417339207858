import { TimedLoaderInterface } from '../objects/timedLoader'

export const REMOVE_TIMED_LOADER: string = 'REMOVE_TIMED_LOADER'
export const ADD_TIMED_LOADER: string = 'ADD_TIMED_LOADER'

export const removeTimedLoader = (id?: string) => {
  return {
    type: REMOVE_TIMED_LOADER,
    payload: { id }
  }
}

export const addTimedLoader = (timedLoaderObj: TimedLoaderInterface) => {
  return {
    type: ADD_TIMED_LOADER,
    payload: { data: timedLoaderObj }
  }
}
