import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Modal from '../../../components/layout/modal'
import {
  tamaracReceiver,
  salesforceReceiver,
  isSalesforce,
  isMSDynamicsCRM
} from '../../../helpers/integrations'
import {
  CRMTypes,
  MSDynamicsCRM,
  SalesforceCRM
} from '../../../../objects/institution'
import { GlobalState } from '../../../../reducers'
import { history } from '../../../../store'

import JunxureSyncReceiver from './junxureSyncReceiver'
import IntegrationsRow from './integrationsRow'
import SyncDispatchModal from '../../../components/loaderModal'
import Button from '../../../components/button'
import LinkCRMModal from '../../../components/linkCRMModal'

import TamaracAuth from './tamaracAuth'
import WealthboxAuth from './wealthboxAuth'
import RedtailLogin from './redtailLogin'
import JunxureAuth from './junxureAuth'

import SalesforceIcon from '../../../assets/images/logos/salesforce-logo.png'
import JunxureIcon from '../../../assets/images/logos/junxure-temp-logo.png'
import SalenticaIcon from '../../../assets/images/logos/salentica-logo.png'
import TamaracIcon from '../../../assets/images/logos/tamarac-logo.png'
import WealthboxIcon from '../../../assets/images/logos/wealthbox-logo--small.png'
import RedtailIcon from '../../../assets/images/logos/redtail-logo.png'
import Xlr8Icon from '../../../assets/images/logos/xlr8.png'
import SalenticaEngageIcon from '../../../assets/images/logos/salentica-engage-logo.png'

export interface IntegrationsProps {
  dispatch: Dispatch<GlobalState>
  crmSource: CRMTypes
  integration: CRMTypes
  userId: string
  v2ACL?: boolean
}

interface IntegrationsState {
  showSyncDispatchModal?: boolean
  loaderTimer: any
  showLinkCRMModal: boolean
  crmAuthRedirect: boolean
}

export class Integrations extends Component<
  IntegrationsProps,
  IntegrationsState
> {
  constructor(props: IntegrationsProps) {
    super(props)
    this.state = {
      showSyncDispatchModal: false,
      loaderTimer: null,
      showLinkCRMModal: false,
      crmAuthRedirect: false
    }
  }

  public componentDidMount() {
    const { crmSource, integration } = this.props
    isSalesforce(integration) && salesforceReceiver(crmSource as SalesforceCRM)
  }

  public componentDidUpdate(prevProps: IntegrationsProps) {
    isMSDynamicsCRM(this.props.crmSource as MSDynamicsCRM) &&
      prevProps.crmSource !== this.props.crmSource &&
      tamaracReceiver(this.props.crmSource as MSDynamicsCRM)
  }

  public toggleSyncDispatchModal = () => {
    // This logic shows the sync action dispatch modal for 15 sec and then reloads page
    // It should dispatch previewHousehold and only show sync action dispatch modal while households is null
    const displayTime: number = 15000

    const loaderTimer = setTimeout(() => {
      clearTimeout(this.state.loaderTimer)
      window.location.reload()
    }, displayTime)

    this.setState({
      showSyncDispatchModal: true,
      loaderTimer
    })
  }

  public nullState = () => {
    return <div>No Integrations have been set up yet.</div>
  }

  public navigateToSyncPage = () => {
    const { crmSource } = this.props
    if (
      isSalesforce(crmSource) &&
      crmSource !== 'xlr8' &&
      crmSource !== 'salentica'
    ) {
      history.push('/administration/integrations/salesforce')
    } else {
      history.push(`/administration/integrations/${crmSource}`)
    }
  }

  public crmIntegration = (): {
    integrationRow: React.ReactNode
  } => {
    const { crmSource } = this.props
    // Every CRM must be referenced in this switch.
    // If any value is missing then the sync button will not appear
    switch (crmSource) {
      case 'redtail':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={RedtailIcon}
              title={'Redtail'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      case 'junxure':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={JunxureIcon}
              title={'Junxure'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      case 'tamarac':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={TamaracIcon}
              title={'Tamarac'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      case 'ms_salentica_engage':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={SalenticaEngageIcon}
              title={'Salentica Engage'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }

      case 'wealthbox':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={WealthboxIcon}
              title={'Wealthbox'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      // Salesforce but with unique icons and title
      case 'xlr8':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={Xlr8Icon}
              title={'XLR8'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      // Salesforce but with unique icons and title
      case 'salentica':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={SalenticaIcon}
              title={'Salentica'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      // Every Custom Salesforce must be listed here.
      case 'salesforce':
      case 'orion':
      case 'rz_wealth':
      case 'captrust':
      case 'pax':
      case 'mosaic':
      case 'ayco':
      case 'brown':
      case 'salesforce_financial_services_cloud':
      case 'salesforce_pfm':
      case 'salesforce_financial_services_cloud_ayco':
      case 'salesforce_practifi':
      case 'salesforce_lamco':
      case 'salesforce_barber_osiwala':
      case 'sendero_practifi':
        return {
          integrationRow: (
            <IntegrationsRow
              icon={SalesforceIcon}
              title={'Salesforce'}
              onSyncClick={this.navigateToSyncPage}
            />
          )
        }
      default:
        return {
          integrationRow: this.nullState()
        }
    }
  }

  public onLinkCRMClick = () => {
    const { showLinkCRMModal } = this.state
    this.setState({ showLinkCRMModal: !showLinkCRMModal })
  }

  public toggleCRMAuthRedirect = () => {
    const { crmAuthRedirect } = this.state
    this.setState({ crmAuthRedirect: !crmAuthRedirect })
  }

  public redirectCRMAuth = () => {
    const { crmSource } = this.props
    switch (crmSource) {
      case 'junxure':
        return <JunxureAuth />
      case 'tamarac':
      case 'ms_salentica_engage':
        return <TamaracAuth crmSource={crmSource} />
      default:
        return null
    }
  }

  public crmList = () => {
    const { crmAuthRedirect } = this.state
    const { crmSource, v2ACL } = this.props
    switch (crmSource) {
      case 'salesforce':
      case 'orion':
      case 'rz_wealth':
      case 'captrust':
      case 'pax':
      case 'mosaic':
      case 'ayco':
      case 'brown':
      case 'xlr8':
      case 'salesforce_financial_services_cloud':
      case 'salesforce_financial_services_cloud_ayco':
      case 'salesforce_practifi':
      case 'salesforce_lamco':
      case 'salesforce_barber_osiwala':
      case 'salesforce_pfm':
      case 'sendero_practifi':
        return (
          <LinkCRMModal
            crm={crmSource}
            v2ACL={v2ACL}
            isIntegrations={true}
            title={'Link CRM'}
            closeModal={this.onLinkCRMClick}
          />
        )
      case 'redtail':
        return <RedtailLogin closeModal={this.onLinkCRMClick} />
      case 'wealthbox':
        return <WealthboxAuth closeModal={this.onLinkCRMClick} />
      case 'junxure':
      case 'tamarac':
      case 'ms_salentica_engage':
        return (
          <Modal title={'Link CRM'} closeModal={this.onLinkCRMClick}>
            {crmAuthRedirect ? this.redirectCRMAuth() : null}
            <div className='modal__content'>
              <div>Please click the button below to link your CRM.</div>
              <div className='modal__buttons'>
                <Button primary={true} onClick={this.toggleCRMAuthRedirect}>
                  Link CRM
                </Button>
              </div>
            </div>
          </Modal>
        )
      default:
        return (
          <Modal title={'Link CRM'} closeModal={this.onLinkCRMClick}>
            <div className='modal__content'>
              <div>
                Unable to link CRM. Please contact service desk for assistance.
              </div>
            </div>
          </Modal>
        )
    }
  }

  public render() {
    const { showSyncDispatchModal, showLinkCRMModal } = this.state
    const { integration, userId } = this.props
    const { integrationRow } = this.crmIntegration()
    return (
      <Fragment>
        {showLinkCRMModal ? this.crmList() : null}
        <div>
          <h2 className='sync__content-heading'>Integrations</h2>
        </div>
        <div className='a-settings__link-crm-row'>
          <p className='sync__content-info'>
            Please limit number of households to sync at one time to 100 or
            less.
          </p>
          <span>
            <Button primary={true} onClick={this.onLinkCRMClick}>
              Link CRM
            </Button>
          </span>
        </div>
        {integrationRow}
        {integration === 'junxure' ? (
          <JunxureSyncReceiver
            openSyncModal={this.navigateToSyncPage}
            userId={userId}
          />
        ) : null}
        {showSyncDispatchModal ? <SyncDispatchModal /> : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    crmSource:
      store.institution.crmSource &&
      store.institution.crmSource.replace(/[^\w\s]/gi, ''),
    integration: match.params.integration,
    userId: store.user.userId,
    v2ACL: store.user.v2ACL
  }
}

export default withRouter(connect(mapStateToProps)(Integrations))
