import React, { Component } from 'react'

import { dollarFormat } from '../../../helpers'
import { ClientAccountPositionsObj } from '../../../../objects/clientAccount'

interface HoldingsCardProps {
  position: ClientAccountPositionsObj
  setSelectedPosition(position: ClientAccountPositionsObj): void
  handleAllocationRevert(position: ClientAccountPositionsObj): void
}

class HoldingsCard extends Component<HoldingsCardProps> {
  public handleSelectPosition = () => {
    return this.props.setSelectedPosition(this.props.position)
  }

  public handleSelectRevert = () => {
    const { handleAllocationRevert, position } = this.props
    return handleAllocationRevert(position)
  }

  public render(): JSX.Element {
    const { position } = this.props
    return (
      <div className='holdings-card__card'>
        <div>
          <div className='holdings-card__card-row'>
            <div className='holdings-card__card-text--bold'>
              {position.tickerSymbol}
            </div>
            <div className='holdings-card__card-text--bold'>
              {dollarFormat(position.price, 0)}
            </div>
          </div>
          <div className='holdings-card__card-row'>
            <div className='holdings-card__card-text--thin'>
              {position.securityDescription}
            </div>
            <div className='holdings-card__card-text--thin holdings-card__card-text--right'>
              Qty: {position.quantity}
            </div>
          </div>
        </div>
        <div>
          <div className='holdings-card__card-row'>
            <div className='holdings-card__card-text-value'>
              {dollarFormat(position.value, 0)}
            </div>
          </div>
          <div className='holdings-card__card-row'>
            <div className='holdings-card__card-text-overridden'>
              {position.allocationOverridden ? (
                <div>
                  This holding has been overridden{' '}
                  <span
                    onClick={this.handleSelectRevert}
                    className='holdings-card__card-text-overridden--link'>
                    Revert
                  </span>
                </div>
              ) : (
                <span
                  onClick={this.handleSelectPosition}
                  className=' holdings-card__card-text-overridden--link'>
                  Override
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HoldingsCard
