import React, { Component } from 'react'
import Button from '../../components/button'

interface AssetAllocationNullProps {
  onBtnClick(): void
}

class AssetAllocationNull extends Component<AssetAllocationNullProps> {
  public render() {
    return (
      <div className='asset-null-w'>
        <div />
        <Button onClick={this.props.onBtnClick} primary={true}>
          Set Target
        </Button>
        <div className='asset-null__text'>
          TARGET <br />
          ALLOCATION
        </div>
      </div>
    )
  }
}

export default AssetAllocationNull
