import React, { FC, useState } from 'react'
import HonestConversationEditCardRow from '../hcEditCardRow'
import { ContactsInterface, ContactObj } from '../../../../../objects/contact'
import handleImage from '../../../../assets/images/icons/ic_handle.png'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../../objects/HonestConversations/card'
import { CardRankingObj } from '../../../../../objects/honestConversations'

interface HonestConversationNewIndividualCardsProps {
  cardOptions?: CardOptionsInterface
  householdFinId: string
  contacts: ContactsInterface
  primaryContactCards: CardRankingObj[]
  secondaryContactCards: CardRankingObj[]
  onCommentChange(index: number, contactType: string, comment: string): void
  onCardSelection(index: number, contactType: string, card: CardObj): void
  onDrag?(result: DropResult): void
  onBlur?(contactType: string, index: number, card: CardObj): void
}

const HonestConversationNewIndividualCards: FC<HonestConversationNewIndividualCardsProps> = (
  props
) => {
  const [disableInputs, setDisableInputs] = useState(false)

  const {
    contacts,
    primaryContactCards,
    secondaryContactCards,
    householdFinId
  } = props

  const onMouseDown = (providedProps: any) => (event: any) => {
    if (providedProps.dragHandleProps)
      providedProps.dragHandleProps.onMouseDown(event)

    if (event.defaultPrevented) event.currentTarget.focus()
  }

  const honestConversationRows = (
    contactType: string,
    cards: CardRankingObj[]
  ) => {
    return cards.map((card, index) => {
      const key = card?.card?.id ? `card${card?.card?.id}` : `card${index}`
      return (
        <Draggable key={key} draggableId={key} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                ...provided.draggableProps.style
              }}
              className='hc-draggable-row__wrapper'>
              <div
                className='hc-draggable-row__handle'
                {...provided.dragHandleProps}
                onMouseDown={onMouseDown(provided)}>
                <img src={handleImage} alt='' role='presentation' />
              </div>

              <div className='hc-card-row__wrapper'>
                <HonestConversationEditCardRow
                  key={key}
                  householdFinId={householdFinId}
                  displayIndex={index + 1}
                  index={index}
                  contactType={contactType}
                  card={card}
                  editMode={false}
                  selectedCards={cards}
                  cardOptions={props.cardOptions}
                  onCardSelection={props.onCardSelection}
                  onCommentChange={props.onCommentChange}
                  onBlur={props.onBlur}
                  setDisableInputs={setDisableInputs}
                  disableInputs={disableInputs}
                />
              </div>
            </div>
          )}
        </Draggable>
      )
    })
  }

  const onDragEnd = (result: DropResult) => {
    if (!result || !result.destination) {
      return
    }
    props.onDrag(result)
  }

  const contactCards = (
    contactType: string,
    contact: ContactObj,
    cards: CardRankingObj[]
  ) => {
    const contactTitle =
      contactType === 'primaryContactCards' ? 'Primary' : 'Secondary'
    const title = contact.firstName
      ? `${contact.firstName}'s Choices`
      : `${contactTitle} Choices`
    return (
      <div>
        <div className='hc-meeting__sub-header'>
          {contact.firstName && (
            <div className='hc-meeting__sub-header--title'>{title}</div>
          )}
        </div>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={contactType} direction='vertical'>
              {(provided, _snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {honestConversationRows(contactType, cards)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }

  if (contacts && (contacts.primary || contacts.secondary)) {
    return (
      <div>
        {contacts.primary &&
          contactCards(
            'primaryContactCards',
            contacts.primary,
            primaryContactCards
          )}
        {contacts.secondary &&
          contactCards(
            'secondaryContactCards',
            contacts.secondary,
            secondaryContactCards
          )}
      </div>
    )
  }

  return null
}

export default HonestConversationNewIndividualCards
