import { statusType } from '../../objects/lifeActions'
import {
  GoalActions,
  ProfileImgClients,
  FinancialGoalConfigObj,
  FinancialGoalActionObj
} from '../../objects/financialGoalActions'
import { HonestConversationsExerciseObj } from '../../objects/honestConversations'
import { CardObj } from '../../objects/HonestConversations/card'
import { ContactsInterface } from '../../objects/contact'
import { simpleDateFormat } from './index'
import { handleSort, contactCount } from './sort/goalActionSort'
import { dateToMilliseconds } from './date'
export const getStatusIcon = (status: string) => {
  let statusCls = ''
  switch (status) {
    case statusType.immediate:
      statusCls = 'red'
      break
    case statusType.improvement:
      statusCls = 'orange'
      break
    case statusType.optimal:
      statusCls = 'green'
      break
    case statusType.na:
      statusCls = 'na'
      break
    default:
      statusCls = 'na'
  }
  return statusCls !== '' ? `tile__status--${statusCls}` : ''
}

export const getClientData = (
  goal: FinancialGoalActionObj,
  contacts: ContactsInterface
) => {
  const types = ['Primary', 'Secondary']
  const clients: ProfileImgClients = {}
  types?.map((type) => {
    const typeLower = type.toLowerCase()
    if (goal[`assignee${type}Id`]) {
      clients[typeLower] = {
        firstName: contacts && contacts[typeLower]?.firstName,
        lastName: contacts && contacts[typeLower]?.lastName,
        photo: contacts && contacts[typeLower]?.photo,
        isPrimary: type === 'Primary'
      }
    }
  })
  return clients
}

export const filterGoals = (
  goals: FinancialGoalActionObj[],
  selectedCardId: string
) => {
  if (goals && selectedCardId) {
    const tileData: any = []
    Object.keys(goals).forEach((key, i) => {
      const action = goals[key]
      const selectedCardMatch =
        action.cards &&
        Object.keys(action.cards).filter(
          (cardKey) =>
            action.cards[cardKey].id === selectedCardId &&
            action.cards[cardKey].showCard
        ).length > 0
      if (selectedCardMatch) {
        tileData.push(action)
      }
    })
    return tileData
  } else return []
}

export const filterGoalsByDate = (goals: GoalActions[], dateKey?: string) => {
  let filteredGoals: GoalActions[] = []

  // Hide all the goals before today
  if (dateKey === 'TODAY') {
    filteredGoals = goals.filter((goal) => {
      const { createdDate } = goal
      // comparing the date by parsing into milliseconds without time  i.e "2021-01-28 -> 1611810000000"
      return (
        dateToMilliseconds(new Date(createdDate)) >=
        dateToMilliseconds(new Date())
      )
    })
  }

  return {
    filteredGoals,
    hasPreviousGoals: Boolean(goals.length > filteredGoals.length) // Check if the goals data before today exist
  }
}

export const getPrioritiesHCCards = (
  goal: FinancialGoalActionObj,
  honestConversation: HonestConversationsExerciseObj
) => {
  const cards = honestConversation?.householdCards
  const relaventCards: CardObj[] = []
  const palCardTitleArray = goal?.cards?.map((card: CardObj) =>
    card.title.toLowerCase()
  )
  if (cards) {
    Object.keys(cards).forEach((key) => {
      const card = cards[key]
      const indexOfCard = palCardTitleArray.indexOf(
        card?.card?.title?.toLowerCase()
      )
      if (card?.card?.title && palCardTitleArray && indexOfCard !== -1) {
        relaventCards.push(goal?.cards[indexOfCard])
      }
    })
  }
  return relaventCards
}

// NOTE: Any change in this function, will also be go to the below function
// reducer/financialGoalActions.ts -> Fn -> mapGoalActions
export const getTileConfig = (
  tileData: FinancialGoalActionObj[],
  honestConversation: HonestConversationsExerciseObj
): FinancialGoalConfigObj[] => {
  return tileData?.map((goal: FinancialGoalActionObj) => {
    return {
      id: goal?.id,
      type: goal?.type,
      isLifeAction: goal?.type === 'life',
      name: goal?.name,
      note: goal?.note,
      status: goal?.status,
      dueDate: simpleDateFormat(goal?.dueDate),
      isNew: false,
      client: goal?.client,
      inGuidecenter: goal?.inGuidecenter,
      cards: getPrioritiesHCCards(goal, honestConversation),
      palItemId: goal?.palItemId,
      archived: goal?.archived,
      createdDate: goal?.createdDate,
      customGoal: goal?.customGoal
    }
  })
}

export const getContactAvatars = (
  primaryId: string,
  secondaryId: string,
  contacts: ContactsInterface
): {
  source: string
  firstName: string
  lastName: string
  isPrimary: boolean
}[] => {
  const contactAvatars: {
    source: string
    firstName: string
    lastName: string
    isPrimary: boolean
  }[] = []

  if (contacts?.primary && contacts.primary.id === primaryId) {
    contactAvatars.push({
      source: contacts && contacts.primary.photo,
      firstName: contacts && `${contacts.primary.firstName}`,
      lastName: contacts && `${contacts.primary.lastName}`,
      isPrimary: true
    })
  }

  if (contacts?.secondary && contacts.secondary.id === secondaryId) {
    contactAvatars.push({
      source: contacts && contacts.secondary.photo,
      firstName: contacts && `${contacts.secondary.firstName}`,
      lastName: contacts && `${contacts.secondary.lastName}`,
      isPrimary: false
    })
  }

  return contactAvatars
}

export const sortByDefault = (goals: any[], hideInGuideCenter = true) => {
  //Sort ordering config
  const orderByStatus = [
    'Immediate Action Needed',
    'Improvement Needed',
    'Optimal Position',
    'NA | Pending Review'
  ]

  //filter the data by inGuideCenter true
  const filterGoals = hideInGuideCenter
    ? goals.filter((goal) => {
        return goal.inGuidecenter
      })
    : goals

  //Group the data by STATUS
  const groupData: any = filterGoals.reduce(
    (
      group: {
        [key: string]: FinancialGoalConfigObj[]
      },
      b: FinancialGoalConfigObj
    ) => {
      group[b.status] = [...(group[b.status] || []), b]
      return group
    },
    {}
  )

  //Sort each group and merge the whole set into one single array
  return orderByStatus.reduce((data, status) => {
    //Initiate the sorting
    const sortByDueDate = (groupData[status] || []).sort(
      (a: FinancialGoalConfigObj, b: FinancialGoalConfigObj) => {
        const sortA = a['dueDate'] || ''
        const sortB = b['dueDate'] || ''

        // Stick null values to the bottom in this group
        if (!sortA) return 1
        if (!sortB) return -1

        //Sort the data by dueDate in ascending order
        return handleSort('dueDate', a, b)
      }
    )

    //Return the Merge sorted groups data
    return [...data, ...sortByDueDate]
  }, [])
}

/**
 * ##############  START NESTED GOAL SORTINGS ###################
 */
type FinancialGoalActionObjGroup = {
  [dataKey: string]: FinancialGoalActionObj[]
}

export const deepSortGoals: any = ({
  goals,
  sortKey = 'due date', // DEFAULT: sortKey is 'due date' Refer: DAS-70901
  hideInGuideCenter = true // DeFAULT: is TRUE, will not show the goal having guidecenter is true
}: {
  goals: FinancialGoalActionObj[]
  hideInGuideCenter?: boolean
  sortKey: string
}): FinancialGoalActionObj[] => {
  if (goals?.length > 1) {
    // CONFIG DETAILS FOR NESTED SORTINGS
    const CONFIG = {
      // Add an identifier to capture the null data
      nullKey: 'empty',

      // Nested Sort order logic for each dataType
      sortBy: {
        status: ['status', 'dueDate', 'type', 'name', 'client'],
        'due date': ['dueDate', 'status', 'type', 'name', 'client'],
        type: ['type', 'dueDate', 'status', 'name', 'client'],
        name: ['name', 'dueDate', 'status', 'type', 'client'],
        owner: ['client', 'dueDate', 'status', 'type', 'name']
      },

      // return the selected sort group to deep sort the data
      sortConfigByKey() {
        return this.sortBy[sortKey?.toLowerCase()]
      },

      // Order to show the data in the list while merging back the data
      sortOrder: {
        status: [
          'Immediate Action Needed',
          'Improvement Needed',
          'Optimal Position',
          'NA | Pending Review'
        ],
        type: ['both', 'financial', 'life', 'empty'],

        // BOTH: 3 | PRIMARY : 1 | SECONDARY : 2 | NULL : 0
        client: ['3', '1', '2', '0']
      },

      // Use the common "handleSort" Fn for these sort
      genericSortList: ['name', 'due date']
    }

    // Utitlity Methods for Nesting Sortings
    const _ = {
      //Filter the data by in-guidecenter true
      filterGoals: () =>
        hideInGuideCenter
          ? goals.filter((goal) => {
              return goal.inGuidecenter
            })
          : goals,

      // Group the data by sort type
      groupByKey: (
        list: FinancialGoalActionObj[],
        dataKey: string
      ): FinancialGoalActionObjGroup => {
        return list?.reduce(
          (group: FinancialGoalActionObjGroup, b: FinancialGoalActionObj) => {
            const listNode = b[dataKey]?.toLowerCase
              ? b[dataKey]?.toLowerCase()
              : b[dataKey]
            const groupKey =
              dataKey === 'dueDate'
                ? listNode?.split('t')[0] || CONFIG.nullKey
                : dataKey === 'client'
                ? contactCount(listNode)
                : listNode || CONFIG.nullKey
            group[groupKey?.toString()] = [...(group[groupKey] || []), b]
            return group
          },
          {}
        )
      },

      // Create a group of deep objects by array data
      arrayToObjGroup: (
        config: string[],
        list: FinancialGoalActionObj[] | FinancialGoalActionObjGroup
      ): FinancialGoalActionObjGroup | FinancialGoalActionObj[] => {
        if (!config?.length) {
          return list
        }
        const KEY = config.shift()
        if (Array.isArray(list)) {
          return _.arrayToObjGroup(config.slice(), _.groupByKey(list, KEY))
        } else {
          const colData = {}
          Object.keys(list).forEach((goalKey) => {
            colData[goalKey] = _.arrayToObjGroup(
              config.slice(),
              _.groupByKey(list[goalKey], KEY)
            )
          })
          return colData
        }
      },

      // Flatten the deep object values into array
      objToSortedArray: (
        config: string[],
        list: FinancialGoalActionObjGroup | FinancialGoalActionObj[]
      ): FinancialGoalActionObj[] => {
        const key = config.shift()
        const sortedKeys = _.sortByKey(key, list as FinancialGoalActionObjGroup)
        const sortedData = sortedKeys.reduce(
          (listData: FinancialGoalActionObj[], b: string) => {
            const a = b?.toLowerCase ? b?.toLowerCase() : b
            if (list[a]) {
              if (Array.isArray(list[a])) {
                listData = listData.concat(list[a])
                return listData
              }
              const nestedData = _.objToSortedArray(config?.slice(), list[a])
              listData = listData.concat(nestedData)
              return listData
            }
            return listData
          },
          []
        )
        return sortedData
      },

      // sorting the group keys to pick the order data from the list of group data
      handleSort: (sortBy: string, a: string, b: string) => {
        if (sortBy === 'dueDate') {
          if (a === CONFIG.nullKey) return 1
          if (b === CONFIG.nullKey) return -1
          const sortA = dateToMilliseconds(new Date(a))
          const sortB = dateToMilliseconds(new Date(b))
          return sortA > sortB ? 1 : sortA < sortB ? -1 : 0
        } else if (sortBy === 'name') {
          return a?.toLowerCase().localeCompare(b?.toLowerCase())
        }
        return a > b ? 1 : a < b ? -1 : 0
      },

      // return the sorting object for custom sort group order
      sortByKey: (sortKey: string, data: FinancialGoalActionObjGroup) => {
        let sortConfig = CONFIG?.sortOrder?.[sortKey]

        if (!(sortConfig && sortConfig?.length)) {
          sortConfig = Object.keys(data)?.sort(
            (a: string, b: string): number => {
              return _.handleSort(sortKey, a, b)
            }
          )
        }
        return sortConfig
      }
    }

    // CALING THE NESTING SORTING HERE
    const sortConfig = CONFIG.sortConfigByKey() //i.e ['status', 'dueDate', 'type', 'name', 'client'],
    const filteredGoals = _.filterGoals() // Get Goals filtered by inGuideCenter prop
    if (sortConfig?.length) {
      // return the flatten sorted goals
      return _.objToSortedArray(
        sortConfig?.slice(), // pass the config to merge the goals data in this sorted order
        _.arrayToObjGroup(sortConfig?.slice(), filteredGoals) // Get all goals into grouped by key
      )
    } else {
      return filteredGoals
    }
  } else {
    return goals
  }
}
/**
 * ##############  END NESTED GOAL SORTINGS ###################
 */
