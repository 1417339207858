import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { getContacts } from '../../../../../actions/contacts'
import { dateSortScoringSession } from '../../../../helpers/honestConversations'
import {
  HonestConversationsExerciseObj,
  ScoringSessionsObj
} from '../../../../../objects/honestConversations'
import { ContactsInterface } from '../../../../../objects/contact'
import { getGraphOptions } from '../../../../components/graphs/honestConversations/scoreHistoryOptions'
import {
  dataSetTemplate,
  AverageScoreData,
  PRIMARY_LINE_COLOR,
  SECONDARY_LINE_COLOR
} from '../../../../../objects/lineChart'
import moment from 'moment'

import PriorityGraph from '../../display/priorityGraph'

interface ClientScoreHistoryGraphProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  exerciseId?: string
  contacts: ContactsInterface
  honestConversation: HonestConversationsExerciseObj
}

/**
 *  show graph based on average scores calculated from the scoring sessions
 *  of each cllent.
 */
class ClientScoreHistoryGraph extends Component<ClientScoreHistoryGraphProps> {
  public componentDidMount = () => {
    if (!this.props.contacts) {
      this.props.dispatch(getContacts())
    }
  }

  public getAverageScores = <T extends ScoringSessionsObj>(
    session: T
  ): number => {
    const scoreArray: number[] = []

    if (!session.cardScores) {
      return 0
    }

    Object.keys(session.cardScores).forEach((key) =>
      scoreArray.push(session.cardScores[key].score || 0)
    )
    if (scoreArray.length === 0) {
      return 0
    }
    return Math.round(
      scoreArray.reduce((total, num) => {
        return total + num
      }) / 5
    )
  }

  public getScores = (
    firstName: string,
    contactId: string,
    maxCount?: number
  ) => {
    const { honestConversation } = this.props
    // sort by createdDate
    const scoringSessions = dateSortScoringSession(
      honestConversation && honestConversation.clientScoringSessions[contactId]
    )

    const averageScores: number[] = [] // scores
    const scoreDates: string[] = [] // firstName:date labels
    if (scoringSessions) {
      scoringSessions.forEach((session, index: number) => {
        averageScores.push(this.getAverageScores(session))
        scoreDates.push(
          `${firstName}:${moment(session.createdDate).format('MM/DD/YYYY')}`
        )
      })
    }
    return {
      averageScores,
      scoresDate: scoreDates
    }
  }

  public pointColors = (numberArray: number[]) => {
    const pointColors: string[] = []
    numberArray.forEach((numberValue) => {
      pointColors.push(numberValue >= 7 ? '#A7D500' : '#FFBE1A')
    })
    return pointColors
  }

  public createDataSet = () => {
    const { contacts } = this.props

    let primaryAverageScoreData: AverageScoreData = (contacts?.primary &&
      this.getScores(contacts.primary.firstName, contacts.primary.id)) || {
      averageScores: [],
      scoresDate: []
    }

    let secondaryAverageScoreData: AverageScoreData = {
      averageScores: null,
      scoresDate: null
    }
    if (contacts?.secondary) {
      secondaryAverageScoreData = this.getScores(
        contacts.secondary.firstName,
        contacts.secondary.id
      )
      if (
        primaryAverageScoreData.averageScores.length <
        secondaryAverageScoreData.averageScores.length
      ) {
        primaryAverageScoreData = this.getScores(
          contacts?.primary?.firstName,
          contacts?.primary?.id,
          secondaryAverageScoreData.averageScores.length -
            primaryAverageScoreData.averageScores.length
        )
      }

      if (
        primaryAverageScoreData.averageScores.length >
        secondaryAverageScoreData.averageScores.length
      ) {
        secondaryAverageScoreData = this.getScores(
          contacts?.secondary?.firstName,
          contacts?.secondary?.id,
          primaryAverageScoreData.averageScores.length -
            secondaryAverageScoreData.averageScores.length
        )
      }
    }

    const data: { labels: any[]; datasets: any[] } = {
      labels: primaryAverageScoreData.scoresDate,
      datasets: []
    }

    const primaryLineColor = !contacts.secondary
      ? SECONDARY_LINE_COLOR
      : PRIMARY_LINE_COLOR

    const defaultPointSize = 4

    const primaryDataSet = {
      ...dataSetTemplate,
      label: 'Primary',
      backgroundColor: primaryLineColor,
      borderColor: primaryLineColor,
      pointRadius: Array<number>(
        primaryAverageScoreData?.averageScores?.length || 0
      ).fill(defaultPointSize),
      pointBackgroundColor: this.pointColors(
        primaryAverageScoreData.averageScores
      ),
      labels: primaryAverageScoreData.scoresDate,
      data: primaryAverageScoreData.averageScores
    }
    data.datasets.push(primaryDataSet)

    if (contacts?.secondary) {
      const secondaryDataSet = {
        ...dataSetTemplate,
        label: 'Secondary',
        backgroundColor: SECONDARY_LINE_COLOR,
        borderColor: SECONDARY_LINE_COLOR,
        pointRadius: Array<number>(
          secondaryAverageScoreData?.averageScores?.length || 0
        ).fill(defaultPointSize),
        pointBackgroundColor: this.pointColors(
          secondaryAverageScoreData.averageScores
        ),
        labels: secondaryAverageScoreData.scoresDate,
        data: secondaryAverageScoreData.averageScores
      }
      data.labels =
        secondaryAverageScoreData.scoresDate.length > data.labels.length
          ? secondaryAverageScoreData.scoresDate
          : data.labels
      data.datasets.push(secondaryDataSet)
    }
    return data
  }

  public render() {
    const dataSet = this.createDataSet()
    const dataOptions = getGraphOptions()
    return (
      <PriorityGraph graphDataSet={dataSet} graphDataOptions={dataOptions} />
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, exerciseId }: any
) => {
  return {
    householdFinId,
    honestConversation: store.hcExercises[householdFinId][exerciseId],
    contacts: store.contact[householdFinId]
  }
}

export default connect(mapStateToProps)(ClientScoreHistoryGraph)
