import api from '../v3/helpers/api'
import {
  ScoringSessionsObj,
  SessionCardScoreInterface,
  CardRankingObj,
  CardScoreRequestObj
} from '../objects/honestConversations'
import { CardObj } from '../objects/HonestConversations/card'
import { AxiosResponse } from 'axios'
import { setActiveHonestConversationExercise } from './households'
import moment from 'moment'

export const CREATE_EXERCISE_WITH_CARDS_AND_SCORES =
  'CREATE_EXERCISE_WITH_CARDS_AND_SCORES'
export const CREATE_HONEST_CONVERSATION_EXERCISE =
  'CREATE_HONEST_CONVERSATION_EXERCISE'
export const FETCH_HONEST_CONVERSATION_EXERCISES =
  'FETCH_HONEST_CONVERSATION_EXERCISES'
export const FETCH_HONEST_CONVERSATION_EXERCISE =
  'FETCH_HONEST_CONVERSATION_EXERCISE'
export const UPDATE_HONEST_CONVERSATION_EXERCISE =
  'UPDATE_HONEST_CONVERSATION_EXERCISE'
export const DELETE_HONEST_CONVERSATION_EXERCISE =
  'DELETE_HONEST_CONVERSATION_EXERCISES'

export const CREATE_EXERCISE_CLIENT_CARD = 'CREATE_EXERCISE_CLIENT_CARD'
export const GET_CLIENT_CARDS = 'GET_CLIENT_CARDS'
export const UPDATE_EXERCISE_CLIENT_CARDS = 'UPDATE_EXERCISE_CLIENT_CARDS'
export const CREATE_LOGIN_CODE = 'CREATE_LOGIN_CODE'
export const SAVE_HONEST_CONVERSATION_HOUSEHOLD_CARDS =
  'SAVE_HONEST_CONVERSATION_HOUSEHOLD_CARDS'
export const DELETE_HONEST_CONVERSATION_HOUSEHOLD_CARDS =
  'DELETE_HONEST_CONVERSATION_HOUSEHOLD_CARDS'
export const UPDATE_HONEST_CONVERSATION_HOUSEHOLD_CARDS =
  'UPDATE_HONEST_CONVERSATION_HOUSEHOLD_CARDS'

export const CREATE_SCORING_SESSIONS = 'CREATE_SCORING_SESSIONS'
export const UPDATE_SCORING_SESSION = 'UPDATE_SCORING_SESSION'
export const UPDATE_SCORING_SESSION_SCORE_CARD =
  'UPDATE_SCORING_SESSION_SCORE_CARD'
export const DELETE_SCORING_SESSION = 'DELETE_SCORING_SESSION'

export const CREATE_SCORE_AND_SET_SCORING_SESSION =
  'CREATE_SCORE_AND_SET_SCORING_SESSION'
export const CREATE_SCORING_SESSION_SCORE = 'CREATE_SCORING_SESSION_SCORE'
export const CREATE_SCORING_SESSIONS_AND_SET_AS_ACTIVE =
  'CREATE_SCORING_SESSIONS_AND_SET_AS_ACTIVE'
export const CREATE_SCORE_AND_UPDATE_SESSION = 'CREATE_SCORE_AND_UPDATE_SESSION'

export const GET_HC_LOGIN_TOKEN = 'GET_HC_LOGIN_TOKEN'
export const CREATE_HC_LOGIN_TOKEN = 'CREATE_HC_LOGIN_TOKEN'

export const SEND_HC_LINK_EMAIL = 'SEND_HC_EMAIL_LINK'

interface ClientCardRequestObj {
  clientId: string
  cardId: string
  rankedWithin: string
  ranking: number
  comment?: string
}

interface ClientCardUpdateRequestObj {
  id: string
  cardId: string
  ranking: number
  rankedWithin?: string
  comment?: string
}

const clientCardRequest = (clientId: string, clientCards: CardRankingObj[]) => {
  const requestArray: ClientCardRequestObj[] = []
  clientCards.forEach((card) => {
    const { cardId, rankedWithin, ranking, comment } = card
    requestArray.push({
      clientId,
      cardId,
      ranking,
      rankedWithin: rankedWithin.replace(/^\w/, (char: string) =>
        char.toUpperCase()
      ),
      comment
    })
  })

  return requestArray
}

export const clientCardUpdateRequest = (
  clientId: string,
  clientCards: CardRankingObj[]
) => {
  const requestArray: ClientCardUpdateRequestObj[] = []
  clientCards &&
    clientCards.forEach(
      <T extends CardRankingObj>(card: T & { id?: string }) => {
        const { id, cardId, ranking, comment } = card
        if (cardId) {
          requestArray.push({
            id,
            cardId,
            ranking,
            comment
          })
        }
      }
    )

  return {
    clientId,
    clientCards: requestArray
  }
}

export const clientCardUpdateRequestV2 = (
  clientId: string,
  clientCards: CardRankingObj[]
) => {
  const requestArray: ClientCardUpdateRequestObj[] = []
  clientCards &&
    clientCards.forEach(
      <T extends CardRankingObj>(card: T & { id?: string }) => {
        const { id, cardId, ranking, comment, rankedWithin } = card
        if (cardId) {
          requestArray.push({
            id,
            cardId,
            ranking,
            comment,
            rankedWithin
          })
        }
      }
    )

  return { clientCards: requestArray }
}

export const getHonestConversationExercises = (householdId: string) => {
  return {
    type: FETCH_HONEST_CONVERSATION_EXERCISES,
    payload: api()
      .get(`/households/${householdId}/honestconversations/meetings/exercises`)
      .then((response) => {
        return response
      })
  }
}

export const createLoginCode = (
  householdFinId: string,
  exerciseId: string,
  clientId: string
) => {
  return {
    type: CREATE_LOGIN_CODE,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/logincode`,
        { exerciseId, clientId }
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const getHonestConversationExercise = (
  householdId: string,
  exerciseId: string
) => {
  return {
    type: FETCH_HONEST_CONVERSATION_EXERCISE,
    payload: api()
      .get(
        `/households/${householdId}/honestconversations/meetings/exercises/${exerciseId}`
      )
      .then((response) => {
        return response
      })
  }
}

export const createHonestConversationExercise = (householdFinId: string) => {
  const createResponse = api()
    .post(
      `/households/${householdFinId}/honestconversations/meetings/exercises`
    )
    .then((response) => {
      response.data.householdFinId = householdFinId
      return setActiveHonestConversationExercise(
        householdFinId,
        response.data.id
      ).payload.then(() => response)
    })
  return {
    type: CREATE_HONEST_CONVERSATION_EXERCISE,
    payload: createResponse
  }
}

export const deleteHonestConversationExercise = (
  householdFinId: string,
  exerciseId: string
) => {
  return {
    type: DELETE_HONEST_CONVERSATION_EXERCISE,
    payload: api()
      .delete(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}`
      )
      .then((_response) => {
        return {
          householdFinId,
          exerciseId
        }
      })
  }
}

export const updateHonestConversationExercise = (
  householdFinId: string,
  exerciseId: string,
  body: any
) => {
  return {
    type: UPDATE_HONEST_CONVERSATION_EXERCISE,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}`,
        body
      )
      .then((response) => {
        return response
      })
  }
}

export const createHonestConversationHouseholdCards = (
  householdFinId: string,
  exerciseId: string,
  householdCards: CardObj[] | CardRankingObj[]
) => {
  return {
    type: SAVE_HONEST_CONVERSATION_HOUSEHOLD_CARDS,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/householdcards`,
        householdCards
      )
      .then((response) => {
        return {
          householdCards: response.data,
          householdFinId,
          exerciseId
        }
      })
  }
}

export const deleteHonestConversationHouseholdCards = (
  householdFinId: string,
  exerciseId: string,
  householdCardId: string
) => {
  return {
    type: DELETE_HONEST_CONVERSATION_HOUSEHOLD_CARDS,
    payload: api()
      .delete(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/householdcards/${householdCardId}`
      )
      .then((response) => {
        response.data = {
          householdCardId,
          householdFinId,
          exerciseId
        }
        return response
      })
  }
}

export const updateHonestConversationHouseholdCards = (
  householdFinId: string,
  exerciseId: string,
  rankedCards: CardRankingObj[]
) => {
  const householdCards = rankedCards.map((rankedCard) => {
    return {
      id: rankedCard.id,
      exerciseId,
      commentPrimary: rankedCard.commentPrimary || null,
      commentSecondary: rankedCard.commentSecondary || null,
      ranking: rankedCard.ranking,
      cardId: rankedCard.card.id,
      card: rankedCard.card
    }
  })
  return {
    type: UPDATE_HONEST_CONVERSATION_HOUSEHOLD_CARDS,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/householdcards`,
        { householdCards }
      )
      .then((response) => {
        return {
          householdCards,
          householdFinId,
          exerciseId
        }
      })
  }
}

export const createScoringSessions = (
  householdFinId: string,
  exerciseId: string,
  scoringSession: ScoringSessionsObj,
  isPrimary?: boolean
) => {
  return {
    type: CREATE_SCORING_SESSIONS,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions`,
        scoringSession
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        response.data.exerciseId = exerciseId
        if (isPrimary !== null || isPrimary !== undefined) {
          response.data.isPrimary = isPrimary
        }
        return response
      })
  }
}

export const createScoringSessionsAndSetAsActive = (
  householdFinId: string,
  exerciseId: string,
  scoringSession: ScoringSessionsObj,
  isPrimary?: boolean
) => {
  return {
    type: CREATE_SCORING_SESSIONS_AND_SET_AS_ACTIVE,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions`,
        scoringSession
      )
      .then((sessionResponse) => {
        const body: {
          activeScoringSessionPrimary?: string
          activeScoringSessionSecondary?: string
        } = {}

        if (isPrimary) {
          body.activeScoringSessionPrimary = sessionResponse.data.id
        } else {
          body.activeScoringSessionSecondary = sessionResponse.data.id
        }
        return updateHonestConversationExercise(
          householdFinId,
          exerciseId,
          body
        ).payload
      })
  }
}

export const createCardScore = (
  contactId: string,
  householdFinId: string,
  exerciseId: string,
  scoringSessionId: string,
  scoredCards: CardScoreRequestObj[]
) => {
  const apiUrl = `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions/${scoringSessionId}/cardscores`
  const requestList: Promise<AxiosResponse<any>>[] = []
  scoredCards.forEach((card) => {
    requestList.push(api().post(apiUrl, card))
  })

  return {
    type: CREATE_SCORING_SESSION_SCORE,
    payload: Promise.all(requestList).then((responseArray) => {
      const sessionContainer: SessionCardScoreInterface = {}
      responseArray.forEach((response: any) => {
        sessionContainer[response.data.id] = {
          ...response.data
        }
      })
      return {
        contactId,
        householdFinId,
        scoringSessionId,
        exerciseId,
        scores: sessionContainer
      }
    })
  }
}

export const updateScoringSession = (
  householdFinId: string,
  exerciseId: string,
  scoringSessionId: string,
  updatedScores: { clientId: string; cardScoreIds: string[] }
) => {
  const apiUrl = `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions/${scoringSessionId}`
  return {
    type: UPDATE_SCORING_SESSION,
    payload: api()
      .put(apiUrl, updatedScores)
      .then((response: any) => {
        response.householdFinId = householdFinId
        return response
      })
  }
}

export const updateScoreCardV2 = (
  householdFinId: string,
  contactId: string,
  exerciseId: string,
  scoringSessionId: string,
  updatedScoreCards: CardScoreRequestObj[]
) => {
  const apiUrl = `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions/${scoringSessionId}/cardscores`
  const scoreCards = updatedScoreCards.map((card, index) => {
    card['id'] = card.cardScoreId
    delete card.cardScoreId
    return card
  })
  return {
    type: UPDATE_SCORING_SESSION_SCORE_CARD,
    payload: api()
      .put(apiUrl, scoreCards)
      .then((response) => {
        return {
          data: {
            householdFinId,
            contactId,
            exerciseId,
            scoringSessionId,
            updatedCards: response.data
          }
        }
      })
  }
}

export const updateScoreCard = (
  householdFinId: string,
  contactId: string,
  exerciseId: string,
  scoringSessionId: string,
  updatedScoreCards: CardScoreRequestObj[]
) => {
  const apiUrl = `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions/${scoringSessionId}/cardscores`

  const updateScoreCardRequests: Promise<{
    householdFinId: string
    exerciseId: string
    scoringSessionId: string
    updatedScoreCard: CardScoreRequestObj
  }>[] = []

  updatedScoreCards.forEach((card: CardScoreRequestObj) => {
    updateScoreCardRequests.push(
      api().put(`${apiUrl}/${card.cardScoreId}`, card)
    )
  })

  return {
    type: UPDATE_SCORING_SESSION_SCORE_CARD,
    payload: Promise.all(updateScoreCardRequests).then((responses) => {
      const updatedCards: {
        id: string
        householdCardId: string
        scoringSessionId: string
        score: number
        comment: string
        createdDate: string
      }[] = []
      responses.forEach((response) => {
        updatedCards.push(response['data'])
      })
      return {
        data: {
          householdFinId,
          contactId,
          exerciseId,
          scoringSessionId,
          updatedCards
        }
      }
    })
  }
}

export const createCardScoreAndUpdateScoringSession = (
  contactId: string,
  householdFinId: string,
  exerciseId: string,
  scoringSessionId: string,
  newScoredCards: CardScoreRequestObj[],
  cardScoreIds?: string[]
) => {
  return {
    type: CREATE_SCORE_AND_UPDATE_SESSION,
    payload: updateScoreCardV2(
      householdFinId,
      contactId,
      exerciseId,
      scoringSessionId,
      newScoredCards
    ).payload.then((response) => {
      const sessionContainer: SessionCardScoreInterface = {}
      response.data.updatedCards.forEach((card: any) => {
        sessionContainer[card.id] = {
          ...card
        }
      })
      const updatedScoreIds = cardScoreIds
        ? cardScoreIds.concat(Object.keys(sessionContainer))
        : Object.keys(sessionContainer)
      const updatedScores = {
        clientId: contactId,
        cardScoreIds: updatedScoreIds
      }
      return updateScoringSession(
        householdFinId,
        exerciseId,
        scoringSessionId,
        updatedScores
      ).payload
    })
  }
}

export const createCardScoreAndUpdateScoringSessionv2 = (
  contactId: string,
  householdFinId: string,
  exerciseId: string,
  scoringSessionId: string,
  newScoredCards: CardScoreRequestObj[],
  cardScoreIds?: string[]
) => {
  return {
    type: CREATE_SCORE_AND_UPDATE_SESSION,
    payload: createCardScore(
      contactId,
      householdFinId,
      exerciseId,
      scoringSessionId,
      newScoredCards
    ).payload.then((response) => {
      const updatedScoreIds = cardScoreIds
        ? cardScoreIds.concat(Object.keys(response.scores))
        : Object.keys(response.scores)
      const updatedScores = {
        clientId: contactId,
        cardScoreIds: updatedScoreIds
      }
      return updateScoringSession(
        householdFinId,
        exerciseId,
        scoringSessionId,
        updatedScores
      ).payload
    })
  }
}

export const deleteScoringSession = (
  householdFinId: string,
  exerciseId: string,
  clientId: string,
  scoringSessionId: string
) => {
  const apiUrl = `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/scoringsessions/${scoringSessionId}`
  return {
    type: DELETE_SCORING_SESSION,
    payload: api()
      .delete(apiUrl)
      .then((response) => {
        response.data = {
          householdFinId,
          exerciseId,
          clientId,
          scoringSessionId
        }
        return response.data
      })
  }
}

export const createClientCards = (
  householdFinId: string,
  exerciseId: string,
  clientId: string,
  selectedClientCards: CardRankingObj[]
) => {
  return {
    type: CREATE_EXERCISE_CLIENT_CARD,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/clientcards`,
        clientCardRequest(clientId, selectedClientCards)
      )
      .then((response) => {
        return {
          householdFinId,
          exerciseId,
          clientId,
          clientCards: response.data
        }
      })
  }
}

export const updateClientCards = (
  householdFinId: string,
  exerciseId: string,
  clientId: string,
  selectedClientCards: CardRankingObj[]
) => {
  const updateRequestBody = clientCardUpdateRequest(
    clientId,
    selectedClientCards
  )

  return {
    type: UPDATE_EXERCISE_CLIENT_CARDS,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/clientcards`,
        updateRequestBody
      )
      .then((response) => {
        return {
          householdFinId,
          exerciseId,
          clientId,
          clientCards: selectedClientCards
        }
      })
  }
}

export const updateAllClientCards = (
  householdFinId: string,
  exerciseId: string,
  clientId: string,
  allClientCards: CardRankingObj[]
) => {
  const { clientCards } = clientCardUpdateRequestV2(clientId, allClientCards)
  return {
    type: UPDATE_EXERCISE_CLIENT_CARDS,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/exercises/${exerciseId}/clients/${clientId}/clientcards`,
        {
          clientCards
        }
      )
      .then((response) => {
        return {
          householdFinId,
          exerciseId,
          clientId,
          clientCards: allClientCards
        }
      })
  }
}

export const getClientLoginToken = (
  householdId: string,
  exerciseId: string,
  clientId: string
) => {
  return {
    type: GET_HC_LOGIN_TOKEN,
    payload: api()
      .get(
        `/households/${householdId}/honestconversations/meetings/exercises/${exerciseId}/clients/${clientId}/token`
      )
      .then((response) => {
        response.data.data.householdId = householdId
        return response.data.data
      })
  }
}

export const createClientLoginToken = (
  householdId: string,
  exerciseId: string,
  clientId: string
) => {
  return {
    type: CREATE_HC_LOGIN_TOKEN,
    payload: api()
      .post(
        `/households/${householdId}/honestconversations/meetings/exercises/${exerciseId}/clients/${clientId}/token`,
        { expiration: moment().add(2, 'weeks') }
      )
      .then((response) => {
        response.data.householdId = householdId
        return response.data
      })
  }
}

export const getOrCreateClientLoginToken = (
  householdId: string,
  exerciseId: string,
  clientId: string,
  isPrimary: boolean
) => {
  return {
    type: GET_HC_LOGIN_TOKEN,
    payload: getClientLoginToken(householdId, exerciseId, clientId)
      .payload.then((response) => {
        return { ...response, isPrimary }
      })
      .catch((error) => {
        // If we didn't get a token because one couldn't be found then create one
        if (error && error.response && error.response.status === 400) {
          return createClientLoginToken(
            householdId,
            exerciseId,
            clientId
          ).payload.then((createResponse) => {
            return {
              ...createResponse,
              isPrimary
            }
          })
        } else {
          return Promise.reject()
        }
      })
  }
}

export const sendHonestConversationLinkEmail = (
  householdFinId: string,
  clientId: string,
  institutionLogo: string,
  type: string
) => {
  return {
    type: SEND_HC_LINK_EMAIL,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/email/v2`,
        { clientId, institutionLogo, type }
      )
      .then((response) => response)
  }
}
