import React, { Component, ChangeEvent, SyntheticEvent, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { IMStrategiesObj } from '../../../../../objects/strategies'
import { updateIMStrategyFilter } from '../../../../../actions/strategySearch'
import { GlobalState } from '../../../../../reducers'
import FilterCheckbox from '../filterCheckbox'

export interface FilterManagersProps {
  dispatch: Dispatch<GlobalState>
  filter: string[]
  strategies: IMStrategiesObj[]
  toggleFilterDropdown(): void
}

interface FilterManagersState {
  isFilterChanges: boolean
  managerFilter: string[]
}

export class FilterManagers extends Component<
  FilterManagersProps,
  FilterManagersState
> {
  constructor(props: FilterManagersProps) {
    super(props)
    this.state = {
      isFilterChanges: false,
      managerFilter: this.props.filter
    }
  }

  public handleStopPropagation = (e: SyntheticEvent): void => {
    e.stopPropagation()
  }

  public toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { managerFilter } = this.state
    const value = e.currentTarget.value
    if (!managerFilter?.some((filter: string) => filter === value)) {
      this.setState({
        isFilterChanges: true,
        managerFilter: [...managerFilter, value]
      })
    } else {
      const tempState = [...managerFilter]
      tempState.splice(tempState.indexOf(value), 1)
      this.setState({
        isFilterChanges: true,
        managerFilter: tempState
      })
    }
  }

  public sortManagerList = (managerList: string[]) => {
    const beginningGSValues = managerList
      .filter(
        (manager: string) =>
          manager === 'Goldman Sachs' || manager === 'United Capital'
      )
      .sort()
    const sortedList = managerList
      .filter(
        (manager: string) =>
          manager !== 'Goldman Sachs' && manager !== 'United Capital'
      )
      .sort()
    return [...beginningGSValues, ...sortedList]
  }

  public handleCancel = () => {
    this.setState({ managerFilter: [], isFilterChanges: false })
    this.props.toggleFilterDropdown()
  }

  public handleApply = () => {
    if (this.state.isFilterChanges) {
      this.setState({ isFilterChanges: false })
      this.props.dispatch(
        updateIMStrategyFilter('manager', this.state.managerFilter)
      )
    }
    this.props.toggleFilterDropdown()
  }

  public renderGridList = () => {
    const { isFilterChanges } = this.state
    const filteredManagerList = this.props.strategies
      ?.map((strategy: IMStrategiesObj) => strategy.manager)
      .reduce((acc: string[], curr: string) => {
        return acc.includes(curr) ? acc : [...acc, curr]
      }, [])
    const sortedList =
      filteredManagerList &&
      filteredManagerList?.length &&
      this.sortManagerList(filteredManagerList)
        ?.filter((option: string) => option)
        .map((option: string) => (
          <FilterCheckbox
            key={option}
            toggleCheckbox={this.toggleCheckbox}
            filters={this.state.managerFilter}
            filterName={option}
          />
        ))

    if (!sortedList?.length) {
      return null
    }

    return (
      <Fragment>
        <div className='strategy-search__dropdown-filter-manager'>
          {sortedList}
        </div>
        <div className='strategy-search__filter-buttons-cancel-apply-w strategy-search__filter-buttons-cancel-apply-w--popup'>
          <div
            onClick={this.handleCancel}
            className='strategy-search__filter-buttons-cancel'>
            Cancel
          </div>
          <div
            onClick={this.handleApply}
            className={
              isFilterChanges
                ? 'strategy-search__filter-buttons-apply'
                : 'strategy-search__filter-buttons-disabled'
            }>
            Apply
          </div>
        </div>
      </Fragment>
    )
  }

  public render() {
    const gridList = this.renderGridList() || <span>No Managers Available</span>

    return (
      <div
        onClick={this.handleStopPropagation}
        className='strategy-search__dropdown-filter'>
        {gridList}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    filter: store.strategySearch.filter.manager,
    strategies: store.strategySearch.strategies
  }
}

export default connect(mapStateToProps)(FilterManagers)
