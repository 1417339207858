import React from 'react'
import HcEditCombinedActionsRow from '../hcEditCombinedActions'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import handleImage from '../../../../assets/images/icons/ic_handle.png'
import {
  CardObj,
  CardOptionsInterface
} from '../../../../../objects/HonestConversations/card'
import {
  CardRankingObj,
  HonestConversationsExerciseObj
} from '../../../../../objects/honestConversations'
import { ContactsInterface } from '../../../../../objects/contact'
import { LifeActionObj } from '../../../../../objects/lifeActions'

interface HonestConversationEditCombinedCardsProps {
  cardOptions: CardOptionsInterface
  cards: CardRankingObj[]
  contacts: ContactsInterface
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  onScoreChange(
    index: number,
    scoreKey: 'primaryScore' | 'secondaryScore',
    score: number
  ): void
  onModifyAction?(index: number, actionList: LifeActionObj[]): void
  onCommentChange(index: number, contactType: string, comment: string): void
  onCardSelection(index: number, contactType: string, card: CardObj): void
  onBlur?(contactType: string, index: number, card: CardObj): void
  onDrag?(result: DropResult): void
}

const contactType = 'combinedCards'

type cardComment = {
  [key: string]: {
    primary?: string
    secondary?: string
  }
}
interface HonestConversationEditCombinedCardsState {
  initialComments: cardComment
  disableInputs: boolean
}

class HonestConversationEditCombinedCards extends React.Component<
  HonestConversationEditCombinedCardsProps,
  HonestConversationEditCombinedCardsState
> {
  constructor(props: HonestConversationEditCombinedCardsProps) {
    super(props)
    this.state = {
      initialComments: {},
      disableInputs: false
    }
  }

  componentDidMount() {
    const cards = this.getDisplayCards()
    const initialComments: cardComment = {}
    cards?.forEach((card: CardRankingObj) => {
      initialComments[card?.card?.id] = {}
      initialComments[card?.card?.id] = {
        primary: card?.commentPrimary || '',
        secondary: card?.commentSecondary || ''
      }
    })
    this.setState({ initialComments })
  }

  public getDisplayCards = () => {
    const currentHouseholdCards: CardRankingObj[] = this.props.cards
    const displayCards: CardRankingObj[] = []
    for (let i = 0; i < 5; i += 1) {
      displayCards.push({
        id: null,
        cardId: null,
        commentPrimary: null,
        commentSecondary: null,
        ranking: i + 1,
        card: null
      })
    }
    currentHouseholdCards.forEach((card: CardRankingObj) => {
      displayCards.splice(card.ranking - 1, 1, card)
    })
    return displayCards
  }

  public onMouseDown = (providedProps: any) => (event: any) => {
    if (providedProps.dragHandleProps)
      providedProps.dragHandleProps.onMouseDown(event)

    if (event.defaultPrevented) event.currentTarget.focus()
  }

  public setDisableInput = (disableInputs: boolean) => {
    this.setState({
      disableInputs
    })
  }

  public honestConversationRows = () => {
    const {
      cards,
      householdFinId,
      contacts,
      cardOptions,
      onScoreChange,
      onCardSelection,
      onCommentChange,
      onModifyAction,
      onBlur
    } = this.props

    const { disableInputs } = this.state

    return this.getDisplayCards()?.map((card, index: number) => {
      const comments = this.state.initialComments?.[card?.card?.id]
      const key = card?.card?.id ? `card${card?.card?.id}` : `card${index}`
      return (
        <Draggable key={key} draggableId={key} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                ...provided.draggableProps.style
              }}
              className='hc-draggable-row__wrapper'>
              <div
                className='hc-draggable-row__handle'
                {...provided.dragHandleProps}
                onMouseDown={this.onMouseDown(provided)}>
                <img src={handleImage} alt='' role='presentation' />
              </div>
              <div className='hc-card-row__wrapper'>
                <HcEditCombinedActionsRow
                  displayIndex={index + 1}
                  householdFinId={householdFinId}
                  contacts={contacts}
                  key={key}
                  index={index}
                  card={card}
                  selectedCards={cards}
                  primaryScore={card.primaryScore}
                  initialCommentPrimary={comments?.primary}
                  commentPrimary={card.commentPrimary}
                  secondaryScore={card.secondaryScore}
                  initialCommentSecondary={comments?.secondary}
                  commentSecondary={card.commentSecondary}
                  cardOptions={cardOptions}
                  onScoreChange={onScoreChange}
                  onCardSelection={onCardSelection}
                  onCommentChange={onCommentChange}
                  onModifyAction={onModifyAction}
                  editRecord={true}
                  onBlur={onBlur}
                  setDisableInputs={this.setDisableInput}
                  disableInputs={disableInputs}
                />
              </div>
            </div>
          )}
        </Draggable>
      )
    })
  }

  public onDragEnd = (result: DropResult) => {
    if (!result || !result.destination) {
      return
    }
    this.props.onDrag(result)
  }

  public combinedCards = () => {
    const { contacts } = this.props
    const { primary, secondary } = contacts

    return (
      <div>
        <div className='hc-meeting__sub-header'>
          <div className='hc-meeting__sub-header--title'>
            {secondary ? 'Combined Choices' : 'Score'}
          </div>
          {primary && secondary ? (
            <div className='hc-meeting__sub-header--title hc-meeting__sub-header--title-names'>
              <div className='hc-meeting__sub-header--title-name-w'>
                {secondary.firstName ? secondary.firstName : 'Secondary'}
              </div>
              <div className='hc-meeting__sub-header--title-name-w'>
                {primary.firstName ? primary.firstName : 'Primary'}
              </div>
            </div>
          ) : null}
        </div>
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={contactType} direction='vertical'>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {this.props.cards ? this.honestConversationRows() : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }
  public render() {
    return <div>{this.combinedCards()}</div>
  }
}

export default HonestConversationEditCombinedCards
