import React, { FC, FormEvent } from 'react'
import ToggleSwitch from '../../../components/toggleSwitch'
import ActionHeart from '../../../assets/images/icons/png/goal-action-heart-gray.png'
import ActionDollar from '../../../assets/images/icons/png/goal-action-dollar-gray.png'

interface FilterToggleRowProps {
  img?: string
  label: string
  name: string
  active: boolean
  isActionType: boolean
  handleToggle(
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ): void
}

const FilterToggleRow: FC<FilterToggleRowProps> = (
  props: FilterToggleRowProps
) => {
  const { isActionType, label, active, name, handleToggle } = props
  return (
    <div className='filter-toggle-row filter-toggle-row__w'>
      <div className='filter-toggle-row filter-toggle-row__row-action-type'>
        {isActionType ? (
          <div className='filter-toggle-row filter-toggle-row__row-icon'>
            <img
              src={label === 'Life Goals' ? ActionHeart : ActionDollar}
              height={20}
              alt='goal type icon'
            />
          </div>
        ) : null}
        <div className={!isActionType ? 'filter-toggle-row__label-action' : ''}>
          {label}
        </div>
      </div>
      <ToggleSwitch
        active={active}
        handleToggleActive={handleToggle}
        name={name}
      />
    </div>
  )
}

export default FilterToggleRow
