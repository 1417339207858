import React, { CSSProperties, Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { HouseholdObj } from '../../../../../objects/household'
import { ContactsInterface } from '../../../../../objects/contact'
import {
  Tile,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import CardsList from './cardsList'
import { HonestConversationsExerciseObj } from '../../../../../objects/honestConversations'
import { withRouter } from 'react-router'

interface RevealCardsProps {
  household: HouseholdObj
  hcExercise: HonestConversationsExerciseObj
  contacts: ContactsInterface
  tileStyle?: CSSProperties
  next(): void
}

const tileStyle: CSSProperties = {
  marginTop: '40px',
  alignSelf: 'center',
  padding: 0,
  width: '960px'
}
const btnStyle: CSSProperties = {
  height: '32px',
  width: '82px',
  margin: '5px 0',
  backgroundColor: '#255eba',
  borderRadius: '4px',
  color: 'white'
}

const headerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end'
}

const contentStyle: CSSProperties = {
  backgroundColor: '#F4F4F4',
  padding: 0,
  display: 'flex',
  justifyContent: 'center'
}

class RevealCards extends Component<RevealCardsProps> {
  constructor(props: RevealCardsProps) {
    super(props)
  }

  public tileHeader = () => {
    const { next } = this.props
    return (
      <div>
        <Button btnStyle={btnStyle} onClick={next}>
          Next
        </Button>
      </div>
    )
  }

  public render() {
    const { contacts, hcExercise, household } = this.props
    if (!contacts) {
      return null
    }
    return (
      <Tile
        tileStyle={tileStyle}
        header={this.tileHeader()}
        headerStyle={headerStyle}
        contentStyle={contentStyle}>
        {contacts.secondary ? (
          <Fragment>
            <div className='hc-reveal__divider' />
            <CardsList
              householdFinId={household.id}
              hcExercise={hcExercise}
              contact={contacts.secondary}
              otherContact={contacts.primary}
              contactType='Secondary'
            />
          </Fragment>
        ) : null}
        <CardsList
          householdFinId={household.id}
          hcExercise={hcExercise}
          contact={contacts.primary}
          otherContact={contacts.secondary}
          contactType='Primary'
        />
      </Tile>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, exerciseId } = match.params
  return {
    household: store.households[householdFinId],
    contacts: store.contact[householdFinId],
    hcExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][exerciseId]
  }
}

export default withRouter(connect(mapStateToProps)(RevealCards))
