import React, { RefObject } from 'react'
import PDF from 'react-pdf-js'
import api from '../../../../v3/helpers/api'
import Button from '../../../components/button'
import Modal from '../../../components/layout/modal'
import { ReactComponent as SettingsIcon } from '../../../assets/images/icons/print.svg'
import { ContactsInterface } from '../../../../objects/contact'
import { HouseholdInstitution } from '../../../../objects/household'
import { downloadBlob } from '../../../helpers/download'

interface CostOfServicesProps {
  householdId: string
  summary: any
  contacts: ContactsInterface
  institution: HouseholdInstitution
  closeModal(): void
}

interface CostOfServicesState {
  showPdf: boolean
  pdfData: any
}

class CostOfServicesModal extends React.Component<
  CostOfServicesProps,
  CostOfServicesState
> {
  public institutionLogoRef: RefObject<HTMLImageElement> = React.createRef()
  public institutionCanvasRef: RefObject<HTMLCanvasElement> = React.createRef()
  constructor(props: CostOfServicesProps) {
    super(props)
    this.state = {
      showPdf: false,
      pdfData: null
    }
  }

  public modalButtons = () => {
    const { closeModal } = this.props
    const { pdfData } = this.state
    const thisPdf = () => {
      downloadBlob(pdfData, 'CostOfServicesSummary.pdf')
    }
    return (
      <div className='billing__summary-btn'>
        <Button primary={false} onClick={closeModal}>
          Cancel
        </Button>
        <Button primary={true} onClick={thisPdf}>
          Print
        </Button>
      </div>
    )
  }

  public async componentDidMount() {
    const householdFinId = this.props.householdId
    const pdfFilePath = `summary/${householdFinId}-CostOfServices.pdf`
    const encodedPath = encodeURIComponent(pdfFilePath)

    await api()
      .get(
        `/households/${householdFinId}/guidebookgenerator/renderpdf?pdfFilePath=${encodedPath}`,
        {
          timeout: 600000,
          responseType: 'blob'
        }
      )
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/pdf'
        })
        this.setState({
          showPdf: true,
          pdfData: window.URL.createObjectURL(blobData)
        })
      })
  }

  public render() {
    const { closeModal } = this.props
    const { showPdf, pdfData } = this.state
    return (
      <Modal
        icon={SettingsIcon}
        title='COST OF SERVICES SUMMARY'
        closeModal={closeModal}
        size='M'>
        <div className='billing__summary-pdf'>
          {showPdf && <PDF file={pdfData} />}
        </div>
        {this.modalButtons()}
      </Modal>
    )
  }
}

export default CostOfServicesModal
