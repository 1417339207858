import gql from 'graphql-tag'

export const HouseholdAccessClientSettingsQuery = gql`
  query($userId: String!, $householdId: UUID!, $clientId: UUID!) {
    allClients(condition: { client: $clientId }) {
      nodes {
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
    allHouseholdToAdvisers(
      condition: { adviser: $userId, household: $householdId }
    ) {
      nodes {
        household
      }
    }
  }
`

export const GetClientNotificationSettingsQuery = gql`
  query getClientNotificationSettings($clientId: UUID!) {
    allClients(condition: { client: $clientId }) {
      nodes {
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
  }
`

export const SetClientNotificationSettingsQuery = gql`
  mutation setClientNotificationSettings(
    $desktopSettings: [NotificationSubjects]
    $emailSettings: [NotificationSubjects]
    $mobileSettings: [NotificationSubjects]
    $clientId: UUID!
  ) {
    updateClientByClient(
      input: {
        client: $clientId
        clientPatch: {
          desktopSubject: $desktopSettings
          emailSubject: $emailSettings
          mobileSubject: $mobileSettings
        }
      }
    ) {
      client {
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
  }
`

export const GetMultipleClientsNotificationSettingsQuery = gql`
  query getMultipleClientNotificationSettings($clientIds: [UUID!]!) {
    allClients(filter: { client: { in: $clientIds } }) {
      nodes {
        client
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
  }
`
