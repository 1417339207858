import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { InstitutionObj } from '../../../objects/institution'

import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg'
import Brain from '../../assets/images/icons/png/brain-logo.png'
import PlatformExplorerIcon from '../../assets/images/icons/search_gray.png'

export interface ResourcesDropdownProps {
  institution: InstitutionObj
}

interface ResourcesDropdownState {
  showDropdown: boolean
}

export class ResourcesDropdown extends Component<
  ResourcesDropdownProps,
  ResourcesDropdownState
> {
  constructor(props: ResourcesDropdownProps) {
    super(props)
    this.state = {
      showDropdown: false
    }
  }

  public toggleShowMenu = () => {
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  public handleClickOutside = () => {
    this.setState({ showDropdown: false })
  }

  public openExternalHelp = () => {
    window.open(`${window._env_.REACT_APP_HELP_URL}`)
  }

  public openExternalImPortal = () => {
    window.open(`${window._env_.REACT_APP_IM_PORTAL_URL}`)
  }

  public resourcesMenu = () => {
    return (
      <ul className='header__dropdown header__dropdown--xl'>
        <li>
          <div
            onClick={this.openExternalHelp}
            className='header__dropdown-link'>
            <img src={Brain} alt='brain' />
            <span>Brain</span>
          </div>
        </li>
        <li>
          <div
            onClick={this.openExternalImPortal}
            className='header__dropdown-link'>
            <img src={PlatformExplorerIcon} width={18} alt='launch' />
            <span>Strategy Search</span>
          </div>
        </li>
      </ul>
    )
  }

  public render() {
    const { showDropdown } = this.state
    return (
      <div
        className={
          showDropdown
            ? 'resources-dropdown__w resources-dropdown__w--active'
            : 'resources-dropdown__w'
        }>
        <div onClick={this.toggleShowMenu} className='resources-dropdown__head'>
          <HelpIcon />
          Resources
        </div>
        {showDropdown ? this.resourcesMenu() : null}
      </div>
    )
  }
}

export default onClickOutside(ResourcesDropdown)
