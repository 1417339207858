/**
 * Objects: Life Actions
 * Tasks: Can be found in ./tasks.ts
 */

import { TaskObjState } from './tasks'

export interface LifeActionObj {
  hcCardId?: string
  id: string
  householdId: string
  status: LifeActionStatusType
  name: string
  comment?: string
  dueDate?: string
  assigneePrimaryId: string
  assigneeSecondaryId: string
  assignees: AssigneesObj[]
  tasks?: TaskObjState[]
  cards: HCCardObj[]
}

export interface HCCardObj {
  id: string
  actionId: string
}

export interface AssigneesObj {
  firstName: string
  lastName: string
  id: string
}
export interface LifeActionCards {
  hcCardId: string
}

export interface LifeActionsInterface {
  [id: string]: LifeActionObj
}

export interface LifeActionUpdate {
  householdId: string
  status?: LifeActionStatusType
  name: string
  comment?: string
  dueDate?: string | Date
  assigneePrimaryId: string
  assigneeSecondaryId: string
}

export interface ActionEditInterface {
  [key: string]: ActionEditObj
}

export interface ActionEditObj {
  name: string
  assigneePrimaryId: string
  assigneeSecondaryId: string
}

export type LifeActionStatusType =
  | 'Optimal Position'
  | 'Improvement Needed'
  | 'Immediate Action Needed'
  | 'NA | Pending Review'
  | ''

interface StatusTypeInterface {
  [key: string]: LifeActionStatusType
}

export const statusType: StatusTypeInterface = {
  optimal: 'Optimal Position',
  improvement: 'Improvement Needed',
  immediate: 'Immediate Action Needed',
  na: 'NA | Pending Review'
}
