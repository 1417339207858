import React, { Component, FormEvent } from 'react'

import { ClientAccountPositionsObj } from '../../../../objects/clientAccount'

import ArrowRightIcon from '../../../assets/images/icons/png/ic_left.png'
import ArrowLeftIcon from '../../../assets/images/icons/png/ic_right.png'
import ViewAllIcon from '../../../assets/images/icons/png/eye_blue.png'

interface PaginationProps {
  positions: ClientAccountPositionsObj[]
  householdFinId: string
  currentPage: number
  totalPositionCount: number
  showAll: boolean
  handlePaginationClick(event: FormEvent<HTMLElement>): void
  handlePageArrowLeft(): void
  handlePageArrowRight(): void
  countPageNumbers(): number[]
  toggleShowAll(): void
}

class Pagination extends Component<PaginationProps> {
  public renderPageNumbers = () => {
    const { currentPage, countPageNumbers, handlePaginationClick } = this.props
    const pageNumbers: number[] =
      countPageNumbers().length >= 1 ? countPageNumbers() : []
    return pageNumbers.map((num: number) => {
      return (
        <li
          style={{
            backgroundColor: currentPage === num ? '#255EBA' : '#FFFFFF',
            color: currentPage === num ? '#FFFFFF' : '#231F20'
          }}
          key={num}
          id={`${num}`}
          onClick={handlePaginationClick}>
          {num}
        </li>
      )
    })
  }

  public renderPagination = () => {
    const {
      totalPositionCount,
      handlePageArrowLeft,
      handlePageArrowRight,
      toggleShowAll
    } = this.props
    if (totalPositionCount >= 28) {
      return (
        <div className='holdings__pagination'>
          {!this.props.showAll ? (
            <span>
              <span className='holdings__pagination-section'>
                <img
                  src={ArrowRightIcon}
                  alt='leftArrow'
                  onClick={handlePageArrowLeft}
                />
              </span>
              {this.renderPageNumbers()}
              <span className='holdings__pagination-section'>
                <img
                  src={ArrowLeftIcon}
                  alt='rightArrow'
                  onClick={handlePageArrowRight}
                />
              </span>
            </span>
          ) : (
            <span />
          )}
          <span onClick={toggleShowAll}>
            <img width={20} src={ViewAllIcon} alt='View All Positions' />{' '}
            {!this.props.showAll ? (
              <span className='holdings__pagination-show-all'>Show All</span>
            ) : (
              <span className='holdings__pagination-show-all'>Show Less</span>
            )}
          </span>
        </div>
      )
    } else return null
  }

  public render() {
    return <span>{this.renderPagination()}</span>
  }
}

export default Pagination
