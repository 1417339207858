import React, { Component } from 'react'
import { DotOval } from '../../dotOval'
import FinancialCharts from '../../../../components/graphs/financialCharts'

interface MaxDefGraphProps {
  chartOptions: object
  maxDefensiveData: any
  targetAllocationData: any
}

class MaxDefGraph extends Component<MaxDefGraphProps> {
  public renderGraph = () => {
    const {
      equity,
      fixed,
      cash,
      otherValue,
      Unclassified
    } = this.props.targetAllocationData
    const { chartOptions } = this.props
    const normal = {
      datasets: [
        {
          data: [equity, fixed, cash, otherValue, Unclassified],
          backgroundColor: [
            '#255eba',
            '#FFBE1A',
            '#FFDE8C',
            '#A2D134',
            '#a4a4a4'
          ]
        }
      ]
    }

    return (
      <div>
        <div className='proposed-portfolio__graph'>
          <div className='proposed-portfolio__graph-relative'>
            <FinancialCharts
              chartData={normal}
              chartOptions={chartOptions}
              dimensions={{ width: 100, height: 100 }}
            />
            <div className='proposed-portfolio__graph-target'>
              <p>NORMAL</p>
            </div>
          </div>
          <div className='proposed-portfolio__graph-data-point'>
            <DotOval
              backgroundColor='#255EBA'
              title='Equity'
              value={equity + '%'}
            />
            <DotOval
              backgroundColor='#FFBE1A'
              title='Fixed'
              value={fixed + '%'}
            />
            <DotOval
              backgroundColor='#FFDE8C'
              title='Cash'
              value={cash + '%'}
            />
            <DotOval
              backgroundColor='#A2D134'
              title='Alternatives'
              value={otherValue + '%'}
            />
            <DotOval
              backgroundColor='#a4a4a4'
              title='Unclassified'
              value={Unclassified + '%'}
            />
          </div>
        </div>
      </div>
    )
  }

  public renderMaxGraph = () => {
    const {
      equity: maxDefEquity,
      fixed: maxDefFixed,
      cash: maxDefCash,
      alt: maxDefAlt,
      Unclassified: maxDefUnclassified
    } = this.props.maxDefensiveData
    const { chartOptions } = this.props
    const maxDefense = {
      datasets: [
        {
          data: [
            maxDefEquity,
            maxDefFixed,
            maxDefCash,
            maxDefAlt,
            100 - (maxDefEquity + maxDefFixed + maxDefCash + maxDefAlt)
          ],
          backgroundColor: [
            '#255eba',
            '#FFBE1A',
            '#FFDE8C',
            '#A2D134',
            '#a4a4a4'
          ]
        }
      ]
    }

    return (
      <div>
        <div className='proposed-portfolio__graph'>
          <div className='proposed-portfolio__graph-relative'>
            <FinancialCharts
              chartData={maxDefense}
              chartOptions={chartOptions}
              dimensions={{ height: 100, width: 100 }}
            />
            <div className='proposed-portfolio__graph-target'>
              <p>MAX DEFENSIVE</p>
            </div>
          </div>
          <div className='proposed-portfolio__graph-data-point'>
            <DotOval
              backgroundColor='#255EBA'
              title='Equity'
              value={maxDefEquity + '%'}
            />
            <DotOval
              backgroundColor='#FFBE1A'
              title='Fixed'
              value={maxDefFixed + '%'}
            />
            <DotOval
              backgroundColor='#FFDE8C'
              title='Cash'
              value={maxDefCash + '%'}
            />
            <DotOval
              backgroundColor='#A2D134'
              title='Alternatives'
              value={maxDefAlt + '%'}
            />
            <DotOval
              backgroundColor='#A4A4A4'
              title='Unclassified'
              value={maxDefUnclassified + '%'}
            />
          </div>
        </div>
      </div>
    )
  }

  public render() {
    return (
      <div className='proposed-portfolio__graph-wrapper'>
        {this.renderGraph()}
        <div className='proposed-portfolio__graph__divider-wrapper'>
          <div className='proposed-portfolio__graph__divider' />
        </div>
        {this.renderMaxGraph()}
      </div>
    )
  }
}

export default MaxDefGraph
