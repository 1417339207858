import React from 'react'
import MainContainer from '../mainContainer'
import Button from '../../components/button'
import { RouteComponentProps } from 'react-router-dom'

interface ErrorBoundaryState {
  hasError: boolean
}
interface ErrorBoundaryProps {
  children: any
  routeProps: RouteComponentProps
}
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): any {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error('An error occurred')
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (
      prevProps.routeProps?.location?.pathname !==
      this.props.routeProps?.location?.pathname
    ) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <MainContainer noHeader={false}>
          <div className='error-w'>
            <h1 className='error-statuscode'>500</h1>
            <p className='error-title'>We’re having technical issues</p>
            <p className='error-message'>
              Come back soon, or visit our
              <a target='_blank' href='https://status.finlife.com/'>
                Status Page
              </a>
              to check for outages and subscribe to receive notifications.
            </p>
            <a target='_blank' href='https://status.finlife.com/'>
              <Button primary>Go to Status Page</Button>
            </a>
          </div>
        </MainContainer>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary
