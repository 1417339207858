import React, { Component } from 'react'
import CarrotIcon from '../../../../assets/images/icons/png/carrot-gray.png'

interface CombineListLabelProps {
  title: string
  arrow: boolean
  handleSort(): void
}

class CombineListLabel extends Component<CombineListLabelProps> {
  public render() {
    const { title, handleSort, arrow } = this.props
    return (
      <div onClick={handleSort}>
        <label className='pal__sort-label'>{title}</label>
        {
          <img
            className={
              arrow
                ? 'pal__sort-carrot'
                : 'pal__sort-carrot pal__sort-carrot--up'
            }
            src={CarrotIcon}
            alt='arrow icon'
          />
        }
      </div>
    )
  }
}

export default CombineListLabel
