import * as actions from '../actions/guidebook'

import { GuidebookInterface } from '../objects/guidebook'

const initState: GuidebookInterface = {}

const mapGuidebook = (guidebook: any): GuidebookInterface => {
  if (guidebook) {
    return {
      id: guidebook.id,
      name: guidebook.name,
      sections: guidebook.sections,
      pdfUrl: guidebook.pdfUrl ? guidebook.pdfUrl : null,
      createdDate: guidebook.createdDate
    }
  } else return null
}

const mapGuidebooks = (guideBooks: any) => {
  const obj = {}
  guideBooks
    .filter((guidebook: any) => guidebook.pdfUrl && guidebook.pdfUrl.length > 0)
    .sort((a: any, b: any) => {
      if (a.createdDate < b.createdDate) return 1
      if (a.createdDate > b.createdDate) return -1
      return 0
    })
    .forEach((guidebook: any) => {
      obj[guidebook.id] = mapGuidebook(guidebook)
    })
  return obj
}

const Guidebook = (state = initState, action: any): GuidebookInterface => {
  const newState = JSON.parse(JSON.stringify(state))
  let newGuidebooks = null
  let householdFinId = null
  switch (action.type) {
    case `${actions.FETCH_GUIDEBOOKS}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newState[householdFinId] = newState[householdFinId]
        ? newState[householdFinId]
        : {}
      newState[householdFinId] = {
        ...newState[householdFinId],
        currentGuidebookId: '',
        guidebooks: {
          ...mapGuidebooks(action.payload.data),
          ...newState[householdFinId].guidebooks
        }
      }
      return newState
    case `${actions.CREATE_GUIDEBOOK}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newState[householdFinId] = newState[householdFinId]
        ? newState[householdFinId]
        : {}
      newGuidebooks = newState[householdFinId].guidebooks
        ? newState[householdFinId].guidebooks
        : {}
      newGuidebooks[action.payload.data.id] = mapGuidebook(action.payload.data)
      newState[householdFinId] = {
        ...newState[householdFinId],
        guidebooks: newGuidebooks,
        currentGuidebookId: action.payload.data.id
      }
      return newState
    case `${actions.DELETE_GUIDEBOOK}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newGuidebooks = newState[householdFinId].guidebooks
      delete newGuidebooks[action.payload.data.guidebookFinId]
      newState[householdFinId] = {
        ...newState[householdFinId],
        guidebooks: newGuidebooks,
        currentGuidebookId: ''
      }
      return newState
    case `${actions.UPLOAD_GUIDEBOOK_PDF}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newGuidebooks = newState[householdFinId].guidebooks
      newGuidebooks[action.payload.data.guidebookFinId].pdfUrl =
        action.payload.data.pdfUrl
      newState[householdFinId] = {
        ...newState[householdFinId],
        guidebooks: newGuidebooks,
        currentGuidebookId: ''
      }
      return newState
    case `${actions.DELETE_GUIDEBOOK_PDF}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newGuidebooks = newState[householdFinId].guidebooks
      newGuidebooks[action.payload.data.guidebookFinId].pdfUrl = null
      newState[householdFinId] = {
        ...newState[householdFinId],
        guidebooks: newGuidebooks,
        currentGuidebookId: ''
      }
      return newState
    default:
      return state
  }
}

export default Guidebook
