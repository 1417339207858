import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/financialGoals'
import * as toastActions from '../../../../actions/toasts'
import FundedScoreDetailsModal from '../fundedScoreDetailsModal'
import FundedScoreHistoryList from './fundedScoreHistoryList'
import Tile from '../../../components/layout/tile'
import HistoricalLineChart from '../../../components/graphs/historicalLineChart'

import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../../objects/financialGoal'

interface FundedScoreTileProps {
  dispatch: Dispatch<GlobalState>
  planningSoftware: PlanningSoftwareType
  householdFinId: string
  fundedScores: FundedScoreObj[]
}

interface FundedScoreTileState {
  fundedScoreObj: FundedScoreObj
  showFundedScoreDetailsModal: boolean
}

const limitFundedScore: number = 1000

class FundedScoreTile extends Component<
  FundedScoreTileProps,
  FundedScoreTileState
> {
  constructor(props: FundedScoreTileProps) {
    super(props)
    this.state = {
      fundedScoreObj: null,
      showFundedScoreDetailsModal: false
    }
  }

  public openFundedScoreDetailsModal = (fundedScoreDetail?: FundedScoreObj) => {
    const setTileState = async () => {
      await this.setState({
        fundedScoreObj: fundedScoreDetail,
        showFundedScoreDetailsModal: !this.state.showFundedScoreDetailsModal
      })
    }

    setTileState()
  }

  public updateFundedScore = async (updatedFundedScore: FundedScoreObj) => {
    const { householdFinId } = this.props
    const { showFundedScoreDetailsModal } = this.state
    await this.props.dispatch(
      actions.updatedFundedScore(
        updatedFundedScore.id,
        updatedFundedScore,
        householdFinId
      )
    )

    if (showFundedScoreDetailsModal) {
      this.setState({
        showFundedScoreDetailsModal: false
      })
    }

    this.props.dispatch(
      toastActions.addToast({
        message: 'Successfully updated.',
        backgroundColor: '#D9F2B6'
      })
    )
  }

  public latestTopFundedScores = (): FundedScoreObj[] => {
    const { fundedScores } = this.props
    const latestGoals: FundedScoreObj[] = []

    fundedScores.forEach((fundedScore, index) => {
      if (index < limitFundedScore) latestGoals.push(fundedScore)
    })

    return latestGoals
  }

  public leftHeader = () => {
    return (
      <div className='funded-score-tile__header-w'>
        <div className='funded-score-tile__title'>ALL FUNDED SCORES</div>
      </div>
    )
  }

  public render() {
    const { planningSoftware, householdFinId, fundedScores } = this.props
    const { showFundedScoreDetailsModal } = this.state
    const latestTopFundedScoresArray = this.latestTopFundedScores()

    return (
      <Tile
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={this.leftHeader()}>
        <HistoricalLineChart
          id='historical-funded-score'
          limit={fundedScores.length}
          planningSoftware={planningSoftware}
          fundedScores={fundedScores}
          showModal={this.openFundedScoreDetailsModal}
          style={{ marginBottom: '20px' }}
        />

        <div className='funded-score-history__header row-w'>
          <div className='funded-score-history__row'>
            <div className='funded-score-history__row-col-name'>
              <label>Name</label>
            </div>
            <div className='funded-score-history__row-col-graph'>
              <label>Results</label>
            </div>
            <div className='funded-score-history__row-col-toggle'>
              <label>Show/Hide</label>
            </div>
            <div className='funded-score-history__row-col-updated'>
              <label>UPDATED</label>
            </div>
          </div>
        </div>
        <FundedScoreHistoryList
          showModal={this.openFundedScoreDetailsModal}
          householdFinId={householdFinId}
          fundedScores={latestTopFundedScoresArray}
          planningSoftware={planningSoftware}
        />

        {showFundedScoreDetailsModal ? (
          <FundedScoreDetailsModal
            planningSoftware={this.props.planningSoftware}
            fundedScoreObj={this.state.fundedScoreObj}
            saveFunc={this.updateFundedScore}
            closeModal={this.openFundedScoreDetailsModal}
          />
        ) : null}
      </Tile>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    planningSoftware: store.households[householdFinId]
      ? store.households[householdFinId].planningSoftware
      : 'Manual',
    fundedScores:
      store.financialGoals[householdFinId] &&
      store.financialGoals[householdFinId].fundedScores
  }
}

export default connect(mapStateToProps)(FundedScoreTile)
