import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { DeleteMoneyMind } from '../objects/moneymind'
import { ymmQuizIsNewVersion } from '../v3/helpers/ymmQuizIsNewVersion'

export const FETCH_MONEY_MINDS: string = 'FETCH_MONEY_MIND'
export const FETCH_MONEY_MINDS_PENDING: string = 'FETCH_MONEY_MIND_PENDING'
export const FETCH_MONEY_MINDS_FULFILLED: string = 'FETCH_MONEY_MIND_FULFILLED'
export const FETCH_MONEY_MINDS_REJECTED: string = 'FETCH_MONEY_MIND_REJECTED'
export const DELETE_MONEY_MIND: string = 'DELETE_MONEY_MIND'
export const DELETE_MONEY_MIND_PENDING: string = 'DELETE_MONEY_MIND_PENDING'
export const DELETE_MONEY_MIND_FULFILLED: string = 'DELETE_MONEY_MIND_FULFILLED'
export const DELETE_MONEY_MIND_REJECTED: string = 'DELETE_MONEY_MIND_REJECTED'
export const ADD_MONEY_MIND: string = 'ADD_MONEY_MIND'
export const ADD_MONEY_MIND_PENDING: string = 'ADD_MONEY_MIND_PENDING'
export const ADD_MONEY_MIND_FULFILLED: string = 'ADD_MONEY_MIND_FULFILLED'
export const ADD_MONEY_MIND_REJECTED: string = 'ADD_MONEY_MIND_REJECTED'
export const EDIT_MONEY_MIND: string = 'EDIT_MONEY_MIND'
export const EDIT_MONEY_MIND_PENDING: string = 'EDIT_MONEY_MIND_PENDING'
export const EDIT_MONEY_MIND_FULFILLED: string = 'EDIT_MONEY_MIND_FULFILLED'
export const EDIT_MONEY_MIND_REJECTED: string = 'EDIT_MONEY_MIND_REJECTED'
export const EMAIL_MONEY_MIND: string = 'EMAIL_MONEY_MIND'
export const EMAIL_MONEY_MIND_PENDING: string = 'EMAIL_MONEY_MIND_PENDING'
export const EMAIL_MONEY_MIND_FULFILLED: string = 'EMAIL_MONEY_MIND_FULFILLED'
export const EMAIL_MONEY_MIND_REJECTED: string = 'EMAIL_MONEY_MIND_REJECTED'
export const FETCH_MONEY_MIND_TOKEN: string = 'FETCH_MONEY_MIND_TOKEN'

export const getMoneyMind = (
  contactId: string,
  householdId: string,
  isPrimaryContact: boolean
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: FETCH_MONEY_MINDS,
    payload: api()
      .get('/clients/' + contactId + '/moneyminds')
      .then((response) => {
        response.data.moneyminds = response.data
        response.data.household = householdId
        response.data.primaryContact = isPrimaryContact
        return response
      })
  }
}

export const deleteMoneyMind = (
  deleteMoneyMindObj: DeleteMoneyMind
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  const { contactId, moneymindId } = deleteMoneyMindObj
  return {
    type: DELETE_MONEY_MIND,
    payload: api()
      .delete('/clients/' + contactId + '/moneyminds/' + moneymindId)
      .then((response) => {
        response.data = {
          householdFinId: deleteMoneyMindObj.householdFinId,
          moneymindId: deleteMoneyMindObj.moneymindId,
          contactId: deleteMoneyMindObj.contactId,
          contactKey: deleteMoneyMindObj.contactKey
        }
        return response
      })
  }
}

export const addMoneyMind = (
  request: any,
  contactId: string,
  householdId: string,
  isPrimaryContact: boolean
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: ADD_MONEY_MIND,
    payload: api()
      .post('/clients/' + contactId + '/moneyminds', request)
      .then((response) => {
        response.data.moneyminds = response.data
        response.data.household = householdId
        response.data.primaryContact = isPrimaryContact
        return response
      })
  }
}

export const editMoneyMind = (
  request: any,
  contactId: string,
  moneymindId: string,
  householdId: string,
  isPrimaryContact: boolean
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: EDIT_MONEY_MIND,
    payload: api()
      .put('/clients/' + contactId + '/moneyminds/' + moneymindId, request)
      .then((response) => {
        response.data.moneyminds = response.data
        response.data.household = householdId
        response.data.primaryContact = isPrimaryContact
        return response
      })
  }
}

export const sendMoneyMindEmail = (
  contactId: string,
  token?: string // Optional for new version of YMM
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  let body = {}
  // For new YMM version token is required.
  if (ymmQuizIsNewVersion() && !token) {
    console.error('New version for YMM requires a token.')
  }

  // If the token is present attach it to body.
  if (token) body = { token }

  return {
    type: EMAIL_MONEY_MIND,
    payload: api()
      .post('/clients/' + contactId + '/moneyminds/email', body)
      .then((response) => {
        return response
      })
  }
}

/**
 * Get the token on client side, to be used for print and email.
 * @param {string} contactId Clients uuid
 * @returns
 */
export const getMoneyMindToken = (
  contactId: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: FETCH_MONEY_MIND_TOKEN,
    payload: api()
      .get('/clients/' + contactId + '/moneyminds/token')
      .then((response) => {
        return response
      })
  }
}
