import React, { FC, ChangeEvent } from 'react'

interface FilterCheckboxProps {
  filters: string[]
  filterName: string
  toggleCheckbox(e: ChangeEvent<HTMLInputElement>): void
}

const FilterCheckbox: FC<FilterCheckboxProps> = ({
  toggleCheckbox,
  filters,
  filterName
}) => {
  const isChecked = filters?.some((e: string) => e === filterName)
  return (
    <label
      key={filterName}
      className={
        isChecked
          ? 'strategy-search__checkbox-option strategy-search__checkbox-option--checked'
          : 'strategy-search__checkbox-option'
      }>
      <input
        onChange={toggleCheckbox}
        type='checkbox'
        value={filterName}
        checked={isChecked}
        aria-checked={isChecked}
      />
      {filterName}
    </label>
  )
}

export default FilterCheckbox
