import React, { Component, Fragment, createRef } from 'react'
import { Dispatch, connect } from 'react-redux'
import moment from 'moment'

import { TaskObj } from '../../../objects/tasks'
import * as actions from '../../../actions/tasks'
import { GlobalState } from '../../../reducers'
import ProfileAvatar from '../../components/profileAvatar'
import ToggleSwitch from '../../components/toggleSwitch'
import Tooltip from '../../components/tooltip'

import { ReactComponent as LinkIcon } from '../../assets/images/icons/link.svg'
import { ReactComponent as LockIcon } from '../../assets/images/icons/ic_lock.svg'
import Calendar from '../../assets/images/icons/png/ic_calendar.png'
import { updateSideBarState } from '../../../actions/globalSettings'
import { renderStatus } from '../../helpers/tasks'

interface TaskItemProps {
  task: TaskObj
  hasPic: boolean
  householdFinId: string
  isEditModalOpen?: boolean
  activeTaskId?: string
  dispatch: Dispatch<GlobalState>
  openEditTaskModal?(selectedTask: TaskObj): any
}

interface TaskItemState {
  statusOverflow: boolean
  showStatusTooltip: boolean
}

export class TaskItem extends Component<TaskItemProps, TaskItemState> {
  private statusRef = createRef<HTMLDivElement>()
  constructor(props: TaskItemProps) {
    super(props)
    this.state = {
      statusOverflow: false,
      showStatusTooltip: false
    }
  }

  public componentDidMount() {
    this.setStatusOverflow()
  }

  public componentDidUpdate(prevProps: Readonly<TaskItemProps>) {
    if (this.props.task !== prevProps.task) {
      this.setStatusOverflow()
    }
  }

  public setStatusOverflow = () => {
    this.setState({
      statusOverflow: this.isEllipsisActive(this.statusRef.current)
    })
  }

  public isEllipsisActive = (e: HTMLDivElement) => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth
  }

  public toggleStatusToolTip = () => {
    this.setState({ showStatusTooltip: !this.state.showStatusTooltip })
  }

  public navigateToSfTask = () => {
    const { task } = this.props
    const { REACT_APP_SF_LIGHTNING_BASE } = window._env_
    if (REACT_APP_SF_LIGHTNING_BASE)
      window.open(
        `${REACT_APP_SF_LIGHTNING_BASE}r/Task/${task.sfId}/view`,
        '_blank'
      )
  }

  public renderDescription = () => {
    const { task } = this.props
    if (task.assigneeType === 'user') {
      return (
        <Fragment>
          <span className='task__subject'>{task.subject || ''}</span>
          <span className='task__lock-icon'>
            <Tooltip
              message={
                'To edit this task, open it in your CRM. Once the changes are saved, return to this screen, and refresh the browser.'
              }
              width={160}
              position='bottom'
              multiLine={true}>
              <LockIcon />
            </Tooltip>
          </span>
          {task.sfId && (
            <span className='task__lock-icon' onClick={this.navigateToSfTask}>
              <LinkIcon style={{ color: '#255eba' }} width={18} height={18} />
            </span>
          )}
        </Fragment>
      )
    } else return <span className='task__subject'>{task.subject || ''}</span>
  }

  public toggleTaskShowHide = async () => {
    const { householdFinId, task, dispatch } = this.props
    let updatedTaskObj: any = {}
    // If CRM Task, only include assigneeType and includeInGuidebook, else include everything
    if (task.assigneeType === 'user') {
      updatedTaskObj = {
        assigneeType: task.assigneeType,
        includeInGuidebook: !task.includeInGuidebook
      }
    } else {
      updatedTaskObj = {
        ...task,
        includeInGuidebook: !task.includeInGuidebook
      }
    }
    await dispatch(actions.updateTask(householdFinId, task.id, updatedTaskObj))
    dispatch(
      updateSideBarState({ householdId: householdFinId, sideBarState: false })
    )
  }

  public render() {
    const {
      openEditTaskModal,
      activeTaskId = '',
      task,
      task: taskItem,
      hasPic,
      isEditModalOpen
    } = this.props
    const { statusOverflow, showStatusTooltip } = this.state
    const dueDate =
      task && task.dueDate && moment(task.dueDate).isValid()
        ? moment(task.dueDate).format('M/D/YY')
        : ''
    return (
      <div
        style={
          task.assigneeType === 'client'
            ? {
                cursor: 'pointer',
                pageBreakInside: 'avoid',
                pageBreakBefore: 'auto'
              }
            : {
                pageBreakInside: 'avoid',
                pageBreakBefore: 'auto'
              }
        }
        onClick={openEditTaskModal && openEditTaskModal(taskItem)}
        className={`task__grid-row ${
          activeTaskId === taskItem?.id ? `task__grid-row--active` : ''
        }`}>
        <div className='task__grid-col-left'>
          <div className=' task__desc-w task__desc'>
            {this.renderDescription()}
          </div>
        </div>
        <div
          className={`task__grid-col-left ${
            statusOverflow ? 'task__position-relative' : ''
          }`}>
          <div
            className='task__status'
            ref={this.statusRef}
            onMouseOver={statusOverflow ? this.toggleStatusToolTip : null}
            onMouseLeave={statusOverflow ? this.toggleStatusToolTip : null}>
            {renderStatus(task)}
          </div>
          {showStatusTooltip && (
            <div className='task__tooltip'>{renderStatus(task)}</div>
          )}
        </div>
        <div className='task__grid-col-left'>
          <div className='task__grid-col-right task__date'>
            <img
              src={Calendar}
              alt='Calendar Icon'
              className='task__date-icon'
            />
            {dueDate}
          </div>
        </div>
        <div className='task__grid-col-left'>
          <div className='task__owner'>
            {hasPic ? (
              <span className='task__pic'>
                <ProfileAvatar />
              </span>
            ) : null}{' '}
            {task.assigneeNames}
          </div>
        </div>
        <div id='toggle' className='task__grid-col-center'>
          <ToggleSwitch
            disable={isEditModalOpen}
            active={task.includeInGuidebook}
            handleToggleActive={this.toggleTaskShowHide}
          />
        </div>
      </div>
    )
  }
}

export default connect()(TaskItem)
