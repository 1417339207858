import React, { Component } from 'react'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'

import SuggestedGoalCards from '../components/suggestedGoalCards'

import { InstitutionObj } from '../../../../objects/institution'
import { CardObj } from '../../../../objects/HonestConversations/card'
import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'
import { GoalResponse } from '../../../../objects/financialGoalActions'

import rightArrow from '../../../assets/images/icons/goals/goal-action-arrow-blue.png'
import noteIcon from '../../../assets/images/icons/goals/goal-note-gray-large.png'
import { updateGoalActionById } from '../../../../actions/financialGoalActions'

interface GoalView4Props {
  activeGoal: GoalResponse
  householdFinId: string
  setViewKey(id: number): void
  dispatch: Dispatch<GlobalState>
  honestConversation: HonestConversationsExerciseObj
  institution: InstitutionObj
  suggestedGoals: any[]
  suggestedGoalId: string
  goalCls: string
}
class GoalView4 extends Component<
  GoalView4Props,
  {
    templates: any
  }
> {
  constructor(props: GoalView4Props) {
    super(props)
    this.state = {
      templates: []
    }
  }

  componentDidMount = () => {
    const { activeGoal, suggestedGoalId = '', suggestedGoals = [] } = this.props
    const searchConfig = { key: 'name', comparator: activeGoal.name }

    if (suggestedGoalId) {
      searchConfig.key = 'id'
      searchConfig.comparator = suggestedGoalId
    }

    const suggestedGoal = suggestedGoals.find(
      (goal) => goal[searchConfig.key] === searchConfig.comparator
    )

    if (suggestedGoal) {
      this.setState({
        templates: suggestedGoal.goalNoteTemplates || []
      })
    }
  }

  public getItemData = (): CardObj[] => {
    const { templates } = this.state
    return templates
      ?.sort((a: any, b: any) => {
        if (a.note < b.note) return -1
        if (a.note > b.note) return 1
        return 0
      })
      .map((template: any) => {
        return {
          ...{ template },
          ...{ title: template.note }
        }
      })
  }

  public onCardRowClick = (card: CardObj) => {
    const {
      dispatch,
      householdFinId,
      setViewKey,
      activeGoal: {
        cards,
        id,
        name,
        status,
        assigneePrimaryId,
        assigneeSecondaryId,
        dueDate,
        inGuidecenter,
        type
      }
    } = this.props
    const reqBody: any = {
      id,
      name,
      note: card.title,
      status,
      assigneePrimaryId,
      assigneeSecondaryId,
      dueDate,
      cards,
      inGuidecenter,
      type
    }
    dispatch(updateGoalActionById(reqBody, householdFinId))
    setViewKey(2)
  }

  render() {
    const { goalCls } = this.props
    const notesData = this.getItemData() || []
    return notesData.length ? (
      <div className='side-bar-body__priorities-body'>
        <SuggestedGoalCards
          showCardView={true}
          showDivider={true}
          showTitleIcon={false}
          cards={notesData}
          showIcon={true}
          iconImg={rightArrow}
          imgWidth={'24'}
          imgHeight={'24'}
          rowAction={this.onCardRowClick}
          classnames={goalCls}
          showAsLink={true}
        />
      </div>
    ) : (
      <div className='side-bar-body__priorities-no-data'>
        <img src={noteIcon} width='64' height='64' />
        <span>There are no templates for this goal.</span>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    institution: store.institution
  }
}

export default connect(mapStateToProps)(GoalView4)
