import { createStore, applyMiddleware, StoreEnhancer } from 'redux'
import { history } from './browserHistory'

import { routerMiddleware } from 'react-router-redux'
import reducers, { GlobalState } from './reducers'

import { composeWithDevTools } from 'redux-devtools-extension'
import PromiseMiddleware from 'redux-promise-middleware'
import Thunk from 'redux-thunk'
import NavigationScroll from './middlewares/navigationScroll'
import { authentication } from './middlewares/authentication'
import { meetingMode } from './middlewares/meetingMode'

const router = routerMiddleware(history)

const middleware: StoreEnhancer<GlobalState> = composeWithDevTools(
  applyMiddleware(
    PromiseMiddleware(),
    Thunk,
    router,
    authentication,
    NavigationScroll,
    meetingMode
  )
)

export { history }

export type AppStore = ReturnType<typeof setupStore>
export const store = createStore<GlobalState>(reducers, middleware)

// preloaded state for tests
export const setupStore = (preloadedState?: Partial<GlobalState>) => {
  return createStore<GlobalState>(reducers, preloadedState as any, middleware)
}
