import React from 'react'
import NoResult from '../../../assets/images/icons/no-result.png'

const strategyNull = () => {
  return (
    <div className='no-strategy__w'>
      <div className='no-strategy__img'>
        <img src={NoResult} alt='no-img' />
      </div>
      <div className='no-strategy__text'>
        <p>There are no search results for this query.</p>
        <p>
          Try searching for the different search term and don’t forget to
          spellcheck.
        </p>
      </div>
    </div>
  )
}

export default strategyNull
