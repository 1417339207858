import React from 'react'
import Select, { components } from 'react-select'
import { OptionsType, ValueType } from 'react-select/lib/types'
import { ContactObj, ContactsInterface } from '../../../../objects/contact'
import {
  OfficeTeamMemberObj,
  OfficeTeamObj
} from '../../../../objects/officeTeams'
import CarrotIcon from '../../../assets/images/icons/png/carrot-gray.png'
import { Option } from 'react-select/lib/filters'
import { TaskObj } from '../../../../objects/tasks'
import {
  householdContactSelectOptions,
  officeTeamSelectOptions
} from '../../../helpers/tasks'
import { AssigneeLabel } from './assigneeLabel'

// custom: use CarrotIcon
export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={CarrotIcon} role='presentation' alt='' width='10' height='6' />
    </components.DropdownIndicator>
  )
}
// custom: remove separator
export const IndicatorSeparator = (): null => null

export const selectStyle = (custom?: boolean) => {
  return {
    control: (base: any) => ({
      ...base,
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#c2c2c2'
      },
      border: 'solid 1px #c2c2c2',
      cursor: 'pointer'
    }),
    option: (
      base: any,
      { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }
    ) => {
      const customOption = custom ? { height: '51px', padding: '0px 0px' } : {}
      return {
        ...base,
        ...customOption,
        color: '#231f20',
        cursor: 'pointer',
        backgroundColor: isFocused || isSelected ? '#f5f5f5' : '#fff'
      }
    }
  }
}

export type SelectOptionType = {
  label: any
  value: string
  fieldName?: string
}

export const assigneeDataOptions = (
  optionData: OfficeTeamObj[] | ContactsInterface
) => {
  // custom option look, photo(+count) + name
  const customLabel = (
    id: string,
    name: string,
    members: OfficeTeamMemberObj[],
    contacts: ContactObj[],
    assigneeType: 'primary' | 'secondary' | 'joint' | 'user',
    isLast: boolean
  ) => {
    return (
      <AssigneeLabel
        id={id}
        name={name}
        members={members}
        contacts={contacts}
        assigneeType={assigneeType}
        isLast={isLast}
      />
    )
  }
  // office teams
  if ((optionData as OfficeTeamObj[])?.length) {
    return officeTeamSelectOptions(optionData as OfficeTeamObj[], customLabel)
  }
  // household contacts
  if (
    (optionData as ContactsInterface)?.primary ||
    (optionData as ContactsInterface)?.secondary
  ) {
    return householdContactSelectOptions(
      optionData as ContactsInterface,
      customLabel
    )
  }

  return []
}

export const AssigneeSelect: React.FC<{
  headerTitle: string
  optionData: any
  activeTask: TaskObj
  selectedOption: ValueType<SelectOptionType>
  onChangeFunc(value: ValueType<SelectOptionType>): void
  onBlurFunc(): void
}> = (props) => {
  const { activeTask, onBlurFunc, selectedOption } = props
  const onChangeHandler = (option: ValueType<SelectOptionType>) => {
    props.onChangeFunc(option)
  }

  const setData = (): OptionsType<SelectOptionType> => {
    return assigneeDataOptions(props.optionData)
  }

  const dataSet = setData()

  // extract the value to create a default value clone
  let assigneeDefaultValue: ValueType<SelectOptionType> = null
  if (selectedOption) {
    const { value } = selectedOption as SelectOptionType
    assigneeDefaultValue = { label: value, value }
  }
  if (activeTask.id !== 'NEW' && !selectedOption) {
    const matchedOption = dataSet.filter((option: SelectOptionType) => {
      return option.value === activeTask.assigneeNames
    })?.[0]
    if (matchedOption) {
      const { value } = matchedOption as SelectOptionType
      assigneeDefaultValue = { label: value, value }
    }
  }

  // our search for assignee filter
  const assigneeFilter = (option: Option, searchText: string): boolean => {
    if (!searchText) return true
    const { value } = option.data
    const pattern = searchText.trim().replace(/(?=[^a-zA-Z0-9])/g, '\\')
    return value.match(RegExp(pattern, 'gi'))
  }

  return (
    <div className='task-add__container'>
      <div className='task-add__label'>{props.headerTitle}</div>
      <Select
        isClearable={true}
        styles={selectStyle(true)}
        components={{ IndicatorSeparator, DropdownIndicator }}
        onChange={onChangeHandler}
        onBlur={onBlurFunc}
        value={assigneeDefaultValue}
        options={dataSet}
        isSearchable={true}
        filterOption={assigneeFilter}
        placeholder={'Task owner'}
      />
    </div>
  )
}
