import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { revokeAndLogout } from '../../helpers'
import { meetingModeFalse } from '../../../actions/meetingMode'
import { setUser } from '../../../analytics/googleTagManager'
interface LogoutProps {
  dispatch: Dispatch<GlobalState>
}

class Logout extends React.Component<LogoutProps> {
  public async componentDidMount() {
    revokeAndLogout()
    this.props.dispatch(meetingModeFalse())
    setUser(null, null, null, true)
  }
  public render(): React.ReactNode {
    return null
  }
}

export default connect()(Logout)
