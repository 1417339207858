import moment from 'moment'
import {
  SortSettingProperty,
  SortType
} from '../../objects/preferenceSortSetting'
import {
  ClientAccountObjState,
  DiscreteAllocation
} from '../../objects/clientAccount'
import { HouseholdPreferencesObj } from '../../objects/householdPreferences'

export const compareNumericField = (
  a: number,
  b: number,
  descending?: boolean
) => {
  const comparisonScore = a - b > 0 ? 1 : -1
  return descending === true ? -comparisonScore : comparisonScore
}

export const compareStringWithBlankField = (
  a: string,
  b: string,
  descending?: boolean
) => {
  return a === '' || b === ''
    ? b.localeCompare(a)
    : compareStringField(a, b, descending)
}

export const compareStringField = (
  a: string,
  b: string,
  descending?: boolean
) => {
  return descending === true ? b?.localeCompare(a) : a?.localeCompare(b)
}

export const compareDates = (a: string, b: string, descending?: boolean) => {
  return !descending ? moment(a).diff(moment(b)) : moment(b).diff(moment(a))
}

export const compareAllocationField = (
  a: DiscreteAllocation,
  b: DiscreteAllocation,
  descending?: boolean
) => {
  return descending
    ? b.equity - a.equity ||
        b.fixed - a.fixed ||
        b.cash - a.cash ||
        b.alt - a.alt ||
        b.unclassified - a.unclassified
    : a.equity - b.equity ||
        a.fixed - b.fixed ||
        a.cash - b.cash ||
        a.alt - b.alt ||
        a.unclassified - b.unclassified
}

export const compareStatusField = (a: string, b: string, desc: boolean) => {
  const statusOrder = {
    'Optimal Position': 0,
    'Improvement Needed': 1,
    'Immediate Action Needed': 2,
    'NA | Pending Review': 3,
    'Not Selected': 4,
    'Show All': 5
  }
  return desc
    ? statusOrder[a] - statusOrder[b]
    : statusOrder[b] - statusOrder[a]
}

export const sanitizeSortFieldValues = (
  accountObjects: ClientAccountObjState,
  fields: any
) => {
  const { sortBy, groupBy } = fields
  const sanitizedAccountObjects = {}
  Object.keys(accountObjects).forEach((key: string) => {
    const account = accountObjects[key]
    sanitizedAccountObjects[key] = account
    if (!account[sortBy]) {
      sanitizedAccountObjects[key][sortBy] = ''
    }
    if (!account[groupBy]) {
      sanitizedAccountObjects[key][groupBy] = ''
    }
  })
  return sanitizedAccountObjects
}

export const mapValueToOption = (
  optionSet: SortSettingProperty[],
  value: string
) => {
  return optionSet.filter((option) => {
    return value === option.label || value === option.value
  })[0]
}

export const mapNetworthPreferences = (
  assetSubType: string,
  preferences: SortType
) => {
  const mappedPrefs = {}
  const fieldNames = {
    cash: {
      groupBy: 'networthCashGroupBy',
      sortBy: 'networthCashSortBy',
      sortingOrder: 'networthCashSortingOrder'
    },
    investments: {
      groupBy: 'networthInvestmentsGroupBy',
      sortBy: 'networthInvestmentsSortBy',
      sortingOrder: 'networthInvestmentsSortingOrder'
    },
    liabilities: {
      groupBy: 'networthLiabilitiesGroupBy',
      sortBy: 'networthLiabilitiesSortBy',
      sortingOrder: 'networthLiabilitiesSortingOrder'
    },
    other: {
      groupBy: 'networthOtherAssetsGroupBy',
      sortBy: 'networthOtherAssetsSortBy',
      sortingOrder: 'networthOtherAssetsSortingOrder'
    },
    pending: {
      groupBy: 'networthUnclassifiedGroupBy',
      sortBy: 'networthUnclassifiedSortBy',
      sortingOrder: 'networthUnclassifiedSortingOrder'
    }
  }
  Object.keys(preferences).forEach((prefType) => {
    const fieldName = fieldNames[assetSubType][prefType]
    mappedPrefs[fieldName] = preferences[prefType]
  })
  return mappedPrefs
}

export const mapInvPreferences = (
  groupBy: string,
  sortBy: string,
  sortingOrder: string
) => {
  return {
    investmentsInvestmentsGroupBy: groupBy,
    investmentsInvestmentsSortBy: sortBy,
    investmentsInvestmentsSortingOrder: sortingOrder
  }
}

export const generateNewPreferences = (): HouseholdPreferencesObj => {
  return {
    networthCashGroupBy: 'Classification',
    networthCashSortBy: 'Account Name',
    networthCashSortingOrder: 'Ascending',
    networthInvestmentsGroupBy: 'Classification',
    networthInvestmentsSortBy: 'Account Name',
    networthInvestmentsSortingOrder: 'Ascending',
    networthOtherAssetsGroupBy: 'Classification',
    networthOtherAssetsSortBy: 'Account Name',
    networthOtherAssetsSortingOrder: 'Ascending',
    networthLiabilitiesGroupBy: 'Classification',
    networthLiabilitiesSortBy: 'Account Name',
    networthLiabilitiesSortingOrder: 'Ascending',
    networthUnclassifiedGroupBy: 'Classification',
    networthUnclassifiedSortBy: 'Account Name',
    networthUnclassifiedSortingOrder: 'Ascending',
    goalsTimingGroupBy: 'Owner',
    goalsTimingSortBy: 'Name',
    goalsTimingSortingOrder: 'Ascending',
    goalsSavingsGroupBy: 'Owner',
    goalsSavingsSortBy: 'Name',
    goalsSavingsSortingOrder: 'Ascending',
    goalsSpendingGroupBy: 'Owner',
    goalsSpendingSortBy: 'Name',
    goalsSpendingSortingOrder: 'Ascending',
    goalsPortfoliosGroupBy: 'Owner',
    goalsPortfoliosSortBy: 'Name',
    goalsPortfoliosSortingOrder: 'Ascending',
    goalsLegacyGroupBy: 'Owner',
    goalsLegacySortBy: 'Name',
    goalsLegacySortingOrder: 'Ascending',
    goalsRetirementGroupBy: 'Owner',
    goalsRetirementSortBy: 'Name',
    goalsRetirementSortingOrder: 'Ascending',
    investmentsCashGroupBy: 'Classification',
    investmentsCashSortBy: 'Account Name',
    investmentsCashSortingOrder: 'Ascending',
    investmentsInvestmentsGroupBy: 'Classification',
    investmentsInvestmentsSortBy: 'Account Name',
    investmentsInvestmentsSortingOrder: 'Ascending',
    investmentsOtherAssetsGroupBy: 'Classification',
    investmentsOtherAssetsSortBy: 'Account Name',
    investmentsOtherAssetsSortingOrder: 'Ascending',
    investmentsLiabilitiesGroupBy: 'Classification',
    investmentsLiabilitiesSortBy: 'Account Name',
    investmentsLiabilitiesSortingOrder: 'Ascending',
    investmentsUnclassifiedGroupBy: 'Classification',
    investmentsUnclassifiedSortBy: 'Account Name',
    investmentsUnclassifiedSortingOrder: 'Ascending',
    palGroupBy: 'All',
    palSortBy: 'Name',
    palSortingOrder: 'Ascending',
    goalActionsSortBy: 'Due Date',
    goalActionsShowInactiveItems: false,
    goalActionsShowFinancialGoals: true,
    goalActionsShowLifeActions: true
  }
}
