import { FunctionComponent, SVGProps } from 'react'

import { ReactComponent as PerformanceIcon } from '../assets/images/icons/viewfinder_performance.svg'
import { ReactComponent as ProtectionIcon } from '../assets/images/icons/viewfinder_protection.svg'
import { ReactComponent as TaxesIcon } from '../assets/images/icons/viewfinder_taxes.svg'
import { ReactComponent as CostsIcon } from '../assets/images/icons/viewfinder_costs.svg'

export const getPriorityIcon = (
  priorityType: string
): FunctionComponent<SVGProps<SVGSVGElement>> => {
  switch (priorityType) {
    case 'performance':
      return PerformanceIcon
    case 'protection':
      return ProtectionIcon
    case 'taxes':
      return TaxesIcon
    case 'cost':
      return CostsIcon
    default:
      return null
  }
}
