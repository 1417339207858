import React, { Component } from 'react'
import Tile from '../../components/layout/tile'
import Select from 'react-select'
import Input from '../../components/Input'

interface RecipientInfoProps {
  recipientInfo: {
    firstName: string
    lastName: string
    street: string
    addressLine2: string
    city: string
    state: string
    postalCode: string
    phone: string
  }
  clientRecipient: boolean
  missingFields(): void
  selectRecipientType(value: any): void
  updateClientInfo(value: any): void
  updateAdvisorInfo(value: any): void
}
class RecipientInfo extends Component<RecipientInfoProps, {}> {
  public errorMessage = (val: string, errorMessage: string) => {
    if (errorMessage && !val) {
      return 'Enter a ' + errorMessage
    } else {
      return null
    }
  }

  public render() {
    const {
      selectRecipientType,
      recipientInfo,
      updateClientInfo,
      updateAdvisorInfo,
      clientRecipient,
      missingFields
    } = this.props
    return (
      <div className='mimeo__section-w'>
        <Tile
          iconPng={null}
          leftHeader={'RECIPIENT INFO'}
          style={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '20px'
          }}>
          <div className='mimeo__section'>
            <div className='mimeo__section-left'>
              <div className='mimeo-shipping__shipping-w'>
                <span>Recipient type</span>
                <Select
                  onChange={selectRecipientType}
                  className='mimeo__select'
                  defaultValue={[{ value: 'client', label: 'Client' }]}
                  options={[
                    { value: 'client', label: 'Client' },
                    { value: 'office', label: 'Office' }
                  ]}
                />
              </div>
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__section-left'>
              <div className='mimeo__label'>
                <Input
                  mandatory
                  title='First Name'
                  inputType='text'
                  content={recipientInfo.firstName}
                  name='firstName'
                  controlFunc={
                    clientRecipient ? updateClientInfo : updateAdvisorInfo
                  }
                  onBlur={missingFields}
                  error={this.errorMessage(
                    recipientInfo.firstName,
                    'First Name'
                  )}
                />
              </div>
            </div>
            <div className='mimeo__section-right'>
              <div className='mimeo__label'>
                <Input
                  mandatory
                  title='Last Name'
                  inputType='text'
                  content={recipientInfo.lastName}
                  name='lastName'
                  controlFunc={
                    clientRecipient ? updateClientInfo : updateAdvisorInfo
                  }
                  onBlur={missingFields}
                  error={this.errorMessage(recipientInfo.lastName, 'Last Name')}
                />
              </div>
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label'>
              <Input
                mandatory
                title='Street'
                inputType='text'
                content={recipientInfo.street}
                name='street'
                controlFunc={
                  clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(recipientInfo.street, 'Street')}
              />
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label'>
              <Input
                title='Address Line 2'
                inputType='text'
                content={recipientInfo.addressLine2}
                name='addressLine2'
                controlFunc={
                  clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
              />
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label mimeo__label--trips'>
              <Input
                mandatory
                title='City'
                inputType='text'
                content={recipientInfo.city}
                name='city'
                controlFunc={
                  clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(recipientInfo.city, 'City')}
              />
            </div>
            <div className='mimeo__label mimeo__label--trips'>
              <Input
                mandatory
                title='State/Province'
                inputType='text'
                content={recipientInfo.state}
                name='state'
                maxLength={2}
                controlFunc={
                  clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(recipientInfo.state, 'State/Province')}
              />
            </div>
            <div className='mimeo__label mimeo__label--trips'>
              <Input
                mandatory
                title='Zip/Postal Code'
                inputType='text'
                content={recipientInfo.postalCode}
                name='postalCode'
                controlFunc={
                  clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(
                  recipientInfo.postalCode,
                  'Zip/Postal Code'
                )}
              />
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label'>
              <Input
                mandatory
                title='Phone'
                inputType='tel'
                content={recipientInfo.phone}
                name='phone'
                controlFunc={
                  clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(recipientInfo.phone, 'Phone')}
              />
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

export default RecipientInfo
