import React, { Component } from 'react'

interface ScoreSelectorProps {
  singleView?: boolean
  cardIndex?: any
  modalElement?: boolean
  score?: number
  isPrimary?: boolean
  onScoreChange?(score: number, isPrimary: boolean, key?: number): void
}

interface ScoreSelectorState {
  score: number
}

class ScoreSelector extends Component<ScoreSelectorProps, ScoreSelectorState> {
  constructor(props: ScoreSelectorProps) {
    super(props)
    this.state = {
      score: this.props.score || null
    }
  }

  public componentDidUpdate() {
    if (this.props.score !== this.state.score) {
      this.setState({ score: this.props.score })
    }
  }

  public selectScore = (score: number) => (e: any) => {
    this.props.onScoreChange(score, this.props.isPrimary, this.props.cardIndex)
  }

  public render() {
    const { modalElement, score, singleView } = this.props
    const scoreOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    return (
      <div
        className={
          modalElement
            ? 'score-selector score-selector--modal-element'
            : 'score-selector'
        }>
        <div className='score-selector__selector-flex-w'>
          {scoreOptions.map((item) => {
            let className = 'score-selector__selector-box'
            if (singleView) {
              className += ' score-selector__selector-box--single-view'
            }
            if (modalElement) {
              className += ' score-selector__selector-box--modal-element'
            }
            if (item <= score) {
              if (score >= 7) {
                className += ' score-selector__selector-box--green'
              } else {
                className += ' score-selector__selector-box--orange'
              }
            }
            return (
              <div
                className={className}
                key={item}
                onClick={this.selectScore(item)}>
                <div
                  className={
                    score === item
                      ? 'score-selector__selector-number score-selector__selector-number--selected'
                      : 'score-selector__selector-number'
                  }>
                  {item}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default ScoreSelector
