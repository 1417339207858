import React from 'react'
import { connect } from 'react-redux'
import { HonestConversationCombinedCardRow } from './hcCardRow'
import {
  HonestConversationsExerciseObj,
  HouseholdCardObj,
  CardRankingObj
} from '../../../../objects/honestConversations'
import { FinancialGoalActionObj } from '../../../../objects/financialGoalActions'
import { ContactsInterface } from '../../../../objects/contact'
import { GlobalState } from '../../../../reducers'
import { filterGoals } from '../../../helpers/goals'
import goalActionsSelector from '../../../../selectors/v3/goals'

interface HonestConversationCombinedCardsProps {
  honestConversation: HonestConversationsExerciseObj
  householdFinId?: string
  contacts: ContactsInterface
  cards: CardRankingObj[]
  exerciseView?: boolean
  selectedActionCardId: string
  goals: FinancialGoalActionObj[]
}

class HonestConversationCombinedCards extends React.Component<
  HonestConversationCombinedCardsProps
> {
  public getSortedCardScores = (
    cardScores: any,
    householdCard: HouseholdCardObj
  ): number => {
    let score: number = 0
    if (cardScores && Object.keys(cardScores).length > 0) {
      Object.keys(cardScores).forEach((cardScoreKey) => {
        if (
          cardScores[cardScoreKey].householdCard &&
          cardScores[cardScoreKey].householdCard.id === householdCard.id
        ) {
          score = cardScores[cardScoreKey].score
        }
      })
    }
    return score
  }

  public honestConversationRows = () => {
    const { goals, honestConversation, contacts, cards } = this.props
    const { householdCards } = honestConversation

    const selectedHouseholdCards: HouseholdCardObj[] = Object.keys(
      householdCards
    ).map((key) => {
      return householdCards[key]
    })

    const displayCards: HouseholdCardObj[] = Array<HouseholdCardObj>(5).fill({
      id: null,
      exerciseId: null,
      cardId: null,
      commentPrimary: null,
      commentSecondary: null,
      ranking: null,
      createdDate: null,
      card: null
    })

    selectedHouseholdCards.forEach((card: HouseholdCardObj) => {
      displayCards.splice(card.ranking - 1, 1, card)
    })

    return displayCards.map((householdCard, index) => {
      const hhCard = cards.find((card) => card.id === householdCard.id)
      const listOfActions =
        householdCard.card && filterGoals(goals, householdCard?.card?.id)
      return (
        <HonestConversationCombinedCardRow
          key={index}
          displayIndex={index + 1}
          card={householdCard?.card || null}
          contacts={contacts}
          primaryCardScore={hhCard?.primaryScore || 0}
          secondaryCardScore={hhCard?.secondaryScore || 0}
          lifeActions={listOfActions}
          honestConversation={honestConversation}
        />
      )
    })
  }

  public renderExerciseView = () => {
    const { contacts } = this.props
    if (contacts) {
      const { primary, secondary } = contacts
      return (
        <div className='hc-meeting__sub-header--title hc-meeting__sub-header--title-names hc-meeting__sub-header--title-names-w'>
          <div className='hc-meeting__sub-header--title-name'>
            {secondary.firstName ? secondary.firstName : 'Secondary'}
          </div>
          <div className='hc-meeting__sub-header--title-name'>
            {primary.firstName ? primary.firstName : 'Primary'}
          </div>
        </div>
      )
    } else return null
  }

  public combinedCards = () => {
    const { contacts, exerciseView, honestConversation } = this.props
    const primary = contacts?.primary
    const secondary = contacts?.secondary
    const primaryFirstName = primary?.firstName || ''

    const title = primaryFirstName
      ? `${primaryFirstName}'s Score`
      : 'Primary Score'
    return (
      <div>
        <div className='hc-meeting__sub-header hc-meeting__header-content hc-meeting__content-header'>
          <div className='hc-meeting__sub-header--title'>
            {secondary ? 'Combined Choices' : title}
          </div>
          {exerciseView && secondary && this.renderExerciseView()}
        </div>
        <div className='hc-meeting__record--container'>
          {honestConversation ? this.honestConversationRows() : null}
        </div>
      </div>
    )
  }

  public render() {
    return <div>{this.combinedCards()}</div>
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  const goalActions = store.goalActions[householdFinId]
  return {
    contacts: store.contact[householdFinId],
    goals: goalActionsSelector(store, householdFinId),
    selectedActionCardId: (goalActions && goalActions.selectedCardId) || ''
  }
}

export default connect(mapStateToProps)(HonestConversationCombinedCards)
