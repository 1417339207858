import React, { Fragment } from 'react'
import { MoneyMindGraphic } from './graphic'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/protection.svg'
import { ReactComponent as CommitmentIcon } from '../../assets/images/icons/commitment.svg'
import { ReactComponent as HappinessIcon } from '../../assets/images/icons/happiness.svg'
import { ContactObj } from '../../../objects/contact'
import { MoneyMindObj } from '../../../objects/moneymind'

interface MoneyMindProps {
  contact: ContactObj
  moneymind: MoneyMindObj
}

export class MoneyMindResult extends React.Component<MoneyMindProps> {
  constructor(props: MoneyMindProps) {
    super(props)
  }

  public render() {
    return (
      <Fragment>
        <div className='moneymind__results-name'>
          {this.props.contact.firstName}'s MoneyMind
          <span className='reg-symbol'>&reg;</span>
        </div>
        <div className='moneymind__graphic-w'>
          <MoneyMindGraphic
            percent={this.props.moneymind.commitment}
            title='Commitment'
            color='#0068ae'
            icon={CommitmentIcon}
          />
          <MoneyMindGraphic
            percent={this.props.moneymind.protection}
            title='Protection'
            color='#a2d134'
            icon={ProtectionIcon}
          />
          <MoneyMindGraphic
            percent={this.props.moneymind.happiness}
            title='Happiness'
            color='#f49500'
            icon={HappinessIcon}
          />
        </div>
      </Fragment>
    )
  }
}
