import React from 'react'
import { FileObj, FolderInterface } from '../../../objects/folder'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import Tile from '../../components/layout/tile'
import TableHeader from '../../components/layout/tableHeader'
import File from './file'
import { ReactComponent as PreviousIcon } from '../../assets/images/icons/previous.svg'
import { history } from '../../../store'
import { withRouter } from 'react-router'
import * as actions from '../../../actions/documentVault'
import InnerContainer from '../../components/layout/innerContainer'
import NullDocVault from './nullView'
import {
  archivedFilesSelector,
  getAllFolders
} from '../../../selectors/v3/documentVault'
import trashIcon from '../../assets/images/icons/png/ic_delete.png'
import ContentHeader from '../../components/layout/contentHeader'
import RestoreFileModal from './restoreFileModal'
import HardDeleteModal from './hardDeleteModal'
import AlertMessageToast from '../../components/alertMessageToast'
import * as alertMessageActions from '../../../actions/showAlert'
import { mapFile } from '../../../reducers/documentVault'
import Button from '../../components/button'
import EmptyTrashModal from './emptyTrashModal'
export interface TrashedFilesProps {
  householdFinId: string
  files: FileObj[]
  folders: FolderInterface
  showAlert: boolean
  message: string
  isSuccess: boolean
  dispatch: Dispatch<GlobalState>
}

interface TrashedFilesState {
  showRestoreFileModal: boolean
  showEmptyTrashModal: boolean
  showHardDeleteFileModal: boolean
  currentFile: FileObj
}

export class TrashedFiles extends React.Component<
  TrashedFilesProps,
  TrashedFilesState
> {
  constructor(props: TrashedFilesProps) {
    super(props)
    this.state = {
      showEmptyTrashModal: false,
      showRestoreFileModal: false,
      showHardDeleteFileModal: false,
      currentFile: null
    }
  }

  componentDidMount() {
    const { dispatch, showAlert, householdFinId } = this.props
    dispatch(actions.getFolders(householdFinId))
    showAlert && dispatch(alertMessageActions.hideSyncModal())
  }

  public toggleEmptyTrashModal = () => {
    this.setState({
      showEmptyTrashModal: !this.state.showEmptyTrashModal
    })
  }
  public emptyTrashFn = async () => {
    const { dispatch, householdFinId } = this.props
    try {
      await dispatch(actions.emptyTrash(householdFinId))
      this.toggleEmptyTrashModal()
      dispatch(actions.getFolders(householdFinId))
      dispatch(alertMessageActions.showSyncModal('Trash emptied', true))
    } catch (_) {
      this.toggleEmptyTrashModal()
      dispatch(
        alertMessageActions.showSyncModal('Unable to empty trash.', false)
      )
    }
  }

  public toggleRestoreFileModal = (file?: FileObj) => {
    this.setState({
      showRestoreFileModal: !this.state.showRestoreFileModal,
      currentFile: file
    })
  }

  public restoreFn = async (id: string, archived: boolean) => {
    const { dispatch, householdFinId, folders } = this.props
    const { currentFile } = this.state
    try {
      // if the restoring file's folder does not exist we need to make a new folder for it and move the file to the new folder
      if (folders && folders[currentFile.folderId].archived) {
        // create new folder
        const newFolder = await actions.createFolder(
          currentFile.name,
          householdFinId
        ).payload
        const newFolderId = newFolder.data.id

        //move file to the new folder
        const movedFile = await actions.changeFileFolder(
          householdFinId,
          currentFile.folderId,
          id,
          newFolderId
        ).payload
        const movedFileFolderId = movedFile.data.folder

        //get folders so we have access to all files
        await dispatch(actions.getFolders(householdFinId))

        //restore file from archive
        await dispatch(
          actions.updateArchivedFile(
            householdFinId,
            movedFileFolderId,
            id,
            archived
          )
        )
        dispatch(actions.getFolder(householdFinId, movedFileFolderId))
        const mappedFile: FileObj = mapFile(movedFile.data)
        this.toggleRestoreFileModal(mappedFile)
        // dispatch an action to mark this folder as new in the UI
        dispatch(actions.addNewFolder(mappedFile.folderId))
      } else {
        // the trashed file's folder does exist, restore the file
        await dispatch(
          actions.updateArchivedFile(
            householdFinId,
            currentFile.folderId,
            id,
            archived
          )
        )
        this.toggleRestoreFileModal(currentFile)
        dispatch(actions.getFolder(householdFinId, currentFile.folderId))
      }
      dispatch(
        alertMessageActions.showSyncModal('File successfully restored.', true)
      )
    } catch (err) {
      this.toggleRestoreFileModal(null)
      dispatch(
        alertMessageActions.showSyncModal(
          'There was an error restoring the file.',
          false
        )
      )
    }
  }

  public toggleHardDeleteFileModal = (file?: FileObj) => {
    this.setState({
      showHardDeleteFileModal: !this.state.showHardDeleteFileModal,
      currentFile: file
    })
  }

  public hardDeleteFn = async (id: string) => {
    const { dispatch, householdFinId } = this.props
    const { currentFile } = this.state
    try {
      await dispatch(
        actions.hardDeleteFile(householdFinId, currentFile.folderId, id)
      )
      this.toggleHardDeleteFileModal(currentFile)
      dispatch(actions.getFolder(householdFinId, currentFile.folderId))
      dispatch(
        alertMessageActions.showSyncModal(
          'File successfully deleted permanently.',
          true
        )
      )
    } catch (_) {
      this.toggleHardDeleteFileModal(null)
      dispatch(
        alertMessageActions.showSyncModal(
          'Unable to delete file permanently.',
          false
        )
      )
    }
  }

  public renderFiles = () => {
    const { files, householdFinId } = this.props
    return files.map((file: FileObj, index) => {
      return (
        <File
          className='trashed__file'
          key={index}
          file={file}
          householdFinId={householdFinId}
          restoreModal={this.toggleRestoreFileModal}
          hardDeleteModal={this.toggleHardDeleteFileModal}
          inTrash={true}
        />
      )
    })
  }

  public addEmptyTrashButton = () => {
    const { files } = this.props
    return (
      <Button
        primary={true}
        disabled={files.length < 1}
        onClick={this.toggleEmptyTrashModal}>
        Empty Trash
      </Button>
    )
  }

  public renderNullFiles = () => {
    return (
      <div>
        <NullDocVault text='There are no deleted files in trash.' />
      </div>
    )
  }

  public render() {
    const { files, showAlert, isSuccess, message } = this.props
    const {
      showRestoreFileModal,
      showEmptyTrashModal,
      showHardDeleteFileModal,
      currentFile
    } = this.state
    return (
      <InnerContainer>
        <div
          className='folder-detail__btn--back'
          onClick={this.navigateToVault}>
          <PreviousIcon />
          <div>Back to Document Vault</div>
        </div>
        <div>
          <ContentHeader
            iconPng={trashIcon}
            title='Trash'
            rightHeader={this.addEmptyTrashButton()}
          />
          <Tile>
            <div>
              <TableHeader>
                <div className='folder-detail__name'>NAME</div>
                <div className='folder-detail__date-created'>DATE CREATED</div>
                <div className='folder-detail__actions' />
              </TableHeader>
              {this.renderFiles()}
              {files.length === 0 ? this.renderNullFiles() : null}
            </div>
          </Tile>
        </div>
        {showRestoreFileModal ? (
          <RestoreFileModal
            file={currentFile}
            restoreFn={this.restoreFn}
            closeModal={this.toggleRestoreFileModal}
          />
        ) : null}
        {showEmptyTrashModal ? (
          <EmptyTrashModal
            emptyFn={this.emptyTrashFn}
            closeModal={this.toggleEmptyTrashModal}
          />
        ) : null}
        {showHardDeleteFileModal ? (
          <HardDeleteModal
            file={currentFile}
            hardDeleteFn={this.hardDeleteFn}
            closeModal={this.toggleHardDeleteFileModal}
          />
        ) : null}
        {showAlert && (
          <AlertMessageToast
            icon={isSuccess ? 'success' : 'error'}
            title={isSuccess ? 'Success' : 'Failure'}
            message={message}
            bottomBtn={
              !message.includes('permanently') &&
              !message.includes('emptied') &&
              isSuccess
                ? 'View File'
                : ''
            }
            bottomBtnFunc={
              !message.includes('permanently') && !message.includes('emptied')
                ? this.navigateToFolder
                : null
            }
            stacked={false}
          />
        )}
      </InnerContainer>
    )
  }

  private navigateToVault = (): void => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/documentVault`)
  }

  private navigateToFolder = (): void => {
    const { householdFinId, dispatch } = this.props
    const { currentFile } = this.state
    dispatch(alertMessageActions.hideSyncModal())
    history.push(
      `/households/${householdFinId}/documentVault/${currentFile.folderId}`
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  return {
    files: archivedFilesSelector(store, householdFinId),
    folders: getAllFolders(store, householdFinId),
    householdFinId,
    showAlert: store.showAlert.show,
    message: store.showAlert.message,
    isSuccess: store.showAlert.isSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => {
  return {
    dispatch
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrashedFiles)
)
