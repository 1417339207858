import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import * as marketplaceActions from '../../../actions/marketplaceOffers'
import { marketplaceOfferObj } from '../../../objects/marketplaceOffers'
import ToggleSwitch from '../../components/toggleSwitch'
import { DisclosureModal } from './disclosureModal'
import { ConfirmationModal } from './confirmationModal'

export interface OfferProps {
  offer: marketplaceOfferObj
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  brandTitle: string
}

export interface OfferState {
  isDisclaimerModalOpen: boolean
  isConfirmationModalOpen: boolean
}

class Offer extends React.Component<OfferProps, OfferState> {
  constructor(props: OfferProps) {
    super(props)
    this.state = {
      isDisclaimerModalOpen: false,
      isConfirmationModalOpen: false
    }
  }

  public toogleDisclaimerModal = () => {
    this.setState({
      isDisclaimerModalOpen: !this.state.isDisclaimerModalOpen
    })
  }

  public openConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true
    })
  }

  public closeConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false
    })
  }

  public confirmEligibality = async () => {
    const { householdFinId, offer } = this.props
    const offerState = !offer.enabled
    this.props.dispatch(
      marketplaceActions.updateMarketplaceOffers(
        householdFinId,
        offer.id,
        offerState
      )
    )
    this.setState({
      isConfirmationModalOpen: false
    })
  }

  public goToOfferDetails = (e: any) => {
    const { offer } = this.props
    // this is needed to avoid event bubbling from the toggle button and disclosure modal opening text to the offer tile wrapper
    if (
      e.target.id === 'toggle' ||
      e.target.id === 'toggleInput' ||
      e.target.id === 'modal'
    ) {
      return
    }
    const url = `https://${offer.acOfferUrl}`
    offer?.acOfferUrl && window.open(url, '_blank')
  }

  public copyrightDisclosure = () => {
    const { offer } = this.props
    return offer?.copyrightDisclosure?.length > 0 ? (
      <div className='marketplace__offer-details marketplace__offer-details--gray'>
        To see disclosures,
        <span onClick={this.toogleDisclaimerModal} id='modal'>
          click here
        </span>
      </div>
    ) : null
  }

  public render() {
    const { offer, brandTitle } = this.props
    const { isDisclaimerModalOpen, isConfirmationModalOpen } = this.state
    return (
      <React.Fragment>
        <div className='marketplace__offer-w' onClick={this.goToOfferDetails}>
          <div className='marketplace__offer-left'>
            <img src={offer.marketplaceLogoUrl} alt='offer logo' />
            <div className='marketplace__offer-name-tag-w'>
              <div className='marketplace__offer-name'>{offer.name}</div>
              <div className='marketplace__offer-tag'>{offer.tag}</div>
            </div>
          </div>
          <div className='marketplace__divider' />
          <div className='marketplace__offer-right'>
            <div>
              <div className='marketplace__offer-details'>
                {offer.salesPitch}
              </div>
              <div className='marketplace__offer-details'>
                {offer.contactInfo}
              </div>
              <div className='marketplace__offer-details'>
                {offer.additionalInfo}
              </div>
              <div className='marketplace__offer-details marketplace__offer-details--gray'>
                {offer.footnote}
              </div>
            </div>
            {this.copyrightDisclosure()}
          </div>
          <div className='marketplace__offer-toggle'>
            <ToggleSwitch
              active={offer.enabled}
              handleToggleActive={
                !offer.enabled
                  ? this.openConfirmationModal
                  : this.confirmEligibality
              }
            />
          </div>
        </div>
        {isConfirmationModalOpen ? (
          <ConfirmationModal
            confirmationText={offer.eligibilityCriteria}
            confirm={this.confirmEligibality}
            closeModal={this.closeConfirmationModal}
            name={offer.name}
            title={brandTitle}
          />
        ) : null}
        {isDisclaimerModalOpen ? (
          <DisclosureModal
            disclosureText={offer.copyrightDisclosure}
            closeModal={this.toogleDisclaimerModal}
          />
        ) : null}
      </React.Fragment>
    )
  }
}

export default connect()(Offer)
