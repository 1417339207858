import React, { FunctionComponent, SVGProps } from 'react'

import Tile from '../../../components/layout/tile'
import Search from '../../../components/search'
import Button from '../../../components/button'
import SalesforceSyncRow from './salesforceSyncRow'

import { CRMHouseholdsObj } from '../../../../objects/user'

interface SyncListProps {
  title: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  iconPng?: string
  stagedHouseholds: string[]
  crmHouseholds: CRMHouseholdsObj[]
  v2ACL: boolean
  previewHouseholdSync?(): void
  toggleHousehold(id: string): void
}

interface SyncListState {
  searchKey: string
  offset: number
}

const NUMBER_OF_NEW_ROWS = 20

class SyncList extends React.Component<SyncListProps, SyncListState> {
  constructor(props: SyncListProps) {
    super(props)
    this.state = {
      searchKey: '',
      offset: 0
    }
  }
  public filterHouseholds = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchKey: e.currentTarget.value })
  }
  public householdsList = () => {
    const { searchKey, offset } = this.state
    const {
      crmHouseholds,
      v2ACL,
      toggleHousehold,
      stagedHouseholds
    } = this.props
    return crmHouseholds
      .filter((crmHousehold) => {
        return (
          crmHousehold &&
          crmHousehold.name &&
          crmHousehold.name.toLowerCase().includes(searchKey.toLowerCase())
        )
      })
      .map((crmHousehold, index) => {
        const checked = stagedHouseholds.includes(crmHousehold.id)
        if (index <= NUMBER_OF_NEW_ROWS + offset) {
          return (
            <SalesforceSyncRow
              key={crmHousehold.id}
              crmHousehold={crmHousehold}
              checked={checked}
              onCheckboxToggle={toggleHousehold}
              v2ACL={v2ACL}
            />
          )
        } else return null
      })
  }
  public handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { offset } = this.state
    // leave a 20 pixel buffer for a smoother experience
    const BUFFER = 20
    const bottom =
      e.currentTarget.scrollHeight -
        Math.ceil(e.currentTarget.scrollTop + BUFFER) <=
      e.currentTarget.clientHeight
    if (bottom) {
      this.setState({ offset: offset + NUMBER_OF_NEW_ROWS })
    }
  }

  public render() {
    const {
      icon,
      iconPng,
      previewHouseholdSync,
      stagedHouseholds,
      title
    } = this.props
    return (
      <div className='transfer-list__origin-list'>
        <Tile
          leftHeader={title}
          icon={icon}
          iconPng={iconPng}
          headerStyle={{ height: 50 }}>
          {/* Unsynced Search */}
          <div className='transfer-list__search-wrapper'>
            <Search onChange={this.filterHouseholds} />
          </div>

          {/* Unsynced List */}
          <div
            className='transfer-list__list-items-wrapper'
            onScroll={this.handleScroll}>
            {this.householdsList()}
          </div>

          {/* Unsynced List Footer */}
          <div className='transfer-list__footer'>
            <div className='transfer-list__footer-left'>
              <span className='transfer-list__items-selected--count'>
                {stagedHouseholds.length}
              </span>
              <span> Accounts Selected</span>
            </div>
            {previewHouseholdSync && (
              <div className='transfer-list__footer-right'>
                <Button header={true} onClick={previewHouseholdSync}>
                  <span className='transfer-list__sync-btn'>Refresh List</span>
                </Button>
              </div>
            )}
          </div>
        </Tile>
      </div>
    )
  }
}

export default SyncList
