import React, { FormEvent, ChangeEvent } from 'react'
import Tile from '../../components/layout/tile'
import Input from '../../components/Input'
import CustomSelect from '../../components/CustomSelect'

import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { ClientAccountStrategy } from '../../../objects/clientAccount'
import ArrowIcon from '../../assets/images/icons/png/arrow_right_blue.png'

interface EditInvestDetailProps {
  householdFinId: string
  clientAccountId: string
  productName: string
  name: string
  textCustodian: string
  source: string
  strategy: ClientAccountStrategy
  strategyId: string
  accountYield: number
  expenseRatio: number
  isDeletedConnected: boolean
  showTile: boolean
  dispatch: Dispatch<GlobalState>
  errors: string[]
  requiredFields: string[]
  inputSetter(fieldName: string, fieldValue: string | number | boolean): void
}
interface EditInvestDetailState {
  productName: string
  name: string
  textCustodian: string
  accountYield: number
  isDeletedConnected: boolean
  isInvestment: boolean
  showTile: boolean
  requiredFields: string[]
}

const inputFields = {
  productName: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid product name.'
  },
  textCustodian: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid custodian name.'
  },
  isDeletedConnected: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Select a status'
  },
  accountYield: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid yield.'
  }
}

class InvestmentDetail extends React.Component<
  EditInvestDetailProps,
  EditInvestDetailState
> {
  constructor(props: EditInvestDetailProps) {
    super(props)
    this.state = {
      name: '',
      productName: '',
      textCustodian: '',
      accountYield: null,
      isDeletedConnected: false,
      isInvestment: false,
      showTile: props.showTile,
      requiredFields: []
    }
  }

  public componentDidUpdate(nextProps: any, prevProps: any) {
    const prevPropsKeys = Object.keys(prevProps)
    const nextPropsKeys = Object.keys(nextProps)
    if (prevPropsKeys.length < nextPropsKeys.length) {
      this.updatedClientAccountProps(nextProps)
    }
  }

  public updatedClientAccountProps = (nextProps: any) => {
    this.setState({ ...nextProps })
  }

  public inputSetter = (isDollar?: boolean) => (
    e: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget
    const newValue: string | number = value
    this.props.inputSetter(name, newValue)
  }

  public hideTile = () => {
    this.setState({
      showTile: !this.state.showTile
    })
  }

  public showArrow = () => {
    return (
      <div
        onClick={this.hideTile}
        className='client-account-details-arrow-down'>
        <img
          src={ArrowIcon}
          alt=''
          role='presentation'
          style={{
            transform:
              this.state.showTile === true ? 'rotate(270deg)' : 'rotate(90deg)'
          }}
        />
      </div>
    )
  }

  public inputChanged = (fieldName: string): boolean => {
    const propFieldName = this.props[fieldName]
    const stateFieldName = this.state[fieldName]
    if (propFieldName === null || propFieldName === undefined) {
      return false
    }
    return propFieldName !== stateFieldName
  }

  public isSourceEditable = (inputFieldName: string) => {
    const nonManualEditableInputs: string[] = [
      'productName',
      'textCustodian',
      'isDeletedConnected',
      'accountYield'
    ]
    const { source } = this.props
    if (
      source &&
      source
        .replace(/-|\s/g, '')
        .toUpperCase()
        .includes('MANUAL')
    ) {
      return true
    }
    if (source === null) {
      return nonManualEditableInputs.includes(inputFieldName)
    }
    return false
  }

  public updateOpenClosedStatus = (select: any) => {
    const { value } = select
    this.props.inputSetter('isDeletedConnected', value === 'Closed')
  }

  public selectFields = (
    titleName: string,
    inputFieldName: string,
    contents: any,
    dataList: any,
    controlFunc: any
  ) => {
    if (this.isSourceEditable(inputFieldName)) {
      return (
        <CustomSelect
          classes='client-account-details__inputs client-account-details__inputs-custom-select'
          titleName={titleName}
          contents={contents}
          dataList={dataList}
          controlFunc={controlFunc}
          selectChanged={this.inputChanged(inputFieldName)}
          inputFieldName={inputFieldName}
        />
      )
    } else {
      return (
        <div className='form-group client-account-details__inputs-readonly'>
          <Input
            title={titleName}
            inputType='text'
            name={inputFieldName}
            controlFunc={null}
            content={contents}
            readOnly={true}
          />
        </div>
      )
    }
  }

  public inputFields = (
    titleName: string,
    inputFieldName: string,
    contents: any,
    isDollar?: boolean
  ) => {
    const { requiredFields } = this.state
    const isReadOnly: boolean = this.isSourceEditable(inputFieldName)
    const dollarCss = isDollar ? ' client-account-details__inputs-money ' : ''
    const mandatory: boolean = requiredFields.indexOf(inputFieldName) >= 0

    const errorState = () => {
      if (inputFieldName === 'totalValueManual') {
        return ''
      }
      return this.props.errors.indexOf(inputFieldName) >= 0
        ? inputFields[inputFieldName]
        : null
    }
    const errorMessage = () => {
      if (inputFieldName === 'totalValueManual') {
        return ''
      }
      return this.props.errors.indexOf(inputFieldName) >= 0
        ? 'Enter a ' + titleName
        : ''
    }

    const inputType = inputFieldName === 'accountYield' ? 'number' : 'text'
    const isPercentage = inputFieldName === 'accountYield'
    return (
      <div
        className={
          isReadOnly === true
            ? 'form-group client-account-details__form-group ' + dollarCss
            : 'form-group client-account-details__form-group client-account-details__inputs-readonly ' +
              dollarCss
        }>
        <Input
          mandatory={mandatory}
          classNames={errorState() !== null ? errorState().errorStyle : ''}
          error={errorMessage()}
          isChanged={this.inputChanged(inputFieldName)}
          title={titleName}
          inputType={inputType}
          name={inputFieldName}
          percentageSign={isPercentage}
          controlFunc={this.inputSetter(isDollar)}
          content={contents}
          readOnly={!isReadOnly}
        />
      </div>
    )
  }

  public render() {
    const { productName, strategy, accountYield, textCustodian } = this.props

    const strategyName = strategy && strategy.name
    const expenseRatio = strategy && strategy.expenseRatio

    return (
      <div className='client-account-details-w'>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          leftHeader={'INVESTMENT DETAIL'}
          headerBorder={true}
          rightHeader={this.showArrow()}>
          <div
            className='client-account-details'
            style={{ display: this.state.showTile === true ? '' : 'none' }}>
            <div className={'client-account-details__investment-inputs'}>
              {this.inputFields('Product Name', 'productName', productName)}
              {this.inputFields('Strategy', 'name', strategyName)}
              {this.inputFields('Custodian', 'textCustodian', textCustodian)}
            </div>

            <div className={'client-account-details__investment-inputs'}>
              {this.inputFields('Yield', 'accountYield', accountYield)}
              {this.inputFields('Expense Ratio', 'expenseRatio', expenseRatio)}
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, clientAccountId }: any
) => {
  return {
    householdFinId,
    clientAccountId
  }
}

export default connect(mapStateToProps)(InvestmentDetail)
