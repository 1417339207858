import React, { Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'

import { GlobalState } from '../../../../reducers'
import {
  GoalRequestObjState,
  GoalRequestObj
} from '../../../../objects/goalRequests'
import {
  FinancialGoalsInterface,
  PlanningSoftwareType,
  goalTypesList,
  FinancialGoalObj
} from '../../../../objects/financialGoal'
import { HouseholdPreferencesObj } from '../../../../objects/householdPreferences'

import Tile from '../../../components/layout/tile'
import TableHeader from '../../../components/layout/tableHeader'
import RowNullState from './rowNullState'
import AddGoal from './addGoal'
import GoalTile from './goalTile'
import { FinancialGoalsDisclosures } from '../financialGoalsDisclosures'
import { generateNewPreferences } from '../../../helpers/householdPreferences'

interface GoalTilesProps {
  householdFinId: string
  userId: string
  goalId: string
  goals: FinancialGoalsInterface
  requests: GoalRequestObjState
  dispatch: Dispatch<GlobalState>
  planningSoftware: PlanningSoftwareType
  preferences: HouseholdPreferencesObj
}

class GoalTiles extends Component<GoalTilesProps> {
  public renderTileComponent = (type: string, i: number) => {
    const {
      userId,
      householdFinId,
      planningSoftware,
      dispatch,
      goalId,
      goals,
      requests,
      preferences
    } = this.props
    const currentPreferences = preferences || generateNewPreferences()
    const newRequests = Object.values(requests).filter(
      (req: GoalRequestObj) => req.proposedGoalType === type && !req.existingId
    )
    const existingRequests = Object.values(requests).filter(
      (req: GoalRequestObj) => req.proposedGoalType === type && req.existingId
    )
    const goalsCombinedWithRequests = Object.values(goals)
      .filter((goal: FinancialGoalObj) => goal.type === type)
      .map((goal) => {
        const goalCopy = { ...goal }
        goalCopy.goalRequest =
          existingRequests && existingRequests.length
            ? existingRequests.find((item) => item.existingId === goal.id)
            : null
        return goalCopy
      })
    const requestCollection = [...existingRequests, ...newRequests]
    const goalCollection = [...goalsCombinedWithRequests, ...newRequests]
    return (
      <div key={i} className='goal-tiles__w'>
        <GoalTile
          title={type}
          userId={userId}
          preferences={currentPreferences}
          originalPreferences={preferences}
          householdFinId={householdFinId}
          goals={goalCollection}
          requests={requestCollection}
          dispatch={dispatch}
          planningSoftware={planningSoftware}
          openChangeModalId={goalId}
        />
      </div>
    )
  }

  public render() {
    const {
      goals,
      householdFinId,
      dispatch,
      planningSoftware,
      requests
    } = this.props
    const allGoals = goals ? Object.values(goals) : []
    const allReqs = requests ? Object.values(requests) : []
    const goalCollection = [...allGoals, ...allReqs]
    if (goalCollection && goalCollection.length > 0 && householdFinId) {
      const renderedGoals = Object.keys(goalTypesList).map((type, i) => {
        return goalCollection.some(
          (goal: any) => goal.type === type || goal.proposedGoalType === type
        )
          ? this.renderTileComponent(type, i)
          : null
      })
      return (
        <Fragment>
          <div className='goal-tiles__header'>
            <div className='goal-tiles__header-text'>Plan Inputs</div>
            {!planningSoftware ? (
              <AddGoal householdFinId={householdFinId} dispatch={dispatch} />
            ) : null}
          </div>
          {renderedGoals}
          <FinancialGoalsDisclosures />
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <div className='goal-tiles__header'>
            <div className='goal-tiles__header-text'>Plan Inputs</div>
            {!planningSoftware ? (
              <AddGoal householdFinId={householdFinId} dispatch={dispatch} />
            ) : null}
          </div>
          <Tile headerStyle={{ backgroundColor: '#FAFAFA' }}>
            <TableHeader>
              <div className='financial-goals__header--name'>NAME</div>
              <div className='financial-goals__header--description' />
              <div className='financial-goals__header--period'>PERIOD</div>
              <div className='financial-goals__header--amount'>AMOUNT</div>
            </TableHeader>
            <RowNullState />
          </Tile>
        </Fragment>
      )
    }
  }
}

export default GoalTiles
