import React from 'react'
import { ReactComponent as BackIcon } from '../assets/images/icons/ic_left.svg'

export interface BackButtonProps {
  onClick(): void
}

export class BackButton extends React.Component<BackButtonProps> {
  public render() {
    const { onClick } = this.props
    return (
      <div onClick={onClick} className='back-btn' role='button'>
        <BackIcon className='back-btn__icon' />
        <span className='back-btn__text'>Back</span>
      </div>
    )
  }
}

export default BackButton
