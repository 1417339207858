import React from 'react'
import { FundedScoreObj } from '../../../objects/financialGoal'
import { dateFormat } from '../../helpers'

interface FundedScoreHistoryCommentsProps {
  fundedScores: FundedScoreObj[]
}

class FundedScoreHistoryComments extends React.Component<
  FundedScoreHistoryCommentsProps
> {
  public displayComments = () => {
    const { fundedScores } = this.props
    const fundedScoresToDisplay =
      fundedScores && fundedScores.length > 0
        ? fundedScores
            .filter(
              (score) => score.inGuidebook || score.id === fundedScores[0].id
            )
            .sort((a, b) => b.lastUpdated.localeCompare(a.lastUpdated))
            .slice(0, 4)
        : []
    return fundedScoresToDisplay.map((fundedScore, index) => {
      return fundedScores && fundedScore.notes ? (
        <div className='funded-score-history-comment' key={fundedScore.id}>
          <div className='funded-score-history-comment__header'>
            <div className='funded-score-history-comment__header-score'>
              Score: {fundedScore.score}
            </div>
            <div className='funded-score-history-comment__header-date'>
              {dateFormat(fundedScore.lastUpdated)}
            </div>
          </div>
          <p className='funded-score-history-comment__notes'>
            {fundedScore.notes}
          </p>
        </div>
      ) : null
    })
  }
  public render() {
    return (
      <div className='funded-score-history-comment__column-w'>
        <div className='funded-score-history-comment__column'>
          {this.displayComments()}
        </div>
      </div>
    )
  }
}

export default FundedScoreHistoryComments
