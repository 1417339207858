import React from 'react'

const HorizontalBar = ({
  barColor,
  fillPercent
}: {
  barColor: string
  fillPercent: number
}) => {
  return (
    <div className='horizontal-bar-w'>
      <div
        className='horizontal-bar__bar'
        style={{ backgroundColor: barColor, width: `${fillPercent}%` }}
      />
    </div>
  )
}

export default HorizontalBar
