import React, { Component, Fragment } from 'react'
import { GlobalState } from '../../../reducers'
import { Dispatch } from 'react-redux'

import { deleteGuidebook } from '../../../actions/guidebook'
import { GuidebookObj } from '../../../objects/guidebook'
import { ContactsInterface } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'

import Mimeo from '../../components/mimeo'
import Modal from '../../components/layout/modal'
import ActionDropdown from '../../components/actionDropdown'
import { dateFormat, timeFormat } from '../../helpers'

import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import GuidebookIcon from '../../assets/images/icons/ic_guidebookgray.png'
import deletePngIcon from '../../assets/images/icons/png/ic_delete.png'
import printIcon from '../../assets/images/icons/png/ic_print.png'
import downloadIcon from '../../assets/images/icons/png/ic_download.png'
import { downloadAndUploadFile } from '../../helpers/guidebook'
import { ReactComponent as VaultIcon } from '../../assets/images/icons/add-to-vault.svg'
export interface GuidebookItemProps {
  user: UserObj
  guidebook: GuidebookObj
  contacts: ContactsInterface
  householdFinId: string
  vaultFolderId: string
  dispatch: Dispatch<GlobalState>
}

interface GuidebookItemState {
  showConfirmDeleteGuidebookModal: boolean
  showMimeoModal: boolean
}

class GuidebookItem extends Component<GuidebookItemProps, GuidebookItemState> {
  constructor(props: GuidebookItemProps) {
    super(props)
    this.state = {
      showConfirmDeleteGuidebookModal: false,
      showMimeoModal: false
    }
  }

  downloadGuidebook = () => {
    window.open(this.props.guidebook.pdfUrl)
  }

  public toggleConfirmDeleteModal = () => {
    this.setState({
      showConfirmDeleteGuidebookModal: !this.state
        .showConfirmDeleteGuidebookModal
    })
  }

  public toggleMimeoModal = () => {
    this.setState({
      showMimeoModal: !this.state.showMimeoModal
    })
  }

  deleteGuidebook = () => {
    this.props.dispatch(
      deleteGuidebook(this.props.householdFinId, this.props.guidebook.id)
    )
    this.toggleConfirmDeleteModal()
  }

  public addToVaultGuideBook = () => {
    const { guidebook: { pdfUrl, createdDate: timeStamp }, householdFinId, dispatch, vaultFolderId } = this.props
    downloadAndUploadFile({ fileName: 'GuideBook', householdFinId, fileUrl: pdfUrl, dispatch, vaultFolderId, timeStamp })
  }

  public download() {
    return (
      <Fragment>
        <img src={downloadIcon} alt='download' />
        <span className='action-dropdown__item-label'>Download</span>
      </Fragment>
    )
  }

  public addToVault() {
    return (
      <Fragment>
        <VaultIcon />
        <span className='action-dropdown__item-label'>Add to Vault</span>
      </Fragment>
    )
  }

  public delete() {
    return (
      <Fragment>
        <img src={deletePngIcon} alt='delete' />
        <span className='action-dropdown__item-label'>Delete</span>
      </Fragment>
    )
  }

  public mimeo() {
    return (
      <Fragment>
        <img src={printIcon} alt='print' />
        <span className='action-dropdown__item-label'>Print with Mimeo</span>
      </Fragment>
    )
  }

  public confirmDeleteGuidebookModal() {
    return (
      <Modal
        icon={DeleteIcon}
        title='Delete Guidebook'
        closeModal={this.toggleConfirmDeleteModal}>
        <div className='gb-confirm-modal'>
          <div className='gb-confirm-modal__content'>
            <p>Are you sure you want to delete this Guidebook?</p>
          </div>
          <div className='gb-confirm-modal__buttons'>
            <span
              className='btn btn__clear'
              onClick={this.toggleConfirmDeleteModal}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={this.deleteGuidebook}>
              Delete
            </span>
          </div>
        </div>
      </Modal>
    )
  }

  public render() {

    const { name, createdDate } = this.props.guidebook
    const { contacts, user, guidebook, householdFinId } = this.props
    const dropdownActions = [
      {
        element: this.download(),
        onClick: this.downloadGuidebook
      },
      {
        element: this.mimeo(),
        onClick: this.toggleMimeoModal
      },
      {
        element: this.addToVault(),
        onClick: this.addToVaultGuideBook
      },
      {
        element: this.delete(),
        onClick: this.toggleConfirmDeleteModal
      }
    ]
    return (
      <div className='gb-item'>
        <img className='gb-item__icon' src={GuidebookIcon} alt='icon' />
        <div className='gb-item__name'>{name}</div>
        <div className='gb-item__date'>
          {dateFormat(createdDate) + ' ' + timeFormat(createdDate)}
        </div>
        <div className='gb-item__actions'>
          <ActionDropdown actions={dropdownActions} listClass='action-dropdown__item--hover-disabled' />
        </div>
        {this.state.showConfirmDeleteGuidebookModal
          ? this.confirmDeleteGuidebookModal()
          : null}
        {this.state.showMimeoModal ? (
          <Mimeo
            user={user}
            householdFinId={householdFinId}
            contacts={contacts}
            toggleModal={this.toggleMimeoModal}
            guidebook={guidebook}
          />
        ) : null}
      </div>
    )
  }
}
export default GuidebookItem
