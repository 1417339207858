export const UPDATE_GLOBALNAVIGATION_ACTIONS = 'UPDATE_GLOBALNAVIGATION_ACTIONS'
export const TOGGLE_SIDEBAR_STATE = 'TOGGLE_SIDEBAR_STATE'

/**
 * SET SELECTED GOALID
 * @param action : Toggle state of the navigation
 */
export const updateGlobalNavigation = (action: boolean) => {
  return {
    type: UPDATE_GLOBALNAVIGATION_ACTIONS,
    payload: {
      data: {
        toggleState: action
      }
    }
  }
}

/**
 * SET SIDEBAR STATE
 * @param householdFinId : The householdId to get/set the data/settings/config specific to household
 */
export const updateSideBarState = (data: {
  sideBarState: boolean
  householdId: string
}) => {
  return {
    type: TOGGLE_SIDEBAR_STATE,
    payload: { data }
  }
}
