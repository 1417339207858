import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'

import { GlobalState } from '../../../../reducers'
import {
  updatePortfolioBuilder,
  getPortfolioBuilderAccounts
} from '../../../../actions/portfolioBuilder'
import { addToast } from '../../../../actions/toasts'
import Button from '../../../components/button'
import Modal from '../../../components/layout/modal'
import Input from '../../../components/Input'

import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'

interface OverrideAllocationModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  portfolioBuilderId: string
  currentAllocationData: any
  closeModal(): void
  handleChange(inputVals: any): void
}

interface OverrideAllocationModalState {
  percentTotal: number
  overrideEquityPercent: number | null
  overrideFixedPercent: number | null
  overrideCashPercent: number | null
  overrideOtherPercent: number | null
  overrideTotalPercent: number | null
}

class OverrideAllocationModal extends Component<
  OverrideAllocationModalProps,
  OverrideAllocationModalState
> {
  constructor(props: OverrideAllocationModalProps) {
    super(props)
    this.state = {
      percentTotal: 0,
      overrideEquityPercent: null,
      overrideFixedPercent: null,
      overrideCashPercent: null,
      overrideOtherPercent: null,
      overrideTotalPercent: null
    }
  }

  public componentDidMount() {
    this.setStateValues()
  }

  public verifyPercent = (details: object) => {
    let sum = 0
    Object.keys(details).forEach((e) => {
      const val = Number(details[e])
      sum += val
    })
    return sum
  }

  public inputValidation = (details: object) => {
    const percent = this.verifyPercent(details)
    return !(percent === 0 || percent === 100)
  }
  public sanitize = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = e.currentTarget
    const value = target.value
    const name = target.name
    let parsedValue = parseInt(value, 0)
    if (
      parsedValue < 0 ||
      parsedValue > 100 ||
      parsedValue.toString() === 'NaN'
    ) {
      parsedValue = 0
    }
    this.setState({
      ...this.state,
      [name]: parsedValue
    })
  }
  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value
    const name: string = event.currentTarget.name
    if (!value) {
      value = null
    }
    this.setState({
      ...this.state,
      [name]: value || null
    })
  }

  public setStateValues = () => {
    const {
      overrideEquityPercent: overrideEquity,
      overrideFixedPercent: overrideFixed,
      overrideCashPercent: overrideCash,
      overrideOtherPercent: overrideOther,
      overrideTotalPercent: overrideTotal,
      equity,
      fixed,
      cash,
      otherValue,
      Unclassified
    } = this.props.currentAllocationData
    if (
      overrideEquity ||
      overrideFixed ||
      overrideCash ||
      overrideOther ||
      overrideTotal
    ) {
      this.setState({
        overrideEquityPercent: overrideEquity || null,
        overrideFixedPercent: overrideFixed || null,
        overrideCashPercent: overrideCash || null,
        overrideOtherPercent: overrideOther || null,
        overrideTotalPercent: overrideTotal || null
      })
    } else {
      this.setState({
        overrideEquityPercent: equity || null,
        overrideFixedPercent: fixed || null,
        overrideCashPercent: cash || null,
        overrideOtherPercent: otherValue || null,
        overrideTotalPercent: Unclassified || null
      })
    }
  }

  public cancelOverride = () => {
    this.setStateValues()
    this.props.closeModal()
  }

  public saveOverride = (details: object) => async () => {
    Object.keys(details).forEach((e) => (details[e] = Number(details[e])))
    const {
      dispatch,
      householdFinId,
      portfolioBuilderId,
      closeModal
    } = this.props
    if (this.verifyPercent(details) === 100) {
      await dispatch(
        updatePortfolioBuilder(householdFinId, portfolioBuilderId, details)
      )
      dispatch(getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId))
      this.props.handleChange(this.state)
      this.props.dispatch(
        addToast({
          message: 'Successfully updated allocation override',
          backgroundColor: '#D9F2B6'
        })
      )
      closeModal()
    }
  }

  public renderInputFields = (title: string, state: any, details: object) => {
    return (
      <Input
        mandatory={true}
        percentageSign={true}
        title={
          title === 'Total' ? 'Unclassified' : title === 'Other' ? 'Alt' : title
        }
        name={`override${title}Percent`}
        inputType='number'
        controlFunc={this.handleChange}
        onBlur={this.sanitize}
        classNames={this.inputValidation(details) && 'form-input-Error'}
        content={state}
        min={0}
        max={100}
        placeholder='0'
      />
    )
  }

  public render() {
    const { closeModal } = this.props
    const {
      overrideEquityPercent,
      overrideFixedPercent,
      overrideCashPercent,
      overrideOtherPercent,
      overrideTotalPercent
    } = this.state
    const details = {
      overrideEquityPercent,
      overrideFixedPercent,
      overrideCashPercent,
      overrideOtherPercent,
      overrideTotalPercent
    }
    const percent = this.verifyPercent(details)
    return (
      <Modal
        size='L'
        icon={EditIcon}
        title='OVERRIDE ALLOCATION'
        closeModal={closeModal}>
        <div className='modal-body'>
          <div className='form-inline'>
            {this.renderInputFields('Equity', overrideEquityPercent, details)}
            {this.renderInputFields('Fixed', overrideFixedPercent, details)}
            {this.renderInputFields('Cash', overrideCashPercent, details)}
            {this.renderInputFields('Other', overrideOtherPercent, details)}
            {this.renderInputFields('Total', overrideTotalPercent, details)}
          </div>
          {this.inputValidation(details) && (
            <span className='form-input-Error__message'>
              * Total Allocation must equal 100%.
              {` Currently ${this.verifyPercent(details)}%.`}
            </span>
          )}
          <div className='client-allocation__buttons-w'>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              primary={percent === 100}
              onClick={this.saveOverride(details)}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect()(OverrideAllocationModal)
