import React, { ChangeEvent } from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import Button from '../../components/button'
import GQLClient from '../../helpers/gqlClient'
import {
  CreateMessageThreadQuery,
  GetMessageThreadQuery,
  GetAdvisorMessageThreadQuery
} from '../../helpers/queries'
import { HouseholdObj } from '../../../objects/household'

export interface NewMessageModalProps {
  subjectProp?: string
  messageProp?: string
  householdFinId: string
  userId: string
  household: HouseholdObj
  closeModal(): void
  updateMessageThread?(threadId: string): void
}

interface NewMessageModalState {
  subject: string
  message: string
}

export class NewMessageModal extends React.Component<
  NewMessageModalProps,
  NewMessageModalState
> {
  constructor(props: NewMessageModalProps) {
    super(props)
    const { subjectProp, messageProp } = this.props
    this.state = {
      subject: subjectProp || '',
      message: messageProp || ''
    }
  }
  public createThread = () => {
    const { householdFinId, userId, closeModal } = this.props
    const { message, subject } = this.state
    GQLClient.getClient()
      ?.mutate({
        mutation: CreateMessageThreadQuery,
        variables: { message, householdFinId, subject, userId },
        refetchQueries: window._env_.REACT_APP_ADVISOR_MESSAGES_ENABLED
          ? [
              {
                query: GetMessageThreadQuery,
                variables: { householdFinId }
              },
              {
                query: GetAdvisorMessageThreadQuery,
                variables: { userId }
              }
            ]
          : [
              {
                query: GetMessageThreadQuery,
                variables: { householdFinId }
              }
            ]
      })
      .then((res: any) => {
        const { updateMessageThread } = this.props
        updateMessageThread &&
          updateMessageThread(res.data.createMessage.message.id)
        closeModal()
      })
  }
  public setSubject = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ subject: e.currentTarget.value })
  }
  public setMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: e.currentTarget.value })
  }
  public render() {
    const { closeModal, household } = this.props
    const { subject, message } = this.state
    return (
      <Modal
        title='NEW MESSAGE'
        icon={PlusIcon}
        closeModal={closeModal}
        size='L'>
        <div className='new-message-modal-w'>
          <div className='new-message-modal__row'>
            <div className='new-message-modal__subject-w'>
              <div className='new-message-modal__label'>Subject</div>
              <span className='input-field'>
                <input
                  placeholder='Enter the subject of the message'
                  value={subject}
                  onChange={this.setSubject}
                />
              </span>
            </div>
            <div className='new-message-modal__recipient-w'>
              <div className='new-message-modal__label'>Recipient</div>
              <span className='input-field'>
                <input
                  className='input-field'
                  value={household ? household.name : ''}
                  disabled={true}
                />
              </span>
            </div>
          </div>
          <div className='new-message-modal__message-w'>
            <div className='new-message-modal__label'>Message</div>
            <textarea
              className='new-message-modal__message-text-area'
              placeholder='Add a message to start the thread'
              value={message}
              onChange={this.setMessage}></textarea>
          </div>
          <div className='new-message-modal__buttons'>
            <div>
              <Button onClick={closeModal} clear={true}>
                Cancel
              </Button>
              <Button primary={true} onClick={this.createThread}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default NewMessageModal
