import * as React from 'react'
import {
  FinancialGoalObj,
  goalTypesList
} from '../../../../objects/financialGoal'
import { GoalRequestObj } from '../../../../objects/goalRequests'
import { sanitizeCurrency, formatGoalAmount } from '../../../helpers'

const isEditType = (goal: FinancialGoalObj): boolean => {
  return goal.goalRequest && goal.goalRequest.requestType !== 'DELETE'
}

const deleteStyle = (
  goal: FinancialGoalObj,
  className: string,
  goalRequest?: GoalRequestObj
): string => {
  let style = className
  if (isEditType(goal)) {
    style +=
      className === 'goal-row__tag'
        ? ' goal-row__pending-change-type'
        : ' goal-row__pending-change'
  }
  if (
    !isEditType(goal) &&
    goalRequest &&
    goalRequest.requestType === 'DELETE'
  ) {
    style +=
      className === 'goal-row__tag'
        ? ' goal-row__pending-change-type'
        : ' goal-row__pending-change'
  }

  return style
}

const goalTypeStyle = (className: string, requestType: string): string => {
  let style = className
  if (requestType === 'DELETE') {
    style +=
      className === 'goal-row__tag'
        ? ' goal-row__pending-change-type'
        : ' goal-row__pending-change'
  }
  return style
}

const getGoalType = (
  goal: FinancialGoalObj,
  mappedGoal: FinancialGoalObj
): string => {
  const previousType =
    goal.type && goal.type !== undefined ? goalTypesList[goal.type].label : null
  return goal &&
    isEditType(goal) &&
    previousType !== goalTypesList[mappedGoal.type].label
    ? previousType
    : null
}

export const PreviousGoalName: React.StatelessComponent<{
  goal: FinancialGoalObj
  mappedGoal: FinancialGoalObj
  className: string
}> = ({ goal, mappedGoal, className }) =>
  isEditType(goal) && goal.name !== mappedGoal.name ? (
    <div className={className}>{goal.name}</div>
  ) : null

export const DisplayGoalName: React.StatelessComponent<{
  goal: FinancialGoalObj
  goalRequest: GoalRequestObj
  className: string
}> = ({ goal, className, goalRequest }) => (
  <div className={deleteStyle(goal, className, goalRequest)}>{goal.name}</div>
)

export const DisplayGoalType: React.StatelessComponent<{
  goalType: string
  className: string
  requestType: string
}> = ({ goalType, className, requestType }) =>
  goalType ? (
    <div className={goalTypeStyle(className, requestType)}>{goalType}</div>
  ) : null

export const PreviousGoalType: React.StatelessComponent<{
  goal: FinancialGoalObj
  mappedGoal: FinancialGoalObj
  className: string
}> = ({ goal, mappedGoal, className }) =>
  getGoalType(goal, mappedGoal) ? (
    <div>
      <span className='goal-row__tag goal-row__pending-change-type'>
        {getGoalType(goal, mappedGoal)}
      </span>
    </div>
  ) : null

export const Description: React.StatelessComponent<{
  goal: FinancialGoalObj
}> = ({ goal }) => (
  <div
    className={
      goal && goal.comment
        ? 'goal-row__table-description goal-row__table-description-content'
        : 'goal-row__table-description'
    }>
    {goal.comment}
  </div>
)

export const PreviousGoalDate: React.StatelessComponent<{
  goal: FinancialGoalObj
  mappedGoal: FinancialGoalObj
}> = ({ goal, mappedGoal }) => {
  const isDifferentDate =
    mappedGoal.endDate !== goal.endDate ||
    mappedGoal.startDate !== goal.startDate
  return goal && isEditType(goal) ? (
    <div className='goal-row__tag goal-row__pending-change'>
      {isDifferentDate ? goal.startDate : null}
      {goal.startDate && goal.endDate && isDifferentDate ? '-' : null}
      {isDifferentDate ? goal.endDate : null}
    </div>
  ) : null
}

export const DisplayDate: React.StatelessComponent<{
  goal: FinancialGoalObj
  goalRequest: GoalRequestObj
}> = ({ goal, goalRequest }) => (
  <div
    className={deleteStyle(goal, 'goal-row__table-period--date', goalRequest)}>
    {goalRequest ? goalRequest.proposedStartOfGoal : goal.startDate}
    {!goalRequest && goal.startDate && goal.endDate ? ' - ' : null}
    {goalRequest &&
    goalRequest.proposedStartOfGoal &&
    goalRequest.proposedEndOfGoal
      ? ' - '
      : null}
    {goalRequest ? goalRequest.proposedEndOfGoal : goal.endDate}
  </div>
)

export const PreviousAmount: React.StatelessComponent<{
  goal: FinancialGoalObj
  mappedGoal: FinancialGoalObj
}> = ({ goal, mappedGoal }) =>
  isEditType(goal) &&
  sanitizeCurrency(goal.amount) !== sanitizeCurrency(mappedGoal.amount) ? (
    <div className='goal-row__tag goal-row__pending-change'>{goal.amount}</div>
  ) : null

export const DisplayAmount: React.StatelessComponent<{
  goal: FinancialGoalObj
  goalRequest: GoalRequestObj
}> = ({ goal, goalRequest }) => (
  <div className={deleteStyle(goal, 'goal-row__table-amount', goalRequest)}>
    {isNaN(parseInt(goal.amount, 0))
      ? goal.amount
      : formatGoalAmount(parseInt(goal.amount, 0), goal.amountType)}
  </div>
)
