import React, { Component, CSSProperties } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'
import { history } from '../../../../store'
import * as hcExercisesActions from '../../../../actions/hcExercises'
import { GlobalState } from '../../../../reducers'
import { ContactsInterface } from '../../../../objects/contact'
import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'

import InnerContainer from '../../../components/layout/innerContainer'
import BackToLink from '../../../components/layout/headerBackToLink'
import ContentHeader from '../../../components/layout/contentHeader'
import HonestConversationNewScoreButton from './hcNewScoreButton'
import ClientScoreList from './scoreHistory/clientScoreList'
import ClientScoreHistoryGraph from './scoreHistory/clientScoreHistoryGraph'
import { ScoreGraphLegend } from '../../../components/graphs/honestConversations/scoreGraphLegend'

interface HonestConversationsScoreHistoryProps {
  dispatch: Dispatch<GlobalState>
  match: any
  householdFinId: string
  exerciseId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  history: { goBack(): void }
}

const tileHeaderStyle: CSSProperties = {
  background: 'none',
  backgroundColor: '#FAFAFA',
  borderBottom: 'none'
}

class HonestConversationsScoreHistory extends Component<
  HonestConversationsScoreHistoryProps
> {
  public componentDidMount = () => {
    const { householdFinId, dispatch } = this.props
    if (!this.props.honestConversation) {
      dispatch(
        hcExercisesActions.getHonestConversationExercises(householdFinId)
      )
    }
  }

  public onClickScoreRow = (
    contactId: string,
    scoringSessionId: string,
    isPrimary: boolean
  ) => {
    const { householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/scorePreview/${scoringSessionId}`,
      { isPrimary }
    )
  }

  public tileHeader = () => {
    const { contacts } = this.props
    if (contacts && !contacts.secondary) {
      return (
        <div className='client-score-history__header'>
          <div>Satisfaction Score &trade;</div>
        </div>
      )
    }

    return (
      <div className='client-score-history__header'>
        <div>Satisfaction Score &trade;</div>
        <div className='client-score-history__chart-name-label'>
          {contacts?.secondary?.firstName ? (
            <ScoreGraphLegend
              firstName={contacts?.secondary?.firstName}
              secondaryStyle={true}
            />
          ) : null}
          <ScoreGraphLegend
            firstName={contacts?.primary?.firstName}
            secondaryStyle={false}
          />
        </div>
      </div>
    )
  }

  public render() {
    const {
      householdFinId,
      contacts,
      exerciseId,
      honestConversation
    } = this.props

    if (!honestConversation) return null

    return (
      <InnerContainer>
        <BackToLink onClick={history.goBack}>Back</BackToLink>
        <ContentHeader
          title='Score History'
          rightHeader={
            <HonestConversationNewScoreButton
              householdFinId={householdFinId}
              exerciseId={exerciseId}
            />
          }
        />
        <Tile header={this.tileHeader()} headerStyle={tileHeaderStyle}>
          <div className='client-score-history__graph'>
            <ClientScoreHistoryGraph
              householdFinId={householdFinId}
              exerciseId={exerciseId}
              contacts={contacts}
            />
          </div>
        </Tile>

        <div className='client-score-history__container'>
          {contacts?.secondary ? (
            <ClientScoreList
              householdFinId={householdFinId}
              exerciseId={exerciseId}
              activeScoreId={honestConversation.activeScoringSessionSecondary}
              contactId={contacts?.secondary?.id}
              onClickScoreRow={this.onClickScoreRow}
            />
          ) : null}
          <ClientScoreList
            householdFinId={householdFinId}
            exerciseId={exerciseId}
            activeScoreId={honestConversation.activeScoringSessionPrimary}
            contactId={contacts?.primary?.id}
            onClickScoreRow={this.onClickScoreRow}
          />
        </div>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    honestConversation:
      store.hcExercises[match.params.householdFinId] &&
      store.hcExercises[match.params.householdFinId][match.params.exerciseId],
    householdFinId: match.params.householdFinId,
    exerciseId: match.params.exerciseId,
    household: store.households[match.params.householdFinId],
    contacts: store.contact[match.params.householdFinId]
  }
}

export default withRouter(
  connect(mapStateToProps)(HonestConversationsScoreHistory)
)
