import React, { Component } from 'react'
import TableRow from '../../components/layout/tableRow'
import FrownIcon from '../../assets/images/icons/png/il-folder.png'

interface NullDocVaultProps {
  text: string
  section?: string
}

export class NullDocVault extends Component<NullDocVaultProps> {
  public renderNullRow = () => {
    return (
      <TableRow>
        <div className='folder__column--name '>
          <div>IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII</div>
        </div>
        <div className='folder__column--last-updated'>IIIIIIIIIIIIIII</div>
        <div className='folder__column-actions folder__column-actions--null'>
          IIIIIIII
        </div>
      </TableRow>
    )
  }
  public render() {
    const { section, text } = this.props
    const title = section === 'folder' ? 'No Folders Here' : 'No Files Here'
    return (
      <div>
        <div className='filtered__bottom'>
          {this.renderNullRow()}
          {this.renderNullRow()}
          {this.renderNullRow()}
          {this.renderNullRow()}
          {this.renderNullRow()}
        </div>
        <div className='folder__null--stateBackground'>
          <img src={FrownIcon} role='presentation' alt='' />
          <div className='folder__null--title'>{title}</div>
          <div className='folder__null--description'>{text}</div>
        </div>
      </div>
    )
  }
}

export default NullDocVault
