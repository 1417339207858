import React, { CSSProperties, Fragment, ReactNode } from 'react'
import {
  AppHeader,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { Switch, Route } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import api from '../../../../v3/helpers/api'
import { GlobalState } from '../../../../reducers'
import {
  HouseholdObj,
  HouseholdInstitution
} from '../../../../objects/household'
import { ContactsInterface } from '../../../../objects/contact'
import { history } from '../../../../store'
import InnerContainer from '../../../components/layout/innerContainer'

import { WelcomeContent } from './welcome/hcWelcome'
import { DescriptionContent } from './welcome/hcDescription'
import { StartContent } from './welcome/hcStart'
import {
  HcWelcomeDiv,
  LaunchHonestConvesations
} from './welcome/hcWelcomeHelper'
import PickCombinedCards from './pickCombinedCards'
import PickCombinedCardsSingleFlow from './pickCombinedCards/pickCombinedCardsSingleFlow'
import RevealCards from './reveal'
import ResultsSummary from '../exercise/results'
import PrintPreview from '../exercise/results/resultsPrintPreview'

import hcLogo from '../../../assets/images/honestConversations/hc-logo.png'
import printIcon from '../../../assets/images/icons/png/print.png'
import { PageNotFound } from '../../../../v3/containers/errors/pageNotFound'

import {
  getHonestConversationExercises,
  getOrCreateClientLoginToken,
  createHonestConversationExercise,
  getHonestConversationExercise,
  createHonestConversationHouseholdCards
} from '../../../../actions/hcExercises'
import * as householdActions from '../../../../actions/households'
import { getGoalActions } from '../../../../actions/financialGoalActions'

import { isObjEmpty } from '../../../helpers'
import { getLogo } from '../../../helpers/guidebook'
import { getTopFiveCards } from '../../../helpers/honestConversations'

import {
  ClientCardObj,
  HonestConversationsExerciseObj
} from '../../../../objects/honestConversations'

interface HonestConversationsExerciseProps {
  dispatch: Dispatch<GlobalState>
  match: any
  featureTag: string
  householdFinId: string
  household: HouseholdObj
  contacts: ContactsInterface
  hcExercise: HonestConversationsExerciseObj
  institution: HouseholdInstitution
  accessToken: string
  redux: GlobalState
  routerMatch: {}
}

interface HonestConversationsExerciseState {
  logo: string
  printPreview: boolean
  pdf: any
  fetchingToken: boolean
  hasHouseholdData: boolean
}

export type UserExerciseStatus =
  | 'Completed'
  | 'In Progress'
  | 'Not Started'
  | ''

const btnStyle: CSSProperties = {
  height: '40px',
  width: '168px',
  marginTop: '30px'
}

const tileStyle: CSSProperties = {
  margin: '40px auto',
  width: '730px',
  alignSelf: 'center',
  paddingBottom: '20px'
}

const hcLogoProperties: { src: string; css: CSSProperties } = {
  src: hcLogo,
  css: { height: '32px' }
}

class HonestConversationsExercise extends React.Component<
  HonestConversationsExerciseProps,
  HonestConversationsExerciseState
> {
  constructor(props: HonestConversationsExerciseProps) {
    super(props)
    this.state = {
      logo: '',
      printPreview: false,
      pdf: null,
      fetchingToken: false,
      hasHouseholdData: false
    }
  }

  public setLogoState = async () => {
    const { household } = this.props
    const logoData = await getLogo(household.institution.photoUrl)
    this.setState({
      logo: logoData
    })
  }

  public async componentDidMount() {
    const {
      dispatch,
      household,
      hcExercise,
      match,
      householdFinId,
      contacts
    } = this.props
    await dispatch(householdActions.getIndividualHousehold(householdFinId))
    if (match.params.exerciseId === 'new') {
      await dispatch(createHonestConversationExercise(householdFinId))
    }
    if (!hcExercise) {
      await dispatch(
        getHonestConversationExercises(match.params.householdFinId)
      )
    }
    dispatch(getGoalActions(householdFinId))
    if (hcExercise && contacts?.primary) {
      this.setState({ fetchingToken: true })
      dispatch(
        getOrCreateClientLoginToken(
          householdFinId,
          hcExercise.id,
          contacts.primary.id,
          true
        )
      )
    }
    if (hcExercise && contacts?.secondary) {
      this.setState({ fetchingToken: true })
      dispatch(
        getOrCreateClientLoginToken(
          householdFinId,
          match.params.exerciseId,
          contacts.secondary.id,
          false
        )
      )
    }
    if (household) {
      this.setState({ hasHouseholdData: true })
      if (household?.institution) this.setLogoState()
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<HonestConversationsExerciseProps>
  ) {
    const {
      dispatch,
      hcExercise,
      householdFinId,
      contacts,
      household
    } = this.props
    const { fetchingToken, logo } = this.state

    if (household !== prevProps.household)
      this.setState({ hasHouseholdData: true })

    if (logo?.length === 0 && household?.institution) this.setLogoState()

    if (
      hcExercise &&
      contacts?.primary &&
      !hcExercise.primaryLoginCode &&
      !fetchingToken
    ) {
      this.setState({ fetchingToken: true })
      dispatch(
        getOrCreateClientLoginToken(
          householdFinId,
          hcExercise.id,
          contacts.primary.id,
          true
        )
      )
    }

    if (
      hcExercise &&
      contacts?.secondary &&
      !hcExercise.secondaryLoginCode &&
      !fetchingToken
    ) {
      this.setState({ fetchingToken: true })
      dispatch(
        getOrCreateClientLoginToken(
          householdFinId,
          hcExercise.id,
          contacts.secondary.id,
          false
        )
      )
    }
  }

  public getStart = () => {
    const { contacts, hcExercise, accessToken, household } = this.props

    const getUserExerciseStatus = (
      cards: ClientCardObj[]
    ): UserExerciseStatus => {
      if (cards.length === 0) {
        return ''
      }
      const rankedCards = cards.filter((card: ClientCardObj) => card.ranking)
      if (rankedCards.length > 0) {
        const completedCards = rankedCards.filter(
          (card: ClientCardObj) => card.rankedWithin === 'All'
        )
        if (completedCards.length === 5) {
          return 'Completed'
        } else return 'In Progress'
      } else return 'Not Started'
    }

    let primaryContactExerciseStatus: UserExerciseStatus = ''
    let secondaryContactExerciseStatus: UserExerciseStatus = ''

    if (contacts?.primary?.id && hcExercise?.clientCards) {
      primaryContactExerciseStatus = getUserExerciseStatus(
        hcExercise.clientCards[contacts.primary.id]
      )
    }
    if (contacts?.secondary?.id && hcExercise?.clientCards) {
      secondaryContactExerciseStatus = getUserExerciseStatus(
        hcExercise.clientCards[contacts.secondary.id]
      )
    }

    if (contacts) {
      return (
        // get my code api here
        <StartContent tileStyle={tileStyle} clickFunc={this.openHcLink}>
          <HcWelcomeDiv className='hcw__welcome_messege-row'>
            <LaunchHonestConvesations
              name={contacts?.primary?.firstName || 'Primary'}
              clientId={contacts?.primary?.id}
              exerciseId={hcExercise?.id}
              accessToken={accessToken}
              exerciseStatus={primaryContactExerciseStatus}
              household={household}
            />
            {contacts?.secondary ? (
              <div className='hcw__welcome_divider' />
            ) : null}
            <LaunchHonestConvesations
              name={contacts?.secondary?.firstName || 'Secondary'}
              clientId={contacts?.secondary?.id}
              exerciseId={hcExercise?.id}
              accessToken={accessToken}
              exerciseStatus={secondaryContactExerciseStatus}
              household={household}
            />
          </HcWelcomeDiv>
          <Button onClick={this.goToRevealCards}>Next</Button>
        </StartContent>
      )
    } else {
      return null
    }
  }

  public getDescription = () => {
    return (
      <DescriptionContent
        tileStyle={tileStyle}
        buttonStyle={btnStyle}
        onClickFunc={this.letsBeginClick}
      />
    )
  }

  public getWelcome = () => {
    return (
      <WelcomeContent
        tileStyle={tileStyle}
        buttonStyle={btnStyle}
        onClickFunc={this.gotItClick}
      />
    )
  }

  public getPickCombined = () => {
    const { hcExercise, contacts } = this.props
    return (
      <PickCombinedCards
        hcExercise={hcExercise}
        contacts={contacts}
        back={this.goToRevealCards}
        next={this.goToResultsSummary}
      />
    )
  }

  public getPickCombinedSingleFlow = () => {
    const { hcExercise, contacts } = this.props
    return (
      <PickCombinedCardsSingleFlow
        hcExercise={hcExercise}
        contacts={contacts}
        back={this.goToRevealCards}
        next={this.goToResultsSummary}
      />
    )
  }

  public getRevealCards = () => {
    return (
      <RevealCards
        next={
          this.props.contacts?.secondary
            ? this.gotoPickCombinedCards
            : this.gotoPickCombinedCardsSingleFlow
        }
      />
    )
  }

  public getResultsSummary = () => {
    const { householdFinId, household, contacts, hcExercise } = this.props
    return hcExercise ? (
      <ResultsSummary
        householdFinId={householdFinId}
        honestConversation={hcExercise}
        household={household}
        contacts={contacts}
      />
    ) : null
  }

  public openHcLink = () => {
    window.open(window._env_.REACT_APP_HC_URL_V2)
  }

  public goToResultsSummary = () => {
    const { householdFinId, hcExercise } = this.props
    hcExercise &&
      history.push(
        `/households/${householdFinId}/honestConversations/v3/exercise/${hcExercise.id}/results`
      )
  }

  public goToRevealCards = async () => {
    const { householdFinId, hcExercise, dispatch } = this.props
    await dispatch(
      getHonestConversationExercise(householdFinId, hcExercise?.id)
    )
    hcExercise &&
      history.push(
        `/households/${householdFinId}/honestConversations/v3/exercise/${hcExercise.id}/reveal`
      )
  }

  public letsBeginClick = () => {
    const { householdFinId, hcExercise } = this.props
    hcExercise &&
      history.push(
        `/households/${householdFinId}/honestConversations/v3/exercise/${hcExercise.id}/start`
      )
  }

  public gotItClick = () => {
    const { householdFinId, hcExercise } = this.props
    hcExercise &&
      history.push(
        `/households/${householdFinId}/honestConversations/v3/exercise/${hcExercise.id}/description`
      )
  }

  public gotoPickCombinedCards = () => {
    const { householdFinId, hcExercise } = this.props
    hcExercise &&
      history.push(
        `/households/${householdFinId}/honestConversations/v3/exercise/${hcExercise.id}/pick`
      )
  }

  public gotoPickCombinedCardsSingleFlow = () => {
    const { householdFinId, hcExercise, contacts, dispatch } = this.props
    // For Single Household - Create householdcards using clientCard data if it doesn't exist
    if (
      hcExercise?.clientCards[contacts?.primary?.id] &&
      (!hcExercise?.householdCards || isObjEmpty(hcExercise?.householdCards))
    ) {
      const topFiveClient = getTopFiveCards(
        hcExercise.clientCards[contacts.primary.id]
      )
      const householdCards = topFiveClient.map((card) => {
        return {
          exerciseId: hcExercise.id,
          cardId: card.cardId,
          ranking: card.ranking
        }
      })
      dispatch(
        createHonestConversationHouseholdCards(
          householdFinId,
          hcExercise.id,
          householdCards
        )
      )
    }
    hcExercise &&
      history.push(
        `/households/${householdFinId}/honestConversations/v3/exercise/${hcExercise.id}/pickSingle`
      )
  }

  public goExit = () => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/honestConversations`)
  }

  public exitButton = () => {
    return <Button onClick={this.goExit}>Exit</Button>
  }

  public togglePrintPreview = () => {
    this.setState({
      printPreview: !this.state.printPreview
    })
  }

  public setPrintHeader = (): ReactNode => {
    return (
      <Fragment>
        <Button
          onClick={this.togglePrintPreview}
          type='clear'
          btnStyle={{
            position: 'relative',
            top: '7px',
            height: '30px'
          }}>
          <img src={printIcon} alt='print icon' /> Print
        </Button>
        <Button onClick={this.goExit}>Finish</Button>
      </Fragment>
    )
  }

  public printResults = async () => {
    const { contacts, householdFinId } = this.props

    const primaryName =
      contacts && contacts.primary ? contacts.primary.firstName : ''
    const secondaryName =
      contacts && contacts.secondary ? contacts.secondary.firstName + ' ' : ''
    const clientName =
      primaryName && secondaryName
        ? primaryName + ' and ' + secondaryName
        : primaryName || secondaryName

    await api()
      .post(
        `/households/${householdFinId}/guidebookgenerator/hc/results/html`,
        {
          id: 'results-summary',
          householdId: householdFinId,
          contacts: clientName,
          hcExerciseId: this.props.hcExercise.id
        }
      )
      .then(() => {
        this.setState({
          printPreview: true
        })
      })
      .catch((error: any) => {
        return error
      })
  }

  public closeResultsPrintPreview = () => {
    this.setState({
      printPreview: false
    })
  }

  public setUiFeature = (): { rightHeader: ReactNode; progress: string } => {
    const { featureTag } = this.props
    switch (featureTag) {
      case 'results':
        return { rightHeader: this.setPrintHeader(), progress: '100%' }
      case 'pick':
        return { rightHeader: this.exitButton(), progress: '90%' }
      default:
        return { rightHeader: this.exitButton(), progress: '10%' }
    }
  }

  public render() {
    const { match, household, householdFinId } = this.props
    const { hasHouseholdData } = this.state
    const headerUiFeature = this.setUiFeature()
    return hasHouseholdData ? (
      household && household?.id === match.params.householdFinId ? (
        <Fragment>
          <div className='hcw__w'>
            <AppHeader
              logoAlt=''
              logoSrc={hcLogoProperties.src}
              logoStyles={hcLogoProperties.css}
              hhName={household.name}
              rightHeader={headerUiFeature.rightHeader}
              progressBarStyles={{ width: headerUiFeature.progress }}
            />
            <InnerContainer>
              <Switch>
                <Route
                  path={`${match.path}`}
                  component={this.getWelcome}
                  exact={true}
                />
                <Route
                  path={`${match.path}/description`}
                  render={this.getDescription}
                  exact={true}
                />
                <Route
                  path={`${match.path}/start`}
                  render={this.getStart}
                  exact={true}
                />
                <Route
                  path={`${match.path}/reveal`}
                  render={this.getRevealCards}
                  exact={true}
                />
                <Route
                  path={`${match.path}/pick`}
                  render={this.getPickCombined}
                  exact={true}
                />
                <Route
                  path={`${match.path}/pickSingle`}
                  render={this.getPickCombinedSingleFlow}
                  exact={true}
                />
                <Route
                  path={`${match.path}/results`}
                  render={this.getResultsSummary}
                  exact={true}
                />
              </Switch>
            </InnerContainer>
          </div>
          {this.state.printPreview ? (
            <PrintPreview
              id={householdFinId}
              closeModal={this.closeResultsPrintPreview}
              printResults={this.printResults}
            />
          ) : null}
        </Fragment>
      ) : (
        <PageNotFound />
      )
    ) : null
  }
}

//
const setFeatureTag = (location: { pathname: string }) => {
  const { pathname } = location
  const pathnameArray = pathname.split('/')
  return pathnameArray[pathnameArray.length - 1]
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, exerciseId } = match.params
  return {
    featureTag: setFeatureTag(history.location),
    householdFinId,
    household: store.households[householdFinId],
    hcExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][exerciseId],
    contacts: store.contact[householdFinId],
    institution: store.households[householdFinId]?.institution,
    accessToken: store.user.accessToken,
    redux: store,
    routerMatch: match
  }
}

export default connect(mapStateToProps)(HonestConversationsExercise)
