import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'

import uuid from 'uuid'
import { GlobalState } from '../../../../../reducers'
import {
  updatePortfolioBuilderAccount,
  getPortfolioBuilderAccounts
} from '../../../../../actions/portfolioBuilder'
import {
  PortfolioBuilderObj,
  PortfolioBuilderAccountObj
} from '../../../../../objects/portfolioBuilder'
import Modal from '../../../../components/layout/modal'
import Button from '../../../../components/button'
import TableRow from '../../../../components/layout/tableRow'
import CombineListLabel from './combineListLabel'
import CombineList from './combineList'
import { deepEqualComparison } from '../../../../helpers'

import { ReactComponent as MenuIcon } from '../../../../assets/images/icons/ic_menu.svg'

interface CombineAccountModalProps {
  dispatch?: Dispatch<GlobalState>
  stratDetails: object
  portfolioBuilderData: PortfolioBuilderObj
  selectedAccount: PortfolioBuilderAccountObj
  householdFinId: string
  portfolioBuilderId: string
  toggleCombineModal(): void
}

interface CombineAccountModalState {
  selectedAccounts: PortfolioBuilderAccountObj[]
  accountIdList: string[]
  nameArrow: boolean
  typeArrow: boolean
  amountArrow: boolean
  activeSortToggle: boolean
  activeSortName: string
}

class CombineAccountModal extends Component<
  CombineAccountModalProps,
  CombineAccountModalState
> {
  constructor(props: CombineAccountModalProps) {
    super(props)
    this.state = {
      selectedAccounts: [],
      accountIdList: [],
      nameArrow: true,
      typeArrow: true,
      amountArrow: true,
      activeSortToggle: true,
      activeSortName: ''
    }
  }

  public componentDidMount() {
    this.refreshDisplayList()
  }

  public componentDidUpdate(prevProps: any) {
    const { currentAccounts } = this.props.portfolioBuilderData
    if (
      !deepEqualComparison(
        currentAccounts,
        prevProps.portfolioBuilderData.currentAccounts
      )
    ) {
      this.refreshDisplayList()
    }
  }

  public refreshDisplayList = () => {
    const { currentAccounts } = this.props.portfolioBuilderData
    const selectedAccounts = [this.props.selectedAccount]
    const accountIdList = [this.props.selectedAccount.id]
    Object.keys(currentAccounts).forEach((acc) => {
      if (
        this.props.selectedAccount.combinedId !== null &&
        currentAccounts[acc].combinedId ===
          this.props.selectedAccount.combinedId
      ) {
        if (currentAccounts[acc].id !== this.props.selectedAccount.id) {
          selectedAccounts.push(currentAccounts[acc])
        }
        if (
          !accountIdList.includes(currentAccounts[acc].id) ||
          currentAccounts[acc].id !== this.props.selectedAccount.id
        ) {
          accountIdList.push(currentAccounts[acc].id)
        }
      }
    })
    this.setState({ selectedAccounts, accountIdList })
  }

  public handleCancel = () => {
    this.setState({ selectedAccounts: [], accountIdList: [] })
    this.props.toggleCombineModal()
  }

  public toggleCheckbox = (account: PortfolioBuilderAccountObj) => () => {
    const selectedAccounts = [...this.state.selectedAccounts]
    if (!this.state.accountIdList.includes(account.id)) {
      selectedAccounts.push(account)
    } else {
      selectedAccounts.forEach((acc, i) =>
        account.id === acc.id ? selectedAccounts.splice(i, 1) : null
      )
    }
    const accountIdList = selectedAccounts.map((acc) => acc.id)
    this.setState({ ...this.state, selectedAccounts, accountIdList })
  }

  public saveCombineAccounts = async () => {
    const { portfolioBuilderId, householdFinId } = this.props
    this.props.toggleCombineModal()
    await this.combineAccounts()
    this.props.dispatch(
      getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
    )
  }

  public removeCombineId = () => {
    const {
      currentAccounts,
      proposedAccounts
    } = this.props.portfolioBuilderData
    const { portfolioBuilderId, householdFinId } = this.props
    const selectedAccount = this.props.selectedAccount

    return new Promise((resolve, reject) => {
      const promiseCollection: Promise<boolean>[] = []

      Object.keys(currentAccounts).map(async (val: any) => {
        promiseCollection.push(
          new Promise(async (resolveRemoved) => {
            // remove the combinedId for uncombined current accounts
            const account = currentAccounts[val]
            if (
              account.combinedId &&
              selectedAccount.combinedId &&
              account.combinedId === selectedAccount.combinedId &&
              !this.state.accountIdList.includes(account.id)
            ) {
              account.combinedId = null
              await this.props.dispatch(
                updatePortfolioBuilderAccount(
                  householdFinId,
                  portfolioBuilderId,
                  account.id,
                  account
                )
              )
              // remove the combinedId for uncombined proposed
              // accounts and reset the strategy for all proposed
              Object.keys(proposedAccounts)
                .filter((key: string) => {
                  return (
                    proposedAccounts[key].clientAccount ===
                    currentAccounts[val].clientAccount
                  )
                })
                .forEach((key1: string) => {
                  const newAccount: PortfolioBuilderAccountObj =
                    proposedAccounts[key1]
                  if (newAccount.id) {
                    newAccount.combinedId = null
                    if (newAccount.strategy) {
                      newAccount.strategy = null
                    }
                    newAccount.strategyFee = 0
                    newAccount.yield = 0
                    newAccount.currentCashValue = 0
                    newAccount.currentEquityValue = 0
                    newAccount.currentFixedValue = 0
                    newAccount.currentOtherValue = 0
                    newAccount.currentUnclassifiedValue = 0
                    this.props.dispatch(
                      updatePortfolioBuilderAccount(
                        householdFinId,
                        portfolioBuilderId,
                        newAccount.id,
                        newAccount
                      )
                    )
                  }
                })
              // clean up if all in a combined group have been uncombined
            } else if (
              this.state.accountIdList.includes(account.id) &&
              this.state.accountIdList.length === 1
            ) {
              Object.keys(proposedAccounts)
                .filter((key: string) => {
                  return (
                    proposedAccounts[key].clientAccount ===
                    currentAccounts[val].clientAccount
                  )
                })
                .forEach(async (key1: string) => {
                  const lastAccount: PortfolioBuilderAccountObj =
                    proposedAccounts[key1]
                  lastAccount.combinedId = null
                  await this.props.dispatch(
                    updatePortfolioBuilderAccount(
                      householdFinId,
                      portfolioBuilderId,
                      lastAccount.id,
                      lastAccount
                    )
                  )
                })
            }
            resolveRemoved(true)
          })
        )
      })
      Promise.all(promiseCollection)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public combineAccounts = async () => {
    const { proposedAccounts } = this.props.portfolioBuilderData
    const { portfolioBuilderId, householdFinId } = this.props
    const { selectedAccounts } = this.state
    const { selectedAccount } = this.props

    await this.removeCombineId()
    const newUuid = uuid.v4()

    // set a selected proposed account based on the selected
    // current account
    let selectedProposedAccount: PortfolioBuilderAccountObj
    selectedAccounts.map(async (value: PortfolioBuilderAccountObj) => {
      new Promise((resolveSelected) => {
        Object.keys(proposedAccounts)
          .filter((key: string) => {
            return proposedAccounts[key].clientAccount === value.clientAccount
          })
          .forEach((key1: string) => {
            if (
              selectedAccount &&
              selectedAccount.clientAccount ===
                proposedAccounts[key1].clientAccount
            ) {
              selectedProposedAccount = proposedAccounts[key1]
            }
          })
        resolveSelected(true)
      })
    })
    return new Promise((resolve, reject) => {
      const promiseCollection: Promise<boolean>[] = []

      if (selectedAccounts.length === 1) {
        promiseCollection.push(
          new Promise(async (resolveSingle) => {
            selectedAccounts[0].combinedId = null
            await this.props.dispatch(
              updatePortfolioBuilderAccount(
                householdFinId,
                portfolioBuilderId,
                selectedAccounts[0].id,
                selectedAccounts[0]
              )
            )
            resolveSingle(true)
          })
        )
      } else {
        selectedAccounts.map(async (value: PortfolioBuilderAccountObj) => {
          promiseCollection.push(
            new Promise(async (resolveNew) => {
              value.combinedId = newUuid
              await this.props.dispatch(
                updatePortfolioBuilderAccount(
                  householdFinId,
                  portfolioBuilderId,
                  value.id,
                  value
                )
              )
              let stratAccount: PortfolioBuilderAccountObj
              Object.keys(proposedAccounts)
                .filter((key: string) => {
                  return (
                    proposedAccounts[key].clientAccount === value.clientAccount
                  )
                })
                .forEach((key1: string) => {
                  promiseCollection.push(
                    new Promise(async (resolveCombined) => {
                      stratAccount = proposedAccounts[key1]
                      stratAccount.combinedId = newUuid
                      if (
                        stratAccount &&
                        selectedAccount &&
                        selectedProposedAccount &&
                        selectedAccount.clientAccount !==
                          proposedAccounts[key1].clientAccount
                      ) {
                        stratAccount.strategy = selectedProposedAccount.strategy
                        stratAccount.strategyFee =
                          selectedProposedAccount.strategyFee
                        stratAccount.yield = selectedProposedAccount.yield
                        stratAccount.unsplitId =
                          selectedProposedAccount.unsplitId
                        if (
                          value &&
                          value.accountValue > 0 &&
                          selectedProposedAccount &&
                          selectedProposedAccount.accountValue > 0
                        ) {
                          stratAccount.currentCashValue =
                            (selectedProposedAccount.currentCashValue /
                              selectedProposedAccount.accountValue) *
                            value.accountValue
                          stratAccount.currentEquityValue =
                            (selectedProposedAccount.currentEquityValue /
                              selectedProposedAccount.accountValue) *
                            value.accountValue
                          stratAccount.currentFixedValue =
                            (selectedProposedAccount.currentFixedValue /
                              selectedProposedAccount.accountValue) *
                            value.accountValue
                          stratAccount.currentOtherValue =
                            (selectedProposedAccount.currentOtherValue /
                              selectedProposedAccount.accountValue) *
                            value.accountValue
                          stratAccount.currentUnclassifiedValue =
                            (selectedProposedAccount.currentUnclassifiedValue /
                              selectedProposedAccount.accountValue) *
                            value.accountValue
                        }
                      }
                      await this.props.dispatch(
                        updatePortfolioBuilderAccount(
                          householdFinId,
                          portfolioBuilderId,
                          stratAccount.id,
                          stratAccount
                        )
                      )
                      resolveCombined(true)
                    })
                  )
                })
              resolveNew(true)
            })
          )
        })
      }
      Promise.all(promiseCollection)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public sortToggle = (label: string) => () => {
    if (label === 'name') {
      this.setState({
        nameArrow: !this.state.nameArrow,
        typeArrow: true,
        amountArrow: true,
        activeSortName: 'accountNickname',
        activeSortToggle: !this.state.nameArrow
      })
    } else if (label === 'type') {
      this.setState({
        typeArrow: !this.state.typeArrow,
        nameArrow: true,
        amountArrow: true,
        activeSortName: 'registrationType',
        activeSortToggle: !this.state.typeArrow
      })
    } else if (label === 'amount') {
      this.setState({
        amountArrow: !this.state.amountArrow,
        typeArrow: true,
        nameArrow: true,
        activeSortName: 'accountValue',
        activeSortToggle: !this.state.amountArrow
      })
    }
  }

  public defaultSorting = () => {
    const { currentAccounts } = this.props.portfolioBuilderData
    const { stratDetails } = this.props
    let filterSplitAccounts = Object.keys(stratDetails)
      .map((e, i) => {
        if (stratDetails[e].length <= 1) {
          return stratDetails[e]
        }
      })
      .filter((e) => e)
    filterSplitAccounts = [].concat(...filterSplitAccounts)

    let filterCurrentAccounts: any[] = []
    Object.keys(currentAccounts).forEach((e, i) => {
      filterSplitAccounts.forEach((e1: any) => {
        if (currentAccounts[e].clientAccount === e1.clientAccount) {
          filterCurrentAccounts.push(currentAccounts[e])
        }
      })
    })
    return (filterCurrentAccounts = filterCurrentAccounts.sort((a, b) =>
      a.accountNickname > b.accountNickname
        ? 1
        : b.accountNickname > a.accountNickname
        ? -1
        : 0
    ))
  }

  public render() {
    const { currentAccounts } = this.props.portfolioBuilderData
    return (
      <Modal
        icon={MenuIcon}
        title='COMBINE ACCOUNTS'
        closeModal={this.props.toggleCombineModal}>
        <div className='combine__modal-body'>
          <div className='combine__modal-text'>
            Select accounts to combine with this parent account.
          </div>
          <TableRow backgroundColor='#F5F5F5'>
            <div className='combine__table-header'>
              <div className='combine__table-header-section'>
                <CombineListLabel
                  title='NAME'
                  handleSort={this.sortToggle('name')}
                  arrow={this.state.nameArrow}
                />
              </div>
              <div className='combine__table-header-section'>
                <CombineListLabel
                  title='REGISTRATION TYPE'
                  handleSort={this.sortToggle('type')}
                  arrow={this.state.typeArrow}
                />
              </div>
              <div className='combine__table-header-section'>
                <CombineListLabel
                  title='AMOUNT'
                  handleSort={this.sortToggle('amount')}
                  arrow={this.state.amountArrow}
                />
              </div>
            </div>
          </TableRow>
          {Object.keys(currentAccounts).length !== 0 ? (
            <CombineList
              currentAccounts={this.defaultSorting()}
              toggleCheckbox={this.toggleCheckbox}
              accountIdList={this.state.accountIdList}
              selectedAccount={this.props.selectedAccount}
              activeSortName={this.state.activeSortName}
              activeSortToggle={this.state.activeSortToggle}
            />
          ) : null}

          <div className='data-right'>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button primary={true} onClick={this.saveCombineAccounts}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    portfolioBuilderId: match.params.portfolioBuilderId,
    portfolioBuilderData:
      store.portfolioBuilder[match.params.householdFinId] &&
      store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ]
  }
}

export default withRouter(connect(mapStateToProps)(CombineAccountModal))
