import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'

import { setInstitutions } from '../../../actions/clientFilter'
import { GlobalState } from '../../../reducers'
import { HouseholdsState } from '../../../reducers/households'
import { isObjEmpty } from '../../helpers'
import { UserObj } from '../../../objects/user'
import { InstitutionObj } from '../../../objects/institution'

import ProfileMenu from './profileMenu'
import MeetingMode from '../../components/meetingMode'
import HeaderNotification from './headerNotification'
import ResourcesDropdown from './resourcesDropdown'

import advisorcenterLogo from '../../assets/images/logos/advisorcenter_logo.png'
import ClientsIcon from '../../assets/images/icons/general/clients.png'
import MessagesIcon from '../../assets/images/icons/png/messages-white.png'

interface HeaderProps {
  user: UserObj
  notifications: any
  households: HouseholdsState
  institution: InstitutionObj
  dispatch: Dispatch<GlobalState>
}

class Header extends Component<HeaderProps> {
  public componentDidUpdate(prevProps: HeaderProps) {
    const { households } = this.props
    if (isObjEmpty(prevProps.households) && !isObjEmpty(households)) {
      this.getInstitutions()
    }
  }

  public getInstitutions() {
    const { households, dispatch } = this.props
    // create an array of ids to act as a check to only fetch 1 instance of an institution id
    const institutionIds: string[] = []
    const householdsWithUniqueInstitutions: string[] = []
    Object.keys(households).forEach(async (household) => {
      // check institutionIds to see if the institution has already been fetched
      if (!institutionIds.includes(households[household].institutionId)) {
        // push institution id to array record
        institutionIds.push(households[household].institutionId)
        // set the household
        householdsWithUniqueInstitutions.push(
          households[household].institutionId
        )
      }
    })

    dispatch(setInstitutions(householdsWithUniqueInstitutions))
  }

  public render() {
    const { notifications, institution } = this.props
    return (
      <header className='header'>
        <div className='header__w'>
          <div className='header-w__left'>
            <div className='header__logo'>
              <img src={advisorcenterLogo} alt='finlifeCX-logo' />
            </div>
            <MeetingMode>
              <div className='header__divider' />
              <Link to='/' className='header__icon header__clients'>
                <img src={ClientsIcon} alt='client icon' />
                <span>Clients</span>
              </Link>
            </MeetingMode>
          </div>
          <div className='header-w__right'>
            {window._env_.REACT_APP_ADVISOR_MESSAGES_ENABLED && (
              <Fragment>
                <Link
                  to='/advisor-messages'
                  className='header__icon header__advisor-messages'>
                  <img src={MessagesIcon} alt='message icon' />
                  <span>Messages</span>
                </Link>
                <div className='header__divider' />
              </Fragment>
            )}
            <MeetingMode>
              <ResourcesDropdown institution={institution} />
              <div className='header__divider' />
              <div className='header__icon'>
                <HeaderNotification notifications={notifications} />
              </div>
            </MeetingMode>
            <ProfileMenu />
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    households: store.households,
    user: store.user.userId ? store.user.users[store.user.userId] : null,
    institution: store.institution
  }
}
export default connect(mapStateToProps)(Header)
