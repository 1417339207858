import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from './reducers'
import * as userActions from './actions/user'
import MainContainerRoutes from './mainContainerRoutes'
import { Route, Switch } from 'react-router'
import SalesforceIntegrationAuthv2 from './v3/containers/auth/salesforceIntegrationAuth'
import TamaracIntegrationAuth from './v3/containers/auth/tamaracIntegrationAuth'
import CrmAuth from './v3/containers/auth/crmAuth'
import { getLoginURL } from './helpers'

interface AuthenticatedRoutesProps {
  dispatch: Dispatch<GlobalState>
  accessToken: string
}

class AuthenticatedRoutes extends React.Component<AuthenticatedRoutesProps> {
  componentDidMount() {
    const { dispatch } = this.props
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken || accessToken === 'undefined') {
      sessionStorage.setItem('redirect', window.location.pathname)
      window.location.href = getLoginURL()
    } else {
      dispatch(userActions.setAccessToken(accessToken))
      dispatch(userActions.getUser())
    }
  }
  render() {
    const { accessToken } = this.props
    if (accessToken) {
      return (
        <Switch>
          <Route
            path='/auth/crm/ms'
            exact={true}
            component={TamaracIntegrationAuth}
          />
          <Route path='/auth/crm/:crm' exact={true} component={CrmAuth} />
          <Route
            path='/auth/callback/sf'
            exact={true}
            component={SalesforceIntegrationAuthv2}
          />
          <Route path='/' component={MainContainerRoutes} />
        </Switch>
      )
    } else return null
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    accessToken: store.user.accessToken
  }
}

export default connect(mapStateToProps)(AuthenticatedRoutes)
