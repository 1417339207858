import React, { Component } from 'react'
import Modal from '../../../components/layout/modal'
import { ContactsInterface } from '../../../../objects/contact'
import { ReactComponent as PalIcon } from '../../../assets/images/icons/pal.svg'
import ActionForm from './actionForm'
import { LifeActionObj } from '../../../../objects/lifeActions'

export interface AddActionModalProps {
  editRecord?: boolean
  cardIndex?: number
  currentCardId?: string
  contacts: ContactsInterface
  closeModal(): void
  modifyAction?(action: LifeActionObj, modifyType: string): void
}

class AddActionModal extends Component<AddActionModalProps> {
  public render() {
    const {
      contacts,
      closeModal,
      currentCardId,
      cardIndex,
      editRecord,
      modifyAction
    } = this.props
    return (
      <div className='action-modal'>
        <Modal icon={PalIcon} title='ADD ACTION' closeModal={closeModal}>
          <ActionForm
            editRecord={editRecord}
            cardIndex={cardIndex}
            currentCardId={currentCardId}
            modalType='add'
            closeModal={closeModal}
            contacts={contacts}
            modifyAction={modifyAction}
          />
        </Modal>
      </div>
    )
  }
}

export default AddActionModal
