import api from '../v3/helpers/api'

export const UPDATE_POSITION: string = 'UPDATE_POSITION'
export const UPLOAD_POSITION: string = 'UPLOAD_POSITIONS'
export const REMOVE_IM_TOAST: string = 'REMOVE_IM_TOAST'
export const ADD_IM_POSITIONS_ERROR_TOAST: string =
  'ADD_IM_POSITIONS_ERROR_TOAST'

export const updateOverrideAllocations = (
  householdFinId: string,
  positionId: string,
  requestBody: any
) => {
  return {
    type: UPDATE_POSITION,
    payload: api()
      .put(`households/${householdFinId}/positions/${positionId}`, requestBody)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const uploadPositions = (
  householdFinId: string,
  clientAccount?: string,
  data?: any,
  fields?: string[]
) => {
  return {
    type: UPLOAD_POSITION,
    payload: api()
      .put(
        `/households/${householdFinId}/clientaccounts/${clientAccount}/positions?fields=${fields.join()}`,
        data,
        { headers: { 'content-type': 'text/csv' } }
      )
      .then((response) => {
        const { data: responseData } = response
        return { ...responseData, clientAccount, householdFinId }
      })
  }
}

export const addToast = (data: any) => {
  return {
    type: ADD_IM_POSITIONS_ERROR_TOAST,
    payload: data
  }
}

export const removeToast = (index: number) => {
  return {
    type: REMOVE_IM_TOAST,
    payload: index
  }
}
