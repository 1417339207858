import React from 'react'
import messageIcon from '../../assets/images/icons/png/il-messages.png'
import MessagesError from './error'

interface MessageNullProps {
  error?: boolean
}

const MessageNull = ({ error }: MessageNullProps) => {
  return (
    <div className={`message-null-container${error ? '--error' : ''}`}>
      {error ? (
        <MessagesError />
      ) : (
        <>
          <img alt='message icon' src={messageIcon} />
          <p className='message-null-text__blue'>No messages yet</p>
          <p className='message-null-text'>
            Start a new conversation by clicking "New Message" button on the top
            left.
          </p>
        </>
      )}
    </div>
  )
}

export default MessageNull
