import React, { FunctionComponent, SVGProps } from 'react'
import BarGraph from './barGraph'
import { ReactComponent as TargetIcon } from '../../assets/images/icons/goals.svg'

const PriorityBar = ({
  color,
  Icon,
  name,
  score,
  primary,
  secondary
}: {
  color: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  name: string
  score: number
  primary?: boolean
  secondary?: boolean
}) => {
  const height = (score / 16) * 100
  const opaque = !primary && !secondary
  const opaqueClass = opaque ? 'priority-bar__target--opaque' : ''
  return (
    <div className='priority-bar-w'>
      <div
        className='priority-bar__highlight'
        style={{ visibility: opaque ? 'hidden' : 'visible' }}>
        {primary ? 'Primary' : 'Secondary'}
      </div>
      <div className={`priority-bar__target-w ${opaqueClass}`}>
        <BarGraph color={color} heightPercent={height} />
        <span
          className='priority-bar__target'
          style={{ height: `${height}%`, color }}>
          <TargetIcon />
        </span>
      </div>
      <div className='priority-bar__label-w'>
        <Icon className='priority-bar__label-img' />
        <div className='priority-bar__label'>{name}</div>
      </div>
    </div>
  )
}

export default PriorityBar
