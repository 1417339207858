import axios, { AxiosInstance } from 'axios'

const api = (): AxiosInstance => {
  const baseURL = window._env_.REACT_APP_API_URL
  const defaultHeaders = new URL(baseURL).host.includes('localhost')
    ? { 'x-forwarded-proto': 'https' }
    : {}

  const accessToken = localStorage.getItem('accessToken')
  const authorizationHeader = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : {}
  const instance = axios.create({
    baseURL,
    timeout: 55000,
    maxContentLength: 65 * 1024 * 1024,
    headers: {
      ...defaultHeaders,
      ...authorizationHeader
    }
  })

  return instance
}

export default api
