import * as actions from '../actions/contacts'
import { LoginHistoryObj } from '../objects/loginHistory'

export interface LoginHistoryState {
  [clientId: string]: LoginHistoryObj[]
}

export const initState: LoginHistoryState = {}

const mapHistory = (history: LoginHistoryObj) => {
  return {
    id: history.id,
    crmId: history.crmId,
    guidecenterUserId: history.guidecenterUserId,
    clientId: history.clientId,
    householdId: history.householdId,
    loginDate: history.loginDate,
    loginStatus: history.loginStatus
  }
}

const mapHistories = (histories: LoginHistoryObj[]) => {
  const mappedHistories = histories.reduce(
    (accum: any, history: LoginHistoryObj) => {
      if (accum[history.clientId] === undefined) {
        accum[history.clientId] = []
        accum[history.clientId].push(mapHistory(history))
      } else if (accum[history.clientId] !== undefined) {
        accum[history.clientId].push(mapHistory(history))
      }
      return accum
    },
    {}
  )
  return mappedHistories
}

const LoginHistory = (state = initState, action: any): LoginHistoryState => {
  switch (action.type) {
    case actions.FETCH_CONTACT_LOGIN_HISTORY + '_FULFILLED':
      return { ...state, ...mapHistories(action.payload) }
    default:
      return state
  }
}
export default LoginHistory
