import React, { Fragment } from 'react'
import TableRow from '../../components/layout/tableRow'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg'
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { GlobalState } from '../../../reducers'
import { Dispatch, connect } from 'react-redux'
import { FileObj } from '../../../objects/folder'
import ActionDropdown from '../../components/actionDropdown'

export interface FolderProps {
  name: string
  lastUpdated: string
  id: string
  readOnly: boolean
  url: string
  householdFinId: string
  isNew?: boolean
  dispatch: Dispatch<GlobalState>
  files: FileObj[]
  deleteFn(id: string): void
  editFn(id: string, name: string): void
}

export interface FolderState {
  noneArchivedFiles: FileObj[]
}

export class Folder extends React.Component<FolderProps, FolderState> {
  constructor(props: FolderProps) {
    super(props)
    this.state = {
      noneArchivedFiles: []
    }
  }
  componentDidMount() {
    const { files } = this.props
    this.setState({
      noneArchivedFiles: files && files.filter((file) => !file.archived)
    })
  }
  public editFolderFn = () => {
    const { id, name, editFn, readOnly } = this.props
    if (!readOnly) {
      editFn(id, name)
    }
  }
  public deleteFolderFn = () => {
    const { id, deleteFn, readOnly } = this.props
    const { noneArchivedFiles } = this.state
    if (!readOnly && noneArchivedFiles.length === 0) {
      deleteFn(id)
    }
  }
  public editFolder() {
    return (
      <Fragment>
        <EditIcon />
        <span className='action-dropdown__item-label'>Edit Folder</span>
      </Fragment>
    )
  }
  public deleteFolder() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete Folder</span>
      </Fragment>
    )
  }
  public folderActions = () => {
    const fullActions = [
      {
        element: this.editFolder(),
        onClick: this.editFolderFn
      },
      {
        element: this.deleteFolder(),
        onClick: this.deleteFolderFn
      }
    ]
    const partialActions = [
      {
        element: this.editFolder(),
        onClick: this.editFolderFn
      }
    ]
    const { readOnly } = this.props
    const { noneArchivedFiles } = this.state
    if (!readOnly) {
      return (
        <div className='folder__column-actions folder__column-actions--actions'>
          <ActionDropdown
            actions={
              noneArchivedFiles.length === 0 ? fullActions : partialActions
            }
          />
        </div>
      )
    } else {
      return (
        <div
          className='folder__column-actions folder__column-actions--lock'
          title='Folder can’t be edited or deleted.'>
          <LockIcon />
        </div>
      )
    }
  }
  public render() {
    const { name, id, url, isNew } = this.props
    const { noneArchivedFiles } = this.state
    return (
      <TableRow to={`${url}/${id}`}>
        <div className='folder__column--name'>
          <div className='folder__icon--w'>
            <FolderIcon className='folder__icon' />
          </div>
          <div>
            <span>{name}</span>
            {isNew ? <span className='folder__column-new-tag'>New</span> : null}
          </div>
        </div>
        <div className='folder__column--file-count'>
          {noneArchivedFiles.length}
        </div>
        {this.folderActions()}
      </TableRow>
    )
  }
}

export default connect()(Folder)
