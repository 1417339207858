import { LifeActionsInterface } from '../../objects/lifeActions'
import { HouseholdCardObj } from '../../objects/honestConversations'

export const filterActions = (
  lifeActions: LifeActionsInterface,
  card: HouseholdCardObj
) => {
  if (lifeActions && card) {
    const filtered = Object.keys(lifeActions).filter((key) => {
      let relatedAction = false
      const obj = lifeActions[key].cards
      const relatedCard = obj[Object.keys(obj)[0]]
      if (relatedCard && relatedCard.hcCardId) {
        if (relatedCard.hcCardId === card.card.id) {
          relatedAction = true
        }
      }
      return relatedAction
    })
    return filtered.map((key) => lifeActions[key])
  } else return null
}
