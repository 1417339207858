import React, { CSSProperties } from 'react'
import { ReactComponent as AlertIcon } from '../../assets/images/icons/alert-circle.svg'

export interface MessagesErrorProps {
  className?: string
  style?: CSSProperties
  showLabel?: boolean
}

const MessagesError = ({
  className = '',
  showLabel = true,
  style = {}
}: MessagesErrorProps) => (
  <div className={`messages-error ${className}`.trim()} style={style}>
    <div className='messages-error__body'>
      <AlertIcon
        className='messages-error__body-icon'
        style={{ height: '16px', color: '#f49500' }}
      />
      {showLabel && (
        <span
          className='messages-error__body-text'
          style={{ fontSize: '12px', lineHeight: '16px' }}>
          Messages cannot be loaded.
        </span>
      )}
    </div>
  </div>
)

export default MessagesError
