import React, { CSSProperties } from 'react'
import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'

const btnStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -45%)',
  whiteSpace: 'nowrap'
}

export const RevealButton: React.FunctionComponent<{
  onClickFunc(): void
}> = ({ onClickFunc }) => (
  <div className='hc-reveal__card-reveal-btn'>
    <div className='hc-reveal__card-reveal-btn-w'>
      <div className='hc-reveal__card-reveal-btn-blur-w'>
        <div className='hc-reveal__card-reveal-btn-blur' />
      </div>
      <Button onClick={onClickFunc} btnStyle={btnStyle}>
        Reveal Cards
      </Button>
    </div>
  </div>
)
