import React, { CSSProperties } from 'react'
import avatar from '../../assets/images/icons/general/avatar.png'
import avatarSecondary from '../../assets/images/icons/general/avatar-gray.png'

export interface ProfilePictureProps {
  firstName: string
  lastName: string
  photo: string
  isPrimary: boolean
  pictureSize?: number
  styleOverride?: CSSProperties
}

export class ProfilePicture extends React.Component<ProfilePictureProps> {
  public profilePicture = () => {
    const { pictureSize } = this.props
    return (
      <img
        style={{
          minWidth: pictureSize,
          minHeight: pictureSize,
          maxWidth: pictureSize,
          maxHeight: pictureSize
        }}
        className='profile-picture__picture'
        src={this.props.photo}
        alt='profile picture'
      />
    )
  }

  public initials = () => {
    const { firstName, lastName, isPrimary } = this.props
    const firstInitial = firstName && firstName[0].toUpperCase()
    const lastInitial = lastName && lastName[0].toUpperCase()

    return (
      <div
        className={
          isPrimary
            ? 'profile-picture__initials--primary'
            : 'profile-picture__initials--secondary'
        }>
        {(firstInitial || '') + (lastInitial || '')}
      </div>
    )
  }

  public avatarPicture = () => {
    const { isPrimary } = this.props
    return (
      <img src={isPrimary ? avatar : avatarSecondary} alt='avatar picture' />
    )
  }

  public render() {
    const {
      isPrimary,
      pictureSize,
      photo,
      firstName,
      lastName,
      styleOverride
    } = this.props
    const textSize = pictureSize <= 32 ? 16 : 20
    const letters = /^[A-Za-z]/
    const hasInitials = Boolean(
      this.props && (firstName?.match(letters) || lastName?.match(letters))
    )

    return (
      <div
        style={{
          minWidth: pictureSize,
          minHeight: pictureSize,
          maxWidth: pictureSize,
          maxHeight: pictureSize,
          fontSize: textSize,
          ...styleOverride
        }}
        className={`profile-picture__w ${
          isPrimary
            ? `profile-picture__w--primary`
            : `profile-picture__w--secondary`
        }`}>
        {photo
          ? this.profilePicture()
          : hasInitials
          ? this.initials()
          : this.avatarPicture()}
      </div>
    )
  }
}

export default ProfilePicture
