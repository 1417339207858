import React, { ChangeEvent } from 'react'
import { connect, Dispatch } from 'react-redux'

import { setClientListFilters } from '../../../actions/clientFilter'
import { GlobalState } from '../../../reducers'
import { ContactState } from '../../../reducers/contacts'
import { HouseholdsState } from '../../../reducers/households'
import { InstitutionObj } from '../../../objects/institution'
import { getOfficeTeams } from '../../../actions/officeTeams'

import HouseholdRow from './householdRow'
import Disclosure from '../../components/layout/disclosure'
import HouseholdHeaderLabel from './householdHeaderLabel'
import Search from '../../components/search'
import Tile from '../../components/layout/tile'
import NullState from '../../components/nullState'
import TableHeader from '../../components/layout/tableHeader'
import ContentHeader from '../../components/layout/contentHeader'
import InnerContainer from '../../components/layout/innerContainer'
import ClientFilter from './clientFilter'

import nullIcon from '../../assets/images/icons/ic_nophoto.png'
import FilterIcon from '../../assets/images/icons/png/filter_blue_x2.png'
import noResultsIcon from '../../assets/images/icons/no-result.png'
import { ReactComponent as ClientsIcon } from '../../assets/images/icons/clients.svg'
import { getUserPreferences } from '../../../actions/userPreferences'
import { UserPreferencesState } from '../../../reducers/userPreferences'
import { applyInstitutionFilterHelper } from '../../helpers/clientList/applyInstitutionFilter'
import { applyOfficeTeamsFilterHelper } from '../../helpers/clientList/applyOfficeTeamsFilter'
import { searchFilterHelper } from '../../helpers/clientList/searchFilter'
import { applyMoneyMindFilterHelper } from '../../helpers/clientList/applyMoneyMindFilter'
import { applyNetWorthErrorFilterHelper } from '../../helpers/clientList/applyNetWorthErrorFilter'
import { NetWorthErrorFilterType } from '../../../objects/clientFilter'
import { InstitutionNameIdMap } from '../../../objects/institutionNameIdMap'

interface HouseholdsProps {
  households: HouseholdsState
  contacts: ContactState
  institution: InstitutionObj
  institutions: InstitutionNameIdMap[]
  dispatch: Dispatch<GlobalState>
  selectedOfficeTeams: string[]
  selectedInstitutions: string[]
  selectedMoneyMinds: string[]
  showNetWorthErrors: NetWorthErrorFilterType
  userPreferences: UserPreferencesState
  userId: string
}

interface HouseholdsComponentState {
  search: string
  showFilterDropdown: boolean
  offset: number
}

const NUMBER_OF_NEW_ROWS = 20

const nullStateTextCopyObj: { title: string; copy: string; icon: string } = {
  title: 'No clients yet',
  copy:
    'You have no clients in Advisor Center at the moment. Once clients are imported by your system administrator, they will appear here.',
  icon: nullIcon
}
const noResultsTextCopyObj: { title: string; copy: string; icon: string } = {
  title: 'No Results',
  copy:
    "Try searching for the first name or last name only, and don't forget to spellcheck. In addition, please check all applied filters",
  icon: noResultsIcon
}

class Households extends React.Component<
  HouseholdsProps,
  HouseholdsComponentState
> {
  constructor(props: HouseholdsProps) {
    super(props)
    this.state = {
      search: '',
      showFilterDropdown: false,
      offset: 0
    }
  }
  public async componentDidMount() {
    const {
      dispatch,
      institutions,
      institution,
      selectedInstitutions
    } = this.props
    if (selectedInstitutions.length === 0 && institution.id) {
      this.resetFilters()
    }

    if (
      !institution.id &&
      selectedInstitutions.length === 0 &&
      institutions?.length > 0
    ) {
      dispatch(
        setClientListFilters([institutions?.[0]?.id], [], [], 'none checked')
      )
    }
    await dispatch(getUserPreferences())
    await dispatch(getOfficeTeams())
  }

  // Resets client filter when navigating away from component
  public async componentWillUnmount() {
    this.resetFilters()
  }

  public componentDidUpdate(prevProps: HouseholdsProps) {
    const {
      selectedInstitutions,
      institutions,
      institution,
      dispatch
    } = this.props

    if (
      !institution.id &&
      selectedInstitutions.length === 0 &&
      institutions?.length > 0
    ) {
      dispatch(
        setClientListFilters([institutions?.[0]?.id], [], [], 'none checked')
      )
    }
    if (
      selectedInstitutions.length === 0 &&
      prevProps.institution !== this.props.institution
    ) {
      this.resetFilters()
    }
  }

  public filteredHouseholds = () => {
    const {
      selectedInstitutions,
      selectedMoneyMinds,
      selectedOfficeTeams,
      showNetWorthErrors,
      households
    } = this.props
    const { search } = this.state
    return searchFilterHelper(
      search,
      applyNetWorthErrorFilterHelper(
        showNetWorthErrors,
        applyOfficeTeamsFilterHelper(
          selectedOfficeTeams,
          applyMoneyMindFilterHelper(
            selectedMoneyMinds,
            applyInstitutionFilterHelper(selectedInstitutions, households)
          )
        )
      )
    )
  }

  public householdList = (filteredHouseholds: HouseholdsState) => {
    const { contacts } = this.props
    const { offset } = this.state
    const sortedHouseholds = this.sortClientList(filteredHouseholds)
    return Object.keys(sortedHouseholds).map((key, index) => {
      const household = sortedHouseholds[key]
      const householdContacts = contacts[household.id]
      // We want to only render offset + NUMBER_OF_NEW_ROWS and then grow the list as you scroll down
      // This is in order to prevent the browser from lagging or crashing due to a high number of rows
      if (index <= offset + NUMBER_OF_NEW_ROWS) {
        return (
          <HouseholdRow
            key={household.id}
            id={household.id}
            name={household.name}
            contacts={householdContacts}
            fundedScore={household.latestFundedScore}
            ivProgress={household.ivProgress}
            palProgress={household.palProgress}
            goalsProgress={household.goalsProgress}
            netWorth={household.netWorth}
            hcProgress={household.hcProgress}
            hcProgress2={household.hcProgress2}
            mmProgress={household.mmProgress}
            taskCount={household.taskCount}
            lastLogin={household.lastLogin}
            hasNetworthAccountErrors={household.hasClientAccountErrors}
          />
        )
      }
      return null
    })
  }

  public updateFilter = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.currentTarget.value.toLowerCase() || '' })
  }

  public sortClientList = (households: HouseholdsState) => {
    const { userPreferences, userId } = this.props
    if (!userPreferences[userId]) {
      return this.sortByStringField(households, false, 'name')
    }
    const userPreference = userPreferences[userId]
    const { clientListSortBy, clientListSortOrder } = userPreference
    const descending = clientListSortOrder === 'Descending'
    switch (clientListSortBy) {
      case 'netWorth':
      case 'taskCount':
      case 'latestFundedScore':
        return this.sortByNumericField(households, descending, clientListSortBy)
      case 'progress':
        return this.sortByProgress(households, descending)
      default:
        return this.sortByStringField(households, descending, clientListSortBy)
    }
  }

  public sortByStringField = (
    households: HouseholdsState,
    sortByDescending: boolean,
    field: string
  ) => {
    const sortedHouseholds: HouseholdsState = {}
    let aField: string = field
    let bField: string = field
    Object.keys(households)
      .sort((a, b) => {
        switch (aField) {
          case 'hcProgress':
            aField = households[a].hcProgress2 ? 'hcProgress2' : 'hcProgress'
            break
          case 'palProgress':
            aField = households[a].goalsProgress
              ? 'goalsProgress'
              : 'palProgress'
        }
        switch (bField) {
          case 'hcProgress':
            bField = households[a].hcProgress2 ? 'hcProgress2' : 'hcProgress'
            break
          case 'palProgress':
            bField = households[a].goalsProgress
              ? 'goalsProgress'
              : 'palProgress'
        }
        if (sortByDescending) {
          return String(households[b][bField]).localeCompare(
            households[a][aField]
          )
        } else {
          return String(households[a][aField]).localeCompare(
            households[b][bField]
          )
        }
      })
      .forEach((key) => {
        sortedHouseholds[key] = households[key]
      })
    return sortedHouseholds
  }

  public sortByNumericField = (
    households: HouseholdsState,
    sortByDescending: boolean,
    field: string
  ) => {
    const sortedHouseholds: HouseholdsState = {}
    Object.keys(households)
      .sort((a, b) => {
        const aScore = households[a][field] || 0
        const bScore = households[b][field] || 0
        return sortByDescending ? bScore - aScore : aScore - bScore
      })
      .forEach((key) => {
        sortedHouseholds[key] = households[key]
      })
    return sortedHouseholds
  }

  public sortByProgress = (
    households: HouseholdsState,
    sortByDescending: boolean
  ) => {
    const sortedHouseholds: HouseholdsState = {}
    Object.keys(households)
      .sort((a, b) => {
        let aProgress = 0
        aProgress =
          households[a].latestFundedScore > 0 ? aProgress + 1 : aProgress
        aProgress = households[a].netWorth > 0 ? aProgress + 1 : aProgress
        aProgress =
          households[a].hcProgress2 === 'completed' ||
          households[a].hcProgress === 'completed'
            ? aProgress + 1
            : aProgress
        aProgress =
          households[a].goalsProgress === 'completed'
            ? aProgress + 1
            : aProgress
        aProgress =
          households[a].mmProgress === 'completed' ? aProgress + 1 : aProgress
        aProgress =
          households[a].ivProgress === 'completed' ? aProgress + 1 : aProgress
        aProgress = households[a].lastLogin ? aProgress + 1 : aProgress
        let bProgress = 0
        bProgress =
          households[b].latestFundedScore > 0 ? bProgress + 1 : bProgress
        bProgress = households[b].netWorth > 0 ? bProgress + 1 : bProgress
        bProgress =
          households[b].hcProgress2 === 'completed' ||
          households[b].hcProgress === 'completed'
            ? bProgress + 1
            : bProgress
        bProgress =
          households[b].goalsProgress === 'completed'
            ? bProgress + 1
            : bProgress
        bProgress =
          households[b].mmProgress === 'completed' ? bProgress + 1 : bProgress
        bProgress =
          households[b].ivProgress === 'completed' ? bProgress + 1 : bProgress
        bProgress = households[b].lastLogin ? bProgress + 1 : bProgress
        return sortByDescending ? bProgress - aProgress : aProgress - bProgress
      })
      .forEach((key) => {
        sortedHouseholds[key] = households[key]
      })
    return sortedHouseholds
  }

  public renderNoResultsDisplay = (
    filteredHouseholds: HouseholdsState
  ): JSX.Element => {
    const { households } = this.props
    const nullStateElement = (displayStateObj: {
      title: string
      copy: string
      icon: string
    }): JSX.Element => {
      return (
        <NullState
          style={{ height: 400, marginTop: 200 }}
          imgSrc={displayStateObj.icon}
          heading={displayStateObj.title}
          text={displayStateObj.copy}
        />
      )
    }

    if (Object.keys(households).length === 0) {
      return nullStateElement(nullStateTextCopyObj)
    }

    if (filteredHouseholds && Object.keys(filteredHouseholds).length === 0) {
      return nullStateElement(noResultsTextCopyObj)
    } else {
      return null
    }
  }

  public toggleFilterDropdown = () => {
    this.setState({ showFilterDropdown: !this.state.showFilterDropdown })
  }

  public handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { offset } = this.state
    const filteredHouseholds = this.filteredHouseholds()
    // leave a buffer for a smoother experience
    const BUFFER = 100
    const bottom =
      e.currentTarget.scrollHeight -
        Math.ceil(e.currentTarget.scrollTop + BUFFER) <=
      e.currentTarget.clientHeight
    if (bottom && offset < Object.keys(filteredHouseholds).length) {
      this.setState({ offset: offset + NUMBER_OF_NEW_ROWS })
    }
  }

  public resetFilters = () => {
    const { dispatch, institution } = this.props
    dispatch(setClientListFilters([institution.id], [], [], 'none checked'))
  }

  public onApply = () => {
    this.setState({ offset: 0, showFilterDropdown: false })
  }

  public render() {
    const { showFilterDropdown } = this.state
    const { institution } = this.props
    const filteredHouseholds = this.filteredHouseholds()
    const nullStateDisplay = this.renderNoResultsDisplay(filteredHouseholds)
    return (
      <InnerContainer>
        <div className='households-w household__body-content'>
          <div className='institution-filter__trigger-w'>
            <ContentHeader Icon={ClientsIcon} title='Clients' />
            <div className='institution-filter__trigger-header'>
              <img
                src={FilterIcon}
                alt='no-img'
                onClick={this.toggleFilterDropdown}
              />
              {showFilterDropdown ? (
                <div className='institution-filter__institution-dropdown'>
                  <ClientFilter
                    institution={institution}
                    toggleFilterDropdown={this.toggleFilterDropdown}
                    onApply={this.onApply}
                    resetFilters={this.resetFilters}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <Tile>
            <div className='households__search-w'>
              <Search onChange={this.updateFilter} value={this.state.search} />
            </div>
            <TableHeader>
              <HouseholdHeaderLabel title='NAME' sortingString='name' />
              <HouseholdHeaderLabel title='MM' sortingString='mmProgress' />
              <HouseholdHeaderLabel title='HC' sortingString='hcProgress' />
              <HouseholdHeaderLabel title='IV' sortingString='ivProgress' />
              <HouseholdHeaderLabel
                title={institution.version === '3' ? 'GOALS' : 'PAL'}
                sortingString='goalsProgress'
              />
              <HouseholdHeaderLabel
                title='NET WORTH'
                sortingString='netWorth'
              />
              <HouseholdHeaderLabel
                title='SCORE'
                sortingString='latestFundedScore'
              />
              <HouseholdHeaderLabel title='TASKS' sortingString='taskCount' />
              <HouseholdHeaderLabel
                title='LAST GC LOGIN'
                sortingString='lastLogin'
              />
              <HouseholdHeaderLabel title='PROGRESS' sortingString='progress' />
            </TableHeader>
            {nullStateDisplay !== null ? (
              nullStateDisplay
            ) : (
              <div className='households__list' onScroll={this.handleScroll}>
                {this.householdList(filteredHouseholds)}
              </div>
            )}
          </Tile>
          <Disclosure />
        </div>
      </InnerContainer>
    )
  }
}

export default connect((store: GlobalState) => {
  return {
    contacts: store.contact,
    households: store.households,
    institution: store.institution,
    institutions: store.clientFilter.institutions,
    selectedInstitutions: store.clientFilter.selectedInstitutions,
    selectedMoneyMinds: store.clientFilter.selectedMoneyMinds,
    selectedOfficeTeams: store.clientFilter.selectedOfficeTeams,
    showNetWorthErrors: store.clientFilter.showNetWorthErrors,
    userPreferences: store.userPreferences,
    userId: store.user.userId
  }
})(Households)
