import * as actions from '../actions/tasks'
import { TaskObj, TaskInterfaceV2 } from '../objects/tasks'

export interface TasksState {
  [key: string]: TaskInterfaceV2
}

export const initState: TasksState = {}

const mapCrmTask = (task: any): TaskObj => {
  return {
    id: task.crmTaskId,
    crmId: task.crmTaskId,
    crmSource: null,
    description: task.description,
    status: task.status,
    createdDate: null,
    completedDate: null,
    dueDate: null,
    subject: task.description,
    // all CRM tasks have assigneeType as 'user'
    assigneeType: 'user',
    assigneeNames: null,
    includeInGuidebook: task.showInGuidecenter,
    advisorAssignee: null,
    primaryAssignee: null,
    secondaryAssignee: null,
    isClosed: null,
    lastModifiedDate: null,
    isCrmTask: true,
    lastSynced: task.lastSynced
  }
}

// no id for these tasks, using crmTaskId as the id
const mapCrmTasks = (crmTasks: TaskObj[]) => {
  const TasksObj = {}
  crmTasks.forEach((crmTask) => {
    if (crmTask?.crmTaskId) {
      TasksObj[crmTask?.crmTaskId] = mapCrmTask(crmTask)
    }
  })
  return { TasksObj }
}

const CrmTasks = (state = initState, action: any) => {
  const { type, payload: { data = {} } = {} } = action || {}
  const newState = JSON.parse(JSON.stringify(state))

  // Set Default state when there is new houshold
  if (data?.householdId && !newState[data?.householdId]) {
    newState[data.householdId] = {
      sideBarState: false,
      selectedTaskId: null,
      viewMode: '',
      tasks: {},
      taskRequestId: '',
      activeTask: {}
    }
  }

  switch (type) {
    case `${actions.SET_TASKS}`:
      newState[data.householdId].tasks =
        mapCrmTasks(data.crmTasks.length ? data.crmTasks : []).TasksObj || {}
      newState[data.householdId].activeTask = newState[data.householdId]
        ?.sideBarState
        ? newState[data.householdId]?.activeTask
        : {}
      newState[data.householdId].viewMode = newState[data.householdId]
        ?.sideBarState
        ? newState[data.householdId]?.viewMode
        : ''
      return newState
    default:
      return newState
  }
}
export default CrmTasks
