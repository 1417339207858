import React from 'react'

import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import moment from 'moment'

export interface CustomDatepickerProps {
  dueDate: string
  className: string
  popperPlacement: ReactDatePickerProps['popperPlacement']
  dateSetter(dueDate: any): void
  dateFormat?: string[]
}

interface CustomDatepickerState {
  datePicked: any // moment || Date
  deletingKey: boolean
}

export class CustomDatepicker extends React.Component<
  CustomDatepickerProps,
  CustomDatepickerState
> {
  constructor(props: CustomDatepickerProps) {
    super(props)
    const { dueDate } = this.props
    this.state = {
      datePicked:
        dueDate && moment(dueDate).isValid() ? moment(dueDate).toDate() : null,
      deletingKey: false
    }
  }

  public digitCheck = (stringValue: string, max: number) => {
    let digit = stringValue
    // if the string is a day|month starting with 0 or 00
    if (digit.charAt(0) !== '0' || digit === '00') {
      let digitNum = parseInt(digit)
      //correct its string value to 01-09|10-12
      if (isNaN(digitNum) || digitNum <= 0 || digitNum > max) {
        digitNum = 1
      }
      digit =
        digitNum > parseInt(max.toString().charAt(0)) &&
        digitNum.toString().length === 1
          ? `0${digitNum}`
          : digitNum.toString()
    }
    return digit
  }

  /**
   *
   * @param e
   * Handle the raw value in the Datepicker
   */
  public rawChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { deletingKey } = this.state
    const { value } = e.currentTarget
    if (value && !deletingKey) {
      let inputValue = value
      if (/\D\/$/.test(inputValue))
        inputValue = inputValue.substr(0, inputValue.length - 3)
      const dateSegments = inputValue.split('/').map((segments: string) => {
        return segments.replace(/\D/g, '')
      })
      // do the live formatting
      if (dateSegments[0])
        dateSegments[0] = this.digitCheck(dateSegments[0], 12)
      if (dateSegments[1])
        dateSegments[1] = this.digitCheck(dateSegments[1], 31)

      if (dateSegments[2])
        dateSegments[2] = this.digitCheck(dateSegments[2], 9999)

      const newDateArray = dateSegments.map(
        (segment: string, index: number) => {
          return segment.length === 2 && index < 2 ? `${segment}/` : segment
        }
      )
      const dateInput = newDateArray
        .join('')
        .substr(0, 10)
        .replace(/\s+/g, '')
      // update the raw value of the Datepicker
      e.currentTarget.value = dateInput
    }
  }

  // check if deleting
  public keyedDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const deletingKey = ['backspace', 'delete'].includes(e.key.toLowerCase())
    this.setState({ deletingKey })
  }

  public dateSetter = (datePicked: Date) => {
    if (!datePicked) {
      this.setState({ datePicked: null })
      this.props.dateSetter(null)
    }

    if (datePicked) {
      const momentDateString = moment(datePicked).format('MM/DD/YYYY')
      if (momentDateString && momentDateString !== 'Invalid date') {
        this.setState({ datePicked })
        this.props.dateSetter(datePicked)
      }
    }
  }

  public render = () => {
    const { className, popperPlacement, dateFormat } = this.props
    const { datePicked } = this.state

    return (
      <DatePicker
        popperPlacement={popperPlacement}
        disabledKeyboardNavigation={true}
        className={className}
        placeholderText='MM/DD/YY'
        selected={datePicked}
        onKeyDown={this.keyedDown}
        onChange={this.dateSetter}
        onChangeRaw={this.rawChange}
        dateFormat={dateFormat}
      />
    )
  }
}

export default CustomDatepicker
