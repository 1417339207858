import React, { Component, FormEvent } from 'react'

interface ToggleSwitchProps {
  active: boolean
  disable?: boolean
  name?: string
  custom?: boolean
  handleToggleActive?(
    event?: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ): void
}

export class ToggleSwitch extends Component<ToggleSwitchProps> {
  public render() {
    const { disable, name, active, custom, handleToggleActive } = this.props

    const labelClassName = custom ? 'im-switch' : 'toggle-switch'
    const spanClassName = custom
      ? 'im-switch__slider im-switch__round'
      : 'toggle-switch__slider toggle-switch__round'
    return (
      <div>
        <label className={labelClassName}>
          <input
            disabled={disable}
            id='toggleInput'
            onChange={handleToggleActive}
            type='checkbox'
            name={name}
            aria-checked={active}
            checked={active}
          />
          <span id='toggle' className={spanClassName} />
        </label>
      </div>
    )
  }
}

export default ToggleSwitch
