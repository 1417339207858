import api from '../v3/helpers/api'
import { GuidebookObj, GuidebookPdfRequestObj } from '../objects/guidebook'
import { AxiosResponse } from 'axios'
export const FETCH_GUIDEBOOKS = 'FETCH_GUIDEBOOKS'
export const CREATE_GUIDEBOOK = 'CREATE_GUIDEBOOK'
export const DELETE_GUIDEBOOK = 'DELETE_GUIDEBOOK'
export const UPLOAD_GUIDEBOOK_PDF = 'UPLOAD_GUIDEBOOK_PDF'
export const DELETE_GUIDEBOOK_PDF = 'DELETE_GUIDEBOOK_PDF'
export const RENDER_GUIDEBOOK_HTML = 'RENDER_GUIDEBOOK_HTML'
export const FETCH_GUIDEBOOK_SECTIONS = 'FETCH_GUIDEBOOK_SECTIONS'

export const getGuidebooks = (householdFinId: string) => {
  return {
    type: FETCH_GUIDEBOOKS,
    payload: api()
      .get(`/households/${householdFinId}/guidebooks`)
      .then((response: AxiosResponse) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createGuidebook = (
  householdFinId: string,
  request: GuidebookObj
) => {
  return {
    type: CREATE_GUIDEBOOK,
    payload: api()
      .post(`/households/${householdFinId}/guidebooks`, request)
      .then((response: AxiosResponse) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const deleteGuidebook = (
  householdFinId: string,
  guidebookFinId: string
) => {
  return {
    type: DELETE_GUIDEBOOK,
    payload: api()
      .delete(`/households/${householdFinId}/guidebooks/${guidebookFinId}`)
      .then((response: AxiosResponse) => {
        response.data = { householdFinId, guidebookFinId }
        return response
      })
  }
}

export const uploadPdf = (
  householdFinId: string,
  guidebookFinId: string,
  request: GuidebookPdfRequestObj,
  uploadToDocVault: boolean = false
) => {
  return {
    type: UPLOAD_GUIDEBOOK_PDF,
    payload: api()
      .post(
        `/households/${householdFinId}/guidebookgenerator/pdf`,
        { guidebookFinId, request, uploadToDocVault },
        { timeout: 120000 }
      )
      .then((response: AxiosResponse) => {
        response.data = {
          pdfUrl: response.data.photoUrl,
          householdFinId,
          guidebookFinId
        }
        return response
      })
  }
}

export const deletePdf = (householdFinId: string, guidebookFinId: string) => {
  return {
    type: DELETE_GUIDEBOOK,
    payload: api()
      .delete(`/households/${householdFinId}/guidebooks/${guidebookFinId}/pdf`)
      .then((response: AxiosResponse) => {
        response.data = { householdFinId, guidebookFinId }
        return response
      })
  }
}
