import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg'
import CloudIcon from '../../assets/images/ic-uploadcloud.svg'
import WarningIcon from '../../assets/images/icons/png/warning-red.png'
import Dropzone, { ImageFile } from 'react-dropzone'
import Button from '../../components/button'

interface UploadFileModalProps {
  fileFormatError: boolean
  fileUploadPending: boolean
  closeModal(): void
  uploadFiles(files: any[]): void
}

class UploadFileModal extends React.Component<UploadFileModalProps> {
  private inputRef: HTMLInputElement = null
  public openInput = () => {
    this.inputRef.click()
  }

  public onDrop = (files: ImageFile[]) => {
    const { uploadFiles } = this.props
    uploadFiles(files)
  }

  public setInputRef = (ref: any) => {
    this.inputRef = ref
  }

  public getAndUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { uploadFiles } = this.props
    const files = Array.from(e.target.files)
    uploadFiles(files)
  }

  public fileFormatError = () => {
    const maxVaultFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
    return (
      <div className='file-modal__filename file-modal__filename--format'>
        <span>
          <img
            className='file-modal__filename--format-img'
            src={WarningIcon}
            alt='WarningIcon'
          />
        </span>
        {`Unable to upload. Please make sure the file is in the right format, and
        does not exceed ${maxVaultFileSize} MB in size.`}
      </div>
    )
  }

  public fileFormatPending = () => {
    return (
      <div className='file-modal__filename file-modal__filename--format file-modal__filename--format-pending'>
        Validating file...
      </div>
    )
  }

  public render() {
    const { closeModal, fileFormatError, fileUploadPending } = this.props
    const maxFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
    return (
      <Modal icon={UploadIcon} title='UPLOAD FILE' closeModal={closeModal}>
        <div className='file-modal__content'>
          {fileFormatError && this.fileFormatError()}
          {fileUploadPending && this.fileFormatPending()}
          <div className='file-modal__filename'>
            {`File names should not include spaces, exceed 76 characters in
            length, or use symbols other than underscores. Maximum file size limit is ${maxFileSize}mb`}
          </div>
          <Dropzone onDrop={this.onDrop} className='file-modal__dropzone'>
            <img
              src={CloudIcon}
              alt=''
              role='presentation'
              style={{ width: 96, height: 96 }}
            />
            Drag & Drop Files
            <div className='file-modal__restrictions'>
              Accepted formats: Documents (pdf, docx, doc, txt). Spreadsheets
              <br />
              (csv, xls, xlsx), Images (jpg, jpeg, png, bmp, gif, tif),
              <br />
              Presentations (ppt, pptx) and Archives (zip)
              <br />
            </div>
          </Dropzone>
          <div className='file-modal__divider'>
            <div className='file-modal__divider--line' />
            <div className='file-modal__divider--text'>or</div>
            <div className='file-modal__divider--line' />
          </div>
          <div className='file-modal__btn-w'>
            <input
              type='file'
              onChange={this.getAndUploadFile}
              style={{ display: 'none' }}
              ref={this.setInputRef}
            />
            <Button
              primary={true}
              style={{
                width: 200,
                margin: '0 auto',
                justifyContent: 'center'
              }}
              onClick={this.openInput}>
              Browse File
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default UploadFileModal
