import React, { Component } from 'react'
import moment from 'moment'

// import PDF from 'react-pdf-js';
import coverMainImage from '../../../../assets/images/guidebook/guidebook-cover-main.png'
import { UserObj } from '../../../../../objects/user'

interface CoverPageProps {
  coverPageInfo: {
    title: string
    selectedAdvisor: UserObj
    clientName: string
    logo: string | null
  }
}

class CoverPage extends Component<CoverPageProps> {
  public render() {
    const {
      logo,
      title,
      clientName,
      selectedAdvisor
    } = this.props.coverPageInfo

    return (
      <div className='gb-cover' id='cover'>
        <div className='gb-cover__logo-w'>
          {logo && (
            <img
              className='gb-cover__logo--UC-logo'
              src={`data:image/png;base64,${logo}`}
              alt='logo'
            />
          )}
        </div>
        <h1 className='gb-cover__title'>
          {title}
          <span>
            {clientName} {clientName ? ' | ' : null}{' '}
            {moment(Date()).format('MMMM YYYY')}
          </span>
        </h1>
        <div className='gb-cover__cover-img'>
          <img src={coverMainImage} alt='Cover page image' />
        </div>
        <div className='gb-cover__footer'>
          <h3>
            {selectedAdvisor
              ? `${selectedAdvisor.firstName} ${selectedAdvisor.lastName}`
              : ''}
            {selectedAdvisor && selectedAdvisor.title
              ? ` | ${selectedAdvisor.title}`
              : ' '}
          </h3>
          {selectedAdvisor && selectedAdvisor.street ? (
            <span>
              {selectedAdvisor.street}
              <br />
              {selectedAdvisor.city}, {selectedAdvisor.state}{' '}
              {selectedAdvisor.zip}
            </span>
          ) : null}

          {selectedAdvisor && selectedAdvisor.work ? (
            <span>{selectedAdvisor.work}</span>
          ) : null}
        </div>
      </div>
    )
  }
}

export default CoverPage
