import * as actions from '../actions/user'
import {
  UsersObj,
  UserObj,
  UserInterface,
  CRMHouseholdsObj,
  CRMHouseholdsInterface
} from '../objects/user'

export const initState: UserInterface = {
  users: {},
  userId: '',
  v2ACL: false,
  officeAdmin: false,
  accessToken: null,
  photoUploadSuccess: false,
  photoUploadPending: false,
  photoUploadError: false,
  communityId: '',
  termsOfServiceLoading: true,
  termsOfServiceAccepted: false,
  fetchHouseholdsFailed: false,
  fetchHouseholdsSuccessful: false,
  crmLoginFailed: false,
  crmHouseholds: {},
  crmLinked: true,
  showLinkCRMModal: false,
  version: '1'
}

const mapUser = (user: any): UserObj => {
  if (user) {
    return {
      id: user.id,
      mgpId: user.mgpId,
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      photo: user.photoUrl ? user.photoUrl : '',
      fileName: user.photoFilename ? user.photoFilename : '',
      email: user.email,
      mobile: user.phone ? user.phone.mobile : '',
      home: user.phone ? user.phone.phone : '',
      work: user.work ? user.phone.work : '',
      street: user.address ? user.address.street : '',
      city: user.address ? user.address.city : '',
      state: user.address ? user.address.state : '',
      zip: user.address ? user.address.postalCode : '',
      kerberosId: user.kerberosId || ''
    }
  } else return null
}

const mapCRMHouseholds = (crmHouseholds: CRMHouseholdsObj[]) => {
  const obj: CRMHouseholdsInterface = {}
  crmHouseholds.forEach((crmHousehold) => {
    obj[crmHousehold.id] = crmHousehold
  })
  return obj
}

const mapUsers = (users: any): UsersObj => {
  if (users?.length) {
    const obj = {}
    users?.forEach((user: any) => {
      obj[user?.id] = mapUser(user)
    })
    return obj
  } else return {}
}

// tslint:disable-next-line:max-func-body-length
const User = (state = initState, action: any): UserInterface => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_USER_FULFILLED:
      const users = mapUsers(action.payload.data.users)
      const officeAdmin = action.payload.data.officeAdmin === 'true'
      return {
        ...state,
        users,
        userId: action.payload.data.userId,
        v2ACL: action.payload.data.v2ACL,
        // Set show LinkCRMModal to the value of v2ACL initially.
        // This allows us to have it default on if the user hasn't
        // linked their CRM before and toggle it on for v1 ACL users
        showLinkCRMModal: action.payload.data.v2ACL,
        officeAdmin
      }
    case actions.UPDATE_USER_FULFILLED:
      if (!state.users[action.payload.data.id]) {
        newState.users[action.payload.data.id] = {}
      }
      newState.users[action.payload.data.id] = {
        ...mapUser(action.payload.data),
        photo: newState.users[action.payload.data.id].photo
      }
      return newState
    case actions.NEW_PHOTO_PENDING:
      return {
        ...state,
        photoUploadError: false,
        photoUploadPending: true,
        photoUploadSuccess: false
      }
    case actions.NEW_PHOTO_FULFILLED:
      if (!state.users[action.payload.data.id]) {
        newState.users[action.payload.data.id] = {}
      }
      newState.users[action.payload.data.id] = {
        ...newState.users[action.payload.data.id],
        photo: action.payload.data.data.photoUrl
      }
      return {
        ...newState,
        photoUploadError: false,
        photoUploadPending: false,
        photoUploadSuccess: true
      }
    case actions.NEW_PHOTO_REJECTED:
      return {
        ...state,
        photoUploadError: true,
        photoUploadPending: false,
        photoUploadSuccess: false
      }
    case actions.DELETE_PHOTO_FULFILLED:
      if (!state.users[action.payload.data.id]) {
        newState.users[action.payload.data.id] = {}
      }
      newState.users[action.payload.data.id] = {
        ...newState.users[action.payload.data.id],
        photo: null
      }
      return {
        ...newState,
        photoUploadError: false,
        photoUploadPending: false,
        photoUploadSuccess: true
      }
    case actions.STORE_COMMUNITY_ID:
      return { ...state, communityId: action.payload }
    case actions.FETCH_AUTHENTICATION_TOKENS_FULFILLED:
      return { ...state, accessToken: action.payload.data.accessToken }
    case actions.SET_ACCESS_TOKEN:
      localStorage.setItem('accessToken', action.payload)
      return { ...state, accessToken: action.payload }
    case `${actions.FETCH_TOS_AND_PRIVACY_POLICY}_PENDING`:
      return {
        ...newState,
        termsOfServiceLoading: true
      }
    case `${actions.FETCH_TOS_AND_PRIVACY_POLICY}_FULFILLED`:
      const termsOfServiceAccepted = action.payload.data.termsOfServiceAccepted
        ? action.payload.data.termsOfServiceAccepted
        : false
      return {
        ...newState,
        termsOfServiceAccepted,
        termsOfServiceLoading: false
      }
    case actions.ACCEPT_TOS_AND_PRIVACY_POLICY_FULFILLED:
      return { ...newState, termsOfServiceAccepted: true }
    case `${actions.PREVIEW_HOUSEHOLDS_SYNC}_REJECTED`:
      if (
        action.payload.data &&
        action.payload.data.meta &&
        action.payload.data.meta.status === 403
      ) {
        return { ...newState, fetchHouseholdsFailed: true }
      } else {
        return {
          ...newState,
          fetchHouseholdsFailed: false,
          fetchHouseholdsSuccessful: false
        }
      }
    case `${actions.SYNC_USER_HOUSEHOLDS}_FULFILLED`:
      return {
        ...newState
      }
    case `${actions.SYNC_USER_HOUSEHOLDS}_REJECTED`:
      return {
        ...newState
      }
    case `${actions.PREVIEW_HOUSEHOLDS_SYNC}_FULFILLED`:
      return {
        ...newState,
        fetchHouseholdsFailed: false,
        fetchHouseholdsSuccessful: true,
        crmHouseholds: mapCRMHouseholds(action.payload.data.data)
      }
    case `${actions.REDTAIL_LOGIN}_FULFILLED`:
      return {
        ...newState,
        crmLoginFailed: false,
        fetchHouseholdsFailed: false
      }
    case `${actions.REDTAIL_LOGIN}_REJECTED`:
      return { ...newState, crmLoginFailed: true }
    case actions.REQUEST_CRM_LINK:
      // Adding a new variable crmLinked instead of using
      // the existing crmLoginFailed to prevent issues
      // where we ask for the user's login twice when doing
      // a crm sync. Will eventually get rid of this or
      // convert 100% once migration is complete
      return { ...newState, crmLinked: false }
    case actions.SHOW_LINK_CRM_MODAL:
      return { ...newState, showLinkCRMModal: action.payload }
    case actions.REMOVE_HOUSEHOLDS_FROM_SYNCED_HOUSEHOLD_LIST:
      action &&
        action.payload &&
        action.payload.map((id: string) => {
          if (newState.crmHouseholds && newState.crmHouseholds[id]) {
            newState.crmHouseholds[id] = {
              ...newState.crmHouseholds[id],
              _hasSynced: false
            }
          }
        })
      return newState
    default:
      return state
  }
}

export default User
