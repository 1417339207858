import React from 'react'
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg'
import TableRow from '../../components/layout/tableRow'

interface PendingFileProps {
  name: string
  uploadProgress: number
}

class PendingFile extends React.Component<PendingFileProps> {
  public uploadBar = () => {
    const { uploadProgress } = this.props
    return (
      <div className='file__download-w'>
        <div>Uploading...</div>
        <div className='file__download-bar'>
          <div
            className='file__download-bar--active'
            style={{ width: `${uploadProgress}%` }}
          />
        </div>
      </div>
    )
  }
  public render() {
    const { name } = this.props
    return (
      <TableRow>
        <div className='folder__column--name'>
          <div className='folder__icon--w'>
            <FileIcon className='folder__icon' />
          </div>
          <div>{name}</div>
        </div>
        {this.uploadBar()}
      </TableRow>
    )
  }
}

export default PendingFile
