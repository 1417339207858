import React, { Component } from 'react'
import { InsuranceInterface, insuranceTypes } from '../../../objects/insurance'
import InGuideBookSwitch from './InGuideBookSwitch'
import Tile from '../../components/layout/tile'
import TableRow from '../../components/layout/tableRow'
import { dollarsOrBlankSpace } from '../../helpers'
import InsuranceUpdate from './insuranceUpdate'
import Tooltip from '../../components/tooltip'
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg'
import { staticText } from '../../helpers/resources'

interface DisabilityInsuranceRowProps {
  insurances: InsuranceInterface
}

export class DisabilityInsuranceRow extends Component<
  DisabilityInsuranceRowProps
> {
  public yearsFormat = (duration: number) => {
    if (!duration) {
      return null
    } else if (duration === 1) {
      return duration + ' year'
    } else {
      return duration + ' years'
    }
  }
  public eliminationPeriodFix = (timePeriod: any) => {
    if (timePeriod === 'null') {
      return null
    } else {
      return timePeriod
    }
  }

  public renderData = () => {
    return Object.keys(this.props.insurances).map((key, i) => {
      const insurance = this.props.insurances[key]
      if (insurance.recordTypeName === insuranceTypes.disability) {
        return (
          <div key={key} className='insurance__row insurance__col-w'>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.benefit.policyNumber === 'null'
                ? ''
                : insurance.benefit.policyNumber}
            </span>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.insuredName === 'null' ? '' : insurance.insuredName}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.benefitPeriodYears &&
              insurance.benefit.benefitPeriodYears.toString() === 'null'
                ? ''
                : this.yearsFormat(insurance.benefit.benefitPeriodYears)}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.monthlyBenefitAmount &&
              insurance.benefit.monthlyBenefitAmount.toString() === 'null'
                ? ''
                : dollarsOrBlankSpace(insurance.benefit.monthlyBenefitAmount)}
            </span>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.benefit.eliminationPeriod &&
              insurance.benefit.eliminationPeriod.toString() === 'null'
                ? ''
                : this.eliminationPeriodFix(
                    insurance.benefit.eliminationPeriod
                  )}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.annualPremium &&
              insurance.benefit.annualPremium.toString() === 'null'
                ? ''
                : dollarsOrBlankSpace(insurance.benefit.annualPremium)}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance && insurance.name ? insurance.name : ''}
            </span>
            <span className='insurance__col-item insurance__col-item--small insurance__toggle-pdf'>
              <InGuideBookSwitch
                inGuidebook={insurance.inGuidebook}
                id={insurance.id}
                householdFinId={insurance.household}
              />
            </span>
            <span className='insurance__edit insurance__col-item insurance__col-item--menu-toggle'>
              <InsuranceUpdate insurance={insurance} />
            </span>
          </div>
        )
      } else {
        return null
      }
    })
  }

  public render() {
    if (
      Object.keys(this.props.insurances).filter(
        (key) =>
          this.props.insurances[key].recordTypeName ===
          insuranceTypes.disability
      ).length > 0
    ) {
      return (
        <div className='insurance-w'>
          <Tile
            leftHeader={'DISABILITY'}
            headerBorder={true}
            rightHeader={null}>
            <TableRow backgroundColor='#F5F5F5'>
              <div className='insurance__col-w'>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>Policy Number</label>
                </span>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>Insured</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>Duration</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Monthly <span>Benefit</span>
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>Elimination Period</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>Annual Premium</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Company <br />
                    Name
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small insurance__toggle-pdf'>
                  <label>Show/Hide</label>
                  <Tooltip
                    message={staticText.includeInGc}
                    width={160}
                    position='bottom'
                    multiLine={true}>
                    <HelpIcon />
                  </Tooltip>
                </span>
                <span className='insurance__edit insurance__col-item insurance__col-item--menu-toggle' />
              </div>
            </TableRow>
            {this.renderData()}
          </Tile>
        </div>
      )
    } else {
      return null
    }
  }
}

export default DisabilityInsuranceRow
