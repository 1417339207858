import React, { Component } from 'react'
// import HcCardModal from './hcCardModal'
import ToggleSwitch from '../../toggleSwitch'
import HappinessIcon from '../../../assets/images/icons/png/hc_happiness.png'
import CommitmentIcon from '../../../assets/images/icons/png/hc_commitment.png'
import ProtectionIcon from '../../../assets/images/icons/png/hc_protection.png'

import { CardObj } from '../../../../objects/HonestConversations/card'
import { HouseholdCardObjInterface } from '../../../../objects/honestConversations'
interface suggestedGoalCardsProps {
  cards?: CardObj[]
  showCardView?: boolean
  householdCards?: HouseholdCardObjInterface
  showIcon?: boolean
  showDivider?: boolean
  iconImg?: string
  imgWidth?: string
  imgHeight?: string
  showTitleIcon?: boolean
  iconAction?(id: string): void
  rowAction?(card: CardObj): void
  toggleAction?(id: string): void
  classnames?: string
  showAsLink?: boolean
  rowClickEnabled?: boolean
  view?: number
}

interface suggestedGoalCardsState {
  showHideToggle: boolean
}

export class suggestedGoalCards extends Component<
  suggestedGoalCardsProps,
  suggestedGoalCardsState
> {
  constructor(props: suggestedGoalCardsProps) {
    super(props)
    this.state = {
      showHideToggle: true
    }
  }

  public onRowClick = (card: CardObj) => {
    const {
      showIcon,
      iconAction,
      rowAction,
      rowClickEnabled = true
    } = this.props
    if (rowClickEnabled) {
      if (showIcon && iconAction) {
        iconAction(card.id)
      } else {
        rowAction && rowAction(card as CardObj)
      }
    }
  }

  public onIconClick = (card: CardObj) => {
    const { showIcon, iconAction, rowClickEnabled = true } = this.props
    if (!rowClickEnabled) {
      if (showIcon && iconAction) {
        iconAction(card.id)
      }
    }
  }

  public renderCardButton = (card: CardObj) => {
    const {
      showIcon,
      iconImg,
      imgWidth = 'auto',
      imgHeight = 'auto',
      classnames = '',
      view,
      toggleAction
    } = this.props
    if (showIcon) {
      return (
        <span
          onClick={() => this.onIconClick(card)}
          className={`${classnames}cards-item-body`}>
          <img src={iconImg} width={imgWidth} height={imgHeight} alt='delete' />
        </span>
      )
    } else if (view === 2) {
      return (
        <ToggleSwitch
          active={card?.showCard}
          handleToggleActive={() => toggleAction(card.id)}
        />
      )
    } else return null
  }

  public cardType = (card: CardObj, index: number) => {
    let imageTypeSrc
    switch (card?.category) {
      case 'Happiness':
        imageTypeSrc = HappinessIcon
        break
      case 'Protection':
        imageTypeSrc = ProtectionIcon
        break
      case 'Commitment':
        imageTypeSrc = CommitmentIcon
    }
    const {
      showCardView = false,
      showTitleIcon = true,
      showDivider = false,
      classnames = '',
      showAsLink = false
    } = this.props
    return (
      <span
        className={`${classnames}cards-item${showCardView ? '-block' : ''} ${
          showDivider ? `${classnames}cards-item--border` : ''
        } ${showAsLink ? `${classnames}cards-item--pointer` : ''}`}
        style={{ left: `${index * 10}px`, zIndex: 10 - index }}
        key={index}
        onClick={() => this.onRowClick(card)}>
        <span className={`${classnames}cards-item-content`}>
          {showTitleIcon ? (
            <img src={imageTypeSrc} alt='HC-card-catagory' />
          ) : null}
          {showCardView ? <span>{card.title}</span> : null}
        </span>
        {this.renderCardButton(card)}
      </span>
    )
  }

  public displayCardTypes = (relaventCards: CardObj[]) => {
    const { classnames = '' } = this.props
    return (
      <div className={`${classnames}goal-card`}>
        <div className={`${classnames}cards`}>
          {relaventCards.map((card: CardObj, index: number) => {
            return this.cardType(card, index)
          })}
        </div>
      </div>
    )
  }
  public render() {
    const { cards, householdCards = {} } = this.props
    const HHkeys = Object.keys(householdCards)
    const relaventCards: CardObj[] = []
    if (HHkeys.length) {
      const palCardTitleArray =
        cards && Object.keys(cards).map((key) => cards[key].title.toLowerCase())
      HHkeys.forEach((key) => {
        const card = householdCards[key]
        const indexOfCard = palCardTitleArray.indexOf(
          card.card.title.toLowerCase()
        )
        if (card?.card?.title && palCardTitleArray && indexOfCard !== -1) {
          relaventCards.push(cards[indexOfCard])
        }
      })
    } else {
      relaventCards.splice(relaventCards.length, 0, ...cards)
    }

    return this.displayCardTypes(relaventCards)
  }
}

export default suggestedGoalCards
