import React from 'react'
import nullTasks from '../../assets/images/icons/png/null-tasks.png'
import noResultsIcon from '../../assets/images/icons/no-result.png'
import { ETaskView } from '../../../objects/tasks'

interface TaskNullProps {
  isTasksEmpty?: boolean
  hasNoFilterResult?: boolean
  taskView: ETaskView
}

const TaskNull = (props: TaskNullProps) => {
  const { isTasksEmpty, taskView, hasNoFilterResult } = props
  const taskNullRow = () => {
    return (
      <div className='task__null-row'>
        <div className='task__grid-col-left'>
          <div className='task__null-desc' />
        </div>
        <div className='task__grid-col-left'>
          <div className='task__null-status' />
        </div>
        <div className='task__grid-col-left'>
          <div className='task__null-date' />
        </div>
        <div className='task__grid-col-left'>
          <div className='task__null-assigned' />
        </div>
        <div className='task__grid-col-center'>
          <div className='task__null-show' />
        </div>
      </div>
    )
  }

  const taskNullText = () => {
    const optionalText =
      taskView === ETaskView.open
        ? 'add'
        : taskView === ETaskView.inProgress
        ? 'start'
        : 'complete'
    return (
      <div className='task__null-text'>
        <img src={nullTasks} role='presentation' alt='' />
        <h3>No tasks yet</h3>
        <p>As soon as you {optionalText} a task, it will appear here.</p>
      </div>
    )
  }

  const taskSearchNotFoundText = () => {
    return (
      <div className='task__null-search-text'>
        <img
          src={noResultsIcon}
          role='presentation'
          alt=''
          className='null-state__img'
        />
        <h3>There are no search results for this query.</h3>
        <span>
          Try searching for something more inclusive and don't forget to
          spellcheck.
        </span>
      </div>
    )
  }

  const taskFilterResultNotFoundText = () => {
    return (
      <div className='task__null-search-text'>
        <img
          src={noResultsIcon}
          role='presentation'
          alt=''
          className='null-state__img'
        />
        <h3>There are no results for this query.</h3>
        <span>Try updating the filters</span>
      </div>
    )
  }

  const taskText = isTasksEmpty
    ? taskNullText()
    : hasNoFilterResult
    ? taskFilterResultNotFoundText()
    : taskSearchNotFoundText()
  return (
    <div className='task__null-w'>
      {taskNullRow()}
      {taskNullRow()}
      {taskNullRow()}
      {taskNullRow()}
      {taskNullRow()}
      {taskText}
    </div>
  )
}

export default TaskNull
