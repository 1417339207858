import React, { Component } from 'react'

interface PageHeadingProps {
  number?: string
  text: string
}
class PageHeading extends Component<PageHeadingProps> {
  render() {
    const { number, text } = this.props
    return (
      <div className='hc-page-header__w'>
        {number && <span className='hc-page-header__number'>{number}</span>}
        <p className='hc-page-header__text'>{text}</p>
      </div>
    )
  }
}

export default PageHeading
