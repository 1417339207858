import defaultAvatarIcon from '../../../../assets/images/icons/general/avatar-gray.png'
import React, { Component, CSSProperties, Fragment, ReactNode } from 'react'
import { TaskDescriptionItem } from './ResultsSummaryComponents'
import { CardObj } from '../../../../../objects/HonestConversations/card'
import { LifeActionObj } from '../../../../../objects/lifeActions'

interface TaskDisplayProps {
  index: number
  card: CardObj
  lifeActions: LifeActionObj[]
  contactAvatars: {
    id: string
    source: string
    firstName: string
    lastName: string
    isPrimary: boolean
  }[]
  modalFunc?(card: CardObj): void
}

interface TaskDisplayState {
  listOfActions: ReactNode[]
}

const actionTaskStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '10px'
}

class TaskDisplay extends Component<TaskDisplayProps, TaskDisplayState> {
  constructor(props: TaskDisplayProps) {
    super(props)
    this.state = {
      listOfActions: []
    }
  }

  public componentDidMount = () => {
    const newState = this.state
    const { lifeActions } = this.props

    if (lifeActions && lifeActions.length > 0) {
      for (
        let counter: number = 0;
        counter < lifeActions.length;
        counter += 1
      ) {
        newState.listOfActions.push(this.getTaskDisplay(counter))
      }
    }
    this.setState(newState)
  }

  public displayTasksModal = () => {
    const { card, modalFunc } = this.props
    const { listOfActions } = this.state

    const taskCard = { ...card, tasks: listOfActions }
    modalFunc && modalFunc(taskCard)
  }

  public getContactIcon = (
    hasIcon: boolean,
    name: string,
    style: CSSProperties
  ): ReactNode => {
    return (
      <div className='results-summary__contact-avatar' style={style}>
        {hasIcon ? <img src={defaultAvatarIcon} alt='contact avatar' /> : name}
      </div>
    )
  }

  public getTaskDisplay = (index: number): ReactNode => {
    const { contactAvatars, lifeActions } = this.props

    const taskName =
      lifeActions && lifeActions[index] && lifeActions[index].name
        ? lifeActions[index].name
        : ''

    const taskAvatars: {
      source: string
      firstName: string
      lastName: string
      isPrimary: boolean
    }[] = []
    if (contactAvatars && lifeActions && lifeActions[index]) {
      if (
        contactAvatars[0] &&
        contactAvatars[0].id &&
        lifeActions[index].assigneePrimaryId &&
        contactAvatars[0].id === lifeActions[index].assigneePrimaryId
      ) {
        taskAvatars.push(contactAvatars[0])
      }
      if (
        contactAvatars[1] &&
        contactAvatars[1].id &&
        lifeActions[index].assigneeSecondaryId &&
        contactAvatars[1].id === lifeActions[index].assigneeSecondaryId
      ) {
        taskAvatars.push(contactAvatars[1])
      }
    }

    return (
      <Fragment key={index}>
        <TaskDescriptionItem
          index={index}
          longDescription={taskName}
          avatars={taskAvatars}
        />
      </Fragment>
    )
  }

  public renderAllTasksForPrint = () => {
    const { listOfActions } = this.state
    const { index } = this.props
    const borderTopStyle = index > 0 ? 'solid 1px rgba(200, 200, 200, 0.4)' : ''
    return (
      <div
        style={{ ...actionTaskStyle, borderTop: borderTopStyle }}
        className='results-summary__task-w'>
        {listOfActions.map((task) => {
          return task
        })}
      </div>
    )
  }

  public render() {
    const { index } = this.props
    const { listOfActions } = this.state
    const borderTopStyle = index > 0 ? 'solid 1px rgba(200, 200, 200, 0.4)' : ''

    const firstTask: ReactNode =
      listOfActions && listOfActions[0] ? listOfActions[0] : null
    const secondTask: ReactNode =
      listOfActions && listOfActions[1] ? listOfActions[1] : null

    return (
      <Fragment>
        <div
          style={{ ...actionTaskStyle, borderTop: borderTopStyle }}
          className='results-summary__task-w results-summary__for-web'>
          {firstTask}
          {secondTask}

          {listOfActions.length > 2 ? (
            <div
              className='results-summary__task-see-all'
              onClick={this.displayTasksModal}>
              See All
            </div>
          ) : null}
        </div>
        <div className='results-summary__for-pdf'>
          {this.renderAllTasksForPrint()}
        </div>
      </Fragment>
    )
  }
}

export default TaskDisplay
