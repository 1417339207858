import React, { Fragment } from 'react'
import { FundedScoreObj } from '../../../objects/financialGoal'
import { connect, Dispatch } from 'react-redux'
import * as actions from '../../../actions/financialGoals'
import { GlobalState } from '../../../reducers'
import { addToast } from '../../../actions/toasts'

import EditIcon from '../../assets/images/icons/general/edit-blue.png'
import EditRecentGoalComment from './editRecentGoalComment'

interface RecentGoalCommentProps {
  dispatch: Dispatch<GlobalState>
  latestFundedScore: FundedScoreObj
  householdFinId: string
}

interface RecentGoalCommentState {
  showModal: boolean
}

class RecentGoalComment extends React.Component<
  RecentGoalCommentProps,
  RecentGoalCommentState
> {
  constructor(props: RecentGoalCommentProps) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  public returnLatestGoal = (): string => {
    const { latestFundedScore } = this.props
    return latestFundedScore && latestFundedScore.notes
  }

  public toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  public saveComment = async (notes: string | null) => {
    const { householdFinId, latestFundedScore } = this.props
    await this.props.dispatch(
      actions.updatedFundedScore(
        latestFundedScore.id,
        { ...latestFundedScore, notes },
        householdFinId
      )
    )

    this.props.dispatch(
      addToast({
        message: 'Comment updated.',
        backgroundColor: '#D9F2B6'
      })
    )
    this.toggleModal()
  }

  public render() {
    const { showModal } = this.state
    const recentFinGoalNote: string = this.returnLatestGoal()
    const { latestFundedScore } = this.props
    return (
      <Fragment>
        {
          <div className='funded-score-recent__comment pdf-exclude'>
            <div>
              Notes for client
              {latestFundedScore && latestFundedScore?.id ? (
                <span className='funded-score-recent__edit-img'>
                  <img
                    src={EditIcon}
                    alt=''
                    role='presentation'
                    onClick={this.toggleModal}
                  />
                </span>
              ) : null}
            </div>
            <div className='funded-score-recent__comment-content'>
              <pre>{recentFinGoalNote}</pre>
            </div>
          </div>
        }

        {showModal ? (
          <EditRecentGoalComment
            saveFunc={this.saveComment}
            closeModal={this.toggleModal}
            recentGoalComment={recentFinGoalNote}
          />
        ) : null}
      </Fragment>
    )
  }
}

export default connect()(RecentGoalComment)
