import { InvestmentViewfinderAnswerObj } from '../../objects/investmentViewfinder'

export const getPriorityChanges = (answer: InvestmentViewfinderAnswerObj) => {
  if (answer) {
    const values = answer.value.split(',')
    return {
      taxes: Number(values[0]),
      performance: Number(values[1]),
      protection: Number(values[2]),
      cost: Number(values[3])
    }
  } else {
    return {
      performance: 0,
      protection: 0,
      taxes: 0,
      cost: 0
    }
  }
}

export const sliderMapValue = (sliderValue: any) => {
  const sliderValues = {
    0: 'Strongly Agree',
    2: 'Agree',
    4: 'Disagree',
    6: 'Strongly Disagree'
  }

  const mapValue = isNaN(sliderValue)
    ? Object.keys(sliderValues).find((key) => sliderValues[key] === sliderValue)
    : sliderValues[sliderValue]

  return mapValue || null
}
