import React from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import uuid from 'uuid'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion'

import { isObjEmpty } from '../../../helpers'
import { GlobalState } from '../../../../reducers'
import {
  createPortfolioBuilderAccount,
  updatePortfolioBuilderAccount,
  deletePortfolioBuilderAccount,
  getPortfolioBuilderAccounts
} from '../../../../actions/portfolioBuilder'
import {
  PortfolioBuilderAccountObj,
  PortfolioBuilderAccountInterface,
  PortfolioBuilderObj
} from '../../../../objects/portfolioBuilder'
import AccountModal from '../accountModal'
import ErrorComponent from './errorComponent'
import AccountList from './accountList'
import CombineAccountModal from './combine/combineAccountModal'

import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'

const uuidv4 = uuid.v4

interface ProposedWorkProps {
  dispatch?: Dispatch<GlobalState>
  householdFinId: string
  portfolioBuilderData: PortfolioBuilderObj
  portfolioBuilderId: string
  proposedAccount: PortfolioBuilderAccountObj
  selectedAccount: any
}
interface ProposedWorkState {
  openAdd: boolean
  openEdit: boolean
  filteredAccounts: PortfolioBuilderAccountObj[]
  stratDetails: PortfolioBuilderAccountInterface
  split: boolean
  combine: boolean
  errStatus: 0 | 1 | 2 | 3 | 4
  stratError: 0 | 4
  editStatus: boolean
  openCombine: boolean
  selectedAccount: PortfolioBuilderAccountObj | any
}

interface StratDetails {
  [key: string]: PortfolioBuilderAccountObj[]
}

class ProposedWork extends React.Component<
  ProposedWorkProps,
  ProposedWorkState
> {
  constructor(props: ProposedWorkProps) {
    super(props)
    this.state = {
      filteredAccounts: [],
      openAdd: false,
      openEdit: false,
      stratDetails: {},
      split: false,
      combine: false,
      errStatus: 0,
      stratError: 0,
      editStatus: false,
      selectedAccount: null,
      openCombine: false
    }
  }

  public componentDidMount() {
    const { portfolioBuilderData } = this.props
    if (
      portfolioBuilderData &&
      portfolioBuilderData.currentAccounts &&
      portfolioBuilderData.proposedAccounts
    ) {
      this.generateAccounts(portfolioBuilderData)
    }
  }

  public componentDidUpdate(prevProps: ProposedWorkProps) {
    const { portfolioBuilderData } = this.props
    if (
      prevProps.portfolioBuilderData &&
      portfolioBuilderData &&
      prevProps.portfolioBuilderData !== portfolioBuilderData &&
      portfolioBuilderData.currentAccounts &&
      portfolioBuilderData.proposedAccounts
    ) {
      this.generateAccounts(portfolioBuilderData)
    }
  }

  public unavailableStrategyError = (stratDetails: StratDetails) => {
    this.setState({ stratError: 0 })
    if (!stratDetails) return

    Object.values(stratDetails).forEach(
      (account: PortfolioBuilderAccountObj[]) => {
        account.forEach((sub) => {
          if (sub.unavailableStrategy) {
            this.setState({ stratError: 4 })
          }
        })
      }
    )
  }

  public onErrorSelect = (value: 0 | 1 | 2) => {
    this.setState({ errStatus: value })
  }

  public closeModal = (): void => {
    this.setState({ openAdd: false, openEdit: false })
  }

  public openDialog = () => {
    this.setState({ openAdd: true, editStatus: false })
  }

  public openEditDialog = () => {
    this.setState({ openEdit: true, editStatus: true })
  }

  public toggleCombineModal = () => {
    this.setState({ openCombine: !this.state.openCombine })
  }

  public handleAccountIconClick = (
    selectedAccount: PortfolioBuilderAccountObj,
    type: string
  ) => (): void => {
    switch (type) {
      case 'edit':
        this.setState(
          {
            selectedAccount
          },
          () => {
            this.openEditDialog()
          }
        )
        break
      case 'clone':
        this.setState(
          {
            selectedAccount
          },
          () => {
            this.cloneAccount()
          }
        )
        break
      case 'split':
        this.setState(
          {
            selectedAccount
          },
          () => {
            this.splitAccount()
          }
        )
        break
      case 'combine':
        this.setState(
          {
            selectedAccount
          },
          () => {
            this.toggleCombineModal()
          }
        )
        break
      case 'unSplit':
        this.setState(
          {
            selectedAccount
          },
          () => {
            this.unsplitAccount()
          }
        )
        break
      default:
        return null
    }
  }

  public generateSingleAccounts = (
    singleAccounts: PortfolioBuilderAccountObj[],
    stratDetails: PortfolioBuilderAccountObj[],
    props: PortfolioBuilderObj
  ) => {
    const { currentAccounts, proposedAccounts } = props
    singleAccounts.map((key: PortfolioBuilderAccountObj) => {
      stratDetails[key.id] = []
      Object.keys(proposedAccounts).map((key1: string) => {
        if (
          proposedAccounts[key1].clientAccount !== null &&
          proposedAccounts[key1].clientAccount ===
            currentAccounts[key.id].clientAccount
        ) {
          stratDetails[key.id].push(proposedAccounts[key1])
        }
      })
      if (stratDetails[key.id].length === 2) {
        const first = stratDetails[key.id][0]
        const second = stratDetails[key.id][1]
        if (first.strategy && first.accountValue) {
          stratDetails[key.id] = []
          stratDetails[key.id].push(first)
          stratDetails[key.id].push(second)
        } else if (second.strategy && second.accountValue) {
          stratDetails[key.id] = []
          stratDetails[key.id].push(second)
          stratDetails[key.id].push(first)
        }
      }
    })
  }

  public generateCombinedAccounts = (
    combinedAccount: PortfolioBuilderAccountObj[],
    stratDetails: PortfolioBuilderAccountObj[],
    props: PortfolioBuilderObj
  ) => {
    const { currentAccounts, proposedAccounts } = props
    combinedAccount.map((key: PortfolioBuilderAccountObj) => {
      stratDetails[key.id] = []
      Object.keys(proposedAccounts).map((key1: string) => {
        if (
          proposedAccounts[key1].clientAccount !== null &&
          proposedAccounts[key1].clientAccount ===
            currentAccounts[key.id].clientAccount
        ) {
          stratDetails[key.id].push(proposedAccounts[key1])
        }
      })
    })
  }

  public generateAccounts(props: PortfolioBuilderObj) {
    const { currentAccounts, proposedAccounts } = props
    const stratDetails: any = {}
    let filteredAccounts: any = []
    const singleAccounts: PortfolioBuilderAccountObj[] = []
    let combinedAccounts: PortfolioBuilderAccountObj[] = []
    Object.keys(currentAccounts).map((key: string) => {
      if (currentAccounts[key].combinedId) {
        combinedAccounts.push(currentAccounts[key])
      } else {
        singleAccounts.push(currentAccounts[key])
      }
    })
    this.generateSingleAccounts(singleAccounts, stratDetails, props)
    this.generateCombinedAccounts(combinedAccounts, stratDetails, props)
    if (combinedAccounts.length) {
      combinedAccounts = combinedAccounts.sort((a, b) =>
        a.combinedId > b.combinedId ? 1 : b.combinedId > a.combinedId ? -1 : 0
      )
      let j = 0
      let i = 0
      let combinedId = combinedAccounts[0].combinedId
      while (combinedAccounts.length) {
        if (i === combinedAccounts.length) {
          const tempData = combinedAccounts.slice(j, i)
          if (tempData.length === 1) {
            filteredAccounts = [...tempData, ...filteredAccounts]
            stratDetails[tempData[0].id] = []
            Object.keys(proposedAccounts).map((key1: string) => {
              if (
                proposedAccounts[key1].clientAccount !== null &&
                proposedAccounts[key1].clientAccount ===
                  currentAccounts[tempData[0].id].clientAccount
              ) {
                stratDetails[tempData[0].id].push(proposedAccounts[key1])
              }
            })
          } else {
            filteredAccounts = [tempData, ...filteredAccounts]
          }
          break
        } else if (combinedId !== combinedAccounts[i].combinedId) {
          const tempData = combinedAccounts.slice(j, i)
          if (tempData.length === 1) {
            filteredAccounts = [...filteredAccounts, ...tempData]
            stratDetails[tempData[0].id] = []
            Object.keys(proposedAccounts).map((key1: string) => {
              if (
                proposedAccounts[key1].clientAccount !== null &&
                proposedAccounts[key1].clientAccount ===
                  currentAccounts[tempData[0].id].clientAccount
              ) {
                stratDetails[tempData[0].id].push(proposedAccounts[key1])
              }
            })
          } else {
            filteredAccounts = [tempData, ...filteredAccounts]
          }
          combinedId = combinedAccounts[i].combinedId
          j = i
        }
        i += 1
      }
    }
    filteredAccounts = [...filteredAccounts, ...singleAccounts]
    filteredAccounts = filteredAccounts.sort(
      (a: PortfolioBuilderAccountObj, b: PortfolioBuilderAccountObj) =>
        a.accountNickname > b.accountNickname
          ? 1
          : b.accountNickname > a.accountNickname
          ? -1
          : 0
    )

    this.setState({ filteredAccounts, stratDetails }, () => {
      this.unavailableStrategyError(stratDetails)
    })
  }

  public splitAccountUtility = async (
    selectedAccountEn: { id: string | number; clientAccount: string },
    proposedAccounts: object,
    portfolioBuilderData: PortfolioBuilderObj,
    householdFinId: string,
    portfolioBuilderId: string,
    unsplitId: string
  ) => {
    if (!isObjEmpty(proposedAccounts) && selectedAccountEn) {
      let newObject: PortfolioBuilderAccountObj = null
      Object.keys(proposedAccounts)
        .filter((key: string) => {
          return (
            proposedAccounts[key].clientAccount ===
            portfolioBuilderData.currentAccounts[selectedAccountEn.id]
              .clientAccount
          )
        })
        .forEach((value: string) => {
          newObject = Object.assign({}, proposedAccounts[value])
        })

      newObject.clientAccount = selectedAccountEn.clientAccount
      newObject.unsplitId = unsplitId

      newObject.id = uuidv4()
      newObject.strategy = null
      newObject.strategyFee = null
      newObject.currentCashValue = 0
      newObject.currentEquityValue = 0
      newObject.currentFixedValue = 0
      newObject.currentOtherValue = 0
      newObject.currentUnclassifiedValue = 0
      await this.props.dispatch(
        createPortfolioBuilderAccount(
          householdFinId,
          portfolioBuilderId,
          newObject
        )
      )
      this.props.dispatch(
        getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
      )
    }
  }

  public splitAccount = async () => {
    const {
      portfolioBuilderData,
      portfolioBuilderId,
      householdFinId
    } = this.props
    const { proposedAccounts } = this.props.portfolioBuilderData
    const { selectedAccount } = this.state
    const unsplitId = uuidv4()
    if (selectedAccount && selectedAccount.length > 1) {
      for (let i = 0; i < selectedAccount.length; i++) {
        const selectedAccountEn = selectedAccount[i]
        this.splitAccountUtility(
          selectedAccountEn,
          proposedAccounts,
          portfolioBuilderData,
          householdFinId,
          portfolioBuilderId,
          unsplitId
        )
      }
    } else {
      this.splitAccountUtility(
        selectedAccount,
        proposedAccounts,
        portfolioBuilderData,
        householdFinId,
        portfolioBuilderId,
        unsplitId
      )
    }

    Object.keys(proposedAccounts).filter((key) => {})

    this.setState({ split: true })
  }

  public unsplitAccountUtility = async (
    selectedAccountEn: any,
    householdFinId: string,
    portfolioBuilderId: string,
    proposedAccounts: PortfolioBuilderAccountInterface
  ) => {
    const newObject = { ...selectedAccountEn }
    if (selectedAccountEn.strategy) {
      newObject.strategy = null
      newObject.strategyFee = null
      await this.props.dispatch(
        updatePortfolioBuilderAccount(
          householdFinId,
          portfolioBuilderId,
          newObject.id,
          newObject
        )
      )
    }
    const deleteAccount: PortfolioBuilderAccountObj[] = []
    Object.keys(proposedAccounts)
      .filter((key: string) => {
        return proposedAccounts[key].clientAccount === newObject.clientAccount
      })
      .forEach((value: string) => {
        deleteAccount.push(proposedAccounts[value])
      })
    if (deleteAccount.length > 1) {
      const deleteMe = deleteAccount.find(
        (value: PortfolioBuilderAccountObj): boolean => {
          return value.strategy === null
        }
      )
      await this.props.dispatch(
        deletePortfolioBuilderAccount(
          householdFinId,
          portfolioBuilderId,
          deleteMe.id
        )
      )
      this.props.dispatch(
        getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
      )
    }
  }

  public unsplitAccount = async () => {
    const { portfolioBuilderId, householdFinId } = this.props
    const { selectedAccount = [] } = this.state
    const { proposedAccounts } = this.props.portfolioBuilderData

    if (selectedAccount && selectedAccount.length > 1) {
      for (let i = 0; i < selectedAccount.length; i++) {
        const selectedAccountEn = selectedAccount[i]
        this.unsplitAccountUtility(
          selectedAccountEn,
          householdFinId,
          portfolioBuilderId,
          proposedAccounts
        )
      }
    } else {
      this.unsplitAccountUtility(
        selectedAccount,
        householdFinId,
        portfolioBuilderId,
        proposedAccounts
      )
    }
    this.setState({ split: false })
  }

  public cloneAccount = async () => {
    const {
      portfolioBuilderId,
      householdFinId,
      portfolioBuilderData
    } = this.props
    const { combine, split, selectedAccount } = this.state
    const { proposedAccounts } = this.props.portfolioBuilderData
    if (selectedAccount && !combine && !split) {
      const clientAccountId = uuidv4()
      if (portfolioBuilderData.currentAccounts) {
        const newData: PortfolioBuilderAccountObj = {
          ...selectedAccount
        }
        newData.id = uuidv4()
        newData.unsplitId = uuidv4()
        newData.combinedId = null
        newData.accountNickname = `${newData.accountNickname} - Cloned`
        newData.clientAccount = clientAccountId
        await this.props.dispatch(
          createPortfolioBuilderAccount(
            householdFinId,
            portfolioBuilderId,
            newData
          )
        )
        let newObject: PortfolioBuilderAccountObj = null
        Object.keys(proposedAccounts)
          .filter((key1: string) => {
            return (
              proposedAccounts[key1].clientAccount ===
              portfolioBuilderData.currentAccounts[selectedAccount.id]
                .clientAccount
            )
          })
          .forEach((value: string) => {
            newObject = { ...proposedAccounts[value] }
          })
        newObject.id = uuidv4()
        newObject.unsplitId = uuidv4()
        newObject.combinedId = null
        newObject.clientAccount = clientAccountId
        newObject.type = 'Proposed'
        await this.props.dispatch(
          createPortfolioBuilderAccount(
            householdFinId,
            portfolioBuilderId,
            newObject
          )
        )
        this.props.dispatch(
          getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
        )
        this.setState({ errStatus: 3 })
      }
    }
  }

  public addAccount = async (data: PortfolioBuilderAccountObj) => {
    const { portfolioBuilderId, householdFinId } = this.props
    const { editStatus } = this.state
    if (editStatus) {
      await this.editAccount(data)
    } else {
      const newData: PortfolioBuilderAccountObj = { ...data }
      const {
        currentEquityValue: equity,
        currentCashValue: cash,
        currentFixedValue: fixed,
        currentOtherValue: other,
        currentUnclassifiedValue: unclassified,
        accountValue: val
      } = newData
      newData.portfolioBuilder = portfolioBuilderId
      newData.id = uuidv4()
      newData.unsplitId = uuidv4()
      newData.clientAccount = uuidv4()
      newData.type = 'Current'
      newData.currentCashValue = (cash / 100) * val
      newData.currentEquityValue = (equity / 100) * val
      newData.currentFixedValue = (fixed / 100) * val
      newData.currentOtherValue = (other / 100) * val
      newData.currentUnclassifiedValue = (unclassified / 100) * val
      await this.props.dispatch(
        createPortfolioBuilderAccount(
          householdFinId,
          portfolioBuilderId,
          newData
        )
      )
      newData.id = uuidv4()
      newData.unsplitId = uuidv4()
      newData.type = 'Proposed'
      newData.currentCashValue = 0
      newData.currentEquityValue = 0
      newData.currentFixedValue = 0
      newData.currentOtherValue = 0
      newData.currentUnclassifiedValue = 0
      await this.props.dispatch(
        createPortfolioBuilderAccount(
          householdFinId,
          portfolioBuilderId,
          newData
        )
      )
      this.setState({ errStatus: 3 })
    }
    this.props.dispatch(
      getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
    )
  }

  public editAccount = async (data: PortfolioBuilderAccountObj) => {
    const { portfolioBuilderId, householdFinId } = this.props
    const { proposedAccounts } = this.props.portfolioBuilderData
    const { accountValue: val } = data
    const promiseCollection: Promise<boolean>[] = []
    promiseCollection.push(
      new Promise(async (resolve) => {
        const newCurrent: PortfolioBuilderAccountObj = { ...data }
        newCurrent.currentCashValue = (data.currentCashValue / 100) * val
        newCurrent.currentEquityValue = (data.currentEquityValue / 100) * val
        newCurrent.currentFixedValue = (data.currentFixedValue / 100) * val
        newCurrent.currentOtherValue = (data.currentOtherValue / 100) * val
        newCurrent.currentUnclassifiedValue =
          (data.currentUnclassifiedValue / 100) * val
        await this.props.dispatch(
          updatePortfolioBuilderAccount(
            householdFinId,
            portfolioBuilderId,
            newCurrent.id,
            newCurrent
          )
        )
        resolve(true)
      })
    )
    Object.keys(proposedAccounts).forEach(async (value: string) => {
      if (proposedAccounts[value].clientAccount === data.clientAccount) {
        promiseCollection.push(
          new Promise(async (resolve) => {
            const newProposed: PortfolioBuilderAccountObj = {
              ...proposedAccounts[value]
            }
            newProposed.accountNickname = data.accountNickname
            newProposed.accountDescription = data.accountDescription
            newProposed.accountNumber = data.accountNumber
            newProposed.custodian = data.custodian
            newProposed.registrationType = data.registrationType
            await this.props.dispatch(
              updatePortfolioBuilderAccount(
                householdFinId,
                portfolioBuilderId,
                newProposed.id,
                newProposed
              )
            )
            resolve(true)
          })
        )
      }
    })
    Promise.all(promiseCollection).then((_) =>
      this.props.dispatch(
        getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
      )
    )
  }

  public render() {
    const {
      openAdd,
      openEdit,
      errStatus,
      stratError,
      selectedAccount,
      openCombine,
      filteredAccounts,
      stratDetails
    } = this.state
    return (
      <div>
        <ErrorComponent errStatus={stratError} />
        <ErrorComponent errStatus={errStatus} />
        <div className='proposal-work'>
          <Accordion>
            <AccordionItem expanded={true}>
              <div className='proposal-work__title'>
                <AccordionItemTitle>
                  <h3 className='u-position-relative title-text proposal-work__title-text'>
                    PROPOSAL WORK AREA
                    <div
                      className='accordion__arrow proposal-work__title-arrow'
                      role='presentation'
                    />
                  </h3>
                </AccordionItemTitle>
                <div className='proposal-work__add-account'>
                  <a onClick={this.openDialog}>
                    <PlusIcon />
                    <span>Add Account</span>
                  </a>
                </div>
              </div>
              <AccordionItemBody expanded={false}>
                <div className='proposal-work__header' />
                <div className='proposal-work__account-wrapper'>
                  <AccountList
                    stratDetails={stratDetails}
                    filteredAccounts={filteredAccounts}
                    onErrorSelect={this.onErrorSelect}
                    handleAccountIconClick={this.handleAccountIconClick}
                  />
                </div>
              </AccordionItemBody>
            </AccordionItem>
          </Accordion>
          {openAdd ? (
            <AccountModal
              add={this.state.openAdd}
              onAccountAdd={this.addAccount}
              closeModal={this.closeModal}
              accountDetails={null}
            />
          ) : (
            ''
          )}
          {openEdit ? (
            <AccountModal
              onAccountAdd={this.addAccount}
              closeModal={this.closeModal}
              accountDetails={selectedAccount}
            />
          ) : (
            ''
          )}
          {openCombine ? (
            <CombineAccountModal
              stratDetails={stratDetails}
              toggleCombineModal={this.toggleCombineModal}
              selectedAccount={selectedAccount}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    portfolioBuilderId: match.params.portfolioBuilderId,
    portfolioBuilderData:
      store.portfolioBuilder[match.params.householdFinId] &&
      store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ]
  }
}

export default withRouter(connect(mapStateToProps)(ProposedWork))
