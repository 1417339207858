import React from 'react'
import Tile from '../../../components/layout/tile'
import Select from 'react-select'
import { UserObj, UsersObj } from '../../../../objects/user'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import {
  OfficeTeamObj,
  OfficeTeamsInterface,
  OfficeTeamMemberObj
} from '../../../../objects/officeTeams'
import { getSelectOptionByValue } from '../../../helpers'
import { withRouter } from 'react-router'
import { updatePlanningSoftwareUser } from '../../../../actions/officeTeams'
import { getOfficeTeamByHousehold } from '../../../../actions/households'
import { HouseholdObj } from '../../../../objects/household'

export interface PlanningSoftwareTileProps {
  household: HouseholdObj
  assignedAdvisor: UserObj
  officeTeam: OfficeTeamObj
  users: UsersObj
  dispatch: Dispatch<GlobalState>
}

export class PlanningSoftwareTile extends React.Component<
  PlanningSoftwareTileProps
> {
  public componentDidMount() {
    const { household, dispatch } = this.props
    if (!household.officeTeams) {
      dispatch(getOfficeTeamByHousehold(household.id))
    }
  }

  public updatePlanningSoftwareUser = async (user: any) => {
    const { household, dispatch } = this.props
    await dispatch(updatePlanningSoftwareUser(household.officeTeam, user.value))
    dispatch(getOfficeTeamByHousehold(household.id))
  }

  public render() {
    const { assignedAdvisor, household } = this.props
    const officeTeamsMapped: OfficeTeamsInterface = {}
    if (household.officeTeams) {
      household.officeTeams.forEach(
        (officeTeamObj: OfficeTeamObj, index: number) => {
          officeTeamsMapped[officeTeamObj.id] = officeTeamObj
        }
      )
    }
    const officeTeam = officeTeamsMapped[household.officeTeam]
    const advisorOptions =
      officeTeam &&
      officeTeam.members.map((member: OfficeTeamMemberObj) => {
        return {
          value: member.user.id,
          label: `${member.user.firstName} ${member.user.lastName}`
        }
      })

    const advisorId = assignedAdvisor?.id
    return (
      <div className='financial-planning__tile-w'>
        <Tile
          leftHeader={'FINANCIAL PLANNING'}
          headerBorder={true}
          rightHeader={''}
          headerStyle={{ backgroundColor: '#FAFAFA' }}>
          <div className='financial-planning__tile-content financial-planning__tile-content--row'>
            <div className='financial-planning__advisor'>
              <p className='financial-planning__advisor-text'>Advisor</p>
              {advisorOptions && (
                <Select
                  className='financial-planning__select'
                  options={advisorOptions}
                  defaultValue={
                    advisorOptions &&
                    getSelectOptionByValue(advisorOptions, advisorId)
                  }
                  onChange={this.updatePlanningSoftwareUser}
                />
              )}
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  return {
    household: store.households[match.params.householdFinId],
    officeTeam: store.officeTeams[store.households[householdFinId].officeTeam],
    planningSoftware: store.households[householdFinId].planningSoftware,
    users: store.user.users
  }
}

export default withRouter(connect(mapStateToProps)(PlanningSoftwareTile))
