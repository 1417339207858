import React, { Component, Fragment } from 'react'
import EditAccountModal from './addAccountModal'

import ConfirmDeleteAccountModal from './confirmDeleteAccountModal'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import ActionDropdown from '../../components/actionDropdown'

import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'

interface InsuranceUpdateProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  insurance: any
}

interface InsuranceUpdateState {
  showEditAccountModal: boolean
  showEditOptions: boolean
  showConfirmDeleteAccountModal: boolean
}

export class InsuranceUpdate extends Component<
  InsuranceUpdateProps,
  InsuranceUpdateState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      showEditAccountModal: false,
      showConfirmDeleteAccountModal: false,
      showEditOptions: false
    }
  }

  public toggleEditAccountModal = () => {
    this.setState({ showEditAccountModal: !this.state.showEditAccountModal })
  }

  public toggleConfirmDeleteAccountModal = () => {
    this.setState({
      showConfirmDeleteAccountModal: !this.state.showConfirmDeleteAccountModal
    })
  }

  public toggleEditOptions = () => {
    this.setState({ showEditOptions: !this.state.showEditOptions })
  }

  public editGoal() {
    return (
      <Fragment>
        <EditIcon />
        <span className='action-dropdown__item-label'>Edit</span>
      </Fragment>
    )
  }
  public deleteGoal() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete</span>
      </Fragment>
    )
  }

  public render() {
    const { insurance, householdFinId } = this.props
    const { showEditAccountModal, showConfirmDeleteAccountModal } = this.state
    const dropdownActions = [
      {
        element: this.editGoal(),
        onClick: this.toggleEditAccountModal
      },
      {
        element: this.deleteGoal(),
        onClick: this.toggleConfirmDeleteAccountModal
      }
    ]

    return (
      <Fragment>
        <ActionDropdown actions={dropdownActions} />
        {showEditAccountModal ? (
          <EditAccountModal
            insurance={insurance}
            closeModal={this.toggleEditAccountModal}
          />
        ) : null}
        {showConfirmDeleteAccountModal ? (
          <ConfirmDeleteAccountModal
            insuranceId={insurance.id}
            householdFinId={householdFinId}
            closeModal={this.toggleConfirmDeleteAccountModal}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    insurances: store.insurances[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(InsuranceUpdate))
