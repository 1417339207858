import React from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import Button from '../../components/button'
import AddAccountModal from './addAccountModal'
import InnerContainer from '../../components/layout/innerContainer'

import LifeInsuranceRow from './lifeInsuranceRow'
import AnnuitiesInsuranceRow from './annuitiesInsuranceRow'
import DisabilityInsuranceRow from './disabilityInsuranceRow'
import LongtermcareInsuranceRow from './longtermcareInsuranceRow'

import { InsuranceInterface, insuranceTypes } from '../../../objects/insurance'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'

import * as insuranceActions from '../../../actions/insurance'

import { ReactComponent as InsuranceIcon } from '../../assets/images/icons/insurance.svg'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import nullTasks from '../../assets/images/icons/png/null-tasks.png'

interface InsuranceState {
  showAddAccountModal: boolean
}

interface InsuranceProps {
  dispatch?: Dispatch<GlobalState>
  match: any
  householdFinId: string
  insurances: InsuranceInterface
}

class Insurance extends React.Component<InsuranceProps, InsuranceState> {
  constructor(props: any) {
    super(props)
    this.state = {
      showAddAccountModal: false
    }
  }

  public componentDidMount() {
    this.props.dispatch(
      insuranceActions.getInsurance(this.props.householdFinId)
    )
  }

  public toggleShowAddAccountModal = () => {
    this.setState({ showAddAccountModal: !this.state.showAddAccountModal })
  }

  public addAccountBtn = () => {
    return (
      <Button primary={true} onClick={this.toggleShowAddAccountModal}>
        <PlusIcon />
        <span className='vault__btn--text'>Add Policy</span>
      </Button>
    )
  }

  public render() {
    let showNullState = true
    const { insurances } = this.props
    const types = [
      insuranceTypes.lifeInsurance,
      insuranceTypes.longTerm,
      insuranceTypes.disability,
      insuranceTypes.annuity,
      insuranceTypes.annuities
    ]
    if (insurances) {
      Object.keys(insurances).forEach((key) => {
        if (types.indexOf(insurances[key].recordTypeName) !== -1) {
          showNullState = false
        }
      })
    }
    if (!showNullState) {
      return (
        <InnerContainer>
          <ContentHeader
            Icon={InsuranceIcon}
            title='Insurance'
            rightHeader={this.addAccountBtn()}
          />
          {insurances ? <LifeInsuranceRow insurances={insurances} /> : null}
          {insurances ? (
            <AnnuitiesInsuranceRow insurances={insurances} />
          ) : null}
          {insurances ? (
            <DisabilityInsuranceRow insurances={insurances} />
          ) : null}
          {insurances ? (
            <LongtermcareInsuranceRow insurances={insurances} />
          ) : null}
          {this.state.showAddAccountModal ? (
            <AddAccountModal
              insurances={insurances}
              closeModal={this.toggleShowAddAccountModal}
            />
          ) : null}
        </InnerContainer>
      )
    } else {
      return (
        <InnerContainer>
          <ContentHeader
            Icon={InsuranceIcon}
            title='Insurance'
            rightHeader={this.addAccountBtn()}
          />
          <div className='insurance__null-w'>
            <div className='insurance__null-text'>
              <img src={nullTasks} role='presentation' alt='' />
              <h3>No policies yet</h3>
              <p>
                Add a policy by clicking on the button on the top right of this
                area.
              </p>
            </div>
          </div>
          {this.state.showAddAccountModal ? (
            <AddAccountModal
              insurances={insurances}
              closeModal={this.toggleShowAddAccountModal}
            />
          ) : null}
        </InnerContainer>
      )
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    insurances: store.insurances[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(Insurance))
