import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../objects/financialGoal'

/*
  provides: formatted chart option props
*/
// Y axis annotation
export const annotateYRange = (
  fundedScores: FundedScoreObj[],
  planningSoftware: PlanningSoftwareType
) => {
  const latestFundedScore: FundedScoreObj = fundedScores && fundedScores[0]
  const lowRange = () => {
    if (!latestFundedScore) return 75
    return planningSoftware === 'gdx360' || !latestFundedScore.rangeLow
      ? 75
      : latestFundedScore.rangeLow
  }

  const highRange = () => {
    if (!latestFundedScore) return 90
    return planningSoftware === 'gdx360' || !latestFundedScore.rangeHigh
      ? 90
      : latestFundedScore.rangeHigh
  }

  return { rangeLow: lowRange(), rangeHigh: highRange() }
}

// Scales for X and Y Axis
const getScales = (xColor: string | null, yColor: string | null) => {
  return {
    xAxes: [
      {
        position: 'bottom',
        gridLines: {
          display: Boolean(xColor),
          zeroLineColor: xColor || '',
          tickMarkLength: 5
        },
        scaleLabel: {
          display: false
        },
        ticks: {
          reverse: false,
          fontSize: 12,
          padding: 10,
          fontColor: 'rgba(138, 136, 136, 1)'
        }
      }
    ],
    yAxes: [
      {
        position: 'left',
        gridLines: {
          display: Boolean(yColor),
          drawBorder: false,
          tickMarkLength: 5
        },
        scaleLabel: {
          display: false
        },
        ticks: {
          padding: 20,
          reverse: false,
          suggestedMax: 100,
          suggestedMin: 40,
          stepSize: 20
        }
      }
    ]
  }
}

const customToolTip = (tooltip: any) => {
  //
}

export const onHoverPoint = <E, T>(event: any, chartElement: T[]) => {
  event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
}

// property: animation is required for dynamic updates
export const lineChartDataOptions = (
  limit: number,
  animate: boolean,
  planningSoftwareType: PlanningSoftwareType,
  fundedScores: FundedScoreObj[],
  refreshAfterUpdate?: void
): {} => {
  const animation = !animate ? { duration: 0 } : null
  return {
    events: ['mousemove', 'click'],
    scaleShowVerticalLines: false,
    pointDotRadius: 5,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 40,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    border: 0,
    scales: getScales(null, 'rgba(0 , 255, 0, 0.8)'),
    annotation: {
      annotations: [
        {
          id: 'line-chart-annotation',
          drawTime: 'afterDraw',
          type: 'box',
          xScaleID: 'x-axis-0',
          yScaleID: 'y-axis-0',
          yMin: annotateYRange(fundedScores, planningSoftwareType).rangeLow,
          yMax: annotateYRange(fundedScores, planningSoftwareType).rangeHigh,
          borderColor: 'rgba(162,209,52,0)',
          backgroundColor: 'rgba(162,209,52,0.1)',
          borderWidth: 0,
          borderDash: [2, 2]
        }
      ]
    },
    animation: {
      onComplete: refreshAfterUpdate,
      ...animation
    },
    tooltips: {
      enabled: false,
      custom: customToolTip
    },
    hover: {
      onHover: onHoverPoint
    }
  }
}
