import * as actions from '../actions/syncDisplay'

export interface SyncDisplayState {
  showSync: boolean
}

const initState: SyncDisplayState = {
  showSync: false
}

const SyncDisplay = (state = initState, action: any): SyncDisplayState => {
  switch (action.type) {
    case actions.SHOW_SYNC_MODAL:
      return { ...state, showSync: action.payload }
    case actions.HIDE_SYNC_MODAL:
      return { ...state, showSync: action.payload }
    default:
      return state
  }
}

export default SyncDisplay
