// static strings
export const staticText = {
  includeInGc: 'This is where you select what to show in the GuideCenter™.',
  requiredClassificationGc:
    'Classification must be set to be able to show in GuideCenter™.',
  satisfactionTextCopy:
    'The “Satisfaction Guarantee” field provided on this form is an optional field that provides you (i.e., the FinLife customer) with the ability to customize whether you want to offer a satisfaction guarantee and, if you do, what you want that guarantee be. If you determine that a satisfaction guarantee would be the right offering for you, you should consult with your compliance expert to ensure that you are providing all required information and disclosures to your prospects and clients.',
  linkedToCurrentGoal: 'Linked to current goal.',
  clientAccountChangeText:
    'Changes made here apply to AdvisorCenter and GuideCenter only. Source account info remains intact.'
}
