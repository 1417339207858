import React, { Component } from 'react'
import Input from '../../components/Input'
import { ContactsInterface } from '../../../objects/contact'
import Select from 'react-select'
import Button from '../../components/button'
import {
  HouseholdInstitution,
  HouseholdObj,
  AggregationProviderType
} from '../../../objects/household'
import { Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import * as householdActions from '../../../actions/households'
import * as toastActions from '../../../actions/toasts'
import { PlanningSoftwareType } from '../../../objects/financialGoal'
import { isStringEmpty } from '../../helpers'
import ConfirmDataSourceModal from './confirmDataSourceModal'
import AlertMessageToast from '../../components/alertMessageToast'

interface SettingsHouseholdInfoProps {
  household: HouseholdObj
  contacts: ContactsInterface
  dispatch: Dispatch<GlobalState>
  institution: HouseholdInstitution
  householdId: string
}

interface SettingsHouseholdInfoState {
  emoneyId: string
  planningSoftware: PlanningSoftwareType
  disableManualAccount: boolean
  aggregationProvider: AggregationProviderType
  showConfirmModal: boolean
  showAlert: boolean
  isSuccess: boolean
  aggregationProviderIsLocked: boolean
}

const planningSoftwareOptions = [
  { value: 'gdx360', label: 'GDX360' },
  { value: 'moneyguidepro', label: 'MoneyGuidePro' },
  { value: 'emoney', label: 'eMoney' },
  { value: 'emoney_enterprise', label: 'eMoney Enterprise' },
  { value: null, label: 'Other' }
]

const aggregationProviderOptions = [
  { value: 'emoney_enterprise', label: 'eMoney' },
  { value: 'yodlee', label: 'Yodlee / AdvisorCenter' }
]

interface SelectInterface {
  value: string
  label: string
}

export class SettingsHouseholdInfo extends Component<
  SettingsHouseholdInfoProps,
  SettingsHouseholdInfoState
> {
  private cxRef = React.createRef<HTMLInputElement>()
  constructor(props: SettingsHouseholdInfoProps) {
    super(props)
    this.state = {
      planningSoftware: props.household?.planningSoftware,
      disableManualAccount: props.household?.disableManualAccount,
      emoneyId: props.household.emoneyId || '',
      aggregationProvider: props.household?.aggregationProvider,
      showConfirmModal: false,
      showAlert: false,
      isSuccess: false,
      aggregationProviderIsLocked: false
    }
  }

  public updatePlanningSoftware = (option: any) => {
    const { aggregationProvider } = this.state
    this.setState({
      planningSoftware: option.value,
      aggregationProvider:
        option.value === 'moneyguidepro' ? 'yodlee' : aggregationProvider,
      aggregationProviderIsLocked: option.value === 'moneyguidepro',
      disableManualAccount:
        option.value !== 'moneyguidepro' &&
        aggregationProvider !== 'yodlee' &&
        true,
      showAlert: false
    })
  }

  public updateAggregationProvider = (option: any) => {
    this.setState({
      aggregationProvider: option.value,
      disableManualAccount: option.value !== 'yodlee',
      showAlert: false
    })
  }

  public getOption = (
    options: SelectInterface[],
    value: string
  ): SelectInterface => {
    let selectedOption
    options.forEach((option) => {
      if (option?.value === value) {
        selectedOption = option
      }
    })
    return selectedOption
  }

  public filteroutEmoneyEnterprise = (options: any) => {
    const filteredOption: any = []
    options.forEach((option: any) => {
      if (option?.value !== 'emoney_enterprise') {
        filteredOption.push(option)
      }
    })
    return filteredOption
  }

  public saveEmoneyId = async (emoneyId: string | null) => {
    const {
      household: { id: householdFinId = '' } = {},
      dispatch,
      institution
    } = this.props
    let emoneyIdString
    try {
      if (!isStringEmpty(emoneyId) && emoneyId.includes(':')) {
        emoneyIdString = emoneyId
      } else {
        emoneyIdString = isStringEmpty(emoneyId)
          ? null
          : institution?.isFinlifeInstitution === false ||
            institution?.systemInstitution === true
          ? `${window._env_.REACT_APP_EMONEY_ENTERPRISE_ID_PREFIX}:${emoneyId}`
          : emoneyId
      }
      await dispatch(
        householdActions.updateEmoneyField({
          householdFinId,
          fieldName: 'id',
          fieldValue: emoneyIdString
        })
      )
    } catch (e) {
      dispatch(
        toastActions.addToast({
          message:
            'Unable to save eMoney id. Please make sure that id is not already assigned to another household.',
          backgroundColor: '#F12938'
        })
      )
      throw e
    } finally {
      await dispatch(householdActions.getIndividualHousehold(householdFinId))
    }
  }

  public confirmChangeDataSourceFunc = async () => {
    const { aggregationProvider, disableManualAccount } = this.state
    const { household, dispatch } = this.props
    try {
      const householdInformation = {
        disableManualAccount,
        aggregationProvider
      }
      await dispatch(
        householdActions.setHouseholdInformation(
          household.id,
          householdInformation
        )
      )
      this.setState({
        showConfirmModal: false,
        showAlert: true,
        isSuccess: true
      })
    } catch (err) {
      this.setState({
        showConfirmModal: false,
        showAlert: true,
        isSuccess: false
      })
    }
  }

  public cancelChangeDataSourceFunc = async () => {
    this.setState({
      showConfirmModal: false
    })
  }

  public save = async () => {
    const {
      planningSoftware,
      emoneyId,
      aggregationProvider,
      disableManualAccount
    } = this.state
    const { household, dispatch } = this.props

    if (
      household?.aggregationProvider !== aggregationProvider &&
      planningSoftware !== 'moneyguidepro'
    ) {
      this.setState({
        showConfirmModal: true
      })
    }

    if (household?.planningSoftware !== planningSoftware) {
      const householdInformation = {
        planningSoftware,
        disableManualAccount,
        aggregationProvider
      }
      await dispatch(
        householdActions.setHouseholdInformation(
          household.id,
          householdInformation
        )
      )
      dispatch(
        toastActions.addToast({
          message: 'Setting for Planning Software has been saved.'
        })
      )
    }

    if (
      planningSoftware === 'emoney' ||
      planningSoftware === 'emoney_enterprise'
    ) {
      this.saveEmoneyId(emoneyId)
    } else {
      await dispatch(householdActions.getIndividualHousehold(household.id))
    }
  }

  public selectCXID = (_e: React.FormEvent<HTMLInputElement>) => {
    this.cxRef.current.select()
  }
  public updateEmoneyId = (e: React.FormEvent<HTMLInputElement>) => {
    const emoneyId =
      e.currentTarget.value && e.currentTarget.value.length > 0
        ? e.currentTarget.value
        : null
    this.setState({ emoneyId })
  }
  public isEmoneyLinkShouldVisible = () => {
    const { household, institution } = this.props
    const { planningSoftware } = this.state
    return (
      ['emoney_enterprise', 'emoney'].includes(household.planningSoftware) &&
      ['emoney_enterprise', 'emoney'].includes(planningSoftware) &&
      !institution?.isFinlifeInstitution &&
      window?._env_?.REACT_APP_LINK_EMONEY_ENABLED
    )
  }
  public render() {
    const { household, institution } = this.props
    const {
      planningSoftware,
      emoneyId,
      aggregationProvider,
      showConfirmModal,
      showAlert,
      isSuccess,
      aggregationProviderIsLocked
    } = this.state
    const options =
      window._env_.REACT_APP_EMONEY_ENTERPRISE_ENABLED &&
      !institution?.isFinlifeInstitution
        ? planningSoftwareOptions
        : this.filteroutEmoneyEnterprise(planningSoftwareOptions)

    return (
      <div className='c-settings__household'>
        <h1>Household Information</h1>
        <div className='c-settings__household-half-w'>
          <div>
            <h1>CRM Integrations</h1>
            <Input
              reference={this.cxRef}
              content={household.cxId}
              inputType='text'
              title='FINID'
              name='cxId'
              onClick={this.selectCXID}
              readOnly={true}
            />
          </div>
        </div>
        <div className='c-settings__household--full-w'>
          <label className='c-settings__household-label'>
            Planning Software
          </label>
          <div>
            <div className='c-settings__household-rows'>
              <div className='c-settings__household-input'>
                <Select
                  className='c-settings__household-select'
                  defaultValue={this.getOption(options, planningSoftware)}
                  options={options}
                  onChange={this.updatePlanningSoftware}
                />
              </div>
            </div>
            {(planningSoftware === 'emoney' ||
              planningSoftware === 'emoney_enterprise') && (
              <div className='c-settings__household-input'>
                <Input
                  title='eMoney Id'
                  inputType='text'
                  content={
                    emoneyId?.includes(':') ? emoneyId.split(':')[1] : emoneyId
                  }
                  controlFunc={this.updateEmoneyId}
                />
              </div>
            )}
          </div>
          <label className='c-settings__household-label'>
            Client Account Linking / Manual Account Data Source
          </label>
          <div>
            <div className='c-settings__household-input'>
              <Select
                className='c-settings__household-select'
                value={this.getOption(
                  aggregationProviderOptions,
                  aggregationProvider
                )}
                options={aggregationProviderOptions}
                onChange={this.updateAggregationProvider}
                isDisabled={
                  aggregationProviderIsLocked ||
                  planningSoftware === 'moneyguidepro'
                }
              />
            </div>
          </div>
        </div>
        {showConfirmModal && (
          <ConfirmDataSourceModal
            title='Client Account Linking / Manual Account Data Source'
            message={
              aggregationProvider === 'emoney_enterprise'
                ? 'You will now need to create & maintain manual accounts in eMoney, and will NOT be able to create manual accounts in AdvisorCenter and GuideCenter.'
                : 'You will now need to create & maintain manual accounts in Yodlee / AdvisorCenter.'
            }
            boldMessage={
              aggregationProvider === 'emoney_enterprise'
                ? 'Are you sure you want to change Client Account Linking / Manual Account Data Source to eMoney?'
                : 'Are you sure you want to change Client Account Linking / Manual Account Data Source to Yodlee / AdvisorCenter?'
            }
            confirmFunc={this.confirmChangeDataSourceFunc}
            closeFunc={this.cancelChangeDataSourceFunc}
          />
        )}
        {showAlert && aggregationProvider === 'emoney_enterprise' ? (
          <AlertMessageToast
            icon={isSuccess ? 'successV2' : 'errorV2'}
            title={isSuccess ? 'Success' : 'Failure'}
            message={
              isSuccess
                ? 'Client Account Linking / Manual Account Data Source has been changed to eMoney'
                : 'Manual Account Data Source could not be changed to eMoney'
            }
            stacked={false}
          />
        ) : null}
        {showAlert && aggregationProvider === 'yodlee' ? (
          <AlertMessageToast
            icon={isSuccess ? 'successV2' : 'errorV2'}
            title={isSuccess ? 'Success' : 'Failure'}
            message={
              isSuccess
                ? 'Client Account Linking / Manual Account Data Source has been changed to Yodlee / AdvisorCenter'
                : 'Manual Account Data Source could not be changed to Yodlee / AdvisorCenter'
            }
            stacked={false}
          />
        ) : null}
        <div className='c-settings__household-btns'>
          <div>
            <Button clear={true}>Cancel</Button>
            <Button primary={true} onClick={this.save}>
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsHouseholdInfo
