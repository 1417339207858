import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import Button from '../../components/button'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import Tile from '../../components/layout/tile'
import Heading from './heading'

import { HouseholdObj } from '../../../objects/household'
import { ReactComponent as HonestConversationsIcon } from '../../assets/images/icons/honestconversations.svg'
import CommitmentIcon from '../../assets/images/icons/png/hc-commitment-white.png'
import HappinessIcon from '../../assets/images/icons/png/hc-happiness-white.png'
import ProtectionIcon from '../../assets/images/icons/png/hc-protection-white.png'
import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { ContactsInterface } from '../../../objects/contact'
import { history } from '../../../store'
import {
  HonestConversationsExerciseObj,
  ScoringSessionsObj
} from '../../../objects/honestConversations'
import { CardCategoryType } from '../../../objects/HonestConversations/card'
import { sortHouseholdCards } from '../../helpers/honestConversations'

interface DashHonestConversationsProps {
  household: HouseholdObj
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  householdFinId: string
}

export class DashHonestConversations extends Component<
  DashHonestConversationsProps
> {
  public firstName = (name: string) => {
    return name ? name.split(' ')[0] : ''
  }

  public combinedCard = (card: CardCategoryType) => {
    switch (card) {
      case 'Protection':
        return ProtectionIcon
      case 'Commitment':
        return CommitmentIcon
      case 'Happiness':
        return HappinessIcon
      default:
        return ''
    }
  }

  public contactScores = (scoringSession: ScoringSessionsObj) => {
    return Object.keys(scoringSession.cardScores).map((key) => {
      const cardScore = scoringSession.cardScores[key]
      return (
        <span key={key} className='c-dash__hc-score-item'>
          {cardScore && cardScore.score}
        </span>
      )
    })
  }

  public combinedCards = () => {
    const { honestConversation } = this.props
    const sortedHouseholdCards = sortHouseholdCards(
      honestConversation.householdCards
    )
    return Object.keys(sortedHouseholdCards).map((key) => {
      const { card } = sortedHouseholdCards[key]
      const hcIconClass = card.category
        ? 'c-dash__hc-icon c-dash__hc-icon--' + card.category.toLowerCase()
        : 'c-dash__hc-icon c-dash__hc-icon--'
      return (
        <a data-tip={card.title} key={key}>
          <div className={hcIconClass}>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
            <img
              src={this.combinedCard(card.category)}
              role='presentation'
              alt=''
            />
          </div>
        </a>
      )
    })
  }

  public navigateToAddScores = () => {
    history.push(
      `/households/${this.props.householdFinId}/honestConversations/meetings/${this.props.household.hcMeetingId}/scores/new`
    )
  }

  public renderNoScores = () => {
    return (
      <div className='c-dash__hc'>
        <div className='c-dash__hc-no-scores-w'>
          <div className='c-dash__hc-no-scores'>
            <div className='c-dash__hc-no-scores-icon-w'>
              {this.combinedCards()}
            </div>
            <p className='c-dash__hc-no-scores-text'>
              Clients have selected their cards, but haven't scored them yet.
            </p>
            <Button
              primary={true}
              style={{ padding: '6px 10px' }}
              onClick={this.navigateToAddScores}>
              <PlusIcon />
              New Score
            </Button>
          </div>
        </div>
      </div>
    )
  }

  public renderScores = (
    primaryScores: ScoringSessionsObj,
    secondaryScores: ScoringSessionsObj
  ) => {
    const { contacts } = this.props
    let primarySum = 0
    let secondarySum = 0
    if (primaryScores?.cardScores) {
      Object.keys(primaryScores.cardScores).forEach((key) => {
        primarySum = primaryScores.cardScores[key].score
          ? primarySum + primaryScores.cardScores[key].score
          : primarySum
      })
    }
    if (secondaryScores?.cardScores) {
      Object.keys(secondaryScores.cardScores).forEach((key) => {
        secondarySum = secondaryScores.cardScores[key].score
          ? secondarySum + secondaryScores.cardScores[key].score
          : secondarySum
      })
    }
    const primeCombinedScoreAvg = Math.round(primarySum / 5)
    const secondaryCombinedScoreAvg = Math.round(secondarySum / 5)
    return (
      <div className='c-dash__hc'>
        <div className='c-dash__hc-icon-w'>{this.combinedCards()}</div>
        <div className='c-dash__hc-score'>
          {contacts && contacts.primary && primaryScores ? (
            <div className='c-dash__hc-score-client'>
              <span className='c-dash__hc-score-item'>
                {contacts.primary.firstName && (
                  <span>{contacts.primary.firstName}</span>
                )}{' '}
                <span>{primeCombinedScoreAvg}</span>
              </span>
              {primaryScores && this.contactScores(primaryScores)}
            </div>
          ) : null}
          {contacts && contacts.secondary && secondaryScores ? (
            <div className='c-dash__hc-score-client'>
              <span className='c-dash__hc-score-item'>
                {contacts.secondary.firstName && (
                  <span>{contacts.secondary.firstName}</span>
                )}{' '}
                <span>{secondaryCombinedScoreAvg}</span>
              </span>
              {secondaryScores && this.contactScores(secondaryScores)}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  public render() {
    const { householdFinId, honestConversation, contacts } = this.props
    let primaryScores: ScoringSessionsObj = null
    let secondaryScores: ScoringSessionsObj = null

    const {
      activeScoringSessionPrimary,
      activeScoringSessionSecondary
    } = honestConversation
    if (
      contacts &&
      honestConversation &&
      honestConversation.clientScoringSessions
    ) {
      primaryScores =
        contacts.primary &&
        activeScoringSessionPrimary &&
        honestConversation.clientScoringSessions[contacts.primary.id] &&
        honestConversation.clientScoringSessions[contacts.primary.id][
          activeScoringSessionPrimary
        ]
      secondaryScores =
        contacts.secondary &&
        activeScoringSessionSecondary &&
        honestConversation.clientScoringSessions[contacts.secondary.id] &&
        honestConversation.clientScoringSessions[contacts.secondary.id][
          activeScoringSessionSecondary
        ]
    }
    return (
      <Tile
        to={`/households/${householdFinId}/honestConversations`}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <Heading
            icon={<HonestConversationsIcon />}
            headingText={'HonestConversations®'}
          />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={true}
        anchorBorder={true}>
        <div className='c-dash__row-content'>
          {primaryScores || secondaryScores
            ? this.renderScores(primaryScores, secondaryScores)
            : this.renderNoScores()}
        </div>
      </Tile>
    )
  }
}

export default DashHonestConversations
