import React, { Fragment } from 'react'
import Button from '../../../components/button'

interface IntegrationRowProps {
  icon: string
  title: string
  onSyncClick(): void
}

class IntegrationRow extends React.Component<IntegrationRowProps> {
  public render() {
    const { icon, title, onSyncClick } = this.props
    return (
      <Fragment>
        <div className='a-settings__row sync-row'>
          <div className='sync-row--flex'>
            <img src={icon} alt='Saleforce Icon' className='sync-row__icon' />
            <div className='sync__content'>
              <h1>{title} for FinLife Partners</h1>
              <p className='sync__content-text'>
                Sync your clients directly from your CRM
              </p>
            </div>
          </div>
          <Button onClick={onSyncClick} primary={true}>
            Sync
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default IntegrationRow
