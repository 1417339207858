import React from 'react'
import { Dispatch, connect } from 'react-redux'
import { withRouter } from 'react-router'

import Modal from '../../components/layout/modal'
import { GlobalState } from '../../../reducers'
import * as clientActions from '../../../actions/clientAccounts'
import * as householdActions from '../../../actions/households'

import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'

interface ConfirmDeleteAccountProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  clientId: string
  closeModal(): void
}

class ConfirmDeleteAccountModal extends React.Component<
  ConfirmDeleteAccountProps
> {
  public handleDeactiveTask = async () => {
    const { householdFinId, clientId } = this.props
    await this.props.dispatch(
      clientActions.deleteClientAccount(clientId, householdFinId)
    )
    this.props.dispatch(clientActions.getClientAccounts(householdFinId))
    this.props.dispatch(householdActions.getIndividualHousehold(householdFinId))
    this.props.closeModal()
  }
  public render() {
    const { closeModal } = this.props
    return (
      <Modal icon={DeleteIcon} title='Delete Account' closeModal={closeModal}>
        <div className='pal-confirm-modal'>
          <div className='pal-confirm-modal__content'>
            <p>Are you sure you want to delete this account?</p>
          </div>
          <div className='pal-confirm-modal__buttons'>
            <span className='btn btn__clear' onClick={closeModal}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={this.handleDeactiveTask}>
              Delete
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    priorityActions: store.priorityActions[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(ConfirmDeleteAccountModal))
