import React, { Component, CSSProperties } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { ContactsInterface, ContactObj } from '../../../../../objects/contact'
import {
  HonestConversationsExerciseObj,
  ClientScoringSession
} from '../../../../../objects/honestConversations'
import ClientScoreSessionRow from './clientScoreRow'
import { dateSortScoringSession } from '../../../../helpers/honestConversations'

interface ClientScoreListProps {
  householdFinId: string
  contactId: string
  exerciseId: string
  contacts: ContactsInterface
  contact: ContactObj
  activeScoreId: string
  honestConversation: HonestConversationsExerciseObj
  clientScoringSessions: ClientScoringSession
  onClickScoreRow(
    contactId: string,
    clientScoreSessionId: string,
    isPrimary: boolean
  ): void
}

const tileContentStyle: CSSProperties = { padding: '0px 0px 0px 0px' }

class ClientScoreList extends Component<ClientScoreListProps> {
  public tileHeader = () => {
    const { contact } = this.props
    return (
      <div className='client-score-history__header-left'>
        {contact?.firstName}
      </div>
    )
  }

  public clientScoreSessions = () => {
    const {
      clientScoringSessions,
      contacts,
      contact,
      activeScoreId,
      exerciseId,
      householdFinId,
      onClickScoreRow
    } = this.props

    let activeIndex: number = null

    clientScoringSessions &&
      dateSortScoringSession(clientScoringSessions).forEach(
        (scoreSession, index: number) => {
          if (scoreSession.id === activeScoreId) {
            activeIndex = index
          }
        }
      )

    const dateSorted =
      (clientScoringSessions &&
        dateSortScoringSession(clientScoringSessions)) ||
      null

    if (!dateSorted || (dateSorted.length === 0 && contact)) {
      return (
        <ClientScoreSessionRow
          key={0}
          exerciseId={null}
          isPrimary={false}
          activeScoreId={activeScoreId}
          householdFinId={householdFinId}
          scoringSession={null}
          scoringSessionId={null}
          contactId={contact?.id}
        />
      )
    }

    if (activeIndex) {
      const [active] = dateSorted.splice(activeIndex, 1)
      dateSorted.splice(0, 0, active)
    }

    return dateSorted
      .filter((scoreSession) => {
        return scoreSession.exerciseId === exerciseId
      })
      .map((scoreSession, index: number) => {
        return (
          <ClientScoreSessionRow
            key={index}
            exerciseId={exerciseId}
            isPrimary={Boolean(contact?.id === contacts?.primary?.id)}
            activeScoreId={activeScoreId}
            householdFinId={householdFinId}
            scoringSession={scoreSession}
            scoringSessionId={scoreSession.id}
            contactId={contact?.id}
            onClickScoreRow={onClickScoreRow}
          />
        )
      })
  }

  public render() {
    const { contacts } = this.props
    return (
      <div
        className={
          contacts.secondary
            ? 'client-score-history__list client-score-history__list--couple'
            : 'client-score-history__list'
        }>
        <Tile header={this.tileHeader()} contentStyle={tileContentStyle}>
          <div className='client-score-history__table'>
            <div className='client-score-history__table-header'>
              <div className='client-score-history__table-header-score'>
                SATISFACTION SCORE
              </div>
              <div className='client-score-history__table-header-created'>
                DATE CREATED
              </div>
            </div>
            {this.clientScoreSessions()}
          </div>
        </Tile>
      </div>
    )
  }
}

const setHouseholdContact = (
  contacts: ContactsInterface,
  contactId: string
): ContactObj => {
  if (!contacts) {
    return null
  }
  if (contacts.secondary && contacts.secondary.id === contactId) {
    return contacts.secondary
  } else {
    return contacts.primary
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, contactId, exerciseId }: any
) => {
  const contact = setHouseholdContact(store.contact[householdFinId], contactId)

  const honestConversation =
    store.hcExercises[householdFinId] &&
    store.hcExercises[householdFinId][exerciseId]

  const clientScoringSessions =
    contact && honestConversation.clientScoringSessions[contact.id]

  return {
    householdFinId,
    household: store.households[householdFinId],
    contacts: store.contact[householdFinId],
    contact,
    honestConversation,
    clientScoringSessions
  }
}

export default connect(mapStateToProps)(ClientScoreList)
