import React from 'react'
import Modal from '../../components/layout/modal'

interface ConfirmActionProps {
  title: string
  message?: string
  boldMessage?: string
  closeFunc(): void
  confirmFunc(): void
}

class ConfirmActionModal extends React.Component<ConfirmActionProps> {
  public render() {
    const { confirmFunc, closeFunc, title, message, boldMessage } = this.props
    return (
      <Modal title={title} closeModal={closeFunc}>
        <div className='confirm-modal-dataSource'>
          <div className='confirm-modal-dataSource__content'>
            <p>{message}</p>
            <b>{boldMessage}</b>
          </div>
          <div className='confirm-modal-dataSource__buttons'>
            <span className='btn btn__clear' onClick={closeFunc}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={confirmFunc}>
              Change
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ConfirmActionModal
