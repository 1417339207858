import React from 'react'
import ScoreSelectionModal from '../../../honestConversations/exercise/pickCombinedCards/scoreSelectionModal'
import HonestConversationCardSelectModal from '../../meeting/hcCardSelectModal'
import { Draggable } from 'react-beautiful-dnd'
import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import pencil from '../../../../assets/images/honestConversations/pencil.png'
import couple from '../../../../assets/images/honestConversations/couple.png'
// import switchIcon from '../../../../assets/images/honestConversations/switch.png'
import { ReactComponent as PalIcon } from '../../../../assets/images/icons/pal.svg'

import {
  HouseholdCardObj,
  HonestConversationsExerciseObj,
  CardRankingObj
} from '../../../../../objects/honestConversations'
import { ContactsInterface } from '../../../../../objects/contact'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import { CardObj } from '../../../../../objects/HonestConversations/card'
import {
  updateHonestConversationHouseholdCards,
  getHonestConversationExercise,
  updateClientCards
} from '../../../../../actions/hcExercises'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'

interface CombinedCardProps {
  householdCards: HouseholdCardObj[]
  primaryScore: number
  secondaryScore: number
  card: HouseholdCardObj
  hcExercise: HonestConversationsExerciseObj
  contacts: ContactsInterface
  goalActions: FinancialGoalActionObj[]
  householdFinId: string
  index: number
  dispatch: Dispatch<GlobalState>
  completedSelectingCards: boolean
  instructionsInProgress: boolean
}

interface CombinedCardState {
  showCardSelectionModal: boolean
  showScoreSelectionModal: boolean
  householdCardsComplete: boolean
}

class CombinedCard extends React.Component<
  CombinedCardProps,
  CombinedCardState
> {
  constructor(props: CombinedCardProps) {
    super(props)
    this.state = {
      showCardSelectionModal: false,
      showScoreSelectionModal: false,
      householdCardsComplete: props.completedSelectingCards
    }
  }

  public componentDidUpdate = (prevProps: CombinedCardProps) => {
    /**
     * opens score modal on mount loaded with the first household card
     * when all household cards are selected
     */
    const { completedSelectingCards, instructionsInProgress } = this.props
    if (prevProps.completedSelectingCards !== completedSelectingCards) {
      this.setState({
        householdCardsComplete: this.props.completedSelectingCards
      })
      if (
        this.props.index === 0 &&
        completedSelectingCards &&
        !instructionsInProgress
      ) {
        this.openScoreSelectionModal()
      }
    }
  }

  public openScoreSelectionModal = () => {
    if (!this.state.showScoreSelectionModal) {
      this.setState({
        showScoreSelectionModal: true
      })
    }
  }

  public closeScoreSelectionModal = () => {
    this.setState({
      showScoreSelectionModal: false
    })
  }

  public toggleCardSelectionModal = () => {
    this.setState({
      showCardSelectionModal: !this.state.showCardSelectionModal
    })
  }

  /**
   * @param updatedHouseholdCard
   * In the case of single HH, update primary client cards with the new card
   */
  public singleClientCardUpdate = (updatedHouseholdCard: CardRankingObj) => {
    const { dispatch, householdFinId, hcExercise, contacts } = this.props
    const clientCards = Object.keys(hcExercise.clientCards[contacts.primary.id])
      .filter(
        (cardKey) =>
          hcExercise.clientCards[contacts.primary.id][cardKey].rankedWithin ===
          'All'
      )
      .map((index) => hcExercise.clientCards[contacts.primary.id][index])
      .sort((a, b) => a.ranking - b.ranking)

    const updatedClientCard = clientCards.splice(
      updatedHouseholdCard.ranking - 1,
      1
    )[0]
    updatedClientCard.card = updatedHouseholdCard.card
    updatedClientCard.cardId = updatedHouseholdCard.cardId
    clientCards.splice(updatedHouseholdCard.ranking - 1, 0, updatedClientCard)

    dispatch(
      updateClientCards(
        householdFinId,
        hcExercise.id,
        contacts.primary.id,
        clientCards
      )
    )
  }
  public selectCard = async (selectedCard: CardObj) => {
    const { dispatch, householdFinId, hcExercise, card, contacts } = this.props
    const updatedCard = { ...card, cardId: selectedCard.id, card: selectedCard }
    const updatedHouseholdCards = Object.keys(hcExercise.householdCards)
      .map((cardKey: string) => {
        return {
          ...hcExercise.householdCards[cardKey],
          cardId: hcExercise.householdCards[cardKey].card.id
        }
      })
      .sort((a, b) => a.ranking - b.ranking)
    updatedHouseholdCards.splice(card.ranking - 1, 1, updatedCard)
    await dispatch(
      updateHonestConversationHouseholdCards(
        householdFinId,
        hcExercise.id,
        updatedHouseholdCards
      )
    )

    // if single HH, update clientCards
    if (!contacts?.secondary?.id) {
      this.singleClientCardUpdate(updatedCard)
    }
    dispatch(getHonestConversationExercise(householdFinId, hcExercise.id))
  }

  public handleScoreModalIcon = () => {
    const { goalActions, primaryScore, secondaryScore } = this.props
    const activeGoalActions = goalActions?.filter((goal) => {
      return goal.inGuidecenter === true
    })
    if (activeGoalActions?.length || primaryScore || secondaryScore) {
      return (
        <div
          style={{ pointerEvents: 'auto' }}
          onClick={this.openScoreSelectionModal}
          className='combined-card__card-comment combined-card__card-comment--pal'>
          <PalIcon width='7px' style={{ pointerEvents: 'none' }} />
        </div>
      )
    } else {
      return (
        <div
          className='combined-card__card-comment'
          onClick={this.openScoreSelectionModal}>
          <img src={pencil} role='presentation' alt='' />
        </div>
      )
    }
  }

  public render() {
    const {
      householdCards,
      card,
      index,
      householdFinId,
      hcExercise
    } = this.props
    const { showCardSelectionModal, showScoreSelectionModal } = this.state
    return (
      <div key={card.id}>
        <Draggable draggableId={card.id} index={index}>
          {(provided, snapshot) => (
            <div
              className='combined-card__main-w'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                marginBottom: 15,
                ...provided.draggableProps.style
              }}>
              <Card
                category={card.card ? card.card.category : null}
                title={card.card ? card.card.title : null}
              />
              {this.handleScoreModalIcon()}
              {/* <div
                className='combined-card__card-switch'
                onClick={this.toggleCardSelectionModal}>
                <img src={switchIcon} role='presentation' alt='' />
              </div> */}
              <div className='combined-card__card-shared'>
                <img
                  src={card.sharedCard ? couple : null}
                  role='presentation'
                  alt=''
                />
              </div>
            </div>
          )}
        </Draggable>
        {showCardSelectionModal ? (
          <HonestConversationCardSelectModal
            householdFinId={householdFinId}
            selectedCards={householdCards}
            selectCard={this.selectCard}
            closeModal={this.toggleCardSelectionModal}
          />
        ) : null}
        {showScoreSelectionModal && (
          <ScoreSelectionModal
            closeScoreSelectionModal={this.closeScoreSelectionModal}
            card={card}
            householdFinId={householdFinId}
            hcExercise={hcExercise}
            exerciseId={hcExercise.id}
          />
        )}
      </div>
    )
  }
}

export default connect()(CombinedCard)
