import React, { Component, BaseSyntheticEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'

import { dollarFormat } from '../../../helpers'
import { ClientAccountPositionsObj } from '../../../../objects/clientAccount'
import { GlobalState } from '../../../../reducers'
import { getClientAccounts } from '../../../../actions/clientAccounts'
import { updateOverrideAllocations } from '../../../../actions/positions'
import { addToast } from '../../../../actions/toasts'

import Modal from '../../../components/layout/modal'
import Input from '../../../components/Input'
import Button from '../../../components/button'

import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'

interface OverrideModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  clientAccountId: string
  position: ClientAccountPositionsObj
  closeModal(): void
}

interface OverrideModalState {
  toggleOverride: boolean
  allocationWarning: boolean
  overrideInput: {
    equityPercent: number
    fixedPercent: number
    altPercent: number
    cashPercent: number
    unclassifiedPercent: number
  }
}

class OverrideModal extends Component<OverrideModalProps, OverrideModalState> {
  constructor(props: OverrideModalProps) {
    super(props)
    const {
      equityPercent,
      fixedPercent,
      altPercent,
      cashPercent,
      unclassifiedPercent
    } = this.props.position
    const unclassifiedCalc =
      equityPercent + fixedPercent + altPercent + cashPercent === 0 ||
      (!equityPercent && !fixedPercent && !altPercent && !cashPercent)
        ? 100
        : unclassifiedPercent
    this.state = {
      toggleOverride: false,
      allocationWarning: false,
      overrideInput: {
        equityPercent,
        fixedPercent,
        altPercent,
        cashPercent,
        unclassifiedPercent: unclassifiedCalc
      }
    }
  }

  public toggleOverrideValues = () => {
    this.setState({ toggleOverride: !this.state.toggleOverride })
  }

  public handleInputChange = (event: BaseSyntheticEvent) => {
    const name = `${event.currentTarget.name.toLowerCase()}Percent`
    const value = Number(event.currentTarget.value)
    this.setState((prevState) => ({
      ...prevState,
      overrideInput: {
        ...prevState.overrideInput,
        [name]: value
      }
    }))
  }

  public verifyAllocationTotal = () => {
    return Object.values(this.state.overrideInput).reduce(
      (curr, acc) => (acc += curr),
      0
    )
  }

  public saveOverrideAllocations = async () => {
    const { dispatch, householdFinId, position, closeModal } = this.props
    if (this.verifyAllocationTotal() === 100) {
      await dispatch(
        updateOverrideAllocations(
          householdFinId,
          position.id,
          this.state.overrideInput
        )
      )
      await dispatch(getClientAccounts(householdFinId))
      dispatch(
        addToast({
          message: 'Allocations successfully updated',
          backgroundColor: '#D9F2B6'
        })
      )
      closeModal()
      this.setState({ allocationWarning: false })
    } else {
      this.setState({ allocationWarning: true })
    }
  }

  public renderReadonlyRow = (input: any[]) => {
    return input.map((type, index) => {
      return (
        <div
          key={index}
          className={'form-group client-account-details__inputs-readonly'}>
          <Input
            readOnly={true}
            inputType='text'
            content={type.dollar ? dollarFormat(type.value, 0) : type.value}
            title={type.title}
            controlFunc={this.handleInputChange}
          />
        </div>
      )
    })
  }

  public renderAllocationRow = (input: string[]) => {
    const { overrideInput } = this.state
    return input.map((type: string, index: number) => {
      const value = `${type.toLowerCase()}Percent`
      return (
        <Input
          key={index}
          name={type}
          inputType='number'
          content={overrideInput[value] ? overrideInput[value] : 0}
          title={type}
          percentageSign={true}
          controlFunc={this.handleInputChange}
          max={100}
          maxLength={3}
          placeholder='0'
        />
      )
    })
  }

  public renderOverrideAllocations = () => {
    const {
      tickerSymbol,
      securityDescription,
      quantity,
      value,
      price
    } = this.props.position
    const firstRow = [
      { title: 'Symbol', value: tickerSymbol, dollar: false },
      { title: 'Description', value: securityDescription, dollar: false }
    ]
    const secondRow = [
      { title: 'Quantity', value: quantity, dollar: false },
      { title: 'Value', value, dollar: true },
      { title: 'Price', value: price, dollar: true }
    ]
    const allocationRow = ['Equity', 'Fixed', 'Alt', 'Cash', 'Unclassified']
    return (
      <div>
        <div className='form-inline client-account-modal__allocation'>
          {this.renderReadonlyRow(firstRow)}
        </div>
        <div className='form-inline client-account-modal__allocation'>
          {this.renderReadonlyRow(secondRow)}
        </div>
        <div className='form-inline client-account-modal__allocation'>
          {this.renderAllocationRow(allocationRow)}
        </div>
      </div>
    )
  }

  public render(): JSX.Element {
    const { closeModal } = this.props
    return (
      <Modal
        icon={EditIcon}
        title='OVERRIDE ALLOCATION'
        closeModal={closeModal}>
        <div className='modal-body client-account-modal'>
          {this.renderOverrideAllocations()}
          {this.state.allocationWarning ? (
            <p className='holdings__override-modal-error'>
              Allocation must equal to 100%, current set allocation{' '}
              {this.verifyAllocationTotal()}%
            </p>
          ) : null}
          <div className='pal-confirm-modal__buttons'>
            <Button onClick={closeModal} clear={true}>
              Cancel
            </Button>
            <Button onClick={this.saveOverrideAllocations} primary={true}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    clientAccountId: match.params.clientAccountId
  }
}

export default withRouter(connect(mapStateToProps)(OverrideModal))
