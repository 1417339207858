import React, { Component } from 'react'

export class Footer extends Component {
  public render = () => {
    return (
      <footer className='im-global__footer-w'>
        <div className='im-global__footer-inner'>{this.props.children}</div>
      </footer>
    )
  }
}

export default Footer
