import React from 'react'
import { ContactsInterface } from '../../../objects/contact'
import HonestConversationNullState from '../../assets/images/nullStates/HonestConversationNullState.png'
import Button from '../../components/button'
import { Link } from 'react-router-dom'

export interface HonestConversationsNullRedoProps {
  householdFinId: string
  contacts: ContactsInterface
  showSendLinkModal?(e?: string): void
  launchHCExercise(e?: string): void
}

class HonestConversationsNullRedo extends React.Component<
  HonestConversationsNullRedoProps
> {
  public render() {
    const { householdFinId, launchHCExercise, showSendLinkModal } = this.props
    return (
      <div className='hc-null-redo'>
        <div className='hc-null-redo__null-img-w'>
          <img
            className='hc-null-redo__null-img'
            src={HonestConversationNullState}
            alt='null-state'
          />
        </div>
        <div className='hc-null-redo__button-w'>
          <span className='hc-null-redo__no-result-text'>No results yet.</span>
          <span className='hc-null-redo__selection-text'>
            Select one of the options below in order to get started.
          </span>
          <Button
            primary={true}
            style={{ width: '224px' }}
            onClick={showSendLinkModal}>
            Send Link
          </Button>
          <Link
            to={`/households/${householdFinId}/honestConversations/meetings/new`}>
            <Button primary={true} style={{ margin: '16px 0', width: '224px' }}>
              Enter Results
            </Button>
          </Link>
          <Button
            onClick={launchHCExercise}
            primary={true}
            style={{ margin: 0, width: '224px' }}>
            Launch Exercise
          </Button>
        </div>
      </div>
    )
  }
}

export default HonestConversationsNullRedo
