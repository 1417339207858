import React, { Component, Fragment } from 'react'
import { DotOval } from '../../dotOval'
import Modal from '../../../../components/layout/modal'
import FinancialCharts from '../../../../components/graphs/financialCharts'

interface StrategyRecGraphProps {
  chartOptions: any
  graphColors: string[]
  strategyRecommData: any
  openViewDetailsDialog: any
  handleViewDetails(): void
}

class StrategyRecGraph extends Component<StrategyRecGraphProps> {
  public getStrategyChartProps = (
    stratgeyObject: number[],
    colors: string[]
  ) => {
    const backgroundColors: string[] = []
    return {
      datasets: [
        {
          data: stratgeyObject.map((acc: any, index: number) => {
            backgroundColors.push(colors[index])
            return acc.percent
          }),
          backgroundColor: backgroundColors
        }
      ]
    }
  }

  public renderGraph = () => {
    const { graphColors, chartOptions } = this.props
    const {
      proposedStrategyPercents,
      currentStrategyPercents
    } = this.props.strategyRecommData
    const total = proposedStrategyPercents.reduce((acc: any, curr: any) => {
      return (acc += curr.amount)
    }, 0)
    const proposedChartValues = this.getStrategyChartProps(
      proposedStrategyPercents,
      graphColors
    )
    const currentChartValues = this.getStrategyChartProps(
      currentStrategyPercents,
      graphColors
    )

    return (
      <div className='proposed-portfolio__graph-wrapper'>
        <div>
          <div className='proposed-portfolio__graph'>
            <div className='proposed-portfolio__graph-relative'>
              <FinancialCharts
                canvasId='current-allocation'
                chartData={currentChartValues}
                chartOptions={chartOptions}
                dimensions={{ height: 100, width: 100 }}
              />
              <div className='proposed-portfolio__graph-target'>
                <p>CURRENT</p>
              </div>
            </div>
            <div className='proposed-portfolio__graph-data-point'>
              {currentStrategyPercents.map((acc: any, idx: number) => (
                <DotOval
                  backgroundColor={graphColors[idx]}
                  toolTipLabel={acc.name}
                  key={idx}
                  title={`${acc.name.substring(0, 16)}...`}
                  value={acc.percent.toFixed(1) + '%'}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='proposed-portfolio__graph__divider-wrapper'>
          <div className='proposed-portfolio__graph__divider' />
        </div>
        <div>
          <div className='proposed-portfolio__graph'>
            <div className='proposed-portfolio__graph-relative'>
              <FinancialCharts
                canvasId='current-allocation'
                chartData={proposedChartValues}
                chartOptions={chartOptions}
                dimensions={{ height: 100, width: 100 }}
              />

              <div className='proposed-portfolio__graph-target'>
                <p>PROPOSED</p>
              </div>
            </div>
            <div className='proposed-portfolio__graph-data-point'>
              {proposedStrategyPercents.map((acc: any, idx: number) => (
                <DotOval
                  backgroundColor={graphColors[idx]}
                  toolTipLabel={acc.name}
                  key={idx}
                  title={`${acc.name.substring(0, 16)}...`}
                  value={((acc.amount / total) * 100).toFixed(2) + '%'}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { handleViewDetails } = this.props
    const {
      proposedStrategyPercents,
      currentStrategyPercents
    } = this.props.strategyRecommData
    return (
      <Fragment>
        {(proposedStrategyPercents.length > 1 ||
          currentStrategyPercents.length > 1) && (
          <div
            className='proposed-portfolio__view-details'
            onClick={handleViewDetails}>
            View Details
          </div>
        )}
        {this.props.openViewDetailsDialog && (
          <Modal
            title='PROPOSED PORTFOLIO STRATEGIES'
            closeModal={handleViewDetails}
            size='L'>
            <div className='proposed-portfolio__view-details__content-wrapper'>
              {this.renderGraph()}
            </div>
          </Modal>
        )}
        {this.renderGraph()}
      </Fragment>
    )
  }
}

export default StrategyRecGraph
