import React from 'react'
import EmailPreviewModal from '../../components/layout/emailPreviewModal'
import { ContactObj } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'
import { InstitutionObj } from '../../../objects/institution'
import * as moneymindActions from '../../../actions/moneymind'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { ymmQuizURL } from '../../helpers/ymmQuizURL'
import { ymmQuizIsNewVersion } from '../../helpers/ymmQuizIsNewVersion'

export interface MoneyMindEmailModalProps {
  contact: ContactObj
  user: UserObj
  institution: InstitutionObj
  dispatch: Dispatch<GlobalState>
  saveModal(id: string, token?: string): void
  closeModal(): void
}

interface MoneyMindEmailModalState {
  loading: boolean
  link: string
  token: string
}

export class MoneyMindEmailModal extends React.Component<
  MoneyMindEmailModalProps,
  MoneyMindEmailModalState
> {
  constructor(props: MoneyMindEmailModalProps) {
    super(props)

    this.state = {
      loading: ymmQuizIsNewVersion(),
      link: ymmQuizURL(props.contact.id),
      token: ''
    }
  }

  public handleSend = () => {
    const { contact, saveModal, closeModal } = this.props
    const { token } = this.state
    saveModal(contact.id, token)
    closeModal()
  }

  async componentDidMount() {
    const { contact, dispatch, closeModal } = this.props

    if (ymmQuizIsNewVersion()) {
      try {
        const action = await dispatch(
          moneymindActions.getMoneyMindToken(contact.id)
        )
        const data = action['value']['data']
        this.setState({
          token: data.token,
          loading: false,
          link: ymmQuizURL(data.token)
        })
      } catch (e) {
        console.error(e)
        closeModal()
      }
    } else {
      this.setState({
        link: ymmQuizURL(contact.id)
      })
    }
  }

  public render() {
    const { institution, contact, user, closeModal } = this.props
    const { loading, link } = this.state
    return (
      <EmailPreviewModal
        contact={contact}
        user={user}
        subject='Your Unique MoneyMind® - Find Out Now'
        showCopyLink={true}
        link={link}
        emailSentDate={contact.moneymindEmailDate}
        whiteBackground={true}
        saveModal={this.handleSend}
        loading={loading}
        closeModal={closeModal}>
        <div>
          <img
            src={
              window._env_.REACT_APP_YMM_URL + '/images/mm_results_header.png'
            }
            alt='MoneyMind'
          />
        </div>
        <div className='moneymind-modal__email-w'>
          <h1 className='moneymind-modal__email-header'>
            Take Control of Your Financial Future
          </h1>
          <p className='moneymind-modal__email-p name-label'>
            {contact.firstName},
          </p>
          <p className='moneymind-modal__email-p'>
            Our goal is to serve your specific needs and to help you make
            decisions that work best for you. To do that effectively, we'd like
            to first understand how you think and feel about money.
          </p>
          <p className='moneymind-modal__email-p'>
            The <span>MoneyMind® analyzer</span> is an innovative, proprietary
            tool that provides insights on your instinctive approach to money
            and ways to improve your financial life.
          </p>
          <p className='moneymind-modal__email-p'>
            <a target='_blank' rel='noopener noreferrer' href={link}>
              Click here
            </a>{' '}
            to begin, and we'll discuss the results during our next meeting.
          </p>
          <p className='moneymind-modal__email-p'>Best regards,</p>
          {user.firstName || user.lastName || user.title ? (
            <p className='moneymind-modal__email-p'>
              <span>{user.firstName + ' ' + user.lastName}</span>
              <br />
              {user.title}
            </p>
          ) : null}
          {user.street || user.city || user.state || user.zip ? (
            <p className='moneymind-modal__email-p'>
              {user.street}
              <br />
              {user.city + ', ' + user.state + ' ' + user.zip}
            </p>
          ) : null}
          {user.home || user.mobile ? (
            <p className='moneymind-modal__email-p'>
              {user.home + ' | Main'}
              <br />
              {user.mobile + ' | Other'}
            </p>
          ) : null}
          {user.email && (
            <p className='moneymind-modal__email-p moneymind-modal__email-link'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={'mailto:' + user.email}>
                {user.email}
              </a>
            </p>
          )}
          {institution?.imageUrl && (
            <p className='moneymind-modal__email-p name-label'>
              <img
                className='moneymind-modal__email-image'
                src={institution.imageUrl}
                alt='Logo'
              />
            </p>
          )}
          {institution?.emailDisclaimer && (
            <p
              className='moneymind-modal__email-p'
              dangerouslySetInnerHTML={{
                __html: institution.emailDisclaimer
              }}
            />
          )}
        </div>
      </EmailPreviewModal>
    )
  }
}

export default connect()(MoneyMindEmailModal)
