import { HouseholdsState } from '../../../reducers/households'

export const applyInstitutionFilterHelper = (
  selectedInstitutions: string[],
  households?: HouseholdsState
) => {
  if (!selectedInstitutions?.length) {
    return households
  }
  const householdsFilteredByInstitution = {}
  households &&
    Object.keys(households).forEach((household) => {
      Object.keys(selectedInstitutions).forEach((e) => {
        if (selectedInstitutions[e] === households[household].institutionId) {
          householdsFilteredByInstitution[household] = households[household]
        }
      })
    })
  return householdsFilteredByInstitution
}
