import React from 'react'
import Modal from '../../../components/layout/modal'
import { ReactComponent as HonestConversationsIcon } from '../../../assets/images/icons/honestconversations.svg'
import HonestConversationCardSelectModalRow from './hcCardSelectModalRow'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../objects/HonestConversations/card'
import {
  CardRankingObj,
  ClientCardObj
} from '../../../../objects/honestConversations'
import { GlobalState } from '../../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { getHCCards } from '../../../../actions/hcExerciseCards'

interface HonestConversationCardSelectModalProps {
  selectedCards: CardRankingObj[] | ClientCardObj[]
  householdFinId: string
  cardOptions?: CardOptionsInterface
  dispatch: Dispatch<GlobalState>
  protectionCards: CardObj[]
  commitmentCards: CardObj[]
  happinessCards: CardObj[]
  selectCard(card: CardObj, index?: number): void
  closeModal(): void
}

interface HonestConversationCardSelectModalState {
  selectedTab: string
}

export class HonestConversationCardSelectModal extends React.Component<
  HonestConversationCardSelectModalProps,
  HonestConversationCardSelectModalState
> {
  constructor(props: HonestConversationCardSelectModalProps) {
    super(props)
    this.state = {
      selectedTab: 'Protection'
    }
  }

  public componentDidMount() {
    const {
      householdFinId,
      commitmentCards,
      happinessCards,
      protectionCards,
      dispatch
    } = this.props
    if (
      commitmentCards.length === 0 &&
      happinessCards.length === 0 &&
      protectionCards.length === 0
    ) {
      dispatch(getHCCards(householdFinId))
    }
  }

  public updateSelectedTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget
    this.setState({ selectedTab: id })
  }
  public modalTitle = () => {
    return (
      <div>
        Select HonestConversations<sup key='reg'>&reg;</sup> Card
      </div>
    )
  }
  public selectCard = (card: CardObj) => {
    this.props.selectCard(card)
    this.props.closeModal()
  }
  public cardList = (cards: CardObj[]) => {
    const { selectedCards } = this.props
    const availableCards = cards.filter((card) => {
      let cardAlreadySelected = false
      selectedCards &&
        selectedCards.forEach(
          (selectedCard: CardRankingObj | ClientCardObj) => {
            cardAlreadySelected =
              (selectedCard.card && selectedCard.card.id === card.id) ||
              cardAlreadySelected
          }
        )
      return !cardAlreadySelected
    })

    return availableCards.map((card) => {
      return (
        <HonestConversationCardSelectModalRow
          key={card.title}
          card={card}
          selectCard={this.selectCard}
        />
      )
    })
  }

  public selectedTabContent = () => {
    const { selectedTab } = this.state
    const { protectionCards, happinessCards, commitmentCards } = this.props
    switch (selectedTab) {
      case 'Protection':
        return this.cardList(protectionCards)
      case 'Commitment':
        return this.cardList(commitmentCards)
      case 'Happiness':
        return this.cardList(happinessCards)
      default:
        return this.cardList(protectionCards)
    }
  }
  public render() {
    const { selectedTab } = this.state
    return (
      <Modal
        title={this.modalTitle()}
        icon={HonestConversationsIcon}
        closeModal={this.props.closeModal}>
        <div className='hc-meeting__header-tab-w'>
          <div
            id='Protection'
            onClick={this.updateSelectedTab}
            className={
              selectedTab === 'Protection'
                ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                : 'hc-meeting__header-tab'
            }>
            Protection
          </div>
          <div
            id='Commitment'
            onClick={this.updateSelectedTab}
            className={
              selectedTab === 'Commitment'
                ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                : 'hc-meeting__header-tab'
            }>
            Commitment
          </div>
          <div
            id='Happiness'
            onClick={this.updateSelectedTab}
            className={
              selectedTab === 'Happiness'
                ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                : 'hc-meeting__header-tab'
            }>
            Happiness
          </div>
        </div>
        <div>{this.selectedTabContent()}</div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    protectionCards: store.hcExerciseCards.Protection,
    happinessCards: store.hcExerciseCards.Happiness,
    commitmentCards: store.hcExerciseCards.Commitment
  }
}

export default connect(mapStateToProps)(HonestConversationCardSelectModal)
