import React, { Component, ChangeEvent, FormEvent } from 'react'
import { connect, Dispatch } from 'react-redux'

import * as actions from '../../../actions/households'
import { GlobalState } from '../../../reducers'
import { HouseholdObj } from '../../../objects/household'
import { formatCurrencyInput } from '../../helpers'
import Modal from '../../components/layout/modal'
import Input from '../../components/Input'

import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'

interface EditOverrideAllocationProps {
  dispatch: Dispatch<GlobalState>
  targetEquityAllocation: number
  targetFixedAllocation: number
  targetOtherAllocation: number
  targetCashAllocation: number
  household: HouseholdObj
  setType: boolean
  submitForm(allocations: allocationState): void
  closeModal(): void
}

interface EditOverrideAllocationState {
  allocations: allocationState
  allocationWarning: boolean
}

interface allocationState {
  targetEquityAllocation: string
  targetFixedAllocation: string
  targetOtherAllocation: string
  targetCashAllocation: string
}

class EditTargetAllocationModal extends Component<
  EditOverrideAllocationProps,
  EditOverrideAllocationState
> {
  constructor(props: EditOverrideAllocationProps) {
    super(props)
    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = this.props.household
    this.state = {
      allocations: {
        targetEquityAllocation: targetEquityAllocation
          ? String(targetEquityAllocation)
          : '',
        targetFixedAllocation: targetFixedAllocation
          ? String(targetFixedAllocation)
          : '',
        targetOtherAllocation: targetOtherAllocation
          ? String(targetOtherAllocation)
          : '',
        targetCashAllocation: targetCashAllocation
          ? String(targetCashAllocation)
          : ''
      },
      allocationWarning: false
    }
  }

  public checkAllocations = (
    cash: number,
    equity: number,
    fixed: number,
    other: number
  ) => () => {
    const { household, dispatch, submitForm } = this.props
    if (cash + equity + fixed + other === 100) {
      this.setState({ allocationWarning: false })
      dispatch(
        actions.setTargetAllocation(household.id, cash, equity, fixed, other)
      )
      submitForm(this.state.allocations)
    } else {
      this.setState({ allocationWarning: true })
    }
  }

  public allocationInputSetter = (
    event: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget
    const newState = this.state
    newState.allocations[target.name] = formatCurrencyInput(target.value)
    this.setState({
      ...newState,
      allocationWarning: this.state.allocationWarning
    })
  }

  public inputFields = (name: string, titleName: string, contents: any) => {
    return (
      <div className='form-group'>
        <Input
          maxLength={3}
          percentageSign={true}
          title={titleName}
          inputType='text'
          name={name}
          controlFunc={this.allocationInputSetter}
          content={contents !== '' ? contents : contents}
        />
      </div>
    )
  }

  public render() {
    const { closeModal, setType } = this.props
    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = this.state.allocations
    const cash = Number(targetCashAllocation)
    const equity = Number(targetEquityAllocation)
    const fixed = Number(targetFixedAllocation)
    const other = Number(targetOtherAllocation)
    const setAllocation = cash + equity + fixed + other
    return (
      <Modal
        icon={setType ? PlusIcon : EditIcon}
        title={`${setType ? 'SET' : 'EDIT'} TARGET ALLOCATION`}
        closeModal={closeModal}>
        <div className='modal-body client-account-modal'>
          <div className='form-inline client-account-modal__allocation'>
            {this.inputFields(
              'targetEquityAllocation',
              'Equity',
              targetEquityAllocation
            )}
            {this.inputFields(
              'targetFixedAllocation',
              'Fixed',
              targetFixedAllocation
            )}
            {this.inputFields(
              'targetOtherAllocation',
              'Alt.',
              targetOtherAllocation
            )}
            {this.inputFields(
              'targetCashAllocation',
              'Cash',
              targetCashAllocation
            )}
          </div>
          {this.state.allocationWarning ? (
            <p style={{ color: '#F12938' }}>
              Allocation must equal to 100%, current set allocation{' '}
              {setAllocation}%
            </p>
          ) : null}
          <div className='pal-confirm-modal__buttons'>
            <span className='btn btn__clear' onClick={closeModal}>
              Cancel
            </span>
            <span
              className='btn btn__prime'
              onClick={this.checkAllocations(cash, equity, fixed, other)}>
              Save
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect()(EditTargetAllocationModal)
