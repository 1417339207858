import React, { Component } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { ContactObj } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'

import { dateFormat } from '../../helpers/index'

import Modal from '../../components/layout/modal'
import Button from '../../components/button'

import { ReactComponent as PreviewIcon } from '../../assets/images/icons/preview.svg'
import { ReactComponent as LinkIcon } from '../../assets/images/icons/link.svg'
import SpinnerLoader from '../../assets/images/loader.gif'

export interface EmailPreviewModalProps {
  contact: ContactObj
  user: UserObj
  subject: string
  showCopyLink?: boolean
  link?: string
  emailSentDate?: string
  whiteBackground?: boolean
  loading?: boolean
  saveModal(id: string): void
  closeModal(): void
}

class EmailPreviewModal extends Component<EmailPreviewModalProps> {
  public handleSend = () => {
    const { saveModal, contact, closeModal } = this.props
    saveModal(contact.id)
    closeModal()
  }

  public handleCancel = () => {
    this.props.closeModal()
  }

  public renderCopyLink = () => {
    return (
      <div>
        <LinkIcon />
        <CopyToClipboard onCopy={this.handleCancel} text={this.props.link}>
          <span className='email-preview-modal__copy'>Copy Link and Close</span>
        </CopyToClipboard>
      </div>
    )
  }

  public showLoader = () => {
    return (
      <div className='email-preview-modal__loader'>
        <img src={SpinnerLoader} width='64' height='64' alt='loader' />
        <div className='email-preview-modal__loader-title'>
          Loading Email Preview
        </div>
      </div>
    )
  }

  public render() {
    const {
      contact,
      user,
      closeModal,
      subject,
      children,
      showCopyLink,
      emailSentDate,
      whiteBackground,
      loading
    } = this.props

    return (
      <Modal icon={PreviewIcon} title='EMAIL PREVIEW' closeModal={closeModal}>
        {/* modal preview header */}
        <div className='email-preview-modal'>
          {loading ? (
            this.showLoader()
          ) : (
            <>
              {emailSentDate && (
                <div className='email-preview-modal__email-sent-date'>
                  Last email sent on {dateFormat(emailSentDate)}
                </div>
              )}
              <div className='email-preview-modal__recipient-w'>
                <div className='email-preview-modal__row'>
                  <div className='email-preview-modal__row-label'>
                    Advisor email{' '}
                  </div>
                  <div className='email-preview-modal__row-value'>
                    {user && user.email}
                  </div>
                </div>
                <div className='email-preview-modal__row'>
                  <div className='email-preview-modal__row-label'>
                    Client email{' '}
                  </div>
                  <div
                    className={
                      contact?.email
                        ? 'email-preview-modal__row-value'
                        : 'email-preview-modal__row-error'
                    }>
                    {contact?.email ||
                      'Please add the email address in your CRM and re-sync.'}
                  </div>
                </div>
                <div className='email-preview-modal__row'>
                  <div className='email-preview-modal__row-label'>
                    Subject:{' '}
                  </div>
                  <div className='email-preview-modal__row-value'>
                    {subject}
                  </div>
                </div>
              </div>
              <div className='email-preview-modal__line' />

              {/* content of email */}
              <div
                className={
                  whiteBackground
                    ? 'email-preview-modal__box email-preview-modal__box--white-bg'
                    : 'email-preview-modal__box'
                }>
                {children}
              </div>

              {/* modal button actions */}
              <div
                className={
                  showCopyLink
                    ? 'email-preview-modal__buttons email-preview-modal__buttons--space-between'
                    : 'email-preview-modal__buttons'
                }>
                {showCopyLink && this.renderCopyLink()}
                <div>
                  <Button clear={true} onClick={this.handleCancel}>
                    Cancel
                  </Button>
                  {contact?.email && (
                    <Button primary={true} onClick={this.handleSend}>
                      Send
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    )
  }
}

export default EmailPreviewModal
