import React from 'react'
import { history } from '../../../store'
import Button from '../../components/button'
import MainContainer from '../mainContainer'

export interface PageNotFoundProps {
  redirect?: boolean
}

export class PageNotFound extends React.Component<PageNotFoundProps> {
  public goBack = () => {
    const { redirect } = this.props
    redirect ? history.go(-2) : history.goBack()
  }
  public render() {
    return (
      <MainContainer noHeader={false}>
        <div className='error-w'>
          <h1 className='error-statuscode'>404</h1>
          <p className='error-title'>We’re having technical issues</p>
          <p className='error-message'>
            We can’t seem to find the page you’re looking for.
            <br />
            Go back and try another page, or contact Help.
          </p>
          <Button primary onClick={this.goBack}>
            Go Back
          </Button>
          <p className='error-outage-info'>
            Check for outages and subscribe to receive notifications{' '}
            <a target='_blank' href='https://status.finlife.com/'>
              here
            </a>
          </p>
        </div>
      </MainContainer>
    )
  }
}
