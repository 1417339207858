import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import ActionLoader from '../../components/actionLoader'
import { withRouter } from 'react-router-dom'
import { STRATEGY_LIST, IMStrategiesObj } from '../../../objects/strategies'
import { StrategySearchState } from '../../../objects/strategySearch'
import InnerContainer from '../../components/layout/innerContainer'
import StrategyFilterTags from './strategyFilterTags'
import StrategyHeader from './strategyHeader'
import StrategySortingBar from './strategySortingBar'
import StrategyTile from './strategyTile'
import {
  makeClassifiedStrategies,
  filterStrategyState,
  searchByName
} from '../../helpers/strategies'
import {
  clearAllFilters,
  getIMStrategies
} from '../../../actions/strategySearch'
import { getInstitution } from '../../../actions/institution'
import * as SortHelper from '../../helpers/householdPreferences'

export interface StrategySearchPageProps {
  dispatch: Dispatch<GlobalState>
  strategySearch: StrategySearchState
  searchBarValue: string
}

export interface StrategySearchPageState {
  sortBy: string
  ascending: boolean
}

/**
 *
 * Our default sort state is
 * sortBy : name, in an ascending order
 *
 */
export class StrategySearch extends Component<
  StrategySearchPageProps,
  StrategySearchPageState
> {
  constructor(props: StrategySearchPageProps) {
    super(props)
    this.state = {
      sortBy: 'name',
      ascending: false
    }
  }

  public validateInput = (typedInput: string | number): boolean => {
    if (!typedInput) {
      return true
    }
    return /^[0-9A-Za-z\s\-]+$/.test(typedInput.toString())
  }

  public componentDidMount() {
    const { dispatch } = this.props
    dispatch(getInstitution())
    dispatch(getIMStrategies())
    document.body.classList.add('strategy-search__body-styles')
  }

  public componentWillUnmount() {
    document.body.classList.remove('strategy-search__body-styles')
  }

  public sortFunc = (option: string, ascending: boolean) => {
    this.setState({
      sortBy: option,
      ascending
    })
  }

  public renderLoadingView = () => {
    const {
      strategySearch: { loading, strategies }
    } = this.props

    return loading && !strategies?.length ? <ActionLoader /> : null
  }

  public renderNullState = () => {
    const {
      dispatch,
      strategySearch: { loading }
    } = this.props

    const clear = (e: React.MouseEvent<HTMLSpanElement>) => {
      dispatch(clearAllFilters())
    }

    return !loading ? (
      <div className='strategy-search__item strategy-search__item-null'>
        <span className='strategy-search__item-null strategy-search__item-null--title'>
          No Results
        </span>
        <span className='strategy-search__item-null strategy-search__item-null--subtitle'>
          No results match selected filters. Try using less filters.
        </span>
        <span
          className='strategy-search__item-null strategy-search__item-null--clear'
          onClick={clear}>
          Clear Filters
        </span>
      </div>
    ) : null
  }

  /**
   * sorting
   */
  public sortStrategies = (
    strategies: IMStrategiesObj[]
  ): IMStrategiesObj[] => {
    const { sortBy, ascending } = this.state
    if (!strategies?.length) {
      return null
    }
    return strategies.sort((a, b) => {
      switch (sortBy) {
        case 'name':
        case 'managementStyle':
          return SortHelper.compareStringField(a[sortBy], b[sortBy], ascending)
        default:
          return SortHelper.compareNumericField(a[sortBy], b[sortBy], ascending)
      }
    })
  }

  public renderTiles = () => {
    const { strategySearch } = this.props
    // Filter
    let strategies = filterStrategyState(strategySearch)
    // Search by Name
    strategies = searchByName(strategySearch.search, strategies)
    // Classify into types
    const classifedStrategies = makeClassifiedStrategies(
      strategySearch,
      strategies
    )

    return STRATEGY_LIST.map((strategyName, index) => {
      const collection = this.sortStrategies(
        classifedStrategies[strategyName] || null
      )
      return collection ? (
        <StrategyTile
          key={index}
          displayName={strategyName}
          strategies={collection}
        />
      ) : null
    }).filter((component) => component)
  }

  public render() {
    const { sortBy, ascending } = this.state
    const strategyTiles = this.renderTiles()
    const loadingDisplay = this.renderLoadingView()
    const nullStateDisplay = this.renderNullState()

    return (
      <div className='strategy-search__main-w'>
        <StrategyHeader />
        {loadingDisplay}
        <InnerContainer>
          <StrategyFilterTags />

          {!strategyTiles.length ? (
            nullStateDisplay
          ) : (
            <StrategySortingBar
              sortBy={sortBy}
              ascending={ascending}
              sortFunc={this.sortFunc}
            />
          )}

          {strategyTiles}

          {!loadingDisplay ? (
            <div className='strategy-search__main-disclaimer'>
              <p>For Financial Professional Use Only.</p>
              <br />
              <p>
                The information, data, analyses and opinions contained herein
                (1) include the confidential and proprietary information of
                United Capital, (2) may not be copied or redistributed, (3) do
                not constitute investment advice offered by United Capital, (4)
                are provided solely for informational purposes and therefore are
                not an offer to buy or sell a security, and (5) are not
                warranted to be correct, complete or accurate. Except as
                otherwise required by law, United Capital shall not be
                responsible for any trading decisions, damages or other losses
                resulting from, or related to, this information, data, analyses
                or opinions or their use.
              </p>
              <br />
              <p>
                Investing involves risk and investors should carefully consider
                their own investment objectives and never rely on any single
                chart, graph or marketing piece to make decisions. Equity
                investing involves market risk, including possible loss of
                principal. There are no investment strategies that guarantee a
                profit or protect against a loss. Past performance does not
                guarantee future results. United Capital provides sub-manager
                services to non-affiliated investment advisors, to help service
                their client's investment management needs. When managing assets
                as a sub-manager, United Capital relies solely on the client’s
                advisor to determine what the specific needs and circumstances
                of each client are and to choose the investment options that are
                appropriate to help meet each client’s needs. United Capital
                solely relies on information provided by the client’s advisor
                and does not independently verify any information provided.
              </p>
              <br />
              <p>
                ©{new Date().getFullYear()} United Capital Financial Advisors,
                LLC. All Rights Reserved.
              </p>
            </div>
          ) : null}
        </InnerContainer>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    strategySearch: store.strategySearch,
    searchBarValue: store.strategySearch.search
  }
}

export default withRouter(connect(mapStateToProps)(StrategySearch))
