import React from 'react'
import { MoneyMindDescription } from './description'

interface MoneyMindProps {
  moneymind: any
}

export class MoneyMindDetails extends React.Component<MoneyMindProps> {
  constructor(props: MoneyMindProps) {
    super(props)
  }

  public getFirstTitle = (primary: any[], secondary: any[]) => {
    const colors = {
      protection: '#a2d134',
      commitment: '#0068ae',
      happiness: '#f49500'
    }

    if (primary[1] === secondary[1]) {
      return (
        <div className='moneymind__description-w'>
          BLENDED INFLUENCE:&nbsp;
          <span style={{ color: colors[primary[0]] }}>
            {primary[0].toUpperCase()}
          </span>
          <span> + </span>
          <span style={{ color: colors[secondary[0]] }}>
            {secondary[0].toUpperCase()}
          </span>
        </div>
      )
    } else {
      return (
        <div className='moneymind__description-w'>
          PRIMARY INFLUENCE:&nbsp;
          <span style={{ color: colors[primary[0]] }}>
            {primary[0].toUpperCase()}
          </span>
        </div>
      )
    }
  }

  public getSecondTitle = (
    primary: any[],
    secondary: any[],
    ternary: any[]
  ) => {
    const colors = {
      protection: '#a2d134',
      commitment: '#0068ae',
      happiness: '#f49500'
    }

    if (primary[1] === secondary[1]) {
      return (
        <div className='moneymind__description-w'>
          SECONDARY INFLUENCE:&nbsp;
          <span style={{ color: colors[ternary[0]] }}>
            {ternary[0].toUpperCase()}
          </span>
        </div>
      )
    } else {
      return (
        <div className='moneymind__description-w'>
          SECONDARY INFLUENCE:&nbsp;
          <span style={{ color: colors[secondary[0]] }}>
            {secondary[0].toUpperCase()}
          </span>
        </div>
      )
    }
  }

  public getThirdTitle = (primary: any[], secondary: any[], ternary: any[]) => {
    const colors = {
      protection: '#a2d134',
      commitment: '#0068ae',
      happiness: '#f49500'
    }

    if (primary[1] === secondary[1]) {
      return null
    } else {
      return (
        <div className='moneymind__description-w'>
          SECONDARY INFLUENCE:&nbsp;
          <span style={{ color: colors[ternary[0]] }}>
            {ternary[0].toUpperCase()}
          </span>
        </div>
      )
    }
  }

  public render() {
    const { protection, commitment, happiness } = this.props.moneymind
    const sortedMoneyMind = [
      ['protection', protection] as any,
      ['commitment', commitment] as any,
      ['happiness', happiness] as any
    ]
    sortedMoneyMind.sort((a, b) => {
      return b[1] - a[1]
    })

    const {
      firstDescription,
      secondDescription,
      thirdDescription
    } = this.props.moneymind
    return (
      <div style={{ float: 'left', width: '50%', padding: '5px' }}>
        {firstDescription ? (
          <MoneyMindDescription
            title={this.getFirstTitle(sortedMoneyMind[0], sortedMoneyMind[1])}
            description={firstDescription}
            blended={sortedMoneyMind[0][1] === sortedMoneyMind[1][1]}
          />
        ) : null}
        {secondDescription ? (
          <MoneyMindDescription
            title={this.getSecondTitle(
              sortedMoneyMind[0],
              sortedMoneyMind[1],
              sortedMoneyMind[2]
            )}
            description={secondDescription}
            blended={false}
          />
        ) : null}
        {thirdDescription ? (
          <MoneyMindDescription
            title={this.getThirdTitle(
              sortedMoneyMind[0],
              sortedMoneyMind[1],
              sortedMoneyMind[2]
            )}
            description={thirdDescription}
            blended={false}
          />
        ) : null}
      </div>
    )
  }
}
