import { HouseholdsState } from '../../../reducers/households'

export const applyOfficeTeamsFilterHelper = (
  selectedOfficeTeams: string[],
  households?: HouseholdsState
) => {
  if (!selectedOfficeTeams?.length) {
    return households
  }
  const householdsFilteredByOfficeTeams = {}
  households &&
    Object.keys(households).forEach((household) => {
      Object.keys(selectedOfficeTeams).forEach((e) => {
        if (selectedOfficeTeams[e] === households[household].officeTeam) {
          householdsFilteredByOfficeTeams[household] = households[household]
        }
      })
    })
  return householdsFilteredByOfficeTeams
}
