import React from 'react'
import qs from 'query-string'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { history } from '../../../store'
import { MSDynamicsCRM } from '../../../objects/institution'

interface TamaracIntegrationAuthProps {
  dispatch: Dispatch<GlobalState>
  crmSource: MSDynamicsCRM
}

class TamaracIntegrationAuth extends React.Component<
  TamaracIntegrationAuthProps
> {
  public async componentDidMount() {
    const grantToken = qs.parse(window.location.search).code
    const crmSource = qs.parse(window.location.search).state

    localStorage.setItem('msDynamicsCode', grantToken as string)
    history.push(`/administration/${crmSource}`)
  }
  public render() {
    return <div />
  }
}

export default connect()(TamaracIntegrationAuth)
