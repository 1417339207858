import gql from 'graphql-tag'

export const MessageBodyQuery = gql`
  query Messages($householdFinId: UUID!, $threadId: UUID!) {
    allMessages(
      condition: { household: $householdFinId }
      orderBy: [CREATED_DATE_ASC]
      filter: {
        or: [{ parent: { equalTo: $threadId } }, { id: { equalTo: $threadId } }]
      }
    ) {
      nodes {
        id
        message
        adviserByAdviserSender {
          adviser
          name
        }
        clientByClientSender {
          client
          name
        }
        createdDate
        thumbnailId
        videoId
      }
    }
  }
`

export const CreateMessageReplyQuery = gql`
  mutation CreateMessageReply(
    $value: String!
    $userId: String!
    $householdFinId: UUID!
    $threadId: UUID!
  ) {
    createMessage(
      input: {
        message: {
          message: $value
          adviserSender: $userId
          household: $householdFinId
          parent: $threadId
        }
      }
    ) {
      message {
        subject
        message
        adviserSender
      }
    }
  }
`

export const CreateMessageThreadQuery = gql`
  mutation CreateMessageThread(
    $message: String!
    $userId: String!
    $householdFinId: UUID!
    $subject: String!
  ) {
    createMessage(
      input: {
        message: {
          message: $message
          adviserSender: $userId
          household: $householdFinId
          subject: $subject
        }
      }
    ) {
      message {
        id
        subject
        message
        adviserSender
      }
    }
  }
`

export const GetMessageThreadQuery = gql`
  query MessageThreads($householdFinId: UUID!) {
    allMessages(condition: { household: $householdFinId, parent: null }) {
      nodes {
        id
        subject
        messagesByParent(orderBy: [CREATED_DATE_ASC], first: 1) {
          nodes {
            createdDate
          }
        }
        createdDate
        household
      }
    }
  }
`

export const GetAdvisorMessageThreadQuery = gql`
  query getMessagesByAdviser($userId: String) {
    allAdvisers(condition: { adviser: $userId }) {
      nodes {
        householdsByHouseholdToAdviserAdviserAndHousehold {
          totalCount
          nodes {
            messagesByHousehold(
              condition: { parent: null }
              orderBy: [CREATED_DATE_DESC]
            ) {
              totalCount
              nodes {
                id
                household
                parent
                message
                subject
                createdDate
                read
                messagesByParent {
                  nodes {
                    createdDate
                  }
                }
              }
            }
            household
          }
        }
      }
    }
  }
`

export const GetAdvisorNotificationSettingsQuery = gql`
  query getAdviserNotificationSettings($userId: String!) {
    allAdvisers(condition: { adviser: $userId }) {
      nodes {
        emailSubject
        desktopSubject
        mobileSubject
      }
    }
  }
`

export const SetAdvisorNotificationSettingsQuery = gql`
  mutation setAdviserNotificationSettings(
    $desktopSettings: [NotificationSubjects]
    $emailSettings: [NotificationSubjects]
    $mobileSettings: [NotificationSubjects]
    $userId: String!
  ) {
    updateAdviserByAdviser(
      input: {
        adviser: $userId
        adviserPatch: {
          desktopSubject: $desktopSettings
          emailSubject: $emailSettings
          mobileSubject: $mobileSettings
        }
      }
    ) {
      adviser {
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
  }
`

export const GetClientNotificationSettingsQuery = gql`
  query getClientNotificationSettings($clientId: UUID!) {
    allClients(condition: { client: $clientId }) {
      nodes {
        desktopSubject
        emailSubject
      }
    }
  }
`

export const SetClientNotificationSettingsQuery = gql`
  mutation setClientNotificationSettings(
    $desktopSettings: [NotificationSubjects]
    $emailSettings: [NotificationSubjects]
    $clientId: UUID!
  ) {
    updateClientByClient(
      input: {
        client: $clientId
        clientPatch: {
          desktopSubject: $desktopSettings
          emailSubject: $emailSettings
        }
      }
    ) {
      client {
        desktopSubject
        emailSubject
      }
    }
  }
`

export const GetAdvisorNotifications = gql`
  query getAdvisorNotifications($userId: String!) {
    allAdviserToNotifications(
      condition: { adviser: $userId }
      orderBy: [CREATED_DATE_DESC]
    ) {
      nodes {
        notificationByNotification {
          subject
          content
          createdDate
          title
        }
        read
        id
        delivered
        readDate
      }
    }
  }
`

export const AdvisorNotificationSubscription = gql`
  subscription {
    notification {
      id
      subject
      content
      createdDate
      title
    }
  }
`

export const MarkAdvisorNotificationAsRead = gql`
  mutation markAdvisorNotificationAsRead($notificationId: UUID!) {
    updateAdviserToNotificationById(
      input: { id: $notificationId, adviserToNotificationPatch: { read: true } }
    ) {
      clientMutationId
      adviserToNotification {
        id
        read
      }
    }
  }
`

export const MarkAdvisorNotificationAsReadDelivered = gql`
  mutation markAdvisorNotificationAsRead(
    $notificationId: UUID!
    $readDate: Datetime
  ) {
    updateAdviserToNotificationById(
      input: {
        id: $notificationId
        adviserToNotificationPatch: {
          read: true
          delivered: true
          readDate: $readDate
        }
      }
    ) {
      clientMutationId
      adviserToNotification {
        id
        read
        delivered
        readDate
      }
    }
  }
`

export const CheckAdvisorHouseholdAccess = gql`
  query getAdviserHouseholds($userId: String!, $householdId: UUID!) {
    allHouseholdToAdvisers(
      condition: { adviser: $userId, household: $householdId }
    ) {
      nodes {
        household
      }
    }
  }
`

export const MarkNotificationsRead = gql`
  mutation markRead($userId: String!, $ids: [UUID]!, $readDate: Datetime) {
    markAdviserNotificationsRead(
      input: { userId: $userId, ids: $ids, readDate: $readDate }
    ) {
      data {
        notificationByNotification {
          subject
          content
          createdDate
          title
        }
        read
        readDate
        delivered
        deliveredDate
        id
      }
    }
  }
`
