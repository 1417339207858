import React from 'react'
import CheckImage from '../../assets/images/icons/png/check.png'

const Checkmark = ({ active }: { active: boolean }) => {
  const checkActive = active ? 'ivf-checkmark--checked' : ''
  return (
    <span className={`ivf-checkmark ${checkActive}`}>
      <img
        src={CheckImage}
        role='checkbox'
        aria-checked={active}
        alt={active ? 'checked' : 'unchecked'}
      />
    </span>
  )
}

export default Checkmark
