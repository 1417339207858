import React, { Component } from 'react'
import { NetWorthErrorFilterType } from '../../../objects/clientFilter'
import CheckBox from '../../components/checkbox'

export interface ClientFilterNetworthProps {
  selectedNetWorthErrorFilter: NetWorthErrorFilterType
  setNewNetWorthErrorFilter(
    selectedNetWorthErrorFilter: NetWorthErrorFilterType
  ): void
}

export class NetWorthErrorFilter extends Component<ClientFilterNetworthProps> {
  public toggleYesBox = () => {
    const {
      selectedNetWorthErrorFilter,
      setNewNetWorthErrorFilter
    } = this.props
    let newNetWorthFilter: NetWorthErrorFilterType
    switch (selectedNetWorthErrorFilter) {
      case 'none checked':
        newNetWorthFilter = 'networth errors only'
        break
      case 'networth errors only':
        newNetWorthFilter = 'none checked'
        break
      case 'no networth errors':
        newNetWorthFilter = 'all checked'
        break
      case 'all checked':
        newNetWorthFilter = 'no networth errors'
        break
    }
    setNewNetWorthErrorFilter(newNetWorthFilter)
  }

  public toggleNoBox = () => {
    const {
      selectedNetWorthErrorFilter,
      setNewNetWorthErrorFilter
    } = this.props
    let newNetWorthFilter: NetWorthErrorFilterType
    switch (selectedNetWorthErrorFilter) {
      case 'none checked':
        newNetWorthFilter = 'no networth errors'
        break
      case 'networth errors only':
        newNetWorthFilter = 'all checked'
        break
      case 'no networth errors':
        newNetWorthFilter = 'none checked'
        break
      case 'all checked':
        newNetWorthFilter = 'networth errors only'
        break
    }
    setNewNetWorthErrorFilter(newNetWorthFilter)
  }

  public render() {
    const { selectedNetWorthErrorFilter } = this.props

    return (
      <div>
        <div className='institution-filter__institution-dropdown-sub-header'>
          Net Worth Error
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '25px' }}>
            <CheckBox
              checkedBorder={true}
              checked={
                selectedNetWorthErrorFilter === 'all checked' ||
                selectedNetWorthErrorFilter === 'networth errors only'
              }
              onChange={this.toggleYesBox}
              label='Yes'
            />
          </div>
          <div style={{ marginRight: '25px' }}>
            <CheckBox
              checkedBorder={true}
              checked={
                selectedNetWorthErrorFilter === 'all checked' ||
                selectedNetWorthErrorFilter === 'no networth errors'
              }
              onChange={this.toggleNoBox}
              label='No'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NetWorthErrorFilter
