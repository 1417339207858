import { NetWorthErrorFilterType } from '../../../objects/clientFilter'
import { HouseholdsState } from '../../../reducers/households'

export const applyNetWorthErrorFilterHelper = (
  showNetWorthErrors: NetWorthErrorFilterType,
  households: HouseholdsState
) => {
  if (
    showNetWorthErrors === 'all checked' ||
    showNetWorthErrors === 'none checked'
  ) {
    return households
  }
  const filteredHouseHolds: HouseholdsState = {}
  Object.keys(households).forEach((key) => {
    const hasClientAccountErrors = households[key].hasClientAccountErrors
    if (
      (hasClientAccountErrors &&
        showNetWorthErrors === 'networth errors only') ||
      (!hasClientAccountErrors && showNetWorthErrors === 'no networth errors')
    ) {
      filteredHouseHolds[key] = households[key]
    }
  })
  return filteredHouseHolds
}
