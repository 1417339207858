import React, { Component } from 'react'
import { connect } from 'react-redux'

import { HouseholdsState } from '../../../reducers/households'
import { GlobalState } from '../../../reducers'
import { history } from '../../../store'
import Tile from '../../components/layout/tile'
import Heading from './heading'
import MessageNull from '../../components/messages/messageNull'
import MessageThreadList from '../messages/messageThreadList'
import Button from '../../components/button'
import MessagesError from '../../components/messages/error'

import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { ReactComponent as MessagesIcon } from '../../assets/images/icons/messages.svg'

interface DashMessagesProps {
  householdFinId: string
  households: HouseholdsState
}

interface DashMessageState {
  error: boolean
}

export class DashMessages extends Component<
  DashMessagesProps,
  DashMessageState
> {
  constructor(props: DashMessagesProps) {
    super(props)

    this.state = { error: false }
  }
  public nullMessages = () => {
    return (
      <div className='message-null-w message-null-w--dashboard'>
        <MessageNull />
        <Button primary={true} onClick={this.navigateToMessages}>
          Send a Message
        </Button>
      </div>
    )
  }

  public navigateToMessages = () => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/messages`)
  }

  public navigateToThread = (threadId: string) => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/messages/${threadId}`)
  }

  public renderRightHeader = () => (
    <>
      {this.state.error && <MessagesError showLabel={false} />}
      <img src={ArrowRightIcon} alt='' role='presentation' />
    </>
  )

  public handleError = () => {
    if (!this.state.error) {
      this.setState({ error: true })
    }
  }

  public render() {
    const { households, householdFinId } = this.props
    const household = households[householdFinId]
    return (
      <Tile
        to={`/households/${householdFinId}/messages`}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <Heading icon={<MessagesIcon />} headingText={'SENT MESSAGES'} />
        }
        rightHeader={this.renderRightHeader()}
        headerBorder={true}
        anchorBorder={true}>
        <div className='c-dash__row-content c-dash__messages-w'>
          <MessageThreadList
            dashMessage={true}
            selectMessageThread={this.navigateToThread}
            householdFinId={householdFinId}
            household={household}
            nullCase={this.nullMessages}
            onError={this.handleError}
          />
        </div>
      </Tile>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    households: store.households
  }
}

export default connect(mapStateToProps)(DashMessages)
