import React, { Fragment } from 'react'
import Input from '../../components/Input'
import Button from '../../components/button'
import { UserObj } from '../../../objects/user'
import { formatPhoneNumber } from '../../helpers'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg'
import ProfilePicture from '../../components/layout/profilePicture'

interface EditProfileProps {
  user: UserObj
  editFn(): void
  uploadFn(): void
  saveFn(request: any): void
  cancelFn(): void
}

interface EditProfileState {
  firstName: string
  lastName: string
  email: string
  title: string
  street: string
  city: string
  state: string
  postalCode: string
  phone: string
  mobile: string
}

class EditProfile extends React.Component<EditProfileProps, EditProfileState> {
  constructor(props: EditProfileProps) {
    super(props)
    const {
      firstName,
      lastName,
      email,
      title,
      street,
      city,
      state,
      zip,
      home,
      mobile
    } = this.props.user
    this.state = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      title: title || '',
      street: street || '',
      city: city || '',
      state: state || '',
      postalCode: zip || '',
      phone: home || '',
      mobile: mobile || ''
    }
  }

  public updateFormInput = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    const change = {}
    if (name === 'phone' || name === 'mobile') {
      const formatPhone = formatPhoneNumber(value)
      change[name] = formatPhone || value
    } else change[name] = value
    this.setState(change)
  }

  public saveUser = async () => {
    this.props.saveFn(this.state)
  }

  public renderPhotoButton = () => {
    const { photo } = this.props.user
    return photo ? (
      <div className='a-profile__editBtn' onClick={this.props.editFn}>
        <span className='a-profile__editBtn-icon'>
          <EditIcon />
        </span>
        <span>Edit</span>
      </div>
    ) : (
      <div className='a-profile__uploadBtn' onClick={this.props.uploadFn}>
        <span className='a-profile__uploadBtn-icon'>
          <UploadIcon />
        </span>
        <span>Upload</span>
      </div>
    )
  }

  public render() {
    const {
      firstName,
      lastName,
      email,
      title,
      street,
      city,
      state,
      postalCode,
      phone,
      mobile
    } = this.state
    const { user } = this.props
    return (
      <Fragment>
        <div className='a-profile__info-main'>
          <div className='a-profile__info-pic'>
            <ProfilePicture
              firstName={user.firstName}
              lastName={user.lastName}
              photo={user.photo}
              isPrimary={true}
              pictureSize={75}
            />
            {this.renderPhotoButton()}
          </div>
          <div className='a-profile__edit-half-w'>
            <Input
              title='First Name'
              inputType='text'
              name='firstName'
              controlFunc={this.updateFormInput}
              content={firstName}
            />
            <Input
              title='Last Name'
              inputType='text'
              name='lastName'
              controlFunc={this.updateFormInput}
              content={lastName}
            />
          </div>
        </div>
        <div className='a-profile__edit-details-main'>
          <div className='a-profile__edit-half-w'>
            <Input
              title='Email'
              inputType='email'
              name='email'
              controlFunc={this.updateFormInput}
              content={email}
            />
            <Input
              title='Title'
              inputType='text'
              name='title'
              controlFunc={this.updateFormInput}
              content={title}
            />
          </div>
          <div className='a-profile__edit'>
            <Input
              title='Address'
              inputType='text'
              name='street'
              controlFunc={this.updateFormInput}
              content={street}
            />
          </div>
          <div className='a-profile__edit-third-w'>
            <Input
              title='City'
              inputType='text'
              name='city'
              controlFunc={this.updateFormInput}
              content={city}
            />
            <Input
              title='State'
              inputType='text'
              name='state'
              controlFunc={this.updateFormInput}
              content={state}
            />
            <Input
              title='Zip'
              inputType='text'
              name='postalCode'
              controlFunc={this.updateFormInput}
              content={postalCode}
            />
          </div>
          <div className='a-profile__edit-half-w'>
            <Input
              title='Primary Phone'
              inputType='tel'
              name='phone'
              controlFunc={this.updateFormInput}
              content={phone}
            />
            <Input
              title='Other Phone'
              inputType='tel'
              name='mobile'
              controlFunc={this.updateFormInput}
              content={mobile}
            />
          </div>
          <div className='a-profile__edit-btns'>
            <Button clear={true} onClick={this.props.cancelFn}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.saveUser}>
              Save
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default EditProfile
