/* eslint-disable complexity */
import { ETaskStatus, ETaskView } from '../../../objects/tasks'
import * as SortHelper from '../../helpers/householdPreferences'
import { isEmpty } from 'lodash'

export const translateSort = (label: string) => {
  switch (label) {
    case 'DESCRIPTION':
      return 'subject'
    case 'ASSIGNED':
      return 'assigneeNames'
    case 'DUE DATE':
      return 'dueDate'
    case 'SHOW/HIDE':
      return 'includeInGuidebook'
    case 'STATUS':
      return 'status'
    default:
      return null
  }
}

export const sortTaskItems = (tasks: any, state: any, taskView?: ETaskView) => {
  const { sortBy, sortDescending, stickNullToBottom = false } = state
  const sort = translateSort(sortBy)
  const sortedTasks = {}
  if (isEmpty(tasks)) return {}

  Object.keys(tasks)
    .sort((a, b) => {
      let sortA = tasks[a][sort] || ''
      let sortB = tasks[b][sort] || ''
      if (stickNullToBottom) {
        if (!sortA) return 1
        if (!sortB) return -1
      }
      if (sortBy === 'DESCRIPTION') {
        return SortHelper.compareStringField(sortA, sortB, sortDescending)
      }
      if (sortBy === 'DUE DATE' || sortBy === 'ASSIGNED') {
        return SortHelper.compareStringWithBlankField(
          sortA,
          sortB,
          sortDescending
        )
      }
      if (sortBy === 'SHOW/HIDE') {
        return SortHelper.compareNumericField(sortA, sortB, !sortDescending)
      }
      if (sortBy === 'STATUS') {
        sortA = tasks[a].status || ''

        sortB = tasks[b].status || ''

        return SortHelper.compareStringField(sortA, sortB, sortDescending)
      } else return null
    })
    .forEach((key: string) => {
      sortedTasks[key] = tasks[key]
    })

  return sortedTasks
}
