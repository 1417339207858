import * as actions from '../actions/lifeActions'
import {
  LifeActionsInterface,
  LifeActionObj,
  AssigneesObj
} from '../objects/lifeActions'

export interface LifeActionsState {
  [household: string]: LifeActionsInterface
}

const initState: LifeActionsState = {}

const mapTasks = (lifeActionTasks: any[]) => {
  const taskList: any = {}
  lifeActionTasks.map((lifeActionTask) => {
    if (
      lifeActionTask.task &&
      lifeActionTask.task.assigneeType &&
      lifeActionTask.task.assigneeType.toLowerCase() === 'client'
    ) {
      taskList[lifeActionTask.task.id] = {
        isDeleted: lifeActionTask.isDeleted,
        lifeActionTaskId: lifeActionTask.id,
        id: lifeActionTask.task.id,
        subject: lifeActionTask.task.subject,
        description: lifeActionTask.task.description,
        dueDate: lifeActionTask.task.dueDate,
        createdDate: lifeActionTask.task.createdDate,
        completedDate: lifeActionTask.task.completedDate,
        status: lifeActionTask.task.status,
        assigneeType: lifeActionTask.task.assigneeType,
        assigneeNames: lifeActionTask.task.assigneeNames,
        includeInGuidebook: lifeActionTask.task.includeInGuidebook
      }
    }
  })
  return taskList
}

const mapAssignees = (assignees: AssigneesObj[]) => {
  const assigneeList: any = {}
  assignees.map((assignee) => {
    if (assignee.id) {
      assigneeList[assignee.id] = {
        firstName: assignee.firstName,
        lastName: assignee.lastName,
        id: assignee.id
      }
    }
  })
  return assigneeList
}

const createLifeActionTask = (lifeActionTask: any) => {
  if (lifeActionTask) {
    const newLifeActionTask = {}
    newLifeActionTask[lifeActionTask.id] = {
      id: lifeActionTask.id,
      crmId: lifeActionTask.crmId,
      subject: lifeActionTask.subject,
      description: lifeActionTask.description,
      dueDate: lifeActionTask.dueDate,
      createdDate: lifeActionTask.createdDate,
      completedDate: lifeActionTask.completedDate,
      status: lifeActionTask.status,
      assigneeType: lifeActionTask.assigneeType,
      assigneeNames: lifeActionTask.assigneeNames,
      includeInGuidebook: lifeActionTask.includeInGuidebook
    }
    return newLifeActionTask
  } else {
    return {}
  }
}

const mapUpdatedLifeAction = (lifeAction: LifeActionObj) => {
  if (lifeAction) {
    return {
      id: lifeAction.id,
      name: lifeAction.name,
      comment: lifeAction.comment,
      status: lifeAction.status,
      householdId: lifeAction.householdId,
      dueDate: lifeAction.dueDate,
      assigneePrimaryId: lifeAction.assigneePrimaryId,
      assigneeSecondaryId: lifeAction.assigneeSecondaryId
    }
  } else {
    return {}
  }
}

const mapHcCards = (hcCards: any[]) => {
  const lifeActionCardList: any = {}
  hcCards.map((hcCard) => {
    lifeActionCardList[hcCard.id] = {
      hcCardId: hcCard.hcCardId,
      actionId: hcCard.actionId
    }
  })
  return lifeActionCardList
}

const mapLifeActions = (lifeActions: LifeActionObj[]) => {
  const obj = {}
  let hhId = ''
  const lifeActionList: LifeActionsInterface = {}
  lifeActions.map((lifeAction) => {
    hhId = lifeAction.householdId
    lifeActionList[lifeAction.id] = {
      id: lifeAction.id,
      name: lifeAction.name,
      comment: lifeAction.comment,
      status: lifeAction.status,
      householdId: lifeAction.householdId,
      dueDate: lifeAction.dueDate,
      assigneePrimaryId: lifeAction.assigneePrimaryId,
      assigneeSecondaryId: lifeAction.assigneeSecondaryId,
      assignees: mapAssignees(lifeAction.assignees),
      tasks: mapTasks(lifeAction.tasks),
      cards: mapHcCards(lifeAction.cards)
    }
  })
  obj[hhId] = lifeActionList
  return obj
}

const LifeActions = (state = initState, action: any): LifeActionsState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.CREATE_LIFE_ACTION}_FULFILLED`:
    case `${actions.FETCH_LIFE_ACTIONS}_FULFILLED`:
      const lifeActions = mapLifeActions(action.payload.data.data)
      return { ...newState, ...lifeActions }
    case `${actions.DELETE_LIFE_ACTION}_FULFILLED`:
      return newState
    case `${actions.UPDATE_LIFE_ACTION}_FULFILLED`:
      const lifeActionList = {
        ...newState[action.payload.data.householdId][action.payload.data.id],
        ...mapUpdatedLifeAction(action.payload.data)
      }
      newState[action.payload.data.householdId][
        action.payload.data.id
      ] = lifeActionList
      return newState
    case `${actions.FETCH_LIFE_ACTION_BY_ID}_FULFILLED`:
      return newState
    case `${actions.DELETE_LIFE_ACTION_CARD}_FULFILLED`:
      return newState
    case `${actions.CREATE_LIFE_ACTION_TASK}_FULFILLED`:
      const addLifeActionTask = createLifeActionTask(action.payload.data)
      const taskList = {
        ...newState[action.payload.data.householdFinId][
          action.payload.data.actionId
        ].tasks,
        ...addLifeActionTask
      }
      newState[action.payload.data.householdFinId][
        action.payload.data.actionId
      ].tasks = taskList
      return newState
    case `${actions.DELETE_LIFE_ACTION_TASK}_FULFILLED`:
      return newState
    default:
      return state
  }
}

export default LifeActions
