import React from 'react'
import Tile from '../../components/layout/tile'
import HonestConversationNullState from '../../assets/images/nullStates/HonestConversationNullState.png'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import Button from '../../components/button'
import sendIcon from '../../assets/images/icons/svg/ic_send.svg'
import QuickActionLinks from '../../components/quickActionLinks'
import {
  ClientCardObj,
  HonestConversationsExerciseObj
} from '../../../objects/honestConversations'
import HcPersonalPriorityTile from './hcPersonalPriorityTile'
import { isObjEmpty } from '../../helpers'
import ProfilePicture from '../../components/layout/profilePicture'
import moment from 'moment'

export interface HcNoCombinedCardsProps {
  primaryPersonalCards: ClientCardObj[]
  secondaryPersonalCards: ClientCardObj[]
  honestConversation: HonestConversationsExerciseObj
  quickActions?: {
    text: string
    icon: string
    to: string
  }[]
  contacts: ContactsInterface
  sendEmailModal?(): void
  handleSendLinkButtonPress(contact: ContactObj, isPrimary: boolean): void
}

class HcNoCombinedCards extends React.Component<HcNoCombinedCardsProps> {
  public filterRankedCards = (cards: ClientCardObj[]) =>
    cards.filter((card: ClientCardObj) => card.ranking)

  public filterCompletedCards = (cards: ClientCardObj[]) =>
    cards.filter((card: ClientCardObj) => card.rankedWithin === 'All')

  public checkExerciseStatus = (clientCards: ClientCardObj[]) => {
    const rankedCards = clientCards ? this.filterRankedCards(clientCards) : []
    if (rankedCards.length > 0) {
      const completedCards = this.filterCompletedCards(rankedCards)
      if (completedCards.length === 5) {
        return 'Completed'
      } else return 'In Progress'
    } else return 'Not Started'
  }

  public handleSendClick = (contact: ContactObj, isPrimary: boolean) => () => {
    this.props.sendEmailModal()
    this.props.handleSendLinkButtonPress(contact, isPrimary)
  }

  public renderExerciseStatus = (
    contact: ContactObj,
    isPrimary: boolean,
    emailSent: string,
    clientCards: ClientCardObj[]
  ) => {
    const headerStyle = { backgroundColor: '#FAFAFA' }
    const tileStyle = { margin: '24px 0' }
    const buttonStyle = {
      maxHeight: '32px',
      padding: '8px 8px 8px 5px'
    }
    const contactType = isPrimary ? 'PRIMARY' : 'SECONDARY'
    const leftHeaderText = contact.firstName
      ? `${contact.firstName.toLocaleUpperCase()}'S PERSONAL PRIORITIES`
      : `${contactType} PERSONAL PRIORITIES`
    return (
      <Tile
        leftHeader={leftHeaderText}
        headerBorder={true}
        style={tileStyle}
        headerStyle={headerStyle}>
        <div className='hc-no-combined-cards__result-w'>
          <div className='hc-no-combined-cards__photo-w'>
            <ProfilePicture
              firstName={contact.firstName}
              lastName={contact.lastName}
              photo={contact.photo}
              isPrimary={isPrimary}
              pictureSize={32}
            />
            <span className='hc-no-combined-cards__name'>{`${
              contact.firstName ? contact.firstName : ''
            } ${contact.lastName}`}</span>
          </div>
          <div className='hc-no-combined-cards__status-w'>
            <p className='hc-no-combined-cards__sub-header'>EXERCISE STATUS</p>
            <p className='hc-no-combined-cards__status-text'>
              {this.checkExerciseStatus(clientCards)}
            </p>
          </div>
          <div className='hc-no-combined-cards__status-w'>
            <p className='hc-no-combined-cards__sub-header'>LINK SENT</p>
            <p className='hc-no-combined-cards__status-text'>
              {emailSent ? moment(emailSent).format('MM/D/YYYY') : 'Not Sent'}
            </p>
          </div>
          <Button
            header={true}
            onClick={this.handleSendClick(contact, isPrimary)}
            style={buttonStyle}>
            <img
              className='hc-no-combined-cards__send-icon'
              src={sendIcon}
              alt='send'
            />
            {emailSent ? 'Resend Link' : 'Send Link'}
          </Button>
        </div>
      </Tile>
    )
  }

  public render() {
    const headerStyle = { backgroundColor: '#FAFAFA' }
    const {
      quickActions,
      contacts,
      primaryPersonalCards,
      secondaryPersonalCards,
      honestConversation
    } = this.props

    const emailSentSecondary =
      !isObjEmpty(honestConversation) && honestConversation.emailSentSecondary
        ? honestConversation.emailSentSecondary
        : null
    const emailSentPrimary =
      !isObjEmpty(honestConversation) && honestConversation.emailSentPrimary
        ? honestConversation.emailSentPrimary
        : null
    const clientCards = !isObjEmpty(honestConversation?.clientCards)
      ? honestConversation.clientCards
      : null
    const primaryClientCards = clientCards
      ? clientCards[contacts?.primary.id]
      : null
    const secondaryClientCards =
      clientCards && contacts?.secondary
        ? clientCards[contacts?.secondary.id]
        : null

    return (
      <div className='hc-no-combined-cards'>
        <Tile
          leftHeader='SHARED PRIORITIES'
          rightHeader={<QuickActionLinks customQuickAction={quickActions} />}
          headerBorder={true}
          headerStyle={headerStyle}>
          <div className='hc-no-combined-cards__null-img-w'>
            <img
              className='hc-no-combined-cards__null-img'
              src={HonestConversationNullState}
              alt='null-state'
            />
          </div>
          <p className='hc-no-combined-cards__text-1'>
            No combined results yet.
          </p>
          <p className='hc-no-combined-cards__text-2'>
            Check out individual progress below.
          </p>
        </Tile>
        {contacts?.secondary && secondaryPersonalCards?.length ? (
          <HcPersonalPriorityTile
            contact={contacts.secondary}
            cards={secondaryPersonalCards}
          />
        ) : contacts?.secondary ? (
          this.renderExerciseStatus(
            contacts.secondary,
            false,
            emailSentSecondary,
            secondaryClientCards
          )
        ) : null}
        {contacts?.primary && primaryPersonalCards?.length ? (
          <HcPersonalPriorityTile
            contact={contacts.primary}
            cards={primaryPersonalCards}
          />
        ) : contacts?.primary ? (
          this.renderExerciseStatus(
            contacts.primary,
            true,
            emailSentPrimary,
            primaryClientCards
          )
        ) : null}
      </div>
    )
  }
}

export default HcNoCombinedCards
