import { ymmQuizParamName } from './ymmQuizParamName'

/**
 * Returns the proper URL based on YMM version.
 * This acts like a rollback (feature flag) for new moneymind.
 * If the backend is rolledback FE should use the old links.
 */
export const ymmBaseUrl = (): string => {
  return `${window._env_.REACT_APP_YMM_URL}/`
}

/**
 * Returns the proper URL based on YMM version.
 * This acts like a rollback (feature flag) for new moneymind.
 * If the backend is rolledback FE should use the old links.
 */
export const ymmQuizURL = (paramValue: string): string => {
  const baseURL = ymmBaseUrl()
  const paramName = ymmQuizParamName()
  const param = paramValue ? `?${paramName}=${paramValue}` : ''

  return `${baseURL}${param}`
}
