import React, { FunctionComponent, SVGProps, Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import BackButton from '../../components/backButton'
import Button from '../../components/button'
import { history } from '../../../store'
import BarGraph from './barGraph'
import {
  InvestmentViewfinderInterface,
  InvestmentViewfinderPriorityInterface,
  InvestmentViewfinderQuestionObj
} from '../../../objects/investmentViewfinder'
import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'
import { ReactComponent as PerformanceIcon } from '../../assets/images/icons/viewfinder_performance.svg'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/viewfinder_protection.svg'
import { ReactComponent as TaxesIcon } from '../../assets/images/icons/viewfinder_taxes.svg'
import { ReactComponent as CostsIcon } from '../../assets/images/icons/viewfinder_costs.svg'

interface PriorityQuestionResultsProps {
  priorities: InvestmentViewfinderPriorityInterface
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  householdFinId: string
  exerciseId: string
  questions: {
    [questionNumber: number]: InvestmentViewfinderQuestionObj
  }
}

class PriorityQuestionResults extends React.Component<
  PriorityQuestionResultsProps
> {
  public middleHeader = () => {
    return (
      <div className='ivf-priorities__middle-header'>
        <div className='ivf-priorities__middle-header-text'>Results</div>
      </div>
    )
  }
  public globalPriorities = () => {
    const { exerciseId, householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/geography`
    )
  }
  public rightHeader = () => {
    return (
      <Button primary={true} onClick={this.globalPriorities}>
        Start Geographic Priorities
      </Button>
    )
  }
  public navigateBack = () => {
    const { householdFinId, exerciseId } = this.props
    // const { householdFinId, exerciseId, questions } = this.props
    // const questionNumbers = Object.keys(questions)
    // const lastQuestion = questionNumbers[questionNumbers.length - 1]
    history.push(
      // `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/${lastQuestion}`
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/summary`
    )
  }
  public priorityBarGraph = (
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
    title: string,
    height: number,
    color: string,
    active?: boolean,
    opaque?: boolean
  ) => {
    const additionalClass = active
      ? 'ivf-priorities__priority-result-w--active'
      : opaque
      ? 'ivf-priorities__priority-result-w--opaque'
      : null
    return (
      <div>
        <div className={`ivf-priorities__priority-result-w ${additionalClass}`}>
          <BarGraph color={color} heightPercent={height} />
          <div className='ivf-priorities__priority'>
            <Icon className='ivf-priorities__icon' />
            {title}
          </div>
        </div>
        {active && <div className='ivf-priorities-line' />}
      </div>
    )
  }
  public getHeight = (priority: number) => {
    return (priority / 16) * 100
  }
  // tslint:disable-next-line:max-func-body-length
  public render() {
    const { priorities, exercises, exerciseId, householdFinId } = this.props
    const priorityOptions = {
      performance: {
        active: false,
        opaque: false,
        icon: PerformanceIcon,
        highlight: 'performance'
      },
      protection: {
        active: false,
        opaque: false,
        icon: ProtectionIcon,
        highlight: 'protection'
      },
      taxes: {
        active: false,
        opaque: false,
        icon: TaxesIcon,
        highlight: 'tax advantage'
      },
      cost: {
        active: false,
        opaque: false,
        icon: CostsIcon,
        highlight: 'minimizing costs'
      }
    }
    const exercise =
      exercises[householdFinId] && exercises[householdFinId][exerciseId]
    if (exercise) {
      const priorityValues = {
        cost: exercise.cost,
        taxes: exercise.taxes,
        performance: exercise.performance,
        protection: exercise.protection
      }
      const sortedPriorityKeys = Object.keys(priorityValues).sort((a, b) => {
        const aPriority = priorityValues[a]
        const bPriority = priorityValues[b]
        return bPriority - aPriority
      })
      priorityOptions[sortedPriorityKeys[0]].active = true
      priorityOptions[sortedPriorityKeys[2]].opaque = true
      priorityOptions[sortedPriorityKeys[3]].opaque = true
      return (
        <Fragment>
          <ContentHeader title='Priorities' />
          <Tile
            leftHeader={<BackButton onClick={this.navigateBack} />}
            middleHeader={this.middleHeader()}
            rightHeader={this.rightHeader()}
            headerBorder={true}
            headerStyle={{
              paddingTop: 0,
              paddingBottom: 0,
              backgroundColor: '#FAFAFA'
            }}>
            <div className='ivf-container'>
              <div className='ivf-priorities__graph-w'>
                {this.priorityBarGraph(
                  priorityOptions.performance.icon,
                  priorities.performance.label,
                  this.getHeight(exercise.performance),
                  priorities.performance.color,
                  priorityOptions.performance.active,
                  priorityOptions.performance.opaque
                )}
                {this.priorityBarGraph(
                  priorityOptions.protection.icon,
                  priorities.protection.label,
                  this.getHeight(exercise.protection),
                  priorities.protection.color,
                  priorityOptions.protection.active,
                  priorityOptions.protection.opaque
                )}
                {this.priorityBarGraph(
                  priorityOptions.cost.icon,
                  priorities.cost.label,
                  this.getHeight(exercise.cost),
                  priorities.cost.color,
                  priorityOptions.cost.active,
                  priorityOptions.cost.opaque
                )}
                {this.priorityBarGraph(
                  priorityOptions.taxes.icon,
                  priorities.taxes.label,
                  this.getHeight(exercise.taxes),
                  priorities.taxes.color,
                  priorityOptions.taxes.active,
                  priorityOptions.taxes.opaque
                )}
              </div>
              <div className='ivf-priorities__highlight-w'>
                {'You place a high degree of value on '}
                <span className='ivf-priorities__highlight'>
                  &nbsp;{priorityOptions[sortedPriorityKeys[0]].highlight}
                </span>
              </div>
            </div>
          </Tile>
        </Fragment>
      )
    } else return null
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    priorities: store.investmentViewfinder.priorities,
    exercises: store.investmentViewfinder.exercises,
    questions: store.investmentViewfinder.questions
  }
}

export default connect(mapStateToProps)(PriorityQuestionResults)
