import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { TASK_SUBJECT_MAX_LIMIT } from '../../../../objects/tasks'
import cls from 'classnames'

export interface ITaskTextAreaProps {
  id?: string
  title: string
  titleHelper?: string
  value: string
  name: string
  isDisabled?: boolean
  onChangeFunc(event: React.FormEvent<HTMLTextAreaElement>): void
  onBlurFunc(): void
}

export const TaskTextArea: React.FC<ITaskTextAreaProps> = ({
  id,
  title,
  titleHelper,
  value,
  name,
  isDisabled,
  onChangeFunc,
  onBlurFunc
}) => {
  const isOverLimit = value?.length > TASK_SUBJECT_MAX_LIMIT

  const charCountCls = cls('task-add__charcount', {
    'task-add__charcount--over': isOverLimit
  })

  const tABorderStyle = {
    padding: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: isOverLimit ? '#f12938' : '#c2c2c2'
  }

  return (
    <div id={id} className='task-add__container'>
      <label className='task-add__label' htmlFor={name}>
        {title}
      </label>
      <span className='task-add__label task-add__label--helper'>
        {titleHelper}
      </span>
      <TextareaAutosize
        id={name}
        name={name}
        value={value}
        onChange={onChangeFunc}
        onBlur={onBlurFunc}
        disabled={isDisabled}
        style={tABorderStyle}
        minRows={1}
        maxRows={8}
        className='side-bar-body__text task-add__textarea'
      />
      <div className={charCountCls}>
        {value?.length}/{TASK_SUBJECT_MAX_LIMIT}
      </div>
    </div>
  )
}
