import api from '../v3/helpers/api'

export const FETCH_HOUSEHOLD_BENCHMARKS = 'FETCH_HOUSEHOLD_BENCHMARKS'

export const getHouseholdBenchmarks = (
  householdFinId: string,
  isgBenchmark?: boolean
) => {
  const isgParam = isgBenchmark ? '?isgBenchmarks=true' : ''

  return {
    householdFinId,
    type: FETCH_HOUSEHOLD_BENCHMARKS,
    payload: api()
      .get(`/households/${householdFinId}/benchmarks${isgParam}`)
      .then((response) => {
        response.data = {
          householdId: householdFinId,
          data: response.data
        }
        return response.data
      })
  }
}
