import React, { Component } from 'react'

import AvatarIcon from '../assets/images/icons/general/avatar.png'

export class ProfileAvatar extends Component {
  public render() {
    return (
      <span>
        <img src={AvatarIcon} alt='profile avatar' />
      </span>
    )
  }
}

export default ProfileAvatar
