import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'

interface ConfirmActionProps {
  title: string
  message?: string
  closeFunc(): void
  confirmFunc(): void
}

class ConfirmActionModal extends React.Component<ConfirmActionProps> {
  public render() {
    const { message } = this.props
    return (
      <Modal
        icon={DeleteIcon}
        title={this.props.title}
        closeModal={this.handleCloseAction}>
        <div className='confirm-modal'>
          <div className='confirm-modal__content'>
            <p>{message || 'Are you sure you want to delete this record?'}</p>
          </div>
          <div className='confirm-modal__buttons'>
            <span className='btn btn__clear' onClick={this.handleCloseAction}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={this.handleConfirmAction}>
              Delete
            </span>
          </div>
        </div>
      </Modal>
    )
  }

  private handleCloseAction = () => {
    this.props.closeFunc()
  }

  private handleConfirmAction = () => {
    this.props.confirmFunc()
  }
}

export default ConfirmActionModal
