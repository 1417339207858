import { GoalRequestObj } from './goalRequests'

export interface FinancialGoalObj {
  id: string
  name: string
  type: string
  owner: string
  startDate: string
  endDate: string
  amount: string
  amountType: string
  annualIncrease: number | string
  changeStatus?: string
  goalRequest?: GoalRequestObj
  comment?: string
  needsWantsWishes: NeedsWantsWishesType
  requests?: GoalRequestObj
}

export interface FundedScoreObj {
  id: string
  name: string
  inGuidebook: boolean
  rangeLow: number
  rangeHigh: number
  score: number
  lastUpdated: string
  notes?: string
}

export interface FinancialGoalsInterface {
  [key: string]: FinancialGoalObj
}

export type PlanningSoftwareType =
  | 'emoney'
  | 'moneyguidepro'
  | 'gdx360'
  | 'emoney_enterprise'

export const PlanningSoftwareTypeObj = {
  eMoney: 'emoney',
  moneyGuidePro: 'moneyguidepro',
  gdx360: 'gdx360',
  eMoneyEnterprise: 'emoney_enterprise'
}

export type NeedsWantsWishesType = 'Needs' | 'Wants' | 'Wishes'

export type GoalSortingType =
  | 'name'
  | 'startDate'
  | 'amount'
  | 'needsWantsWishes'

export const goalTypesList = {
  'Legacy Goal': { value: 'Legacy', label: 'Legacy' },
  'Spending Goal': { value: 'Spending', label: 'Spending' },
  'Retirement/Other Income': {
    value: 'Retirement Income',
    label: 'Retirement Income'
  },
  'Savings Goal': { value: 'Savings', label: 'Savings' },
  'Timing Control': { value: 'Timing', label: 'Timing' }
}

export const planningSoftwareOptions = [
  { value: 'gdx360', label: 'GDX360' },
  { value: 'moneyguidepro', label: 'MoneyGuidePro' },
  { value: 'emoney', label: 'eMoney' },
  { value: 'emoney_enterprise', label: 'eMoney Enterprise' },
  { value: null, label: 'Other' }
]
