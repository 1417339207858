import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

import SuggestedGoalCards from '../components/suggestedGoalCards'

import { updateGoalActionById } from '../../../../actions/financialGoalActions'

import { GlobalState } from '../../../../reducers'

import { CardObj } from '../../../../objects/HonestConversations/card'
import { GoalResponse } from '../../../../objects/financialGoalActions'
import {
  HonestConversationsExerciseObj,
  HouseholdCardObjInterface
} from '../../../../objects/honestConversations'

import rightArrow from '../../../assets/images/icons/goals/goal-action-arrow-blue.png'

import { sortAndFillCards } from '../../../helpers/honestConversations'

interface GoalView3Props {
  activeGoal: GoalResponse
  householdFinId: string
  setViewKey(id: number): void
  dispatch: Dispatch<GlobalState>
  honestConversation: HonestConversationsExerciseObj
  goalCls: string
}

interface CardObjWRanking extends CardObj {
  ranking?: number
}
class GoalView3 extends Component<GoalView3Props, {}> {
  constructor(props: GoalView3Props) {
    super(props)
  }

  public getHHCards = (): CardObjWRanking[] => {
    const { honestConversation, activeGoal } = this.props
    const activeGoalCardIds: string[] = activeGoal.cards.map((card: any) => {
      return card?.id
    })

    const householdCards: HouseholdCardObjInterface =
      (honestConversation && honestConversation.householdCards) || {}
    const sortedGoals = sortAndFillCards(householdCards).filter((card) => card)
    return sortedGoals.filter((card: CardObjWRanking, id: number) => {
      card['ranking'] = id
      return !activeGoalCardIds.includes(card.id)
    })
  }

  public onCardRowClick = (card: CardObj) => {
    const {
      dispatch,
      householdFinId,
      setViewKey,
      activeGoal: {
        cards,
        id,
        name,
        status,
        note,
        assigneePrimaryId,
        assigneeSecondaryId,
        dueDate,
        inGuidecenter,
        type
      }
    } = this.props
    const HHCards: CardObjWRanking[] = [...cards, ...[card]]
    const reqBody: any = {
      id,
      name,
      note,
      status,
      assigneePrimaryId,
      assigneeSecondaryId,
      dueDate,
      cards: HHCards.sort((a, b) => a.ranking - b.ranking),
      inGuidecenter,
      type
    }
    dispatch(updateGoalActionById(reqBody, householdFinId))
    setViewKey(2)
  }

  render() {
    const { goalCls } = this.props
    return (
      <div className='side-bar-body__priorities-cards-view'>
        <div className='side-bar-body__priorities-cards-list'>
          <div className='side-bar-body__priorities-cards-item'>
            <SuggestedGoalCards
              showCardView={true}
              cards={this.getHHCards()}
              showIcon={true}
              iconImg={rightArrow}
              imgWidth={'24'}
              imgHeight={'24'}
              rowAction={this.onCardRowClick}
              classnames={goalCls}
              showAsLink={true}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default GoalView3
