import React, { Component, Fragment } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { ContactsInterface } from '../../../../../objects/contact'
import {
  HouseholdCardObj,
  HonestConversationsExerciseObj,
  CardScoreInterface
} from '../../../../../objects/honestConversations'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import CombinedCard from '../../../honestConversations/exercise/pickCombinedCards/combinedCard'
import { getCombinedCardActions } from '../../../../helpers/honestConversations'

interface CombinedCardsTableProps {
  id: string
  contacts: ContactsInterface
  cards: HouseholdCardObj[]
  goalActions: FinancialGoalActionObj[]
  hcExercise: HonestConversationsExerciseObj
  householdFinId: string
  completedSelectingCards: boolean
  instructionsInProgress: boolean
  isDroppingDisabled: boolean
}

export class CombinedCardsTable extends Component<CombinedCardsTableProps> {
  public getCardScores = (contactId: string): CardScoreInterface => {
    const { hcExercise, contacts } = this.props
    if (!hcExercise || !contacts) {
      return null
    }

    const scoringSessionId =
      contacts?.secondary?.id === contactId
        ? hcExercise.activeScoringSessionSecondary
        : hcExercise.activeScoringSessionPrimary

    return (
      (scoringSessionId &&
        hcExercise.clientScoringSessions[contactId][scoringSessionId]
          .cardScores) ||
      null
    )
  }

  public getCardScore = (
    cardScores: CardScoreInterface,
    card: HouseholdCardObj
  ): number => {
    let cardScore: number = 0
    if (!cardScores || !card) {
      return cardScore
    }

    Object.keys(cardScores).forEach((key) => {
      cardScore =
        cardScores[key].householdCard?.id === card.id
          ? cardScores[key].score
          : cardScore
    })

    return cardScore
  }

  public selectedCardsMap = (): React.ReactNode[] => {
    const {
      cards,
      contacts,
      householdFinId,
      hcExercise,
      goalActions,
      completedSelectingCards,
      instructionsInProgress
    } = this.props

    if (!cards || !cards.length) {
      return []
    }

    const primaryCardScores = this.getCardScores(contacts?.primary.id)
    const secondaryCardScores =
      contacts?.secondary && this.getCardScores(contacts?.secondary.id)

    return cards.map((card, index: number) => {
      const relatedActions: any = getCombinedCardActions(goalActions, card)

      const primaryScore: number = this.getCardScore(primaryCardScores, card)

      const secondaryScore: number = this.getCardScore(
        secondaryCardScores,
        card
      )

      return (
        <CombinedCard
          key={card.id}
          householdCards={cards}
          card={card}
          index={index}
          householdFinId={householdFinId}
          goalActions={relatedActions}
          contacts={contacts}
          hcExercise={hcExercise}
          completedSelectingCards={completedSelectingCards}
          instructionsInProgress={instructionsInProgress}
          primaryScore={primaryScore}
          secondaryScore={secondaryScore}
        />
      )
    })
  }

  public render() {
    const { id, cards, isDroppingDisabled } = this.props

    const cardPlaceHolder = (cardLength: number) => {
      if (cards && cards.length === 0 && cards.length === cardLength) {
        return 'combined-card-arranger__card combined-card-arranger__card--active'
      } else if (cards && cards.length > 0 && cards.length === cardLength) {
        return 'combined-card-arranger__card combined-card-arranger__card--active'
      } else {
        return 'combined-card-arranger__card'
      }
    }

    return (
      <div className='combined-card__pick-w'>
        <Droppable
          droppableId={id}
          direction='vertical'
          isDropDisabled={isDroppingDisabled}>
          {(provided, snapshot) => (
            <Fragment>
              <div className='combined-card-arranger__w'>
                <div className={cardPlaceHolder(0)}>1</div>
                <div className={cardPlaceHolder(1)}>2</div>
                <div className={cardPlaceHolder(2)}>3</div>
                <div className={cardPlaceHolder(3)}>4</div>
                <div className={cardPlaceHolder(4)}>5</div>
              </div>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className='combined-card__pick-table'>
                  {this.selectedCardsMap()}
                  {provided.placeholder}
                </div>
              </div>
            </Fragment>
          )}
        </Droppable>
      </div>
    )
  }
}

export default CombinedCardsTable
