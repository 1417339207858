import { NotificationTypeToNotificaiton } from '../../objects/notifications'

import { flatten } from 'lodash'

//generic type
type valueOf<T> = T[keyof T]

//Generic type that converts an array of of notifications by type into an array
//of "batched" notifications per given rules
//NOTE client tuple is the following
//{clientId: householdId}
type NotificationBatcher<T extends keyof NotificationTypeToNotificaiton> = (
  notifications: Array<NotificationTypeToNotificaiton[T]>,
  clientHouseholdTuple?: { [K: string]: string }
) => Array<NotificationTypeToNotificaiton[T]>[]
type Batchers<T extends keyof NotificationTypeToNotificaiton> = {
  [K in T]: NotificationBatcher<K>
}
// Logic for batching all notifications

export const unreadBatchers: Batchers<keyof NotificationTypeToNotificaiton> = {
  SYNC: (sync) => [],
  MESSAGE: (messages) => {
    //for each message, just create a new array with a single message in it
    return messages.map((m) => [m])
  },
  NEW_YMM: (ymm) => {
    //for each YMM, just create a new array with a single YMM in it
    return ymm.map((y) => [y])
  },
  GOAL_CHANGE: (goalChange) => {
    //for each Goal Change, just create a new array with a single goal change in it
    return goalChange.map((g) => [g])
  },
  NEW_ACCOUNT_LINKED: (newAccounts) => {
    //Batch by household
    return genericBatcher(
      newAccounts.map((a) => [
        a.notificationByNotification.content.data.householdId,
        a
      ])
    )
  },
  NEW_HC: (hc) => {
    //for each HC, just create a new array with a single HC in it
    return hc.map((hc) => [hc])
  },
  NEW_PDV_FILE: (pdv, tuple) => {
    // Batch by household
    const mapper = clientToHouseholdTupleter(tuple)
    const batchedByHousehold = genericBatcher(
      pdv.map<[string, typeof pdv[number]]>(mapper)
    )
    //batch again by folder
    return flatten(
      batchedByHousehold.map((batched) => {
        return genericBatcher(
          batched.map((b) => [
            b.notificationByNotification.content.data.folderId,
            b
          ])
        )
      })
    )
  },
  NEW_TASK_ASSIGNED: (newTask) => {
    //Batch by household
    return genericBatcher(
      newTask.map((t) => [
        t.notificationByNotification.content.data.householdId,
        t
      ])
    )
  },
  COMPLETE_TASK: (compTask, tuple) => {
    //Batch by household
    const mapper = clientToHouseholdTupleter(tuple)
    return genericBatcher(
      compTask.map<[string, typeof compTask[number]]>(mapper)
    )
  }
}
export const readBatchers: Batchers<keyof NotificationTypeToNotificaiton> = {
  SYNC: (sync) => [],
  MESSAGE: (messages) => {
    //for each message, just create a new array with a single message in it
    return messages.map((m) => [m])
  },
  NEW_YMM: (ymm) => {
    //for each YMM, just create a new array with a single YMM in it
    return ymm.map((y) => [y])
  },
  GOAL_CHANGE: (goalChange) => {
    //for each Goal Change, just create a new array with a single goal change in it
    return goalChange.map((g) => [g])
  },
  NEW_ACCOUNT_LINKED: (newAccounts) => {
    //Batch by readDate
    return genericBatcher(newAccounts.map((a) => [a.readDate, a]))
  },
  NEW_HC: (hc) => {
    //for each HC, just create a new array with a single HC in it
    return hc.map((hc) => [hc])
  },
  NEW_PDV_FILE: (pdv) => {
    //batch by read date
    return genericBatcher(pdv.map((a) => [a.readDate, a]))
  },
  NEW_TASK_ASSIGNED: (newTask) => {
    //Batch by readDate
    return genericBatcher(newTask.map((a) => [a.readDate, a]))
  },
  COMPLETE_TASK: (compTask) => {
    //Batch by readDate
    return genericBatcher(compTask.map((a) => [a.readDate, a]))
  }
}

//force a custom tuple that is [<key>, notification]
//returns just bacthed by key
//Keys can be householdIds folder ids. whatever
const genericBatcher = <T extends valueOf<NotificationTypeToNotificaiton>>(
  notifications: [string, T][]
) => {
  return Object.values(
    notifications.reduce((prev, current) => {
      prev[current[0]] = prev[current[0]]
        ? [...prev[current[0]], current[1]]
        : [current[1]]
      return prev
    }, {} as { [K: string]: T[] })
  )
}

//CURRY FUNCTION
//takes a clientHouseholdTuple array
// {clientId: householdId}
//And a list of notifications (forces clientID)
//and returns a [householdId, notification] tuple for genericBatcher consuption
const clientToHouseholdTupleter = (clientHouseholdTuple: {
  [K: string]: string
}) => {
  return <T extends valueOf<NotificationTypeToNotificaiton>>(notification: {
    notificationByNotification: { content: { data: { clientId: string } } }
  }): [string, T] => {
    return [
      clientHouseholdTuple[
        notification.notificationByNotification.content.data.clientId
      ],
      notification as T
    ]
  }
}

//ORDER FUNCTION
//Takes a full list of notification with bached arrays and sorts them by createdDate

export const createDateSort = <
  T extends valueOf<NotificationTypeToNotificaiton>
>(
  notifications: T[][]
) => {
  return notifications
    .map((n) => {
      //order by date in each batch so its consistant
      return n.length > 1
        ? n.sort((p, n) => {
            const a = p.notificationByNotification.createdDate
            const b = p.notificationByNotification.createdDate
            //you can compare date strings directly in JS
            if (a < b) {
              return 1
            }
            if (a > b) {
              return -1
            }
            return 0
          })
        : n
    })
    .sort((prev, next) => {
      //sort batches overall
      const a = prev[0].notificationByNotification.createdDate
      const b = next[0].notificationByNotification.createdDate
      //you can compare date strings directly in JS
      if (a < b) {
        return 1
      }
      if (a > b) {
        return -1
      }
      return 0
    })
}

//base batch by subject
const batchBySubject = <
  T extends valueOf<NotificationTypeToNotificaiton>,
  K extends keyof NotificationTypeToNotificaiton
>(
  notifications: T[]
) => {
  //creates an obj for subj to array
  return notifications.reduce((prev, current) => {
    const subj = current.notificationByNotification.subject
    prev[subj] = prev[subj] ? [...prev[subj], current] : [current]
    return prev
  }, {} as { [V in K]: T[] })
}

//Base Sort function
//Takes a raw list of notifications, applies batcher, returns fully batched and sorted array
export const batchAndSort = <T extends valueOf<NotificationTypeToNotificaiton>>(
  notifications: T[],
  clientHouseholdTuple: { [K: string]: string }
) => {
  //first, bucket all notifications by read/unread and by subject type
  // unread is [0] read is [1]
  const [unread, read] = notifications
    .reduce(
      (prev, current) => {
        return current.read
          ? [[...prev[0]], [...prev[1], current]]
          : [[...prev[0], current], [...prev[1]]]
      },
      [[] as T[], [] as T[]]
    )
    .map(batchBySubject)
  //run respective batchers
  const batchedunRead = Object.keys(unread).map((k) => {
    const ret = unreadBatchers[k as keyof NotificationTypeToNotificaiton]
      ? unreadBatchers[k as keyof NotificationTypeToNotificaiton](
          unread[k],
          clientHouseholdTuple
        )
      : [[]]
    return ret
  })
  const batchedRead = Object.keys(read).map((k) => {
    return readBatchers[k as keyof NotificationTypeToNotificaiton](read[k])
  })
  //combine two batched arrays
  const fullBatched = flatten<valueOf<NotificationTypeToNotificaiton>[]>([
    ...batchedunRead,
    ...batchedRead
  ])
  //now sort by create date
  return createDateSort(fullBatched)
}
