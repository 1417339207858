/**
 * ALL DESKTOP SETTINGS
 * ["MESSAGE", "BROKEN_LINK", "GOAL_CHANGE", " NEW_ACCOUNT_LINKED", "SYNC", "NEW_YMM", "NEW_HC", "NEW_PDV_FILE"]
 * settings not in allSettings array will be ignored (like "SYNC")
 */
export type AllSetting =
  | 'MESSAGE'
  | 'GOAL_CHANGE'
  | 'NEW_ACCOUNT_LINKED'
  | 'NEW_YMM'
  | 'NEW_HC'
  | 'NEW_PDV_FILE'
  | 'COMPLETE_TASK'
  | 'NEW_TASK_ASSIGNED'
  | 'NOTIFICATION_SUMMARY'
/**
 * utility properties to gouge which toggles to render for desktop/email settings
 * this can be changed once BE will implement queries to retrieve list of
 * all available subjects
 */
export const allSettings: AllSetting[] = [
  'MESSAGE',
  'NEW_TASK_ASSIGNED',
  'GOAL_CHANGE',
  'NEW_ACCOUNT_LINKED',
  'NEW_YMM',
  'NEW_HC',
  'NEW_PDV_FILE',
  'COMPLETE_TASK'
]

/**
 * Adviser email notification setting
 */
export const allEmailSettings: AllSetting[] = [
  'MESSAGE',
  'NEW_TASK_ASSIGNED',
  'NOTIFICATION_SUMMARY'
]

// Advisor mobile notification setting
export const allMobileSettings: AllSetting[] = ['MESSAGE']

export const captionList: Record<AllSetting, string> = {
  MESSAGE: 'Incoming Messages',
  GOAL_CHANGE: 'Financial Plan Change Requests',
  NEW_YMM: 'New MoneyMind<sup>&reg;</sup>',
  NEW_HC: 'New HonestConversations<sup>&reg;</sup>',
  NEW_PDV_FILE: 'New Vault File',
  NEW_ACCOUNT_LINKED: 'New Account Linked',
  COMPLETE_TASK: 'Client Completed Task',
  NEW_TASK_ASSIGNED: 'New Task Assigned',
  NOTIFICATION_SUMMARY:
    'I want to receive a Daily Notification Summary by email'
}

/**
 * ALL CLIENT SETTINGS
 */
export type ClientNotificationTypes =
  | 'MESSAGE'
  | 'NEW_TASK_ASSIGNED'
  | 'NEW_PDV_FILE'

export const ClientDesktopNotifications: ClientNotificationTypes[] = [
  'MESSAGE',
  'NEW_TASK_ASSIGNED',
  'NEW_PDV_FILE'
]
export const ClientEmailNotifications: ClientNotificationTypes[] = [
  'MESSAGE',
  'NEW_TASK_ASSIGNED'
]
export const ClientMobileNotifications: ClientNotificationTypes[] = ['MESSAGE']

/**
 * DEPENDANT EMAIL NOTIFICATION SETTINGS
 * if an AllSetting type is applied, the related email type will require its
 * web type to be enabled for it to be available for toggling on/off.
 * {[key - email notification type]: [value - tooltip message]}
 * exp:
 * { MESSAGE: 'In order to receive email notifications, please turn both email and web notifications on.'}
 */
export const dependantWebNotificationSettings: Record<string, string> = {}
