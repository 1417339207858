import { NetWorthErrorFilterType } from '../objects/clientFilter'
import api from '../v3/helpers/api'

export const SET_INSTITUTIONS = 'SET_INSTITUTIONS'
export const SET_CLIENT_LIST_FILTERS = 'SET_CLIENT_LIST_FILTERS'

export const setInstitutions = (householdsWithUniqueInstitutions: string[]) => {
  const institutionList = householdsWithUniqueInstitutions.join(',')
  return {
    type: SET_INSTITUTIONS,
    payload: api()
      .get(`/institutions?ids=${institutionList}`)
      .then((response) => {
        return response
      })
  }
}

export const setClientListFilters = (
  selectedInstitutions: string[],
  selectedMoneyMinds: string[],
  selectedOfficeTeams: string[],
  showNetWorthErrors: NetWorthErrorFilterType
) => {
  return {
    type: SET_CLIENT_LIST_FILTERS,
    payload: {
      selectedInstitutions,
      selectedMoneyMinds,
      selectedOfficeTeams,
      showNetWorthErrors
    }
  }
}
