import { TaskObjState } from '../../../objects/tasks'
/**
 * tasks passed into this function already filtered by statuses,
 * this function further refines it since native and crm tasks can have sub-statuses
 * @param selectedStatuses - array of strings, statuses selected in filter dropdown
 * @param tasks - collection of tasks that consists of taskId as key and task object as value
 * @returns collection of tasks filtered by statuses
 */
export const applyStatusFilterHelper = (
  selectedStatuses: string[],
  tasks?: TaskObjState
) => {
  if (!selectedStatuses?.length || !tasks) {
    return tasks
  }
  const tasksFilteredByAssignee = Object.values(tasks).reduce(
    (filteredTasks, currentTask) => {
      const isStatusSelected = selectedStatuses.some(
        (selectedStatus) => currentTask.status === selectedStatus
      )
      if (isStatusSelected) filteredTasks[currentTask.id] = currentTask
      return filteredTasks
    },
    {}
  )

  return tasksFilteredByAssignee
}
