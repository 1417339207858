import * as React from 'react'
import Modal from '../../components/layout/modal'

export interface DisclosureModalProps {
  disclosureText: string
  closeModal: () => void
}

export function DisclosureModal(props: DisclosureModalProps) {
  return (
    <Modal title='DISCLAIMER' closeModal={props.closeModal}>
      <div className='modal__content'>
        <div
          className='marketplace__disclosure'
          dangerouslySetInnerHTML={{ __html: props.disclosureText }}></div>
      </div>
    </Modal>
  )
}
