import { GlobalState } from '../../reducers'
import { createDeepEqualSelector } from './selectorCreator'
import { TaskInterfaceV2, TaskObj, ETaskStatus } from '../../objects/tasks'

export const getAllTasks = (
  store: GlobalState,
  householdFinId: string
): TaskInterfaceV2 => {
  return store?.tasks[householdFinId]
}

export const getAllNativeTasks = (
  store: GlobalState,
  householdFinId: string
): TaskInterfaceV2 => {
  return store?.tasks[householdFinId]
}

export const getAllCRMTasks = (
  store: GlobalState,
  householdFinId: string
): TaskInterfaceV2 => {
  return store?.crmTasks[householdFinId]
}

export const combineTasks = createDeepEqualSelector(
  getAllNativeTasks,
  getAllCRMTasks,
  (nativeTasks, crmTasks) => {
    return (
      nativeTasks &&
      crmTasks && {
        ...nativeTasks,
        tasks: { ...nativeTasks.tasks, ...crmTasks.tasks }
      }
    )
  }
)

// split tasks into open, in progress, and closed (Completed and Cancelled)
// TODO: this code modifies reduce state with assigment operators which can introduce bugs,
// breaks redux optimizations and completely unexpected behaviour, fix with next tasks improvemets
const mapTaskStatuses = (tasks: TaskInterfaceV2) => {
  if (tasks?.tasks) {
    const openTaskObj = {}
    const inProgressTasks = {}
    const completedTaskObj = {}
    Object.values(tasks.tasks).forEach((task: TaskObj) => {
      if (task.crmId || task.isCrmTask) {
        if (task.isClosed) {
          completedTaskObj[task.id] = task
        } else if (task.status === ETaskStatus.InProgress) {
          inProgressTasks[task.id] = task
        } else {
          openTaskObj[task.id] = task
        }
      } else {
        // native task considered closed if its Completed or Cancelled
        if (
          task.status === ETaskStatus.Completed ||
          task.status === ETaskStatus.Cancelled
        ) {
          completedTaskObj[task.id] = task
        } else if (task.status === ETaskStatus.InProgress) {
          inProgressTasks[task.id] = task
        } else {
          openTaskObj[task.id] = task
        }
      }
    })
    tasks.openTasks = openTaskObj
    tasks.inProgressTasks = inProgressTasks
    tasks.completedTasks = completedTaskObj
  }
  return tasks
}

export const tasksSelector = createDeepEqualSelector(
  [getAllTasks],
  (tasks): TaskInterfaceV2 => mapTaskStatuses(tasks)
)

export const tasksSelectorV2 = createDeepEqualSelector(
  combineTasks,
  (tasks): TaskInterfaceV2 => mapTaskStatuses(tasks)
)
