import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as PalIcon } from '../../assets/images/icons/pal.svg'

import Tile from '../../components/layout/tile'
import Heading from './heading'
import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'

import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'

export interface DashPalProps {
  goals: FinancialGoalActionObj[]
  householdFinId: string
}

const statusName = {
  optimal: 'Optimal Position',
  improvement: 'Improvement Needed',
  immediate: 'Immediate Action Needed',
  pending: 'NA | Pending Review'
}

export class DashPal extends Component<DashPalProps> {
  public render() {
    const { goals, householdFinId } = this.props
    let improvementCount: number = 0
    let optimalCount: number = 0
    let immediateCount: number = 0
    let pendingCount: number = 0
    if (goals) {
      goals
        .filter((goal: FinancialGoalActionObj) => goal.inGuidecenter)
        .forEach((key) => {
          const { status } = key
          switch (status) {
            case statusName.improvement:
              improvementCount += 1
              break
            case statusName.optimal:
              optimalCount += 1
              break
            case statusName.immediate:
              immediateCount += 1
              break
            default:
              pendingCount += 1
          }
        })
    }

    return (
      <Tile
        to={`/households/${householdFinId}/priorityActions`}
        headerStyle={{ padding: '12px 10px' }}
        leftHeader={<Heading icon={<PalIcon />} headingText='GOALS' />}
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={false}
        anchorBorder={true}>
        <div className='c-dash__row-content'>
          <div className='c-dash__row-four-content'>
            {improvementCount ||
            optimalCount ||
            immediateCount ||
            pendingCount ? (
              <div className='c-dash__pal-icon-w'>
                <a data-tip={statusName.optimal}>
                  <ReactTooltip place='bottom' type='dark' effect='solid' />
                  <span className='c-dash__pal-icon c-dash__pal-icon--green' />
                  <span>{optimalCount}</span>
                </a>
                <a data-tip={statusName.improvement}>
                  <ReactTooltip place='bottom' type='dark' effect='solid' />
                  <span className='c-dash__pal-icon c-dash__pal-icon--orange' />
                  <span>{improvementCount}</span>
                </a>
                <a data-tip={statusName.immediate}>
                  <ReactTooltip place='bottom' type='dark' effect='solid' />
                  <span className='c-dash__pal-icon c-dash__pal-icon--red' />
                  <span>{immediateCount}</span>
                </a>
                <a data-tip={statusName.pending}>
                  <ReactTooltip place='bottom' type='dark' effect='solid' />
                  <span className='c-dash__pal-icon c-dash__pal-icon--na' />
                  <span>{pendingCount}</span>
                </a>
              </div>
            ) : (
              <div className='c-dash__pal-icon-w'>
                <div className='c-dash__null-State' />
              </div>
            )}
          </div>
        </div>
      </Tile>
    )
  }
}

export default DashPal
