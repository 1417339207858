import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { dateFormat, timeFormat } from '../../../helpers'
import ActionDropdown from '../../../components/actionDropdown'
import { PortfolioProposalObj } from '../../../../objects/portfolioProposal'
import { GlobalState } from '../../../../reducers'
import { deletePortfolioProposal } from '../../../../actions/portfolioProposal'
import Modal from '../../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'
import { ReactComponent as InvestmentsIconSVG } from '../../../assets/images/icons/investments.svg'
import { downloadAndUploadFile } from '../../../helpers/guidebook'
import deletePngIcon from '../../../assets/images/icons/png/ic_delete.png'
import downloadIcon from '../../../assets/images/icons/png/ic_download.png'
import { ReactComponent as VaultIcon } from '../../../assets/images/icons/add-to-vault.svg'

export interface PortfolioProposalItemProps {
  portfolioProposal: PortfolioProposalObj
  householdFinId: string
  vaultFolderId: string
  dispatch: Dispatch<GlobalState>
}

interface PortfolioProposalItemState {
  showConfirmDeletePortfolioProposalModal: boolean
}

export class PortfolioProposalItem extends Component<
  PortfolioProposalItemProps,
  PortfolioProposalItemState
  > {
  constructor(props: PortfolioProposalItemProps) {
    super(props)
    this.state = { showConfirmDeletePortfolioProposalModal: false }
  }

  public downloadPortfolioProposal = () => {
    window.open(this.props.portfolioProposal.pdfUrl)
  }

  public addToVaultPortfolioProposal = () => {
    const { portfolioProposal: { pdfUrl, createdDate: timeStamp }, householdFinId, dispatch, vaultFolderId } = this.props
    downloadAndUploadFile({ fileName: 'PortolioProposal', householdFinId, fileUrl: pdfUrl, dispatch, vaultFolderId, timeStamp })
  }

  public toggleConfirmDeleteModal = () => {
    this.setState({
      showConfirmDeletePortfolioProposalModal: !this.state
        .showConfirmDeletePortfolioProposalModal
    })
  }

  public deleteGuidebook = () => {
    this.props.dispatch(
      deletePortfolioProposal(
        this.props.householdFinId,
        this.props.portfolioProposal.id
      )
    )
    this.toggleConfirmDeleteModal()
  }

  public download() {
    return (
      <Fragment>
        <img src={downloadIcon} alt='download' />
        <span className='action-dropdown__item-label'>Download</span>
      </Fragment>
    )
  }

  public addToVault() {
    return (
      <Fragment>
        <VaultIcon />
        <span className='action-dropdown__item-label'>Add to Vault</span>
      </Fragment>
    )
  }

  public delete() {
    return (
      <Fragment>
        <img src={deletePngIcon} alt='delete' />
        <span className='action-dropdown__item-label'>Delete</span>
      </Fragment>
    )
  }

  public confirmDeletePortfolioProposalModal() {
    return (
      <Modal
        icon={DeleteIcon}
        title='Delete Portfolio Proposal'
        closeModal={this.toggleConfirmDeleteModal}>
        <div className='gb-confirm-modal'>
          <div className='gb-confirm-modal__content'>
            <p>Are you sure you want to delete this Portfolio Proposal?</p>
          </div>
          <div className='gb-confirm-modal__buttons'>
            <span
              className='btn btn__clear'
              onClick={this.toggleConfirmDeleteModal}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={this.deleteGuidebook}>
              Delete
            </span>
          </div>
        </div>
      </Modal>
    )
  }

  public render() {
    const { name, createdDate } = this.props.portfolioProposal
    const dropdownActions = [
      {
        element: this.download(),
        onClick: this.downloadPortfolioProposal
      },
      {
        element: this.addToVault(),
        onClick: this.addToVaultPortfolioProposal
      },
      {
        element: this.delete(),
        onClick: this.toggleConfirmDeleteModal
      }
    ]
    return (
      <div className='gb-item'>
        <span className='gb-item__icon'>
          <InvestmentsIconSVG />
        </span>
        <div className='gb-item__name'>{name}</div>
        <div className='gb-item__date'>
          {dateFormat(createdDate) + ' ' + timeFormat(createdDate)}
        </div>
        <div className='gb-item__actions'>
          <ActionDropdown actions={dropdownActions} listClass='action-dropdown__item--hover-disabled' />
        </div>
        {this.state.showConfirmDeletePortfolioProposalModal
          ? this.confirmDeletePortfolioProposalModal()
          : null}
      </div>
    )
  }
}
export default connect()(PortfolioProposalItem)
