import React, { Fragment } from 'react'
import ScoreSelector from '../../scoreSelector'
import { ContactObj } from '../../../../../objects/contact'
// import ScoreCommentTextArea from '../../scoreCommentTextArea'
// import ArrowIcon from '../../../../assets/images/icons/arrow-gray-down.png'

export interface ScoreModalBodyProps {
  contact: ContactObj
  isPrimary: boolean
  cardId: string
  comment: string
  score: number
  modalElement: boolean
  placeholderTxt?: string
  onScoreChange(score: number, isPrimary: boolean): void
  onCommentChange(comment: string, isPrimary: boolean, index: number): any
}

const ScoreModalBody = ({
  contact,
  // onCommentChange,
  isPrimary,
  cardId,
  comment,
  score,
  modalElement,
  onScoreChange,
  placeholderTxt = 'What does this card mean to you, and why is it important?'
}: ScoreModalBodyProps) => {
  // const [showCommentView, setCommentViewState] = useState(false)
  // const toggleCommentView = () => {
  //   setCommentViewState(!showCommentView)
  // }

  const title = contact.firstName
    ? contact.firstName
    : isPrimary
    ? 'Primary'
    : 'Secondary'
  return (
    <Fragment>
      <div>
        <div className='score-select-modal__header-w score-select-modal__header-collapsible'>
          <span className='score-select-modal__header-collapsible-title'>
            <span>{title}</span>
            {/* <img
              src={ArrowIcon}
              alt=''
              role='presentation'
              className={`score-select-modal__header-collapsible-icon${
                showCommentView ? '--up' : ''
              }`}
            /> */}
          </span>
        </div>
        {/* {showCommentView ? (
          <ScoreCommentTextArea
            onCommentChange={onCommentChange}
            isPrimary={isPrimary}
            cardIndex={cardId}
            placeholderTxt={placeholderTxt}
            value={comment}
            tooltipPos='top-left'
          />
        ) : null} */}
      </div>
      <ScoreSelector
        score={score}
        modalElement={modalElement}
        isPrimary={isPrimary}
        onScoreChange={onScoreChange}
      />
    </Fragment>
  )
}

export default ScoreModalBody
