import React, { Component, Fragment, ReactNode } from 'react'
import { connect, Dispatch } from 'react-redux'
import * as hcExerciseActions from '../../../../../actions/hcExercises'
import { GlobalState } from '../../../../../reducers'
import ActionDropdown from '../../../../components/actionDropdown'
import { ReactComponent as CheckMarkIcon } from '../../../../assets/images/icons/check.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/delete.svg'
import { ReactComponent as LockIcon } from '../../../../assets/images/icons/lock.svg'
import { ScoringSessionsObj } from '../../../../../objects/honestConversations'
import moment from 'moment'
import Tooltip from '../../../../components/tooltip'

interface ClientScoreSessionRowProps {
  dispatch: Dispatch<GlobalState>
  isPrimary: boolean
  householdFinId: string
  exerciseId: string
  contactId: string
  scoringSession: ScoringSessionsObj
  scoringSessionId: string
  activeScoreId: string
  onClickScoreRow(
    contactId: string,
    scoringSessionId: string,
    isPrimary: boolean
  ): void
}

interface ClientScoringSessionRowState {
  activeScoreIdState: string
}

class ClientScoreSessionRow extends Component<
  ClientScoreSessionRowProps,
  ClientScoringSessionRowState
> {
  constructor(props: ClientScoreSessionRowProps) {
    super(props)
    this.state = {
      activeScoreIdState: props.activeScoreId
    }
  }

  public componentDidUpdate(nextProps: any) {
    if (this.state.activeScoreIdState !== this.props.activeScoreId) {
      this.setState({
        activeScoreIdState: this.props.activeScoreId
      })
    }
  }

  public generateMeterTick = (
    score: number,
    isPrimaryContact: boolean
  ): ReactNode[] => {
    const tick: ReactNode[] = []
    let i: number = 0
    let fillColor = '#D2D2D2'
    do {
      const xPos: number = i * 16
      i = i + 1
      if (score > 0) {
        if (i <= score) {
          fillColor = score >= 7 ? '#a2d134' : '#FFBE1A'
        } else {
          fillColor = '#d2d2d2'
        }
      }
      tick.push(
        <rect key={i} width='8px' height='16px' fill={fillColor} x={xPos} />
      )
    } while (i < 10)
    return tick
  }

  public averageScore = () => {
    const { scoringSession } = this.props

    if (
      !scoringSession ||
      !scoringSession.cardScores ||
      Object.keys(scoringSession.cardScores).length === 0
    ) {
      return 0
    }

    const scoreArray: number[] = []
    Object.keys(scoringSession.cardScores).forEach((key) => {
      scoreArray.push(scoringSession.cardScores[key].score)
    })

    return Math.round(
      scoreArray.reduce((total, num) => {
        return total + num
      }) / 5
    )
  }

  public deleteThisScore = () => {
    const {
      dispatch,
      householdFinId,
      contactId,
      scoringSession,
      scoringSessionId
    } = this.props

    const { activeScoreIdState } = this.state

    if (scoringSessionId !== activeScoreIdState) {
      dispatch(
        hcExerciseActions.deleteScoringSession(
          householdFinId,
          scoringSession.exerciseId,
          contactId,
          scoringSessionId
        )
      )
    }
  }

  public setAsActiveScore = async () => {
    const {
      dispatch,
      isPrimary,
      householdFinId,
      exerciseId,
      scoringSessionId
    } = this.props

    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    if (isPrimary) {
      updatedProperty.activeScoringSessionPrimary = scoringSessionId
    } else {
      updatedProperty.activeScoringSessionSecondary = scoringSessionId
    }

    await dispatch(
      hcExerciseActions.updateHonestConversationExercise(
        householdFinId,
        exerciseId,
        updatedProperty
      )
    )
  }

  public setDropdownOptions = (): { element: ReactNode; onClick(): void }[] => {
    const { scoringSessionId } = this.props
    const { activeScoreIdState } = this.state

    const deleteOption =
      scoringSessionId !== activeScoreIdState ? (
        <Fragment>
          <DeleteIcon />
          <span className='action-dropdown__item-label'>Delete Record</span>
        </Fragment>
      ) : (
        <Fragment>
          <LockIcon />
          <Tooltip position='top' message='Linked to active record' width={160}>
            <span className='action-dropdown__item-label'>Delete Record</span>
          </Tooltip>
        </Fragment>
      )

    return [
      {
        element: (
          <Fragment>
            <CheckMarkIcon />
            <span className='action-dropdown__item-label'>Set as Active</span>
          </Fragment>
        ),
        onClick: this.setAsActiveScore
      },
      {
        element: deleteOption,
        onClick: this.deleteThisScore
      }
    ]
  }

  public getActiveState = (activeId: string) => {
    const { scoringSessionId } = this.props
    return scoringSessionId === activeId ? (
      <div className='task__status task__status--green'>Active</div>
    ) : null
  }

  public getScoringDate = (createdDate: string): string => {
    return moment(createdDate).format('MM/DD/YY')
  }

  public onClickRow = () => {
    if (this.props.onClickScoreRow) {
      const { scoringSessionId, contactId, isPrimary } = this.props
      this.props.onClickScoreRow(contactId, scoringSessionId, isPrimary)
    }
  }

  public render() {
    const { scoringSession, isPrimary } = this.props
    const { activeScoreIdState } = this.state
    const averageScore: number = this.averageScore()

    let fillClass =
      'client-score-history__average client-score-history__average-empty'
    if (averageScore > 0) {
      fillClass =
        averageScore >= 7
          ? 'client-score-history__average client-score-history__average-primary'
          : 'client-score-history__average client-score-history__average-secondary'
    }

    return (
      <div className='client-score-history__row' onClick={this.onClickRow}>
        <div className='client-score-history__column-one'>
          <div className={fillClass}>{averageScore}</div>
          <div className='client-score-history__meter'>
            <svg height='16px'>
              {this.generateMeterTick(averageScore, isPrimary)}
            </svg>
            <div className='client-score-history__state'>
              {this.getActiveState(activeScoreIdState)}
            </div>
          </div>
        </div>
        <div className='client-score-history__column-two'>
          {scoringSession ? (
            <div className='client-score-history__date'>
              {scoringSession
                ? moment(scoringSession.createdDate).format('MM/DD/YY')
                : '--/--/--'}
            </div>
          ) : (
            <div className='client-score-history__date-null'>--/--/--</div>
          )}
          {scoringSession ? (
            <div className='client-score-history__actions'>
              <ActionDropdown actions={this.setDropdownOptions()} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    householdFinId
  }
}

export default connect(mapStateToProps)(ClientScoreSessionRow)
