import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { HtmlAttributes } from 'csstype'

import CheckBox from '../checkbox'
import ProfilePicture from './profilePicture'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import { ActionEditInterface } from '../../../objects/lifeActions'

export interface AssigneeSelectProps {
  selectPosition?: 'absolute'
  contacts: ContactsInterface
  lifeActionId?: string
  actionEdits?: ActionEditInterface
  assigneePrimaryId: string
  assigneeSecondaryId: string
  tickPosition?: 'middle' | 'left' | 'right'
  toggleAssignOwnerModal(): void
  updateActionAssignee(
    assigneeId: string,
    contact: string,
    actionId: string
  ): void
}

interface AssigneeSelectState {
  assigneePrimary: boolean
  assigneeSecondary: boolean
}

export class AssigneeSelect extends Component<
  AssigneeSelectProps,
  AssigneeSelectState
> {
  constructor(props: AssigneeSelectProps) {
    super(props)
    this.state = {
      assigneePrimary: this.isChecked(true),
      assigneeSecondary: this.isChecked(false)
    }
  }

  public isChecked = (isPrimary: boolean): boolean => {
    const {
      lifeActionId,
      actionEdits,
      assigneePrimaryId,
      assigneeSecondaryId
    } = this.props
    const actionEditId = actionEdits && actionEdits[lifeActionId]
    const primaryId = actionEditId?.assigneePrimaryId
    const secondaryId = actionEditId?.assigneeSecondaryId
    const filteredPrimaryId =
      primaryId?.length > 0 || primaryId === null
        ? primaryId
        : assigneePrimaryId
    const filteredSecondaryId =
      secondaryId?.length > 0 || secondaryId === null
        ? secondaryId
        : assigneeSecondaryId
    return isPrimary ? Boolean(filteredPrimaryId) : Boolean(filteredSecondaryId)
  }

  public selectOwner = (assigneeId: string, checked: boolean) => {
    const { lifeActionId, contacts, updateActionAssignee } = this.props
    if (assigneeId === contacts.primary.id) {
      this.setState({
        assigneePrimary: checked ? Boolean(assigneeId) : false
      })
      updateActionAssignee(checked ? assigneeId : null, 'Primary', lifeActionId)
    } else {
      this.setState({
        assigneeSecondary: checked ? Boolean(assigneeId) : false
      })
      updateActionAssignee(
        checked ? assigneeId : null,
        'Secondary',
        lifeActionId
      )
    }
  }

  public handleClickOutside = (_e: React.MouseEvent<HtmlAttributes>) => {
    this.props.toggleAssignOwnerModal()
  }

  public renderContact = (
    contact: ContactObj,
    isChecked: boolean,
    isPrimary: boolean
  ) => {
    return (
      <div className='assignee-select__contact-w'>
        <CheckBox
          id={contact.id}
          style={{ width: 'auto' }}
          checkedBorder={true}
          checked={isChecked}
          onChange={this.selectOwner}
        />
        <ProfilePicture
          firstName={contact?.firstName}
          lastName={contact?.lastName}
          photo={contact?.photo}
          isPrimary={isPrimary}
          pictureSize={25}
        />
        <p className='assignee-select__contact-name'>
          {contact.firstName} {contact.lastName}
        </p>
      </div>
    )
  }

  public render() {
    const { contacts, tickPosition, selectPosition = '' } = this.props
    return (
      <div
        className={`assignee-select assignee-select--${tickPosition ||
          'left'} ${
          selectPosition ? `assignee-select--${selectPosition}` : ''
        }`}>
        {contacts.secondary &&
          this.renderContact(contacts.secondary, this.isChecked(false), false)}
        {contacts.primary &&
          this.renderContact(contacts.primary, this.isChecked(true), true)}
      </div>
    )
  }
}

export default onClickOutside(AssigneeSelect)
