export const PRIMARY_LINE_COLOR = '#CBBFB4'
export const SECONDARY_LINE_COLOR = '#989DC2'

export const dataSetTemplate: LineChartObj = {
  label: 'Score History',
  fill: false,
  lineTension: 0,
  borderCapStyle: 'butt',
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 4,
  pointHoverBorderColor: 'rgba(255,255,255,1)',
  pointHoverBorderWidth: 1,
  pointHitRadius: 10,
  pointBorderColor: 'white'
}

export interface LineChartObj {
  label: string
  fill: boolean
  lineTension: number
  borderCapStyle: string
  borderJoinStyle: string
  pointBorderWidth: number
  pointHoverRadius: number
  pointHoverBorderColor: string
  pointHoverBorderWidth: number
  pointHitRadius: number
  pointBorderColor: string
  datasets?: any[]
}

export interface AverageScoreData {
  averageScores: number[]
  scoresDate: string[]
}
