import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Header from './header'
import CurrentAllocationTile from './currentAllocation/currentAllocationTile'
import TargetAllocationTile from './targetAllocation/targetAllocationTile'

import ClientStrategies from './strategy/clientStrategies'

import ProposedWork from './proposalWork'
import ClientProfile from './clientProfile/clientProfile'
// import PortfolioProposal from './portfolioProposal'

import { GlobalState } from '../../../reducers'
import { getStrategies } from '../../../actions/strategies'
// import * as honestActions from '../../../actions/honestConversations'
import * as clientAccounts from '../../../actions/clientAccounts'
import * as viewfinderActions from '../../../actions/investmentViewfinder'
import * as investmentAction from '../../../actions/investments'
import * as accountMapActions from '../../../actions/accountMap'
import * as portfolioBuilderActions from '../../../actions/portfolioBuilder'
import Disclosure from '../../components/layout/disclosure'

interface PortfolioProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  portfolioBuilderId: string
}

interface PortfolioStates {
  strategyMenu: boolean
  openFilterPopup: boolean
  openSortPopup: boolean
}
class PortfolioBuilder extends Component<PortfolioProps, PortfolioStates> {
  constructor(props: PortfolioProps) {
    super(props)
    this.state = {
      strategyMenu: true,
      openFilterPopup: false,
      openSortPopup: false
    }
  }

  public async componentDidMount() {
    const { householdFinId, portfolioBuilderId, dispatch } = this.props
    // dispatch(honestActions.getHonestConversation(householdFinId))
    dispatch(clientAccounts.getClientAccounts(householdFinId))
    dispatch(viewfinderActions.getViewfinderExercises(householdFinId))
    dispatch(investmentAction.getPssSummary(householdFinId))
    dispatch(accountMapActions.createMapValue(householdFinId))
    await dispatch(getStrategies(householdFinId))
    await dispatch(portfolioBuilderActions.getPortfolioBuilders(householdFinId))
    dispatch(
      portfolioBuilderActions.getPortfolioBuilderAccounts(
        householdFinId,
        portfolioBuilderId
      )
    )
  }

  public strategyMenuToggle = () => {
    this.setState({ strategyMenu: !this.state.strategyMenu })
  }

  public onFilterPopupOpen = () => {
    this.setState({ openFilterPopup: true, openSortPopup: false })
  }

  public onFilterPopupClose = () => {
    this.setState({ openFilterPopup: false })
  }

  public onSortPopupOpen = () => {
    this.setState({ openSortPopup: true, openFilterPopup: false })
  }

  public onSortPopupClose = () => {
    this.setState({ openSortPopup: false })
  }

  public handleStrategyHeaderClick = () => {
    this.strategyMenuToggle()
    this.onFilterPopupClose()
    this.onSortPopupClose()
  }

  public mainView = () => {
    const { strategyMenu } = this.state
    return (
      <div className='portfolio-builder__wrapper-body'>
        <div
          className='portfolio-builder__wrapper-tile1'
          style={
            strategyMenu ? { marginRight: '400px' } : { paddingRight: '106px' }
          }>
          <ClientProfile />
          <div className='portfolio-builder__wrapper-allocation'>
            <div className='portfolio-builder__wrapper-allocation-current'>
              <CurrentAllocationTile />
            </div>
            <div className='portfolio-builder__wrapper-allocation-proposed'>
              <TargetAllocationTile />
            </div>
          </div>
          <ProposedWork />
          <Disclosure />
        </div>
        <div
          className='portfolio-builder__wrapper-tile2'
          style={strategyMenu ? { width: 'auto' } : { width: 0 }}>
          <ClientStrategies
            strategyMenuToggle={this.strategyMenuToggle}
            strategyMenu={this.state.strategyMenu}
            onFilterPopupOpen={this.onFilterPopupOpen}
            onFilterPopupClose={this.onFilterPopupClose}
            onSortPopupOpen={this.onSortPopupOpen}
            onSortPopupClose={this.onSortPopupClose}
            openFilterPopup={this.state.openFilterPopup}
            openSortPopup={this.state.openSortPopup}
            handleStrategyHeaderClick={this.handleStrategyHeaderClick}
          />
        </div>
      </div>
    )
  }

  public render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <div className='portfolio-builder'>
          <Header handleStrategyHeaderClick={this.handleStrategyHeaderClick} />
          <div className='portfolio-builder__wrapper'>{this.mainView()}</div>
        </div>
      </DndProvider>
    )
  }
}

const mapStateToProps = (_store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    portfolioBuilderId: match.params.portfolioBuilderId
  }
}

export default withRouter(connect(mapStateToProps)(PortfolioBuilder))
