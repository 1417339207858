import React, { SFC } from 'react'
import {
  HonestConversationsExerciseObj,
  CardRankingObj
} from '../../../../objects/honestConversations'
import HonestConversationCardWrapper from '../hcCardWrapper'
import { CardObj } from '../../../../objects/HonestConversations/card'

import { HonestConversationCardRow } from './hcCardRow'
import { ContactsInterface, ContactObj } from '../../../../objects/contact'

interface HonestConversationIndividualCardsProps {
  honestConversation: HonestConversationsExerciseObj
  primaryContactCards: CardRankingObj[]
  secondaryContactCards: CardRankingObj[]
  contacts: ContactsInterface
}

const HonestConversationIndividualCardsReact: SFC<HonestConversationIndividualCardsProps> = (
  props
) => {
  const { contacts, primaryContactCards, secondaryContactCards } = props

  const primaryContact = contacts && contacts.primary
  const secondaryContact = contacts && contacts.secondary

  const individualCardRows = (rankedCards: CardRankingObj[]) => {
    if (!rankedCards) return null

    return rankedCards.map((card, index) => {
      if (card && card.id) {
        const commentString: string =
          card.comment && card.comment.length > 0
            ? card.comment
            : 'No comment added'
        return (
          <HonestConversationCardRow
            key={card.id}
            card={card.card}
            comment={commentString}
          />
        )
      } else {
        const nullCard: CardObj = { id: null, title: null, category: null }
        return (
          <div className='hc-card-row__w' key={index + 1}>
            <HonestConversationCardWrapper
              card={nullCard}
              displayIndex={index + 1}
            />
            <div className='hc-card-row__comment'>
              <span className='hc-card-row__comment--null'>
                No comment added
              </span>
            </div>
          </div>
        )
      }
    })
  }

  const individualCards = (
    contact: ContactObj,
    rankedCards: CardRankingObj[],
    contactType: string
  ) => {
    const headerText = contact.firstName
      ? `${contact.firstName}'s Individual Results`
      : `${contactType} Individual Results`
    return (
      <div>
        <div className='hc-meeting__sub-header hc-meeting__content-header'>
          <div className='hc-meeting__sub-header--title'>{headerText}</div>
        </div>
        <div className='hc__individual--pdf-margin'>
          {individualCardRows(rankedCards)}
        </div>
      </div>
    )
  }

  return (
    <div>
      {primaryContact &&
        individualCards(primaryContact, primaryContactCards, 'Primary')}
      <div style={{ height: '40px' }} />
      {secondaryContact &&
        individualCards(secondaryContact, secondaryContactCards, 'Secondary')}
    </div>
  )
}

export default HonestConversationIndividualCardsReact
