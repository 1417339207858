import React, { FunctionComponent, SVGProps, Fragment } from 'react'
import * as userActions from '../../../../actions/user'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { CRMHouseholdsInterface } from '../../../../objects/user'
import Button from '../../../components/button'
import SyncList from './syncList'
import { ReactComponent as Chevron } from '../../../assets/images/icons/arrow.svg'
import { deleteHouseholds } from '../../../../actions/households'
import { ReactComponent as FinLifeSvg } from '../../../assets/images/logos/finlife-cx-logo.svg'
import { history } from '../../../../store'
import { SyncLoader } from './syncLoader'

interface SyncModalProps {
  crm: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  iconPng?: string
  dispatch: Dispatch<GlobalState>
  fetchHouseholdsFailed: boolean
  fetchHouseholdsSuccessful: boolean
  crmHouseholds: CRMHouseholdsInterface
  v2ACL: boolean
}

interface SyncModalState {
  syncedHouseholdIds: string[]
  unsyncedHouseholdIds: string[]
  householdsToBeDeleted: string[]
  householdsToBeSynced: string[]
  syncing: boolean
}

class HouseholdSync extends React.Component<SyncModalProps, SyncModalState> {
  constructor(props: SyncModalProps) {
    super(props)
    this.state = {
      syncedHouseholdIds: [],
      unsyncedHouseholdIds: [],
      householdsToBeDeleted: [],
      householdsToBeSynced: [],
      syncing: false
    }
  }

  public componentDidMount() {
    this.filterAndSetHouseholdIds()
  }

  public componentDidUpdate(prevProps: SyncModalProps) {
    if (prevProps.crmHouseholds !== this.props.crmHouseholds) {
      this.filterAndSetHouseholdIds()
    }
  }

  public filterAndSetHouseholdIds = () => {
    const { crmHouseholds } = this.props
    const syncedHouseholdIds = Object.keys(crmHouseholds).filter((key) => {
      return crmHouseholds[key]._hasSynced
    })
    const unsyncedHouseholdIds = Object.keys(crmHouseholds).filter((key) => {
      return !crmHouseholds[key]._hasSynced
    })

    this.setState({
      syncedHouseholdIds,
      unsyncedHouseholdIds
    })
  }

  public previewHouseholdSync = async () => {
    await this.props.dispatch(userActions.previewHouseholdSync())
  }

  public toggleUnsyncedHousehold = (id: string) => {
    const { householdsToBeSynced } = this.state
    const index = householdsToBeSynced.indexOf(id)
    if (index > -1) {
      householdsToBeSynced.splice(index, 1)
    } else {
      householdsToBeSynced.push(id)
    }
    this.setState({ householdsToBeSynced })
  }

  public toggleSyncedHousehold = (id: string) => {
    const { householdsToBeDeleted } = this.state
    const index = householdsToBeDeleted.indexOf(id)
    if (index > -1) {
      householdsToBeDeleted.splice(index, 1)
    } else {
      householdsToBeDeleted.push(id)
    }
    this.setState({ householdsToBeDeleted })
  }

  public addHouseholds = async () => {
    const { dispatch } = this.props
    const { householdsToBeSynced } = this.state
    await dispatch(userActions.syncHouseholds(householdsToBeSynced))
    this.setState({ householdsToBeSynced: [], syncing: true })
  }

  public removeHouseholds = async () => {
    const { dispatch, crmHouseholds } = this.props
    const { householdsToBeDeleted } = this.state
    const householdIds = householdsToBeDeleted.map((id: string) => {
      return crmHouseholds[id]._id
    })
    await dispatch(deleteHouseholds(householdIds))
    dispatch(
      userActions.removeHouseholdsFromSyncedHouseholdList(householdsToBeDeleted)
    )
    this.setState({ householdsToBeDeleted: [] })
  }

  public redirectToAdministration = () => {
    history.push('/administration')
  }

  public loadComplete = () => {
    this.setState({ syncing: false })
    this.previewHouseholdSync()
  }

  public render() {
    const {
      crm,
      fetchHouseholdsSuccessful,
      fetchHouseholdsFailed,
      icon,
      iconPng,
      v2ACL,
      crmHouseholds
    } = this.props
    const {
      householdsToBeSynced,
      householdsToBeDeleted,
      syncedHouseholdIds,
      unsyncedHouseholdIds,
      syncing
    } = this.state

    const unsyncedHouseholds = unsyncedHouseholdIds.map(
      (householdId: string) => {
        return crmHouseholds[householdId]
      }
    )

    const syncedHouseholds = syncedHouseholdIds.map((householdId: string) => {
      return crmHouseholds[householdId]
    })

    if (fetchHouseholdsSuccessful) {
      return (
        <Fragment>
          <div className='transfer-list__group'>
            {/* Unsynced List Component */}
            <SyncList
              title={`${crm.toUpperCase()} CLIENTS`}
              icon={icon}
              iconPng={iconPng}
              v2ACL={v2ACL}
              crmHouseholds={unsyncedHouseholds}
              previewHouseholdSync={this.previewHouseholdSync}
              stagedHouseholds={householdsToBeSynced}
              toggleHousehold={this.toggleUnsyncedHousehold}
            />

            {/* List Transfer Buttons - Add/Remove Unsynced Accounts to/from Sync List */}
            <div className='transfer-list__transfer-btn-group'>
              <div onClick={this.addHouseholds}>
                <div className='transfer-list__transfer-btn btn__prime'>
                  <span className='transfer-list__transfer-btn--end-spacer' />
                  <span className='transfer-list__transfer-btn-text--add-transfer transfer-list__transfer-btn--middle-spacer'>
                    Add
                  </span>
                  <span className='transfer-list__transfer-btn-icon--add-transfer transfer-list__transfer-btn--end-spacer test'>
                    <Chevron />
                  </span>
                </div>
              </div>
              <div onClick={this.removeHouseholds}>
                <div className='transfer-list__transfer-btn btn__prime'>
                  <span className='transfer-list__transfer-btn-icon--remove-transfer transfer-list__transfer-btn--end-spacer'>
                    <Chevron />
                  </span>
                  <span className='transfer-list__transfer-btn-text--remove-transfer transfer-list__transfer-btn--middle-spacer'>
                    Remove
                  </span>
                  <span className='transfer-list__transfer-btn--end-spacer' />
                </div>
              </div>
            </div>
            {/* Synced List Component */}
            <SyncList
              title='SYNCED CLIENTS'
              icon={FinLifeSvg}
              v2ACL={v2ACL}
              crmHouseholds={syncedHouseholds}
              stagedHouseholds={householdsToBeDeleted}
              toggleHousehold={this.toggleSyncedHousehold}
            />
          </div>
          <div className='modal__buttons modal__buttons--w'>
            <Button primary={true} onClick={this.redirectToAdministration}>
              Finish
            </Button>
          </div>
          {syncing ? <SyncLoader loadComplete={this.loadComplete} /> : null}
        </Fragment>
      )
    } else {
      if (!fetchHouseholdsFailed) {
        return <div className='sync-modal__loading'>Loading Client List</div>
      } else return null
    }
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    fetchHouseholdsFailed: store.user.fetchHouseholdsFailed,
    fetchHouseholdsSuccessful: store.user.fetchHouseholdsSuccessful,
    crmHouseholds: store.user.crmHouseholds,
    v2ACL: store.user.v2ACL
  }
}

export default connect(mapStateToProps)(HouseholdSync)
