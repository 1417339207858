import React, { Component } from 'react'
import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { ClientCardObj } from '../../../../../objects/honestConversations'
import couple from '../../../../assets/images/honestConversations/couple.png'

interface ClientCardTableProps {
  cards: ClientCardObj[]
  firstName: string
  id: string
  contactType: string
}

export class ClientCardTable extends Component<ClientCardTableProps> {
  public render() {
    const { firstName, cards, id, contactType } = this.props
    return (
      <div className='combined-card__column-w'>
        <h1 className='combined-card__name'>
          {Boolean(firstName) ? firstName : contactType}
        </h1>
        <Droppable droppableId={id} direction='vertical'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className='combined-card__cards-w'>
                {cards &&
                  cards.map((card, index: number) => {
                    return (
                      <Draggable
                        key={card.id}
                        draggableId={card.id}
                        index={index}>
                        {(dragProvided, _snapshot) => (
                          <div
                            className='combined-card__main-w'
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            style={{
                              marginBottom: 15,
                              ...dragProvided.draggableProps.style
                            }}>
                            <Card
                              category={card.card.category}
                              title={card.card.title}
                            />
                            <div className='combined-card__card-shared'>
                              <img
                                src={card.sharedCard ? couple : null}
                                role='presentation'
                                alt=''
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    )
  }
}

export default ClientCardTable
