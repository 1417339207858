import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

import { GlobalState } from '../../../../reducers'
import {
  getFinancialGoals,
  addFinancialGoal
} from '../../../../actions/financialGoals'
import { addToast } from '../../../../actions/toasts'
import { FinancialGoalObj } from '../../../../objects/financialGoal'

import GoalModal from './goalModal'
import Button from '../../../components/button'

import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'

interface AddGoalProps {
  householdFinId: string
  dispatch: Dispatch<GlobalState>
}

interface AddGoalState {
  modalOpen: boolean
}

class AddGoal extends Component<AddGoalProps, AddGoalState> {
  constructor(props: AddGoalProps) {
    super(props)
    this.state = {
      modalOpen: false
    }
  }

  public submitGoal = async (goal: FinancialGoalObj) => {
    const { householdFinId, dispatch } = this.props
    this.toggleModal()
    await dispatch(addFinancialGoal(goal, householdFinId))
    dispatch(getFinancialGoals(householdFinId))
    dispatch(
      addToast({
        message: 'Plan Input successfully created',
        backgroundColor: '#D9F2B6'
      })
    )
  }

  public toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  public render() {
    const { modalOpen } = this.state
    return (
      <div className='add-goal-w'>
        <div className='add-goal__btn' onClick={this.toggleModal}>
          <Button primary={true} onClick={this.toggleModal}>
            <PlusIcon /> Add New
          </Button>
        </div>
        {modalOpen ? (
          <GoalModal
            title='Add Plan Input'
            submitText='Add'
            submit={this.submitGoal}
            closeModal={this.toggleModal}
          />
        ) : null}
      </div>
    )
  }
}

export default AddGoal
