import React, { Component } from 'react'
import BookIcon from '../../assets/images/icons/png/il-book.png'
import Tile from '../../components/layout/tile'
import { formatPhoneNumber } from '../../helpers'
import Select from 'react-select'

interface ShippingProps {
  quantity: number
  quantityError: boolean
  recipientInfo: {
    firstName: string
    lastName: string
    street: string
    addressLine2: string
    city: string
    state: string
    postalCode: string
    phone: string
  }
  template: string
  shipping: any
  updatedQuantity(value: any): void
  selectShippingMethodLiterally(value: any): void
}

interface ShippingState {
  uneditable: boolean
}

class Shipping extends Component<ShippingProps, ShippingState> {
  constructor(props: ShippingProps) {
    super(props)
    this.state = {
      uneditable: true
    }
  }

  public editQuantity = () => {
    this.setState({ uneditable: !this.state.uneditable })
  }

  public render() {
    const {
      quantity,
      updatedQuantity,
      quantityError,
      recipientInfo,
      template,
      shipping,
      selectShippingMethodLiterally
    } = this.props

    const { uneditable } = this.state

    const shippingOptionsMap = () => {
      const optionList: any = []
      if (shipping.options) {
        shipping.options.forEach((option: any) => {
          optionList.push({
            value: option.id,
            label: `${option.deliveryName}, ${option.deliveryCharge}, ${option.deliveryCommitmentDate.date}`
          })
        })
      }
      return optionList
    }

    return (
      <div className='mimeo__section-w'>
        <Tile
          iconPng={null}
          leftHeader={'SHIPPING INFO'}
          style={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '20px'
          }}>
          <div className='mimeo__section'>
            <div className='mimeo__section-left mimeo-shipping__title-w'>
              <div className='mimeo-shipping__title'>
                <img src={BookIcon} alt='book icon' />
                Guidebook
              </div>
              <div className='mimeo-shipping__input-w'>
                <div className='mimeo-shipping__input'>
                  <span>Quantity</span>
                  {!uneditable ? (
                    <input
                      onChange={updatedQuantity}
                      value={quantity}
                      type='text'
                      className={
                        quantityError
                          ? 'mimeo__input  mimeo-productinfo__input mimeo__input--error'
                          : 'mimeo__input  mimeo-productinfo__input'
                      }
                    />
                  ) : (
                    <div className='mimeo-shipping__input--locked'>
                      {quantity}
                    </div>
                  )}
                </div>
                <div
                  onClick={this.editQuantity}
                  className='mimeo-shipping__quantity-update'>
                  {uneditable ? 'Change' : 'Update'}
                </div>
              </div>
            </div>
            <div className='mimeo__section-right mimeo__section-right--end'>
              <div className='mimeo-shipping__details'>
                <span>TEMPLATE</span>
                <div>{template}</div>
              </div>
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__section-left mimeo__section-left--column'>
              <div className='mimeo-shipping__info-details'>
                <span>NAME</span>
                <div>
                  {recipientInfo.firstName + ' ' + recipientInfo.lastName}
                </div>
              </div>
              {recipientInfo.street && (
                <div className='mimeo-shipping__info-details'>
                  <span>ADDRESS</span>
                  <div>
                    {recipientInfo.street +
                      ', ' +
                      recipientInfo.addressLine2 +
                      ' ' +
                      recipientInfo.city +
                      ', ' +
                      recipientInfo.state +
                      ' ' +
                      recipientInfo.postalCode}
                  </div>
                </div>
              )}
              {recipientInfo.phone && (
                <div className='mimeo-shipping__info-details'>
                  <span>PHONE</span>
                  <div>{formatPhoneNumber(recipientInfo.phone)}</div>
                </div>
              )}
            </div>
            <div className='mimeo__section-right'>
              <div className='mimeo-shipping__shipping-w'>
                <span>Select Shipping Method</span>
                <Select
                  isClearable
                  onChange={selectShippingMethodLiterally}
                  options={shippingOptionsMap()}
                />
              </div>
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

export default Shipping
