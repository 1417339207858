import React, { FormEvent } from 'react'
// Types
import {
  GoalsToggleStateObj,
  GoalActionSortByTypes
} from '../../../../objects/financialGoalActions'
// components
import Button from '../../../components/button'
import { RadioButton } from '../../../components/Radio'
import FilterToggleRow from './filterToggleRow'
import FilterIcon from '../../../assets/images/icons/png/filter_blue_x2.png'

interface FilterModalProps {
  selectedSortBy: GoalActionSortByTypes
  toggle: GoalsToggleStateObj
  onRadioSelect(
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ): void
  toggleFilterModal(): void
  handleFilterToggle(
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ): void
  updatePreferences(): void
  resetFilters(): void
}

export const FilterModal: React.FC<FilterModalProps> = (props) => {
  const {
    toggleFilterModal,
    resetFilters,
    toggle,
    handleFilterToggle,
    updatePreferences,
    onRadioSelect,
    selectedSortBy
  } = props

  const radioOptions: GoalActionSortByTypes[] = [
    'Type',
    'Name',
    'Status',
    'Date',
    'Owner'
  ]

  const renderRadioGroup = () =>
    radioOptions.map((option: GoalActionSortByTypes, i: number) => (
      <div key={option} className='filter-modal__body-radio-group'>
        <RadioButton
          key={i}
          id={`${option}--${i}`}
          labelDisplay={option}
          name={option}
          value={option}
          checked={
            selectedSortBy === 'Due Date' && option === 'Date'
              ? true
              : selectedSortBy === option
          }
          changeFunc={onRadioSelect}
          className='list-group-item'
          underlineTextOnHover={true}
        />
      </div>
    ))

  return (
    <div className='filter-modal'>
      <div className='filter-modal__header'>
        <div className='filter-modal__header-label'>
          <img src={FilterIcon} width={20} alt='filter' />
          <span className='filter-modal__header-text'>FILTER</span>
        </div>
        <Button clear={true} onClick={resetFilters}>
          Reset
        </Button>
      </div>
      <div className='filter-modal__body'>
        <div>
          <FilterToggleRow
            label='Hide Inactive Goals'
            isActionType={false}
            active={!toggle.showInactiveItems}
            name='showInactiveItems'
            handleToggle={handleFilterToggle}
          />
          <span className='filter-modal__body-label'>Goal Type</span>
          <FilterToggleRow
            label='Life Goals'
            isActionType={true}
            active={toggle.showLifeActions}
            name='showLifeActions'
            handleToggle={handleFilterToggle}
          />
          <FilterToggleRow
            label='Financial Goals'
            isActionType={true}
            active={toggle.showFinancialGoals}
            name='showFinancialGoals'
            handleToggle={handleFilterToggle}
          />
        </div>
        <div>
          <span className='filter-modal__body-label'>Sort By</span>
          {renderRadioGroup()}
        </div>
        <div className='filter-modal__body-buttons'>
          <Button clear={true} onClick={toggleFilterModal}>
            Cancel
          </Button>
          <Button primary={true} onClick={updatePreferences}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
