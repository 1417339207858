import React, { Component } from 'react'

import CommitmentIcon from '../../../assets/images/icons/png/hc-commitment-white.png'
import ProtectionIcon from '../../../assets/images/icons/png/hc-protection-white.png'
import HappinessIcon from '../../../assets/images/icons/png/hc-happiness-white.png'
import HonestConversationCardSelectModal from './hcCardSelectModal'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../objects/HonestConversations/card'
import { CardRankingObj } from '../../../../objects/honestConversations'

interface HonestConversationEditCardProps {
  displayIndex: number
  index: number
  householdFinId: string
  card: CardObj
  selectedCards: CardRankingObj[]
  cardOptions?: CardOptionsInterface
  onCardSelection(card: CardObj): void
}

interface HonestConversationEditCardState {
  showCardSelectModal: boolean
}

class HonestConversationEditCard extends Component<
  HonestConversationEditCardProps,
  HonestConversationEditCardState
> {
  constructor(props: HonestConversationEditCardProps) {
    super(props)
    this.state = {
      showCardSelectModal: false
    }
  }
  public cardAttributes = () => {
    switch (this.props.card.category) {
      case 'Commitment':
        return {
          icon: CommitmentIcon,
          color: '#0068AE'
        }
      case 'Happiness':
        return {
          icon: HappinessIcon,
          color: '#F49500'
        }
      case 'Protection':
        return {
          icon: ProtectionIcon,
          color: '#A7D500'
        }
      default:
        return {
          icon: null,
          color: '#F4F4F4'
        }
    }
  }

  public card = () => {
    const { title } = this.props.card
    const { icon, color } = this.cardAttributes()
    return (
      <div className={'hc__card'} style={{ backgroundColor: color }}>
        <div className='hc__card-title'>
          {title}
          {icon ? (
            <span className='hc__card-icon'>
              <img src={icon} alt='' role='presentation' />
            </span>
          ) : null}
        </div>
      </div>
    )
  }
  public nullCard = () => {
    const { displayIndex } = this.props
    return (
      <div className='hc-new-meeting__card'>
        <div className='hc-new-meeting__card-index'>{displayIndex}</div>
        <div className='hc-new-meeting__card-select-text'>Select a Card</div>
      </div>
    )
  }
  public openCardSelectModal = () => {
    this.setState({ showCardSelectModal: true })
  }
  public closeCardSelectModal = () => {
    this.setState({ showCardSelectModal: false })
  }
  public updateCard = (card: CardObj) => {
    const { onCardSelection } = this.props
    onCardSelection(card)
  }
  public render() {
    const { showCardSelectModal } = this.state
    const { card, selectedCards, householdFinId } = this.props
    return (
      <div>
        <div
          className='hc-new-meeting__card-w'
          onClick={this.openCardSelectModal}>
          {card && card && card.category && card.title
            ? this.card()
            : this.nullCard()}
        </div>
        {showCardSelectModal ? (
          <HonestConversationCardSelectModal
            householdFinId={householdFinId}
            selectCard={this.updateCard}
            selectedCards={selectedCards.filter((selectedCard) => selectedCard)}
            cardOptions={this.props.cardOptions}
            closeModal={this.closeCardSelectModal}
          />
        ) : null}
      </div>
    )
  }
}

export default HonestConversationEditCard
