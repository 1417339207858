import React, { Fragment } from 'react'
import FinancialCharts from '../../components/graphs/financialCharts'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import {
  ClientAccountObj,
  TotalAllocatedCalculated
} from '../../../objects/clientAccount'
import { dollarFormat } from '../../helpers'
import { calculateAssetAllocation } from '../../helpers/netWorth'

const chartData = [
  {
    name: 'equity',
    value: 0,
    color: '#255EBA'
  },
  {
    name: 'fixed',
    value: 0,
    color: '#FFBE1A'
  },
  {
    name: 'cash',
    value: 0,
    color: '#FFDE8C'
  },
  {
    name: 'alt',
    value: 0,
    color: '#A2D134'
  },
  {
    name: 'unclassified',
    value: 0,
    color: '#8A8888'
  }
]

const chartOptions = {
  animateRotate: true,
  showTooltips: false,
  responsive: false,
  cutoutPercentage: 70,
  segmentShowStroke: true,
  tooltips: {
    enabled: false
  }
}

interface AssetAllocationProps {
  householdFinId: string
  clientAccountId: string
  clientAccount: ClientAccountObj
  dispatch: Dispatch<GlobalState>
  totalAllocatedCalculated: TotalAllocatedCalculated
}

interface AssetAllocationState {
  discreteAllocation: {
    equity: number
    fixed: number
    cash: number
    other: number
  }
  allocations: {
    stocks: number
    bonds: number
    other: number
  }
  totalAllocatedCalculated: TotalAllocatedCalculated
}

const discreteAllocation = { equity: 0, fixed: 0, cash: 0, other: 0 }
const allocations = { stocks: 0, bonds: 0, other: 0 }

class AssetAllocation extends React.Component<
  AssetAllocationProps,
  AssetAllocationState
> {
  constructor(props: AssetAllocationProps) {
    super(props)
    this.state = {
      allocations,
      discreteAllocation,
      totalAllocatedCalculated: props.totalAllocatedCalculated
    }
  }

  public doughnutHeaderLabel = (allocationCalculated: any) => {
    if (allocationCalculated !== null) {
      return Object.keys(allocationCalculated)
        .map((key) => {
          const allocation = allocationCalculated[key]
          chartData.forEach((allocationType) =>
            allocationType.name === key
              ? (allocationType.value = allocation.percent)
              : null
          )
          return Math.floor(allocation.percent)
        })
        .join('/')
    } else {
      return '0/0/0/0/100'
    }
  }

  public doughnutData = (): { label: string; data: any } => {
    const { clientAccount } = this.props
    const totalAllocatedCalculated = clientAccount
      ? calculateAssetAllocation(clientAccount, true)
      : null
    const doughnutLabel = this.doughnutHeaderLabel(totalAllocatedCalculated)
    const dataProp = {
      datasets: [
        {
          data: chartData.map((item) => {
            return item.value
          }),
          backgroundColor: chartData.map((item) => {
            return item.color
          }),
          borderColor: chartData.map((item) => {
            return '#FFFFFF'
          }),
          borderWidth: 2
        }
      ]
    }

    return { label: doughnutLabel, data: dataProp }
  }

  public doughnutRender = () => {
    const doughnutData = this.doughnutData()

    return (
      <Fragment>
        <div className='client-account-details__graph-doughnut'>
          <div className='client-account-details__graph-doughnut-data'>
            {doughnutData.label}
          </div>
          <FinancialCharts
            chartData={doughnutData.data}
            chartOptions={chartOptions}
            dimensions={{ width: 100, height: 100 }}
          />

          <div className='client-account-details__graph-doughnut-text'>
            CURRENT <br />
            PORTFOLIO
          </div>
        </div>
      </Fragment>
    )
  }

  public barRender = () => {
    const { clientAccount } = this.props
    const totalAllocatedCalculated = clientAccount
      ? calculateAssetAllocation(clientAccount, true)
      : null
    const allocationValues = (allocationName: string) => {
      if (totalAllocatedCalculated) {
        const allocation = totalAllocatedCalculated[allocationName]
        return { percent: allocation.percent, allocated: allocation.amount }
      } else return { percent: 0, allocated: 0 }
    }
    const equity = allocationValues('equity')
    const fixed = allocationValues('fixed')
    const unclassified = allocationValues('unclassified')
    const alt = allocationValues('alt')
    const cash = allocationValues('cash')
    return (
      <div className='client-account-details__graph-bar-x'>
        <div className='client-account-details__graph-bar--label'>
          <label>Asset Type</label>
          <label>Current Allocation</label>
          <label>Amount</label>
        </div>
        <div className='client-account-details__graph-bar--data'>
          <span>Equity</span>
          <div className='client-account-details__graph-bar'>
            <div
              style={{ width: equity.percent + '%' }}
              className='client-account-details__graph-bar--equity'
            />
            <span>{equity.percent}%</span>
          </div>
          <span>{dollarFormat(equity.allocated, 0)}</span>
        </div>
        <div className='client-account-details__graph-bar--data'>
          <span>Fixed Income</span>
          <div className='client-account-details__graph-bar'>
            <div
              style={{ width: fixed.percent + '%' }}
              className='client-account-details__graph-bar--fix'
            />
            <span>{fixed.percent}%</span>
          </div>
          <span>{dollarFormat(fixed.allocated, 0)}</span>
        </div>
        <div className='client-account-details__graph-bar--data'>
          <span>Cash & Equivalents</span>
          <div className='client-account-details__graph-bar'>
            <div
              style={{ width: cash.percent + '%' }}
              className='client-account-details__graph-bar--cash'
            />
            <span>{cash.percent}%</span>
          </div>
          <span>{dollarFormat(cash.allocated, 0)}</span>
        </div>
        <div className='client-account-details__graph-bar--data'>
          <span>Alternatives</span>
          <div className='client-account-details__graph-bar'>
            <div
              style={{ width: alt.percent + '%' }}
              className='client-account-details__graph-bar--alt'
            />
            <span>{alt.percent}%</span>
          </div>
          <span>{dollarFormat(alt.allocated, 0)}</span>
        </div>
        <div className='client-account-details__graph-bar--data'>
          <span>Unclassified</span>
          <div className='client-account-details__graph-bar'>
            <div
              style={{ width: unclassified.percent + '%' }}
              className='client-account-details__graph-bar--unclass'
            />
            <span>{unclassified.percent}%</span>
          </div>
          <span>{dollarFormat(unclassified.allocated, 0)}</span>
        </div>
      </div>
    )
  }

  public render() {
    return (
      <div className='client-account-details__graph-bar-w'>
        <div className='client-account-details__graph-w'>
          {this.doughnutRender()}
          {this.barRender()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, clientAccountId }: any
) => {
  return {
    householdFinId,
    clientAccountId
  }
}

export default connect(mapStateToProps)(AssetAllocation)
