import React, { Component } from 'react'
import FinancialCharts from '../../../../components/graphs/financialCharts'
import { dotOvalList } from '../../dotOval'

interface SectorWeightingsGraphProps {
  chartOptions: object
  graphColors: string[]
  sectorWeightings: any
}

class SectorWeightingsGraph extends Component<SectorWeightingsGraphProps> {
  public getValueList = (keyType: string): any[] => {
    const { graphColors, sectorWeightings } = this.props

    return Object.keys(sectorWeightings.graphData)
      .sort()
      .map((valueItem: any, idx: number) => {
        return keyType === 'percent'
          ? parseFloat(sectorWeightings.graphData[valueItem])
          : graphColors[idx]
      })
  }

  public render() {
    const { graphColors, sectorWeightings, chartOptions } = this.props
    const graphValues = this.getValueList('percent')
    const graphValuesColors = this.getValueList('colors')

    if (graphValues.length === 0) {
      graphValues.push(100) && graphValuesColors.push('grey')
    } else {
      graphValues.reduce((sum, item) => {
        return sum + item
      })
    }

    const columnOne = dotOvalList(graphColors, sectorWeightings.graphData)
    const columnTwo =
      columnOne.length > 8 ? columnOne.splice(8, columnOne.length) : null

    return (
      <div className='proposed-portfolio__graph-wrapper'>
        <div className='proposed-portfolio__graph'>
          <div className='proposed-portfolio__graph-relative'>
            <FinancialCharts
              key={'sector-weightings-graph'}
              canvasId='sector-weightings-graph'
              chartData={{
                datasets: [
                  {
                    data: graphValues,
                    backgroundColor: graphValuesColors
                  }
                ]
              }}
              chartOptions={chartOptions}
              dimensions={{ height: 110, width: 110 }}
            />
            <div className='proposed-portfolio__graph-target' />
          </div>
          <div className='proposed-portfolio__graph-data-point'>
            <div className='proposed-portfolio__graph-data-point-flex'>
              <div className='proposed-portfolio__graph-data-point-flex__column'>
                {columnOne.splice(0, 8)}
              </div>
              <div>{columnTwo}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SectorWeightingsGraph
