import React, { Component, FormEvent } from 'react'
import ToggleSwitch from '../../components/toggleSwitch'

interface FilterToggleRowProps {
  label: string
  name: string
  active: boolean
  disable: boolean
  handleToggle(taskType: string): void
}

class FilterToggleRow extends Component<FilterToggleRowProps> {
  public handleToggle = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { name } = event.currentTarget
    const { handleToggle } = this.props
    handleToggle(name)
  }

  public render() {
    const { label, active, name, disable } = this.props
    return (
      <div className='task-filter__toggle-row task-filter__toggle-row__w'>
        <div className='task-filter__toggle-row task-filter__toggle-row__row-action-type'>
          {label}
        </div>
        <ToggleSwitch
          active={active}
          handleToggleActive={this.handleToggle}
          name={name}
          disable={disable}
        />
      </div>
    )
  }
}

export default FilterToggleRow
