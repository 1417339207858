import React from 'react'
import { getCardIcon } from '../../helpers'
import ReactTooltip from 'react-tooltip'
import { CardType } from '@unitedcapitalfinancialadvisors/finlife-component-library'

const HonestConversationSmallCard = ({
  cardType,
  cardTitle,
  withTooltip
}: {
  cardType: CardType | string
  cardTitle: string
  withTooltip: boolean
}) => {
  if (cardType) {
    return (
      <div data-tip={cardTitle}>
        <div
          className={
            'hc-history__hc-icon hc-history__hc-icon--' + cardType.toLowerCase()
          }>
          {withTooltip ? (
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          ) : null}
          <img src={getCardIcon(cardType)} role='presentation' alt='' />
        </div>
      </div>
    )
  } else return null
}

export default HonestConversationSmallCard
