import { TimedLoaderInterface } from '../../objects/timedLoader'
import { GlobalState } from '../../reducers'

import { createSelectorCreator, defaultMemoize } from 'reselect'
import { isEqual } from '../../v3/helpers'
// changing default select memoization '===' with check for undefined, null and 'isEqual' to work with objects
const extendedMemoize = (value1: any, value2: any) => {
  if (typeof value1 === 'undefined' && typeof value2 === 'undefined') {
    return true
  }
  if (value1 === null && value2 === null) {
    return true
  }
  return isEqual(value1, value2)
}

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  extendedMemoize
)

const getLoaders = (store: GlobalState): TimedLoaderInterface[] => {
  return store?.timedLoader?.loaders
}

export const goalLoadersSelector = createDeepEqualSelector(
  [getLoaders],
  (loaders): TimedLoaderInterface[] => {
    return loaders
  }
)
