import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  GetClientNotificationSettingsQuery,
  SetClientNotificationSettingsQuery
} from './settingsQuery'

// GQL mutatations for client settings
export const SetClientNotifications = async (
  GQLClient: ApolloClient<NormalizedCacheObject>,
  queryVariables: {
    clientId: string
    desktopSettings: string[]
    emailSettings: string[]
    mobileSettings: string[]
  }
) => {
  const {
    clientId,
    desktopSettings,
    emailSettings,
    mobileSettings
  } = queryVariables
  if (GQLClient && clientId) {
    return GQLClient.mutate({
      mutation: SetClientNotificationSettingsQuery,
      variables: { clientId, desktopSettings, emailSettings, mobileSettings },
      refetchQueries: [
        {
          query: GetClientNotificationSettingsQuery,
          variables: { clientId }
        }
      ]
    })
  } else return null
}
