import React from 'react'
import * as userActions from '../../../../actions/user'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'

interface JunxureReceiverProps {
  dispatch: Dispatch<GlobalState>
  userId: string
  openSyncModal(): void
}

class JunxureReceiver extends React.Component<JunxureReceiverProps> {
  public async componentDidMount() {
    const { dispatch, userId } = this.props
    dispatch(userActions.saveJunxureSyncToken(userId))
    this.props.openSyncModal()
  }
  public render() {
    return <div />
  }
}

export default connect()(JunxureReceiver)
