import React, { Component, Fragment } from 'react'
import ScoreSelector from './scoreSelector'
import ScoreComment from './scoreComment'
import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import Button from '../../components/button'
import SharedPriorityActions from './lifeActions'
import { ContactsInterface } from '../../../objects/contact'
import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'
import { HouseholdObj } from '../../../objects/household'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import * as hcExerciseActions from '../../../actions/hcExercises'
import {
  sortHouseholdCards,
  mapScoreProperties
} from '../../helpers/honestConversations'
import { isEqual } from '../../helpers/index'
import { filterGoals } from '../../helpers/goals'

import {
  HonestConversationsExerciseObj,
  HouseholdCardObj,
  ScoringSessionsObj
} from '../../../objects/honestConversations'
import { CardObj } from '../../../objects/HonestConversations/card'
import AddActionModal from './lifeActions/addActionModal'
import { history } from '../../../store'
import { ClipLoader } from 'react-spinners'

export interface RecordFormProps {
  previousActiveScoringSessionIdPrimary?: string
  previousActiveScoringSessionIdSecondary?: string
  scoringSessionId?: string
  scoringSession?: ScoringSessionsObj
  isPrimary?: boolean
  edit?: boolean
  dispatch: Dispatch<GlobalState>
  household: HouseholdObj
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  goals: FinancialGoalActionObj[]
}

interface ScoreStateObj {
  householdCardId: string
  primaryScore: number
  secondaryScore: number
  primaryComment: string
  secondaryComment: string
  card: CardObj
  isChanged: boolean
}

interface RecordFormState {
  isSaving: boolean
  isCanceling: boolean
  primaryScoreSessionId: string
  secondaryScoreSessionId: string
  scores: ScoreStateObj[]
  currenthouseholdCardId: string
  currenthouseholdCardIndex: number
  showAddActionModal: boolean
  actionList: FinancialGoalActionObj[][]
  isAllCommentBoxDisabled: boolean
}

export class RecordForm extends Component<RecordFormProps, RecordFormState> {
  constructor(props: RecordFormProps) {
    super(props)
    this.state = {
      isSaving: false,
      isCanceling: false,
      primaryScoreSessionId: null,
      secondaryScoreSessionId: null,
      currenthouseholdCardId: null,
      currenthouseholdCardIndex: null,
      showAddActionModal: false,
      actionList: null,
      scores: [],
      isAllCommentBoxDisabled: false
    }
  }

  public componentDidMount() {
    const { honestConversation, goals } = this.props
    if (honestConversation && honestConversation.householdCards) {
      const sortedHouseholdCards = sortHouseholdCards(
        honestConversation.householdCards
      )
      this.mapExistingHouseholdCards(sortedHouseholdCards)
      if (goals) {
        this.mapExistingLifeActions(sortedHouseholdCards)
      }
    }
  }

  public componentDidUpdate(prevProps: any) {
    const { goals, honestConversation } = this.props
    if (goals && honestConversation && !isEqual(goals, prevProps.goals)) {
      const sortedHouseholdCards = sortHouseholdCards(
        honestConversation.householdCards
      )
      this.mapExistingLifeActions(sortedHouseholdCards)
    }
  }

  public mapExistingHouseholdCards = (householdCards: HouseholdCardObj[]) => {
    let newScores: ScoreStateObj[] = []
    householdCards.forEach((card: HouseholdCardObj) => {
      newScores.push({
        householdCardId: card.id,
        card: card.card,
        primaryComment: '',
        secondaryComment: '',
        primaryScore: null,
        secondaryScore: null,
        isChanged: false
      })
    })
    if (this.props.edit) {
      newScores = this.mapExistingScores(newScores)
    }
    this.setState({ scores: newScores })
  }

  public mapExistingLifeActions = (householdCards: HouseholdCardObj[]) => {
    const { goals } = this.props
    const actionList: FinancialGoalActionObj[][] = []
    householdCards.forEach((card: HouseholdCardObj) => {
      const listOfActions = filterGoals(goals, card?.card?.id)
      actionList.push(listOfActions)
    })
    this.setState({ actionList })
  }

  public mapExistingScores = (newScores: ScoreStateObj[]) => {
    const { isPrimary, scoringSession } = this.props
    const scoreCopy = [...newScores]
    const contactType = isPrimary ? 'primary' : 'secondary'
    mapScoreProperties(scoreCopy, scoringSession, contactType)
    return scoreCopy
  }

  public updateSavingState = () => {
    const { isSaving } = this.state
    !isSaving && this.setState({ isSaving: true })
  }

  public save = async () => {
    const { dispatch, edit, householdFinId, honestConversation } = this.props

    // To handle the unmount state of the component and disable cancelling the scoring session if score addded to any card
    this.updateSavingState()

    // Edit score API - Single Contact
    if (edit) {
      await dispatch(
        hcExerciseActions.getHonestConversationExercises(householdFinId)
      )
      history.push(
        `/households/${householdFinId}/honestConversations/meetings/${honestConversation.id}/scores`
      )
      // Add Score API - Single and Couple
    } else {
      await dispatch(
        hcExerciseActions.getHonestConversationExercises(householdFinId)
      )
      history.push(`/households/${householdFinId}/honestConversations`)
    }
  }

  public createCardScoreAndUpdateScoringSession = async (
    contactId: string,
    scoringSessionId?: string,
    cardIndex?: number
  ) => {
    const { scores } = this.state

    const {
      contacts,
      householdFinId,
      honestConversation,
      dispatch
    } = this.props
    const scoreSessionId =
      scoringSessionId ||
      (contactId === contacts.primary.id
        ? honestConversation.activeScoringSessionPrimary
        : honestConversation.activeScoringSessionSecondary)

    contactId === contacts.primary.id
      ? this.setState({
          primaryScoreSessionId: scoreSessionId
        })
      : this.setState({
          secondaryScoreSessionId: scoreSessionId
        })

    const scoreCardArray: any = []
    scores.forEach((card: any) => {
      scoreCardArray.push({
        householdCardId: card.householdCardId,
        score:
          contactId === contacts.primary.id
            ? card.primaryScore
            : card.secondaryScore,
        comment:
          contactId === contacts.primary.id
            ? card.primaryComment
            : card.secondaryComment
      })
    })

    // To handle the unmount state of the component and disable cancelling the scoring session if score addded to any card
    this.updateSavingState()

    await dispatch(
      hcExerciseActions.createCardScoreAndUpdateScoringSession(
        contactId,
        householdFinId,
        honestConversation.id,
        scoreSessionId,
        scoreCardArray
      )
    )
    let newState = {}
    if (cardIndex + 1) {
      scores[cardIndex].isChanged = false
      newState = {
        ...newState,
        ...{ scores }
      }
    }
    Object?.keys(newState)?.length && this.setState(newState)
  }

  public updateActiveScoreSessionId = async () => {
    const {
      honestConversation,
      household,
      dispatch,
      previousActiveScoringSessionIdPrimary,
      previousActiveScoringSessionIdSecondary
    } = this.props
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    if (previousActiveScoringSessionIdPrimary) {
      updatedProperty.activeScoringSessionPrimary = previousActiveScoringSessionIdPrimary
    }
    if (previousActiveScoringSessionIdSecondary) {
      updatedProperty.activeScoringSessionSecondary = previousActiveScoringSessionIdSecondary
    }
    await dispatch(
      hcExerciseActions.updateHonestConversationExercise(
        household.id,
        honestConversation.id,
        updatedProperty
      )
    )
  }

  public openAddActionModal = (
    currenthouseholdCardId: string,
    cardIndex: number
  ) => (e: any) => {
    this.setState({
      showAddActionModal: true,
      currenthouseholdCardId,
      currenthouseholdCardIndex: cardIndex
    })
  }

  public closeAddActionModal = () => {
    this.setState({
      showAddActionModal: false,
      currenthouseholdCardId: null,
      currenthouseholdCardIndex: null
    })
  }

  public cancel = async (goBack: boolean = true) => {
    const {
      household,
      previousActiveScoringSessionIdPrimary,
      previousActiveScoringSessionIdSecondary,
      dispatch,
      honestConversation,
      contacts
    } = this.props
    this.setState({ isCanceling: true })
    if (
      previousActiveScoringSessionIdPrimary ||
      previousActiveScoringSessionIdSecondary
    ) {
      if (previousActiveScoringSessionIdPrimary) {
        dispatch(
          hcExerciseActions.deleteScoringSession(
            household.id,
            honestConversation.id,
            contacts.primary.id,
            honestConversation.activeScoringSessionPrimary
          )
        )
      }
      if (previousActiveScoringSessionIdSecondary) {
        await dispatch(
          hcExerciseActions.deleteScoringSession(
            household.id,
            honestConversation.id,
            contacts.secondary.id,
            honestConversation.activeScoringSessionSecondary
          )
        )
      }
      await this.updateActiveScoreSessionId()
      await dispatch(
        hcExerciseActions.getHonestConversationExercises(household.id)
      )
    }
    if (goBack) {
      history.goBack()
    }
  }

  public onScoreChange = (score: number, isPrimary: boolean, index: any) => {
    const { contacts } = this.props
    const newScores = [...this.state.scores]
    const contactId = isPrimary ? contacts.primary.id : contacts.secondary.id
    if (isPrimary) {
      newScores[index].primaryScore = score
    } else {
      newScores[index].secondaryScore = score
    }

    this.setState({ scores: newScores }, () => {
      this.createCardScoreAndUpdateScoringSession(contactId)
    })
  }

  public onCommentChange = (
    comment: string,
    isPrimary: boolean,
    index: number
  ) => {
    const newScores = JSON.parse(JSON.stringify(this.state.scores))
    if (isPrimary) {
      newScores[index].primaryComment = comment
    } else {
      newScores[index].secondaryComment = comment
    }
    newScores[index].isChanged = true
    this.setState({ scores: newScores })
  }

  public setAllComponentBoxDisabled = (commentBoxState: boolean) => {
    this.setState({ isAllCommentBoxDisabled: commentBoxState })
  }

  public renderScoreSelectorAndComment = (
    isPrimary: boolean,
    cardIndex: number,
    scores: ScoreStateObj
  ) => {
    const { isAllCommentBoxDisabled, scores: newScores } = this.state
    const {
      contacts,
      isPrimary: isPrimaryContact,
      edit,
      scoringSessionId
    } = this.props
    return (
      <div
        className={
          contacts?.secondary && !edit
            ? 'record-form__score'
            : 'record-form__score--single-contact'
        }>
        <ScoreSelector
          onScoreChange={this.onScoreChange}
          isPrimary={isPrimary}
          cardIndex={cardIndex}
          score={isPrimary ? scores?.primaryScore : scores?.secondaryScore}
          singleView={edit || !contacts.secondary}
        />
        {edit ? (
          <ScoreComment
            onCommentChange={this.onCommentChange}
            isCommentChange={newScores?.[cardIndex]?.isChanged}
            showCompactView={true}
            onAddUpdateSession={(contactId, scoringSessionId) =>
              this.createCardScoreAndUpdateScoringSession(
                contactId,
                scoringSessionId,
                cardIndex
              )
            }
            isPrimary={isPrimary}
            cardIndex={cardIndex}
            params={{ contacts, isPrimaryContact, edit, scoringSessionId }}
            isAllCommentBoxDisabled={isAllCommentBoxDisabled}
            setAllComponentBoxDisabled={this.setAllComponentBoxDisabled}
            value={
              isPrimary ? scores?.primaryComment : scores?.secondaryComment
            }
          />
        ) : null}
      </div>
    )
  }

  public renderSaveCancelButton = () => {
    const { edit = false } = this.props
    const { isCanceling } = this.state
    const disableButtons = isCanceling
    return (
      <div className='record-form__button-w'>
        {!edit ? (
          <Button
            clear={true}
            onClick={disableButtons ? null : this.cancel}
            style={{
              minWidth: '130px',
              height: '32px',
              pointerEvents: disableButtons ? 'none' : 'auto'
            }}>
            {!isCanceling ? (
              'Cancel'
            ) : (
              <Fragment>
                <ClipLoader size={20} color={'#fff'} loading={true} />
                <span style={{ marginRight: '10px' }} />
                Canceling...
              </Fragment>
            )}
          </Button>
        ) : null}
        <Button
          primary={true}
          onClick={disableButtons ? null : this.save}
          style={{
            minWidth: '130px',
            height: '32px',
            pointerEvents: disableButtons ? 'none' : 'auto',
            background: disableButtons ? 'grey' : '#4a78cf'
          }}>
          Save
        </Button>
      </div>
    )
  }

  public renderHeader = () => {
    const { contacts, edit, isPrimary } = this.props
    return (
      <div className='record-form__name-w'>
        {!edit && contacts.secondary && (
          <p className='record-form__name'>{contacts?.secondary?.firstName}</p>
        )}
        {contacts && isPrimary === undefined && (
          <p className='record-form__name'>{contacts?.primary?.firstName}</p>
        )}
      </div>
    )
  }

  public renderForm = (
    score: ScoreStateObj,
    key: number,
    actions: FinancialGoalActionObj[]
  ) => {
    const { contacts, edit, isPrimary } = this.props
    const { showAddActionModal, currenthouseholdCardId } = this.state
    return (
      <Fragment>
        <div className='record-form__score-action-col'>
          <div className='record-form__score-w'>
            {!edit &&
              contacts.secondary &&
              this.renderScoreSelectorAndComment(false, key, score)}
            {isPrimary === undefined
              ? this.renderScoreSelectorAndComment(true, key, score)
              : this.renderScoreSelectorAndComment(isPrimary, key, score)}
          </div>
          {
            <SharedPriorityActions
              selectedCardId={score?.card?.id}
              selectedCard={score}
              actionBtnPosition='bottom'
              contacts={contacts}
              showNoDataView={false}
              enableToggleGoal={true}
              componentClass='block'
            />
          }
        </div>
        {showAddActionModal && score.card.id === currenthouseholdCardId && (
          <AddActionModal
            cardIndex={key}
            currentCardId={score.card.id}
            contacts={contacts}
            closeModal={this.closeAddActionModal}
          />
        )}
      </Fragment>
    )
  }

  public render() {
    const { scores, actionList } = this.state
    const hasHouseHoldCards = scores.every(
      (score: ScoreStateObj) => score.householdCardId
    )
    if (hasHouseHoldCards) {
      return (
        <div className='record-form'>
          <div className='record-form__title-w'>
            <p className='record-form__title'> Shared priorities</p>
            {this.renderHeader()}
          </div>
          <div className='record-form__inner-w'>
            {scores.map((score: ScoreStateObj, key: number) => {
              const actions =
                actionList && actionList[key] ? actionList[key] : null
              return (
                <div key={key} className='record-form__details-w'>
                  <div className='record-form__card-col'>
                    <Card
                      category={score.card.category}
                      title={score.card.title}
                    />
                  </div>
                  {this.renderForm(score, key, actions)}
                </div>
              )
            })}
            {this.renderSaveCancelButton()}
          </div>
        </div>
      )
    } else return null
  }

  componentWillUnmount = () => {
    const { isSaving, isCanceling } = this.state
    if (!isSaving && !isCanceling) {
      this.cancel(false)
    }
  }
}

export default connect()(RecordForm)
