import React from 'react'

interface TableHeaderLabelProps {
  title: string
  resetFlag: boolean
  toggle(sortByDescending: boolean): void
}

interface TableHeaderLabelState {
  arrowDown: boolean
}

class TableHeaderLabel extends React.Component<
  TableHeaderLabelProps,
  TableHeaderLabelState
> {
  constructor(props: TableHeaderLabelProps) {
    super(props)
    this.state = {
      arrowDown: true
    }
  }
  public componentWillReceiveProps(nextProps: TableHeaderLabelProps) {
    if (this.props.resetFlag !== nextProps.resetFlag) {
      this.setState({ arrowDown: true })
    }
  }
  public toggle = async () => {
    const { arrowDown } = this.state
    await this.props.toggle(arrowDown)
    this.setState({ arrowDown: !arrowDown })
  }
  public render() {
    const { title } = this.props
    const { arrowDown } = this.state
    return (
      <div className='holdings__table-header-label' onClick={this.toggle}>
        {title}
        {arrowDown ? (
          <span className='holdings__table-header-arrow--down' />
        ) : (
          <span className='holdings__table-header-arrow--up' />
        )}
      </div>
    )
  }
}

export default TableHeaderLabel
