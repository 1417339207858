import React, { FC, ChangeEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { updateSearchBarText } from '../../../../actions/strategySearch'
import searchIcon from '../../../assets/images/icons/search_dark-gray.png'
import xIcon from '../../../assets/images/icons/x_gray.png'

interface StrategySearchProps {
  SearchBarValue: string
  dispatch: Dispatch<GlobalState>
}

const StrategySearch: FC<StrategySearchProps> = ({
  SearchBarValue,
  dispatch
}) => {
  const strategySearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length <= 100) {
      dispatch(updateSearchBarText(value))
    }
  }
  return (
    <div className='strategy-search__input-w'>
      <img
        className='strategy-search__input-icon'
        src={searchIcon}
        alt='input search icon'
      />
      <input
        className='strategy-search__input'
        type='text'
        value={SearchBarValue}
        onChange={strategySearchChange}
        placeholder='Search by Strategy Name'
      />
      <img
        className={
          SearchBarValue?.length > 0
            ? `strategy-search__input-clear strategy-search__input-clear--show`
            : 'strategy-search__input-clear'
        }
        onClick={() => dispatch(updateSearchBarText(''))}
        src={xIcon}
        alt='close icon'
      />
    </div>
  )
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    SearchBarValue: store.strategySearch.search
  }
}

export default connect(mapStateToProps)(StrategySearch)
