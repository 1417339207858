import {
  CHANGE_CLIENT_VALUE,
  CHANGE_PROPOSED_VALUE,
  CREATE_MAP_VALUE,
  ADD_PROPOSED_VALUE,
  REMOVE_PROPOSED_VALUE
} from '../actions/accountMap'
export interface AccountMapObj {
  clientValue: number
  proposeValue: { id: number; value: number }[]
}

export interface AccountMapState {
  [key: string]: AccountMapObj
}

// interface ActionType {
//   type: string;
//   houseHoldId: string;
//   clientValue: number;
//   proposeValue: { id: number; value: number };
// }

const initState: AccountMapState = {}

const accountMap = (state = initState, action: any): AccountMapState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case CREATE_MAP_VALUE:
      if (!newState[action.houseHoldId]) {
        newState[action.houseHoldId] = {
          ...newState[action.houseHoldId],
          clientValue: 0,
          proposeValue: []
        }
      }
      return newState
    case CHANGE_CLIENT_VALUE:
      newState[action.houseHoldId] = {
        ...newState[action.houseHoldId],
        clientValue: action.clientValue
      }
      return newState
    case ADD_PROPOSED_VALUE:
      const newValue = [
        ...newState[action.houseHoldId].proposeValue,
        action.proposeValue
      ]
      newState[action.houseHoldId] = {
        ...newState[action.houseHoldId],
        proposeValue: newValue
      }
      return newState
    case CHANGE_PROPOSED_VALUE:
      const editValue = newState[action.houseHoldId].proposeValue.map(
        (key: { id: number; value: number }) => {
          if (key.id === action.proposeValue.id) {
            return { ...key, value: action.proposeValue.value }
          }
          return key
        }
      )
      newState[action.houseHoldId] = {
        ...newState[action.houseHoldId],
        proposeValue: editValue
      }
      return newState
    case REMOVE_PROPOSED_VALUE:
      const removeValue = newState[action.houseHoldId].proposeValue.filter(
        (key: { id: number; value: number }) => {
          return key.id !== action.proposeValue.id
        }
      )
      newState[action.houseHoldId] = {
        ...newState[action.houseHoldId],
        proposeValue: removeValue
      }
      return newState
    default:
      return newState
  }
}

export default accountMap
