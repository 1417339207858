import React, { Component } from 'react'

import { HouseholdInstitution } from '../../../objects/household'
import { ContactObj } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'

import EmailPreviewModal from '../../components/layout/emailPreviewModal'

import HonestConversationBanner from '../../assets/images/nullStates/HonestConversationNullState.png'

export interface HcScoringEmailModalProps {
  contact: ContactObj
  user: UserObj
  institution: HouseholdInstitution
  saveModal(id: string): void
  closeModal(): void
}

class HcScoringEmailModal extends Component<HcScoringEmailModalProps> {
  public render() {
    const { institution, contact, user, saveModal, closeModal } = this.props
    const link = window._env_.REACT_APP_GUIDECENTER_URL
    return (
      <EmailPreviewModal
        user={user}
        contact={contact}
        saveModal={saveModal}
        closeModal={closeModal}
        showCopyLink={true}
        link={link}
        subject={'Your top five HonestConversations® cards'}
        whiteBackground={true}>
        <div className='hc-scoring-email-modal__email-img-container'>
          <img src={HonestConversationBanner} alt='HC' />
        </div>
        <div className='hc-scoring-email-modal__email-w'>
          <p className='name-label'>Dear {contact?.firstName},</p>
          <p>
            It has been a while since we last met and discussed your priorities.
            I have provided a link to a one page progress report that will
            remind you of your top five Honest Conversations® cards you selected
            as being of most importance to you.
          </p>
          <p>
            {'By '}
            <a target='_blank' rel='noopener noreferrer' href={link}>
              Clicking Here
            </a>
            , you will be able to reevaluate your score for that card and jot
            down notes as to why you gave it that score (progress or setbacks
            you may have had) so that we can discuss those concerns at our
            meeting. This quick survey will allow you to add any additional
            comments or concerns you would like me to be aware of prior to our
            meeting.
          </p>
          <p>
            As life is always changing, we want to be sure we are staying on
            course to meet your goals. I look forward to seeing you soon!
          </p>
          <p className='signature'>Best regards,</p>
          {user.firstName || user.lastName || user.title ? (
            <p className='hc-scoring-email-modal__email-p'>
              <span>{user.firstName + ' ' + user.lastName}</span>
              <br />
              {user.title}
            </p>
          ) : null}
          {user.street || user.city || user.state || user.zip ? (
            <p className='hc-scoring-email-modal__email-p'>
              {user.street}
              <br />
              {user.city + ', ' + user.state + ' ' + user.zip}
            </p>
          ) : null}
          {user.home || user.mobile ? (
            <p className='hc-scoring-email-modal__email-p'>
              {user.home + ' | Main'}
              <br />
              {user.mobile + ' | Other'}
            </p>
          ) : null}
          {user.email ? (
            <p className='hc-scoring-email-modal__email-p hc-scoring-email-modal__email-link'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={'mailto:' + user.email}>
                {user.email}
              </a>
            </p>
          ) : null}
          {institution?.imageUrl ? (
            <p className='hc-scoring-email-modal__email-p name-label'>
              <img
                className='hc-scoring-email-modal__email-image'
                src={institution.imageUrl}
                alt='Logo'
              />
            </p>
          ) : null}
          {institution?.emailDisclaimer ? (
            <p
              className='hc-scoring-email-modal__email-p'
              dangerouslySetInnerHTML={{ __html: institution.emailDisclaimer }}
            />
          ) : null}
        </div>
      </EmailPreviewModal>
    )
  }
}

export default HcScoringEmailModal
