import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'

import Tile from '../../components/layout/tile'
import InnerContainer from '../../components/layout/innerContainer'
import TableHeader from '../../components/layout/tableHeader'
import BackToLink from '../../components/layout/headerBackToLink'
import Button from '../../components/button'
import ConfirmActionModal from '../confirmationModal/confirmActionModal'

import { MoneyMindHistoryRow } from './historyRow'
import AddMoneyMind from './add'
import * as contactActions from '../../../actions/contacts'
import * as moneymindActions from '../../../actions/moneymind'
import { addToast } from '../../../actions/toasts'
import { HouseholdObj } from '../../../objects/household'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import {
  MoneyMindInterface,
  MoneyMindState,
  MoneyMindObj,
  DeleteMoneyMind
} from '../../../objects/moneymind'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { ReactComponent as MoneyMindIcon } from '../../assets/images/icons/moneymind.svg'

interface MoneyMindHistoryProps {
  household: HouseholdObj
  contacts: ContactsInterface
  moneyminds: MoneyMindInterface
  dispatch: Dispatch<GlobalState>
}

interface MoneyMindHistoryState {
  selectedTab: string
  addMoneyMind: boolean
  currentMoneyMind: MoneyMindObj
  showDeleteModal: boolean
  moneymindId: string
}

export class MoneyMindHistory extends Component<
  MoneyMindHistoryProps,
  MoneyMindHistoryState
> {
  constructor(props: MoneyMindHistoryProps) {
    super(props)
    this.state = {
      selectedTab: 'primary',
      addMoneyMind: false,
      currentMoneyMind: null,
      showDeleteModal: false,
      moneymindId: null
    }
  }

  public componentDidMount() {
    // Get Contact and YMM Data here
    const { id, primaryContact, secondaryContact } = this.props.household
    if (primaryContact) {
      this.props.dispatch(
        moneymindActions.getMoneyMind(primaryContact, id, true)
      )
    }
    if (secondaryContact) {
      this.props.dispatch(
        moneymindActions.getMoneyMind(secondaryContact, id, false)
      )
    }
  }

  public addMoneyMindBtn = () => {
    return (
      <Button primary={true} onClick={this.toggleAddMoneyMind}>
        <PlusIcon />
        <span className='ymm-history__btn--text'>New Record</span>
      </Button>
    )
  }

  public toggleAddMoneyMind = () => {
    this.setState({
      addMoneyMind: !this.state.addMoneyMind,
      currentMoneyMind: null
    })
  }

  public toggleEditMoneyMind = (moneymind: MoneyMindObj) => {
    this.setState({
      addMoneyMind: !this.state.addMoneyMind,
      currentMoneyMind: moneymind
    })
  }

  public toggleSetActive = async (moneymindid: string) => {
    const { selectedTab } = this.state
    const { primaryContact, secondaryContact } = this.props.household
    if (selectedTab === 'primary') {
      await this.props.dispatch(
        contactActions.setActiveMoneyMind(moneymindid, primaryContact)
      )
      this.props.dispatch(contactActions.getContact(primaryContact))
    }
    if (selectedTab === 'secondary') {
      await this.props.dispatch(
        contactActions.setActiveMoneyMind(moneymindid, secondaryContact)
      )
      this.props.dispatch(contactActions.getContact(secondaryContact))
    }
  }

  public toggleDeleteRecord = async () => {
    const { selectedTab, moneymindId } = this.state
    const { id, primaryContact, secondaryContact } = this.props.household
    if (moneymindId) {
      const deleteMoneyMindObj: DeleteMoneyMind = {
        householdFinId: id,
        moneymindId,
        contactId:
          selectedTab === 'primary' ? primaryContact : secondaryContact,
        contactKey: selectedTab
      }

      await this.props.dispatch(
        moneymindActions.deleteMoneyMind(deleteMoneyMindObj)
      )
      this.props.dispatch(
        addToast({
          icon: DeleteIcon,
          message: 'Record Deleted.',
          backgroundColor: '#D9F2B6'
        })
      )
      this.closeDeleteModal()
    }
  }

  public updateSelectedTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget
    this.setState({ selectedTab: id })
  }

  public historyResults = (contact: ContactObj, moneyminds: MoneyMindState) => {
    const activeMoneyMind =
      contact && moneyminds && contact.activeYmmId
        ? moneyminds[contact.activeYmmId]
        : null

    if (moneyminds) {
      const values = Object.keys(moneyminds).map((key) => moneyminds[key])
      const sorted = values.sort((a: MoneyMindObj, b: MoneyMindObj) =>
        a.date > b.date ? -1 : 1
      )
      return sorted.map((moneymind: MoneyMindObj, index: number) => {
        return (
          <MoneyMindHistoryRow
            activeFn={this.toggleSetActive}
            editFn={this.toggleEditMoneyMind}
            deleteFn={this.showDeleteModal}
            key={index}
            moneymind={moneymind}
            active={activeMoneyMind && moneymind.id === activeMoneyMind.id}
          />
        )
      })
    } else {
      return null
    }
  }

  public addMoneyMind = async (request: any, moneymind: MoneyMindObj) => {
    const { id, primaryContact, secondaryContact } = this.props.household
    if (primaryContact === request.clientId) {
      if (moneymind) {
        await this.props.dispatch(
          moneymindActions.editMoneyMind(
            request,
            primaryContact,
            moneymind.id,
            id,
            true
          )
        )
        this.props.dispatch(
          addToast({
            icon: MoneyMindIcon,
            message: 'Record edited.',
            backgroundColor: '#D9F2B6'
          })
        )
      } else {
        await this.props.dispatch(
          moneymindActions.addMoneyMind(request, primaryContact, id, true)
        )
        await this.props.dispatch(contactActions.getContact(primaryContact))
        this.props.dispatch(
          addToast({
            icon: MoneyMindIcon,
            message: 'Record added.',
            backgroundColor: '#D9F2B6'
          })
        )
      }
    }
    if (secondaryContact === request.clientId) {
      if (moneymind) {
        await this.props.dispatch(
          moneymindActions.editMoneyMind(
            request,
            secondaryContact,
            moneymind.id,
            id,
            false
          )
        )
        this.props.dispatch(
          addToast({
            icon: MoneyMindIcon,
            message: 'Record edited.',
            backgroundColor: '#D9F2B6'
          })
        )
      } else {
        await this.props.dispatch(
          moneymindActions.addMoneyMind(request, secondaryContact, id, false)
        )
        await this.props.dispatch(contactActions.getContact(secondaryContact))
        this.props.dispatch(
          addToast({
            icon: MoneyMindIcon,
            message: 'Record added.',
            backgroundColor: '#D9F2B6'
          })
        )
      }
    }
    this.setState({ addMoneyMind: false })
  }

  public cancelAddMoneyMind = () => {
    this.setState({ addMoneyMind: false })
  }

  public render() {
    const { household, contacts, moneyminds } = this.props
    const {
      selectedTab,
      addMoneyMind,
      currentMoneyMind,
      showDeleteModal
    } = this.state
    const primaryClass =
      selectedTab === 'primary' ? 'ymm-history__results-selected' : ''
    const secondaryClass =
      selectedTab === 'secondary' ? 'ymm-history__results-selected' : ''
    if (addMoneyMind) {
      return (
        <InnerContainer>
          <BackToLink onClick={this.toggleAddMoneyMind}>
            Back to MoneyMind&reg; History
          </BackToLink>
          <Tile>
            <AddMoneyMind
              moneymind={currentMoneyMind}
              contacts={contacts}
              saveFn={this.addMoneyMind}
              cancelFn={this.cancelAddMoneyMind}
              initialSelection={selectedTab}
            />
          </Tile>
        </InnerContainer>
      )
    } else {
      return (
        <InnerContainer>
          <div className='ymm-history__container-w'>
            <BackToLink to={`/households/${household.id}/moneyMind`}>
              Back to MoneyMind&reg;
            </BackToLink>
            {this.addMoneyMindBtn()}
          </div>
          <Tile>
            <TableHeader>
              {contacts && contacts.primary ? (
                <span
                  id='primary'
                  className={'ymm-history__results-tab ' + primaryClass}
                  onClick={this.updateSelectedTab}>
                  {contacts.primary.firstName}
                </span>
              ) : null}
              {contacts && contacts.secondary ? (
                <span
                  id='secondary'
                  className={'ymm-history__results-tab ' + secondaryClass}
                  onClick={this.updateSelectedTab}>
                  {contacts.secondary.firstName}
                </span>
              ) : null}
            </TableHeader>
            {contacts && moneyminds
              ? this.historyResults(
                  contacts[selectedTab],
                  moneyminds[selectedTab]
                )
              : null}
          </Tile>
          {showDeleteModal ? (
            <ConfirmActionModal
              title='Delete Record'
              confirmFunc={this.toggleDeleteRecord}
              closeFunc={this.closeDeleteModal}
            />
          ) : null}
        </InnerContainer>
      )
    }
  }

  private showDeleteModal = (moneymindid: string) => {
    this.setState({
      showDeleteModal: true,
      moneymindId: moneymindid
    })
  }

  private closeDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      moneymindId: null
    })
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId],
    contacts: store.contact[match.params.householdFinId],
    moneyminds: store.moneymind[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(MoneyMindHistory))
