import React, { Dispatch } from 'react'
import { salesforceReceiver } from '../../helpers/integrations'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import { history } from '../../../store'
import { SalesforceCRM } from '../../../objects/institution'

interface CRMAuthProps {
  crm: SalesforceCRM
  dispatch?: Dispatch<GlobalState>
}

class CRMAuth extends React.Component<CRMAuthProps> {
  public async componentDidMount() {
    const { crm } = this.props
    if (crm) {
      await salesforceReceiver(crm)
      const route = sessionStorage.getItem('originalRoute') || '/'
      history.push(route)
    }
  }
  public render() {
    return <div />
  }
}

const mapStateToProps = (_store: GlobalState, { match }: any) => {
  return {
    crm: match.params && match.params.crm
  }
}

export default withRouter(connect(mapStateToProps)(CRMAuth))
