import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import Button from '../../components/button'
import { FileObj } from '../../../objects/folder'
interface HardDeleteModalProps {
  file: FileObj
  hardDeleteFn(id: string): void
  closeModal(file?: FileObj): void
}

class HardDeleteModal extends React.Component<HardDeleteModalProps> {
  constructor(props: any) {
    super(props)
  }

  public hardDeleteFn = () => {
    const { file, hardDeleteFn } = this.props
    hardDeleteFn(file.id)
  }

  public closeFn = () => {
    const { file } = this.props
    this.props.closeModal(file)
  }

  public render() {
    return (
      <Modal
        icon={DeleteIcon}
        title='DELETE FILE PERMANENTLY'
        closeModal={this.closeFn}>
        <div className='vault-modal__content'>
          Deleting this file will remove it from the trash, and you will no
          longer be able to access it. Are you sure you want to delete this
          file?
          <div className='vault-modal__buttons'>
            <Button clear={true} onClick={this.closeFn}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.hardDeleteFn}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default HardDeleteModal
