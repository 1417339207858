import React from 'react'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import { onlinePortalObj, ONLINE_PORTAL_LIST } from '../../helpers'
import CheckBox from '../../components/checkbox'

interface AddPartnerModalProps {
  householdFinId: string
  onlinePortals: string[]
  editOnlinePortals(onlinePortals: any): void
  closeModal(): void
}

interface AddPartnerModalState {
  folderName: string
  onlinePortals: string[]
}

class AddPartnerModal extends React.Component<
  AddPartnerModalProps,
  AddPartnerModalState
> {
  constructor(props: AddPartnerModalProps) {
    super(props)
    this.state = {
      folderName: null,
      onlinePortals: [...this.props.onlinePortals]
    }
  }

  public toggleOnlinePortal = (onlinePortal: any) => {
    const { onlinePortals } = this.state
    const newOnlinePortals = onlinePortals || []
    const i = newOnlinePortals.indexOf(onlinePortal)
    if (i === -1) {
      newOnlinePortals.push(onlinePortal)
    } else {
      newOnlinePortals.splice(i, 1)
    }
    this.setState({ onlinePortals: newOnlinePortals })
  }

  public addOnlinePortal = () => {
    this.props.editOnlinePortals(this.state.onlinePortals)
    this.props.closeModal()
  }

  public cancelAddAccount = () => {
    this.props.closeModal()
  }

  public onlinePortalItem = () => {
    return onlinePortalObj(ONLINE_PORTAL_LIST.sort()).map(
      (onlinePortal: { name: string; img: string }, index: number) => {
        if (onlinePortal !== null) {
          return (
            <div className='partner__modal-item' key={index} role='button'>
              <CheckBox
                onChange={this.toggleOnlinePortal}
                style={{ width: 'auto' }}
                id={onlinePortal.name}
                checked={
                  this.state.onlinePortals
                    ? this.state.onlinePortals.indexOf(onlinePortal.name) !== -1
                    : false
                }
                checkedBorder={true}
              />
              <img src={onlinePortal.img} alt={onlinePortal.name} />
              <span>{onlinePortal.name}</span>
            </div>
          )
        } else {
          return null
        }
      }
    )
  }

  public render() {
    const { closeModal } = this.props
    return (
      <Modal title='EDIT PARTNER LOGIN' closeModal={closeModal}>
        <div className='partner__modal-content'>{this.onlinePortalItem()}</div>
        <div className='partner__modal-btns'>
          <Button clear={true} onClick={this.cancelAddAccount}>
            Cancel
          </Button>
          <Button primary={true} onClick={this.addOnlinePortal}>
            Save
          </Button>
        </div>
      </Modal>
    )
  }
}

export default AddPartnerModal
