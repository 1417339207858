import { valdatedMaxFloat, sanitizeCurrency } from '../v3/helpers'
const MAX_FLOAT = 100

export interface ClientBillingSummaryInput {
  validated: boolean
  value: string | number | boolean
}

export interface ClientBillingSummary {
  notes: string
  satisfaction: string
  reviewYear: string
  totalSummaryCost: string
  guidanceFee: string
  managementCost: string
  managementCostAdjustment: string
  managementCostAdjustmentEnabled: string
  includeStatisfaction: boolean
  aumBased: string
  sumOfFees: string
}

export const feeFormat = (
  inputValue: string,
  aumBased: string
): ClientBillingSummaryInput => {
  if (aumBased === 'percentage') {
    return valdatedMaxFloat(inputValue, MAX_FLOAT) // 100%
  } else {
    return { validated: true, value: sanitizeCurrency(inputValue) }
  }
}

export const sumOfFees = (
  feeArray: any[],
  adjustment: string | number,
  aumBased: string
): string => {
  let combinedCostValue: any = feeArray.reduce((total, fee) => {
    return total + fee
  })

  const calculateManagmentCost = (
    combinedFees: number,
    managementCost: any
  ): number => {
    if (isNaN(managementCost)) return null

    if (managementCost % 1 !== 0) {
      return combinedCostValue - parseFloat(managementCost)
    }
    return combinedCostValue - managementCost
  }

  combinedCostValue = calculateManagmentCost(combinedCostValue, adjustment)

  const prettyPercentage =
    combinedCostValue === MAX_FLOAT
      ? combinedCostValue
      : combinedCostValue.toFixed(2).toString()

  return aumBased === 'percentage'
    ? prettyPercentage
    : combinedCostValue.toString()
}

export const updateBillingSummary = (
  newValue: { name: string; value: any },
  summaryState: ClientBillingSummary
): ClientBillingSummary => {
  const { name, value } = newValue
  const { aumBased } = summaryState

  const feeFormatter = {
    guidanceFee: (inputValue: string): ClientBillingSummaryInput => {
      return feeFormat(value, aumBased)
    },
    managementCost: (inputValue: string): ClientBillingSummaryInput => {
      return feeFormat(value, aumBased)
    },
    managementCostAdjustment: (
      inputValue: string
    ): ClientBillingSummaryInput => {
      return feeFormat(value, aumBased)
    },
    aumBased: (inputValue: boolean): ClientBillingSummaryInput => {
      summaryState.guidanceFee = null
      summaryState.managementCost = null
      summaryState.managementCostAdjustment = null
      summaryState.managementCostAdjustmentEnabled = 'No'
      return { validated: true, value: inputValue }
    },
    managementCostAdjustmentEnabled: (inputValue: string) => {
      summaryState.managementCostAdjustment = null
      return { validated: true, value: inputValue }
    }
  }

  const billingValue: ClientBillingSummaryInput = feeFormatter[name]
    ? feeFormatter[name](value, aumBased)
    : { validated: true, value }

  let updatedSummary = {
    ...summaryState,
    [name]: billingValue.validated ? billingValue.value : summaryState[name]
  }

  updatedSummary = {
    ...updatedSummary,
    sumOfFees:
      sumOfFees(
        [
          parseFloat(updatedSummary.guidanceFee) || null,
          parseFloat(updatedSummary.managementCost) || null
        ],
        updatedSummary.managementCostAdjustment,
        updatedSummary.aumBased
      ) || null
  }

  return updatedSummary
}
