import React, { Fragment } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { history } from '../../../store'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { HouseholdObj } from '../../../objects/household'
import * as contactsActions from '../../../actions/contacts'
import { ContactObj, ContactsInterface } from '../../../objects/contact'
import { meetingModeFalse, meetingModeTrue } from '../../../actions/meetingMode'
import { updateGlobalNavigation } from '../../../actions/globalSettings'
import * as syncDisplayActions from '../../../actions/syncDisplay'
import { setYodleeSyncAttempt } from '../../../actions/clientAccounts'
import FinancialGoals from '../financialGoals'
import ClientDashboard from '../clientDashboard'
import MoneyMind from '../moneymind'
import MoneyMindHistory from '../moneymind/history'

import Goals from '../goals/index'
import MeetingMode from '../../components/meetingMode/index'
import MeetingModeModal from '../../components/meetingMode/meetingModeModal'
import Tooltip from '../../components/tooltip'
import NavigationMenu from './navigationMenu'
import Toasts from '../toasts'
import SyncSpinner from '../syncLoader'
import DocumentVault from '../documentVault'
import FolderDetails from '../documentVault/folderDetails'
import Insurance from '../insurance'
import HonestConversations from '../honestConversations'
import HonestConversationsHistory from '../honestConversations/hcHistory'
import PortfolioBuilder from '../portfolioBuilder'
import PortfolioProposal from '../portfolioBuilder/portfolioProposal'
import PortfolioProposalHistory from '../portfolioBuilder/portfolioProposal/portfolioProposalHistory'
import PortfolioAnalytics from '../im/portfolioAnalytics'

import Investment from '../investment'
import InvestmentHistory from '../investment/investmentHistory'
import NetWorth from '../netWorth'
import ClientAccountDetail from '../clientAccount'
import Positions from '../clientAccount/positions'
import ClientSettings from '../clientSettings'
import Tasks from '../tasks'
import Messages from '../messages'
import Guidebook from '../guidebook'
import GuidebookPage from '../guidebook/guidebookPage'

import ImpersonationModal from '../impersonationModal'

import HonestConversationsMeeting from '../honestConversations/meeting/hcMeeting'
import HonestConversationsScoreHistory from '../honestConversations/meeting/hcScoreHistory'
import HonestConversationsScore from '../honestConversations/meeting/hcScore'
import HonestConversationsNewScore from '../honestConversations/addNewScore'
import HonestConversationsEditScore from '../honestConversations/editScore'
import ScorePreview from '../honestConversations/scorePreview'
import HonestConversationsNewMeeting from '../honestConversations/meeting/newMeeting/hcNewMeeting'

import FinancialPlanning from '../financialGoals/financialPlanning'
import FinancialGoalsHistory from '../financialGoals/history'

import hamburgerMenuIcon from '../../assets/images/icons/general/hamburger-menu.png'
import SettingsIcon from '../../assets/images/icons/general/settings-gray.png'
import RefreshIcon from '../../assets/images/icons/png/refresh-gray.png'
import GCIcon from '../../assets/images/icons/png/gc_logo-blue.png'
import MeetingModeIcon from '../../assets/images/icons/power_off.png'
import MeetingModeActiveIcon from '../../assets/images/icons/power_off--active.png'
import { getHonestConversationExercises } from '../../../actions/hcExercises'
import Disclosure from '../../components/layout/disclosure'
import SideBarGoalView from '../../components/goals'
import SideBarTaskView from '../../components/tasks'
import ShowTimedLoader from '../../components/loader/timedLoader'
import YodleeLoader from '../yodleeLoader'
import Marketplace from '../marketplace'
import TrashedFiles from '../documentVault/trashedFiles'
import PageNotFoundRedirect from '../errors/pageNotFoundRedirect'
interface HouseholdProps {
  location: any
  match: any
  toasts: any[]
  contacts: ContactsInterface
  household: HouseholdObj
  dispatch: Dispatch<GlobalState>
  collapsed: boolean
  disableDocumentVault: boolean
  goalRequestId: string
  sideBarState: boolean
  showTimedLoader: boolean
}

interface HouseholdState {
  householdLoaded: boolean
  showImpersonationModal: boolean
  showMeetingModeModal: boolean
}

class Household extends React.Component<HouseholdProps, HouseholdState> {
  constructor(props: HouseholdProps) {
    super(props)
    this.state = {
      householdLoaded: false,
      showImpersonationModal: false,
      showMeetingModeModal: false
    }
  }

  public componentDidUpdate(nextProps: any, prevProps: any) {
    if (!prevProps.householdLoaded && nextProps.household) {
      this.setState({ householdLoaded: true })
      this.syncClientAccounts()
    }
  }

  public componentDidMount() {
    const { dispatch, contacts, household } = this.props
    const meetingMode = JSON.parse(sessionStorage.getItem('meetingmode'))
    const isMeetingMode =
      meetingMode && meetingMode.active ? meetingMode.active : null
    const householdId =
      meetingMode && meetingMode.householdId ? meetingMode.householdId : null
    if (meetingMode && isMeetingMode && householdId) {
      dispatch(meetingModeTrue(householdId))
    } else {
      dispatch(meetingModeFalse())
    }
    if (contacts.primary) {
      this.getUsername(contacts.primary)
    }
    if (contacts.secondary) {
      this.getUsername(contacts.secondary)
    }
    if (household && household.id) {
      this.props.dispatch(getHonestConversationExercises(household.id))
    }
  }

  public componentWillUnmount() {
    const { dispatch, household } = this.props
    if (household?.aggregationProvider === 'yodlee') {
      dispatch(setYodleeSyncAttempt(household?.id, false))
    }
  }

  public getUsername = (contact: ContactObj) => {
    const { household, dispatch } = this.props
    dispatch(contactsActions.getUsername(household.id, contact.id))
  }

  public collapseMenu = () => {
    this.props.dispatch(updateGlobalNavigation(Boolean(!this.props.collapsed)))
  }

  public toggleImpersonationModal = () => {
    this.setState({
      showImpersonationModal: !this.state.showImpersonationModal
    })
  }

  public clientSettingsClick = () => {
    return history.push(`/households/${this.props.household.id}/clientSettings`)
  }

  // tslint:disable-next-line:max-func-body-length
  public routeRender = () => {
    const { disableDocumentVault } = this.props
    const appRoutes = [
      {
        path: '/goals',
        component: Goals,
        exact: true
      },
      {
        path: '/financialGoals/history',
        component: FinancialGoalsHistory,
        exact: true
      },
      {
        path: '/financialGoals/requests/:goalRequestId',
        component: FinancialGoals,
        exact: true
      },
      {
        path: '/financialGoals/financialPlanning',
        component: FinancialPlanning,
        exact: true
      },
      {
        path: '/financialGoals/:goalId',
        component: FinancialGoals,
        exact: true
      },
      {
        path: '/financialGoals',
        component: FinancialGoals,
        exact: true
      },
      {
        path: '/priorityActions',
        component: Goals,
        exact: true
      },
      {
        path: '/moneyMind',
        component: MoneyMind,
        exact: true
      },
      {
        path: '/moneyMindHistory',
        component: MoneyMindHistory,
        exact: true
      },
      {
        path: '/dashboard',
        component: ClientDashboard,
        exact: true
      },
      {
        path: '/insurance',
        component: Insurance,
        exact: true
      },
      {
        path: '/honestConversations',
        component: HonestConversations,
        exact: true
      },
      {
        path: '/honestConversations/meetings',
        component: HonestConversationsHistory,
        exact: true
      },
      {
        path: '/honestConversations/meetings/new',
        component: HonestConversationsNewMeeting,
        exact: true
      },
      {
        path: '/honestConversations/meetings/:meetingId',
        component: HonestConversationsMeeting,
        exact: true
      },
      {
        path: '/honestConversations/meetings/:exerciseId/scores',
        component: HonestConversationsScoreHistory,
        exact: true
      },
      {
        path: '/honestConversations/newScore/:exerciseId',
        component: HonestConversationsNewScore,
        exact: true
      },
      {
        path: '/honestConversations/scorePreview/:scoreId',
        component: ScorePreview,
        exact: true
      },
      {
        path: '/honestConversations/editScore/:scoreId',
        component: HonestConversationsEditScore,
        exact: true
      },
      {
        path: '/honestConversations/meetings/:meetingId/scores/:scoreId',
        component: HonestConversationsScore,
        exact: true
      },
      {
        path: '/portfolioBuilder/:portfolioBuilderId',
        component: PortfolioBuilder,
        exact: true
      },
      {
        path: '/portfolioBuilder/:portfolioBuilderId/PortfolioProposal',
        component: PortfolioProposal,
        exact: true
      },
      {
        path: '/portfolioBuilder/:portfolioBuilderId/PortfolioProposal/history',
        component: PortfolioProposalHistory,
        exact: true
      },
      {
        path: '/portfolio/analytics',
        component: PortfolioAnalytics,
        exact: true
      },
      {
        path: '/netWorth',
        component: NetWorth,
        exact: true
      },
      {
        path: '/clientAccount/:clientAccountId/:componentReferrer',
        component: ClientAccountDetail,
        exact: true
      },
      {
        path: '/clientAccount/:clientAccountId/:componentReferrer/positions',
        component: Positions,
        exact: true
      },
      {
        path: '/investments',
        component: Investment,
        exact: true
      },
      {
        path: '/investmentHistory',
        component: InvestmentHistory,
        exact: true
      },
      {
        path: '/clientSettings',
        component: ClientSettings,
        exact: true
      },
      {
        path: '/tasks/:taskId?',
        component: Tasks,
        exact: true
      },
      {
        path: '/messages/:threadId',
        component: Messages,
        exact: true
      },
      {
        path: '/messages',
        component: Messages,
        exact: true
      },
      {
        path: '/guidebook',
        component: Guidebook,
        exact: true
      },
      {
        path: '/guidebookPage',
        component: GuidebookPage,
        exact: true
      },
      {
        path: '/resources',
        component: Marketplace,
        exact: true
      }
    ]
    const documentVaultRoutes = [
      {
        path: '/documentVault',
        component: DocumentVault,
        exact: true
      },
      {
        path: '/documentVault/trash',
        component: TrashedFiles,
        exact: true
      },
      {
        path: '/documentVault/:folderFinId',
        component: FolderDetails,
        exact: true
      }
    ]
    const pageNotFoundRoute = [
      {
        path: '*',
        component: PageNotFoundRedirect,
        exact: false
      }
    ]

    const finalRoutes = !disableDocumentVault
      ? [...appRoutes, ...documentVaultRoutes, ...pageNotFoundRoute]
      : [...appRoutes, ...pageNotFoundRoute]

    return finalRoutes.map((route) => {
      return (
        <Route
          key={route.path}
          path={'/households/:householdFinId' + route.path}
          component={route.component}
          exact={route.exact}
        />
      )
    })
  }

  public syncClientAccounts = () => {
    const { dispatch, household } = this.props
    if (household) {
      dispatch(syncDisplayActions.showSyncModal())
    }
  }

  public toggleMeetingModeModal = () => {
    this.setState({
      showMeetingModeModal: !this.state.showMeetingModeModal
    })
  }

  // Function passed to the confirm modal
  public confirmMeetingModeOff = () => {
    const { dispatch } = this.props
    dispatch(meetingModeFalse())
    this.toggleMeetingModeModal()
  }

  public toggleMeetingMode = () => {
    const meetingMode = JSON.parse(sessionStorage.getItem('meetingmode'))
    const isMeetingMode =
      meetingMode && meetingMode.active ? meetingMode.active : null
    const { dispatch, household } = this.props
    if (meetingMode && isMeetingMode) {
      this.setState({
        showMeetingModeModal: !this.state.showMeetingModeModal
      })
    } else {
      dispatch(meetingModeTrue(household.id))
    }
  }

  public renderHeader = () => {
    const { household } = this.props
    return (
      <header>
        <div className='household-w'>
          <nav>
            <div className='household__menu'>
              <span onClick={this.collapseMenu}>
                <img src={hamburgerMenuIcon} alt='open-close menu' />
              </span>
            </div>
            <span className='household__name'>{household.name}</span>
            <Tooltip
              message={'Client Settings'}
              width={160}
              position='bottom-left'
              multiLine>
              <span
                className='household__actions household__actions-gear'
                onClick={this.clientSettingsClick}>
                <img src={SettingsIcon} alt={SettingsIcon} />
              </span>
            </Tooltip>
          </nav>
          <nav>
            <MeetingMode>
              <Tooltip
                message='Meeting Mode: OFF'
                width={160}
                position='bottom-left'
                multiLine>
                <span
                  className='household__actions'
                  onClick={this.toggleMeetingMode}>
                  <img src={MeetingModeIcon} alt='MeetingMode Icon' />
                </span>
              </Tooltip>
            </MeetingMode>
            <MeetingMode include>
              <Tooltip
                message={'Meeting Mode: ON'}
                width={160}
                position='bottom-left'
                multiLine>
                <span
                  className='household__actions'
                  onClick={this.toggleMeetingMode}>
                  <img
                    src={MeetingModeActiveIcon}
                    alt='MeetingMode Icon Active'
                  />
                </span>
              </Tooltip>
            </MeetingMode>
            <Tooltip
              message={'Sync'}
              width={160}
              position='bottom-left'
              multiLine>
              <span
                className='household__actions'
                onClick={this.syncClientAccounts}>
                <img src={RefreshIcon} alt={RefreshIcon} />
              </span>
            </Tooltip>
            <Tooltip
              message={'Guidecenter View'}
              width={160}
              position='bottom-left'
              multiLine>
              <span
                className='household__actions'
                onClick={this.toggleImpersonationModal}>
                <img src={GCIcon} role='presentation' alt='' />
              </span>
            </Tooltip>
          </nav>
        </div>
      </header>
    )
  }

  public bodyModfierCls = () => {
    const { sideBarState } = this.props
    const cls: string[] = []
    if (sideBarState) {
      cls.push('household__body-content-w--spacer')
    }
    return cls?.join(' ')?.trim()
  }

  public onCancelSideBar = () => {}

  public getSideBarComponent = () => {
    const { sideBarState, location } = this.props
    let Component: any = SideBarGoalView
    if (location.pathname.includes('/tasks')) {
      Component = SideBarTaskView
    }
    return (
      <Component showSideBar={sideBarState} onCancel={this.onCancelSideBar} />
    )
  }

  public render() {
    const {
      match,
      household,
      contacts,
      disableDocumentVault,
      collapsed,
      location,
      showTimedLoader
    } = this.props
    const { showImpersonationModal, showMeetingModeModal } = this.state
    const isHouseholdComplete = household && Object.keys(household).length > 17
    const runYodleeOnPage =
      household?.aggregationProvider === 'yodlee' &&
      (location.pathname.includes('dashboard') ||
        location.pathname.includes('netWorth') ||
        location.pathname.includes('investments'))
    if (isHouseholdComplete) {
      return (
        <Fragment>
          <div
            className={
              !collapsed ? 'household household--nav-open' : 'household'
            }>
            {this.renderHeader()}
            <div className='household__body'>
              <NavigationMenu
                url={match.url}
                collapsed={collapsed}
                disableDocumentVault={disableDocumentVault}
              />
              <div
                className={`household__body-content-w ${this.bodyModfierCls()}`}>
                <div className='household__body-content'>
                  <Switch>{this.routeRender()}</Switch>
                  <Disclosure routerLocation={location.pathname} />
                  <Toasts />
                  <div className='loader__container'>
                    {runYodleeOnPage && <YodleeLoader stacked />}
                    <SyncSpinner household={household} stacked />
                    {showTimedLoader ? <ShowTimedLoader stacked /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showImpersonationModal && contacts && (
            <ImpersonationModal
              household={household}
              primaryContact={contacts.primary}
              secondaryContact={contacts.secondary}
              closeModal={this.toggleImpersonationModal}
            />
          )}
          {showMeetingModeModal ? (
            <MeetingModeModal
              closeModal={this.toggleMeetingModeModal}
              confirmMeetingModeOff={this.confirmMeetingModeOff}
            />
          ) : null}
          {this.getSideBarComponent()}
        </Fragment>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (store: GlobalState, abc: any) => {
  const householdFinId = abc?.match?.params?.householdFinId
  const household = store?.households[householdFinId]
  const goalActions = store?.goalActions[householdFinId]
  return {
    contacts: store?.contact[householdFinId] || {},
    household,
    disableDocumentVault: store?.institution.disableDocumentVault,
    collapsed: store?.globalSettings?.globalNavigation?.collapsed,
    goalRequestId: goalActions && goalActions.goalRequestId,
    sideBarState: (goalActions && goalActions.sideBarState) || false,
    showTimedLoader: store?.timedLoader?.loaders?.length || false,
    ...store.toasts
  }
}

export default withRouter(connect(mapStateToProps)(Household))
