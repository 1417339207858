import React, { Component } from 'react'
import { dollarFormat } from '../../helpers'
import TableRow from '../../components/layout/tableRow'
import { ClientAccountObj } from '../../../objects/clientAccount'
import { maskAccountNumber } from '../../helpers/clientAccounts'
import CheckBox from '../../components/checkbox'
export interface MergeAccountsTableRowProps {
  clientAccount: ClientAccountObj
  primeAccountId?: string
  finalStep?: boolean
  onSelectAccount(account: ClientAccountObj, merge?: boolean): void
}

interface MergeAccountsTableRowState {
  accountSelected: boolean
}

export class MergeAccountsTableRow extends Component<
  MergeAccountsTableRowProps,
  MergeAccountsTableRowState
> {
  constructor(props: MergeAccountsTableRowProps) {
    super(props)
    this.state = {
      accountSelected: false
    }
  }

  onToggleAccountSelection = () => {
    const { accountSelected } = this.state
    const { onSelectAccount, clientAccount } = this.props
    this.setState(
      {
        accountSelected: !accountSelected
      },
      () => {
        if (this.state.accountSelected) {
          onSelectAccount(clientAccount, true)
        } else {
          onSelectAccount(clientAccount, false)
        }
      }
    )
  }

  public dollarsFormatted = () => {
    const { clientAccount } = this.props
    const liabilityDollarNames = [
      'Short-Term Liabilities',
      'Long-Term Liabilities'
    ]
    return dollarFormat(
      clientAccount.totalValue,
      0,
      clientAccount.category &&
        liabilityDollarNames.indexOf(clientAccount.category) >= 0
    )
  }

  public render() {
    const { clientAccount, finalStep, primeAccountId } = this.props
    const { accountSelected } = this.state
    return (
      <TableRow>
        <div className='merge-accounts-row'>
          <div className='merge-accounts-row__check'>
            {!finalStep ? (
              <CheckBox
                checked={accountSelected && !finalStep}
                onChange={this.onToggleAccountSelection}
                checkedBorder={true}
              />
            ) : (
              <CheckBox
                checked={clientAccount.id === primeAccountId}
                onChange={this.onToggleAccountSelection}
                checkedBorder={true}
                rounded={true}
              />
            )}
          </div>
          <div className='merge-accounts-row__name'>
            {clientAccount.name}{' '}
            {clientAccount.accountNumber ? (
              <span className='merge-accounts-row__account-number'>
                ({maskAccountNumber(clientAccount.accountNumber)})
              </span>
            ) : null}
          </div>
          <div className='merge-accounts-row__source'>
            {clientAccount.source}
          </div>
          <div className='merge-accounts-row__account-type'>
            {clientAccount.registrationType}
          </div>
          <div className='merge-accounts-row__amount'>
            {this.dollarsFormatted()}
          </div>
        </div>
      </TableRow>
    )
  }
}

export default MergeAccountsTableRow
