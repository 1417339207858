import React, { Component, ChangeEvent, SyntheticEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { esgFilterMap } from '../../../../../objects/strategySearch'
import { updateIMStrategyFilter } from '../../../../../actions/strategySearch'
import { GlobalState } from '../../../../../reducers'
import FilterCheckbox from '../filterCheckbox'

export interface FilterESGProps {
  dispatch: Dispatch<GlobalState>
  filter: string[]
  toggleFilterDropdown(): void
}

interface FilterESGState {
  isFilterChanges: boolean
  esgFilter: string[]
}

export class FilterESG extends Component<FilterESGProps, FilterESGState> {
  constructor(props: FilterESGProps) {
    super(props)
    const esgFilter = this.props.filter.map((x) =>
      Boolean(x) === true ? 'Yes' : 'No'
    )
    this.state = {
      isFilterChanges: false,
      esgFilter
    }
  }

  public handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  public toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { esgFilter } = this.state
    const value = e.currentTarget.value
    if (!esgFilter?.some((filter: string) => filter === value)) {
      this.setState({
        isFilterChanges: true,
        esgFilter: [...esgFilter, value]
      })
    } else {
      const tempState = [...esgFilter]
      tempState.forEach((item: string, i: number) => {
        if (value === item) {
          tempState.splice(i, 1)
        }
      })
      this.setState({
        isFilterChanges: true,
        esgFilter: tempState
      })
    }
  }

  public handleCancel = () => {
    this.setState({ esgFilter: [], isFilterChanges: false })
    this.props.toggleFilterDropdown()
  }

  public handleApply = () => {
    if (this.state.isFilterChanges) {
      this.setState({ isFilterChanges: false })
      this.props.dispatch(updateIMStrategyFilter('esg', this.state.esgFilter))
    }
    this.props.toggleFilterDropdown()
  }

  public render() {
    const { isFilterChanges } = this.state
    const accountTypeOptions = Object.keys(esgFilterMap).map(
      (key: string) => key
    )
    return (
      <div
        onClick={this.handleStopPropagation}
        className='strategy-search__dropdown-filter'>
        <div className='strategy-search__dropdown-filter-options--sm'>
          {accountTypeOptions.map((option: string) => (
            <FilterCheckbox
              key={esgFilterMap[option]}
              toggleCheckbox={this.toggleCheckbox}
              filters={this.state.esgFilter}
              filterName={option}
            />
          ))}
        </div>
        <div className='strategy-search__filter-buttons-cancel-apply-w strategy-search__filter-buttons-cancel-apply-w--popup'>
          <div
            onClick={this.handleCancel}
            className='strategy-search__filter-buttons-cancel'>
            Cancel
          </div>
          <div
            onClick={this.handleApply}
            className={
              isFilterChanges
                ? 'strategy-search__filter-buttons-apply'
                : 'strategy-search__filter-buttons-disabled'
            }>
            Apply
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    filter: store.strategySearch.filter.esg
  }
}

export default connect(mapStateToProps)(FilterESG)
