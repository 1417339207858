import * as actions from '../actions/financialGoalActions'
import {
  GoalActions as GoalActionsResponse,
  GoalResponse,
  FinancialGoalActionObj,
  GoalTemplatesResponse
} from '../objects/financialGoalActions'

export interface GoalsState {
  [key: string]: HouseholdGoalActionsState
}

export interface HouseholdGoalActionsState {
  sideBarState: boolean
  goalActions: GoalResponse[]
  goalRequestId: string
  activeGoalAction: GoalResponse
  suggestedGoals?: GoalTemplatesResponse[]
  suggestedGoalId: string
  selectedCardId?: string
}

export const initState: GoalsState = {}

const addGoalConfig = (data: GoalActionsResponse) => {
  return {
    ...{
      id: 'NEW',
      householdId: '',
      name: '',
      isNew: true,
      note: '',
      status: 'NA | Pending Review',
      type: 'life',
      dueDate: null,
      assigneePrimaryId: null,
      assigneeSecondaryId: null,
      cards: [],
      inGuidecenter: true,
      createdDate: new Date(),
      customGoal: true
    },
    ...data
  } as FinancialGoalActionObj
}
// NOTE: Any change in this function, will also be go to the below function
// helpers/goal.ts -> Fn -> getTileConfig
export const mapGoalActions = (goals: GoalResponse[]) => {
  const statusValues = [
    'Immediate Action Needed',
    'Improvement Needed',
    'Optimal Position',
    'NA | Pending Review'
  ]
  return goals?.map((goal: GoalResponse) => {
    return {
      assigneePrimaryId: goal.assigneePrimaryId,
      assigneeSecondaryId: goal.assigneeSecondaryId,
      cards: goal.cards,
      dueDate: goal.dueDate,
      id: goal.id,
      inGuidecenter: goal.inGuidecenter,
      name: goal.name,
      note: goal.note,
      palItemId: goal.palItemId,
      status: statusValues.includes(goal.status)
        ? goal.status
        : 'NA | Pending Review',
      type: goal.type,
      createdDate: goal.createdDate,
      archived: goal.archived,
      customGoal: goal.customGoal
    }
  })
}
/* eslint-disable complexity */
const GoalActions = (state = initState, action: any) => {
  const { type, payload: { data = {} } = {} } = action || {}
  const newState = JSON.parse(JSON.stringify(state))
  let modifiedState = {}
  // Set Default state when there is new houshold
  if (data?.householdId && !newState[data.householdId]) {
    newState[data.householdId] = {
      goalActions: [],
      goalRequestId: null,
      activeGoalAction: null,
      suggestedGoals: [],
      suggestedGoalId: null,
      sideBarState: false,
      selectedCardId: null
    }
  }
  switch (type) {
    case `${actions.FETCH_GOAL_ACTIONS}_FULFILLED`:
      modifiedState = {
        ...newState,
        ...{
          [data.householdId]: {
            goalActions: mapGoalActions(data?.data),
            goalRequestId: null,
            activeGoalAction: null,
            suggestedGoalId: null,
            sideBarState: false,
            selectedCardId: null
          }
        }
      }
      return data.data ? modifiedState : state
    case `${actions.CREATE_GOAL_ACTIONS}`:
      const newGoalData = addGoalConfig(data)
      if (newState[data.householdId]) {
        if (!newState[data.householdId]?.goalActions?.length) {
          newState[data.householdId]['goalActions'] = []
        }
        newState[data.householdId].goalActions.push({
          ...newGoalData,
          ...{ isNew: true }
        })
        newState[data.householdId].activeGoalAction = newGoalData
        newState[data.householdId].goalRequestId = newGoalData.id
        newState[data.householdId].suggestedGoalId = null
        modifiedState = newState
      } else {
        modifiedState = {
          ...newState,
          ...{
            [data.householdId]: {
              goalActions: [
                {
                  ...newGoalData,
                  ...{ isNew: true }
                }
              ],
              goalRequestId: newGoalData.id,
              activeGoalAction: newGoalData,
              suggestedGoalId: null
            }
          }
        }
      }
      return modifiedState
    case `${actions.CREATE_GOAL_ACTIONS}_FULFILLED`:
      const newGoalDatav2 = addGoalConfig(data)
      if (newState[data.householdId]) {
        if (!newState[data.householdId]?.goalActions?.length) {
          newState[data.householdId]['goalActions'] = []
        }
        newState[data.householdId].goalActions = newState[
          data.householdId
        ].goalActions.filter((goal: GoalResponse) => {
          return goal.id !== 'NEW'
        })
        newState[data.householdId].goalActions.push({
          ...newGoalDatav2,
          ...{ isNew: true }
        })
        newState[data.householdId].activeGoalAction = newGoalDatav2
        newState[data.householdId].goalRequestId = newGoalDatav2.id
        newState[data.householdId].suggestedGoalId = null
        modifiedState = newState
      } else {
        modifiedState = {
          ...newState,
          ...{
            [data.householdId]: {
              goalActions: [
                {
                  ...newGoalDatav2,
                  ...{ isNew: true }
                }
              ],
              goalRequestId: newGoalDatav2.id,
              activeGoalAction: newGoalDatav2,
              suggestedGoalId: null
            }
          }
        }
      }
      return modifiedState
    case `${actions.EDIT_GOAL_ACTIONS}_FULFILLED`:
      newState[data.householdId].goalActions = newState[
        data.householdId
      ].goalActions.map((goal: FinancialGoalActionObj) => {
        if (goal.id === data.id) {
          goal = data
        }
        return goal
      })
      return newState
    case `${actions.DELETE_GOAL_ACTIONS}_FULFILLED`:
      newState[data.householdId].goalActions = newState[
        data.householdId
      ].goalActions.filter(
        (goal: FinancialGoalActionObj) => goal.id !== data.goal.id
      )
      return newState
    case `${actions.SELECTED_GOALID_ACTIONS}`: {
      const {
        shouldResetSideBar,
        goalId,
        householdId,
        selectedCardId = ''
      } = data
      const currentGoalId = newState[data.householdId].goalRequestId

      const activeGoalId =
        goalId === null && shouldResetSideBar !== undefined
          ? shouldResetSideBar
            ? goalId
            : currentGoalId
          : goalId

      if (!newState[householdId]) {
        newState[householdId] = {
          goalRequestId: activeGoalId,
          goalActions: [],
          activeGoalAction: null,
          suggestedGoals: [],
          suggestedGoalId: null,
          sideBarState: false,
          selectedCardId: selectedCardId || ''
        }
      } else {
        newState[householdId].goalRequestId = activeGoalId
        newState[householdId].selectedCardId = selectedCardId || ''
        newState[householdId].activeGoalAction = newState[
          householdId
        ]?.goalActions.find(
          (action: { id: string }) => action.id === activeGoalId
        ) || { cards: [] }

        if (goalId === null) {
          newState[householdId].goalActions = newState[
            householdId
          ].goalActions.filter((action: { id: string }) => action.id !== 'NEW')
        }
      }
      return newState
    }
    case `${actions.FETCH_GOAL_ACTIONS_BY_ID}`:
      newState[data.householdId].activeGoalAction = data.data
      return newState
    case `${actions.UPDATE_GOAL_ACTIONS_BY_ID}`:
      newState[data.householdId].activeGoalAction = {
        ...newState[data.householdId].activeGoalAction,
        ...data
      }
      return newState
    case `${actions.FETCH_GOALS_TEMPLATES}_FULFILLED`:
      newState[data.householdId].suggestedGoals = data.data
      return newState
    case `${actions.SELECTED_SUGGESTED_GOALID}`:
      newState[data.householdId].suggestedGoalId = data.suggestedGoalId
      const { name: sgName = '', hcCards: sgHcCards = [], id } = newState[
        data.householdId
      ].suggestedGoals.find(
        (goal: GoalTemplatesResponse) => goal.id === data.suggestedGoalId
      )
      newState[data.householdId].activeGoalAction = {
        ...newState[data.householdId].activeGoalAction,
        ...{
          name: sgName,
          cards: sgHcCards,
          type: 'financial',
          palItemId: id,
          customGoal: false
        }
      }
      return newState
    case `ADD_NOTE_TEMPLATE_FULFILLED`:
      newState[data.householdId].suggestedGoals = newState[
        data.householdId
      ].suggestedGoals.map((goal: any) => {
        if (goal.id === data.palItemId) {
          goal.goalNoteTemplates.push(data)
        }
        return goal
      })
      return newState
    case `TOGGLE_SIDEBAR_STATE`:
      if (newState[data.householdId]) {
        newState[data.householdId].sideBarState = data.sideBarState
      } else {
        newState[data.householdId] = {
          sideBarState: data.sideBarState
        }
      }
      return newState
    default:
      return state
  }
}
export default GoalActions
