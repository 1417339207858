import React, { Component } from 'react'
import { GlobalState } from '../../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { MoneyMindInterface, MoneyMindObj } from '../../../../objects/moneymind'
import { ContactsInterface, ContactObj } from '../../../../objects/contact'
import { HouseholdObj } from '../../../../objects/household'
import * as moneymindActions from '../../../../actions/moneymind'
import ic_commitment from '../../../assets/images/icons/ic_commitment.svg'
import ic_happiness from '../../../assets/images/icons/ic_happiness.svg'
import ic_protection from '../../../assets/images/icons/ic_protection.svg'
import logo_mm from '../../../assets/images/logos/logo_mm.svg'

interface MoneyMindProps {
  household: HouseholdObj
  moneyminds: MoneyMindInterface
  dispatch: Dispatch<GlobalState>
  contact: ContactsInterface
}

class MoneyMind extends Component<MoneyMindProps> {
  public componentDidMount() {
    const { id, primaryContact, secondaryContact } = this.props.household
    if (primaryContact) {
      this.props.dispatch(
        moneymindActions.getMoneyMind(primaryContact, id, true)
      )
    }
    if (secondaryContact) {
      this.props.dispatch(
        moneymindActions.getMoneyMind(secondaryContact, id, false)
      )
    }
  }

  public sortHeader = (value: MoneyMindObj, contact: ContactObj) => {
    const { protection, commitment, happiness } = value

    const moneyArray = [
      { name: 'protection', value: protection },
      { name: 'commitment', value: commitment },
      { name: 'happiness', value: happiness }
    ]

    const sortedArray = moneyArray.sort((a, b) => {
      const tempA = a.value
      const tempB = b.value
      return tempA > tempB ? -1 : tempA < tempB ? 1 : 0
    })

    const data = () => {
      if (sortedArray[0].value > sortedArray[1].value) {
        return (
          <span
            className={`money-mind__header money-mind__header-${sortedArray[0].name}`}>
            {sortedArray[0].name.toUpperCase()}
          </span>
        )
      } else if (sortedArray[0].value === sortedArray[1].value) {
        const tempArray = [sortedArray[0].name, sortedArray[1].name]
        const returnData: JSX.Element[] = []
        let count = 0
        const commitmentIndex = tempArray.indexOf('commitment')
        const protectionIndex = tempArray.indexOf('protection')
        const happinessIndex = tempArray.indexOf('happiness')
        if (commitmentIndex > -1) {
          count += 1
          returnData.push(
            <span
              key={1}
              className={`money-mind__header money-mind__header-${sortedArray[commitmentIndex].name}`}>
              {sortedArray[commitmentIndex].name.toUpperCase()}
            </span>
          )
        }
        if (protectionIndex > -1) {
          if (count > 0) {
            returnData.push(<span key={2}> + </span>)
          }
          count += 1
          returnData.push(
            <span
              key={3}
              className={`money-mind__header money-mind__header-${sortedArray[protectionIndex].name}`}>
              {sortedArray[protectionIndex].name.toUpperCase()}
            </span>
          )
        }
        if (happinessIndex > -1) {
          if (count > 0) {
            returnData.push(<span key={4}> + </span>)
          }
          returnData.push(
            <span
              key={5}
              className={`money-mind__header money-mind__header-${sortedArray[happinessIndex].name}`}>
              {sortedArray[happinessIndex].name.toUpperCase()}
            </span>
          )
        }
        return returnData.map((element: JSX.Element) => element)
      } else {
        return null
      }
    }

    return (
      <div>
        <p className='money-mind__header'>
          {contact.firstName}'s MoneyMind® is{' '}
          {sortedArray[0].value === sortedArray[1].value ? 'a blend of' : ''}
        </p>
        <p>{data()}</p>
      </div>
    )
  }

  public clientAccounts = (contact: ContactObj, account: MoneyMindObj) => {
    return (
      <div className='money-mind__wrapper'>
        {this.sortHeader(account, contact)}
        <div className='money-mind__ratings'>
          <div>
            <p className='money-mind__ratings-text money-mind__ratings-blue'>
              {account.commitment}%
            </p>
            <img
              className='money-mind__ratings-logo'
              src={ic_commitment}
              alt='not-found'
            />
            <p className='money-mind__ratings-bottom money-mind__ratings-blue'>
              Commitment
            </p>
          </div>
          <div>
            <p className='money-mind__ratings-text money-mind__ratings-green'>
              {account.protection}%
            </p>
            <img
              className='money-mind__ratings-logo'
              src={ic_protection}
              alt='not-found'
            />
            <p className='money-mind__ratings-bottom money-mind__ratings-green'>
              Protection
            </p>
          </div>
          <div>
            <p className='money-mind__ratings-text money-mind__ratings-orange'>
              {account.happiness}%
            </p>
            <img
              className='money-mind__ratings-logo'
              src={ic_happiness}
              alt='not-found'
            />
            <p className='money-mind__ratings-bottom money-mind__ratings-orange'>
              Happiness
            </p>
          </div>
        </div>
      </div>
    )
  }

  public noData = () => {
    return (
      <div>
        <div className='no-result'>
          <img className='no-result' src={logo_mm} alt='not-found' />
          <div className='no-result__heading'>No results yet</div>
          <div className='no-result__sub-heading'>
            Understand your client and build a better portfolio proposal.
          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { moneyminds, contact } = this.props
    if (moneyminds && contact) {
      const { primary, secondary } = this.props.moneyminds
      const primaryContact = this.props.contact.primary
      const secondaryContact = this.props.contact.secondary
      const primaryMoneymind =
        primaryContact && primaryContact.activeYmmId && primary
          ? primary[primaryContact.activeYmmId]
          : null
      const secondaryMoneymind =
        secondaryContact && secondaryContact.activeYmmId && secondary
          ? secondary[secondaryContact.activeYmmId]
          : null
      if (!primaryMoneymind) {
        return this.noData()
      } else {
        return (
          <div className='money-mind'>
            {this.clientAccounts(primaryContact, primaryMoneymind)}
            {secondaryMoneymind ? this.divider() : ''}
            {secondaryMoneymind
              ? this.clientAccounts(secondaryContact, secondaryMoneymind)
              : ''}
          </div>
        )
      }
    } else {
      return this.noData()
    }
  }
  public divider = () => {
    return (
      <div className='money-mind__divider'>
        <div className='money-mind__divider-line' />
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId],
    moneyminds: store.moneymind[match.params.householdFinId],
    contact: store.contact[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(MoneyMind))
