import React from 'react'
import Button from '../../components/button'
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg'
import UploadFileModal from './uploadFileModal'

interface UploadFileProps {
  fileFormatError: boolean
  showUploadFileModal: boolean
  fileUploadPending: boolean
  folderId: string
  toggleUploadFileModal(): void
  uploadFiles(files: any[]): void
}

class UploadFile extends React.Component<UploadFileProps, {}> {
  public render() {
    const {
      uploadFiles,
      fileFormatError,
      showUploadFileModal,
      fileUploadPending,
      toggleUploadFileModal
    } = this.props
    return (
      <div>
        <Button primary={true} onClick={toggleUploadFileModal}>
          <div style={{ color: 'white' }}>
            <UploadIcon />
          </div>
          <div className='vault__btn--text'>Upload File</div>
        </Button>
        {showUploadFileModal ? (
          <UploadFileModal
            fileUploadPending={fileUploadPending}
            fileFormatError={fileFormatError}
            closeModal={toggleUploadFileModal}
            uploadFiles={uploadFiles}
          />
        ) : null}
      </div>
    )
  }
}

export default UploadFile
