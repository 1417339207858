import React from 'react'
import qs from 'query-string'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { history } from '../../../store'

interface SalesforceIntegrationAuthProps {
  dispatch: Dispatch<GlobalState>
}

class SalesforceIntegrationAuth extends React.Component<
  SalesforceIntegrationAuthProps
> {
  public async componentDidMount() {
    const grantToken = qs.parse(window.location.search).code
    const state = qs.parse(window.location.search).state as string
    const crm = state ? (qs.parse(state).crm as string) : null
    const v2ACL = state ? qs.parse(state).v2ACL : null
    localStorage.setItem('sfCode', grantToken as string)
    if (v2ACL) {
      history.push(`/auth/crm/${crm}`)
    } else {
      history.push(`/administration/${crm}`)
    }
  }
  public render() {
    return <div />
  }
}

export default connect()(SalesforceIntegrationAuth)
