import React, { Fragment, Component, ChangeEvent } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import Select from 'react-select'

import { OfficeTeamObj } from '../../../../objects/officeTeams'
import { UserObj, UsersObj } from '../../../../objects/user'
import { MoneyMindInterface } from '../../../../objects/moneymind'
import { ContactsInterface } from '../../../../objects/contact'
import {
  HouseholdInstitution,
  HouseholdObj
} from '../../../../objects/household'
import { StrategiesInterface } from '../../../../objects/strategies'
import { HonestConversationsExerciseInterface } from '../../../../objects/honestConversations'
import { InvestmentViewfinderInterface } from '../../../../objects/investmentViewfinder'
import { FundedScoreObj } from '../../../../objects/financialGoal'
import { getInstitutionByHousehold } from '../../../../actions/households'
import { getPortfolioBuilderAccounts } from '../../../../actions/portfolioBuilder'
import { getStrategies } from '../../../../actions/strategies'
import { isObjEmpty } from '../../../helpers'
import Tile from '../../../components/layout/tile'
import CheckBox from '../../../components/checkbox'

import helpIcon from '../../../assets/images/icons/general/help-gray.png'
import { filterStrategyIds } from '../../../helpers/portfolioBuilder'

export interface PpbNavigationProps {
  dispatch: Dispatch<GlobalState>
  household: HouseholdObj
  householdFinId: string
  portfolioBuilderId: string
  portfolioBuilderData: any
  institution: HouseholdInstitution
  moneyminds: MoneyMindInterface
  contacts: ContactsInterface
  fundedScores: FundedScoreObj[]
  honestConversations: HonestConversationsExerciseInterface
  viewFinderResutls: InvestmentViewfinderInterface
  coverPageInfo: {
    title: string
    clientName: string
    selectedAdvisor: UserObj
    defaultAdvisor: UserObj
    users: UsersObj
  }
  officeTeam: OfficeTeamObj
  gbSections: {
    checkedCover: boolean
    // --- Intro
    // checkedFirmOverview: boolean
    // checkedFinLifeProcessOverview: boolean
    checkedDigitalOnlyFormat: boolean
    checkedInvestmentProcessOverview: boolean
    checkedFinLifeProcessOverview: boolean
    // checkedInvestmentProcessOverview: boolean
    // --- Client Profile
    checkedMoneyMind: boolean
    checkedHonestConversations: boolean
    checkedViewfinderResults: boolean
    checkedFundedScore: boolean
    //  -- Recommendations
    checkedPortfolioSnapshot: boolean
    checkedPortfolioImplementation: boolean
    checkedInvestmentRecommendations: boolean
    // -- Recommendation Details
    checkedPortfolioDetails: boolean
    checkedPortfolioChangeIllustration: boolean
    checkedCostofServicesIllustration: boolean
    // -- Appendix
    checkedFactSheet: boolean
    checkedDisclosures: boolean
  }
  loadingStrategies: boolean
  strategies: StrategiesInterface
  allStrategyIds: string[]
  factSheetStrategyIds: string[]
  disableRecommendationDetail: boolean
  coverPageDataUpdate(
    inputField: string
  ): (e: ChangeEvent<HTMLInputElement>) => void
  coverPageAdvisorUpdate(selected: any): void
  toggleMe(section: string): any
}

export interface PpbNavigationState {
  factSheetStrategyIds: any
  allStrategyIds: any
}

interface SelectInterface {
  label: string
  value: UserObj
}

interface inputBoxItemInterface {
  label: string
  placeholder: string
  value: string
  inputField: string
}

interface checkBoxItemInterface {
  noToggleAllowed: boolean
  toggleName: string
  id: string
  label: string
  toolTip?: boolean
  toolTipText?: string
}

export class PpbNavigation extends Component<
  PpbNavigationProps,
  PpbNavigationState
> {
  constructor(props: PpbNavigationProps) {
    super(props)
    this.state = {
      factSheetStrategyIds: this.props.factSheetStrategyIds,
      allStrategyIds: this.props.allStrategyIds
    }
  }

  public async componentDidMount() {
    const { dispatch, householdFinId, portfolioBuilderId } = this.props
    await dispatch(getInstitutionByHousehold(this.props.householdFinId))
    await dispatch(getStrategies(householdFinId))
    await dispatch(
      getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
    )
    this.updateFactsheetList()
  }

  public componentDidUpdate() {
    const { portfolioBuilderData, factSheetStrategyIds } = this.props
    if (
      !factSheetStrategyIds &&
      portfolioBuilderData &&
      portfolioBuilderData.proposedAccounts
    ) {
      this.updateFactsheetList()
    }
  }

  public updateFactsheetList = () => {
    const { portfolioBuilderData, institution, strategies } = this.props
    const factSheetKey =
      institution && institution.isFinlifeInstitution
        ? 'finlifeFactSheetUrl'
        : 'ucfaFactSheetUrl'
    const allStrategyIds = filterStrategyIds(portfolioBuilderData)
    const factSheetStrategyIds = allStrategyIds.filter(
      (id: string) => strategies[id][factSheetKey] !== null
    )
    this.setState({
      factSheetStrategyIds,
      allStrategyIds
    })
  }

  public checkBoxItem = ({
    noToggleAllowed,
    toggleName,
    id,
    label,
    toolTip,
    toolTipText
  }: checkBoxItemInterface) => {
    const { toggleMe, gbSections } = this.props
    return (
      <div className='gb-container__nav-checkbox-w'>
        <CheckBox
          noToggleAllowed={noToggleAllowed}
          onChange={toggleMe(toggleName)}
          id={id}
          checked={gbSections[toggleName]}
          checkedBorder={true}
          label={label}
          toolTip={toolTip}
          toolTipIcon={helpIcon}
          toolTipText={toolTipText}
          style={{ padding: '20px 16px' }}
        />
      </div>
    )
  }

  public inputBoxItem = ({
    label,
    placeholder,
    value,
    inputField
  }: inputBoxItemInterface) => {
    return (
      <div className='gb-container__nav-input-w'>
        <label>
          <span>{label}</span>
          <input
            onChange={this.props.coverPageDataUpdate(inputField)}
            placeholder={placeholder}
            value={value}
          />
        </label>
      </div>
    )
  }

  public dropDown = (label: string) => {
    const { users, selectedAdvisor } = this.props.coverPageInfo
    const officeTeam = this.props.officeTeam
    let advisorOptions: SelectInterface[] = []
    if (officeTeam && officeTeam.members) {
      advisorOptions = officeTeam.members
        .filter((member) => users[member.userId])
        .map((member) => {
          return {
            value: users[member.userId],
            label: `${users[member.userId].firstName} ${
              users[member.userId].lastName
            }`
          }
        })
    }

    advisorOptions = advisorOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
      return 0
    })

    let defaultValue = advisorOptions[0]
    let placeHolder = ' '
    if (advisorOptions.length > 0) {
      placeHolder = `${advisorOptions[0].value.firstName} ${advisorOptions[0].value.lastName}`
    }
    advisorOptions.forEach((advisor, index: number) => {
      if (selectedAdvisor && selectedAdvisor.id === advisor.value.id) {
        defaultValue = advisor
        placeHolder = `${advisor.value.firstName} ${advisor.value.lastName}`
      }
    })
    return (
      <div className='gb-container__nav-select-w'>
        <label>
          <span>{label}</span>
          <Select
            placeholder={placeHolder}
            defaultValue={defaultValue}
            options={advisorOptions}
            onChange={this.props.coverPageAdvisorUpdate}
            className='react-select-container'
            classNamePrefix='react-select'
          />
        </label>
      </div>
    )
  }

  public cover = () => {
    const { title, clientName } = this.props.coverPageInfo
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='COVER PAGE'>
        {this.inputBoxItem({
          label: 'Portfolio Proposal Title',
          placeholder: 'Title',
          value: title,
          inputField: 'title'
        })}
        {this.dropDown('Advisor Name')}
        {this.inputBoxItem({
          label: 'Client Name',
          placeholder: 'Client',
          value: clientName,
          inputField: 'clientName'
        })}
      </Tile>
    )
  }

  public intro = () => {
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='INTRO'>
        {/* {this.checkBoxItem(
          false,
          'checkedFirmOverview',
          'FirmOverview',
          'Firm Overview',
          false,
          "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        )} */}
        {this.checkBoxItem({
          noToggleAllowed: false,
          toggleName: 'checkedFinLifeProcessOverview',
          id: 'FinLifeProcessOverview',
          label: 'FinLife Process Overview',
          toolTip: false,
          toolTipText:
            "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        })}
        {this.checkBoxItem({
          noToggleAllowed: false,
          toggleName: 'checkedInvestmentProcessOverview',
          id: 'InvestmentProcessOverview',
          label: 'Investment Process Overview',
          toolTip: false,
          toolTipText:
            "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        })}
      </Tile>
    )
  }

  public clientProfile = () => {
    const { moneyminds, contacts } = this.props
    const noToggleAllowedValue = !(
      moneyminds &&
      moneyminds.primary &&
      (!isObjEmpty(moneyminds.primary) || !isObjEmpty(moneyminds.secondary)) &&
      contacts &&
      contacts.primary &&
      contacts.primary.activeYmmId
    )
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='CLIENT PROFILE'>
        {this.checkBoxItem({
          noToggleAllowed: noToggleAllowedValue,
          toggleName: 'checkedMoneyMind',
          id: 'MoneyMind',
          label: 'MoneyMind',
          toolTip: noToggleAllowedValue,
          toolTipText:
            "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        })}
        {this.checkBoxItem({
          noToggleAllowed: isObjEmpty(this.props.honestConversations),
          toggleName: 'checkedHonestConversations',
          id: 'HonestConversations',
          label: 'HonestConversations',
          toolTip: isObjEmpty(this.props.honestConversations),
          toolTipText:
            "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        })}
        {this.checkBoxItem({
          noToggleAllowed: !(
            this.props.viewFinderResutls &&
            isObjEmpty(this.props.viewFinderResutls.answers)
          ),
          toggleName: 'checkedViewfinderResults',
          id: 'ViewfinderResults',
          label: 'Viewfinder Results',
          toolTip: !(
            this.props.viewFinderResutls &&
            isObjEmpty(this.props.viewFinderResutls.answers)
          ),
          toolTipText:
            "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        })}
        {this.checkBoxItem({
          noToggleAllowed: !(
            this.props.fundedScores && this.props.fundedScores.length
          ),
          toggleName: 'checkedFundedScore',
          id: 'FundedScore',
          label: 'Funded Score',
          toolTip: !(this.props.fundedScores && this.props.fundedScores.length),
          toolTipText:
            "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        })}
      </Tile>
    )
  }

  public recommendations = () => {
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='RECOMMENDATIONS'>
        {this.checkBoxItem({
          noToggleAllowed: false,
          toggleName: 'checkedPortfolioSnapshot',
          id: 'PortfolioSnapshot',
          label: 'Portfolio Snapshot',
          toolTip: false,
          toolTipText: null
        })}
        {this.checkBoxItem({
          noToggleAllowed: false,
          toggleName: 'checkedPortfolioImplementation',
          id: 'PortfolioImplementation',
          label: 'Portfolio Implementation',
          toolTip: false,
          toolTipText:
            'There were no current accounts, this page can not be included at this time.'
        })}
        {this.checkBoxItem({
          noToggleAllowed: false,
          toggleName: 'checkedInvestmentRecommendations',
          id: 'InvestmentRecommendations',
          label: 'Investment Recommendations',
          toolTip: false,
          toolTipText: null
        })}
      </Tile>
    )
  }

  public recommendationDetails = () => {
    const { disableRecommendationDetail } = this.props
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='RECOMMENDATION DETAILS'>
        {this.checkBoxItem({
          noToggleAllowed: disableRecommendationDetail,
          toggleName: 'checkedPortfolioDetails',
          id: 'PortfolioDetails',
          label:
            'Portfolio Details (Equity/Sector Weightings, Equity/Geographic Weightings, Fixed/Credit Weightings)',
          toolTip: disableRecommendationDetail,
          toolTipText:
            'Portfolio Details cannot be included due to unavailable data for the strategies selected'
        })}
        {/* {this.checkBoxItem(
          false,
          'checkedPortfolioChangeIllustration',
          'PortfolioChangeIllustration',
          'Portfolio Change Illustration (Yield, Expense Ratio, Fees)',
          false,
          "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        )} */}
        {/* {this.checkBoxItem(
          false,
          'checkedCostofServicesIllustration',
          'CostofServicesIllustration',
          'Cost of Services Illustration',
          false,
          "It looks like your client hasn't completed this part of the experience, so this section can't be included at this time."
        )} */}
      </Tile>
    )
  }

  public appendix = () => {
    const loadingStrategies = this.props.loadingStrategies
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='APPENDIX'>
        {this.checkBoxItem({
          noToggleAllowed:
            loadingStrategies || this.state.factSheetStrategyIds.length < 1,
          toggleName: 'checkedFactSheet',
          id: 'FactSheet',
          label: 'Fact Sheets',
          toolTip: this.state.factSheetStrategyIds.length < 1,
          toolTipText:
            'Fact sheets cannot be included based off your selection of strategies'
        })}
      </Tile>
    )
  }

  public downloadFormat = () => {
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='DOWNLOAD FORMAT'>
        {this.checkBoxItem({
          noToggleAllowed: false,
          toggleName: 'checkedDigitalOnlyFormat',
          id: 'DigitalOnlyCopy',
          label: 'Digital Only',
          toolTip: false,
          toolTipText: null
        })}
      </Tile>
    )
  }

  public render() {
    return (
      <Fragment>
        {this.downloadFormat()}
        {this.cover()}
        {this.intro()}
        {this.clientProfile()}
        {this.recommendations()}
        {this.recommendationDetails()}
        {this.appendix()}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId],
    portfolioBuilderData:
      store.portfolioBuilder[match.params.householdFinId] &&
      store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ],
    strategies: store.strategies[match.params.householdFinId],
    institution: store.households[match.params.householdFinId].institution
  }
}

export default withRouter(connect(mapStateToProps)(PpbNavigation))
