import {
  ClientAccountInterface,
  ClientAccountObj,
  ClientAccountPositionsObj,
  ClientAccountPositionsInterface
} from '../objects/clientAccount'
import * as actions from '../actions/clientAccounts'
import * as positionActions from '../actions/positions'

export interface ClientAccountState {
  [key: string]: ClientAccountInterface
}

const initState: ClientAccountState = {}

const mapClientAccountType = (
  classification: string
): { type: string; category: string } => {
  switch (classification) {
    case 'Cash & Equivalents':
      return {
        type: 'Assets',
        category: 'Cash & Equivalents'
      }
    case 'Taxable Investments':
      return {
        type: 'Assets',
        category: 'Taxable Investments'
      }
    case 'Tax-Deferred Investments':
      return {
        type: 'Assets',
        category: 'Tax-Deferred Investments'
      }
    case 'Tax-Exempt Investments':
      return {
        type: 'Assets',
        category: 'Tax-Exempt Investments'
      }
    case 'Real Estate':
      return { type: 'Assets', category: 'Real Estate' }
    case 'Personal Assets':
      return { type: 'Assets', category: 'Personal Assets' }
    case 'Business Assets':
      return { type: 'Assets', category: 'Business Assets' }
    case 'Other - Personal Assets':
      return { type: 'Assets', category: 'Personal Assets' }
    case 'Other - Business Assets':
      return { type: 'Assets', category: 'Business Assets' }
    case 'Short-Term Liabilities':
      return {
        type: 'Liabilities',
        category: 'Short-Term Liabilities'
      }
    case 'Long-Term Liabilities':
      return {
        type: 'Liabilities',
        category: 'Long-Term Liabilities'
      }
    default:
      return { type: null, category: null }
  }
}

const mapClientAccount = (clientAccount: any): ClientAccountObj => {
  const { type, category } = mapClientAccountType(
    clientAccount.guidebookClassification
  )
  return {
    id: clientAccount.id,
    name: clientAccount.accountNickname,
    accountNickname:
      clientAccount.accountNickname || clientAccount.accountNickname === ''
        ? clientAccount.accountNickname
        : clientAccount.accountName,
    balance: clientAccount.balance,
    balanceDate: clientAccount.balanceDate,
    totalValue: clientAccount.totalValueManual,
    accountType: clientAccount.accountType,
    type,
    category,
    includeInGuidebook: clientAccount.includeInGuidebook,
    isDeletedConnected: clientAccount.isDeletedConnected,
    pssSelected: clientAccount.pssSelected ? clientAccount.pssSelected : false,
    accountReporting: clientAccount.accountReporting,
    investment: clientAccount.pssSelected,
    accountNumber:
      clientAccount.source && clientAccount.source.toLowerCase() === 'emoney'
        ? '****'
        : clientAccount.accountNumber,
    accountNumberFull: clientAccount.accountNumberFull,
    description: clientAccount.description,
    date: clientAccount.balanceDate
      ? clientAccount.balanceDate.split('T')[0]
      : null,
    available: clientAccount.liquid,
    managed: clientAccount.managed,
    status: clientAccount.status,
    source: clientAccount.source,
    providerAccountId: clientAccount.connectedProviderAccountId,
    errorCode: clientAccount.connectedAccountSyncStatusCode,
    errorCodeDescription: clientAccount.connectedAccountSyncStatusMessage,
    classification: clientAccount.classification,
    registrationType: clientAccount.type,
    mgpOwner: clientAccount.mgpOwner,
    costBasis: clientAccount.costBasis,
    textCustodian: clientAccount.textCustodian,
    strategyReporting: clientAccount.strategyReporting,
    sendToPlanningSoftware: clientAccount.sendToPlanningSoftware,
    strategyId: clientAccount.strategyId,
    strategy: clientAccount.strategy,
    productName: clientAccount.productName,
    accountYield: clientAccount.yield,
    unclassified: clientAccount.unclassified,
    allocations: clientAccount.allocations,
    discreteAllocation: clientAccount.discreteAllocation,
    guidebookClassification: clientAccount.guidebookClassification,
    taxStatus: clientAccount.taxStatus,
    lastRunDate: clientAccount.lastRunDate,
    expenseRatio: clientAccount.expenseRatio,
    totalValueManual: clientAccount.totalValueManual,
    ownerIds: clientAccount.ownerIds,
    positions: mapAccountPositions(clientAccount.positions),
    allocationOverridden: clientAccount.allocationOverridden,
    visibleAccountId: clientAccount.visibleAccountId,
    isDeduped: clientAccount.isDeduped
  }
}

const mapAccountPositions = (
  positions: ClientAccountPositionsObj[],
  clientAccountPositions?: ClientAccountPositionsInterface
) => {
  const positionsObj = {}
  if (!clientAccountPositions) {
    positions?.forEach((pos) => {
      positionsObj[pos.id] = mapPosition(pos)
    })
    return positionsObj
  }

  positions?.forEach((newPosition: ClientAccountPositionsObj) => {
    const { id, symbol, clientAccount } = newPosition
    positionsObj[id] = { ...newPosition, tickerSymbol: symbol }
    Object.keys(clientAccountPositions).forEach((positionKey: string) => {
      const {
        clientAccount: cClientAccount,
        tickerSymbol: cTickerSymbol
      } = clientAccountPositions[positionKey]
      if (clientAccount === cClientAccount && symbol === cTickerSymbol) {
        positionsObj[id] = {
          ...clientAccountPositions[positionKey],
          ...newPosition,
          tickerSymbol: symbol
        }
      }
    })
  })

  return positionsObj
}

const mapPosition = (position: ClientAccountPositionsObj) => {
  return {
    clientAccount: position.clientAccount,
    createdDate: position.createdDate,
    asOfDate: position.asOfDate,
    id: position.id,
    isSleeve: position.isSleeve,
    name: position.name,
    price: position.price,
    quantity: position.quantity,
    rawValue: position.rawValue,
    totalCost: position.totalCost,
    value: position.value,
    altPercent: position.altPercent,
    cashPercent: position.cashPercent,
    equityPercent: position.equityPercent,
    fixedPercent: position.fixedPercent,
    unclassifiedPercent: position.unclassifiedPercent,
    securityDescription: position.securityDescription,
    securityId: position.securityId,
    securityStyle: position.securityStyle,
    securityType: position.securityType,
    tickerSymbol: position.tickerSymbol || position.symbol,
    allocationOverridden: position.allocationOverridden
  }
}

const mapClientAccounts = (clientAccounts: any[]) => {
  const returnObj = {}
  clientAccounts &&
    clientAccounts.forEach((clientAccount) => {
      returnObj[clientAccount.id] = mapClientAccount(clientAccount)
    })
  return returnObj
}

const mapClientAccountsForMerge = (clientAccounts: any[]) => {
  const allAccounts = {}
  const dedupedAccounts: string[] = []
  clientAccounts &&
    clientAccounts.forEach((clientAccount: ClientAccountObj) => {
      if (clientAccount.visibleAccountId) {
        if (!dedupedAccounts.includes(clientAccount.visibleAccountId)) {
          dedupedAccounts.push(clientAccount.visibleAccountId)
        }
        const visibleAccount: ClientAccountObj = clientAccounts.find(
          (account: ClientAccountObj) =>
            account.id === clientAccount.visibleAccountId
        )
        const updateVisibleAccount: ClientAccountObj = {
          ...visibleAccount,
          isDeduped: true
        }
        if (updateVisibleAccount?.id) {
          allAccounts[updateVisibleAccount?.id] = mapClientAccount(
            updateVisibleAccount
          )
        }
      } else {
        const acc = clientAccount
        if (acc?.id && !dedupedAccounts.includes(acc?.id)) {
          acc.isDeduped = false
          allAccounts[acc?.id] = mapClientAccount(acc)
        }
      }
    })
  const returnObj = {}
  Object.keys(allAccounts).forEach((id) => {
    if (!allAccounts[id].visibleAccountId) {
      returnObj[id] = allAccounts[id]
    }
  })
  return returnObj
}

const mapClientAccountUpdate = (clientAccounts: any[], clientAccount: any) => {
  if (
    clientAccounts[clientAccount.id] !== null ||
    clientAccounts[clientAccount.id] !== undefined
  ) {
    clientAccounts[clientAccount.id] = mapClientAccount(clientAccount)
  }
  return clientAccounts
}

const ClientAccounts = (state = initState, action: any): ClientAccountState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_CLIENT_ACCOUNTS}_FULFILLED`:
      newState[action.payload.data.householdFinId] = {
        ...newState[action.payload.data.householdFinId],
        clientAccounts: window._env_.REACT_APP_MERGE_ACCOUNTS_ENABLED
          ? mapClientAccountsForMerge(action.payload.data)
          : mapClientAccounts(action.payload.data)
      }
      return newState
    case `${actions.UPDATE_CLIENT_ACCOUNT}_FULFILLED`:
      const caStr = 'clientAccounts'
      newState[action.payload.data.householdFinId] = {
        ...newState[action.payload.data.householdFinId],
        clientAccounts: mapClientAccountUpdate(
          newState[action.payload.data.householdFinId][caStr],
          action.payload.data
        )
      }
      return newState
    case `${actions.CREATE_CLIENT_ACCOUNT}_FULFILLED`:
      const clientAccount = mapClientAccount(action.payload.data)
      newState[action.payload.data.householdFinId].clientAccounts = {
        ...newState[action.payload.data.householdFinId].clientAccounts,
        [clientAccount.id]: clientAccount
      }
      return newState
    case `${positionActions.UPDATE_POSITION}_FULFILLED`:
      newState[action.payload.data.householdFinId].clientAccounts[
        action.payload.data.clientAccount
      ].positions[action.payload.data.id] = mapPosition(action.payload.data)
      return newState
    case `${positionActions.UPLOAD_POSITION}_FULFILLED`:
      newState[action.payload.householdFinId].clientAccounts[
        action.payload.clientAccount
      ].positions = {
        ...mapAccountPositions(
          action.payload.data,
          newState[action.payload.householdFinId].clientAccounts[
            action.payload.clientAccount
          ].positions
        )
      }
      return newState
    case `${actions.MERGE_CLIENT_ACCOUNTS}_FULFILLED`:
      return state
    case `${actions.GET_CLIENT_ACCOUNTS_ERROR_EMAIL}_FULFILLED`:
      newState[action.payload.data.householdFinId].clientAccountsErrorEmail =
        action.payload.data
      return newState
    case `${actions.SET_YODLEE_SYNC_ATTEMPT}`:
      if (newState[action.payload.householdId]) {
        newState[action.payload.householdId].yodleeSyncAttempted =
          action.payload.value
      }
      return newState
    default:
      return state
  }
}

export default ClientAccounts
