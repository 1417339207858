import React, { Component, ReactNode, CSSProperties } from 'react'
/**
 * Score Graph
 * cardColor : Color of Card
 * score : 1 - 10 | null
 */
interface ScoreGraphProps {
  score: number
}

const scoreTickPaths = [
  'm 0.456,33.86278 c 0,0 -0.16832,2.15147 -0.14032,3.24331 0.028,1.09183 0.14034,3.19999 0.14034,3.19999 l 7.84673,-0.72165 c 0,0 -0.17622,-1.80644 -0.17622,-2.45035 0,-0.6439 0.12622,-2.6012 0.12622,-2.6012 z',
  'm 3.60469,21.59474 c 0,0 -0.85337,1.93334 -1.13333,2.66123 -0.27996,0.7279 -1.01578,3.31815 -1.01578,3.31815 l 7.59684,2.1134 c 0,0 0.33392,-1.37214 0.64188,-2.26801 0.30795,-0.89587 0.95745,-2.42271 0.95745,-2.42271 z',
  'm 10.70173,11.18237 c 0,0 -1.58641,1.78772 -2.02192,2.30241 -0.43551,0.5147 -1.92644,2.5945 -1.92644,2.5945 l 6.39734,4.58762 c 0,0 0.91363,-1.36207 1.34915,-1.89656 0.43551,-0.5345 1.74956,-1.91787 1.74956,-1.91787 z',
  'm 19.84792,13.60505 c 0,0 1.54866,-1.10735 2.09458,-1.4293 0.54592,-0.32195 2.10367,-1.09647 2.10367,-1.09647 L 20.69756,3.75969 c 0,0 -2.18456,1.12313 -2.66049,1.41709 -0.47593,0.29395 -2.68728,1.83033 -2.68728,1.83033 z',
  'm 32.49265,0.46072 c 0,0 -2.23511,0.29518 -3.06655,0.45355 -0.83143,0.15837 -2.93095,0.73202 -2.93095,0.73202 l 1.99917,7.73196 c 0,0 1.70383,-0.48616 2.43629,-0.62473 0.73245,-0.13857 2.26175,-0.35466 2.26175,-0.35466 z',
  'm 38.09032,8.29577 c 0,0 1.76832,0.12122 2.382,0.2004 0.61367,0.0792 2.46598,0.52125 2.46598,0.52125 l 1.99916,-7.73196 c 0,0 -2.40635,-0.52956 -3.05962,-0.62854 -0.65327,-0.099 -3.13779,-0.35084 -3.13779,-0.35084 z',
  'm 47.58636,10.71845 c 0,0 1.41807,0.66795 2.09113,1.08367 0.67307,0.41571 2.20708,1.49365 2.20708,1.49365 L 56.3827,6.59474 c 0,0 -1.99374,-1.36492 -2.6866,-1.80044 C 53.00324,4.35879 50.93497,3.34732 50.93497,3.34732 Z',
  'm 55.68299,16.49165 c 0,0 1.12109,1.11072 1.65559,1.74419 0.53449,0.63348 1.64303,2.07024 1.64303,2.07024 l 6.59725,-4.74227 c 0,0 -1.6086,-2.01964 -2.1233,-2.59372 -0.51469,-0.57409 -2.12493,-2.25164 -2.12493,-2.25164 z',
  'm 61.53055,24.68752 c 0,0 0.71202,1.61215 0.94999,2.27006 0.23796,0.6579 0.7493,2.42067 0.7493,2.42067 l 7.74677,-2.16495 c 0,0 -0.68522,-2.29942 -0.90919,-2.94333 -0.22396,-0.6439 -1.18993,-3.0876 -1.18993,-3.0876 z',
  'm 64.07951,34.53283 c 0,0 0.12831,1.9451 0.12831,2.4301 0,0.48501 -0.0783,2.67305 -0.0783,2.67305 l 8.09662,0.6701 c 0,0 0.081,-2.41611 0.067,-3.25599 -0.014,-0.83987 -0.16694,-3.23891 -0.16694,-3.23891 z'
]

const scoreGraphStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingRight: '20px',
  paddingLeft: '20px'
}

class ScoreGraph extends Component<ScoreGraphProps> {
  public getBaseColor = () => {
    const { score } = this.props

    if (!score || score === 0) {
      return '#D2D2D2'
    }

    return score < 7 ? '#FFBE1A' : '#8ccc33'
  }

  public getScoreText = (): ReactNode => {
    const { score } = this.props
    const roundedScore = score && Math.round(score)
    return (
      <text
        style={{
          fontSize: '14px',
          fill: 'white',
          fontWeight: 500,
          fillRule: 'evenodd'
        }}
        x='0.4'
        y='0.4'>
        <tspan y='40' x={score > 9 ? '27.5' : '32.5'}>
          {roundedScore}
        </tspan>
      </text>
    )
  }

  public getScoreTicks = (): ReactNode[] => {
    const { score } = this.props
    const pathNodes: ReactNode[] = []
    scoreTickPaths.forEach((shape: string, index: number) => {
      const pathStyle: CSSProperties = {
        fill: score <= index ? '#ccc' : this.getBaseColor()
      }
      pathNodes.push(<path key={index} d={shape} style={pathStyle} />)
    })

    return pathNodes
  }

  public render() {
    // tslint:disable-next-line:no-http-string
    const svgNameSpace = 'http://www.w3.org/2000/svg'
    const fillColor = this.getBaseColor()
    return (
      <div style={scoreGraphStyle}>
        <svg
          xmlns={svgNameSpace}
          version='1.1'
          viewBox='0 0 75 50'
          height='50'
          width='75'>
          <path
            key='text-circle'
            style={{ fill: fillColor }}
            d='m 45.45606,26.10608 c -2.5,-2.53333 -5.55,-3.8 -9.15,-3.8 -3.6,0 -6.66667,1.26667 -9.2,3.8 -2.53333,2.53333 -3.8,5.6 -3.8,9.2 0,3.6 1.26667,6.66667 3.8,9.2 2.53333,2.53333 5.6,3.8 9.2,3.8 3.6,0 6.65,-1.26667 9.15,-3.8 2.56667,-2.53333 3.85,-5.6 3.85,-9.2 0,-3.6 -1.28333,-6.66667 -3.85,-9.2 z'
          />
          {this.getScoreText()}
          {this.getScoreTicks()}
        </svg>
      </div>
    )
  }
}

export default ScoreGraph
