import React, { Component } from 'react'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import { history } from '../../../store'

export interface DisabledModalProps {
  householdFinId: string
  closeModal(): void
}

class DisabledModal extends Component<DisabledModalProps> {
  public render() {
    const { closeModal, householdFinId } = this.props
    return (
      <Modal title='Investment Viewfinder' closeModal={closeModal}>
        <div className='disabled-modal__w'>
          <p>Investment Viewfinder is currently disabled.</p>
          <div className='disabled-modal__btn-w'>
            <Button onClick={closeModal} clear>
              Cancel
            </Button>
            <Button
              onClick={() =>
                history.push(`/households/${householdFinId}/clientSettings`, {
                  fromDisabledLink: true
                })
              }
              primary>
              Change Settings
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default DisabledModal
