import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { HouseholdPreferencesObj } from '../objects/householdPreferences'

export const SET_HOUSEHOLD_PREFERENCES = 'SET_HOUSEHOLD_PREFERENCES'
export const UPDATE_HOUSEHOLD_PREFERENCES = 'UPDATE_HOUSEHOLD_PREFERENCES'

export const setHouseholdPreferences = (
  householdFinId: string,
  householdPreferences: HouseholdPreferencesObj
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  if (householdPreferences.investmentsInvestmentsSortBy === 'Strategy') {
    householdPreferences.investmentsInvestmentsSortBy = 'Product Name'
  }

  return {
    type: SET_HOUSEHOLD_PREFERENCES,
    payload: api()
      .post(`/households/${householdFinId}/preferences`, householdPreferences)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateHouseholdPreferences = (
  householdFinId: string,
  householdPreferences: HouseholdPreferencesObj
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  if (householdPreferences.investmentsInvestmentsSortBy === 'Strategy') {
    householdPreferences.investmentsInvestmentsSortBy = 'Product Name'
  }

  return {
    type: UPDATE_HOUSEHOLD_PREFERENCES,
    payload: api()
      .put(`/households/${householdFinId}/preferences`, householdPreferences)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}
