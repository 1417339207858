import { AxiosResponse } from 'axios'
import api from './api'
import { TaskObj, TaskResponse } from '../../objects/tasks'
import { setTasks } from '../../actions/tasks'
import { store } from '../../store'

export const taskRefetch = (
  householdId: string,
  remainingRetryAttempt: number = 3
) => {
  void api()
    .get(`/households/${householdId}/tasks/tasksV2`)
    .then((response: AxiosResponse<TaskResponse>) => {
      const { crmTasks } = response.data
      const fiveMins = window._env_.REACT_APP_TASKS_OUT_OF_SYNC_TIME_IN_MS
      let isOutOfSync: boolean = false
      response.data.householdId = householdId
      // check every task to see if they are all within 5 mins of lastSynced time
      isOutOfSync = crmTasks.length
        ? crmTasks.every((task: TaskObj) => {
            const currentDateTimeTest: any = new Date()
            const lastSyncedDate: any = new Date(task.lastSynced)
            return Math.abs(lastSyncedDate - currentDateTimeTest) > fiveMins
          })
        : false
      // retry api call if response has crmTasks that are out of sync
      if (crmTasks.length > 0 && isOutOfSync) {
        if (remainingRetryAttempt < 1) {
          // Base case
          store.dispatch(setTasks(response))
          return response
        } else {
          // if isOutOfSync is true and there are crmTasks, retry api call 3 times in 10 second intervals
          setTimeout(() => {
            // recursively call until base case is met
            taskRefetch(householdId, remainingRetryAttempt - 1)
          }, 10000)
        }
      }
      return store.dispatch(setTasks(response))
    })
}
