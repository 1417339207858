import React, { Component, ChangeEvent, KeyboardEvent } from 'react'
import { Link } from 'react-router-dom'
import { history } from '../../../store'
import { connect, Dispatch } from 'react-redux'
import moment from 'moment'

import { dateFormat } from '../../helpers'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import * as actions from '../../../actions/portfolioBuilder'
import { PortfolioBuilderObj } from '../../../objects/portfolioBuilder'
import { PortfolioBuilderState } from '../../../reducers/portfolioBuilder'
import { GlobalState } from '../../../reducers'

import { ReactComponent as CalendarIconSVG } from '../../assets/images/icons/calendar.svg'
import { ReactComponent as ChartIcon } from '../../assets/images/icons/chart-gray.svg'

interface PortfolioBuilderModalProps {
  allPortfolioBuilders: PortfolioBuilderState
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  closeModal(): void
}

interface PortfolioBuilderModalState {
  createNewVal: string
}

class PortfolioBuilderModal extends Component<
  PortfolioBuilderModalProps,
  PortfolioBuilderModalState
> {
  constructor(props: PortfolioBuilderModalProps) {
    super(props)
    this.state = {
      createNewVal: ''
    }
  }

  public updateCreateNewVal = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ createNewVal: e.target.value })
  }

  public createPortfolioBuilder = async () => {
    const { householdFinId } = this.props
    await this.props.dispatch(
      actions.createPortfolioBuilder(householdFinId, this.state.createNewVal)
    )
    const portfolioBuilderList = this.props.allPortfolioBuilders[householdFinId]
    history.push(
      `/households/${householdFinId}/portfolioBuilder/${portfolioBuilderList.newPortfolioBuilder.id}`
    )
  }

  public handleEnterPress = (e: KeyboardEvent): any => {
    return e.key === 'Enter' ? this.createPortfolioBuilder() : false
  }

  public ppbList = () => {
    const { householdFinId, allPortfolioBuilders } = this.props
    const portfolioBuilderList = allPortfolioBuilders[householdFinId]
    return (
      portfolioBuilderList &&
      Object.keys(portfolioBuilderList)
        .sort(
          (a, b) =>
            moment().diff(portfolioBuilderList[a].lastModified) -
            moment().diff(portfolioBuilderList[b].lastModified)
        )
        .map((key) => {
          const portfolioBuilder = portfolioBuilderList[key]
          return <div key={`${key}`}>{this.ppRender(portfolioBuilder)}</div>
        })
    )
  }

  public ppRender = (portfolioBuilder: PortfolioBuilderObj) => {
    const { householdFinId } = this.props
    const {
      targetEquityPercent,
      targetAltPercent,
      targetCashPercent,
      targetFixedPercent
    } = portfolioBuilder
    return (
      <Link
        to={`/households/${householdFinId}/portfolioBuilder/${portfolioBuilder.id}`}>
        <div className='launch-exercise__pp'>
          <div className='launch-exercise__pp-label'>
            {portfolioBuilder.name}
          </div>
          <div className='launch-exercise__pp-graph-w'>
            {targetEquityPercent ? (
              <span
                style={{ width: targetEquityPercent + '%' }}
                className='launch-exercise__pp-graph launch-exercise__pp-graph--equity'
              />
            ) : null}
            {targetFixedPercent ? (
              <span
                style={{ width: targetFixedPercent + '%' }}
                className='launch-exercise__pp-graph launch-exercise__pp-graph--fixed'
              />
            ) : null}
            {targetCashPercent ? (
              <span
                style={{ width: targetCashPercent + '%' }}
                className='launch-exercise__pp-graph launch-exercise__pp-graph--cash'
              />
            ) : null}
            {targetAltPercent ? (
              <span
                style={{ width: targetAltPercent + '%' }}
                className='launch-exercise__pp-graph launch-exercise__pp-graph--alt'
              />
            ) : null}
          </div>
          <div className='launch-exercise__pp-date'>
            {portfolioBuilder.lastModified ? (
              dateFormat(String(portfolioBuilder.lastModified))
            ) : (
              <CalendarIconSVG />
            )}
          </div>
        </div>
      </Link>
    )
  }

  public render() {
    const { allPortfolioBuilders, householdFinId, closeModal } = this.props
    const { createNewVal } = this.state
    const portfolioBuilderList =
      allPortfolioBuilders &&
      allPortfolioBuilders[householdFinId] &&
      Object.keys(allPortfolioBuilders[householdFinId]).length !== 0
    return (
      <Modal
        icon={ChartIcon}
        title='LAUNCH PORTFOLIO PROPOSAL'
        closeModal={closeModal}>
        <div className='launch-exercise'>
          {portfolioBuilderList && (
            <>
              <div className='launch-exercise__pp-w'>{this.ppbList()}</div>
              <div className='launch-exercise__input-w'>
                <span className='launch-exercise__or-text'>or</span>
              </div>
            </>
          )}
          <div className='launch-exercise__input-w'>
            <input
              value={createNewVal}
              onChange={this.updateCreateNewVal}
              onKeyPress={this.handleEnterPress}
              className='form-input'
              type='text'
              placeholder='Write the name of the new proposal'
            />
          </div>
        </div>
        {!portfolioBuilderList ? (
          <div className='modal__buttons modal__buttons--w modal__buttons--center'>
            <Button
              style={createNewVal === '' ? { cursor: 'unset' } : null}
              primary={createNewVal !== ''}
              onClick={
                createNewVal !== '' ? this.createPortfolioBuilder : null
              }>
              Create New
            </Button>
          </div>
        ) : (
          <div className='modal__buttons modal__buttons--w modal__buttons--center'>
            <Button primary={true} onClick={this.createPortfolioBuilder}>
              Launch Portfolio Proposal
            </Button>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    allPortfolioBuilders: store.portfolioBuilder
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioBuilderModal)
