import React, { Component } from 'react'

import {
  goalTypesList,
  FinancialGoalObj
} from '../../../../objects/financialGoal'
import { GoalRequestObj } from '../../../../objects/goalRequests'
import {
  PreviousGoalName,
  DisplayGoalName,
  PreviousGoalDate,
  DisplayDate,
  PreviousAmount,
  DisplayAmount
} from './goalDisplay'

import Modal from '../../../components/layout/modal'
import TableRow from '../../../components/layout/tableRow'
import CustomSelect from '../../../components/CustomSelect'
import Button from '../../../components/button'

import { ReactComponent as GoalIcon } from '../../../assets/images/icons/edit.svg'

interface GoalChangeProps {
  name: string
  startDate: string
  endDate: string
  amount: string
  type: string
  owner: string
  amountType: string
  goalRequest: GoalRequestObj
  goal: FinancialGoalObj
  mappedGoal: FinancialGoalObj
  closeModal(): void
  completeRequest(request: GoalRequestObj): void
}

interface GoalChangeState {
  startDate: any
  endDate: any
}

class GoalChange extends Component<GoalChangeProps, GoalChangeState> {
  public handleRemoveGoal = async () => {
    const { completeRequest, goalRequest } = this.props
    const request = { ...goalRequest, status: 'COMPLETED' }
    completeRequest(request)
  }

  public renderModalFooter = () => {
    const { closeModal } = this.props
    return (
      <div className='goal-change-modal__footer'>
        <div />
        <div className='goal-change-modal__footer--buttons'>
          <Button clear={true} onClick={closeModal}>
            Cancel
          </Button>
          <Button primary={true} onClick={this.handleRemoveGoal}>
            Remove Changes
          </Button>
        </div>
      </div>
    )
  }

  public displayDescription = () => {
    const { goalRequest } = this.props
    const formatDisplay = (): string => {
      return goalRequest && goalRequest.comment
        ? 'goal-row__description goal-row__description-content'
        : 'goal-row__description'
    }
    return (
      <div className={formatDisplay()}>
        {goalRequest && goalRequest.comment ? goalRequest.comment : null}
      </div>
    )
  }

  public render() {
    const { goalRequest, goal, mappedGoal } = this.props
    return (
      <Modal
        icon={GoalIcon}
        title='Pending Plan Input Changes'
        closeModal={this.props.closeModal}>
        <TableRow>
          <div className='goal-change-modal__header--name'>NAME</div>
          <div className='goal-change-modal__header--period'>PERIOD</div>
          <div className='goal-change-modal__header--amount'>AMOUNT</div>
        </TableRow>
        <TableRow>
          <div className='goal-row__name-m'>
            <PreviousGoalName
              goal={goal}
              mappedGoal={mappedGoal}
              className='goal-row__tag goal-row__pending-change'
            />
            <DisplayGoalName
              goal={mappedGoal}
              goalRequest={this.props.goalRequest}
              className='goal-row__name-m'
            />
          </div>
          <div className='goal-row__period goal-row__period-left'>
            <PreviousGoalDate goal={goal} mappedGoal={mappedGoal} />
            <DisplayDate
              goal={mappedGoal}
              goalRequest={this.props.goalRequest}
            />
          </div>
          <div className='goal-change-modal__header--amount'>
            <PreviousAmount goal={goal} mappedGoal={mappedGoal} />
            <DisplayAmount
              goal={mappedGoal}
              goalRequest={this.props.goalRequest}
            />
          </div>
        </TableRow>
        <TableRow>
          <div className='goal-change-modal__details'>
            {this.displayDescription()}
            {goalRequest && goalRequest.proposedGoalType ? (
              <CustomSelect
                titleName='Type'
                contents={goalRequest.proposedGoalType.replace('Goal', '')}
                dataList={goalTypesList}
                controlFunc={() => {}}
                inputFieldName='goalChange'
                isDisabled={true}
                classes='goal-change-modal__details--select-goal'
                toolTipText='To change Type, delete the Plan Input, and create New in the correct Category.'
                selectChanged={false}
              />
            ) : null}
            {this.renderModalFooter()}
          </div>
        </TableRow>
      </Modal>
    )
  }
}

export default GoalChange
