import React from 'react'
import Tile from '../../../components/layout/tile'
import TableHeader from '../../../components/layout/tableHeader'
import ContactRow from './contactRow'
import { ContactsInterface } from '../../../../objects/contact'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/contacts'

interface ContactsTileProps {
  contacts: ContactsInterface
  dispatch: Dispatch<GlobalState>
}

class ContactsTile extends React.Component<ContactsTileProps> {
  public async componentDidMount() {
    const { contacts } = this.props
    if (contacts) {
      contacts.primary &&
        (await this.props.dispatch(actions.getContact(contacts.primary.id)))
      contacts.secondary &&
        (await this.props.dispatch(actions.getContact(contacts.secondary.id)))
    }
  }
  public render() {
    const { contacts } = this.props
    return (
      <div className='financial-planning__tile-w'>
        <Tile
          leftHeader={'CONFIRM CONTACT INFORMATION'}
          headerBorder={true}
          rightHeader={''}
          headerStyle={{
            backgroundColor: '#FAFAFA',
            borderBottom: 'none',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}>
          <div className='financial-planning__tile-content'>
            <TableHeader>
              <div className='financial-planning__content-header financial-planning__content-header--fname'>
                First Name
              </div>
              <div className='financial-planning__content-header financial-planning__content-header--lname'>
                Last Name
              </div>
              <div className='financial-planning__content-header'>
                Contact Priority
              </div>
              <div className='financial-planning__content-header'>Gender</div>
              <div className='financial-planning__content-header financial-planning__content-header--birthdate'>
                Birthdate
              </div>
              <div className='financial-planning__content-header'>
                Marital Status
              </div>
              <div className='financial-planning__content-header financial-planning__content-header--employment'>
                Employment Status
              </div>
              <div className='financial-planning__content-header'>
                State of Residence
              </div>
            </TableHeader>
            {contacts && contacts.primary ? (
              <ContactRow contact={contacts.primary} contactType='Primary' />
            ) : null}
            {contacts && contacts.secondary ? (
              <ContactRow
                contact={contacts.secondary}
                contactType='Secondary'
              />
            ) : null}
          </div>
        </Tile>
      </div>
    )
  }
}

export default connect()(ContactsTile)
