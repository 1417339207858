import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { UserObj } from '../../../objects/user'
import * as userActions from '../../../actions/user'
import * as institutionActions from '../../../actions/institution'
import onClickOutside from 'react-onclickoutside'
import MeetingMode from '../../components/meetingMode'

import avatarIcon from '../../assets/images/icons/general/avatar-gray.png'
import settingsIcon from '../../assets/images/icons/general/settings-gray.png'
import logoutIcon from '../../assets/images/icons/general/logout-gray.png'
import ProfilePicture from '../../components/layout/profilePicture'

interface ProfileMenuProps {
  user: UserObj
  match: any
  dispatch: Dispatch<GlobalState>
}

interface ProfileMenuState {
  profileMenuToggle: boolean
}

export class ProfileMenu extends Component<ProfileMenuProps, ProfileMenuState> {
  constructor(props: any) {
    super(props)
    this.state = {
      profileMenuToggle: false
    }
  }

  public componentDidMount() {
    this.props.dispatch(institutionActions.getInstitution())
    this.props.dispatch(userActions.getUser())
  }

  public toggleProfileMenu = () => {
    this.setState({ profileMenuToggle: !this.state.profileMenuToggle })
  }

  public handleClickOutside = (e: any) => {
    this.setState({ profileMenuToggle: false })
  }

  public profileMenuDropdown = () => {
    if (this.state.profileMenuToggle) {
      return (
        <ul className='header__dropdown'>
          <MeetingMode>
            <li>
              <Link to='/profile'>
                <img src={avatarIcon} alt='profile icon' />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <Link to='/advisor-settings'>
                <img src={settingsIcon} alt='settings icon' />
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link to='/administration'>
                <img src={logoutIcon} alt='logout icon' />
                <span>Administration</span>
              </Link>
            </li>
          </MeetingMode>
          <li>
            <Link to='/logout'>
              <img src={logoutIcon} alt='logout icon' />
              <span>Log out</span>
            </Link>
          </li>
        </ul>
      )
    } else {
      return null
    }
  }

  public render() {
    const firstName = this.props.user ? this.props.user.firstName : ''
    const lastName = this.props.user ? this.props.user.lastName : ''
    return (
      <div
        onClick={this.toggleProfileMenu}
        className={
          this.state.profileMenuToggle === true
            ? 'header__icon header__profile header__profile--active'
            : 'header__icon header__profile'
        }>
        <div className='header__profile-icon'>
          <ProfilePicture
            firstName={firstName}
            lastName={lastName}
            photo={this.props?.user?.photo}
            isPrimary={true}
            pictureSize={32}
          />
        </div>
        <span>
          {firstName} {lastName}
        </span>
        {this.profileMenuDropdown()}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    user: store.user.userId ? store.user.users[store.user.userId] : null
  }
}

export default withRouter(connect(mapStateToProps)(onClickOutside(ProfileMenu)))
