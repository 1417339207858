import React, { Component } from 'react'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import scoreIcon from '../../assets/images/icons/svg/ic_score-icon.svg'
import { history } from '../../../store'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'

export interface EditScoresModalProps {
  honestConversation: HonestConversationsExerciseObj

  isPrimary: boolean
  householdFinId: string
  scoringSessionId: string
  closeModal(): void
}

class EditScoresModal extends Component<EditScoresModalProps> {
  public gotoAddScore = () => {
    const { householdFinId, honestConversation } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/newScore/${honestConversation.id}`
    )
  }

  public goToEditScore = () => {
    const { isPrimary, householdFinId, scoringSessionId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/editScore/${scoringSessionId}`,
      { isPrimary }
    )
  }

  public render() {
    const { closeModal } = this.props
    return (
      <Modal iconPng={scoreIcon} title='EDITING SCORES' closeModal={closeModal}>
        <div className='edit-scores-modal'>
          <p className='edit-scores-modal__text-1'>
            Are you sure you want to edit this record?
          </p>
          <p className='edit-scores-modal__text-2'>
            If you're making a change because scores changed, we recommend you
            re-score.
          </p>
          <div className='edit-scores-modal__btn-w'>
            <Button
              primary={true}
              style={{ width: '268px' }}
              onClick={this.gotoAddScore}>
              Let's rescore
            </Button>
            <Button
              primary={true}
              style={{ width: '268px' }}
              onClick={this.goToEditScore}>
              Continue with a change
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default EditScoresModal
