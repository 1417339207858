import { FolderInterface, FileObj } from '../objects/folder'
import * as actions from '../actions/documentVault'
export interface DocumentVaultState {
  folders: {
    [key: string]: FolderInterface
  }
  newFolders: {
    [key: string]: boolean
  }
}

const initState: DocumentVaultState = {
  folders: {},
  newFolders: {}
}

export const mapFile = (file: any): FileObj => {
  return {
    id: file.id,
    name: file.name,
    lastUpdated: file.lastUpdated,
    size: file.size,
    createdDate: file.createdDate,
    archived: file.archived || false,
    folderId: file.folder
  }
}

const mapFiles = (files: any[]): FileObj[] => {
  return files.map((file) => {
    return mapFile(file)
  })
}

const mapHouseholdFolder = (folder: any) => {
  return {
    id: folder.id,
    parentId: folder.household,
    name: folder.name,
    lastUpdated: folder.lastUpdated,
    files: folder.files ? mapFiles(folder.files) : [],
    readOnly: folder.readOnly,
    archived: folder.archived
  }
}

const mapHouseholdFolders = (householdFinId: string, folders: any[]) => {
  const folderObjs = {}
  folders.forEach((folder) => {
    folderObjs[folder.id] = mapHouseholdFolder(folder)
  })
  const obj = {}
  obj[householdFinId] = folderObjs
  return obj
}

const DocumentVault = (state = initState, action: any): DocumentVaultState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_FOLDERS_FULFILLED:
      const folders = mapHouseholdFolders(
        action.payload.data.householdFinId,
        action.payload.data
      )
      return { ...state, folders: { ...folders } }
    case actions.FETCH_FOLDER_FULFILLED:
      newState.folders[action.payload.data.householdFinId][
        action.payload.data.id
      ] = mapHouseholdFolder(action.payload.data)
      return newState
    case actions.CREATE_FOLDER_FULFILLED:
      if (!newState.folders[action.payload.data.householdFinId]) {
        newState.folders[action.payload.data.householdFinId] = {}
      }
      newState.folders[action.payload.data.householdFinId][
        action.payload.data.id
      ] = mapHouseholdFolder(action.payload.data)
      return newState
    case actions.EDIT_FOLDER_FULFILLED:
      newState.folders[action.payload.data.householdFinId][
        action.payload.data.id
      ].name = action.payload.data.name
      return newState
    case actions.DELETE_FOLDER_FULFILLED:
      return newState
    case actions.EMPTY_TRASH_FULFILLED:
      return newState
    case actions.HARD_DELETE_FULFILLED:
      return newState
    case actions.DELETE_FILE_FULFILLED:
      const newFiles: FileObj[] = []
      const files =
        newState.folders[action.payload.data.householdFinId][
          action.payload.data.folderId
        ]?.files
      files &&
        files.forEach((file: FileObj) => {
          if (action.payload.data.fileId !== file.id) {
            newFiles.push(file)
          }
        })
      newState.folders[action.payload.data.householdFinId][
        action.payload.data.folderId
      ].files = newFiles
      return newState
    case actions.ADD_NEW_FOLDER:
      newState.newFolders[action.payload] = true
      return newState
    case actions.CLEAR_NEW_FOLDERS:
      newState.newFolders = {}
      return newState
    default:
      return state
  }
}

export default DocumentVault
