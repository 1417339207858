import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'

import { GlobalState } from '../../../../reducers'
import { getClientAccounts } from '../../../../actions/clientAccounts'
import { updateOverrideAllocations } from '../../../../actions/positions'
import {
  ClientAccountPositionsObj,
  ClientAccountObj,
  PositionAllocationObj
} from '../../../../objects/clientAccount'
import { dateFormat } from '../../../helpers'

import Tile from '../../../components/layout/tile'
import TableHeader from '../../../components/layout/tableHeader'
import TableRow from '../../../components/layout/tableRow'
import HoldingsRow from './holdingsRow'
import HoldingsCard from './holdingsCard'
import OverrideModal from './overrideModal'
import TableHeaderLabel from './tableHeaderLabel'

import ListIconGray from '../../../assets/images/icons/png/hamburger_menu_gray.png'
import ListIconBlue from '../../../assets/images/icons/png/hamburger_menu_blue.png'
import CardIconGray from '../../../assets/images/icons/png/modular_menu_gray.png'
import CardIconBlue from '../../../assets/images/icons/png/modular_menu_blue.png'

interface HoldingsProps {
  dispatch: Dispatch<GlobalState>
  positions: ClientAccountPositionsObj[]
  clientAccount: ClientAccountObj
  resetLabelsFlag: boolean
  householdFinId: string
  position: ClientAccountPositionsObj
  sortByStringField(sortByDescending: boolean, field: string): void
  sortByNumericField(sortByDescending: boolean, field: string): void
  sortByAllocation(sortByDescending: boolean): void
  sortByAOverride(sortByDescending: boolean): void
}
interface HoldingsState {
  isListView: boolean
  isCardView: boolean
  selectedPosition: ClientAccountPositionsObj
  toggleOverride: boolean
}

class Holdings extends Component<HoldingsProps, HoldingsState> {
  constructor(props: HoldingsProps) {
    super(props)
    this.state = {
      isCardView: false,
      isListView: true,
      selectedPosition: null,
      toggleOverride: false
    }
  }

  public toggleListLayoutView = () => {
    this.setState({ isListView: true, isCardView: false })
  }

  public toggleCardLayoutView = () => {
    this.setState({ isListView: false, isCardView: true })
  }

  public toggleOverrideModal = () => {
    this.setState({ toggleOverride: !this.state.toggleOverride })
  }

  public setSelectedPosition = (position: ClientAccountPositionsObj) => {
    this.toggleOverrideModal()
    this.setState({ selectedPosition: position })
  }

  public handleAllocationRevert = async (
    position: ClientAccountPositionsObj
  ) => {
    const { dispatch, householdFinId } = this.props
    const reqBody: PositionAllocationObj = {
      equityPercent: null,
      fixedPercent: null,
      altPercent: null,
      cashPercent: null,
      unclassifiedPercent: null
    }
    await dispatch(
      updateOverrideAllocations(householdFinId, position.id, reqBody)
    )
    dispatch(getClientAccounts(householdFinId))
  }

  public sortBySymbol = (sortByDescending: boolean) => {
    this.props.sortByStringField(sortByDescending, 'tickerSymbol')
  }

  public sortByDescription = (sortByDescending: boolean) => {
    this.props.sortByStringField(sortByDescending, 'securityDescription')
  }

  public sortByValue = (sortByDescending: boolean) => {
    this.props.sortByNumericField(sortByDescending, 'value')
  }

  public sortByAllocation = (sortByDescending: boolean) => {
    this.props.sortByAllocation(sortByDescending)
  }

  public sortByAOverride = (sortByDescending: boolean) => {
    this.props.sortByAOverride(sortByDescending)
  }

  public renderListHeader = () => {
    const { resetLabelsFlag, positions } = this.props
    const positionRows = positions
      ? Object.keys(positions).map((rowItem: string, i: number) => (
          <TableRow key={i}>
            <HoldingsRow
              position={positions[rowItem]}
              setSelectedPosition={this.setSelectedPosition}
              handleAllocationRevert={this.handleAllocationRevert}
            />
          </TableRow>
        ))
      : null
    return (
      <Tile headerStyle={{ backgroundColor: '#FAFAFA' }}>
        <TableHeader>
          <div className='holdings__table-header holdings__table-header-symbol'>
            <TableHeaderLabel
              title='TICKER SYMBOL'
              toggle={this.sortBySymbol}
              resetFlag={resetLabelsFlag}
            />
          </div>
          <div className='holdings__table-header holdings__table-header-security'>
            <TableHeaderLabel
              title='SECURITY DESCRIPTION'
              toggle={this.sortByDescription}
              resetFlag={resetLabelsFlag}
            />
          </div>
          <div className='holdings__table-header holdings__table-header-allocation'>
            <TableHeaderLabel
              title='ALLOCATION'
              toggle={this.sortByAllocation}
              resetFlag={resetLabelsFlag}
            />
          </div>
          <div className='holdings__table-header holdings__table-header-value'>
            <TableHeaderLabel
              title='VALUE'
              toggle={this.sortByValue}
              resetFlag={resetLabelsFlag}
            />
          </div>
          <div className='holdings__table-header holdings__table-header-actions'>
            <TableHeaderLabel
              title='OVERRIDE ACTIONS'
              toggle={this.sortByAOverride}
              resetFlag={resetLabelsFlag}
            />
          </div>
        </TableHeader>
        {positionRows}
      </Tile>
    )
  }

  public render() {
    const { positions, clientAccount } = this.props
    const {
      isListView,
      isCardView,
      selectedPosition,
      toggleOverride
    } = this.state
    const positionCards = positions
      ? Object.keys(positions).map((card: string, i: number) => (
          <HoldingsCard
            key={i}
            position={positions[card]}
            setSelectedPosition={this.setSelectedPosition}
            handleAllocationRevert={this.handleAllocationRevert}
          />
        ))
      : null
    return (
      <div>
        <div className='holdings__header'>
          <span className='holdings__header-left'>
            <span className='holdings__header-left-title'>Positions</span>
            <span className='holdings__header-left-update'>
              Last Updated on {dateFormat(clientAccount.date)}
            </span>
          </span>
          <span className='holdings__header-right'>
            <span className='holdings__header-right-toggle'>
              <div className='holdings__header-right-toggle-link'>
                <img
                  onClick={this.toggleListLayoutView}
                  alt='list icon'
                  width={25}
                  height={25}
                  src={isListView ? ListIconBlue : ListIconGray}
                />
              </div>
              <div className='holdings__header-right-toggle-link'>
                <img
                  onClick={this.toggleCardLayoutView}
                  alt='list icon'
                  width={25}
                  height={25}
                  src={isCardView ? CardIconBlue : CardIconGray}
                />
              </div>
            </span>
          </span>
        </div>
        {isListView ? this.renderListHeader() : null}
        {isCardView ? (
          <div className='holdings-card__w'>{positionCards}</div>
        ) : null}
        {toggleOverride ? (
          <OverrideModal
            closeModal={this.toggleOverrideModal}
            position={selectedPosition}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(Holdings))
