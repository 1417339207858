import React from 'react'
import { DotOval } from '../dotOval'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion'

import OverrideAllocationModal from './overrideAllocationModal'
import { GlobalState } from '../../../../reducers'
import { dollarFormat } from '../../../helpers'
import {
  PortfolioBuilderObj,
  PortfolioBuilderAccountObj
} from '../../../../objects/portfolioBuilder'

import FinancialCharts from '../../../components/graphs/financialCharts'

interface CurrentAllocationProps {
  dispatch: Dispatch<GlobalState>
  portfolioBuilderData: PortfolioBuilderObj
  portfolioBuilderId: string
  householdFinId: string
}

interface CurrentAllocationStates {
  filteredAccounts: PortfolioBuilderAccountObj
  openModal: boolean
  currentAllocationData: {
    portfolioValue: number
    yield: number
    expenseRatio: number
    fees: number
    equity: number
    fixed: number
    cash: number
    otherValue: number
    Unclassified: number
    currentAccounts: PortfolioBuilderAccountObj
    overrideEquityPercent: number
    overrideFixedPercent: number
    overrideCashPercent: number
    overrideOtherPercent: number
    overrideTotalPercent: number
  }
}
class CurrentAllocationTile extends React.Component<
  CurrentAllocationProps,
  CurrentAllocationStates
> {
  constructor(props: CurrentAllocationProps) {
    super(props)
    this.state = {
      filteredAccounts: null,
      openModal: false,
      currentAllocationData: {
        overrideEquityPercent: 0,
        overrideFixedPercent: 0,
        overrideCashPercent: 0,
        overrideOtherPercent: 0,
        overrideTotalPercent: 0,
        portfolioValue: 0,
        yield: 0,
        expenseRatio: 0,
        fees: 0,
        equity: 0,
        fixed: 0,
        cash: 0,
        otherValue: 0,
        Unclassified: 0,
        currentAccounts: null
      }
    }
  }

  public componentDidMount() {
    if (this.props.portfolioBuilderData) {
      this.generateAccounts(this.props)
    }
  }

  public componentWillReceiveProps(nextProps: CurrentAllocationProps) {
    if (nextProps.portfolioBuilderData !== this.props.portfolioBuilderData) {
      this.generateAccounts(nextProps)
    }
  }

  public closeModal = (): void => {
    this.setState({ openModal: false })
  }

  public openDialog = () => {
    this.setState({ openModal: true })
  }

  public calcPercent = () => {
    const {
      overrideCashPercent,
      overrideEquityPercent,
      overrideFixedPercent,
      overrideOtherPercent,
      overrideTotalPercent
    } = this.state.currentAllocationData
    return (
      overrideCashPercent +
        overrideEquityPercent +
        overrideFixedPercent +
        overrideOtherPercent +
        overrideTotalPercent ===
      100
    )
  }

  public handleChange = (inputVals: any) => {
    const {
      overrideCashPercent,
      overrideEquityPercent,
      overrideFixedPercent,
      overrideOtherPercent,
      overrideTotalPercent
    } = inputVals
    this.setState({
      currentAllocationData: {
        ...this.state.currentAllocationData,
        overrideCashPercent,
        overrideEquityPercent,
        overrideFixedPercent,
        overrideOtherPercent,
        overrideTotalPercent
      }
    })
  }

  public generateAccounts(nextProps: CurrentAllocationProps) {
    const newDetails = Object.assign(
      {},
      this.state.currentAllocationData,
      nextProps.portfolioBuilderData
    )
    this.setState({ currentAllocationData: newDetails }, () => {
      this.generateData()
    })
  }

  public overRideBtn = () => {
    return (
      <div>
        <a onClick={this.openDialog} className='override-btn'>
          Override
        </a>
      </div>
    )
  }
  // tslint:disable-next-line:max-func-body-length
  public generateData() {
    const { currentAllocationData } = this.state
    let { portfolioValue } = currentAllocationData
    const {
      overrideEquityPercent,
      overrideCashPercent,
      overrideFixedPercent,
      overrideOtherPercent,
      overrideTotalPercent,
      currentAccounts
    } = this.state.currentAllocationData

    if (currentAccounts) {
      Object.keys(currentAccounts)
        .filter((key: string) => {
          const clientAccount = currentAccounts[key]
          return clientAccount.type === 'Current'
        })
        .forEach((key: string) => {
          currentAllocationData.currentAccounts[key] = currentAccounts[key]
        })
      currentAllocationData.portfolioValue = Object.keys(
        currentAllocationData.currentAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous + currentAllocationData.currentAccounts[key].accountValue
        )
      }, 0)
      portfolioValue = this.state.currentAllocationData.portfolioValue

      currentAllocationData.yield = Object.keys(
        currentAllocationData.currentAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          currentAllocationData.currentAccounts[key].yield *
            (currentAllocationData.currentAccounts[key].accountValue /
              portfolioValue)
        )
      }, 0)
      currentAllocationData.yield =
        Math.round(currentAllocationData.yield * 100) / 100

      currentAllocationData.expenseRatio = Object.keys(
        currentAllocationData.currentAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          currentAllocationData.currentAccounts[key].expenseRatio *
            (currentAllocationData.currentAccounts[key].accountValue /
              portfolioValue)
        )
      }, 0)
      currentAllocationData.expenseRatio =
        Math.round(currentAllocationData.expenseRatio * 100) / 100

      currentAllocationData.fees = Object.keys(
        currentAllocationData.currentAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          currentAllocationData.currentAccounts[key].strategyFee *
            (currentAllocationData.currentAccounts[key].accountValue /
              portfolioValue)
        )
      }, 0)
      currentAllocationData.fees =
        Math.round(currentAllocationData.fees * 100) / 100
    }

    if (overrideEquityPercent || overrideEquityPercent === 0) {
      currentAllocationData.equity = overrideEquityPercent
    } else if (currentAllocationData.currentAccounts) {
      const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
        (sum: number, key: string) => {
          return (
            (sum || 0) +
            currentAllocationData.currentAccounts[key].currentEquityValue
          )
        },
        0
      )
      currentAllocationData.equity =
        parseFloat(((temp / portfolioValue) * 100).toFixed(2)) || 0
    }

    if (overrideCashPercent || overrideCashPercent === 0) {
      currentAllocationData.cash = overrideCashPercent
    } else if (currentAllocationData.currentAccounts) {
      const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
        (sum: number, key: string) => {
          return (
            sum + currentAllocationData.currentAccounts[key].currentCashValue
          )
        },
        0
      )
      currentAllocationData.cash =
        parseFloat(((temp / portfolioValue) * 100).toFixed(2)) || 0
    }

    if (overrideOtherPercent || overrideOtherPercent === 0) {
      currentAllocationData.otherValue = overrideOtherPercent
    } else if (currentAllocationData.currentAccounts) {
      const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
        (sum: number, key: string) => {
          return (
            sum + currentAllocationData.currentAccounts[key].currentOtherValue
          )
        },
        0
      )
      currentAllocationData.otherValue = temp
        ? parseFloat(((temp / portfolioValue) * 100).toFixed(2))
        : 0
    }

    if (overrideTotalPercent || overrideTotalPercent === 0) {
      currentAllocationData.Unclassified = overrideTotalPercent
    } else if (currentAllocationData.currentAccounts) {
      const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
        (sum: number, key: string) => {
          return (
            sum +
            currentAllocationData.currentAccounts[key].currentUnclassifiedValue
          )
        },
        0
      )
      currentAllocationData.Unclassified = temp
        ? parseFloat(((temp / portfolioValue) * 100).toFixed(2))
        : 0
    }

    if (overrideFixedPercent || overrideFixedPercent === 0) {
      currentAllocationData.fixed = overrideFixedPercent
    } else if (currentAllocationData.currentAccounts) {
      const temp = Object.keys(currentAllocationData.currentAccounts).reduce(
        (sum: number, key: string) => {
          return (
            sum + currentAllocationData.currentAccounts[key].currentFixedValue
          )
        },
        0
      )
      currentAllocationData.fixed = temp
        ? parseFloat(((temp / portfolioValue) * 100).toFixed(2))
        : 0
    }
    this.setState({ currentAllocationData })
  }
  // tslint:disable-next-line:max-func-body-length
  public renderAccordionBody = () => {
    const {
      equity,
      fixed,
      cash,
      otherValue,
      Unclassified,
      yield: allocationYield,
      expenseRatio,
      fees,
      portfolioValue
    } = this.state.currentAllocationData

    const chartOptions = {
      animateRotate: true,
      showTooltips: false,
      responsive: false,
      cutoutPercentage: 75,
      segmentShowStroke: false,
      tooltips: {
        enabled: false
      },
      elements: {
        arc: {
          borderWidth: 2
        }
      }
    }

    const chartData = {
      datasets: [
        {
          data: [equity, fixed, cash, otherValue, Unclassified],
          backgroundColor: [
            '#255EBA',
            '#FFBE1A',
            '#FFDE8C',
            '#A2D134',
            '#a4a4a4'
          ]
        }
      ]
    }

    return (
      <div className='proposal-work__proposed-w'>
        <div className='client-allocation'>
          <div className='client-allocation__ratio-wrapper'>
            <div className='client-allocation__ratio-data'>
              Yield {allocationYield}%
            </div>
            <div className='client-allocation__ratio-data'>
              Exp Ratio {expenseRatio}%
            </div>
            <div className='client-allocation__ratio-data'>
              Fee {fees && (fees / 100).toFixed(2)}%
            </div>
          </div>
          <div className='client-allocation__graph'>
            <div style={{ marginRight: '20px' }}>
              <FinancialCharts
                canvasId='current-allocation'
                chartData={chartData}
                chartOptions={chartOptions}
                dimensions={{ height: 100, width: 100 }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <DotOval
                backgroundColor='#255EBA'
                title='Equity'
                value={equity + '%'}
              />
              <DotOval
                backgroundColor='#FFBE1A'
                title='Fixed'
                value={fixed + '%'}
              />
              <DotOval
                backgroundColor='#FFDE8C'
                title='Cash'
                value={cash + '%'}
              />
              <DotOval
                backgroundColor='#A2D134'
                title='Alternatives'
                value={otherValue + '%'}
              />
              <DotOval
                backgroundColor='#a4a4a4'
                title='Unclassified'
                value={Unclassified + '%'}
              />
            </div>
          </div>
          <div className='client-allocation__ratio-wrapper client-allocation__total-investment'>
            <div className='client-allocation__name'>
              Total Available to Invest
            </div>
            <div className='client-allocation__data'>
              {dollarFormat(Math.round(portfolioValue), 0)}
            </div>
          </div>
          <div className='client-allocation__progress-bar' />
        </div>
        {this.state.openModal ? (
          <OverrideAllocationModal
            householdFinId={this.props.householdFinId}
            portfolioBuilderId={this.props.portfolioBuilderId}
            currentAllocationData={this.state.currentAllocationData}
            closeModal={this.closeModal}
            handleChange={this.handleChange}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  public render() {
    const { portfolioBuilderData } = this.props
    if (portfolioBuilderData) {
      return (
        <div className='proposal-work__accordion-w'>
          <Accordion>
            <AccordionItem expanded={true}>
              <div className='proposal-work__title proposal-work__title--border'>
                <AccordionItemTitle>
                  <div className='proposal-work__title--current'>
                    <span className='title-text'>CURRENT ALLOCATION</span>
                    <div className='accordion__arrow proposal-work__title-arrow' />
                  </div>
                </AccordionItemTitle>
                {this.overRideBtn()}
              </div>
              <AccordionItemBody
                className='proposal-work__accordion'
                expanded={false}>
                {this.renderAccordionBody()}
              </AccordionItemBody>
            </AccordionItem>
          </Accordion>
        </div>
      )
    } else {
      return <div />
    }
  }
}

const mapStateToProps = (_store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    portfolioBuilderId: match.params.portfolioBuilderId,
    portfolioBuilderData:
      _store.portfolioBuilder[match.params.householdFinId] &&
      _store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ]
  }
}
export default withRouter(connect(mapStateToProps)(CurrentAllocationTile))
