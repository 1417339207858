import React, { CSSProperties } from 'react'
import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import palIcon from '../../assets/images/icons/householdNav/pal.png'

const contentStyle: CSSProperties = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '251px'
}

const tileStyle: CSSProperties = {
  border: 'none',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
}

const GoalsNullView: React.FunctionComponent = () => {
  return (
    <Tile contentStyle={contentStyle} tileStyle={tileStyle}>
      <img className='goals-null-view__icon' src={palIcon} alt='goals' />
      <p className='goals-null-view__main-text'>There are no goals here.</p>
      <p className='goals-null-view__secondary-text'>
        Add new goals for this client by clicking
      </p>
      <p className='goals-null-view__tertiary-text'>
        the button in the top right of this area.
      </p>
    </Tile>
  )
}

export default GoalsNullView
