import React, { Component } from 'react'
import InnerContainer from '../../components/layout/innerContainer'
import Tile from '../../components/layout/tile'
import BackToLink from '../../components/layout/headerBackToLink'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  HonestConversationsExerciseInterface,
  HonestConversationsExerciseObj
} from '../../../objects/honestConversations'
import { ContactsInterface } from '../../../objects/contact'
import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'
import { HouseholdObj } from '../../../objects/household'
import RecordForm from './recordForm'
import * as hcExerciseActions from '../../../actions/hcExercises'
import { history } from '../../../store'
import { getGoalActions } from '../../../actions/financialGoalActions'
import { getContacts } from '../../../actions/contacts'
import goalActionsSelector from '../../../selectors/v3/goals'

interface AddNewScoreProps {
  dispatch?: Dispatch<GlobalState>
  exerciseId?: string
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  household: HouseholdObj
  goals: FinancialGoalActionObj[]
}

interface AddNewScoreState {
  previousActiveScoringSessionIdPrimary: string
  previousActiveScoringSessionIdSecondary: string
}

class AddNewScore extends Component<AddNewScoreProps, AddNewScoreState> {
  constructor(props: AddNewScoreProps) {
    super(props)

    this.state = {
      previousActiveScoringSessionIdPrimary: null,
      previousActiveScoringSessionIdSecondary: null
    }
  }

  public async componentDidMount() {
    const {
      dispatch,
      householdFinId,
      contacts,
      goals,
      honestConversation
    } = this.props
    !contacts && dispatch(getContacts())
    !goals && dispatch(getGoalActions(householdFinId))
    if (!honestConversation) {
      await dispatch(
        hcExerciseActions.getHonestConversationExercises(householdFinId)
      )
    }
    await this.newScoringSession(contacts?.primary?.id, true)
    if (contacts?.secondary) {
      await this.newScoringSession(contacts?.secondary?.id, false)
    }
  }

  public goBack = () => {
    history.goBack()
  }

  public newScoringSession = async (contactId: string, isPrimary: boolean) => {
    // create new scoring session
    await this.createScoringSession(contactId, isPrimary)
    // update exercise with new active scoring session id
    await this.updateActiveScoreSessionId(contactId, isPrimary)
  }

  public createScoringSession = async (
    contactId: string,
    isPrimary: boolean
  ) => {
    const {
      dispatch,
      household,
      honestConversation,
      contacts,
      exerciseId
    } = this.props

    if (isPrimary) {
      this.setState({
        previousActiveScoringSessionIdPrimary:
          honestConversation.activeScoringSessionPrimary
      })
    } else if (contacts.secondary && !isPrimary) {
      this.setState({
        previousActiveScoringSessionIdSecondary:
          honestConversation.activeScoringSessionSecondary
      })
    }
    await dispatch(
      hcExerciseActions.createScoringSessions(
        household.id,
        exerciseId,
        {
          clientId: contactId,
          scoredWithAdvisor: true
        },
        isPrimary
      )
    )
  }

  public updateActiveScoreSessionId = async (
    contactId: string,
    isPrimary: boolean
  ) => {
    const {
      household,
      honestConversation,
      dispatch,
      contacts,
      exerciseId
    } = this.props
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    updatedProperty.activeScoringSessionPrimary =
      honestConversation.activeScoringSessionPrimary
    if (contacts.secondary) {
      updatedProperty.activeScoringSessionSecondary =
        honestConversation.activeScoringSessionSecondary
    }
    await dispatch(
      hcExerciseActions.updateHonestConversationExercise(
        household.id,
        exerciseId,
        updatedProperty
      )
    )
  }

  public render() {
    const {
      honestConversation,
      contacts,
      householdFinId,
      household,
      goals
    } = this.props
    const {
      previousActiveScoringSessionIdPrimary,
      previousActiveScoringSessionIdSecondary
    } = this.state
    return (
      <InnerContainer>
        <BackToLink onClick={this.goBack}>Back</BackToLink>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          rightHeader='NEW SCORES'
          headerBorder={true}>
          {honestConversation && honestConversation.householdCards ? (
            <RecordForm
              goals={goals}
              household={household}
              honestConversation={honestConversation}
              contacts={contacts}
              householdFinId={householdFinId}
              previousActiveScoringSessionIdPrimary={
                previousActiveScoringSessionIdPrimary
              }
              previousActiveScoringSessionIdSecondary={
                previousActiveScoringSessionIdSecondary
              }
            />
          ) : null}
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, exerciseId } = match.params

  const household = store.households[householdFinId]
  const honestConversations: HonestConversationsExerciseInterface =
    store.hcExercises[householdFinId]
  const honestConversation: HonestConversationsExerciseObj = honestConversations
    ? honestConversations[exerciseId]
    : null
  return {
    exerciseId,
    goals: goalActionsSelector(store, householdFinId),
    householdFinId,
    household,
    contacts: store.contact[householdFinId],
    honestConversation
  }
}

export default connect(mapStateToProps)(AddNewScore)
