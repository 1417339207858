import api from '../v3/helpers/api'
import {
  HouseholdPartialApiObject,
  AggregationProviderType
} from '../objects/household'
import { PlanningSoftwareType } from '../objects/financialGoal'

export const FETCH_INDIVIDUAL_HOUSEHOLD = 'FETCH_INDIVIDUAL_HOUSEHOLD'
export const FETCH_HOUSEHOLD_INSTITUTION = 'FETCH_HOUSEHOLD_INSTITUTION'
export const FETCH_HOUSEHOLD_OFFICE_TEAM = 'FETCH_HOUSEHOLD_OFFICE_TEAM'
export const FETCH_HOUSEHOLDS = 'FETCH_HOUSEHOLDS'
export const SET_ACTIVE_HONEST_CONVERSATION_MEETING =
  'SET_ACTIVE_HONEST_CONVERSATION_MEETING'
export const SET_ACTIVE_HONEST_CONVERSATION_EXERCISE =
  'SET_ACTIVE_HONEST_CONVERSATION_EXERCISE'
export const SET_ONLINE_PORTALS = 'SET_ONLINE_PORTALS'
export const SET_ACTIVE_INVESTMENT_PRIORITY = 'SET_ACTIVE_INVESTMENT_PRIORITY'
export const SET_ACTIVE_OFFICE_TEAM = 'SET_ACTIVE_OFFICE_TEAM'
export const SET_TARGET_ALLOCATION = 'SET_TARGET_ALLOCATION'
export const SET_PLANNING_SOFTWARE = 'SET_PLANNING_SOFTWARE'
export const SET_PLANNING_SOFTWARE_PENDING = 'SET_PLANNING_SOFTWARE_PENDING'
export const SET_PLANNING_SOFTWARE_REJECTED = 'SET_PLANNING_SOFTWARE_REJECTED'
export const SET_PLANNING_SOFTWARE_FULFILLED = 'SET_PLANNING_SOFTWARE_FULFILLED'
export const SET_BENCHMARK = 'SET_BENCHMARK'
export const UPDATE_EMONEY_FIELD = 'UPDATE_EMONEY_FIELD'
export const DELETE_HOUSEHOLDS = 'DELETE_HOUSEHOLDS'
export const SET_AGGREGATION_PROVIDER = 'SET_AGGREGATION_PROVIDER'
export const SET_HOUSEHOLD_INFORMATION = 'SET_HOUSEHOLD_INFORMATION'
export const TOGGLE_OPTIONAL_FEATURE = 'TOGGLE_OPTIONAL_FEATURE'
export const FETCH_OPPORTUNITIES = 'FETCH_OPPORTUNITIES'
export const getIndividualHousehold = (householdFinId: string) => {
  const requestInstitution = api().get(
    `/households/${householdFinId}/institution`
  )
  const requestIndividualHousehold = api().get(`/households/${householdFinId}`)
  return {
    type: FETCH_INDIVIDUAL_HOUSEHOLD,
    payload: Promise.all([requestInstitution, requestIndividualHousehold]).then(
      ([reqInstitution, reqHousehold]) => {
        if (reqInstitution.data) {
          reqHousehold.data = {
            ...reqHousehold.data,
            institution: { ...reqInstitution.data, householdFinId }
          }
        }
        return reqHousehold
      }
    )
  }
}

export const getOfficeTeamByHousehold = (householdFinId: string) => {
  return {
    type: FETCH_HOUSEHOLD_OFFICE_TEAM,
    payload: api()
      .get(`/households/${householdFinId}/teams`)
      .then((response) => {
        return {
          ...response,
          data: { ...response.data, householdFinId }
        }
      })
  }
}

export const getInstitutionByHousehold = (householdFinId: string) => {
  return {
    type: FETCH_HOUSEHOLD_INSTITUTION,
    payload: api()
      .get(`/households/${householdFinId}/institution`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const getHouseholds = () => {
  const fields: (keyof HouseholdPartialApiObject)[] = [
    'id',
    'crmId',
    'cxId',
    'name',
    'viewfinderProgress',
    'invresponseId',
    'palProgress',
    'goalsProgress',
    'netWorth',
    'hcProgress',
    'hcProgress2',
    'mmProgress',
    'taskCount',
    'fundedScores',
    'institutionId',
    'primaryContact',
    'secondaryContact',
    'hasClientAccountErrors',
    'aggregationProvider',
    'primaryMoneymind',
    'secondaryMoneymind',
    'officeTeams',
    'officeTeam'
  ]
  const URI = '/households?fields=' + fields.toString()

  return {
    type: FETCH_HOUSEHOLDS,
    payload: api()
      .get(URI)
      .then(
        (response: {
          data: HouseholdPartialApiObject[] & { meta?: any; errors?: any }
          status: number
        }) => {
          const { meta, errors } = response.data || {}
          const trueStatus = meta?.status ?? response.status
          if (trueStatus >= 400) {
            console.log('Household fetch error', errors)
            throw new Error('Unable to fetch households')
          }
          return response
        }
      )
  }
}

export const deleteHouseholds = (ids: string[]) => {
  const BATCH_SIZE = 50

  const paramsArray: string[] = []
  for (let i = 0; i < ids.length; i += BATCH_SIZE) {
    const list = ids.slice(i, i + BATCH_SIZE)
    paramsArray.push(list.join(',').replace(/\s+/g, ''))
  }
  const deletePromise = Promise.all(
    paramsArray.map(async (params) => {
      return api().delete(`/households?ids=${params}`)
    })
  )
  return {
    type: DELETE_HOUSEHOLDS,
    payload: deletePromise
  }
}

export const setActiveHonestConversationExercise = (
  householdFinId: string,
  exerciseId: any
) => {
  const requestBody = {
    hcExerciseId: exerciseId
  }
  return {
    type: SET_ACTIVE_HONEST_CONVERSATION_EXERCISE,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const setActiveHonestConversationMeeting = (
  householdFinId: string,
  meetingFinId: any
) => {
  const requestBody = {
    hcMeetingId: meetingFinId
  }
  return {
    type: SET_ACTIVE_HONEST_CONVERSATION_MEETING,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const setOnlinePortals = (
  householdFinId: string,
  onlinePortals: string
) => {
  const requestBody = {
    onlinePortal: onlinePortals
  }
  return {
    type: SET_ONLINE_PORTALS,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const setTargetAllocation = (
  householdFinId: string,
  targetCashAllocation: number,
  targetEquityAllocation: number,
  targetFixedAllocation: number,
  targetOtherAllocation: number
) => {
  const requestBody = {
    targetCashAllocation: targetCashAllocation || 0,
    targetEquityAllocation: targetEquityAllocation || 0,
    targetFixedAllocation: targetFixedAllocation || 0,
    targetOtherAllocation: targetOtherAllocation || 0
  }
  return {
    type: SET_TARGET_ALLOCATION,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const setActiveInvestmentPriority = (
  householdFinId: string,
  invresponseId: string
) => {
  const requestBody = {
    invresponseId
  }
  return {
    type: SET_ACTIVE_INVESTMENT_PRIORITY,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => response)
      .catch((err: Error) => console.log(err))
  }
}

export const setActiveOfficeTeam = (householdFinId: string, teamId: string) => {
  const requestBody = {
    officeTeam: teamId
  }
  return {
    type: SET_ACTIVE_OFFICE_TEAM,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

/**
 * This action will update the planningSoftware on the household object
 *
 * @param householdFinId id of household
 * @param planningSoftware string containing planningSoftware
 * @returns updated household object
 */
export const setPlanningSoftware = (
  householdFinId: string,
  planningSoftware: PlanningSoftwareType
) => {
  const requestBody = {
    planningSoftware
  }
  return {
    type: SET_PLANNING_SOFTWARE,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const setBenchmark = (householdFinId: string, benchmarkId: string) => {
  const requestBody = {
    benchmarkId
  }
  return {
    type: SET_BENCHMARK,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const updateEmoneyField = ({
  householdFinId,
  fieldValue,
  fieldName
}: {
  householdFinId: string
  fieldValue: string
  fieldName?: string
}) => {
  const requestBody = {
    data: [
      {
        fieldValue,
        fieldName: fieldName || 'id',
        source: 'emoney'
      }
    ]
  }

  return {
    type: UPDATE_EMONEY_FIELD,
    payload: api()
      .post(`/households/${householdFinId}/sources`, requestBody)
      .then((response) => {
        response.data = {
          fieldName,
          fieldValue,
          householdFinId
        }
        return response
      })
  }
}

/**
 * This action will update the aggregationProvider on the household object
 *
 * @param householdFinId id of household
 * @param aggregationProvider string containing aggregationProvider
 * @returns updated household object
 */
export const setAggregationProvider = (
  householdFinId: string,
  aggregationProvider: AggregationProviderType
) => {
  const requestBody = {
    aggregationProvider
  }
  return {
    type: SET_AGGREGATION_PROVIDER,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

/**
 * This action will update the household information
 *
 * @param householdFinId id of household
 * @param householdInformation obj containing optional fields aggregationProvider, planningSoftware
 * @returns updated household object
 */
export const setHouseholdInformation = (
  householdFinId: string,
  householdInformation: {
    disableManualAccount?: boolean
    aggregationProvider?: AggregationProviderType
    planningSoftware?: PlanningSoftwareType
  }
) => {
  const requestBody = {
    ...householdInformation
  }
  return {
    type: SET_HOUSEHOLD_INFORMATION,
    payload: api()
      .put(`/households/${householdFinId}`, requestBody)
      .then((response) => {
        return response
      })
  }
}

export const toggleOptionalFeature = (
  householdFinId: string,
  feature: {
    [key in
    | 'showInvestments'
    | 'showHonestConversations'
    | 'showEmoneyClientPortal'
    | 'showAllStrategies']?: boolean
  }
) => {
  return {
    type: TOGGLE_OPTIONAL_FEATURE,
    payload: api()
      .put(`/households/${householdFinId}`, feature)
      .then((response) => {
        return response
      })
  }
}

export const getOpportunities = (householdFinId: string) => {
  return {
    type: FETCH_OPPORTUNITIES,
    payload: api()
      .get(`/households/${householdFinId}/opportunities`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}
