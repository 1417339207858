import React from 'react'
import { connect, Dispatch } from 'react-redux'
import * as alertMessageActions from '../../actions/showAlert'
import { iconType } from '../../objects/timedLoader'
import { GlobalState } from '../../reducers'
import { Loader } from './loader/loader'

interface AlertMessageProps {
  dispatch: Dispatch<GlobalState>
  stacked: boolean
  icon?: iconType
  title?: string
  message?: string
  showAlert: boolean
  closeFunc?(): void
  bottomBtn?: string
  bottomBtnFunc?(): void
}

interface AlertMessageState {
  processing: boolean
  refreshTimer: any
  displaySync: boolean
}

class AlertMessageToast extends React.Component<
  AlertMessageProps,
  AlertMessageState
> {
  constructor(props: AlertMessageProps) {
    super(props)
    this.state = {
      processing: false,
      refreshTimer: null,
      displaySync: true
    }
  }

  public syncTimer = () => {
    const { refreshTimer } = this.state
    const timerCountDown = setTimeout(async () => {
      clearTimeout(refreshTimer)
      await this.setState({
        refreshTimer: null,
        processing: false
      })
      this.closeFunc()
    }, 5000)
    this.setState({
      refreshTimer: timerCountDown
    })
  }

  public dispatchAlertAction = () => {
    const { showAlert } = this.props
    this.setState({ processing: showAlert })
    this.syncTimer()
  }

  public closeFunc = () => {
    const { dispatch } = this.props
    dispatch(alertMessageActions.hideSyncModal())
  }
  public hideSync = () => {
    this.setState({ displaySync: false })
  }
  public componentDidUpdate(nextProps: any, prevProps: any) {
    const { showAlert } = this.props
    const { processing } = this.state

    if (!prevProps.refreshTimer && showAlert && !processing) {
      this.dispatchAlertAction()
    }
  }

  public componentDidMount() {
    this.dispatchAlertAction()
  }

  public render() {
    const {
      stacked,
      message,
      icon,
      title,
      showAlert,
      bottomBtn,
      bottomBtnFunc
    } = this.props
    const { refreshTimer, displaySync } = this.state

    return showAlert || (!showAlert && refreshTimer) ? (
      <div style={{ display: displaySync ? 'block' : 'none' }}>
        <Loader
          closeFunc={this.hideSync}
          stacked={stacked}
          iconType={icon}
          loadingType={title}>
          {message}
          {bottomBtn && (
            <div
              className='loader__syncloader-bottom-btn'
              onClick={bottomBtnFunc}>
              <a>{bottomBtn}</a>
            </div>
          )}
        </Loader>
      </div>
    ) : null
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    showAlert: store.showAlert.show
  }
}

export default connect(mapStateToProps)(AlertMessageToast)
