import React, { Fragment } from 'react'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/protection.svg'
import { ReactComponent as CommitmentIcon } from '../../assets/images/icons/commitment.svg'
import { ReactComponent as HappinessIcon } from '../../assets/images/icons/happiness.svg'
import Button from '../../components/button'

interface MoneyMindPlaceholderProps {
  contact: any
  type: string
  emailModal(type: string): void
}

export class MoneyMindPlaceholder extends React.Component<
  MoneyMindPlaceholderProps
> {
  constructor(props: MoneyMindPlaceholderProps) {
    super(props)
  }

  public handleSend = () => {
    this.props.emailModal(this.props.type)
  }

  public renderGraph(Icon: any, title: string, color: string) {
    const graph = []
    for (let row = 0; row < 10; row = row + 1) {
      const graphRow = []
      for (let column = 0; column < 10; column = column + 1) {
        const position = row * 10 + column
        const graphCell = (
          <div
            key={'position-' + row * 10 + column}
            className='moneymind__graphic-cell'>
            <div
              className='moneymind__graphic-icon moneymind__graphic-placeholder'
              key={`cell ${position}`}
            />
          </div>
        )
        graphRow.push(graphCell)
      }
      graph.push(
        <div key={`row ${row}`} className='moneymind__graphic-row'>
          {graphRow}
        </div>
      )
    }
    return (
      <div className='moneymind__graphic'>
        <div className='moneymind__graphic-perctage'>
          <span style={{ backgroundColor: color, fontSize: 19 }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>
        <div>{graph}</div>
        <div className='moneymind__graphic-label' style={{ color }}>
          {Icon ? <Icon /> : null}
          {title}
        </div>
      </div>
    )
  }

  public render() {
    return (
      <Fragment>
        <div className='moneymind__graphic-bg'>
          <div className='moneymind__results-name'>
            {this.props.contact.firstName}'s MoneyMind&reg;
          </div>
          <div className='moneymind__graphic-w'>
            {this.renderGraph(CommitmentIcon, 'Commitment', '#0068ae')}
            {this.renderGraph(ProtectionIcon, 'Protection', '#a2d134')}
            {this.renderGraph(HappinessIcon, 'Happiness', '#f49500')}
          </div>
        </div>
        <div style={{ position: 'relative', top: -125, left: '20% ' }}>
          <div className='moneymind-modal__btn-w'>
            <Button primary={true} onClick={this.handleSend}>
              <span className='moneymind-modal__btn-text'>
                Send {this.props.contact.firstName}'s MoneyMind&reg;
              </span>
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }
}
