import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import {
  FinancialGoalActionObj,
  GoalRequest,
  GoalResponse,
  addNoteTemplateObj,
  GoalTemplatesResponse
} from '../objects/financialGoalActions'
import { CardObj } from '../objects/HonestConversations/card'

export const FETCH_GOAL_ACTIONS = 'FETCH_GOAL_ACTIONS'
export const CREATE_GOAL_ACTIONS = 'CREATE_GOAL_ACTIONS'
export const DELETE_GOAL_ACTIONS = 'DELETE_GOAL_ACTIONS'
export const EDIT_GOAL_ACTIONS = 'EDIT_GOAL_ACTIONS'
export const FETCH_GOALS_TEMPLATES = 'FETCH_GOALS_TEMPLATES'
export const SELECTED_GOALID_ACTIONS = 'SELECTED_GOALID_ACTIONS'
export const FETCH_GOAL_ACTIONS_BY_ID = 'FETCH_GOAL_ACTIONS_BY_ID'
export const UPDATE_GOAL_ACTIONS_BY_ID = 'UPDATE_GOAL_ACTIONS_BY_ID'
export const UPDATE_GOAL_ACTION_CARD_BY_ID = 'UPDATE_GOAL_ACTION_CARD_BY_ID'
export const SELECTED_SUGGESTED_GOALID = 'SELECTED_SUGGESTED_GOALID'
export const ADD_NOTE_TEMPLATE = 'ADD_NOTE_TEMPLATE'
export const TOGGLE_SIDEBAR_STATE = 'TOGGLE_SIDEBAR_STATE'

/**
 * HELPER FUNCTION
 * @param goal: Goal Action data to send to service
 * @param householdFinId: The householdId to map the data for the request
 */
export const getGoalRequestBody = (
  goal: FinancialGoalActionObj,
  householdFinId: string
): GoalRequest => {
  return {
    assigneePrimaryId: goal.assigneePrimaryId,
    assigneeSecondaryId: goal.assigneeSecondaryId,
    householdId: householdFinId,
    status: goal.status,
    name: goal.name,
    note: goal.note,
    type: goal.type,
    cardIds: (goal.cards || []).map((card: any) =>
      card.hcCard ? card?.hcCard?.id : card.id
    ),
    dueDate: goal.dueDate,
    inGuidecenter: goal.inGuidecenter,
    palItemId: goal.palItemId,
    archived: goal.archived,
    customGoal: goal.customGoal
  }
}

/**
 * FETCH GOALS ACTIONS
 * @param householdFinId: The householdId to get the goals data for the id
 */
export const getGoalActions = (householdFinId: string) => {
  return {
    type: FETCH_GOAL_ACTIONS,
    payload: api()
      .get(`/households/${householdFinId}/goalactions`)
      .then((response: AxiosResponse<GoalResponse>) => {
        response.data.householdId = householdFinId
        return response
      })
  }
}

/**
 * FETCH SINGLE GOALS ACTION DATA BY ID
 * @param householdFinId: The householdId to get the goals data for the id
 */
export const getGoalActionData = (goalId: string, householdFinId: string) => {
  return {
    type: FETCH_GOAL_ACTIONS_BY_ID,
    payload: api()
      .get(`/households/${householdFinId}/goalactions/${goalId}`)
      .then((response: AxiosResponse<GoalResponse>) => {
        response.data.householdId = householdFinId
        return response
      })
  }
}

/**
 * UPDATE SINGLE GOALS ACTION SINGLE CARD DATA BY ID
 * @param householdFinId: The householdId to get the goals data for the id
 * @param goalId
 * @param cardId
 */
export const updateGoalActionHCCard = async (
  goalId: string,
  householdFinId: string,
  cardId: string,
  reqBody: any
) => {
  return {
    type: UPDATE_GOAL_ACTION_CARD_BY_ID,
    payload: await api()
      .put(
        `/households/${householdFinId}/goalactions/${goalId}/cards/${cardId}`,
        reqBody
      )
      .then((response: AxiosResponse<GoalResponse>) => {
        response.data.householdId = householdFinId
        return response
      })
  }
}

/**
 * NEW ADD GOAL RECORD
 * @param goal: new goal data to create a record
 * @param householdFinId: The householdId to set the data for the id
 */
export const addNewGoalActions = (
  goal: FinancialGoalActionObj,
  householdFinId: string
) => {
  return {
    type: CREATE_GOAL_ACTIONS,
    payload: {
      data: { ...goal, ...{ householdId: householdFinId } }
    }
  }
}

/**
 * NEW ADD GOAL RECORD
 * @param goal: new goal data to create a record
 * @param householdFinId: The householdId to set the data for the id
 */
export const addGoalActions = (
  goal: FinancialGoalActionObj,
  householdFinId: string,
  cardsToUpdate?: CardObj[]
) => {
  const requestBody = getGoalRequestBody(goal, householdFinId)
  return {
    type: CREATE_GOAL_ACTIONS,
    payload: api()
      .post(`/households/${householdFinId}/goalactions`, requestBody)
      .then(async (response: AxiosResponse<GoalResponse>) => {
        response.data.householdId = householdFinId
        if (cardsToUpdate?.length) {
          await Promise.all(
            cardsToUpdate.map(async (card: CardObj) => {
              await updateGoalActionHCCard(
                response.data.id,
                householdFinId,
                card.id,
                { showCard: card.showCard }
              )
            })
          )
            .then(() => {
              cardsToUpdate?.forEach((card: CardObj) => {
                response.data?.cards.forEach((resCard: CardObj) => {
                  if (card.id === resCard.id) {
                    resCard.showCard = card.showCard
                  }
                })
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
        return response
      })
  }
}

/**
 * EDIT GOAL RECORD
 * @param goal : goals new data for to edit/update the goals
 * @param householdFinId : The householdfinid to edit/update the data for this id
 */
export const editGoalActions = (
  goal: FinancialGoalActionObj,
  householdFinId: string,
  cardsToUpdate?: CardObj[]
) => {
  const requestBody: GoalRequest = getGoalRequestBody(goal, householdFinId)
  return {
    type: EDIT_GOAL_ACTIONS,
    payload: api()
      .put(`/households/${householdFinId}/goalactions/${goal.id}`, requestBody)
      .then(async (response: AxiosResponse<GoalResponse>) => {
        response.data.householdId = householdFinId
        // if cardsToUpdate exist update them
        if (cardsToUpdate?.length) {
          await Promise.all(
            cardsToUpdate.map(async (card: CardObj) => {
              await updateGoalActionHCCard(
                response.data.id,
                householdFinId,
                card.id,
                { showCard: card.showCard }
              )
            })
          )
            .then(() => {
              cardsToUpdate?.forEach((card: CardObj) => {
                response.data?.cards.forEach((resCard: CardObj) => {
                  if (card.id === resCard.id) {
                    resCard.showCard = card.showCard
                  }
                })
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
        return response
      })
  }
}

/**
 * EDIT GOAL RECORD BY ID
 * @param goal : goals new data for to edit/update the goals
 */
export const updateGoalActionById = (
  goal: FinancialGoalActionObj,
  householdFinId: string
) => {
  return {
    type: UPDATE_GOAL_ACTIONS_BY_ID,
    payload: {
      data: { ...goal, ...{ householdId: householdFinId } }
    }
  }
}

/**
 * DELETE GOAL RECORD
 * @param goal : goals new data for to edit/update the goals
 * @param householdFinId : The householdfinid to edit/update the data for this id
 */
export const deleteGoalActions = (
  goal: FinancialGoalActionObj,
  householdFinId: string
) => {
  return {
    type: DELETE_GOAL_ACTIONS,
    payload: api()
      .delete(`/households/${householdFinId}/goalactions/${goal.id}`)
      .then((response: AxiosResponse<GoalResponse>) => {
        return {
          data: {
            goal, // AN ARRAY OF OBJECT WILL RECIEVE IN RESPONSE
            householdId: householdFinId
          },
          status: response.status
        }
        // return response
      })
  }
}
/**
 * SET SELECTED GOALID
 * @param goalId : selected goal id
 * @param householdFinId : The householdfinid to edit/update the data for this id
 */
export const setGoalIdActions = ({
  goalId,
  householdFinId,
  selectedCardId,
  shouldResetSideBar
}: {
  goalId: null | string
  householdFinId: string
  selectedCardId?: string
  shouldResetSideBar?: boolean
}) => {
  return {
    type: SELECTED_GOALID_ACTIONS,
    payload: {
      data: {
        goalId, // AN ARRAY OF OBJECT WILL RECIEVE IN RESPONSE
        householdId: householdFinId,
        selectedCardId,
        shouldResetSideBar
      }
    }
  }
}

/**
 * FETCH GOAL TEMPLATES
 * @param householdFinId : The householdId to get the data for the goals template
 */
export const getFinancialGoalsTemplates = (householdFinId: string) => {
  return {
    type: FETCH_GOALS_TEMPLATES,
    payload: api()
      .get(`/households/${householdFinId}/goaltemplates`)
      .then((response: AxiosResponse<GoalTemplatesResponse>) => {
        response.data.householdId = householdFinId
        return response
      })
  }
}

/**
 * FETCH GOAL TEMPLATES
 * @param householdFinId : The householdId to get the data for the goals template
 */
export const setSuggestedGoal = (data: any) => {
  return {
    type: SELECTED_SUGGESTED_GOALID,
    payload: data
  }
}

/**
 *
 * @param householdFinId: The householdId to set the data for the goals template
 * @param priorityActionId: The householdId to get the data for the goals template
 * @param reqBody
 */
export const addNoteTemplate = (
  householdFinId: string,
  priorityActionId: string,
  reqBody: addNoteTemplateObj
) => {
  return {
    type: ADD_NOTE_TEMPLATE,
    payload: api()
      .post(`/households/${householdFinId}/palnotetemplates`, reqBody)
      .then((response) => {
        response.data.householdId = householdFinId
        response.data.priorityActionId = priorityActionId
        return response
      })
  }
}

/**
 * FETCH GOAL TEMPLATES
 * @param householdFinId : The householdId to get the data for the goals template
 */
export const updateSideBarState = (data: {
  sideBarState: boolean
  householdId: string
}) => {
  return {
    type: TOGGLE_SIDEBAR_STATE,
    payload: { data }
  }
}
