import { combineReducers } from 'redux'
import { routerReducer, RouterState } from 'react-router-redux'
import clientAccount, { ClientAccountState } from './clientAccounts'
import user from './user'
import { UserInterface } from '../objects/user'
import contact, { ContactState } from './contacts'
import honestConversations, {
  HonestConversationsState
} from './honestConversations'
import hcExercises, { HonestConversationExercisesState } from './hcExercises'
import hcExerciseCards from './hcExerciseCards'
import { CardOptionsInterface } from '../objects/HonestConversations/card'
import investments, { InvestmentsState } from './investments'
import moneymind, { MoneyMindState } from './moneymind'
import priorityActions, { PriorityActionsState } from './priorityActions'
import documentVault, { DocumentVaultState } from './documentVault'
import toasts, { ToastState } from './toasts'
import showSync, { SyncDisplayState } from './syncDisplay'
import insurances, { InsuranceState } from './insurance'
import * as userActions from '../actions/user'
import households, { HouseholdsState } from './households'
import financialGoals, { FinancialGoalsState } from './financialGoals'
import goalActions, { GoalsState } from './financialGoalActions'
import globalSettings, { GlobalSettingsState } from './globalSettings'
import goalRequests, { GoalRequestObjInterface } from './goalRequests'
import strategies, { StrategiesState } from './strategies'
import accountMap, { AccountMapState } from './accountMap'
import portfolioBuilder, { PortfolioBuilderState } from './portfolioBuilder'
import { InstitutionObj } from '../objects/institution'
import institution from './institution'
import clientFilter from './clientFilter'
import taskFilter from './taskFilter'
import householdPreferences, {
  HouseholdPreferencesState
} from './householdPreferences'
import userPreferences, { UserPreferencesState } from './userPreferences'
import { OfficeTeamsInterface } from '../objects/officeTeams'
import officeTeams from './officeTeams'
import tasks, { TasksState } from './tasks'
import investmentViewfinder, {
  InvestmentViewfinderState
} from './investmentViewfinder'
import marketplaceOffers from './marketplaceOffers'
import { marketplaceOfferRequestInterface } from '../objects/marketplaceOffers'
import loader, { LoaderState } from './loader'
import meetingMode, { MeetingModeState } from './meetingMode'
import guidebook from './guidebook'
import { GuidebookInterface } from '../objects/guidebook'
import portfolioProposal from './portfolioProposal'
import { PortfolioProposalInterface } from '../objects/portfolioProposal'
import { ClientFilterObj } from '../objects/clientFilter'
import { TaskFilterObj } from '../objects/taskFilter'
import lifeActions from './lifeActions'
import { LifeActionsInterface } from '../objects/lifeActions'
import strategySearch from './strategySearch'
import { StrategySearchState } from '../objects/strategySearch'
import positions, { PositionsState } from './positions'
import timedLoader from './timedLoader'
import { TimedLoaderState } from '../objects/timedLoader'
import { MessagesInterface } from '../objects/messages'
import messages from './messages'
import showAlert, { ShowAlertState } from './showAlert'
import loginHistory, { LoginHistoryState } from './loginHistory'
import opportunities, { OpportunitiesState } from './opportunities'
import settingsContactInfoLoader, {
  SettingsContactInfoLoaderState
} from './settingsContactInfoLoader'
import crmTasks from './crmTasks'

const appReducer = combineReducers<GlobalState>({
  clientAccount,
  user,
  contact,
  honestConversations,
  investments,
  marketplaceOffers,
  moneymind,
  priorityActions,
  documentVault,
  toasts,
  households,
  insurances,
  goalActions,
  financialGoals,
  goalRequests,
  routerReducer,
  strategies,
  accountMap,
  portfolioBuilder,
  institution,
  clientFilter,
  taskFilter,
  officeTeams,
  tasks,
  investmentViewfinder,
  loader,
  guidebook,
  portfolioProposal,
  meetingMode,
  householdPreferences,
  userPreferences,
  showSync,
  showAlert,
  loginHistory,
  hcExercises,
  hcExerciseCards,
  lifeActions,
  strategySearch,
  globalSettings,
  positions,
  timedLoader,
  messages,
  opportunities,
  settingsContactInfoLoader,
  crmTasks
})

export interface GlobalState {
  clientAccount: ClientAccountState
  user: UserInterface
  contact: ContactState
  honestConversations: HonestConversationsState
  investments: InvestmentsState
  marketplaceOffers: marketplaceOfferRequestInterface
  moneymind: MoneyMindState
  priorityActions: PriorityActionsState
  documentVault: DocumentVaultState
  toasts: ToastState
  households: HouseholdsState
  insurances: InsuranceState
  goalActions: GoalsState
  financialGoals: FinancialGoalsState
  goalRequests: GoalRequestObjInterface
  portfolioBuilder: PortfolioBuilderState
  routerReducer: RouterState
  strategies: StrategiesState
  accountMap: AccountMapState
  institution: InstitutionObj
  clientFilter: ClientFilterObj
  taskFilter: TaskFilterObj
  officeTeams: OfficeTeamsInterface
  tasks: TasksState
  investmentViewfinder: InvestmentViewfinderState
  loader: LoaderState
  meetingMode: MeetingModeState
  guidebook: GuidebookInterface
  portfolioProposal: PortfolioProposalInterface
  householdPreferences: HouseholdPreferencesState
  userPreferences: UserPreferencesState
  showSync: SyncDisplayState
  showAlert: ShowAlertState
  loginHistory: LoginHistoryState
  hcExercises: HonestConversationExercisesState
  hcExerciseCards: CardOptionsInterface
  lifeActions: LifeActionsInterface
  strategySearch: StrategySearchState
  globalSettings: GlobalSettingsState
  positions: PositionsState
  timedLoader: TimedLoaderState

  messages: MessagesInterface
  opportunities: OpportunitiesState
  settingsContactInfoLoader: SettingsContactInfoLoaderState
  crmTasks: TasksState
}

const rootReducer = (state: GlobalState, action: any) => {
  if (action.type === userActions.LOGOUT_USER) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
