import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'

import Tile from '../../components/layout/tile'
import TableHeader from '../../components/layout/tableHeader'
import BackToLink from '../../components/layout/headerBackToLink'

import InvestmentHistoryRow from './investmentHistoryRow'
import InnerContainer from '../../components/layout/innerContainer'
import * as viewfinderActions from '../../../actions/investmentViewfinder'
import { HouseholdObj } from '../../../objects/household'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderExerciseObj,
  InvestmentViewfinderInterface
} from '../../../objects/investmentViewfinder'
// import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg';

interface InvestmentPorps {
  dispatch?: Dispatch<GlobalState>
  viewfinderExercises: InvestmentViewfinderInterface
  household: HouseholdObj
  householdFinId: string
}

export class InvestmentViewfinderHistory extends Component<InvestmentPorps> {
  public componentDidMount() {
    const { householdFinId } = this.props
    this.props.dispatch(
      viewfinderActions.getViewfinderExercises(householdFinId)
    )
  }

  public investmentsHistoryRender = (
    viewfinderExercises: InvestmentViewfinderInterface
  ) => {
    const { householdFinId, household } = this.props
    return Object.keys(viewfinderExercises).map(
      (key: string, index: number) => {
        const viewfinderExercise: InvestmentViewfinderExerciseObj =
          viewfinderExercises[key]
        return (
          <InvestmentHistoryRow
            key={key}
            householdFinId={householdFinId}
            household={household}
            viewfinderExercise={viewfinderExercise}
            index={index}
          />
        )
      }
    )
  }

  public render() {
    const { householdFinId, viewfinderExercises } = this.props
    return (
      <InnerContainer>
        <BackToLink to={`/households/${householdFinId}/investments`}>
          Back to Investments
        </BackToLink>
        <Tile>
          <TableHeader>
            <span className='inv-history__results-name'>NAME</span>
            <span className='inv-history__results-results'>RESULTS</span>
            <span className='inv-history__results-updated inv-history__results-updated-label'>
              CREATED DATE
            </span>
          </TableHeader>
          {viewfinderExercises &&
            this.investmentsHistoryRender(viewfinderExercises)}
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    viewfinderExercises:
      store.investmentViewfinder.exercises[match.params.householdFinId],
    household: store.households[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(InvestmentViewfinderHistory))
