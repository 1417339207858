import React from 'react'
import NavLink from '../../components/navLink'

import dashboardIcon from '../../assets/images/icons/householdNav/dashboard.png'
import moneymindIcon from '../../assets/images/icons/householdNav/moneymind.png'
import honestconversationsIcon from '../../assets/images/icons/householdNav/honestconversations.png'
import networthIcon from '../../assets/images/icons/householdNav/networth.png'
import investmentsIcon from '../../assets/images/icons/householdNav/investments.png'
import insuranceIcon from '../../assets/images/icons/householdNav/insurance.png'
import goalsIcon from '../../assets/images/icons/householdNav/goals.png'
import palIcon from '../../assets/images/icons/householdNav/pal.png'
import vaultIcon from '../../assets/images/icons/householdNav/vault.png'
import tasksIcon from '../../assets/images/icons/householdNav/tasks.png'
import messagesIcon from '../../assets/images/icons/householdNav/messages.png'
import GuideBookIcon from '../../assets/images/icons/householdNav/guidebook.png'
import ResourcesIcon from '../../assets/images/icons/householdNav/resources.png'

// Active
import dashboardIconActive from '../../assets/images/icons/householdNav/active_dashboard.png'
import moneymindIconActive from '../../assets/images/icons/householdNav/active_moneymind.png'
import honestconversationsIconActive from '../../assets/images/icons/householdNav/active_honestconversations.png'
import networthIconActive from '../../assets/images/icons/householdNav/active_networth.png'
import investmentsIconActive from '../../assets/images/icons/householdNav/active_investments.png'
import insuranceIconActive from '../../assets/images/icons/householdNav/active_insurance.png'
import goalsIconActive from '../../assets/images/icons/householdNav/active_goals.png'
import palIconActive from '../../assets/images/icons/householdNav/active_pal.png'
import vaultIconActive from '../../assets/images/icons/householdNav/active_vault.png'
import tasksIconActive from '../../assets/images/icons/householdNav/active_tasks.png'
import messagesIconActive from '../../assets/images/icons/householdNav/active_messages.png'
import guidebookiconActive from '../../assets/images/icons/householdNav/active_guidebook.png'
import ResourcesIconActive from '../../assets/images/icons/householdNav/active_resources.png'

interface NavigationMenuProps {
  url: string
  collapsed: boolean
  disableDocumentVault: boolean
}

class NavigationMenu extends React.Component<NavigationMenuProps> {
  public render() {
    const { url, collapsed, disableDocumentVault } = this.props
    const navClass = collapsed
      ? 'nav-menu nav-menu--collapsed'
      : 'nav-menu nav-menu--open'
    return (
      <div className={navClass}>
        <NavLink
          icon={dashboardIcon}
          iconActive={dashboardIconActive}
          to={url + '/dashboard'}
          title='Dashboard'
        />
        <NavLink
          icon={moneymindIcon}
          iconActive={moneymindIconActive}
          to={url + '/moneyMind'}
          title='MoneyMind&reg;'
        />
        <NavLink
          icon={honestconversationsIcon}
          iconActive={honestconversationsIconActive}
          to={url + '/honestConversations'}
          title='HonestConversations&reg;'
        />
        <NavLink
          icon={networthIcon}
          iconActive={networthIconActive}
          to={url + '/netWorth'}
          title='Net Worth'
        />
        <NavLink
          icon={investmentsIcon}
          iconActive={investmentsIconActive}
          to={url + '/investments'}
          title='Investments'
        />
        <NavLink
          icon={insuranceIcon}
          iconActive={insuranceIconActive}
          to={url + '/insurance'}
          title='Insurance'
        />
        <NavLink
          icon={goalsIcon}
          iconActive={goalsIconActive}
          to={url + '/financialGoals'}
          title='Financial Plan'
        />
        <NavLink
          icon={palIcon}
          iconActive={palIconActive}
          to={url + '/priorityActions'}
          title='Goals'
        />
        <NavLink
          icon={ResourcesIcon}
          iconActive={ResourcesIconActive}
          to={url + '/resources'}
          title='Resources'
        />
        <NavLink
          icon={tasksIcon}
          iconActive={tasksIconActive}
          to={url + '/tasks'}
          title='Tasks'
        />
        {!disableDocumentVault ? (
          <NavLink
            icon={vaultIcon}
            iconActive={vaultIconActive}
            to={url + '/documentVault'}
            title='Document Vault'
          />
        ) : null}
        <NavLink
          icon={messagesIcon}
          iconActive={messagesIconActive}
          to={url + '/messages'}
          title='Messages'
        />
        <NavLink
          icon={GuideBookIcon}
          iconActive={guidebookiconActive}
          to={url + '/Guidebook'}
          title='Guidebook'
        />
      </div>
    )
  }
}

export default NavigationMenu
