export const SHOW_SYNC_MODAL: string = 'SHOW_SYNC_MODAL'
export const HIDE_SYNC_MODAL: string = 'HIDE_SYNC_MODAL'

export const showSyncModal = () => {
  return {
    type: SHOW_SYNC_MODAL,
    payload: true
  }
}

export const hideSyncModal = () => {
  return {
    type: HIDE_SYNC_MODAL,
    payload: false
  }
}
