import { ymmQuizIsNewVersion } from './ymmQuizIsNewVersion'

/**
 * Returns proper param name (token/cid) for YMM version(new/old)
 *
 * @returns {string} Query param name used to identify the client.
 */
export const ymmQuizParamName = (): string => {
  return ymmQuizIsNewVersion() ? 'token' : 'cid'
}
