import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

import { GlobalState } from '../../../../reducers'
import { deleteFinancialGoal } from '../../../../actions/financialGoals'
import { updateGoalRequest } from '../../../../actions/goalRequests'

import Modal from '../../../components/layout/modal'
import Button from '../../../components/button'

import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'

interface DeleteGoalModalProps {
  householdFinId: string
  userId: string
  goal: any
  dispatch: Dispatch<GlobalState>
  closeModal(): void
}

class DeleteGoalModal extends Component<DeleteGoalModalProps> {
  public deleteGoal = async () => {
    const { goal, householdFinId, closeModal, dispatch, userId } = this.props
    if (goal?.requestType === 'CREATE') {
      const completeRequest = {
        ...goal,
        status: 'COMPLETED',
        reviewedBy: userId
      }
      await dispatch(
        updateGoalRequest(householdFinId, completeRequest.id, completeRequest)
      )
    } else if (goal?.goalRequest?.requestType === 'EDIT') {
      const completeRequest = {
        ...goal.goalRequest,
        status: 'COMPLETED',
        reviewedBy: userId
      }
      await dispatch(
        updateGoalRequest(householdFinId, completeRequest.id, completeRequest)
      )
    } else {
      await dispatch(deleteFinancialGoal(goal.id, householdFinId))
    }
    closeModal()
  }

  public render() {
    const { closeModal } = this.props
    return (
      <Modal
        icon={DeleteIcon}
        title='Delete Plan Input'
        closeModal={closeModal}>
        <div className='modal__content'>
          Are you sure you want to delete this plan input?
          <div className='modal__buttons'>
            <div>
              <Button clear={true} onClick={closeModal}>
                Cancel
              </Button>
              <Button primary={true} onClick={this.deleteGoal}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default DeleteGoalModal
