import React, { Component } from 'react'

import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { GlobalState } from '../../../reducers'

import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import CardWrapper from './display/cardWrapper'
import {
  HonestConversationsExerciseObj,
  HouseholdCardObj,
  CardScoreObj,
  CardScoreInterface
} from '../../../objects/honestConversations'
import { ContactsInterface } from '../../../objects/contact'
import {
  sortHouseholdCards,
  getSharedPrioritiesCardStyles
} from '../../helpers/honestConversations'

export interface SharedPriorityCardsProps {
  contacts: ContactsInterface
  honestConversation: HonestConversationsExerciseObj
  householdFinId: string
  selectedCard: HouseholdCardObj
  selectCard(householdCard: HouseholdCardObj): void
}

interface SharedPriorityCardsState {
  cardRowWidth: number
}

// maybe individual or combined
export class SharedPriorityCards extends Component<
  SharedPriorityCardsProps,
  SharedPriorityCardsState
> {
  constructor(props: SharedPriorityCardsProps) {
    super(props)
    this.state = {
      cardRowWidth: null
    }
  }

  public onResize = (width: number) => {
    this.setState({ cardRowWidth: width })
  }

  public cardClick = (householdCard: HouseholdCardObj) => {
    const { selectCard } = this.props
    selectCard(householdCard)
  }

  public getContactCardScores = (
    contactId: string,
    scoringSessionId: string
  ): CardScoreInterface => {
    const { honestConversation } = this.props

    if (
      scoringSessionId &&
      Object.keys(honestConversation.clientScoringSessions).length > 0
    ) {
      if (!contactId || !honestConversation.clientScoringSessions[contactId]) {
        return null
      }

      return honestConversation.clientScoringSessions[contactId][
        scoringSessionId
      ]?.cardScores
    }

    return null
  }

  public cardFactory = () => {
    const { honestConversation, selectedCard, contacts } = this.props
    const { cardRowWidth } = this.state
    const { householdCards } = honestConversation
    if (!householdCards || !contacts) {
      return null
    }

    let secondaryCardScores: CardScoreInterface = null
    if (contacts.secondary) {
      secondaryCardScores = this.getContactCardScores(
        contacts.secondary?.id,
        honestConversation.activeScoringSessionSecondary
      )
    }
    const primaryCardScores: CardScoreInterface = this.getContactCardScores(
      contacts.primary?.id,
      honestConversation.activeScoringSessionPrimary
    )

    const sortedHouseholdCards = sortHouseholdCards(householdCards)

    return sortedHouseholdCards.map((householdCard, index: number) => {
      if (!householdCard) {
        return null
      }
      const { category, title } = householdCard && householdCard.card
      let primaryCardScore: CardScoreObj

      primaryCardScores &&
        Object.keys(primaryCardScores).map((scorekey) => {
          if (primaryCardScores[scorekey]?.householdCard) {
            if (
              householdCard.id === primaryCardScores[scorekey].householdCard.id
            ) {
              primaryCardScore = primaryCardScores[scorekey]
            }
          }
        })

      let secondaryCardScore: CardScoreObj
      secondaryCardScores &&
        Object.keys(secondaryCardScores).forEach((scorekey) => {
          if (secondaryCardScores[scorekey]?.householdCard) {
            if (
              secondaryCardScores[scorekey].householdCard.id ===
              householdCard.id
            ) {
              secondaryCardScore = secondaryCardScores[scorekey]
            }
          }
        })

      // need true key name
      const active = selectedCard.id === householdCard.id
      return (
        <CardWrapper
          key={index}
          contacts={contacts}
          primaryScore={primaryCardScore && primaryCardScore.score}
          secondaryScore={secondaryCardScore && secondaryCardScore.score}
          onClick={this.cardClick}
          householdCard={householdCard}
          active={active}>
          <Card
            category={category}
            title={title}
            cardStyle={getSharedPrioritiesCardStyles(cardRowWidth, category)}
          />
        </CardWrapper>
      )
    })
  }

  public render() {
    return (
      <ReactResizeDetector handleWidth={true} onResize={this.onResize}>
        <div
          className='hc-components__row'
          style={{ borderBottom: '1px solid #D2D2D2' }}>
          {this.cardFactory()}
        </div>
      </ReactResizeDetector>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, honestConversation, contacts }: any
) => {
  return {
    householdFinId,
    honestConversations: store.hcExercises[householdFinId],
    honestConversation,
    contacts
  }
}

export default connect(mapStateToProps)(SharedPriorityCards)
