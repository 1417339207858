import * as actions from '../actions/officeTeams'
import {
  OfficeTeamsInterface,
  OfficeTeamMemberObj
} from '../objects/officeTeams'
import { UsersObj, UserObj } from '../objects/user'

const initState: OfficeTeamsInterface = {}

const mapOfficeTeam = (officeTeam: any) => {
  if (!officeTeam) {
    return null
  }

  return {
    id: officeTeam.id,
    name: officeTeam.name,
    members: officeTeam.members,
    planningSoftwareUserId: officeTeam.planningSoftwareUserId
  }
}

const mapOfficeTeams = (officeTeams: any) => {
  const obj = {}
  officeTeams &&
    officeTeams.forEach((officeTeam: any) => {
      const team = mapOfficeTeam(officeTeam)
      if (team) {
        obj[officeTeam.id] = mapOfficeTeam(officeTeam)
      }
    })
  return obj
}

const mapUser = (user: any): UserObj => {
  if (user) {
    return {
      id: user.id,
      mgpId: user.mgpId,
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      photo: user.photoUrl ? user.photoUrl : '',
      fileName: user.photoFilename ? user.photoFilename : '',
      email: user.email,
      mobile: user.phone ? user.phone.mobile : '',
      home: user.phone ? user.phone.phone : '',
      work: user.work ? user.phone.work : '',
      street: user.address ? user.address.street : '',
      city: user.address ? user.address.city : '',
      state: user.address ? user.address.state : '',
      zip: user.address ? user.address.postalCode : '',
      kerberosId: user.kerberosId || ''
    }
  } else return null
}

const mapUsers = (users: any): UsersObj => {
  if (users) {
    const obj = {}
    users.forEach((user: any) => {
      obj[user.id] = mapUser(user)
    })
    return obj
  } else return {}
}

const OfficeTeams = (state = initState, action: any): OfficeTeamsInterface => {
  const newState = JSON.parse(JSON.stringify(state))

  switch (action.type) {
    case `${actions.FETCH_OFFICE_TEAMS}_FULFILLED`:
      const officeTeams = mapOfficeTeams(action.payload.data)
      return { ...state, ...officeTeams }
    case `${actions.CREATE_OFFICE_TEAM}_FULFILLED`:
      const newTeam = mapOfficeTeam(action.payload.data)
      if (!newTeam) {
        return state
      }
      return { ...newState, [newTeam.id]: { ...newTeam } }
    case `${actions.FETCH_ELIGIBLE_USERS}_FULFILLED`:
      newState[action.payload.data.id].eligibleUsers = mapUsers(
        action.payload.data.eligibleUsers
      )
      return newState
    case `${actions.EDIT_OFFICE_TEAM}_FULFILLED`:
      if (!state[action.payload.data.id]) {
        newState[action.payload.data.id] = {}
      }
      newState[action.payload.data.id] = mapOfficeTeam(action.payload.data)
      return newState
    case `${actions.DELETE_OFFICE_TEAM}_FULFILLED`:
      delete newState[action.payload.data.id]
      return newState
    case `${actions.ADD_OFFICE_TEAM_MEMBERS}_FULFILLED`:
      const addMembers = action.payload.data.members
      addMembers.forEach((value: OfficeTeamMemberObj) => {
        newState[action.payload.data.id].members.push(value)
      })
      return newState
    case `${actions.EDIT_OFFICE_TEAM_MEMBER}_FULFILLED`:
      const member: OfficeTeamMemberObj = action.payload.data
      const team = newState[member.officeTeamId]
      team.members.forEach((mem: OfficeTeamMemberObj, index: number) => {
        if (mem.userId === member.userId) {
          newState[member.officeTeamId].members[index].showInGuidecenter =
            member.showInGuidecenter
        }
      })
      return newState
    case `${actions.DELETE_OFFICE_TEAM_MEMBERS}_FULFILLED`:
      const newMembers: OfficeTeamMemberObj[] = []
      const members = newState[action.payload.data.id].members
      members.forEach((value: OfficeTeamMemberObj) => {
        if (action.payload.data.userIds.indexOf(value.userId) === -1) {
          newMembers.push(value)
        }
      })
      newState[action.payload.data.id].members = newMembers
      return newState
    default:
      return state
  }
}

export default OfficeTeams
