import React, { Fragment } from 'react'
import closeIcon from '../../../assets/images/icons/png/ic_close.png'

interface ChangeWarningState {
  showChangeWarning: boolean
}

class ChangeWarning extends React.Component<{}, ChangeWarningState> {
  constructor(props: {}) {
    super(props)
    this.state = {
      showChangeWarning: true
    }
  }
  public closeChangeWarning = () => {
    this.setState({ showChangeWarning: false })
  }
  public render() {
    const { showChangeWarning } = this.state
    return (
      <Fragment>
        {showChangeWarning ? (
          <div className='financial-planning__changes-warning'>
            <p>
              Changes made here apply to AdvisorCenter and GuideCenter only.
              Source account info remains intact
            </p>
            <img
              src={closeIcon}
              alt='close'
              onClick={this.closeChangeWarning}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default ChangeWarning
