import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// Containers
import Login from './v3/containers/login'
import TimeoutModal from './v3/containers/timeoutModal'

import './v3/assets/styles/master.scss'
import AuthenticatedRoutes from './authenticatedRoutes'
import MaintenancePage from './v3/containers/Maintenance'
const maintenanceMode = window._env_.REACT_APP_MAINTENANCE_MODE === 'true'

class App extends React.Component {
  public render() {
    return (
      <div>
        <TimeoutModal />
        <Switch>
          <Route path='/maintenance' component={MaintenancePage} exact={true} />
          <Route path='/auth/callback' exact={true} component={Login} />
          <Route
            path='/'
            render={(props) =>
              maintenanceMode ? (
                <Redirect to='/maintenance' />
              ) : (
                <AuthenticatedRoutes {...props} />
              )
            }
          />
        </Switch>
      </div>
    )
  }
}

export default App
