import React, { Component, Fragment } from 'react'
import { Dispatch, connect } from 'react-redux'
import { ContactObj } from '../../../../objects/contact'
import { GlobalState } from '../../../../reducers'
import Button from '../../../components/button'
import { HouseholdAccessClientSettingsQuery } from '../../../../gql/client/settings/settingsQuery'
import {
  clientSettingsMapping,
  settingsStateValidator
} from '../../../helpers/clientSettings'
import { SetClientNotifications } from '../../../../gql/client/settings/settings'
import { NotificationSettings } from '../../../components/settings/notificationSettings'
import { ClientToggleRows } from '../../../components/settings/clientToggles'
import * as toastActions from '../../../../actions/toasts'

import { newApolloClient } from '../../../../gql/initializer'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { dependantWebNotificationSettings } from '../../../../objects/settings/clientAdvisor'

export interface ClientNotificationsProps {
  dispatch: Dispatch<GlobalState>
  householdId: string
  contact: ContactObj
  userId: string
  accessToken: string
}

interface ClientNotificationsState {
  desktopSettings: string[] // state container
  emailSettings: string[] // state container
  mobileSettings: string[] // state container
  disableSettings: boolean
}

let apolloClient: ApolloClient<NormalizedCacheObject> = null
export class ClientNotifications extends Component<
  ClientNotificationsProps,
  ClientNotificationsState
> {
  constructor(props: ClientNotificationsProps) {
    super(props)
    this.state = {
      desktopSettings: [],
      emailSettings: [],
      mobileSettings: [],
      disableSettings: true
    }
  }

  public componentWillUnmount = () => {
    // clean up on unmount
    apolloClient.clearStore()
    apolloClient.stop()
  }

  public componentDidMount = () => {
    apolloClient = newApolloClient(this.props.accessToken)
    if (apolloClient) {
      const query = this.querySettingsPermissions()
      if (query) {
        query.then((queryResponse: any) => {
          this.setStateFromQuery(queryResponse)
        })
      }
    }
  }

  /**
   *
   * @param queryResponse
   * map and set state
   *
   */
  public setStateFromQuery = (queryResponse: any) => {
    const { householdId } = this.props
    const {
      desktopSettings,
      emailSettings,
      mobileSettings,
      disabled
    } = clientSettingsMapping(householdId, queryResponse)
    this.setState({
      desktopSettings,
      emailSettings,
      mobileSettings,
      disableSettings: disabled
    })
  }

  /**
   *
   * @returns Query response
   * Queries client notification and advisor household permission.
   */
  public querySettingsPermissions = () => {
    const { contact, householdId, userId } = this.props
    //if (contact?.id && apolloClient) {
    // query for client settings and advisor permissions
    return (
      contact?.id &&
      apolloClient &&
      apolloClient
        .query({
          query: HouseholdAccessClientSettingsQuery,
          variables: { userId, householdId, clientId: contact.id }
        })
        .then((queryResponse: any) => {
          return queryResponse
        })
    )
    //}
  }

  /**
   *
   * @param event
   * Enable/Disable notification states for desktop (web) and email
   */
  public toggleNotificationSetting = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { desktopSettings, emailSettings, mobileSettings } = this.state
    const { name: notificationType, checked: enabled } = event.currentTarget
    let settings = Array.from(desktopSettings)

    const isEmailNotification = notificationType.startsWith('EMAIL_')
    const isMobileNotification = notificationType.startsWith('MOBILE_')

    if (isEmailNotification) {
      settings = Array.from(emailSettings)
    }
    if (isMobileNotification) {
      settings = Array.from(mobileSettings)
    }

    if (!enabled) {
      settings.splice(settings.indexOf(notificationType), 1)
    } else if (isEmailNotification) {
      settings.push(notificationType.replace('EMAIL_', ''))
    } else {
      settings.push(notificationType.replace('MOBILE_', ''))
    }

    if (isEmailNotification) {
      this.setState({ emailSettings: settings })
    } else if (isMobileNotification) {
      this.setState({ mobileSettings: settings })
    } else {
      const emailSettingsClone = Array.from(emailSettings)
      const mobileSettingsClone = Array.from(mobileSettings)

      if (
        dependantWebNotificationSettings[notificationType]?.length > 0 &&
        !enabled
      ) {
        emailSettingsClone.splice(
          emailSettingsClone.indexOf(notificationType),
          1
        )
        mobileSettingsClone.splice(
          mobileSettingsClone.indexOf(notificationType),
          1
        )
      }
      this.setState({
        desktopSettings: settings,
        emailSettings: emailSettingsClone,
        mobileSettings: mobileSettingsClone
      })
    }
  }

  public showToast = () => {
    this.props.dispatch(
      toastActions.addToast({ message: 'Settings have been saved.' })
    )
  }

  /**
   * Query settings cache and reset the state.
   */
  public reset = () => {
    this.querySettingsPermissions().then((queryReponse: any) => {
      this.setStateFromQuery(queryReponse)
    })
  }

  /**
   * Compare our settings cache vs state, then save if we have changes
   */
  public save = () => {
    const { contact } = this.props
    const clientId = contact ? contact.id : null
    const { desktopSettings, emailSettings, mobileSettings } = this.state
    // check our settings, has anything changed
    this.querySettingsPermissions().then((queryResponse: any) => {
      const settings = clientSettingsMapping(
        this.props.householdId,
        queryResponse
      )
      const desktopSettingsChanged =
        settings?.desktopSettings &&
        settingsStateValidator(settings.desktopSettings, desktopSettings)
      const emailSettingsChanged =
        settings?.emailSettings &&
        settingsStateValidator(settings.emailSettings, emailSettings)
      const mobileSettingsChanged =
        settings?.mobileSettings &&
        settingsStateValidator(settings.mobileSettings, mobileSettings)
      if (
        desktopSettingsChanged ||
        emailSettingsChanged ||
        mobileSettingsChanged
      ) {
        // exec the mutation
        const gqlClient = apolloClient
        SetClientNotifications(gqlClient, {
          desktopSettings,
          emailSettings,
          mobileSettings,
          clientId
        }).then((response: any) => this.showToast())
      }
    })
  }

  public render = () => {
    const {
      disableSettings,
      desktopSettings,
      emailSettings,
      mobileSettings
    } = this.state
    return (
      <Fragment>
        <NotificationSettings
          disableSettings={disableSettings}
          title='GuideCenter Alerts and Notifications'
          subTitle={
            <span>
              Choose notifications and alerts for <b>this client</b>. You can
              set global alerts in Advisor Settings.
            </span>
          }>
          <ClientToggleRows
            disabled={disableSettings}
            className='a-settings__row--content'
            handler={this.toggleNotificationSetting}
            desktopSettings={desktopSettings}
            emailSettings={emailSettings}
            mobileSettings={mobileSettings}
          />
        </NotificationSettings>

        <div className='c-settings__gc-save-btn-w'>
          <Button onClick={this.reset} clear={true}>
            Cancel
          </Button>
          <Button onClick={this.save} primary={true}>
            Save
          </Button>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return { userId: store?.user?.userId, accessToken: store?.user?.accessToken }
}

export default connect(mapStateToProps)(ClientNotifications)
