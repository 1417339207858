import React from 'react'
import TableRow from '../../../components/layout/tableRow'
import CheckBox from '../../../components/checkbox'
import { CRMHouseholdsObj } from '../../../../objects/user'
// import WarningIcon from '../../../assets/images/icons/ic_warning_grey.png'
import helpIcon from '../../../assets/images/icons/general/help-gray.png'

interface SalesforceSyncRowProps {
  crmHousehold: CRMHouseholdsObj
  checked: boolean
  v2ACL: boolean
  onCheckboxToggle(id: string): void
}

class SalesforceSyncRow extends React.Component<SalesforceSyncRowProps> {
  public toggleSync = () => {
    const { crmHousehold, onCheckboxToggle } = this.props
    onCheckboxToggle(crmHousehold.id)
  }
  public render() {
    const { crmHousehold, checked, v2ACL } = this.props
    const checkboxDisabled = !crmHousehold.institutionId && v2ACL
    return (
      <TableRow>
        <CheckBox
          id={crmHousehold.id}
          checked={checked}
          onChange={this.toggleSync}
          checkedBorder={true}
          noToggleAllowed={checkboxDisabled}
          toolTip={checkboxDisabled}
          toolTipIcon={helpIcon}
          toolTipText='Please assign an Office in your CRM and retry.'
          label={crmHousehold.name}
        />
        {crmHousehold.institutionId && v2ACL ? (
          <div className='transfer-list-row__office'>
            {crmHousehold.institutionId}
          </div>
        ) : null}
      </TableRow>
    )
  }
}

export default SalesforceSyncRow
