import api from '../v3/helpers/api'
import { LifeActionUpdate } from '../objects/lifeActions'
import { TaskObj } from '../objects/tasks'
import { mapNewTaskObj } from '../v3/helpers/tasks'

/**
 *
 * LIFE ACTIONS
 *
 */
export const CREATE_LIFE_ACTION = 'CREATE_LIFE_ACTION'
export const FETCH_LIFE_ACTIONS = 'FETCH_LIFE_ACTION'
export const DELETE_LIFE_ACTION = 'DELETE_LIFE_ACTION'
/**
 *
 */
export const UPDATE_LIFE_ACTION = 'UPDATE_LIFE_ACTION'
/**
 *
 */
export const FETCH_LIFE_ACTION_BY_ID = 'FETCH_LIFE_ACTION_BY_ID'
export const DELETE_LIFE_ACTION_CARD = 'DELETE_LIFE_ACTION_CARD'
/**
 *
 */
export const CREATE_LIFE_ACTION_TASK = 'CREATE_LIFE_ACTION_TASK'
export const DELETE_LIFE_ACTION_TASK = 'DELETE_LIFE_ACTION_TASK'

const createLifeActionCards = async (
  householdFinId: string,
  lifeActionId: string,
  hcCardId: string
) => {
  return api()
    .post(`/households/${householdFinId}/lifeactions/${lifeActionId}/cards`, {
      hcCardId
    })
    .then((response) => {
      response.data.hcCardId = hcCardId
      return response
    })
}

export const getLifeAction = (householdFinId: string) => {
  return {
    type: FETCH_LIFE_ACTIONS,
    payload: api()
      .get(`/households/${householdFinId}/lifeactions`)
      .then((response) => {
        return response
      })
  }
}

export const createLifeAction = (
  lifeActionObject: LifeActionUpdate,
  hcCardId: string
) => {
  const householdFinId = lifeActionObject.householdId
  return {
    type: CREATE_LIFE_ACTION,
    payload: api()
      .post(`/households/${householdFinId}/lifeactions`, lifeActionObject)
      .then((response) => {
        return createLifeActionCards(
          householdFinId,
          response.data.id,
          hcCardId
        ).then((lifeActionCardResponse) => {
          return getLifeAction(householdFinId).payload
        })
      })
  }
}

export const deleteLifeAction = (
  householdFinId: string,
  lifeActionId: string
) => {
  return {
    type: DELETE_LIFE_ACTION,
    payload: api()
      .delete(`/households/${householdFinId}/lifeactions/${lifeActionId}`)
      .then((response) => {
        return response
      })
  }
}

export const updateLifeAction = (
  lifeActionObject: LifeActionUpdate,
  lifeActionId: string
) => {
  const householdFinId = lifeActionObject.householdId
  return {
    type: UPDATE_LIFE_ACTION,
    payload: api()
      .put(
        `/households/${householdFinId}/lifeactions/${lifeActionId}`,
        lifeActionObject
      )
      .then((response) => {
        return response
      })
  }
}

export const getLifeActionById = (
  householdFinId: string,
  lifeActionId: string
) => {
  return {
    type: FETCH_LIFE_ACTION_BY_ID,
    payload: api()
      .post(`/households/${householdFinId}/lifeactions/${lifeActionId}`)
      .then((response) => {
        return response
      })
  }
}

export const deleteActionCard = (
  householdFinId: string,
  lifeActionId: string,
  actionCardId: string
) => {
  return {
    type: DELETE_LIFE_ACTION_CARD,
    payload: api()
      .delete(
        `/households/${householdFinId}/lifeactions/${lifeActionId}/cards/${actionCardId}`
      )
      .then((response) => {
        return response
      })
  }
}

export const addTaskIdToLifeAction = (
  householdFinId: string,
  lifeActionId: string,
  lifeActionTaskId: number
) => {
  return {
    payload: api()
      .post(`/households/${householdFinId}/lifeactions/${lifeActionId}/tasks`, {
        taskId: lifeActionTaskId
      })
      .then((response) => {
        response.data.actionId = lifeActionId
        return response
      })
  }
}

export const createLifeActionTask = (
  householdFinId: string,
  request: TaskObj,
  actionId: string
) => {
  const newTaskObj = mapNewTaskObj(request, householdFinId)
  return {
    type: CREATE_LIFE_ACTION_TASK,
    payload: api()
      .post(`/households/${householdFinId}/tasks`, newTaskObj)
      .then((response) => {
        response.data.householdFinId = householdFinId
        response.data.actionId = actionId
        addTaskIdToLifeAction(householdFinId, actionId, response.data.id)
        return response
      })
  }
}

export const deleteLifeActionTask = (
  householdFinId: string,
  lifeActionId: string,
  actionTaskId: string
) => {
  return {
    type: DELETE_LIFE_ACTION_TASK,
    payload: api()
      .delete(
        `/households/${householdFinId}/lifeactions/${lifeActionId}/tasks/${actionTaskId}`
      )
      .then((response) => {
        return response
      })
  }
}
