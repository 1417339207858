import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'

export interface TableRowProps {
  keyId?: string | number
  backgroundColor?: string
  to?: string
  onClick?(e?: MouseEvent<HTMLDivElement>): void
}

class TableRow extends React.Component<TableRowProps> {
  public render() {
    const { backgroundColor, to, onClick, keyId } = this.props
    const className = onClick ? 'row-w row-w--hover' : 'row-w'
    if (to) {
      return (
        <Link
          key={keyId}
          to={to}
          className={className}
          style={{ backgroundColor }}>
          {this.props.children}
        </Link>
      )
    } else {
      return (
        <div
          key={keyId}
          className={className}
          style={{ backgroundColor }}
          onClick={onClick}>
          {this.props.children}
        </div>
      )
    }
  }
}

export default TableRow
