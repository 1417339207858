import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  ClientFilterObj,
  NetWorthErrorFilterType
} from '../../../objects/clientFilter'
import FilterDropDown from './filterDropdown'
import Button from '../../components/button'
import FilterIcon from '../../assets/images/icons/png/filter_blue_x2.png'
import NetWorthErrorFilter from './netWorthErrorFilter'
import { setClientListFilters } from '../../../actions/clientFilter'
import { InstitutionObj } from '../../../objects/institution'

export interface ClientFilterProps {
  clientFilter: ClientFilterObj
  institution: InstitutionObj
  dispatch: Dispatch<GlobalState>
  toggleFilterDropdown(): void
  onApply(): void
  resetFilters?(): void
}
interface ClientFilterState {
  selectedInstitutionFilter: string[]
  selectedMoneyMindFilter: string[]
  selectedOfficeTeamFilter: string[]
  selectedNetWorthErrorFilter: NetWorthErrorFilterType
}

export class ClientFilter extends Component<
  ClientFilterProps,
  ClientFilterState
> {
  constructor(props: ClientFilterProps) {
    super(props)
    this.state = {
      selectedInstitutionFilter: props.clientFilter.selectedInstitutions,
      selectedMoneyMindFilter: props.clientFilter.selectedMoneyMinds,
      selectedOfficeTeamFilter: props.clientFilter.selectedOfficeTeams,
      selectedNetWorthErrorFilter: props.clientFilter.showNetWorthErrors
    }
  }

  public renderLeftHeader = () => {
    return (
      <div className='filter-modal__header'>
        <div className='filter-modal__header-label'>
          <img src={FilterIcon} width={20} alt='filter' />
          <span className='filter-modal__header-text'>FILTER</span>
        </div>
        <Button clear={true} onClick={this.resetFilters}>
          Reset
        </Button>
      </div>
    )
  }

  public updateSelectedInstitutions = (value: string[]) => {
    this.setState({ selectedInstitutionFilter: value })
  }

  public updateSelectedMoneyminds = (value: string[]) => {
    this.setState({ selectedMoneyMindFilter: value })
  }

  public updateSelectedOfficeteams = (value: string[]) => {
    this.setState({ selectedOfficeTeamFilter: value })
  }

  public handleSubmit = async () => {
    const { dispatch, onApply } = this.props
    const {
      selectedInstitutionFilter,
      selectedMoneyMindFilter,
      selectedOfficeTeamFilter,
      selectedNetWorthErrorFilter
    } = this.state
    dispatch(
      setClientListFilters(
        selectedInstitutionFilter,
        selectedMoneyMindFilter,
        selectedOfficeTeamFilter,
        selectedNetWorthErrorFilter
      )
    )
    onApply()
  }

  public handleCancel = () => {
    const { toggleFilterDropdown } = this.props
    toggleFilterDropdown()
  }

  public handleClickOutside = () => {
    this.handleCancel()
  }

  public resetFilters = () => {
    const { resetFilters, institution } = this.props
    this.setState(
      {
        selectedInstitutionFilter: [institution.id],
        selectedMoneyMindFilter: [],
        selectedOfficeTeamFilter: [],
        selectedNetWorthErrorFilter: 'none checked'
      },
      () => resetFilters()
    )
  }

  public setNewNetWorthErrorFilter = (
    newNetWorthErrorFilter: NetWorthErrorFilterType
  ) => {
    this.setState({
      selectedNetWorthErrorFilter: newNetWorthErrorFilter
    })
  }

  public render() {
    const { clientFilter, institution } = this.props
    const {
      selectedInstitutionFilter,
      selectedMoneyMindFilter,
      selectedOfficeTeamFilter,
      selectedNetWorthErrorFilter
    } = this.state
    const hasInstitutions = clientFilter.institutions?.length > 0

    // We pass the default institution as the drop down options in case filter.instituions is empty
    const { name, id } = institution
    const singleInstitutionOption = [{ name, id }]
    const institutionOptions =
      hasInstitutions &&
      clientFilter.institutions.map((institution) => {
        return {
          name: institution.name,
          id: institution.id
        }
      })
    const moneyMindOptions = clientFilter.moneyMinds.map((moneyMind) => {
      return {
        name: moneyMind.name,
        id: moneyMind.id
      }
    })
    const officeTeamOptions = clientFilter.officeTeams.map((officeTeam) => {
      return {
        name: officeTeam.name,
        id: officeTeam.id
      }
    })
    return (
      <div className='institution-filter__institution-dropdown-list'>
        <div>{this.renderLeftHeader()}</div>

        <FilterDropDown
          title='Institutions'
          updateSelectedItems={this.updateSelectedInstitutions}
          options={
            hasInstitutions ? institutionOptions : singleInstitutionOption
          }
          selectedOptions={selectedInstitutionFilter}
        />

        <FilterDropDown
          title='MoneyMind Type'
          updateSelectedItems={this.updateSelectedMoneyminds}
          options={moneyMindOptions}
          selectedOptions={selectedMoneyMindFilter}
        />

        <FilterDropDown
          title='Service Team'
          updateSelectedItems={this.updateSelectedOfficeteams}
          options={officeTeamOptions}
          selectedOptions={selectedOfficeTeamFilter}
        />

        <div className='institution-filter__institution-dropdown-list-body'>
          <NetWorthErrorFilter
            selectedNetWorthErrorFilter={selectedNetWorthErrorFilter}
            setNewNetWorthErrorFilter={this.setNewNetWorthErrorFilter}
          />
        </div>
        <div className='institution-filter__institution-dropdown-list-buttons'>
          <Button clear={true} onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button primary onClick={this.handleSubmit}>
            Apply
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    clientFilter: store.clientFilter
  }
}
const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(ClientFilter))
