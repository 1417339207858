import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../objects/financialGoal'
import { annotateYRange } from './lineChartOptions'
import { PointStyle } from 'chart.js'
import moment from 'moment'
import { DataPointCircleObj, dataPointFactory } from './graphPoint'
/*
  provides: formatted data prop for chart
*/

const scorePointColors = (
  planningSoftwareType: PlanningSoftwareType,
  chartData: number,
  dataRange: { rangeLow: number; rangeHigh: number }
): string => {
  const underfunded: string = '#b21e28' // red
  const ideal: string = '#8ccc33' // green
  const overfunded: string = '#f49500' // orange

  if (!planningSoftwareType) {
    return '#8a8888'
  }

  if (chartData < dataRange.rangeLow) return underfunded
  if (chartData > dataRange.rangeHigh) return overfunded
  if (dataRange.rangeLow <= chartData && dataRange.rangeHigh >= chartData) {
    return ideal
  }

  return '#8a8888'
}

// set point style to image, default: circle
const pointStyle = (
  chartPointColors: string[],
  listOfFundedScores: FundedScoreObj[]
): (PointStyle | HTMLImageElement)[] => {
  const pointsDisplay: (PointStyle | HTMLImageElement)[] = []
  listOfFundedScores &&
    listOfFundedScores.forEach((fundedScore: FundedScoreObj, index: number) => {
      const pointImg = new Image()
      const pointData: DataPointCircleObj = {
        id: fundedScore.id,
        squareDimension: 50,
        circle: {
          radius: 10,
          stroke: chartPointColors[index],
          strokeWidth: 2.5,
          fillColor: 'rgba(255, 255, 255, 1.0)'
        },
        text: {
          content: `${fundedScore.score}`,
          fontSize: 12,
          fill: 'rgba(0, 0, 0, 1.0)'
        }
      }
      pointImg.src = dataPointFactory(pointData)
      pointsDisplay.push(pointImg)
    })

  return pointsDisplay
}

export const filterFundedScores = (
  limit: number,
  fundedScores: FundedScoreObj[]
): FundedScoreObj[] => {
  let showPoints =
    fundedScores &&
    fundedScores.filter((score, index) => {
      return index === 0 || score.inGuidebook === true
    })

  showPoints =
    showPoints &&
    showPoints
      .sort((scoreA, scoreB) => {
        if (moment(scoreA.lastUpdated).isBefore(scoreB.lastUpdated)) {
          return 1
        } else {
          return 0
        }
      })
      .slice(0, limit)
      .reverse()
  return showPoints
}

export const lineChartDataSet = (
  limit: number,
  planningSoftwareType: PlanningSoftwareType,
  fundedScores: FundedScoreObj[],
  labelKeyName: string,
  dataKeyName: string
): {
  labels: string[]
  datasets: {
    lineTension: number
    label: string
    fill: boolean
    spanGaps: number
    pointRadius: number
    pointStyle: (PointStyle | HTMLImageElement)[]
    pointHitRadius: number
    pointColor: string
    pointStrokeColor: string
    pointHighlightFill: string
    pointHighlightStroke: string
    pointHoverBackgroundColor: string
    strokeColor: string
    data: number[]
    backgroundColor: string[]
  }[]
} => {
  const chartLabels: string[] = []
  const chartData: number[] = []
  const chartPointColors: string[] = []

  const listOfFundedScores = filterFundedScores(limit, fundedScores)
  if (listOfFundedScores.length) {
    const yRanges = annotateYRange(fundedScores, planningSoftwareType)
    listOfFundedScores &&
      listOfFundedScores.forEach((score) => {
        chartLabels.push(moment(score[labelKeyName]).format('M/D/YY'))
        chartData.push(score[dataKeyName])
        chartPointColors.push(
          scorePointColors(planningSoftwareType, score[dataKeyName], yRanges)
        )
      })
  }

  return {
    labels: chartLabels,
    datasets: [
      {
        lineTension: 0,
        label: null,
        fill: false,
        spanGaps: 5,
        pointRadius: 4,
        pointStyle: pointStyle(chartPointColors, listOfFundedScores),
        pointHitRadius: 10,
        pointColor: 'rgba(220,220,220,1)',
        pointStrokeColor: 'rgba(255,255,255,1)',
        pointHighlightFill: 'rgba(255,255,255,1)',
        pointHighlightStroke: 'rgba(220,220,220,1)',
        pointHoverBackgroundColor: 'rgba(255,0,0,0)',
        strokeColor: 'rgba(220,220,220,1)',
        data: chartData,
        backgroundColor: chartPointColors
      }
    ]
  }
}
