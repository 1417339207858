import React, { Component, FormEvent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'

import { GlobalState } from '../../../reducers'
import { updateGlobalNavigation } from '../../../actions/globalSettings'
import {
  getGoalActions,
  setGoalIdActions,
  updateSideBarState
} from '../../../actions/financialGoalActions'

import {
  setHouseholdPreferences,
  updateHouseholdPreferences
} from '../../../actions/householdPreferences'
import {
  GoalsToggleStateObj,
  FinancialGoalActionObj,
  GoalActionSortByTypes
} from '../../../objects/financialGoalActions'
import {
  GoalActionsPreferences,
  HouseholdPreferencesObj
} from '../../../objects/householdPreferences'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'
import Button from '../../components/button'
import ContentHeader from '../../components/layout/contentHeader'
import InnerContainer from '../../components/layout/innerContainer'
import FilterModal from './goalsFilter/filterModal'
import { SelectableTile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import GoalsNullView from './goalsNullView'
import GoalsLoadingView from './goalsLoadingView'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { HouseholdGoalActionsState } from '../../../reducers/financialGoalActions'
import { generateNewPreferences } from '../../helpers/householdPreferences'
import { getTileConfig } from '../../helpers/goals'
import goalActionsSelector, {
  relevantPreferencesSelector
} from '../../../selectors/v3/goals'

export interface GoalProps {
  householdFinId: string
  goalId: string
  relevantPreferences: GoalActionsPreferences
  preferences: HouseholdPreferencesObj
  dispatch: Dispatch<GlobalState>
  goals: FinancialGoalActionObj[]
  goalActions: HouseholdGoalActionsState | null
  honestConversation: HonestConversationsExerciseObj
  collapsedState: boolean
  sideBarState: boolean
}

interface GoalState {
  goalId: string
  showfilterModal: boolean
  toggle: GoalsToggleStateObj
  sortBy: GoalActionSortByTypes
}

export class Goals extends Component<GoalProps, GoalState> {
  constructor(props: GoalProps) {
    super(props)
    this.state = {
      goalId: null,
      showfilterModal: false,
      toggle: {
        showInactiveItems: false,
        showLifeActions: true,
        showFinancialGoals: true
      },
      sortBy: 'Due Date'
    }
  }

  public async componentDidMount() {
    const { householdFinId, dispatch } = this.props
    await dispatch(getGoalActions(householdFinId))
  }

  public onSelectedGoal = (goalId: string) => {
    const { householdFinId, dispatch } = this.props
    if (goalId) {
      dispatch(setGoalIdActions({ goalId, householdFinId }))
      dispatch(updateGlobalNavigation(true))
      dispatch(
        updateSideBarState({ sideBarState: true, householdId: householdFinId })
      )
    }
  }

  public handleFilterModalToggle = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { name } = event.currentTarget
    this.setState((prevState) => ({
      ...prevState,
      toggle: {
        ...prevState.toggle,
        [name]: !this.state.toggle[name]
      }
    }))
  }

  public updatePreferences = () => {
    const { householdFinId, preferences, dispatch } = this.props
    const { sortBy, toggle } = this.state
    this.setState({ showfilterModal: false })
    const prefSortBy: GoalActionSortByTypes =
      sortBy === 'Date' ? 'Due Date' : sortBy
    if (!preferences) {
      const newPreferences: HouseholdPreferencesObj = {
        ...generateNewPreferences(),
        goalActionsSortBy: prefSortBy,
        goalActionsShowInactiveItems: toggle.showInactiveItems,
        goalActionsShowFinancialGoals: toggle.showFinancialGoals,
        goalActionsShowLifeActions: toggle.showLifeActions
      }
      return dispatch(setHouseholdPreferences(householdFinId, newPreferences))
    } else {
      const updatePreferences: HouseholdPreferencesObj = {
        ...preferences,
        goalActionsSortBy: prefSortBy,
        goalActionsShowInactiveItems: toggle.showInactiveItems,
        goalActionsShowFinancialGoals: toggle.showFinancialGoals,
        goalActionsShowLifeActions: toggle.showLifeActions
      }
      return dispatch(
        updateHouseholdPreferences(householdFinId, updatePreferences)
      )
    }
  }

  public resetFilters = () => {
    this.setState((prevState) => ({
      ...prevState,
      toggle: {
        ...prevState.toggle,
        showInactiveItems: false,
        showLifeActions: true,
        showFinancialGoals: true
      },
      sortBy: 'Due Date'
    }))
  }

  public onRadioSelect = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { value } = event.currentTarget
    this.setState({ sortBy: value })
  }

  public onAddNewGoal = () => {
    const isNewGoalExist = this.props.goals.find((goal) => goal.id === 'NEW')
    const { householdFinId: householdId, dispatch } = this.props
    if (!isNewGoalExist) {
      dispatch(updateSideBarState({ sideBarState: true, householdId }))
      dispatch(updateGlobalNavigation(true))
      dispatch({
        type: 'CREATE_GOAL_ACTIONS',
        payload: { data: { householdId } }
      })
    }
  }

  public addNewGoalBtn = () => {
    return (
      <Button primary={true} onClick={this.onAddNewGoal}>
        <PlusIcon />
        <span className='vault__btn--text'>Add Goal</span>
      </Button>
    )
  }

  public renderGoals = () => {
    const { goals, goalActions, sideBarState, honestConversation } = this.props
    const tileData = getTileConfig(goals, honestConversation)
    if (!goalActions) {
      return <GoalsLoadingView />
    }
    if (tileData?.length > 0) {
      const { goalId } = this.state
      return (
        <SelectableTile
          activeTileId={goalId}
          data={tileData}
          resetSelection={sideBarState}
          onSelected={this.onSelectedGoal}
        />
      )
    } else {
      return <GoalsNullView />
    }
  }

  public toggleFilterModal = () => {
    this.setState(
      (prev) => ({ showfilterModal: !prev.showfilterModal }),
      () => this.syncPreferences()
    )
  }

  public syncPreferences() {
    const { relevantPreferences } = this.props

    if (relevantPreferences) {
      this.setState((prevState) => ({
        ...prevState,
        toggle: {
          ...prevState.toggle,
          showInactiveItems: relevantPreferences.goalActionsShowInactiveItems,
          showLifeActions: relevantPreferences.goalActionsShowLifeActions,
          showFinancialGoals: relevantPreferences.goalActionsShowFinancialGoals
        },
        sortBy: relevantPreferences.goalActionsSortBy
      }))
    } else {
      this.resetFilters()
    }
  }

  public render() {
    const { showfilterModal, toggle, sortBy } = this.state
    return (
      <InnerContainer>
        <div className='goals__w'>
          <div className='goals__body'>
            <ContentHeader
              hasFilter={true}
              onFilterClick={this.toggleFilterModal}
              title='Goals'
              rightHeader={this.addNewGoalBtn()}
            />
            {showfilterModal && (
              <FilterModal
                toggle={toggle}
                selectedSortBy={sortBy}
                toggleFilterModal={this.toggleFilterModal}
                handleFilterToggle={this.handleFilterModalToggle}
                resetFilters={this.resetFilters}
                onRadioSelect={this.onRadioSelect}
                updatePreferences={this.updatePreferences}
              />
            )}
            {this.renderGoals()}
          </div>
        </div>
      </InnerContainer>
    )
  }

  public componentWillUnmount() {
    const { householdFinId, dispatch } = this.props
    dispatch(setGoalIdActions({ goalId: null, householdFinId }))
    dispatch(
      updateSideBarState({ sideBarState: false, householdId: householdFinId })
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdFinId = match?.params?.householdFinId
  const goalActions = store.goalActions[householdFinId]
  const housePreferences = store?.householdPreferences[householdFinId]
  const household = store.households[householdFinId]
  const houseHcExercises = store.hcExercises[householdFinId]
  return {
    collapsedState: store?.globalSettings?.globalNavigation?.collapsed,
    householdFinId,
    goals: goalActionsSelector(store, householdFinId),
    goalActions,
    goalId: goalActions?.goalRequestId || null,
    sideBarState: goalActions?.sideBarState || false,
    preferences: housePreferences?.preferences,
    relevantPreferences: relevantPreferencesSelector(store, householdFinId),
    honestConversation:
      houseHcExercises && houseHcExercises[household?.hcExerciseId],
    globalNavigationState: store?.globalSettings?.globalNavigation?.collapsed
  }
}

export default withRouter(connect(mapStateToProps)(Goals))
