import React, { Component } from 'react'

import Tooltip from '../components/tooltip'
import { staticText } from '../helpers/resources'

import CarrotIcon from '../assets/images/icons/png/carrot-gray.png'
import { ReactComponent as HelpIcon } from '../assets/images/icons/help.svg'

export interface TableLabelProps {
  title: string
  defaultArrow: boolean
  toolTipWidth?: number
  onClick(sortDescending: boolean, sortBy: string): void
}
interface TableLabelState {
  arrowDown: boolean
}

class TableLabel extends Component<TableLabelProps, TableLabelState> {
  constructor(props: TableLabelProps) {
    super(props)
    this.state = {
      arrowDown: this.props.defaultArrow
    }
  }

  public componentDidUpdate(prevProps: TableLabelProps) {
    const { defaultArrow: arrowDown } = this.props
    if (arrowDown !== prevProps.defaultArrow) {
      this.setState({ arrowDown })
    }
  }

  public toggleArrow = async () => {
    const { arrowDown } = this.state
    await this.props.onClick(arrowDown, this.props.title)
    this.setState({ arrowDown: !arrowDown })
  }

  public renderToolTip = () => {
    const { title, toolTipWidth } = this.props
    return title.toLocaleLowerCase() === 'show/hide' ? (
      <Tooltip
        message={staticText.includeInGc}
        width={toolTipWidth}
        position='bottom'
        multiLine={true}>
        <HelpIcon />
      </Tooltip>
    ) : null
  }

  public render() {
    const { title } = this.props
    const { arrowDown } = this.state
    return (
      <div className='pal__sort-w' onClick={this.toggleArrow}>
        <label style={{ cursor: 'pointer' }}>{title}</label>
        {this.renderToolTip()}
        {
          <img
            className={
              arrowDown
                ? 'pal__sort-carrot'
                : 'pal__sort-carrot pal__sort-carrot--up'
            }
            src={CarrotIcon}
            role='presentation'
            alt=''
          />
        }
      </div>
    )
  }
}

export default TableLabel
