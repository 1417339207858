import React, { CSSProperties } from 'react'
import { ReactComponent as PreviousIcon } from '../../assets/images/icons/previous.svg'
import { Link } from 'react-router-dom'
import { history } from '../../../store'
const BackToLink = ({
  to,
  style,
  children,
  onClick,
  goBack = false
}: {
  children: string | React.ReactNode[]
  style?: CSSProperties
  to?: string
  goBack?: boolean
  onClick?(): void
}) => {
  if (to) {
    return (
      // This outer div is to prevent flex
      //  affecting the click area on flex wrappers
      <div>
        <Link style={style} className='back-to-link__wrap' to={to}>
          <span className='back-to-link__link'>
            <PreviousIcon />
            <span>{children}</span>
          </span>
        </Link>
      </div>
    )
  } else {
    return (
      // This outer div is to prevent flex
      //  affecting the click area on flex wrappers
      <div>
        <div
          style={style}
          className='back-to-link__wrap'
          onClick={goBack ? history.goBack : onClick}>
          <span className='back-to-link__link'>
            <PreviousIcon />
            <span>{children}</span>
          </span>
        </div>
      </div>
    )
  }
}

export default BackToLink
