import React, { FC, ChangeEvent, Fragment } from 'react'
import { assetStructureMap } from '../../../../../objects/strategySearch'

export interface ManagedStrategiesProps {
  managedStrategyFilters: string[]
  toggleCheckbox(e: ChangeEvent<HTMLInputElement>): void
}

const ManagedStrategies: FC<ManagedStrategiesProps> = ({
  managedStrategyFilters,
  toggleCheckbox
}) => {
  const levelTwoItem = (isChecked: boolean, label: string) => {
    return (
      <label
        className={
          isChecked
            ? 'strategy-search__checkbox-option strategy-search__checkbox-option--checked'
            : 'strategy-search__checkbox-option'
        }
        key={label}>
        <input
          onChange={toggleCheckbox}
          type='checkbox'
          name='managedStrategies'
          value={label}
          checked={isChecked}
          aria-checked={isChecked}
        />
        {label}
      </label>
    )
  }

  const displaylevelTwoItems = (items: string[]) => {
    return items?.map((item: string) => {
      return levelTwoItem(managedStrategyFilters?.includes(item), item)
    })
  }

  return (
    <Fragment>
      {displaylevelTwoItems(assetStructureMap['Managed Strategies'])}
    </Fragment>
  )
}

export default ManagedStrategies
