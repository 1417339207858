import React, { Fragment, FC } from 'react'

import Button from '../../../../components/button'
import AssigneeSelect from '../../../../components/layout/assigneeSelect'
import { ReactComponent as PlusIcon } from '../../../../assets/images/icons/plus.svg'
import noPhoto from '../../../../assets/images/icons/png/nophoto.png'
import { ReactComponent as Avatar } from '../../../../assets/images/icons/svg/avatar.svg'
import { ContactsInterface } from '../../../../../objects/contact'
import { LifeActionUpdate } from '../../../../../objects/lifeActions'

const assignOwnerModal: React.FC<{
  contacts: ContactsInterface
  lifeActionUpdate: LifeActionUpdate
  newActionAssignee(assigneeId: string, contact: string, actionId: string): any
  toggleModal(): void
}> = (props) => {
  const { contacts, lifeActionUpdate, newActionAssignee, toggleModal } = props
  return (
    <div className='card-actions__assign-owner-modal-w'>
      <AssigneeSelect
        tickPosition='right'
        toggleAssignOwnerModal={toggleModal}
        assigneePrimaryId={lifeActionUpdate.assigneePrimaryId}
        assigneeSecondaryId={lifeActionUpdate.assigneeSecondaryId}
        contacts={contacts}
        updateActionAssignee={newActionAssignee}
      />
    </div>
  )
}

const assignOwnerHandler: FC<{ toggleModal(): void }> = (props) => {
  return (
    <Fragment>
      <div
        className='card-actions__owner-w card-actions__owner-w--new'
        onClick={props.toggleModal}>
        <Avatar className='card-actions__owner-avatar' />
        <PlusIcon className='card-actions__owner-plus' />
      </div>
    </Fragment>
  )
}

const actionInput: FC<{
  inputRef?: React.RefObject<HTMLInputElement>
  lifeActionUpdate: LifeActionUpdate
  nameLifeAction?(e: React.ChangeEvent<HTMLInputElement>): void
}> = (props) => {
  const { lifeActionUpdate } = props
  return (
    <input
      ref={props.inputRef}
      name='name'
      className='card-actions__input'
      placeholder='Enter Goal'
      onChange={props.nameLifeAction}
      value={lifeActionUpdate.name}
    />
  )
}

const contactAvatar = (imgSource: string, className: string) => {
  return (
    <img className={className} src={imgSource || noPhoto} alt='profile-photo' />
  )
}

const secondaryAvatarStyle = (assigneePrimaryId: string) => {
  return assigneePrimaryId && 'card-actions__owner-photo--overlap'
}

const secondaryAvatar = (
  lifeActionUpdate: LifeActionUpdate,
  contacts: ContactsInterface
) => {
  return (
    lifeActionUpdate.assigneeSecondaryId &&
    contactAvatar(
      contacts?.secondary?.photo,
      `card-actions__owner-photo ${secondaryAvatarStyle(
        lifeActionUpdate.assigneePrimaryId
      )}`
    )
  )
}

const primaryAvatar = (
  lifeActionUpdate: LifeActionUpdate,
  contacts: ContactsInterface
) => {
  return (
    lifeActionUpdate.assigneePrimaryId &&
    contactAvatar(contacts?.primary?.photo, `card-actions__owner-photo`)
  )
}

export const CombinedCardActionInput: FC<{
  inputRef?: React.RefObject<HTMLInputElement>
  contacts: ContactsInterface
  showModal: boolean
  lifeActionUpdate: LifeActionUpdate
  createLifeAction(): void
  nameLifeAction(e: React.ChangeEvent<HTMLInputElement>): void
  newActionAssignee(actionId: string, assigneeId: string, contact: string): any
  toggleModal(): void
}> = (props) => {
  const {
    inputRef,
    contacts,
    showModal,
    lifeActionUpdate,
    createLifeAction,
    nameLifeAction,
    newActionAssignee,
    toggleModal
  } = props

  return (
    <Fragment>
      <div className='card-actions__wrapper'>
        {actionInput({ inputRef, lifeActionUpdate, nameLifeAction })}
        {secondaryAvatar(lifeActionUpdate, contacts)}
        {primaryAvatar(lifeActionUpdate, contacts)}
        {assignOwnerHandler({ toggleModal })}
        {showModal &&
          assignOwnerModal({
            contacts,
            lifeActionUpdate,
            newActionAssignee,
            toggleModal
          })}
      </div>

      <Button
        clear={true}
        style={{ color: '#255EBA', padding: 0, marginTop: '8px' }}
        onClick={createLifeAction}>
        <PlusIcon className='card-actions__plus-icon' />
        Add Goal
      </Button>
    </Fragment>
  )
}
