import { createSelectorCreator, defaultMemoize } from 'reselect'
import { isEqual } from '../../v3/helpers'

// changing default select memoization '===' with check for undefined, null and 'isEqual' to work with objects
export const extendedMemoize = (value1: any, value2: any) => {
  if (typeof value1 === 'undefined' && typeof value2 === 'undefined') {
    return true
  }
  if (value1 === null && value2 === null) {
    return true
  }
  return isEqual(value1, value2)
}

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  extendedMemoize
)
