import * as actions from '../actions/hcExercises'
import {
  HonestConversationsExerciseInterface,
  HonestConversationsExerciseObj,
  HouseholdCardObjInterface,
  HouseholdCardObj,
  ClientCardObjInterface,
  ClientCardObj,
  ScoringSessionsObj,
  SaveHouseHoldCards,
  ClientScoringSession
} from '../objects/honestConversations'

export interface HonestConversationExercisesState {
  [householdId: string]: HonestConversationsExerciseInterface
}

const initState: HonestConversationExercisesState = {}

const mapHouseholdCards = (householdCards: HouseholdCardObj[]) => {
  const obj: HouseholdCardObjInterface = {}
  if (!householdCards) {
    return obj
  }
  householdCards.map((householdCard: HouseholdCardObj) => {
    obj[householdCard.id] = {
      id: householdCard.id,
      card: householdCard.card,
      commentPrimary: householdCard.commentPrimary,
      commentSecondary: householdCard.commentSecondary,
      ranking: householdCard.ranking
    }
  })
  return obj
}

const mapClientCards = (clientCards: ClientCardObj) => {
  const obj: ClientCardObjInterface = {}
  if (!clientCards) {
    return obj
  }

  Object.keys(clientCards).forEach((key) => {
    const arr: ClientCardObj[] = []
    const client = clientCards[key]
    client.map((card: ClientCardObj) => {
      arr.push(card)
      obj[key] = arr
    })
  })
  return obj
}

const mappingCardScoresObj = (cardScores: any) => {
  const obj: any = {}
  if (!cardScores) {
    return obj
  }
  cardScores.map((cardScore: any) => {
    obj[cardScore.id] = {
      comment: cardScore.comment,
      score: cardScore.score,
      householdCard: cardScore.householdCard
    }
  })
  return obj
}

const mapClientScoringSessions = (clientScoringSessions: any) => {
  const obj: any = {}
  if (!clientScoringSessions) {
    return obj
  }
  Object.keys(clientScoringSessions).forEach((clientId: string) => {
    const clientScoringSession = clientScoringSessions[clientId]
    clientScoringSession.map((scoringSession: any) => {
      obj[clientId] = {
        ...obj[clientId],
        [scoringSession.id]: {
          exerciseId: scoringSession.exerciseId,
          createdDate: scoringSession.createdDate,
          cardScores: mappingCardScoresObj(scoringSession.cardScores)
        }
      }
    })
  })
  return obj
}

const mapHcExercise = (
  exercise: any,
  stateExercise: HonestConversationsExerciseObj | {}
) => {
  if (stateExercise) {
    return {
      ...stateExercise,
      id: exercise.id,
      householdCards: mapHouseholdCards(exercise.householdCards),
      clientCards: mapClientCards(exercise.clientCards),
      clientScoringSessions: mapClientScoringSessions(exercise.scores),
      emailSentPrimary: exercise.emailSentPrimary,
      emailSentSecondary: exercise.emailSentSecondary,
      activeScoringSessionPrimary: exercise.activeScoringSessionPrimary,
      activeScoringSessionSecondary: exercise.activeScoringSessionSecondary,
      createdDate: exercise.createdDate
    }
  }
  return {
    id: exercise.id,
    householdCards: mapHouseholdCards(exercise.householdCards),
    clientCards: mapClientCards(exercise.clientCards),
    clientScoringSessions: mapClientScoringSessions(exercise.scores),
    emailSentPrimary: exercise.emailSentPrimary,
    emailSentSecondary: exercise.emailSentSecondary,
    activeScoringSessionPrimary: exercise.activeScoringSessionPrimary,
    activeScoringSessionSecondary: exercise.activeScoringSessionSecondary,
    createdDate: exercise.createdDate
  }
}

const mapHcExercises = (
  exercises: any,
  newState: HonestConversationExercisesState
) => {
  const obj = {}
  exercises.forEach((exercise: any) => {
    if (!obj[exercise.householdId]) {
      obj[exercise.householdId] = { ...newState[exercise.householdId] }
    }
    if (
      newState[exercise.householdId] &&
      newState[exercise.householdId][exercise.id]
    ) {
      obj[exercise.householdId][exercise.id] = mapHcExercise(
        exercise,
        newState[exercise.householdId][exercise.id]
      )
    } else {
      obj[exercise.householdId][exercise.id] = mapHcExercise(exercise, {})
    }
  })
  return obj
}

const addScoringSessionToExercise = (
  exercise: HonestConversationsExerciseObj,
  newScoringSession: any
) => {
  const { id, clientId, scoredWithAdvisor, isPrimary } = newScoringSession
  if (!exercise.clientScoringSessions[clientId]) {
    exercise.clientScoringSessions[clientId] = {}
  }
  const clientScoringSessions = exercise.clientScoringSessions[clientId]
  clientScoringSessions[id] = {
    id,
    clientId,
    scoredWithAdvisor
  }

  clientScoringSessions.new = {
    id,
    clientId,
    scoredWithAdvisor
  }
  const activeScoringId: {
    activeScoringSessionPrimary?: string
    activeScoringSessionSecondary?: string
  } = {}
  if (isPrimary === true) {
    activeScoringId.activeScoringSessionPrimary = id
  }
  if (isPrimary === false) {
    activeScoringId.activeScoringSessionSecondary = id
  }
  return {
    ...exercise,
    ...activeScoringId,
    clientScoringSessions: {
      ...exercise.clientScoringSessions,
      [clientId]: {
        ...clientScoringSessions
      }
    }
  }
}

const addHouseholdCards = (
  exercises: any,
  householdCardResponse: SaveHouseHoldCards,
  reset: boolean = false
) => {
  const { householdFinId, exerciseId, householdCards } = householdCardResponse
  const householdExercise: HonestConversationsExerciseObj =
    exercises[householdFinId][exerciseId]
  if (householdExercise) {
    if (reset) {
      householdExercise.householdCards = {}
    }
    householdCards.forEach((card) => {
      householdExercise.householdCards[card.id] = { ...card }
    })
  }
  return exercises
}

const sessionCardScores = (exercises: any, scoredSessionResponse: any) => {
  const {
    contactId,
    householdFinId,
    exerciseId,
    scoringSessionId,
    scores
  } = scoredSessionResponse
  const householdExercise: HonestConversationsExerciseInterface =
    exercises[householdFinId][exerciseId]
  const clientScoringSessions =
    householdExercise.clientScoringSessions[contactId][scoringSessionId]
  const newScores: ClientScoringSession = {}
  Object.keys(scores).forEach((key: string) => {
    const newScore: ScoringSessionsObj = scores[key]
    newScores[newScore.id] = newScore
  })
  clientScoringSessions.scores = newScores
  return exercises
}

const mapUpdatedHcExercise = (exercises: any, newExercise: any) => {
  exercises[newExercise.householdId][newExercise.id] = mapHcExercise(
    newExercise,
    exercises[newExercise.householdId][newExercise.id]
  )
  return exercises
}

const mapUpdatedScoreCardAndComment = (
  activeExercise: HonestConversationsExerciseObj,
  responseData: {
    contactId: string
    scoringSessionId: string
    updatedCards: ScoringSessionsObj[]
  }
) => {
  const { contactId, scoringSessionId, updatedCards = [] } = responseData
  const scoringSession =
    activeExercise.clientScoringSessions[contactId][scoringSessionId]
  updatedCards.forEach((updatedCard) => {
    if (!scoringSession.cardScores[updatedCard.id]) {
      scoringSession.cardScores[updatedCard.id] = {
        score: updatedCard.score,
        comment: updatedCard.comment,
        cardScoreId: updatedCard.id,
        householdCard:
          activeExercise.householdCards[updatedCard.householdCardId]
      }
    } else {
      scoringSession.cardScores[updatedCard.id].score = updatedCard.score
      scoringSession.cardScores[updatedCard.id].comment =
        updatedCard.comment || ''
    }
  })

  return scoringSession
}

// TODO: Clean up duplicate cases here
const HonestConversations = (
  state = initState,
  action: any
): HonestConversationExercisesState => {
  const newState: HonestConversationExercisesState = JSON.parse(
    JSON.stringify(state)
  )

  switch (action.type) {
    case `${actions.FETCH_HONEST_CONVERSATION_EXERCISES}_FULFILLED`:
      return {
        ...newState,
        ...mapHcExercises(action.payload.data.data, newState)
      }
    case `${actions.FETCH_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
      if (!newState[action.payload.data.householdId]) {
        newState[action.payload.data.householdId] = {}
      }
      newState[action.payload.data.householdId][
        action.payload.data.id
      ] = mapHcExercise(
        action.payload.data,
        newState[action.payload.data.householdId][action.payload.data.id]
      )
      return newState
    case `${actions.CREATE_SCORING_SESSIONS_AND_SET_AS_ACTIVE}_FULFILLED`:
    case `${actions.CREATE_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
      if (!newState[action.payload.data.householdId]) {
        newState[action.payload.data.householdId] = {}
      }
      const newExercise = mapHcExercise(
        action.payload.data,
        newState[action.payload.data.householdId][action.payload.data.id]
      )
      newState[action.payload.data.householdId][
        action.payload.data.id
      ] = newExercise
      newState[action.payload.data.householdId].new = newExercise
      return newState
    case `${actions.DELETE_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
      delete newState[action.payload.householdFinId][action.payload.exerciseId]
      return newState
    case `${actions.UPDATE_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
      const newHc = mapUpdatedHcExercise(newState, action.payload.data)
      return { ...newState, ...newHc }
    case `${actions.SAVE_HONEST_CONVERSATION_HOUSEHOLD_CARDS}_FULFILLED`:
      return { ...newState, ...addHouseholdCards(newState, action.payload) }
    case `${actions.UPDATE_HONEST_CONVERSATION_HOUSEHOLD_CARDS}_FULFILLED`:
      return {
        ...newState,
        ...addHouseholdCards(newState, action.payload, true)
      }
    case `${actions.DELETE_HONEST_CONVERSATION_HOUSEHOLD_CARDS}_FULFILLED`:
      delete newState[action.payload.data.householdFinId][
        action.payload.data.exerciseId
      ].householdCards[action.payload.data.householdCardId]
      return newState
    case `${actions.CREATE_SCORING_SESSIONS}_FULFILLED`:
      const householdFinId = action.payload.data.householdFinId
      const exerciseId = action.payload.data.exerciseId
      const exercise = addScoringSessionToExercise(
        newState[householdFinId][exerciseId],
        action.payload.data
      )
      newState[householdFinId][exerciseId] = exercise
      return newState
    case `${actions.CREATE_SCORING_SESSION_SCORE}_FULFILLED`:
      return { ...newState, ...sessionCardScores(newState, action.payload) }
    case `${actions.CREATE_EXERCISE_WITH_CARDS_AND_SCORES}_FULFILLED`:
      if (!newState[action.payload.data.householdId]) {
        newState[action.payload.data.householdId] = {}
      }
      newState[action.payload.data.householdId][
        action.payload.data.id
      ] = mapHcExercise(
        action.payload.data,
        newState[action.payload.data.householdId][action.payload.data.id]
      )
      return newState
    case `${actions.CREATE_EXERCISE_CLIENT_CARD}_FULFILLED`:
      newState[action.payload.householdFinId][
        action.payload.exerciseId
      ].clientCards[action.payload.clientId].push(action.payload.clientCards[0])
      return newState
    case `${actions.UPDATE_SCORING_SESSION}_FULFILLED`:
      return newState
    case `${actions.CREATE_SCORE_AND_UPDATE_SESSION}_FULFILLED`:
      const cardScores = mappingCardScoresObj(
        action.payload.data.cardScores || []
      )
      newState[action.payload.householdFinId][
        action.payload.data.exerciseId
      ].clientScoringSessions[action.payload.data.clientId][
        action.payload.data.id
      ] = { ...action.payload.data, ...{ cardScores } }
      return newState
    case `${actions.DELETE_SCORING_SESSION}_FULFILLED`:
      const { payload } = action
      delete newState[payload.householdFinId][payload.exerciseId]
        ?.clientScoringSessions?.[payload.clientId]?.[payload.scoringSessionId]
      return newState
    case `${actions.GET_HC_LOGIN_TOKEN}_FULFILLED`:
    case `${actions.CREATE_HC_LOGIN_TOKEN}_FULFILLED`:
      if (!newState[action.payload.householdId]) {
        newState[action.payload.householdId] = {}
      }
      if (
        !newState[action.payload.householdId][
          action.payload.honestConversationExerciseId
        ]
      ) {
        newState[action.payload.householdId][
          action.payload.honestConversationExerciseId
        ] = { ...action.payload }
      }
      if (action.payload.isPrimary) {
        newState[action.payload.householdId][
          action.payload.honestConversationExerciseId
        ].primaryLoginCode = action.payload.loginCode
      } else {
        newState[action.payload.householdId][
          action.payload.honestConversationExerciseId
        ].secondaryLoginCode = action.payload.loginCode
      }
      return newState
    case `${actions.UPDATE_SCORING_SESSION_SCORE_CARD}_FULFILLED`:
      const { data } = action.payload
      const activeExercise: HonestConversationsExerciseObj =
        newState[data.householdFinId][data.exerciseId]
      const updatedScoreCards = mapUpdatedScoreCardAndComment(
        activeExercise,
        data
      )
      newState[data.householdFinId][data.exerciseId].clientScoringSessions[
        data.contactId
      ][data.scoringSessionId] = updatedScoreCards
      return newState
    case `${actions.UPDATE_EXERCISE_CLIENT_CARDS}_FULFILLED`:
      const reducerClientcards =
        newState &&
        action.payload &&
        action.payload.householdFinId &&
        action.payload.exerciseId &&
        newState[action.payload.householdFinId][action.payload.exerciseId]
          .clientCards[action.payload.clientId]
      if (reducerClientcards) {
        reducerClientcards.forEach((item, index) => {
          action.payload.clientCards.forEach((card: ClientCardObj) => {
            if (item.id === card.id) {
              reducerClientcards[index] = card
            }
          })
        })
        newState[action.payload.householdFinId][
          action.payload.exerciseId
        ].clientCards[action.payload.clientId] = reducerClientcards
      }
      return newState
    default:
      return newState
  }
}

export default HonestConversations
