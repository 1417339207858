import * as userPreferencesActions from '../actions/userPreferences'
import { UserPreferencesObj } from '../objects/user'
import { isObjEmpty } from '../v3/helpers'

export interface UserPreferencesState {
  [key: string]: UserPreferencesObj
}

const initState: UserPreferencesState = {}

const UserPreferences = (
  state = initState,
  action: any
): UserPreferencesState => {
  const newState = JSON.parse(JSON.stringify(state))
  const data = action.payload?.data
  switch (action.type) {
    case `${userPreferencesActions.GET_USER_PREFERENCES}_FULFILLED`:
      if (isObjEmpty(data)) {
        return {}
      }
      const preferences = data ? mapPreferences(data) : null
      if (preferences) {
        newState[preferences.id] = preferences
      }
      return newState
    case `${userPreferencesActions.UPSERT_USER_PREFERENCES}_FULFILLED`:
      if (data?.[0]?.id) {
        newState[data[0].id] = mapPreferences(data[0])
      }
      return newState
    default:
      return state
  }
}

const mapSortingString = (sortString: string) => {
  let newString
  switch (sortString) {
    case 'Last GC Login':
      newString = 'lastLogin'
      break
    case 'Honest Conversations':
      newString = 'hcProgress'
      break
    case 'Investment':
      newString = 'ivProgress'
      break
    case 'Goals':
      newString = 'goalsProgress'
      break
    case 'Money Mind':
      newString = 'mmProgress'
      break
    case 'Net Worth':
      newString = 'netWorth'
      break
    case 'Score':
      newString = 'latestFundedScore'
      break
    case 'Tasks':
      newString = 'taskCount'
      break
    case 'Name':
      newString = 'name'
      break
    default:
      newString = 'progress'
  }
  return newString
}

const mapPreferences = (preferences: UserPreferencesObj) => {
  const responseToState = { ...preferences }
  responseToState.clientListSortBy = mapSortingString(
    responseToState.clientListSortBy
  )
  return responseToState
}

export default UserPreferences
