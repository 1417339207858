import api from '../v3/helpers/api'
export const FETCH_VIDEO_URL_DATA: string = 'FETCH_VIDEO_URL_DATA'
export const FETCH_THUMBNAIL_URL_DATA: string = 'FETCH_THUMBNAIL_URL_DATA'

/**
 * This action will fetch a temporary url for thumbnail/video message
 *
 * @param householdFinId id of household
 * @param threadId id of parent thread
 * @param messageId id of message that relates to the video
 * @param dataId UUID of thumbnail or video
 * @param type the type of data ie thumbnail or video
 * @returns temporary url of the thumbnail/video
 */
export const getVideoData = (
  householdFinId: string,
  threadId: string,
  messageId: string,
  dataId: string,
  type: 'video' | 'thumbnail'
) => {
  const key = `${type}Url`
  return {
    type: `FETCH_${type?.toUpperCase()}_URL_DATA`,
    payload: api()
      .get(`/households/${householdFinId}/videomessage/${dataId}`)
      .then((response) => {
        response.data[key] = response.data.url
        response.data.messageId = messageId
        response.data.type = type
        response.data.threadId = threadId
        response.data.householdFinId = householdFinId
        return response
      }),
    meta: {
      householdFinId,
      threadId,
      messageId,
      type
    }
  }
}
