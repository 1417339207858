import * as actions from '../actions/portfolioProposal'

import { PortfolioProposalInterface } from '../objects/portfolioProposal'

const initState: PortfolioProposalInterface = {}

const mapPortfolioProposal = (
  portfolioproposal: any
): PortfolioProposalInterface => {
  if (portfolioproposal) {
    return {
      id: portfolioproposal.id,
      name: portfolioproposal.name,
      sections: portfolioproposal.sections,
      pdfUrl: portfolioproposal.pdfUrl ? portfolioproposal.pdfUrl : null,
      createdDate: portfolioproposal.createdDate
    }
  } else return null
}

const mapPortfolioProposals = (portfolioProposals: any) => {
  const obj = {}
  portfolioProposals
    .filter(
      (portfolioProposal: any) =>
        portfolioProposal.pdfUrl && portfolioProposal.pdfUrl.length > 0
    )
    .sort((a: any, b: any) => {
      if (a.createdDate < b.createdDate) return 1
      if (a.createdDate > b.createdDate) return -1
      return 0
    })
    .forEach((portfolioProposal: any) => {
      obj[portfolioProposal.id] = mapPortfolioProposal(portfolioProposal)
    })
  return obj
}

const portfolioProposal = (
  state = initState,
  action: any
): PortfolioProposalInterface => {
  const newState = JSON.parse(JSON.stringify(state))
  let newPortfolioProposals = null
  let householdFinId = null
  switch (action.type) {
    case `${actions.FETCH_PORTFOLIOPROPOSAL}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newState[householdFinId] = newState[householdFinId]
        ? newState[householdFinId]
        : {}
      newState[householdFinId] = {
        ...newState[householdFinId],
        currentPortfolioProposalId: '',
        portfolioProposals: {
          ...mapPortfolioProposals(action.payload.data),
          ...newState[householdFinId].portfolioProposals
        }
      }
      return newState
    case `${actions.CREATE_PORTFOLIOPROPOSAL}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newState[householdFinId] = newState[householdFinId]
        ? newState[householdFinId]
        : {}
      newPortfolioProposals = newState[householdFinId].portfolioProposals
        ? newState[householdFinId].portfolioProposals
        : {}
      newPortfolioProposals[action.payload.data.id] = mapPortfolioProposal(
        action.payload.data
      )
      newState[householdFinId] = {
        ...newState[householdFinId],
        portfolioProposals: newPortfolioProposals,
        currentPortfolioProposalId: action.payload.data.id
      }
      return newState
    case `${actions.DELETE_PORTFOLIOPROPOSAL}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newPortfolioProposals = newState[householdFinId].portfolioProposals
      delete newPortfolioProposals[action.payload.data.portfolioProposalFinId]
      newState[householdFinId] = {
        ...newState[householdFinId],
        portfolioProposals: newPortfolioProposals,
        currentPortfolioProposalId: ''
      }
      return newState
    case `${actions.UPLOAD_PORTFOLIOPROPOSAL_PDF}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newPortfolioProposals = newState[householdFinId].portfolioProposals
      newPortfolioProposals[action.payload.data.portfolioProposalFinId].pdfUrl =
        action.payload.data.pdfUrl
      newState[householdFinId] = {
        ...newState[householdFinId],
        portfolioProposals: newPortfolioProposals,
        currentPortfolioProposalId: ''
      }
      return newState
    case `${actions.DELETE_PORTFOLIOPROPOSAL_PDF}_FULFILLED`:
      householdFinId = action.payload.data.householdFinId
      newPortfolioProposals = newState[householdFinId].portfolioProposals
      newPortfolioProposals[
        action.payload.data.portfolioProposalFinId
      ].pdfUrl = null
      newState[householdFinId] = {
        ...newState[householdFinId],
        portfolioProposals: newPortfolioProposals,
        currentPortfolioProposalId: ''
      }
      return newState
    default:
      return state
  }
}

export default portfolioProposal
