import React from 'react'

import { ContactObj } from '../../../objects/contact'
import { LoginHistoryState } from '../../../reducers/loginHistory'

import TableRow from '../../components/layout/tableRow'
import TableHeader from '../../components/layout/tableHeader'

import { dateFormat, timeFormat } from '../../helpers'

interface SettingsLoginHistoryProps {
  loginHistory?: LoginHistoryState
  contact?: ContactObj
}

interface SettingsLoginHistoryState {
  offset: number
}

const NUMBER_OF_NEW_ROWS = 40

const loginHistoryStatusMessages = {
  'Failed: Invalid Portal ID': `Failure: Incorrect Username`,
  'Password Lockout': `Failure: Account Locked`,
  'Invalid Password': `Failure: Incorrect Password`,
  'Passwordless Login required': `Failure: Passwordless login required`,
  'Restricted IP': `Failure: Restricted IP`,
  Success: 'Success'
}

class SettingsLoginHistory extends React.Component<
  SettingsLoginHistoryProps,
  SettingsLoginHistoryState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      offset: 0
    }
  }

  public handleScrollTable = (e: React.UIEvent<HTMLDivElement>) => {
    const { offset } = this.state
    // leave a 20 pixel buffer for a smoother experience
    const BUFFER = 20
    const bottom =
      e.currentTarget.scrollHeight -
        Math.ceil(e.currentTarget.scrollTop + BUFFER) <=
      e.currentTarget.clientHeight
    if (bottom) {
      this.setState({ offset: offset + NUMBER_OF_NEW_ROWS })
    }
  }

  public render() {
    const { loginHistory, contact } = this.props
    const { offset } = this.state
    const clientLoginHistory = loginHistory[contact.id]
    return (
      <div onScroll={this.handleScrollTable}>
        {clientLoginHistory ? (
          <>
            <TableHeader>
              <div className='c-settings-login-history__column--header'>
                Login
              </div>
              <div className='c-settings-login-history__column--header-two'>
                Status
              </div>
            </TableHeader>
            <div className='c-settings-login-history__list'>
              {clientLoginHistory &&
                clientLoginHistory?.map((history: any, index: number) => {
                  if (index <= offset + NUMBER_OF_NEW_ROWS) {
                    return (
                      <TableRow key={index}>
                        <div className='c-settings-login-history__column--name'>
                          {dateFormat(history.loginDate)}{' '}
                          {timeFormat(history.loginDate)}
                        </div>
                        <div
                          className={
                            history.loginStatus === 'Success'
                              ? 'c-settings-login-history__column--name c-settings-login-history__success-status'
                              : 'c-settings-login-history__column--name c-settings-login-history__err-status'
                          }>
                          {loginHistoryStatusMessages[history.loginStatus] ||
                            `Failure: Technical Error`}
                        </div>
                      </TableRow>
                    )
                  }
                  return null
                })}
            </div>
          </>
        ) : null}
      </div>
    )
  }
}

export default SettingsLoginHistory
