import React, { Component, CSSProperties, FormEvent } from 'react'
import {
  Card,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import pencil from '../../../../assets/images/honestConversations/pencil.png'
import couple from '../../../../assets/images/honestConversations/couple.png'
import switchIcon from '../../../../assets/images/honestConversations/switch.png'
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/svg/close.svg'
import { ReactComponent as CommentBubbleIcon } from '../../../../assets/images/icons/comment.svg'
import HonestConversationCardSelectModal from '../../meeting/hcCardSelectModal'
import {
  ClientCardObj,
  CardRankingObj
} from '../../../../../objects/honestConversations'
import { updateClientCards } from '../../../../../actions/hcExercises'
import { GlobalState } from '../../../../../reducers'
import { Dispatch, connect } from 'react-redux'
import { CardObj } from '../../../../../objects/HonestConversations/card'

interface CardTileProps {
  card: ClientCardObj
  householdFinId: string
  exerciseId: string
  selectedCards: ClientCardObj[]
  index: number
  nextCard: number
  revealCards?: boolean
  isShared?: boolean
  dispatch: Dispatch<GlobalState>
  moveToNext?(index: number): void
  onSwitchCard(
    card: CardObj,
    index: number,
    exerciseId: string,
    clientId: string,
    previousCardSwitchedOut: CardObj
  ): void
}

interface CardTileState {
  showCardSelectModal: boolean
  comments: string
}

const btnStyle: CSSProperties = {
  margin: '0 18px 0 16px'
}

export class CardTile extends Component<CardTileProps, CardTileState> {
  constructor(props: CardTileProps) {
    super(props)
    this.state = {
      showCardSelectModal: false,
      comments: props.card.comment
    }
  }

  public showEditCardModal = () => {
    const { moveToNext, index } = this.props
    moveToNext(index)
  }
  public closeEditCardModal = () => {
    const { moveToNext } = this.props
    moveToNext(99)
  }

  public openCardSelectModal = () => {
    this.setState({ showCardSelectModal: true })
  }
  public closeCardSelectModal = () => {
    this.setState({ showCardSelectModal: false })
  }

  public setCardComments = (e: FormEvent<HTMLTextAreaElement>) => {
    const newState = this.state
    const { name, value } = e.currentTarget
    newState[name] = value
    this.setState(newState)
  }

  public saveComment = () => {
    const {
      moveToNext,
      card,
      dispatch,
      householdFinId,
      exerciseId,
      index
    } = this.props
    const { comments } = this.state
    const clientCards: CardRankingObj[] = [{ ...card, comment: comments }]
    dispatch(
      updateClientCards(householdFinId, exerciseId, card.clientId, clientCards)
    )
    // tell parent to move to next, if any
    moveToNext(index + 1)
  }

  public selectCard = (card: CardObj) => {
    const { selectedCards, index, exerciseId } = this.props

    // When a card is switched out, we need to remove if
    // was in the householdCards
    const previousCardSwitchedOut = selectedCards[this.props.index].card

    this.props.onSwitchCard(
      card,
      index,
      exerciseId,
      this.props.card.clientId,
      previousCardSwitchedOut
    )
  }

  public renderEditCardModal = (comments: string) => {
    const { index } = this.props

    return (
      <div
        className={
          index < 4
            ? 'hc-reveal__card-modal'
            : 'hc-reveal__card-modal hc-reveal__card-modal-last'
        }>
        <div
          className='hc-reveal__card-modal-close'
          onClick={this.closeEditCardModal}>
          <CloseIcon width='15px' />
        </div>
        <div className='hc-reveal__card-modal-title'>
          What does this card mean to you, and why is it important?
        </div>
        <textarea
          name='comments'
          value={comments}
          onChange={this.setCardComments}
        />
        <div className='hc-reveal__card-modal-btns'>
          <Button onClick={this.closeEditCardModal}>Cancel</Button>
          <Button btnStyle={btnStyle} onClick={this.saveComment}>
            Save
          </Button>
        </div>
      </div>
    )
  }

  public render() {
    const {
      index,
      card,
      nextCard,
      revealCards,
      isShared,
      selectedCards,
      householdFinId
    } = this.props
    const { comments } = this.state

    return (
      <div
        className={
          revealCards
            ? 'hc-reveal__card-w hc-reveal__card-w--margin'
            : 'hc-reveal__card-w hc-reveal__card-w--absolute'
        }>
        <Card category={card.card.category} title={card.card.title} />
        <div
          className='hc-reveal__card-comment'
          onClick={this.showEditCardModal}>
          {comments ? (
            <CommentBubbleIcon style={{ opacity: 0.9, width: '20px' }} />
          ) : (
            <img src={pencil} role='presentation' alt='' />
          )}
        </div>
        <div
          className='hc-reveal__card-switch'
          onClick={this.openCardSelectModal}>
          <img src={switchIcon} role='presentation' alt='' />
        </div>
        <div className='hc-reveal__card-shared'>
          {isShared ? <img src={couple} role='presentation' alt='' /> : null}
        </div>

        {index === nextCard && revealCards
          ? this.renderEditCardModal(comments)
          : null}

        {this.state.showCardSelectModal ? (
          <HonestConversationCardSelectModal
            householdFinId={householdFinId}
            selectedCards={selectedCards}
            selectCard={this.selectCard}
            closeModal={this.closeCardSelectModal}
          />
        ) : null}
      </div>
    )
  }
}

export default connect()(CardTile)
