import React from 'react'
import Modal from '../../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'
import { OfficeTeamObj } from '../../../../objects/officeTeams'
import { UserObj } from '../../../../objects/user'

interface DeleteTeamMemberModalProps {
  team: OfficeTeamObj
  member: UserObj
  deleteModal(id: string, memberId: string): void
  closeModal(): void
}

class DeleteTeamMemberModal extends React.Component<
  DeleteTeamMemberModalProps
> {
  public deleteTeamMember = () => {
    this.props.deleteModal(this.props.team.id, this.props.member.id)
  }
  public render() {
    const { closeModal, member } = this.props
    return (
      <Modal icon={DeleteIcon} title='Remove Member' closeModal={closeModal}>
        <div className='team-management__modal-body'>
          <p className='team-management__modal-text'>{`Are you sure you want to remove ${member.firstName} ${member.lastName}?`}</p>
        </div>

        <div className='team-management__modal-btns-w'>
          <span className='btn btn__clear' onClick={closeModal}>
            Cancel
          </span>
          <span className='btn btn__prime' onClick={this.deleteTeamMember}>
            Remove Member
          </span>
        </div>
      </Modal>
    )
  }
}

export default DeleteTeamMemberModal
