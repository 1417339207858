import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { setUserPreferences } from '../../../actions/userPreferences'
import { GlobalState } from '../../../reducers'
import { UserPreferencesState } from '../../../reducers/userPreferences'

interface HouseholdHeaderLabelProps {
  title: string
  userId: string
  dispatch: Dispatch<GlobalState>
  sortingString: string
  userPreferences: UserPreferencesState
}

interface HouseholdHeaderLabelState {
  arrowDown: boolean
}

class HouseholdHeaderLabel extends React.Component<
  HouseholdHeaderLabelProps,
  HouseholdHeaderLabelState
> {
  constructor(props: HouseholdHeaderLabelProps) {
    super(props)
    this.state = {
      arrowDown: true
    }
  }

  public toggle = async () => {
    const { arrowDown } = this.state
    const { dispatch, userId, sortingString } = this.props
    const sortOrder = arrowDown ? 'Descending' : 'Ascending'
    await dispatch(
      setUserPreferences({
        id: userId,
        clientListSortBy: this.mapSortingString(sortingString),
        clientListSortOrder: sortOrder
      })
    )
    this.setState({ arrowDown: !arrowDown })
  }

  public mapSortingString = (sortString: string) => {
    let newString
    switch (sortString) {
      case 'lastLogin':
        newString = 'Last GC Login'
        break
      case 'hcProgress':
      case 'hcProgress2':
        newString = 'Honest Conversations'
        break
      case 'ivProgress':
        newString = 'Investment'
        break
      case 'palProgress':
      case 'goalsProgress':
        newString = 'Goals'
        break
      case 'mmProgress':
        newString = 'Money Mind'
        break
      case 'netWorth':
        newString = 'Net Worth'
        break
      case 'latestFundedScore':
        newString = 'Score'
        break
      case 'taskCount':
        newString = 'Tasks'
        break
      case 'name':
        newString = 'Name'
        break
      default:
        newString = 'Progress'
    }
    return newString
  }
  public render() {
    const { title, userId, userPreferences, sortingString } = this.props
    const clientListSortBy = userPreferences?.[userId]?.clientListSortBy
    const clientListSortOrder = userPreferences?.[userId]?.clientListSortOrder
    const isCurrentLabelSorted =
      clientListSortBy && clientListSortBy === sortingString
    const labelClass = `households__column--${title
      .toLowerCase()
      .replace(' ', '-')}`
    return (
      <div className={`households__column ${labelClass}`}>
        <div className='households__label-w' onClick={this.toggle}>
          {title}
          {isCurrentLabelSorted && clientListSortOrder === 'Descending' ? (
            <span className='households__arrow--up' />
          ) : (
            <span className='households__arrow--down' />
          )}
        </div>
      </div>
    )
  }
}

export default connect((store: GlobalState) => {
  return {
    userId: store.user.userId,
    userPreferences: store.userPreferences
  }
})(HouseholdHeaderLabel)
