import React from 'react'
import Tile from '../../components/layout/tile'
import HorizontalBar from '../../components/horizontalBar'
import { dollarFormat, getNetWorthFromClientAccounts } from '../../helpers'
import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ClientAccountObjState } from '../../../objects/clientAccount'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow_right.svg'
import { HouseholdInstitution, HouseholdObj } from '../../../objects/household'
import { Link } from 'react-router-dom'

interface AssetsAndLiabilitiesTileProps {
  clientAccounts: ClientAccountObjState
  householdFinId: string
  household: HouseholdObj
  institution: HouseholdInstitution
}

class AssetsAndLiabilitiesTile extends React.Component<
  AssetsAndLiabilitiesTileProps
> {
  public assetsBar = (totalAssets: number, netWorth: number) => {
    const fillPercent =
      (totalAssets / netWorth) * 100 > 100
        ? 100
        : (totalAssets / netWorth) * 100
    return (
      <HorizontalBar
        barColor={
          fillPercent === 0 || isNaN(fillPercent) ? '#E4E4E4' : '#8CCC33'
        }
        fillPercent={fillPercent === 0 ? 100 : fillPercent}
      />
    )
  }
  public liabilitiesBar = (totalLiabilities: number, netWorth: number) => {
    const fillPercent =
      (totalLiabilities / netWorth) * 100 > 100
        ? 100
        : (totalLiabilities / netWorth) * 100
    return (
      <HorizontalBar
        barColor={
          fillPercent === 0 || isNaN(fillPercent) ? '#E4E4E4' : '#FFBE1A'
        }
        fillPercent={fillPercent === 0 ? 20 : fillPercent}
      />
    )
  }

  public isEmoneyLinkShouldVisible = () => {
    const { household, institution } = this.props
    return (
      ['emoney_enterprise', 'emoney'].includes(household.planningSoftware) &&
      !institution?.isFinlifeInstitution &&
      window?._env_?.REACT_APP_LINK_EMONEY_ENABLED
    )
  }

  public rightHeader = (netWorth: number) => {
    return (
      <div className='assets-liabilities__right-header'>
        <div className='assets-liabilities__right-header--value'>
          {dollarFormat(netWorth, 0)}
        </div>
      </div>
    )
  }
  public render() {
    const { clientAccounts, householdFinId } = this.props
    const {
      netWorth,
      netLiabilities,
      netAssets
    } = getNetWorthFromClientAccounts(clientAccounts)
    const hasClientAccounts =
      clientAccounts && Object.keys(clientAccounts).length > 0
    const netAssetsValue = dollarFormat(netAssets, 0)
    const netLiabilitesValue = dollarFormat(netLiabilities, 0)

    return (
      <Tile
        leftHeader='ASSETS & LIABILITIES'
        rightHeader={this.rightHeader(netWorth)}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        headerBorder={true}>
        <div className='assets-liabilities__w'>
          <div className='assets-liabilities__graph-w'>
            <div className='assets-liabilities__label'>Assets</div>
            {this.assetsBar(netAssets, netWorth)}
            <div
              className={
                !hasClientAccounts
                  ? 'assets-liabilities__value al-tile-null__assets'
                  : 'assets-liabilities__value'
              }>
              {netAssetsValue}
            </div>
          </div>
          <div className='assets-liabilities__graph-w'>
            <div className='assets-liabilities__label'>Liabilities</div>
            {this.liabilitiesBar(netLiabilities, netWorth)}
            <div
              className={
                !hasClientAccounts
                  ? 'assets-liabilities__value al-tile-null__assets'
                  : 'assets-liabilities__value'
              }>
              {netLiabilitesValue}
            </div>
          </div>
        </div>
        <Link
          to={`/households/${householdFinId}/investments`}
          className='assets-liabilities__investments-w'>
          <div>View Investments</div>
          <div className='assets-liabilities__investments-icon'>
            <ArrowRightIcon />
          </div>
        </Link>
      </Tile>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdId = match.params.householdFinId
  return {
    clientAccounts:
      store.clientAccount[householdId] &&
      store.clientAccount[householdId].clientAccounts,
    householdFinId: householdId,
    household: store.households[householdId],
    institution: store.households[householdId].institution
  }
}

export default withRouter(connect(mapStateToProps)(AssetsAndLiabilitiesTile))
