import React, { MouseEvent } from 'react'
import { history } from '../../../store'

interface HouseholdTextDisplayProps {
  text: string | number
  className?: string
  to?: string
}

class HouseholdTextDisplay extends React.Component<HouseholdTextDisplayProps> {
  public onClick = (e: MouseEvent<HTMLDivElement>) => {
    const { to } = this.props
    history.push(to)
    if (e.stopPropagation) {
      e.stopPropagation()
    }
  }
  public render() {
    const { text, className, children } = this.props
    return (
      <div className={`households__column ${className}`} onClick={this.onClick}>
        <div className='household-text-display-w'>{text}</div>
        {children}
      </div>
    )
  }
}

export default HouseholdTextDisplay
