import React, { Component } from 'react'
import { DragSource } from 'react-dnd'

import { dollarFormat } from '../../../helpers'
import { accountType } from '../../../../objects/strategies'
import Tooltip from '../../../components/tooltip'

import view_performance from '../../../assets/images/icons/view_performance.svg'
import view_protection from '../../../assets/images/icons/view_protection.svg'
import view_cost from '../../../assets/images/icons/view_cost.svg'
import view_tax from '../../../assets/images/icons/view_tax.svg'
import warning_icon from '../../../assets/images/icons/warning_icon.png'

interface CardProps {
  yieldValue: number
  investmentMinimum: number
  type: string
  value: number
  name: string
  id: number
  connectDragSource?: any
  isDragging?: boolean
  fees: number
  restrictedFromQualifiedAccounts: boolean
}

const cardSource = {
  beginDrag(props: CardProps) {
    const { type, value, name, id } = props
    return { type, name, value, id }
  }
}

const collect = (connect: any, monitor: any) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class StrategyCard extends Component<CardProps> {
  constructor(props: CardProps) {
    super(props)
  }

  public convertTypeName = (type: string) => {
    switch (type) {
      case 'Performance':
        return 'Performance'
      case 'Tax Minimization':
        return 'Taxes'
      case 'Low Cost Tracking':
        return 'Costs'
      case 'Protection':
        return 'Protection'
      default:
        return null
    }
  }

  public stratData = () => {
    const {
      name,
      yieldValue,
      fees,
      investmentMinimum,
      restrictedFromQualifiedAccounts
    } = this.props
    return (
      <div className='client-strategies__favorite-main'>
        <div className='client-strategies__favorite-image'>
          {restrictedFromQualifiedAccounts ? (
            <Tooltip
              position='right'
              width={250}
              message='Not available for any retirement accounts or similar tax-qualified accounts (i.e., IRAs, tax-qualified plans, Keogh plans, ERISA Accounts, Coverdell ESAs)'>
              <img src={warning_icon} alt='$' style={{ marginTop: '5px' }} />
            </Tooltip>
          ) : null}
        </div>
        <div className='client-strategies__favorite-heading-w'>
          <div className='client-strategies__favorite-heading'>
            <span>{name}</span>
          </div>
          <div className='client-strategies__favorite-wrapper'>
            <Tooltip
              position='bottom'
              width={160}
              message='Account minimum is the minimum dollar amount on the account for this strategy to be applied.'>
              <span className='client-strategies__favorite-text-balance'>
                {dollarFormat(investmentMinimum, 0)}
              </span>
            </Tooltip>
            <div className='client-strategies__favorite-text-ratio'>
              Yield {yieldValue ? Math.round(yieldValue * 100) / 100 : 0}%
            </div>
            <div className='client-strategies__favorite-text-ratio'>
              {fees ? `Fees ${fees}%` : 'Fees 0%'}
            </div>
          </div>
        </div>
      </div>
    )
  }

  public gettype = (value: string) => {
    if (value === accountType.protection) {
      return view_protection
    } else if (value === accountType.performance) {
      return view_performance
    } else if (value === accountType.taxMinimization) {
      return view_tax
    } else if (value === accountType.lowCostTracking) {
      return view_cost
    } else {
      return null
    }
  }
  public render() {
    const { connectDragSource, isDragging } = this.props
    return connectDragSource(
      <div className='client-strategies__favorite'>
        {isDragging ? <div style={{ height: '46px' }} /> : this.stratData()}
      </div>
    )
  }
}

export default DragSource('item', cardSource, collect)(StrategyCard)
