import * as actions from '../actions/insurance'
import { InsuranceInterface } from '../objects/insurance'

export interface InsuranceState {
  [key: string]: InsuranceInterface
}

const initState: InsuranceState = {}

const mapInsurance = (
  householdFinId: string,
  insurances: any[]
): InsuranceState => {
  const insuranceList: InsuranceInterface = {}
  insurances.forEach((insurance) => {
    insuranceList[insurance.id] = insurance
  })
  const obj = {}
  obj[householdFinId] = insuranceList
  return obj
}

const Insurance = (state = initState, action: any): InsuranceState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_INSURANCE_FULFILLED:
      const insurances = mapInsurance(
        action.payload.data.householdFinId,
        action.payload.data
      )
      return { ...state, ...insurances }
    case actions.ADD_INSURANCE_FULFILLED:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][action.payload.data.id] =
        action.payload.data
      return newState
    case actions.UPDATE_INSURANCE_FULFILLED:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][action.payload.data.id] =
        action.payload.data
      return newState
    case actions.UPDATE_ACTIVE_INSURANCE_FULFILLED:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][action.payload.data.id] =
        action.payload.data
      return newState
    case actions.DELETE_INSURANCE_FULFILLED:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {}
      }
      newState[action.payload.data.householdFinId][action.payload.data.id] =
        action.payload.data
      return newState
    default:
      return state
  }
}

export default Insurance
