import React from 'react'
import moment from 'moment'
import { Dispatch, connect } from 'react-redux'

import { updateTaskById } from '../../../../actions/tasks'
import { GlobalState } from '../../../../reducers'
import {
  GoalResponse,
  GoalTemplatesResponse
} from '../../../../objects/financialGoalActions'

import { ContactsInterface } from '../../../../objects/contact'
import { InstitutionObj } from '../../../../objects/institution'
import { TaskStatusSelection } from '../../../../objects/tasks'

import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'
import DateIcon from '../../../assets/images/icons/goals/goal-date-icon.png'
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/svg/delete.svg'
import CustomDatepicker from '../../customDatepicker'
import ToggleSwitch from '../../../components/toggleSwitch'
import Tooltip from '../../../components/tooltip'
import { TaskTextArea } from '../inputs/taskTextArea'
import { TaskAssignType } from '../inputs/tasksAssignType'
import {
  AssigneeSelect,
  SelectOptionType
} from '../picklist/taskAssigneeSelect'
import { StatusSelect } from '../picklist/taskStatusSelect'
import ClientNotificationStatusWarning from '../clientNotificationStatus'
import { OfficeTeamObj } from '../../../../objects/officeTeams'
import { ValueType } from 'react-select/lib/types'
import {
  assigneeIsClient,
  assigneeIsServiceMember
} from '../../../helpers/tasks'

import { staticText } from '../../../helpers/resources'
import { ReactComponent as HelpIcon } from '../../../assets/images/icons/help.svg'
export interface SideBarBodyProps {
  activeGoal?: GoalResponse
  contacts: ContactsInterface
  officeTeams: OfficeTeamObj[]
  tasksState: any
  householdFinId: string
  suggestedGoalId?: string
  suggestedGoal?: GoalTemplatesResponse
  honestConversation?: HonestConversationsExerciseObj
  setViewKey(id: number): void
  dispatch: Dispatch<GlobalState>
  sideBarRef?: React.Ref<HTMLDivElement>
  institution: InstitutionObj
  goalCls?: string
  activeTask: any
  viewMode: string
  deleteTask(): void
}

interface SideBarBodyState {
  subject: string
  assigneeNames: string
  advisorAssignee: string
  primaryAssignee: string
  secondaryAssignee: string
  assigneeType: string
  selectedAssignee: ValueType<SelectOptionType>
  selectedStatus: ValueType<SelectOptionType>
  status: string
  dueDate: any
  showHideToggle: boolean
  statusToggle: boolean
  completedDate: string
}

export class SideBarBody extends React.Component<
  SideBarBodyProps,
  SideBarBodyState
> {
  constructor(props: SideBarBodyProps) {
    super(props)
    this.state = {
      subject: '',
      assigneeNames: '',
      advisorAssignee: null,
      primaryAssignee: null,
      secondaryAssignee: null,
      assigneeType: 'client',
      selectedAssignee: null,
      selectedStatus: null,
      status: '',
      dueDate: moment(),
      showHideToggle: true,
      statusToggle: false,
      completedDate: ''
    }
  }

  public componentDidMount() {
    const { activeTask, contacts, officeTeams } = this.props

    const isClientAssignedTask = assigneeIsClient(activeTask, contacts)
    const isUserAssignedTask = assigneeIsServiceMember(activeTask, officeTeams)

    let nativeActiveTask = {
      ...activeTask
    }

    if (nativeActiveTask.id !== 'NEW') {
      nativeActiveTask = {
        ...nativeActiveTask,
        assigneeType:
          !isClientAssignedTask && isUserAssignedTask ? 'user' : 'client'
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      subject: activeTask?.subject,
      assigneeType: nativeActiveTask?.assigneeType || 'client',
      assigneeNames: activeTask?.assigneeNames,
      status: activeTask?.status,
      showHideToggle: activeTask?.includeInGuidebook,
      dueDate: activeTask?.dueDate ? activeTask?.dueDate : null,
      completedDate: activeTask?.completedDate,
      selectedStatus: TaskStatusSelection.find(
        (selection) => selection.value === activeTask?.status
      )
    }))
  }

  public handleShowHideToggle = () => {
    this.setState({ showHideToggle: !this.state.showHideToggle })
  }

  public toggleStatusDropdown = () => {
    this.setState({
      statusToggle: !this.state.statusToggle
    })
  }

  public handleClickOutside = () => {
    this.setState({
      statusToggle: false
    })
  }

  public updateTextArea = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget
    if (this.state[name] !== value) {
      this.setState(
        (prevState) => ({
          ...prevState,
          [name]: value
        }),
        () => this.syncWithStore()
      )
    }
  }

  public dateChange = (dueDate: any) => {
    this.setState({
      dueDate
    })
  }

  public syncWithStore = () => {
    const { householdFinId, activeTask, dispatch } = this.props
    const {
      subject,
      assigneeNames,
      assigneeType,
      status,
      dueDate,
      showHideToggle,
      completedDate,
      advisorAssignee,
      primaryAssignee,
      secondaryAssignee
    } = this.state
    const checkInDate = (dueDate && moment(dueDate).format()) || null

    dispatch(
      updateTaskById(
        {
          ...activeTask,
          ...{
            subject,
            assigneeNames,
            assigneeType,
            status,
            advisorAssignee,
            primaryAssignee,
            secondaryAssignee,
            dueDate: checkInDate,
            completedDate,
            includeInGuidebook: showHideToggle
          }
        },
        householdFinId
      )
    )
  }

  public getStatusLabel = () => {
    const taskStatus = TaskStatusSelection.find((task) => {
      return task.value === this.state.status
    })
    return taskStatus?.label || ''
  }

  public setAssignType = (event: React.FormEvent<HTMLInputElement>) => {
    const { value: assigneeType } = event.currentTarget
    this.setState(
      {
        assigneeType,
        assigneeNames: null,
        selectedAssignee: null
      },
      () => this.syncWithStore()
    )
  }

  public setAssigneeName = (option: ValueType<SelectOptionType>) => {
    const assigneeOption = option as SelectOptionType
    const { assigneeType } = this.state

    if (assigneeOption) {
      const {
        label: { props }
      } = assigneeOption

      const contactIds = props.id.split(',')

      let primaryAssignee = null
      let secondaryAssignee = null
      if (assigneeType !== 'user') {
        switch (props.assigneeType) {
          case 'joint':
            primaryAssignee = contactIds[0]
            secondaryAssignee = contactIds[1]
            break
          case 'primary':
            primaryAssignee = props.id
            break
          case 'secondary':
            secondaryAssignee = props.id
            break
          default:
            primaryAssignee = null
            secondaryAssignee = null
        }
      }

      this.setState(
        {
          assigneeNames: assigneeOption?.value,
          selectedAssignee: option,
          advisorAssignee: assigneeType === 'user' ? props.id : null,
          primaryAssignee,
          secondaryAssignee
        },
        () => this.syncWithStore()
      )
    }
  }

  public setTaskStatus = (option: ValueType<SelectOptionType>) => {
    const { value } = option as SelectOptionType
    const { activeTask } = this.props
    const today = moment().format('MM-DD-YYYY')
    this.setState(
      {
        selectedStatus: option,
        status: value,
        completedDate:
          activeTask.value === 'Completed' || value === 'Completed'
            ? activeTask?.completedDate
              ? activeTask.completedDate
              : today
            : ''
      },
      () => this.syncWithStore()
    )
  }

  public assigneeReferenceNote = (
    <div className='task-add__label task-add__label-note'>
      Assignee list has been updated to a drop-down selection.
      <br />
      <br />
      Please reference the name above to select the correct contact(s) from the
      list below.
    </div>
  )

  public renderToolTip = (title: string) => {
    return title.toLocaleLowerCase() === 'show/hide' ? (
      <Tooltip
        message={staticText.includeInGc}
        width={110}
        position='bottom'
        multiLine={true}>
        <HelpIcon />
      </Tooltip>
    ) : null
  }

  public render() {
    const {
      dueDate,
      assigneeType,
      selectedAssignee,
      selectedStatus,
      assigneeNames,
      showHideToggle,
      subject
    } = this.state
    const {
      viewMode,
      officeTeams,
      contacts,
      activeTask,
      deleteTask,
      householdFinId
    } = this.props

    const isClientAssignedTask = assigneeIsClient(activeTask, contacts)

    return (
      <React.Fragment>
        <div className='side-bar-body' style={{ height: '100%' }}>
          <TaskTextArea
            id='task_name_textarea'
            title='Task Description'
            titleHelper=' (500 characters max)'
            name='subject'
            value={subject}
            onChangeFunc={this.updateTextArea}
            onBlurFunc={this.syncWithStore}
          />

          <TaskAssignType
            viewMode={viewMode}
            isClientAssignedTask={isClientAssignedTask}
            selected={assigneeType}
            onChangeFunc={this.setAssignType}
            onBlurFunc={this.syncWithStore}
            activeTask={activeTask}
          />

          <AssigneeSelect
            headerTitle='Assign To*'
            onChangeFunc={this.setAssigneeName}
            onBlurFunc={this.syncWithStore}
            selectedOption={selectedAssignee}
            optionData={assigneeType === 'client' ? contacts : officeTeams}
            activeTask={activeTask}
          />

          <div className='side-bar-body__grid task-add__container'>
            <div>
              <StatusSelect
                headerTitle='Status'
                onChangeFunc={this.setTaskStatus}
                selectedOption={selectedStatus}
              />
            </div>

            <div>
              <div className='task-add__label'>Due date</div>
              <div className='side-bar-body__text side-bar-body__icon-row'>
                <div className='task-add__datepicker'>
                  <CustomDatepicker
                    key={dueDate}
                    dueDate={dueDate}
                    popperPlacement='left'
                    className='task-add__textarea'
                    dateSetter={this.dateChange}
                  />
                  <img alt='Date Icon' src={DateIcon} />
                </div>
              </div>
            </div>

            <div>
              <div className='task-add__label task-add__label-switch'>
                Show/Hide{' '}
                <span className='task-add__label-tooltip'>
                  {this.renderToolTip('SHOW/HIDE')}
                </span>
              </div>

              <div className='side-bar-body__text side-bar-body__icon-row'>
                <ToggleSwitch
                  disable={false}
                  active={showHideToggle}
                  handleToggleActive={this.handleShowHideToggle}
                />
              </div>
            </div>
          </div>

          <ClientNotificationStatusWarning
            contacts={contacts}
            assignee={assigneeType === 'client' && assigneeNames}
            householdFinId={householdFinId}
          />
        </div>

        <div className='task-add__footer-bar' />
        {viewMode === 'EDIT' ? (
          <div className='task-add__footer'>
            <div className='task-add__footer-delete' onClick={deleteTask}>
              <TrashIcon />
              <div>Remove Task</div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}
const mapStateToProps = (store: GlobalState, match: any) => {
  const officeTeams = store?.households?.[match?.householdFinId]?.officeTeams
  const contacts = store?.contact?.[match?.householdFinId]
  const tasksState = store?.tasks?.[match?.householdFinId]

  return {
    institution: store.institution,
    officeTeams,
    contacts,
    tasksState
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(
  SideBarBody
)
