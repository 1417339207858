import React from 'react'
import HouseholdV3 from './v3/containers/household'
import { HouseholdObj } from './objects/household'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from './reducers'
import { getIndividualHousehold } from './actions/households'
import { PageNotFound } from './v3/containers/errors/pageNotFound'
import { HouseholdsState } from './reducers/households'

interface HouseholdVersionRouterProps {
  households: HouseholdsState
  household: HouseholdObj
  dispatch: Dispatch<GlobalState>
}

class HouseholdVersionRouter extends React.Component<
  HouseholdVersionRouterProps
> {
  public componentDidMount() {
    const { household, dispatch } = this.props

    if (household?.id) {
      // individual household call also gets institution
      dispatch(getIndividualHousehold(household.id))
    }
  }
  public componentDidUpdate(prevProps: HouseholdVersionRouterProps) {
    const { household, dispatch } = this.props
    const hhId = household?.id
    if (hhId !== prevProps.household?.id) {
      dispatch(getIndividualHousehold(household.id))
    }
  }
  public render() {
    const { household, households } = this.props
    const isHouseholds = Object.keys(households).length > 0
    if (!isHouseholds) return null
    return household ? <HouseholdV3 /> : <PageNotFound />
  }
}

const mapStateWithProps = (store: GlobalState, { match }: any) => {
  return {
    household: store?.households[match?.params?.householdFinId],
    households: store?.households
  }
}

export default withRouter(connect(mapStateWithProps)(HouseholdVersionRouter))
