import qs from 'query-string'
import {
  saveSalesforceSyncToken,
  saveMSDynamicsSyncToken
} from '../../actions/user'
import { store } from '../../store'
import {
  SalesforceCRM,
  CRMTypes,
  MSDynamicsCRM
} from '../../objects/institution'

export const getLoginUrl = (crm: string, v2ACL: boolean) => {
  const url: string = `${window.location.protocol}//${window.location.host}/auth/callback/sf`
  const authQS = qs.stringify({
    response_type: 'code',
    client_id: window._env_.REACT_APP_CLIENT_KEY,
    redirect_uri: url,
    prompt: 'login consent',
    state: `crm=${crm}&v2ACL=${v2ACL}`
  })
  return `${window._env_.REACT_APP_SALESFORCE_AUTH_URL}/services/oauth2/authorize?${authQS}`
}

// 'salesforce' is left out of syncTypeList in order to default syncType
// value to 'sf' within the salesforceReceiver method.
const syncTypeList = [
  'xlr8',
  'salentica',
  'orion',
  'rz_wealth',
  'captrust',
  'pax',
  'mosaic',
  'ayco',
  'brown',
  'salesforce_financial_services_cloud',
  'salesforce_pfm',
  'salesforce_financial_services_cloud_ayco',
  'salesforce_practifi',
  'salesforce_lamco',
  'salesforce_barber_osiwala',
  'sendero_practifi'
]

export const isSalesforce = (integration: CRMTypes) => {
  const syncTypes = [...syncTypeList, 'salesforce']
  return syncTypes.includes(integration)
}

export const salesforceReceiver = async (crm: SalesforceCRM) => {
  const sfToken = localStorage.getItem('sfCode')
  const syncType = syncTypeList.includes(crm) ? crm.replace(/_/g, '') : 'sf'
  if (sfToken) {
    store.dispatch(saveSalesforceSyncToken(sfToken, syncType))
    localStorage.removeItem('sfCode')
  }
}

export const tamaracReceiver = async (
  crmSource: MSDynamicsCRM // 'tamrac' | 'ms_salentica_engage'
) => {
  const token = localStorage.getItem('msDynamicsCode')
  if (token) {
    store.dispatch(saveMSDynamicsSyncToken(token, crmSource))
    localStorage.removeItem('msDynamicsCode')
  }
}
export const isMSDynamicsCRM = (crmSource: MSDynamicsCRM) => {
  const MSDynamicsCRMs = ['tamarac', 'ms_salentica_engage']
  return MSDynamicsCRMs.includes(crmSource)
}
