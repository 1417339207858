import api from '../v3/helpers/api'
import { PalNoteTemplateObj } from '../objects/priorityActions'

export const FETCH_PRIORITY_ACTION_LIST: string = 'FETCH_PRIORITY_ACTION_LIST'
export const CREATE_PRIORITY_ACTION_LIST: string = 'CREATE_PRIORITY_ACTION_LIST'
export const UPDATE_PAL_STATUS: string = 'UPDATE_PAL_STATUS'
export const UPDATE_PAL_ACTIVE: string = 'UPDATE_PAL_ACTIVE'
export const UPDATE_PAL_NOTE: string = 'UPDATE_PAL_NOTE'
export const UPDATE_PAL_DATE: string = 'UPDATE_PAL_DATE'
export const CREATE_NOTE_TEMPLATE: string = 'CREATE_NOTE_TEMPLATE'

export const getPAL = (householdFinId: string) => {
  return {
    type: FETCH_PRIORITY_ACTION_LIST,
    payload: api()
      .get(`/households/${householdFinId}/palquestions?withCards=true`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateStatus = (
  householdFinId: string,
  id: string,
  status: string
) => {
  return {
    type: UPDATE_PAL_NOTE,
    payload: api()
      .put(`/households/${householdFinId}/palquestions/${id}`, {
        status: `${status}`
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updatePalActive = (
  householdFinId: string,
  id: string,
  active: boolean
) => {
  return {
    type: UPDATE_PAL_ACTIVE,
    payload: api()
      .put(`/households/${householdFinId}/palquestions/${id}`, {
        toBeDiscussed: `${active}`
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateNote = (
  householdFinId: string,
  id: string,
  notes: string
) => {
  return {
    type: UPDATE_PAL_NOTE,
    payload: api()
      .put(`/households/${householdFinId}/palquestions/${id}`, {
        notes: `${notes}`
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updatePriorityActionDate = (
  householdFinId: string,
  id: string,
  checkindate: string
) => {
  return {
    type: UPDATE_PAL_DATE,
    payload: api()
      .put(`/households/${householdFinId}/palquestions/${id}`, {
        checkInDate: `${checkindate}`
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}
export const deletePriorityActionDate = (
  householdFinId: string,
  id: string
) => {
  return {
    type: UPDATE_PAL_DATE,
    payload: api()
      .put(`/households/${householdFinId}/palquestions/${id}`, {
        checkInDate: null
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createPAL = (householdFinId: string) => {
  return {
    type: CREATE_PRIORITY_ACTION_LIST,
    payload: api()
      .post(`/households/${householdFinId}/pal`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createNoteTemplate = (
  householdFinId: string,
  priorityActionId: string,
  reqBody: PalNoteTemplateObj
) => {
  return {
    type: CREATE_NOTE_TEMPLATE,
    payload: api()
      .post(`/households/${householdFinId}/palnotetemplates`, reqBody)
      .then((response) => {
        response.data.householdFinId = householdFinId
        response.data.priorityActionId = priorityActionId
        return response
      })
  }
}
