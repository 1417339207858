import React from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { UserObj } from '../../../../objects/user'
import Axios from 'axios'

interface JunxureAuthProps {
  user: UserObj
}

interface JunxureAuthState {
  samlResponse: string
  formSubmitted: boolean
}

class JunxureAuth extends React.Component<JunxureAuthProps, JunxureAuthState> {
  public formRef = React.createRef<HTMLFormElement>()
  constructor(props: JunxureAuthProps) {
    super(props)
    this.state = {
      samlResponse: null,
      formSubmitted: false
    }
  }
  public componentDidMount() {
    const requestBody = {
      idpUrl: window._env_.REACT_APP_AC_URL,
      partnerUserId: this.props.user.id,
      partnerUserLastName: this.props.user.lastName,
      partnerUserEmail: this.props.user.email
    }
    Axios.post(
      window._env_.REACT_APP_JUNXURE_AUTH_API + '/auth',
      requestBody
    ).then((res) => {
      this.setState({ samlResponse: res.data })
    })
  }

  public componentDidUpdate = () => {
    if (!this.state.formSubmitted && this.state.samlResponse) {
      this.submitPost()
    }
  }

  public submitPost = () => {
    this.formRef.current.submit()
    this.setState({ formSubmitted: true })
  }

  public render() {
    const { samlResponse } = this.state
    const junxurePostUrl =
      window._env_.REACT_APP_JUNXURE_API +
      '?partnerId=' +
      window._env_.REACT_APP_JUNXURE_PARTNER_ID
    const relayState = `${window.location.protocol}//${window.location.host}/administration/junxure`
    return !samlResponse ? (
      <div className='sync-modal__loading'>Redirecting to Junxure</div>
    ) : (
      <div>
        <form
          id='junxure-form'
          method='POST'
          action={junxurePostUrl}
          ref={this.formRef}
          target='_self'>
          <input type='hidden' name='SAMLResponse' value={samlResponse} />
          <input
            type='hidden'
            name='RelayState'
            value={encodeURI(relayState)}
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    user: store.user.userId ? store.user.users[store.user.userId] : null
  }
}

export default connect(mapStateToProps)(JunxureAuth)
