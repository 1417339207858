import React, { Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import BackButton from '../../components/backButton'
import Tile from '../../components/layout/tile'
import Button from '../../components/button'
import { history } from '../../../store'
import GlobalImage from '../../assets/images/global.png'
import DomesticImage from '../../assets/images/domestic.png'
import GeographicAttribute from './geographicAttribute'
import * as actions from '../../../actions/investmentViewfinder'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderInterface,
  InvestmentViewfinderGeographicPriorityInterface
} from '../../../objects/investmentViewfinder'

interface GlobalPriorityProps {
  householdFinId: string
  exerciseId: string
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  geographicPriorities: InvestmentViewfinderGeographicPriorityInterface
  dispatch: Dispatch<GlobalState>
}

interface GlobalPriorityState {
  activeProsAndCons: string
  active: string
}

class GlobalPriority extends React.Component<
  GlobalPriorityProps,
  GlobalPriorityState
> {
  constructor(props: GlobalPriorityProps) {
    super(props)
    const { exercises, householdFinId, exerciseId } = this.props
    const exercise = exercises[householdFinId][exerciseId]
    this.state = {
      activeProsAndCons: null,
      active:
        exercise.global || exercise.domestic
          ? exercise.global > exercise.domestic
            ? 'Global'
            : 'Domestic'
          : null
    }
  }
  public middleHeader = () => {
    return (
      <div className='ivf-priorities__middle-header'>
        <div className='ivf-priorities__middle-header-text'>
          Do you prefer to invest domestically or diversify globally to other
          countries?
        </div>
      </div>
    )
  }
  public navigateToAllocation = async () => {
    const { exerciseId, householdFinId } = this.props
    const { active } = this.state
    await this.props.dispatch(
      actions.updateViewfinderGeographicPriority(
        householdFinId,
        exerciseId,
        active
      )
    )
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/allocation`
    )
  }
  public rightHeader = () => {
    const { active } = this.state
    return (
      <Button
        primary={true}
        onClick={this.navigateToAllocation}
        disabled={!active}>
        Next
      </Button>
    )
  }
  public navigateBack = () => {
    const { exerciseId, householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/results`
    )
  }
  public setProsAndCons = (title: string) => {
    this.setState({ activeProsAndCons: title })
  }
  public prosAndCons = () => {
    const { activeProsAndCons } = this.state
    const { geographicPriorities } = this.props
    return (
      <div
        className='ivf-geography__pros-cons-w'
        style={{ visibility: activeProsAndCons ? 'visible' : 'hidden' }}>
        <div className='ivf-geography__pros-cons'>
          <div className='ivf-geography__pros-cons-title'>Benefits</div>
          <ul>
            {geographicPriorities[activeProsAndCons] &&
              geographicPriorities[activeProsAndCons].benefits.map(
                (benefit: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className='ivf-geography__pros-cons-description'>
                      {benefit}
                    </li>
                  )
                }
              )}
          </ul>
        </div>
        <div className='ivf-geography__pros-cons-divider' />
        <div className='ivf-geography__pros-cons'>
          <div className='ivf-geography__pros-cons-title'>Drawbacks</div>
          <ul>
            {geographicPriorities[activeProsAndCons] &&
              geographicPriorities[activeProsAndCons].drawbacks.map(
                (drawback: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className='ivf-geography__pros-cons-description'>
                      {drawback}
                    </li>
                  )
                }
              )}
          </ul>
        </div>
      </div>
    )
  }
  public setActive = (title: string) => {
    this.setState({ active: title })
  }
  public render() {
    const { active } = this.state
    return (
      <Fragment>
        <ContentHeader title='Geographic Priorities' />
        <Tile
          leftHeader={<BackButton onClick={this.navigateBack} />}
          middleHeader={this.middleHeader()}
          rightHeader={this.rightHeader()}
          headerBorder={true}
          headerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#FAFAFA'
          }}>
          <div className='ivf-container'>
            <div className='ivf-geography__option-w'>
              <GeographicAttribute
                imageSrc={DomesticImage}
                title='Domestic'
                definition='Portfolio consists of mostly familiar companies'
                onHover={this.setProsAndCons}
                onClick={this.setActive}
                active={active === 'Domestic'}
              />
              <GeographicAttribute
                imageSrc={GlobalImage}
                title='Global'
                definition='Greater diversification'
                onHover={this.setProsAndCons}
                onClick={this.setActive}
                active={active === 'Global'}
              />
            </div>
            {this.prosAndCons()}
          </div>
        </Tile>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    exercises: store.investmentViewfinder.exercises,
    geographicPriorities: store.investmentViewfinder.geographicPriorities
  }
}

export default connect(mapStateToProps)(GlobalPriority)
