import React from 'react'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import {
  PortfolioBuilderObj,
  PortfolioBuilderInterface
} from '../../../objects/portfolioBuilder'
import Field from './field'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import {
  updatePortfolioBuilder,
  getPortfolioBuilderAccounts
} from '../../../actions/portfolioBuilder'

interface DocumentProps {
  portfolioBuilderList: PortfolioBuilderInterface
  portfolioBuilderId: string
  householdFinId: string
  dispatch: Dispatch<GlobalState>
}

interface DocumentState {
  protfolioBuilderData: PortfolioBuilderObj
  edit: boolean
  name: string
}

class DocumentName extends React.Component<DocumentProps, DocumentState> {
  private timeout: any
  constructor(props: DocumentProps) {
    super(props)
    this.state = {
      edit: false,
      protfolioBuilderData: null,
      name: 'Untitled Document'
    }
  }

  public componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  public componentWillReceiveProps(nextProps: DocumentProps) {
    if (nextProps.portfolioBuilderList) {
      const protfolioBuilderData: PortfolioBuilderObj = Object.assign(
        {},
        nextProps.portfolioBuilderList[this.props.portfolioBuilderId]
      )
      this.setState({ protfolioBuilderData }, () => {
        if (protfolioBuilderData.name) {
          this.setState({ name: protfolioBuilderData.name })
        } else {
          this.setState({ name: 'Untitled Document' })
        }
      })
    }
  }

  public handleClick = () => {
    this.setState({ edit: true })
  }

  public handleBlur = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (this.state.name !== e.currentTarget.value) {
      const { portfolioBuilderId, householdFinId, dispatch } = this.props
      this.setState(
        {
          name: e.currentTarget.value
        },
        async () => {
          await dispatch(
            updatePortfolioBuilder(householdFinId, portfolioBuilderId, {
              name: this.state.name
            })
          )
          dispatch(
            getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
          )
        }
      )
    }
    this.setState({ edit: false })
  }

  public editStatus = () => {
    const { edit, name } = this.state
    if (edit) {
      return <Field autoFocus={true} onBlur={this.handleBlur} value={name} />
    } else {
      return <span>{name}</span>
    }
  }

  public render() {
    return (
      <div className='document-name'>
        {this.editStatus()}
        <EditIcon onClick={this.handleClick} />
        {/* <div className='document-name__approved'>
          Approved
        </div>
        <div className='document-name__date'>
          Last Edited: 21/8/2018
        </div>*/}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    portfolioBuilderId: match.params.portfolioBuilderId,
    portfolioBuilderList: store.portfolioBuilder[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(DocumentName))
