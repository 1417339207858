import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export interface DataPointCircleObj {
  id: string
  className?: string
  squareDimension: number
  circle: {
    radius: number
    stroke: string
    strokeWidth: number
    fillColor: string
  }
  text: {
    content: string
    fontSize: number
    fill: string
  }
}

const getSVGNamespace = () => {
  // required for svg generation
  // tslint:disable-next-line:no-http-string
  return 'http://www.w3.org/2000/svg'
}

const childDimension = (size: number, pad?: number): number => {
  const padValue = pad || 0
  return size / 2 + padValue
}

const DataPointCircleElement: React.StatelessComponent<DataPointCircleObj> = ({
  id,
  className,
  squareDimension,
  circle,
  text
}) => (
  <svg
    xmlns={getSVGNamespace()}
    className={className}
    height={squareDimension}
    width={squareDimension}>
    <circle
      cx={`${childDimension(squareDimension)}`}
      cy={`${childDimension(squareDimension)}`}
      r={`${circle && circle.radius}`}
      stroke={circle && circle.stroke}
      strokeWidth={circle && circle.strokeWidth}
      fill={circle && circle.fillColor}
    />
    <text
      textAnchor='middle'
      x={`${childDimension(squareDimension)}`}
      y={`${childDimension(squareDimension, 4)}`}
      fontFamily='Helvetica Neue'
      fontSize={text && text.fontSize}
      fill={text && text.fill}>
      {text && text.content}
    </text>
  </svg>
)

export const dataPointFactory = (
  dataPointCircle: DataPointCircleObj
): string => {
  const thisWindow: any = window as any
  return (
    'data:image/svg+xml;base64,' +
    thisWindow.btoa(
      renderToStaticMarkup(<DataPointCircleElement {...dataPointCircle} />)
    )
  )
}
