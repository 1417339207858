import React, { Component } from 'react'
import Tooltip from '../../components/tooltip'
import { TooltipPosition } from '../../../objects/general'
import CheckIcon from '../../assets/images/icons/png/ic_check@2x.png'
import WarningIcon from '../../assets/images/icons/png/ic_warning@2x.png'
import LoaderGif from '../../assets/images/status_loader_transparent.gif'
import pencilIcon from '../../assets/images/honestConversations/ic-pencil_blue.svg'
export interface ScoreCommentTextAreaProps {
  value?: string
  cardIndex?: number
  isPrimary?: boolean
  placeholderTxt?: string
  tooltipPos?: TooltipPosition
  isDisabled?: boolean
  hideTooltip?: boolean
  parentCls?: string
  charLimit?: number
  enableMaxLen?: boolean
  enableUndo?: boolean
  initialComment?: string
  autoSaveStatus?: string
  showCompactView?: boolean // This is to show comment with the pencil icon and hide the commentbox
  onCommentChange?(comment: string, isPrimary: boolean, key?: number): void
  onInputBlur?(isPrimary: boolean): void
  onFocus?(isPrimary: boolean): void
}

interface ScoreCommentTextAreaState {
  initialComment?: string
  showTextEditor: boolean
}

class ScoreCommentTextArea extends Component<
  ScoreCommentTextAreaProps,
  ScoreCommentTextAreaState
> {
  static defaultProps: Pick<
    ScoreCommentTextAreaProps,
    | 'placeholderTxt'
    | 'tooltipPos'
    | 'isDisabled'
    | 'hideTooltip'
    | 'parentCls'
    | 'charLimit'
    | 'enableMaxLen'
    | 'enableUndo'
    | 'showCompactView'
  > = {
    placeholderTxt: 'What do you need to do to improve this score?',
    tooltipPos: 'top',
    isDisabled: false,
    hideTooltip: false,
    parentCls: '',
    enableMaxLen: false,
    charLimit: 180,
    enableUndo: true,
    showCompactView: false // Default is FALSE to sync the condition with componentDidMount and initial state
  }

  constructor(props: ScoreCommentTextAreaProps) {
    super(props)
    const { value = '', initialComment = '', showCompactView } = this.props
    this.state = {
      initialComment: initialComment || value,
      showTextEditor: !showCompactView // Initial state is TRUE to show the commentbox and hide the comment with the pencil
    }
  }

  public setInputState = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { isPrimary, cardIndex, onCommentChange } = this.props
    const { value = '' } = e.currentTarget
    onCommentChange(value.trimLeft(), isPrimary, cardIndex)
  }

  public onInputBlur = () => {
    const { isPrimary, onInputBlur } = this.props
    onInputBlur && onInputBlur(isPrimary)
  }

  public onFocus = () => {
    const { isPrimary, onFocus } = this.props
    onFocus(isPrimary)
  }

  public constructProps = () => {
    const PROPS: any = {}
    const { charLimit, onInputBlur, enableMaxLen, onFocus } = this.props
    if (onFocus) {
      PROPS.onFocus = this.onFocus
    }
    if (onInputBlur) {
      PROPS.onBlur = this.onInputBlur
    }
    if (enableMaxLen) {
      PROPS.maxLength = charLimit
    }
    return PROPS
  }

  public onResetComment = async (event: React.MouseEvent) => {
    const {
      value,
      isPrimary,
      cardIndex,
      onCommentChange,
      isDisabled
    } = this.props
    const { initialComment } = this.state
    if (event) event.preventDefault()

    if (initialComment !== value && !isDisabled) {
      await onCommentChange(this.state.initialComment, isPrimary, cardIndex)
      this.onInputBlur()
    }
  }

  public showStatus = (): JSX.Element => {
    const { autoSaveStatus } = this.props

    switch (autoSaveStatus) {
      case 'ongoing':
        return (
          <>
            <img
              src={LoaderGif}
              alt='loading animation'
              className='score-comment-text-area__status-icon'
            />
            <span className='score-comment-text-area__status-text--progress'>
              Saving...
            </span>
          </>
        )
      case 'error':
        return (
          <>
            <img
              src={WarningIcon}
              alt='red warning mark'
              className='score-comment-text-area__status-icon'
            />
            <span className='score-comment-text-area__status-text--error'>
              Error saving.
            </span>
          </>
        )
      case 'success':
        return (
          <>
            <img
              src={CheckIcon}
              alt='green check mark'
              className='score-comment-text-area__status-icon'
            />
            <span className='score-comment-text-area__status-text--success'>
              Saved!
            </span>
          </>
        )
      default:
        return null
    }
  }

  public getContainerClassName = () => {
    const { parentCls, autoSaveStatus } = this.props
    let className = 'score-comment-text-area'
    if (parentCls) className = `${parentCls}__${className}`
    if (autoSaveStatus === 'error') className = `${className}--error`
    return className
  }

  public getCoreCommentClassName = () => {
    const { isDisabled } = this.props
    let className = 'score-comment-text-area__limit'
    if (isDisabled) className = `${className}--disabled`
    return className
  }

  public getCharacterCountClassName = () => {
    const { value, charLimit } = this.props
    const inputLength = value ? charLimit - value.length : charLimit
    let className = 'score-comment-text-area__character-count'
    if (inputLength < 0) className = `${className}--over`
    return className
  }

  public getUndoBtnClassName = () => {
    const { initialComment } = this.state
    const { value = '' } = this.props
    let className = 'score-comment-text-area__undo'
    if (initialComment === value) className = `${className}--inactive`
    return className
  }
  // Toggle the commentbox state (Hide or Show) for now we are only using to show
  public showTextEditor = () => {
    this.setState({ showTextEditor: !this.state.showTextEditor })
  }

  public render() {
    const {
      value,
      placeholderTxt,
      tooltipPos,
      isDisabled,
      hideTooltip,
      charLimit,
      enableUndo,
      cardIndex
    } = this.props
    const { showTextEditor } = this.state
    const txtValue = value || ''
    // Do not show text area if prop showCompactView === true and value is empty
    if (!showTextEditor && !txtValue) return null
    const tabIndex = cardIndex + 1
    return (
      <>
        {showTextEditor ? (
          <div className={this.getContainerClassName()}>
            <textarea
              tabIndex={tabIndex}
              className='score-comment-text-area__input'
              value={txtValue}
              onChange={this.setInputState}
              placeholder={placeholderTxt}
              disabled={isDisabled}
              {...this.constructProps()}
            />

            <div className={this.getCoreCommentClassName()}>
              <div className='score-comment-text-area__status-container'>
                {this.showStatus()}
              </div>
              <div>
                {enableUndo && (
                  <span
                    onMouseDown={this.onResetComment}
                    className={this.getUndoBtnClassName()}>
                    Undo
                  </span>
                )}
                <Tooltip
                  message={`Recommended number of characters for ideal Guidebook layout: ${charLimit}`}
                  width={160}
                  hideTooltip={hideTooltip}
                  position={tooltipPos}
                  multiLine={false}>
                  <span className={this.getCharacterCountClassName()}>
                    {value ? value.length : charLimit}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        ) : (
          // Show the comment with the pencil icon if comment exits and showtextEditor is false
          <div className='score-comment-compact-view'>
            {txtValue}
            <img
              tabIndex={tabIndex}
              className='score-comment-compact-view__edit-icon'
              src={pencilIcon}
              onClick={this.showTextEditor}
            />
          </div>
        )}
      </>
    )
  }
}

export default ScoreCommentTextArea
