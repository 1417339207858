import React, { Fragment } from 'react'
import moment from 'moment'
import InnerContainer from '../../components/layout/innerContainer'
import ContentHeader from '../../components/layout/contentHeader'
import { ReactComponent as NetWorthIcon } from '../../assets/images/icons/networth.svg'
import AssetsAndLiabilitiesTile from './assetsAndLiabilitiesTile'
import * as clientAccountActions from '../../../actions/clientAccounts'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import ClientAccountsTile from './clientAccountsTile'
import {
  ClientAccountObj,
  ClientAccountObjState,
  ClientAccountsErrorEmail,
  staleBalanceClassifications
} from '../../../objects/clientAccount'
import CreateClientAccount from './createClientAccount'
import { HouseholdPreferencesObj } from '../../../objects/householdPreferences'
import * as householdActions from '../../../actions/households'
import { generateNewPreferences } from '../../helpers/householdPreferences'
import ClientAccountsErrorEmailModal from './clientAccountsErrorEmailModal'
import { UserObj } from '../../../objects/user'
import {
  ContactsInterface,
  ContactObj,
  SelectedContactObj
} from '../../../objects/contact'
import HcSendLinkModal from '../honestConversations/hcSendLinkModal'
import { getContact } from '../../../actions/contacts'
import { isEqual, isObjEmpty } from '../../helpers/index'
import MergeClientAccountsModal from './mergeAccountsModal'
import UnmergeClientAccountsModal from './unmergeAccountsModal'
import AlertMessageToast from '../../components/alertMessageToast'

export interface NetWorthProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  title: string
  clientAccounts: ClientAccountObjState
  preferences: HouseholdPreferencesObj
  showSync: { showSync: boolean }
  showAlert: boolean
  message: string
  isSuccess: boolean
  contacts: ContactsInterface
  user: UserObj
  clientAccountsErrorEmail: ClientAccountsErrorEmail
  disableManualAccount: boolean
  clientAccountsMergeError: string
}

interface NetWorthState {
  errorAccounts: ClientAccountObjState
  cashAccounts: ClientAccountObjState
  investmentAccounts: ClientAccountObjState
  otherAccounts: ClientAccountObjState
  liabilityAccounts: ClientAccountObjState
  pendingAccounts: ClientAccountObjState
  showClientAccountsErrorEmailModal: boolean
  showSendLinkModal: boolean
  showAccountsMergeModal: boolean
  showAccountsUnmergeModal: boolean
  selectedAccount: ClientAccountObj
  selectedContact: {
    value: ContactObj
    label: string
  }
}

export class NetWorth extends React.Component<NetWorthProps, NetWorthState> {
  constructor(props: NetWorthProps) {
    super(props)
    this.state = {
      errorAccounts: {},
      cashAccounts: {},
      investmentAccounts: {},
      otherAccounts: {},
      liabilityAccounts: {},
      pendingAccounts: {},
      showClientAccountsErrorEmailModal: false,
      showSendLinkModal: false,
      showAccountsMergeModal: false,
      showAccountsUnmergeModal: false,
      selectedAccount: null,
      selectedContact: {
        value: null,
        label: ''
      }
    }
  }

  public componentDidMount() {
    const { householdFinId, dispatch } = this.props
    dispatch(householdActions.getIndividualHousehold(householdFinId))
    dispatch(clientAccountActions.getClientAccounts(householdFinId))
    this.setAccountCategories()
    this.setSelectedContact()
  }

  public async componentDidUpdate(prevProps: NetWorthProps) {
    const { clientAccounts, showSync, contacts } = this.props
    if (clientAccounts !== prevProps.clientAccounts) {
      await this.setAccountCategories()
    }

    if (showSync.showSync !== prevProps.showSync.showSync) {
      await this.setAccountCategories()
    }
    if (!isObjEmpty(contacts) && !isEqual(contacts, prevProps.contacts)) {
      this.setSelectedContact()
    }
  }

  public setSelectedContact = () => {
    const { contacts } = this.props
    this.setState({
      selectedContact: {
        ...this.state.selectedContact,
        value: contacts && {
          ...contacts.primary,
          isPrimary: true
        },
        label:
          contacts &&
          `${contacts.primary?.firstName} ${contacts.primary?.lastName}`
      }
    })
  }

  public onSelectAccountToMerge = (account: ClientAccountObj) => {
    this.setState({
      selectedAccount: account && account
    })
  }

  public showModalView = (type: string) => async () => {
    const { dispatch, householdFinId } = this.props
    const { selectedContact } = this.state
    switch (type) {
      case 'sendLink':
        this.setState({ showSendLinkModal: true })
        break
      case 'viewClientAccountsErrorEmail':
        dispatch(
          clientAccountActions.getClientAccountsErrorEmail(
            householdFinId,
            selectedContact.value.id
          )
        )
        this.setState({ showClientAccountsErrorEmailModal: true })
        break
      case 'mergAccounts':
        this.setState({ showAccountsMergeModal: true })
        break
      case 'unmergeAccounts':
        this.setState({ showAccountsUnmergeModal: true })
        break
      case 'close':
        this.setState({
          showSendLinkModal: false,
          showClientAccountsErrorEmailModal: false,
          showAccountsMergeModal: false,
          showAccountsUnmergeModal: false
        })
    }
  }

  public sendClientAccountsErrorEmail = async (clientId: string) => {
    const { householdFinId, dispatch } = this.props
    await dispatch(
      clientAccountActions.sendClientAccountsErrorEmail(
        householdFinId,
        clientId
      )
    )
    // call contact to get updated email sent date
    await dispatch(getContact(clientId))
    this.setState({ showClientAccountsErrorEmailModal: false })
  }

  public handleSendLinkSelectChange = () => (
    selectedContact: SelectedContactObj
  ) => {
    this.setState({ selectedContact })
  }

  public filterAccountsByCategory = (
    clientAccounts: ClientAccountObjState,
    category: string | string[] | null
  ) => {
    const filteredAccounts = {}
    if (clientAccounts) {
      Object.keys(clientAccounts)
        .filter((key: string) => {
          const clientAccount = clientAccounts[key]
          if (category === null) {
            return clientAccount.category === null
          } else {
            return (
              clientAccount.category &&
              category.includes(clientAccount.category)
            )
          }
        })
        .forEach((key: string) => {
          filteredAccounts[key] = clientAccounts[key]
        })
      return filteredAccounts
    }
    return {}
  }

  public filterErrorAccounts = (clientAccounts: ClientAccountObjState) => {
    const filteredErrorAccounts = {}
    if (clientAccounts) {
      Object.keys(clientAccounts).forEach((key: string) => {
        // Checks for the existence of errorCode. If there is no error it will not be mapped in the reducer.
        const errorCode = clientAccounts[key].errorCode
        // Allowed error types.
        const hasError =
          errorCode &&
          errorCode !== '0' &&
          errorCode !== '801' &&
          errorCode !== '802'
        // Check if balance date is more than 60 days ago.
        const isDatePastDue =
          moment(clientAccounts[key].balanceDate).diff(moment(), 'days') <=
            -60 &&
          staleBalanceClassifications.includes(
            clientAccounts[key].guidebookClassification
          )
        if ((errorCode && hasError) || isDatePastDue) {
          filteredErrorAccounts[key] = clientAccounts[key]
        }
      })
      return filteredErrorAccounts
    } else return {}
  }

  public setAccountCategories = () => {
    const { clientAccounts } = this.props
    if (clientAccounts) {
      const cashAccounts = this.filterAccountsByCategory(
        clientAccounts,
        'Cash & Equivalents'
      )
      const investmentAccounts = this.filterAccountsByCategory(clientAccounts, [
        'Taxable Investments',
        'Tax-Deferred Investments',
        'Tax-Exempt Investments'
      ])
      const otherAccounts = this.filterAccountsByCategory(clientAccounts, [
        'Personal Assets',
        'Business Assets',
        'Real Estate'
      ])
      const liabilityAccounts = this.filterAccountsByCategory(clientAccounts, [
        'Short-Term Liabilities',
        'Long-Term Liabilities'
      ])
      const pendingAccounts = this.filterAccountsByCategory(
        clientAccounts,
        null
      )
      const errorAccounts = this.filterErrorAccounts(clientAccounts)

      this.setState({
        cashAccounts,
        investmentAccounts,
        otherAccounts,
        liabilityAccounts,
        pendingAccounts,
        errorAccounts
      })
    }
  }

  public renderClientAccountCategories = () => {
    const { clientAccounts, preferences, contacts } = this.props
    const {
      errorAccounts,
      cashAccounts,
      investmentAccounts,
      otherAccounts,
      liabilityAccounts,
      pendingAccounts
    } = this.state
    const currentPreferences = preferences || generateNewPreferences()
    if (clientAccounts && Object.keys(clientAccounts).length > 0) {
      return (
        <Fragment>
          {Object.keys(errorAccounts).length > 0 ? (
            <ClientAccountsTile
              title='Need Attention'
              accountSubType='error'
              persistedSortBy={currentPreferences.networthCashSortBy}
              persistedGroupBy={currentPreferences.networthCashGroupBy}
              persistedSortingOrder={
                currentPreferences.networthCashSortingOrder
              }
              clientAccounts={errorAccounts}
              preferences={preferences}
              showSendEmailModal={this.showModalView('sendLink')}
              contacts={contacts}
              onMergeAccounts={this.showModalView('mergAccounts')}
              onUnmergeAccounts={this.showModalView('unmergeAccounts')}
              onAccountSelected={this.onSelectAccountToMerge}
            />
          ) : (
            ''
          )}
          {Object.keys(pendingAccounts).length > 0 ? (
            <ClientAccountsTile
              title='PENDING'
              accountSubType='pending'
              persistedSortBy={currentPreferences.networthUnclassifiedSortBy}
              persistedGroupBy={currentPreferences.networthUnclassifiedGroupBy}
              persistedSortingOrder={
                currentPreferences.networthUnclassifiedSortingOrder
              }
              clientAccounts={pendingAccounts}
              preferences={preferences}
              onMergeAccounts={this.showModalView('mergAccounts')}
              onUnmergeAccounts={this.showModalView('unmergeAccounts')}
              onAccountSelected={this.onSelectAccountToMerge}
            />
          ) : (
            ''
          )}
          {Object.keys(cashAccounts).length > 0 ? (
            <ClientAccountsTile
              title='CASH & EQUIVALENTS'
              accountSubType='cash'
              persistedSortBy={currentPreferences.networthCashSortBy}
              persistedGroupBy={currentPreferences.networthCashGroupBy}
              persistedSortingOrder={
                currentPreferences.networthCashSortingOrder
              }
              clientAccounts={cashAccounts}
              preferences={preferences}
              onMergeAccounts={this.showModalView('mergAccounts')}
              onUnmergeAccounts={this.showModalView('unmergeAccounts')}
              onAccountSelected={this.onSelectAccountToMerge}
            />
          ) : (
            ''
          )}
          {Object.keys(investmentAccounts).length > 0 ? (
            <ClientAccountsTile
              title='INVESTMENTS'
              accountSubType='investments'
              persistedSortBy={currentPreferences.networthInvestmentsSortBy}
              persistedGroupBy={currentPreferences.networthInvestmentsGroupBy}
              persistedSortingOrder={
                currentPreferences.networthInvestmentsSortingOrder
              }
              clientAccounts={investmentAccounts}
              preferences={preferences}
              onMergeAccounts={this.showModalView('mergAccounts')}
              onUnmergeAccounts={this.showModalView('unmergeAccounts')}
              onAccountSelected={this.onSelectAccountToMerge}
            />
          ) : (
            ''
          )}
          {Object.keys(otherAccounts).length > 0 ? (
            <ClientAccountsTile
              title='OTHER ASSETS'
              accountSubType='other'
              persistedSortBy={currentPreferences.networthOtherAssetsSortBy}
              persistedGroupBy={currentPreferences.networthOtherAssetsGroupBy}
              persistedSortingOrder={
                currentPreferences.networthOtherAssetsSortingOrder
              }
              clientAccounts={otherAccounts}
              preferences={preferences}
              onMergeAccounts={this.showModalView('mergAccounts')}
              onUnmergeAccounts={this.showModalView('unmergeAccounts')}
              onAccountSelected={this.onSelectAccountToMerge}
            />
          ) : (
            ''
          )}
          {Object.keys(liabilityAccounts).length > 0 ? (
            <ClientAccountsTile
              title='LIABILITIES'
              accountSubType='liabilities'
              persistedSortBy={currentPreferences.networthLiabilitiesSortBy}
              persistedGroupBy={currentPreferences.networthLiabilitiesGroupBy}
              persistedSortingOrder={
                currentPreferences.networthLiabilitiesSortingOrder
              }
              clientAccounts={liabilityAccounts}
              preferences={preferences}
              onMergeAccounts={this.showModalView('mergAccounts')}
              onUnmergeAccounts={this.showModalView('unmergeAccounts')}
              onAccountSelected={this.onSelectAccountToMerge}
            />
          ) : (
            ''
          )}
        </Fragment>
      )
    }
    return <ClientAccountsTile title='' clientAccounts={{}} />
  }

  public render() {
    const {
      contacts,
      user,
      clientAccountsErrorEmail,
      householdFinId,
      disableManualAccount,
      showAlert,
      message,
      isSuccess,
      clientAccounts
    } = this.props
    const {
      showClientAccountsErrorEmailModal,
      showSendLinkModal,
      selectedContact,
      showAccountsMergeModal,
      showAccountsUnmergeModal,
      errorAccounts,
      cashAccounts,
      investmentAccounts,
      otherAccounts,
      liabilityAccounts,
      pendingAccounts,
      selectedAccount
    } = this.state

    return (
      <InnerContainer>
        <ContentHeader Icon={NetWorthIcon} title='Net Worth' />
        <AssetsAndLiabilitiesTile />
        <div className='client-account__account-tile-w'>
          <div className='client-account__btn-w'>
            <div className='client-account__title'>Accounts</div>
            <CreateClientAccount
              householdFinId={householdFinId}
              disableManualAccount={disableManualAccount}
            />
          </div>
          {this.renderClientAccountCategories()}
        </div>
        {showSendLinkModal && (
          <HcSendLinkModal
            contacts={contacts}
            closeModal={this.showModalView('close')}
            sendEmailModal={this.showModalView('viewClientAccountsErrorEmail')}
            handleSelectChange={this.handleSendLinkSelectChange}
            selectedContact={selectedContact}
          />
        )}
        {showClientAccountsErrorEmailModal && (
          <ClientAccountsErrorEmailModal
            contact={selectedContact.value}
            user={user}
            saveModal={this.sendClientAccountsErrorEmail}
            closeModal={this.showModalView('close')}
            clientAccountsErrorEmail={clientAccountsErrorEmail}
            emailSentDate={selectedContact.value?.accountErrorEmailDate}
          />
        )}
        {showAccountsMergeModal && (
          <MergeClientAccountsModal
            errorAccounts={errorAccounts}
            cashAccounts={cashAccounts}
            investmentAccounts={investmentAccounts}
            otherAccounts={otherAccounts}
            liabilityAccounts={liabilityAccounts}
            pendingAccounts={pendingAccounts}
            selectedAccount={selectedAccount}
            closeModal={this.showModalView('close')}
          />
        )}
        {showAccountsUnmergeModal && (
          <UnmergeClientAccountsModal
            user={user}
            clientAccounts={clientAccounts}
            errorAccounts={errorAccounts}
            cashAccounts={cashAccounts}
            investmentAccounts={investmentAccounts}
            otherAccounts={otherAccounts}
            liabilityAccounts={liabilityAccounts}
            pendingAccounts={pendingAccounts}
            selectedAccount={selectedAccount}
            closeModal={this.showModalView('close')}
          />
        )}
        {showAlert && (
          <AlertMessageToast
            icon={isSuccess ? 'success' : 'error'}
            title={isSuccess ? 'Success' : 'Failure'}
            message={message}
            stacked={false}
          />
        )}
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  return {
    showSync: store.showSync,
    showAlert: store.showAlert.show,
    message: store.showAlert.message,
    isSuccess: store.showAlert.isSuccess,
    clientAccounts: store.clientAccount[householdFinId]?.clientAccounts,
    clientAccountsErrorEmail:
      store.clientAccount[householdFinId]?.clientAccountsErrorEmail,
    householdFinId,
    preferences: store.householdPreferences[householdFinId]?.preferences,
    contacts: store.contact[householdFinId],
    user: store.user.users[store.user.userId],
    disableManualAccount: store.households[householdFinId]?.disableManualAccount
  }
}

export default withRouter(connect(mapStateToProps)(NetWorth))
