import React from 'react'
import api from '../../../../v3/helpers/api'
import PDF from 'react-pdf-js'

interface PdfRendererState {
  ProcessFlow: {
    page: number
    pages: number
  }
  MoneyMind: {
    page: number
    pages: number
  }
  HonestConversations: {
    page: number
    pages: number
  }
  ProfileCover: {
    page: number
    pages: number
  }
  NetWorth: {
    page: number
    pages: number
  }
  Investments: {
    page: number
    pages: number
  }
  FinancialGoals: {
    page: number
    pages: number
  }
  Insurance: {
    page: number
    pages: number
  }
  Goals: {
    page: number
    pages: number
  }
  Tasks: {
    page: number
    pages: number
  }
  AdditionalDocs0: {
    page: number
    pages: number
  }
  AdditionalDocs1: {
    page: number
    pages: number
  }
  AdditionalDocs2: {
    page: number
    pages: number
  }
  AdditionalDocs3: {
    page: number
    pages: number
  }
  AdditionalDocs4: {
    page: number
    pages: number
  }
}

interface pdfSectionsObj {
  checkedcover: boolean
  checkedProcessFlow: boolean
  checkedMoneyMind: boolean
  checkedHonestConversations: boolean
  checkedProfileCover: boolean // this is for the "where are we today?" section
  checkedNetWorth: boolean
  checkedInvestments: boolean
  checkedFinancialGoals: boolean
  checkedInsurance: boolean
  checkedGoals: boolean
  checkedTasks: boolean
  checkedAdditionalDocs0: boolean
  checkedAdditionalDocs1: boolean
  checkedAdditionalDocs2: boolean
  checkedAdditionalDocs3: boolean
  checkedAdditionalDocs4: boolean
}

interface PdfRendererProps {
  id: string
  pdfSections: pdfSectionsObj
  pdfData: any
  setPdfData(filename: string, content: any): void
}

const mainSections = [
  'ProcessFlow',
  'MoneyMind',
  'HonestConversations',
  'ProfileCover',
  'NetWorth',
  'Investments',
  'FinancialGoals',
  'Insurance',
  'Goals',
  'Tasks'
]

const allSections = [
  'ProcessFlow',
  'MoneyMind',
  'HonestConversations',
  'ProfileCover',
  'NetWorth',
  'Investments',
  'FinancialGoals',
  'Insurance',
  'Goals',
  'Tasks',
  'AdditionalDocs0',
  'AdditionalDocs1',
  'AdditionalDocs2',
  'AdditionalDocs3',
  'AdditionalDocs4'
]

class PdfRenderer extends React.Component<PdfRendererProps, PdfRendererState> {
  constructor(props: PdfRendererProps) {
    super(props)
    this.state = {
      ProcessFlow: {
        page: null,
        pages: null
      },
      MoneyMind: {
        page: null,
        pages: null
      },
      HonestConversations: {
        page: null,
        pages: null
      },
      ProfileCover: {
        page: null,
        pages: null
      },
      NetWorth: {
        page: null,
        pages: null
      },
      Investments: {
        page: null,
        pages: null
      },
      FinancialGoals: {
        page: null,
        pages: null
      },
      Insurance: {
        page: null,
        pages: null
      },
      Goals: {
        page: null,
        pages: null
      },
      Tasks: {
        page: null,
        pages: null
      },
      AdditionalDocs0: {
        page: null,
        pages: null
      },
      AdditionalDocs1: {
        page: null,
        pages: null
      },
      AdditionalDocs2: {
        page: null,
        pages: null
      },
      AdditionalDocs3: {
        page: null,
        pages: null
      },
      AdditionalDocs4: {
        page: null,
        pages: null
      }
    }
  }

  // PDF PAGE FLIP METHODS
  // the 'e' in onDocumentComplete is the total page number of the pdf
  public onDocumentComplete = (pdfName: string) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: 1,
      pages: parseInt(e.toString())
    }
    this.setState(state)
  }

  public handlePrevious = (pdfName: string) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: this.state[pdfName].page - 1,
      pages: this.state[pdfName].pages
    }

    this.setState(state)
  }
  public handleNext = (pdfName: any) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: this.state[pdfName].page + 1,
      pages: this.state[pdfName].pages
    }
    this.setState(state)
  }
  public renderPagination = (pdfName: string, page: number, pages: number) => {
    let previousButton = (
      <li className='btn btn__prime' onClick={this.handlePrevious(pdfName)}>
        Previous
      </li>
    )
    if (page === 1) {
      previousButton = <li className='btn btn__default'>Previous</li>
    }
    let nextButton = (
      <li className='btn btn__prime' onClick={this.handleNext(pdfName)}>
        Next
      </li>
    )
    if (page === pages) {
      nextButton = <li className='btn btn__default'>Next</li>
    }
    return (
      <div className='gb-container__next-prev-w'>
        {previousButton}
        {nextButton}
      </div>
    )
  }

  public componentDidMount(): void {
    const pdfData = this.props.pdfData

    // When the pdfs are first rendered, additional docs are
    // not included.
    mainSections.map((pdfName: string) => {
      const pdfFilePath =
        pdfName === 'ProfileCover'
          ? `guidebook/static/Profile-cover.pdf`
          : `guidebook/${this.props.id}-${pdfName}.pdf`
      const householdFinId = this.props.id

      const encodedPath = encodeURIComponent(pdfFilePath)
      api()
        .get(
          `/households/${householdFinId}/guidebookgenerator/renderpdf?pdfFilePath=${encodedPath}`,
          {
            timeout: 600000,
            responseType: 'blob'
          }
        )
        .then((response) => {
          const blobData = new Blob([response.data], {
            type: 'application/pdf'
          })
          pdfData[pdfName] = window.URL.createObjectURL(blobData)
          this.props.setPdfData(pdfName, window.URL.createObjectURL(blobData))
        })
    })
  }

  public renderPdfs = () => {
    const renderSections = { ...this.props.pdfSections }
    const pdfData = this.props.pdfData

    return allSections.map((pdfName: string) => {
      if (renderSections[`checked${pdfName}`] && pdfData[pdfName]) {
        const pdfContent = pdfData[pdfName]
        return (
          <div key={pdfName}>
            <PDF
              file={pdfContent}
              onDocumentComplete={this.onDocumentComplete(pdfName)}
              page={this.state[pdfName] ? this.state[pdfName].page : null}
              style={{ width: '100%' }}
            />
            <br />
            {this.state[pdfName] && this.state[pdfName].pages
              ? this.renderPagination(
                  pdfName,
                  this.state[pdfName].page,
                  this.state[pdfName].pages
                )
              : null}
          </div>
        )
      } else return null
    })
  }

  public render() {
    return this.renderPdfs()
  }
}

export default PdfRenderer
