import React, { Component } from 'react'
// import { useScrollData } from 'scroll-data-hook'
import StrategySearch from './strategySearch'
import StrategyFilters from './strategyFilters'
import FilterStrategies from './filterStrategies/filterStrategies'
import { FilterDropdownTypes } from '../../../../objects/strategySearch'
import ucLogo from '../../../assets/images/logos/United-Capital-Logo-230x80.png'

interface StrategyHeaderState {
  scrollLocation: number
  strategyFilterTypeSelected: string
  filterOpenObj: {
    custodiansFilterOpen: boolean
    strategiesFilterOpen: boolean
    managersFilterOpen: boolean
    accountFilterOpen: boolean
    taxStatusFilterOpen: boolean
    esgFilterOpen: boolean
  }
}

class StrategyHeader extends Component<{}, StrategyHeaderState> {
  public state: StrategyHeaderState = {
    scrollLocation: 0,
    strategyFilterTypeSelected: 'Managed Strategies',
    filterOpenObj: {
      custodiansFilterOpen: false,
      strategiesFilterOpen: false,
      managersFilterOpen: false,
      accountFilterOpen: false,
      taxStatusFilterOpen: false,
      esgFilterOpen: false
    }
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  public componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  public handleScroll = (event: any) => {
    this.setState({
      scrollLocation: event.target.scrollingElement.scrollTop
    })
  }

  public toggleStrategyFilterTypeSelected = (selected: string) => {
    this.setState({ strategyFilterTypeSelected: selected })
  }

  public toggleFilterDropdown = (label?: FilterDropdownTypes) => {
    const { filterOpenObj } = this.state
    if (!label) {
      return this.setState({
        filterOpenObj: {
          ...filterOpenObj,
          custodiansFilterOpen: false,
          strategiesFilterOpen: false,
          managersFilterOpen: false,
          accountFilterOpen: false,
          taxStatusFilterOpen: false,
          esgFilterOpen: false
        }
      })
    }
    const filterType = () => {
      switch (label) {
        case 'Select Custodians':
          return 'custodiansFilterOpen'
        case 'Select Strategies':
          return 'strategiesFilterOpen'
        case 'Select Managers':
          return 'managersFilterOpen'
        case 'Account Minimum':
          return 'accountFilterOpen'
        case 'Account Type':
          return 'taxStatusFilterOpen'
        case 'ESG':
          return 'esgFilterOpen'
        default:
          return ''
      }
    }
    this.setState({
      filterOpenObj: {
        ...filterOpenObj,
        custodiansFilterOpen: false,
        strategiesFilterOpen: false,
        managersFilterOpen: false,
        accountFilterOpen: false,
        taxStatusFilterOpen: false,
        esgFilterOpen: false,
        [filterType()]: !filterOpenObj[filterType()]
      }
    })
  }

  public render() {
    const scrollDown = this.state.scrollLocation > 20
    const { filterOpenObj } = this.state
    return (
      <div className='strategy-search__header-w'>
        <div
          className={`strategy-search__header-inner ${
            scrollDown ? `strategy-search__header-inner--scrolling` : ``
          }`}>
          <div>
            <div className='strategy-search__header-top-w'>
              <img
                className='strategy-search__header-logo'
                src={ucLogo}
                alt='UC logo'
              />
            </div>
            <div className='strategy-search__header-top-w'>
              <StrategySearch />
            </div>
          </div>
          <div
            className={`strategy-search__header-bottom-w ${
              scrollDown ? `strategy-search__header-bottom-w--scrolling` : ``
            }`}>
            {filterOpenObj.strategiesFilterOpen ? (
              <FilterStrategies
                strategyFilterTypeSelected={
                  this.state.strategyFilterTypeSelected
                }
                toggleFilterDropdown={this.toggleFilterDropdown}
                toggleStrategyFilterTypeSelected={
                  this.toggleStrategyFilterTypeSelected
                }
              />
            ) : (
              <StrategyFilters
                toggleFilterDropdown={this.toggleFilterDropdown}
                filterOpenObj={filterOpenObj}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default StrategyHeader
