import { LOCATION_CHANGE } from 'react-router-redux'
import { history } from '../browserHistory'

export const meetingMode = (store: any) => (next: any) => async (
  action: any
) => {
  const meetingModeId = sessionStorage.getItem('meetingmode')
    ? JSON.parse(sessionStorage.getItem('meetingmode')).householdId
    : null

  if (
    meetingModeId &&
    action.type === LOCATION_CHANGE &&
    !action.payload.pathname.includes(meetingModeId) &&
    !action.payload.pathname.includes('auth/callback') &&
    !action.payload.pathname.includes('logout')
  ) {
    history.push(`/households/${meetingModeId}/dashboard`)
    return next(action)
  }
  return next(action)
}
