import React from 'react'

const TableHeader = ({
  children
}: {
  children: JSX.Element[] | JSX.Element
}) => {
  return <div className='table-header__w'>{children}</div>
}

export default TableHeader
