import React from 'react'
// import { CardObj } from '../../../objects/honestConversations'
import TableRow from '../../../components/layout/tableRow'
import HonestConversationSmallCard from '../hcSmallCard'
import { CardObj } from '../../../../objects/HonestConversations/card'

interface HonestConversationCardSelectModalRowProps {
  card: CardObj
  selectCard(card: CardObj): void
}

class HonestConversationCardSelectModalRow extends React.Component<
  HonestConversationCardSelectModalRowProps
> {
  public selectCard = () => {
    this.props.selectCard(this.props.card)
  }
  public render() {
    const { card } = this.props
    return (
      <TableRow key={card.title} onClick={this.selectCard}>
        <HonestConversationSmallCard
          cardTitle={card.title}
          cardType={card.category}
          withTooltip={false}
        />
        <div className='hc-meeting__card-select-title'>{card.title}</div>
      </TableRow>
    )
  }
}

export default HonestConversationCardSelectModalRow
