import { ToastObj } from '../objects/toasts'
import * as actions from '../actions/toasts'

export interface ToastState {
  [id: string]: ToastObj
}

export const initState: ToastState = {}

const Toasts = (state = initState, action: any): ToastState => {
  switch (action.type) {
    case actions.ADD_TOAST:
      return {
        ...state,
        [action.payload.id]: action.payload
      }
    case actions.REMOVE_TOAST: {
      const id = action.payload
      if (id && state[id]) {
        const toasts = { ...state }
        delete toasts[id]
        return toasts
      }
      return state
    }
    default:
      return state
  }
}

export default Toasts
