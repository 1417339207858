import React, { Component } from 'react'
import StrategyItem from '../strategyItem'
import { IMStrategiesObj } from '../../../../objects/strategies'

export interface StrategyTileProps {
  displayName: string
  strategies: IMStrategiesObj[]
}

interface StrategyTileState {
  expandedId: string
}

class StrategyTile extends Component<StrategyTileProps, StrategyTileState> {
  constructor(props: StrategyTileProps) {
    super(props)
    this.state = {
      expandedId: null
    }
  }

  /**
   *
   * @param id
   * set the id of the clicked strategy row
   */
  public setExpandedId = (id: string) => {
    const { expandedId } = this.state
    this.setState({
      expandedId: expandedId !== id ? id : null
    })
  }

  public renderItem = (sorted: IMStrategiesObj[]) => {
    const { expandedId } = this.state
    return sorted.map((strategy: IMStrategiesObj, index: number) => {
      return (
        <StrategyItem
          key={index}
          index={index}
          strategy={strategy}
          expandedId={expandedId}
          expandFunc={this.setExpandedId}
          isLast={sorted.length === index + 1}
        />
      )
    })
  }

  public render = () => {
    const { displayName, strategies } = this.props
    return strategies?.length ? (
      <div className='strategy-search__tile'>
        <div className='strategy-search__row strategy-search__tile strategy-search__tile-label'>
          {displayName}
        </div>
        {this.renderItem(strategies)}
      </div>
    ) : null
  }
}

export default StrategyTile
