import { ClientTask } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

export interface TaskObj {
  id: string
  sfId?: string
  lifeActionTaskId?: string
  crmId: string
  crmSource: string
  subject: string
  description: string
  dueDate: string
  createdDate: string
  completedDate: string
  status: string
  assigneeType: string
  assigneeNames: string
  includeInGuidebook: boolean
  advisorAssignee?: string
  primaryAssignee?: string
  secondaryAssignee?: string
  isClosed?: boolean
  lastModifiedDate?: string
  crmTaskId?: string
  showInGuidecenter?: boolean
  isCrmTask?: boolean
  lastSynced?: string
}

export const emptyTaskObj: TaskObj = {
  id: null,
  crmId: null,
  crmSource: null,
  subject: null,
  description: null,
  dueDate: null,
  createdDate: null,
  completedDate: null,
  status: null,
  assigneeType: null,
  assigneeNames: null,
  includeInGuidebook: false,
  advisorAssignee: null,
  primaryAssignee: null,
  secondaryAssignee: null,
  isClosed: null,
  lastModifiedDate: null
}

export interface TaskObjState {
  [key: string]: TaskObj
}

export interface TaskInterface {
  tasks: TaskObjState
  openTasks?: TaskObjState
  completedTasks?: TaskObjState
  inProgressTasks?: TaskObjState
}

export interface TaskInterfaceV2 extends TaskInterface {
  sideBarState: boolean
  selectedTaskId?: null
  viewMode: boolean
  taskRequestId: string
  activeTask?: TaskObj
  disableTask?: boolean
}

export enum ETaskStatus {
  Open = 'Open',
  InProgress = 'In Progress',
  Cancelled = 'Canceled',
  Completed = 'Completed'
}

export interface TaskStatusSelectionObj {
  value: string
  label: string
  field: string
}

export const TaskStatusSelection: TaskStatusSelectionObj[] = [
  { value: ETaskStatus.Open, label: ETaskStatus.Open, field: 'completedDate' },
  {
    value: ETaskStatus.InProgress,
    label: ETaskStatus.InProgress,
    field: 'completedDate'
  },
  {
    value: ETaskStatus.Cancelled,
    label: ETaskStatus.Cancelled,
    field: 'completedDate'
  },
  {
    value: ETaskStatus.Completed,
    label: ETaskStatus.Completed,
    field: 'completedDate'
  }
]

export interface TaskResponse extends ClientTask.Response {
  householdId: string
  clientTaskId?: string
  crmTasks?: TaskObj[]
}

export enum ETaskView {
  'open',
  'inProgress',
  'history'
}

export type ClientsTaskNotificationStatus = {
  [key: string]: SingleClientNotificationStatus
}

export type SingleClientNotificationStatus = {
  key: 'primary' | 'secondary'
  name: string
  enabled: boolean
  assigned: boolean
}

export const TASK_SUBJECT_MAX_LIMIT = 500
