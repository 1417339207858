import React from 'react'
import ProfilePicture from '../../components/layout/profilePicture'

interface HouseholdDisplayProps {
  name: string
  primaryFirstName?: string
  primaryLastName?: string
  secondaryFirstName?: string
  secondaryLastName?: string
  primaryPhoto: string
  secondaryPhoto?: string
  hasSecondary?: boolean
}

class HouseholdDisplay extends React.Component<HouseholdDisplayProps> {
  public render() {
    const { name, primaryPhoto, secondaryPhoto } = this.props
    return (
      <div className='household-display-w'>
        <div className='household-display__avatar-w'>
          <div className='household-display__avatar household-display__avatar--primary'>
            <ProfilePicture
              firstName={
                this.props.primaryFirstName ? this.props.primaryFirstName : null
              }
              lastName={
                this.props.primaryLastName ? this.props.primaryLastName : null
              }
              photo={primaryPhoto}
              isPrimary={true}
              pictureSize={32}
            />
          </div>
          {this.props.hasSecondary ? (
            <div className='household-display__avatar household-display__avatar--secondary'>
              <ProfilePicture
                firstName={
                  this.props.secondaryFirstName
                    ? this.props.secondaryFirstName
                    : null
                }
                lastName={
                  this.props.secondaryLastName
                    ? this.props.secondaryLastName
                    : null
                }
                photo={secondaryPhoto}
                isPrimary={false}
                pictureSize={32}
              />
            </div>
          ) : null}
        </div>
        <div className='household-display__name'>{name}</div>
      </div>
    )
  }
}

export default HouseholdDisplay
