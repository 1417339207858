import React, { Component, SyntheticEvent } from 'react'

import Tile from '../../../components/layout/tile'
import { SortFields } from './clientStrategies'
import Button from '../../../components/button'

import SortIcon from '../../../assets/images/icons/ic_sort.svg'

interface StrategySortContentProps {
  sortFields: SortFields
  tooltipHeader(title: string, icon: any): JSX.Element
  resetSort(): void
  onSortFieldChecked(field: string): (evt: SyntheticEvent) => void
  sortData(): void
  onSortPopupClose(): void
}

export default class StrategySortContent extends Component<
  StrategySortContentProps
> {
  public tileBody = () => {
    const {
      sortFields,
      onSortFieldChecked,
      sortData,
      onSortPopupClose
    } = this.props
    return (
      <div className='client-strategies__sort'>
        <div className='client-strategies__sort-link'>
          <label
            htmlFor='name'
            className={
              sortFields.name ? 'client-strategies__sort--selected' : ''
            }>
            Strategy Name
          </label>
          <input
            id='name'
            type='checkbox'
            aria-checked={true}
            className='checkbox checkbox-no-border'
            checked={sortFields.name}
            onChange={onSortFieldChecked('name')}
          />
        </div>
        <div className='client-strategies__sort-link'>
          <label
            htmlFor='investment'
            className={
              sortFields.investmentMinimum
                ? 'client-strategies__sort--selected'
                : ''
            }>
            Minimum Investment
          </label>
          <input
            id='investment'
            type='checkbox'
            aria-checked={true}
            className='checkbox checkbox-no-border'
            checked={sortFields.investmentMinimum}
            onChange={onSortFieldChecked('investmentMinimum')}
          />
        </div>
        <div className='client-strategies__sort-link'>
          <label
            htmlFor='yield'
            className={
              sortFields.yield ? 'client-strategies__sort--selected' : ''
            }>
            Yield
          </label>
          <input
            id='yield'
            type='checkbox'
            aria-checked={true}
            className='checkbox checkbox-no-border'
            checked={sortFields.yield}
            onChange={onSortFieldChecked('yield')}
          />
        </div>
        <div className='client-strategies__sort-link'>
          <label
            htmlFor='fee'
            className={
              sortFields.fees ? 'client-strategies__sort--selected' : ''
            }>
            Fee
          </label>
          <input
            id='fee'
            type='checkbox'
            aria-checked={false}
            className='checkbox checkbox-no-border'
            checked={sortFields.fees}
            onChange={onSortFieldChecked('fees')}
          />
        </div>
        <div className='client-strategies__filter'>
          <div className='client-strategies__sort-btns'>
            <Button onClick={onSortPopupClose}>Cancel</Button>
            <Button primary={true} onClick={sortData}>
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }

  public render() {
    return (
      <Tile
        headerBorder={true}
        style={{ border: '0px' }}
        leftHeader={this.props.tooltipHeader('SORT BY', SortIcon)}
        rightHeader={
          <a
            className='client-strategies__reset'
            onClick={this.props.resetSort}>
            Reset
          </a>
        }>
        {this.tileBody()}
      </Tile>
    )
  }
}
