import React, { Component, RefObject, createRef } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { ContactsInterface } from '../../../../../objects/contact'
import { isObjEmpty } from '../../../../helpers'
import { ClientAccountInterface } from '../../../../../objects/clientAccount'

interface PortfolioHeaderProps {
  benchmark: any
  householdFinId: string
  contact: ContactsInterface
  clientAccounts: ClientAccountInterface
  editName(): void
}

interface PortfolioHeaderState {
  nameDisabled: boolean
}

class AnalysisHeader extends Component<
  PortfolioHeaderProps,
  PortfolioHeaderState
> {
  nameInputRef: RefObject<HTMLInputElement>
  constructor(props: PortfolioHeaderProps) {
    super(props)
    this.state = {
      nameDisabled: true
    }
    this.nameInputRef = createRef()
  }

  public combinedName = () => {
    const { contact } = this.props
    const primary = 'primary' in contact && contact.primary
    const secondary = 'secondary' in contact && contact.secondary

    if (!primary) {
      return null
    }

    if (!secondary) {
      return `${primary.firstName} ${primary.lastName}`
    }

    return (
      (secondary.lastName.toLowerCase() === primary.lastName.toLowerCase() &&
        `${primary.firstName} & ${secondary.firstName} ${secondary.lastName}`) ||
      `${primary.firstName} ${primary.lastName} & ${secondary.firstName} ${secondary.lastName}`
    )
  }

  public enableEditName = () => {
    const { nameDisabled } = this.state
    this.setState({
      nameDisabled: !nameDisabled
    })

    this.nameInputRef.current.focus()
  }

  public render = () => {
    return <div></div>
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  const { contact, clientAccount = {} } = store
  return {
    householdFinId,
    contact: contact[householdFinId],
    clientAccounts:
      !isObjEmpty(clientAccount) && clientAccount[householdFinId].clientAccounts
  }
}

export default connect(mapStateToProps)(AnalysisHeader)
