import React, { Fragment } from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as ClientsIcon } from '../../assets/images/icons/clients_fill.svg'
import {
  OfficeTeamsInterface,
  OfficeTeamObj,
  OfficeTeamMemberObj
} from '../../../objects/officeTeams'
import { HouseholdObj } from '../../../objects/household'
import ProfilePicture from '../../components/layout/profilePicture'

interface AssignTeamModalProps {
  household: HouseholdObj
  officeTeams: OfficeTeamsInterface
  officeAdmin: boolean
  closeModal(): void
  chooseTeam(teamId: string): void
}

interface AssignTeamModalState {
  currentTeamId: string
}

class AssignTeamModal extends React.Component<
  AssignTeamModalProps,
  AssignTeamModalState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      currentTeamId: this.props.household.officeTeam
    }
  }

  public handleCheckBox = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const currentTeamId = event.currentTarget.id
    this.setState({ currentTeamId })
  }

  public assignTeam = () => {
    this.props.chooseTeam(this.state.currentTeamId)
    this.props.closeModal()
  }

  public renderTeamMembers = (members: OfficeTeamMemberObj[]) => {
    const position: number = 25
    const remainingMembers = members.length - 4
    let count = 0
    return members.map((member: OfficeTeamMemberObj, index: number) => {
      const user = member.userId && member.user ? member.user : null
      if (count < 4 && member.showInGuidecenter) {
        count = count + 1
        return (
          <Fragment key={index}>
            <div
              key={index}
              className='assign-team__modal__body__teams'
              style={{
                top: 0,
                left: `${(count - 1) * position}px`,
                zIndex: index
              }}>
              <ProfilePicture
                firstName={user.firstName}
                lastName={user.lastName}
                photo={user.photoUrl}
                isPrimary={true}
                pictureSize={32}
              />
            </div>
          </Fragment>
        )
      } else if (count === 4 && member.showInGuidecenter) {
        count = count + 1
        return (
          <Fragment key={index}>
            <span
              className='assign-team__modal__body__team-member-photo more'
              style={{ top: 0, left: `${4 * position}px`, zIndex: 5 }}>
              +{remainingMembers}
            </span>
          </Fragment>
        )
      } else return null
    })
  }

  public renderTable = () => {
    const { officeTeams, officeAdmin } = this.props
    const teams: OfficeTeamObj[] = officeTeams
      ? Object.keys(officeTeams).map((key) => officeTeams[key])
      : []
    return teams.map((team: OfficeTeamObj, index: number) => {
      if (team.members && team.members.length > 0) {
        return (
          <div
            key={index}
            className='assign-team__modal__body__row  assign-team__modal__body__row--col'>
            <div className='assign-team__modal__body__row-radio'>
              {officeAdmin && (
                <input
                  type='radio'
                  value={index}
                  onChange={this.handleCheckBox}
                  id={team.id}
                  name='team'
                  aria-checked={this.state.currentTeamId === team.id}
                  checked={this.state.currentTeamId === team.id}
                />
              )}
              <label htmlFor={team.name}>
                <h5>{team.name}</h5>
              </label>
            </div>
            <div>
              <div className='assign-team__modal__body__teams-w'>
                {this.renderTeamMembers(team.members)}
              </div>
            </div>
          </div>
        )
      } else return null
    })
  }

  public render() {
    const { closeModal, officeAdmin } = this.props
    return (
      <Modal icon={ClientsIcon} title='CLIENT TEAM' closeModal={closeModal}>
        <div className='assign-team__modal__body'>{this.renderTable()}</div>
        <div className='assign-team__modal__body__actionBtns'>
          <span className='btn btn__clear' onClick={closeModal}>
            {officeAdmin ? 'Cancel' : 'Close'}
          </span>
          {officeAdmin && (
            <span className='btn btn__prime' onClick={this.assignTeam}>
              Update
            </span>
          )}
        </div>
      </Modal>
    )
  }
}

export default AssignTeamModal
