import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../../../reducers'
import InnerContainer from '../../../../components/layout/innerContainer'
import Tile from '../../../../components/layout/tile'
import BackToLink from '../../../../components/layout/headerBackToLink'
import {
  HonestConversationsExerciseInterface,
  CardRankingObj,
  HonestConversationsExerciseObj,
  ClientCardObj
} from '../../../../../objects/honestConversations'
import * as hcExercisesActions from '../../../../../actions/hcExercises'
import { setActiveHonestConversationExercise } from '../../../../../actions/households'
import HonestConversationNewIndividualCards from './hcNewIndividualCards'
import HonestConversationNewCombinedCards from './hcNewCombinedCards'
import { ContactsInterface } from '../../../../../objects/contact'
import { HouseholdObj } from '../../../../../objects/household'
import Button from '../../../../components/button'
import { history } from '../../../../../store'
import { DropResult } from 'react-beautiful-dnd'
import { getCardForComment, getCardForAutoSave } from '../helpers/hcCardMeeting'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../../objects/HonestConversations/card'
import {
  mapSelectedCardsToModel,
  setRanking,
  mapScoresToCards
} from '../../../../helpers/honestConversations'
import {
  LifeActionObj,
  LifeActionsInterface
} from '../../../../../objects/lifeActions'
import { filterActions } from '../../../../helpers/lifeActions'
import { getHCCards } from '../../../../../actions/hcExerciseCards'
import { isEqual } from '../../../../helpers/index'
import _ from 'lodash'

// Max no. of card a client can have
const maxCardCount = 5

// extend so we can bind actionList while DnD
interface CombinedCardRankingObj extends CardRankingObj {
  actionList?: LifeActionObj[]
}

interface HonestConversationsMeetingProps {
  dispatch: Dispatch<GlobalState>
  hcExercises: HonestConversationsExerciseInterface
  hcExercise: HonestConversationsExerciseObj
  hcExerciseCards: CardOptionsInterface
  newExercise: HonestConversationsExerciseObj
  contacts: ContactsInterface
  householdFinId: string
  household: HouseholdObj
  lifeActions: LifeActionsInterface
}

export interface HonestConversationsMeetingState {
  selectedTab: 'Combined' | 'Individual'
  primaryContactCards: CardRankingObj[]
  secondaryContactCards: CardRankingObj[]
  combinedCards: CombinedCardRankingObj[]
  primaryScoreSessionId: string
  secondaryScoreSessionId: string
  enableSave: boolean
  oldHcExerciseId: string
  previousActiveScoringSessionIdPrimary: string
  previousActiveScoringSessionIdSecondary: string
}

class HonestConversationsNewMeeting extends Component<
  HonestConversationsMeetingProps,
  HonestConversationsMeetingState
> {
  constructor(props: HonestConversationsMeetingProps) {
    super(props)
    this.state = {
      enableSave: false,
      selectedTab: 'Individual',
      primaryContactCards: _.times(maxCardCount, (rank) => ({
        cardId: null,
        id: null,
        card: null,
        rankedWithin: 'All',
        comment: '',
        ranking: rank + 1,
        isChanged: false
      })),
      secondaryContactCards: _.times(maxCardCount, (rank) => ({
        cardId: null,
        id: null,
        card: null,
        rankedWithin: 'All',
        comment: '',
        ranking: rank + 1,
        isChanged: false
      })),
      combinedCards: _.times(maxCardCount, (rank) => ({
        id: null,
        card: null,
        rankedWithin: 'All',
        commentPrimary: '',
        commentSecondary: '',
        ranking: rank + 1,
        primaryScore: null,
        secondaryScore: null,
        actionList: null,
        isChanged: false
      })),
      primaryScoreSessionId: null,
      secondaryScoreSessionId: null,
      oldHcExerciseId: null,
      previousActiveScoringSessionIdPrimary: null,
      previousActiveScoringSessionIdSecondary: null
    }
  }

  public async componentDidMount() {
    const { dispatch, householdFinId, hcExercises, household } = this.props
    this.setState({
      oldHcExerciseId: household.hcExerciseId
    })
    if (!hcExercises) {
      await dispatch(
        hcExercisesActions.getHonestConversationExercises(householdFinId)
      )
    }
    await dispatch(
      hcExercisesActions.createHonestConversationExercise(householdFinId)
    )
    // get all exercise cards
    await dispatch(getHCCards(householdFinId))
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    const { hcExercise, contacts } = this.props
    if (!hcExercise) {
      return
    }
    // If clientCards are created(client cards are over 15), add the created card's id to state (necessary for autosave for comment changes)
    if (
      !isEqual(hcExercise, prevProps?.hcExercise) &&
      ((hcExercise &&
        contacts &&
        hcExercise.clientCards &&
        hcExercise.clientCards[contacts.primary.id].length > 15) ||
        (contacts?.secondary &&
          hcExercise.clientCards[contacts.secondary.id].length > 15))
    ) {
      let primaryContactCardsFromState = [...this.state.primaryContactCards]
      let secondaryContactCardsFromState = [...this.state.secondaryContactCards]
      // Handles primary clientCard id
      if (hcExercise.clientCards[contacts.primary.id].length > 15) {
        const primaryClientCards = hcExercise.clientCards[contacts.primary.id]
        const filteredPrimary = primaryClientCards.filter(
          (clientCard) => clientCard.rankedWithin === 'All'
        )
        primaryContactCardsFromState = this.mapIdsToCards(
          filteredPrimary,
          primaryContactCardsFromState
        )
      }
      // Handles secondary clientCard id
      if (
        contacts.secondary &&
        hcExercise.clientCards[contacts.secondary.id].length > 15
      ) {
        const secondaryClientCards =
          hcExercise.clientCards[contacts.secondary.id]
        const filteredSecondary = secondaryClientCards.filter(
          (clientCard) => clientCard.rankedWithin === 'All'
        )
        secondaryContactCardsFromState = this.mapIdsToCards(
          filteredSecondary,
          secondaryContactCardsFromState
        )
      }
      this.setState({
        primaryContactCards: primaryContactCardsFromState,
        secondaryContactCards: secondaryContactCardsFromState
      })
    }

    // handles combined cards and scoring sessions
    if (
      (!prevProps?.hcExercise?.householdCards && hcExercise.householdCards) ||
      !isEqual(
        prevProps.hcExercise.householdCards,
        hcExercise.householdCards
      ) ||
      !isEqual(
        prevProps.hcExercise.clientScoringSessions,
        hcExercise.clientScoringSessions
      )
    ) {
      const setCombinedCardsArray = this.setCombinedCards()
      this.setState({
        combinedCards: setCombinedCardsArray
      })
    }
  }

  public newScoringSession = async (contactId: string, isPrimary: boolean) => {
    // create new scoring session
    await this.createScoringSession(contactId, isPrimary)
    // update exercise with new active scoring session id
    await this.updateActiveScoreSessionId()
  }

  public createScoringSession = async (
    contactId: string,
    isPrimary: boolean
  ) => {
    const { dispatch, household, hcExercise, contacts } = this.props
    if (isPrimary) {
      this.setState({
        previousActiveScoringSessionIdPrimary:
          hcExercise.activeScoringSessionPrimary
      })
    } else if (contacts.secondary && !isPrimary) {
      this.setState({
        previousActiveScoringSessionIdSecondary:
          hcExercise.activeScoringSessionSecondary
      })
    }
    await dispatch(
      hcExercisesActions.createScoringSessions(
        household.id,
        household.hcExerciseId,
        {
          clientId: contactId,
          scoredWithAdvisor: true
        },
        isPrimary
      )
    )
  }

  public updateActiveScoreSessionId = async () => {
    const { household, hcExercise, dispatch, contacts } = this.props
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    updatedProperty.activeScoringSessionPrimary =
      hcExercise.activeScoringSessionPrimary
    if (contacts.secondary) {
      updatedProperty.activeScoringSessionSecondary =
        hcExercise.activeScoringSessionSecondary
    }
    await dispatch(
      hcExercisesActions.updateHonestConversationExercise(
        household.id,
        household.hcExerciseId,
        updatedProperty
      )
    )
  }

  public createCardScoreAndUpdateScoringSession = async (
    contactId: string,
    scoringSessionId?: string
  ) => {
    const {
      contacts,
      householdFinId,
      hcExercise,
      dispatch,
      household
    } = this.props
    const scoreSessionId =
      scoringSessionId ||
      (contactId === contacts.primary.id
        ? hcExercise.activeScoringSessionPrimary
        : hcExercise.activeScoringSessionSecondary)

    contactId === contacts.primary.id
      ? this.setState({
          primaryScoreSessionId: scoreSessionId
        })
      : this.setState({
          secondaryScoreSessionId: scoreSessionId
        })

    const scoreCardArray: any = []
    const contactType =
      contactId === contacts.primary.id ? 'Primary' : 'Secondary'
    this.state.combinedCards.forEach((card: CardRankingObj) => {
      if (card?.card?.id) {
        scoreCardArray.push({
          householdCardId: card.id,
          score: card[`${contactType.toLowerCase()}Score`] || null,
          comment: card[`comment${contactType}`] || '',
          cardScoreId: card[`${contactType.toLowerCase()}CardScoreId`]
        })
      }
    })
    await dispatch(
      hcExercisesActions.createCardScoreAndUpdateScoringSession(
        contactId,
        householdFinId,
        household.hcExerciseId,
        scoreSessionId,
        scoreCardArray
      )
    )
  }

  public onCardSelection = (
    index: number,
    contactType: string,
    card: CardObj
  ) => {
    if (card) {
      const prevState = JSON.parse(JSON.stringify(this.state))
      const { contacts } = this.props
      const {
        newState,
        cardExist,
        priorityCardExist,
        combinedCardExist
      } = getCardForAutoSave({
        contacts,
        prevState,
        contactType,
        index,
        card
      })
      newState.enableSave = true
      this.setState(newState, () => {
        // cardExist determines if creation or update needs to occur
        if (cardExist) {
          this.autoSaveOnUpdate(
            index,
            contactType,
            card,
            priorityCardExist,
            combinedCardExist
          )
        } else {
          this.autoSave(
            index,
            contactType,
            priorityCardExist,
            combinedCardExist
          )
        }
      })
    }
  }
  public onCombinedCommentChange = (
    index: number,
    commentKey: 'commentPrimary' | 'commentSecondary',
    comment: string
  ) => {
    // get current combined card state
    const { combinedCards } = this.state
    combinedCards[index][commentKey] = comment
    this.setState({ combinedCards })
  }

  public onModifyAction = (index: number, actionList: LifeActionObj[]) => {
    const { combinedCards } = this.state
    combinedCards[index]['actionList'] = actionList
    this.setState({ combinedCards })
  }

  public onScoreChange = (
    index: number,
    scoreKey: 'primaryScore' | 'secondaryScore',
    score: number
  ) => {
    const { combinedCards } = this.state
    const { hcExercise, contacts } = this.props
    const { primary, secondary } = contacts
    const {
      activeScoringSessionPrimary,
      activeScoringSessionSecondary
    } = hcExercise
    combinedCards[index][scoreKey] = score
    const isPrimary = scoreKey === 'primaryScore'
    const contactId = isPrimary ? primary.id : secondary.id
    let activeScoringSessionId = isPrimary
      ? activeScoringSessionPrimary
      : activeScoringSessionSecondary
    this.setState({ combinedCards }, async () => {
      if (!activeScoringSessionId) {
        await this.newScoringSession(contactId, isPrimary)
        activeScoringSessionId = isPrimary
          ? this.props.hcExercise.activeScoringSessionPrimary
          : this.props.hcExercise.activeScoringSessionSecondary
      }
      this.createCardScoreAndUpdateScoringSession(contactId)
    })
  }
  public onCommentChange = (
    index: number,
    contactType: string,
    comment: string
  ) => {
    const cardList: CardRankingObj[] = this.state[contactType]
    const newState = {
      ...this.state,
      ...{
        [contactType]: getCardForComment({ comment, cardList, index })
      }
    }
    this.setState(newState)
  }

  // Recursion boolean is for Single HH only feature:
  // If clientCard or householdCard is reordered it needs to update one another
  // onDrag is retriggered one time with the recursion set to true
  public onDrag = (result: DropResult, recursion?: boolean) => {
    const newState = this.state
    const { source, destination } = result

    let listResult = Object.keys(newState[destination.droppableId]).map(
      (key) => newState[destination.droppableId][key]
    )
    if (newState[`${destination.droppableId}`].length > 0) {
      listResult = newState[`${destination.droppableId}`]
    }

    const [removed] = listResult.splice(source.index, 1)
    listResult.splice(destination.index, 0, removed)
    newState[`${destination.droppableId}`] = setRanking(listResult)

    // If single HH and recursion is false(onDrag has not repeated), set singleResult to either clientCard or CombinedCard
    // If recursion is true, we know that onDrag is in its repeated phase, below is skipped so infinite call does not happen
    let singleResult: DropResult = null
    if (!this.props.contacts.secondary && !recursion) {
      if (destination.droppableId === 'combinedCards') {
        singleResult = JSON.parse(JSON.stringify(result))
        singleResult.destination.droppableId = 'primaryContactCards'
      } else if (destination.droppableId === 'primaryContactCards') {
        singleResult = JSON.parse(JSON.stringify(result))
        singleResult.destination.droppableId = 'combinedCards'
      }
    }

    this.setState(newState, () => {
      // If single Result, repeat onDrag with recursion set to true so clientCard -> hh card or HH card -> clientCard also gets updated.
      if (singleResult) {
        this.onDrag(singleResult, true)
      }
      const { dispatch, householdFinId, hcExercise, contacts } = this.props
      const {
        primaryContactCards,
        secondaryContactCards,
        combinedCards
      } = this.state
      const contactType = destination.droppableId
      switch (contactType) {
        case 'primaryContactCards':
          if (primaryContactCards?.length >= 1) {
            dispatch(
              hcExercisesActions.updateAllClientCards(
                householdFinId,
                hcExercise.id,
                contacts.primary.id,
                primaryContactCards
              )
            )
          }
          break
        case 'secondaryContactCards':
          if (secondaryContactCards?.length >= 1) {
            dispatch(
              hcExercisesActions.updateAllClientCards(
                householdFinId,
                hcExercise.id,
                contacts.secondary.id,
                secondaryContactCards
              )
            )
          }
          break
        case 'combinedCards':
          const allCombinedCards = combinedCards.filter((card) => card.id)
          dispatch(
            hcExercisesActions.updateHonestConversationHouseholdCards(
              householdFinId,
              hcExercise.id,
              allCombinedCards
            )
          )
          break
        default:
      }
    })
  }
  public setActive = () => {
    const { householdFinId, newExercise } = this.props
    this.props.dispatch(
      setActiveHonestConversationExercise(householdFinId, newExercise.id)
    )
  }
  public updateSelectedTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id }: any = e.currentTarget
    this.setState({ selectedTab: id })
  }
  public toggleSelectedTab = () => {
    this.setState({ selectedTab: 'Combined' })
  }
  public leftHeader = () => {
    return (
      <div className='hc-meeting__left-header-w'>
        <h2 className='hc-meeting__left-header'>ADD NEW RECORD</h2>
      </div>
    )
  }

  public redirect = () => {
    const { dispatch, householdFinId } = this.props
    dispatch(hcExercisesActions.getHonestConversationExercises(householdFinId))
    history.push(`/households/${householdFinId}/honestConversations/meetings`)
  }

  public cancel = async () => {
    const { householdFinId, dispatch, newExercise } = this.props
    const { oldHcExerciseId } = this.state
    if (oldHcExerciseId) {
      await dispatch(
        setActiveHonestConversationExercise(householdFinId, oldHcExerciseId)
      )
    }
    await dispatch(
      hcExercisesActions.deleteHonestConversationExercise(
        householdFinId,
        newExercise.id
      )
    )
    history.push(`/households/${householdFinId}/honestConversations/meetings`)
  }

  public setCombinedCardData = async (
    cards: CardRankingObj[],
    action: string,
    clientId: string,
    index: number | string,
    type: string = 'create'
  ) => {
    const { householdFinId, newExercise, dispatch } = this.props
    let newCards
    if (type === 'create') {
      newCards = cards.filter((clientCard, arrIndex) => {
        const node = type === 'create' ? arrIndex : clientCard.cardId
        return node === index
      })
    } else {
      newCards = cards.filter((card) => Boolean(card.id))
    }
    await dispatch(
      hcExercisesActions[`${action}ClientCards`](
        householdFinId,
        newExercise.id,
        clientId,
        newCards
      )
    )
  }

  public autoSave = async (
    index?: number,
    contactType?: string,
    priorityCardExist?: boolean,
    combinedCardExist?: boolean
  ) => {
    const {
      primaryContactCards,
      secondaryContactCards,
      combinedCards
    } = this.state
    const {
      contacts,
      contacts: { primary, secondary },
      householdFinId,
      newExercise,
      dispatch
    } = this.props

    if (contactType === 'combinedCards') {
      await this.createCombinedCards(
        combinedCards,
        index,
        householdFinId,
        newExercise,
        contacts
      )
      // For single HH ONLY - handles priority card to default to selected combinedcard, two scenarios can be encountered
      if (!secondary) {
        // priority card can already exist so we need to update the client card instead of create
        const action = priorityCardExist ? 'updateAll' : 'create'
        this.setCombinedCardData(primaryContactCards, action, primary.id, index)
      }
    }

    if (contactType === 'secondaryContactCards') {
      this.setCombinedCardData(
        secondaryContactCards,
        'create',
        secondary.id,
        index
      )
    }

    if (contactType === 'primaryContactCards') {
      this.setCombinedCardData(primaryContactCards, 'create', primary.id, index)

      if (!secondary) {
        if (combinedCardExist) {
          const allCombinedCards = combinedCards?.filter((card) => card.id)
          if (allCombinedCards?.length >= 1) {
            await dispatch(
              hcExercisesActions.updateHonestConversationHouseholdCards(
                householdFinId,
                newExercise.id,
                allCombinedCards
              )
            )
          }
        } else {
          await this.createCombinedCards(
            combinedCards,
            index,
            householdFinId,
            newExercise,
            contacts
          )
        }
      }
    }
  }

  public autoSaveOnUpdate = async (
    index: number,
    contactType: string,
    card: CardObj,
    priorityCardExist?: boolean,
    combinedCardExist?: boolean
  ) => {
    const {
      contacts,
      contacts: { primary, secondary },
      householdFinId,
      newExercise,
      dispatch,
      hcExercise
    } = this.props
    const {
      primaryContactCards,
      secondaryContactCards,
      combinedCards
    } = this.state

    if (contactType === 'combinedCards') {
      await dispatch(
        hcExercisesActions.updateHonestConversationHouseholdCards(
          householdFinId,
          hcExercise.id,
          combinedCards.filter((card) => card.id)
        )
      )
      // single HH ONLY - updating combinedcards needs to also update clientcards to the same new card
      if (!secondary) {
        const action = priorityCardExist ? 'update' : 'create'
        this.setCombinedCardData(
          primaryContactCards,
          action,
          primary.id,
          card.id,
          'update'
        )
      }
      return
    }

    if (contactType === 'secondaryContactCards') {
      this.setCombinedCardData(
        secondaryContactCards,
        'updateAll',
        secondary.id,
        card.id,
        'update'
      )
    }

    if (contactType === 'primaryContactCards') {
      this.setCombinedCardData(
        primaryContactCards,
        'updateAll',
        primary.id,
        card.id,
        'update'
      )
      if (!secondary) {
        // Still within primaryContact if-statement block - Single HH ONLY
        if (combinedCardExist) {
          const allCombinedCards = combinedCards?.filter((card) => card.id)
          if (allCombinedCards?.length >= 1) {
            await dispatch(
              hcExercisesActions.updateHonestConversationHouseholdCards(
                householdFinId,
                hcExercise.id,
                allCombinedCards
              )
            )
          }
        } else {
          await this.createCombinedCards(
            combinedCards,
            index,
            householdFinId,
            newExercise,
            contacts
          )
        }
      }
    }
  }

  public autoSaveOnBlur = async (
    contactType: string,
    index?: number,
    card?: CardObj
  ) => {
    const {
      contacts,
      householdFinId,
      hcExerciseCards,
      newExercise,
      dispatch
    } = this.props

    const cardKey =
      contactType === 'primaryContactCards'
        ? 'primary'
        : contactType === 'secondaryContactCards'
        ? 'secondary'
        : ''
    const cardList = this.state[`${cardKey}ContactCards`]
    const activeCard = cardList[index]

    // if card key is exist and there is change in comment
    if (cardKey && activeCard.isChanged) {
      const clientCards: CardRankingObj[] = setRanking(cardList)
      const mappedCards = mapSelectedCardsToModel(clientCards, hcExerciseCards)
      const contactId = contacts[cardKey].id
      await dispatch(
        hcExercisesActions.updateAllClientCards(
          householdFinId,
          newExercise.id,
          contactId,
          mappedCards
        )
      )

      // Reset isChanged set to false to notify that new comment is pushed
      activeCard.isChanged = false
      this.setState(activeCard)
    }
  }

  public mapIdsToCards(
    filteredCards: ClientCardObj[],
    contactCardsFromState: CardRankingObj[]
  ) {
    filteredCards.forEach((item, index) => {
      contactCardsFromState.forEach((primeStateCard) => {
        if (primeStateCard.card && item.card.id === primeStateCard.card.id) {
          if (!primeStateCard.id) {
            primeStateCard.id = item.id
          }
          primeStateCard.cardId = item.card.id
        }
      })
    })
    return contactCardsFromState
  }

  public async createCombinedCards(
    mappedHouseholdCards: CardRankingObj[],
    index: number,
    householdFinId: string,
    newExercise: HonestConversationsExerciseObj,
    contacts: ContactsInterface
  ) {
    const newCards = mappedHouseholdCards.filter((clientCard, arrIndex) => {
      return arrIndex === index
    })
    await this.props.dispatch(
      hcExercisesActions.createHonestConversationHouseholdCards(
        householdFinId,
        newExercise?.id,
        newCards
      )
    )
    this.setState({ combinedCards: this.setCombinedCards() }, () => {
      return newCards
    })
  }

  public setCombinedCards = () => {
    const {
      hcExercise,
      hcExercise: {
        activeScoringSessionPrimary,
        activeScoringSessionSecondary
      },
      lifeActions,
      contacts
    } = this.props

    const { primary, secondary } = contacts || {}
    const mappedPrimaryScores =
      primary &&
      Object.keys(primary).length &&
      activeScoringSessionPrimary &&
      mapScoresToCards(primary.id, activeScoringSessionPrimary, hcExercise)
    const mappedSecondaryScores =
      secondary &&
      Object.keys(secondary).length &&
      activeScoringSessionSecondary &&
      mapScoresToCards(secondary.id, activeScoringSessionSecondary, hcExercise)
    const newState = [...this.state.combinedCards]
    const HHCard: any = hcExercise ? hcExercise.householdCards : []
    const newHouseholdCards = newState.map((card: any, indx: number) => {
      let listOfActions = null
      let newId = null

      const primaryScoreObj = mappedPrimaryScores && mappedPrimaryScores[indx]
      const secondaryScoreObj =
        mappedSecondaryScores && mappedSecondaryScores[indx]

      Object.keys(HHCard).map((id) => {
        const hcCard = hcExercise.householdCards[id]
        if (card?.card?.id === hcCard?.card?.id) {
          if (lifeActions) {
            listOfActions = filterActions(lifeActions, hcCard)
          }
          newId = hcCard?.id
        }
      })
      return {
        ...card,
        id: newId,
        cardId: card?.card?.id,
        actionList: listOfActions,
        primaryCardScoreId: primaryScoreObj?.id || primaryScoreObj?.cardScoreId,
        secondaryCardScoreId:
          secondaryScoreObj?.id || secondaryScoreObj?.cardScoreId
      }
    })
    return newHouseholdCards
  }

  public selectedTabContent = () => {
    const {
      selectedTab,
      primaryContactCards,
      secondaryContactCards,
      combinedCards
    } = this.state
    const { contacts, hcExerciseCards, householdFinId } = this.props

    return selectedTab === 'Combined' ? (
      <HonestConversationNewCombinedCards
        householdFinId={householdFinId}
        contacts={contacts}
        cards={combinedCards}
        onDrag={this.onDrag}
        onBlur={this.autoSaveOnBlur}
        cardOptions={hcExerciseCards}
        onScoreChange={this.onScoreChange}
        onCardSelection={this.onCardSelection}
        onCommentChange={this.onCombinedCommentChange}
        onModifyAction={this.onModifyAction}
      />
    ) : (
      <HonestConversationNewIndividualCards
        householdFinId={householdFinId}
        contacts={contacts}
        onDrag={this.onDrag}
        onBlur={this.autoSaveOnBlur}
        onCardSelection={this.onCardSelection}
        onCommentChange={this.onCommentChange}
        cardOptions={hcExerciseCards}
        primaryContactCards={primaryContactCards}
        secondaryContactCards={secondaryContactCards}
      />
    )
  }

  public editButtons = () => {
    const { enableSave, selectedTab } = this.state

    const saveButton = (
      <Button primary={enableSave} onClick={this.redirect}>
        Save
      </Button>
    )
    const nextButton = (
      <Button
        primary={enableSave}
        onClick={enableSave ? this.toggleSelectedTab : null}>
        Next
      </Button>
    )

    return (
      <div className='hc-meeting__button-w'>
        <Button clear={true} onClick={this.cancel}>
          Cancel
        </Button>
        {selectedTab === 'Individual' ? nextButton : saveButton}
      </div>
    )
  }

  public render() {
    const { hcExerciseCards, contacts } = this.props
    const { selectedTab } = this.state
    const secondary = contacts && contacts.secondary

    if (!hcExerciseCards) {
      return null
    }
    return (
      <InnerContainer>
        <div className='hc-meeting__container-w'>
          <BackToLink onClick={this.redirect}>
            Back to HonestConversations<sup key='reg'>&reg;</sup> History
          </BackToLink>
        </div>
        <Tile leftHeader={this.leftHeader()} headerStyle={{ padding: 20 }}>
          <div className='hc-meeting__header-tab-w'>
            <div
              id='Individual'
              onClick={this.updateSelectedTab}
              className={
                selectedTab === 'Individual'
                  ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                  : 'hc-meeting__header-tab'
              }>
              {secondary ? 'Individual' : 'Priorities'}
            </div>
            <div
              id='Combined'
              onClick={this.updateSelectedTab}
              className={
                selectedTab === 'Combined'
                  ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                  : 'hc-meeting__header-tab'
              }>
              {secondary ? 'Combined' : 'Scores'}
            </div>
          </div>
          <div>{this.selectedTabContent()}</div>
          {this.editButtons()}
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  return {
    hcExerciseCards: store.hcExerciseCards,
    hcExercises: store.hcExercises[householdFinId],
    hcExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][
        store.households[householdFinId].hcExerciseId
      ],
    newExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId].new,
    contacts: store.contact[householdFinId],
    householdFinId,
    household: store.households[householdFinId],
    lifeActions: store.lifeActions[householdFinId]
  }
}

export default withRouter(
  connect(mapStateToProps)(HonestConversationsNewMeeting)
)
