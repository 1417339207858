import React from 'react'
import Tile from '../../components/layout/tile'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'

import { UserObj } from '../../../objects/user'
import * as userActions from '../../../actions/user'
import ShowProfile from './showProfile'
import EditProfile from './editProfile'

import { ReactComponent as ProfileIcon } from '../../assets/images/icons/avatar.svg'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import ProfilePhotoModal from './photoModal'
import Disclosure from '../../components/layout/disclosure'

interface ProfileProps {
  user: UserObj
  match: any
  dispatch: Dispatch<GlobalState>
}

interface ProfileState {
  edit: boolean
  showUploadModal: boolean
  showEditModal: boolean
}

class Profile extends React.Component<ProfileProps, ProfileState> {
  constructor(props: ProfileProps) {
    super(props)
    this.state = {
      edit: false,
      showEditModal: false,
      showUploadModal: false
    }
  }

  public editProfileIcon = () => {
    return (
      <span className='tile__header-link' onClick={this.editProfile}>
        <EditIcon /> Edit
      </span>
    )
  }

  public editProfile = () => {
    this.setState({ edit: true })
  }

  public cancelEditProfile = () => {
    this.setState({ edit: false })
  }

  public saveProfile = (request: any) => {
    this.props.dispatch(userActions.updateUser(request))
    this.setState({ edit: false })
  }

  public uploadPhoto = async (fileName: string, image: string) => {
    await this.props.dispatch(
      userActions.addPhoto(this.props.user.id, fileName, image)
    )
    this.toggleEditModal()
  }

  public deletePhoto = async () => {
    await this.props.dispatch(userActions.deletePhoto(this.props.user.id))
  }

  public toggleEditModal = () =>
    this.setState({ showEditModal: !this.state.showEditModal })
  public toggleUploadModal = () =>
    this.setState({ showUploadModal: !this.state.showUploadModal })

  public render() {
    const title = this.state.edit ? 'EDIT PROFILE' : 'MY PROFILE'
    const { showUploadModal, showEditModal } = this.state
    return (
      <div className='advisor__body'>
        <div className='advisor__body-content-w'>
          <div className='advisor__body-content'>
            <Tile
              style={{ marginTop: '25px' }}
              headerStyle={{ backgroundColor: '#FAFAFA' }}
              leftHeader={
                <span className='tile__header-label'>
                  <ProfileIcon key='icon' />
                  {title}
                </span>
              }
              rightHeader={this.editProfileIcon()}
              headerBorder={true}>
              <div className='a-profile'>
                <div className='a-profile__info'>
                  {this.state.edit ? (
                    <EditProfile
                      user={this.props.user}
                      cancelFn={this.cancelEditProfile}
                      saveFn={this.saveProfile}
                      editFn={this.toggleEditModal}
                      uploadFn={this.toggleUploadModal}
                    />
                  ) : (
                    <ShowProfile user={this.props.user} />
                  )}
                  <ProfilePhotoModal
                    showUploadModal={showUploadModal}
                    showEditModal={showEditModal}
                    toggleEditModal={this.toggleEditModal}
                    toggleUploadModal={this.toggleUploadModal}
                    user={this.props.user}
                    uploadFn={this.uploadPhoto}
                    deleteFn={this.deletePhoto}
                  />
                </div>
              </div>
            </Tile>
            <Disclosure />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    user: store.user.userId ? store.user.users[store.user.userId] : null
  }
}

export default withRouter(connect(mapStateToProps)(Profile))
