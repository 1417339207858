import { HouseholdsState } from '../../../reducers/households'

export const searchFilterHelper = (
  searchFilter: string,
  households?: HouseholdsState
) => {
  if (!searchFilter || searchFilter === '') {
    return households
  }
  const filteredHouseholds: HouseholdsState = {}

  households &&
    Object.keys(households)
      .filter((key) => {
        return households[key].name
          ? households[key].name.toLowerCase().includes(searchFilter)
          : ''
      })
      .forEach((key) => {
        filteredHouseholds[key] = households[key]
      })

  return filteredHouseholds
}
