import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../../reducers'
import PortfolioProposalItem from './portfolioProposalItem'

import { PortfolioBuilderInterface } from '../../../../objects/portfolioBuilder'
import { PortfolioProposalObj } from '../../../../objects/portfolioProposal'
import { HouseholdObj } from '../../../../objects/household'
import { getPortfolioProposals } from '../../../../actions/portfolioProposal'
import { portfolioBuilderTitle } from '../../../helpers/portfolioProposal'
import { isObjEmpty } from '../../../helpers'

import InnerContainer from '../../../components/layout/innerContainer'
import ContentHeader from '../../../components/layout/contentHeader'
import NullState from '../../../components/nullState'
import Tile from '../../../components/layout/tile'
import BackToLink from '../../../components/layout/headerBackToLink'

import { ReactComponent as InvestmentsIconSVG } from '../../../assets/images/icons/investments.svg'
import InvestmentIcon from '../../../assets/images/icons/png/investments.png'

// import { ClipLoader } from 'react-spinners'
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'
import Button from '../../../components/button'
import { history } from '../../../../store'
import { getFolders } from '../../../../actions/documentVault'
import { FolderInterface } from '../../../../objects/folder'
import { getVaultFolderIdByName } from '../../../helpers/guidebook'

interface PphProps {
  dispatch?: Dispatch<GlobalState>
  match: any
  portfolioBuilderList: PortfolioBuilderInterface
  portfolioBuilderId: string
  householdFinId: HouseholdObj
  portfolioProposals: PortfolioProposalObj[]
  folders: FolderInterface
}

interface PphState {
  title: string
  vaultFolderId: string
}

class PortfolioProposalHistory extends Component<PphProps, PphState> {
  constructor(props: PphProps) {
    super(props)

    this.state = {
      title: portfolioBuilderTitle(
        this.props.portfolioBuilderList,
        this.props.portfolioBuilderId
      ),
      vaultFolderId: ''
    }
  }

  public componentDidMount = async () => {
    const { householdFinId, dispatch } = this.props
    this.props.dispatch(getPortfolioProposals(householdFinId.id))
    await dispatch(getFolders(householdFinId.id))
    const vaultFolderId = await getVaultFolderIdByName({
      vaultFolderName: 'Portfolio Proposals',
      householdId: householdFinId.id
    })
    this.setState({
      vaultFolderId
    })
  }

  public nullState = () => {
    return (
      <NullState
        style={{ padding: '80px 0' }}
        imgSrc={InvestmentIcon}
        heading='No portfolio proposal yet'
        text='Create a new portfolio proposal by clicking on the button in the top right.'
      />
    )
  }

  public createNewPortfolioProposal = () => {
    const currentUrl = this.props.match.url
    const removedHistory = currentUrl.replace('/history', '')
    return (
      <Button primary onClick={() => history.push(removedHistory)}>
        <PlusIcon />
        Create New
      </Button>
    )
  }

  public portfolioProposalItems = () => {
    return Object.keys(this.props.portfolioProposals).map((key) => {
      const portfolioProposal = this.props.portfolioProposals[key]
      return (
        <PortfolioProposalItem
          vaultFolderId={this.state.vaultFolderId}
          portfolioProposal={portfolioProposal}
          householdFinId={this.props.householdFinId.id}
          key={key}
        />
      )
    })
  }

  public render() {
    return (
      <InnerContainer>
        <BackToLink
          style={{ marginBottom: 0 }}
          to={`/households/${this.props.householdFinId.id}/portfolioBuilder/${this.props.portfolioBuilderId}`}>
          Back to {this.state.title}
        </BackToLink>
        <ContentHeader
          Icon={InvestmentsIconSVG}
          title='Portfolio Proposal'
          rightHeader={this.createNewPortfolioProposal()}
        />
        <Tile
          leftHeader='PROPOSAL VERSIONS'
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          headerBorder={true}>
          {!isObjEmpty(this.props.portfolioProposals)
            ? this.portfolioProposalItems()
            : this.nullState()}
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: store.households[match.params.householdFinId],
    portfolioBuilderId: match.params.portfolioBuilderId,
    portfolioBuilderList: store.portfolioBuilder[match.params.householdFinId],
    portfolioProposals:
      store.portfolioProposal &&
      store.portfolioProposal[match.params.householdFinId]
        ? store.portfolioProposal[match.params.householdFinId]
            .portfolioProposals
        : null,
    folders: store.documentVault.folders[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(PortfolioProposalHistory))
