import * as actions from '../actions/tasks'
import { TaskObj, TaskInterface, TaskInterfaceV2 } from '../objects/tasks'
export interface TasksState {
  [key: string]: TaskInterfaceV2
}

export const initState: TasksState = {}

const mapTask = (task: any): TaskObj => {
  return {
    id: task.id,
    crmId: task.crmId,
    sfId: task.sfId,
    crmSource: task.crmSource,
    description: task.description,
    status: task.status,
    createdDate: task.createdDate,
    completedDate: task.completedDate,
    dueDate: task.dueDate,
    subject: task.subject,
    assigneeType: task.assigneeType,
    assigneeNames: task.assigneeNames,
    includeInGuidebook: task.includeInGuidebook,
    advisorAssignee: task.advisorAssignee,
    primaryAssignee: task.primaryAssignee,
    secondaryAssignee: task.secondaryAssignee,
    isClosed: task.isClosed,
    lastModifiedDate: task.lastModifiedDate,
    isCrmTask: Boolean(task.crmId && task.crmSource),
    lastSynced: null
  }
}

const mapTaskUpdate = (tasks: TaskInterface, task: TaskObj) => {
  if (tasks && tasks[task?.id]) {
    tasks[task?.id] = mapTask(task)
  }
  return tasks
}

const mapTasks = (tasks: TaskObj[]) => {
  const TasksObj = {}
  tasks.forEach((task) => {
    if (task?.id) {
      TasksObj[task?.id] = mapTask(task)
    }
  })
  return { TasksObj }
}

const Tasks = (state = initState, action: any) => {
  const { type, payload: { data = {} } = {}, payload } = action || {}
  const newState = JSON.parse(JSON.stringify(state))

  // Set Default state when there is new houshold
  if (data?.householdId && !newState[data?.householdId]) {
    newState[data.householdId] = {
      sideBarState: false,
      selectedTaskId: null,
      viewMode: '',
      tasks: {},
      taskRequestId: '',
      activeTask: {}
    }
  }

  switch (type) {
    case `${actions.FETCH_ALL_TASKS}_FULFILLED`:
      newState[data.householdId].tasks =
        mapTasks(data.length ? data : []).TasksObj || {}
      newState[data.householdId].activeTask = newState[data.householdId]
        ?.sideBarState
        ? newState[data.householdId]?.activeTask
        : {}
      newState[data.householdId].viewMode = newState[data.householdId]
        ?.sideBarState
        ? newState[data.householdId]?.viewMode
        : ''
      return newState
    case `${actions.SET_TASKS}`:
      newState[data.householdId].tasks =
        mapTasks(data.nativeTasks.length ? data.nativeTasks : []).TasksObj || {}
      newState[data.householdId].activeTask = newState[data.householdId]
        ?.sideBarState
        ? newState[data.householdId]?.activeTask
        : {}
      newState[data.householdId].viewMode = newState[data.householdId]
        ?.sideBarState
        ? newState[data.householdId]?.viewMode
        : ''
      return newState
    case `${actions.FETCH_TASK}_FULFILLED`:
      const singleTask = mapTask(data)
      newState[data.householdId].tasks = {
        ...newState[data.householdId],
        [singleTask.id]: singleTask
      }
      newState[data.householdId].activeTask = {}
      newState[data.householdId].viewMode = ''
      return newState
    case `${actions.CREATE_TASK}_FULFILLED`:
      const newTask = mapTask(data)
      newState[data.householdId].tasks = {
        ...newState[data.householdId].tasks,
        [newTask.id]: newTask
      }
      return newState
    case `${actions.UPDATE_TASK}_FULFILLED`:
      newState[data.householdId].tasks = {
        ...mapTaskUpdate(newState[data.householdId].tasks, data)
      }
      return newState
    case `${actions.DELETE_TASK}_FULFILLED`:
      if (
        payload.householdId &&
        payload.clientTaskId &&
        newState[payload.householdId]
      ) {
        delete newState[payload.householdId].tasks[payload.clientTaskId]
      }
      return newState
    case `${actions.UPDATE_TASK_BY_ID}`:
      newState[data.householdId].activeTask = {
        ...newState[data.householdId].activeTask,
        ...data
      }
      return newState
    case `TASK_MODE`:
      newState[data.householdId].viewMode = data.mode
      newState[data.householdId].disableTasks = data.disableTasks
      newState[data.householdId].activeTask =
        data.mode === 'ADD'
          ? {
              id: 'NEW',
              subject: '',
              assigneeNames: '',
              status: '',
              dueDate: '',
              includeInGuidebook: true,
              completedDate: ''
            }
          : data.mode === 'EDIT'
          ? data.activeTask
          : {}
      return newState
    case `TOGGLE_SIDEBAR_STATE`:
      newState[data.householdId].sideBarState = data.sideBarState
      return newState
    default:
      return newState
  }
}
export default Tasks
