import React from 'react'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'

interface SendEmailModalProps {
  title: string
  bodyText: string
  actionBtnTitle: string
  onAction(): void
  onCancel(): void
}

class SendEmailModal extends React.Component<SendEmailModalProps> {
  constructor(props: any) {
    super(props)
  }

  public render() {
    const { title, bodyText, actionBtnTitle, onCancel, onAction } = this.props
    return (
      <Modal
        headerStyle={{ fontSize: '16px' }}
        title={title}
        closeModal={onCancel}>
        <div className='send-modal__content'>
          {bodyText}
          <div className='send-modal__buttons'>
            <Button onClick={onCancel}>Cancel</Button>
            <Button primary={true} onClick={onAction}>
              {actionBtnTitle}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default SendEmailModal
