import React, { Component } from 'react'
import Tile from '../../components/layout/tile'
import SubContainer from '../../components/layout/subContainer'
import AlertsNotifications from './alertsNotifications'
import { ReactComponent as SettingsIcons } from '../../assets/images/icons/settings.svg'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import Disclosure from '../../components/layout/disclosure'

interface AdvisorSettingsProps {
  dispatch?: Dispatch<GlobalState>
  userId: string
}

interface AdvisorSettingsState {
  activeTab:
    | 'AlertsNotifications'
    | 'SigninSecurity'
    | 'ClientAlertsNotification'
    | 'TeamManagement'
}

interface AdvisorSettingsProps {
  userId: string
}

const activeTabOptions = {
  AlertsNotifications: 'AlertsNotifications',
  SigninSecurity: 'SigninSecurity',
  ClientAlertsNotification: 'ClientAlertsNotification',
  TeamManagement: 'TeamManagement'
}

export class AdvisorSettings extends Component<
  AdvisorSettingsProps,
  AdvisorSettingsState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      activeTab: 'AlertsNotifications'
    }
  }

  public toggleAlertsNotifications = () => {
    this.setState({ activeTab: 'AlertsNotifications' })
  }
  public toggleSigninSecurity = () => {
    this.setState({ activeTab: 'SigninSecurity' })
  }
  public toggleClientAlertsNotification = () => {
    this.setState({ activeTab: 'ClientAlertsNotification' })
  }

  public advisorSettingsRender = () => {
    const { activeTab } = this.state
    const { userId } = this.props
    return (
      <Tile
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <span className='tile__header-label'>
            <SettingsIcons /> Settings
          </span>
        }
        headerBorder={true}>
        <div className='c-settings-w'>
          <aside className='c-settings__menu'>
            <ul>
              <li
                onClick={this.toggleAlertsNotifications}
                className={
                  activeTab === activeTabOptions.AlertsNotifications
                    ? 'c-settings__menu-item c-settings__menu-item--active'
                    : 'c-settings__menu-item'
                }>
                Alerts and Notifications
              </li>
            </ul>
          </aside>
          <main className='c-settings a-settings__row-w'>
            {activeTab === activeTabOptions.AlertsNotifications && userId ? (
              <AlertsNotifications userId={userId} />
            ) : null}
          </main>
        </div>
      </Tile>
    )
  }

  public render() {
    return (
      <SubContainer>
        {this.advisorSettingsRender()}
        <Disclosure />
      </SubContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    userId: store.user.userId
  }
}

export default withRouter(connect(mapStateToProps)(AdvisorSettings))
