import React from 'react'
import Modal from '../../components/layout/modal'

import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg'
import { Link } from 'react-router-dom'
import { ContactObj } from '../../../objects/contact'
import { HouseholdObj } from '../../../objects/household'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import Button from '../../components/button'
import { isObjEmpty, dateFormat } from '../../helpers'
import { getUsername } from '../../../actions/contacts'
import ProfilePicture from '../../components/layout/profilePicture'
// import GCIcon from '../../assets/images/icons/png/gc_logo-gray.png';

interface ImpersonationModalProps {
  dispatch?: Dispatch<GlobalState>
  household: HouseholdObj
  primaryContact: ContactObj
  secondaryContact: ContactObj
  accessToken: string
  closeModal(): void
}

class ImpersonationModal extends React.Component<ImpersonationModalProps> {
  public async componentDidMount() {
    const { dispatch } = this.props
    try {
      await dispatch(
        getUsername(this.props.household.id, this.props.primaryContact.id)
      )
      if (this.props.secondaryContact) {
        await dispatch(
          getUsername(this.props.household.id, this.props.secondaryContact.id)
        )
      }
    } catch (error) {
      console.error('An error occurred')
    }

    // Set cookie at top most domain
    let i = 0
    let domain = document.domain
    const parts = domain.split('.')
    if (domain === 'localhost') {
      document.cookie = `token=${this.props.accessToken}; domain=${domain}; path=/`
    }
    while (
      i < parts.length - 1 &&
      !document.cookie.includes(`token=${this.props.accessToken}`)
    ) {
      i = i + 1
      domain = parts.slice(-1 - i).join('.')
      document.cookie = `token=${this.props.accessToken}; domain=${domain}; path=/`
    }
  }

  public openPrimaryGuideCenter = () => {
    const { primaryContact } = this.props
    window.open(
      `${window._env_.REACT_APP_GUIDECENTER_URL}?contact=${primaryContact.id}`
    )
  }
  public openSecondaryGuideCenter = () => {
    const { secondaryContact } = this.props
    window.open(
      `${window._env_.REACT_APP_GUIDECENTER_URL}?contact=${secondaryContact.id}`
    )
  }

  public renderLastLogin = (contact: ContactObj) => {
    return contact.enabled ? (
      <p className='impersonation-modal__last-login'>
        Last login:{' '}
        {contact.lastLogin ? dateFormat(contact.lastLogin) : 'Never'}
      </p>
    ) : (
      <p className=' impersonation-modal__last-login impersonation-modal__last-login--not-activated'>
        Not activated
      </p>
    )
  }

  public contactInfo = (
    contact: ContactObj,
    isPrimary: boolean,
    openGuideCenterOnClick: () => void
  ) => {
    return (
      <div className='impersonation-modal__contact'>
        <ProfilePicture
          firstName={contact.firstName}
          lastName={contact.lastName}
          photo={contact.photo}
          isPrimary={isPrimary}
          pictureSize={64}
        />
        <p className='impersonation-modal__contact-name'>
          {contact.firstName} {contact.lastName}
        </p>
        {this.renderLastLogin(contact)}
        <Button
          style={{ width: '160px' }}
          primary={true}
          onClick={openGuideCenterOnClick}>
          Login
        </Button>
      </div>
    )
  }

  public render() {
    const {
      closeModal,
      primaryContact,
      secondaryContact,
      household
    } = this.props
    return (
      <Modal title='GuideCenter View' closeModal={closeModal}>
        <div className='impersonation-modal__content-w'>
          <p className='impersonation-modal__text'>
            Choose a client on whose behalf you want to view GuideCenter.
          </p>
          <div className='impersonation-modal__contact-w'>
            {!isObjEmpty(primaryContact)
              ? this.contactInfo(
                  primaryContact,
                  true,
                  this.openPrimaryGuideCenter
                )
              : null}
            {!isObjEmpty(secondaryContact)
              ? this.contactInfo(
                  secondaryContact,
                  false,
                  this.openSecondaryGuideCenter
                )
              : null}
          </div>
          <hr className='impersonation-modal__divider' />
          <p className='impersonation-modal__text'>
            To send activation email to a client go to
          </p>
          <Link
            className='impersonation-modal__client-settings-w'
            to={`/households/${household.id}/clientSettings`}
            onClick={closeModal}>
            <SettingsIcon className='impersonation-modal__settings-icon' />
            Client Settings
          </Link>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    accessToken: store.user.accessToken
  }
}

export default connect(mapStateToProps)(ImpersonationModal)
