import React from 'react'

// tslint:disable-next-line:max-func-body-length
const GoalsLoadingView = () => {
  const nullRow = (opacity: number) => {
    return (
      <div
        className='goals__null__col-w goals__null__row'
        style={{ opacity: `${opacity}` }}>
        <div className='goals__null__col-item goals__null__col-item--large'>
          <div className='goals__null__loading-effect' />
          <span className='goals__null__status-icon goals__null__status-icon--na' />
          <div className='goals__null--col'>
            <span className='goals__null goals__null--name' />
            <span className='goals__null goals__null--status' />
          </div>
        </div>
        <div className='goals__null__right'>
          <div className='goals__null__col-item goals__null__col-item--small goals__null__col-item--center'>
            <span className='goals__null__status-icon goals__null__status-icon--na' />
          </div>
          <div className='goals__null__col-item goals__null__col-item--small'>
            <span className='goals__null goals__null--active' />
          </div>
          <div className='goals__null__col-item goals__null__col-item--xsmall'>
            <span className='goals__null__status-icon goals__null__status-icon--na goals__null__status-icon--nomargin' />
            <span className='goals__null__status-icon goals__null__status-icon--na goals__null__status-icon--nomargin' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='goals__null-w'>
      {nullRow(1)}
      {nullRow(1)}
      {nullRow(1)}
      {nullRow(0.9)}
      {nullRow(0.75)}
      {nullRow(0.55)}
      {nullRow(0.45)}
      {nullRow(0.35)}
      {nullRow(0.25)}
      {nullRow(0.09)}
    </div>
  )
}

export default GoalsLoadingView
