import React from 'react'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/user'
import WealthboxAuth from './wealthboxAuth'
import HouseholdSync from './householdsSync'
import WealthboxIcon from '../../../assets/images/logos/wealthbox-logo--small.png'
import ContentHeader from '../../../components/layout/contentHeader'
import InnerContainer from '../../../components/layout/innerContainer'
import Disclosure from '../../../components/layout/disclosure'

interface WeathboxSyncProps {
  crm: string
  dispatch: Dispatch<GlobalState>
  fetchHouseholdsFailed: boolean
}

class WeathboxSync extends React.Component<WeathboxSyncProps> {
  public async componentDidMount() {
    await this.props.dispatch(actions.previewHouseholdSync())
  }
  public render() {
    const { crm, fetchHouseholdsFailed } = this.props
    return (
      <div className='sync-screen--w'>
        <InnerContainer>
          <ContentHeader title='Connect Your Clients' />
          {fetchHouseholdsFailed ? (
            <div>
              <WealthboxAuth />
            </div>
          ) : (
            <HouseholdSync crm={crm} iconPng={WealthboxIcon} />
          )}
          <Disclosure />
        </InnerContainer>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    fetchHouseholdsFailed: store.user.fetchHouseholdsFailed
  }
}

export default connect(mapStateToProps)(WeathboxSync)
