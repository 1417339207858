import React, { Component } from 'react'
import CardTile from './cardTile'
import { RevealButton } from './revealButton'
import {
  HonestConversationsExerciseObj,
  ClientCardObj,
  CardRankingObj
} from '../../../../../objects/honestConversations'
import { ContactObj } from '../../../../../objects/contact'
import { getTopFiveCards } from '../../../../helpers/honestConversations'
import { CardObj } from '../../../../../objects/HonestConversations/card'
import {
  updateClientCards,
  updateHonestConversationHouseholdCards,
  getHonestConversationExercise
} from '../../../../../actions/hcExercises'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { HouseholdObj } from '../../../../../objects/household'

export interface CardsListProps {
  household: HouseholdObj
  hcExercise: HonestConversationsExerciseObj
  householdFinId: string
  contact: ContactObj
  otherContact: ContactObj
  dispatch: Dispatch<GlobalState>
  contactType: string
}

interface CardsListState {
  revealCards: boolean
  nextCard: number
  clientCards: any
}

export class CardsList extends Component<CardsListProps, CardsListState> {
  constructor(props: CardsListProps) {
    super(props)
    this.state = {
      nextCard: null,
      revealCards: false,
      clientCards: null
    }
  }

  public componentDidMount() {
    const { hcExercise, contact } = this.props
    const clientCards =
      hcExercise &&
      getTopFiveCards(hcExercise.clientCards[contact && contact.id])
    this.setState({
      clientCards
    })
  }

  public nextAutoComment = (index: number) => {
    this.setState({
      nextCard: index
    })
  }

  /**
   * @param newClientCard
   * In the case of single HH, update household cards with the new card
   * update single card, and include all cards in payload
   */
  public singleHouseholdCardsUpdate = async (
    newClientCard: CardRankingObj[]
  ) => {
    const { hcExercise, household, householdFinId, dispatch } = this.props
    if (!household?.secondaryContact) {
      const { householdCards } = hcExercise
      const { ranking, card } = newClientCard[0]
      const householdCardsArray = Object.keys(householdCards)
        .map((cardKey: string) => {
          return {
            ...householdCards[cardKey],
            cardId: householdCards[cardKey].card.id
          }
        })
        .sort((a, b) => a.ranking - b.ranking)

      const newHouseholdCard = householdCardsArray.splice(ranking - 1, 1)[0]
      newHouseholdCard.cardId = card.id
      newHouseholdCard.card = card
      householdCardsArray.splice(ranking - 1, 0, newHouseholdCard)

      await dispatch(
        updateHonestConversationHouseholdCards(
          householdFinId,
          hcExercise.id,
          householdCardsArray
        )
      )
      dispatch(getHonestConversationExercise(householdFinId, hcExercise.id))
    }
  }

  public onSwitchCard = (
    card: CardObj,
    index: number,
    exerciseId: string,
    clientId: string,
    previousCardSwitchedOut: CardObj
  ) => {
    const { householdFinId, dispatch, hcExercise, contact } = this.props

    // Since a card was switched out, check if it's one that
    // was in the householdCards (combined list) and remove
    const updatedHouseholdCards = Object.keys(hcExercise.householdCards)
      .filter(
        (cardId) =>
          hcExercise.householdCards[cardId].card.id !==
          previousCardSwitchedOut.id
      )
      .map((cardKey: string) => {
        return {
          ...hcExercise.householdCards[cardKey],
          cardId: hcExercise.householdCards[cardKey].card.id
        }
      })
      .sort((a, b) => a.ranking - b.ranking)

    dispatch(
      updateHonestConversationHouseholdCards(
        householdFinId,
        hcExercise.id,
        updatedHouseholdCards
      )
    )

    if (card) {
      const clientCards =
        hcExercise && getTopFiveCards(hcExercise.clientCards[contact.id])
      const newCard = clientCards[index]
      newCard.cardId = card.id
      newCard.card = card
      clientCards.splice(index, 1, newCard)
      this.setState(
        {
          clientCards
        },
        async () => {
          const newClientCards: CardRankingObj[] = [newCard]
          await dispatch(
            updateClientCards(
              householdFinId,
              exerciseId,
              clientId,
              newClientCards
            )
          )
          this.singleHouseholdCardsUpdate(newClientCards)
        }
      )
    }
  }

  public renderCards = () => {
    const { hcExercise, householdFinId, otherContact } = this.props
    const { nextCard, clientCards } = this.state
    return (
      clientCards &&
      clientCards.map((clientCard: ClientCardObj, index: number) => {
        let isShared = false
        if (otherContact) {
          const otherClientCards = getTopFiveCards(
            hcExercise.clientCards[otherContact.id]
          )
          otherClientCards.forEach((otherClientCard) => {
            if (clientCard.card.id === otherClientCard.card.id) {
              isShared = true
            }
          })
        }
        return (
          <CardTile
            key={index}
            householdFinId={householdFinId}
            exerciseId={hcExercise.id}
            card={clientCard}
            selectedCards={this.state.clientCards}
            index={index}
            nextCard={nextCard}
            isShared={isShared}
            revealCards={this.state.revealCards}
            moveToNext={this.nextAutoComment}
            onSwitchCard={this.onSwitchCard}
          />
        )
      })
    )
  }

  public revealCards = () => {
    this.setState({
      revealCards: true
    })
    setTimeout(this.nextAutoComment, 1500, 0)
  }

  public render() {
    const { contact, contactType } = this.props
    const { revealCards } = this.state
    return (
      <div className='hc-reveal-w'>
        <div className='hc-reveal__title'>
          {(contact && contact.firstName) || contactType}
        </div>
        {this.renderCards()}
        {revealCards ? null : <RevealButton onClickFunc={this.revealCards} />}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    household: store.households[householdFinId]
  }
}

export default connect(mapStateToProps)(CardsList)
