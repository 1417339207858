import React, { Component } from 'react'
import CollapsableTile from '../../components/layout/collapsableTile'
import { ClientCardObj } from '../../../objects/honestConversations'
import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { ContactObj } from '../../../objects/contact'
import moment from 'moment'
import ProfilePicture from '../../components/layout/profilePicture'

const collapsableTileStyle = {
  marginTop: '24px',
  backgroundColor: '#FAFAFA'
}

interface HCPersonalPriorityTileProps {
  contact: ContactObj
  cards?: ClientCardObj[]
  isPrimary?: boolean
}

interface HCPersonalPriorityTileState {
  selectedCard: ClientCardObj
}

class HCPersonalPriorityTile extends Component<
  HCPersonalPriorityTileProps,
  HCPersonalPriorityTileState
> {
  public state = {
    selectedCard: this.props.cards[0]
  }

  public selectCard = (card: ClientCardObj) => (e: any) =>
    this.setState({ selectedCard: card })

  public renderCards = () => {
    const { cards } = this.props
    const { selectedCard } = this.state
    return (
      <div className='hc-personal-priority__card-w'>
        {cards.map((card, key) => {
          return (
            <div key={key}>
              <div
                style={{
                  cursor: 'pointer',
                  opacity: selectedCard.id === card.id ? 1 : 0.8
                }}
                onClick={this.selectCard(card)}>
                <Card category={card.card.category} title={card.card.title} />
              </div>
              {selectedCard.id === card.id && (
                <div className='hc-personal-priority__card-selected' />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  public render() {
    const { contact, cards, isPrimary } = this.props
    const { selectedCard } = this.state

    const contactType = isPrimary ? 'primary' : 'secondary'
    const title = contact.firstName
      ? `${contact.firstName}'s personal priorities`
      : `${contactType} personal priorities`
    return (
      <CollapsableTile
        collapsed={true}
        style={collapsableTileStyle}
        title={title.toLocaleUpperCase()}>
        {this.renderCards()}
        <div className='hc-personal-priority__content-w'>
          <div>
            <ProfilePicture
              firstName={contact.firstName}
              lastName={contact.lastName}
              photo={contact.photo}
              isPrimary={this.props.isPrimary}
              pictureSize={48}
            />
          </div>
          <div className='hc-personal-priority__name-w'>
            <p className='hc-personal-priority__name'>
              {contact.firstName} {contact.lastName}
            </p>
            <p className='hc-personal-priority__date'>
              {moment(cards[0] && cards[0].createdDate).format('MM/DD/YYYY')}
            </p>
          </div>
          <div className='hc-personal-priority__comment-w'>
            <p className='hc-personal-priority__comment-intro'>
              Why did {contact.firstName} choose this card?
            </p>
            <p className='hc-personal-priority__comment'>
              {selectedCard && selectedCard.comment
                ? selectedCard.comment
                : 'There is no comment for this card.'}
            </p>
          </div>
        </div>
      </CollapsableTile>
    )
  }
}

export default HCPersonalPriorityTile
