import React from 'react'
import InnerContainer from '../../../components/layout/innerContainer'
import Tile from '../../../components/layout/tile'
import BackToLink from '../../../components/layout/headerBackToLink'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
// import { HonestConversationObj } from '../../../objects/honestConversations'
// import * as actions from '../../../../actions/honestConversations'
// import HonestConversationScores from './hcCardScores'
import { ContactsInterface } from '../../../../objects/contact'
import { dateFormat } from '../../../helpers'
// import Button from '../../../components/button'
// import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg'
import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'

interface HonestConversationsScoreProps {
  dispatch: Dispatch<GlobalState>
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  householdFinId: string
  meetingFinId: string
  scoreFinId: string
}

interface HonestConversationsScoreState {
  selectedTab: string
}

class HonestConversationsScore extends React.Component<
  HonestConversationsScoreProps,
  HonestConversationsScoreState
> {
  constructor(props: HonestConversationsScoreProps) {
    super(props)

    this.state = {
      selectedTab: 'Combined'
    }
  }
  // public componentDidMount() {
  //   if (!this.props.honestConversation) {
  //     this.props.dispatch(
  //       actions.getHonestConversation(this.props.householdFinId)
  //     )
  //   }
  // }
  public updateSelectedTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget
    this.setState({ selectedTab: id })
  }
  // public setActive = () => {
  //   const { householdFinId, meetingFinId, scoreFinId } = this.props
  //   this.props.dispatch(
  //     actions.setActiveHonestConversationScore(
  //       householdFinId,
  //       meetingFinId,
  //       scoreFinId
  //     )
  //   )
  // }
  public render() {
    const {
      householdFinId,
      honestConversation,
      // contacts,
      // scoreFinId,
      meetingFinId
    } = this.props
    if (honestConversation) {
      // const rightHeader = scoreFinId
      //   ? `Score added ${dateFormat(
      //       honestConversation.scores[scoreFinId].dateOfScore
      //     )}`
      //   : null
      // const active = honestConversation.activeScore === scoreFinId
      return (
        <InnerContainer>
          <div className='hc-meeting__container-w'>
            <BackToLink
              to={`/households/${householdFinId}/honestConversations/meetings/${meetingFinId}/scores`}>
              Back to Score History
            </BackToLink>
            {/* {!active ? (
              <Button primary={true} onClick={this.setActive}>
                <CheckIcon /> Set as Active
              </Button>
            ) : (
              <div className='hc-meeting__active-badge' />
            )} */}
          </div>
          <Tile
            leftHeader={`Created on ${dateFormat(
              honestConversation.createdDate
            )}`}
            // rightHeader={rightHeader}
            headerBorder={true}
            headerStyle={{ backgroundColor: '#FAFAFA', padding: 20 }}>
            {/* <div>
              <HonestConversationScores
                householdFinId={householdFinId}
                meetingFinId={meetingFinId}
                honestConversation={honestConversation}
                score={honestConversation.scores[scoreFinId]}
                contacts={contacts}
              />
            </div> */}
          </Tile>
        </InnerContainer>
      )
    } else return null
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, meetingId, scoreId } = match.params
  return {
    honestConversation:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][meetingId],
    contacts: store.contact[householdFinId],
    householdFinId,
    meetingFinId: meetingId,
    scoreFinId: scoreId
  }
}

export default withRouter(connect(mapStateToProps)(HonestConversationsScore))
