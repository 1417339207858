import React, { FC, ChangeEvent } from 'react'
import { assetStructureMap } from '../../../../../objects/strategySearch'
import {
  childrenAllChecked,
  childrenSomeChecked
} from '../../../../helpers/strategySearch'

export interface AssetClassProps {
  assetClassFilters: string[]
  toggleL1Checkbox(e: ChangeEvent<HTMLInputElement>): void
  toggleL2Checkbox(e: ChangeEvent<HTMLInputElement>): void
}

const AssetClass: FC<AssetClassProps> = ({
  toggleL1Checkbox,
  toggleL2Checkbox,
  assetClassFilters
}) => {
  const levelOneItem = (isChecked: boolean, label: string) => {
    return (
      <label
        // childrenAllChecked: checkmark on checkbox
        // childrenSomeChecked: dash on checkbox
        // default: no checkmark or dash
        className={
          childrenAllChecked(assetClassFilters, label)
            ? 'strategy-search__checkbox-option strategy-search__checkbox-option--level-one strategy-search__checkbox-option--checked'
            : childrenSomeChecked(assetClassFilters, label)
            ? 'strategy-search__checkbox-option strategy-search__checkbox-option--level-one strategy-search__checkbox-option--dash'
            : 'strategy-search__checkbox-option strategy-search__checkbox-option--level-one'
        }
        key={label}>
        <input
          onChange={toggleL1Checkbox}
          type='checkbox'
          value={label}
          checked={isChecked}
          aria-checked={isChecked}
        />
        {label}
      </label>
    )
  }

  const levelTwoItem = (isChecked: boolean, label: string) => {
    return (
      <label
        className={
          isChecked
            ? 'strategy-search__checkbox-option strategy-search__checkbox-option--checked'
            : 'strategy-search__checkbox-option'
        }
        key={label}>
        <input
          onChange={toggleL2Checkbox}
          type='checkbox'
          name='assetClassL2'
          value={label}
          checked={isChecked}
          aria-checked={isChecked}
        />
        {label}
      </label>
    )
  }

  const displaylevelTwoItems = (items: string[]) => {
    return items?.map((item: string) => {
      return levelTwoItem(assetClassFilters?.includes(item), item)
    })
  }

  return (
    <div className='strategy-search__asset-class-w'>
      <div className='strategy-search__asset-class-group strategy-search__asset-class-group--spacing'>
        {levelOneItem(false, 'Public Equity')}
        {displaylevelTwoItems(assetStructureMap['Public Equity'])}
      </div>
      <div className='strategy-search__asset-class-group'>
        {levelOneItem(false, 'Fixed Income')}
        {displaylevelTwoItems(assetStructureMap['Fixed Income'])}
      </div>
      <div className='strategy-search__asset-class-group'>
        {levelOneItem(false, 'Cash, Deposits & Money Market Funds')}
        {displaylevelTwoItems(
          assetStructureMap['Cash, Deposits & Money Market Funds']
        )}
      </div>
      <div className='strategy-search__asset-class-group'>
        {levelOneItem(false, 'Alternative Investments')}
        {displaylevelTwoItems(assetStructureMap['Alternative Investments'])}
      </div>
      <div className='strategy-search__asset-class-group'>
        {levelOneItem(false, 'Other Investments')}
        {displaylevelTwoItems(assetStructureMap['Other Investments'])}
      </div>
    </div>
  )
}

export default AssetClass
