import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { UserPreferencesObj } from '../objects/user'

export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES'
export const UPSERT_USER_PREFERENCES = 'UPSERT_USER_PREFERENCES'

export const getUserPreferences = (): {
  type: string
  payload: Promise<AxiosResponse<any>>
} => {
  return {
    type: GET_USER_PREFERENCES,
    payload: api()
      .get(`/users/preferences`)
      .then((response) => {
        return response
      })
  }
}

export const setUserPreferences = (
  userPreferences: UserPreferencesObj
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: UPSERT_USER_PREFERENCES,
    payload: api()
      .post(`/users/preferences`, userPreferences)
      .then((response) => {
        return response
      })
  }
}
