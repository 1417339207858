import React from 'react'
import Select from 'react-select'
import Modal from '../../components/layout/modal'
import { ReactComponent as FinancialGoalsIcon } from '../../assets/images/icons/score.svg'
import Button from '../../components/button'
import { SelectOptionInterface } from '../../../objects/select'
import { planningSoftwareOptions } from '../../../objects/financialGoal'

interface SelectPlanningSoftwareProps {
  defaultPlanningSoftware: string
  closeModal(): void
  saveModal(planningSoftware: string): void
}

interface SelectPlanningSoftwareState {
  planningSoftware: string
}

class SelectPlanningSoftware extends React.Component<
  SelectPlanningSoftwareProps,
  SelectPlanningSoftwareState
> {
  constructor(props: SelectPlanningSoftwareProps) {
    super(props)
    this.state = {
      planningSoftware: this.props.defaultPlanningSoftware
    }
  }

  public getOption = (
    options: SelectOptionInterface[],
    value: string
  ): SelectOptionInterface => {
    let selectedOption
    options.forEach((option) => {
      if (option.value === value) {
        selectedOption = option
      }
    })
    return selectedOption
  }

  public handleSelectChange = (option: any) => {
    this.setState({ planningSoftware: option.value })
  }

  public saveModal = () => {
    this.props.saveModal(this.state.planningSoftware)
  }

  public render() {
    const { closeModal, defaultPlanningSoftware } = this.props
    return (
      <Modal
        icon={FinancialGoalsIcon}
        title='Select Planning Software'
        closeModal={closeModal}>
        <div className='select-planning-software-modal__body'>
          <p className='select-planning-software-modal__text'>
            Pick your planning software to see your client’s financial plan.
          </p>
          <fieldset className='select-planning-software-modal__fieldset'>
            <p className='select-planning-software-modal__text'>
              Select your planning software
            </p>
            <Select
              className='select-planning-software-modal__select'
              defaultValue={this.getOption(
                planningSoftwareOptions,
                defaultPlanningSoftware
              )}
              options={planningSoftwareOptions}
              onChange={this.handleSelectChange}
            />
          </fieldset>
        </div>
        <div className='select-planning-software-modal__btns-w'>
          <Button clear={true} onClick={closeModal}>
            Cancel
          </Button>
          <Button disabled={false} primary={true} onClick={this.saveModal}>
            Save
          </Button>
        </div>
      </Modal>
    )
  }
}

export default SelectPlanningSoftware
