import React, { FunctionComponent, SVGProps } from 'react'
import onClickOutside from 'react-onclickoutside'
import MoreIcon from '../assets/images/icons/general/more.png'

interface ActionObj {
  element: string | React.ReactNode
  onClick?(): any
}

export interface ActionDropdownProps {
  actions: ActionObj[]
  left?: boolean
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  listClass?: string
  // TO-DO TEAM AND SETTEAM: REMOVE
  team?: any
  setTeam?(team: any): void
}

interface ActionDropdownState {
  open: boolean
}

class ActionDropdown extends React.Component<
  ActionDropdownProps,
  ActionDropdownState
  > {
  constructor(props: any) {
    super(props)
    this.state = {
      open: false
    }
  }

  public handleClickOutside = (_e: any) => {
    this.closeDropdown()
  }

  public dropDownActions = () => {
    const { actions, listClass = '' } = this.props
    return actions.map((action, index) => {
      return (
        <li
          key={index}
          onClick={action.onClick}
          className={`action-dropdown__item ${listClass}`}>
          {action.element}
        </li>
      )
    })
  }

  public dropDown = () => {
    if (this.state.open) {
      return <ul className='action-dropdown__menu'>{this.dropDownActions()}</ul>
    } else return null
  }

  public toggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    // TOD NEEDS TO BE REMOVED if(this.props.setTeam)
    const { setTeam, team } = this.props
    if (setTeam) {
      setTeam(team)
    }
    this.setState({ open: !this.state.open })
  }

  public closeDropdown = () => {
    this.setState({ open: false })
  }

  public render() {
    const { Icon } = this.props
    return (
      <div
        onClick={this.toggleDropdown}
        className={
          this.props.left === true
            ? 'action-dropdown__w action-dropdown__w--left'
            : 'action-dropdown__w action-dropdown__w--right'
        }>
        {Icon ? <Icon /> : <img src={MoreIcon} alt='three dot menu' />}
        {this.dropDown()}
      </div>
    )
  }
}

export default onClickOutside(ActionDropdown)
