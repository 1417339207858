import React, { RefObject } from 'react'

import Modal from '../../../components/layout/modal'
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'
import { PriorityQuestionDescription } from './priorityAnswerComponents'
import { PrioritySlider, sliderChange } from '../prioritySlider'
import { getPriorityIcon } from '../../../components/priorityIcons'
import {
  InvestmentViewfinderAnswerObj,
  InvestmentViewfinderPriorityObj,
  InvestmentViewfinderQuestionObj,
  InvestmentViewfinderPriorityInterface,
  InvestmentViewfinderInterface
} from '../../../../objects/investmentViewfinder'
import { getPriorityChanges, sliderMapValue } from '../../../helpers/viewfinder'

interface PriorityAnswerUpdateModalProps {
  householdFinId: string
  exerciseId: string
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  priorities: InvestmentViewfinderPriorityInterface
  question: InvestmentViewfinderQuestionObj
  answer: InvestmentViewfinderAnswerObj
  updateAnswerFunc?(
    thisState: PriorityAnswerUpdateModalState,
    thisProps: {
      answer: InvestmentViewfinderAnswerObj
      question: InvestmentViewfinderQuestionObj
    }
  ): void
  closeModal?(): void
}

interface PriorityAnswerUpdateModalState {
  sliderValue: number
  priorityChange: {
    performance: number
    protection: number
    taxes: number
    cost: number
  }
}

class PriorityAnswerUpdateModal extends React.Component<
  PriorityAnswerUpdateModalProps,
  PriorityAnswerUpdateModalState
> {
  public inputSliderRef: RefObject<HTMLInputElement> = React.createRef()

  constructor(props: PriorityAnswerUpdateModalProps) {
    super(props)
    const { answer } = this.props
    this.state = {
      priorityChange: getPriorityChanges(answer),
      sliderValue: sliderMapValue(answer.response)
    }
  }

  public closeModal = () => {
    this.props.closeModal()
  }

  public updateAnswer = () => {
    const { answer, question } = this.props
    this.props.updateAnswerFunc(
      {
        sliderValue: this.state.sliderValue,
        priorityChange: this.state.priorityChange
      },
      { answer, question }
    )
  }

  public getPrioritiesDetails = (): {
    priority1: InvestmentViewfinderPriorityObj
    priority2: InvestmentViewfinderPriorityObj
  } => {
    const { priorities, question } = this.props
    return question
      ? {
          priority1: priorities[question.priority1],
          priority2: priorities[question.priority2]
        }
      : null
  }

  public onChange = () => {
    const priorities = this.getPrioritiesDetails()
    const priority1Label = priorities.priority1.label.toLowerCase()
    const priority2Label = priorities.priority2.label.toLowerCase()

    const numberValue = Number(this.inputSliderRef.current.value)
    const priorityChange = this.state.priorityChange

    const sliderValueChange = sliderChange(
      Number(numberValue),
      priorityChange,
      priority1Label,
      priority2Label
    )

    if (sliderValueChange) {
      this.setState({ ...sliderValueChange })
    }
  }

  public renderSlider = () => {
    const priorities = this.getPrioritiesDetails()
    const { sliderValue } = this.state

    if (priorities) {
      const PriorityIcon1 = getPriorityIcon(
        priorities.priority1.label.toLowerCase()
      )

      const PriorityIcon2 = getPriorityIcon(
        priorities.priority2.label.toLowerCase()
      )

      return (
        <div className='ivf-priorities__question-w'>
          <div className='ivf-update-answer-modal__priority-slider-endcap'>
            <PriorityIcon1 />
          </div>
          <PrioritySlider
            modalSize={true}
            inputRef={this.inputSliderRef}
            sliderValue={sliderValue}
            leftPriority={(priorities && priorities.priority1) || null}
            rightPriority={(priorities && priorities.priority2) || null}
            onChangeFunc={this.onChange}
          />
          <div className='ivf-update-answer-modal__priority-slider-endcap'>
            <PriorityIcon2 />
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  public render() {
    const priorities = this.getPrioritiesDetails()

    return (
      <Modal
        size='L'
        icon={EditIcon}
        title={'Update Answer'}
        closeModal={this.closeModal}>
        <div className='ivf-update-answer-modal'>
          <div className='ivf-update-answer-modal__instructions'>
            Choose which one of the shown attributes has more priority to you.
            If you think the one on the left should have more priority, move the
            handle to the left, otherwise, move the handle to the right. The
            handle can’t be left in the middle.
          </div>

          <div className='ivf-update-answer-modal__priority-types'>
            {priorities ? (
              <PriorityQuestionDescription {...priorities.priority1} />
            ) : null}
            {priorities ? (
              <PriorityQuestionDescription {...priorities.priority2} />
            ) : null}
          </div>

          <div className='ivf-update-answer-modal__priority-slider'>
            {this.renderSlider()}
          </div>

          <div className='ivf-update-answer-modal__buttons'>
            <span className='btn btn__clear' onClick={this.closeModal}>
              Cancel
            </span>
            <span className={'btn btn__prime'} onClick={this.updateAnswer}>
              Update
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PriorityAnswerUpdateModal
