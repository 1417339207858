import React from 'react'

import { ContactObj } from '../../../../objects/contact'
import { OfficeTeamMemberObj } from '../../../../objects/officeTeams'
import ProfilePicture from '../../layout/profilePicture'

export const AssigneePhotos: React.FC<{
  members: OfficeTeamMemberObj[]
  contacts: ContactObj[]
}> = (props) => {
  const pictureSize = 20
  let position: number = pictureSize / 2
  let count = 0
  if (props.contacts?.length === 1) {
    position = pictureSize / 4
    count = 1
  }

  const pictureStyle = { top: 14, zIndex: 5 }

  const roundPictures = (
    idx: number,
    firstName: string | null,
    lastName: string | null,
    photoUrl: string | null,
    isPrimary: boolean
  ) => {
    return (
      <div
        key={`assignee_${idx}`}
        className='assign-team__modal__body__teams'
        style={{ ...pictureStyle, left: `${count * position}px`, zIndex: idx }}>
        <ProfilePicture
          firstName={firstName}
          lastName={lastName}
          photo={photoUrl}
          isPrimary={isPrimary}
          pictureSize={pictureSize}
          styleOverride={{ fontSize: '12px' }}
        />
      </div>
    )
  }

  const teamsPhotos = (members: OfficeTeamMemberObj[]) => {
    return members.map((member: OfficeTeamMemberObj, idx: number) => {
      const user = member.userId && member.user ? member.user : null
      if (!member.showInGuidecenter) return null

      const teamMemberPhoto = roundPictures(
        idx,
        user.firstName,
        user.lastName,
        user.photoUrl,
        true
      )

      count = count + 1
      return teamMemberPhoto
    })
  }

  const contactsPhotos = (contacts: ContactObj[]) => {
    return contacts.map((contact: ContactObj, idx: number) => {
      const contactPictures = roundPictures(
        idx,
        contact.firstName,
        contact.lastName,
        contact.photo,
        true
      )
      count = count + 1
      return contactPictures
    })
  }
  return (
    <div
      className='assign-team__modal__body__teams-w'
      style={{
        width: `30px`
      }}>
      {props.members && teamsPhotos(props.members)}
      {props.contacts && contactsPhotos(props.contacts)}
    </div>
  )
}
