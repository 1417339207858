import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../reducers'
import * as clientAccountActions from '../../../actions/clientAccounts'
import * as alertMessageActions from '../../../actions/showAlert'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import {
  ClientAccountObj,
  ClientAccountObjState
} from '../../../objects/clientAccount'
import { maskAccountNumber } from '../../helpers/clientAccounts'

export interface UnmergeClientAccountsModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  errorAccounts?: ClientAccountObjState
  cashAccounts?: ClientAccountObjState
  investmentAccounts?: ClientAccountObjState
  otherAccounts?: ClientAccountObjState
  liabilityAccounts?: ClientAccountObjState
  pendingAccounts?: ClientAccountObjState
  selectedAccount: ClientAccountObj
  closeModal(): void
}

export class UnmergeClientAccountsModal extends React.Component<
  UnmergeClientAccountsModalProps
> {
  public closeModal = () => {
    const { closeModal } = this.props
    closeModal()
  }

  public onUnmergeAccounts = async () => {
    const { householdFinId, dispatch, selectedAccount } = this.props
    try {
      await dispatch(
        clientAccountActions.unmergeClientAccounts(
          householdFinId,
          selectedAccount.id
        )
      )
      this.closeModal()
      dispatch(clientAccountActions.getClientAccounts(householdFinId))
      dispatch(
        alertMessageActions.showSyncModal('Accounts have been ungrouped.', true)
      )
    } catch (error) {
      this.closeModal()
      dispatch(
        alertMessageActions.showSyncModal(
          'Accounts unable to be ungrouped at this time. Contact FinLife Service Desk.',
          false
        )
      )
    }
  }

  public render() {
    const { selectedAccount } = this.props
    return (
      <Modal
        title='Ungroup Accounts'
        closeModal={this.closeModal}
        centerModal={true}
        headerStyle={{ fontSize: '16px' }}>
        <div>
          <div className='modal-body merge-accounts__selected_account'>
            <p>
              Are you sure you want to ungroup '
              {selectedAccount.accountNickname} (
              {maskAccountNumber(selectedAccount.accountNumber)})'?
            </p>
          </div>
          <div className='form-inline '>
            <div className='form-group client-account-modal__buttons'>
              <Button onClick={this.closeModal}>Cancel</Button>
              <Button primary={true} onClick={this.onUnmergeAccounts}>
                Ungroup
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(UnmergeClientAccountsModal))
