import { InvestmentsInterface } from '../objects/investment'
import { FETCH_HOUSEHOLDS } from '../actions/households'
import { FETCH_PSS_SUMMARY_FULFILLED } from '../actions/investments'
import {
  Account,
  PssSummary
} from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

export interface InvestmentsState {
  [key: string]: InvestmentsInterface
}

interface RecommendedAllocationInterface {
  recommendedEquity: number
  recommendedFixed: number
  recommendedCash: number
  recommendedAlt: number
}

interface CurrentAllocationInterface {
  currentEquity: number
  currentFixed: number
  currentAlt: number
  currentCash: number
  currentEquityValue: number
  currentFixedValue: number
  currentAltValue: number
  currentCashValue: number
  maxDefensiveCash: number
  maxDefensiveEquity: number
  maxDefensiveFixed: number
  maxDefensiveOther: number
  maxOffensiveCash: number
  maxOffensiveEquity: number
  maxOffensiveFixed: number
  maxOffensiveOther: number
}

const initState: InvestmentsState = {}

// TO-DO change type of data once we have updated types for HH response
const mapTargetAllocation = (data: any): RecommendedAllocationInterface => {
  return {
    recommendedEquity: data && data.targetEquityAllocation,
    recommendedFixed: data && data.targetFixedAllocation,
    recommendedCash: data && data.targetCashAllocation,
    recommendedAlt: data && data.targetOtherAllocation
  }
}

const mapCurrentAllocation = (
  data: PssSummary.Response
): CurrentAllocationInterface => {
  return {
    currentEquity:
      data &&
      data.current &&
      data.current.allocation &&
      data.current.allocation.equity,
    currentFixed:
      data &&
      data.current &&
      data.current.allocation &&
      data.current.allocation.fixed,
    currentAlt:
      data &&
      data.current &&
      data.current.allocation &&
      data.current.allocation.other,
    currentCash:
      data &&
      data.current &&
      data.current.allocation &&
      data.current.allocation.cash,
    currentEquityValue:
      data && data.current && data.current.value && data.current.value.equity,
    currentFixedValue:
      data && data.current && data.current.value && data.current.value.fixed,
    currentAltValue:
      data && data.current && data.current.value && data.current.value.other,
    currentCashValue:
      data && data.current && data.current.value && data.current.value.cash,
    maxDefensiveCash:
      data &&
      data.position &&
      data.position.maxDefensivePosition &&
      data.position.maxDefensivePosition.cash,
    maxDefensiveEquity:
      data &&
      data.position &&
      data.position.maxDefensivePosition &&
      data.position.maxDefensivePosition.equity,
    maxDefensiveFixed:
      data &&
      data.position &&
      data.position.maxDefensivePosition &&
      data.position.maxDefensivePosition.fixed,
    maxDefensiveOther:
      data &&
      data.position &&
      data.position.maxDefensivePosition &&
      data.position.maxDefensivePosition.other,
    maxOffensiveCash:
      data &&
      data.position &&
      data.position.maxOffensivePosition &&
      data.position.maxOffensivePosition.cash,
    maxOffensiveEquity:
      data &&
      data.position &&
      data.position.maxOffensivePosition &&
      data.position.maxOffensivePosition.equity,
    maxOffensiveFixed:
      data &&
      data.position &&
      data.position.maxOffensivePosition &&
      data.position.maxOffensivePosition.fixed,
    maxOffensiveOther:
      data &&
      data.position &&
      data.position.maxOffensivePosition &&
      data.position.maxOffensivePosition.other
  }
}

const Investments = (state = initState, action: any): InvestmentsState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case FETCH_PSS_SUMMARY_FULFILLED:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {
          priorities: [],
          allocation: {},
          partners: null
        }
      }
      newState[action.payload.data.householdFinId].allocation = {
        ...newState[action.payload.data.householdFinId].allocation,
        ...mapCurrentAllocation(action.payload.data)
      }
      return newState
    case `${FETCH_HOUSEHOLDS}_FULFILLED`:
      action.payload.data.forEach((household: Account.Response) => {
        if (!newState[household.id]) {
          newState[household.id] = {
            priorities: [],
            allocation: {},
            partners: null
          }
        }
        newState[household.id].allocation = {
          ...newState[household.id].allocation,
          ...mapTargetAllocation(household)
        }
      })
      return newState
    default:
      return state
  }
}

export default Investments
