import React, { FunctionComponent, SVGProps } from 'react'

interface MoneyMindProps {
  title: string
  color: string
  percent: number
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export class MoneyMindGraphic extends React.Component<MoneyMindProps> {
  constructor(props: MoneyMindProps) {
    super(props)
  }

  public render() {
    const { percent, color, title } = this.props
    const Icon = this.props.icon
    const backgroundColor = color
    const graph = []
    const rowNum = (percent - (percent % 10)) / 10
    for (let row = 0; row < 10; row = row + 1) {
      const graphRow = []
      for (let column = 0; column < 10; column = column + 1) {
        const position = row * 10 + column
        let opacity = position >= 100 - percent ? null : 0.2
        if (row === 9 - rowNum) {
          opacity = percent % 10 > column ? null : 0.2
        }
        const graphCell = (
          <div
            key={'position-' + row * 10 + column}
            className='moneymind__graphic-cell'>
            <div
              className='moneymind__graphic-icon'
              style={{ opacity, backgroundColor }}
              key={`cell ${position}`}
            />
          </div>
        )
        graphRow.push(graphCell)
      }
      graph.push(
        <div key={`row ${row}`} className='moneymind__graphic-row'>
          {graphRow}
        </div>
      )
    }

    return (
      <div className='moneymind__graphic'>
        <div className='moneymind__graphic-perctage' style={{ color }}>
          {percent}%
        </div>
        <div>{graph}</div>
        <div className='moneymind__graphic-label' style={{ color }}>
          {Icon ? <Icon /> : null}
          {title}
        </div>
      </div>
    )
  }
}
