import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../reducers'
import * as clientAccountActions from '../../../actions/clientAccounts'
import * as alertMessageActions from '../../../actions/showAlert'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'
import {
  ClientAccountObj,
  ClientAccountObjState
} from '../../../objects/clientAccount'
import TableRow from '../../components/layout/tableRow'
import { MergeAccountsTableRow } from './meregAccountsTableRow'
import { maskAccountNumber } from '../../helpers/clientAccounts'

interface MergeClientAccountsModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  errorAccounts: ClientAccountObjState
  cashAccounts: ClientAccountObjState
  investmentAccounts: ClientAccountObjState
  otherAccounts: ClientAccountObjState
  liabilityAccounts: ClientAccountObjState
  pendingAccounts: ClientAccountObjState
  selectedAccount: ClientAccountObj
  closeModal(): void
}

interface MergeClientAccountsModalState {
  selectedAccounts: string[]
  primeAccount: string
  secondStep: boolean
}

class MergeClientAccountsModal extends React.Component<
  MergeClientAccountsModalProps,
  MergeClientAccountsModalState
> {
  constructor(props: MergeClientAccountsModalProps) {
    super(props)
    this.state = {
      selectedAccounts: [],
      primeAccount: null,
      secondStep: false
    }
  }

  public closeModal = () => {
    this.props.closeModal()
  }

  public renderStepTwo = () => {
    const { selectedAccount } = this.props
    const updateAccounts = this.state.selectedAccounts
    updateAccounts.push(selectedAccount.id)
    this.setState({
      secondStep: true,
      selectedAccounts: updateAccounts
    })
  }

  public onSelectAccountsToMerge = (
    account: ClientAccountObj,
    include: boolean
  ) => {
    let updateAccounts = this.state.selectedAccounts
    if (include) {
      updateAccounts.push(account.id)
    } else {
      updateAccounts = updateAccounts.filter((id: string) => {
        return id !== account.id
      })
    }
    this.setState({
      selectedAccounts: updateAccounts
    })
  }

  public onSelectPrimeAccount = (account: ClientAccountObj) => {
    this.setState({
      primeAccount: account.id
    })
  }

  public onMergAccounts = async () => {
    const { householdFinId, dispatch } = this.props
    const { primeAccount, selectedAccounts } = this.state
    const accountsToBeMerged = selectedAccounts.filter((account: string) => {
      return account !== primeAccount
    })
    const requestBody = {
      accountsToBeMerged,
      visibleAccount: primeAccount
    }
    try {
      await dispatch(
        clientAccountActions.mergeClientAccounts(householdFinId, requestBody)
      )
      this.closeModal()
      dispatch(clientAccountActions.getClientAccounts(householdFinId))
      dispatch(
        alertMessageActions.showSyncModal('Accounts have been grouped.', true)
      )
    } catch (error) {
      this.closeModal()
      dispatch(
        alertMessageActions.showSyncModal(
          'Accounts unable to be grouped at this time. Contact FinLife Service Desk.',
          false
        )
      )
    }
  }

  public clientAccountRows = (
    accounts: ClientAccountObj[],
    categoryName: string
  ) => {
    const { selectedAccounts, secondStep, primeAccount } = this.state
    const { selectedAccount } = this.props
    const allAccountsIds = accounts && Object.keys(accounts)
    if (secondStep) {
      const secondStepAccountsIds = allAccountsIds.filter((key: string) =>
        selectedAccounts.includes(key)
      )
      return (
        secondStepAccountsIds.length > 0 && (
          <div>
            <TableRow backgroundColor='#F2F5F9'>
              <div className='merge-accounts__header-row'>{categoryName}</div>
            </TableRow>
            {secondStepAccountsIds.map((key: string) => {
              return (
                <MergeAccountsTableRow
                  clientAccount={accounts[key]}
                  key={accounts[key].id}
                  onSelectAccount={this.onSelectPrimeAccount}
                  finalStep={secondStep}
                  primeAccountId={primeAccount}
                />
              )
            })}
          </div>
        )
      )
    } else {
      const firstStepAccountsIds = allAccountsIds.filter(
        (id: string) => id !== selectedAccount.id
      )
      return (
        firstStepAccountsIds.length > 0 && (
          <div>
            <TableRow backgroundColor='#F2F5F9'>
              <div className='merge-accounts__header-row'>{categoryName}</div>
            </TableRow>
            {firstStepAccountsIds.map((key: string) => {
              return (
                <MergeAccountsTableRow
                  clientAccount={accounts[key]}
                  key={accounts[key].id}
                  onSelectAccount={this.onSelectAccountsToMerge}
                />
              )
            })}
          </div>
        )
      )
    }
  }

  public renderAccounts = (
    categoryName: string,
    accounts: ClientAccountObj[]
  ) => {
    const accountsKeys = accounts && Object.keys(accounts)
    if (accountsKeys?.length > 0) {
      return <div>{this.clientAccountRows(accounts, categoryName)}</div>
    } else {
      return null
    }
  }

  public modalBody = () => {
    const { selectedAccounts, primeAccount, secondStep } = this.state
    const {
      pendingAccounts,
      cashAccounts,
      otherAccounts,
      liabilityAccounts,
      investmentAccounts,
      selectedAccount
    } = this.props
    const accountsCategories = [
      'Cash & Equivalents',
      'Investments',
      'Pending',
      'Liabilities',
      'Other Assets'
    ]
    const accounts = {
      'Cash & Equivalents': cashAccounts,
      Investments: investmentAccounts,
      Pending: pendingAccounts,
      Liabilities: liabilityAccounts,
      'Other Assets': otherAccounts
    }
    const modalHeadertext = secondStep ? (
      <div>Select account to be primary.</div>
    ) : (
      <div>
        {`Select accounts below to group with  `}
        <span className='merge-accounts__selected_account--blue'>
          {selectedAccount.accountNickname} (
          {maskAccountNumber(selectedAccount.accountNumber)})
        </span>
      </div>
    )
    return (
      <div>
        <div className='modal-body merge-accounts__selected_account'>
          {modalHeadertext}
        </div>
        <div className='merge-accounts__modal-body'>
          {accountsCategories.map((category: string) => {
            return this.clientAccountRows(accounts[`${category}`], category)
          })}
        </div>
        <div className='form-inline '>
          <div className='form-group client-account-modal__buttons'>
            <Button onClick={this.closeModal}>Cancel</Button>
            {!this.state.secondStep ? (
              <Button
                primary={true}
                disabled={selectedAccounts.length <= 0}
                onClick={this.renderStepTwo}>
                Group
              </Button>
            ) : (
              <Button
                primary={true}
                disabled={primeAccount === null}
                onClick={this.onMergAccounts}>
                Group
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }

  public render() {
    return (
      <Modal
        title='Group Accounts'
        closeModal={this.closeModal}
        centerModal={true}
        headerStyle={{ fontSize: '16px' }}>
        {this.modalBody()}
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(MergeClientAccountsModal))
