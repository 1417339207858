import React, { Component, ChangeEvent, SyntheticEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { custodiansSelectMap } from '../../../../../objects/strategySearch'
import { updateIMStrategyFilter } from '../../../../../actions/strategySearch'
import { GlobalState } from '../../../../../reducers'
import FilterCheckbox from '../filterCheckbox'

export interface FilterCustodiansProps {
  dispatch: Dispatch<GlobalState>
  filter: string[]
  toggleFilterDropdown(): void
}

interface FilterCustodiansState {
  isFilterChanges: boolean
  custodiansFilter: string[]
}

export class FilterCustodians extends Component<
  FilterCustodiansProps,
  FilterCustodiansState
> {
  constructor(props: FilterCustodiansProps) {
    super(props)
    this.state = {
      isFilterChanges: false,
      custodiansFilter: this.props.filter
    }
  }

  public handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  public toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { custodiansFilter } = this.state
    const value = e.currentTarget.value
    if (!custodiansFilter?.some((filter: string) => filter === value)) {
      this.setState({
        isFilterChanges: true,
        custodiansFilter: [...custodiansFilter, value]
      })
    } else {
      const tempState = [...custodiansFilter]
      tempState.forEach((item: string, i: number) => {
        if (value === item) {
          tempState.splice(i, 1)
        }
      })
      this.setState({
        isFilterChanges: true,
        custodiansFilter: tempState
      })
    }
  }

  public handleCancel = () => {
    this.setState({ custodiansFilter: [], isFilterChanges: false })
    this.props.toggleFilterDropdown()
  }

  public handleApply = () => {
    if (this.state.isFilterChanges) {
      this.setState({ isFilterChanges: false })
      this.props.dispatch(
        updateIMStrategyFilter(
          'custodianSelection',
          this.state.custodiansFilter
        )
      )
    }
    this.props.toggleFilterDropdown()
  }

  public render() {
    const { isFilterChanges } = this.state
    const custodianOptions = Object.keys(custodiansSelectMap).map(
      (key: string) => key
    )
    return (
      <div
        onClick={this.handleStopPropagation}
        className='strategy-search__dropdown-filter'>
        <div className='strategy-search__dropdown-filter-options--sm'>
          {custodianOptions.map((option: string) => (
            <FilterCheckbox
              key={option}
              toggleCheckbox={this.toggleCheckbox}
              filters={this.state.custodiansFilter}
              filterName={option}
            />
          ))}
        </div>
        <div className='strategy-search__filter-buttons-cancel-apply-w strategy-search__filter-buttons-cancel-apply-w--popup'>
          <div
            onClick={this.handleCancel}
            className='strategy-search__filter-buttons-cancel'>
            Cancel
          </div>
          <div
            onClick={this.handleApply}
            className={
              isFilterChanges
                ? 'strategy-search__filter-buttons-apply'
                : 'strategy-search__filter-buttons-disabled'
            }>
            Apply
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    filter: store.strategySearch.filter.custodianSelection
  }
}

export default connect(mapStateToProps)(FilterCustodians)
