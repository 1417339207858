import * as actions from '../actions/institution'
import { InstitutionObj } from '../objects/institution'

const initState: InstitutionObj = {
  id: '',
  imageUrl: '',
  emailDisclaimer: '',
  crmSource: '',
  crmLoginUrl: '',
  mgpId: '',
  isFinlifeInstitution: false,
  systemInstitution: false,
  benchmarks: [],
  sendPositionData: false,
  disableTasks: false,
  dataTypesSupported: [],
  disableDocumentVault: false,
  disableVideoMessenger: false,
  guidebookDisclaimer: '',
  disableYodlee: false,
  enableFactsetWorkstation: false,
  showGIR: false,
  version: '3'
}

const mapInstitution = (
  institution: any,
  institutionState: any
): InstitutionObj => {
  if (institution) {
    return {
      id: institution.id,
      imageUrl: institution.photoUrl,
      emailDisclaimer: institution.emailDisclaimer,
      crmSource: institution.crmSource,
      crmLoginUrl: institution.crmLoginUrl,
      mgpId: institution.mgpId,
      isFinlifeInstitution: institution.isFinlifeInstitution,
      systemInstitution: institution.systemInstitution,
      benchmarks: institutionState.benchmarks,
      sendPositionData: institution.sendPositionData,
      disableTasks:
        institution.dataTypesSupported &&
        institution.dataTypesSupported.indexOf('tasks') === -1,
      dataTypesSupported: institution.dataTypesSupported,
      disableVideoMessenger: institution.disableVideoMessenger,
      disableDocumentVault: institution.disableDocumentVault,
      guidebookDisclaimer: institution.guidebookDisclaimer,
      disableYodlee: institution.disableYodlee,
      showGIR: institution.showGIR,
      enableFactsetWorkstation: institution.enableFactsetWorkstation,
      version: institution.version,
      name: institution.name,
      enableCRMTasks: institution.enableCRMTasks
    }
  } else return institutionState
}

const Institution = (state = initState, action: any): InstitutionObj => {
  switch (action.type) {
    case actions.FETCH_INSTITUTION_FULFILLED:
      const institution = mapInstitution(action.payload.data, state)
      return { ...state, ...institution }
    case actions.FETCH_BENCHMARKS_FULFILLED:
      const benchmarks = action.payload.data
      return { ...state, benchmarks }
    default:
      return state
  }
}

export default Institution
