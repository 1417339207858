import * as actions from '../actions/timedLoader'
import { TimedLoaderInterface, TimedLoaderState } from '../objects/timedLoader'
import { v4 } from 'uuid'

const initState: TimedLoaderState = {
  loaders: []
}

const TimedLoader = (state = initState, action: any): TimedLoaderState => {
  const { payload } = action
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.ADD_TIMED_LOADER: {
      const { id, loadingMessage, loadingType, iconType } = payload?.data
      const uuid = id || v4()
      const isLoaderExist =
        newState?.loaders?.some(
          (loader: TimedLoaderInterface) =>
            loader.id === uuid &&
            loader.iconType === iconType &&
            loader.loadingMessage === loadingMessage &&
            loader.loadingType === loadingType
        ) || false
      if (!isLoaderExist) {
        newState.loaders.push({
          showTimedLoader: true,
          id: uuid,
          ...payload.data
        })
      }
      return newState
    }

    case actions.REMOVE_TIMED_LOADER:
      newState.loaders = newState.loaders.filter(
        (loader: TimedLoaderInterface) => loader.id !== payload.id
      )
      return newState
    default:
      return newState
  }
}

export default TimedLoader
