import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { ContactsInterface } from '../../../objects/contact'
import {
  FinancialGoalActionObj,
  GoalTemplatesResponse
} from '../../../objects/financialGoalActions'
import { SideBar } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { GoalView1, GoalView2, GoalView3, GoalView4 } from './steps'
import {
  deleteGoalActions,
  addGoalActions,
  editGoalActions,
  setSuggestedGoal,
  setGoalIdActions,
  updateSideBarState
} from '../../../actions/financialGoalActions'
import DeleteGoalModal from './components/deleteGoalModal'
import RestoreGoalModal from './components/restoreGoalModal'
import _ from 'lodash'
import { updateGlobalNavigation } from '../../../actions/globalSettings'
import {
  HonestConversationsExerciseObj,
  HouseholdCardObjInterface
} from '../../../objects/honestConversations'
import { isObjEmpty } from '../../helpers'
import { HouseholdObj } from '../../../objects/household'
import { CardObj } from '../../../objects/HonestConversations/card'
import goalActionsSelector, {
  archivedGoalActionsSelector
} from '../../../selectors/v3/goals'

export interface SideBarViewProps {
  contacts: ContactsInterface
  goalId: string
  goals: any
  householdFinId: string
  suggestedGoals: GoalTemplatesResponse[]
  suggestedGoalId: string
  archivedGoals: FinancialGoalActionObj[]
  goalsFromSelector: FinancialGoalActionObj[]
  honestConversation: HonestConversationsExerciseObj
  showSideBar: boolean
  dispatch: Dispatch<GlobalState>
  activeGoalAction: FinancialGoalActionObj
  onCancel(): void
  globalNavigationState: boolean
  sideBarState: boolean
  household: HouseholdObj
}
interface SideBarViewState {
  viewKey: number
  globalNavState: boolean
  deleteModalToggle: boolean
  deleteModalText: string
  restoreModalToggle: boolean
  activeArchivedGoal: FinancialGoalActionObj
}

export class SideBarView extends Component<SideBarViewProps, SideBarViewState> {
  viewRef: any
  constructor(props: SideBarViewProps) {
    super(props)
    this.state = {
      viewKey: 0,
      globalNavState: false,
      deleteModalToggle: false,
      deleteModalText: null,
      restoreModalToggle: false,
      activeArchivedGoal: null
    }
    this.viewRef = React.createRef()
  }

  public componentDidMount() {
    const { globalNavigationState, showSideBar, goalId } = this.props
    this.setState({
      globalNavState: globalNavigationState
    })
    if (showSideBar) {
      if (goalId === 'NEW') {
        this.setState({
          viewKey: 1
        })
      } else {
        this.setState({
          viewKey: 2
        })
      }
    }
  }

  public componentDidUpdate(prevProps: SideBarViewProps) {
    const {
      globalNavigationState,
      sideBarState,
      goalId,
      showSideBar
    } = this.props
    const { globalNavState, viewKey } = this.state
    if (globalNavigationState !== globalNavState && !sideBarState) {
      this.setState({
        globalNavState: globalNavigationState
      })
    }
    if (goalId !== prevProps.goalId) {
      if (showSideBar) {
        if (goalId === 'NEW') {
          if (viewKey !== 1) {
            this.setState({
              viewKey: 1
            })
          }
        } else if (viewKey !== 2) {
          this.setState({
            viewKey: 2
          })
        }
      }
    }
  }

  public mapCardsToUpdate(householdCards: HouseholdCardObjInterface) {
    const { activeGoalAction } = this.props
    const goalCardsTitles = activeGoalAction.cards?.map((card) =>
      card.title.toLowerCase()
    )
    const cardsToUpdate: CardObj[] = []
    !isObjEmpty(householdCards) &&
      Object.keys(householdCards).forEach(async (key) => {
        const card = householdCards[key]
        const indexOfCard = goalCardsTitles?.indexOf(
          card.card.title.toLowerCase()
        )
        if (
          card?.card?.title &&
          goalCardsTitles &&
          indexOfCard !== -1 &&
          !activeGoalAction.customGoal
        ) {
          const cardToUpdate = activeGoalAction.cards[indexOfCard]
          cardsToUpdate.push(cardToUpdate)
        }
      })
    return cardsToUpdate
  }

  public onPrimaryBtnClick = async () => {
    await this.viewRef?.wrappedInstance?.syncWithStore()
    const {
      activeGoalAction,
      dispatch,
      householdFinId,
      honestConversation
    } = this.props
    const { activeArchivedGoal } = this.state
    const householdCards = honestConversation?.householdCards
    const cardsToUpdate = this.mapCardsToUpdate(householdCards)

    if (activeGoalAction.id === 'NEW') {
      // if this is archive goal, restore the goal by setting the archived property to false
      if (
        activeArchivedGoal &&
        activeArchivedGoal?.palItemId === activeGoalAction?.palItemId
      ) {
        const restoredGoal = {
          ...activeGoalAction,
          id: activeArchivedGoal.id,
          isNew: false,
          archived: false
        }
        await dispatch(
          editGoalActions(restoredGoal, householdFinId, cardsToUpdate)
        )
      } else {
        await dispatch(
          addGoalActions(activeGoalAction, householdFinId, cardsToUpdate)
        )
      }
    } else {
      await dispatch(
        editGoalActions(activeGoalAction, householdFinId, cardsToUpdate)
      )
    }
    this.onCancelSideBar()
  }

  public getView = () => {
    const { viewKey } = this.state
    const {
      household,
      goalsFromSelector,
      goals,
      goalId,
      contacts,
      activeGoalAction,
      dispatch,
      householdFinId,
      suggestedGoals,
      honestConversation,
      suggestedGoalId,
      archivedGoals
    } = this.props
    let ComponentView!: any
    let config = {}
    let componentProps = {}
    switch (viewKey) {
      case 1:
        config = {
          ...config,
          ...{
            footer: {
              hideFooter: true
            }
          }
        }
        componentProps = {
          ...componentProps,
          ...{
            goalsFromSelector,
            goals,
            dispatch,
            householdFinId,
            setViewKey: this.setViewKey,
            suggestedGoals,
            setSuggestedGoal: this.setSuggestedGoal,
            honestConversation,
            archivedGoals,
            toggleRestoreModal: this.toggleRestoreModal,
            setActiveArchivedGoal: this.setActiveArchivedGoal
          }
        }
        ComponentView = GoalView1
        break
      case 2:
        config = {
          ...config,
          ...{
            header: {
              hideActions: false,
              actions:
                goalId === 'NEW'
                  ? []
                  : [
                      {
                        iconName: 'TRASH',
                        action: () => this.toggleDeleteModal(),
                        toolTipMessage: 'Delete Goal'
                      }
                    ],
              title: goalId === 'NEW' ? 'Add Goal' : 'Edit Goal'
            },
            footer: {
              primaryBtnAction: this.onPrimaryBtnClick
            }
          }
        }
        componentProps = {
          ...componentProps,
          ...{
            household,
            activeGoal: activeGoalAction,
            setViewKey: this.setViewKey,
            contacts,
            dispatch,
            householdFinId,
            suggestedGoalId,
            honestConversation,
            suggestedGoal: suggestedGoals?.find(
              (goal) => goal?.id === activeGoalAction.palItemId
            )
          }
        }
        ComponentView = GoalView2
        break
      case 3:
        config = {
          ...config,
          ...{
            header: {
              showBackBtn: true,
              backBtnAction: () => this.setViewKey(2)
            },
            footer: {
              hideFooter: true
            },
            onCancel: () => this.setViewKey(2)
          }
        }
        componentProps = {
          ...componentProps,
          ...{
            activeGoal: activeGoalAction,
            honestConversation,
            setViewKey: this.setViewKey,
            dispatch,
            householdFinId
          }
        }
        ComponentView = GoalView3
        break
      case 4:
        config = {
          ...config,
          ...{
            header: {
              showBackBtn: true,
              backBtnAction: () => this.setViewKey(2)
            },
            footer: {
              hideFooter: true
            },
            onCancel: () => this.setViewKey(2)
          }
        }
        componentProps = {
          ...componentProps,
          ...{
            activeGoal: activeGoalAction,
            suggestedGoals,
            suggestedGoalId,
            honestConversation,
            dispatch,
            householdFinId,
            setViewKey: this.setViewKey
          }
        }
        ComponentView = GoalView4
        break
      default:
        ComponentView = () => {
          return ''
        }
    }
    return { config, ComponentView, componentProps }
  }

  public setViewKey = (viewKey: number) => {
    this.setState({ viewKey })
  }

  public toggleDeleteModal = () => {
    const { activeGoalAction } = this.props
    const { deleteModalToggle } = this.state
    const modalText = {
      custom:
        'Are you sure you want to delete this goal? This is a custom goal so there is no way to revert this action.',
      suggested:
        'Are you sure you want to delete this goal? You will be able to add it back from the Suggested Goal list if needed.'
    }
    this.setState({
      deleteModalToggle: !deleteModalToggle,
      deleteModalText: activeGoalAction.customGoal
        ? modalText.custom
        : modalText.suggested
    })
  }

  public toggleRestoreModal = () => {
    const { restoreModalToggle } = this.state
    this.setState({ restoreModalToggle: !restoreModalToggle })
  }

  public setActiveArchivedGoal = (
    activeArchivedGoal: FinancialGoalActionObj
  ) => {
    this.setState({ activeArchivedGoal })
  }

  public handleGoalDelete = async () => {
    const { activeGoalAction, dispatch, householdFinId } = this.props
    if (!activeGoalAction.customGoal) {
      const archivedGoalAction = { ...activeGoalAction, archived: true }
      await dispatch(editGoalActions(archivedGoalAction, householdFinId))
    } else {
      await dispatch(deleteGoalActions(activeGoalAction, householdFinId))
    }
    this.toggleDeleteModal()
    this.onCancelSideBar()
  }

  public restoreSuggestedGoal = async () => {
    const { dispatch, householdFinId } = this.props
    const { activeArchivedGoal } = this.state
    const restoredGoal = { ...activeArchivedGoal, archived: false }
    await dispatch(editGoalActions(restoredGoal, householdFinId))
    this.toggleRestoreModal()
    this.onCancelSideBar()
  }

  public clearSuggestedGoal = () => {
    const { activeArchivedGoal } = this.state
    this.toggleRestoreModal()
    this.setViewKey(2)
    this.setSuggestedGoal(activeArchivedGoal.palItemId)
  }

  public setSuggestedGoal = (id: string) => {
    const { householdFinId, dispatch } = this.props
    const reqBody = {
      data: {
        suggestedGoalId: id,
        householdId: householdFinId
      }
    }
    dispatch(setSuggestedGoal(reqBody))
  }

  public onCancelSideBar = async () => {
    const { dispatch, onCancel, householdFinId, goalId } = this.props
    await dispatch(updateGlobalNavigation(this.state.globalNavState))
    dispatch(
      updateSideBarState({ householdId: householdFinId, sideBarState: false })
    )
    setTimeout(() => {
      // Comparing the active goalId at the start of calling the timeout Fn vs at the end of timeout.
      // And checking the sidebarstate should be false i.e we did not select any goal tile including the one we are active with.
      const shouldResetSideBar =
        goalId === this.props.goalId && !this.props.sideBarState
      dispatch(
        setGoalIdActions({
          goalId: null,
          householdFinId,
          shouldResetSideBar
        })
      )
    }, 800)
    onCancel && onCancel()
  }

  public getSideBarConfig = () => {
    const { activeGoalAction } = this.props
    const { viewKey } = this.state
    const { ComponentView, config, componentProps } = this.getView()
    return _.merge(
      {
        onCancel: this.onCancelSideBar,
        header: {
          title: 'Add Goal',
          showBackBtn: false,
          hideActions: true,
          actions: null
        },
        component: ComponentView,
        componentProps: {
          ...(viewKey !== 0
            ? {
                ref: (ref: any) => {
                  this.viewRef = ref
                }
              }
            : {}),
          ...{
            goalCls: 'side-bar-body__'
          },
          ...componentProps
        },
        footer: {
          isBtnDisabled: !activeGoalAction?.name?.length,
          primaryBtnText: 'Save',
          primaryBtnAction: null,
          secondaryBtnText: 'Cancel'
        },
        classNames: `side-bar-body__w`
      },
      config
    )
  }

  public render() {
    const { activeGoalAction: { id } = {}, showSideBar } = this.props
    const {
      deleteModalToggle,
      restoreModalToggle,
      deleteModalText
    } = this.state
    return (
      <Fragment>
        <div
          className={`side-bar-body__panel side-bar-body__panel--${
            showSideBar ? `open` : `closed`
          } `}>
          <SideBar key={id} {...this.getSideBarConfig()} />
        </div>
        {deleteModalToggle && (
          <DeleteGoalModal
            deleteModalText={deleteModalText}
            toggleDeleteModal={this.toggleDeleteModal}
            handleGoalDelete={this.handleGoalDelete}
          />
        )}
        {restoreModalToggle && (
          <RestoreGoalModal
            toggleRestoreModal={this.toggleRestoreModal}
            restoreSuggestedGoal={this.restoreSuggestedGoal}
            clearSuggestedGoal={this.clearSuggestedGoal}
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdFinId = match?.params?.householdFinId
  const household = store?.households[householdFinId]
  const houseGoalActions = store?.goalActions[householdFinId]
  const houseHcExercises = store?.hcExercises[householdFinId]
  return {
    contacts: store.contact[householdFinId],
    householdFinId,
    goals: houseGoalActions?.goalActions || [],
    goalId: houseGoalActions?.goalRequestId || null,
    activeGoalAction: houseGoalActions?.activeGoalAction || {},
    suggestedGoals: houseGoalActions?.suggestedGoals || [],
    suggestedGoalId: houseGoalActions?.suggestedGoalId || null,
    goalsFromSelector: goalActionsSelector(store, householdFinId),
    archivedGoals: archivedGoalActionsSelector(store, householdFinId),
    honestConversation:
      houseHcExercises && houseHcExercises[household?.hcExerciseId],
    globalNavigationState: store?.globalSettings?.globalNavigation?.collapsed,
    sideBarState: houseGoalActions && houseGoalActions.sideBarState,
    household
  }
}

export default withRouter(connect(mapStateToProps)(SideBarView))
