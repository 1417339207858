import React from 'react'
import Checkmark from './checkmark'

interface GeographicAttributeProps {
  imageSrc: string
  title: string
  definition: string
  active: boolean
  onHover(title: string): void
  onClick(title: string): void
}

interface GeographicAttributeState {
  hovering: boolean
}

class GeographicAttribute extends React.Component<
  GeographicAttributeProps,
  GeographicAttributeState
> {
  public state = {
    hovering: false
  }
  public setHovering = () => {
    const { title, onHover } = this.props
    onHover(title)
    this.setState({ hovering: true })
  }
  public setNotHovering = () => {
    const { onHover } = this.props
    this.setState({ hovering: false })
    onHover(null)
  }
  public onClick = () => {
    const { onClick, title } = this.props
    onClick(title)
  }
  public render() {
    const { imageSrc, title, definition, active } = this.props
    const { hovering } = this.state
    const activeClass = active ? 'ivf-geography__option-details--active' : ''
    return (
      <div
        className='ivf-geography__option'
        onMouseEnter={this.setHovering}
        onMouseLeave={this.setNotHovering}
        onClick={this.onClick}>
        <div className={`ivf-geography__option-details ${activeClass}`}>
          <Checkmark active={active} />
          <div className='ivf-geography__option-img-w'>
            <img
              src={imageSrc}
              className='ivf-geography__option-img'
              role='presentation'
              alt=''
            />
          </div>
          <div className='ivf-geography__option-title'>{title}</div>
          <div className='ivf-geography__option-definition'>{definition}</div>
        </div>
        <div
          className='ivf-geography__option-bar'
          style={{ visibility: hovering ? 'visible' : 'hidden' }}
        />
      </div>
    )
  }
}

export default GeographicAttribute
