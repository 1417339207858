import React, { Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import Button from '../../components/button'
import { history } from '../../../store'
import BackButton from '../../components/backButton'
import InvestmentAttribute from './investmentAttribute'
import { ReactComponent as PerformanceIcon } from '../../assets/images/icons/viewfinder_performance.svg'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/viewfinder_protection.svg'
import { ReactComponent as TaxesIcon } from '../../assets/images/icons/viewfinder_taxes.svg'
import { ReactComponent as CostsIcon } from '../../assets/images/icons/viewfinder_costs.svg'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderPriorityInterface,
  AttributeDescription
} from '../../../objects/investmentViewfinder'

interface InvestmentAttributesProps {
  householdFinId: string
  exerciseId: string
  priorities: InvestmentViewfinderPriorityInterface
}

interface InvestmentAttributesState {
  activeDescription: AttributeDescription
}

class InvestmentAttributes extends React.Component<
  InvestmentAttributesProps,
  InvestmentAttributesState
> {
  constructor(props: InvestmentAttributesProps) {
    super(props)
    this.state = {
      activeDescription: null
    }
  }
  public navigateToQuestions = () => {
    const { householdFinId, exerciseId } = this.props
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/1`
    )
  }
  public startPriorities = () => {
    return (
      <Button primary={true} onClick={this.navigateToQuestions}>
        Start Priorities Questions
      </Button>
    )
  }
  public navigateBack = () => {
    const { householdFinId, exerciseId } = this.props
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}`
    )
  }
  public setActiveDescription = (activeDescription: AttributeDescription) => {
    this.setState({ activeDescription })
  }
  public description = () => {
    const { activeDescription } = this.state
    const benefits =
      activeDescription &&
      this.props.priorities[activeDescription.toLowerCase()].benefits
    const drawbacks =
      activeDescription &&
      this.props.priorities[activeDescription.toLowerCase()].drawbacks
    return (
      <div
        className='ivf-attributes__pros-cons-w'
        style={{ visibility: activeDescription ? 'visible' : 'hidden' }}>
        <div className='ivf-attributes__pros-cons'>
          <div className='ivf-attributes__pros-cons-title'>Benefits</div>
          <ul>
            {activeDescription &&
              benefits.map((benefit: string, index: number) => {
                return (
                  <li
                    key={index}
                    className='ivf-attributes__pros-cons-description'>
                    {benefit}
                  </li>
                )
              })}
          </ul>
        </div>
        <div className='ivf-attributes__pros-cons-divider' />
        <div className='ivf-attributes__pros-cons'>
          <div className='ivf-attributes__pros-cons-title'>Drawbacks</div>
          <ul>
            {activeDescription &&
              drawbacks.map((drawback: string, index: number) => {
                return (
                  <li
                    key={index}
                    className='ivf-attributes__pros-cons-description'>
                    {drawback}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }
  public render() {
    const { priorities } = this.props
    return (
      <Fragment>
        <ContentHeader title='Investment Attributes' />
        <Tile
          rightHeader={this.startPriorities()}
          leftHeader={<BackButton onClick={this.navigateBack} />}
          headerBorder={true}
          headerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#FAFAFA'
          }}>
          <div className='ivf-container'>
            <div className='ivf-attributes-w'>
              <InvestmentAttribute
                Icon={PerformanceIcon}
                title={priorities.performance.label}
                description={priorities.performance.description}
                barColor={priorities.performance.color}
                setActiveDescription={this.setActiveDescription}
              />
              <InvestmentAttribute
                Icon={ProtectionIcon}
                title={priorities.protection.label}
                description={priorities.protection.description}
                barColor={priorities.protection.color}
                setActiveDescription={this.setActiveDescription}
              />
              <InvestmentAttribute
                Icon={CostsIcon}
                title={priorities.cost.label}
                description={priorities.cost.description}
                barColor={priorities.cost.color}
                setActiveDescription={this.setActiveDescription}
              />
              <InvestmentAttribute
                Icon={TaxesIcon}
                title={priorities.taxes.label}
                description={priorities.taxes.description}
                barColor={priorities.taxes.color}
                setActiveDescription={this.setActiveDescription}
              />
            </div>
            {this.description()}
          </div>
        </Tile>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    priorities: store.investmentViewfinder.priorities
  }
}

export default connect(mapStateToProps)(InvestmentAttributes)
