import React, { Component } from 'react'

import { ContactObj } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'

import EmailPreviewModal from '../../components/layout/emailPreviewModal'

import HonestConversationBanner from '../../assets/images/nullStates/HonestConversationNullState.png'
import { ReactComponent as HCIcon } from '../../assets/images/logos/logo_hc_blue.svg'
import { ReactComponent as FinLifeLogo } from '../../assets/images/logos/finlife-cx-logo-gray.svg'
import { ReactComponent as UnitedCapitalLogo } from '../../assets/images/logos/united-capital-logo.svg'

export interface HcPrioritizationEmailModalProps {
  contact: ContactObj
  user: UserObj
  institutionPhotoUrl: string
  saveModal(id: string): void
  closeModal(): void
}

class HcPrioritizationEmailModal extends Component<
  HcPrioritizationEmailModalProps
> {
  public render() {
    const {
      contact,
      user,
      institutionPhotoUrl,
      saveModal,
      closeModal
    } = this.props
    const link = `${window._env_.REACT_APP_GUIDECENTER_URL}/honest-conversations`
    return (
      <EmailPreviewModal
        user={user}
        contact={contact}
        saveModal={saveModal}
        closeModal={closeModal}
        showCopyLink={false}
        subject={'Your HonestConversations® experience'}>
        <div className='hc-prioritization-email-modal__header'>
          <HCIcon />
          <span className='hc-prioritization-email-modal__header-text'>
            HonestConversations®
          </span>
        </div>
        <div className='hc-prioritization-email-modal__img-container'>
          <img src={HonestConversationBanner} alt='HC' />
        </div>
        <div className='hc-prioritization-email-modal__email-w'>
          <div className='hc-prioritization-email-modal__name-label'>
            Dear {contact?.firstName},
          </div>
          <p className='hc-prioritization-email-modal__body-text'>
            To better understand what matters most to you, we start with a
            collaborative process to clarify your goals and top priorities. This
            exercise, and the choices you make, start to uncover what's
            important to you - and why.
          </p>
          <p className='hc-prioritization-email-modal__body-text'>
            <a
              className='hc-prioritization-email-modal__body-text--link'
              target='_blank'
              rel='noopener noreferrer'
              href={link}>
              Click here
            </a>
            <span className='hc-prioritization-email-modal__body-text'>
              {
                ' to begin the HonestConversations® experience in advance of our next meeting.'
              }
            </span>
          </p>
          <p className='hc-prioritization-email-modal__body-text'>
            We look forward to seeing you soon.
          </p>
        </div>
        <div className='hc-prioritization-email-modal__disclosure'>
          <div className='hc-prioritization-email-modal__disclosure-logo-w'>
            {institutionPhotoUrl ? (
              <img
                className='hc-prioritization-email-modal__institution-logo hc-prioritization-email-modal__institution-logo--gray'
                src={institutionPhotoUrl}
                alt='institution logo'
              />
            ) : (
              <UnitedCapitalLogo />
            )}
            <FinLifeLogo className='hc-prioritization-email-modal__finlife-logo' />
          </div>
          <div className='hc-prioritization-email-modal__disclosure-section-1'>
            <p>
              {new Date().getFullYear()} | United Capital Financial Advisors,
              LLC (“United Capital”) d/b/a
            </p>
            <p>
              United Capital Financial Advisors, LLC (“United Capital”). All
              Rights Reserved.
            </p>
            <p>
              FinLife Partners is a d/b/a of United Capital Financial Advisors,
              LLC |{' '}
              <a
                href={window._env_.REACT_APP_PRIVACY_POLICY_URL}
                target='_blank'
                rel='noopener noreferrer'>
                Privacy Policy
              </a>
            </p>
          </div>
          <div className='hc-prioritization-email-modal__disclosure-section-2'>
            <p className='hc-prioritization-email-modal__disclosure-section-2--justify'>
              {' '}
              All names, logos, and slogans identifying United Capital or United
              Capital’s products and services (including, without limitation,
              HonestConversations®, MoneyMind®, FinLife®, Financial Control
              Scorecard®, Live Richly℠, We Help You Live Richly℠, Helping People
              Live Richly®, One Best Financial Life®, GuideCenter®,
              InvestmentViewfinder℠, United Capital Financial Life Management®,
              and Financial Years of Freedom℠) are trademarks and service marks
              or registered trademarks and service marks of United Capital or
              its affiliates in the United States and/or other countries.
            </p>
            <p className='hc-prioritization-email-modal__disclosure-section-2--justify'>
              Any investment advisory services are provided pursuant to the
              terms of your agreement with United Capital or an affiliate or a
              third party investment advisor, as applicable. United Capital is
              an investment advisor registered with the SEC. The information
              contained in this material is for general informational purposes
              only and does not constitute an offer, solicitation, invitation or
              recommendation to buy or sell any financial instruments or to
              provide any investment advice or service of any kind.
            </p>
          </div>
        </div>
      </EmailPreviewModal>
    )
  }
}

export default HcPrioritizationEmailModal
