import React from 'react'
import Tile from '../../components/layout/tile'
import InnerContainer from '../../components/layout/innerContainer'
import Button from '../../components/button'
import { history } from '../../../store'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { ReactComponent as WelcomeAvatarImg } from '../../assets/images/viewfinder_welcome_avatar.svg'

interface WelcomeProps {
  match: any
}
class Welcome extends React.Component<WelcomeProps> {
  public startExercise = () => {
    const { url } = this.props.match
    history.push(`${url}/priorities`)
  }
  public render() {
    return (
      <InnerContainer>
        <Tile
          style={{ marginTop: 24 }}
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <div className='ivf-welcome__title-w'>
            <div className='ivf-welcome__title-image'>
              <WelcomeAvatarImg />
            </div>
            <p className='ivf-welcome__title-text'>
              When you invest, you can't have it all.
            </p>
            <p className='ivf-welcome__title-text'>
              This tool will help us learn three things.
            </p>
          </div>
          <div className='ivf-welcome__description-w'>
            <div className='ivf-welcome__description'>
              <div className='ivf-welcome__description-number'>1</div>
              <div className='ivf-welcome__description-text'>
                This exercise will help us identify your investment preferences,
                with an understanding of benefits and trade-offs.
              </div>
            </div>
            <div className='ivf-welcome__description'>
              <div className='ivf-welcome__description-number'>2</div>
              <div className='ivf-welcome__description-text'>
                Here we will evaluate your existing portfolio, and determine
                whether it aligns with your preferences.
              </div>
            </div>
            <div className='ivf-welcome__description'>
              <div className='ivf-welcome__description-number'>3</div>
              <div className='ivf-welcome__description-text'>
                The final step is to create a portfolio that is right for you,
                targeted to your goals and aligned with your preferences.
              </div>
            </div>
          </div>
          <div className='ivf-welcome__buttons'>
            <Button primary={true} onClick={this.startExercise}>
              Start Exercise
            </Button>
          </div>
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (_store: GlobalState, { match }: any) => {
  return {
    match
  }
}

export default withRouter(connect(mapStateToProps)(Welcome))
