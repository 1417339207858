import * as actions from '../actions/portfolioBuilder'
import {
  PortfolioBuilderInterface,
  PortfolioBuilderAccountInterface
} from '../objects/portfolioBuilder'
import {
  PortfolioBuilder as PortfolioBuilderApi,
  PortfolioBuilderAccounts
} from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
import { store } from '../store'

export interface PortfolioBuilderState {
  [household: string]: PortfolioBuilderInterface
}

const initState = {}

const mapPortfolioBuilder = (portfolioBuilderRecord: any) => {
  return {
    id: portfolioBuilderRecord.id,
    adviserFees: portfolioBuilderRecord.adviserFees || 0,
    adviserImFees: portfolioBuilderRecord.adviserImFees || 0,
    clientAdviceFees: portfolioBuilderRecord.clientAdviceFees || 0,
    clientAdviceFeesPercent:
      portfolioBuilderRecord.clientAdviceFeesPercent || 0,
    clientImFees: portfolioBuilderRecord.clientImFees || 0,
    clientImFeesPercent: portfolioBuilderRecord.clientImFeesPercent || 0,
    clientNameLabel: portfolioBuilderRecord.clientNameLabel,
    clientTotalFees: portfolioBuilderRecord.clientTotalFees || 0,
    clientTotalFeesPercent: portfolioBuilderRecord.clientTotalFeesPercent || 0,
    clientType: portfolioBuilderRecord.clientType,
    clientInvestmentManagementFees:
      portfolioBuilderRecord.clientInvestmentManagementFees || 0,
    duration: portfolioBuilderRecord.duration,
    feeSchedule: portfolioBuilderRecord.feeSchedule,
    includeChangeIllustration: portfolioBuilderRecord.includeChangeIllustration,
    includeDefensivePosture: portfolioBuilderRecord.includeDefensivePosture,
    includeEquityFixedIncomeDrilldown:
      portfolioBuilderRecord.includeEquityFixedIncomeDrilldown,
    includeFees: portfolioBuilderRecord.includeFees,
    includeFirmOverview: portfolioBuilderRecord.includeFirmOverview,
    includeIntroduction: portfolioBuilderRecord.includeIntroduction,
    includeOurProcess: portfolioBuilderRecord.includeOurProcess,
    includePortfolioImplementation:
      portfolioBuilderRecord.includePortfolioImplementation,
    includePortfolioSnapshot: portfolioBuilderRecord.includePortfolioSnapshot,
    includeMonthlyInvComOutlook:
      portfolioBuilderRecord.includeMonthlyInvComOutlook,
    lastModified: portfolioBuilderRecord.lastModified,
    maturity: portfolioBuilderRecord.maturity,
    name: portfolioBuilderRecord.name,
    outsideAmount: portfolioBuilderRecord.outsideAmount,
    overrideCashPercent: portfolioBuilderRecord.overrideCashPercent,
    overrideEquityPercent: portfolioBuilderRecord.overrideEquityPercent,
    overrideFixedPercent: portfolioBuilderRecord.overrideFixedPercent,
    overrideOtherPercent: portfolioBuilderRecord.overrideOtherPercent,
    overrideTotalPercent: portfolioBuilderRecord.overrideTotalPercent,
    proposalDate: portfolioBuilderRecord.proposal,
    targetAltPercent: portfolioBuilderRecord.targetAltPercent || 0,
    targetCashPercent: portfolioBuilderRecord.targetCashPercent || 0,
    targetEquityPercent: portfolioBuilderRecord.targetEquityPercent || 0,
    targetFixedPercent: portfolioBuilderRecord.targetFixedPercent || 0,
    templateids: portfolioBuilderRecord.templateids,
    yieldDollars: portfolioBuilderRecord.yieldDollars || 0,
    currentAccounts: {},
    proposedAccounts: {}
  }
}

const mapPortfolioBuilders = (
  portfolioBuilderRecords: PortfolioBuilderApi.Response[]
) => {
  const obj: PortfolioBuilderInterface = {}
  portfolioBuilderRecords.forEach((pbRecord: PortfolioBuilderApi.Response) => {
    obj[pbRecord.id] = mapPortfolioBuilder(pbRecord)
  })
  return obj
}

const mapPortfolioBuilderAccount = (
  pbAccount: PortfolioBuilderAccounts.Response,
  householdFinId: string
) => {
  const obj: PortfolioBuilderAccountInterface = {}
  const strategies = store.getState().strategies?.[householdFinId]
  obj[pbAccount.id] = {
    ...pbAccount,
    strategy:
      strategies && strategies[pbAccount.strategy] ? pbAccount.strategy : null,
    accountValue:
      pbAccount.currentCashValue +
      pbAccount.currentEquityValue +
      pbAccount.currentFixedValue +
      pbAccount.currentOtherValue +
      pbAccount.currentUnclassifiedValue
  }

  return obj
}

const mapProposedPortfolioBuilderAccount = (
  account: PortfolioBuilderAccounts.Response,
  householdId: string
) => {
  const { id, strategy } = account
  const householdStrategies = store.getState().strategies?.[householdId]
  const isStratAssigned = Boolean(strategy)
  const isStratAvailable = isStratAssigned
    ? Boolean(householdStrategies?.[strategy])
    : true
  const obj: PortfolioBuilderAccountInterface = {}
  if (isStratAvailable) {
    obj[id] = {
      ...account,
      accountValue:
        account.currentCashValue +
        account.currentEquityValue +
        account.currentFixedValue +
        account.currentOtherValue +
        account.currentUnclassifiedValue
    }
  } else {
    obj[id] = {
      ...account,
      accountValue: 0,
      currentCashValue: 0,
      currentEquityValue: 0,
      currentFixedValue: 0,
      currentOtherValue: 0,
      currentUnclassifiedValue: 0,
      yield: 0,
      strategyFee: 0,
      strategy: null,
      expenseRatio: 0,
      unavailableStrategy: strategy
    }
  }

  return obj
}

const mapPortfolioBuilderAccounts = (
  portfolioBuilderAccounts: PortfolioBuilderAccounts.Response[]
) => {
  const obj: {
    currentAccounts: PortfolioBuilderAccountInterface
    proposedAccounts: PortfolioBuilderAccountInterface
  } = { currentAccounts: {}, proposedAccounts: {} }
  const householdFinId = (<any>portfolioBuilderAccounts).householdFinId
  portfolioBuilderAccounts
    .filter((pbAccount) => pbAccount.type === 'Current')
    .forEach((pbAccount: PortfolioBuilderAccounts.Response) => {
      obj.currentAccounts = {
        ...obj.currentAccounts,
        ...mapPortfolioBuilderAccount(pbAccount, householdFinId)
      }
    })
  portfolioBuilderAccounts
    .filter((pbAccount) => pbAccount.type === 'Proposed')
    .forEach((pbAccount: PortfolioBuilderAccounts.Response) => {
      obj.proposedAccounts = {
        ...obj.proposedAccounts,
        ...mapProposedPortfolioBuilderAccount(pbAccount, householdFinId)
      }
    })
  return obj
}

const PortfolioBuilder = (
  state = initState,
  action: any
): PortfolioBuilderState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_PORTFOLIO_BUILDER}_FULFILLED`:
      newState[action.payload.data.householdFinId] = {
        ...mapPortfolioBuilders(action.payload.data)
      }
      return newState
    case `${actions.FETCH_PORTFOLIO_BUILDER_ACCOUNTS}_FULFILLED`:
      if (!newState[action.payload.data.householdFinId]) {
        newState[action.payload.data.householdFinId] = {
          newPortfolioBuilder: null
        }
        newState[action.payload.data.householdFinId][
          action.payload.data.portfolioBuilderFinId
        ] = {}
      }
      newState[action.payload.data.householdFinId][
        action.payload.data.portfolioBuilderFinId
      ] = {
        ...newState[action.payload.data.householdFinId][
          action.payload.data.portfolioBuilderFinId
        ],
        ...mapPortfolioBuilderAccounts(action.payload.data)
      }
      return newState
    case `${actions.CREATE_PORTFOLIO_BUILDER}_FULFILLED`:
      newState[action.payload.data.householdFinId][
        action.payload.data.id
      ] = mapPortfolioBuilder(action.payload.data)
      newState[
        action.payload.data.householdFinId
      ].newPortfolioBuilder = mapPortfolioBuilder(action.payload.data)
      return newState
    case `${actions.CREATE_PORTFOLIO_BUILDER_ACCOUNT}_FULFILLED`:
      return newState
    case `${actions.UPDATE_PORTFOLIO_BUILDER_ACCOUNT}_FULFILLED`:
      return newState
    case `${actions.UPDATE_PORTFOLIO_BUILDER}_FULFILLED`:
      newState[action.payload.data.householdFinId][
        action.payload.data.portfolioBuilderFinId
      ] = mapPortfolioBuilder(action.payload.data)
      return newState
    case `${actions.DELETE_PORTFOLIO_BUILDER_ACCOUNT}_FULFILLED`:
      return newState
    default:
      return state
  }
}

export default PortfolioBuilder
