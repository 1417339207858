import { MoneyMindInterface } from '../objects/moneymind'
import * as actions from '../actions/moneymind'

export interface MoneyMindState {
  [key: string]: MoneyMindInterface
}

const initState: MoneyMindState = {}

const mapMoneyMinds = (
  data: any,
  moneymindState: MoneyMindInterface
): MoneyMindState => {
  const { moneyminds, household, primaryContact } = data
  const obj = {}
  obj[household] = { ...moneymindState }
  const type = primaryContact ? 'primary' : 'secondary'
  obj[household][type] = {}
  if (moneyminds) {
    moneyminds.forEach((moneymind: any) => {
      obj[household][type][moneymind.responseId] = {
        id: moneymind.responseId,
        date: moneymind.date,
        protection: moneymind.protection,
        commitment: moneymind.commitment,
        happiness: moneymind.happiness,
        age: moneymind.age,
        ageRange: moneymind.ageRange,
        gender: moneymind.gender,
        firstDescription: moneymind.firstDescription,
        secondDescription: moneymind.secondDescription,
        thirdDescription: moneymind.thirdDescription
      }
    })
  }
  return obj
}

const deleteSingleRecord = (
  data: any,
  moneymindState: MoneyMindState
): MoneyMindState => {
  const { householdFinId, moneymindId, contactKey } = data
  if (moneymindState[householdFinId]) {
    if (moneymindState[householdFinId][contactKey][moneymindId]) {
      delete moneymindState[householdFinId][contactKey][moneymindId]
    }
  }

  return moneymindState
}

const MoneyMind = (state = initState, action: any): MoneyMindState => {
  switch (action.type) {
    case actions.ADD_MONEY_MIND_FULFILLED:
    case actions.EDIT_MONEY_MIND_FULFILLED:
    case actions.FETCH_MONEY_MINDS_FULFILLED:
      const moneymind = mapMoneyMinds(
        action.payload.data,
        state[action.payload.data.household]
      )
      return { ...state, ...moneymind }
      break
    case actions.DELETE_MONEY_MIND_FULFILLED:
      return { ...state, ...deleteSingleRecord(action.payload.data, state) }
      break
    default:
      return state
  }
}

export default MoneyMind
