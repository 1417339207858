import React from 'react'
import { TaskObj } from '../../../../objects/tasks'

import TaskIcon from '../../../assets/images/icons/png/tasks_grey.png'

export const TaskTitle: React.FC<{ titleName: string }> = ({ titleName }) => (
  <div className='task-add__header'>
    <img
      className='content-header__img'
      src={TaskIcon}
      alt=''
      role='presentation'
      key='icon'
    />
    <span>{titleName}</span>
  </div>
)

export const TaskAssignType: React.FC<{
  isClientAssignedTask: boolean
  selected: string
  activeTask: TaskObj
  viewMode: string
  onChangeFunc(event: React.FormEvent<HTMLInputElement>): void
  onBlurFunc(): void
}> = ({
  isClientAssignedTask,
  onChangeFunc,
  onBlurFunc,
  selected,
  activeTask,
  viewMode
}) => {
  const isServiceTeamDisabled = activeTask?.id !== 'NEW' && isClientAssignedTask

  const clientAssignmentNote = (
    <div className='task-add__label' style={{ marginTop: '16px' }}>
      If a task {viewMode === 'EDIT' ? 'is' : 'was'} assigned to a client, it
      can only be assigned to another client (not a service team member).
    </div>
  )

  return (
    <div className='task-add__container'>
      <div className='task-add__label'>Assign Type</div>
      <div className='task-add__options'>
        <label htmlFor='clientType'>
          <input
            type='radio'
            name='pickClient'
            id='assignClientType'
            role='radio'
            aria-checked={false}
            checked={selected === 'client'}
            value='client'
            onChange={onChangeFunc}
            onBlur={onBlurFunc}
          />
          <span>Client</span>
        </label>
        <label htmlFor='serviceTeamType'>
          <input
            disabled={isServiceTeamDisabled}
            type='radio'
            name='pickServiceTeam'
            id='assignServiceTeamType'
            role='radio'
            aria-checked={false}
            checked={selected === 'user'}
            value='user'
            onChange={onChangeFunc}
            onBlur={onBlurFunc}
          />
          <span style={{ opacity: isServiceTeamDisabled ? '0.3' : '1' }}>
            Service Team
          </span>
        </label>
      </div>
      {isServiceTeamDisabled && clientAssignmentNote}
    </div>
  )
}
