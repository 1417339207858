import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'

import { calcProposedTotal } from '../../../helpers/portfolioBuilder'
import { dollarFormat } from '../../../helpers'
import {
  PortfolioBuilderAccountObj,
  PortfolioBuilderObj
} from '../../../../objects/portfolioBuilder'
import { GlobalState } from '../../../../reducers'
import {
  deletePortfolioBuilderAccount,
  getPortfolioBuilderAccounts
} from '../../../../actions/portfolioBuilder'
import AcctStrategy from './acctStrategy'
import DeleteAccountModal from './deleteAccountModal'
import Tooltip from '../../../components/tooltip'

import deleteIcon from '../../../assets/images/icons/ic_delete.png'
import blueDeleteIcon from '../../../assets/images/icons/ic_delete_blue.png'
import accountIcon from '../../../assets/images/icons/ic_account.png'
import blueAccountIcon from '../../../assets/images/icons/ic_account_blue.png'
import editIcon from '../../../assets/images/icons/ic_edit.png'
import blueEditIcon from '../../../assets/images/icons/ic_edit_blue.png'
import menuIcon from '../../../assets/images/icons/ic_menu.png'
import blueMenuIcon from '../../../assets/images/icons/ic_menu_blue.png'
import splitIcon from '../../../assets/images/icons/ic_split.png'
import blueSplitIcon from '../../../assets/images/icons/ic_split_blue.png'

interface CurrentAccountProps {
  dispatch: Dispatch<GlobalState>
  strategies: PortfolioBuilderAccountObj[]
  householdFinId: string
  clientAccount: PortfolioBuilderAccountObj
  portfolioBuilderData: PortfolioBuilderObj
  portfolioBuilderId: string
  onAccountError(e: number): void
  handleAccountIconClick(
    selectedAccount: PortfolioBuilderAccountObj,
    type: string
  ): () => void
}

interface CurrentAccountState {
  clientAccount: any
  value: number
  errStatus: boolean
  deleteModal: boolean
  selectedAccount: PortfolioBuilderAccountObj | null
}
class CurrentAccount extends Component<
  CurrentAccountProps,
  CurrentAccountState
> {
  constructor(props: CurrentAccountProps) {
    super(props)
    this.state = {
      clientAccount: this.props.clientAccount,
      value: null,
      errStatus: false,
      deleteModal: false,
      selectedAccount: null
    }
  }

  public componentDidMount() {
    const { clientAccount } = this.state
    if (!clientAccount.length) {
      this.setState({ value: this.state.clientAccount.accountValue })
    } else {
      const totalValue = clientAccount.reduce(
        (total: number, current: PortfolioBuilderAccountObj) => {
          return total + current.accountValue
        },
        0
      )
      this.setState({ value: totalValue })
    }
  }

  public componentWillReceiveProps(nextProps: CurrentAccountProps) {
    if (nextProps.clientAccount !== this.props.clientAccount) {
      this.setState({ clientAccount: nextProps.clientAccount }, () => {
        const { clientAccount } = this.state
        if (!clientAccount.length) {
          this.setState({ value: clientAccount.accountValue })
        } else {
          const totalValue = clientAccount.reduce(
            (total: number, current: PortfolioBuilderAccountObj) => {
              return total + current.accountValue
            },
            0
          )
          this.setState({ value: totalValue })
        }
      })
    }
  }

  public deleteAccountClick = (acctMult: PortfolioBuilderAccountObj) => () => {
    this.setState({ selectedAccount: acctMult })
    this.toggleDelete()
  }

  public toggleDelete = () => {
    this.setState({ deleteModal: !this.state.deleteModal })
  }

  public onStratSelect = (errStatus: boolean) => {
    if (!errStatus) {
      this.props.onAccountError(0)
      this.setState({ errStatus: false })
    } else {
      this.props.onAccountError(1)
      this.setState({ errStatus: true })
    }
  }

  public generateBorder = (type: string, index?: number) => {
    const { clientAccount } = this.state
    if (type === 'split') {
      return (
        <div className='client-accounts__line'>
          <div className={'border-bottom '} />
          <div />
        </div>
      )
    } else if (type === 'combined') {
      if (
        !this.props.strategies ||
        this.props.strategies.length <= clientAccount.length
      ) {
        return (
          <div className='client-accounts__line'>
            <div
              className={'border-bottom ' + (index !== 0 ? 'border-right' : '')}
            />
            <div
              className={
                index === 0 || (index >= 1 && index < clientAccount.length - 1)
                  ? 'border-right'
                  : ''
              }
            />
          </div>
        )
      } else {
        return (
          <div className='client-accounts__line'>
            <div className={'border-bottom '} />
            <div />
          </div>
        )
      }
    } else return null
  }

  public initiateDelete = async () => {
    const { householdFinId, portfolioBuilderId } = this.props
    const { selectedAccount } = this.state
    const { proposedAccounts } = this.props.portfolioBuilderData
    const newAccount: any = Object.keys(proposedAccounts)
      .map((e) =>
        proposedAccounts[e].clientAccount === selectedAccount.clientAccount
          ? proposedAccounts[e]
          : null
      )
      .filter((e) => e)
    if (newAccount.length > 1) {
      newAccount.forEach((acc: PortfolioBuilderAccountObj) => {
        this.props.dispatch(
          deletePortfolioBuilderAccount(
            householdFinId,
            portfolioBuilderId,
            acc.id
          )
        )
      })
    } else if (newAccount.length === 1) {
      await this.props.dispatch(
        deletePortfolioBuilderAccount(
          householdFinId,
          portfolioBuilderId,
          newAccount[0].id
        )
      )
    }
    await this.props.dispatch(
      deletePortfolioBuilderAccount(
        householdFinId,
        portfolioBuilderId,
        selectedAccount.id
      )
    )
    this.props.dispatch(
      getPortfolioBuilderAccounts(householdFinId, portfolioBuilderId)
    )
    this.toggleDelete()
  }

  public onIconEnter = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const target = e.currentTarget
    switch (target.alt) {
      case 'edit':
        return (target.src = blueEditIcon)
      case 'delete':
        return (target.src = blueDeleteIcon)
      case 'clone':
        return (target.src = blueAccountIcon)
      case 'split':
        return (target.src = blueSplitIcon)
      case 'combine':
        return (target.src = blueMenuIcon)
      default:
        return null
    }
  }

  public onIconLeave = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const target = e.currentTarget
    switch (target.alt) {
      case 'edit':
        return (target.src = editIcon)
      case 'delete':
        return (target.src = deleteIcon)
      case 'clone':
        return (target.src = accountIcon)
      case 'split':
        return (e.currentTarget.src = splitIcon)
      case 'combine':
        return (e.currentTarget.src = menuIcon)
      default:
        return null
    }
  }

  public generateAccountInfo = (account?: any) => {
    const { clientAccount, errStatus } = this.state
    const splitButtonToggle = false
    const acctMult: PortfolioBuilderAccountObj =
      clientAccount && clientAccount.length ? account : clientAccount
    return (
      <Fragment>
        <label
          htmlFor={acctMult.id}
          style={{ border: `1px solid ${errStatus ? '#f12938' : '#d2d2d2'}` }}
          className='client-accounts__account-wrapper'>
          <div className='client-accounts__account-wrapper-left'>
            <div className='client-accounts__name'>
              {acctMult.accountNickname}
            </div>
            <div className='client-accounts__acc-number'>
              {acctMult.accountNumber}
            </div>
            <div className='client-accounts__amount'>
              {dollarFormat(acctMult.accountValue, 0)}
            </div>
            <div className='client-accounts__reg-type'>
              {acctMult.registrationType ? (
                <span>{acctMult.registrationType}</span>
              ) : null}
            </div>
          </div>
          <div className='client-accounts__account-wrapper-right'>
            <div className='client-accounts__account-wrapper-right-icons'>
              <Tooltip position='top' width={60} message='Edit'>
                <img
                  src={editIcon}
                  onMouseOver={this.onIconEnter}
                  onMouseOut={this.onIconLeave}
                  alt='edit'
                  onClick={this.props.handleAccountIconClick(acctMult, 'edit')}
                />
              </Tooltip>
              <Tooltip position='top' width={70} message='Delete'>
                <img
                  src={deleteIcon}
                  onMouseOver={this.onIconEnter}
                  onMouseOut={this.onIconLeave}
                  alt='delete'
                  onClick={this.deleteAccountClick(acctMult)}
                />
              </Tooltip>
              {this.props.strategies &&
              this.props.strategies.length > 1 &&
              clientAccount ? null : (
                <Tooltip position='top' width={80} message='Combine'>
                  <img
                    src={menuIcon}
                    onMouseOver={this.onIconEnter}
                    onMouseOut={this.onIconLeave}
                    alt='combine'
                    onClick={this.props.handleAccountIconClick(
                      acctMult,
                      'combine'
                    )}
                  />
                </Tooltip>
              )}
              <Tooltip position='top' width={65} message='Clone'>
                <img
                  src={accountIcon}
                  onMouseOver={this.onIconEnter}
                  onMouseOut={this.onIconLeave}
                  alt='clone'
                  onClick={this.props.handleAccountIconClick(acctMult, 'clone')}
                />
              </Tooltip>
              {clientAccount &&
              clientAccount.length &&
              splitButtonToggle ? null : (
                <Tooltip position='top' width={60} message='Split'>
                  <img
                    src={splitIcon}
                    onMouseOver={this.onIconEnter}
                    onMouseOut={this.onIconLeave}
                    alt='split'
                    onClick={this.props.handleAccountIconClick(
                      clientAccount,
                      'split'
                    )}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </label>
      </Fragment>
    )
  }

  public combinedStratBox = () => {
    const {
      strategies,
      handleAccountIconClick,
      portfolioBuilderData
    } = this.props
    const { clientAccount } = this.state
    const singleStrat =
      strategies &&
      strategies
        .sort((a: PortfolioBuilderAccountObj, b: PortfolioBuilderAccountObj) =>
          a.id > b.id ? 1 : b.id > a.id ? -1 : 0
        )
        .slice(0, 1)
        .pop()
    if (clientAccount && singleStrat) {
      return (
        <React.Fragment>
          {' '}
          {strategies &&
            strategies.map((x, i) => {
              const propAmount = calcProposedTotal(
                clientAccount,
                portfolioBuilderData.proposedAccounts,
                strategies[i].unsplitId
              )
              return (
                <AcctStrategy
                  currentAccounts={clientAccount}
                  key={`${clientAccount.id}-${i}-strategy`}
                  index={i}
                  stratCount={1}
                  totalStratCount={strategies.length}
                  value={propAmount}
                  proposedAccount={strategies[i]}
                  showStrat={Boolean(x.strategy)}
                  strategy={x.strategy}
                  onStratSelect={this.onStratSelect}
                  regType={x.registrationType}
                  splitStatus={false}
                  handleAccountIconClick={handleAccountIconClick}
                />
              )
            })}
        </React.Fragment>
      )
    } else {
      return <div />
    }
  }

  public stratBox = () => {
    const { strategies, handleAccountIconClick } = this.props
    const { clientAccount } = this.state
    if (strategies) {
      return strategies
        .sort((a: PortfolioBuilderAccountObj, b: PortfolioBuilderAccountObj) =>
          a.id > b.id ? 1 : b.id > a.id ? -1 : 0
        )
        .map((_, i: number) => {
          const strategy =
            strategies[i] && strategies[i].strategy
              ? strategies[i].strategy
              : null
          const showStrat = Boolean(strategy)
          const regType =
            strategies[i] && strategies[i].registrationType
              ? strategies[i].registrationType
              : null
          const splitStatus = !(strategies.length <= 1)
          return (
            <AcctStrategy
              currentAccounts={clientAccount}
              errStatus={this.state.errStatus}
              key={`${clientAccount.id}-${i}-strategy`}
              index={i}
              stratCount={strategies.length}
              value={clientAccount.accountValue}
              proposedAccount={strategies[i]}
              showStrat={showStrat}
              strategy={strategy}
              onStratSelect={this.onStratSelect}
              regType={regType}
              splitStatus={splitStatus}
              handleAccountIconClick={handleAccountIconClick}
            />
          )
        })
    } else {
      return <div />
    }
  }

  public render() {
    const { clientAccount, deleteModal } = this.state
    return (
      <Fragment>
        {clientAccount.length ? (
          <div className='client-accounts__wrapper'>
            <div>
              {clientAccount
                .sort(
                  (
                    a: PortfolioBuilderAccountObj,
                    b: PortfolioBuilderAccountObj
                  ) =>
                    a.accountNickname > b.accountNickname
                      ? 1
                      : b.accountNickname > a.accountNickname
                      ? -1
                      : 0
                )
                .map((account: PortfolioBuilderAccountObj, i: number) => {
                  return (
                    <div key={account.id} className='client-accounts'>
                      {this.generateAccountInfo(account)}
                      {this.generateBorder('combined', i)}
                    </div>
                  )
                })}
            </div>

            <div
              className={`strat-wrapper client-account-${clientAccount[0].id}`}>
              {this.combinedStratBox()}
            </div>
          </div>
        ) : (
          <div className='client-accounts__wrapper'>
            <div>
              <div className='client-accounts'>
                {this.generateAccountInfo()}
                {this.generateBorder('split')}
              </div>
            </div>
            <div className={`strat-wrapper client-strat-${clientAccount.id}`}>
              {this.stratBox()}
            </div>
          </div>
        )}
        {deleteModal ? (
          <DeleteAccountModal
            toggleDelete={this.toggleDelete}
            initiateDelete={this.initiateDelete}
          />
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    portfolioBuilderId: match.params.portfolioBuilderId,
    portfolioBuilderData:
      store.portfolioBuilder[match.params.householdFinId] &&
      store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ]
  }
}

export default withRouter(connect(mapStateToProps)(CurrentAccount))
