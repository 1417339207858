import React from 'react'
import { Switch, Route } from 'react-router'
import Welcome from './welcome'
import Header from './header'
import Footer from './footer'
import InvestmentAttributes from './investmentAttributes'
import PriorityQuestion from './priorityQuestion'
import PriorityQuestionSummary from './priorityQuestionSummary'
import PriorityQuestionResults from './priorityQuestionResults'
import { GlobalState } from '../../../reducers'
import { Dispatch, connect } from 'react-redux'
import * as actions from '../../../actions/investmentViewfinder'
import GeographicPriority from './geographicPriority'
import InnerContainer from '../../components/layout/innerContainer'
import TargetAllocation from './targetAllocations'
import {
  InvestmentViewfinderExerciseObj,
  InvestmentViewfinderQuestionObj
} from '../../../objects/investmentViewfinder'
import Results from './results'
import Disclosure from '../../components/layout/disclosure'
import { PageNotFound } from '../../../v3/containers/errors/pageNotFound'

interface InvestmentViewfinderProps {
  match: any
  location: any
  exercises: {
    [exerciseId: string]: InvestmentViewfinderExerciseObj
  }
  questions: {
    [questionNumber: number]: InvestmentViewfinderQuestionObj
  }
  dispatch: Dispatch<GlobalState>
}

class InvestmentViewfinder extends React.Component<InvestmentViewfinderProps> {
  public componentDidMount() {
    const { householdFinId } = this.props.match.params
    this.props.dispatch(actions.getViewfinderExercises(householdFinId))
    this.props.dispatch(actions.getViewfinderQuestions())
    this.props.dispatch(actions.getAllocations())
    this.props.dispatch(actions.getGeographicPriorities())
    this.props.dispatch(actions.getPriorities())
  }
  public investmentAttributes = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return (
      <InvestmentAttributes
        householdFinId={householdFinId}
        exerciseId={exerciseId}
      />
    )
  }
  public priorityQuestions = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return (
      <PriorityQuestion
        householdFinId={householdFinId}
        exerciseId={exerciseId}
      />
    )
  }
  public priorityQuestionSummary = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return (
      <PriorityQuestionSummary
        householdFinId={householdFinId}
        exerciseId={exerciseId}
      />
    )
  }
  public priorityQuestionResults = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return (
      <PriorityQuestionResults
        householdFinId={householdFinId}
        exerciseId={exerciseId}
      />
    )
  }
  public globalPriority = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return (
      <GeographicPriority
        householdFinId={householdFinId}
        exerciseId={exerciseId}
      />
    )
  }
  public allocation = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return (
      <TargetAllocation
        householdFinId={householdFinId}
        exerciseId={exerciseId}
      />
    )
  }
  public results = () => {
    const { householdFinId, exerciseId } = this.props.match.params
    return <Results householdFinId={householdFinId} exerciseId={exerciseId} />
  }
  public render() {
    const { match, exercises, questions, location } = this.props
    if (exercises && questions) {
      return (
        <div className='ivf-w'>
          <Header />
          <InnerContainer>
            <Switch>
              <Route path={`${match.url}`} component={Welcome} exact={true} />
              <Route
                path={`${match.url}/priorities`}
                render={this.investmentAttributes}
                exact={true}
              />
              <Route
                path={`${match.url}/priorities/summary`}
                render={this.priorityQuestionSummary}
              />
              <Route
                path={`${match.url}/priorities/results`}
                render={this.priorityQuestionResults}
              />
              <Route
                path={`${match.url}/priorities/geography`}
                render={this.globalPriority}
              />
              <Route
                path={`${match.url}/priorities/:questionNumber`}
                render={this.priorityQuestions}
              />
              <Route
                path={`${match.url}/allocation`}
                render={this.allocation}
              />
              <Route path={`${match.url}/results`} render={this.results} />
            </Switch>
            <Disclosure routerLocation={location.pathname} />
          </InnerContainer>
          <Footer />
        </div>
      )
    } else return <PageNotFound />
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    exercises:
      store.investmentViewfinder.exercises[match.params.householdFinId],
    questions: store.investmentViewfinder.questions,
    location: store.routerReducer.location
  }
}

export default connect(mapStateToProps)(InvestmentViewfinder)
