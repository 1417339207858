import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

interface AllocationGraphProps {
  equity: number
  fix: number
  cash: number
  alt: number
  unclassified: number
}

export class AllocationGraph extends Component<AllocationGraphProps> {
  public render() {
    const { equity, fix, cash, alt, unclassified } = this.props
    if (unclassified !== undefined && unclassified !== 100) {
      return (
        <div className='inv-accounts__allocation-graph'>
          <span
            data-tip={Math.round(equity) + '% Equity'}
            className='inv-accounts__allocation-graph-item inv-accounts__allocation-graph-item--equity'
            style={{ width: equity + '%' }}>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          </span>
          <span
            data-tip={Math.round(fix) + '% Fixed Income'}
            className='inv-accounts__allocation-graph-item inv-accounts__allocation-graph-item--fix'
            style={{ width: fix + '%' }}>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          </span>
          <span
            data-tip={Math.round(cash) + '% Cash'}
            className='inv-accounts__allocation-graph-item inv-accounts__allocation-graph-item--cash'
            style={{ width: cash + '%' }}>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          </span>
          <span
            data-tip={Math.round(alt) + '% Alt'}
            className='inv-accounts__allocation-graph-item inv-accounts__allocation-graph-item--alt'
            style={{ width: alt + '%' }}>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          </span>
          <span
            data-tip={Math.round(unclassified) + '% Unclassified'}
            className='inv-accounts__allocation-graph-item inv-accounts__allocation-graph-item--unclassified'
            style={{ width: unclassified + '%' }}>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          </span>
        </div>
      )
    } else {
      return (
        <div className='inv-accounts__allocation-graph'>
          <span
            data-tip={'Unclassified'}
            className='inv-accounts__allocation-graph-item inv-accounts__allocation-graph-item--unclassified'>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
          </span>
        </div>
      )
    }
  }
}

export default AllocationGraph
