import React, { Fragment } from 'react'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'

import ActionDropdown from '../../components/actionDropdown'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg'
import { dateFormat } from '../../helpers'
import { Link } from 'react-router-dom'
import { GlobalState } from '../../../reducers'
import { Dispatch } from 'redux'
import { setActiveHonestConversationExercise } from '../../../actions/households'
import { deleteHonestConversationExercise } from '../../../actions/hcExercises'
import { connect } from 'react-redux'
import HonestConversationSmallCard from './hcSmallCard'
import ConfirmActionModal from '../confirmationModal/confirmActionModal'
import { sortAndFillCards } from '../../helpers/honestConversations'

interface HonestConversationsHistoryRowProps {
  dispatch: Dispatch<GlobalState>
  honestConversation: HonestConversationsExerciseObj
  householdFinId: string
  active: boolean
}

interface HonestConversationsHistoryRowState {
  showDeleteModal: boolean
}

class HonestConversationsHistoryRow extends React.Component<
  HonestConversationsHistoryRowProps,
  HonestConversationsHistoryRowState
> {
  constructor(props: HonestConversationsHistoryRowProps) {
    super(props)
    this.state = {
      showDeleteModal: false
    }
  }

  public cardList = () => {
    const { householdCards } = this.props.honestConversation
    const displayCards = sortAndFillCards(householdCards)
    return displayCards.map((card, index) => {
      const cardTitle = (card && card.title) || null
      const cardType = (card && card.category) || 'null'

      return (
        <div key={index}>
          <HonestConversationSmallCard
            cardTitle={cardTitle}
            cardType={cardType}
            withTooltip={!card}
          />
        </div>
      )
    })
  }
  public setActive() {
    return (
      <Fragment>
        <CheckIcon />
        <span className='action-dropdown__item-label'>Set as Active</span>
      </Fragment>
    )
  }
  public deleteRecord() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete Record</span>
      </Fragment>
    )
  }

  public toggleSetActive = () => {
    const { householdFinId, honestConversation } = this.props
    this.props.dispatch(
      setActiveHonestConversationExercise(householdFinId, honestConversation.id)
    )
  }
  public toggleDeleteRecord = async () => {
    const { householdFinId, honestConversation } = this.props
    await this.props.dispatch(
      deleteHonestConversationExercise(householdFinId, honestConversation.id)
    )
    this.closeDeleteModal()
  }
  public render() {
    const { honestConversation, active, householdFinId } = this.props
    const formattedDate = honestConversation.createdDate
      ? dateFormat(honestConversation.createdDate)
      : null
    const { showDeleteModal } = this.state
    const dropdownActions = [
      {
        element: this.setActive(),
        onClick: this.toggleSetActive
      },
      {
        element: this.deleteRecord(),
        onClick: this.showDeleteModal
      }
    ]
    return (
      <Fragment>
        <Link
          className='hc-history__results-w'
          to={`/households/${householdFinId}/honestConversations/meetings/${honestConversation.id}`}>
          <span className='hc-history__results-active'>
            {
              <span
                className={
                  active
                    ? 'hc-history__active-badge hc-history__active-badge--active'
                    : 'hc-history__active-badge'
                }
              />
            }
          </span>
          <span className='hc-history__results-results'>{this.cardList()}</span>
          <span className='hc-history__results-updated'>
            <span className='hc-history__results-updated-date'>
              {formattedDate}
            </span>
          </span>
          <span className='hc-history__results-actions'>
            <ActionDropdown actions={dropdownActions} />
          </span>
        </Link>
        {showDeleteModal ? (
          <ConfirmActionModal
            title='Delete Record'
            message='Are you sure you want to delete this record?'
            confirmFunc={this.toggleDeleteRecord}
            closeFunc={this.closeDeleteModal}
          />
        ) : null}
      </Fragment>
    )
  }
  private showDeleteModal = () => {
    this.setState({
      showDeleteModal: true
    })
  }
  private closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    })
  }
}

export default connect()(HonestConversationsHistoryRow)
