import React from 'react'

interface BarGraphProps {
  color: string
  heightPercent: number
}

class BarGraph extends React.Component<BarGraphProps> {
  public render() {
    const { color, heightPercent } = this.props
    return (
      <div className='ivf-priorities__bar-w'>
        <div
          className='ivf-priorities__bar'
          style={{ backgroundColor: color, height: `${heightPercent}%` }}
        />
      </div>
    )
  }
}

export default BarGraph
