import { Dispatch } from 'react-redux'
import { GlobalState } from '../../reducers'
import { isObjEmpty } from '.'
import {
  PortfolioBuilderInterface,
  PortfolioBuilderAccountInterface,
  PortfolioBuilderObj
} from '../../objects/portfolioBuilder'
import { PositionsResponseObj } from '../../objects/positions'
import { addToast } from '../../actions/positions'
import { BenchmarkObj } from '../../objects/institution'

type AllocationDetails<T extends any> = T & {
  targetEquityPercent: number
  targetFixedPercent: number
  targetCashPercent: number
  targetAltPercent: number
  portfolioValue: number
  proposedTotalValue: number
  yieldData: number
  expenseRatio: number
  fees: number
  equity: number
  fixed: number
  cash: number
  otherValue: number
  Unclassified: number
  currentAccounts: PortfolioBuilderAccountInterface
  proposedAccounts: PortfolioBuilderAccountInterface
  proposedProgress: number
}

const emptyAllocationDetailsObj = (): AllocationDetails<any> => {
  return {
    targetEquityPercent: 0,
    targetFixedPercent: 0,
    targetCashPercent: 0,
    targetAltPercent: 0,
    portfolioValue: 0,
    proposedTotalValue: 0,
    yieldData: 0,
    expenseRatio: 0,
    fees: 0,
    equity: 0,
    fixed: 0,
    cash: 0,
    otherValue: 0,
    Unclassified: 0,
    currentAccounts: null,
    proposedAccounts: null,
    proposedProgress: 0
  }
}

const getPortfolioValue = (
  accountsCollection: PortfolioBuilderAccountInterface
): number => {
  if (!accountsCollection) {
    return 0
  }

  return (
    Object.keys(accountsCollection).reduce((sum: number, key: string) => {
      const { accountValue } = accountsCollection[key]
      return sum + accountValue
    }, 0) || 0
  )
}

const getFieldSum = (
  fieldName: string,
  accountsCollection: PortfolioBuilderAccountInterface,
  portfolioValue?: number
) => {
  return (
    Object.keys(accountsCollection).reduce((sum: number, key: string) => {
      const account = { ...accountsCollection[key] }
      const { accountValue } = account
      if (portfolioValue) {
        return sum + account[fieldName] * (accountValue / portfolioValue)
      }
      return sum + account[fieldName]
    }, 0) || 0
  )
}

const mathRounder = (numValue: number): number => {
  return Math.round(numValue * 100) / 100
}

const parseFloater = (numValue: number, divisible: number): number => {
  return parseFloat(((numValue / divisible) * 100).toFixed(2)) || 0
}

export const generatePortfolioBuilderData = (
  portfolioBuilderData: PortfolioBuilderInterface
) => {
  const allocationDataSet = {}
  !isObjEmpty(portfolioBuilderData) &&
    Object.keys(portfolioBuilderData).forEach((id: string) => {
      const portfolioDataSet: AllocationDetails<PortfolioBuilderObj> = {
        ...emptyAllocationDetailsObj(),
        ...portfolioBuilderData[id]
      }
      const { currentAccounts, proposedAccounts } = portfolioDataSet
      //
      const portfolioValue = getPortfolioValue(currentAccounts)
      const proposedTotalValue = getPortfolioValue(proposedAccounts)
      //
      const yieldData = mathRounder(
        getFieldSum('yield', proposedAccounts, portfolioValue)
      )
      const expenseRatio = mathRounder(
        getFieldSum('expenseRatio', proposedAccounts, portfolioValue)
      )
      const fees = mathRounder(
        getFieldSum('strategyFee', proposedAccounts, portfolioValue)
      )
      //
      const equity = parseFloater(
        getFieldSum('currentEquityValue', proposedAccounts),
        proposedTotalValue
      )
      const cash = parseFloater(
        getFieldSum('currentCashValue', proposedAccounts),
        proposedTotalValue
      )
      const otherValue = parseFloater(
        getFieldSum('currentOtherValue', proposedAccounts),
        proposedTotalValue
      )
      const fixed = parseFloater(
        getFieldSum('currentFixedValue', proposedAccounts),
        proposedTotalValue
      )
      const Unclassified = parseFloater(
        getFieldSum('currentUnclassifiedValue', proposedAccounts),
        proposedTotalValue
      )

      allocationDataSet[id] = {
        ...portfolioDataSet,
        portfolioValue,
        proposedTotalValue,
        yieldData,
        expenseRatio,
        fees,
        equity,
        cash,
        otherValue,
        fixed,
        Unclassified
      }
    })

  return allocationDataSet
}

export const portfolioProposalsBenchmarks = (allocationDataSet: unknown) => {
  const benchmarks: any[] = []
  Object.keys(allocationDataSet).forEach((key: string) => {
    const {
      id,
      name,
      lastModified,
      equity: equityAllocation,
      fixed: fixedAllocation,
      cash: cashAllocation,
      otherValue: alternativeAllocation,
      Unclassified: otherAllocation
    } = allocationDataSet[key]

    const totalSum = [
      equityAllocation,
      fixedAllocation,
      cashAllocation,
      alternativeAllocation,
      otherAllocation
    ].reduce((sum: number, item: number) => {
      return sum + item
    })

    totalSum > 0 &&
      name?.length &&
      benchmarks.push({
        id,
        name,
        lastModified,
        equityAllocation,
        fixedAllocation,
        cashAllocation,
        alternativeAllocation,
        otherAllocation
      })
  })

  return benchmarks
}

export const filterIsgBenchmarks = (benchmarks: BenchmarkObj[]) => {
  return benchmarks.filter(
    (benchmark: BenchmarkObj) =>
      benchmark.equityAllocation || benchmark.fixedAllocation
  )
}

// acceptable fields for upload, pre-defined order is expected.
const IM_POSITIONS_FIELDS = [
  'Symbol',
  'Security Description',
  'Quantity',
  'Total Value'
]

/**
 * Provides a sample template for download
 */
export const samplePositionsTemplate = () => {
  const csv = `Statement Date, MM/DD/YYYY\r\n${IM_POSITIONS_FIELDS.join(
    ','
  )}\r\nPosition Symbol, Short Description, 0, 0`
  return `data:text/csv;chartset=utf-8,${encodeURIComponent(csv)}`
}

/**
 * Provides data for upload
 * @param data
 * data : csv
 */
export const positionsPayload = (csv: string): string => {
  const [statementDate, positionsFields, ...positions] = csv.split(/\r\n|\n/)
  const fieldsArray = positionsFields.split(',')
  const requiredFields = fieldsArray.map((field: string) => field.trim())
  const lineItems = positions
    .filter((position: string) => position.length)
    .map((position: string) => {
      return `${position.trim()}`
    })
    .join('\n')

  if (!statementDate) {
    return ''
  }

  const statementDateLine = statementDate
    .split(',')
    .filter((segment: string) => segment)
    .join(',')

  return `${statementDateLine.trim()}\n${requiredFields
    .join(',')
    .trim()}\n${lineItems}`
}

/**
 * Positions CSV validator
 */
const statementDateValidation = (data: string[]): PositionsResponseObj => {
  const errorMessage: PositionsResponseObj = {
    status: 0,
    type: ['/errors/unparseable-request-body'],
    message: 'Missing or invalid Statement Date.',
    detail: 'Please provide Statement Date.'
  }

  const multipleFieldInstances = (): boolean => {
    const dataFields = [...data]
    const sorted = dataFields.sort().filter((item: string) => {
      return item
        .toLowerCase()
        .replace(/\s+/g, '')
        .startsWith('statementdate')
    })
    return sorted.length === 1
  }

  const multipleValueInstances = (): number => {
    const dataValue = [...data]
    const dataArray = dataValue[0].split(',')
    const elements = dataArray.filter((element: string) => element.trim())

    if (elements[0].trim() !== 'Statement Date' || !dataArray[1]?.length) {
      return 0
    }

    return elements.length
  }

  const countValueInstances = multipleValueInstances()
  if (countValueInstances > 2) {
    return {
      ...errorMessage,
      detail:
        'Multiple Statement Dates detected. Please enter one Statement Date in the designated field, with MM/DD/YYYY format, and try again.'
    }
  }
  if (countValueInstances < 2) {
    return {
      ...errorMessage,
      detail:
        'Please enter Statement Date in the designated field, with MM/DD/YYYY format, and try again.'
    }
  }

  if (!multipleFieldInstances()) {
    return {
      ...errorMessage,
      detail:
        'Multiple Statement Dates detected. Please enter one Statement Date in the designated field, with MM/DD/YYYY format, and try again.'
    }
  }

  const [field = '', value = ''] = data[0]
    .split(',')
    .filter((element: string) => element.trim())
  const fieldString = field.trim()
  const valueString = value.trim()

  if (fieldString !== 'Statement Date') return errorMessage
  if (
    !new RegExp(
      '^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2})*$'
    ).test(valueString) ||
    Object.is(Date.parse(valueString), NaN)
  ) {
    return {
      ...errorMessage,
      detail:
        'Invalid Statement Date. Please enter Statement Date as MM/DD/YYYY format, and try again.'
    }
  }

  return null
}

export const positionsFileValidator = (
  csv: string,
  dispatch: Dispatch<GlobalState>
): boolean => {
  const dataArray = csv
    .split(/\r\n|\n/)
    .filter((item) => item.trim().length > 0)

  const errorsList: PositionsResponseObj[] = []
  const errorResponse = {
    response: {
      data: {
        errors: errorsList,
        meta: { statu: 400 }
      }
    }
  }
  const errorObj: PositionsResponseObj = {
    status: 0,
    type: ['/errors/unparseable-request-body'],
    message: '',
    detail: ''
  }

  // empty file
  if (!dataArray.length) {
    errorResponse.response.data.errors.push({
      ...errorObj,
      status: 400,
      message: 'Your file is empty.',
      detail: 'Expected the request to be parseable as text/csv.'
    })
    dispatch(addToast(errorResponse))
    return false
  }

  // invalid statement date
  const statementDateError = statementDateValidation(dataArray)
  if (!Object.is(statementDateError, null)) {
    errorResponse.response.data.errors.push(statementDateError)
    dispatch(addToast(errorResponse))
    return false
  }

  return true
}
