import * as actions from '../actions/hcExerciseCards'
import {
  CardOptionsInterface,
  CardObj
} from '../objects/HonestConversations/card'

export const initState: CardOptionsInterface = {
  Happiness: [],
  Protection: [],
  Commitment: []
}

const mapExerciseCards = (cardArray: CardObj[]): CardOptionsInterface => {
  const cardOptions: CardOptionsInterface = {
    Happiness: [],
    Protection: [],
    Commitment: []
  }

  cardArray.map((card) => {
    cardOptions[card.category] && cardOptions[card.category].push(card)
  })

  return cardOptions
}

const HcExerciseCards = (
  state = initState,
  action: any
): CardOptionsInterface => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.GET_HONEST_CONVERSATIONS_CARDS}_FULFILLED`:
      const exerciseCards = mapExerciseCards(action.payload.data)

      return { ...newState, ...exerciseCards }

    default:
      return state
  }
}

export default HcExerciseCards
