import React, { FunctionComponent } from 'react'
import HonestConversationCardWrapper from '../hcCardWrapper'
import { CardObj } from '../../../../objects/HonestConversations/card'
import { ContactsInterface } from '../../../../objects/contact'
import ScoreGraph from '../../../components/graphs/honestConversations/scoreGraph'
import { LifeActionObj } from '../../../../objects/lifeActions'
import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'
import { TaskDescriptionItem } from '../exercise/results/ResultsSummaryComponents'
import {
  getContactAvatars,
  getTileConfig,
  sortByDefault
} from '../../../helpers/goals'
import { GoalActions } from '../../../../objects/financialGoalActions'
const scoreGraphColumn = (score: number) => {
  return (
    <div className='hc-card-row__score'>
      <ScoreGraph score={score} />
    </div>
  )
}

const renderNoCommentCard = ({ card }: { card: CardObj }) => {
  return (
    <div className='hc-card-row__w'>
      <HonestConversationCardWrapper card={card} />
      <div className='hc-card-row__comment'>
        <span className='hc-card-row__comment--null'>No comment added</span>
      </div>
    </div>
  )
}

const renderCommentCard = ({
  card,
  comment
}: {
  card: CardObj
  comment: string
}) => {
  return (
    <div className='hc-card-row__w'>
      <HonestConversationCardWrapper card={card} />
      <div className='hc-card-row__comment'>{comment}</div>
    </div>
  )
}

export const HonestConversationCardRow = ({
  card,
  comment
}: {
  card: CardObj
  comment: string
}) => {
  if (comment) {
    return renderCommentCard({ card, comment })
  } else if (card) {
    return renderNoCommentCard({ card })
  } else {
    return null
  }
}

export const HonestConversationCombinedCardRow: FunctionComponent<{
  card: CardObj
  contacts: ContactsInterface
  lifeActions: GoalActions[]
  primaryCardScore: number
  secondaryCardScore: number
  displayIndex?: number
  honestConversation: HonestConversationsExerciseObj
}> = ({
  card,
  contacts,
  lifeActions,
  primaryCardScore,
  secondaryCardScore,
  displayIndex,
  honestConversation
}) => {
  // sort goals
  const lineItems = lifeActions?.length
    ? sortByDefault(getTileConfig(lifeActions, honestConversation))
    : []

  const taskDescriptionItems = lineItems.length
    ? lineItems.map((item: LifeActionObj, index: number) => {
        const contactAvatars = getContactAvatars(
          item.assigneePrimaryId,
          item.assigneeSecondaryId,
          contacts
        )

        return (
          <TaskDescriptionItem
            key={index}
            index={index}
            longDescription={item.name}
            avatars={contactAvatars}
          />
        )
      })
    : null

  return (
    <div className='hc-card-row__w hc-card-row__w--no-padding hc-card-row__w--border'>
      <HonestConversationCardWrapper card={card} displayIndex={displayIndex} />

      {contacts?.secondary?.id && scoreGraphColumn(secondaryCardScore)}
      {scoreGraphColumn(primaryCardScore)}

      <div className='hc-card-row__tasks'>{taskDescriptionItems}</div>
    </div>
  )
}
