import React from 'react'

export const PositionToastMessage: React.FC<{
  index: number
  status: number
  message: string
  detail: string
  errorItems: string[]
  removeToast(index: number): void
}> = ({ status }) => {
  return (
    <div className='im-toaster__container'>
      <div
        className={
          status !== 200
            ? `im-toaster__container-inner im-toaster__container-inner--error`
            : `im-toaster__container-inner`
        }></div>
    </div>
  )
}
