import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import _ from 'lodash'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion'

import { GlobalState } from '../../../../reducers'
import { ClientAccountObjState } from '../../../../objects/clientAccount'
import {
  PortfolioBuilderAccountObj,
  PortfolioBuilderObj,
  PortfolioBuilderAccountInterface
} from '../../../../objects/portfolioBuilder'
import { StrategiesInterface } from '../../../../objects/strategies'
import ActionDropdown from '../../../components/actionDropdown'

import SectorWeightingsGraph from './graphs/SectorWeightingsGraph'
import StrategyRecGraph from './graphs/strategyRecGraph'
import MaxDefGraph from './graphs/maxDefGraph'
import CreditWeightingsGraph from './graphs/creditWeightingsGraph'
import GeographicGraph from './graphs/geographicGraph'
import AllocationGraph from './graphs/allocationGraph'

import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg'

interface TargetState {
  openModal: boolean
  errStatus: boolean
  filteredAccounts: ClientAccountObjState
  selected: string
  openViewDetailsDialog: boolean
  targetAllocationData: {
    portfolioValue: number
    proposedTotalValue: number
    yieldData: number
    expenseRatio: number
    fees: number
    equity: number
    fixed: number
    cash: number
    otherValue: number
    Unclassified: number
    currentAccounts: PortfolioBuilderAccountInterface
    proposedAccounts: PortfolioBuilderAccountInterface
    targetEquityPercent: number
    targetFixedPercent: number
    targetCashPercent: number
    targetAltPercent: number
    proposedProgress: number
  }
  maxDefensiveData: {
    portfolioValue: number
    equity: number
    fixed: number
    cash: number
    alt: number
    Unclassified: number
    currentAccounts: PortfolioBuilderAccountInterface
    proposedAccounts: PortfolioBuilderAccountInterface
  }
  strategies: StrategiesInterface
  strategyRecommData: {
    recommStrategies: any[]
    proposedStrategyPercents: number[]
    currentStrategyPercents: number[]
  }
  geographicWeightings: {
    graphData: object
  }
  sectorWeightings: {
    graphData: object
  }
  creditWeightings: {
    graphData: object
  }
}

interface TargetAllocationProps {
  dispatch: Dispatch<GlobalState>
  portfolioBuilderData: PortfolioBuilderObj
  strategies: StrategiesInterface
  portfolioBuilderId: string
  householdFinId: string
}

class TargetAllocationTile extends React.Component<
  TargetAllocationProps,
  TargetState
> {
  constructor(props: TargetAllocationProps) {
    super(props)
    this.state = {
      selected: 'Allocation',
      openModal: false,
      errStatus: false,
      filteredAccounts: null,
      targetAllocationData: {
        targetEquityPercent: 0,
        targetFixedPercent: 0,
        targetCashPercent: 0,
        targetAltPercent: 0,
        portfolioValue: 0,
        proposedTotalValue: 0,
        yieldData: 0,
        expenseRatio: 0,
        fees: 0,
        equity: 0,
        fixed: 0,
        cash: 0,
        otherValue: 0,
        Unclassified: 0,
        currentAccounts: null,
        proposedAccounts: null,
        proposedProgress: 0
      },
      maxDefensiveData: {
        portfolioValue: 0,
        equity: 0,
        fixed: 0,
        cash: 0,
        alt: 0,
        Unclassified: 0,
        currentAccounts: null,
        proposedAccounts: null
      },
      openViewDetailsDialog: false,
      strategies: null,
      strategyRecommData: {
        recommStrategies: [],
        proposedStrategyPercents: [],
        currentStrategyPercents: []
      },
      geographicWeightings: {
        graphData: {}
      },
      sectorWeightings: {
        graphData: {}
      },
      creditWeightings: {
        graphData: {}
      }
    }
  }

  public componentWillReceiveProps(nextProps: TargetAllocationProps) {
    if (nextProps.portfolioBuilderData !== this.props.portfolioBuilderData) {
      this.setState({ strategies: nextProps.strategies })
      this.generateAllocationData(nextProps)

      const newDetailsMaxDefensive = Object.assign(
        { alt: 0 },
        this.state.targetAllocationData,
        nextProps.portfolioBuilderData
      )
      this.setState({ maxDefensiveData: newDetailsMaxDefensive }, () => {
        if (this.state.strategies !== undefined) {
          this.generateMaxDefensiveData()
        }
      })
    }
  }

  public checkGraphDataArrForNaN(arr: any) {
    if (arr?.length > 0) {
      for (let i = 0; i < arr.length; ++i) {
        const graphDataValue = arr[i][1]
        if (graphDataValue === 'NaN') {
          arr[i][1] = 0
        }
      }
    }
  }

  public makeGraphDataArr(arr: [string, number | string][]) {
    return arr.reduce((acc: any, [key, value]) => {
      acc[key] = value
      return acc
    }, {})
  }

  public generateAllocationData(nextProps: TargetAllocationProps) {
    const newDetailsTargetAllocation = Object.assign(
      {},
      this.state.targetAllocationData,
      nextProps.portfolioBuilderData
    )
    if (!newDetailsTargetAllocation) {
      return
    }
    const { currentAccounts, proposedAccounts } = newDetailsTargetAllocation

    const proposedStrategyAccounts = {}
    const currentStrategyAccounts = {}

    const currentPortfolio = _.reduce(
      _.keys(currentAccounts),
      (previous: number, key: string) => {
        return previous + currentAccounts[key].accountValue
      },
      0
    )

    const proposedPortfolio = _.reduce(
      _.keys(proposedAccounts),
      (previous: number, key: string) => {
        return previous + proposedAccounts[key].accountValue
      },
      0
    )

    const proposedPortfolioEquity = _.reduce(
      _.keys(proposedAccounts),
      (previous: number, key: string) => {
        const hasStrategyWeightings = proposedAccounts[key].strategy
          ? this.props.strategies[proposedAccounts[key].strategy]
              .sectorWeightings
          : false
        return (
          previous +
          (hasStrategyWeightings ? proposedAccounts[key].currentEquityValue : 0)
        )
      },
      0
    )

    const proposedPortfolioFixed = _.reduce(
      _.keys(proposedAccounts),
      (previous: number, key: string) => {
        const hasStrategyWeightings = proposedAccounts[key].strategy
          ? this.props.strategies[proposedAccounts[key].strategy]
              .creditWeightings
          : false
        return (
          previous +
          (hasStrategyWeightings ? proposedAccounts[key].currentFixedValue : 0)
        )
      },
      0
    )

    const sectorWeightingsData = {}
    const sectorWeightingsGraphData = {}
    const geographicWeightingsData = {}
    const geographicWeightingsGraphData = {}
    const creditWeightingsData = {}
    const creditWeightingsGraphData = {}
    const recommStrategies: any[] = []

    _.map(currentAccounts, (value: PortfolioBuilderAccountObj) => {
      if (value.strategy) {
        let sum = value.accountValue
        if (currentStrategyAccounts[value.strategy]) {
          sum = sum + currentStrategyAccounts[value.strategy]
        }
        currentStrategyAccounts[value.strategy] = sum
      }
    })

    _.map(proposedAccounts, (value: PortfolioBuilderAccountObj, key) => {
      if (value.strategy) {
        let sum = value.accountValue
        const equityValue = value.currentEquityValue
        const fixedValue = value.currentFixedValue
        if (_.size(this.props.strategies)) {
          const strategy = this.props.strategies[value.strategy]
          if (strategy) {
            _.map(strategy.sectorWeightings, (val, key) => {
              const total = (sectorWeightingsData[key] || 0) + equityValue * val
              sectorWeightingsData[key] = total
              sectorWeightingsGraphData[key] = (
                total / proposedPortfolioEquity
              ).toFixed(2)
              if (isNaN(sectorWeightingsData?.[key])) {
                sectorWeightingsData[key] = 0
              }
            })
            _.map(strategy.geographicWeightings, (val, key) => {
              const total =
                (geographicWeightingsData[key] || 0) + equityValue * val
              geographicWeightingsData[key] = total
              geographicWeightingsGraphData[key] = (
                total / proposedPortfolioEquity
              ).toFixed(2)
              if (isNaN(geographicWeightingsData?.[key])) {
                geographicWeightingsData[key] = 0
              }
            })
            _.map(strategy.creditWeightings, (val, key) => {
              const total = (creditWeightingsData[key] || 0) + fixedValue * val
              creditWeightingsData[key] = total
              creditWeightingsGraphData[key] = (
                total / proposedPortfolioFixed
              ).toFixed(2)
              if (isNaN(creditWeightingsData?.[key])) {
                creditWeightingsData[key] = 0
              }
            })
          }
        }
        if (proposedStrategyAccounts[value.strategy]) {
          sum = sum + proposedStrategyAccounts[value.strategy]
        }
        proposedStrategyAccounts[value.strategy] = sum
      }
    })

    const proposedStrategyPercents: any[] = Object.keys(
      proposedStrategyAccounts
    ).map((value: any) => {
      return {
        strategy: value,
        name: this.props.strategies[value].name,
        shortName: this.props.strategies[value].shortName,
        amount: proposedStrategyAccounts[value],
        percent: parseFloat(
          ((proposedStrategyAccounts[value] / proposedPortfolio) * 100).toFixed(
            2
          )
        )
      }
    })

    const currentStrategyPercents: any[] = Object.keys(
      currentStrategyAccounts
    ).map((value: any) => {
      return {
        strategy: value,
        name: this.props.strategies[value].name,
        shortName: this.props.strategies[value].shortName,
        percent: parseFloat(
          ((currentStrategyAccounts[value] / currentPortfolio) * 100).toFixed(2)
        )
      }
    })

    const targetAllocationData = newDetailsTargetAllocation
    if (proposedAccounts) {
      let { portfolioValue } = newDetailsTargetAllocation
      newDetailsTargetAllocation.portfolioValue = Object.keys(
        targetAllocationData.currentAccounts
      ).reduce((previous: number, key: string) => {
        return previous + targetAllocationData.currentAccounts[key].accountValue
      }, 0)
      portfolioValue = newDetailsTargetAllocation.portfolioValue

      newDetailsTargetAllocation.proposedTotalValue = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous + targetAllocationData.proposedAccounts[key].accountValue
        )
      }, 0)
      const proposedTotalValue = newDetailsTargetAllocation.proposedTotalValue

      targetAllocationData.yieldData = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          proposedAccounts[key].yield *
            (proposedAccounts[key].accountValue / portfolioValue)
        )
      }, 0)

      targetAllocationData.yieldData =
        Math.round(targetAllocationData.yieldData * 100) / 100

      targetAllocationData.expenseRatio = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          proposedAccounts[key].expenseRatio *
            (proposedAccounts[key].accountValue / portfolioValue)
        )
      }, 0)

      targetAllocationData.expenseRatio =
        Math.round(targetAllocationData.expenseRatio * 100) / 100

      targetAllocationData.fees = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          proposedAccounts[key].strategyFee *
            (proposedAccounts[key].accountValue / portfolioValue)
        )
      }, 0)

      targetAllocationData.fees =
        Math.round(targetAllocationData.fees * 100) / 100

      const equityTemp = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((sum: number, key: string) => {
        return sum + proposedAccounts[key].currentEquityValue
      }, 0)
      targetAllocationData.equity =
        parseFloat(((equityTemp / proposedTotalValue) * 100).toFixed(2)) || 0

      const cashTemp = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((sum: number, key: string) => {
        return sum + proposedAccounts[key].currentCashValue
      }, 0)
      targetAllocationData.cash =
        parseFloat(((cashTemp / proposedTotalValue) * 100).toFixed(2)) || 0

      const otherTemp = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((sum: number, key: string) => {
        return sum + proposedAccounts[key].currentOtherValue
      }, 0)
      targetAllocationData.otherValue =
        parseFloat(((otherTemp / proposedTotalValue) * 100).toFixed(2)) || 0

      const fixedTemp = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((sum: number, key: string) => {
        return sum + proposedAccounts[key].currentFixedValue
      }, 0)
      targetAllocationData.fixed =
        parseFloat(((fixedTemp / proposedTotalValue) * 100).toFixed(2)) || 0

      const unclassfiedTemp = Object.keys(
        targetAllocationData.proposedAccounts
      ).reduce((sum: number, key: string) => {
        return sum + proposedAccounts[key].currentUnclassifiedValue
      }, 0)
      targetAllocationData.Unclassified =
        parseFloat(((unclassfiedTemp / proposedTotalValue) * 100).toFixed(2)) ||
        0

      targetAllocationData.proposedProgress = Object.keys(
        targetAllocationData.proposedAccounts
      )
        .filter((key: string) => {
          return proposedAccounts[key].strategy
        })
        .reduce((sum: number, key: string) => {
          return sum + proposedAccounts[key].accountValue
        }, 0)
    }

    const geographicGraphDataArr: [string, number | string][] = Object.entries(
      geographicWeightingsGraphData
    )
    const sectorGraphDataArr: [string, number | string][] = Object.entries(
      sectorWeightingsGraphData
    )
    const creditGraphDataArr: [string, number | string][] = Object.entries(
      creditWeightingsGraphData
    )

    this.checkGraphDataArrForNaN(geographicGraphDataArr)
    this.checkGraphDataArrForNaN(sectorGraphDataArr)
    this.checkGraphDataArrForNaN(creditGraphDataArr)

    const geographicGraphData = this.makeGraphDataArr(geographicGraphDataArr)
    const sectorGraphData = this.makeGraphDataArr(sectorGraphDataArr)
    const creditGraphData = this.makeGraphDataArr(creditGraphDataArr)

    this.setState({
      targetAllocationData,
      strategyRecommData: {
        ...this.state.strategyRecommData,
        recommStrategies,
        proposedStrategyPercents,
        currentStrategyPercents
      },
      geographicWeightings: {
        ...this.state.geographicWeightings,
        graphData: geographicGraphData
      },
      sectorWeightings: {
        ...this.state.sectorWeightings,
        graphData: sectorGraphData
      },
      creditWeightings: {
        ...this.state.creditWeightings,
        graphData: creditGraphData
      }
    })
  }

  public generateMaxDefensiveData() {
    const { maxDefensiveData } = this.state
    let { portfolioValue } = this.state.maxDefensiveData
    const { proposedAccounts } = this.state.maxDefensiveData

    if (proposedAccounts && this.state.strategies !== undefined) {
      maxDefensiveData.portfolioValue = Object.keys(
        maxDefensiveData.proposedAccounts
      ).reduce((previous: number, key: string) => {
        return (
          previous +
          proposedAccounts[key].currentCashValue +
          proposedAccounts[key].currentEquityValue +
          proposedAccounts[key].currentFixedValue +
          proposedAccounts[key].currentOtherValue +
          proposedAccounts[key].currentUnclassifiedValue
        )
      }, 0)
      portfolioValue = this.state.maxDefensiveData.portfolioValue

      let accountValue = 0
      const findAccountValue = (input: string) => {
        return (
          proposedAccounts[input].currentCashValue +
          proposedAccounts[input].currentEquityValue +
          proposedAccounts[input].currentFixedValue +
          proposedAccounts[input].currentOtherValue +
          proposedAccounts[input].currentUnclassifiedValue
        )
      }
      const findDefensivePosture = (
        key: string,
        allocationType: string,
        currentType: string,
        accountValue: number
      ) => {
        return proposedAccounts[key].strategy === null ||
          this.state.strategies[proposedAccounts[key].strategy] === undefined
          ? proposedAccounts[key][currentType]
          : (this.state.strategies[proposedAccounts[key].strategy]
              .maxDefensivePosture[allocationType] /
              100) *
              accountValue
      }
      const equityTemp = Object.keys(maxDefensiveData.proposedAccounts).reduce(
        (sum: number, key: string) => {
          accountValue = findAccountValue(key)
          return (
            sum +
            findDefensivePosture(
              key,
              'equity',
              'currentEquityValue',
              accountValue
            )
          )
        },
        0
      )
      maxDefensiveData.equity =
        parseFloat(((equityTemp / portfolioValue) * 100).toFixed(2)) || 0

      const fixedTemp = Object.keys(maxDefensiveData.proposedAccounts).reduce(
        (sum: number, key: string) => {
          accountValue = findAccountValue(key)
          return (
            sum +
            findDefensivePosture(
              key,
              'fixed',
              'currentFixedValue',
              accountValue
            )
          )
        },
        0
      )
      maxDefensiveData.fixed =
        parseFloat(((fixedTemp / portfolioValue) * 100).toFixed(2)) || 0

      const cashTemp = Object.keys(maxDefensiveData.proposedAccounts).reduce(
        (sum: number, key: string) => {
          accountValue = findAccountValue(key)
          return (
            sum +
            findDefensivePosture(key, 'cash', 'currentCashValue', accountValue)
          )
        },
        0
      )
      maxDefensiveData.cash =
        parseFloat(((cashTemp / portfolioValue) * 100).toFixed(2)) || 0

      const altTemp = Object.keys(maxDefensiveData.proposedAccounts).reduce(
        (sum: number, key: string) => {
          accountValue = findAccountValue(key)
          return (
            sum +
            findDefensivePosture(key, 'alt', 'currentOtherValue', accountValue)
          )
        },
        0
      )
      maxDefensiveData.alt =
        parseFloat(((altTemp / portfolioValue) * 100).toFixed(2)) || 0

      this.setState({ maxDefensiveData })

      const unclassifiedTemp = Object.keys(
        maxDefensiveData.proposedAccounts
      ).reduce((sum: number, key: string) => {
        accountValue = findAccountValue(key)
        return (
          sum +
          (proposedAccounts[key].strategy === null
            ? proposedAccounts[key].currentUnclassifiedValue
            : 0)
        )
      }, 0)
      maxDefensiveData.Unclassified =
        parseFloat(((unclassifiedTemp / portfolioValue) * 100).toFixed(2)) || 0

      this.setState({ maxDefensiveData })
    }
  }

  public handleViewDetails = () => {
    this.setState({
      openViewDetailsDialog: !this.state.openViewDetailsDialog
    })
  }

  public closeModal = (): void => {
    this.setState({ openModal: false })
  }
  public openDialog = () => {
    this.setState({ openModal: true })
  }

  public handleChange = (inputVals: any) => {
    const {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    } = inputVals
    this.setState({
      targetAllocationData: {
        ...this.state.targetAllocationData,
        targetEquityPercent,
        targetFixedPercent,
        targetCashPercent,
        targetAltPercent
      }
    })
  }

  public dropdownLabel = (label: string) => {
    const className =
      label === this.state.selected
        ? 'action-dropdown__item-label action-dropdown__item-label--selected'
        : 'action-dropdown__item-label'
    return (
      <Fragment>
        <span className={className}>{label}</span>
        {label === this.state.selected ? (
          <div className='client-accounts-tile__filter--selected'>
            <CheckIcon />
          </div>
        ) : null}
      </Fragment>
    )
  }

  public showGraph = () => {
    const chartOptions = {
      animation: { animateRotate: true },
      showTooltips: false,
      responsive: false,
      cutoutPercentage: 75,
      segmentShowStroke: false,
      tooltips: {
        enabled: false
      },
      elements: {
        arc: {
          borderWidth: 2
        }
      }
    }

    const graphColors = [
      '#255eba',
      '#FFBE1A',
      '#FFDE8C',
      '#A2D134',
      'grey',
      '#ffe6e6',
      '#0066ff',
      '#8a5c74',
      '#d6c2cc',
      '#c6ecc6',
      '#80aaff',
      '#330011',
      '#ffb3b3'
    ]
    const { selected } = this.state

    if (selected === 'Allocation') {
      return (
        <AllocationGraph
          openModal={this.state.openModal}
          chartOptions={chartOptions}
          householdFinId={this.props.householdFinId}
          portfolioBuilderId={this.props.portfolioBuilderId}
          targetAllocationData={this.state.targetAllocationData}
          portfolioBuilderData={this.props.portfolioBuilderData}
          closeModal={this.closeModal}
          openDialog={this.openDialog}
          handleChange={this.handleChange}
        />
      )
    } else if (selected === 'Max Defensive') {
      return (
        <MaxDefGraph
          chartOptions={chartOptions}
          maxDefensiveData={this.state.maxDefensiveData}
          targetAllocationData={this.state.targetAllocationData}
        />
      )
    } else if (selected === 'Strategy Recommendation') {
      return (
        <StrategyRecGraph
          chartOptions={chartOptions}
          graphColors={graphColors}
          strategyRecommData={this.state.strategyRecommData}
          openViewDetailsDialog={this.state.openViewDetailsDialog}
          handleViewDetails={this.handleViewDetails}
        />
      )
    } else if (selected === 'Geographic Weightings') {
      return (
        <GeographicGraph
          chartOptions={chartOptions}
          graphColors={graphColors}
          geographicWeightings={this.state.geographicWeightings}
        />
      )
    } else if (selected === 'Sector Weightings') {
      return (
        <SectorWeightingsGraph
          chartOptions={chartOptions}
          graphColors={graphColors}
          sectorWeightings={this.state.sectorWeightings}
        />
      )
    } else if (selected === 'Credit Quality') {
      return (
        <CreditWeightingsGraph
          chartOptions={chartOptions}
          graphColors={graphColors}
          creditWeightings={this.state.creditWeightings}
        />
      )
    } else {
      return (
        <AllocationGraph
          openModal={this.state.openModal}
          chartOptions={chartOptions}
          householdFinId={this.props.householdFinId}
          portfolioBuilderId={this.props.portfolioBuilderId}
          targetAllocationData={this.state.targetAllocationData}
          portfolioBuilderData={this.props.portfolioBuilderData}
          closeModal={this.closeModal}
          openDialog={this.openDialog}
          handleChange={this.handleChange}
        />
      )
    }
  }

  public handleDropdownAction = (type: string) => {
    this.setState({ selected: type })
  }

  public render() {
    const actions = [
      {
        element: this.dropdownLabel('Allocation'),
        onClick: () => this.handleDropdownAction('Allocation')
      },
      {
        element: this.dropdownLabel('Max Defensive'),
        onClick: () => this.handleDropdownAction('Max Defensive')
      },
      {
        element: this.dropdownLabel('Strategy Recommendation'),
        onClick: () => this.handleDropdownAction('Strategy Recommendation')
      },
      {
        element: this.dropdownLabel('Geographic Weightings'),
        onClick: () => this.handleDropdownAction('Geographic Weightings')
      },
      {
        element: this.dropdownLabel('Sector Weightings'),
        onClick: () => this.handleDropdownAction('Sector Weightings')
      },
      {
        element: this.dropdownLabel('Credit Quality'),
        onClick: () => this.handleDropdownAction('Credit Quality')
      }
    ]
    if (this.props.portfolioBuilderData) {
      return (
        <div className='proposal-work__accordion-w'>
          <Accordion>
            <AccordionItem expanded={true}>
              <div className='proposal-work__title proposal-work__title--border'>
                <AccordionItemTitle>
                  <h3 className='u-position-relative title-text proposal-work__title-text'>
                    PROPOSED PORTFOLIO {this.state.selected.toUpperCase()}
                    <div className='accordion__arrow proposal-work__title-arrow' />
                  </h3>
                </AccordionItemTitle>
                <div style={{ height: 36, width: 36 }}>
                  <ActionDropdown actions={actions} />
                </div>
              </div>
              <AccordionItemBody
                className='proposal-work__accordion'
                expanded={false}>
                <div className='proposal-work__proposed-w'>
                  {this.showGraph()}
                </div>
              </AccordionItemBody>
            </AccordionItem>
          </Accordion>
        </div>
      )
    } else {
      return <div />
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    portfolioBuilderId: match.params.portfolioBuilderId,
    proposedValue: store.accountMap[match.params.householdFinId],
    portfolioBuilderData:
      store.portfolioBuilder[match.params.householdFinId] &&
      store.portfolioBuilder[match.params.householdFinId][
        match.params.portfolioBuilderId
      ],
    strategies: store.strategies[match.params.householdFinId]
  }
}
export default withRouter(connect(mapStateToProps)(TargetAllocationTile))
