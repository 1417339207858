import React from 'react'

interface FieldProps {
  autoFocus: boolean
  value: string
  onBlur(
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ): void
}

interface FieldState {
  value: string
}
export default class Field extends React.Component<FieldProps, FieldState> {
  private ref: React.RefObject<HTMLInputElement>
  constructor(props: FieldProps) {
    super(props)
    this.state = {
      value: this.props.value
    }

    this.ref = React.createRef()
  }
  public componentDidMount() {
    this.ref && this.ref.current.focus()
  }

  public handleChange = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.currentTarget.value
    this.setState({ value })
  }

  public render() {
    return (
      <input
        ref={this.ref}
        type='text'
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
      />
    )
  }
}
