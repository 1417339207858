import React, { Component } from 'react'
import FinancialCharts from '../../../../components/graphs/financialCharts'
import Button from '../../../../components/button'
import { DotOval } from '../../dotOval'
import { dollarFormat } from '../../../../helpers'
import TargetAllocationModal from '../targetAllocationModal'

import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'

interface AllocationGraphProps {
  openModal: boolean
  chartOptions: any
  householdFinId: string
  portfolioBuilderId: string
  targetAllocationData: any
  portfolioBuilderData: any
  closeModal(): void
  openDialog(): void
  handleChange(event: React.FormEvent<HTMLInputElement>): void
}

interface AllocationGraphState {
  modalType: string
}

class AllocationGraph extends Component<
  AllocationGraphProps,
  AllocationGraphState
> {
  constructor(props: AllocationGraphProps) {
    super(props)
    this.state = {
      modalType: ''
    }
  }

  public handleModalToggle = (modalType: string) => () => {
    this.setState({ modalType })
    this.props.openDialog()
  }

  public actualGraph = () => {
    const { chartOptions } = this.props
    const {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    } = this.props.portfolioBuilderData

    const targetUnclassifiedPercent =
      100 -
      (targetAltPercent +
        targetCashPercent +
        targetEquityPercent +
        targetFixedPercent)

    const chartData = {
      datasets: [
        {
          data: [
            targetEquityPercent,
            targetFixedPercent,
            targetCashPercent,
            targetAltPercent,
            targetUnclassifiedPercent
          ],
          backgroundColor: ['#255eba', '#FFBE1A', '#FFDE8C', '#A2D134', 'grey']
        }
      ]
    }

    if (
      targetAltPercent ||
      targetCashPercent ||
      targetEquityPercent ||
      targetFixedPercent
    ) {
      return (
        <div className='proposed-portfolio__graph'>
          <div className='proposed-portfolio__graph-relative'>
            <FinancialCharts
              canvasId='proposed-target-allocation'
              chartData={chartData}
              chartOptions={chartOptions}
              dimensions={{ height: 100, width: 100 }}
            />

            <div className='proposed-portfolio__graph-target'>
              <p>TARGET</p>
            </div>
          </div>
          <div className='proposed-portfolio__graph-data-point'>
            <DotOval
              backgroundColor='#255EBA'
              title='Equity'
              value={targetEquityPercent + '%'}
            />
            <DotOval
              backgroundColor='#FFBE1A'
              title='Fixed'
              value={targetFixedPercent + '%'}
            />
            <DotOval
              backgroundColor='#FFDE8C'
              title='Cash'
              value={targetCashPercent + '%'}
            />
            <DotOval
              backgroundColor='#A2D134'
              title='Alternatives'
              value={targetAltPercent + '%'}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className='proposed-portfolio__graph proposed-portfolio__graph__no-data'>
          <div className='proposed-portfolio__graph__no-data-heading'>
            No target allocation data
          </div>
          <div className='proposed-portfolio__graph__no-data-desc'>
            Please click to add a target allocation
          </div>
          <div className='proposed-portfolio__graph__no-data-btn'>
            <Button primary={true} onClick={this.handleModalToggle('add')}>
              Add Target
            </Button>
          </div>
        </div>
      )
    }
  }

  public proposedGraph = () => {
    const { targetAllocationData, chartOptions } = this.props
    const {
      equity,
      fixed,
      cash,
      otherValue,
      Unclassified
    } = targetAllocationData

    const chartDataColors = [
      '#255EBA',
      '#FFBE1A',
      '#FFDE8C',
      '#A2D134',
      '#a4a4a4'
    ]
    const chartData = {
      datasets: [
        {
          data: [equity, fixed, cash, otherValue, Unclassified],
          backgroundColor: chartDataColors
        }
      ]
    }
    const proposedSum = chartData.datasets[0].data.reduce(
      (total, proposedValue) => {
        return total + proposedValue
      }
    )
    // hide
    if (proposedSum === 0) {
      chartData.datasets = [
        {
          data: [100],
          backgroundColor: ['rgba(255,255,255,0)']
        }
      ]
    }
    return (
      <div className='proposed-portfolio__graph'>
        <div className='proposed-portfolio__graph-relative'>
          <FinancialCharts
            canvasId='proposed-portfolio-allocation'
            chartData={chartData}
            chartOptions={chartOptions}
            dimensions={{ height: 100, width: 100 }}
          />

          <div className='proposed-portfolio__graph-target proposed-portfolio__graph-target-null'>
            <p>PROPOSED</p>
          </div>
        </div>
        <div className='proposed-portfolio__graph-data-point'>
          <DotOval
            backgroundColor='#255EBA'
            title='Equity'
            value={equity + '%'}
          />
          <DotOval
            backgroundColor='#FFBE1A'
            title='Fixed'
            value={fixed + '%'}
          />
          <DotOval backgroundColor='#FFDE8C' title='Cash' value={cash + '%'} />
          <DotOval
            backgroundColor='#A2D134'
            title='Alternatives'
            value={otherValue + '%'}
          />
          <DotOval
            backgroundColor='#a4a4a4'
            title='Unclassified'
            value={Unclassified + '%'}
          />
        </div>
      </div>
    )
  }

  public getWidth = () => {
    const { proposedProgress, portfolioValue } = this.props.targetAllocationData
    return (proposedProgress / portfolioValue) * 100
  }

  public calcShowEdit = () => {
    const {
      targetEquityPercent,
      targetFixedPercent,
      targetCashPercent,
      targetAltPercent
    } = this.props.targetAllocationData
    return (
      targetEquityPercent +
        targetFixedPercent +
        targetCashPercent +
        targetAltPercent ===
      0
    )
  }

  public render() {
    const {
      targetAllocationData,
      closeModal,
      handleChange,
      openModal,
      householdFinId,
      portfolioBuilderId
    } = this.props
    const {
      yieldData,
      expenseRatio,
      fees,
      proposedProgress,
      portfolioValue
    } = targetAllocationData
    const progressWidth = this.getWidth()
    const showEdit = this.calcShowEdit()
    return (
      <div className='proposed-portfolio__graph-container'>
        <div className='proposed-portfolio__graph-wrapper'>
          <div>
            {!showEdit ? (
              <div className='proposed-portfolio__graph-wrapper-edit'>
                <EditIcon onClick={this.handleModalToggle('edit')} />
              </div>
            ) : null}
            {this.actualGraph()}
          </div>
          <div className='proposed-portfolio__graph__divider-wrapper'>
            <div
              style={{ height: '100%' }}
              className='proposed-portfolio__graph__divider'
            />
          </div>
          <div>
            <div className='proposed-portfolio__ratio-wrapper'>
              <div className='proposed-portfolio__ratio-data'>{`Yield ${yieldData}%`}</div>
              <div className='proposed-portfolio__ratio-data'>
                Exp Ratio {expenseRatio}%
              </div>
              <div
                style={{ marginRight: '0px' }}
                className='proposed-portfolio__ratio-data'>
                Fee {(fees / 100).toFixed(2)}%
              </div>
            </div>
            {this.proposedGraph()}
          </div>
        </div>
        <div className='proposed-portfolio__balance'>
          <span className='proposed-portfolio__balance-bold'>
            {dollarFormat(Math.round(proposedProgress), 0)}
          </span>{' '}
          of
          <span className='proposed-portfolio__balance-bold'>
            {dollarFormat(Math.round(portfolioValue), 0)}
          </span>
          Proposed
        </div>
        <div className='proposed-portfolio__progress-bar'>
          <div
            style={{
              width: progressWidth > 100 ? '100%' : `${progressWidth}%`
            }}
            className='proposed-portfolio__progress-bar-active'
          />
        </div>
        {openModal ? (
          <TargetAllocationModal
            modalType={this.state.modalType}
            householdFinId={householdFinId}
            portfolioBuilderId={portfolioBuilderId}
            targetAllocationData={targetAllocationData}
            closeModal={closeModal}
            handleChange={handleChange}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default AllocationGraph
