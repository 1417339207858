import * as React from 'react'
import ReactTooltip from 'react-tooltip'
export interface InputProps {
  placeholder?: string
  percentageSign?: boolean
  dollarSign?: boolean
  readOnly?: boolean
  isChanged?: boolean
  classNames?: string
  mandatory?: boolean
  error?: string
  title: string
  name?: string
  inputType: 'text' | 'number' | 'email' | 'tel' | 'password'
  content: string | number
  maxLength?: number
  reference?: React.RefObject<HTMLInputElement>
  max?: number
  min?: number
  textView?: boolean
  onClick?(e: React.FormEvent<HTMLInputElement>): void
  controlFunc?(e: React.FormEvent<HTMLInputElement>): void
  onBlur?(e: React.FormEvent<HTMLInputElement>): void
}

class Input extends React.Component<InputProps> {
  public render() {
    const {
      title,
      name,
      inputType,
      content,
      controlFunc,
      onBlur,
      placeholder,
      dollarSign,
      percentageSign,
      readOnly,
      isChanged,
      error,
      mandatory,
      classNames,
      maxLength,
      reference,
      textView = false,
      onClick,
      min,
      max
    } = this.props
    const prefixClass = () => {
      if (dollarSign) {
        return 'form-input__money'
      } else if (percentageSign) {
        return 'form-input__percentage'
      } else {
        return ''
      }
    }
    const titleDisplay = () => {
      if (mandatory) {
        return (
          <a data-tip='This is a mandatory field'>
            <ReactTooltip place='top' type='dark' effect='solid' />
            {title}
          </a>
        )
      }
      return title
    }
    const prefixInput = () => {
      const readOnlyStyle = `${
        this.props.readOnly ? 'form-input__readonly' : ''
      }`
      if (dollarSign || percentageSign) {
        return `form-input__padding ${readOnlyStyle}`
      } else {
        return `${readOnlyStyle}`
      }
    }
    const isChangedStyle = () => {
      if (isChanged) {
        return `${prefixInput()} form-input__changed`
      }
      if (error) {
        return `${prefixInput()} form-input__error`
      }
      return `${prefixInput()}`
    }
    return (
      <div className='form-group'>
        {title ? (
          <label
            htmlFor={name}
            className={
              mandatory === true
                ? 'form-label form-label_mandatory'
                : 'form-label'
            }>
            {titleDisplay()}
          </label>
        ) : null}
        <span className={prefixClass()}>
          {textView ? (
            <span className='form-label'>
              {content || content === 0 ? content : ''}
            </span>
          ) : (
            <input
              className={`form-input ${isChangedStyle()} ${classNames}`}
              name={name}
              type={inputType}
              maxLength={inputType === 'tel' ? 16 : maxLength || null}
              value={content || content === 0 ? content : ''}
              onChange={controlFunc}
              placeholder={placeholder}
              onBlur={onBlur}
              readOnly={readOnly}
              required={true}
              ref={reference}
              onClick={onClick}
              max={max}
              min={min}
            />
          )}
        </span>
        {error ? (
          <div className='form-group__error-box'>
            <span className='form-input-Error form-input-Error__message'>
              {error}
            </span>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Input
