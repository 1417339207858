import React, { Component, ReactElement } from 'react'

import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { removeToast } from '../../../actions/positions'

import ucLogo from '../../assets/images/logos/United-Capital-Logo-230x80.png'
import { ClientAccountObjState } from '../../../objects/clientAccount'

import ImToast from './toast'
import { PositionToastMessage } from './messages'

interface HeaderProps {
  dispatch: Dispatch<GlobalState>
  clientAccountId: string
  clientAccounts: ClientAccountObjState
  positions: any
  top?: ReactElement
  bottom?: ReactElement
}

interface HeaderState {
  scrollLocation: number
}

class Header extends Component<HeaderProps, HeaderState> {
  public state: HeaderState = {
    scrollLocation: 0
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  public componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  public handleScroll = (event: any) => {
    this.setState({
      scrollLocation: event.target.scrollingElement.scrollTop
    })
  }

  public clearToast = (id: number) => {
    const { dispatch } = this.props
    dispatch(removeToast(id))
  }

  public toastMessages = () => {
    const {
      positions: { toast }
    } = this.props

    return toast.map((item: any, index: number) => {
      const { message, detail, status, errorItems } = item

      return (
        <ImToast key={index} index={index} removeToast={this.clearToast}>
          <PositionToastMessage
            index={index}
            status={status}
            message={message}
            detail={detail}
            errorItems={errorItems}
            removeToast={this.clearToast}
          />
        </ImToast>
      )
    })
  }

  public render = () => {
    const { scrollLocation } = this.state

    const toastMessages = this.toastMessages()

    return (
      <div className='im-global__header-w'>
        <div
          className={`im-global__header-inner ${scrollLocation > 20 &&
            `im-global__header-inner--scrolling`}`}>
          <div className='im-global__header-top-w'>
            <img
              className='im-global__header-logo'
              src={ucLogo}
              alt='UC logo'
            />
          </div>
        </div>
        {toastMessages}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  const { positions = {} } = store

  return {
    positions
  }
}

export default connect(mapStateToProps)(Header)
