import React, { Component, Fragment } from 'react'
import FinancialCharts from '../../components/graphs/financialCharts'
import { connect } from 'react-redux'

import { GlobalState } from '../../../reducers'

import { calculateAssetAllocation } from '../../helpers/netWorth'
import { dollarFormat } from '../../helpers'
import {
  ClientAccountObj,
  TotalAllocatedCalculated
} from '../../../objects/clientAccount'
import { HouseholdObj } from '../../../objects/household'
import EditTargetAllocationModal from './editTargetAllocationModal'
import AssetAllocationNull from './assetAllocationNull'

import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'

const chartOptions = {
  animation: { animateRotate: true },
  showTooltips: false,
  responsive: false,
  cutoutPercentage: 75,
  segmentShowStroke: true,
  tooltips: {
    enabled: false
  },
  elements: {
    arc: {
      borderWidth: 2
    }
  }
}

interface AssetAllocationProps {
  clientAccounts: ClientAccountObj[]
  household: HouseholdObj
}

interface AssetAllocationState {
  targetCashAllocation: number
  targetEquityAllocation: number
  targetFixedAllocation: number
  targetOtherAllocation: number
  showEditTargetAllocationModal: boolean
}

export class AssetAllocation extends Component<
  AssetAllocationProps,
  AssetAllocationState
> {
  constructor(props: AssetAllocationProps) {
    super(props)
    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = this.props.household
    this.state = {
      targetCashAllocation: targetCashAllocation || 0,
      targetEquityAllocation: targetEquityAllocation || 0,
      targetFixedAllocation: targetFixedAllocation || 0,
      targetOtherAllocation: targetOtherAllocation || 0,
      showEditTargetAllocationModal: false
    }
  }

  public toggleEditTargetAllocationModal = () => {
    this.setState({
      showEditTargetAllocationModal: !this.state.showEditTargetAllocationModal
    })
  }

  public submitAllocationForm = (allocations: object) => {
    this.setState({
      ...allocations,
      showEditTargetAllocationModal: this.state.showEditTargetAllocationModal
    })
    this.toggleEditTargetAllocationModal()
  }

  public allocationTotalZero = () => {
    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = this.state
    if (
      targetCashAllocation +
        targetEquityAllocation +
        targetFixedAllocation +
        targetOtherAllocation ===
      0
    ) {
      return true
    } else return false
  }

  public doughnutRender = () => {
    const { clientAccounts } = this.props
    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = this.state
    const totalAllocatedObj = clientAccounts
      ? calculateAssetAllocation(clientAccounts)
      : null

    const targetChartData = {
      datasets: [
        {
          data: [
            targetEquityAllocation,
            targetFixedAllocation,
            targetCashAllocation,
            targetOtherAllocation
          ],
          backgroundColor: ['#255EBA', '#FFBE1A', '#FFDE8C', '#A2D134']
        }
      ]
    }
    const chartData = {
      datasets: [
        {
          data: [
            totalAllocatedObj.equity.percent,
            totalAllocatedObj.fixed.percent,
            totalAllocatedObj.cash.percent,
            totalAllocatedObj.alt.percent,
            totalAllocatedObj.unclassified.percent
          ],
          backgroundColor: [
            '#255EBA',
            '#FFBE1A',
            '#FFDE8C',
            '#A2D134',
            '#8A8888'
          ]
        }
      ]
    }

    return (
      <Fragment>
        {this.allocationTotalZero() ? (
          <AssetAllocationNull
            onBtnClick={this.toggleEditTargetAllocationModal}
          />
        ) : (
          <div className='inv-allo__graph-doughnut'>
            <div className='inv-allo__graph-doughnut-data'>
              <span>
                {Math.round(targetEquityAllocation)}/
                {Math.round(targetFixedAllocation)}/
                {Math.round(targetCashAllocation)}/
                {Math.round(targetOtherAllocation)}
              </span>
              <span
                onClick={this.toggleEditTargetAllocationModal}
                className='inv-allo__graph-doughnut-data-edit'>
                <EditIcon />
              </span>
            </div>

            <FinancialCharts
              canvasId='inv-allo-target-doughnut'
              chartData={targetChartData}
              chartOptions={chartOptions}
              dimensions={{ width: 110, height: 110 }}
            />

            <div className='inv-allo__graph-doughnut-text'>
              TARGET <br />
              ALLOCATION
            </div>
          </div>
        )}
        <div className='inv-allo__graph-doughnut'>
          <div className='inv-allo__graph-doughnut-data'>
            {chartData.datasets[0].data[0]}/{chartData.datasets[0].data[1]}/
            {chartData.datasets[0].data[2]}/{chartData.datasets[0].data[3]}/
            {chartData.datasets[0].data[4]}
          </div>

          <FinancialCharts
            canvasId='inv-allo-current-doughnut'
            chartData={chartData}
            chartOptions={chartOptions}
            dimensions={{ width: 110, height: 110 }}
          />

          <div className='inv-allo__graph-doughnut-text'>
            CURRENT <br />
            PORTFOLIO
          </div>
        </div>
      </Fragment>
    )
  }

  public barRender = () => {
    const allocationAmount: TotalAllocatedCalculated = calculateAssetAllocation(
      this.props.clientAccounts
    )
    const { equity, fixed, cash, alt, unclassified } = allocationAmount
    return (
      <div className='inv-allo__graph-bar-w'>
        <div className='inv-allo__graph-bar--label'>
          <label>Asset Type</label>
          <label>Current Allocation</label>
          <label>Amount</label>
        </div>
        <div className='inv-allo__graph-bar--data'>
          <span>Equity</span>
          <div className='inv-allo__graph-bar'>
            <div
              style={{ width: equity.percent + '%' }}
              className='inv-allo__graph-bar--equity'
            />
            <span>{equity.percent}%</span>
          </div>
          <span>{dollarFormat(equity.amount, 0)}</span>
        </div>
        <div className='inv-allo__graph-bar--data'>
          <span>Fixed income</span>
          <div className='inv-allo__graph-bar'>
            <div
              style={{ width: fixed.percent + '%' }}
              className='inv-allo__graph-bar--fix'
            />
            <span>{fixed.percent}%</span>
          </div>
          <span>{dollarFormat(fixed.amount, 0)}</span>
        </div>
        <div className='inv-allo__graph-bar--data'>
          <span>Cash & Equivalents</span>
          <div className='inv-allo__graph-bar'>
            <div
              style={{ width: cash.percent + '%' }}
              className='inv-allo__graph-bar--cash'
            />
            <span>{cash.percent}%</span>
          </div>
          <span>{dollarFormat(cash.amount, 0)}</span>
        </div>
        <div className='inv-allo__graph-bar--data'>
          <span>Alternatives</span>
          <div className='inv-allo__graph-bar'>
            <div
              style={{ width: alt.percent + '%' }}
              className='inv-allo__graph-bar--alt'
            />
            <span>{alt.percent}%</span>
          </div>
          <span>{dollarFormat(alt.amount, 0)}</span>
        </div>
        <div className='inv-allo__graph-bar--data'>
          <span>Unclassified</span>
          <div className='inv-allo__graph-bar'>
            <div
              style={{ width: unclassified.percent + '%' }}
              className='inv-allo__graph-bar--unclass'
            />
            <span>{unclassified.percent}%</span>
          </div>
          <span>{dollarFormat(unclassified.amount, 0)}</span>
        </div>
      </div>
    )
  }

  public render() {
    const {
      targetCashAllocation,
      targetEquityAllocation,
      targetFixedAllocation,
      targetOtherAllocation
    } = this.state
    return (
      <div className='inv-allo'>
        <div className='inv-allo__graph-w'>
          {this.doughnutRender()}
          {this.barRender()}
        </div>
        {this.state.showEditTargetAllocationModal ? (
          <EditTargetAllocationModal
            targetCashAllocation={targetCashAllocation}
            targetEquityAllocation={targetEquityAllocation}
            targetFixedAllocation={targetFixedAllocation}
            targetOtherAllocation={targetOtherAllocation}
            submitForm={this.submitAllocationForm}
            closeModal={this.toggleEditTargetAllocationModal}
            household={this.props.household}
            setType={this.allocationTotalZero()}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, clientAccountId, clientAccount }: any
) => {
  return {
    clientAccount,
    householdFinId,
    clientAccountId
  }
}

export default connect(mapStateToProps)(AssetAllocation)
