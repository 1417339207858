import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'

import {
  InvestmentViewfinderInterface,
  InvestmentViewfinderExerciseObj
} from '../../../../objects/investmentViewfinder'
import { HouseholdObj } from '../../../../objects/household'
import { GlobalState } from '../../../../reducers'

import { ReactComponent as TargetIcon } from '../../../assets/images/icons/goals.svg'
import view_performance from '../../../assets/images/icons/view_performance.svg'
import view_protection from '../../../assets/images/icons/view_protection.svg'
import view_cost from '../../../assets/images/icons/view_cost.svg'
import view_taxes from '../../../assets/images/icons/view_tax.svg'
import logo_iv from '../../../assets/images/logos/logo_iv.svg'
import domesticImage from '../../../assets/images/domestic.png'
import globalImage from '../../../assets/images/global.png'

interface ViewFinderProps {
  viewfinderExercises: InvestmentViewfinderInterface
  dispatch: Dispatch<GlobalState>
  household: HouseholdObj
  householdFinId: string
}

const viewFinderKey = {
  performance: {
    color: '#9DD47D',
    styleTag: 'pref',
    icon: view_performance,
    title: 'Performance'
  },
  protection: {
    color: '#3386BE',
    styleTag: 'pro',
    icon: view_protection,
    title: 'Protection'
  },
  cost: {
    color: '#F6AA33',
    styleTag: 'cost',
    icon: view_cost,
    title: 'Costs'
  },
  taxes: {
    color: '#AD6A99',
    styleTag: 'tax',
    icon: view_taxes,
    title: 'Taxes'
  }
}

class ViewFinder extends Component<ViewFinderProps> {
  public renderVfGraph = (
    viewFinder: InvestmentViewfinderExerciseObj,
    type: string
  ) => {
    return (
      <div className='view-finder__exercise-w'>
        <div
          className={`view-finder__graph inv-exercise__graph--${viewFinderKey[type].styleTag}`}>
          <span
            className='inv-exercise__graph--target'
            style={{ height: `${(viewFinder[type] / 16) * 100}%` }}>
            <TargetIcon />
          </span>
          <span
            className='inv-exercise__graph--current'
            style={{
              backgroundColor: viewFinderKey[type].color,
              height: 0 + '%'
            }}
          />
        </div>
        <div className='view-finder__label'>
          <img src={viewFinderKey[type].icon} alt='not-found' />
          <p>{viewFinderKey[type].title}</p>
        </div>
      </div>
    )
  }

  public renderMap = (viewfinderExercise: InvestmentViewfinderExerciseObj) => {
    const { global, domestic } = viewfinderExercise
    let pref = 'Global'
    let image = globalImage
    if (global >= 50 || domestic === 0) {
      pref = 'Global'
      image = globalImage
    } else if (domestic >= 75) {
      pref = 'Domestic'
      image = domesticImage
    }
    return (
      <div className='view-finder__preference-global'>
        <div className='view-finder__preference-global-text'>
          <p>Preference - {pref}</p>
        </div>
        <div className='view-finder__preference-global-map-w'>
          <img src={image} alt='no-img' />
        </div>
      </div>
    )
  }

  public renderGraphs = (activeViewfinder: any) => {
    return (
      <div className='view-finder__exercise'>
        {activeViewfinder.performance &&
          this.renderVfGraph(activeViewfinder, 'performance')}
        {activeViewfinder.performance &&
          this.renderVfGraph(activeViewfinder, 'protection')}
        {activeViewfinder.performance &&
          this.renderVfGraph(activeViewfinder, 'cost')}
        {activeViewfinder.performance &&
          this.renderVfGraph(activeViewfinder, 'taxes')}
      </div>
    )
  }

  public displayNull = () => {
    return (
      <div>
        <div className='view-finder__no-result'>
          <img
            className='view-finder__no-result'
            src={logo_iv}
            alt='not-found'
          />
          <div className='view-finder__no-result-heading'>No results yet</div>
          <div className='view-finder__no-result-sub-heading'>
            Understand your client’s preferences, and see how their current
            portfolio stacks up.
          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { viewfinderExercises, household } = this.props
    const activeViewfinderExercise =
      viewfinderExercises && viewfinderExercises[household.invresponseId]
    if (
      !activeViewfinderExercise ||
      (activeViewfinderExercise.cost === null &&
        activeViewfinderExercise.performance === null &&
        activeViewfinderExercise.taxes === null &&
        activeViewfinderExercise.protection === null)
    ) {
      return this.displayNull()
    } else {
      return (
        <div className='view-finder__main'>
          <div className='inv-exercise'>
            {this.renderGraphs(activeViewfinderExercise)}
          </div>
          <div className='view-finder__divider'>
            <div />
          </div>
          {(activeViewfinderExercise.domestic ||
            activeViewfinderExercise.global) &&
            this.renderMap(activeViewfinderExercise)}
        </div>
      )
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    investments: store.investments[match.params.householdFinId],
    householdFinId: match.params.householdFinId,
    household: store.households[match.params.householdFinId],
    viewfinderExercises:
      store.investmentViewfinder.exercises[match.params.householdFinId]
  }
}
export default withRouter(connect(mapStateToProps)(ViewFinder))
