import React, { Component, SyntheticEvent } from 'react'
import onClickOutside from 'react-onclickoutside'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow_right.svg'
import FilterDropdownRow from './filterDropdownRow'

import { Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'

interface FilterDropdownProps {
  dispatch?: Dispatch<GlobalState>
  title: string
  updateSelectedItems(selectedItems: string[]): void
  options: { name: string; id: string }[]
  selectedOptions: string[]
}

interface FilterDropdownState {
  filterOpen: boolean
}

class FilterDropdown extends Component<
  FilterDropdownProps,
  FilterDropdownState
> {
  constructor(props: FilterDropdownProps) {
    super(props)
    this.state = {
      filterOpen: false
    }
  }
  public handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  public toggleFilterDropdown = () => {
    this.setState({ filterOpen: !this.state.filterOpen })
  }

  public handleClickOutside = () => {
    this.setState({ filterOpen: false })
  }

  public handleCheckboxToggle = (selectedOption?: string) => () => {
    const { selectedOptions, updateSelectedItems } = this.props
    const newSelectedOptions = [...selectedOptions]

    if (newSelectedOptions.includes(selectedOption)) {
      newSelectedOptions.splice(newSelectedOptions.indexOf(selectedOption), 1)
    } else {
      newSelectedOptions.push(selectedOption)
    }
    updateSelectedItems(newSelectedOptions)
  }

  public dropDownList = (
    propArray: { name: string; id: string }[],
    propStateList: string | string[]
  ) => {
    return (
      <div
        onClick={this.handleStopPropagation}
        className='institution-filter__institution-dropdown-selection__dropdown-filter'>
        <div className='institution-filter__institution-dropdown-selection__dropdown-filter-options--sm'>
          {propArray.map((el: { name: string; id: string }, index: number) => {
            const isChecked = propStateList && propStateList.includes(el.id)
            return (
              <div key={index}>
                <FilterDropdownRow
                  key={index}
                  isChecked={isChecked}
                  toggleBox={this.handleCheckboxToggle}
                  option={el}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  public render() {
    const isOpen = this.state.filterOpen
    const { options, title, selectedOptions } = this.props
    const selectedInstitutionLabels = selectedOptions.map((selectedOption) => {
      return options.find((option) => option.id === selectedOption)?.name
    })
    // If user has just one institution we want to preselect that institution and do not show the dropdown for institutions
    const hasInstitutions = title === 'Institutions' && options?.length > 0
    const isSingleInstitution = hasInstitutions && options?.length === 1
    const dropDownLabels = selectedInstitutionLabels.toString()
    const hasSelections = selectedOptions.length > 0
    return (
      <div>
        <div className='institution-filter__institution-dropdown-list-body'>
          <div className='institution-filter__institution-dropdown-sub-header'>
            {title}
          </div>
          <div
            onClick={!isSingleInstitution && this.toggleFilterDropdown}
            id='institution-filter-dropdown'
            className='institution-filter__institution-dropdown-selection__filter institution-filter__institution-dropdown-selection'>
            <div
              className={`${'institution-filter__institution-dropdown-selection__header'} ${isOpen &&
                'institution-filter__institution-dropdown-selection__header--open'}`}>
              {hasSelections ? dropDownLabels : 'Select'}
            </div>
            {!isSingleInstitution ? (
              <ArrowRightIcon
                width='10px'
                height='10px'
                viewBox='0 -2 9 20'
                className={`institution-filter__institution-dropdown-selection__arrow`}
              />
            ) : null}
            {isOpen && !isSingleInstitution
              ? this.dropDownList(options, selectedOptions)
              : null}
          </div>
        </div>
      </div>
    )
  }
}

export default onClickOutside(FilterDropdown)
