import React from 'react'
import ScoreCommentTextArea from './scoreCommentTextArea'
import { autoSaveStatus } from './meeting/hcEditCardRow'
import { ContactsInterface } from '../../../objects/contact'
export interface ScoreCommentProps {
  isPrimary: boolean
  cardIndex: number
  value: string
  params: {
    contacts: ContactsInterface
    isPrimaryContact: boolean
    edit: boolean
    scoringSessionId: string
  }
  showCompactView: boolean
  isCommentChange: boolean
  isAllCommentBoxDisabled: boolean
  setAllComponentBoxDisabled(commentBoxState: boolean): void
  onAddUpdateSession(contactId: string, scoringSessionId?: string): void
  onCommentChange(comment: string, isPrimary: boolean, index: number): void
}
interface ScoreCommentState {
  autoSaveStatus: autoSaveStatus
}

class ScoreComment extends React.Component<
  ScoreCommentProps,
  ScoreCommentState
> {
  constructor(props: ScoreCommentProps) {
    super(props)
    this.state = {
      autoSaveStatus: null
    }
  }
  public updateAutoSaveStatus(status: autoSaveStatus) {
    this.setState({ autoSaveStatus: status })
  }
  public onFocus = (isPrimary: boolean) => {
    this.setState({ autoSaveStatus: null })
  }
  public onCommentBlur = async () => {
    const {
      onAddUpdateSession,
      params: { contacts, isPrimaryContact, scoringSessionId },
      setAllComponentBoxDisabled,
      isCommentChange
    } = this.props
    if (isCommentChange) {
      setAllComponentBoxDisabled(true)
      this.updateAutoSaveStatus('ongoing')
      try {
        const contactId = isPrimaryContact
          ? contacts.primary.id
          : contacts.secondary.id
        await onAddUpdateSession(contactId, scoringSessionId)
        this.updateAutoSaveStatus('success')
        setAllComponentBoxDisabled(false)
      } catch {
        this.updateAutoSaveStatus('error')
        setAllComponentBoxDisabled(false)
      }
    }
  }
  render() {
    const {
      onCommentChange,
      params: { isPrimaryContact },
      cardIndex,
      isAllCommentBoxDisabled,
      value,
      showCompactView
    } = this.props
    const { autoSaveStatus } = this.state
    return (
      <ScoreCommentTextArea
        showCompactView={showCompactView}
        onCommentChange={onCommentChange}
        isPrimary={isPrimaryContact}
        cardIndex={cardIndex}
        onInputBlur={this.onCommentBlur}
        autoSaveStatus={autoSaveStatus}
        isDisabled={isAllCommentBoxDisabled}
        onFocus={this.onFocus}
        value={value}
      />
    )
  }
}

export default ScoreComment
