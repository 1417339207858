import React, { FormEvent } from 'react'
import onClickOutside from 'react-onclickoutside'
import { RadioButton } from './Radio'
import Button from './button'
import { ReactComponent as SettingsIcon } from '../assets/images/icons/settings.svg'
import {
  SortSettingProperty,
  RadioGroup
} from '../../objects/preferenceSortSetting'
import * as SortHelper from '../helpers/householdPreferences'

interface PreferenceSortSettingProps {
  persistedSortBy?: any
  persistedGroupBy?: any
  persistedSortingOrder?: any
  groupByOptions?: SortSettingProperty[]
  sortByOptions: SortSettingProperty[]
  sortOrderOptions: SortSettingProperty[]
  currentGroupBy?: string
  currentSortBy?: string
  currentSortingOrder?: string
  showPriorityOption?: boolean
  changePreference(e?: any): void
  onSubmitPreferences(): void
  onCancelPreferences(): void
  onDefaultPreferences(): void
}
interface PreferenceSortSettingState {
  open: boolean
  groupBy: string
  sortBy: string
  sortOrder: string
}

class PreferenceSortSetting extends React.Component<
  PreferenceSortSettingProps,
  PreferenceSortSettingState
> {
  constructor(props: any) {
    super(props)
    const { currentGroupBy, currentSortBy, currentSortingOrder } = this.props
    const nullOption = ''
    this.state = {
      open: false,
      groupBy: currentGroupBy || nullOption,
      sortBy: currentSortBy || nullOption,
      sortOrder: currentSortingOrder || nullOption
    }
  }

  public toggleDropdown = () => this.setState({ open: !this.state.open })

  public closeDropdown = () => this.setState({ open: false })

  public handleClickOutside = (_e: any) => {
    this.props.onCancelPreferences()
    this.closeDropdown()
  }

  public onSelectGroupBy = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { value } = event.currentTarget
    const newPref = SortHelper.mapValueToOption(
      this.props.groupByOptions,
      value
    ).label
    this.props.changePreference({ groupBy: newPref })
  }

  public onSelectSortBy = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { value } = event.currentTarget
    const newPref = SortHelper.mapValueToOption(this.props.sortByOptions, value)
      .label
    this.props.changePreference({ sortBy: newPref })
  }

  public onSelectSortOrder = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { value } = event.currentTarget
    const newPref = SortHelper.mapValueToOption(
      this.props.sortOrderOptions,
      value
    ).label
    this.props.changePreference({ sortingOrder: newPref })
  }

  public renderRadioGroup = (radioGroupOptions: RadioGroup) => {
    const {
      radioGroupName,
      radioOptions,
      defaultSelect,
      onRadioChange
    } = radioGroupOptions
    const radioElements: JSX.Element[] = []
    const options = radioOptions.filter((option) => {
      return !(
        option.value === 'needsWantsWishes' && !this.props.showPriorityOption
      )
    })
    options.forEach((option, index) => {
      const { label, value } = option
      radioElements.push(
        <RadioButton
          key={index}
          id={`${radioGroupName}-option-${index}`}
          labelDisplay={label}
          name={radioGroupName}
          value={value}
          checked={defaultSelect === label}
          changeFunc={onRadioChange}
          className='list-group-item'
          underlineTextOnHover={true}
        />
      )
    })
    return radioElements
  }

  public renderSortByGroup = (): JSX.Element[] => {
    const { sortByOptions, currentSortBy } = this.props
    return this.renderRadioGroup({
      radioGroupName: 'sortBy',
      radioOptions: sortByOptions,
      defaultSelect: currentSortBy,
      onRadioChange: this.onSelectSortBy
    })
  }

  public renderGroupByGroup = (): JSX.Element[] => {
    const { groupByOptions, currentGroupBy } = this.props
    return this.renderRadioGroup({
      radioGroupName: 'groupBy',
      radioOptions: groupByOptions,
      defaultSelect: currentGroupBy,
      onRadioChange: this.onSelectGroupBy
    })
  }

  public renderSortOrderGroup = (): JSX.Element[] => {
    const { sortOrderOptions, currentSortingOrder } = this.props
    return this.renderRadioGroup({
      radioGroupName: 'sortOrder',
      radioOptions: sortOrderOptions,
      defaultSelect: currentSortingOrder,
      onRadioChange: this.onSelectSortOrder
    })
  }

  public renderDropdown = (elements: JSX.Element[]): JSX.Element => {
    return (
      <ul className='pref-sort-settings__radiogroup'>
        {elements.map((el, index) => {
          return (
            <li key={index} className='pref-sort-settings__radiogroup-item'>
              {el}
            </li>
          )
        })}
      </ul>
    )
  }

  public dropDown = () => {
    if (this.state.open) {
      const {
        persistedGroupBy,
        persistedSortBy,
        persistedSortingOrder,
        currentGroupBy,
        currentSortBy,
        currentSortingOrder,
        onDefaultPreferences,
        groupByOptions
      } = this.props
      const activateSubmitButton = Boolean(
        persistedGroupBy !== currentGroupBy ||
          persistedSortBy !== currentSortBy ||
          persistedSortingOrder !== currentSortingOrder
      )
      return (
        <ul className='pref-sort-settings__dropdown-menu'>
          <li className='pref-sort-settings__header'>
            <div className='pref-sort-settings__header-label'>
              <SettingsIcon style={{ color: '#315eb4' }} />
              <span className='pref-sort-settings__header-text'>
                {groupByOptions ? 'GROUP AND SORT' : 'SORT'}
              </span>
            </div>
            <Button clear={true} onClick={onDefaultPreferences}>
              Default
            </Button>
          </li>
          {groupByOptions ? (
            <li className='pref-sort-settings__item'>
              <span className='pref-sort-settings__item-header'>Group By</span>
              {this.renderDropdown(this.renderGroupByGroup())}
            </li>
          ) : null}
          <li className='pref-sort-settings__item'>
            <span className='pref-sort-settings__item-header'>Sort By</span>
            {this.renderDropdown(this.renderSortByGroup())}
          </li>
          <li className='pref-sort-settings__item'>
            <span className='pref-sort-settings__item-header'>
              Sorting Order
            </span>
            {this.renderDropdown(this.renderSortOrderGroup())}
          </li>
          <li className='pref-sort-settings__buttons'>
            <Button clear={true} onClick={this.cancelPreferences}>
              Cancel
            </Button>
            <Button
              primary={activateSubmitButton}
              onClick={activateSubmitButton ? this.submitPreferences : null}>
              Apply
            </Button>
          </li>
        </ul>
      )
    } else return null
  }

  public submitPreferences = () => {
    this.props.onSubmitPreferences()
    this.toggleDropdown()
  }

  public cancelPreferences = () => {
    this.props.onCancelPreferences()
    this.toggleDropdown()
  }

  public render() {
    return (
      <div className='pref-sort-settings'>
        <div
          onClick={this.toggleDropdown}
          className='pref-sort-settings__gear-btn'>
          <SettingsIcon style={{ color: '#315eb4' }} />
        </div>
        {this.state.open && this.dropDown()}
      </div>
    )
  }
}

export default onClickOutside(PreferenceSortSetting)
