export const CHANGE_CLIENT_VALUE = 'CHANGE_CLIENT_VALUE'
export const CHANGE_PROPOSED_VALUE = 'CHANGE_PROPOSED_VALUE'
export const CREATE_MAP_VALUE = 'CREATE_MAP_VALUE'
export const ADD_PROPOSED_VALUE = 'ADD_PROPOSED_VALUE'
export const REMOVE_PROPOSED_VALUE = 'REMOVE_PROPOSED_VALUE'
export const createMapValue = (houseHoldId: string) => ({
  type: CREATE_MAP_VALUE,
  houseHoldId
})

export const changeClientValue = (houseHoldId: string, clientValue: number) => {
  return {
    type: CHANGE_CLIENT_VALUE,
    houseHoldId,
    clientValue
  }
}

export const changeProposedValue = (
  houseHoldId: string,
  proposeValue: { id: number; value: number }
) => {
  return {
    type: CHANGE_PROPOSED_VALUE,
    houseHoldId,
    proposeValue
  }
}

export const addProposedValue = (
  houseHoldId: string,
  proposeValue: { id: number; value: number }
) => {
  return {
    type: ADD_PROPOSED_VALUE,
    houseHoldId,
    proposeValue
  }
}

export const removeProposedValue = (
  houseHoldId: string,
  proposeValue: { id: number; value: number }
) => {
  return {
    type: REMOVE_PROPOSED_VALUE,
    houseHoldId,
    proposeValue
  }
}
