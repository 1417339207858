import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'

import Tile from '../../components/layout/tile'
import Heading from './heading'

import Button from '../../components/button'
import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import EmailMoneyMindModal from '../moneymind/emailModal'

import * as moneymindActions from '../../../actions/moneymind'

import { ReactComponent as MoneymindIcon } from '../../assets/images/icons/moneymind.svg'
import { ReactComponent as ProtectionIcon } from '../../assets/images/icons/protection.svg'
import { ReactComponent as CommitmentIcon } from '../../assets/images/icons/commitment.svg'
import { ReactComponent as HappinessIcon } from '../../assets/images/icons/happiness.svg'
import { GlobalState } from '../../../reducers'
import { UserInterface } from '../../../objects/user'
import { InstitutionObj } from '../../../objects/institution'

interface DashMoneymindProps {
  moneyminds?: any
  contacts: any
  householdFinId: string
  institution: InstitutionObj
  users: UserInterface
  userId: string
  dispatch: Dispatch<GlobalState>
}

interface DashMoneymindState {
  showEmailModal: boolean
  emailType: string
}

export class NullMoneyMind extends Component<
  DashMoneymindProps,
  DashMoneymindState
> {
  constructor(props: DashMoneymindProps) {
    super(props)

    this.state = {
      showEmailModal: false,
      emailType: ''
    }
  }

  public renderSendButtons = () => {
    const { contacts } = this.props
    return (
      <div className='c-dash__mm-buttons'>
        {contacts && contacts.primary ? (
          <Button
            style={{ width: '100%' }}
            primary={true}
            onClick={this.handlePrimaryEmailType}>
            <p style={{ margin: 0 }}>
              Send {contacts.primary.firstName}'s{' '}
              <span>
                MoneyMind<sup>&reg;</sup>
              </span>
            </p>
          </Button>
        ) : null}
        {contacts && contacts.secondary ? (
          <Button
            style={{ width: '100%', margin: 0 }}
            primary={true}
            onClick={this.handleSecondaryEmailType}>
            <p style={{ margin: 0 }}>
              Send {contacts.secondary.firstName}'s{' '}
              <span>
                MoneyMind<sup>&reg;</sup>
              </span>
            </p>
          </Button>
        ) : null}
      </div>
    )
  }

  public render() {
    return (
      <Tile
        to={`/households/${this.props.householdFinId}/moneyMind`}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        leftHeader={
          <Heading icon={<MoneymindIcon />} headingText={'MoneyMind®'} />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={true}
        anchorBorder={true}>
        <div className='c-dash__row-content filtered__bottom'>
          <Fragment>
            <div className='c-dash__mm'>
              <div className='c-dash__mm-icon'>
                <div className='c-dash__mm-icon-protection'>
                  <ProtectionIcon />
                  <span>Protection</span>
                </div>
                <div className='c-dash__mm-icon-commitment'>
                  <CommitmentIcon />
                  <span>Commitment</span>
                </div>
                <div className='c-dash__mm-icon-happiness'>
                  <HappinessIcon />
                  <span>Happiness</span>
                </div>
              </div>
            </div>
            <div className='c-dash__mm-score'>
              <div className='c-dash__mm-score-client'>
                <span />
                <span>%</span>
                <span>%</span>
                <span>%</span>
              </div>
            </div>
          </Fragment>
        </div>
        <div
          className='c-dash__null--stateBackground'
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <p>
            Send MoneyMind<sup>&reg;</sup> to the clients and see the results
            here.
          </p>
          {this.renderSendButtons()}
        </div>
        {this.state.showEmailModal && this.state.emailType === 'primary' ? (
          <EmailMoneyMindModal
            institution={this.props.institution}
            user={this.props.users[this.props.userId]}
            contact={this.props.contacts.primary}
            saveModal={this.emailMoneyMind}
            closeModal={this.closeView}
          />
        ) : null}
        {this.state.showEmailModal && this.state.emailType === 'secondary' ? (
          <EmailMoneyMindModal
            institution={this.props.institution}
            user={this.props.users[this.props.userId]}
            contact={this.props.contacts.secondary}
            saveModal={this.emailMoneyMind}
            closeModal={this.closeView}
          />
        ) : null}
      </Tile>
    )
  }

  private handlePrimaryEmailType = () => {
    this.showEmailView('primary')
  }
  private handleSecondaryEmailType = () => {
    this.showEmailView('secondary')
  }
  private showEmailView = (emailType: string) => {
    this.setState({ showEmailModal: true, emailType })
  }
  private emailMoneyMind = (clientId: string, token: string) => {
    this.props.dispatch(moneymindActions.sendMoneyMindEmail(clientId, token))
    this.closeView()
  }
  private closeView = () => {
    this.setState({ showEmailModal: false })
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, contacts }: any
) => {
  return {
    householdFinId,
    contacts,
    userId: store.user.userId,
    users: store.user.users,
    institution: store.institution
  }
}

export default connect(mapStateToProps)(NullMoneyMind)
