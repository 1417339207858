import React, { Component } from 'react'
import Tile from '../../components/layout/tile'
import Input from '../../components/Input'

interface SenderInfoProps {
  senderInfo: {
    firstName: string
    lastName: string
    street: string
    addressLine2: string
    city: string
    state: string
    postalCode: string
    phone: string
  }
  clientRecipient: boolean
  missingFields(): void
  updateClientInfo(value: any): void
  updateAdvisorInfo(value: any): void
}
class SenderInfo extends Component<SenderInfoProps, {}> {
  public errorMessage = (val: string, errorMessage: string) => {
    if (errorMessage && !val) {
      return 'Enter a ' + errorMessage
    } else {
      return null
    }
  }
  public render() {
    const {
      senderInfo,
      clientRecipient,
      updateClientInfo,
      updateAdvisorInfo,
      missingFields
    } = this.props
    return (
      <div className='mimeo__section-w'>
        <Tile
          iconPng={null}
          leftHeader={'SENDER INFO'}
          style={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '20px'
          }}>
          <div className='mimeo__section'>
            <div className='mimeo__section-left'>
              <div className='mimeo__label'>
                <Input
                  mandatory
                  title='First Name'
                  inputType='text'
                  content={senderInfo.firstName}
                  name='firstName'
                  controlFunc={
                    !clientRecipient ? updateClientInfo : updateAdvisorInfo
                  }
                  onBlur={missingFields}
                  error={this.errorMessage(senderInfo.firstName, 'First Name')}
                />
              </div>
            </div>
            <div className='mimeo__section-right'>
              <div className='mimeo__label'>
                <Input
                  mandatory
                  title='Last Name'
                  inputType='text'
                  content={senderInfo.lastName}
                  name='lastName'
                  controlFunc={
                    !clientRecipient ? updateClientInfo : updateAdvisorInfo
                  }
                  onBlur={missingFields}
                  error={this.errorMessage(senderInfo.lastName, 'Last Name')}
                />
              </div>
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label'>
              <Input
                mandatory
                title='Street'
                inputType='text'
                content={senderInfo.street}
                name='street'
                controlFunc={
                  !clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(senderInfo.street, 'Street')}
              />
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label'>
              <Input
                title='Address Line 2'
                inputType='text'
                content={senderInfo.addressLine2}
                name='addressLine2'
                onBlur={missingFields}
                controlFunc={
                  !clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
              />
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label mimeo__label--trips'>
              <Input
                mandatory
                title='City'
                inputType='text'
                content={senderInfo.city}
                name='city'
                controlFunc={
                  !clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(senderInfo.city, 'City')}
              />
            </div>
            <div className='mimeo__label mimeo__label--trips'>
              <Input
                mandatory
                title='State/Province'
                inputType='text'
                maxLength={2}
                content={senderInfo.state}
                name='state'
                controlFunc={
                  !clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(senderInfo.state, 'State/Province')}
              />
            </div>
            <div className='mimeo__label mimeo__label--trips'>
              <Input
                mandatory
                title='Zip/Postal Code'
                inputType='text'
                content={senderInfo.postalCode}
                name='postalCode'
                controlFunc={
                  !clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(
                  senderInfo.postalCode,
                  'Zip/Postal Code'
                )}
              />
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__label'>
              <Input
                mandatory
                title='Phone'
                inputType='tel'
                content={senderInfo.phone}
                name='phone'
                controlFunc={
                  !clientRecipient ? updateClientInfo : updateAdvisorInfo
                }
                onBlur={missingFields}
                error={this.errorMessage(senderInfo.phone, 'Phone')}
              />
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

export default SenderInfo
