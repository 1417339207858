import React, { Component, CSSProperties } from 'react'
import {
  PortfolioAnalyticsFunc,
  PortfolioAnalyticsStateKeys
} from '../../../../../../objects/PortfolioAnalytics/analytics'

import { defaultTodayDate } from '../../../../../helpers'

interface AddAccountProps {
  featureFunc: PortfolioAnalyticsFunc
}

interface AddAccountState {
  accountNickname: string
  accountNicknameValid: boolean
  accountNumberFull: string
  accountNumberFullValid: boolean
  totalValueManual: string
  totalValueManualValid: boolean
  guidebookClassification: string
  guidebookClassificationValid: boolean

  balanceDate: string
  ownerIds: string[]
  accountType: string
  taxStatus: string
  type: string
  pssSelected: boolean
  includeInGuidebook: boolean
  enableAdd: boolean
}

const requiredFields = [
  'accountNickname',
  'accountNumberFull',
  'totalValueManual',
  'guidebookClassification'
]

export class AddAccount extends Component<AddAccountProps, AddAccountState> {
  constructor(props: AddAccountProps) {
    super(props)
    this.state = {
      accountNickname: null,
      accountNicknameValid: null,
      accountNumberFull: null,
      accountNumberFullValid: null,
      totalValueManual: null,
      totalValueManualValid: true,
      guidebookClassification: null,
      guidebookClassificationValid: true,

      balanceDate: null,
      ownerIds: null,
      accountType: null,
      taxStatus: null,
      type: null,
      pssSelected: true,
      includeInGuidebook: true,
      enableAdd: false
    }
  }

  public toggleView = () => {
    const {
      featureFunc: { toggleModal }
    } = this.props
    const stateKey: PortfolioAnalyticsStateKeys = {
      toggleAddAccount: 'toggleAddAccount'
    }
    toggleModal(stateKey)
  }

  public handlSwitches = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget
    const newStateObj = {}
    newStateObj[name] = !this.state[name]
    this.setState(newStateObj)
  }

  public handleChange = (inputName: string, inputValue: any) => {
    const stateKeys = Object.keys(this.state)
    const update = {}
    update[inputName] = inputValue || null
    stateKeys.includes(inputName) &&
      this.setState(update, () => this.validateRequired())
  }

  public validateRequired = () => {
    const currentState = this.state
    const validFields: string[] = []
    const fieldsCheck = requiredFields.filter((requiredKey: string) => {
      if (
        Object.is(currentState[requiredKey], null) ||
        currentState[requiredKey].length === 0
      ) {
        return true
      } else {
        validFields.push(requiredKey)
        return false
      }
    })

    validFields.forEach((fieldName: string) => {
      currentState[`${fieldName}Valid`] = true
    })

    this.setState({ ...currentState, enableAdd: fieldsCheck.length === 0 })
  }

  public validateRequirements = () => {
    const currentState = this.state
    const fieldsCheck = requiredFields.filter((requiredKey: string) => {
      return (
        Object.is(currentState[requiredKey], null) ||
        currentState[requiredKey].length === 0
      )
    })

    fieldsCheck.forEach((fieldName: string) => {
      currentState[`${fieldName}Valid`] = false
    })
    this.setState(currentState)
  }

  /**
   * set balance date.
   */
  public setBalanceDate = () => {
    const { balanceDate } = this.state
    if (!balanceDate) {
      const [defaultMonth, defaultDate, defaultYear] = defaultTodayDate()
      return `${defaultMonth}/${defaultDate}/${defaultYear
        .toString()
        .substr(-2)}`
    }
    const [month, day, year] = balanceDate.split('/')
    return `${month}/${day}/${year.substr(-2)}`
  }

  public createNewAccount = () => {
    const {
      featureFunc: { createAccount }
    } = this.props
    const {
      accountNickname,
      accountNumberFull,
      ownerIds,
      totalValueManual,
      accountType,
      taxStatus,
      type,
      guidebookClassification,
      pssSelected,
      includeInGuidebook,
      enableAdd
    } = this.state

    const newAccount = {
      accountNickname,
      accountNumberFull,
      balanceDate: this.setBalanceDate(),
      ownerIds,
      totalValueManual: totalValueManual.replace(/,/g, ''),
      accountType,
      taxStatus,
      type,
      guidebookClassification,
      pssSelected,
      includeInGuidebook
    }

    this.validateRequirements()
    enableAdd && createAccount(newAccount)
  }

  public render = () => {
    const { enableAdd } = this.state

    let addStyle: CSSProperties = { minHeight: '55px', minWidth: '94px' }
    if (!enableAdd) {
      addStyle = {
        ...addStyle,
        background: 'white',
        border: 'solid 2px rgba(33, 120, 196, 0.1)',
        color: '#AEB6BE'
      }
    }

    return <div></div>
  }
}

export default AddAccount
