import api from '../v3/helpers/api'

import { insuranceTypes } from '../objects/insurance'

export const FETCH_INSURANCE: string = 'FETCH_INSURANCE'
export const FETCH_INSURANCE_PENDING: string = 'FETCH_INSURANCE_PENDING'
export const FETCH_INSURANCE_FULFILLED: string = 'FETCH_INSURANCE_FULFILLED'
export const FETCH_INSURANCE_REJECTED: string = 'FETCH_INSURANCE_REJECTED'

export const ADD_INSURANCE: string = 'ADD_INSURANCE'
export const ADD_INSURANCE_PENDING: string = 'ADD_INSURANCE_PENDING'
export const ADD_INSURANCE_FULFILLED: string = 'ADD_INSURANCE_FULFILLED'
export const ADD_INSURANCE_REJECTED: string = 'ADD_INSURANCE_REJECTED'

export const DELETE_INSURANCE: string = 'DELETE_INSURANCE'
export const DELETE_INSURANCE_PENDING: string = 'DELETE_INSURANCE_PENDING'
export const DELETE_INSURANCE_FULFILLED: string = 'DELETE_INSURANCE_FULFILLED'
export const DELETE_INSURANCE_REJECTED: string = 'DELETE_INSURANCE_REJECTED'

export const UPDATE_INSURANCE: string = 'UPDATE_INSURANCE'
export const UPDATE_INSURANCE_PENDING: string = 'UPDATE_INSURANCE_PENDING'
export const UPDATE_INSURANCE_FULFILLED: string = 'UPDATE_INSURANCE_FULFILLED'
export const UPDATE_INSURANCE_REJECTED: string = 'UPDATE_INSURANCE_REJECTED'

export const UPDATE_ACTIVE_INSURANCE: string = 'UPDATE_ACTIVE_INSURANCE'
export const UPDATE_ACTIVE_INSURANCE_PENDING: string =
  'UPDATE_ACTIVE_INSURANCE_PENDING'
export const UPDATE_ACTIVE_INSURANCE_FULFILLED: string =
  'UPDATE_ACTIVE_INSURANCE_FULFILLED'
export const UPDATE_ACTIVE_INSURANCE_REJECTED: string =
  'UPDATE_ACTIVE_INSURANCE_REJECTED'

export const getInsurance = (householdFinId: string) => {
  return {
    type: FETCH_INSURANCE,
    payload: api()
      .get(`/households/${householdFinId}/insurances`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const activeInsurance = (
  householdFinId: string,
  id: string,
  activeStatus: boolean
) => {
  return {
    type: UPDATE_ACTIVE_INSURANCE,
    payload: api()
      .put(`/households/${householdFinId}/insurances/${id}`, {
        inGuidebook: `${activeStatus}`
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const isDeleted = (householdFinId: string, id: string) => {
  return {
    type: DELETE_INSURANCE,
    payload: api()
      .delete(`/households/${householdFinId}/insurances/${id}`)
      .then((response) => {
        if (!response.data) {
          response.data = { ...response.data, householdFinId }
        }
        return response
      })
  }
}

export const insuranceTypeData = (insuranceData: any) => {
  const {
    typeName,
    policyNumber,
    premiumFrequency,
    premiumAmount,
    name,
    ...data
  } = insuranceData
  const commonData = {
    recordTypeName: typeName,
    policyNumber,
    premiumFrequency,
    name,
    premiumAmount: `${premiumAmount}`
  }
  switch (typeName) {
    case insuranceTypes.lifeInsurance:
      return {
        ...commonData,
        ownerName: `${data.owner}`,
        beneficiaryName: `${data.beneficiary}`,
        insuredName: `${data.insured}`,
        deathBenefit: `${data.deathBenefit}`
      }
    case insuranceTypes.annuity:
      return {
        ...commonData,
        ownerName: `${data.owner}`,
        yearlyBenefit: `${data.yearlyBenefit}`,
        surrenderPeriod: `${data.surrenderPeriod}`
      }
    case insuranceTypes.disability:
      return {
        ...commonData,
        insuredName: `${data.insured}`,
        benefitPeriodYears: `${data.duration}`,
        monthlyBenefitAmount: `${data.monthlyBenefit}`,
        eliminationPeriod: `${data.eliminationPeriod}`
      }
    case insuranceTypes.longTerm:
      return {
        ...commonData,
        insuredName: `${data.insured}`,
        benefitPeriodYears: `${data.duration}`,
        monthlyBenefitAmount: `${data.monthlyBenefit}`,
        eliminationPeriod: `${data.eliminationPeriod}`
      }
    default:
      return {}
  }
}

export const addInsurance = (householdFinId: string, insuranceData: any) => {
  return {
    type: ADD_INSURANCE,
    payload: api()
      .post(`/households/${householdFinId}/insurances`, {
        ...insuranceTypeData(insuranceData),
        inGuidebook: true
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const editInsurance = (householdFinId: string, insuranceData: any) => {
  return {
    type: UPDATE_INSURANCE,
    payload: api()
      .put(
        `/households/${householdFinId}/insurances/${insuranceData.id}`,
        insuranceTypeData(insuranceData)
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}
