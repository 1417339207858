import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
// types
import { GlobalState } from '../../../reducers'
import { ClientAccountInterface } from '../../../objects/clientAccount'
// actions
import {
  getYodleeAccounts,
  getClientAccounts,
  setYodleeSyncAttempt
} from '../../../actions/clientAccounts'
// components
import { Loader } from '../../components/loader/loader'
export interface YodleeLoaderProps {
  dispatch: Dispatch<GlobalState>
  householdAccounts?: ClientAccountInterface
  householdFinId: string
  disableYodlee: boolean | undefined
  stacked?: boolean
}

export interface YodleeLoaderState {
  isLoader: boolean
  showLoader: boolean
}
export class YodleeLoader extends React.Component<
  YodleeLoaderProps,
  YodleeLoaderState
> {
  constructor(props: YodleeLoaderProps) {
    super(props)
    this.state = {
      isLoader: false,
      showLoader: true
    }
  }

  mounted = false

  async componentDidMount() {
    const { dispatch, householdFinId } = this.props
    this.mounted = true
    if (this.checkConditions()) {
      dispatch(setYodleeSyncAttempt(householdFinId, true))
      if (this.checkIfHasYodleeAccount()) {
        await this.syncYodleeAccounts()
      }
    }
  }

  async componentDidUpdate(prevProps: YodleeLoaderProps) {
    const { dispatch, householdAccounts, householdFinId } = this.props

    if (
      JSON.stringify(prevProps.householdAccounts) !==
      JSON.stringify(householdAccounts)
    ) {
      if (this.checkConditions()) {
        dispatch(setYodleeSyncAttempt(householdFinId, true))
        if (this.checkIfHasYodleeAccount()) {
          await this.syncYodleeAccounts()
        }
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  checkConditions = () => {
    const { disableYodlee, householdAccounts } = this.props
    if (householdAccounts) {
      const { clientAccounts, yodleeSyncAttempted } = householdAccounts
      if (clientAccounts && !disableYodlee && !yodleeSyncAttempted) {
        const clientAccountsKeys = Object.keys(clientAccounts)
        if (clientAccountsKeys.length > 0) {
          return true
        }
      }
    }
    return false
  }

  checkIfHasYodleeAccount = () => {
    const { clientAccounts } = this.props.householdAccounts
    const clientAccountsKeys = Object.keys(clientAccounts)
    const hasYodleeAccount = clientAccountsKeys.some(
      (key) => clientAccounts[key].source === 'Guide Center - Yodlee'
    )
    return hasYodleeAccount
  }

  syncYodleeAccounts = async () => {
    const { dispatch, householdFinId } = this.props
    this.setState({ isLoader: true })
    try {
      await dispatch(getYodleeAccounts(householdFinId))
      await dispatch(getClientAccounts(householdFinId))
    } catch (err) {}
    if (this.mounted) this.setState({ isLoader: false })
  }

  public render() {
    const { stacked } = this.props
    const { isLoader, showLoader } = this.state

    if (isLoader) {
      return (
        <div style={{ display: showLoader ? 'block' : 'none' }}>
          <Loader
            closeFunc={() => {
              this.setState({ showLoader: false })
            }}
            loadingType='Refreshing'
            stacked={stacked}>
            One moment while we fetch the latest data for your client's linked
            accounts.
          </Loader>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdAccounts: store.clientAccount[match.params.householdFinId],
    householdFinId: match.params.householdFinId,
    disableYodlee: store.institution.disableYodlee
  }
}

export default withRouter(connect(mapStateToProps)(YodleeLoader))
