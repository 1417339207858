import React, { Component } from 'react'
import { ClipLoader } from 'react-spinners'
import Tooltip from '../../../components/tooltip'

import { HouseholdObj } from '../../../../objects/household'

import guidebookicon from '../../../assets/images/icons/householdNav/active_guidebook.png'
import downloadicon from '../../../assets/images/icons/ic_downloadx2.png'
import vaultIcon from '../../../assets/images/icons/push_to_vault.png'
import CloseIcon from '../../../assets/images/icons/ic_close.png'
import { history } from '../../../../store'
import ReactTooltip from 'react-tooltip'

export interface GuidebookActionsProps {
  household: HouseholdObj
  isLoading: boolean
  isVaultLoading: boolean
  downloader(uploadToDocVault?: boolean): void
}

class GuidebookActions extends Component<GuidebookActionsProps, {}> {
  public goBackToGuidebookList = () => {
    history.push(`/households/${this.props.household.id}/guidebook`)
  }

  public render() {
    const { isLoading, isVaultLoading, downloader } = this.props
    return (
      <div className='gb-actions'>
        <div className='gb-actions__left-w'>
          <img
            className='gb-actions__logo'
            src={guidebookicon}
            alt='Guidebook Icon'
          />
          <span className='gb-actions__logo-text'>Guidebook Builder</span>
          <div className='gb-actions__divider' />
          <span className='gb-actions__client-name'>
            {this.props.household.name}
          </span>
        </div>
        <div className='gb-actions__right-w'>
          <ClipLoader
            sizeUnit={'px'}
            size={18}
            color={'#4a78cf'}
            loading={isLoading || isVaultLoading}
          />
          <div onClick={() => downloader(true)} className='gb-actions__item'><Tooltip
            message={
              isVaultLoading
                ? 'Push the Guidebook to your client’s vault disabled during load'
                : 'Push the Guidebook to your client’s vault.'
            }
            width={isVaultLoading ? 200 : 140}
            position={'bottom'}
            multiLine={true}>
            <img src={vaultIcon} alt='Push the Guidebook' />
          </Tooltip></div>
          <div onClick={() => downloader()} className='gb-actions__item'>
            <Tooltip
              message={
                isLoading
                  ? 'Download disabled during load'
                  : 'Download as PDF'
              }
              width={isLoading ? 200 : 140}
              position={isLoading ? 'bottom-left' : 'left'}
              multiLine={true}>
              <img src={downloadicon} alt='Download Icon' />
            </Tooltip>
          </div>
          <div className='gb-actions__item__divider' />
          <div
            onClick={this.goBackToGuidebookList}
            className='gb-actions__item'>
            <a data-tip='Go Back to Guidebook List'>
              <ReactTooltip place='bottom' type='dark' effect='solid' />
              <img src={CloseIcon} alt='Close Icon' />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default GuidebookActions
