import React, { Component } from 'react'
import { InsuranceInterface, insuranceTypes } from '../../../objects/insurance'
import InGuideBookSwitch from './InGuideBookSwitch'
import Tile from '../../components/layout/tile'
import TableRow from '../../components/layout/tableRow'
import { dollarsOrBlankSpace } from '../../helpers'
import InsuranceUpdate from './insuranceUpdate'
import Tooltip from '../../components/tooltip'

import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg'
import { staticText } from '../../helpers/resources'

interface LifeInsuranceRowProps {
  insurances: InsuranceInterface
}

export class LifeInsuranceRow extends Component<LifeInsuranceRowProps> {
  public renderData = () => {
    return Object.keys(this.props.insurances).map((key, i) => {
      const insurance = this.props.insurances[key]
      if (insurance.recordTypeName === insuranceTypes.lifeInsurance) {
        return (
          <div key={key} className='insurance__row insurance__col-w'>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.benefit.policyNumber === 'null'
                ? ''
                : insurance.benefit.policyNumber}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.ownerName === 'null'
                ? ''
                : insurance.benefit.ownerName}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.beneficiaryName === 'null'
                ? ''
                : insurance.benefit.beneficiaryName}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.insuredName === 'null' ? '' : insurance.insuredName}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.deathBenefit &&
              insurance.deathBenefit.toString() === 'null'
                ? ''
                : dollarsOrBlankSpace(insurance.deathBenefit)}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.annualPremium &&
              insurance.benefit.annualPremium.toString() === 'null'
                ? ''
                : dollarsOrBlankSpace(insurance.benefit.annualPremium)}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance && insurance.name ? insurance.name : ''}
            </span>
            <span className='insurance__col-item insurance__col-item--small  insurance__toggle-pdf'>
              <InGuideBookSwitch
                inGuidebook={insurance.inGuidebook}
                id={insurance.id}
                householdFinId={insurance.household}
              />
            </span>
            <span className='insurance__edit insurance__col-item insurance__col-item--menu-toggle'>
              <InsuranceUpdate insurance={insurance} />
            </span>
          </div>
        )
      } else {
        return null
      }
    })
  }

  public render() {
    if (
      Object.keys(this.props.insurances).filter(
        (key) =>
          this.props.insurances[key].recordTypeName ===
          insuranceTypes.lifeInsurance
      ).length > 0
    ) {
      return (
        <div className='insurance-w'>
          <Tile
            leftHeader={'LIFE INSURANCE POLICIES'}
            headerBorder={true}
            rightHeader={null}>
            <TableRow backgroundColor='#F5F5F5'>
              <div className='insurance__col-w'>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>Policy Number</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>Owner</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>Beneficiary</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>Insured</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Death <br />
                    Benefit
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Annual <br />
                    Premium
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Company <br />
                    Name
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small insurance__toggle-pdf'>
                  <label>Show/Hide</label>
                  <Tooltip
                    message={staticText.includeInGc}
                    width={160}
                    position='bottom'
                    multiLine={true}>
                    <HelpIcon />
                  </Tooltip>
                </span>
                <span className='insurance__col-item insurance__col-item--menu-toggle' />
              </div>
            </TableRow>
            {this.renderData()}
          </Tile>
        </div>
      )
    } else {
      return null
    }
  }
}

export default LifeInsuranceRow
