import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import Header from './header'
import { AdvisorNotificationSubscription } from '../../helpers/queries'
import SyncHelper from '../../helpers/sync'

interface MainContainerProps {
  notifications?: any
  subscribeToMore?: any
  noHeader?: boolean
  dispatch?: Dispatch<GlobalState>
}
class MainContainer extends Component<MainContainerProps> {
  public componentDidMount() {
    this.props.subscribeToMore &&
      this.props.subscribeToMore({
        document: AdvisorNotificationSubscription,
        variables: null,
        onError: (err: any) => {
          console.log('err', err)
        },
        updateQuery: (
          prev: any,
          { subscriptionData }: { subscriptionData: any }
        ) => {
          const {
            id,
            subject,
            content,
            createdDate,
            title,
            __typename
          } = subscriptionData.data.notification

          const newItem = {
            subject,
            content:
              typeof content === 'string' ? JSON.parse(content) : content,
            createdDate,
            __typename
          }

          SyncHelper(this.props.dispatch).refresh(newItem)
          const updatedCache = Object.assign({}, prev, {
            allAdviserToNotifications: {
              nodes: [
                {
                  notificationByNotification: {
                    ...newItem,
                    title,
                    __typename: 'Notification'
                  },
                  read: false,
                  id,
                  delivered: false,
                  readDate: null,
                  __typename: 'AdviserToNotification'
                },
                ...prev.allAdviserToNotifications.nodes
              ],
              __typename: 'AdviserToNotificationsConnection'
            }
          })
          return updatedCache
        }
      })
  }
  public render() {
    const { notifications, children } = this.props
    return (
      <div>
        {!this.props.noHeader ? <Header notifications={notifications} /> : null}
        {children}
      </div>
    )
  }
}

export default connect()(MainContainer)
