import { StrategiesInterface, StrategiesObj } from '../objects/strategies'
import * as actions from '../actions/strategies'
export interface StrategiesState {
  [householdId: string]: StrategiesInterface
}

const initState: StrategiesState = {}

export const mapStrategies = (strategies: StrategiesObj[]) => {
  const strategyList: StrategiesInterface = {}
  strategies.forEach((strategy) => {
    strategyList[strategy.id] = {
      id: strategy.id,
      name: strategy.name,
      value: strategy.value,
      type: strategy.type,
      url: strategy.url,
      shortName: strategy.shortName,
      bulletPoint: strategy.bulletPoint,
      factSheetBullets: strategy.factSheetBullets,
      disclaimer: strategy.disclaimer,
      attribute: strategy.attribute,
      yield: strategy.yield,
      duration: strategy.duration,
      expenseRatio: strategy.expenseRatio,
      maturity: strategy.maturity,
      equity: strategy.equity,
      cash: strategy.cash,
      fixed: strategy.fixed,
      investmentMinimum: strategy.investmentMinimum,
      taxStatus: strategy.taxStatus,
      fees: strategy.fees ? strategy.fees : 0,
      alt: strategy.alt,
      createdDate: strategy.createdDate,
      scores: {
        tax: strategy.scores && strategy.scores.tax,
        cost: strategy.scores && strategy.scores.cost,
        performance: strategy.scores && strategy.scores.performance,
        protection: strategy.scores && strategy.scores.protection,
        global: strategy.scores && strategy.scores.global,
        domestic: strategy.scores && strategy.scores.domestic,
        stocks: strategy.scores && strategy.scores.stocks,
        bonds: strategy.scores && strategy.scores.bonds
      },
      maxDefensivePosture: {
        equity:
          strategy.maxDefensivePosture && strategy.maxDefensivePosture.equity,
        cash: strategy.maxDefensivePosture && strategy.maxDefensivePosture.cash,
        fixed:
          strategy.maxDefensivePosture && strategy.maxDefensivePosture.fixed,
        alt: strategy.maxDefensivePosture && strategy.maxDefensivePosture.alt
      },
      sectorWeightings: strategy.sectorWeightings,
      geographicWeightings: strategy.geographicWeightings,
      creditWeightings: strategy.creditWeightings,
      ucfaFactSheetUrl: strategy.ucfaFactSheetUrl,
      finlifeFactSheetUrl: strategy.finlifeFactSheetUrl,
      restrictedFromQualifiedAccounts: strategy.restrictedFromQualifiedAccounts,
      altWarning: strategy.altWarning
    }
  })

  return strategyList
}

const Strategies = (state = initState, action: any): StrategiesState => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case actions.GET_STRATEGIES_FULFILLED: {
      const strategies = mapStrategies(action.payload.data)
      newState[action.payload.data.householdFinId] = strategies

      return newState
    }
    case actions.ADD_STRATEGIES:
    // const details = action.payload;
    // return [...state, details];
    case actions.REMOVE_STRATEGIES:
    // return state.filter(({ id }) => id !== action.id);
    default:
      return state
  }
}

export default Strategies
