import React, { Component } from 'react'
import Modal from '../../../../components/layout/modal'
import Button from '../../../../components/button'
import ActionRow from './actionRow'
import { ContactsInterface } from '../../../../../objects/contact'
import {
  HouseholdCardObj,
  HonestConversationsExerciseObj,
  CardScoreRequestObj,
  CardScoreInterface
} from '../../../../../objects/honestConversations'
import { CardCategoryType } from '../../../../../objects/HonestConversations/card'
import { GlobalState } from '../../../../../reducers'
import { Dispatch, connect } from 'react-redux'
import {
  addGoalActions,
  getGoalActions,
  editGoalActions
} from '../../../../../actions/financialGoalActions'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import {
  createCardScoreAndUpdateScoringSessionv2,
  getHonestConversationExercise,
  updateScoreCard,
  updateHonestConversationHouseholdCards
} from '../../../../../actions/hcExercises'
import {
  LifeActionUpdate,
  statusType,
  ActionEditInterface
} from '../../../../../objects/lifeActions'
import { getCombinedCardActions } from '../../../../helpers/honestConversations'
import { isObjEmpty } from '../../../../helpers'
import { CombinedCardActionInput } from './actionInput'
import ScoreModalBody from './scoreSelectionModalBody'
import goalActionsSelector from '../../../../../selectors/v3/goals'

export interface ScoreSelectionModalProps {
  dispatch: Dispatch<GlobalState>
  contacts: ContactsInterface
  card: HouseholdCardObj
  exerciseId: string
  hcExercise: HonestConversationsExerciseObj
  householdFinId: string
  goalActions: FinancialGoalActionObj[]
  closeScoreSelectionModal(e?: any): void
}

interface ScoreSelectionModalState {
  primaryScore: number
  secondaryScore: number
  savedHouseholdCards: HouseholdCardObj[]
  householdCards: HouseholdCardObj[]
  loadedCard: HouseholdCardObj
  loadedCardActions: any
  cardActionsDisplayNodes: React.ReactNode[]
  lifeActionUpdate: LifeActionUpdate
  showAssignOwnerModal: boolean
  actionEdits: ActionEditInterface
  showCommentView: boolean
  primaryComment: string
  secondaryComment: string
}

const emptyLifeActionUpdate: LifeActionUpdate = {
  assigneePrimaryId: null,
  assigneeSecondaryId: null,
  householdId: null,
  name: null,
  dueDate: null,
  status: null,
  comment: null
}

export class ScoreSelectionModal extends Component<
  ScoreSelectionModalProps,
  ScoreSelectionModalState
> {
  // ensure we clear input value
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef()

  constructor(props: ScoreSelectionModalProps) {
    super(props)
    const {
      hcExercise,
      contacts,
      card,
      goalActions,
      householdFinId
    } = this.props

    const loadedCardActions = getCombinedCardActions(goalActions, card)

    this.state = {
      primaryScore: this.getScore(
        contacts.primary.id,
        card,
        hcExercise.activeScoringSessionPrimary
      ),
      secondaryScore:
        contacts.secondary &&
        this.getScore(
          contacts.secondary.id,
          card,
          hcExercise.activeScoringSessionSecondary
        ),
      savedHouseholdCards: [],
      householdCards: this.getHouseholdCards(),
      loadedCard: card,
      lifeActionUpdate: {
        ...emptyLifeActionUpdate,
        householdId: householdFinId,
        status: statusType.na
      },
      loadedCardActions,
      cardActionsDisplayNodes: null,
      showAssignOwnerModal: false,
      actionEdits: {},
      showCommentView: false,
      primaryComment: card.commentPrimary,
      secondaryComment: card.commentSecondary
    }
  }

  public getHouseholdCards = (): HouseholdCardObj[] => {
    const { hcExercise } = this.props
    if (!hcExercise || !Object.keys(hcExercise.householdCards).length) {
      return null
    }
    return Object.keys(hcExercise.householdCards)
      .map((key) => hcExercise.householdCards[key])
      .sort((a, b) => a.ranking - b.ranking)
  }

  public componentDidUpdate = (prevProps: ScoreSelectionModalProps) => {
    const { goalActions, card } = this.props

    // Updating lifeactions
    if (
      prevProps.goalActions &&
      goalActions &&
      Object.keys(goalActions).length !==
        Object.keys(prevProps.goalActions).length
    ) {
      this.setState({
        loadedCardActions: getCombinedCardActions(goalActions, card)
      })
    }
  }

  public componentDidMount = async () => {
    const { contacts, hcExercise, card, householdFinId, dispatch } = this.props
    const cardActionsDisplayNodes =
      this.state.loadedCardActions &&
      this.getActionDisplayList(this.state.loadedCardActions)
    await dispatch(getHonestConversationExercise(householdFinId, hcExercise.id))

    const primaryScore = this.getScore(
      contacts.primary.id,
      card,
      hcExercise.activeScoringSessionPrimary
    )
    const secondaryScore = contacts.secondary
      ? this.getScore(
          contacts.secondary.id,
          card,
          hcExercise.activeScoringSessionSecondary
        )
      : null

    this.setState({
      primaryScore,
      secondaryScore,
      savedHouseholdCards: [],
      householdCards: this.getHouseholdCards(),
      cardActionsDisplayNodes
    })
  }

  public renderCardTitle = () => {
    const {
      loadedCard: { card: { category = '', title = '' } = {} } = {}
    } = this.state
    return (
      <div>
        <div className='score-select-modal__category'>
          {category.toUpperCase()}
        </div>
        <div className='score-select-modal__title'>{title}</div>
      </div>
    )
  }

  public toggleCommentView = () => {
    this.setState({ showCommentView: !this.state.showCommentView })
  }

  public onCommentChange = (comment: string, isPrimary: boolean) => {
    const stateName: string = `${isPrimary ? 'primary' : 'secondary'}Comment`
    // tslint:disable-next-line: no-object-literal-type-assertion
    this.setState({ [stateName]: comment } as Pick<
      ScoreSelectionModalState,
      'primaryComment' | 'secondaryComment'
    >)
  }

  public cardColor = (category: CardCategoryType) => {
    switch (category) {
      case 'Commitment':
        return '#0068AE'
      case 'Happiness':
        return '#F49500'
      case 'Protection':
        return '#A7D500'
      default:
        return 'white'
    }
  }

  public onScoreChange = (score: number, isPrimary: boolean) => {
    const { primaryScore, secondaryScore } = this.state
    this.setState({
      primaryScore: isPrimary ? score : primaryScore,
      secondaryScore: !isPrimary ? score : secondaryScore
    })
  }

  public getScore = (
    householdContactId: string,
    newAvailableCards: HouseholdCardObj,
    scoringSessionId: string
  ): number => {
    const { hcExercise } = this.props

    const cardScores =
      hcExercise.clientScoringSessions[householdContactId][scoringSessionId] &&
      hcExercise.clientScoringSessions[householdContactId][scoringSessionId]
        .cardScores
    let modalCardScore = null

    if (!newAvailableCards || !cardScores) return modalCardScore

    Object.keys(cardScores).forEach((cardScoreKey) => {
      if (
        cardScores[cardScoreKey]?.householdCard?.cardId ===
        newAvailableCards.card.id
      ) {
        modalCardScore = cardScores[cardScoreKey].score
      }
    })

    return modalCardScore
  }

  public addMissingCards = (
    propCards: HouseholdCardObj[],
    stateCards: HouseholdCardObj[],
    exerciseId: string
  ): HouseholdCardObj[] => {
    const checkCard = (householdCard: HouseholdCardObj): HouseholdCardObj => {
      let existingCard: HouseholdCardObj = null
      stateCards.forEach((stateCard: HouseholdCardObj) => {
        existingCard =
          stateCard.id === householdCard.id ? stateCard : existingCard
      })
      return existingCard
    }
    return propCards.map((propCard: HouseholdCardObj) => {
      const card = checkCard(propCard)
      return card || { ...propCard, exerciseId, cardId: propCard.card.id }
    })
  }

  public loadNextCard = (targetCard: HouseholdCardObj) => {
    const {
      closeScoreSelectionModal,
      goalActions,
      hcExercise,
      contacts
    } = this.props

    const { householdCards, savedHouseholdCards } = this.state
    const propHouseholdCards = this.getHouseholdCards()
    let householdCardsList = householdCards
    if (
      !savedHouseholdCards.length &&
      propHouseholdCards.length === 5 &&
      propHouseholdCards.length > householdCards.length
    ) {
      householdCardsList = this.addMissingCards(
        propHouseholdCards,
        householdCards,
        hcExercise.id
      )
    }
    const newAvailableCards = householdCardsList.filter(
      (availableCard: HouseholdCardObj) => {
        return (
          availableCard?.id !== targetCard?.id &&
          availableCard?.ranking > targetCard?.ranking
        )
      }
    )

    let relatedActions: any = null
    if (newAvailableCards.length) {
      relatedActions = getCombinedCardActions(goalActions, newAvailableCards[0])
    }

    if (!newAvailableCards.length) {
      closeScoreSelectionModal()
    }

    const primaryScoreState = this.getScore(
      contacts.primary.id,
      newAvailableCards[0],
      hcExercise.activeScoringSessionPrimary
    )

    const secondaryScore =
      (contacts.secondary &&
        this.getScore(
          contacts.secondary.id,
          newAvailableCards[0],
          hcExercise.activeScoringSessionSecondary
        )) ||
      null

    const cardActionsDisplayNodes: any =
      relatedActions && this.getActionDisplayList(relatedActions)
    if (!newAvailableCards.length) {
      closeScoreSelectionModal()
      return
    }
    this.setState({
      primaryScore: primaryScoreState,
      secondaryScore,
      savedHouseholdCards: [...savedHouseholdCards, targetCard],
      householdCards: newAvailableCards,
      loadedCard: newAvailableCards[0],
      loadedCardActions: relatedActions,
      cardActionsDisplayNodes,
      showCommentView: false,
      primaryComment: newAvailableCards[0].commentPrimary,
      secondaryComment: newAvailableCards[0].commentSecondary
    })
  }

  public mapCardScoreUpdates = (
    cardScores: CardScoreInterface,
    modalCard: HouseholdCardObj,
    updateScore: number
  ): CardScoreRequestObj[] => {
    return Object.keys(cardScores).map(
      (scoreCardKey: string, index: number) => {
        const currentCard = cardScores[scoreCardKey]

        const householdCardId: string =
          currentCard.householdCard?.id || modalCard.id

        const newScore =
          modalCard.id === currentCard?.householdCard?.id
            ? updateScore
            : currentCard.score

        return {
          cardScoreId: scoreCardKey,
          comment: currentCard.comment,
          score: newScore,
          householdCardId
        }
      }
    )
  }

  public newLifeAction = (newLifeAction: LifeActionUpdate) => {
    this.setState({
      lifeActionUpdate: newLifeAction
    })
  }

  public newActionAssignee = (assigneeId: string, contact: string) => {
    const updatedLifeAction: LifeActionUpdate = this.state.lifeActionUpdate
    if (contact === 'Primary') {
      updatedLifeAction.assigneePrimaryId = assigneeId || null
    } else if (contact === 'Secondary') {
      updatedLifeAction.assigneeSecondaryId = assigneeId || null
    }
    this.setState({ lifeActionUpdate: updatedLifeAction })
  }

  public assignSecondaryToggle = (contactId: string) => {
    const updatedLifeAction: LifeActionUpdate = this.state.lifeActionUpdate
    updatedLifeAction.assigneeSecondaryId = (contactId && contactId) || null

    this.setState({ lifeActionUpdate: updatedLifeAction })
  }

  public nameNewLifeAction = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = this.state
    const { name, value } = e.target
    newState.lifeActionUpdate[name] = value
    this.setState(newState)
  }

  public createNewLifeAction = async () => {
    const { dispatch, householdFinId } = this.props
    const { lifeActionUpdate, loadedCard } = this.state

    const goalAction: FinancialGoalActionObj = {
      id: null,
      cards: [loadedCard.card],
      assigneePrimaryId: lifeActionUpdate.assigneePrimaryId,
      assigneeSecondaryId: lifeActionUpdate.assigneeSecondaryId,
      name: lifeActionUpdate.name,
      note: '',
      status: 'NA | Pending Review',
      type: 'life',
      dueDate: null,
      inGuidecenter: true,
      customGoal: true
    }

    if (lifeActionUpdate.name) {
      await dispatch(addGoalActions(goalAction, householdFinId))
      await dispatch(getGoalActions(householdFinId))

      const loadedCardActions: any = getCombinedCardActions(
        this.props.goalActions,
        loadedCard
      )

      const cardActionsDisplayNodes =
        loadedCardActions && this.getActionDisplayList(loadedCardActions)

      // reset and clear the input
      this.setState(
        {
          lifeActionUpdate: {
            ...emptyLifeActionUpdate,
            householdId: householdFinId
          },
          loadedCardActions,
          cardActionsDisplayNodes
        },
        () => {
          this.inputRef.current.value = null
        }
      )
    }
  }

  public toggleAssignOwnerModal = () => {
    this.setState({
      showAssignOwnerModal: !this.state.showAssignOwnerModal
    })
  }

  public scoreSessionServicer = async (
    contactId: string,
    contactScoringSessionId: string,
    newScore: number
  ) => {
    const { dispatch, hcExercise, householdFinId } = this.props
    const { loadedCard } = this.state

    const cardScoreRequestObj = [
      {
        householdCardId: loadedCard.id,
        score: newScore
      }
    ]

    const activeScoreCards =
      hcExercise.clientScoringSessions[contactId][contactScoringSessionId] &&
      hcExercise.clientScoringSessions[contactId][contactScoringSessionId]
        .cardScores

    const cardScoreIds = Object.keys(activeScoreCards)

    const activeScoreCardUpdates = this.mapCardScoreUpdates(
      activeScoreCards,
      loadedCard,
      newScore
    )

    await dispatch(
      createCardScoreAndUpdateScoringSessionv2(
        contactId,
        householdFinId,
        hcExercise.id,
        contactScoringSessionId,
        cardScoreRequestObj,
        cardScoreIds
      )
    )

    await updateScoreCard(
      householdFinId,
      contactId,
      hcExercise.id,
      contactScoringSessionId,
      activeScoreCardUpdates
    )
    await dispatch(getHonestConversationExercise(householdFinId, hcExercise.id))
  }

  public saveCombinedCardComment = () => {
    const {
      contacts: { secondary } = {},
      hcExercise: { householdCards = {} } = {}
    } = this.props
    const {
      primaryComment,
      secondaryComment,
      loadedCard: { commentPrimary, commentSecondary },
      loadedCard
    } = this.state

    const condition = secondary
      ? primaryComment !== commentPrimary ||
        secondaryComment !== commentSecondary
      : primaryComment !== commentPrimary

    if (condition) {
      const filteredHHCards: HouseholdCardObj[] = Object.values(
        householdCards || {}
      )?.filter((card) => {
        return card?.card?.id !== loadedCard?.card?.id
      })
      const updatedCard = [
        {
          ...loadedCard,
          ...{
            commentPrimary: primaryComment,
            commentSecondary: secondaryComment
          }
        }
      ]
      updateHonestConversationHouseholdCards(
        this.props.householdFinId,
        this.props.hcExercise.id,
        [...filteredHHCards, ...updatedCard]
      )
    }
  }

  public save = async () => {
    const { hcExercise, contacts } = this.props
    const { primaryScore, secondaryScore, loadedCard, actionEdits } = this.state
    if (actionEdits && !isObjEmpty(actionEdits)) this.updateLifeAction()
    // create a card score for each contact and attach it to the contact's scoring session
    const {
      activeScoringSessionPrimary,
      activeScoringSessionSecondary
    } = hcExercise

    // Saving Combined Card Comment in hcExcercise -> HousehodCards Object
    //this.saveCombinedCardComment()

    await this.createNewLifeAction()

    // create primary card score and update scoring session
    if (contacts.primary && activeScoringSessionPrimary && primaryScore) {
      await this.scoreSessionServicer(
        contacts.primary.id,
        activeScoringSessionPrimary,
        primaryScore
      )
    }

    // create secondary card score and update scoring session
    if (contacts.secondary && activeScoringSessionSecondary && secondaryScore) {
      await this.scoreSessionServicer(
        contacts.secondary.id,
        activeScoringSessionSecondary,
        secondaryScore
      )
    }

    this.loadNextCard(loadedCard)
  }

  public updateLifeAction = async () => {
    const { card, goalActions, householdFinId, dispatch } = this.props
    const { actionEdits } = this.state
    const loadedCardActions = getCombinedCardActions(goalActions, card)

    if (actionEdits && !isObjEmpty(actionEdits)) {
      Object.keys(actionEdits).forEach((actionId) => {
        const updatedAction = loadedCardActions.find(
          (action) => action.id === actionId
        )
        const { name, assigneePrimaryId, assigneeSecondaryId } = actionEdits[
          actionId
        ]

        // set `any` as our types require the full obj when edits here don't
        // lifeActionUpdateObj: FinancialGoalActionObj
        const lifeActionUpdateObj: any = {
          ...updatedAction,
          name,
          assigneePrimaryId,
          assigneeSecondaryId,
          householdId: householdFinId
        }

        dispatch(editGoalActions(lifeActionUpdateObj, householdFinId))
      })
    }
  }

  public editActionName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const { actionEdits } = this.state
    this.setState(
      {
        actionEdits: {
          ...actionEdits,
          [name]: { ...actionEdits[name], name: value }
        }
      },
      () => {
        const { loadedCardActions } = this.state
        const cardActionsDisplayNodes =
          loadedCardActions && this.getActionDisplayList(loadedCardActions)
        this.setState({ cardActionsDisplayNodes })
      }
    )
  }

  public editActionAssignee = (
    assigneeId: string,
    contactType: string,
    actionId: string
  ) => {
    const { actionEdits } = this.state
    this.setState(
      {
        actionEdits: {
          ...actionEdits,
          [actionId]: {
            ...actionEdits[actionId],
            [`assignee${contactType}Id`]: assigneeId
          }
        }
      },
      () => {
        const { loadedCardActions } = this.state
        const cardActionsDisplayNodes =
          loadedCardActions && this.getActionDisplayList(loadedCardActions)
        this.setState({ cardActionsDisplayNodes })
      }
    )
  }

  public getActionDisplayList = (
    loadedCardActions: FinancialGoalActionObj[]
  ): React.ReactNode[] => {
    const { contacts } = this.props
    return (
      loadedCardActions &&
      loadedCardActions
        .filter((action: FinancialGoalActionObj) => action.inGuidecenter)
        .map((action: FinancialGoalActionObj, i: number) => {
          return (
            <ActionRow
              key={i}
              contacts={contacts}
              lifeAction={action}
              actionEdits={this.state?.actionEdits}
              editActionName={this.editActionName}
              updateActionAssignee={this.editActionAssignee}
            />
          )
        })
    )
  }

  public render() {
    const { closeScoreSelectionModal, contacts } = this.props
    const {
      primaryScore,
      secondaryScore,
      loadedCard: { card: { category, id: cardId } = {}, ranking } = {},
      showAssignOwnerModal,
      cardActionsDisplayNodes,
      lifeActionUpdate
    } = this.state

    return (
      <Modal
        title={this.renderCardTitle()}
        closeModal={closeScoreSelectionModal}
        headerStyle={{
          backgroundColor: this.cardColor(category)
        }}>
        <div className='score-select-modal'>
          {contacts && contacts.secondary && (
            <ScoreModalBody
              key={ranking}
              contact={contacts.secondary}
              onCommentChange={this.onCommentChange}
              isPrimary={false}
              cardId={cardId}
              comment={this.state.secondaryComment}
              score={secondaryScore}
              modalElement={true}
              onScoreChange={this.onScoreChange}
            />
          )}
          {contacts && contacts.primary && (
            <ScoreModalBody
              key={ranking + 2}
              contact={contacts.primary}
              onCommentChange={this.onCommentChange}
              isPrimary={true}
              cardId={cardId}
              comment={this.state.primaryComment}
              score={primaryScore}
              modalElement={true}
              onScoreChange={this.onScoreChange}
            />
          )}

          {cardActionsDisplayNodes}

          <CombinedCardActionInput
            inputRef={this.inputRef}
            contacts={contacts}
            showModal={showAssignOwnerModal}
            lifeActionUpdate={lifeActionUpdate}
            createLifeAction={this.createNewLifeAction}
            nameLifeAction={this.nameNewLifeAction}
            newActionAssignee={this.newActionAssignee}
            toggleModal={this.toggleAssignOwnerModal}
          />

          <div className='score-select-modal__actions-w'>
            <Button clear={true} onClick={closeScoreSelectionModal}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.save}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, exerciseId }: any
) => {
  return {
    hcExercise:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][exerciseId],
    householdFinId,
    contacts: store.contact[householdFinId],
    goalActions: goalActionsSelector(store, householdFinId)
  }
}

export default connect(mapStateToProps)(ScoreSelectionModal)
