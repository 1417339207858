import React from 'react'
import Modal from '../../components/layout/modal'
import Input from '../../components/Input'
import { GlobalState } from '../../../reducers'
import * as actions from '../../../actions/clientAccounts'
import { connect, Dispatch } from 'react-redux'
import {
  DiscreteAllocation,
  ClientAccountObj,
  emptyDiscreteAllocation
} from '../../../objects/clientAccount'
import { rollupOriginalAllocations } from '../../helpers/clientAccounts'
import ToggleSwitch from '../../components/toggleSwitch'

import overrideIcon from '../../assets/images/icons/png/ic_updates_sm.png'

interface EditOverrideAllocationProps {
  householdFinId: string
  clientAccountId: string
  clientAccount: ClientAccountObj
  discreteAllocation: DiscreteAllocation
  dispatch: Dispatch<GlobalState>
  closeModal(): void
}

interface EditOverrideAllocationState {
  discreteAllocationState: DiscreteAllocation
  allocationWarning: boolean
  overrideAllocations: boolean
}

class EditOverrideAllocation extends React.Component<
  EditOverrideAllocationProps,
  EditOverrideAllocationState
> {
  constructor(props: EditOverrideAllocationProps) {
    super(props)
    this.state = {
      discreteAllocationState: emptyDiscreteAllocation,
      allocationWarning: false,
      overrideAllocations: true
    }
  }
  public componentDidMount() {
    const { discreteAllocation } = this.props.clientAccount
    const newState = this.state
    Object.keys(newState.discreteAllocationState).forEach((allocationType) => {
      newState.discreteAllocationState[allocationType] =
        discreteAllocation[allocationType] !== null ||
        discreteAllocation[allocationType] !== undefined
          ? discreteAllocation[allocationType]
          : newState.discreteAllocationState[allocationType]
    })
    this.setState(newState)
  }

  public handleToggle = () => {
    this.setState({ overrideAllocations: !this.state.overrideAllocations })
  }

  public checkAllocations = async () => {
    const { discreteAllocationState } = this.state
    if (this.state.overrideAllocations) {
      // filter
      const arrayOfAllocations: number[] = [
        Number(discreteAllocationState.alt),
        Number(discreteAllocationState.cash),
        Number(discreteAllocationState.equity),
        Number(discreteAllocationState.fixed),
        Number(discreteAllocationState.unclassified)
      ].filter((allocationNum) => !isNaN(allocationNum))
      // sum
      const sumOfAllocations: number = arrayOfAllocations.reduce(
        (prev, next) => prev + next,
        0
      )
      if (sumOfAllocations === 100 || sumOfAllocations === 0) {
        if (sumOfAllocations === 0) {
          discreteAllocationState.unclassified = 100
        }
        this.setState({
          allocationWarning: false,
          discreteAllocationState
        })
        this.handleUpdateTask()
      } else {
        this.setState({ allocationWarning: true })
      }
    } else {
      await this.revertAllocations()
      this.handleUpdateTask()
    }
  }

  public revertAllocations = () => {
    const newState = { ...this.state.discreteAllocationState }
    newState.alt = null
    newState.cash = null
    newState.equity = null
    newState.fixed = null
    newState.unclassified = null
    this.setState({ discreteAllocationState: newState })
  }

  public inputSetter = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.currentTarget
    let parsedValue = parseInt(value, 0)
    if (parsedValue < 0) {
      parsedValue = 0
    }
    const newState = this.state
    newState.discreteAllocationState[name] = parsedValue
    this.setState({ ...newState, allocationWarning: false })
  }

  public sanitize = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.currentTarget
    let parsedValue = parseInt(value, 0)
    if (
      parsedValue < 0 ||
      parsedValue > 100 ||
      parsedValue.toString() === 'NaN'
    ) {
      parsedValue = 0
    }
    const newState = this.state
    newState.discreteAllocationState[name] = parsedValue
    this.setState({ ...newState, allocationWarning: false })
  }

  public handleUpdateTask = () => {
    const { clientAccountId, householdFinId, clientAccount } = this.props
    const { discreteAllocationState } = this.state
    this.props.dispatch(
      actions.updateOverrideAllocation(clientAccountId, householdFinId, {
        discreteAllocation: discreteAllocationState,
        accountNickname: clientAccount.accountNickname
      })
    )
    this.props.closeModal()
  }

  public inputChanged = (inputFieldName: string) => {
    const { discreteAllocationState } = this.state
    const { discreteAllocation } = this.props.clientAccount
    let isChanged: boolean = false
    if (discreteAllocation !== null || discreteAllocation !== undefined) {
      if (
        discreteAllocationState !== null ||
        discreteAllocationState !== undefined
      ) {
        isChanged =
          discreteAllocation[inputFieldName] !==
          discreteAllocationState[inputFieldName]
      }
    }
    return isChanged
  }

  public inputFields = (
    titleName: string,
    contents: any,
    inputFieldName?: string,
    edit?: boolean
  ) => {
    return (
      <div className='form-group'>
        <Input
          isChanged={this.inputChanged(inputFieldName)}
          title={titleName}
          inputType='number'
          name={inputFieldName}
          controlFunc={edit ? null : this.inputSetter}
          onBlur={edit ? null : this.sanitize}
          content={Math.round(contents)}
          placeholder='0'
          percentageSign={true}
          min={0}
          max={100}
          readOnly={edit || false}
        />
      </div>
    )
  }

  public render() {
    const { closeModal, clientAccount } = this.props
    const { discreteAllocationState, allocationWarning } = this.state
    const displayEquity =
      discreteAllocationState.equity !== undefined
        ? discreteAllocationState.equity
        : 0
    const displayFixed =
      discreteAllocationState.fixed !== undefined
        ? discreteAllocationState.fixed
        : 0
    const displayAlt =
      discreteAllocationState.alt !== undefined
        ? discreteAllocationState.alt
        : 0
    const displayCash =
      discreteAllocationState.cash !== undefined
        ? discreteAllocationState.cash
        : 0
    const displayUnclassified =
      discreteAllocationState.cash !== undefined
        ? discreteAllocationState.unclassified
        : 0
    const setAllocationsErrorDisplay = () => {
      const setAllocations =
        Number(discreteAllocationState.alt) +
        Number(discreteAllocationState.cash) +
        Number(discreteAllocationState.equity) +
        Number(discreteAllocationState.fixed) +
        Number(discreteAllocationState.unclassified)
      return isNaN(setAllocations) ? 0 : setAllocations
    }
    const {
      equity,
      fixed,
      alt,
      cash,
      unclassified
    } = rollupOriginalAllocations(clientAccount)
    return (
      <Modal
        iconPng={overrideIcon}
        title='OVERRIDE ALLOCATION'
        closeModal={closeModal}>
        <div className='modal-body client-account-modal'>
          <div className='inv-accounts__modal-title'>
            Real Allocation Values
          </div>
          <div className='form-inline client-account-modal__allocation'>
            {this.inputFields('Equity', equity, null, true)}
            {this.inputFields('Fixed', fixed, null, true)}
            {this.inputFields('Alt.', alt, null, true)}
            {this.inputFields('Cash', cash, null, true)}
            {this.inputFields('Unclassified', unclassified, null, true)}
          </div>
          <div className='inv-accounts__modal-title'>
            Override Allocation Values{' '}
            <span className='inv-accounts__modal-title--toggle'>
              <ToggleSwitch
                active={this.state.overrideAllocations}
                handleToggleActive={this.handleToggle}
              />
            </span>
          </div>
          <div className='form-inline client-account-modal__allocation'>
            {this.inputFields('Equity', displayEquity, 'equity')}
            {this.inputFields('Fixed', displayFixed, 'fixed')}
            {this.inputFields('Alt.', displayAlt, 'alt')}
            {this.inputFields('Cash', displayCash, 'cash')}
            {this.inputFields(
              'Unclassified',
              displayUnclassified,
              'unclassified'
            )}
          </div>
          {allocationWarning ? (
            <p style={{ color: '#F12938' }}>
              Allocation must equal to 100%, current set allocation{' '}
              {setAllocationsErrorDisplay()}%
            </p>
          ) : null}
          <div className='pal-confirm-modal__buttons'>
            <span className='btn btn__clear' onClick={closeModal}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={this.checkAllocations}>
              Save
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, clientAccountId, clientAccount }: any
) => {
  return {
    clientAccount,
    householdFinId,
    clientAccountId
  }
}

export default connect(mapStateToProps)(EditOverrideAllocation)
