import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { isObjEmpty } from '../../../../helpers'
import PositionsTile from './tile'
import { HouseholdObj } from '../../../../../objects/household'
import {
  ClientAccountObjState,
  ClientAccountInterface,
  ClientAccountObj
} from '../../../../../objects/clientAccount'
import { ContactsInterface } from '../../../../../objects/contact'
import PortfolioHeader from './portfolioHeader'

import { PortfolioAnalyticsFunc } from '../../../../../objects/PortfolioAnalytics/analytics'

export interface PositionsProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  household: HouseholdObj
  clientAccounts: ClientAccountObjState
  contact: ContactsInterface
  featureFunc: PortfolioAnalyticsFunc
}

export class Positions extends Component<PositionsProps> {
  public registrationTypesSorters = () => {
    const { clientAccounts = {} } = this.props
    const registerTypes = { Unclassified: {} }
    Object.values(clientAccounts).forEach((clientAccount) => {
      const { registrationType = null, id, pssSelected } = clientAccount
      if (pssSelected) {
        if (registrationType && !registerTypes[registrationType]) {
          registerTypes[registrationType] = {}
        }
        registerTypes[registrationType || `Unclassified`][id] = clientAccount
      }
    })

    return registerTypes
  }

  /**
   *
   * @param accounts
   * Expectations :
   * 1. return sum of all positions
   * or
   * 2. return sum of like registration account types if no positions exists
   *
   */
  public accountsTotalRegType = (accounts: ClientAccountInterface) => {
    // check if at least one account has positions
    const hasPositions = Object.values(accounts).filter(
      ({ positions }) => Object.values(positions).length > 0
    )
    let sum = 0
    Object.values(accounts).forEach((account: ClientAccountObj) => {
      const { positions, totalValue } = account
      // override the totalValue
      const override = hasPositions.length > 0 ? 0 : totalValue
      const positionsArray =
        !Object.is(positions, null) && Object.values(positions)
      const sumOf =
        positionsArray.reduce((total, { value }) => {
          return total + (isNaN(value) ? 0 : value)
        }, 0) || override

      sum = sumOf + sum
    })

    return sum
  }

  public registerTypesTiles = () => {
    const { featureFunc } = this.props
    const registerTypes = this.registrationTypesSorters()
    const tileDisplay = [
      ...Object.keys(registerTypes).filter(
        (regTypeName) => regTypeName !== 'Unclassified'
      ),
      ...Object.keys(registerTypes).filter(
        (regTypeName) => regTypeName === 'Unclassified'
      )
    ]
      .filter((key) => {
        return !isObjEmpty(registerTypes[key]) && registerTypes[key]
      })
      .map((registrationType, index: number) => {
        const sum = this.accountsTotalRegType(registerTypes[registrationType])
        return (
          <PositionsTile
            key={index}
            title={registrationType}
            registrationTypeSum={sum}
            accounts={registerTypes[registrationType]}
            featureFunc={featureFunc}
          />
        )
      })

    return tileDisplay.length ? tileDisplay : null
  }

  public render = () => {
    const { householdFinId, contact, featureFunc } = this.props
    return (
      <div className='im-portfolio__main-w'>
        <PortfolioHeader
          householdFinId={householdFinId}
          contact={contact}
          featureFunc={featureFunc}
        />
        {this.registerTypesTiles()}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const {
    params: { householdFinId }
  } = match
  const { households, clientAccount, contact } = store

  return {
    householdFinId,
    clientAccounts:
      !isObjEmpty(clientAccount) &&
      clientAccount[householdFinId].clientAccounts,
    contact: !isObjEmpty(contact) && contact[householdFinId],
    household: !isObjEmpty(households) && households[householdFinId]
  }
}

export default connect(mapStateToProps)(Positions)
