import React from 'react'
import {
  dependantWebNotificationSettings,
  captionList
} from '../../../objects/settings/clientAdvisor'
import ToggleSwitch from '../toggleSwitch'
import Tooltip from '../tooltip'

export const EmailToggle: React.FC<{
  disabled: boolean
  settingName: string
  settingValue: boolean
  requiredSettingValue: boolean
  handler(event: React.ChangeEvent<HTMLInputElement>): void
}> = (props) => {
  const {
    disabled,
    settingName,
    settingValue,
    requiredSettingValue,
    handler
  } = props

  const dependantSettingMessage = dependantWebNotificationSettings[settingName]
  const disabledState =
    (!requiredSettingValue && Boolean(dependantSettingMessage)) || disabled

  const Toggle = (
    <ToggleSwitch
      disable={disabledState}
      name={`EMAIL_${settingName}`}
      active={settingValue}
      handleToggleActive={handler}
    />
  )

  const Wrapper = (child: JSX.Element) => (
    <Tooltip
      message={dependantSettingMessage}
      width={150}
      multiLine={true}
      position='bottom'>
      {child}
    </Tooltip>
  )

  return !requiredSettingValue && dependantSettingMessage
    ? Wrapper(Toggle)
    : Toggle
}

export const ToggleRow: React.FC<{
  desktopActive: boolean
  disabled: boolean
  mobileActive: boolean
  mobileSupported: boolean
  emailActive: boolean
  emailSupported: boolean
  settingName: any
  className?: string
  handler(event: React.ChangeEvent<HTMLInputElement>): void
}> = (props) => {
  const {
    disabled,
    className,
    settingName,
    handler,
    emailSupported,
    emailActive,
    desktopActive,
    mobileActive,
    mobileSupported
  } = props

  /**
   * dangerouslySetInnerHTML <sup> : set the name inline
   */

  return (
    <div className={`a-settings__row ${className}`} key={settingName}>
      <span
        className='toggle-caption'
        dangerouslySetInnerHTML={{
          __html: captionList[settingName]
        }}
      />

      <div className='a-settings__row-toggle-w'>
        {mobileSupported ? (
          <ToggleSwitch
            disable={disabled}
            name={`MOBILE_${settingName}`}
            active={mobileActive}
            handleToggleActive={handler}
          />
        ) : (
          <div className='toggle-space' />
        )}

        {emailSupported ? (
          <EmailToggle
            disabled={disabled}
            settingName={settingName}
            settingValue={emailActive}
            requiredSettingValue={desktopActive}
            handler={handler}
          />
        ) : (
          <div />
        )}

        <ToggleSwitch
          disable={disabled}
          name={settingName}
          active={desktopActive}
          handleToggleActive={handler}
        />
      </div>
    </div>
  )
}
