import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'

import IconHappinessWhite from '../../../../assets/images/icons/icon_happiness_white.png'
import IconProtectionWhite from '../../../../assets/images/icons/hc-protection-white.png'
import IconCommitmentWhite from '../../../../assets/images/icons/icon_commitment_white.png'

import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg'
import Modal from '../../../../components/layout/modal'
import React, { Component, CSSProperties, ReactNode } from 'react'

import { TaskDescriptionItem } from './ResultsSummaryComponents'
import { ContactsInterface } from '../../../../../objects/contact'
import { LifeActionObj } from '../../../../../objects/lifeActions'

interface TaskDescriptionModalProps {
  exceriseCard?: any
  contacts: ContactsInterface
  householdFinId: string
  modalCloseFunc(): void
  lifeActions: LifeActionObj[]
}

let headerStyle: CSSProperties = {
  paddingLeft: '10px',
  color: 'white'
}

const iconStyle: CSSProperties = {
  width: '24px',
  margin: 'auto 5px',
  opacity: 0.5
}

const cardTypes = {
  happiness: { color: '#f49500', image: IconHappinessWhite },
  protection: { color: '#8ccc33', image: IconProtectionWhite },
  commitment: { color: '#255eba', image: IconCommitmentWhite }
}

const contentStyle: CSSProperties = {
  padding: '10px 10px'
}

class TaskDescriptionModal extends Component<TaskDescriptionModalProps> {
  public getCardTasks = () => {
    const { contacts, exceriseCard } = this.props
    const taskItemArray: ReactNode[] = []
    const contactAvatars: {
      source: string
      firstName: string
      lastName: string
      isPrimary: boolean
    }[] = []
    contactAvatars.push({
      source: contacts && contacts.primary.photo,
      firstName: contacts && `${contacts.primary.firstName}`,
      lastName: contacts && `${contacts.primary.lastName}`,
      isPrimary: true
    })

    if (contacts.secondary) {
      contactAvatars.push({
        source: contacts && contacts.secondary.photo,
        firstName: contacts && `${contacts.secondary.firstName}`,
        lastName: contacts && `${contacts.secondary.lastName}`,
        isPrimary: false
      })
    }

    exceriseCard.tasks.forEach((task: string, index: number) => {
      taskItemArray.push(
        <TaskDescriptionItem
          key={index}
          index={index}
          longDescription={task}
          avatars={contactAvatars}
        />
      )
    })
    return exceriseCard.tasks
  }

  public getHeader = () => {
    const { exceriseCard, modalCloseFunc } = this.props
    const title = exceriseCard && exceriseCard.title ? exceriseCard.title : ''

    const category =
      exceriseCard && exceriseCard.category
        ? exceriseCard.category.toLocaleLowerCase()
        : 'happiness'
    const cardType = cardTypes[category]

    return (
      <div className='results-summary-action-modal__header'>
        <div className='results-summary-action-modal__icon'>
          <img
            src={`${cardType.image}`}
            alt={`${cardType} Category`}
            style={iconStyle}
          />
        </div>
        <div
          className='results-summary-action-modal__title'
          style={{ color: 'white', fontWeight: 500 }}>
          {title}
        </div>
        <div
          className='results-summary-action-modal__close'
          onClick={modalCloseFunc}>
          <CloseIcon />
        </div>
      </div>
    )
  }

  public render() {
    const { exceriseCard, children } = this.props
    if (!exceriseCard) return null
    headerStyle = {
      ...headerStyle,
      background: cardTypes[exceriseCard.category.toLowerCase()].color
    }
    return (
      <Modal
        contentStyle={contentStyle}
        headerStyle={headerStyle}
        title={this.getHeader()}>
        <div className='results-summary-action-modal__tasks'>
          {children}
          <div className='results-summary-action-modal__tasks results-summary-action-modal__tasks-list'>
            {this.getCardTasks()}
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    contacts: store.contact[householdFinId],
    household: store.households[householdFinId]
  }
}

export default connect(mapStateToProps)(TaskDescriptionModal)
