import { createDeepEqualSelector } from './selectorCreator'
// types
import {
  HouseholdPreferencesObj,
  GoalActionsPreferences
} from '../../objects/householdPreferences'
import { FinancialGoalActionObj } from '../../objects/financialGoalActions'
import { GlobalState } from '../../reducers'
// helpers
import { checkObjFields } from '../../v3/helpers'
import { getClientData, deepSortGoals } from '../../v3/helpers/goals'

/** BEGIN GETTERS
 * get redux store values with selector depends on
 */
const getGoalActions = (
  store: GlobalState,
  householdFinId: string
): FinancialGoalActionObj[] => {
  return store.goalActions?.[householdFinId]?.goalActions
}

const getPreferences = (
  store: GlobalState,
  householdFinId: string
): HouseholdPreferencesObj =>
  store.householdPreferences?.[householdFinId]?.preferences

const getContacts = (store: GlobalState, householdFinId: string) =>
  store.contact?.[householdFinId]
/** END GETTERS
 */
/** BEGIN PREFERENCES SELECTOR
 * Docs: https://redux.js.org/recipes/computing-derived-data
 */
export const relevantPreferencesSelector = createDeepEqualSelector(
  [getPreferences],
  (
    preferences: HouseholdPreferencesObj
  ): GoalActionsPreferences | undefined => {
    const relevantPrefs: (keyof GoalActionsPreferences)[] = [
      'goalActionsShowInactiveItems',
      'goalActionsShowLifeActions',
      'goalActionsShowFinancialGoals',
      'goalActionsSortBy'
    ]
    if (checkObjFields(preferences, relevantPrefs)) {
      const {
        goalActionsShowInactiveItems,
        goalActionsShowLifeActions,
        goalActionsShowFinancialGoals,
        goalActionsSortBy
      } = preferences
      return {
        goalActionsShowInactiveItems,
        goalActionsShowLifeActions,
        goalActionsShowFinancialGoals,
        goalActionsSortBy
      }
    }
    return undefined
  }
)
/** END PREFERENCES SELECTOR
 */
/** BEGIN GOAL ACTIONS SELECTOR
 */
const goalActionsSelector = createDeepEqualSelector(
  [getGoalActions, relevantPreferencesSelector, getContacts],
  (goalActions, preferences, contacts): FinancialGoalActionObj[] => {
    if (!goalActions || goalActions.length === 0) {
      return []
    }
    const filteredGoals = goalActions.filter((goal: FinancialGoalActionObj) => {
      /** by default we hide inactive items DAS-63430
       * if requirement changes this can be removed
       * default state inside v3/goals should be adjusted accordingly
       */
      // goals with archived === true are filtered out, they should not be rendered
      if (goal.archived === true) {
        return false
      }
      if (!preferences) {
        return goal.inGuidecenter
      }

      if (
        !preferences.goalActionsShowInactiveItems &&
        goal.inGuidecenter === false
      ) {
        return false
      } else if (
        (preferences.goalActionsShowLifeActions &&
          (goal.type === 'life' || goal.type === 'both')) ||
        (preferences.goalActionsShowFinancialGoals &&
          (goal.type === 'financial' || goal.type === 'both')) ||
        goal.type === null
      ) {
        return true
      } else return false
    })
    // add client object to goals for sortGoals fn dependency
    const goalsWithClientMap = filteredGoals.map((goal) => {
      return { ...goal, client: getClientData(goal, contacts) }
    })
    return deepSortGoals({
      goals: goalsWithClientMap,
      sortKey: preferences?.goalActionsSortBy,
      hideInGuideCenter: false
    })
  }
)
/**
 * END GOAL ACTIONS SELECTOR
 */

/** ARCHIVED GOAL ACTIONS SELECTOR
 * A Goal Selector that returns archived only goals
 */
export const archivedGoalActionsSelector = createDeepEqualSelector(
  [getGoalActions, getContacts],
  (goals, contacts): FinancialGoalActionObj[] => {
    if (!goals || goals.length === 0) {
      return []
    }
    // filter out goals with archive false
    const archivedGoals = goals.filter((goal: FinancialGoalActionObj) => {
      return goal.archived === true
    })
    // add client object to goals for sortGoals fn dependency
    const archivedGoalsWithClientMap = archivedGoals.map((goal) => {
      return { ...goal, client: getClientData(goal, contacts) }
    })
    return archivedGoalsWithClientMap
  }
)
/**
 * END ARCHIVED GOAL ACTIONS SELECTOR
 */

export default goalActionsSelector
