import React, { Component, CSSProperties } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'

import {
  dollarFormat,
  getNetWorthFromClientAccounts,
  dateFormat
} from '../../helpers'
import * as NetWorthSortHelper from '../../helpers/netWorthSort'
import { staticText } from '../../helpers/resources'
import {
  generateNewPreferences,
  mapNetworthPreferences
} from '../../helpers/householdPreferences'
import {
  setHouseholdPreferences,
  updateHouseholdPreferences
} from '../../../actions/householdPreferences'
import { GlobalState } from '../../../reducers'
import {
  ClientAccountObjState,
  ClientAccountObj
} from '../../../objects/clientAccount'
import { SortType } from '../../../objects/preferenceSortSetting'
import { HouseholdPreferencesObj } from '../../../objects/householdPreferences'
import { ContactsInterface } from '../../../objects/contact'
import { HouseholdsState } from '../../../reducers/households'

import Tile from '../../components/layout/tile'
import TableRow from '../../components/layout/tableRow'
import ClientAccountRow from './clientAccountRow'
import ClientAccountRowNull from './clientAccountRowNull'
import PreferenceSortSetting from '../../components/preferenceSortSetting'
import Tooltip from '../../components/tooltip'
import NewMessageModal from '../../containers/messages/newMessageModal'

import DownArrowIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg'
import SendIcon from '../../assets/images/icons/png/airplane1x.png'
import MessageIcon from '../../assets/images/icons/png/message1x.png'

export interface ClientAccountTileProps {
  dispatch: Dispatch<GlobalState>
  clientAccounts: ClientAccountObjState
  householdFinId: string
  title: string
  accountSubType: string
  persistedSortBy: string
  persistedGroupBy: string
  persistedSortingOrder: string
  onAccountSelected?(account: ClientAccountObj): void
  preferences: HouseholdPreferencesObj
  contacts?: ContactsInterface
  userId: string
  households: HouseholdsState
  showSendEmailModal?(): void
  onMergeAccounts?(): void
  onUnmergeAccounts?(): void
}

interface ClientAccountTileState {
  title: string
  sortedAccounts: ClientAccountObj[]
  sortBy: string
  groupBy: string
  sortingOrder: string
  toggleArrow: boolean
  showMessageModal: boolean
}

const btnStyle: CSSProperties = {
  padding: '0 2px',
  color: '#255eba'
}

export class ClientAccountsTile extends Component<
  ClientAccountTileProps,
  ClientAccountTileState
> {
  constructor(props: ClientAccountTileProps) {
    super(props)

    const sortedAccounts: ClientAccountObj[] =
      props?.clientAccounts &&
      Object.keys(props.clientAccounts).map(
        (key: string) => props.clientAccounts[key]
      )

    this.state = {
      title: this.props.title,
      sortedAccounts,
      sortBy: this.props.persistedSortBy,
      groupBy: this.props.persistedGroupBy,
      sortingOrder: this.props.persistedSortingOrder,
      toggleArrow: false,
      showMessageModal: false
    }
  }

  public componentDidMount() {
    const {
      clientAccounts,
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder
    } = this.props
    if (
      clientAccounts &&
      persistedGroupBy &&
      persistedSortBy &&
      persistedSortingOrder
    ) {
      this.setState({
        groupBy: persistedGroupBy,
        sortBy: persistedSortBy,
        sortingOrder: persistedSortingOrder,
        sortedAccounts: NetWorthSortHelper.sortNetWorthAccounts(
          clientAccounts,
          {
            groupBy: persistedGroupBy,
            sortBy: persistedSortBy,
            sortingOrder: persistedSortingOrder
          }
        )
      })
    }
  }

  public componentDidUpdate(prevProps: ClientAccountTileProps) {
    const {
      clientAccounts,
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder
    } = this.props

    if (
      clientAccounts !== prevProps.clientAccounts ||
      persistedGroupBy !== prevProps.persistedGroupBy ||
      persistedSortBy !== prevProps.persistedSortBy ||
      persistedSortingOrder !== prevProps.persistedSortingOrder
    ) {
      if (
        clientAccounts &&
        persistedGroupBy &&
        persistedSortBy &&
        persistedSortingOrder
      ) {
        this.setState({
          sortedAccounts: NetWorthSortHelper.sortNetWorthAccounts(
            clientAccounts,
            {
              groupBy: persistedGroupBy,
              sortBy: persistedSortBy,
              sortingOrder: persistedSortingOrder
            }
          ),
          groupBy: persistedGroupBy,
          sortBy: persistedSortBy,
          sortingOrder: persistedSortingOrder
        })
      }
    }
  }

  public toggleMessageModal = () => {
    const { showMessageModal } = this.state
    this.setState({ showMessageModal: !showMessageModal })
  }

  public rightHeader = () => {
    const { clientAccounts, accountSubType } = this.props
    const { netWorth } = getNetWorthFromClientAccounts(clientAccounts)

    if (accountSubType === 'error') {
      return <div>{this.renderArrow()}</div>
    } else {
      return (
        <div
          className={
            netWorth === null || netWorth === undefined
              ? 'client-accounts-tile__header-right ca-tile-null__networth'
              : 'client-accounts-tile__header-right'
          }>
          {netWorth === null || netWorth === undefined
            ? ''
            : dollarFormat(netWorth, 0, 0 > netWorth)}
        </div>
      )
    }
  }
  public leftHeader = () => {
    const { groupBy, sortBy, sortingOrder } = this.state
    const {
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder,
      title,
      accountSubType
    } = this.props
    const {
      groupByOptions,
      sortByOptions,
      sortOrderOptions
    } = NetWorthSortHelper
    return (
      <div className='client-accounts-tile__header-left'>
        <div className='client-accounts-tile__header-title'>{title}</div>
        {accountSubType !== 'error' && (
          <PreferenceSortSetting
            groupByOptions={groupByOptions}
            sortByOptions={sortByOptions}
            sortOrderOptions={sortOrderOptions}
            currentSortBy={sortBy}
            currentGroupBy={groupBy}
            currentSortingOrder={sortingOrder}
            persistedSortBy={persistedSortBy}
            persistedGroupBy={persistedGroupBy}
            persistedSortingOrder={persistedSortingOrder}
            changePreference={this.changePreference}
            onSubmitPreferences={this.onSubmitPreferences}
            onCancelPreferences={this.onCancelPreferences}
            onDefaultPreferences={this.onDefaultPreferences}
          />
        )}
      </div>
    )
  }

  public clientAccountRows = () => {
    const {
      householdFinId,
      onAccountSelected,
      onMergeAccounts,
      onUnmergeAccounts
    } = this.props
    const { sortedAccounts } = this.state
    if (sortedAccounts?.length) {
      return sortedAccounts.map((sortedAccount) => {
        return (
          <ClientAccountRow
            householdFinId={householdFinId}
            clientAccount={sortedAccount}
            key={sortedAccount.id}
            onMergeAccounts={onMergeAccounts}
            onUnmergeAccounts={onUnmergeAccounts}
            onAccountSelected={onAccountSelected}
          />
        )
      })
    } else {
      return <ClientAccountRowNull />
    }
  }

  public onDefaultPreferences = () => {
    this.setState({
      sortBy: 'Account Name',
      groupBy: 'Classification',
      sortingOrder: 'Ascending'
    })
  }

  public onCancelPreferences = () => {
    const {
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder
    } = this.props
    this.setState({
      sortBy: persistedSortBy,
      groupBy: persistedGroupBy,
      sortingOrder: persistedSortingOrder
    })
  }

  public onSubmitPreferences = () => {
    const { groupBy, sortBy, sortingOrder } = this.state
    const options = { groupBy, sortBy, sortingOrder }
    this.updateSortedAccounts()
    this.updatePreferences(this.props.accountSubType, options)
  }

  public changePreference = (options: SortType) => {
    this.setState({ ...this.state, ...options })
  }

  public updatePreferences = (assetSubType: string, options: SortType) => {
    const currentPreferences = this.props.preferences
    if (!currentPreferences) {
      const newPreferences: HouseholdPreferencesObj = {
        ...generateNewPreferences(),
        ...mapNetworthPreferences(assetSubType, options)
      }
      return this.props.dispatch(
        setHouseholdPreferences(this.props.householdFinId, newPreferences)
      )
    } else {
      const newPreferences: HouseholdPreferencesObj = {
        ...currentPreferences,
        ...mapNetworthPreferences(assetSubType, options)
      }
      return this.props.dispatch(
        updateHouseholdPreferences(this.props.householdFinId, newPreferences)
      )
    }
  }

  public updateSortedAccounts = () => {
    const { groupBy, sortBy, sortingOrder, sortedAccounts } = this.state
    const newSortedAccounts = NetWorthSortHelper.sortNetWorthAccounts(
      sortedAccounts,
      {
        groupBy,
        sortBy,
        sortingOrder
      }
    )
    this.setState({ sortedAccounts: newSortedAccounts })
  }

  public renderArrow = () => {
    return (
      <div
        onClick={this.arrowOnClick}
        className='client-accounts-tile__arrow-toggle-w'>
        <img
          src={DownArrowIcon}
          alt='Down Arrow'
          role='presentation'
          className={
            'client-accounts-tile__arrow-toggle-arrow ' +
            (this.state.toggleArrow
              ? 'client-accounts-tile__arrow-toggle-arrow--down'
              : 'client-accounts-tile__arrow-toggle-arrow--up')
          }
        />
      </div>
    )
  }

  public arrowOnClick = () => {
    this.setState({
      toggleArrow: !this.state.toggleArrow
    })
  }

  public renderLastEmailSentDate = () => {
    const { contacts } = this.props
    const emailDateFunc = (date: string) =>
      `Last email sent on ${dateFormat(date)}`
    let emailDate: string = null

    // If single household, check for email date and render it, else return null
    if (!contacts?.secondary) {
      if (contacts?.primary?.accountErrorEmailDate) {
        emailDate = emailDateFunc(contacts.primary.accountErrorEmailDate)
      } else return null
    }

    //if couple household, and both have no email date, render nothing
    if (
      !contacts?.secondary?.accountErrorEmailDate &&
      !contacts.primary.accountErrorEmailDate
    ) {
      return null
    }

    //if couple household, check who has most recent email date and render it
    if (
      contacts?.secondary?.accountErrorEmailDate &&
      contacts?.primary.accountErrorEmailDate
    ) {
      if (
        dateFormat(contacts?.secondary?.accountErrorEmailDate) <
        dateFormat(contacts?.primary?.accountErrorEmailDate)
      ) {
        emailDate = emailDateFunc(contacts.primary.accountErrorEmailDate)
      } else {
        emailDate = emailDateFunc(contacts.secondary.accountErrorEmailDate)
      }
    }

    //if couple household and only secondary has email date, render it
    if (
      contacts?.secondary?.accountErrorEmailDate &&
      !contacts?.primary?.accountErrorEmailDate
    ) {
      emailDate = emailDateFunc(contacts.secondary.accountErrorEmailDate)
    }
    //if couple household and only primary has email date, render it
    if (
      !contacts?.secondary?.accountErrorEmailDate &&
      contacts?.primary?.accountErrorEmailDate
    ) {
      emailDate = emailDateFunc(contacts.primary.accountErrorEmailDate)
    }
    return emailDate
  }

  public renderTileBody = (isErrorType?: boolean) => {
    return (
      <div>
        {isErrorType && (
          <div className='client-accounts-tile__header-sub-title'>
            Some of your client’s accounts need to be updated by them. You
            should bring this to their attention by either sending them an email
            or a message, or creating a task for them.
          </div>
        )}
        <TableRow backgroundColor='#F5F5F5'>
          <div className='client-accounts-tile__column--name'>NAME</div>
          <div className='client-accounts-tile__column--type'>
            REGISTRATION TYPE
          </div>
          <div className='client-accounts-tile__column--amount'>AMOUNT</div>
          <div className='client-accounts-tile__column--show-hide'>
            SHOW/HIDE
            <Tooltip
              message={staticText.includeInGc}
              width={160}
              position='bottom'
              multiLine={true}>
              <HelpIcon />
            </Tooltip>
          </div>
          <div className='client-accounts-tile__column--actions' />
        </TableRow>
        {this.clientAccountRows()}
        {isErrorType && (
          <div className='client-accounts-tile__error-actions-w'>
            <span className='client-accounts-tile__last-email-date'>
              {this.renderLastEmailSentDate()}
            </span>
            <div className='client-accounts-tile__error-actions-btn-w'>
              <Button
                type='clear'
                btnStyle={btnStyle}
                onClick={this.props.showSendEmailModal}>
                <img
                  src={SendIcon}
                  className='client-accounts-tile__error-actions-btn-icon'
                  alt='send/email'
                />
                Send Email
              </Button>
              <Button
                type='clear'
                btnStyle={btnStyle}
                onClick={this.toggleMessageModal}>
                <img
                  src={MessageIcon}
                  className='client-accounts-tile__error-actions-btn-icon'
                  alt='message'
                />
                Send Message
              </Button>
              {/* For future implementation */}
              {/* Note: When re-implementing all buttons, change client-accounts-tile__error-actions-btn-w : justify-content: flex-end to justify-content: space-between */}
              {/* <Button type='clear' btnStyle={btnStyle}>
                <img
                  src={TasksIcon}
                  className='client-accounts-tile__error-actions-btn-icon'
                  alt='message'
                />
                Create a Task
              </Button> */}
            </div>
          </div>
        )}
      </div>
    )
  }

  public render() {
    const { accountSubType, householdFinId, userId, households } = this.props
    const { toggleArrow, showMessageModal } = this.state
    const isErrorType = accountSubType === 'error'
    const isArrowActive = isErrorType && toggleArrow
    const messageText =
      'Some accounts you added require your attention.\nPlease navigate to your Net Worth page for more information.'
    return (
      <div className='client-accounts-tile__account-w'>
        {isErrorType && <div className='client-accounts-tile__errortag' />}
        <Tile
          leftHeader={this.leftHeader()}
          rightHeader={this.rightHeader()}
          headerStyle={{
            backgroundColor: '#FAFAFA',
            borderBottom: 'solid 1px #d2d2d2'
          }}>
          {isErrorType
            ? isArrowActive
              ? this.renderTileBody(isErrorType)
              : null
            : this.renderTileBody()}
        </Tile>
        {showMessageModal && (
          <NewMessageModal
            closeModal={this.toggleMessageModal}
            householdFinId={householdFinId}
            userId={userId}
            household={households[householdFinId]}
            subjectProp='Some accounts require your attention'
            messageProp={messageText}
            updateMessageThread={null}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    userId: store.user.userId,
    householdFinId: match.params.householdFinId,
    households: store.households
  }
}

export default withRouter(connect(mapStateToProps)(ClientAccountsTile))
