import * as React from 'react'
import Tooltip from '../components/tooltip'
import Select from 'react-select'

export interface SelectProps {
  classes?: string
  toolTipText?: string | boolean
  requiredField?: boolean
  errorMsg?: string
  selectChanged: boolean
  titleName: string
  inputFieldName: string
  contents: any
  dataList: any
  isDisabled?: boolean
  controlFunc(e: any): void
}

class CustomSelect extends React.Component<SelectProps, {}> {
  public setStyleBorders = (
    isFocused: boolean
  ): { shadow: string; border: string } => {
    const { contents, selectChanged, requiredField, errorMsg } = this.props
    const blueShadow = '0 0 0 0.1rem rgba(0, 123, 255, 0.25)'
    const redShadow = '0 0 0 0.1rem rgba(255, 0, 0, 0.25)'
    let shadowColor = ''
    let borderColor = '#c2c2c2'

    let contentsLength: number = 0
    if (contents === null || contents === undefined) {
      contentsLength = 0
    } else {
      contentsLength = contents.length
    }

    if (isFocused || (contentsLength > 0 && selectChanged)) {
      shadowColor = blueShadow
      borderColor = '#80bdff'
    }
    if (requiredField && errorMsg.length > 0 && contentsLength === 0) {
      shadowColor = redShadow
      borderColor = '#ff0000'
    }
    return { shadow: shadowColor, border: borderColor }
  }

  public render() {
    const {
      contents,
      classes,
      controlFunc,
      titleName,
      dataList,
      errorMsg,
      isDisabled
    } = this.props
    const styles = {}
    const placeholderText =
      contents === null || contents === undefined || contents.length === 0
        ? 'Select ' + titleName
        : contents
    const classStyles = 'form-group ' + classes

    const errorMessege = () => {
      return errorMsg ? (
        <div className='form-group__error-box'>
          <span className='form-input-Error form-input-Error__message'>
            {errorMsg}
          </span>
        </div>
      ) : null
    }

    const styleColors = (state: any) => {
      return this.setStyleBorders(state.isFocused)
    }

    return (
      <div className={classStyles}>
        {this.selectLabel()}
        <Select
          styles={{
            ...styles,
            control: (base, state) => ({
              ...base,
              boxShadow: styleColors(state).shadow,
              borderColor: styleColors(state).border
            })
          }}
          onChange={controlFunc}
          placeholder={placeholderText}
          options={
            dataList && Object.keys(dataList).map((key) => dataList[key])
          }
          value={contents || ''}
          isDisabled={isDisabled}
          className='form-input__control'
          classNamePrefix='form-input'
        />
        {errorMessege()}
      </div>
    )
  }

  private selectLabel = () => {
    const { titleName, toolTipText } = this.props
    if (toolTipText) {
      return (
        <label className='form-label form-label_mandatory'>
          <Tooltip
            message={typeof toolTipText === 'string' ? toolTipText : ''}
            width={160}
            position='bottom'
            multiLine={true}>
            {titleName}
          </Tooltip>
        </label>
      )
    } else {
      return <label className='form-label'>{titleName}</label>
    }
  }
}

export default CustomSelect
