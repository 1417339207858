import React, { ChangeEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import * as reactTabs from 'react-tabs'
import { withRouter } from 'react-router'
import Popup from 'reactjs-popup'
import _, { isEqual } from 'lodash'

import {
  StrategiesObj,
  StrategiesInterface
} from '../../../../objects/strategies'
import * as strategiesActions from '../../../../actions/strategies'
import { GlobalState } from '../../../../reducers'
import { isObjEmpty } from '../../../helpers'
import Tile from '../../../components/layout/tile'
import Search from '../../../components/search'
import StrategyCard from './strategyCard'
import StrategyFilter from './strategyFilter'
import StrategyNull from './strategyNull'
import StrategySortContent from './strategySortContent'

import strategy_icon from '../../../assets/images/icons/ic_strategy.png'
import SearchIcon from '../../../assets/images/icons/ic_search.png'
import FilterIcon from '../../../assets/images/icons/ic_filter.svg'
import CloseIcon from '../../../assets/images/icons/ic_close.svg'
import SortIcon from '../../../assets/images/icons/ic_sort.svg'

export class SortFields {
  public name = true
  public investmentMinimum = false
  public yield = false
  public fees = false
}

export class FilterFields {
  public allocation = {
    leftEquity: '0',
    leftFixed: '100',
    rightEquity: '100',
    rightFixed: '0',
    defaultMin: 0,
    defaultMax: 100,
    min: 0,
    max: 100
  }
  public minimum = {
    defaultMin: 0,
    defaultMax: 500000,
    min: 0,
    max: 500000
  }
  public yield = {
    defaultMin: 0,
    defaultMax: 50,
    min: 0,
    max: 50
  }
  public fees = {
    defaultMin: 0,
    defaultMax: 100,
    min: 0,
    max: 100
  }
  public attributes: string[] = []
  public attributeTypes = {
    globalCheck: true,
    domesticCheck: true
  }
}

interface ClientStrategiesProps {
  strategies: StrategiesInterface
  dispatch: Dispatch<GlobalState>
  strategyMenu: boolean
  openFilterPopup: boolean
  openSortPopup: boolean
  strategyMenuToggle(): boolean
  onFilterPopupOpen(): void
  onFilterPopupClose(): void
  onSortPopupOpen(): void
  onSortPopupClose(): void
  handleStrategyHeaderClick(): void
}
interface StrategiesStates {
  showSearch: boolean
  searchValue: string
  sortFields: SortFields
  filterFields: FilterFields
  strategies: StrategiesInterface
}

class ClientStrategies extends React.Component<
  ClientStrategiesProps,
  StrategiesStates
> {
  constructor(props: ClientStrategiesProps) {
    super(props)
    this.state = {
      showSearch: false,
      sortFields: new SortFields(),
      filterFields: new FilterFields(),
      searchValue: '',
      strategies: {}
    }
  }
  // Resets filters search and sorts by name as strategies change for household
  componentDidUpdate(prevProps: ClientStrategiesProps) {
    if (
      this.props.strategies &&
      !isEqual(prevProps.strategies, this.props.strategies)
    ) {
      this.setState(
        {
          showSearch: false,
          sortFields: new SortFields(),
          filterFields: new FilterFields(),
          searchValue: '',
          strategies: this.props.strategies
        },
        () => {
          this.sortData()
        }
      )
    }
  }

  public async componentDidMount() {
    const { match }: any = this.props
    await this.props.dispatch(
      strategiesActions.getStrategies(match.params.householdFinId)
    )
  }

  public showStrategySearch = () => {
    this.setState({
      showSearch: !this.state.showSearch,
      searchValue: '',
      strategies: this.props.strategies
    })
  }

  public resetFilter = () => {
    this.setState({
      filterFields: new FilterFields(),
      strategies: this.props.strategies
    })
  }

  public resetSort = () => {
    this.setState({
      sortFields: new SortFields(),
      strategies: this.props.strategies
    })
  }

  public onCheckboxChange = (type: string) => async () => {
    await this.handleCheckboxChange(type)
  }

  public handleCheckboxChange = (type: string) => {
    this.setState({
      filterFields: {
        ...this.state.filterFields,
        attributeTypes: {
          ...this.state.filterFields.attributeTypes,
          [type]: !this.state.filterFields.attributeTypes[type]
        }
      }
    })
  }

  public isFilterFieldChecked = (field: string) => {
    return this.state.filterFields.attributeTypes[field] === true
  }

  public onSortFieldChecked = (field: string) => (evt: any) => {
    this.setState({
      sortFields: { ...this.state.sortFields, [field]: evt.target.checked }
    })
  }

  public handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.currentTarget.value
    const strategies = _.pickBy(
      this.props.strategies,
      (strategy: StrategiesObj) =>
        strategy.name.toLowerCase().includes(searchValue.toLowerCase())
    )
    this.setState({ searchValue, strategies })
  }

  public tooltipHeader = (title: string, icon: any) => {
    return (
      <div className='client-strategies__header'>
        <img src={icon} alt='' role='presentation' />
        <span>{title}</span>
      </div>
    )
  }

  public onAllocationTextChange = (type: string, pos: string) => (evt: any) => {
    const text = evt.target.value
    const equityBenchmark = pos === 'left' ? 'min' : 'max'
    let value = text.replace(/[^0-9\.-]+/g, '')
    if (_.isNaN(value)) {
      return
    }
    value = Number(value)
    if (type === 'equity') {
      const equity = value
      const fixed = 100 - value
      this.setState({
        filterFields: {
          ...this.state.filterFields,
          allocation: {
            ...this.state.filterFields.allocation,
            [`${equityBenchmark}`]: equity,
            [`${pos}Equity`]: `${equity}`,
            [`${pos}Fixed`]: `${fixed}`
          }
        }
      })
    } else {
      const equity = 100 - value
      const fixed = value
      this.setState({
        filterFields: {
          ...this.state.filterFields,
          allocation: {
            ...this.state.filterFields.allocation,
            [`${equityBenchmark}`]: equity,
            [`${pos}Equity`]: `${equity}`,
            [`${pos}Fixed`]: `${fixed}`
          }
        }
      })
    }
  }

  public onSlideChange = (field: string) => (value: any) => {
    const min = value[0]
    const max = value[1]
    this.setState({
      filterFields: {
        ...this.state.filterFields,
        [field]: { ...this.state.filterFields[field], min, max }
      }
    })
  }

  public onAllocationSlideChange = (value: any) => {
    const min = value[0]
    const max = value[1]
    const leftEquity = min
    const leftFixed = 100 - min
    const rightEquity = max
    const rightFixed = 100 - max
    this.setState({
      filterFields: {
        ...this.state.filterFields,
        allocation: {
          ...this.state.filterFields.allocation,
          min,
          max,
          leftEquity: `${leftEquity}`,
          leftFixed: `${leftFixed}`,
          rightEquity: `${rightEquity}`,
          rightFixed: `${rightFixed}`
        }
      }
    })
  }

  public onTextRangeChange = (property: string, subProperty: string) => (
    evt: any
  ) => {
    const text = evt.target.value
    let value = text.replace(/[^0-9\.-]+/g, '')
    if (_.isNaN(value)) {
      return
    }
    value = Number(value)
    if (
      subProperty === 'min' &&
      value >= this.state.filterFields[property].defaultMin &&
      value < this.state.filterFields[property].defaultMax
    ) {
      this.setState({
        filterFields: {
          ...this.state.filterFields,
          [property]: {
            ...this.state.filterFields[property],
            [subProperty]: value
          }
        }
      })
    }
    if (
      subProperty === 'max' &&
      value > this.state.filterFields[property].defaultMin &&
      value <= this.state.filterFields[property].defaultMax
    ) {
      this.setState({
        filterFields: {
          ...this.state.filterFields,
          [property]: {
            ...this.state.filterFields[property],
            [subProperty]: value
          }
        }
      })
    }
  }

  public filterByPreference = (strategies: any) => {
    const {
      globalCheck,
      domesticCheck
    } = this.state.filterFields.attributeTypes
    if (globalCheck && domesticCheck) {
      return strategies
    } else if (globalCheck) {
      return {
        ...Object.keys(strategies)
          .map((strategy) => {
            return strategies[strategy].scores.global > 50
              ? strategies[strategy]
              : null
          })
          .filter((e) => e)
      }
    } else if (domesticCheck) {
      return {
        ...Object.keys(strategies)
          .map((strategy) => {
            return strategies[strategy].scores.domestic > 50
              ? strategies[strategy]
              : null
          })
          .filter((e) => e)
      }
    } else return null
  }

  public filterData = () => {
    const {
      minimum,
      yield: filterYield,
      allocation,
      attributes,
      fees
    } = this.state.filterFields
    this.props.onFilterPopupClose()
    let strategies: any = this.props.strategies
    strategies = _.pickBy(
      strategies,
      (strategy: StrategiesObj) =>
        !strategy.investmentMinimum ||
        (strategy.investmentMinimum >= minimum.min &&
          strategy.investmentMinimum <= minimum.max)
    )
    strategies = _.pickBy(
      strategies,
      (strategy: StrategiesObj) =>
        strategy.yield >= filterYield.min && strategy.yield <= filterYield.max
    )
    strategies = _.pickBy(
      strategies,
      (strategy: StrategiesObj) =>
        strategy.equity >= allocation.min && strategy.equity <= allocation.max
    )
    strategies = _.pickBy(
      strategies,
      (strategy: StrategiesObj) =>
        strategy.fees >= fees.min && strategy.fees <= fees.max
    )
    strategies = this.filterByPreference(strategies)
    if (attributes.length) {
      const attrStrategies: StrategiesInterface = {}
      _.forEach(this.state.filterFields.attributes, (attr) => {
        const pickedStrategies = _.pickBy(
          strategies,
          (strategy: StrategiesObj) => strategy.attribute === attr
        )
        Object.assign(attrStrategies, pickedStrategies)
      })
      strategies = attrStrategies
    }
    this.setState({ strategies })
  }

  public sortData = () => {
    this.props.onSortPopupClose()
    let strategiesArray = _.map(
      this.state.strategies,
      (strategy: StrategiesObj, key: any) => ({
        ...strategy,
        _key: key
      })
    )
    _.forEach(this.state.sortFields, (value, key) => {
      if (value) {
        strategiesArray = _.sortBy(strategiesArray, [key], ['asc'])
      }
    })
    const strategies: StrategiesInterface = {}
    _.forEach(
      strategiesArray,
      (strategy: StrategiesObj) => (strategies[strategy['_key']] = strategy)
    )
    this.setState({ strategies })
  }

  public searchBar = () => {
    return (
      <div className='client-strategies__search'>
        <Search
          value={this.state.searchValue}
          onChange={this.handleSearchChange}
        />
        <div onClick={this.showStrategySearch}>
          <img src={CloseIcon} alt='no-img' />
        </div>
      </div>
    )
  }

  public tabLists = () => {
    return (
      <div
        style={this.state.showSearch ? { display: 'none' } : {}}
        className='flex'>
        <div className='flex'>
          <reactTabs.Tab>All</reactTabs.Tab>
        </div>
        <div className='client-strategies__filters'>
          <a
            onClick={this.showStrategySearch}
            className='client-strategies__filters--pointer'>
            <img src={SearchIcon} alt='no-img' />
          </a>
          <Popup
            overlayStyle={{ width: 0 }}
            contentStyle={{ width: '350px', padding: '0px' }}
            trigger={
              <a className='client-strategies__filters--pointer'>
                <img src={FilterIcon} alt='no-img' />
              </a>
            }
            position='bottom right'
            closeOnDocumentClick={true}
            open={this.props.openFilterPopup}
            onOpen={this.props.onFilterPopupOpen}
            onClose={this.props.onFilterPopupClose}>
            <StrategyFilter
              onFilterPopupClose={this.props.onFilterPopupClose}
              tooltipHeader={this.tooltipHeader}
              filterData={this.filterData}
              onCheckboxChange={this.onCheckboxChange}
              onTextRangeChange={this.onTextRangeChange}
              onSlideChange={this.onSlideChange}
              onAllocationTextChange={this.onAllocationTextChange}
              onAllocationSlideChange={this.onAllocationSlideChange}
              isFilterFieldChecked={this.isFilterFieldChecked}
              filterFields={this.state.filterFields}
              resetFilter={this.resetFilter}
            />
          </Popup>
          <Popup
            overlayStyle={{ width: 0 }}
            contentStyle={{ width: '220px', padding: '0px' }}
            trigger={
              <a className='client-strategies__filters--pointer'>
                <img src={SortIcon} alt='no-img' />
              </a>
            }
            position='bottom right'
            closeOnDocumentClick={true}
            open={this.props.openSortPopup}
            onOpen={this.props.onSortPopupOpen}
            onClose={this.props.onSortPopupClose}>
            <StrategySortContent
              tooltipHeader={this.tooltipHeader}
              resetSort={this.resetSort}
              sortFields={this.state.sortFields}
              onSortFieldChecked={this.onSortFieldChecked}
              sortData={this.sortData}
              onSortPopupClose={this.props.onSortPopupClose}
            />
          </Popup>
        </div>
      </div>
    )
  }

  public strategyHeader = () => {
    return (
      <div
        onClick={this.props.handleStrategyHeaderClick}
        className='client-strategies__header'>
        <img src={strategy_icon} alt='' role='presentation' />
        STRATEGIES
      </div>
    )
  }

  public renderTileContent = () => {
    const headerStyle = {
      backgroundColor: '#FAFAFA',
      padding: '12px 16px 14px 16px',
      color: '#231F20',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      borderRadius: '0px'
    }
    const { strategies } = this.state

    if (this.props.strategyMenu) {
      return (
        <Tile
          headerBorder={false}
          style={{ borderRadius: '0px' }}
          headerStyle={headerStyle}
          leftHeader={this.strategyHeader()}>
          {this.props.strategyMenu && (
            <div style={{ width: 400 }}>
              <reactTabs.Tabs>
                <reactTabs.TabList>
                  {this.tabLists()}
                  {this.state.showSearch ? this.searchBar() : ''}
                </reactTabs.TabList>
                <reactTabs.TabPanel>
                  <div />
                  <div className='client-strategies__scroll'>
                    {strategies && !isObjEmpty(strategies) ? (
                      Object.keys(strategies).map((strategy: any) => {
                        return (
                          <StrategyCard
                            id={strategies[strategy].id}
                            name={strategies[strategy].name}
                            key={strategies[strategy].id}
                            value={strategies[strategy].value}
                            investmentMinimum={
                              strategies[strategy].investmentMinimum
                            }
                            type={strategies[strategy].attribute}
                            yieldValue={strategies[strategy].yield}
                            fees={strategies[strategy].fees}
                            restrictedFromQualifiedAccounts={
                              strategies[strategy]
                                .restrictedFromQualifiedAccounts
                            }
                          />
                        )
                      })
                    ) : (
                      <StrategyNull />
                    )}
                  </div>
                </reactTabs.TabPanel>
              </reactTabs.Tabs>
            </div>
          )}
        </Tile>
      )
    } else {
      return null
    }
  }

  public render() {
    return this.renderTileContent()
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    strategies: store.strategies[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(ClientStrategies))
