import React, { Component, FormEvent, Fragment } from 'react'

import {
  updateBillingSummary,
  ClientBillingSummary
} from '../../../objects/clientBillingSummary'
import { ContactsInterface } from '../../../objects/contact'
import { formatCurrencyInput } from '../../helpers'
import { staticText } from '../../helpers/resources'

import CostOfServicesModal from './billing/costOfSevicesModal'
import { CostAdjustmentOption } from './billing/costOfServicesDisplay'
import ToggleSwitch from '../../components/toggleSwitch'
import Input from '../../components/Input'
import Button from '../../components/button'
import TextArea from '../../components/TextArea'
import { RadioButton } from '../../components/Radio'
import { HouseholdInstitution } from '../../../objects/household'
import api from '../../helpers/api'

interface SettingsBillingProp {
  contacts: ContactsInterface
  householdId: string
  institution: HouseholdInstitution
}

interface SettingsBillingState {
  satisfactionField: boolean
  showPdfModal: boolean
  enableAddButton: boolean
  errors: {
    sumOfFeesErr: boolean
    guidanceFeeErr: boolean
    managementCostErr: boolean
  }
  billingSummary: ClientBillingSummary
}

const satisfactionTextCopy = staticText.satisfactionTextCopy

export class SettingsBilling extends Component<
  SettingsBillingProp,
  SettingsBillingState
> {
  constructor(props: SettingsBillingProp) {
    super(props)
    this.state = {
      satisfactionField: false,
      showPdfModal: false,
      enableAddButton: false,
      errors: {
        sumOfFeesErr: null,
        guidanceFeeErr: null,
        managementCostErr: null
      },
      billingSummary: {
        notes: null,
        satisfaction: null,
        reviewYear: null,
        totalSummaryCost: null,
        guidanceFee: null,
        managementCost: null,
        managementCostAdjustment: null,
        managementCostAdjustmentEnabled: 'No',
        includeStatisfaction: false,
        aumBased: 'dollar',
        sumOfFees: ''
      }
    }
  }

  public closeModal = () => {
    this.setState({
      showPdfModal: !this.state.showPdfModal
    })
  }

  public satisfactionField = () => {
    const newState = this.state
    const includeStatisfaction = !this.state.satisfactionField
    const billingSummary = {
      ...newState.billingSummary,
      includeStatisfaction,
      satisfaction: includeStatisfaction ? satisfactionTextCopy : null
    }
    this.setState({
      satisfactionField: includeStatisfaction,
      billingSummary
    })
  }

  public formatDollar = (input: string | number) => {
    const { billingSummary } = this.state
    const percentage = billingSummary.aumBased === 'percentage'
    const inputNumber: number = input as number
    const formattedInput = !percentage
      ? formatCurrencyInput(String(input))
      : percentage
      ? inputNumber
      : ''
    return inputNumber < 0 ? `-${formattedInput}` : formattedInput
  }

  public async createBillingSummary() {
    const { contacts, householdId, institution } = this.props
    const summary = this.state.billingSummary
    const imageData = institution.imageUrl

    await api().post(
      `/households/${householdId}/guidebookgenerator/summary/html`,
      {
        contacts,
        summary,
        imageData,
        section: 1,
        id: householdId + '-CostOfServices'
      }
    )
  }

  public previewBilling = async () => {
    const errors = this.validateInputs()
    const allowPreviewBilling = Object.keys(errors).some(
      (x) => errors[x] === false
    )
    if (!allowPreviewBilling) {
      await this.createBillingSummary()
      this.setState({
        showPdfModal: true
      })
    }
    return false
  }

  public renderCostAdjustmentOptions = () => {
    const { billingSummary } = this.state
    const {
      managementCostAdjustment,
      managementCostAdjustmentEnabled,
      aumBased
    } = billingSummary
    const percentage = aumBased === 'percentage'
    return (
      <CostAdjustmentOption title='Management Cost Adjustment Required?'>
        <RadioButton
          id='managementCostYes'
          labelDisplay='Yes'
          name='managementCostAdjustmentEnabled'
          value='Yes'
          checked={managementCostAdjustmentEnabled === 'Yes'}
          changeFunc={this.setBillingState}
        />
        <RadioButton
          id='managementCostNo'
          labelDisplay='No'
          name='managementCostAdjustmentEnabled'
          value='No'
          checked={managementCostAdjustmentEnabled === 'No'}
          changeFunc={this.setBillingState}
        />
        <Input
          name='managementCostAdjustment'
          title=''
          inputType='text'
          readOnly={managementCostAdjustmentEnabled === 'No'}
          content={this.formatDollar(managementCostAdjustment)}
          controlFunc={this.setBillingState}
          dollarSign={!percentage}
          percentageSign={percentage}
        />
      </CostAdjustmentOption>
    )
  }

  public billingInputs = () => {
    const {
      guidanceFee,
      aumBased,
      managementCost,
      sumOfFees,
      managementCostAdjustmentEnabled
    } = this.state.billingSummary
    const {
      sumOfFeesErr,
      guidanceFeeErr,
      managementCostErr
    } = this.state.errors
    const percentage = aumBased === 'percentage'
    const sumOfFeesErrMsg = sumOfFeesErr !== null && sumOfFeesErr !== true
    const guidanceFeeErrMsg = guidanceFeeErr !== null && guidanceFeeErr !== true
    const managementCostErrMsg =
      managementCostErr !== null && managementCostErr !== true

    const guidanceFeeInput = (
      <Input
        name='guidanceFee'
        title='Guidance Services Cost'
        inputType='text'
        mandatory={true}
        classNames={guidanceFeeErrMsg && 'form-input-Error'}
        error={
          guidanceFeeErrMsg && percentage
            ? 'Must Enter Minimum .25%'
            : guidanceFeeErrMsg
            ? 'Must Enter Minimum $2500'
            : ''
        }
        content={this.formatDollar(guidanceFee)}
        controlFunc={this.setBillingState}
        onBlur={this.handleBlur}
        dollarSign={!percentage}
        percentageSign={percentage}
      />
    )
    const billingLayout = (): JSX.Element => {
      return percentage ? (
        <Fragment>
          <Input
            name='managementCost'
            title='Management Cost'
            inputType='text'
            mandatory={true}
            classNames={managementCostErrMsg && 'form-input-Error'}
            error={managementCostErrMsg && 'Must Enter Guidance Services Cost'}
            content={managementCost}
            controlFunc={this.setBillingState}
            onBlur={this.handleBlur}
            dollarSign={!percentage}
            percentageSign={percentage}
          />
          {this.renderCostAdjustmentOptions()}
        </Fragment>
      ) : (
        guidanceFeeInput
      )
    }
    return (
      <div className='c-settings__billing-inputs'>
        <div className='c-settings__billing-inputs-item c-settings__billing-inputs-item-column'>
          {billingLayout()}
        </div>
        <div className='c-settings__billing-inputs-item c-settings__billing-inputs-item-column'>
          {percentage ? guidanceFeeInput : this.renderCostAdjustmentOptions()}

          {managementCostAdjustmentEnabled === 'Yes' ||
          aumBased === 'percentage' ? (
            <Input
              name='sumOfFees'
              title='Combined Cost'
              inputType='text'
              mandatory={true}
              readOnly={true}
              classNames={sumOfFeesErrMsg && 'form-input-Error'}
              error={sumOfFeesErrMsg && 'Must Enter Guidance Services Cost'}
              onBlur={this.handleBlur}
              content={
                percentage ? Number(sumOfFees) : this.formatDollar(sumOfFees)
              }
              dollarSign={!percentage}
              percentageSign={percentage}
            />
          ) : null}
        </div>
      </div>
    )
  }

  public billingCalc = (): JSX.Element[] => {
    const { billingSummary } = this.state
    const costRadiosElements: JSX.Element[] = []
    const costTypes: any[] = [
      {
        value: 'percentage',
        label: 'This Guidance Services Cost is a percentage fee based on AUM'
      },
      {
        value: 'dollar',
        label: 'This Guidance Services Cost is a specific dollar amount'
      }
    ]
    costTypes.forEach((costType, index) => {
      costRadiosElements.push(
        <RadioButton
          key={index}
          id={'costType' + index}
          labelDisplay={costType.label}
          name='aumBased'
          value={costType.value}
          checked={billingSummary.aumBased === costType.value}
          changeFunc={this.setBillingState}
        />
      )
    })
    return costRadiosElements
  }

  public reviewsPerYear = (): JSX.Element[] => {
    const { billingSummary } = this.state
    const reviewsTimes: string[] = ['one', 'two', 'three', 'four']
    const reviewsPerYear: JSX.Element[] = []
    reviewsTimes.forEach((count, index) => {
      const yearCount = index + 1
      reviewsPerYear.push(
        <RadioButton
          key={index}
          id={count + 'Year'}
          labelDisplay={yearCount}
          name='reviewYear'
          value={yearCount}
          checked={Boolean(
            billingSummary.reviewYear &&
              billingSummary.reviewYear === yearCount.toString()
          )}
          changeFunc={this.setBillingState}
        />
      )
    })
    return reviewsPerYear
  }

  public setBillingState = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>
  ) => {
    const { name, value } = event.currentTarget
    const newState = this.state
    this.setState({
      billingSummary: {
        ...updateBillingSummary({ name, value }, newState.billingSummary)
      }
    })
  }

  public validateInputs = () => {
    const {
      guidanceFee,
      aumBased,
      managementCost,
      sumOfFees
    } = this.state.billingSummary
    if (aumBased === 'percentage') {
      return {
        guidanceFee: Boolean(guidanceFee) && Number(guidanceFee) >= 0.25,
        managementCost: Boolean(managementCost),
        sumOfFees: Boolean(sumOfFees)
      }
    } else {
      return {
        guidanceFee: guidanceFee && Number(guidanceFee) >= 2500,
        sumOfFees: Boolean(sumOfFees)
      }
    }
  }

  public handleBlur = (e: React.FormEvent<HTMLInputElement>) => {
    const { guidanceFee, aumBased } = this.state.billingSummary
    const target = `${e.currentTarget.name}Err`
    let meetsMinGuidance = false
    if (target === 'guidanceFeeErr') {
      if (aumBased === 'percentage' && Number(guidanceFee) >= 0.25) {
        meetsMinGuidance = true
      } else if (aumBased !== 'percentage' && Number(guidanceFee) >= 2500) {
        meetsMinGuidance = true
      }
    } else {
      meetsMinGuidance = true
    }
    const meetsAllReq = Boolean(e.currentTarget.value) && meetsMinGuidance
    this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [target]: meetsAllReq
      }
    }))
  }

  public render() {
    const { showPdfModal, billingSummary } = this.state
    const { notes } = billingSummary
    const { contacts } = this.props
    const errors = this.validateInputs()
    const isDisabled = Object.keys(errors).some((x) => errors[x] === false)
    return (
      <div className='c-settings__billing'>
        <h1 className='c-settings__billing-h'>Client Billing</h1>
        <div className='c-settings__billing-tagline-w'>
          <h3 className='c-settings__billing-tagline'>
            Include Satisfaction Guarantee
          </h3>
          <ToggleSwitch
            active={this.state.satisfactionField}
            handleToggleActive={this.satisfactionField}
          />
        </div>
        <p className='c-settings__billing-tagline-p'>{satisfactionTextCopy}</p>
        <TextArea
          customStyled='c-settings__billing-textfield'
          controlFunc={this.setBillingState}
          value={notes}
          name='notes'
          maxlength={200}
          noresize={true}
          placeholder='Add Info (Optional)'
        />
        <div className='c-settings__billing-divide' />
        <div className='c-settings__billing-review'>
          <h4>Number of Reviews a Year</h4>
          <div className='c-settings__billing-review-select'>
            {this.reviewsPerYear()}
          </div>
        </div>
        <div className='c-settings__billing-divide' />
        <div className='c-settings__billing-calc'>{this.billingCalc()}</div>
        <div className='c-settings__billing-divide' />
        {this.billingInputs()}
        <div className='c-settings__billing-btns'>
          <div>
            <Button
              primary={!isDisabled}
              onClick={!isDisabled ? this.previewBilling : null}>
              Preview
            </Button>
          </div>
        </div>
        {showPdfModal ? (
          <CostOfServicesModal
            institution={this.props.institution}
            householdId={this.props.householdId}
            summary={this.state.billingSummary}
            closeModal={this.closeModal}
            contacts={contacts}
          />
        ) : null}
      </div>
    )
  }
}

export default SettingsBilling
