import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ContactsInterface } from '../../../../../objects/contact'
import { GlobalState } from '../../../../../reducers'

import AnalysisHeader from './analysisHeader'
import AnalysisMenu from './analysisMenu'
import AllocationSummary from './allocationSummary'

import {
  AssetAllocationGraphData,
  PerformanceReturnGraphData
} from '../../../../../objects/PortfolioAnalytics/analytics'
import PerformanceSummary from './performanceSummary'
import { isObjEmpty } from '../../../../helpers'
import { HouseholdObj } from '../../../../../objects/household'
import { PortfolioBuilderInterface } from '../../../../../objects/portfolioBuilder'

interface AnalysisProps {
  householdFinId: string
  contact: ContactsInterface
  portfolioBuilder: PortfolioBuilderInterface
  household: HouseholdObj
  benchmarkId: string
}

const taxAllocationData: AssetAllocationGraphData = {
  Taxable: { cashAllocation: 5, fixedAllocation: 15, equityAllocation: 40 },
  'Tax-Exempt': { cashAllocation: 4, fixedAllocation: 20, equityAllocation: 30 }
}

const assetAllocationData: AssetAllocationGraphData = {
  'Current Portfolio': {
    cashAllocation: 25,
    fixedAllocation: 25,
    equityAllocation: 25,
    otherAllocation: 25
  },
  'Moderate Aggressive': {
    cashAllocation: 5,
    fixedAllocation: 15,
    equityAllocation: 40,
    otherAllocation: 15
  }
}

const performanceReturnData: PerformanceReturnGraphData = {
  'Current Portfolio': {
    ytd: 2.6,
    oneYear: 3.6,
    threeYear: 7.6,
    fiveYear: 4.3
  },
  'Moderate Aggressive': {
    ytd: 6.6,
    oneYear: 1.6,
    threeYear: 7.6,
    fiveYear: 1.3
  }
}

class Analysis extends Component<AnalysisProps> {
  /**
   * get the selected metric (isg benchmark || proposed portfolio) by id
   */
  public getSelectedMetric = () => {
    const {
      benchmarkId,
      household: { benchmarks },
      portfolioBuilder = null
    } = this.props

    if (Object.keys(portfolioBuilder).includes(benchmarkId)) {
      return portfolioBuilder[benchmarkId]
    }

    return benchmarks?.filter((item) => item.id === benchmarkId)[0]
  }

  public render = () => {
    const { householdFinId, contact } = this.props
    const benchmarkObj = this.getSelectedMetric()

    return (
      <div className='im-portfolio__main-w'>
        <AnalysisHeader
          benchmark={benchmarkObj}
          householdFinId={householdFinId}
          contact={contact}
          featureFunc={null} // edit name
        />
        <AnalysisMenu />
        <AllocationSummary
          donutData={taxAllocationData}
          headerName='Taxable and Tax-Exempt Asset Allocation'
          fields={['Asset', 'Taxable', 'Tax-Exempt']}
        />
        <AllocationSummary
          donutData={assetAllocationData}
          headerName='Asset Allocation'
          fields={['Asset', 'Current Portfolio', 'Moderate Aggressive']}
        />

        <PerformanceSummary
          headerName='Performance/Return'
          barData={performanceReturnData}
        />
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const {
    params: { householdFinId }
  } = match
  const { contact, households = {}, portfolioBuilder } = store
  return {
    householdFinId,
    portfolioBuilder:
      !isObjEmpty(portfolioBuilder) && portfolioBuilder[householdFinId],
    household: !isObjEmpty(households) && households[householdFinId],
    contact: contact[householdFinId]
  }
}

export default connect(mapStateToProps)(Analysis)
