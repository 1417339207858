import React, { Component } from 'react'
import { InsuranceInterface, insuranceTypes } from '../../../objects/insurance'
import InGuideBookSwitch from './InGuideBookSwitch'
import Tile from '../../components/layout/tile'
import TableRow from '../../components/layout/tableRow'
import { dollarsOrBlankSpace, nullStringFormat } from '../../helpers'
import InsuranceUpdate from './insuranceUpdate'
import Tooltip from '../../components/tooltip'
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg'
import { staticText } from '../../helpers/resources'

interface AnnuitiesInsuranceRowProps {
  insurances: InsuranceInterface
}

export class AnnuitiesInsuranceRow extends Component<
  AnnuitiesInsuranceRowProps
> {
  public renderData = () => {
    return Object.keys(this.props.insurances).map((key, i) => {
      const insurance = this.props.insurances[key]
      if (
        insurance.recordTypeName === insuranceTypes.annuity ||
        insurance.recordTypeName === insuranceTypes.annuities
      ) {
        return (
          <div key={key} className='insurance__row insurance__col-w'>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.benefit.policyNumber === 'null'
                ? ''
                : insurance.benefit.policyNumber}
            </span>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.benefit.ownerName === 'null'
                ? ''
                : insurance.benefit.ownerName}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.yearlyBenefit &&
              insurance.benefit.yearlyBenefit.toString() === 'null'
                ? ''
                : dollarsOrBlankSpace(insurance.benefit.yearlyBenefit)}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance.benefit.surrenderPeriod === 'null'
                ? ''
                : nullStringFormat(insurance.benefit.surrenderPeriod)}
            </span>
            <span className='insurance__col-item insurance__col-item--medium'>
              {insurance.benefit.annualPremium &&
              insurance.benefit.annualPremium.toString() === 'null'
                ? ''
                : dollarsOrBlankSpace(insurance.benefit.annualPremium)}
            </span>
            <span className='insurance__col-item insurance__col-item--small'>
              {insurance && insurance.name ? insurance.name : ''}
            </span>
            <span className='insurance__col-item insurance__col-item--small insurance__toggle-pdf'>
              <InGuideBookSwitch
                inGuidebook={insurance.inGuidebook}
                id={insurance.id}
                householdFinId={insurance.household}
              />
            </span>
            <span className='insurance__edit insurance__col-item insurance__col-item--menu-toggle'>
              <InsuranceUpdate insurance={insurance} />
            </span>
          </div>
        )
      } else {
        return null
      }
    })
  }

  public render() {
    if (
      Object.keys(this.props.insurances).filter(
        (key) =>
          this.props.insurances[key].recordTypeName === insuranceTypes.annuity
      ).length > 0
    ) {
      return (
        <div className='insurance-w'>
          <Tile leftHeader={'ANNUITIES'} headerBorder={true} rightHeader={null}>
            <TableRow backgroundColor='#F5F5F5'>
              <div className='insurance__col-w'>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>Policy Number</label>
                </span>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>Owner</label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Yearly <br />
                    Benefit
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Surrender <br /> Period
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--medium'>
                  <label>
                    Annual <br />
                    Premium
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small'>
                  <label>
                    Company <br />
                    Name
                  </label>
                </span>
                <span className='insurance__col-item insurance__col-item--small insurance__toggle-pdf'>
                  <label>Show/Hide</label>
                  <Tooltip
                    message={staticText.includeInGc}
                    width={160}
                    position='bottom'
                    multiLine={true}>
                    <HelpIcon />
                  </Tooltip>
                </span>
                <span className='insurance__col-item insurance__col-item--menu-toggle' />
              </div>
            </TableRow>
            {this.renderData()}
          </Tile>
        </div>
      )
    } else {
      return null
    }
  }
}

export default AnnuitiesInsuranceRow
