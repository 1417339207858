import React, { FC, Fragment } from 'react'
import {
  IMStrategiesObj,
  CollateralObj,
  custodianKeyMap
} from '../../../../objects/strategies'
import { formatGoalAmount } from '../../../helpers'
import * as SortHelper from '../../../helpers/householdPreferences'
import { InstitutionObj } from '../../../../objects/institution'
import StrategyToolTip from '../strategyTooltip'
import helpIcon from '../../../assets/images/strategySearch/ic-help.png'

const expenseRatioText =
  'The expense ratio only incorporates the fee for the underlying holdings, which is dynamic in nature and would change whenever the strategy undergoes a model update changing the constituents/weightings of the portfolio. '

const managerFeeText =
  'Associated fee for the sub-manager responsible for managing the strategy.'

const operationalFeeText =
  'Envestnet fee charged for the strategy including but not limited to gateway, platform and/or administration fees. Also known as sponsor fee as it relates to Envestnet.'

export const FeesList: FC<{
  strategy: IMStrategiesObj
  institution: InstitutionObj
}> = (props) => {
  const { institution } = props
  const {
    expenseRatio = null,
    managerFee = null,
    operationalFee = null,
    yield: yieldDisplay = null
  } = props.strategy

  const operationalFeeDisplay = () => {
    return !institution.isFinlifeInstitution ? (
      <span>
        <h5>
          Operational Fee
          <StrategyToolTip imgSrc={helpIcon}>
            {operationalFeeText}
          </StrategyToolTip>
        </h5>

        <span>
          {(operationalFee > 0 &&
            operationalFee &&
            formatGoalAmount(operationalFee, 'percent')) ||
            '0.00%'}
        </span>
      </span>
    ) : null
  }

  const managerFeeDisplay = () => {
    return !institution.isFinlifeInstitution ? (
      <Fragment>
        <h5>
          Manager Fee
          <StrategyToolTip imgSrc={helpIcon}>{managerFeeText}</StrategyToolTip>
        </h5>
        <span>
          {(managerFee > 0 &&
            managerFee &&
            formatGoalAmount(managerFee, 'percent')) ||
            '0.00%'}
        </span>
      </Fragment>
    ) : null
  }

  return (
    <div
      className='strategy-search__col-item strategy-search__col-item--long-med'
      style={{ width: '140px' }}>
      <div className='strategy-search__item strategy-search__item-list'>
        <h5>12-Month Yield</h5>
        <span>
          {(yieldDisplay > 0 &&
            yieldDisplay &&
            formatGoalAmount(yieldDisplay, 'percent')) ||
            '0.00%'}
        </span>
        <h5>
          Expense Ratio
          <StrategyToolTip imgSrc={helpIcon}>
            {expenseRatioText}
          </StrategyToolTip>
        </h5>
        <span>
          {(expenseRatio > 0 &&
            expenseRatio &&
            formatGoalAmount(expenseRatio, 'percent')) ||
            '0.00%'}
        </span>

        {managerFeeDisplay()}
        {operationalFeeDisplay()}
      </div>
    </div>
  )
}

export const CollateralFiles: FC<{
  collaterals: CollateralObj[]
  expand: boolean
}> = (props) => {
  const { collaterals, expand } = props

  const ranked: CollateralObj[] = []
  let unRanked: CollateralObj[] = []

  collaterals &&
    collaterals
      .sort((a, b) => {
        const fieldName = 'rankOrder'
        return SortHelper.compareNumericField(a[fieldName], b[fieldName], false)
      })
      .forEach((collateral: CollateralObj) => {
        const { rankOrder, name } = collateral
        if (!rankOrder || !name) {
          unRanked.push(collateral)
        } else {
          ranked.push(collateral)
        }
      })
  unRanked = unRanked.sort((a, b) => {
    const fieldName = 'name'
    if (!a[fieldName]) {
      return 1
    }
    return SortHelper.compareStringField(a[fieldName], b[fieldName], false)
  })

  const filesDisplay = [...ranked, ...unRanked].map(
    (collateral: CollateralObj, index: number) => {
      const openFileWindow = () => {
        collateral.collateralUrl &&
          window.open(`${collateral.collateralUrl}`, '_blank')
      }
      return (
        <span key={index} onClick={openFileWindow}>
          {`${collateral.name}`}
        </span>
      )
    }
  )

  const collateralsClass = expand
    ? 'strategy-search__item-collateral strategy-search__item-collateral--expand'
    : 'strategy-search__item-collateral'
  return (
    <div className='strategy-search__col-item strategy-search__col-item--long-med'>
      <div className='strategy-search__item strategy-search__item-list strategy-search__item-list--item'>
        <h5>Collateral</h5>
        <div className={`strategy-search__item-list ${collateralsClass}`}>
          {filesDisplay}
        </div>
        {props.children}
      </div>
    </div>
  )
}

export const CustodianList: FC<{
  strategy: IMStrategiesObj
}> = (props) => {
  const { strategy } = props
  // validate if all custodian flags are false for a given strategy
  const noDataAvailable = Object.values(custodianKeyMap).every(
      (custodian) => strategy[custodian] === false
  )
  return !noDataAvailable ? (
      <div className='strategy-search__item strategy-search__item-table'>
        <div className='strategy-search__item strategy-search__item-table strategy-search__item-table--row'>
          <div className='strategy-search__item-table--custodian'>
            <h5>{'Available Custodians'}</h5>
          </div>
        </div>

        {Object.keys(custodianKeyMap).map((custodianItem) => {
          return (
              (strategy[custodianKeyMap[custodianItem]] && (
                  <div
                      key={custodianKeyMap[custodianItem]}
                      className='strategy-search__item strategy-search__item-table strategy-search__item-table--row  strategy-search__item-table--item'>
                    <div className='strategy-search__item-table--custodian'>
                      {custodianItem}
                    </div>
                  </div>
              )) ||
              null
          )
        })}
      </div>
  ) : (
      <div />
  )
}
