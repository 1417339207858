import React, { FunctionComponent } from 'react'

import WarningIcon from '../../../assets/images/icons/png/ic_warning.png'
import WarningIconGrey from '../../../assets/images/icons/ic_warning_grey.png'

interface ErrorComponentProps {
  errStatus: number
}

const ErrorComponent: FunctionComponent<ErrorComponentProps> = (props): any => {
  const { errStatus } = props
  if (errStatus === 1) {
    return (
      <div className='proposal-work__message-wrapper'>
        <div className='proposal-work__message proposal-work__message-error'>
          <div className='proposal-work__message__icon'>
            <img src={WarningIcon} alt='no-icon' />
          </div>
          <div>This proposal does not meet the strategy minimum.</div>
        </div>
      </div>
    )
  } else if (errStatus === 2) {
    return (
      <div className='proposal-work__message-wrapper'>
        <div className='proposal-work__message'>
          <div className='proposal-work__message__icon'>
            <img src={WarningIconGrey} alt='no-icon' />
          </div>
          <div>
            Account was removed in the Portfolio Builder only. AdvisorCenter
            account remains intact.
          </div>
        </div>
      </div>
    )
  } else if (errStatus === 3) {
    return (
      <div className='proposal-work__message-wrapper'>
        <div className='proposal-work__message'>
          <div className='proposal-work__message__icon'>
            <img src={WarningIconGrey} alt='no-icon' />
          </div>
          <div>
            Account changes are made in Portfolio Builder only. AdvisorCenter
            account details remains intact.
          </div>
        </div>
      </div>
    )
  } else if (errStatus === 4) {
    return (
      <div className='proposal-work__message-wrapper'>
        <div className='proposal-work__message proposal-work__message-error'>
          <div className='proposal-work__message__icon'>
            <img src={WarningIcon} alt='no-icon' />
          </div>
          <div>
            This proposal has strategies assigned that are no longer recommended
            for the household and will not be included in Proposal PDF.
          </div>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

export default ErrorComponent
