import React, { Component, CSSProperties } from 'react'
import Button from '../components/button'

interface NullStateProps {
  text: string | JSX.Element
  style?: CSSProperties
  heading?: string
  imgSrc?: string
  multiImageSrc?: JSX.Element
  buttonTxt?: string
  buttonHref?: string
}

export class NullState extends Component<NullStateProps> {
  public render() {
    const {
      style,
      heading,
      text,
      imgSrc,
      buttonTxt,
      buttonHref,
      multiImageSrc
    } = this.props

    const nullImage = () => {
      const singleImg = (
        <img
          className='null-state__img'
          src={imgSrc}
          alt=''
          role='presentation'
        />
      )
      return imgSrc ? singleImg : multiImageSrc
    }

    return (
      <div style={style} className='null-state__w'>
        {nullImage()}
        {heading && <h3 className='null-state__heading'> {heading} </h3>}
        <p className={`null-state__text${imgSrc ? '' : '--multiImg'}`}>
          {' '}
          {text}{' '}
        </p>
        {buttonTxt ? (
          <div className='null-state__btn-w'>
            <Button primary={true} href={buttonHref}>
              {buttonTxt}
            </Button>
          </div>
        ) : null}
      </div>
    )
  }
}

export default NullState
