import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { Institution } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

export const FETCH_INSTITUTION: string = 'FETCH_INSTITUTION'
export const FETCH_INSTITUTION_PENDING: string = 'FETCH_INSTITUTION_PENDING'
export const FETCH_INSTITUTION_FULFILLED: string = 'FETCH_INSTITUTION_FULFILLED'
export const FETCH_INSTITUTION_REJECTED: string = 'FETCH_INSTITUTION_REJECTED'

export const FETCH_BENCHMARKS: string = 'FETCH_BENCHMARKS'
export const FETCH_BENCHMARKS_PENDING: string = 'FETCH_BENCHMARKS_PENDING'
export const FETCH_BENCHMARKS_FULFILLED: string = 'FETCH_BENCHMARKS_FULFILLED'
export const FETCH_BENCHMARKS_REJECTED: string = 'FETCH_BENCHMARKS_REJECTED'

export const getInstitution = (): {
  type: string
  payload: Promise<AxiosResponse<Institution.Response>>
} => {
  return {
    type: FETCH_INSTITUTION,
    payload: api()
      .get('/institutions')
      .then((response) => {
        return response
      })
  }
}

export const getBenchmarks = () => {
  return {
    type: FETCH_BENCHMARKS,
    payload: api()
      .get('/institutions/benchmarks')
      .then((response) => {
        return response
      })
  }
}
