export interface InsuranceObj {
  id: string
  name: string
  type: string
  deathBenefit: number
  inGuidebook: boolean
  household: string
  policyLimit: number
  formattedValue: string
  insuredName: string
  recordTypeName: string
  isDeleted: boolean
  benefit: {
    type: string
    amount: number
    summary: string
    beneficiaryName: string
    benefitPeriodYears: number
    eliminationPeriod: number
    ownerName: string
    policyNumber: string
    annualPremium: number
    surrenderPeriod: string
    monthlyBenefitAmount: number
    yearlyBenefit: number
    monthlyBenefitAmountLtc: number
    premiumAmount: number
    premiumFrequency: string
  }
}

export interface InsuranceInterface {
  [key: string]: InsuranceObj
}

export interface InsuranceSelectInterface {
  value: string
  label: string
  fieldName: string
  currentTarget?: { name: string; value: string }
}

export const insuranceTypes = {
  lifeInsurance: 'Life Insurance',
  annuities: 'Annuities',
  annuity: 'Annuity',
  disability: 'Disability',
  longTerm: 'Long Term Care'
}

export const premiumFrequencyOptionList = {
  monthly: {
    value: 'Monthly',
    label: 'Monthly',
    fieldName: 'premiumFrequency'
  },
  annually: {
    value: 'Annually',
    label: 'Annually',
    fieldName: 'premiumFrequency'
  },
  quarterly: {
    value: 'Quarterly',
    label: 'Quarterly',
    fieldName: 'premiumFrequency'
  },
  onetime: {
    value: 'One-Time',
    label: 'One-Time',
    fieldName: 'premiumFrequency'
  }
}

export const insuranceTypesOptionList = {
  lifeInsurance: {
    value: 'Life Insurance',
    label: 'Life Insurance',
    fieldName: 'typeName'
  },
  annuity: { value: 'Annuity', label: 'Annuity', fieldName: 'typeName' },
  disability: {
    value: 'Disability',
    label: 'Disability',
    fieldName: 'typeName'
  },
  longTerm: {
    value: 'Long Term Care',
    label: 'Long Term Care',
    fieldName: 'typeName'
  }
}

export const requiredInsuranceFields = {
  annuity: ['owner', 'yearlyBenefit', 'premiumAmount'],
  lifeinsurance: ['owner', 'deathBenefit', 'premiumAmount'],
  disability: ['insured', 'duration', 'monthlyBenefit', 'premiumAmount'],
  longtermcare: ['insured', 'duration', 'monthlyBenefit', 'premiumAmount']
}

export const formatTypes = [
  'duration',
  'deathBenefit',
  'premiumAmount',
  'monthlyBenefit',
  'yearlyBenefit'
]
