import React, { Component, RefObject } from 'react'
import {
  PortfolioAnalyticsFunc,
  PortfolioAnalyticsStateKeys
} from '../../../../../../objects/PortfolioAnalytics/analytics'

import { ImageFile } from 'react-dropzone'
import { samplePositionsTemplate } from '../../../../../helpers/positions'

interface ImportPositionProps {
  householdFinId: string
  clientAccountId: string
  featureFunc: PortfolioAnalyticsFunc
}

class ImportPosition extends Component<ImportPositionProps> {
  public fileInputRef: RefObject<HTMLInputElement> = React.createRef()
  public fileSampleRef: RefObject<HTMLAnchorElement> = React.createRef()

  public toggleView = () => {
    const {
      featureFunc: { toggleModal }
    } = this.props
    const stateKey: PortfolioAnalyticsStateKeys = {
      toggleImportPositions: 'toggleImportPositions'
    }
    toggleModal(stateKey)
  }

  public uploadFileEvent = (data: any) => {
    const {
      clientAccountId,
      featureFunc: { uploadPositions }
    } = this.props
    uploadPositions(clientAccountId, data)
  }

  public selectFileEvent = (
    e: React.ChangeEvent<HTMLInputElement> | ImageFile[]
  ) => {
    const isChangeEventType = (
      fileEvent: React.ChangeEvent<HTMLInputElement> | ImageFile[]
    ): fileEvent is React.ChangeEvent<HTMLInputElement> => {
      return Boolean((fileEvent as React.ChangeEvent<HTMLInputElement>).target)
    }
    const files = isChangeEventType(e) ? Array.from(e.target.files) : e
    files.forEach((rawFile) => {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        const { result, error } = fileReader
        !error && this.uploadFileEvent(result)
      }
      fileReader.readAsText(rawFile)
    })
  }

  public sampleFile = () => {
    const { current } = this.fileSampleRef
    current.id = 'Position-Upload-Template'
    current.setAttribute('href', samplePositionsTemplate())
    current.setAttribute('download', 'Position Upload Template.csv')
    current.click()
  }

  public render = () => {
    return <div></div>
  }
}

export default ImportPosition
