import React from 'react'
import PDF from 'react-pdf-js'

interface PdfRendererState {
  PortfolioImplementation: {
    page: number
    pages: number
  }
  ClientProfile: {
    page: number
    pages: number
  }
  RecommendationsCover: {
    page: number
    pages: number
  }
  PortfolioSnapshot: {
    page: number
    pages: number
  }
  InvestmentRecommendations: {
    page: number
    pages: number
  }
  PortfolioDetails: {
    page: number
    pages: number
  }
  AppendixCover: {
    page: number
    pages: number
  }
  FactSheet: {
    page: number
    pages: number
  }
}

interface pdfSectionsObj {
  checkedClientProfileCover: boolean
  checkedRecommendationsCover: boolean
  checkedPortfolioSnapshot: boolean
  checkedPortfolioDetails: boolean
  checkedInvestmentRecommendations: boolean
  checkedPortfolioImplementation: boolean
  checkedFundedScore: boolean
  checkedHonestConversations: boolean
  checkedMoneyMind: boolean
  checkedViewfinderResults: boolean
  checkedFactSheet: boolean
}

interface PdfRendererProps {
  id: string
  loadingFactSheet: boolean
  pdfSections: pdfSectionsObj
  pdfData: any
}

const portfolioPdfs = [
  'FinLifeProcessOverview',
  'InvestmentProcessOverview',
  'ClientProfile',
  'RecommendationsCover',
  'PortfolioSnapshot',
  'PortfolioImplementation',
  'InvestmentRecommendations',
  'PortfolioDetails',
  'AppendixCover',
  'FactSheet'
]

class PdfRenderer extends React.Component<PdfRendererProps, PdfRendererState> {
  constructor(props: PdfRendererProps) {
    super(props)
    this.state = {
      PortfolioImplementation: {
        page: null,
        pages: null
      },
      ClientProfile: {
        page: null,
        pages: null
      },
      RecommendationsCover: {
        page: null,
        pages: null
      },
      PortfolioSnapshot: {
        page: null,
        pages: null
      },
      InvestmentRecommendations: {
        page: null,
        pages: null
      },
      PortfolioDetails: {
        page: null,
        pages: null
      },
      AppendixCover: {
        page: null,
        pages: null
      },
      FactSheet: {
        page: null,
        pages: null
      }
    }
  }

  // PDF PAGE FLIP METHODS
  // the 'e' in onDocumentComplete is the total page number of the pdf
  public onDocumentComplete = (pdfName: string) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: 1,
      pages: parseInt(e.toString())
    }
    this.setState(state)
  }

  public handlePrevious = (pdfName: string) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: this.state[pdfName].page - 1,
      pages: this.state[pdfName].pages
    }

    this.setState(state)
  }
  public handleNext = (pdfName: any) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: this.state[pdfName].page + 1,
      pages: this.state[pdfName].pages
    }
    this.setState(state)
  }
  public renderPagination = (pdfName: string, page: number, pages: number) => {
    let previousButton = (
      <li className='btn btn__prime' onClick={this.handlePrevious(pdfName)}>
        Previous
      </li>
    )
    if (page === 1) {
      previousButton = <li className='btn btn__default'>Previous</li>
    }
    let nextButton = (
      <li className='btn btn__prime' onClick={this.handleNext(pdfName)}>
        Next
      </li>
    )
    if (page === pages) {
      nextButton = <li className='btn btn__default'>Next</li>
    }
    return (
      <div className='gb-container__next-prev-w'>
        {previousButton}
        {nextButton}
      </div>
    )
  }

  public renderPdfs = () => {
    const renderSections = { ...this.props.pdfSections }
    const { pdfData } = this.props
    return portfolioPdfs.map((pdfName: string) => {
      if (renderSections[`checked${pdfName}`] && pdfData[pdfName]) {
        const pdfContent = pdfData[pdfName]
        return (
          <div key={pdfName}>
            <PDF
              file={pdfContent}
              onDocumentComplete={this.onDocumentComplete(pdfName)}
              page={this.state[pdfName] ? this.state[pdfName].page : null}
              style={{ width: '100%' }}
            />
            <br />
            {this.state[pdfName] && this.state[pdfName].pages
              ? this.renderPagination(
                  pdfName,
                  this.state[pdfName].page,
                  this.state[pdfName].pages
                )
              : null}
          </div>
        )
      } else return null
    })
  }

  public render() {
    return this.renderPdfs()
  }
}

export default PdfRenderer
