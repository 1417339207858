import React, { SFC } from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'

interface HcConfirmCancel {
  confirmCancel(event: React.MouseEvent | string): void
}

const HcConfirmCancelEdit: SFC<HcConfirmCancel> = (props) => {
  const { confirmCancel } = props

  const closeConfirmModal = () => {
    props.confirmCancel('cancel')
  }

  return (
    <Modal
      icon={EditIcon}
      title={'Cancel Changes'}
      closeModal={closeConfirmModal}>
      <div className='hc-comment-modal'>
        <div className='hc-comment-modal__content'>Cancel all changes?</div>
        <div
          className='hc-comment-modal__buttons'
          style={{ justifyContent: 'flex-end' }}>
          <div>
            <span className='btn btn__clear' onClick={confirmCancel}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={confirmCancel}>
              Confirm
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default HcConfirmCancelEdit
