import api from '../v3/helpers/api'
import { FinancialGoalObj, FundedScoreObj } from '../objects/financialGoal'
import { Goal } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
import { AxiosResponse } from 'axios'
import moment from 'moment'

export const FETCH_FINANCIAL_GOALS = 'FETCH_FINANCIAL_GOALS'
export const CREATE_FINANCIAL_GOAL = 'CREATE_FINANCIAL_GOAL'
export const DELETE_FINANCIAL_GOAL = 'DELETE_FINANCIAL_GOAL'
export const EDIT_FINANCIAL_GOAL = 'EDIT_FINANCIAL_GOAL'
export const REFRESH_MGP_GOALS = 'REFRESH_MGP_GOALS'
export const REFRESH_GDX360_GOALS = 'REFRESH_GDX360_GOALS'
export const REFRESH_EMONEY_GOALS = 'REFRESH_EMONEY_GOALS'
export const CREATE_FUNDED_SCORE = 'CREATE_FUNDED_SCORE'
export const UPDATE_FUNDED_SCORE = 'UPDATE_FUNDED_SCORE'

interface GoalResponse extends Goal.Response {
  householdFinId: string
  goalFinId?: string
}

const getGoalRequestBody = (
  goal: FinancialGoalObj,
  householdFinId: string
): Goal.Request => {
  return {
    householdId: householdFinId,
    amount:
      goal.amount && typeof goal.amount === 'string'
        ? Number(goal.amount.replace(/[^\d.]/gi, ''))
        : null,
    amountType: goal.amountType === '' ? 'Dollar' : goal.amountType,
    annualIncrease:
      goal.annualIncrease && typeof goal.annualIncrease === 'string'
        ? Number(goal.annualIncrease.replace(/[^\d.]/gi, ''))
        : Number(goal.annualIncrease),
    createdDate: moment().toDate(),
    endOfGoal: goal.endDate,
    timing: goal.type && goal.type === 'Timing Control' ? goal.amount : null,
    goalOwnerId: goal.owner,
    includeInGuideBook: true,
    name: goal.name,
    startOfGoal: goal.startDate,
    targetDollerValue: null,
    targetYearOfGoal: null,
    type: goal.type,
    recordType: 'Default'
  }
}

export const getFinancialGoals = (householdFinId: string) => {
  return {
    type: FETCH_FINANCIAL_GOALS,
    payload: api()
      .get(`/households/${householdFinId}/goals`)
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const addFinancialGoal = (
  goal: FinancialGoalObj,
  householdFinId: string
) => {
  const requestBody = getGoalRequestBody(goal, householdFinId)
  return {
    type: CREATE_FINANCIAL_GOAL,
    payload: api()
      .post(`/households/${householdFinId}/goals`, requestBody)
      .then((response: AxiosResponse<GoalResponse>): any => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const deleteFinancialGoal = (
  goalFinId: string,
  householdFinId: string
) => {
  return {
    type: DELETE_FINANCIAL_GOAL,
    payload: api()
      .delete(`/households/${householdFinId}/goals/${goalFinId}`)
      .then((response: AxiosResponse<GoalResponse>): any => {
        if (!response.data) {
          response.data = { ...response.data, householdFinId, goalFinId }
        }
        return response
      })
  }
}

export const editFinancialGoal = (
  goal: FinancialGoalObj,
  householdFinId: string
) => {
  const requestBody: Goal.Request = getGoalRequestBody(goal, householdFinId)
  return {
    type: EDIT_FINANCIAL_GOAL,
    payload: api()
      .put(`/households/${householdFinId}/goals/${goal.id}`, requestBody)
      .then((response: AxiosResponse<GoalResponse>): any => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const refreshMGP = (householdFinId: string) => {
  return {
    type: REFRESH_MGP_GOALS,
    payload: api()
      .post(
        `/households/${householdFinId}/goals/mgpsync`,
        {},
        { timeout: 60000 }
      )
      .then((response): any => {
        if (
          response.data.meta.status >= 200 &&
          response.data.meta.status < 300
        ) {
          response.data.householdFinId = householdFinId
          return response
        } else {
          return Promise.reject(response)
        }
      })
  }
}

export const refreshGDX360 = (householdFinId: string) => {
  return {
    type: REFRESH_GDX360_GOALS,
    payload: api()
      .post(`/households/${householdFinId}/goals/gdx360sync`)
      .then((response: AxiosResponse<GoalResponse>): any => {
        return response
      })
  }
}

export const refreshEmoney = (householdFinId: string, emoneyId: string) => {
  return {
    type: REFRESH_EMONEY_GOALS,
    payload: api()
      .post(`/households/${householdFinId}/goals/emoneysync`, {
        ids: [emoneyId]
      })
      .then((response: AxiosResponse<GoalResponse>): any => {
        return response
      })
  }
}

export const addFundedScore = (score: number, householdFinId: string) => {
  return {
    type: CREATE_FUNDED_SCORE,
    payload: api()
      .post(`/households/${householdFinId}/fundedscores`, { score })
      .then((response: AxiosResponse<any>): any => {
        return response
      })
  }
}

export const updatedFundedScore = (
  id: string,
  fundedScoreUpdated: FundedScoreObj,
  householdFinId: string
) => {
  return {
    type: UPDATE_FUNDED_SCORE,
    payload: api()
      .put(`/households/${householdFinId}/fundedscores/${id}`, {
        ...fundedScoreUpdated
      })
      .then((response: AxiosResponse<any>): any => {
        return response
      })
  }
}
