import React, { Component } from 'react'

import { dateFormat } from '../../helpers'
import { filterDisableTasks, renderStatus } from '../../helpers/tasks'
import { TaskObjState, TaskObj, ETaskView } from '../../../objects/tasks'
import Tile from '../../components/layout/tile'
import NullTask from './nullTask'
import Heading from './heading'

import { ReactComponent as TasksIcon } from '../../assets/images/icons/ic_tasks.svg'
import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import CheckIcon from '../../assets/images/icons/png/task_check.png'
import { sortTaskItems } from '../../helpers/sort/taskSort'

interface DashTasksProps {
  householdFinId: string
  disableTasks: boolean
  tasks: TaskObjState
}

export class DashTasks extends Component<DashTasksProps> {
  public task = (task: TaskObj, index: number) => {
    let nonCompleteStyle = null
    // If CRM Task, check isClosed to determine open/completed task
    if (task.crmId || task.isCrmTask) {
      nonCompleteStyle = !task.isClosed ? { backgroundColor: '#d2d2d2' } : null
      // If Native Task, check status to determine open/completed task
    } else {
      nonCompleteStyle =
        task.status !== 'Completed' ? { backgroundColor: '#d2d2d2' } : null
    }
    return (
      <div className='c-dash-task' key={`${task.id}${index}`}>
        <div className='c-dash-task-completed'>
          <span style={nonCompleteStyle}>
            <img src={CheckIcon} role='presentation' alt='' />
          </span>
        </div>
        <div className='c-dash-task__row'>
          <h5 className='c-dash-task__row-name'>{task.subject}</h5>
          <div className='c-dash-task__row-w'>
            <div className='c-dash-task__row-date'>{task.assigneeNames}</div>
            <div className='message-thread-list-item__dot'> • </div>
            <div className='c-dash-task__row-date'>
              {task.dueDate ? dateFormat(task.dueDate) : ''}
            </div>
            <div className='c-dash-task__row-status'>{renderStatus(task)}</div>
          </div>
        </div>
      </div>
    )
  }

  public renderTaskList = (tasks: TaskObjState) => {
    const srtTasksByDate = sortTaskItems(tasks, {
      sortBy: 'DUE DATE',
      sortDescending: false
    })
    const srtTasksByStatus = sortTaskItems(srtTasksByDate, { sortBy: 'STATUS' })
    return Object.keys(srtTasksByStatus)
      .slice(0, 5)
      .map((key, index) => this.task(tasks[key], index))
  }

  public render() {
    const { tasks, householdFinId, disableTasks } = this.props
    if (!tasks || Object.keys(tasks).length === 0) {
      return <NullTask householdFinId={householdFinId} />
    } else {
      return (
        <Tile
          to={`/households/${this.props.householdFinId}/tasks`}
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          leftHeader={
            <Heading icon={<TasksIcon />} headingText={'CLIENT TASKS'} />
          }
          rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
          headerBorder={true}
          anchorBorder={true}>
          <div className='c-dash__row-content c-dash-task-w'>
            {this.renderTaskList(filterDisableTasks(tasks, disableTasks))}
          </div>
        </Tile>
      )
    }
  }
}

export default DashTasks
