import React, { CSSProperties, FunctionComponent } from 'react'
import {
  Button,
  Tile
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  HcWelcomeContainer,
  HcWelcomeDiv,
  HcWelcomeTitle,
  HcParagraph
} from './hcWelcomeHelper'
import motivationImage from '../../../../assets/images/honestConversations/ic-motivation.png'
import progressImage from '../../../../assets/images/honestConversations/ic-rating.png'

const blockOne = `Help us understand what matters to you and why`
const blockTwo = `Get us in agreement on what you want to prioritize`
const blockThree = `Measure progress and provide focus for the future`

const imageStyle = { height: '140px', marginBottom: '5px' }

export const DescriptionContent: FunctionComponent<{
  tileStyle: CSSProperties
  buttonStyle: CSSProperties
  onClickFunc?(): void
}> = ({ buttonStyle, onClickFunc, tileStyle }) => (
  <Tile tileStyle={tileStyle}>
    <HcWelcomeContainer>
      <HcWelcomeDiv className='hcw__welcome_messege-row'>
        <HcWelcomeDiv className='hcw__welcome_messege-row'>
          <HcWelcomeDiv className='hcw__welcome_messege-col'>
            <HcWelcomeTitle className='hcw__welcome_messege-title hcw__welcome_messege-title-m'>
              This exercise will...
            </HcWelcomeTitle>
            <HcWelcomeDiv className='hcw__welcome_messege-row'>
              <HcWelcomeDiv className='hcw__welcome_messege-col'>
                <div style={imageStyle}>
                  <img
                    src={motivationImage}
                    alt=''
                    role='presentation'
                    width='134'
                  />
                </div>
                <div>
                  <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-sm'>
                    {blockOne}
                  </HcParagraph>
                </div>
              </HcWelcomeDiv>

              <HcWelcomeDiv className='hcw__welcome_messege-col'>
                <div style={imageStyle}>
                  <img
                    src={progressImage}
                    alt=''
                    role='presentation'
                    width='134'
                  />
                </div>
                <div>
                  <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-sm'>
                    {blockTwo}
                  </HcParagraph>
                </div>
              </HcWelcomeDiv>

              <HcWelcomeDiv className='hcw__welcome_messege-col'>
                <div style={imageStyle}>
                  <img
                    src={progressImage}
                    alt=''
                    role='presentation'
                    width='134'
                  />
                </div>
                <div>
                  <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-sm'>
                    {blockThree}
                  </HcParagraph>
                </div>
              </HcWelcomeDiv>
            </HcWelcomeDiv>
            <Button btnStyle={buttonStyle} onClick={onClickFunc}>
              Let's Begin!
            </Button>
          </HcWelcomeDiv>
        </HcWelcomeDiv>
      </HcWelcomeDiv>
    </HcWelcomeContainer>
  </Tile>
)
