import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../../reducers'
import * as householdActions from '../../../../actions/households'
import * as hcExercisesActions from '../../../../actions/hcExercises'
import { HouseholdObj } from '../../../../objects/household'
import { ContactsInterface } from '../../../../objects/contact'
import { dateFormat, isObjEmpty } from '../../../helpers'
import { getGoalActions } from '../../../../actions/financialGoalActions'
import InnerContainer from '../../../components/layout/innerContainer'
import Tile from '../../../components/layout/tile'
import BackToLink from '../../../components/layout/headerBackToLink'
import QuickActionLinks from '../../../components/quickActionLinks'
import Button from '../../../components/button'
import HonestConversationIndividualCards from './hcIndividualCards'
import HonestConversationCombinedCards from './hcCombinedCards'
import HonestConversationEditIndividualCards from './editMeeting/hcEditIndividualCards'
import HonestConversationEditCombinedCards from './editMeeting/hcEditCombinedCards'
import HonestConversationConfirmCancelModal from '../hcConfirmCancel'
import ActivityIcon from '../../../assets/images/icons/png/activity.png'
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'
import { DropResult } from 'react-beautiful-dnd'
import {
  CardObj,
  CardOptionsInterface
} from '../../../../objects/HonestConversations/card'
import {
  HonestConversationsExerciseObj,
  ClientCardObj,
  CardRankingObj,
  CardScoreRequestObj
} from '../../../../objects/honestConversations'
import { FinancialGoalActionObj } from '../../../../objects/financialGoalActions'
import { filterGoals } from '../../../helpers/goals'
import { getHCCards } from '../../../../actions/hcExerciseCards'
import {
  setGoalIdActions,
  updateSideBarState
} from '../../../../actions/financialGoalActions'
import {
  mapScoresToCards,
  setRanking,
  mapUpdateCardScore,
  sortHouseholdCards
} from '../../../helpers/honestConversations'
import { isEqual } from '../../../helpers/index'
import { getCardForComment, getCardForAutoSave } from './helpers/hcCardMeeting'
import goalActionsSelector from '../../../../selectors/v3/goals'

interface HonestConversationsMeetingProps {
  hcExerciseCards: CardOptionsInterface
  dispatch: Dispatch<GlobalState>
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  householdFinId: string
  meetingFinId: string
  household: HouseholdObj
  goals: FinancialGoalActionObj[]
}

interface HonestConversationsMeetingState {
  selectedTab: string
  edit: boolean
  showCancelConfirmModal: boolean
  primaryContactCards: CardRankingObj[]
  secondaryContactCards: CardRankingObj[]
  combinedCards: CardRankingObj[]
  previousActiveScoringSessionIdPrimary: string
  previousActiveScoringSessionIdSecondary: string
  primaryScoreSessionId: string
  secondaryScoreSessionId: string
}

class HonestConversationsMeeting extends Component<
  HonestConversationsMeetingProps,
  HonestConversationsMeetingState
> {
  constructor(props: HonestConversationsMeetingProps) {
    super(props)
    const { honestConversation, contacts } = this.props
    const primaryContactCards = this.getTopFiveCards(
      honestConversation && honestConversation.clientCards[contacts.primary.id]
    )

    const secondaryContactCards =
      contacts &&
      contacts.secondary &&
      this.getTopFiveCards(
        honestConversation &&
          honestConversation.clientCards[contacts.secondary.id]
      )
    this.state = {
      selectedTab: 'Combined',
      showCancelConfirmModal: false,
      edit: false,
      primaryContactCards,
      secondaryContactCards,
      combinedCards: this.preFillCombinedCards(),
      previousActiveScoringSessionIdPrimary: null,
      previousActiveScoringSessionIdSecondary: null,
      primaryScoreSessionId: null,
      secondaryScoreSessionId: null
    }
  }

  public async componentDidMount() {
    const { householdFinId, dispatch, honestConversation, goals } = this.props
    if (honestConversation && honestConversation.householdCards) {
      this.setState({ combinedCards: this.setCombinedCards() })
    } else if (!honestConversation) {
      await dispatch(
        hcExercisesActions.getHonestConversationExercises(householdFinId)
      )
    }
    if (!goals) {
      dispatch(getGoalActions(householdFinId))
    }
    await dispatch(getHCCards(householdFinId))
  }

  public componentDidUpdate(prevProps: HonestConversationsMeetingProps) {
    const { honestConversation, contacts, goals } = this.props

    if (
      contacts &&
      honestConversation &&
      (!isEqual(honestConversation, prevProps.honestConversation) ||
        (goals && !isEqual(goals, prevProps.goals)))
    ) {
      let newState = {}
      // handles client cards
      if (
        (!prevProps?.honestConversation?.clientCards &&
          honestConversation.clientCards) ||
        !isEqual(
          honestConversation.clientCards,
          prevProps.honestConversation.clientCards
        )
      ) {
        const primaryContactCards = this.getTopFiveCards(
          honestConversation.clientCards[contacts.primary.id]
        )
        const secondaryContactCards =
          contacts &&
          contacts.secondary &&
          this.getTopFiveCards(
            honestConversation.clientCards[contacts.secondary.id]
          )
        newState = {
          ...newState,
          ...{
            primaryContactCards,
            secondaryContactCards
          }
        }
      }

      // handles combined cards and scoring sessions
      if (
        (!prevProps?.honestConversation?.householdCards &&
          honestConversation.householdCards) ||
        !isEqual(
          prevProps.honestConversation.householdCards,
          honestConversation.householdCards
        ) ||
        !isEqual(
          prevProps.honestConversation.clientScoringSessions,
          honestConversation.clientScoringSessions
        ) ||
        (goals && !isEqual(goals, prevProps.goals))
      ) {
        newState = {
          ...newState,
          ...{
            combinedCards: this.setCombinedCards()
          }
        }
      }
      if (Object.keys(newState).length > 0) {
        this.setState(newState)
      }
    }
  }

  public setCombinedCards = () => {
    const { honestConversation, contacts, goals } = this.props

    const secondaryContact = contacts && contacts.secondary

    const scoringSessionPrimary =
      honestConversation && honestConversation.activeScoringSessionPrimary

    const scoringSessionSecondary =
      honestConversation && honestConversation.activeScoringSessionSecondary

    const mappedPrimaryScores =
      contacts &&
      scoringSessionPrimary &&
      mapScoresToCards(
        contacts.primary.id,
        honestConversation.activeScoringSessionPrimary,
        honestConversation
      )

    const mappedSecondaryScores =
      secondaryContact &&
      scoringSessionSecondary &&
      mapScoresToCards(
        contacts.secondary.id,
        honestConversation.activeScoringSessionSecondary || null,
        honestConversation
      )

    const sortedHouseholdCards =
      honestConversation &&
      sortHouseholdCards(honestConversation.householdCards)

    // copy of combined cards state
    const stateCombinedCards: CardRankingObj[] = [...this.state.combinedCards]

    // list of household cards with scores and actions added to it
    const newCombinedCards = stateCombinedCards.map((card) => {
      let newStateCard: any = {
        ...card
      }
      sortedHouseholdCards.map((item) => {
        if (card.ranking === item.ranking) {
          const primaryScoreObj =
            mappedPrimaryScores &&
            mappedPrimaryScores.find((scoreCard) => {
              return scoreCard?.householdCard?.id === item?.id
            })

          const secondaryScoreObj =
            mappedSecondaryScores &&
            mappedSecondaryScores.find((scoreCard) => {
              return scoreCard?.householdCard?.id === item?.id
            })

          let listOfActions = null
          if (goals) {
            listOfActions = filterGoals(goals, item?.card?.id)
          }
          newStateCard = {
            ...newStateCard,
            ...{
              card: item.card,
              ranking: item.ranking,
              id: item.id,
              primaryCardScoreId:
                primaryScoreObj?.cardScoreId || primaryScoreObj?.id,
              primaryScore: primaryScoreObj?.score,
              commentPrimary: primaryScoreObj?.comment,
              secondaryCardScoreId:
                secondaryScoreObj?.cardScoreId || secondaryScoreObj?.id,
              secondaryScore: secondaryScoreObj?.score,
              commentSecondary: secondaryScoreObj?.comment,
              actionList: listOfActions
            }
          }
        }
      })

      return newStateCard
    })
    return newCombinedCards
  }

  public preFillCombinedCards = (): CardRankingObj[] => {
    const tempCombinedCards: CardRankingObj[] = []
    for (let i = 0; i < 5; i += 1) {
      tempCombinedCards.push({
        id: null,
        cardId: null,
        ranking: i + 1,
        rankedWithin: 'All',
        card: null,
        commentPrimary: null,
        primaryScore: null,
        secondaryScore: null,
        commentSecondary: null
      })
    }
    return tempCombinedCards
  }

  public newScoringSession = async (contactId: string, isPrimary: boolean) => {
    // create new scoring session
    await this.createScoringSession(contactId, isPrimary)
    // update exercise with new active scoring session id
    await this.updateActiveScoreSessionId()
  }

  public getTopFiveCards = (clientCards: ClientCardObj[]) => {
    const selectedClientCards: CardRankingObj[] = clientCards
      ? clientCards.filter((clientCard) => clientCard.rankedWithin === 'All')
      : []
    const tempClientCards: CardRankingObj[] = []
    for (let i = 0; i < 5; i += 1) {
      tempClientCards.push({
        card: { id: null, title: null, category: null },
        ranking: i + 1,
        rankedWithin: 'All',
        comment: ''
      })
    }

    if (selectedClientCards.length > 0) {
      selectedClientCards.forEach((card: CardRankingObj) => {
        if (card) {
          tempClientCards.splice(card.ranking - 1, 1, card)
        }
      })
    }
    return tempClientCards
  }
  public onCancelSideBar = () => {
    const { household, dispatch } = this.props
    dispatch(
      updateSideBarState({ householdId: household?.id, sideBarState: false })
    )
    dispatch(setGoalIdActions({ goalId: null, householdFinId: household?.id }))
  }
  public onCardSelection = (
    index: number,
    contactType: string,
    card: CardObj
  ) => {
    const { contacts } = this.props
    if (card) {
      const prevState = JSON.parse(JSON.stringify(this.state))
      const {
        newState,
        cardExist,
        priorityCardExist,
        combinedCardExist
      } = getCardForAutoSave({
        contacts,
        prevState,
        contactType,
        index,
        card
      })
      this.setState(newState, () => {
        this.onCancelSideBar()
        this.autoSaveCard({
          cardExist,
          contactType,
          card,
          priorityCardExist,
          combinedCardExist
        })
      })
    }
  }

  public onCombinedCommentChange = (
    index: number,
    commentKey: 'commentPrimary' | 'commentSecondary',
    comment: string
  ) => {
    // get current combined card state
    const { combinedCards } = this.state
    const activeCard = combinedCards[index]
    if (!activeCard.isChanged) {
      activeCard.isChanged = true
    }
    activeCard[commentKey] = comment
    this.setState({ combinedCards })
  }

  public onCommentChange = (
    index: number,
    contactType: 'primaryContactCards' | 'secondaryContactCards',
    comment: string
  ) => {
    const cardList: CardRankingObj[] = this.state[contactType]
    const newState = {
      ...this.state,
      ...{
        [contactType]: getCardForComment({ comment, cardList, index })
      }
    }
    this.setState(newState)
  }

  // Recursion boolean is for Single HH only feature:
  // If clientCard or householdCard is reordered it needs to update one another
  // onDrag is retriggered one time with the recursion set to true
  public onDrag = (result: DropResult, recursion?: boolean) => {
    const newState = this.state
    const { source, destination } = result

    let listResult = Object.keys(newState[destination.droppableId]).map(
      (key) => newState[destination.droppableId][key]
    )

    if (newState[`${destination.droppableId}`].length > 0) {
      listResult = newState[`${destination.droppableId}`]
    }

    const [removed] = listResult.splice(source.index, 1)
    listResult.splice(destination.index, 0, removed)
    newState[`${destination.droppableId}`] = setRanking(listResult)

    // If single HH and recursion is false(onDrag has not repeated), set singleResult to either clientCard or CombinedCard
    // If recursion is true, we know that onDrag is in its repeated phase, below is skipped so infinite call does not happen
    let singleResult: DropResult = null
    if (!this.props.contacts.secondary && !recursion) {
      if (destination.droppableId === 'combinedCards') {
        singleResult = JSON.parse(JSON.stringify(result))
        singleResult.destination.droppableId = 'primaryContactCards'
      } else if (destination.droppableId === 'primaryContactCards') {
        singleResult = JSON.parse(JSON.stringify(result))
        singleResult.destination.droppableId = 'combinedCards'
      }
    }

    this.setState(newState, () => {
      // If single Result, repeat onDrag with recursion set to true so clientCard -> hh card or HH card -> clientCard also gets updated.
      if (singleResult) {
        this.onDrag(singleResult, true)
      }
      const { dispatch, householdFinId, meetingFinId, contacts } = this.props
      const {
        primaryContactCards,
        secondaryContactCards,
        combinedCards
      } = this.state
      const contactType = destination.droppableId
      switch (contactType) {
        case 'primaryContactCards':
          if (primaryContactCards?.length >= 1) {
            dispatch(
              hcExercisesActions.updateAllClientCards(
                householdFinId,
                meetingFinId,
                contacts.primary.id,
                primaryContactCards
              )
            )
          }
          break
        case 'secondaryContactCards':
          if (secondaryContactCards?.length >= 1) {
            dispatch(
              hcExercisesActions.updateAllClientCards(
                householdFinId,
                meetingFinId,
                contacts.secondary.id,
                secondaryContactCards
              )
            )
          }
          break
        case 'combinedCards':
          dispatch(
            hcExercisesActions.updateHonestConversationHouseholdCards(
              householdFinId,
              meetingFinId,
              combinedCards.filter((card) => card.id)
            )
          )
          break
        default:
      }
    })
  }

  public getActiveScoringSessionId = async (dataType: string) => {
    const {
      honestConversation: {
        activeScoringSessionPrimary,
        activeScoringSessionSecondary
      },
      contacts: { primary, secondary }
    } = this.props
    const possibleKeys = [
      'primary',
      'primaryComment',
      'commentPrimary',
      'primaryScore'
    ]
    const isPrimary = possibleKeys.includes(dataType)
    const contactId = isPrimary ? primary.id : secondary.id
    let activeScoringSessionId = isPrimary
      ? activeScoringSessionPrimary
      : activeScoringSessionSecondary
    if (!activeScoringSessionId) {
      await this.newScoringSession(contactId, isPrimary)
      activeScoringSessionId = isPrimary
        ? this.props.honestConversation.activeScoringSessionPrimary
        : this.props.honestConversation.activeScoringSessionSecondary
    }
    return { activeScoringSessionId, contactId, isPrimary }
  }

  public onScoreChange = async (
    index: number,
    scoreKey: 'primaryScore' | 'secondaryScore',
    score: number
  ) => {
    const { combinedCards } = this.state
    combinedCards[index][scoreKey] = score
    const {
      activeScoringSessionId,
      contactId,
      isPrimary
    } = await this.getActiveScoringSessionId(scoreKey)

    if (
      isObjEmpty(
        this.props.honestConversation?.clientScoringSessions?.[contactId][
          activeScoringSessionId
        ]?.cardScores
      )
    ) {
      this.setState({ combinedCards }, () => {
        this.createCardScoreAndUpdateScoringSession(contactId)
      })
    } else {
      const updatedCombinedCards: CardScoreRequestObj[] = []
      const contactType = isPrimary ? 'Primary' : 'Secondary'
      combinedCards &&
        combinedCards.forEach((combCard: CardRankingObj) => {
          if (combCard?.card?.id) {
            updatedCombinedCards.push({
              householdCardId: combCard.id,
              score: combCard[`${contactType.toLowerCase()}Score`] || 0,
              comment: combCard[`comment${contactType}`] || '',
              cardScoreId: combCard[`${contactType.toLowerCase()}CardScoreId`]
            })
          }
        })
      this.setState({ combinedCards }, () => {
        this.updateCardScores(
          contactId,
          activeScoringSessionId,
          updatedCombinedCards,
          true
        )
      })
    }
  }

  public createScoringSession = async (
    contactId: string,
    isPrimary: boolean
  ) => {
    const { dispatch, household, honestConversation, contacts } = this.props
    if (isPrimary) {
      this.setState({
        previousActiveScoringSessionIdPrimary:
          honestConversation.activeScoringSessionPrimary
      })
    } else if (contacts.secondary && !isPrimary) {
      this.setState({
        previousActiveScoringSessionIdSecondary:
          honestConversation.activeScoringSessionSecondary
      })
    }
    await dispatch(
      hcExercisesActions.createScoringSessions(
        household.id,
        household.hcExerciseId,
        {
          clientId: contactId,
          scoredWithAdvisor: true
        },
        isPrimary
      )
    )
  }

  public updateActiveScoreSessionId = async () => {
    const { household, honestConversation, dispatch, contacts } = this.props
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    updatedProperty.activeScoringSessionPrimary =
      honestConversation.activeScoringSessionPrimary
    if (contacts.secondary) {
      updatedProperty.activeScoringSessionSecondary =
        honestConversation.activeScoringSessionSecondary
    }
    await dispatch(
      hcExercisesActions.updateHonestConversationExercise(
        household.id,
        household.hcExerciseId,
        updatedProperty
      )
    )
  }

  public createCardScoreAndUpdateScoringSession = async (
    contactId: string,
    scoringSessionId?: string
  ) => {
    const {
      contacts,
      householdFinId,
      honestConversation,
      dispatch,
      household
    } = this.props
    const scoreSessionId =
      scoringSessionId ||
      (contactId === contacts.primary.id
        ? honestConversation.activeScoringSessionPrimary
        : honestConversation.activeScoringSessionSecondary)

    contactId === contacts.primary.id
      ? this.setState({ primaryScoreSessionId: scoreSessionId })
      : this.setState({ secondaryScoreSessionId: scoreSessionId })

    const scoreCardArray: any = []
    this.state.combinedCards.forEach((card: CardRankingObj) => {
      card.id &&
        scoreCardArray.push({
          householdCardId: card.id,
          score:
            (contactId === contacts.primary.id
              ? card.primaryScore
              : card.secondaryScore) || null,
          comment:
            (contactId === contacts.primary.id
              ? card.commentPrimary
              : card.commentSecondary) || ''
        })
    })
    await dispatch(
      hcExercisesActions.createCardScoreAndUpdateScoringSession(
        contactId,
        householdFinId,
        household.hcExerciseId,
        scoreSessionId,
        scoreCardArray
      )
    )
  }

  public updateCardScores = async (
    contactId: string,
    activeScoringSessionId: string,
    updatedCombinedCards: (CardScoreRequestObj | CardRankingObj)[],
    ignoreMapping?: boolean
  ) => {
    const {
      honestConversation,
      contacts,
      dispatch,
      meetingFinId,
      householdFinId
    } = this.props
    const { clientScoringSessions } = honestConversation
    const updatedScoreCards: any[] = ignoreMapping
      ? updatedCombinedCards
      : mapUpdateCardScore(
          contactId,
          activeScoringSessionId,
          clientScoringSessions,
          updatedCombinedCards,
          contacts
        )

    if (updatedScoreCards.length) {
      await dispatch(
        hcExercisesActions.updateScoreCardV2(
          householdFinId,
          contactId,
          meetingFinId,
          activeScoringSessionId,
          updatedScoreCards
        )
      )
    }
  }

  public setActive = () => {
    const { householdFinId, honestConversation, dispatch } = this.props
    dispatch(
      householdActions.setActiveHonestConversationExercise(
        householdFinId,
        honestConversation.id
      )
    )
  }

  public updateSelectedTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget
    this.setState({ selectedTab: id })
  }

  public cancelButton = () => {
    this.cancelEdit('cancel')
  }

  public cancelEdit = (event: string | React.MouseEvent) => {
    const { showCancelConfirmModal, edit } = this.state
    let show: boolean = showCancelConfirmModal
    let enabled: boolean = edit
    switch (
      typeof event === 'string'
        ? event.toLowerCase()
        : event.currentTarget.textContent.toLowerCase()
    ) {
      case 'cancel':
        show = !showCancelConfirmModal
        break
      case 'confirm':
        show = false
        enabled = false
        break
      default:
    }

    this.setState({
      showCancelConfirmModal: show,
      edit: enabled
    })
  }

  public toggleEdit = () => {
    this.setState({
      edit: true
    })
  }

  public editDone = () => {
    const { dispatch, householdFinId } = this.props
    this.setState({ edit: false }, async () => {
      await dispatch(
        hcExercisesActions.getHonestConversationExercises(householdFinId)
      )
    })
  }

  public handleScoreAndCommentChange = async (
    contactId: string,
    activeScoringSessionId: string
  ) => {
    const { clientScoringSessions } = this.props.honestConversation
    if (
      contactId &&
      activeScoringSessionId &&
      isObjEmpty(
        clientScoringSessions[contactId][activeScoringSessionId].cardScores
      )
    ) {
      await this.createCardScoreAndUpdateScoringSession(contactId)
    } else {
      await this.updateCardScores(
        contactId,
        activeScoringSessionId,
        this.state.combinedCards
      )
    }
  }

  public newContactCards = (contactCards: CardRankingObj[]) => {
    if (!contactCards) {
      return []
    }

    return contactCards.filter((contactCard) => {
      return !contactCard.id && contactCard.card.title
    })
  }

  public autoSaveCard = async ({
    cardExist,
    contactType,
    card,
    priorityCardExist,
    combinedCardExist,
    primaryKey
  }: {
    cardExist: boolean
    contactType: string
    card: CardObj
    primaryKey?: 'primary' | 'primaryComment' | 'commentPrimary'
    priorityCardExist?: boolean
    combinedCardExist?: boolean
  }) => {
    const action = cardExist ? 'updateAll' : 'create'
    const { dispatch, householdFinId, meetingFinId, contacts } = this.props
    const {
      primaryContactCards,
      secondaryContactCards,
      combinedCards
    } = this.state

    let contactCards: CardRankingObj[]
    let contactId: string

    if (contactType === 'combinedCards') {
      if (cardExist) {
        const updatedCombinedCards = combinedCards.filter((card) => card.id)

        //NEW: To update comment from combined card view
        if (primaryKey) {
          const {
            activeScoringSessionId,
            contactId
          } = await this.getActiveScoringSessionId(primaryKey)
          await this.updateCardScores(
            contactId,
            activeScoringSessionId,
            updatedCombinedCards
          )
        } else {
          await dispatch(
            hcExercisesActions.updateHonestConversationHouseholdCards(
              householdFinId,
              meetingFinId,
              updatedCombinedCards
            )
          )
        }
      } else {
        await this.createCombinedCards(
          combinedCards,
          card,
          dispatch,
          householdFinId,
          meetingFinId,
          contacts
        )
      }
      // single HH ONLY - updating combinedcards need to also update clientcards to the same new card
      if (!contacts.secondary && priorityCardExist !== undefined) {
        contactCards = primaryContactCards.filter((contactCard) => {
          return contactCard.cardId === card.id
        })
        contactId = contacts.primary.id
        const priorityAction = priorityCardExist ? 'updateAll' : 'create'
        await dispatch(
          hcExercisesActions[`${priorityAction}ClientCards`](
            householdFinId,
            meetingFinId,
            contactId,
            priorityAction === 'updateAll' ? primaryContactCards : contactCards
          )
        )
      }

      if (cardExist) return
    }

    if (contactType === 'primaryContactCards') {
      contactCards = primaryContactCards.filter(
        (clientCard) => clientCard.cardId === card.id
      )
      contactId = contacts.primary.id
      await dispatch(
        hcExercisesActions[`${action}ClientCards`](
          householdFinId,
          meetingFinId,
          contactId,
          action === 'updateAll' ? primaryContactCards : contactCards
        )
      )
      if (!contacts.secondary) {
        // Still within primaryContact if-statement block - Single HH ONLY
        if (combinedCardExist) {
          await dispatch(
            hcExercisesActions.updateHonestConversationHouseholdCards(
              householdFinId,
              meetingFinId,
              combinedCards.filter((card) => card.id)
            )
          )
        } else {
          await this.createCombinedCards(
            combinedCards,
            card,
            dispatch,
            householdFinId,
            meetingFinId,
            contacts
          )
        }
      }
    }

    if (contactType === 'secondaryContactCards') {
      contactCards = secondaryContactCards.filter(
        (clientCard) => clientCard.cardId === card.id
      )
      contactId = contacts.secondary.id
      await dispatch(
        hcExercisesActions[`${action}ClientCards`](
          householdFinId,
          meetingFinId,
          contactId,
          action === 'updateAll' ? secondaryContactCards : contactCards
        )
      )
    }
  }

  public autoSaveOnBlur = async (
    contactType: string,
    index?: number,
    card?: CardObj,
    primaryKey?: 'primary' | 'primaryComment' | 'commentPrimary'
  ) => {
    const cardList = this.state[contactType || '']
    const activeCard = cardList[index] || ''
    if (contactType && activeCard.isChanged) {
      await this.autoSaveCard({
        cardExist: true,
        contactType,
        card,
        primaryKey
      })

      // Reset isChanged set to false to notify that new comment is pushed
      if (activeCard) {
        activeCard.isChanged = false
        this.setState(activeCard)
      }
    }
  }

  public createCombinedCards = async (
    combinedCards: CardRankingObj[],
    card: CardObj,
    dispatch: Dispatch<GlobalState>,
    householdFinId: string,
    meetingFinId: string,
    contacts: ContactsInterface
  ) => {
    const newCombinedCards: CardRankingObj[] = []
    combinedCards &&
      combinedCards.forEach((combCard: CardRankingObj) => {
        if (!combCard.id && combCard.card) {
          newCombinedCards.push({
            ...combCard,
            cardId: combCard.card.id
          })
        }
      })
    const newCard = newCombinedCards.filter((combCard) => {
      return combCard.cardId === card.id
    })
    if (newCard.length) {
      await dispatch(
        hcExercisesActions.createHonestConversationHouseholdCards(
          householdFinId,
          meetingFinId,
          newCard
        )
      )
    }
  }

  public selectedTabContent = () => {
    const {
      selectedTab,
      edit,
      primaryContactCards,
      secondaryContactCards,
      combinedCards
    } = this.state
    const {
      honestConversation,
      contacts,
      householdFinId,
      hcExerciseCards
    } = this.props

    switch (selectedTab) {
      case 'Individual':
        return edit ? (
          <HonestConversationEditIndividualCards
            householdFinId={householdFinId}
            contacts={contacts}
            primaryContactCards={primaryContactCards}
            secondaryContactCards={secondaryContactCards}
            onDrag={this.onDrag}
            onCommentChange={this.onCommentChange}
            onCardSelection={this.onCardSelection}
            onBlur={this.autoSaveOnBlur}
          />
        ) : (
          <HonestConversationIndividualCards
            honestConversation={honestConversation}
            primaryContactCards={primaryContactCards}
            secondaryContactCards={secondaryContactCards}
            contacts={contacts}
          />
        )
      case 'Combined':
        return edit ? (
          <HonestConversationEditCombinedCards
            householdFinId={householdFinId}
            honestConversation={honestConversation}
            cards={combinedCards}
            onDrag={this.onDrag}
            contacts={contacts}
            cardOptions={hcExerciseCards}
            onScoreChange={this.onScoreChange}
            onCommentChange={this.onCombinedCommentChange}
            onCardSelection={this.onCardSelection}
            onBlur={this.autoSaveOnBlur}
          />
        ) : (
          <HonestConversationCombinedCards
            cards={combinedCards}
            householdFinId={householdFinId}
            honestConversation={honestConversation}
            contacts={contacts}
            exerciseView={true}
          />
        )
      default:
        return null
    }
  }

  // tslint:disable-next-line:max-func-body-length
  public render() {
    const {
      householdFinId,
      honestConversation,
      meetingFinId,
      household,
      contacts
    } = this.props
    const { edit, showCancelConfirmModal } = this.state
    if (honestConversation) {
      const { selectedTab } = this.state
      const quickActions = [
        {
          text: 'Score History',
          icon: ActivityIcon,
          to: `/households/${householdFinId}/honestConversations/meetings/${meetingFinId}/scores`
        }
      ]

      const active = honestConversation.id === household.hcExerciseId
      return (
        <InnerContainer>
          <div className='hc-meeting__container-w'>
            <BackToLink
              to={`/households/${householdFinId}/honestConversations/meetings`}>
              Back to HonestConversations<sup key='reg'>&reg;</sup> History
            </BackToLink>
            <div className='hc-meeting__quick-action-w'>
              <div className='hc-meeting__quick-actions'>
                <QuickActionLinks customQuickAction={quickActions} />
              </div>

              <Button
                primary={true}
                disabled={Boolean(active)}
                onClick={active ? null : this.setActive}>
                <CheckIcon /> Set as Active
              </Button>
            </div>
          </div>
          <Tile
            leftHeader={
              active ? <div className='hc-meeting__active-badge' /> : null
            }
            rightHeader={`Scores updated ${dateFormat(
              honestConversation.createdDate
            )}`}
            headerStyle={{ padding: 20 }}>
            <div className='hc-meeting__header-tab-w'>
              <div
                id='Individual'
                onClick={this.updateSelectedTab}
                className={
                  selectedTab === 'Individual'
                    ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                    : 'hc-meeting__header-tab'
                }>
                {contacts && contacts.secondary ? 'Individual' : 'Priorities'}
              </div>
              <div
                id='Combined'
                onClick={this.updateSelectedTab}
                className={
                  selectedTab === 'Combined'
                    ? 'hc-meeting__header-tab hc-meeting__header-tab--active'
                    : 'hc-meeting__header-tab'
                }>
                {contacts && contacts.secondary ? 'Combined' : 'Scores'}
              </div>
            </div>

            {!this.state.edit ? (
              <div
                className='hc-meeting__sub-header--edit hc-meeting__sub-header--edit-cards'
                onClick={this.toggleEdit}>
                <EditIcon /> Edit
              </div>
            ) : null}

            <div>{this.selectedTabContent()}</div>

            {edit ? (
              <div className='hc-meeting__button-w'>
                <Button primary={true} onClick={this.editDone}>
                  Save
                </Button>
              </div>
            ) : null}
          </Tile>
          {showCancelConfirmModal ? (
            <HonestConversationConfirmCancelModal
              confirmCancel={this.cancelEdit}
            />
          ) : null}
        </InnerContainer>
      )
    } else return null
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, meetingId } = match.params
  return {
    goals: goalActionsSelector(store, householdFinId),
    hcExerciseCards: store.hcExerciseCards,
    honestConversation:
      store.hcExercises[householdFinId] &&
      store.hcExercises[householdFinId][meetingId],
    contacts: store.contact[householdFinId],
    householdFinId,
    meetingFinId: meetingId,
    household: store.households[householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(HonestConversationsMeeting))
