import React, { FC } from 'react'
import Modal from '../../../components/layout/modal'
import Button from '../../../components/button'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'

interface DeleteAccountModalProps {
  toggleDelete(): void
  initiateDelete(): void
}

const DeleteAccountModal: FC<DeleteAccountModalProps> = (
  props: DeleteAccountModalProps
) => {
  return (
    <Modal
      icon={DeleteIcon}
      title='DELETE ACCOUNT'
      closeModal={props.toggleDelete}>
      <div className='delete-body modal-body'>
        <p>Are you sure you want to delete this account?</p>
        <br />
        <p>
          It will be removed from Portfolio Builder only. AdvisorCenter account
          will remain intact.
        </p>
        <div className='data-right'>
          <Button onClick={props.toggleDelete}>Cancel</Button>
          <Button primary={true} onClick={props.initiateDelete}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteAccountModal
