import React, { Component } from 'react'
import AssigneeSelect from '../../../../components/layout/assigneeSelect'
import noPhoto from '../../../../assets/images/icons/png/nophoto.png'
import { ActionEditInterface } from '../../../../../objects/lifeActions'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import { ContactsInterface } from '../../../../../objects/contact'

interface ActionRowProps {
  contacts: ContactsInterface
  lifeAction: FinancialGoalActionObj
  actionEdits: ActionEditInterface
  editActionName(e: React.ChangeEvent<HTMLInputElement>): void
  updateActionAssignee(
    assigneeId: string,
    contact: string,
    actionId: string
  ): void
}

interface ActionRowState {
  showAssignOwnerModal: boolean
}

class ActionRow extends Component<ActionRowProps, ActionRowState> {
  constructor(props: ActionRowProps) {
    super(props)
    this.state = {
      showAssignOwnerModal: false
    }
  }

  public toggleAssignOwnerModal = () => {
    this.setState({
      showAssignOwnerModal: !this.state.showAssignOwnerModal
    })
  }

  public showProfileImg = (isPrimary: boolean): boolean => {
    const { lifeAction, actionEdits } = this.props
    const actionEditId = actionEdits && actionEdits[lifeAction?.id]
    const primaryId = actionEditId?.assigneePrimaryId
    const secondaryId = actionEditId?.assigneeSecondaryId
    const filteredPrimaryId =
      primaryId?.length > 0 || primaryId === null
        ? primaryId
        : lifeAction.assigneePrimaryId
    const filteredSecondaryId =
      secondaryId?.length > 0 || secondaryId === null
        ? secondaryId
        : lifeAction.assigneeSecondaryId
    return isPrimary ? Boolean(filteredPrimaryId) : Boolean(filteredSecondaryId)
  }

  public renderProfileImages = (
    contact: ContactsInterface,
    isPrimary: boolean
  ) => {
    const contactAvatar = (imgSource: string, className: string) => {
      return <img className={className} src={imgSource} alt='profile' />
    }
    if (isPrimary) {
      if (this.showProfileImg(true)) {
        return contactAvatar(
          contact?.primary?.photo || noPhoto,
          `card-actions__owner-photo`
        )
      } else return null
    } else {
      const secondaryAvatarStyle = this.showProfileImg(true)
        ? 'card-actions__owner-photo--overlap'
        : null
      if (this.showProfileImg(false)) {
        return contactAvatar(
          contact?.secondary?.photo || noPhoto,
          `card-actions__owner-photo ${secondaryAvatarStyle}`
        )
      } else return null
    }
  }

  public render() {
    const { lifeAction, contacts, actionEdits } = this.props
    return (
      <div className='card-actions__wrapper'>
        <input
          name={lifeAction?.id || ''}
          className='card-actions__input'
          placeholder='Enter Goal'
          onChange={this.props.editActionName}
          value={
            (actionEdits && actionEdits[lifeAction?.id]?.name) ||
            lifeAction.name
          }
        />
        <div className='card-actions__owner-photos-container'>
          <div
            className='card-actions__owner-w'
            onClick={this.toggleAssignOwnerModal}>
            {this.renderProfileImages(contacts, false)}
            {this.renderProfileImages(contacts, true)}
          </div>
          {this.state.showAssignOwnerModal && (
            <div className='card-actions__assign-owner-modal-w'>
              <AssigneeSelect
                tickPosition='right'
                lifeActionId={lifeAction?.id}
                actionEdits={actionEdits}
                toggleAssignOwnerModal={this.toggleAssignOwnerModal}
                assigneePrimaryId={lifeAction?.assigneePrimaryId}
                assigneeSecondaryId={lifeAction?.assigneeSecondaryId}
                contacts={contacts}
                updateActionAssignee={this.props.updateActionAssignee}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default ActionRow
