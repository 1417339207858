import React from 'react'

class ActionLoader extends React.Component<{}> {
  public render() {
    return (
      <div className='modal__overlay modal__overlay--im-bg'>
        <div className='modal__box-w'>
          <div className='modal__graphic'>
            <div className='modal-im__content'>
              <div className='modal-im__spinner' />
              <div className='modal-im__loading'>Loading</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ActionLoader
