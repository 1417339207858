import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { dateFormat } from '../../../helpers'
import { getStatusIcon } from '../../../helpers/goals'
import { GlobalState } from '../../../../reducers'
import {
  updateLifeAction,
  createLifeAction
} from '../../../../actions/lifeActions'

import StatusSelect from '../../../components/layout/statusSelect'
import AssigneeSelect from '../../../components/layout/assigneeSelect'

import Input from '../../../components/Input'
import Button from '../../../components/button'
import { ContactsInterface } from '../../../../objects/contact'
import {
  LifeActionObj,
  LifeActionUpdate,
  AssigneesObj,
  LifeActionsInterface,
  LifeActionStatusType
} from '../../../../objects/lifeActions'

import { ReactComponent as Calendar } from '../../../assets/images/icons/calendar.svg'
export interface ActionFormProps {
  editRecord?: boolean
  cardIndex?: number
  currentCardId?: string
  lifeActions: LifeActionsInterface
  modalType: 'add' | 'edit'
  householdFinId: string
  contacts: ContactsInterface
  dispatch: Dispatch<GlobalState>
  action?: LifeActionObj
  modifyAction?(action: LifeActionObj, modifyType: string): void
  closeModal(): void
}

interface ActionFormState {
  name: string
  status: LifeActionStatusType
  dueDate: any
  comment: string
  assigneePrimaryId: string
  assigneeSecondaryId: string
  assignees: AssigneesObj[] | {}
  showAssignOwnerModal: boolean
  showStatusModal: boolean
  tasks: any[]
  nameError: boolean
  noteError: boolean
  statusError: boolean
}

class ActionForm extends Component<ActionFormProps, ActionFormState> {
  constructor(props: ActionFormProps) {
    super(props)
    const { action } = this.props
    this.state = {
      name: action && action.name ? action.name : '',
      status: action && action.status ? action.status : '',
      dueDate: this.dateFormatter(action),
      comment: action && action.comment ? action.comment : '',
      assigneePrimaryId:
        action && action.assigneePrimaryId ? action.assigneePrimaryId : '',
      assigneeSecondaryId:
        action && action.assigneeSecondaryId ? action.assigneeSecondaryId : '',
      assignees: action && action.assignees ? action.assignees : {},
      showAssignOwnerModal: false,
      showStatusModal: false,
      tasks: [],
      nameError: false,
      noteError: false,
      statusError: false
    }
  }

  public dateFormatter = (action: LifeActionObj) => {
    // Remove Time and extra chars
    return action && action.dueDate
      ? action.dueDate.substring(0, action.dueDate.indexOf('T'))
      : moment().toDate()
  }

  public nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value,
      nameError: event.target.value === ''
    })
  }
  public statusChange = (status: LifeActionStatusType) => {
    this.setState({
      status,
      showStatusModal: !this.state.showStatusModal,
      statusError: false
    })
  }

  public dateChange = (date: any) => {
    this.setState({
      dueDate: date
    })
  }

  public noteChange = (event: any) => {
    this.setState({
      comment: event.target.value,
      noteError: event.target.value === ''
    })
  }

  public openAssignOwnerModal = () => {
    this.setState({ showAssignOwnerModal: true })
  }

  public toggleAssignOwnerModal = () => {
    this.setState({ showAssignOwnerModal: !this.state.showAssignOwnerModal })
  }

  public toggleStatusModal = () => {
    this.setState({ showStatusModal: !this.state.showStatusModal })
  }

  public ownerNames = () => {
    const { primary, secondary } = this.props.contacts
    const { assigneePrimaryId, assigneeSecondaryId } = this.state
    const primaryAssigned = Boolean(assigneePrimaryId)
    const secondaryAssigned = Boolean(assigneeSecondaryId)
    const primaryName = primaryAssigned
      ? `${primary.firstName} ${primary.lastName}`
      : ''
    const secondaryName = secondaryAssigned
      ? `${secondary.firstName} ${secondary.lastName}`
      : ''
    const bothAssignee = primaryAssigned && secondaryAssigned
    return {
      primaryName,
      secondaryName: `${bothAssignee ? 'and' : ''} ${secondaryName}`
    }
  }

  public updateActionAssignee = (assigneeId: string, contact: string) => {
    if (contact === 'Primary') {
      this.setState({
        assigneePrimaryId: assigneeId
      })
    } else if (contact === 'Secondary') {
      this.setState({
        assigneeSecondaryId: assigneeId
      })
    }
  }

  public submit = async () => {
    const {
      closeModal,
      modalType,
      action,
      householdFinId,
      currentCardId
    } = this.props

    const { comment, name, status } = this.state

    const nameField = name && name.length
    const commentField = comment && comment.length
    const statusField = status !== ''

    if (!nameField || !commentField || !statusField) {
      this.setState({
        nameError: !nameField,
        noteError: !commentField,
        statusError: !statusField
      })
      return
    }

    const { assigneePrimaryId, assigneeSecondaryId } = this.state
    const assigneePrimaryIdCheck =
      assigneePrimaryId === '' ? null : assigneePrimaryId
    const assigneeSecondaryIdCheck =
      assigneeSecondaryId === '' ? null : assigneeSecondaryId

    const updateBody: LifeActionUpdate = {
      householdId: householdFinId,
      status: this.state.status,
      name: this.state.name,
      comment: this.state.comment,
      dueDate: dateFormat(this.state.dueDate),
      assigneePrimaryId: assigneePrimaryIdCheck,
      assigneeSecondaryId: assigneeSecondaryIdCheck
    }

    if (modalType === 'edit') {
      this.updateLifeActionDispatch(updateBody, action.id)
      closeModal()
    } else if (currentCardId) {
      this.createLifeActionDispatch(updateBody, currentCardId)
      closeModal()
      // if no honest_conversation_card
    }
  }

  public updateLifeActionDispatch = async (
    updateBody: LifeActionUpdate,
    id: string
  ) => {
    const { dispatch, editRecord, modifyAction } = this.props
    const data = await dispatch(updateLifeAction(updateBody, id))
    if (modifyAction && editRecord === false) {
      modifyAction(data['value']['data'], 'update')
    }
  }

  // create a life action, upon success associate a hc card
  public createLifeActionDispatch = async (
    updateBody: LifeActionUpdate,
    currentCardId: string
  ) => {
    const { dispatch, editRecord, modifyAction } = this.props
    try {
      const lifeActionDispatch = await dispatch(
        createLifeAction(updateBody, currentCardId)
      )
      if (lifeActionDispatch && lifeActionDispatch['value']['status'] === 200) {
        if (modifyAction && editRecord === false) {
          const data = lifeActionDispatch['value']['data']
          modifyAction(data.data[data.data.length - 1], 'create')
        }
      } else {
        console.log('error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  public render() {
    const {
      status,
      name,
      comment,
      showStatusModal,
      dueDate,
      showAssignOwnerModal,
      assigneePrimaryId,
      assigneeSecondaryId,
      nameError,
      noteError,
      statusError
    } = this.state
    const { closeModal, contacts } = this.props
    const dueDateFormat: any = moment(dueDate)

    const nameField = name && name.length
    const commentField = comment && comment.length
    const statusField = status !== ''
    const formFieldsRequired = commentField && nameField && statusField
    return (
      <div className='action-form__content-w'>
        <label htmlFor='subject' className='form-label form-label_mandatory'>
          <a data-tip='This is a mandatory field'>
            <ReactTooltip place='bottom' type='dark' effect='solid' />
            Action Name
          </a>
        </label>
        <Input
          title=''
          content={name}
          inputType='text'
          controlFunc={this.nameChange}
        />
        {nameError ? (
          <p className='form-input-Error form-input-Error__message'>
            Action Name can't be blank
          </p>
        ) : null}
        <div className='action-form__second-row'>
          <div className='action-form__assign-to-w'>
            <Input
              title='Assign To'
              content={`${this.ownerNames().primaryName} ${
                this.ownerNames().secondaryName
              }`}
              inputType='text'
              onClick={this.toggleAssignOwnerModal}
            />
            {showAssignOwnerModal && (
              <div className='action-form__assign-owner-modal-w'>
                <AssigneeSelect
                  tickPosition='left'
                  toggleAssignOwnerModal={this.toggleAssignOwnerModal}
                  assigneePrimaryId={assigneePrimaryId}
                  assigneeSecondaryId={assigneeSecondaryId}
                  contacts={contacts}
                  updateActionAssignee={this.updateActionAssignee}
                />
              </div>
            )}
          </div>
          <div className='action-form__status-w'>
            <label
              htmlFor='subject'
              className='form-label form-label_mandatory'>
              <a data-tip='This is a mandatory field'>
                <ReactTooltip place='bottom' type='dark' effect='solid' />
                Status
              </a>
            </label>
            <div
              className='action-form__status'
              onClick={this.toggleStatusModal}>
              <div className={`status-select__icon ${getStatusIcon(status)}`} />
              <p className='action-form__status-text'>{status}</p>
            </div>
            {showStatusModal && (
              <StatusSelect
                handleStatusSelect={this.statusChange}
                currentStatus={status}
              />
            )}
            {statusError ? (
              <p className='form-input-Error form-input-Error__message'>
                Status can't be blank
              </p>
            ) : null}
          </div>
          <div className='action-form__due-date-w'>
            <p className='action-form__input-header'>Due Date</p>
            <div className='action-form__date-input-w'>
              <DatePicker
                className='action-form__date-picker'
                selected={dueDateFormat}
                onChange={this.dateChange}
                dateFormat='MM/DD/YY'
              />
              <Calendar className='action-form__calendar-icon' />
            </div>
          </div>
        </div>
        <div className='action-form__note-w'>
          <label htmlFor='subject' className='form-label form-label_mandatory'>
            <a data-tip='This is a mandatory field'>
              <ReactTooltip place='bottom' type='dark' effect='solid' />
              Note
            </a>
          </label>
          <textarea
            className='action-form__note'
            value={comment}
            onChange={this.noteChange}
          />
          {noteError ? (
            <p className='form-input-Error form-input-Error__message'>
              Note can't be blank
            </p>
          ) : null}
        </div>
        <div className='action-form__button-w'>
          <Button clear={true} onClick={closeModal}>
            Cancel
          </Button>
          <Button primary={Boolean(formFieldsRequired)} onClick={this.submit}>
            Apply
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    lifeActions: store.lifeActions,
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(ActionForm))
