import React, { Component } from 'react'
import CheckBox from '../../components/checkbox'

export interface FilterDropdownRowProps {
  option?: { name: string; id: string }
  isChecked: boolean
  toggleBox(selectedOption: string): () => void
}

class FilterDropdownRow extends Component<FilterDropdownRowProps> {
  public render() {
    const { option, isChecked, toggleBox } = this.props
    const displayName = option.name ? option.name.replace(' ', '\xa0') : null
    return (
      <div className='task-filter-dropdown__item'>
        <CheckBox
          checkedBorder={true}
          checked={isChecked}
          onChange={toggleBox(option.id)}
          label={displayName}
        />
      </div>
    )
  }
}

export default FilterDropdownRow
