import api from '../v3/helpers/api'
import {
  CardScoreObj,
  CardInterface,
  CardObj
} from '../objects/honestConversationsV1'
import { dateFormat } from '../v3/helpers'
import moment from 'moment'
import { setActiveHonestConversationMeeting } from './households'

export const FETCH_HONEST_CONVERSATION = 'FETCH_HONEST_CONVERSATION'
export const FETCH_HONEST_CONVERSATION_PENDING =
  'FETCH_HONEST_CONVERSATION_PENDING'
export const FETCH_HONEST_CONVERSATION_REJECTED =
  'FETCH_HONEST_CONVERSATION_REJECTED'
export const FETCH_HONEST_CONVERSATION_FULFILLED =
  'FETCH_HONEST_CONVERSATION_FULFILLED'

export const UPDATE_HC_COMMENT = 'UPDATE_HC_COMMENT'
export const UPDATE_HC_COMMENT_PENDING = 'UPDATE_HC_COMMENT_PENDING'
export const UPDATE_HC_COMMENT_REJECTED = 'UPDATE_HC_COMMENT_REJECTED'
export const UPDATE_HC_COMMENT_FULFILLED = 'UPDATE_HC_COMMENT_FULFILLED'

export const CREATE_HONEST_CONVERSATION_MEETING =
  'CREATE_HONEST_CONVERSATION_MEETING'
export const CREATE_HONEST_CONVERSATION_MEETING_PENDING =
  'CREATE_HONEST_CONVERSATION_MEETING_PENDING'
export const CREATE_HONEST_CONVERSATION_MEETING_FULFILLED =
  'CREATE_HONEST_CONVERSATION_MEETING_FULFILLED'
export const CREATE_HONEST_CONVERSATION_MEETING_REJECTED =
  'CREATE_HONEST_CONVERSATION_MEETING_REJECTED'

export const UPDATE_HONEST_CONVERSATION_MEETING =
  'UPDATE_HONEST_CONVERSATION_MEETING'
export const UPDATE_HONEST_CONVERSATION_MEETING_PENDING =
  'UPDATE_HONEST_CONVERSATION_MEETING_PENDING'
export const UPDATE_HONEST_CONVERSATION_MEETING_FULFILLED =
  'UPDATE_HONEST_CONVERSATION_MEETING_FULFILLED'
export const UPDATE_HONEST_CONVERSATION_MEETING_REJECTED =
  'UPDATE_HONEST_CONVERSATION_MEETING_REJECTED'

export const DELETE_HONEST_CONVERSATION_MEETING =
  'DELETE_HONEST_CONVERSATION_MEETING'
export const DELETE_HONEST_CONVERSATION_MEETING_PENDING =
  'DELETE_HONEST_CONVERSATION_MEETING_PENDING'
export const DELETE_HONEST_CONVERSATION_MEETING_FULFILLED =
  'DELETE_HONEST_CONVERSATION_MEETING_FULFILLED'
export const DELETE_HONEST_CONVERSATION_MEETING_REJECTED =
  'DELETE_HONEST_CONVERSATION_MEETING_REJECTED'

export const DELETE_HONEST_CONVERSATION_SCORE =
  'DELETE_HONEST_CONVERSATION_SCORE'
export const DELETE_HONEST_CONVERSATION_SCORE_PENDING =
  'DELETE_HONEST_CONVERSATION_SCORE_PENDING'
export const DELETE_HONEST_CONVERSATION_SCORE_FULFILLED =
  'DELETE_HONEST_CONVERSATION_SCORE_FULFILLED'
export const DELETE_HONEST_CONVERSATION_SCORE_REJECTED =
  'DELETE_HONEST_CONVERSATION_SCORE_REJECTED'

export const SET_ACTIVE_HONEST_CONVERSATION_SCORE =
  'SET_ACTIVE_HONEST_CONVERSATION_SCORE'
export const SET_ACTIVE_HONEST_CONVERSATION_SCORE_PENDING =
  'SET_ACTIVE_HONEST_CONVERSATION_SCORE_PENDING'
export const SET_ACTIVE_HONEST_CONVERSATION_SCORE_FULFILLED =
  'SET_ACTIVE_HONEST_CONVERSATION_SCORE_FULFILLED'
export const SET_ACTIVE_HONEST_CONVERSATION_SCORE_REJECTED =
  'SET_ACTIVE_HONEST_CONVERSATION_SCORE_REJECTED'

export const CREATE_NEW_HONEST_CONVERSATION_SCORE =
  'CREATE_NEW_HONEST_CONVERSATION_SCORE'
export const CREATE_NEW_HONEST_CONVERSATION_SCORE_PENDING =
  'CREATE_NEW_HONEST_CONVERSATION_SCORE_PENDING'
export const CREATE_NEW_HONEST_CONVERSATION_SCORE_FULFILLED =
  'CREATE_NEW_HONEST_CONVERSATION_SCORE_FULFILLED'
export const CREATE_NEW_HONEST_CONVERSATION_SCORE_REJECTED =
  'CREATE_NEW_HONEST_CONVERSATION_SCORE_REJECTED'

export const UPDATE_HONEST_CONVERSATION_SCORE =
  'UPDATE_HONEST_CONVERSATION_SCORE'
export const UPDATE_HONEST_CONVERSATION_SCORE_PENDING =
  'UPDATE_HONEST_CONVERSATION_SCORE_PENDING'
export const UPDATE_HONEST_CONVERSATION_SCORE_FULFILLED =
  'UPDATE_HONEST_CONVERSATION_SCORE_FULFILLED'
export const UPDATE_HONEST_CONVERSATION_SCORE_REJECTED =
  'UPDATE_HONEST_CONVERSATION_SCORE_REJECTED'

export const EMAIL_HONEST_CONVERSATION: string = 'EMAIL_HONEST_CONVERSATION'
export const EMAIL_HONEST_CONVERSATION_PENDING: string =
  'EMAIL_HONEST_CONVERSATION_PENDING'
export const EMAIL_HONEST_CONVERSATION_FULFILLED: string =
  'EMAIL_HONEST_CONVERSATION_FULFILLED'
export const EMAIL_HONEST_CONVERSATION_REJECTED: string =
  'EMAIL_HONEST_CONVERSATION_REJECTED'

export const CREATE_AND_SET_ACTIVE_HONEST_CONVERSATION_MEETING: string =
  'CREATE_AND_SET_ACTIVE_HONEST_CONVERSATION_MEETING'

export const getHonestConversation = (householdFinId: string) => {
  return {
    type: FETCH_HONEST_CONVERSATION,
    payload: api()
      .get(`/households/${householdFinId}/honestconversations/meetings`)
      .then((response) => response)
      .catch((err) => console.log(err))
  }
}

export const updateComment = (
  householdFinId: string,
  meetingFinId: string,
  scoreId: string,
  cardNumber: number,
  note: string,
  user: string
) => {
  const requestBody = {
    [user]: {
      comments: {
        ['card' + (cardNumber + 1)]: `${note}`
      }
    }
  }
  return {
    type: UPDATE_HC_COMMENT,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}/scores/${scoreId}`,
        requestBody
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

const getCardName = (card: CardObj) => {
  const { cardType, title } = card
  switch (cardType) {
    case 'protection':
      return `${title} (f)`
    case 'happiness':
      return `${title} (h)`
    case 'commitment':
      return `${title} (c)`
    default:
      return null
  }
}

const mapCardRequest = (cards: CardInterface) => {
  return {
    1: {
      card: getCardName(cards[1]),
      comments: cards[1].comment
    },
    2: {
      card: getCardName(cards[2]),
      comments: cards[2].comment
    },
    3: {
      card: getCardName(cards[3]),
      comments: cards[3].comment
    },
    4: {
      card: getCardName(cards[4]),
      comments: cards[4].comment
    },
    5: {
      card: getCardName(cards[5]),
      comments: cards[5].comment
    }
  }
}

export const mapHonestConversationMeetingRequest = (
  householdFinId: string,
  primaryContactCards: CardInterface,
  secondaryContactCards: CardInterface,
  combinedCards: CardInterface,
  conversationDate?: Date
) => {
  return {
    householdId: householdFinId,
    conversationDate: conversationDate || null,
    combinedCards: mapCardRequest(combinedCards),
    spouse1Cards: mapCardRequest(primaryContactCards),
    spouse2Cards: mapCardRequest(secondaryContactCards)
  }
}

export const createAndSetActiveHonestConversationMeeting = (
  householdFinId: string,
  primaryContactCards: CardInterface,
  secondaryContactCards: CardInterface,
  combinedCards: CardInterface
) => {
  const requestBody = mapHonestConversationMeetingRequest(
    householdFinId,
    primaryContactCards,
    secondaryContactCards,
    combinedCards
  )
  return {
    type: CREATE_AND_SET_ACTIVE_HONEST_CONVERSATION_MEETING,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings`,
        requestBody
      )
      .then((hcResponse) => {
        return setActiveHonestConversationMeeting(
          householdFinId,
          hcResponse.data.id
        ).payload.then((_householdResponse) => {
          hcResponse.data.householdId = householdFinId
          return hcResponse
        })
      })
  }
}

export const createHonestConversationMeeting = (
  householdFinId: string,
  primaryContactCards: CardInterface,
  secondaryContactCards: CardInterface,
  combinedCards: CardInterface
) => {
  const requestBody = mapHonestConversationMeetingRequest(
    householdFinId,
    primaryContactCards,
    secondaryContactCards,
    combinedCards
  )
  return {
    type: CREATE_HONEST_CONVERSATION_MEETING,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings`,
        requestBody
      )
      .then((response) => {
        response.data.householdId = householdFinId
        return response
      })
  }
}

export const updateHonestConversationMeeting = (
  householdFinId: string,
  meetingFinId: string,
  primaryContactCards: CardInterface,
  secondaryContactCards: CardInterface,
  combinedCards: CardInterface,
  conversationDate?: Date
) => {
  const requestBody = mapHonestConversationMeetingRequest(
    householdFinId,
    primaryContactCards,
    secondaryContactCards,
    combinedCards,
    conversationDate
  )
  return {
    type: UPDATE_HONEST_CONVERSATION_MEETING,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}`,
        requestBody
      )
      .then((response) => {
        return response
      })
  }
}

export const deleteHonestConversationMeeting = (
  householdFinId: string,
  meetingFinId: string
) => {
  return {
    type: DELETE_HONEST_CONVERSATION_MEETING,
    payload: api()
      .delete(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}`
      )
      .then((response) => {
        response.data = {}
        response.data.householdFinId = householdFinId
        response.data.meetingFinId = meetingFinId
        return response
      })
  }
}

export const deleteHonestConversationScore = (
  householdFinId: string,
  meetingFinId: string,
  scoreFinId: string
) => {
  return {
    type: DELETE_HONEST_CONVERSATION_SCORE,
    payload: api()
      .delete(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}/scores/${scoreFinId}`
      )
      .then((response) => {
        response.data = {}
        response.data.scoreFinId = scoreFinId
        response.data.householdFinId = householdFinId
        response.data.meetingFinId = meetingFinId
        return response
      })
  }
}

export const setActiveHonestConversationScore = (
  householdFinId: string,
  meetingFinId: string,
  scoreFinId: string
) => {
  const requestBody = {
    activeScore: scoreFinId
  }
  return {
    type: SET_ACTIVE_HONEST_CONVERSATION_SCORE,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}`,
        requestBody
      )
      .then((response) => {
        return response
      })
  }
}

const mapHonestConversationScoreRequest = (
  primaryContact: { [key: string]: CardScoreObj },
  secondaryContact: { [key: string]: CardScoreObj }
) => {
  return {
    dateOfScore: dateFormat(moment().toString()),
    spouse1: {
      scores: {
        card1: primaryContact[1].score,
        card2: primaryContact[2].score,
        card3: primaryContact[3].score,
        card4: primaryContact[4].score,
        card5: primaryContact[5].score
      },
      comments: {
        card1: primaryContact[1].comment,
        card2: primaryContact[2].comment,
        card3: primaryContact[3].comment,
        card4: primaryContact[4].comment,
        card5: primaryContact[5].comment
      }
    },
    spouse2: {
      scores: {
        card1: secondaryContact[1].score,
        card2: secondaryContact[2].score,
        card3: secondaryContact[3].score,
        card4: secondaryContact[4].score,
        card5: secondaryContact[5].score
      },
      comments: {
        card1: secondaryContact[1].comment,
        card2: secondaryContact[2].comment,
        card3: secondaryContact[3].comment,
        card4: secondaryContact[4].comment,
        card5: secondaryContact[5].comment
      }
    }
  }
}

export const createHonestConversationScore = (
  primaryContact: { [key: string]: CardScoreObj },
  secondaryContact: { [key: string]: CardScoreObj },
  householdFinId: string,
  meetingFinId: string
) => {
  const requestBody = mapHonestConversationScoreRequest(
    primaryContact,
    secondaryContact
  )
  return {
    type: CREATE_NEW_HONEST_CONVERSATION_SCORE,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}/scores`,
        requestBody
      )
      .then((response) => {
        return response
      })
  }
}

export const updateHonestConversationScore = (
  primaryContact: { [key: string]: CardScoreObj },
  secondaryContact: { [key: string]: CardScoreObj },
  householdFinId: string,
  meetingFinId: string,
  scoreFinId: string
) => {
  const requestBody = mapHonestConversationScoreRequest(
    primaryContact,
    secondaryContact
  )
  return {
    type: UPDATE_HONEST_CONVERSATION_SCORE,
    payload: api()
      .put(
        `/households/${householdFinId}/honestconversations/meetings/${meetingFinId}/scores/${scoreFinId}`,
        requestBody
      )
      .then((response) => {
        return response
      })
  }
}

export const sendHonestConversationEmail = (
  householdFinId: string,
  clientId: string,
  meetingId: string,
  type: string
) => {
  return {
    type: EMAIL_HONEST_CONVERSATION,
    payload: api()
      .post(
        `/households/${householdFinId}/honestconversations/meetings/email/v1`,
        { clientId, meetingId, type }
      )
      .then((response) => {
        return response
      })
  }
}
