import React, { Component } from 'react'
import { AxiosResponse } from 'axios'
import { ContactObj } from '../../../objects/contact'
import Input from '../../components/Input'
import Button from '../../components/button'

import SettingsLoginHistory from './settingsLoginHistory'
import {
  dateFormat,
  timeFormat,
  isValidEmail,
  formatPhoneNumber
} from '../../helpers'

import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg'
import { ReactComponent as MessageIcon } from '../../assets/images/icons/messages.svg'
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/phone.svg'
import { ReactComponent as BriefcaseIcon } from '../../assets/images/icons/briefcase.svg'
import { ReactComponent as SendIcon } from '../../assets/images/icons/send.svg'
import { ReactComponent as AdminIcon } from '../../assets/images/icons/admin-key.svg'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { SettingsContactInfoLoaderState } from '../../../reducers/settingsContactInfoLoader'
import {
  enableGC,
  getContact,
  getUsername,
  sendWelcomeEmail,
  sendResetPassword,
  unlockAccount,
  deactivateAccount,
  reactivateAccount,
  getContactLoginHistory
} from '../../../actions/contacts'
import * as toastActions from '../../../actions/toasts'
import { HouseholdObj } from '../../../objects/household'
import SendWelcomeEmailModal from './sendModal'
import ProfilePicture from '../../components/layout/profilePicture'
import ClientNotifications from './notifications'

export interface SettingsContactInfoOwnProps {
  householdId: string
  primaryContact?: ContactObj
  contact: ContactObj
  household: HouseholdObj
  primary?: boolean
  dispatch?: Dispatch<GlobalState>
  loadingContactInfo?: SettingsContactInfoLoaderState
}

export type SettingsContactInfoProps = ReturnType<typeof mapStateToProps>

type ModalKey =
  | 'registrationEmail'
  | 'resendWelcomeEmail'
  | 'unlockAccount'
  | 'passwordReset'
  | 'deactivateAccount'
  | 'reactivateAccount'
  | 'none'

interface SettingsContactState {
  email: string
  username: string
  login: string
  enabled: boolean
  message: string
  showModal: boolean
  activeModalKey: ModalKey
  showHistory: boolean
}

export class SettingsContactInfo extends Component<
  SettingsContactInfoProps,
  SettingsContactState
> {
  constructor(props: SettingsContactInfoProps) {
    super(props)
    this.state = {
      email: props.contact.userEmail,
      username: props.contact.username,
      login:
        props.loginHistory && props.loginHistory[props.contact.id]
          ? props.loginHistory[props.contact.id][0].loginDate
          : null,
      enabled: false,
      message: '',
      showModal: false,
      activeModalKey: 'none',
      showHistory: false
    }
  }

  public async componentDidMount() {
    await this.getContact()
  }

  public getContact = async (ignoredApiFields: string[] = []) => {
    const { household, contact, householdId, dispatch } = this.props
    if (householdId && contact?.id) {
      dispatch(getContactLoginHistory(householdId, contact.id))
    }
    if (household && contact && !contact.jitFetched) {
      await dispatch(getContact(contact.id))
    }
    if (contact) {
      dispatch(getUsername(householdId, contact.id, ignoredApiFields))
    }
  }
  public componentDidUpdate(prevProps: SettingsContactInfoProps) {
    const {
      contact: { username, userEmail, id },
      loginHistory
    } = this.props
    if (
      username !== prevProps.contact.username ||
      userEmail !== prevProps.contact.userEmail
    ) {
      this.setState({
        username,
        email: userEmail
      })
    }

    if (loginHistory && loginHistory[id] !== prevProps.loginHistory[id]) {
      this.setState({
        login: loginHistory[id][0].loginDate
      })
    }
  }

  public showToast = () => {
    this.props.dispatch(
      toastActions.addToast({ message: 'Settings have been saved.' })
    )
  }

  public editEmail = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value })
  }

  public editUsername = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ username: event.currentTarget.value })
  }
  public enableGC = async () => {
    const { contact, householdId, dispatch } = this.props
    const { email, username } = this.state

    if (!username || !isValidEmail(username)) {
      this.setState({
        enabled: false,
        message: 'Oops! This username must be an email address.'
      })
    } else if (!email || !isValidEmail(email)) {
      this.setState({
        enabled: false,
        message: 'Oops! This email must be a valid email address.'
      })
    } else {
      try {
        await dispatch(enableGC(householdId, contact.id, email, username))
        await this.getContact()
        this.setState({ enabled: true, message: '' })
      } catch (e) {}
    }
    this.toggleSendModal({})
  }
  public toggleSendModal = ({
    modalState = !this.state.showModal,
    modalKey = 'none'
  }: {
    modalState?: boolean
    modalKey?: ModalKey
  }) => {
    this.setState({
      showModal: modalState,
      activeModalKey: modalKey
    })
  }
  public callModalAction = (
    fn: (
      id: string
    ) => {
      type: string
      payload: Promise<AxiosResponse<any>>
    },
    ignoredApiFields: string[] = []
  ) => {
    return async () => {
      const { contact, dispatch } = this.props
      try {
        await dispatch(fn(contact.id))
        await this.getContact(ignoredApiFields)
      } catch (e) {}
      this.toggleSendModal({})
    }
  }

  public redtailAddressFix = (contact: ContactObj) => {
    const { primaryContact } = this.props
    const newContact = contact
    if (primaryContact && contact.jitFetched) {
      newContact.street = contact.street
        ? contact.street
        : primaryContact.street
      newContact.city = contact.city ? contact.city : primaryContact.city
      newContact.state = contact.state ? contact.state : primaryContact.state
      newContact.zip = contact.zip ? contact.zip : primaryContact.zip
    }
    return newContact
  }
  public toggleLoginHistoryTable = () => {
    this.setState({ showHistory: !this.state.showHistory })
  }
  public contactInfo = (contact: ContactObj) => {
    const { showHistory } = this.state
    const { primary, crmSource, loginHistory } = this.props

    contact =
      crmSource === 'redtail' ? this.redtailAddressFix(contact) : contact
    return (
      <div className='c-settings__info-w'>
        {showHistory ? (
          <>
            <div className='c-settings-login-history__client-login-settings-title'>
              <span>Client Login History</span>
            </div>
            <div>
              <h4 className='c-settings-login-history__client-login-history-name'>
                {contact.firstName} {contact.lastName}
              </h4>
            </div>
          </>
        ) : (
          <div className='c-settings__info-main'>
            <ProfilePicture
              firstName={contact.firstName}
              lastName={contact.lastName}
              photo={contact.photo}
              isPrimary={primary}
              pictureSize={64}
            />
            <div className='c-settings__info-name-w'>
              <h4 className='c-settings__info-name'>
                {contact.firstName} {contact.lastName}
              </h4>
              <span
                className={
                  primary
                    ? 'c-settings__info-badge c-settings__info-badge--prime'
                    : 'c-settings__info-badge c-settings__info-badge--secondary'
                }
              />
            </div>
          </div>
        )}

        {showHistory ? (
          <SettingsLoginHistory loginHistory={loginHistory} contact={contact} />
        ) : (
          <div className='c-settings__info-details-main'>
            <div>
              <span>
                <MessageIcon />
              </span>
              {contact.email}
            </div>
            <div>
              <span>
                <PhoneIcon />
              </span>
              {contact.home ? 'h: ' + formatPhoneNumber(contact.home) : ''}
              {contact.home && contact.mobile ? <br /> : null}
              {contact.mobile ? 'm: ' + formatPhoneNumber(contact.mobile) : ''}
            </div>
            <div>
              <span>
                <LocationIcon />
              </span>
              {contact.street ? contact.street + ',' : ''}
              <br />
              {contact.city ? contact.city + ',' : ''} {contact.state}{' '}
              {contact.zip}
            </div>
            <div>
              <span>
                <BriefcaseIcon />
              </span>
              {formatPhoneNumber(contact.work)}
            </div>
          </div>
        )}
        {showHistory ? null : this.contactGuideCenterManager()}
      </div>
    )
  }

  public getModalConfig = () => {
    const {
      contact: { firstName, lastName }
    } = this.props
    const { activeModalKey } = this.state

    //concatenate the client name
    const clientName = `${firstName} ${lastName}`

    const config = {
      registrationEmail: {
        title: `Send Registration Email`,
        bodyText: `Are you sure you want to send a Registration email to ${clientName}?`,
        actionBtnTitle: 'Send',
        onAction: this.enableGC
      },
      resendWelcomeEmail: {
        title: `Resend Welcome Email`,
        bodyText: `Are you sure you want to send a Welcome email to ${clientName}?`,
        actionBtnTitle: 'Send',
        onAction: this.callModalAction(sendWelcomeEmail, ['accountStatus'])
      },
      unlockAccount: {
        title: `Unlock Account`,
        bodyText: `Are you sure you want to unlock ${clientName} account?`,
        actionBtnTitle: 'Unlock',
        onAction: this.callModalAction(unlockAccount)
      },
      passwordReset: {
        title: `Send Password Reset`,
        bodyText: `Are you sure you want to send a password reset email to ${firstName} ${lastName}?`,
        actionBtnTitle: 'Send',
        onAction: this.callModalAction(sendResetPassword)
      },
      deactivateAccount: {
        title: `Deactivate GuideCenter Account`,
        bodyText: `Are you sure you want to deactivate this client’s account?`,
        actionBtnTitle: 'Deactivate',
        onAction: this.callModalAction(deactivateAccount)
      },
      reactivateAccount: {
        title: `Reactivate GuideCenter Account`,
        bodyText: `Are you sure you want to reactivate this client’s account? This will automatically resend an email.`,
        actionBtnTitle: 'Activate',
        onAction: this.callModalAction(reactivateAccount)
      }
    }

    return {
      ...config?.[activeModalKey],
      onCancel: () => this.toggleSendModal({})
    }
  }

  public loadingInfo = () => {
    return (
      <div className='c-settings__gc'>
        <div>
          <div className='c-settings__gc-h-toggle'>
            <span className='c-settings__gc-h'>GuideCenter Manager</span>
            <span></span>
          </div>
          <div className='c-settings__gc-email-w'>
            <span className='c-settings__gc-loadingtext' id='loading_text'>
              Loading information...
            </span>
          </div>
        </div>
      </div>
    )
  }

  public contactGuideCenterManager = () => {
    const { email, username, login, enabled, message } = this.state

    const {
      contact: { accountStatus, lastResetPasswordSent, lastResendWelcomeSent },
      loadingContactInfo: { loadingUsername, loadingContact },
      contact,
      householdId
    } = this.props
    const accountStatusCls = `c-settings__gc-account-status--${accountStatus
      ?.toLowerCase()
      ?.split(' ')
      ?.join('-')}`
    if (loadingUsername || loadingContact) return this.loadingInfo()
    if (accountStatus && accountStatus !== 'Not Created') {
      return (
        <div className='c-settings__gc'>
          <div className='c-settings__gc-w'>
            <div className='c-settings__gc-h-toggle'>
              <span className='c-settings__gc-h'>GuideCenter Manager</span>
              <span>
                {accountStatus === 'Locked' ? (
                  <span>
                    <Button
                      style={{ padding: '9px 24px 9px 0px' }}
                      link={true}
                      onClick={() =>
                        this.toggleSendModal({
                          modalKey: 'unlockAccount'
                        })
                      }>
                      <div className='c-settings__gc__btn--text'>
                        Unlock Account
                      </div>
                    </Button>
                  </span>
                ) : null}
                {accountStatus === 'Locked' || accountStatus === 'Active' ? (
                  <span>
                    <Button
                      style={{ padding: '9px 24px 9px 0px' }}
                      link={true}
                      onClick={() =>
                        this.toggleSendModal({
                          modalKey: 'deactivateAccount'
                        })
                      }>
                      <div className='c-settings__gc__btn--text'>
                        Deactivate Account
                      </div>
                    </Button>
                  </span>
                ) : null}
                {accountStatus === 'Deactivated' ? (
                  <span>
                    <Button
                      style={{ padding: '9px 24px 9px 0px' }}
                      link={true}
                      onClick={() =>
                        this.toggleSendModal({
                          modalKey: 'reactivateAccount'
                        })
                      }>
                      <div className='c-settings__gc__btn--text'>
                        Reactivate Account
                      </div>
                    </Button>
                  </span>
                ) : null}
                <span className='c-settings__gc-account-status'>
                  Account Status:&nbsp;{' '}
                  <span className={accountStatusCls}>{accountStatus}</span>
                </span>
              </span>
            </div>
            <div className='c-settings__gc-email-w'>
              <div className='c-settings__gc-email-btn-w'>
                <span
                  className='c-settings__gc-email-btn'
                  onClick={() =>
                    this.toggleSendModal({
                      modalKey: 'resendWelcomeEmail'
                    })
                  }>
                  <SendIcon />
                  &nbsp;Resend Welcome Email
                </span>
              </div>
              <div className='c-settings__gc-email-btn-w'>
                <span
                  className='c-settings__gc-email-btn'
                  onClick={() =>
                    this.toggleSendModal({
                      modalKey: 'passwordReset'
                    })
                  }>
                  <AdminIcon />
                  &nbsp;Send Password Reset
                </span>
              </div>
            </div>
            {lastResetPasswordSent || lastResendWelcomeSent ? (
              <div className='c-settings__gc-email-password'>
                {lastResendWelcomeSent ? (
                  <h3 className='c-settings__gc-lastemail'>
                    Welcome Email Sent:{' '}
                    <span>
                      {dateFormat(lastResendWelcomeSent) +
                        ' ' +
                        timeFormat(lastResendWelcomeSent)}
                    </span>
                  </h3>
                ) : null}
                {lastResetPasswordSent ? (
                  <h3 className='c-settings__gc-lastpassword'>
                    Password Reset Sent:{' '}
                    <span>
                      {dateFormat(lastResetPasswordSent) +
                        ' ' +
                        timeFormat(lastResetPasswordSent)}
                    </span>
                  </h3>
                ) : null}
              </div>
            ) : null}
            <div className='c-settings__gc-user-email'>
              <span className='c-settings__gc-user-email-textview'>
                <span>
                  Last Login:{' '}
                  {login
                    ? dateFormat(login) + ' ' + timeFormat(login)
                    : 'Never'}
                </span>
              </span>
              <span className='c-settings__gc-user-email-textview'>
                {login ? (
                  <span
                    className='c-settings-login-history__view-loginhistory'
                    onClick={() => this.toggleLoginHistoryTable()}>
                    View Client Login History
                  </span>
                ) : null}
              </span>
            </div>
            <div className='c-settings__gc-user-email'>
              <span className='c-settings__gc-user-email-textview'>
                <span>Client Email:</span>
                <span>{email || ''}</span>
              </span>
              <span className='c-settings__gc-user-email-textview'>
                <span>Username:</span>
                <span>{username || ''}</span>
              </span>
            </div>
          </div>
          <ClientNotifications householdId={householdId} contact={contact} />
        </div>
      )
    } else {
      const isDropdownDisabled =
        window._env_.REACT_APP_ONBOARDING_REGISTRATION_DISABLED === 'true'
          ? 'c-settings__gc__btn--w-without-dropdown'
          : 'c-settings__gc__btn--w'
      return (
        <div className='c-settings__gc'>
          <div className='c-settings__gc-h-toggle'>
            <span className='c-settings__gc-h'>GuideCenter Manager</span>
            <span>
              Account Status:&nbsp;{' '}
              <span className='disabled'>{accountStatus}</span>
            </span>
          </div>
          <p className='c-settings__gc-info-text'>
            Client doesn’t have access to GuideCenter yet. You need to setup
            their account first.
          </p>
          <div className='c-settings__gc-user-email'>
            <div>
              <Input
                content={username || ''}
                title='Client Username'
                inputType='email'
                name='clientUsername'
                controlFunc={this.editUsername}
                placeholder='Enter a username (must be an email)'
              />
            </div>
            <div>
              <Input
                content={email || ''}
                title='Client Email Address'
                inputType='email'
                name='clientEmail'
                controlFunc={this.editEmail}
                placeholder='Enter a valid email'
              />
            </div>
          </div>
          <p className='c-settings__gc--error'>
            {enabled && contact.hasError
              ? 'Oops!  This email/username is already being used.  Please select a different one.'
              : message}
            &nbsp;
          </p>
          <div className={`${isDropdownDisabled}`}>
            <Button
              style={{ padding: '12px 17px' }}
              primary={true}
              onClick={() =>
                this.toggleSendModal({
                  modalKey: 'registrationEmail'
                })
              }>
              <div className='c-settings__gc__btn--text'>
                Register Client to GuideCenter
              </div>
            </Button>
          </div>
        </div>
      )
    }
  }

  public render() {
    const { contact } = this.props
    const { showModal, activeModalKey, showHistory } = this.state
    return (
      <div className='c-settings__info'>
        {showHistory ? (
          <div
            className='c-settings-login-history__back-to-settings'
            onClick={() => this.toggleLoginHistoryTable()}>
            <p>{`< Back To Client Information`}</p>
          </div>
        ) : null}

        {contact ? this.contactInfo(contact) : null}

        {showModal && activeModalKey !== 'none' ? (
          <SendWelcomeEmailModal {...this.getModalConfig()} />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  ownProps: SettingsContactInfoOwnProps
) => {
  return {
    crmSource: store.institution.crmSource,
    loginHistory: store.loginHistory,
    loadingContactInfo: store.settingsContactInfoLoader,
    ...ownProps
  }
}

export default connect(mapStateToProps)(SettingsContactInfo)
