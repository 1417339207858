import React, { RefObject, createRef, Fragment } from 'react'
import { Doughnut } from 'react-chartjs-2'

export interface FinancialChartsProps {
  canvasId?: string
  chartData: {
    datasets: { data: number[]; backgroundColor: string[] }[]
  }
  dimensions: { height: number; width: number }
  chartOptions?: {}
  assetRef?: string
}

export interface FinancialChartsState {
  chartBase64: string
  optionsSet: {}
}

const defaultDoughnutOptions = {
  showTooltips: false,
  responsive: false,
  cutoutPercentage: 75,
  segmentShowStroke: false,
  tooltips: {
    enabled: false
  },
  elements: {
    arc: {
      borderWidth: 2
    }
  }
}

export const initialDataSet = {
  datasets: [
    {
      data: [100],
      backgroundColor: ['#a4a4a4']
    }
  ]
}
class FinancialCharts extends React.Component<
  FinancialChartsProps,
  FinancialChartsState
> {
  public canvasRef: RefObject<HTMLCanvasElement> = createRef()

  constructor(props: FinancialChartsProps) {
    super(props)
    const { chartOptions } = this.props
    this.state = {
      chartBase64: null,
      optionsSet: { ...defaultDoughnutOptions, ...chartOptions }
    }
  }

  public componentDidMount() {
    this.getBase64()
  }

  public componentDidUpdate() {
    this.getBase64()
  }

  public getBase64 = () => {
    const { assetRef } = this.props
    const { chartBase64 } = this.state
    const chartInstance: string = assetRef || 'chartInstance'
    const thisWindow: any = window as any
    thisWindow.devicePixelRatio = 2

    if (this.canvasRef.current) {
      const canvas: HTMLCanvasElement = this.canvasRef.current[chartInstance]
        .canvas
      const base64String: string = canvas.toDataURL('image/png', 1)
      if (!chartBase64 || chartBase64 !== base64String) {
        this.setState({ chartBase64: base64String })
      }
    }
  }

  public getDataSetSum = (): number => {
    const { chartData } = this.props
    if (chartData?.datasets[0]?.data) {
      return chartData.datasets[0].data.reduce((a, b) => a + b, 0)
    }
    return 0
  }

  public render() {
    const { canvasId, dimensions, chartData } = this.props
    const { optionsSet } = this.state
    const doughnutData = this.getDataSetSum() > 0 ? chartData : initialDataSet

    return (
      <Fragment>
        <div className='pdf-exclude'>
          <Doughnut
            ref={this.canvasRef}
            id={canvasId}
            data={doughnutData}
            options={optionsSet}
            height={dimensions?.height}
            width={dimensions?.width}
          />
        </div>

        <img
          src={this.state.chartBase64}
          role='presentation'
          alt=''
          width={dimensions?.width}
          height={dimensions?.height}
          className='pdf-only'
        />
      </Fragment>
    )
  }
}

export default FinancialCharts
