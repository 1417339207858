import React from 'react'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/user'
import JunxureAuth from './junxureAuth'
import HouseholdSync from './householdsSync'
import JunxureIcon from '../../../assets/images/logos/junxure-temp-logo.png'
import ContentHeader from '../../../components/layout/contentHeader'
import InnerContainer from '../../../components/layout/innerContainer'
import Disclosure from '../../../components/layout/disclosure'

interface JunxureSyncProps {
  crm: string
  dispatch: Dispatch<GlobalState>
  fetchHouseholdsFailed: boolean
}

class JunxureSync extends React.Component<JunxureSyncProps> {
  public async componentDidMount() {
    await this.props.dispatch(actions.previewHouseholdSync())
  }
  public render() {
    const { crm, fetchHouseholdsFailed } = this.props
    return (
      <div className='sync-screen--w'>
        <InnerContainer>
          <ContentHeader title='Connect Your Clients' />
          {fetchHouseholdsFailed ? (
            <div>
              <JunxureAuth />
            </div>
          ) : (
            <HouseholdSync crm={crm} iconPng={JunxureIcon} />
          )}
          <Disclosure />
        </InnerContainer>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    fetchHouseholdsFailed: store.user.fetchHouseholdsFailed
  }
}

export default connect(mapStateToProps)(JunxureSync)
