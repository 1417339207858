import { PortfolioBuilderAccountObj } from '../../objects/portfolioBuilder'

export const calcProposedTotal = (
  current: any,
  proposed: any,
  unplitId: string
) => {
  if (current && current.length) {
    let propAmount = 0
    current.map((data: PortfolioBuilderAccountObj) => {
      Object.keys(proposed).map((key: string) => {
        if (
          data.clientAccount === proposed[key].clientAccount &&
          unplitId === proposed[key].unsplitId
        ) {
          propAmount += proposed[key].accountValue
        }
      })
    })
    return propAmount
  } else return 0
}

export const filterStrategyIds = (portfolioBuilderData: any) => {
  return (
    portfolioBuilderData &&
    portfolioBuilderData.proposedAccounts &&
    Object.keys(portfolioBuilderData.proposedAccounts)
      .map((account) => {
        const strategyId =
          portfolioBuilderData.proposedAccounts[account].strategy
        return strategyId || null
      })
      .filter((e) => e)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      )
  )
}
