import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'

import {
  ClientAccountObj,
  ClientAccountObjState
} from '../../../objects/clientAccount'
import ClientAccountRow from './clientAccountRow'
import Tile from '../../components/layout/tile'
import { HouseholdPreferencesObj } from '../../../objects/householdPreferences'
import TableRow from '../../components/layout/tableRow'
import Tooltip from '../../components/tooltip'
import { staticText } from '../../helpers/resources'
import { SortType } from '../../../objects/preferenceSortSetting'
import PreferenceSortSetting from '../../components/preferenceSortSetting'
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg'
import {
  setHouseholdPreferences,
  updateHouseholdPreferences
} from '../../../actions/householdPreferences'
import * as InvestmentSortHelper from '../../helpers/investmentSort'
import {
  generateNewPreferences,
  mapInvPreferences
} from '../../helpers/householdPreferences'
import { dollarFormat } from '../../helpers'
import { calculateAccountTotal } from '../../helpers/netWorth'

interface ClientAccountsProps {
  dispatch: Dispatch<GlobalState>
  clientAccounts: ClientAccountObjState
  householdFinId: string
  persistedSortBy: string
  persistedGroupBy: string
  persistedSortingOrder: string
  preferences: HouseholdPreferencesObj
  showPreferencesToggle: boolean
}

interface ClientAccountsState {
  sortedAccounts: ClientAccountObjState
  sortBy: string
  groupBy: string
  sortingOrder: string
}

const nullRow = (
  <div>
    <div className='inv-accounts inv-accounts__col-w'>
      <span className='inv-accounts__col-1'>
        <span className='inv-accounts__null-box' />
      </span>
      <span className='inv-accounts__col-2'>
        <span className='inv-accounts__null-box' />
      </span>
      <span className='inv-accounts__col-3'>
        <span className='inv-accounts__null-box' />
      </span>
      <span className='inv-accounts__col-4'>
        <span className='inv-accounts__null-box' />
      </span>
      <span className='inv-accounts__col-5'>
        <span className='inv-accounts__null-box' />
      </span>
      <span className='inv-accounts__col-6'>
        <span className='inv-accounts__null-box' />
      </span>
      <span className='inv-accounts__col-7'>
        <span className='inv-accounts__null-box' />
      </span>
    </div>
  </div>
)

export class ClientAccounts extends Component<
  ClientAccountsProps,
  ClientAccountsState
> {
  constructor(props: ClientAccountsProps) {
    super(props)
    this.state = {
      sortedAccounts: this.props.clientAccounts,
      sortBy: this.props.persistedSortBy,
      groupBy: this.props.persistedGroupBy,
      sortingOrder: this.props.persistedSortingOrder
    }
  }

  public componentDidMount() {
    const {
      clientAccounts,
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder
    } = this.props
    if (
      clientAccounts &&
      persistedGroupBy &&
      persistedSortBy &&
      persistedSortingOrder
    ) {
      this.setState({
        groupBy: persistedGroupBy,
        sortBy: persistedSortBy,
        sortingOrder: persistedSortingOrder,
        sortedAccounts: InvestmentSortHelper.sortInvestmentAccounts(
          clientAccounts,
          {
            groupBy: persistedGroupBy,
            sortBy: persistedSortBy,
            sortingOrder: persistedSortingOrder
          }
        )
      })
    }
  }

  public componentDidUpdate(prevProps: ClientAccountsProps) {
    const {
      clientAccounts,
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder
    } = this.props

    if (
      clientAccounts !== prevProps.clientAccounts ||
      persistedGroupBy !== prevProps.persistedGroupBy ||
      persistedSortBy !== prevProps.persistedSortBy ||
      persistedSortingOrder !== prevProps.persistedSortingOrder
    ) {
      if (
        clientAccounts &&
        persistedGroupBy &&
        persistedSortBy &&
        persistedSortingOrder
      ) {
        this.setState({
          sortedAccounts: InvestmentSortHelper.sortInvestmentAccounts(
            clientAccounts,
            {
              groupBy: persistedGroupBy,
              sortBy: persistedSortBy,
              sortingOrder: persistedSortingOrder
            }
          ),
          groupBy: persistedGroupBy,
          sortBy: persistedSortBy,
          sortingOrder: persistedSortingOrder
        })
      }
    }
  }

  public accountRender = () => {
    const { householdFinId } = this.props
    const { sortedAccounts } = this.state
    return Object.keys(sortedAccounts).map((key, index) => {
      const clientAccount: ClientAccountObj = sortedAccounts[key]
      return (
        <ClientAccountRow
          key={index}
          householdFinId={householdFinId}
          clientAccount={clientAccount}
        />
      )
    })
  }

  public nullAccounts = () => {
    return (
      <div>
        {nullRow}
        {nullRow}
        {nullRow}
        {nullRow}
        {nullRow}
        {nullRow}
      </div>
    )
  }

  public clientAccountsHeader = () => {
    return (
      <TableRow backgroundColor='#F5F5F5'>
        <div className='inv-accounts__col-w inv-accounts__col-w--header'>
          <div className='inv-accounts__col-1'>ACCOUNT NAME</div>
          <div className='inv-accounts__col-2'>CLASSIFICATION</div>
          <div className='inv-accounts__col-3'>STRATEGY</div>
          <div className='inv-accounts__col-4'>ALLOCATION</div>
          <div className='inv-accounts__col-5'>AMOUNT</div>
          <div className='inv-accounts__col-6 inv-accounts__help-icon'>
            SHOW/HIDE
            <Tooltip
              message={staticText.includeInGc}
              width={160}
              position='bottom'
              multiLine={true}>
              <HelpIcon />
            </Tooltip>
          </div>
          <div className='inv-accounts__col-7' />
        </div>
      </TableRow>
    )
  }

  public accountsLeftHeader = () => {
    const { groupBy, sortBy, sortingOrder } = this.state
    const {
      persistedSortBy,
      persistedGroupBy,
      persistedSortingOrder
    } = this.props
    const {
      groupByOptions,
      sortByOptions,
      sortOrderOptions
    } = InvestmentSortHelper
    return (
      <div className='client-accounts-tile__header-left'>
        ACCOUNTS
        <PreferenceSortSetting
          groupByOptions={groupByOptions}
          sortByOptions={sortByOptions}
          sortOrderOptions={sortOrderOptions}
          currentSortBy={sortBy}
          currentGroupBy={groupBy}
          currentSortingOrder={sortingOrder}
          changePreference={this.changePreference}
          onCancelPreferences={this.onCancelPreferences}
          onSubmitPreferences={this.onSubmitPreferences}
          onDefaultPreferences={this.onDefaultPreferences}
          persistedSortBy={persistedSortBy}
          persistedGroupBy={persistedGroupBy}
          persistedSortingOrder={persistedSortingOrder}
        />
      </div>
    )
  }

  public onDefaultPreferences = () => {
    this.setState({
      groupBy: 'Classification',
      sortBy: 'Account Name',
      sortingOrder: 'Ascending'
    })
  }

  public onCancelPreferences = () => {
    const {
      persistedGroupBy,
      persistedSortBy,
      persistedSortingOrder
    } = this.props
    this.setState({
      groupBy: persistedGroupBy,
      sortBy: persistedSortBy,
      sortingOrder: persistedSortingOrder
    })
  }

  public onSubmitPreferences = () => {
    this.updateSortedAccounts()
    this.updatePreferences()
  }

  public updateSortedAccounts = () => {
    const { groupBy, sortBy, sortingOrder, sortedAccounts } = this.state
    const newSortedAccounts = InvestmentSortHelper.sortInvestmentAccounts(
      sortedAccounts,
      {
        groupBy,
        sortBy,
        sortingOrder
      }
    )
    this.setState({ sortedAccounts: newSortedAccounts })
  }

  public changePreference = (options: SortType) => {
    this.setState({ ...this.state, ...options })
  }

  public updatePreferences = () => {
    const currentPreferences = this.props.preferences
    const { groupBy, sortBy, sortingOrder } = this.state
    const { householdFinId } = this.props
    if (!currentPreferences) {
      const newPreferences: HouseholdPreferencesObj = {
        ...generateNewPreferences(),
        ...mapInvPreferences(groupBy, sortBy, sortingOrder)
      }
      return this.props.dispatch(
        setHouseholdPreferences(householdFinId, newPreferences)
      )
    } else {
      const updatePreferences: HouseholdPreferencesObj = {
        ...currentPreferences,
        ...mapInvPreferences(groupBy, sortBy, sortingOrder)
      }
      return this.props.dispatch(
        updateHouseholdPreferences(householdFinId, updatePreferences)
      )
    }
  }

  public accountsAmount = () => {
    const accountTotal = calculateAccountTotal(this.props.clientAccounts).total
    return (
      <span className='investment__allocation-amount'>
        {this.props.clientAccounts ? dollarFormat(accountTotal, 0) : ' '}
      </span>
    )
  }

  public render() {
    if (this.props.showPreferencesToggle) {
      return (
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          leftHeader={this.accountsLeftHeader()}
          headerBorder={true}
          rightHeader={this.accountsAmount()}>
          {this.clientAccountsHeader()}
          {this.state.sortedAccounts
            ? this.accountRender()
            : this.nullAccounts()}
        </Tile>
      )
    } else {
      return (
        <Fragment>
          {this.state.sortedAccounts
            ? this.accountRender()
            : this.nullAccounts()}
        </Fragment>
      )
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(ClientAccounts))
