import React, { Component } from 'react'
import { ClientAccountObjState } from '../../../../objects/clientAccount'
import ClientAccountRow from './clientAccountRow'

interface ClientAccountsProps {
  accounts: ClientAccountObjState
  currentItems: string[]
}
interface ClientAccountsState {
  showEditAccountModal: boolean
}

class ClientAccounts extends Component<
  ClientAccountsProps,
  ClientAccountsState
> {
  public render() {
    if (this.props.currentItems !== null) {
      return this.props.currentItems.map((accountId: any) => {
        const clientAccount = this.props.accounts[accountId]
        return (
          <ClientAccountRow key={accountId} clientAccount={clientAccount} />
        )
      })
    } else return null
  }
}

export default ClientAccounts
