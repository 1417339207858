import React, { RefObject, Fragment, CSSProperties } from 'react'
import { Line } from 'react-chartjs-2'
import { ChartOptions } from 'chart.js'
import moment from 'moment'
import 'chartjs-plugin-annotation'
import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../objects/financialGoal'

import { lineChartDataSet, filterFundedScores } from './lineChartData'
import { lineChartDataOptions } from './lineChartOptions'

export interface HistoricalLineChartProps {
  id: string
  limit: number
  fundedScores: FundedScoreObj[]
  planningSoftware?: PlanningSoftwareType
  style?: CSSProperties
  animate?: boolean
  showModal?(fundedScore: FundedScoreObj): void
}

export interface HistoricalLineChartState {
  chartBase64: string
}

class HistoricalLineChart extends React.Component<
  HistoricalLineChartProps,
  HistoricalLineChartState
> {
  public canvasRef: RefObject<HTMLCanvasElement> = React.createRef()

  constructor(props: HistoricalLineChartProps) {
    super(props)
    this.state = {
      chartBase64: null
    }
  }

  public componentDidMount() {
    setTimeout(() => {
      if (this.canvasRef.current && !this.state.chartBase64) {
        this.getBase64()
      } else {
        this.setState({ chartBase64: '' })
      }
    }, 2000)
  }

  //TO-DO fix this later
  //Type 'ChartEvent' is missing the following properties from type 'MouseEvent': altKey, button, buttons, clientX, and 40 more.  TS2322
  // to append chartjs types to non-types
  public standardChartOptions = (): ChartOptions => {
    return {
      onClick: this.scoreDetails
    }
  }

  // open modal
  public scoreDetails = <E, T extends Chart.ChartPoint>(
    event: MouseEvent,
    activeElements?: T[]
  ) => {
    const { fundedScores } = this.props
    const chartPoint = activeElements && activeElements[0]
    const numberOfScores = this.numberOfFundedScoresToDisplay()
    const key = '_index'

    let showPoints =
      fundedScores &&
      fundedScores.filter((score, index) => {
        return index === 0 || score.inGuidebook === true
      })
    showPoints =
      showPoints &&
      showPoints
        .sort((scoreA, scoreB) => {
          if (moment(scoreA.lastUpdated).isBefore(scoreB.lastUpdated)) {
            return 1
          } else {
            return 0
          }
        })
        .slice(0, numberOfScores)
        .reverse()
    if (chartPoint) {
      showPoints &&
        showPoints.forEach((_score, index) => {
          if (index === chartPoint[key]) {
            this.props.showModal(showPoints[chartPoint[key]])
          }
        })
    }
  }

  public getBase64 = () => {
    const chartInstance: string = 'chartInstance'
    const thisWindow: any = window as any
    thisWindow.devicePixelRatio = 2

    if (this.canvasRef.current && !this.state.chartBase64) {
      const canvas: HTMLCanvasElement = this.canvasRef.current[chartInstance]
        .canvas
      this.setState({ chartBase64: canvas.toDataURL('image/png', 1) })
    }
  }

  public numberOfFundedScoresToDisplay = (): number => {
    const { fundedScores, limit, id } = this.props
    let maximumPointsToShow = limit
    switch (id) {
      case 'historical-funded-score':
        maximumPointsToShow = 10
        break
      case 'latest-funded-score':
        maximumPointsToShow = 4
        break
      default:
        maximumPointsToShow = limit
    }

    let count = 0
    const scoreMaxPoints = filterFundedScores(
      maximumPointsToShow,
      fundedScores || []
    )
    scoreMaxPoints.forEach((item, index) => {
      if (count < maximumPointsToShow) {
        count = count + 1
      }
    })
    return count
  }

  public render() {
    const {
      fundedScores,
      planningSoftware,
      id,
      style,
      animate = true
    } = this.props

    const roundedScores = fundedScores.map((fundedScore) => ({
      ...fundedScore,
      score: Math.round(fundedScore.score)
    }))

    const numberOfScores = this.numberOfFundedScoresToDisplay()
    const chartDataSet =
      numberOfScores > 0
        ? lineChartDataSet(
            numberOfScores,
            planningSoftware,
            roundedScores,
            'lastUpdated',
            'score'
          )
        : null

    let chartOptions =
      numberOfScores > 0
        ? lineChartDataOptions(
            numberOfScores,
            animate,
            planningSoftware,
            roundedScores,
            this.getBase64()
          )
        : null

    if (chartOptions) {
      chartOptions = { ...chartOptions, ...this.standardChartOptions() }
    }

    return (
      <Fragment>
        {numberOfScores > 1 ? (
          <div className='funded-score-historical-graph' style={style}>
            <div className='pdf-exclude funded-score-historical-graph__chart'>
              <Line
                ref={this.canvasRef}
                id={id}
                data={chartDataSet}
                options={chartOptions}
              />
            </div>
            <img
              src={this.state.chartBase64}
              className='funded-score-historical-graph pdf-only'
              role='presentation'
              alt=''
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default HistoricalLineChart
