export const SET_TASK_FILTERS = 'SET_TASK_FILTERS'

export const setTaskFilters = (
  showLinkedTaskType: boolean,
  showNativeTaskType: boolean,
  selectedStatuses: string[],
  selectedAssignees: string[]
) => {
  return {
    type: SET_TASK_FILTERS,
    payload: {
      showLinkedTaskType,
      showNativeTaskType,
      selectedStatuses,
      selectedAssignees
    }
  }
}
