export const download = (data: string, name: string) => {
  const byteCharacters = atob(data)
  const byteArrays = []
  const sliceSize = 512
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = []
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers.push(slice.charCodeAt(i))
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  const docBlob = new Blob(byteArrays, { type: 'octet/stream' })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    return window.navigator.msSaveOrOpenBlob(docBlob, name)
  }

  const url = window.URL.createObjectURL(docBlob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.setAttribute('download', name)

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download.ts attribute.
  // This allows you to download.ts files in desktop safari if pop up blocking
  // is enabled.
  if (tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(url)
  return null
}

export const downloadBlob = (docBlob: string, name: string) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    return window.navigator.msSaveOrOpenBlob(docBlob, name)
  }

  const url = docBlob
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.setAttribute('download', name)

  // See Safari note above.
  if (tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(url)
  return null
}
