import React, { Component } from 'react'
import { ContactObj } from '../../../objects/contact'
import { CardScoreObj } from '../../../objects/honestConversations'
import { dateFormat } from '../../helpers'
import ProfilePicture from '../../components/layout/profilePicture'

export interface SharedPriorityCommentsProps {
  contact: ContactObj
  cardScore: CardScoreObj
  createdDate: string
  isPrimary?: boolean
}

class SharedPriorityComments extends Component<SharedPriorityCommentsProps> {
  public render() {
    const { contact, cardScore, createdDate } = this.props
    if (!cardScore) {
      return null
    }

    return (
      <div className='shared-priority-comments'>
        <div className='shared-priority-comments__contact-w'>
          <div>
            <ProfilePicture
              firstName={contact.firstName}
              lastName={contact.lastName}
              photo={contact.photo}
              isPrimary={this.props.isPrimary}
              pictureSize={48}
            />
          </div>
          <div className='shared-priority-comments__name-score-w'>
            <div className='shared-priority-comments__name-w'>
              <p className='shared-priority-comments__name'>
                {contact.firstName} {contact.lastName}
              </p>
              <p className='shared-priority-comments__date'>
                {createdDate && dateFormat(createdDate)}
              </p>
            </div>
            <div
              className={
                cardScore.score >= 7
                  ? 'shared-priority-comments__score-w'
                  : 'shared-priority-comments__score-w shared-priority-comments__score-w--orange'
              }>
              {cardScore.score || 0}
            </div>
          </div>
        </div>
        <div className='shared-priority-comments__comment-heading'>
          {cardScore.score
            ? cardScore.comment
              ? `${contact.firstName}'s comment`
              : null
            : `${contact.firstName} hasn't scored this priority yet`}
        </div>
        <div className='shared-priority-comments__comment'>
          {cardScore
            ? cardScore.comment
            : 'Click on the Add New Score button in the top right to add scores to priorities'}
        </div>
      </div>
    )
  }
}

export default SharedPriorityComments
