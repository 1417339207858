import React from 'react'
import { Dispatch, connect } from 'react-redux'

import { addToast } from '../../../actions/toasts'
import { GlobalState } from '../../../reducers'
import Input from '../../components/Input'
import Modal from '../../components/layout/modal'
import { PortfolioBuilderAccountObj } from '../../../objects/portfolioBuilder'
import { formatCurrencyInput, sanitizeCurrency } from '../../helpers'
import Button from '../../components/button'

import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'

interface AddAccountProps {
  accountDetails?: PortfolioBuilderAccountObj
  add?: boolean
  dispatch: Dispatch<GlobalState>
  closeModal(): void
  onAccountAdd(value: PortfolioBuilderAccountObj): void
}

interface AddAccountStates {
  accountDetails: PortfolioBuilderAccountObj
  nameError: boolean
  assetError: boolean
  balanceError: boolean
  accVal: string
  clickCount: number
}

class AccountModal extends React.Component<AddAccountProps, AddAccountStates> {
  private accountNull: PortfolioBuilderAccountObj = {
    id: '',
    clientAccount: '',
    accountValue: null,
    custodian: null,
    portfolioBuilder: '',
    strategy: null,
    accountDescription: '',
    accountNickname: '',
    accountNumber: '',
    combinedId: null,
    currentCashValue: null,
    currentEquityValue: null,
    currentFixedValue: null,
    currentOtherValue: null,
    currentUnclassifiedValue: null,
    expenseRatio: null,
    includeCurrentPortfolio: false,
    includeFactSheet: false,
    includePerformanceFactSheet: false,
    recommendationSummary: '',
    registrationType: '',
    strategyFee: null,
    type: 'Current',
    unsplitId: '',
    yield: null
  }

  constructor(props: AddAccountProps) {
    super(props)
    this.state = {
      nameError: false,
      assetError: false,
      balanceError: false,
      accountDetails: this.props.accountDetails
        ? { ...this.props.accountDetails }
        : this.accountNull,
      accVal: '',
      clickCount: 0
    }
  }

  public componentDidMount() {
    const { accountDetails } = this.state
    if (accountDetails && accountDetails.accountValue) {
      this.setState({
        accVal: formatCurrencyInput(String(accountDetails.accountValue))
      })
    }
    accountDetails.currentCashValue =
      parseFloat(
        (
          (accountDetails.currentCashValue / accountDetails.accountValue) *
          100
        ).toFixed(2)
      ) || 0
    accountDetails.currentEquityValue =
      parseFloat(
        (
          (accountDetails.currentEquityValue / accountDetails.accountValue) *
          100
        ).toFixed(2)
      ) || 0
    accountDetails.currentFixedValue =
      parseFloat(
        (
          (accountDetails.currentFixedValue / accountDetails.accountValue) *
          100
        ).toFixed(2)
      ) || 0
    accountDetails.currentOtherValue =
      parseFloat(
        (
          (accountDetails.currentOtherValue / accountDetails.accountValue) *
          100
        ).toFixed(2)
      ) || 0
    accountDetails.currentUnclassifiedValue =
      parseFloat(
        (
          (accountDetails.currentUnclassifiedValue /
            accountDetails.accountValue) *
          100
        ).toFixed(2)
      ) || 0
    this.setState({ accountDetails })
  }

  public calcPercent = () => {
    const { accountDetails } = this.state
    return (
      accountDetails.currentCashValue +
        accountDetails.currentEquityValue +
        accountDetails.currentFixedValue +
        accountDetails.currentOtherValue +
        accountDetails.currentUnclassifiedValue ===
      100
    )
  }

  public valClickCount = () => {
    const { clickCount } = this.state
    if (this.state.clickCount >= 1) {
      return false
    } else {
      this.setState({ clickCount: clickCount + 1 })
      return true
    }
  }

  public saveModel = async () => {
    const { accountDetails } = this.state
    this.validateForm()
    if (this.enableAddButton() && this.valClickCount()) {
      await this.props.onAccountAdd(accountDetails)
      this.props.dispatch(
        addToast({
          message: `Account successfully ${
            this.props.add ? 'created' : 'edited'
          }`,
          backgroundColor: '#D9F2B6'
        })
      )
      this.props.closeModal()
    }
  }

  public cancelModal = () => {
    this.props.closeModal()
  }

  public handleChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget
    let value: string | number = target.value
    const name = target.name
    const accountDetails = this.state.accountDetails
    if (name === 'expenseRatio' || name === 'strategyFee' || name === 'yield') {
      value = parseFloat(value)
      value = isNaN(value) ? null : value
      if (value >= 0 && value !== null && value <= 100) {
        accountDetails[name] = value
        this.setState({ accountDetails })
      } else if (value < 0 || !value) {
        accountDetails[name] = null
        this.setState({ accountDetails })
      }
    } else if (
      name === 'currentCashValue' ||
      name === 'currentEquityValue' ||
      name === 'currentFixedValue' ||
      name === 'currentOtherValue' ||
      name === 'currentUnclassifiedValue'
    ) {
      value = parseFloat(value)
      value = isNaN(value) ? null : value
      if (
        value >= 0 &&
        value <= 100 &&
        value !== null &&
        accountDetails.currentCashValue +
          accountDetails.currentEquityValue +
          accountDetails.currentFixedValue +
          accountDetails.currentOtherValue +
          value -
          accountDetails[name] <=
          100
      ) {
        accountDetails[name] = value
        this.setState({ accountDetails })
      } else if (value < 0 || !value) {
        accountDetails[name] = null
        this.setState({ accountDetails })
      }
    } else if (name === 'accountValue') {
      this.setState({ accVal: formatCurrencyInput(value) })
      accountDetails[name] = sanitizeCurrency(value)
      this.setState({ accountDetails })
    } else if (name === 'accountNickname') {
      accountDetails[name] = value
      this.setState({ accountDetails })
    } else {
      accountDetails[name] = value
      this.setState({ accountDetails })
    }
  }

  public validateForm = () => {
    const { accountDetails } = this.state
    !accountDetails.accountNickname
      ? this.setState({ nameError: true })
      : this.setState({ nameError: false })
    !accountDetails.accountValue
      ? this.setState({ balanceError: true })
      : this.setState({ balanceError: false })
    !this.calcPercent()
      ? this.setState({ assetError: true })
      : this.setState({ assetError: false })
  }

  public enableAddButton = (): boolean => {
    const { accountDetails } = this.state
    if (
      !accountDetails.accountNickname ||
      !accountDetails.accountValue ||
      !this.calcPercent()
    )
      return false
    else return true
  }

  // tslint:disable-next-line:max-func-body-length
  public assetAllocation = () => {
    const { accountDetails, assetError } = this.state
    return (
      <div className='w-90'>
        <label htmlFor={'name'} className='form-label-radio'>
          Asset Allocation
        </label>
        <div className='form-inline'>
          <Input
            percentageSign={true}
            mandatory={true}
            classNames={assetError ? 'form-input-Error' : ''}
            title='Equity'
            name='currentEquityValue'
            inputType='number'
            controlFunc={this.handleChange}
            content={
              accountDetails.currentEquityValue < 0 ||
              accountDetails.currentEquityValue === null
                ? ''
                : accountDetails.currentEquityValue
            }
            placeholder='0'
          />
          <Input
            percentageSign={true}
            mandatory={true}
            classNames={assetError ? 'form-input-Error' : ''}
            title='Fixed'
            name='currentFixedValue'
            inputType={'number'}
            controlFunc={this.handleChange}
            content={
              accountDetails.currentFixedValue < 0 ||
              accountDetails.currentFixedValue === null
                ? ''
                : accountDetails.currentFixedValue
            }
            placeholder='0'
          />
          <Input
            percentageSign={true}
            mandatory={true}
            classNames={assetError ? 'form-input-Error' : ''}
            title={'Alt'}
            name='currentOtherValue'
            inputType='number'
            controlFunc={this.handleChange}
            content={
              accountDetails.currentOtherValue < 0 ||
              accountDetails.currentOtherValue === null
                ? ''
                : accountDetails.currentOtherValue
            }
            placeholder='0'
          />
          <Input
            percentageSign={true}
            mandatory={true}
            classNames={assetError ? 'form-input-Error' : ''}
            title='Cash'
            name='currentCashValue'
            inputType='number'
            controlFunc={this.handleChange}
            content={
              accountDetails.currentCashValue < 0 ||
              accountDetails.currentCashValue === null
                ? ''
                : accountDetails.currentCashValue
            }
            placeholder='0'
          />
          <Input
            percentageSign={true}
            mandatory={true}
            classNames={assetError ? 'form-input-Error' : ''}
            title='Unclassified'
            name='currentUnclassifiedValue'
            inputType='number'
            controlFunc={this.handleChange}
            content={
              accountDetails.currentUnclassifiedValue < 0 ||
              accountDetails.currentUnclassifiedValue === null
                ? ''
                : accountDetails.currentUnclassifiedValue
            }
            placeholder='0'
          />
        </div>
        <div className='form-inline'>
          <Input
            percentageSign={true}
            title='Yield'
            name='yield'
            inputType='number'
            controlFunc={this.handleChange}
            content={
              accountDetails.yield < 0 || accountDetails.yield === null
                ? ''
                : accountDetails.yield
            }
            placeholder='0'
          />
          <Input
            percentageSign={true}
            title='Exp Ratio'
            name='expenseRatio'
            inputType='number'
            controlFunc={this.handleChange}
            content={
              accountDetails.expenseRatio < 0 ||
              accountDetails.expenseRatio === null
                ? ''
                : accountDetails.expenseRatio
            }
            placeholder='0'
          />
        </div>
      </div>
    )
  }

  public render() {
    const { closeModal, add } = this.props
    const {
      accountDetails,
      nameError,
      assetError,
      accVal,
      balanceError
    } = this.state
    if (accountDetails) {
      return (
        <Modal
          icon={add ? PlusIcon : EditIcon}
          title={add ? 'ADD ACCOUNT' : 'EDIT ACCOUNT'}
          closeModal={closeModal}>
          <div className='modal-body'>
            <div className='form-inline'>
              <Input
                title='Account Name'
                mandatory={true}
                name='accountNickname'
                classNames={nameError ? 'form-input-Error' : ''}
                inputType='text'
                controlFunc={this.handleChange}
                content={accountDetails.accountNickname || ''}
                placeholder='Account Name'
              />
              <Input
                title='Account Number'
                name='accountNumber'
                inputType='text'
                controlFunc={this.handleChange}
                content={accountDetails.accountNumber || ''}
                placeholder='Account Number'
              />
            </div>
            <div className='form-inline'>
              <Input
                dollarSign={true}
                title='Account Balance'
                mandatory={true}
                classNames={balanceError ? 'form-input-Error' : ''}
                name='accountValue'
                inputType='text'
                controlFunc={this.handleChange}
                content={accVal}
                placeholder='0'
              />
              <Input
                title='Registration Type'
                name='registrationType'
                inputType='text'
                controlFunc={this.handleChange}
                content={accountDetails.registrationType || ''}
                placeholder='Registration Type'
              />
            </div>
            <div className='w-50 form-inline'>
              <Input
                title='Custodian'
                name='custodian'
                inputType='text'
                controlFunc={this.handleChange}
                content={accountDetails.custodian || ''}
                placeholder='Custodian'
              />
            </div>
            {this.assetAllocation()}
            {nameError && (
              <span className='form-input-Error__message'>
                * Account Name is Required
              </span>
            )}
            <br />
            {balanceError && (
              <span className='form-input-Error__message'>
                * Account Balance is Required
              </span>
            )}
            <br />
            {assetError && (
              <span className='form-input-Error__message'>
                * Total Asset Allocation Should be Equal to 100
              </span>
            )}
            <div className='data-right'>
              <Button onClick={this.cancelModal}>Cancel</Button>
              <Button primary={this.enableAddButton()} onClick={this.saveModel}>
                Save
              </Button>
            </div>
          </div>
        </Modal>
      )
    } else {
      return null
    }
  }
}

export default connect()(AccountModal)
