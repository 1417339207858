import * as actions from '../actions/meetingMode'

export interface MeetingModeState {
  meetingMode: boolean
}

const initState: MeetingModeState = {
  meetingMode: false
}

const MeetingMode = (state = initState, action: any): MeetingModeState => {
  switch (action.type) {
    case actions.MEETINGMODE_TRUE:
      const activeMeetingMode = { active: true, householdId: action.payload }
      sessionStorage.setItem('meetingmode', JSON.stringify(activeMeetingMode))
      return { ...state, meetingMode: true }
    case actions.MEETINGMODE_FALSE:
      sessionStorage.removeItem('meetingmode')
      return { ...state, meetingMode: false }
    default:
      return state
  }
}

export default MeetingMode
