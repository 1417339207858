import React from 'react'
import HonestConversationEditCard from './hcEditCard'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../objects/HonestConversations/card'
import { CardRankingObj } from '../../../../objects/honestConversations'
import ScoreCommentTextArea from '../scoreCommentTextArea'

interface HonestConversationEditCardRowProps {
  index: number
  displayIndex: number
  contactType: string
  card: CardRankingObj
  householdFinId: string
  cardOptions?: CardOptionsInterface
  selectedCards: CardRankingObj[]
  editMode?: boolean
  initialComment?: string
  onCardSelection(index: number, contactType: string, card: CardObj): void
  onCommentChange(index: number, contactType: string, comment: string): void
  onBlur(contactType: string, index: number, card: CardObj): void
  setDisableInputs(disabledInputs: boolean): void
  disableInputs: boolean
}
export type autoSaveStatus = 'ongoing' | 'success' | 'error' | null
interface HonestConversationEditCardRowState {
  autoSaveStatus: autoSaveStatus
}
class HonestConversationEditCardRow extends React.Component<
  HonestConversationEditCardRowProps,
  HonestConversationEditCardRowState
> {
  constructor(props: HonestConversationEditCardRowProps) {
    super(props)
    this.state = {
      autoSaveStatus: null
    }
  }

  public mounted = false
  public componentDidMount() {
    this.mounted = true
  }
  public componentWillUnmount() {
    this.mounted = false
  }

  public commentChange = (
    comment: string = '',
    isPrimary?: boolean,
    key?: number
  ) => {
    const {
      card: { comment: prevComment }
    } = this.props
    if (comment !== prevComment) {
      const { index, contactType } = this.props
      this.props.onCommentChange(index, contactType, comment)
    }
  }

  public updateAutoSaveStatus(status: 'ongoing' | 'success' | 'error' | null) {
    this.setState({ autoSaveStatus: status })
  }

  public onInputBlur = async (isPrimary: boolean) => {
    const { contactType, index, card, onBlur, setDisableInputs } = this.props
    if (onBlur) {
      setDisableInputs(true)
      this.updateAutoSaveStatus('ongoing')
      try {
        await onBlur(contactType, index, card.card)
        this.updateAutoSaveStatus('success')
      } catch {
        this.updateAutoSaveStatus('error')
      }
      this.mounted && setDisableInputs(false)
    }
  }

  public onFocus = (isPrimary: boolean) => {
    this.setState({ autoSaveStatus: null })
  }

  public isCardSelected = () => {
    const { card } = this.props
    return !(card && card.id)
  }
  public onCardSelection = (card: CardObj) => {
    const { index, contactType, onCardSelection } = this.props
    onCardSelection(index, contactType, card)
  }
  public render() {
    const {
      displayIndex,
      index,
      card,
      initialComment,
      editMode = true,
      selectedCards,
      householdFinId,
      disableInputs
    } = this.props
    const { autoSaveStatus } = this.state
    const comment = card && card.comment
    return (
      <div className='hc-card-row__w hc-card-row__w--no-padding '>
        <HonestConversationEditCard
          householdFinId={householdFinId}
          displayIndex={displayIndex}
          index={index}
          card={card.card}
          cardOptions={this.props.cardOptions}
          selectedCards={selectedCards}
          onCardSelection={this.onCardSelection}
        />
        <div className='hc-new-meeting__row'>
          <ScoreCommentTextArea
            key={card?.card?.id}
            initialComment={initialComment}
            value={comment}
            hideTooltip={this.isCardSelected()}
            isDisabled={this.isCardSelected() || disableInputs}
            onCommentChange={this.commentChange}
            onInputBlur={this.onInputBlur}
            isPrimary={true}
            charLimit={300}
            enableUndo={editMode}
            cardIndex={displayIndex}
            parentCls='hc-new-meeting'
            placeholderTxt='Enter the card comment'
            autoSaveStatus={autoSaveStatus}
            onFocus={this.onFocus}
          />
        </div>
      </div>
    )
  }
}

export default HonestConversationEditCardRow
