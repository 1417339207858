import React from 'react'
import Modal from '../../../components/layout/modal'
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'
import { OfficeTeamObj } from '../../../../objects/officeTeams'

interface EditModalProps {
  team: OfficeTeamObj
  closeModal(): void
  saveModal(id: string, name: string): void
}

interface EditTeamModalState {
  name: string
}

class EditModal extends React.Component<EditModalProps, EditTeamModalState> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: this.props.team.name
    }
  }

  public handleInputChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      name: event.currentTarget.value
    })
  }

  public editTeam = () => {
    if (this.state.name.trim()) {
      this.props.saveModal(this.props.team.id, this.state.name)
    }
  }

  public render() {
    const { closeModal } = this.props
    const { name } = this.state
    return (
      <Modal icon={EditIcon} title='Edit Team' closeModal={closeModal}>
        <div className='team-management__modal-body'>
          <div className='team-management__modal-body__input'>
            <p>Team Name</p>
            <input
              onChange={this.handleInputChange}
              className='input'
              value={name}
            />
          </div>
        </div>
        <div className='team-management__modal-btns-w'>
          <span className='btn btn__clear' onClick={closeModal}>
            Cancel
          </span>
          <span className='btn btn__prime' onClick={this.editTeam}>
            Save
          </span>
        </div>
      </Modal>
    )
  }
}

export default EditModal
