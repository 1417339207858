import React, { Fragment } from 'react'
import HonestConversationEditCard from './hcEditCard'
import { CardRankingObj } from '../../../../objects/honestConversations'
import ScoreCommentTextArea from '../scoreCommentTextArea'
import ScoreSelector from '../scoreSelector'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../objects/HonestConversations/card'
import SharedPriorityActions from '../lifeActions'
import { ContactsInterface } from '../../../../objects/contact'
import { LifeActionObj } from '../../../../objects/lifeActions'

interface CombinedCardRankingObj extends CardRankingObj {
  actionList?: LifeActionObj[]
}

interface HonestConversationEditCombinedActionsProps {
  displayIndex: number
  index: number
  card: CombinedCardRankingObj
  cardOptions: CardOptionsInterface
  selectedCards: CardRankingObj[]
  primaryScore: number
  commentPrimary: string
  secondaryScore: number
  householdFinId: string
  commentSecondary: string
  contacts: ContactsInterface
  editRecord: boolean
  initialCommentPrimary?: string
  initialCommentSecondary?: string
  onScoreChange(
    index: number,
    scoreKey: 'primaryScore' | 'secondaryScore',
    score: number
  ): void
  onCardSelection(index: number, contactType: string, card: CardObj): void
  onCommentChange(
    index: number,
    commentKey: 'commentPrimary' | 'commentSecondary',
    comment: string
  ): void
  onModifyAction(index: number, actionList: LifeActionObj[]): void
  onBlur?(
    contactType: string,
    index: number,
    card: CardObj,
    primaryKey: string
  ): void
  setDisableInputs(disabledInputs: boolean): void
  disableInputs: boolean
}
interface HonestConversationEditCombinedActionsState {
  currentCard: string
  showAddActionModal: boolean
  selectedCard: CardRankingObj
  cardRowIndex: number
  autoSavePrimary: 'ongoing' | 'success' | 'error' | null
  autoSaveSecondary: 'ongoing' | 'success' | 'error' | null
}

class HonestConversationEditCombinedActions extends React.Component<
  HonestConversationEditCombinedActionsProps,
  HonestConversationEditCombinedActionsState
> {
  constructor(props: HonestConversationEditCombinedActionsProps) {
    super(props)
    this.state = {
      cardRowIndex: props.index,
      currentCard: null,
      showAddActionModal: false,
      selectedCard: props.card,
      autoSavePrimary: null,
      autoSaveSecondary: null
    }
  }

  public mounted: boolean = false

  public componentDidMount() {
    this.setState({
      selectedCard: this.props.card
    })
    this.mounted = true
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public onScoreChange = (score: number, isPrimary: boolean) => {
    const { onScoreChange, index } = this.props
    const scoreKey = isPrimary ? 'primaryScore' : 'secondaryScore'
    if (this.isCardExist()) {
      onScoreChange(index, scoreKey, score)
    }
  }

  public openAddActionModal = (
    currentCardKey: string,
    cardRowIndex: number
  ) => (e: any) => {
    if (currentCardKey) {
      this.setState({
        showAddActionModal: true,
        currentCard: currentCardKey,
        cardRowIndex
      })
    }
  }

  public closeAddActionModal = () => {
    this.setState({ showAddActionModal: false, currentCard: null })
  }

  public modifyAction = (action: LifeActionObj, modifyType: string) => {
    const { index, card, onModifyAction } = this.props
    const newActionList = card.actionList ? [...card.actionList] : []
    const relatedIndex = newActionList.findIndex(
      (item) => item.id === action.id
    )
    switch (modifyType) {
      case 'create':
        newActionList.push(action)
        onModifyAction(index, newActionList)
        break
      case 'update':
        newActionList[relatedIndex] = action
        onModifyAction(index, newActionList)
        break
      case 'delete':
        newActionList.splice(relatedIndex, 1)
        onModifyAction(index, newActionList)
        break
      default:
    }
  }

  public onCommentChange = (comment: string, isPrimary: boolean) => {
    const { index, onCommentChange } = this.props
    if (onCommentChange && this.isCardExist()) {
      const commentKey = isPrimary ? 'commentPrimary' : 'commentSecondary'
      onCommentChange(index, commentKey, comment)
    }
  }

  public isCardExist = () => {
    const { card } = this.props
    return Boolean(card && card?.card && card?.id)
  }

  public updateAutoSaveStatus(
    isPrimary: boolean,
    status: 'ongoing' | 'success' | 'error' | null
  ) {
    if (isPrimary) {
      this.setState({ autoSavePrimary: status })
    } else {
      this.setState({ autoSaveSecondary: status })
    }
  }

  public onInputBlur = async (isPrimary: boolean) => {
    const { index, card, onBlur, setDisableInputs } = this.props
    const primaryKey = isPrimary ? 'commentPrimary' : 'commentSecondary'
    if (onBlur) {
      setDisableInputs(true)
      this.updateAutoSaveStatus(isPrimary, 'ongoing')
      try {
        await onBlur('combinedCards', index, card.card, primaryKey)
        this.updateAutoSaveStatus(isPrimary, 'success')
      } catch {
        this.updateAutoSaveStatus(isPrimary, 'error')
      }
      this.mounted && setDisableInputs(false)
    }
  }

  public onFocus = (isPrimary: boolean) => {
    this.updateAutoSaveStatus(isPrimary, null)
  }
  /**
   * Disable inputs and buttons if card doesn't exist,
   * during autosave and if score is null or undefined
   */
  public isDisabled = (score: number | null | undefined) => {
    const { disableInputs } = this.props
    return !this.isCardExist() || disableInputs || typeof score !== 'number'
  }

  // IMPORTANT: **** ScoreCommentTextArea ****** Component will only visible in Edit Mode,
  // IMPORTANT: We assume client went through GuideCenter page before landing here
  public renderScoreSelectorAndComment = (
    isPrimary: boolean,
    score: number,
    commentValue: string,
    autoSaveStatus: 'ongoing' | 'success' | 'error' | null
  ) => {
    const {
      contacts,
      editRecord,
      displayIndex,
      index,
      card,
      initialCommentPrimary,
      initialCommentSecondary
    } = this.props

    const secondary = contacts && contacts.secondary

    const isDisabled = this.isDisabled(score)

    return (
      <div
        className={
          secondary
            ? 'record-form__score'
            : 'record-form__score record-form__score--single-contact'
        }>
        <ScoreSelector
          cardIndex={index}
          score={score}
          onScoreChange={this.onScoreChange}
          isPrimary={isPrimary}
          singleView={!secondary}
        />
        {editRecord ? (
          <ScoreCommentTextArea
            initialComment={
              isPrimary ? initialCommentPrimary : initialCommentSecondary
            }
            key={`${card?.card?.id} ${isPrimary ? 'primary' : 'secondary'}`}
            value={commentValue}
            hideTooltip={isDisabled}
            isDisabled={isDisabled}
            onCommentChange={this.onCommentChange}
            isPrimary={isPrimary}
            cardIndex={displayIndex}
            onInputBlur={this.onInputBlur}
            autoSaveStatus={autoSaveStatus}
            onFocus={this.onFocus}
            showCompactView={true}
          />
        ) : null}
      </div>
    )
  }

  public onCardSelection = (card: CardObj) => {
    const { index, onCardSelection } = this.props
    const updateCard = { ...this.state.selectedCard, card }
    this.setState(
      {
        selectedCard: updateCard
      },
      () =>
        onCardSelection(index, 'combinedCards', this.state.selectedCard.card)
    )
  }

  public showPriorityContent = (rankedCard: CardRankingObj) => {
    const { selectedCard, autoSavePrimary, autoSaveSecondary } = this.state
    const { contacts, primaryScore, secondaryScore } = this.props
    const containerCls = this.isCardExist()
      ? ''
      : 'record-form__score-action-col--fade'
    return (
      <Fragment>
        <div className={`record-form__score-action-col ${containerCls}`}>
          <div className='record-form__score-w'>
            {contacts && contacts.secondary
              ? this.renderScoreSelectorAndComment(
                  false,
                  secondaryScore,
                  rankedCard.commentSecondary,
                  autoSaveSecondary
                )
              : null}
            {this.renderScoreSelectorAndComment(
              true,
              primaryScore,
              rankedCard.commentPrimary,
              autoSavePrimary
            )}
          </div>
          {
            <SharedPriorityActions
              selectedCardId={selectedCard?.card?.id}
              selectedCard={selectedCard}
              actionBtnPosition='bottom'
              showNoDataView={false}
              componentClass='block'
              contacts={contacts}
              enableToggleGoal={true}
            />
          }
        </div>
      </Fragment>
    )
  }

  public render() {
    const {
      displayIndex,
      index,
      card,
      selectedCards,
      householdFinId,
      cardOptions
    } = this.props
    return (
      <div className='hc-card-row__w hc-card-row__w--no-padding hc-card-row__w--border'>
        <HonestConversationEditCard
          householdFinId={householdFinId}
          displayIndex={displayIndex}
          index={index}
          card={card.card}
          selectedCards={selectedCards}
          cardOptions={cardOptions}
          onCardSelection={this.onCardSelection}
        />
        <div className='hc-new-meeting__row'>
          {this.showPriorityContent(card)}
        </div>
      </div>
    )
  }
}

export default HonestConversationEditCombinedActions
