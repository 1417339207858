import React, { Component } from 'react'
import { Switch, Route } from 'react-router'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { history } from '../../../../store'

import { isObjEmpty } from '../../../helpers'
import ImHeader from '../../../components/im/header'

import InnerContainer from '../../../components/layout/innerContainer'
import Positions from './positions'
import Benchmarks from './benchmarks'
import Analysis from './analysis'
import ProgressTracker from './footer/progressTracker'
import PositionsModal from './positions/modal'

import { HouseholdObj } from '../../../../objects/household'
import { ContactsInterface } from '../../../../objects/contact'
import {
  ClientAccountObjState,
  positionsRequestFields
} from '../../../../objects/clientAccount'

import { getIndividualHousehold } from '../../../../actions/households'
import { getHouseholdBenchmarks } from '../../../../actions/portfolioAnalytics'
import { getPortfolioBuilders } from '../../../../actions/portfolioBuilder'
import {
  getClientAccounts,
  createClientAccount
} from '../../../../actions/clientAccounts'
import { uploadPositions } from '../../../../actions/positions'
import { getContacts } from '../../../../actions/contacts'
import { PortfolioAnalyticsStateKeys } from '../../../../objects/PortfolioAnalytics/analytics'
import AddAccount from './positions/modal/addAccount'
import ImportPosition from './positions/modal/importPosition'
import {
  positionsFileValidator,
  positionsPayload
} from '../../../helpers/positions'

const ImTheme = ({ children }: any) => {
  return (
    <>
      <React.Suspense fallback={<></>}>{children}</React.Suspense>
    </>
  )
}

export interface PositionsProps {
  dispatch: Dispatch<GlobalState>
  match: any
  householdFinId: string
  household: HouseholdObj
  clientAccounts: ClientAccountObjState
  contact: ContactsInterface
  portfolioBuilder: any
  pathname: string
  positions: any
}

interface PositionsState {
  selected: string
  selectedOption: string
  toggleAddAccount: boolean
  toggleImportPositions: boolean
  clientAccountId: string
}

export class PortfolioAnalytics extends Component<
  PositionsProps,
  PositionsState
> {
  constructor(props: PositionsProps) {
    super(props)
    this.state = {
      selected: null,
      selectedOption: null,
      toggleAddAccount: false,
      toggleImportPositions: false,
      clientAccountId: null
    }
  }

  public componentDidMount = async () => {
    const { dispatch, householdFinId, household, clientAccounts } = this.props
    document.body.classList.add('im-portfolio__body-styles')
    if (householdFinId) {
      await dispatch(getContacts())

      !household && (await dispatch(getIndividualHousehold(householdFinId)))
      !clientAccounts && dispatch(getClientAccounts(householdFinId))

      await dispatch(getPortfolioBuilders(householdFinId))
      dispatch(getHouseholdBenchmarks(householdFinId, true))
    }
  }

  public componentWillUnmount() {
    document.body.classList.remove('im-portfolio__body-styles')
  }

  /**
   * @param selectedId
   * @param selectedOption
   * Set the selected benchmark id and type(isg|proposed)
   */
  public setSelectedId = (selectedId: string, selectedOption: string) => {
    const { selected } = this.state
    this.setState({
      selectedOption,
      selected: selectedId === selected ? null : selectedId
    })
  }

  /**
   *
   * @param stateKeyObj
   * Set toggle for any number of modals by this.state toggle* key
   */
  public toggleModal = <T extends PortfolioAnalyticsStateKeys>(
    stateKeyObj: T
  ) => {
    const { clientAccountId = null } = stateKeyObj
    const toggleKey = Object.keys(this.state).filter((stateKey) =>
      Object.values(stateKeyObj).includes(stateKey)
    )[0]

    this.setState((state) => ({
      ...state,
      [toggleKey]: !state[toggleKey],
      clientAccountId
    }))
  }

  /**
   *
   * @param newClientAccount
   * Minimal set of fields to create an account
   */
  public createAccount = (newClientAccount: any) => {
    const { dispatch, householdFinId, contact } = this.props
    const clientAccountObj = { ...newClientAccount }
    const ownerIdList = []

    clientAccountObj.pssSelected = clientAccountObj.pssSelected.toString()
    clientAccountObj.includeInGuidebook = clientAccountObj.includeInGuidebook.toString()

    if (!Object.is(clientAccountObj.ownerIds, null)) {
      const { primary, secondary } = contact
      const ownerType = clientAccountObj.ownerIds.toLowerCase()

      if (ownerType === 'primary') {
        ownerIdList.push(primary.id)
      }

      if (ownerType === 'secondary') {
        ownerIdList.push(secondary.id)
      }

      if (ownerType === 'joint') {
        ownerIdList.push(primary.id)
        !Object.is(secondary, null) && ownerIdList.push(secondary.id)
      }
      clientAccountObj.ownerIds = ownerIdList
    }

    dispatch(createClientAccount(householdFinId, clientAccountObj))
    this.toggleModal({ toggleAddAccount: 'toggleAddAccount' })
  }

  /**
   *
   * @param clientAccountId
   * client account to upload to
   * @param data
   * CSV data
   *
   */
  public uploadPositions = async (clientAccountId: string, data: any) => {
    const { householdFinId, dispatch } = this.props

    const validated = positionsFileValidator(data, dispatch)

    if (!validated) {
      this.toggleModal({
        toggleImportPositions: 'toggleImportPositions',
        clientAccountId
      })

      return
    }

    const positionsData = positionsPayload(data)

    await dispatch(
      uploadPositions(
        householdFinId,
        clientAccountId,
        positionsData,
        positionsRequestFields
      )
    )

    dispatch(getClientAccounts(householdFinId))

    // close the modal, with clientAccountId
    this.toggleModal({
      toggleImportPositions: 'toggleImportPositions',
      clientAccountId
    })
  }

  public runAanalysis = () => {
    const {
      match: { url }
    } = this.props
    history.push(`${url}/analysis`)
  }

  public render = () => {
    const {
      clientAccounts,
      householdFinId,
      pathname,
      match: { path, url }
    } = this.props
    const {
      selected,
      selectedOption,
      toggleAddAccount,
      toggleImportPositions,
      clientAccountId
    } = this.state

    return (
      <ImTheme>
        <div className='im-portfolio__main-w'>
          <ImHeader
            clientAccounts={clientAccounts}
            clientAccountId={clientAccountId}
          />

          <InnerContainer>
            <Switch>
              <Route
                path={`${path}`}
                render={() => (
                  <Positions
                    {...this.props}
                    featureFunc={{
                      toggleModal: this.toggleModal
                    }}
                  />
                )}
                exact={true}
              />
              <Route
                path={`${path}/benchmark`}
                render={() => (
                  <Benchmarks
                    selectedId={selected}
                    selectedOption={selectedOption}
                    householdFinId={householdFinId}
                    selectionIdSetter={this.setSelectedId}
                  />
                )}
                exact={true}
              />
              <Route
                path={`${path}/analysis/`}
                render={() => (
                  <Analysis {...this.props} benchmarkId={selected} />
                )}
                exact={true}
              />
            </Switch>

            <ProgressTracker
              runAnalysis={this.runAanalysis}
              selectedId={selected}
              householdFinId={householdFinId}
              url={url}
              pathname={pathname}
              clientAccounts={clientAccounts}
            />
          </InnerContainer>

          {toggleAddAccount ? (
            <PositionsModal size='XL'>
              <AddAccount
                featureFunc={{
                  toggleModal: this.toggleModal,
                  createAccount: this.createAccount
                }}
              />
            </PositionsModal>
          ) : null}
          {toggleImportPositions ? (
            <PositionsModal>
              <ImportPosition
                householdFinId={householdFinId}
                clientAccountId={clientAccountId}
                featureFunc={{
                  toggleModal: this.toggleModal,
                  uploadPositions: this.uploadPositions
                }}
              />
            </PositionsModal>
          ) : null}
        </div>
      </ImTheme>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match, location }: any) => {
  const {
    params: { householdFinId }
  } = match
  const { pathname } = location
  const {
    households = {},
    clientAccount = {},
    contact = {},
    positions = {},
    portfolioBuilder = null
  } = store

  return {
    pathname,
    householdFinId,
    clientAccounts:
      !isObjEmpty(clientAccount) &&
      clientAccount[householdFinId].clientAccounts,
    contact: !isObjEmpty(contact) && contact[householdFinId],
    household: !isObjEmpty(households) && households[householdFinId],
    portfolioBuilder,
    positions
  }
}

export default connect(mapStateToProps)(PortfolioAnalytics)
