import React, { ReactNode } from 'react'
import Tile from '../../components/layout/tile'

interface MoneyMindProps {
  title: ReactNode
  description: string
  blended: boolean
}

export class MoneyMindDescription extends React.Component<MoneyMindProps> {
  constructor(props: MoneyMindProps) {
    super(props)
  }

  public render() {
    return (
      <div className='moneymind__description'>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          leftHeader={this.props.title}
          headerBorder={true}>
          <div
            className={
              this.props.blended
                ? 'moneymind__description-content moneymind__description-content-blended'
                : 'moneymind__description-content'
            }>
            <p dangerouslySetInnerHTML={{ __html: this.props.description }} />
          </div>
        </Tile>
      </div>
    )
  }
}
