import React, { Component, Fragment, ChangeEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import api from '../../helpers/api'
import { dollarFormat, dateFormat } from '../../helpers'
import moment from 'moment'
import Modal from '../../components/layout/modal'
import Tile from '../../components/layout/tile'
import { ContactsInterface } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'
import GuidebookIcon from '../../assets/images/icons/ic_guidebookgray.png'

import ProductInfo from './productInfo'
import SuccessfulOrder from './successfulOrder'
import RecipientInfo from './recipientInfo'
import SenderInfo from './senderInfo'
import Shipping from './shipping'
import MimeoButtons from './mimeoButtons'
import { MimeoOrderRequest } from '../../../objects/mimeo'

interface MimeoProps {
  guidebook: any
  contacts: ContactsInterface
  user: UserObj
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  toggleModal(): void
}

interface MimeoState {
  error: {
    quantityError: boolean
    missingField: boolean
    serverError: boolean
    shippingSelected: boolean
  }
  activePage: number
  order: MimeoOrderRequest
}

class Mimeo extends Component<MimeoProps, MimeoState> {
  constructor(props: MimeoProps) {
    super(props)
    const { contacts, user } = this.props
    const contactPrimary = contacts && contacts.primary
    this.state = {
      error: {
        quantityError: false,
        missingField: true,
        serverError: false,
        shippingSelected: false
      },
      activePage: 1,
      order: {
        template: '',
        quantity: 1,
        orderNumber: null,
        shippingSelectedId: null,
        shippingSelectedName: null,
        lineItemId: null,
        price: {
          total: null
        },
        shipping: {
          shipDate: null,
          options: [
            {
              id: null,
              deliveryName: null,
              deliveryCharge: null,
              deliveryCommitmentDate: {
                time: null,
                date: null
              }
            }
          ]
        },
        // recipient: Client or Office
        recipient: 'client',
        clientInfo: {
          firstName: (contactPrimary && contactPrimary.firstName) || '',
          lastName: (contactPrimary && contactPrimary.lastName) || '',
          street: (contactPrimary && contactPrimary.street) || '',
          addressLine2: '',
          city: (contactPrimary && contactPrimary.city) || '',
          // Some pre filled data contains full state spellings
          // sending this will cause Mimeo service to explode
          state: '',
          postalCode: (contactPrimary && contactPrimary.zip) || '',
          phone: (contactPrimary && contactPrimary.work) || ''
        },
        advisorInfo: {
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          street: user.street || '',
          addressLine2: '',
          city: user.city || '',
          // Some pre filled data contains full state spellings
          // sending this will cause Mimeo service to explode
          state: '',
          postalCode: user.zip || '',
          phone: user.work || '',
          email: user.email || ''
        }
      }
    }
  }

  public missingFields = () => {
    const { error } = this.state
    const { advisorInfo } = this.state.order
    const { clientInfo } = this.state.order
    if (
      !advisorInfo.firstName ||
      !advisorInfo.lastName ||
      !advisorInfo.street ||
      !advisorInfo.city ||
      !advisorInfo.state ||
      !advisorInfo.postalCode ||
      !advisorInfo.phone ||
      !clientInfo.firstName ||
      !clientInfo.lastName ||
      !clientInfo.street ||
      !clientInfo.city ||
      !clientInfo.state ||
      !clientInfo.postalCode ||
      !clientInfo.phone
    ) {
      this.setState({ error: { ...error, missingField: true } })
    } else {
      this.setState({ error: { ...error, missingField: false } })
    }
  }

  public nextPage = () => {
    const currentPage = this.state.activePage
    const { error } = this.state
    if (error.missingField === false) {
      this.setState({
        activePage: currentPage + 1
      })
    }
  }

  public prevPage = () => {
    const currentPage = this.state.activePage
    this.setState({
      activePage: currentPage - 1
    })
  }

  public availableDeliveryOptionsMap = (options: any) => {
    const deliveryOpts: any = []
    const timeFormatter = (data: string) => {
      // Remove Date and extra chars
      const time = data.substring(data.lastIndexOf('T') + 1)
      // Format 24 time to 12 hour time
      return moment(time, ['HH']).format('h:mm A')
    }
    const dateFormatter = (data: string) => {
      // Remove Time and extra chars
      return data.substring(0, data.indexOf('T'))
    }
    options.forEach((option: any) => {
      deliveryOpts.push({
        id: option.ShippingMethodDetail.Id,
        deliveryCharge: dollarFormat(option.DeliveryCharge),
        deliveryCommitmentDate: {
          time: timeFormatter(option.DeliveryCommitmentDate),
          date: dateFormat(dateFormatter(option.DeliveryCommitmentDate))
        },
        deliveryName: option.ShippingMethodDetail.Name
      })
    })
    return deliveryOpts
  }

  getStreet(infoObj: { street: string; addressLine2: string }) {
    const line2 = infoObj.addressLine2 ? ` ${infoObj.addressLine2}` : ''
    return infoObj.street + line2
  }

  public createOrder = async () => {
    const { guidebook, householdFinId } = this.props
    const { order, error } = this.state
    const { shipping } = this.state.order

    const isClientRecipient = order.recipient === 'client'
    const [senderInfo, recipientInfo] = isClientRecipient
      ? [order.advisorInfo, order.clientInfo]
      : [order.clientInfo, order.advisorInfo]

    await api()
      .post(
        `households/${householdFinId}/guidebooks/${guidebook.id}/print/order`,
        {
          quantity: order.quantity,
          sender: {
            firstName: senderInfo.firstName,
            lastName: senderInfo.lastName,
            street: this.getStreet(senderInfo),
            city: senderInfo.city,
            state: senderInfo.state.toUpperCase(),
            country: 'US',
            postalCode: senderInfo.postalCode,
            email: order.advisorInfo.email || '',
            phone: senderInfo.phone
          },
          recipient: {
            firstName: recipientInfo.firstName,
            lastName: recipientInfo.lastName,
            street: this.getStreet(recipientInfo),
            city: recipientInfo.city,
            state: recipientInfo.state.toUpperCase(),
            country: 'US',
            postalCode: recipientInfo.postalCode,
            telephone: recipientInfo.phone
          }
        },
        { timeout: 600000 }
      )
      .then((response: any) => {
        const body = response.data
        const status = body.meta.status

        if (status >= 200 && status < 300) {
          const shippingData = body.data.shippingOptions
          const quoteData = body.data.quote
          const orderData = body.data.order

          const bindingCovers1 =
            quoteData.LineItemDetails[0].OfferingDetails.BindingAndCovers[1]

          const templateName =
            (bindingCovers1 && bindingCovers1.OfferingName) || `Perfect Bound`

          this.setState({
            error: { ...error, serverError: false },
            order: {
              ...order,
              lineItemId: orderData.LineItems[0].StoreItemReference.Id,
              template: templateName,
              shipping: {
                ...shipping,
                shipDate: shippingData.ShipDate,
                options: this.availableDeliveryOptionsMap(
                  shippingData.AvailableDeliveryOptionsPerRecipient[0]
                    .DeliveryOptionQuotes
                )
              }
            }
          })
        } else {
          this.setState({
            activePage: 0,
            error: { ...error, serverError: true }
          })
        }
      })
  }

  public submitOrder = async () => {
    const { guidebook, householdFinId } = this.props
    const { order, error } = this.state
    const { price } = this.state.order
    const currentPage = this.state.activePage

    const isClientRecipient = order.recipient === 'client'
    const infoObj = isClientRecipient ? order.clientInfo : order.advisorInfo

    await api()
      .post(
        `households/${householdFinId}/guidebooks/${guidebook.id}/print/submit`,
        {
          order: {
            LineItems: [
              {
                Name: 'Guidebook',
                Quantity: order.quantity,
                StoreItemReference: {
                  Id: order.lineItemId
                }
              }
            ],
            Recipients: [
              {
                Address: {
                  FirstName: infoObj.firstName,
                  LastName: infoObj.lastName,
                  Street: this.getStreet(infoObj),
                  ApartmentOrSuite: infoObj.addressLine2,
                  City: infoObj.city,
                  StateOrProvince: infoObj.state.toUpperCase(),
                  Country: 'US',
                  PostalCode: infoObj.postalCode,
                  TelephoneNumber: infoObj.phone,
                  Email: isClientRecipient ? '' : order.advisorInfo.email,
                  IsResidential: false,
                  Name: '',
                  CompanyName: ''
                },
                ShippingMethodId: order.shippingSelectedId
              }
            ],
            PaymentMethod: { PaymentMethodType: 1 },
            ShipFromInfo: {
              FirstName: infoObj.firstName,
              LastName: infoObj.lastName,
              Email: isClientRecipient ? order.advisorInfo.email : '',
              CompanyName: '',
              TelephoneNumber: infoObj.phone
            }
          }
        },
        { timeout: 600000 }
      )
      .then((response: any) => {
        const body = response.data
        const status = body.meta.status
        if (status >= 200 && status < 300) {
          this.setState({
            activePage: currentPage + 1,
            error: { ...error, serverError: false },
            order: {
              ...order,
              price: {
                ...price,
                total: body.data.total
              },
              orderNumber: body.data.friendlyId
            }
          })
        } else {
          this.setState({
            activePage: 0,
            error: { ...error, serverError: true }
          })
        }
      })
  }

  public updatedQuantity = (e: any) => {
    const order = this.state.order
    const error = this.state.error
    const value = e.target.value
    // ^0*(?:[1-9][0-9]?|100)$ -- values only 1-100
    if (value.match(/^0*(?:[1-9][0-9]?|100)$/) || value === '') {
      this.setState({
        order: {
          ...order,
          quantity: Number(value)
        },
        error: {
          ...error,
          quantityError: false
        }
      })
    } else {
      this.setState({
        error: {
          ...error,
          quantityError: true
        }
      })
    }
  }

  public selectShippingMethodLiterally = (obj: any) => {
    const error = this.state.error
    const order = this.state.order
    if (obj && obj.value) {
      this.setState({
        error: { ...error, shippingSelected: true },
        order: {
          ...order,
          shippingSelectedId: obj.value,
          shippingSelectedName: obj.label
        }
      })
    } else {
      this.setState({
        error: { ...error, shippingSelected: false },
        order: {
          ...order,
          shippingSelectedId: null
        }
      })
    }
  }

  public selectRecipientType = (obj: any) => {
    const order = this.state.order
    const clientInfo = this.state.order.clientInfo
    if (obj && obj.value === 'client') {
      this.setState({
        order: { ...order, recipient: 'client' }
      })
    } else {
      this.setState({
        order: {
          ...order,
          recipient: 'office',
          // Clear Sender fields (Client can never be a sender)
          clientInfo: {
            ...clientInfo,
            firstName: '',
            lastName: '',
            street: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            phone: ''
          }
        }
      })
    }
  }

  public updateClientInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const clientInfo = this.state.order.clientInfo
    const order = this.state.order

    const target = e.currentTarget
    const value = target.value
    const nameField = target.name

    if (value || value === '') {
      this.setState({
        order: {
          ...order,
          clientInfo: { ...clientInfo, [nameField]: value }
        }
      })
    }
  }

  public updateAdvisorInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const advisorInfo = this.state.order.advisorInfo
    const order = this.state.order

    const target = e.currentTarget
    const value = target.value
    const nameField = target.name

    if (value || value === '') {
      this.setState({
        order: {
          ...order,
          advisorInfo: {
            ...advisorInfo,
            [nameField]: value
          }
        }
      })
    }
  }

  public render() {
    const { order, error, activePage } = this.state
    const {
      recipient,
      clientInfo,
      advisorInfo,
      template,
      shipping,
      orderNumber,
      price,
      shippingSelectedName
    } = this.state.order

    const pageInfo = () => {
      return (
        <Fragment>
          <ProductInfo
            quantity={order.quantity}
            updatedQuantity={this.updatedQuantity}
            quantityError={error.quantityError}
          />
          <RecipientInfo
            clientRecipient={recipient === 'client'}
            recipientInfo={recipient === 'client' ? clientInfo : advisorInfo}
            selectRecipientType={this.selectRecipientType}
            updateClientInfo={this.updateClientInfo}
            updateAdvisorInfo={this.updateAdvisorInfo}
            missingFields={this.missingFields}
          />
          <SenderInfo
            clientRecipient={recipient === 'client'}
            senderInfo={recipient !== 'client' ? clientInfo : advisorInfo}
            updateClientInfo={this.updateClientInfo}
            updateAdvisorInfo={this.updateAdvisorInfo}
            missingFields={this.missingFields}
          />
          <MimeoButtons
            createOrder={this.createOrder}
            error={error}
            nextPage={this.nextPage}
            missingFields={this.missingFields}
          />
        </Fragment>
      )
    }

    const pageShipping = () => {
      return (
        <Fragment>
          <Shipping
            quantity={order.quantity}
            updatedQuantity={this.updatedQuantity}
            quantityError={error.quantityError}
            recipientInfo={recipient === 'client' ? clientInfo : advisorInfo}
            template={template}
            shipping={shipping}
            selectShippingMethodLiterally={this.selectShippingMethodLiterally}
          />
          <MimeoButtons
            error={error}
            prevPage={this.prevPage}
            submitOrder={this.submitOrder}
          />
        </Fragment>
      )
    }

    const successfulOrder = () => {
      return (
        <Fragment>
          <SuccessfulOrder
            recipientInfo={recipient === 'client' ? clientInfo : advisorInfo}
            quantity={order.quantity}
            template={template}
            orderNumber={orderNumber}
            totalPrice={dollarFormat(price.total)}
            shippingSelectedName={shippingSelectedName}
          />
          <MimeoButtons closeModal={this.props.toggleModal} />
        </Fragment>
      )
    }

    const errorPage = () => {
      return (
        <Fragment>
          <div className='mimeo__section-w'>
            <Tile
              iconPng={null}
              leftHeader={'Error'}
              style={{
                backgroundColor: '#FAFAFA',
                paddingBottom: '20px'
              }}>
              <div className='mimeo__section'>
                An error has occurred, please review the submitted information
                or contact support.
              </div>
            </Tile>
          </div>
        </Fragment>
      )
    }

    return (
      <Modal
        title={'Mimeo'}
        iconPng={GuidebookIcon}
        closeModal={this.props.toggleModal}
        size={'L'}
        centerModal={true}
        contentStyle={{ height: '700px', overflow: 'scroll' }}>
        <div className='mimeo'>
          {activePage === 1 ? pageInfo() : null}
          {activePage === 2 ? pageShipping() : null}
          {activePage === 3 ? successfulOrder() : null}
          {error.serverError ? errorPage() : null}
        </div>
      </Modal>
    )
  }
}

export default connect()(Mimeo)
