import * as actions from '../actions/marketplaceOffers'
import {
  marketplaceOfferRequestInterface,
  marketplaceOfferObj
} from '../objects/marketplaceOffers'

export interface MarketplaceOffersActionsState {
  [key: string]: marketplaceOfferRequestInterface
}

const initState: MarketplaceOffersActionsState = {}

const mapMarketplaceOffers = (
  householdFinId: string,
  offers: marketplaceOfferObj[]
): MarketplaceOffersActionsState => {
  const offersList: marketplaceOfferRequestInterface = {}
  offers.forEach((offer) => {
    offersList[offer.id] = offer
  })
  const obj = {}
  obj[householdFinId] = offersList
  return obj
}

const MarketplaceOffers = (
  state = initState,
  action: any
): MarketplaceOffersActionsState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.GET_MARKETPLACE_OFFERS}_FULFILLED`:
      const offers = mapMarketplaceOffers(
        action.payload.householdFinId,
        action.payload
      )
      return { ...state, ...offers }
    case `${actions.UPDATE_MARKETPLACE_OFFERS}_FULFILLED`:
      newState[action.payload.householdFinId][action.payload.id] = {
        ...action.payload
      }
      return newState
    default:
      return state
  }
}

export default MarketplaceOffers
