import { TaskObjState } from '../../../objects/tasks'

export const applyTaskTypeFilterHelper = (
  includeNativeTask: boolean,
  includeLinkedTask: boolean,
  tasks?: TaskObjState
) => {
  if (includeLinkedTask && includeNativeTask) {
    return tasks
  }
  if (includeLinkedTask && !includeNativeTask) {
    const tasksFilteredByType = {}

    tasks &&
      Object.keys(tasks).forEach((task: string) => {
        tasks[task].assigneeType === 'user' &&
          (tasksFilteredByType[task] = tasks[task])
      })

    return tasksFilteredByType
  }
  if (!includeLinkedTask && includeNativeTask) {
    const tasksFilteredByType = {}

    tasks &&
      Object.keys(tasks).forEach((task: string) => {
        tasks[task].assigneeType === 'client' &&
          (tasksFilteredByType[task] = tasks[task])
      })

    return tasksFilteredByType
  }

  return {}
}
