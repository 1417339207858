import React from 'react'
import InnerContainer from '../../components/layout/innerContainer'
import { ReactComponent as MessagesIcon } from '../../assets/images/icons/messages.svg'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import MessagePane from '../../components/messages/messagePane'
import MessageBody from '../../components/messages/messageBody'
import { GlobalState } from '../../../reducers'
import { Dispatch, connect } from 'react-redux'
import { UserObj, UsersObj } from '../../../objects/user'
import { history } from '../../../store'
import GQLClient from '../../helpers/gqlClient'
import { GetMessageThreadQuery } from '../../helpers/queries'
import { MessageThreadInterface } from './messageThreadList'
import moment from 'moment'
import { ContactsInterface } from '../../../objects/contact'
import { withRouter } from 'react-router'

interface MessagesProps {
  householdFinId: string
  userId: string
  threadId?: string
  user: UserObj
  users: UsersObj
  contacts: ContactsInterface
  dispatch: Dispatch<GlobalState>
}

interface MessagesState {
  activeThreadId: string
  error: boolean
}

class Messages extends React.Component<MessagesProps, MessagesState> {
  constructor(props: MessagesProps) {
    super(props)
    this.state = {
      activeThreadId: props.threadId ? props.threadId : null,
      error: false
    }
  }

  public componentDidMount() {
    const { householdFinId } = this.props
    if (!this.state.activeThreadId) {
      GQLClient.getClient()
        ?.query({
          query: GetMessageThreadQuery,
          variables: { householdFinId }
        })
        .then((res: any) => {
          // cache is readonly since apollo v3 and can't be directry sorted
          const sortedMessages = res?.data?.allMessages?.nodes?.toSorted(
            (a: MessageThreadInterface, b: MessageThreadInterface) => {
              const aDate =
                a.messagesByParent.nodes.length > 0
                  ? a.messagesByParent.nodes[0]?.createdDate
                  : a.createdDate
              const bDate =
                b.messagesByParent.nodes.length > 0
                  ? b.messagesByParent.nodes[0]?.createdDate
                  : b.createdDate
              return moment(bDate).diff(moment(aDate))
            }
          )
          const latestThread = sortedMessages?.[0]
          this.setMessageThread(latestThread?.id)
        })
        .catch((err: any) => {
          this.setState({ error: true })
        })
    }
  }

  public setMessageThread = (threadId: string) => {
    if (!threadId) return
    const { householdFinId } = this.props
    this.setState({ activeThreadId: threadId })
    history.push(`/households/${householdFinId}/messages/${threadId}`)
  }

  public render() {
    const { activeThreadId, error } = this.state
    const {
      dispatch,
      householdFinId,
      userId,
      user,
      users,
      contacts
    } = this.props
    return (
      <InnerContainer>
        <ContentHeader Icon={MessagesIcon} title='Messages' />
        <Tile>
          <div className='messages-w'>
            <MessagePane
              selectMessageThread={this.setMessageThread}
              householdFinId={householdFinId}
              userId={userId}
              activeThreadId={activeThreadId}
            />
            <MessageBody
              dispatch={dispatch}
              threadId={activeThreadId}
              householdFinId={householdFinId}
              contacts={contacts}
              users={users}
              userId={userId}
              userEmail={user ? user.email : ''}
              error={error}
            />
          </div>
        </Tile>
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    threadId: match.params.threadId,
    userId: store.user.userId,
    user: store.user.userId ? store.user.users[store.user.userId] : null,
    users: store.user.users,
    contacts: store.contact[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(Messages))
