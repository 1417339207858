import React from 'react'
import { ReactComponent as GlobeIcon } from '../../assets/images/icons/ic_globe.svg'
import Tile from '../../components/layout/tile'
import GlobalImage from '../../assets/images/global.png'
import DomesticImage from '../../assets/images/domestic.png'
import { InvestmentViewfinderGeographicPriorityObj } from '../../../objects/investmentViewfinder'

interface GeographicResultsProps {
  geographicPriority: InvestmentViewfinderGeographicPriorityObj
  global: number
  domestic: number
}

class GeographicResults extends React.Component<GeographicResultsProps> {
  public render() {
    const { global, domestic, geographicPriority } = this.props
    const source = global > domestic ? GlobalImage : DomesticImage
    return (
      <Tile
        icon={GlobeIcon}
        leftHeader='YOUR GEOGRAPHIC PREFERENCE'
        headerBorder={true}
        headerStyle={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: '#FAFAFA',
          justifyContent: 'left'
        }}
        style={{ marginRight: 8 }}>
        <div className='results__geography'>
          <div className='results__geography-text'>
            Preference - {geographicPriority.name}
          </div>
          <img
            className='results__geography-image'
            src={source}
            role='img'
            alt={geographicPriority.name}
          />
          <div className='results__geography-description'>
            {geographicPriority.definition}
          </div>
        </div>
      </Tile>
    )
  }
}

export default GeographicResults
