import { TaskObjState } from '../../../objects/tasks'

export const applyAssigneeFilterHelper = (
  selectedAssignees: string[],
  tasks?: TaskObjState
) => {
  if (!selectedAssignees?.length) {
    return tasks
  }
  const tasksFilteredByAssignee = {}
  tasks &&
    Object.keys(tasks).forEach((task) => {
      Object.keys(selectedAssignees).forEach((e) => {
        if (selectedAssignees[e] === tasks[task].assigneeNames) {
          tasksFilteredByAssignee[task] = tasks[task]
        }
      })
    })

  return tasksFilteredByAssignee
}
