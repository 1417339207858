import React from 'react'
import { ReactComponent as DocumentVaultIcon } from '../../assets/images/icons/svg/ic_vault.svg'
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import TableHeader from '../../components/layout/tableHeader'
import Folder from './folder'
import { FolderInterface } from '../../../objects/folder'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import CreateFolder from './createFolder'
import * as actions from '../../../actions/documentVault'
import { withRouter } from 'react-router'
import InnerContainer from '../../components/layout/innerContainer'
import Search from '../../components/search'
import NullDocVault from './nullView'
import { isObjEmpty } from '../../helpers'
import DeleteModal from './deleteModal'
import { addToast } from '../../../actions/toasts'
import EditModal from './editModal'
import QuickActionLinks from '../../components/quickActionLinks'
import trashIcon from '../../assets/images/icons/png/ic_delete_blue.png'

export interface DocumentVaultProps {
  folders: FolderInterface
  newFolders?: any
  match: any
  householdFinId: string
  dispatch: Dispatch<GlobalState>
}

interface DocumentVaultState {
  filteredFolders: FolderInterface
  showDeleteModal: boolean
  showEditModal: boolean
  currentFolderId: string
  currentFolderName: string
}

export class DocumentVault extends React.Component<
  DocumentVaultProps,
  DocumentVaultState
> {
  constructor(props: DocumentVaultProps) {
    super(props)
    this.state = {
      filteredFolders: this.props.folders,
      showDeleteModal: false,
      showEditModal: false,
      currentFolderId: '',
      currentFolderName: ''
    }
  }
  public componentDidMount() {
    this.props.dispatch(actions.getFolders(this.props.householdFinId))
  }
  public componentWillReceiveProps(nextProps: DocumentVaultProps) {
    if (nextProps.folders !== this.props.folders) {
      this.setState({ filteredFolders: nextProps.folders })
    }
  }
  public componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(actions.clearNewFolders())
  }
  public toggleDeleteModal = (id: string) => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      currentFolderId: id
    })
  }
  public deleteFn = async (id: string, archived: boolean) => {
    const { dispatch, householdFinId } = this.props
    await dispatch(actions.deleteFolder(householdFinId, id, archived))
    dispatch(actions.getFolder(householdFinId, id))
    dispatch(
      addToast({
        icon: FolderIcon,
        message: 'Folder deleted.',
        backgroundColor: '#D9F2B6'
      })
    )
    this.toggleDeleteModal('')
  }
  public toggleEditModal = (id: string, name: string) => {
    this.setState({
      showEditModal: !this.state.showEditModal,
      currentFolderId: id,
      currentFolderName: name
    })
  }
  public editFn = (id: string, name: string) => {
    this.props.dispatch(actions.editFolder(this.props.householdFinId, id, name))
    this.props.dispatch(
      addToast({
        icon: FolderIcon,
        message: 'Folder name updated.',
        backgroundColor: '#D9F2B6'
      })
    )
    this.toggleEditModal('', '')
  }

  public folders = () => {
    const { match, householdFinId, newFolders } = this.props
    const { filteredFolders } = this.state
    if (filteredFolders && Object.keys(filteredFolders).length > 0) {
      const noneArchivedFolders = Object.keys(filteredFolders).filter((key) => {
        if (
          filteredFolders[key].name === 'Guidebooks' ||
          filteredFolders[key].name === 'Portfolio Proposals'
        ) {
          const activeFiles =
            filteredFolders[key].files?.length &&
            filteredFolders[key].files.some((file) => !file.archived)
          return activeFiles || !filteredFolders[key].archived
        } else return !filteredFolders[key].archived
      })
      return noneArchivedFolders.map((folderFinId, index) => {
        const folder = filteredFolders[folderFinId]
        const isNew = newFolders && newFolders[folder.id]
        return (
          <Folder
            key={index}
            householdFinId={householdFinId}
            name={folder.name}
            lastUpdated={folder.lastUpdated}
            files={folder.files}
            readOnly={folder.readOnly}
            id={folder.id}
            url={match.url}
            deleteFn={this.toggleDeleteModal}
            editFn={this.toggleEditModal}
            isNew={isNew}
          />
        )
      })
    } else return this.nullFolders()
  }

  public nullFolders = () => {
    return (
      <div>
        <NullDocVault text='There are no folders here yet.' section='folder' />
      </div>
    )
  }

  public filterFolder = (e: any) => {
    const filter = e.target.value.trim().toLowerCase()
    const { folders } = this.props
    const filteredFolderArray = Object.keys(folders)
      .map((key) => {
        const folder = folders[key]
        const doesFolderContainFilter =
          !filter ||
          folder.name.toLowerCase().includes(filter) ||
          folder.files.filter((file) =>
            file.name.toLowerCase().includes(filter)
          ).length > 0
        return doesFolderContainFilter ? folder : null
      })
      .filter((folder) => folder)

    const filteredFolderObj = {}
    filteredFolderArray.forEach((folder) => {
      if (folder && folder.id && !folder.archived) {
        filteredFolderObj[folder.id] = folder
      }
    })
    this.setState({ filteredFolders: filteredFolderObj })
  }

  public render() {
    const { householdFinId, folders, match } = this.props
    const {
      showDeleteModal,
      showEditModal,
      currentFolderId,
      currentFolderName
    } = this.state
    if (!folders || isObjEmpty(folders)) {
      return (
        <InnerContainer>
          <ContentHeader Icon={DocumentVaultIcon} title='Document Vault' />
          <Tile
            leftHeader='FOLDERS'
            headerBorder={true}
            headerStyle={{ backgroundColor: '#FAFAFA' }}>
            <div className='vault__actions'>
              {/* Folder Search Filter */}
              <Search onChange={this.filterFolder} />
              {/* Create Folder */}
              <CreateFolder householdFinId={householdFinId} />
            </div>
            <div>
              {/* Folder Header */}
              <TableHeader>
                <div className='vault__name'>NAME</div>
                <div className='vault__files'>FILES</div>
                <div className='vault__folder-actions'>ACTIONS</div>
              </TableHeader>
              {/* List of Folders */}
              {this.nullFolders()}
            </div>
          </Tile>
        </InnerContainer>
      )
    } else {
      return (
        <InnerContainer>
          <ContentHeader Icon={DocumentVaultIcon} title='Document Vault' />
          <Tile
            leftHeader='FOLDERS'
            headerBorder={true}
            headerStyle={{ backgroundColor: '#FAFAFA' }}>
            <div className='vault__actions'>
              <Search onChange={this.filterFolder} />
              <div className='vault__actions-btns'>
                <div className='vault__actions-trash-btn'>
                  <QuickActionLinks
                    customQuickAction={[
                      {
                        text: 'Trash',
                        icon: trashIcon,
                        to: `${match.url}/trash`
                      }
                    ]}
                  />
                </div>
                <CreateFolder householdFinId={householdFinId} />
              </div>
            </div>
            <div>
              {/* Folder Header */}
              <TableHeader>
                <div className='vault__name'>NAME</div>
                <div className='vault__files'>FILES</div>
                <div className='vault__folder-actions'>ACTIONS</div>
              </TableHeader>
              {/* List of Folders */}
              {this.folders()}
            </div>
          </Tile>
          {showDeleteModal ? (
            <DeleteModal
              type='folder'
              id={currentFolderId}
              deleteFn={this.deleteFn}
              closeModal={this.toggleDeleteModal}
            />
          ) : null}
          {showEditModal ? (
            <EditModal
              type='folder'
              id={currentFolderId}
              name={currentFolderName}
              editFn={this.editFn}
              closeModal={this.toggleEditModal}
            />
          ) : null}
        </InnerContainer>
      )
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    folders: store.documentVault.folders[match.params.householdFinId],
    newFolders: store.documentVault.newFolders,
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(DocumentVault))
