import React, { Component } from 'react'

export interface StrategyToolTipProps {
  imgSrc?: string
  imgCss?: string
}

interface StrategyToolTipState {
  isMouseOver: boolean
}

export class StrategyToolTip extends Component<
  StrategyToolTipProps,
  StrategyToolTipState
> {
  constructor(props: StrategyToolTipProps) {
    super(props)
    this.state = {
      isMouseOver: false
    }
  }

  public mouseEvent = (e: React.MouseEvent<HTMLImageElement>) => {
    const isMouseOver: boolean = e.type === 'mouseover'
    this.setState({
      isMouseOver
    })
  }

  public render = () => {
    const { imgSrc = null, imgCss = null, children = null } = this.props
    const { isMouseOver } = this.state

    return (
      <span onMouseLeave={this.mouseEvent} onMouseOver={this.mouseEvent}>
        {imgSrc && (
          <img
            src={imgSrc}
            alt=''
            role='presentation'
            style={{
              marginTop: '-3px',
              paddingLeft: '4px',
              verticalAlign: 'middle',
              cursor: 'pointer'
            }}
            className={imgCss}
          />
        )}
        {isMouseOver ? (
          <span className='strategy-tooltip'>
            <div className='strategy-tooltip-bubble strategy-tooltip-position--top'>
              <div className='strategy-tooltip-body'> {children} </div>
            </div>
          </span>
        ) : null}
      </span>
    )
  }
}

export default StrategyToolTip
