import { HouseholdsState } from '../../../reducers/households'

export const applyMoneyMindFilterHelper = (
  selectedMoneyMinds: string[],
  households: HouseholdsState
) => {
  if (!selectedMoneyMinds?.length) {
    return households
  }
  const householdsArray = Object.keys(households)
  const filteredHouseholds = {}
  householdsArray.forEach((key) => {
    const primaryMM = households[key].primaryMoneymind
    const secondaryMM = households[key].secondaryMoneymind
    selectedMoneyMinds.forEach((option: string) => {
      // Check if any primary money mind has a value
      if (
        primaryMM.commitment !== 'null' ||
        primaryMM.protection !== 'null' ||
        primaryMM.happiness !== 'null'
      ) {
        // Assign the highest or blended money mind
        if (
          primaryMM[option] >= primaryMM.commitment &&
          primaryMM[option] >= primaryMM.happiness &&
          primaryMM[option] >= primaryMM.protection
        ) {
          filteredHouseholds[key] = households[key]
        }
      }
      // Check to see if any secondaries have a value
      if (
        secondaryMM.commitment !== 'null' ||
        secondaryMM.protection !== 'null' ||
        secondaryMM.happiness !== 'null'
      ) {
        // Assign the highest or blended money mind
        if (
          secondaryMM[option] >= secondaryMM.commitment &&
          secondaryMM[option] >= secondaryMM.happiness &&
          secondaryMM[option] >= secondaryMM.protection
        ) {
          filteredHouseholds[key] = households[key]
        }
      }
    })
  })
  return filteredHouseholds
}
