import React, { ChangeEvent } from 'react'
import Input from '../../../components/Input'
import Button from '../../../components/button'
import * as actions from '../../../../actions/user'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import Modal from '../../../components/layout/modal'
import WealthboxIcon from '../../../assets/images/logos/wealthbox-logo--small.png'

interface WealthboxAuthProps {
  dispatch: Dispatch<GlobalState>
  closeModal?(): void
}

interface WealthboxAuthState {
  apiAccessToken: string
}

class WealthboxAuth extends React.Component<
  WealthboxAuthProps,
  WealthboxAuthState
> {
  constructor(props: WealthboxAuthProps) {
    super(props)
    this.state = {
      apiAccessToken: null
    }
  }
  public submit = async () => {
    const { apiAccessToken } = this.state
    await this.props.dispatch(
      actions.saveWealthboxApiAccessToken(apiAccessToken)
    )
    this.props.dispatch(actions.previewHouseholdSync())
    this.props.closeModal()
  }
  public updateApiAccessToken = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ apiAccessToken: e.currentTarget.value })
  }
  public checkInput = (): boolean => {
    const { apiAccessToken } = this.state
    return !apiAccessToken
  }
  public render() {
    const { apiAccessToken } = this.state
    const { closeModal } = this.props
    return (
      <Modal
        title='Link Wealthbox'
        iconPng={WealthboxIcon}
        closeModal={closeModal}>
        <div className='wealthbox-login-w'>
          <Input
            inputType='text'
            title='API Access Token'
            name='apiAccessToken'
            content={apiAccessToken}
            controlFunc={this.updateApiAccessToken}
            mandatory={true}
          />
          <div className='modal__buttons modal__buttons--w modal__buttons--center'>
            <Button
              onClick={this.submit}
              primary={true}
              disabled={this.checkInput()}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect()(WealthboxAuth)
