import React, { useState, useEffect } from 'react'
import Tile from '../../components/layout/tile'
import InnerContainer from '../../components/layout/innerContainer'

import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'

import SettingsContactInfo from './settingsContactInfo'
import { SettingsHouseholdInfo } from './settingsHouseholdInfo'
import { SettingsBilling } from './settingsBilling'
import SettingsOptionalFeatures from './settingsOptionalFeatures'

import { ContactsInterface } from '../../../objects/contact'

import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg'
import { HouseholdInstitution, HouseholdObj } from '../../../objects/household'

enum ActiveTab {
  primary,
  secondary,
  household,
  billing,
  optionality
}

interface ClientSettingsProps {
  dispatch?: Dispatch<GlobalState>
  match: any
  contacts: ContactsInterface
  householdId: string
  household: HouseholdObj
  institution: HouseholdInstitution
  location?: any
}

const cSettingsMenuItemCls = 'c-settings__menu-item'

// Accept any # of args
const classNames = (...args: any[]) => {
  const arr: string[] = []
  // Check if currently iterated arg is a string
  const isString = (x: string | 'object'): x is string => {
    return typeof x === 'string'
  }
  args.forEach((arg) => {
    // if the arg is a string, push to array
    if (isString(arg) && arg.length > 0) {
      arr.push(arg)
    }
    // check if arg is an object that is not empty, add its key to the array if its value is true
    if (
      arg &&
      typeof arg === 'object' &&
      !Array.isArray(arg) &&
      Object.keys(arg).length > 0
    ) {
      arr.push(
        Object.entries(arg)
          .filter(([, bool]: [string, unknown]) => bool)
          .map((e) => e[0])[0]
      )
    }
  })
  // convert array to string
  const returnString = arr.join(',')
  // replace returnString commas with spaces
  return returnString.replace(/,/g, ' ')
}

const getMenuItemClass = (active: boolean) =>
  classNames(cSettingsMenuItemCls, {
    [`${cSettingsMenuItemCls}--active`]: active
  })

const ClientSettings = (props: ClientSettingsProps) => {
  const {
    contacts,
    location,
    institution,
    household,
    householdId,
    dispatch
  } = props

  const [activeTab, setActiveTab] = useState(ActiveTab.primary)

  useEffect(() => {
    if (location?.state?.fromDisabledLink) {
      setActiveTab(ActiveTab.optionality)
    }
    if (location?.state?.contact) {
      if (location.state.contact === 'primary') {
        setActiveTab(ActiveTab.primary)
      } else {
        setActiveTab(ActiveTab.secondary)
      }
    }
  }, [location])

  const changeTabOnClick = (aT: ActiveTab) => () => {
    setActiveTab(aT)
  }

  const contactRow = (type: 'primary' | 'secondary') => (
    <li
      onClick={changeTabOnClick(ActiveTab[type])}
      className='c-settings__contact-list-item'>
      <span
        className={classNames('c-settings__contact-list-name', {
          'c-settings__contact-list-name--active': activeTab === ActiveTab[type]
        })}>
        {`${contacts[type].firstName} ${contacts[type].lastName}`}
      </span>
      <span
        className={`c-settings__contact-list-icon c-settings__contact-list-icon--${type}`}>
        {type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()}
      </span>
    </li>
  )

  const renderClientSettings = () => (
    <Tile
      style={{ marginTop: '25px' }}
      headerStyle={{ backgroundColor: '#FAFAFA' }}
      leftHeader={
        <span className='tile__header-label'>
          <SettingsIcon key='icon' /> CLIENT SETTINGS
        </span>
      }
      headerBorder={true}>
      <div className='c-settings-w'>
        <aside className='c-settings__menu'>
          <ul>
            <li
              className={classNames(`${cSettingsMenuItemCls}--list`, {
                [`${cSettingsMenuItemCls}--active`]:
                  activeTab === ActiveTab.primary ||
                  activeTab === ActiveTab.secondary
              })}>
              <span className='c-settings__menu-label'>
                Contact Information
              </span>
              <ul className='c-settings__contact-list'>
                {contacts?.primary && contactRow('primary')}
                {contacts?.secondary && contactRow('secondary')}
              </ul>
            </li>
            <li
              onClick={changeTabOnClick(ActiveTab.household)}
              className={getMenuItemClass(activeTab === ActiveTab.household)}>
              Household Information
            </li>
            {institution?.isFinlifeInstitution ? (
              <li
                onClick={changeTabOnClick(ActiveTab.billing)}
                className={getMenuItemClass(activeTab === ActiveTab.billing)}>
                Billing
              </li>
            ) : null}
            <li
              onClick={changeTabOnClick(ActiveTab.optionality)}
              className={getMenuItemClass(activeTab === ActiveTab.optionality)}>
              Optionality Settings
            </li>
          </ul>
        </aside>
        <main className='c-settings'>
          {activeTab === ActiveTab.primary && (
            <SettingsContactInfo
              householdId={householdId}
              contact={contacts?.primary || null}
              household={household}
              primary={true}
            />
          )}
          {activeTab === ActiveTab.secondary && (
            <SettingsContactInfo
              householdId={householdId}
              contact={contacts?.secondary || null}
              household={household}
              primaryContact={contacts?.primary || null}
            />
          )}
          {activeTab === ActiveTab.household && (
            <SettingsHouseholdInfo
              contacts={contacts}
              household={household}
              dispatch={dispatch}
              householdId={householdId}
              institution={institution}
            />
          )}
          {activeTab === ActiveTab.billing && (
            <SettingsBilling
              contacts={contacts}
              householdId={householdId}
              institution={institution}
            />
          )}
          {activeTab === ActiveTab.optionality && (
            <SettingsOptionalFeatures
              dispatch={dispatch}
              householdId={householdId}
              household={household}
            />
          )}
        </main>
      </div>
    </Tile>
  )

  return <InnerContainer>{contacts && renderClientSettings()}</InnerContainer>
}

const mapStateToProps = (store: GlobalState, { match, location }: any) => {
  const householdId = match.params.householdFinId
  return {
    institution: store.households[householdId].institution,
    householdId,
    household: store.households[householdId],
    contacts: store.contact[householdId],
    location
  }
}

export default withRouter(connect(mapStateToProps)(ClientSettings))
