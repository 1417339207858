import React, { Component, ChangeEvent } from 'react'
import PpbNavigation from './ppbNavigation'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { withRouter } from 'react-router'

import { PortfolioBuilderInterface } from '../../../../objects/portfolioBuilder'
import { PortfolioProposalObj } from '../../../../objects/portfolioProposal'
import {
  createPortfolioProposal,
  uploadPortfolioProposalPdf
} from '../../../../actions/portfolioProposal'
import { portfolioBuilderTitle } from '../../../helpers/portfolioProposal'
import { download } from '../../../helpers/download'
import { history } from '../../../../store'
import { isObjEmpty } from '../../../helpers'
import PortfolioProposalActions from './portfolioProposalActions'
import { getStrategies } from '../../../../actions/strategies'
import * as portfolioBuilderActions from '../../../../actions/portfolioBuilder'
import * as moneymindActions from '../../../../actions/moneymind'

import { FundedScoreObj } from '../../../../objects/financialGoal'
import {
  HonestConversationsExerciseInterface,
  HouseholdCardObj
} from '../../../../objects/honestConversations'
import { InvestmentViewfinderInterface } from '../../../../objects/investmentViewfinder'
import { ContactsInterface } from '../../../../objects/contact'
import { ClientAccountObjState } from '../../../../objects/clientAccount'
import { UserObj, UsersObj } from '../../../../objects/user'
import {
  HouseholdObj,
  HouseholdInstitution
} from '../../../../objects/household'
import { MoneyMindInterface } from '../../../../objects/moneymind'
import { StrategiesInterface } from '../../../../objects/strategies'

import CoverPage from './ppb-sections/cover'
import { Buffer } from 'buffer/'
import { getLogo } from '../../../helpers/guidebook'

import { OfficeTeamObj } from '../../../../objects/officeTeams'
import { getOfficeTeams } from '../../../../actions/officeTeams'
import { addTimedLoader } from '../../../../actions/timedLoader'

import Axios from 'axios'
import api from '../../../../v3/helpers/api'
import PdfRenderer from './pdfRenderer'
import {
  getIndividualHousehold,
  getOfficeTeamByHousehold
} from '../../../../actions/households'
import { coverPageInfoSetter } from '../../../helpers/householdOfficeTeams'
import { filterStrategyIds } from '../../../helpers/portfolioBuilder'
import { getActiveHouseholdCards } from '../../../helpers/honestConversations'
import Disclosure from '../../../components/layout/disclosure'

interface PortfolioProposalProps {
  portfolioBuilderList: PortfolioBuilderInterface
  fundedScores: FundedScoreObj[]
  honestConversations: HonestConversationsExerciseInterface
  viewfinderExercises: InvestmentViewfinderInterface
  strategies: StrategiesInterface
  portfolioBuilderId: string
  portfolioBuilderData: any
  householdFinId: string
  currentPortfolioProposalId: string
  match: any
  dispatch: Dispatch<GlobalState>
  user: UserObj
  users: UsersObj
  officeTeam: OfficeTeamObj
  moneyminds: MoneyMindInterface
  household: HouseholdObj
  contacts: ContactsInterface
  clientAccounts: ClientAccountObjState
  institution: HouseholdInstitution
}

interface PortfolioProposalState {
  pdfData: any
  coverPageInfo: {
    title: string
    clientName: string
    selectedAdvisor: UserObj
    defaultAdvisor: UserObj
    logo: string | null
    users: UsersObj
    teamAssigned: OfficeTeamObj
  }
  gbSections: {
    checkedCover: boolean
    // --- Intro
    // checkedFirmOverview: boolean
    // checkedFinLifeProcessOverview: boolean
    checkedDigitalOnlyFormat: boolean
    checkedInvestmentProcessOverview: boolean
    checkedFinLifeProcessOverview: boolean
    // checkedInvestmentProcessOverview: boolean
    // --- Client Profile
    checkedClientProfileCover: boolean
    checkedClientProfile: boolean
    checkedMoneyMind: boolean
    checkedHonestConversations: boolean
    checkedViewfinderResults: boolean
    checkedFundedScore: boolean
    //  -- Recommendations
    checkedRecommendationsCover: boolean
    checkedPortfolioSnapshot: boolean
    checkedPortfolioImplementation: boolean
    checkedInvestmentRecommendations: boolean
    // -- Recommendation Details
    checkedPortfolioDetails: boolean
    checkedPortfolioChangeIllustration: boolean
    checkedCostofServicesIllustration: boolean
    // -- Appendix
    checkedAppendixCover: boolean
    checkedFactSheet: boolean
    fetchedFactSheet: boolean
    checkedDisclosures: boolean
  }
  loadingFactSheet: boolean
  loadingStrategies: boolean
  isDownloadEnabled: boolean
  isVaultEnabled: boolean
  responsesDidPass: boolean
  // Null check
  allStrategyIds: string[]
  factSheetStrategyIds: string[]
  factSheetKey: string
  disableRecommendationDetail: boolean
}

interface SelectInterface {
  label: string
  value: UserObj
}

const portfolioPdfs = [
  'FinLifeProcessOverview',
  'InvestmentProcessOverview',
  'ClientProfile',
  'RecommendationsCover',
  'PortfolioSnapshot',
  'PortfolioImplementation',
  'InvestmentRecommendations',
  'PortfolioDetails',
  'AppendixCover',
  'FactSheet'
]

class PortfolioProposal extends Component<
  PortfolioProposalProps,
  PortfolioProposalState
> {
  constructor(props: PortfolioProposalProps) {
    super(props)
    const {
      contacts,
      portfolioBuilderList,
      portfolioBuilderId,
      users,
      moneyminds,
      household,
      honestConversations,
      viewfinderExercises,
      fundedScores
    } = this.props
    const primaryName = contacts?.primary ? contacts.primary.firstName : ''
    const secondaryName = contacts?.secondary
      ? contacts.secondary.firstName + ' '
      : ''
    const clientName =
      primaryName && secondaryName
        ? primaryName + ' and ' + secondaryName
        : primaryName || secondaryName

    this.state = {
      pdfData: [],
      coverPageInfo: {
        title: portfolioBuilderTitle(portfolioBuilderList, portfolioBuilderId),
        clientName,
        selectedAdvisor: null,
        defaultAdvisor: null,
        users,
        logo: '',
        teamAssigned: null
      },
      gbSections: {
        checkedCover: true,
        checkedDigitalOnlyFormat: true,
        // --- Intro
        // checkedFirmOverview: true,
        // checkedFinLifeProcessOverview: true,
        checkedInvestmentProcessOverview: true,
        checkedFinLifeProcessOverview: true,
        // checkedInvestmentProcessOverview: true,
        // --- Client Profile
        checkedClientProfileCover: true,
        checkedClientProfile: true,
        checkedMoneyMind: Boolean(
          moneyminds &&
            moneyminds?.primary &&
            (!isObjEmpty(moneyminds.primary) ||
              !isObjEmpty(moneyminds.secondary)) &&
            contacts.primary.activeYmmId
        ),
        checkedHonestConversations: this.activeHonestConversationResults(
          household,
          honestConversations
        ),
        checkedViewfinderResults: Boolean(
          viewfinderExercises && !isObjEmpty(viewfinderExercises.answers)
        ),
        checkedFundedScore: Boolean(fundedScores?.length),
        //  -- Recommendations
        checkedRecommendationsCover: true,
        checkedPortfolioSnapshot: true,
        checkedPortfolioImplementation: true,
        checkedInvestmentRecommendations: true,
        // -- Recommendation Details
        checkedPortfolioDetails: true,
        checkedPortfolioChangeIllustration: true,
        checkedCostofServicesIllustration: true,
        // -- Appendix
        checkedAppendixCover: false,
        checkedFactSheet: false,
        fetchedFactSheet: false,
        checkedDisclosures: true
      },
      loadingFactSheet: false,
      loadingStrategies: true,
      isDownloadEnabled: false,
      isVaultEnabled: false,
      responsesDidPass: false,
      // Null check
      allStrategyIds: [],
      factSheetStrategyIds: [],
      factSheetKey: '',
      disableRecommendationDetail: false
    }
  }

  public componentDidUpdate = (
    nextProps: PortfolioProposalProps,
    prevProps: PortfolioProposalState
  ) => {
    const { strategies } = this.props
    if (strategies !== nextProps.strategies) {
      this.setState({
        loadingStrategies: false
      })
    }

    if (
      !prevProps.coverPageInfo.teamAssigned &&
      nextProps.household.officeTeams &&
      Object.keys(nextProps.household.officeTeams).length > 0 &&
      nextProps.household.officeTeam
    ) {
      this.updateCoverPageState(prevProps.coverPageInfo)
    }
  }

  public activeHonestConversationResults = (
    household: HouseholdObj,
    honestConversations: HonestConversationsExerciseInterface
  ): boolean => {
    const combinedCards: HouseholdCardObj[] = getActiveHouseholdCards(
      household,
      honestConversations
    )
    return Boolean(combinedCards?.length > 0)
  }

  public updateCoverPageState = async (
    currentCoverPage: PortfolioProposalState['coverPageInfo']
  ) => {
    const { household } = this.props
    const coverPageInfo: PortfolioProposalState['coverPageInfo'] = currentCoverPage

    if (household?.institution) {
      coverPageInfo.logo = await getLogo(household.institution.photoUrl)
    }
    const coverPageInfoState = coverPageInfoSetter(coverPageInfo, household)

    this.setState({
      coverPageInfo: coverPageInfoState
    })
  }

  public coverPageDataUpdate = (inputField: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const coverPageInfo = { ...this.state.coverPageInfo }
    coverPageInfo[inputField] = event.currentTarget.value
    this.setState({ coverPageInfo })
  }

  public coverPageAdvisorUpdate = (selected: SelectInterface) => {
    const coverPageInfo = { ...this.state.coverPageInfo }
    coverPageInfo.selectedAdvisor = selected.value
    this.setState({ coverPageInfo })
  }

  // tslint:disable-next-line:max-func-body-length
  public toggleMe = (section: string) => async () => {
    const gbSections = { ...this.state.gbSections }
    gbSections[section] = !gbSections[section]

    // Re-generate Client Profile PDF if its sections are checked on or off
    if (
      section === 'checkedMoneyMind' ||
      section === 'checkedFundedScore' ||
      section === 'checkedHonestConversations' ||
      section === 'checkedViewfinderResults'
    ) {
      this.setState({ gbSections, responsesDidPass: false }, async () => {
        await this.sectionBuilder()
      })
    } else {
      this.setState({ gbSections })
    }

    // Toggle Cover PDFs on or off depending on checked state of sections
    if (
      gbSections.checkedClientProfile &&
      !gbSections.checkedMoneyMind &&
      !gbSections.checkedFundedScore &&
      !gbSections.checkedHonestConversations &&
      !gbSections.checkedViewfinderResults
    ) {
      gbSections['checkedClientProfile'] = false
      this.setState({
        gbSections: { ...gbSections }
      })
    }
    if (
      !gbSections.checkedClientProfile &&
      (gbSections.checkedMoneyMind ||
        gbSections.checkedFundedScore ||
        gbSections.checkedHonestConversations ||
        gbSections.checkedViewfinderResults)
    ) {
      gbSections['checkedClientProfile'] = true
      this.setState({
        gbSections: { ...gbSections }
      })
    }
    const isRecommendationsCoverSection =
      gbSections.checkedPortfolioSnapshot ||
      gbSections.checkedPortfolioDetails ||
      gbSections.checkedInvestmentRecommendations ||
      gbSections.checkedPortfolioImplementation

    if (
      gbSections.checkedRecommendationsCover &&
      !isRecommendationsCoverSection
    ) {
      gbSections['checkedRecommendationsCover'] = false
      this.setState({
        gbSections: { ...gbSections }
      })
    }
    if (
      !gbSections.checkedRecommendationsCover &&
      isRecommendationsCoverSection
    ) {
      gbSections['checkedRecommendationsCover'] = true
      this.setState({
        gbSections: { ...gbSections }
      })
    }

    const isAppendixCoverSection = gbSections.checkedFactSheet
    if (gbSections.checkedAppendixCover && !isAppendixCoverSection) {
      gbSections['checkedAppendixCover'] = false
      this.setState({
        gbSections: { ...gbSections }
      })
    }
    if (!gbSections.checkedAppendixCover && isAppendixCoverSection) {
      gbSections['checkedAppendixCover'] = true
      this.setState({
        gbSections: { ...gbSections }
      })
    }

    if (
      section === 'checkedFactSheet' &&
      !this.state.gbSections.fetchedFactSheet
    ) {
      gbSections['checkedFactSheet'] = true
      this.setState({
        gbSections: { ...gbSections }
      })

      const fetched = section.replace('checked', 'fetched')
      gbSections[fetched] = !gbSections[fetched]
      this.setState({ loadingFactSheet: false, gbSections })
    } else {
      this.setState({ gbSections })
    }
  }

  public getAndSaveS3PDF = async (name: string, url: string) => {
    const { householdFinId } = this.props

    if (!name || !url) {
      return console.log('name and url must be passed in as strings')
    }
    /*
     *  To get a PDF from S3 in a savable format,
     *  responseType must be set to 'arraybuffer'
     *  then it should be converted to a base64 string
     *
     *  When you write the file on the backend,
     *  be sure to include {encoding: 'base64'}
     *  as an argument to fs.writeFile
     */
    try {
      await Axios.get(url, {
        responseType: 'arraybuffer'
      })
        .then(async (res) => {
          const resData64 = new Buffer(res.data, 'binary').toString('base64')

          api().post(
            `/households/${householdFinId}/guidebookgenerator/portfolio/externalfile`,
            {
              file: resData64,
              id: householdFinId,
              name
            }
          )
        })
        .catch()
    } catch (err) {
      console.log(err)
    }
  }
  // tslint:disable-next-line:max-func-body-length
  public downloader = async (uploadToDocVault: boolean = false) => {
    if (!this.state.isDownloadEnabled && uploadToDocVault === false) return
    if (!this.state.isVaultEnabled && uploadToDocVault === true) return
    const maxVaultFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
    const {
      dispatch,
      household,
      householdFinId,
      portfolioBuilderId
    } = this.props

    const { coverPageInfo } = this.state

    // // Setting the state to capture the request to upload the document to vault
    // // Re-setting the state to false in the uploadPdf Fn when the upload is done
    uploadToDocVault
      ? this.setState({ isVaultEnabled: false })
      : this.setState({ isDownloadEnabled: false })

    const {
      gbSections: { checkedDigitalOnlyFormat }
    } = this.state

    // Cover post request
    const fileNames: string[] = []
    // this files are not ready  or not needed in the file names
    const exclude = [
      'MoneyMind',
      'HonestConversations',
      'ViewfinderResults',
      'FundedScore',
      'PortfolioChangeIllustration',
      'CostofServicesIllustration',
      'Disclosures'
    ]

    // Cover Page

    await api()
      .post(
        `/households/${householdFinId}/guidebookgenerator/portfolio/cover`,
        {
          coverPageInfo: this.state.coverPageInfo,
          contactId: this.props.householdFinId
        }
      )
      .catch()

    Object.entries(this.state.gbSections).forEach((entries: any, i: number) => {
      let keep: boolean = false
      if (entries[0].split('fetched').length === 2) {
        keep = false
      } else {
        keep =
          entries[1] &&
          !exclude.includes(entries[0].split('checked')[1].toString())
      }
      if (keep) {
        fileNames.push(entries[0].split('checked')[1])
      }
    })
    const mergeParams = {
      fileNames,
      id: householdFinId,
      contact: coverPageInfo?.clientName || '',
      planningSoftware: (household && household.planningSoftware) || '',
      logo: coverPageInfo.logo,
      removeBlankPages: checkedDigitalOnlyFormat
    }

    await api()
      .post(
        `/households/${householdFinId}/guidebookgenerator/portfolio/merger`,
        {
          mergeParams
        }
      )
      .then(async (response) => {
        if (response && response.data) {
          const pdf = {
            pdfFilename: 'PortfolioProposal.pdf',
            file: response.data,
            uploadToDocVault
          }
          // Filter fileNames to include valid sections
          // These sections are static and set on the API side
          // Do not edit:
          const allowedSections = [
            'MoneyMind',
            'HonestConversations',
            'InvestmentViewfinder',
            'FundedScore',
            'PortfolioSnapshot',
            'PortfolioImplementation',
            'InvestmentRecommendations',
            'PortfolioDetails',
            'InvestmentStrategy'
          ]
          const fileNamesToStore = fileNames.filter((fileName) =>
            allowedSections.includes(fileName)
          )

          // Create a place to upload the pdf
          const portfolioProposal: PortfolioProposalObj = {
            name: 'Portfolio Proposal',
            sections: fileNamesToStore
          }
          await dispatch(
            createPortfolioProposal(householdFinId, portfolioProposal)
          )

          await dispatch(
            uploadPortfolioProposalPdf(
              householdFinId,
              this.props.currentPortfolioProposalId,
              pdf
            )
          )
          if (!uploadToDocVault) {
            download(response.data, 'PortfolioProposal.pdf')
          }
          if (uploadToDocVault) {
            // Show success message on successful upload
            dispatch(
              addTimedLoader({
                iconType: 'success',
                loadingMessage: 'File successfully uploaded to the vault.',
                loadingType: 'Success'
              })
            )
          }
          history.push(
            `/households/${householdFinId}/portfolioBuilder/${portfolioBuilderId}/PortfolioProposal/history`
          )
        }
      })
      .catch((err) => {
        console.log(err)
        if (uploadToDocVault) {
          // Show error message in case if upload failed
          dispatch(
            addTimedLoader({
              iconType: 'error',
              loadingMessage: `Unable to upload. File exceeds ${maxVaultFileSize} MB in size.`,
              loadingType: 'Error'
            })
          )
        }
        uploadToDocVault
          ? this.setState({ isVaultEnabled: true })
          : this.setState({ isDownloadEnabled: true })
      })
  }

  public componentDidMount = async () => {
    const { householdFinId, dispatch, portfolioBuilderId } = this.props

    const { id, primaryContact, secondaryContact } = this.props.household
    if (primaryContact) {
      dispatch(moneymindActions.getMoneyMind(primaryContact, id, true))
    }
    if (secondaryContact) {
      dispatch(moneymindActions.getMoneyMind(secondaryContact, id, false))
    }

    await Promise.all([
      dispatch(getIndividualHousehold(householdFinId)),
      dispatch(getOfficeTeamByHousehold(householdFinId)),
      dispatch(getOfficeTeams()),
      dispatch(getStrategies(householdFinId)),
      dispatch(portfolioBuilderActions.getPortfolioBuilders(householdFinId)),
      dispatch(
        portfolioBuilderActions.getPortfolioBuilderAccounts(
          householdFinId,
          portfolioBuilderId
        )
      )
    ])

    this.updateCoverPageState(this.state.coverPageInfo)
    const factSheetKey = this.props.institution.isFinlifeInstitution
      ? 'finlifeFactSheetUrl'
      : 'ucfaFactSheetUrl'
    const allStrategyIds = filterStrategyIds(this.props.portfolioBuilderData)
    const factSheetStrategyIds =
      allStrategyIds &&
      allStrategyIds.filter(
        (id: string) => this.props.strategies[id][factSheetKey] !== null
      )

    this.setState({
      factSheetStrategyIds,
      factSheetKey,
      allStrategyIds
    })

    if (
      this.props.portfolioBuilderData &&
      this.props.portfolioBuilderData.proposedAccounts
    ) {
      const factSheetObjs = this.state.factSheetStrategyIds
        .map((id) => {
          if (this.props.strategies[id][this.state.factSheetKey]) {
            return {
              id,
              url: this.props.strategies[id][this.state.factSheetKey]
            }
          }
          return null
        })
        .filter((element) => element !== null)
      if (factSheetObjs.length) {
        this.setState({
          loadingFactSheet: true
        })
        Promise.all(
          factSheetObjs.map(async (factSheet) =>
            this.getAndSaveS3PDF(
              `FactSheet-${factSheet.id}`,
              factSheet.url.toString()
            )
          )
        ).then(async () => {
          const householdFinId = this.props.householdFinId

          await api()
            .post(
              `/households/${householdFinId}/guidebookgenerator/portfolio/factsheets`,
              {
                fileIds: this.state.factSheetStrategyIds,
                contactId: householdFinId,
                logo: this.state.coverPageInfo.logo
              }
            )
            .then(() => {
              const pdfFilePath = `portfolio/${householdFinId}-FactSheet.pdf`
              const encodedPath = encodeURIComponent(pdfFilePath)
              api()
                .get(
                  `/households/${householdFinId}/guidebookgenerator/renderpdf?pdfFilePath=${encodedPath}`,
                  {
                    timeout: 600000,
                    responseType: 'blob'
                  }
                )
                .then((response) => {
                  const blobData = new Blob([response.data], {
                    type: 'application/pdf'
                  })
                  this.setPdfData(
                    'FactSheet',
                    window.URL.createObjectURL(blobData)
                  )
                  this.setState({
                    responsesDidPass: true
                  })
                })
            })
        })
      }
    }
    setTimeout(this.proposalBuilder, 7000)
  }

  public sectionBuilder = async () => {
    const { householdFinId, portfolioBuilderId } = this.props

    const {
      checkedFundedScore,
      checkedMoneyMind,
      checkedHonestConversations,
      checkedViewfinderResults
    } = this.state.gbSections

    const profileSections = {
      checkedFundedScore,
      checkedMoneyMind,
      checkedHonestConversations,
      checkedViewfinderResults
    }

    await api()
      .post(`/households/${householdFinId}/guidebookgenerator/portfolio/html`, {
        profileSections,
        sectionId: 'ClientProfile',
        householdId: householdFinId,
        portfolioBuilderId
      })
      .then(() => {
        const pdfFilePath = `portfolio/${householdFinId}-ClientProfile.pdf`

        const encodedPath = encodeURIComponent(pdfFilePath)
        api()
          .get(
            `/households/${householdFinId}/guidebookgenerator/renderpdf?pdfFilePath=${encodedPath}`,
            {
              timeout: 600000,
              responseType: 'blob'
            }
          )
          .then((response) => {
            const blobData = new Blob([response.data], {
              type: 'application/pdf'
            })
            this.setPdfData(
              'ClientProfile',
              window.URL.createObjectURL(blobData)
            )
            this.setState({
              responsesDidPass: true
            })
          })
      })
      .catch()
  }

  public proposalBuilder = async () => {
    const { householdFinId, portfolioBuilderId } = this.props

    const {
      checkedFundedScore,
      checkedMoneyMind,
      checkedHonestConversations,
      checkedViewfinderResults
    } = this.state.gbSections

    const profileSections = {
      checkedFundedScore,
      checkedMoneyMind,
      checkedHonestConversations,
      checkedViewfinderResults
    }

    await api()
      .post(`/households/${householdFinId}/guidebookgenerator/portfolio/html`, {
        profileSections,
        householdId: householdFinId,
        portfolioBuilderId
      })

      .then(() => {
        portfolioPdfs.map((pdfName: string) => {
          const pdfFilePath = `portfolio/${householdFinId}-${pdfName}.pdf`
          const encodedPath = encodeURIComponent(pdfFilePath)

          api()
            .get(
              `/households/${householdFinId}/guidebookgenerator/renderpdf?pdfFilePath=${encodedPath}`,
              {
                timeout: 600000,
                responseType: 'blob'
              }
            )
            .then((response) => {
              const blobData = new Blob([response.data], {
                type: 'application/pdf'
              })
              this.setPdfData(pdfName, window.URL.createObjectURL(blobData))
              this.setState({
                responsesDidPass: true,
                isDownloadEnabled: true,
                isVaultEnabled: true
              })
            })
        })
      })
      .catch()
  }

  public handleReccomendationDetails = (
    disableRecommendationDetail: boolean
  ) => {
    this.setState((prevState) => ({
      disableRecommendationDetail,
      gbSections: {
        ...prevState.gbSections,
        checkedPortfolioDetails: !disableRecommendationDetail
      }
    }))
  }

  public setPdfData = (filename: string, content: any) => {
    const pdfData = this.state.pdfData
    pdfData[filename] = content
    this.setState(pdfData)
  }

  public render() {
    const {
      responsesDidPass,
      coverPageInfo,
      disableRecommendationDetail,
      pdfData,
      isDownloadEnabled,
      isVaultEnabled,
      gbSections,
      allStrategyIds,
      loadingStrategies,
      factSheetStrategyIds,
      loadingFactSheet
    } = this.state
    const {
      household,
      householdFinId,
      portfolioBuilderId,
      fundedScores,
      honestConversations,
      viewfinderExercises,
      moneyminds,
      contacts
    } = this.props

    const updatedCoverPageInfo = coverPageInfoSetter(coverPageInfo, household)

    return (
      <div className='ppb-container'>
        <div className='ppb-container__main'>
          <PortfolioProposalActions
            household={household}
            downloader={this.downloader}
            isLoading={!isDownloadEnabled}
            isVaultLoading={!isVaultEnabled}
            portfolioBuilderId={portfolioBuilderId}
          />
          <div className='ppb-container__nav'>
            <PpbNavigation
              coverPageInfo={updatedCoverPageInfo}
              officeTeam={updatedCoverPageInfo.teamAssigned}
              gbSections={gbSections}
              coverPageDataUpdate={this.coverPageDataUpdate}
              coverPageAdvisorUpdate={this.coverPageAdvisorUpdate}
              toggleMe={this.toggleMe}
              allStrategyIds={allStrategyIds}
              fundedScores={fundedScores}
              honestConversations={honestConversations}
              viewFinderResutls={viewfinderExercises}
              moneyminds={moneyminds}
              contacts={contacts}
              loadingStrategies={loadingStrategies}
              factSheetStrategyIds={factSheetStrategyIds}
              householdFinId={householdFinId}
              portfolioBuilderId={portfolioBuilderId}
              disableRecommendationDetail={disableRecommendationDetail}
              setPdfData={this.setPdfData}
            />
          </div>

          <div className='ppb-container__pdf-preview ppb-container__canvas'>
            <CoverPage coverPageInfo={updatedCoverPageInfo} />
            {responsesDidPass ? (
              <PdfRenderer
                id={householdFinId}
                pdfSections={gbSections}
                loadingFactSheet={loadingFactSheet}
                pdfData={pdfData}
              />
            ) : null}
            <Disclosure />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const portfolioBuilderId = match.params.portfolioBuilderId
  const householdFinId = match.params.householdFinId
  return {
    portfolioBuilderId,
    portfolioBuilderList: store.portfolioBuilder[householdFinId],
    household: store.households[householdFinId],
    user: store.user.userId ? store.user.users[store.user.userId] : null,
    users: store.user.users,
    officeTeam: store.officeTeams[store.households[householdFinId].officeTeam],
    householdFinId: match.params.householdFinId,
    honestConversations: store.hcExercises[householdFinId],
    moneyminds: store.moneymind[householdFinId],
    contacts: store.contact[householdFinId],
    viewfinderExercises: store.investmentViewfinder.exercises[householdFinId],
    fundedScores:
      store.financialGoals[householdFinId] &&
      store.financialGoals[householdFinId].fundedScores,
    clientAccounts:
      store.clientAccount[householdFinId] &&
      store.clientAccount[householdFinId].clientAccounts,
    accountMap: store.accountMap[householdFinId],
    portfolioBuilderData:
      store.portfolioBuilder[householdFinId] &&
      store.portfolioBuilder[householdFinId][portfolioBuilderId],
    strategies: store.strategies[householdFinId],
    institution: store.households[householdFinId].institution,
    currentPortfolioProposalId:
      store.portfolioProposal && store.portfolioProposal[householdFinId]
        ? store.portfolioProposal[householdFinId].currentPortfolioProposalId
        : '',
    portfolioBuilder: store.portfolioBuilder[householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(PortfolioProposal))
