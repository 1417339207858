import React, { Fragment } from 'react'
import moment from 'moment'
import { Dispatch, connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

import {
  getFinancialGoalsTemplates,
  updateGoalActionById,
  addNoteTemplate
} from '../../../../actions/financialGoalActions'

import { GlobalState } from '../../../../reducers'
import {
  GoalResponse,
  GoalTemplatesResponse,
  GoalType
} from '../../../../objects/financialGoalActions'

import { HouseholdObj } from '../../../../objects/household'
import { ContactsInterface } from '../../../../objects/contact'
import { InstitutionObj } from '../../../../objects/institution'
import { getStatusIcon, getClientData } from '../../../helpers/goals'
import { simpleDateFormat } from '../../../helpers'
import { CardObj } from '../../../../objects/HonestConversations/card'
import { HonestConversationsExerciseObj } from '../../../../objects/honestConversations'

import {
  GroupProfilePictures,
  Tooltip
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import ToggleSwitch from '../../toggleSwitch'
import SuggestedGoalCards from '../components/suggestedGoalCards'
import StatusSelect from '../../layout/statusSelect'
import AssigneeSelect from '../../layout/assigneeSelect'

import DateIcon from '../../../assets/images/icons/goals/goal-date-icon.png'
import DownArrow from '../../../assets/images/icons/goals/goal-arrow.png'
import NoHCCard from '../../../assets/images/icons/goals/goal-hc-card.png'
import PlusBlueArrow from '../../../assets/images/icons/goals/goal-add-blue.png'
import TrashIcon from '../../../assets/images/icons/goals/goal-trash-gray.png'

import UseNote from '../../../assets/images/icons/goals/goal-note-gray.png'
import AddGray from '../../../assets/images/icons/goals/goal-add-gray.png'

import CustomDatepicker from '../../customDatepicker'

interface SideBarBodyProps {
  activeGoal: GoalResponse
  contacts: ContactsInterface
  householdFinId: string
  suggestedGoalId: string
  suggestedGoal?: GoalTemplatesResponse
  honestConversation: HonestConversationsExerciseObj
  setViewKey(id: number): void
  dispatch: Dispatch<GlobalState>
  sideBarRef?: React.Ref<HTMLDivElement>
  institution: InstitutionObj
  goalCls: string
  showHonestConversations: boolean
  household: HouseholdObj
}

interface SideBarBodyState {
  assigneePrimaryId: string
  assigneeSecondaryId: string
  name: string
  note: string
  status: string
  dueDate: any
  showHideToggle: boolean
  statusToggle: boolean
  assignToToggle: boolean
  priorities: CardObj[]
  nameInputError: boolean
  type: GoalType
}

export class SideBarBody extends React.PureComponent<
  SideBarBodyProps,
  SideBarBodyState
> {
  constructor(props: SideBarBodyProps) {
    super(props)
    this.state = {
      assigneePrimaryId: '',
      assigneeSecondaryId: '',
      name: '',
      note: '',
      status: '',
      dueDate: null,
      showHideToggle: false,
      statusToggle: false,
      assignToToggle: false,
      priorities: [],
      nameInputError: false,
      type: null
    }
  }

  public componentDidMount() {
    const { dispatch, householdFinId, activeGoal } = this.props
    dispatch(getFinancialGoalsTemplates(householdFinId))
    this.setState((prevState) => ({
      ...prevState,
      assigneePrimaryId: activeGoal?.assigneePrimaryId,
      assigneeSecondaryId: activeGoal?.assigneeSecondaryId,
      name: activeGoal?.name,
      note: activeGoal?.note,
      status: activeGoal?.status,
      showHideToggle: activeGoal?.inGuidecenter,
      priorities: activeGoal.cards,
      dueDate: this.state.dueDate
        ? moment(this.state.dueDate)
        : activeGoal?.dueDate
        ? moment(activeGoal?.dueDate)
        : null,
      type: activeGoal?.type
    }))
  }

  public handleShowHideToggle = () => {
    this.setState({ showHideToggle: !this.state.showHideToggle })
  }

  public toggleSuggestedCard = (cardId: string) => {
    const { priorities } = this.state
    const newPriorities = priorities?.map((card: CardObj) => {
      if (card.id === cardId) {
        card.showCard = !card.showCard
        return card
      }
      return card
    })
    this.setState(
      {
        priorities: newPriorities
      },
      this.syncWithStore
    )
  }

  public toggleAssignToDropdown = () => {
    this.setState({
      assignToToggle: !this.state.assignToToggle,
      statusToggle: false
    })
  }
  public toggleStatusDropdown = () => {
    this.setState({
      statusToggle: !this.state.statusToggle,
      assignToToggle: false
    })
  }

  public handleClickOutside = () => {
    this.setState({
      statusToggle: false,
      assignToToggle: false
    })
  }

  public updateTextArea = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget
    if (name === 'name') {
      this.setState({
        nameInputError: !value
      })
    }

    if (this.state[name] !== value) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }
  }

  public dateChange = (dueDate: any) => {
    this.setState({
      dueDate
    })
  }

  public updateActionAssignee = (assigneeId: string, contact: string) => {
    if (contact === 'Primary') {
      this.setState({
        assigneePrimaryId: assigneeId
      })
    } else if (contact === 'Secondary') {
      this.setState({
        assigneeSecondaryId: assigneeId
      })
    }
  }

  public updateStatusSelection = (selection: string) => {
    this.handleClickOutside()
    this.setState({
      status: selection
    })
  }

  public syncWithStore = () => {
    const { contacts, householdFinId, activeGoal, dispatch } = this.props
    const {
      name,
      note,
      status,
      assigneePrimaryId,
      assigneeSecondaryId,
      dueDate,
      showHideToggle,
      type
    } = this.state

    this.setState({
      nameInputError: !name
    })

    const checkInDate = (dueDate && moment(dueDate).format()) || null
    const reqBody = {
      id: activeGoal?.id,
      name,
      note,
      status,
      assigneePrimaryId: assigneePrimaryId?.length
        ? contacts?.primary?.id
        : null,
      assigneeSecondaryId: assigneeSecondaryId?.length
        ? contacts?.secondary?.id
        : null,
      dueDate: checkInDate,
      cards: this.getPrioritiesHCCards(),
      inGuidecenter: showHideToggle,
      type,
      customGoal: activeGoal?.customGoal
    }
    dispatch(updateGoalActionById(reqBody, householdFinId))
  }

  public getPrioritiesHCCards = () => {
    const { honestConversation: { householdCards = {} } = {} } = this.props
    const { priorities = [] } = this.state
    const relaventCards: CardObj[] = []
    const palCardTitleArray = priorities.map((card) => card.title.toLowerCase())
    if (householdCards) {
      Object.keys(householdCards).forEach((key) => {
        const card = householdCards[key]
        const indexOfCard = palCardTitleArray.indexOf(
          card.card.title.toLowerCase()
        )
        if (card?.card?.title && palCardTitleArray && indexOfCard !== -1) {
          if (!priorities[indexOfCard].hasOwnProperty('showCard')) {
            priorities[indexOfCard].showCard = true
          }
          relaventCards.push(priorities[indexOfCard])
        }
      })
    }
    return relaventCards
  }

  public renderTextArea = (title: string) => {
    const { name, note, nameInputError } = this.state
    const { suggestedGoalId, activeGoal } = this.props
    const isNoteType = title === 'Notes'
    const requiredAsterisk = <sub style={{ fontSize: '14px' }}>*</sub>
    return (
      <div id='textArea'>
        <div className='side-bar-body__text--header'>
          {title}
          {!isNoteType ? requiredAsterisk : null}
        </div>
        <TextareaAutosize
          maxRows={isNoteType ? 8 : 3}
          className='side-bar-body__text'
          name={isNoteType ? 'note' : 'name'}
          value={isNoteType ? note : name}
          onChange={this.updateTextArea}
          placeholder={`Enter ${title?.toLowerCase()}`}
          readOnly={Boolean(
            !isNoteType && (suggestedGoalId !== null || !activeGoal.customGoal)
          )}
          onBlur={this.syncWithStore}
        />
        {!isNoteType && nameInputError ? (
          <div className='side-bar-body__text--error'>Must enter name</div>
        ) : null}
        <div className='side-bar-body__line-break' />
      </div>
    )
  }

  public setGoalActionType = (type: GoalType) => {
    this.setState({ type }, this.syncWithStore)
  }

  public handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkboxName = event.target.name as GoalType
    const { type } = this.state
    switch (type) {
      case 'both':
        if (checkboxName === 'financial') {
          return this.setGoalActionType('life')
        } else {
          return this.setGoalActionType('financial')
        }
      case 'financial':
        if (checkboxName === 'financial') {
          return this.setGoalActionType(null)
        } else {
          return this.setGoalActionType('both')
        }
      case 'life':
        if (checkboxName === 'life') {
          return this.setGoalActionType(null)
        } else {
          return this.setGoalActionType('both')
        }
      default:
        return this.setGoalActionType(checkboxName)
    }
  }

  public renderGoalType = () => {
    const { type } = this.state
    const lifeChecked = type === 'life' || type === 'both'
    const financialChecked = type === 'financial' || type === 'both'
    return (
      <div className='side-bar-body__checkbox-container'>
        <div className='side-bar-body__text--header'>Goal Type</div>
        <div className='side-bar-body__checkbox-row'>
          <input
            type='checkbox'
            name='life'
            id='lifeGoalCheckbox'
            checked={lifeChecked}
            onChange={this.handleCheckboxChange}
          />
          <label htmlFor='lifeGoalCheckbox'>Life Goal</label>

          <input
            type='checkbox'
            name='financial'
            id='financialGoalCheckbox'
            checked={financialChecked}
            onChange={this.handleCheckboxChange}
          />
          <label htmlFor='financialGoalCheckbox'>Financial Goal</label>
        </div>
      </div>
    )
  }

  public renderAssigneeNames = () => {
    const { assigneePrimaryId, assigneeSecondaryId } = this.state
    const { primary, secondary } = ({} = this.props.contacts)
    return assigneePrimaryId || assigneeSecondaryId ? (
      `${(assigneePrimaryId && primary?.firstName) ||
        ''} ${(assigneePrimaryId && assigneeSecondaryId && ' and ') ||
        ''} ${(assigneeSecondaryId && secondary?.firstName) || ''}`
    ) : (
      <div className='side-bar-body__text--placeholder'>Select users</div>
    )
  }

  public deletePrioritiesCard = (id: string) => {
    const { priorities } = this.state
    if (priorities.length) {
      this.setState({
        priorities: priorities.filter((card) => card.id !== id)
      })
    }
  }

  public addPrioritiesCard = () => {
    this.syncWithStore()
    this.props.setViewKey(3)
  }

  public useNoteTemplate = () => {
    this.syncWithStore()
    this.props.setViewKey(4)
  }

  public addNoteTemplate = () => {
    const {
      householdFinId,
      institution,
      activeGoal,
      dispatch,
      suggestedGoal
    } = this.props
    const { note = '', status } = this.state
    const isExist = suggestedGoal?.goalNoteTemplates.find(
      (template: any) => template?.note?.toLowerCase() === note.toLowerCase()
    )
    if (note !== '' && activeGoal.palItemId && !isExist) {
      const reqBody = {
        palItemId: activeGoal.palItemId,
        institutionId: institution.id,
        status,
        note
      }
      dispatch(addNoteTemplate(householdFinId, activeGoal.id, reqBody))
    }
  }

  public render() {
    const {
      activeGoal: goalObj,
      contacts,
      goalCls,
      household,
      honestConversation: { householdCards = {} } = {}
    } = this.props
    const {
      assignToToggle,
      assigneePrimaryId,
      assigneeSecondaryId,
      status,
      statusToggle,
      showHideToggle
    } = this.state
    const isCustomGoal = goalObj?.customGoal
    const cards = this.getPrioritiesHCCards()
    const hasCard = cards.length
    const hasHHCard = Object.keys(householdCards).length
    const clients: any = getClientData(
      {
        ...goalObj,
        ...{
          assigneePrimaryId,
          assigneeSecondaryId
        }
      },
      contacts
    )

    return (
      <Fragment>
        <div className='side-bar-body'>
          {this.renderTextArea('Goal Name')}
          {this.renderGoalType()}
          {this.renderTextArea('Notes')}
          {!isCustomGoal ? (
            <div className='side-bar-body__notes'>
              <div
                className='side-bar-body__notes-item'
                onClick={this.useNoteTemplate}>
                <img src={UseNote} alt='Use Note Template' />
                Use Note Template
              </div>
              <div
                className='side-bar-body__notes-item'
                onClick={this.addNoteTemplate}>
                <img src={AddGray} alt='Add Note To templates' />
                Add Note To templates
              </div>
            </div>
          ) : null}
          <div className='side-bar-body__text--header'>Assign To</div>
          <div className='side-bar-body__assignee-row'>
            {Object.keys(clients)?.length ? (
              <div className='side-bar-body__assignee-row-margin--right'>
                {goalObj && (
                  <GroupProfilePictures
                    clients={clients}
                    pictureSize={20}
                    overlap={true}
                  />
                )}
              </div>
            ) : null}
            <div
              className='side-bar-body__selector-w'
              onClick={this.toggleAssignToDropdown}>
              <div className='side-bar-body__text side-bar-body__selector-target'>
                {this.renderAssigneeNames()}
              </div>
              <img src={DownArrow} alt='Down Arrow' />
            </div>
          </div>
          {assignToToggle && (
            <div className='side-bar-body__selector-status-modal'>
              <AssigneeSelect
                selectPosition='absolute'
                tickPosition='left'
                toggleAssignOwnerModal={this.toggleAssignToDropdown}
                assigneePrimaryId={assigneePrimaryId}
                assigneeSecondaryId={assigneeSecondaryId}
                contacts={contacts}
                updateActionAssignee={this.updateActionAssignee}
              />
            </div>
          )}
          <div className='side-bar-body__line-break' />
          <div className='side-bar-body__grid'>
            <div>
              <div className='side-bar-body__text--header'>Status</div>
              <div className='side-bar-body__icon-row'>
                <div
                  className={`status-select__icon ${getStatusIcon(status)}`}
                />
                <div
                  className='side-bar-body__selector-w'
                  onClick={this.toggleStatusDropdown}>
                  <div className='side-bar-body__text side-bar-body__selector-target'>
                    {status || (
                      <div className='side-bar-body__text--placeholder'>
                        Select status
                      </div>
                    )}
                  </div>
                  <img src={DownArrow} alt='Down Arrow' />
                </div>
              </div>
              <div className='side-bar-body__selector-status-modal'>
                {statusToggle && (
                  <StatusSelect
                    handleStatusSelect={this.updateStatusSelection}
                    currentStatus={status}
                  />
                )}
              </div>
            </div>
            <div>
              <div className='side-bar-body__text--header'>Check In Date</div>
              <div className='side-bar-body__text side-bar-body__icon-row'>
                <img
                  alt='Date Icon'
                  src={DateIcon}
                  className='side-bar-body__icon-row-margin--right'
                />

                <CustomDatepicker
                  dueDate={simpleDateFormat(goalObj?.dueDate)}
                  popperPlacement='left'
                  className='side-bar-body__text'
                  dateSetter={this.dateChange}
                  dateFormat={['MM/dd/yy', 'MM/dd/yyyy']}
                />
              </div>
            </div>
          </div>
          <div className='side-bar-body__line-break' />
          <div className='side-bar-body__icon-row side-bar-body__show-hide'>
            <ToggleSwitch
              active={showHideToggle}
              handleToggleActive={this.handleShowHideToggle}
            />
            <div className='side-bar-body__text side-bar-body__icon-row-margin--left'>
              <Tooltip
                message='Select whether you want this item to be visible to your client in his/her GuideCenter and Guidebook'
                width={190}
                position='bottom'
                multiLine={true}>
                Show/Hide
              </Tooltip>
            </div>
          </div>
        </div>
        {household.showHonestConversations && (
          <div className='side-bar-body__priorities'>
            <div className='side-bar-body__priorities-header'>
              <span>Priorities</span>
              {isCustomGoal && hasHHCard ? (
                <span
                  className='side-bar-body__add_priorities'
                  onClick={this.addPrioritiesCard}>
                  <img src={PlusBlueArrow} width='13' height='13' /> Add
                </span>
              ) : null}
            </div>
            <div className='side-bar-body__priorities-body'>
              {hasCard ? (
                <div className='side-bar-body__priorities-cards-list'>
                  <SuggestedGoalCards
                    showCardView={true}
                    cards={this.getPrioritiesHCCards()}
                    showIcon={isCustomGoal}
                    iconImg={TrashIcon}
                    imgWidth={'24'}
                    imgHeight={'24'}
                    classnames={goalCls}
                    rowClickEnabled={false}
                    iconAction={this.deletePrioritiesCard}
                    view={2}
                    toggleAction={this.toggleSuggestedCard}
                  />
                </div>
              ) : (
                <div className='side-bar-body__priorities-no-data'>
                  <img src={NoHCCard} width='64' height='64' />
                  <span>
                    You can't add Priorities to this Goal until you do the
                    HonestConversations exercise.
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps = (store: GlobalState) => {
  return {
    institution: store.institution
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(
  SideBarBody
)
