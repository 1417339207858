import { ProfileImgClients } from '../../objects/financialGoalActions'
import { ContactsInterface } from '../../objects/contact'
import {
  AccountLinkedNotification,
  FileNotification,
  GoalChangeNotification,
  HonestConversationNotification,
  AdvisorNotification,
  NotificationContent,
  MessageNotfication,
  CompleteTaskNotification,
  NewTaskAssignedNotification,
  DisplayableNotification,
  NotificationTypeToNotificaiton,
  Notification,
  SyncNotification,
  NotificationTypes,
  YmmNotification,
  FullNotification
} from '../../objects/notifications'
import { ContactState } from '../../reducers/contacts'
import moment from 'moment'
import { HouseholdsState } from '../../reducers/households'
import { batchAndSort } from './notificationBatcher'
import { isEmpty } from 'lodash'

export const getClientData = (contacts: ContactsInterface) => {
  const types = ['Primary', 'Secondary']
  const clients: ProfileImgClients = {}
  types?.forEach((type) => {
    const typeLower: string = type.toLowerCase()
    if (contacts?.[typeLower]) {
      clients[typeLower] = {
        firstName: contacts && contacts[typeLower]?.firstName,
        lastName: contacts && contacts[typeLower]?.lastName,
        photo: contacts && contacts[typeLower]?.photo,
        isPrimary: type === 'Primary'
      }
    }
  })
  return clients
}

export const getHouseholdId = (
  contacts: ContactState,
  notficationContent: NotificationContent
) => {
  const { triggerId, triggerType, data } = notficationContent

  let notificationHouseholdId = ''

  if (data && 'householdId' in data) {
    const { householdId } = data
    notificationHouseholdId = householdId
  }

  if (triggerType === 'client') {
    Object.keys(contacts).forEach((id: string) => {
      if (
        contacts[id].primary?.id === triggerId ||
        contacts[id].secondary?.id === triggerId
      ) {
        notificationHouseholdId = id
      }
    })
  }
  return notificationHouseholdId
}

export const getNotificationLink = (
  advisorNotification: DisplayableNotification<NotificationTypes>
) => {
  //return empty link if empty notification
  if (isEmpty(advisorNotification) || !advisorNotification.batchSize) {
    return ''
  }
  const { batchSize, household } = advisorNotification
  const { subject, content } = advisorNotification.notificationByNotification
    ? advisorNotification.notificationByNotification
    : { subject: '', content: { data: '' } }
  const contentData = content as NotificationContent

  const { data } = contentData
  if (data) {
    const householdId = household?.id
    const {
      exerciseId: hcExerciseId,
      type: hcType,
      recordId: hcRecordId
    } = data as HonestConversationNotification
    const { parentId, messageId } = data as MessageNotfication
    const { recordId: goalRecordId } = data as GoalChangeNotification
    const { folderId: fileFolderId } = data as FileNotification
    const { recordId: accountRecordId } = data as AccountLinkedNotification
    const { taskId } = data as NewTaskAssignedNotification
    const threadId = parentId != null ? parentId : messageId
    switch (subject.toLowerCase()) {
      case 'message':
        return `/households/${householdId}/messages/${threadId}`
      case 'new_ymm':
        return `/households/${householdId}/moneyMind`
      case 'new_hc':
        if (hcExerciseId) {
          return hcType === 'Scoring Session'
            ? `/households/${householdId}/honestConversations/scorePreview/${hcRecordId}?exerciseId=${hcExerciseId}`
            : `/households/${householdId}/honestConversations/meetings/${hcExerciseId}`
        }
        return `/households/${householdId}/honestConversations`
      case 'goal_change':
        return `/households/${householdId}/financialGoals/requests/${goalRecordId}`
      case 'new_pdv_file':
        return `/households/${householdId}/documentVault/${fileFolderId}`
      case 'new_account_linked':
        return batchSize && batchSize > 1
          ? `/households/${householdId}/netWorth`
          : `/households/${householdId}/clientAccount/${accountRecordId}/networth`
      case 'complete_task':
      case 'new_task_assigned':
        return batchSize && batchSize > 1
          ? `/households/${householdId}/tasks`
          : `/households/${householdId}/tasks/${taskId}`
      default:
        return ''
    }
  }
  return ''
}

export const notificationDate = (date: string) => {
  const days = moment().diff(date, 'days')
  if (days < 1) {
    const hours = moment().diff(date, 'hours')
    if (hours < 1) {
      const minutes = moment().diff(date, 'minutes')
      if (minutes < 1) {
        return null
      }
      return minutes === 1 ? `1 minute ago` : `${minutes} minutes ago`
    }
    return hours > 1 ? `${hours} hours ago` : `1 hour ago`
  } else if (days < 7) {
    return days > 1 ? `${days} days ago` : `1 day ago`
  } else if (days >= 7 && days < 30) {
    const weeks = moment().diff(date, 'weeks')
    return weeks > 1 ? `${weeks} weeks ago` : `1 week ago`
  } else {
    const months = moment().diff(date, 'months')
    return months > 1 ? `${months} months ago` : `1 month ago`
  }
}

export const enabledNotifications = (
  advisorEnabledSubjects: string[],
  advisorNotifications: AdvisorNotification[]
) => {
  const subjects = advisorEnabledSubjects?.length ? advisorEnabledSubjects : []
  const notifications = advisorNotifications?.length ? advisorNotifications : []

  return notifications.filter((notificationsNode: AdvisorNotification) => {
    const {
      notificationByNotification: { subject }
    } = notificationsNode
    return subject !== 'SYNC' && subjects.includes(subject)
  })
}

//Helper function that takes current state and makes a displayableNotification
//pretty bound with the extend notifications deployable function below
const createDisplayableNotification = (
  households: HouseholdsState,
  contacts: ContactState,
  contactHouseholdTuple: { [key: string]: string },
  notification: FullNotification
) => {
  //householdID to info helper
  const statesFromHouseholdId = (householdId: string, contactId?: string) => {
    return {
      household: households[householdId],
      householdContacts: contacts[householdId],
      contact: contactId
        ? households[householdId]?.primaryContact === contactId
          ? contacts[householdId]?.primary
          : contacts[householdId]?.secondary
        : contacts[householdId]?.primary
    }
  }
  const statesFromContactId = (contactId: string) => {
    const h = households[contactHouseholdTuple[contactId]]
    const ret = h
      ? {
          household: h,
          householdContacts: contacts[h.id],
          contact:
            households[h.id]?.primaryContact === contactId
              ? contacts[h.id]?.primary
              : contacts[h.id]?.secondary
        }
      : {}
    return ret
  }
  switch (notification?.notificationByNotification?.subject) {
    case 'SYNC':
      return {} as DisplayableNotification<SyncNotification>
    case 'COMPLETE_TASK':
      const a = notification as Notification<CompleteTaskNotification>
      return {
        ...a,
        ...statesFromContactId(
          a.notificationByNotification.content.data?.clientId
        )
      } as DisplayableNotification<CompleteTaskNotification>
    case 'GOAL_CHANGE':
      const b = notification as Notification<GoalChangeNotification>
      return {
        ...b,
        ...statesFromHouseholdId(
          b.notificationByNotification.content.data.householdId
        )
      } as DisplayableNotification<GoalChangeNotification>
    case 'MESSAGE':
      const c = notification as Notification<MessageNotfication>
      return {
        ...c,
        ...statesFromHouseholdId(
          c.notificationByNotification.content.data?.householdId,
          c.notificationByNotification.content?.triggerId
        )
      } as DisplayableNotification<MessageNotfication>
    case 'NEW_ACCOUNT_LINKED':
      const d = notification as Notification<AccountLinkedNotification>
      return {
        ...d,
        ...statesFromHouseholdId(
          d.notificationByNotification.content.data?.householdId
        )
      } as DisplayableNotification<AccountLinkedNotification>
    case 'NEW_HC':
      const e = notification as Notification<HonestConversationNotification>
      return {
        ...e,
        ...statesFromContactId(e.notificationByNotification.content.triggerId)
      } as DisplayableNotification<HonestConversationNotification>
    case 'NEW_PDV_FILE':
      const f = notification as Notification<FileNotification>
      return {
        ...f,
        ...statesFromContactId(
          f.notificationByNotification.content.data?.clientId
        )
      } as DisplayableNotification<FileNotification>
    case 'NEW_TASK_ASSIGNED':
      const g = notification as Notification<NewTaskAssignedNotification>
      return {
        ...g,
        ...statesFromHouseholdId(
          g.notificationByNotification.content.data?.householdId
        )
      } as DisplayableNotification<NewTaskAssignedNotification>
    case 'NEW_YMM':
      const h = notification as Notification<YmmNotification>
      return {
        ...h,
        ...statesFromContactId(h.notificationByNotification.content.triggerId)
      } as DisplayableNotification<YmmNotification>
    default:
      return {} as DisplayableNotification<SyncNotification>
  }
}

export const extendedNotificationsDisplayables = (
  households: HouseholdsState,
  contacts: ContactState,
  advisorNotifications: AdvisorNotification[]
) => {
  //convert contact states to contact/household tuple
  const contactTuple = Object.values(households).reduce((prev, val) => {
    // NOTE: assumes a contact can be a part of ONE and ONLY ONE household
    prev[val.primaryContact] = val.id
    if (val.secondaryContact) {
      prev[val.secondaryContact] = val.id
    }
    return prev
  }, {})
  // filter for active contacts
  const filtered = advisorNotifications.filter(
    (notificationNode: AdvisorNotification) => {
      const {
        notificationByNotification: { content }
      } = notificationNode

      const notificationContent =
        typeof content === 'string' ? JSON.parse(content) : content

      // allow advisor types
      if (notificationContent?.triggerType === 'adviser') {
        return true
      }
      // allow household types with valid household
      if (
        notificationContent?.triggerType === 'household' &&
        typeof households[notificationContent?.triggerId] !== 'undefined'
      ) {
        return true
      }

      return typeof contactTuple[notificationContent?.triggerId] !== 'undefined'
    }
  )
  const batched = batchAndSort(
    filtered.map((v) => {
      return {
        ...v,
        notificationByNotification: {
          ...v.notificationByNotification,
          content:
            typeof v.notificationByNotification.content === 'string'
              ? JSON.parse(v.notificationByNotification.content)
              : v.notificationByNotification.content
        }
      } as NotificationTypeToNotificaiton[keyof NotificationTypeToNotificaiton]
    }),
    contactTuple
  )
  //convert batched notification to displayable type
  return batched.map((v) => {
    return {
      ...createDisplayableNotification(
        households,
        contacts,
        contactTuple,
        v[0]
      ),
      batchSize: v.length,
      batchedIds: v.map((v) => v.id)
    } as DisplayableNotification<NotificationTypes>
  })
}

// get IDs of unread notifications from a single notification with possible batch
export const unreadFromNotification = (
  notification: DisplayableNotification<NotificationTypes>
): string[] => {
  if (notification.read || notification.readDate) {
    return []
  }
  if (notification.batchedIds?.length) {
    return notification.batchedIds
  }
  return [notification.id]
}

// get IDs of all unread notifications from batched notifications
export const unreadFromAllNotifications = (
  notifications: DisplayableNotification<NotificationTypes>[]
): string[] => {
  return notifications.reduce((p: string[], n) => {
    p = [...p, ...unreadFromNotification(n)]
    return p
  }, [])
}
