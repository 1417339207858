import { CSSProperties } from 'react'
import {
  HonestConversationsExerciseObj,
  CardRankingObj,
  CardScoreRequestObj,
  ClientCardObj,
  ScoringSessionsObj,
  HouseholdCardObjInterface,
  ClientScoringSession,
  ClientScoringSessionsInterface,
  HouseholdCardObj,
  HonestConversationsExerciseInterface
} from '../../objects/honestConversations'
import { CardObj } from '../../objects/HonestConversations/card'
import moment from 'moment'
import { ContactsInterface } from '../../objects/contact'
import { LifeActionObj } from '../../objects/lifeActions'
import { FinancialGoalActionObj } from '../../objects/financialGoalActions'
import { HouseholdObj } from '../../objects/household'

interface ScoreStateObj {
  householdCardId: string
  primaryScore: number
  secondaryScore: number
  primaryComment: string
  secondaryComment: string
  card: CardObj
}

/**
 * Map selected cards to hc cards
 * string comparison on title
 */
export const mapSelectedCardsToModel = (
  selectedCards: CardRankingObj[],
  cardList: { [category: string]: CardObj[] }
) => {
  const mappedCards = selectedCards.map((selectedCard, index) => {
    if (!selectedCard.card || !selectedCard.card.category || !cardList) {
      return null
    }
    cardList[selectedCard.card.category].forEach((card: any) => {
      const selectedCardTitle = selectedCard.card.title
        .replace(/[^a-zA-Z0-9+]+/g, '')
        .trim()
      const cardCardTitle = card.title.replace(/[^a-zA-Z0-9+]+/g, '').trim()
      if (selectedCardTitle === cardCardTitle) {
        selectedCard = { ...selectedCard, cardId: card.id, ranking: index + 1 }
      }
    })
    return selectedCard
  })

  return mappedCards.filter((card) => {
    return card !== null
  })
}

export const getTopFiveCards = (cards: ClientCardObj[]) => {
  if (!cards || cards.length === 0) {
    return []
  }
  const filteredCards = cards
    .filter((card) => card.ranking && card.rankedWithin === 'All')
    .sort((a, b) => a.ranking - b.ranking)
  return filteredCards.length ? filteredCards : []
}

export const sortHouseholdCards = (cards: HouseholdCardObjInterface) => {
  return Object.keys(cards)
    .sort((a, b) => cards[a].ranking - cards[b].ranking)
    .map((key) => cards[key])
}

export const mapScoreProperties = (
  scoreCopy: ScoreStateObj[],
  scoringSession: ScoringSessionsObj,
  contactType: string
) => {
  scoringSession?.cardScores &&
    Object.keys(scoringSession.cardScores).forEach((key) => {
      const scoreIndex = scoreCopy.findIndex(
        (obj) =>
          obj.householdCardId ===
          scoringSession.cardScores[key].householdCard.id
      )
      scoreCopy[scoreIndex][`${contactType}Score`] =
        scoringSession.cardScores[key].score
      scoreCopy[scoreIndex][`${contactType}Comment`] =
        scoringSession.cardScores[key].comment
    })
  return scoreCopy
}

export const dateSortScoringSession = (
  scoringSession: ClientScoringSession
) => {
  if (!scoringSession) {
    return null
  }

  return Object.keys(scoringSession)
    .sort((aKey: string, bKey: string) => {
      return moment(scoringSession[aKey].createdDate).isBefore(
        scoringSession[bKey].createdDate
      )
        ? -1
        : 1
    })
    .map((key: string) => {
      return { ...scoringSession[key], id: key }
    })
}

/**
 * provides boolean : is primary | secondary scoring session
 * @param honestConversation
 * @param contacts
 * @param scoreSessionId
 */
export const isPrimaryScoringSession = (
  honestConversation: HonestConversationsExerciseObj,
  contacts: ContactsInterface,
  scoreSessionId: string
): boolean => {
  if (!honestConversation || !contacts) return false

  const { clientScoringSessions } = honestConversation
  const isPrimarySession = Object.keys(clientScoringSessions)
    .map((clientId: string) => {
      const clientScoringSession = clientScoringSessions[clientId]
      if (clientScoringSession && clientScoringSession[scoreSessionId]) {
        return contacts.primary.id === clientId
      }
      return false
    })
    .filter((sessionState) => {
      // return only true
      return sessionState && sessionState
    })

  return isPrimarySession.length > 0
}

/**
 * provides scoring session lookup and scoring session obj
 * @param honestConversation
 * @param scoreSessionId
 */
export const getScoringSession = (
  honestConversation: HonestConversationsExerciseObj,
  scoreSessionId: string
) => {
  if (!honestConversation || !scoreSessionId) {
    return null
  }
  const { clientScoringSessions } = honestConversation
  const currentScoringSession = Object.keys(clientScoringSessions)
    .map((clientId: string) => {
      const clientScoringSession = clientScoringSessions[clientId]
      return clientScoringSession && clientScoringSession[scoreSessionId]
    })
    .filter((item) => {
      return item && item
    })
  if (!currentScoringSession || !currentScoringSession.length) {
    return null
  }

  return currentScoringSession[0]
}

export const mapUpdateCardScore = (
  contactId: string,
  activeScoringSessionId: string,
  clientScoringSessions: ClientScoringSessionsInterface,
  combinedCards: CardRankingObj[],
  contacts: ContactsInterface
): CardScoreRequestObj[] => {
  const cardScores =
    (clientScoringSessions &&
      clientScoringSessions[contactId] &&
      clientScoringSessions[contactId][activeScoringSessionId] &&
      clientScoringSessions[contactId][activeScoringSessionId].cardScores) ||
    null

  return (
    cardScores &&
    Object.keys(cardScores)?.map((cardScoreId: string) => {
      const cardScore = cardScores[cardScoreId]
      let newScore: number = 0
      let newComment: string = null
      let householdCardId: string = null
      combinedCards.forEach((combinedCard) => {
        const scoreId =
          contacts?.secondary?.id === contactId
            ? combinedCard.secondaryCardScoreId
            : combinedCard.primaryCardScoreId
        if (cardScore.cardScoreId === scoreId) {
          householdCardId = combinedCard.id
          newScore =
            contacts.secondary && contacts.secondary.id === contactId
              ? combinedCard.secondaryScore
              : combinedCard.primaryScore
          newComment =
            contacts.secondary && contacts.secondary.id === contactId
              ? combinedCard.commentSecondary
              : combinedCard.commentPrimary
        }
      })
      return {
        cardScoreId,
        comment: newComment || '',
        score: newScore || 0,
        householdCardId
      }
    })
  )
}

export const sortAndFillCards = (householdCards: HouseholdCardObjInterface) => {
  const selectedHouseholdCards = Object.keys(householdCards)
    .sort((card1: string, card2: string) => {
      return householdCards[card1].ranking < householdCards[card2].ranking
        ? -1
        : 1
    })
    .map((key: string) => {
      return householdCards[key]
    })
  const displayCards: CardObj[] = Array<CardObj>(5).fill(null)
  selectedHouseholdCards.forEach((card: CardRankingObj) => {
    displayCards.splice(card.ranking - 1, 1)
    displayCards.splice(card.ranking - 1, 0, card.card)
  })
  return displayCards
}

export const getCombinedCardActions = (
  actions: FinancialGoalActionObj[],
  card: HouseholdCardObj
): LifeActionObj[] => {
  if (!actions) return null
  return Object.keys(actions)
    .map((key) => {
      const action = actions[key]
      const matchingCards = Object.keys(action.cards).filter(
        (cardKey) =>
          action.cards[cardKey] &&
          card?.card?.id &&
          card?.card?.id === action.cards[cardKey]?.id &&
          action.cards[cardKey].showCard
      )
      if (matchingCards.length > 0) {
        return action
      } else return null
    })
    .filter((action) => action)
}
export const mapScoresToCards = (
  contactId: string,
  activeSessionId: string,
  hcExercise: any
) => {
  const { clientScoringSessions } = hcExercise

  if (!activeSessionId) {
    return null
  }

  const cardScores =
    clientScoringSessions &&
    clientScoringSessions[contactId] &&
    clientScoringSessions[contactId][activeSessionId] &&
    clientScoringSessions[contactId][activeSessionId].cardScores

  return (
    cardScores &&
    Object.keys(cardScores)
      .sort((aScore: string, bScore: string) => {
        return cardScores[aScore].householdCard &&
          cardScores[bScore].householdCard &&
          cardScores[aScore].householdCard.ranking <
            cardScores[bScore].householdCard.ranking
          ? -1
          : 1
      })
      .map((key: string) => {
        cardScores[key].cardScoreId = key
        return cardScores[key]
      })
  )
}

export const setRanking = (cardsArray: CardRankingObj[]) => {
  const newCardsPriority: CardRankingObj[] = []
  cardsArray.map((card: CardRankingObj, index: number) => {
    newCardsPriority[index] = { ...card, ranking: index + 1 }
  })
  return newCardsPriority
}

/**
 * returns household cards for active exercise
 * @param household
 * @param hcExercises
 */
export const getActiveHouseholdCards = (
  household: HouseholdObj,
  hcExercises: HonestConversationsExerciseInterface
): HouseholdCardObj[] => {
  if (!hcExercises || !household?.hcExerciseId) {
    return null
  }

  if (!hcExercises[household.hcExerciseId]?.householdCards) {
    return null
  }

  return Object.keys(hcExercises[household.hcExerciseId].householdCards).map(
    (key: string) => {
      return hcExercises[household.hcExerciseId].householdCards[key]
    }
  )
}

/**
 * returns shared priorities card styles based on the width of the row the cards are contained in
 * @param cardRowWidth the width of the container/row that the cards resides in
 * @param category the category that the card belongs to
 */
export const getSharedPrioritiesCardStyles = (
  cardRowWidth: number,
  category: string
) => {
  const cardStyles: CSSProperties = {}
  if (cardRowWidth < 1030) {
    cardStyles.width = '180px'
  }
  if (cardRowWidth < 930) {
    cardStyles.width = '160px'
    cardStyles.padding = '0 5px'
  }
  if (cardRowWidth < 830) {
    cardStyles.width = '140px'
  }
  if (cardRowWidth < 730) {
    cardStyles.width = '120px'
  }
  // if there is no category, add a default background color, else remove background inline style (background will come from category)
  if (!category) {
    cardStyles.background = 'rgba(255,255,255,0.5)'
  } else delete cardStyles.background
  return cardStyles
}
