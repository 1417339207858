/* eslint-disable complexity */
import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { dollarFormat, dateFormat } from '../../helpers'
import { GlobalState } from '../../../reducers'
import TableRow from '../../components/layout/tableRow'
import { ToggleSwitch } from '../../components/toggleSwitch'
import {
  ClientAccountObj,
  ClientAccountObjState,
  staleBalanceClassifications
} from '../../../objects/clientAccount'
import * as householdActions from '../../../actions/households'
import * as clientActions from '../../../actions/clientAccounts'
import ActionDropdown from '../../components/actionDropdown'
import ConfirmDeleteAccountModal from './confirmDeleteAccountModal'
import UnmergeAccountsModal from './unmergeAccountsModal'

import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { ReactComponent as MergeIcon } from '../../assets/images/icons/svg/merge.svg'
import Tooltip from '../../components/tooltip'
import { errorMessage } from '../../helpers/clientAccounts'

export interface ClientAccountRowProps {
  isGuidebook?: boolean
  clientAccount: ClientAccountObj
  clientAccounts?: ClientAccountObjState
  householdFinId?: string
  dispatch?: Dispatch<GlobalState>
  onMergeAccounts?(): void
  onUnmergeAccounts?(): void
  onAccountSelected?(account: ClientAccountObj): void
}

interface ClientAccountRowState {
  clientAccount: ClientAccountObj
  deleteAcctModalShow: boolean
  unmergeAcctModalShow: boolean
  editAcctModalShow: boolean
  showHide: boolean
}

export class ClientAccountRow extends Component<
  ClientAccountRowProps,
  ClientAccountRowState
> {
  constructor(props: ClientAccountRowProps) {
    super(props)
    const { clientAccount } = props
    this.state = {
      clientAccount,
      deleteAcctModalShow: false,
      editAcctModalShow: false,
      unmergeAcctModalShow: false,
      showHide: clientAccount.includeInGuidebook
    }
  }

  public updateShowHide = async () => {
    const { clientAccount, householdFinId, dispatch } = this.props
    await dispatch(
      clientActions.updateClientAccount(clientAccount.id, householdFinId, {
        ...clientAccount,
        includeInGuidebook: !clientAccount.includeInGuidebook
      })
    )

    dispatch(householdActions.getIndividualHousehold(householdFinId))
  }

  public editAccount = () => {
    return (
      <Fragment>
        <EditIcon />
        <Link
          to={{
            pathname:
              '/households/' +
              this.props.householdFinId +
              '/clientAccount/' +
              this.props.clientAccount.id +
              '/networth'
          }}>
          <span className='action-dropdown__item-label'>Edit Account</span>
        </Link>
      </Fragment>
    )
  }

  public deleteAccount = () => {
    const { clientAccount } = this.props
    if (clientAccount.isDeduped) {
      const className = clientAccount.isDeduped
        ? 'client-accounts-tile--disabled'
        : ''
      return (
        <Tooltip
          message='Accounts must be ungrouped first.'
          position='bottom'
          multiLine={false}
          width={220}>
          <div className={className}>
            <DeleteIcon />
            <span className='action-dropdown__item-label'>Delete Account</span>
          </div>
        </Tooltip>
      )
    } else {
      return (
        <Fragment>
          <DeleteIcon />
          <span className='action-dropdown__item-label'>Delete Account</span>
        </Fragment>
      )
    }
  }

  public mergeAccount = () => {
    const { clientAccount } = this.props
    if (clientAccount.isDeduped) {
      const className = clientAccount.isDeduped
        ? 'client-accounts-tile--disabled'
        : ''
      return (
        <div className={className}>
          <MergeIcon />
          <span className='action-dropdown__item-label'>Group</span>
        </div>
      )
    } else {
      return (
        <Fragment>
          <MergeIcon />
          <span className='action-dropdown__item-label'>Group</span>
        </Fragment>
      )
    }
  }

  public unmergeAccount = () => {
    const { clientAccount } = this.props
    if (!clientAccount.isDeduped) {
      const className = !clientAccount.isDeduped
        ? 'client-accounts-tile--disabled'
        : ''
      return (
        <div className={className}>
          <MergeIcon />
          <span className='action-dropdown__item-label'>Ungroup</span>
        </div>
      )
    } else {
      return (
        <Fragment>
          <MergeIcon />
          <span className='action-dropdown__item-label'>Ungroup</span>
        </Fragment>
      )
    }
  }

  public openDeleteModal = () => {
    if (this.state.deleteAcctModalShow === false) {
      this.setState({ deleteAcctModalShow: true })
    }
  }

  public closeDeleteModal = () => {
    this.setState({ deleteAcctModalShow: false })
  }

  public openEditModal = () => {
    if (this.state.editAcctModalShow === false) {
      this.setState({ editAcctModalShow: true })
    }
  }

  public closeEditModal = () => {
    this.setState({ editAcctModalShow: false })
  }

  public openMergeAccountModal = () => {
    const { onMergeAccounts, onAccountSelected, clientAccount } = this.props
    onAccountSelected(clientAccount)
    onMergeAccounts()
  }

  public openUnmergeAccountModal = () => {
    const { onUnmergeAccounts, onAccountSelected, clientAccount } = this.props
    onAccountSelected(clientAccount)
    onUnmergeAccounts()
  }

  public closeUnmergeAccountModal = () => {
    this.setState({ unmergeAcctModalShow: false })
  }

  public dollarsFormatted = () => {
    const { clientAccount } = this.props
    const liabilityDollarNames = [
      'Short-Term Liabilities',
      'Long-Term Liabilities'
    ]
    return dollarFormat(
      clientAccount.totalValue,
      0,
      clientAccount.category &&
        liabilityDollarNames.indexOf(clientAccount.category) >= 0
    )
  }

  public errorMarker = () => {
    const { clientAccount } = this.props
    return (
      <Tooltip
        message={errorMessage(clientAccount)}
        width={160}
        position='bottom'
        multiLine={true}
        style={{ left: '6px' }}>
        <div className='client-accounts-tile__error-icon' />
      </Tooltip>
    )
  }

  public renderDrowpDownActions = () => {
    const { clientAccounts, clientAccount } = this.props
    const keys = clientAccounts && Object.keys(clientAccounts)
    let regularAccountsCount = 0
    let accountsWithYodleeSyncAttempetedCount = 0

    // if an account has yodleeSyncAttempted === false, then AC is not rendering this account.
    // we need to count how many accounts are rendering on the page.
    if (keys) {
      for (let i = 0; i < keys.length; i++) {
        const nestedObject = clientAccounts[keys[i]]
        if (nestedObject['yodleeSyncAttempted'] === undefined) {
          regularAccountsCount += Object.keys(nestedObject['clientAccounts'])
            .length
        }
        if (nestedObject['yodleeSyncAttempted'] === true) {
          accountsWithYodleeSyncAttempetedCount += Object.keys(
            nestedObject['clientAccounts']
          ).length
        }
      }
    }

    const accountsTotalCount =
      regularAccountsCount + accountsWithYodleeSyncAttempetedCount

    const isMergeAccountsEnabled = window._env_.REACT_APP_MERGE_ACCOUNTS_ENABLED
      ? clientAccount.isDeduped || !clientAccount.visibleAccountId
      : false

    const dropdownActions = [
      {
        element: this.editAccount(),
        onClick: this.openEditModal
      },
      {
        element: this.deleteAccount(),
        onClick: !clientAccount.isDeduped ? this.openDeleteModal : null
      }
    ]
    const dropdowActionMerge = [
      {
        element: this.mergeAccount(),
        onClick: !clientAccount.isDeduped ? this.openMergeAccountModal : null
      }
    ]

    const dropdownActionUnmerge = [
      {
        element: this.unmergeAccount(),
        onClick: clientAccount.isDeduped ? this.openUnmergeAccountModal : null
      }
    ]
    const dropdownActionsAll = [
      ...dropdownActions,
      ...dropdowActionMerge,
      ...dropdownActionUnmerge
    ]

    if (isMergeAccountsEnabled && accountsTotalCount > 1) {
      return dropdownActionsAll
    } else if (
      isMergeAccountsEnabled &&
      accountsTotalCount === 1 &&
      clientAccount.isDeduped
    ) {
      return dropdownActionsAll
    } else {
      return dropdownActions
    }
  }

  public render() {
    const { householdFinId, clientAccount, isGuidebook } = this.props
    const { deleteAcctModalShow, unmergeAcctModalShow } = this.state
    const toggleSwitchActive =
      clientAccount.guidebookClassification === null ||
      clientAccount.guidebookClassification === undefined
        ? false
        : clientAccount.includeInGuidebook
    const hasError =
      clientAccount?.errorCode &&
      clientAccount.errorCode !== '0' &&
      clientAccount.errorCode !== '801' &&
      clientAccount.errorCode !== '802'
    const isDatePastDue =
      moment(clientAccount.balanceDate).diff(moment(), 'days') <= -60 &&
      staleBalanceClassifications.includes(
        clientAccount.guidebookClassification
      )
    const showErrorMaker = (hasError || isDatePastDue) && !isGuidebook
    return (
      <TableRow>
        <div className='client-accounts-tile__column--name'>
          <div className='client-accounts-tile__account-name'>
            {clientAccount.name}{' '}
            {clientAccount.accountNumber ? (
              <span className='client-accounts-tile__account-number'>
                ({clientAccount.accountNumber})
              </span>
            ) : null}
            {clientAccount.isDeduped && !isGuidebook && (
              <Tooltip
                message='Grouped account'
                position='bottom'
                multiLine={false}
                width={125}>
                <span className='client-accounts-tile__account-merged'>
                  <MergeIcon />
                </span>
              </Tooltip>
            )}
          </div>
          {clientAccount.category ? (
            <div className='client-accounts-tile__category-label'>
              <span>{clientAccount.category}</span>
            </div>
          ) : null}
        </div>
        <div className='client-accounts-tile__column--type client-accounts-tile__account-type'>
          {clientAccount.registrationType}
        </div>
        <div className='client-accounts-tile__column--amount'>
          <div className='client-accounts-tile__amount'>
            <div
              className={`${
                showErrorMaker && !clientAccount.balanceDate
                  ? 'client-accounts-tile__amount-error'
                  : ''
              }`}>
              {this.dollarsFormatted()}
              {showErrorMaker &&
                !clientAccount.balanceDate &&
                this.errorMarker()}
            </div>
          </div>
          {clientAccount.balanceDate != null && (
            <div className='client-accounts-tile__date'>
              <div
                className={`${
                  showErrorMaker && clientAccount.balanceDate
                    ? 'client-accounts-tile__date-error'
                    : ''
                }`}>
                {dateFormat(clientAccount.balanceDate)}
                {showErrorMaker &&
                  clientAccount.balanceDate &&
                  this.errorMarker()}
              </div>
            </div>
          )}
        </div>
        <div className='client-accounts-tile__column--show-hide'>
          <ToggleSwitch
            active={toggleSwitchActive}
            handleToggleActive={this.updateShowHide}
          />
        </div>
        <div className='client-accounts-tile__column--actions'>
          <ActionDropdown actions={this.renderDrowpDownActions()} />
        </div>
        {deleteAcctModalShow ? (
          <ConfirmDeleteAccountModal
            householdFinId={householdFinId}
            clientId={clientAccount.id}
            closeModal={this.closeDeleteModal}
          />
        ) : null}
        {unmergeAcctModalShow ? (
          <UnmergeAccountsModal
            householdFinId={householdFinId}
            clientId={clientAccount.id}
            closeModal={this.closeUnmergeAccountModal}
          />
        ) : null}
      </TableRow>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { clientAccount, location }: any
) => {
  return {
    clientAccount,
    location,
    clientAccounts: store.clientAccount
  }
}

export default connect(mapStateToProps)(ClientAccountRow)
