import React, { FC } from 'react'
import Modal from '../../layout/modal'
import Button from '../../button'

export interface DeleteGoalModalProps {
  deleteModalText: string
  toggleDeleteModal(): void
  handleGoalDelete(): void
}

export const DeleteGoalModal: FC<DeleteGoalModalProps> = ({
  deleteModalText,
  toggleDeleteModal,
  handleGoalDelete
}) => {
  return (
    <Modal title='Delete Goal' closeModal={toggleDeleteModal}>
      <div className='delete-goal-modal__text'>{deleteModalText}</div>
      <div className='delete-goal-modal__button-w'>
        <Button clear={true} onClick={toggleDeleteModal}>
          Cancel
        </Button>
        <Button remove={true} onClick={handleGoalDelete}>
          Delete
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteGoalModal
