import React, { Component } from 'react'
import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { connect, Dispatch } from 'react-redux'
import qs from 'query-string'
import { history } from '../../../store'
import { withRouter } from 'react-router'
import moment from 'moment'
import InnerContainer from '../../components/layout/innerContainer'
import Tile from '../../components/layout/tile'
import BackToLink from '../../components/layout/headerBackToLink'
import Button from '../../components/button'
import ScoreGraph from '../../components/graphs/honestConversations/scoreGraph'
import EditScoresModal from './editScoresModal'
import { GlobalState } from '../../../reducers'
import { CardObj } from '../../../objects/HonestConversations/card'
import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'
import {
  HonestConversationsExerciseInterface,
  HonestConversationsExerciseObj,
  HouseholdCardObj
} from '../../../objects/honestConversations'
import { ContactsInterface } from '../../../objects/contact'
import { HouseholdObj } from '../../../objects/household'
import * as hcExerciseActions from '../../../actions/hcExercises'
import { getGoalActions } from '../../../actions/financialGoalActions'
import { getContacts } from '../../../actions/contacts'
import {
  filterGoals,
  getContactAvatars,
  getTileConfig,
  sortByDefault
} from '../../helpers/goals'
import {
  sortHouseholdCards,
  getScoringSession,
  isPrimaryScoringSession
} from '../../helpers/honestConversations'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/svg/check.svg'
import EditIcon from '../../assets/images/icons/general/edit-blue.png'
import { TaskDescriptionItem } from './exercise/results/ResultsSummaryComponents'
import goalActionsSelector from '../../../selectors/v3/goals'

interface ScoreStateObj {
  householdCardId: string
  score: number
  comment: string
  card: CardObj
}

interface ScorePreviewProps {
  location: Location
  dispatch?: Dispatch<GlobalState>
  match: any
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  household: HouseholdObj
  scoreId: string
  goals: FinancialGoalActionObj[]
}

interface ScorePreviewState {
  showEditScoresModal: boolean
  actionList: FinancialGoalActionObj[][]
  scores: ScoreStateObj[]
}

class ScorePreview extends Component<ScorePreviewProps, ScorePreviewState> {
  constructor(props: ScorePreviewProps) {
    super(props)
    this.state = {
      showEditScoresModal: false,
      actionList: null,
      scores: []
    }
  }
  public componentDidUpdate = (prevProps: ScorePreviewProps) => {
    const { honestConversation } = this.props
    if (!prevProps.honestConversation && honestConversation?.id) {
      this.mappingForDisplay()
    }
  }

  public async componentDidMount() {
    const { dispatch, householdFinId } = this.props
    dispatch(getContacts())
    await dispatch(
      hcExerciseActions.getHonestConversationExercises(householdFinId)
    )
    await dispatch(getGoalActions(householdFinId))
    this.mappingForDisplay()
  }

  public mappingForDisplay = () => {
    const { honestConversation, goals } = this.props
    if (honestConversation && honestConversation.householdCards) {
      const sortedHouseholdCards = sortHouseholdCards(
        honestConversation.householdCards
      )
      this.mapExistingHouseholdCards(sortedHouseholdCards)
      if (goals) {
        this.mapExistingLifeActions(sortedHouseholdCards)
      }
    }
  }

  public mapExistingHouseholdCards = (householdCards: HouseholdCardObj[]) => {
    const newObj: ScoreStateObj[] = []
    householdCards.forEach((card: HouseholdCardObj) => {
      newObj.push({
        householdCardId: card.id,
        card: card.card,
        score: null,
        comment: null
      })
    })
    this.setState({ scores: newObj }, () => {
      this.mapExistingScores()
    })
  }

  public mapExistingLifeActions = (householdCards: HouseholdCardObj[]) => {
    const { goals } = this.props
    const actionList: FinancialGoalActionObj[][] = []
    householdCards.forEach((card: HouseholdCardObj) => {
      const listOfActions = filterGoals(goals, card?.card?.id)
      actionList.push(listOfActions)
    })
    this.setState({ actionList })
  }

  public mapExistingScores = () => {
    const { honestConversation, match } = this.props
    const { scores } = this.state
    const scoringSession = getScoringSession(
      honestConversation,
      match.params.scoreId
    )
    const objCopy = [...scores]
    scoringSession?.cardScores &&
      Object.keys(scoringSession.cardScores).forEach((key) => {
        const scoreIndex = objCopy?.findIndex(
          (obj) =>
            obj.householdCardId ===
            scoringSession?.cardScores[key]?.householdCard?.id
        )
        if (
          scoringSession?.cardScores[key] &&
          scoringSession?.cardScores[key]?.score
        ) {
          objCopy[scoreIndex].score =
            scoringSession?.cardScores[key] &&
            scoringSession?.cardScores[key]?.score
        }
        if (
          scoringSession?.cardScores[key] &&
          scoringSession?.cardScores[key]?.comment
        ) {
          objCopy[scoreIndex].comment =
            scoringSession?.cardScores[key] &&
            scoringSession?.cardScores[key]?.comment
        }
      })
    this.setState({ scores: objCopy })
  }

  public openEditScoresModal = () => {
    this.setState({
      showEditScoresModal: true
    })
  }

  public closeEditScoresModal = () => {
    this.setState({
      showEditScoresModal: false
    })
  }

  public goBack = () => {
    const { householdFinId, honestConversation } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/meetings/${honestConversation.id}/scores`
    )
  }

  public leftHeader = () => {
    const scoreId = this.props?.scoreId || ''
    const scoreIdFormatted = scoreId
      .substring(0, scoreId.length - 31)
      .toUpperCase()
    return (
      <div className='score-preview__left-header'>
        <div className='score-preview__title'>SCORE-{scoreIdFormatted}</div>
        {this.isActiveScoringSession() && (
          <div className='score-preview__active-tag'>Active</div>
        )}
      </div>
    )
  }

  public isActiveScoringSession = () => {
    const { honestConversation, scoreId, contacts } = this.props
    const isPrimary = isPrimaryScoringSession(
      honestConversation,
      contacts,
      scoreId
    )
    return isPrimary && honestConversation?.activeScoringSessionPrimary
      ? honestConversation?.activeScoringSessionPrimary === scoreId
      : honestConversation?.activeScoringSessionSecondary
      ? honestConversation?.activeScoringSessionSecondary === scoreId
      : null
  }

  public updateActiveScoreSessionId = async () => {
    const {
      householdFinId,
      dispatch,
      household,
      match,
      honestConversation,
      contacts,
      scoreId
    } = this.props
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    if (isPrimaryScoringSession(honestConversation, contacts, scoreId)) {
      updatedProperty.activeScoringSessionPrimary = match.params.scoreId
    } else {
      updatedProperty.activeScoringSessionSecondary = match.params.scoreId
    }
    await dispatch(
      hcExerciseActions.updateHonestConversationExercise(
        householdFinId,
        household.hcExerciseId,
        updatedProperty
      )
    )
    this.goBack()
  }

  public renderActions = (actions: FinancialGoalActionObj[]) => {
    const { contacts, honestConversation } = this.props
    const lineItems = sortByDefault(getTileConfig(actions, honestConversation))

    return lineItems.map((action: FinancialGoalActionObj, index: number) => {
      const contactAvatars = getContactAvatars(
        action.assigneePrimaryId,
        action.assigneeSecondaryId,
        contacts
      )
      return (
        <TaskDescriptionItem
          key={index}
          index={index}
          longDescription={action.name}
          avatars={contactAvatars}
        />
      )
    })
  }

  public renderRow = () => {
    const { scores, actionList } = this.state
    return scores.map((score: ScoreStateObj, key: number) => {
      const actions = actionList && actionList[key] ? actionList[key] : null
      return (
        <div key={key} className='record-form__details-w'>
          <div className='record-form__card-col'>
            <Card category={score.card.category} title={score.card.title} />
          </div>
          <div className='record-form__score-graph'>
            <ScoreGraph score={score.score} />
          </div>
          <div className='record-form__score-action-col'>
            {actions && actions.length ? (
              this.renderActions(actions)
            ) : (
              <div className='record-form__action-null'>
                There are no goals for this card.
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  public render() {
    const { honestConversation, contacts, householdFinId, match } = this.props
    const { showEditScoresModal, scores } = this.state
    const primarySessions =
      honestConversation?.clientScoringSessions[contacts?.primary?.id]
    const isPrimaryContact =
      primarySessions &&
      Object.keys(primarySessions).includes(match.params.scoreId)
    const contactNameLabel =
      contacts?.primary && isPrimaryContact
        ? contacts?.primary?.firstName
        : primarySessions
        ? contacts?.secondary?.firstName
        : null
    const scoringSession = getScoringSession(
      honestConversation,
      match.params.scoreId
    )
    const hasHouseHoldCards = scores.every(
      (score: ScoreStateObj) => score.householdCardId
    )

    const scoreCreatedDate = () => {
      if (scoringSession?.createdDate) {
        return `Scores added ${moment(scoringSession.createdDate).format(
          'MM/DD/YY'
        )}`
      }
      return ''
    }

    return (
      <InnerContainer>
        <div className='score-preview__header-w'>
          <BackToLink style={{ margin: 0 }} onClick={this.goBack}>
            Back to Score History
          </BackToLink>
          <Button
            primary={true}
            style={{ opacity: this.isActiveScoringSession() ? 0.5 : 1 }}
            onClick={
              this.isActiveScoringSession()
                ? null
                : this.updateActiveScoreSessionId
            }>
            <CheckIcon />
            Set as Active
          </Button>
        </div>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          rightHeader={scoreCreatedDate()}
          leftHeader={this.leftHeader()}
          headerBorder={true}>
          {hasHouseHoldCards ? (
            <div className='record-form'>
              <div className='record-form__title-w'>
                <p className='record-form__title'> Shared priorities</p>
                <div className='record-form__name-w record-form__name-w--preview'>
                  <p className='record-form__name record-form__name--preview'>
                    {contactNameLabel}
                  </p>
                  <div
                    className='record-form__edit-btn-w'
                    onClick={this.openEditScoresModal}>
                    <img
                      className='record-form__edit-icon'
                      src={EditIcon}
                      alt='edit'
                    />
                    <div className='record-form__edit-text'>Edit</div>
                  </div>
                </div>
              </div>
              <div className='record-form__inner-w'>{this.renderRow()}</div>
            </div>
          ) : null}
        </Tile>
        {showEditScoresModal && (
          <EditScoresModal
            honestConversation={honestConversation}
            closeModal={this.closeEditScoresModal}
            householdFinId={householdFinId}
            scoringSessionId={match.params.scoreId}
            isPrimary={isPrimaryContact}
          />
        )}
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match, location }: any) => {
  const { householdFinId } = match.params
  const household = store.households[householdFinId]
  const exerciseId = qs.parse(location?.search)?.exerciseId
  const honestConversations: HonestConversationsExerciseInterface =
    store.hcExercises[householdFinId]
  const honestConversation: HonestConversationsExerciseObj =
    honestConversations && exerciseId
      ? honestConversations[exerciseId.toString()]
      : honestConversations && honestConversations[household?.hcExerciseId]
      ? honestConversations[household.hcExerciseId]
      : null
  return {
    goals: goalActionsSelector(store, householdFinId),
    householdFinId,
    household,
    contacts: store.contact[householdFinId],
    honestConversation,
    user: store.user,
    scoreId: match.params.scoreId,
    exerciseId
  }
}

export default withRouter(connect(mapStateToProps)(ScorePreview))
