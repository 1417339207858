import React, { Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import AllocationResults from './allocationResults'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderInterface,
  InvestmentViewfinderAllocationInterface,
  InvestmentViewfinderGeographicPriorityInterface
} from '../../../objects/investmentViewfinder'
import GeographicResults from './geographicResults'
import PriorityResults from './priorityResults'
import Button from '../../components/button'
import Tile from '../../components/layout/tile'
import PortfolioBuilderModal from '../investment/portfolioBuilderModal'
import * as portfolioBuilderActions from '../../../actions/portfolioBuilder'

interface ResultsProps {
  householdFinId: string
  exerciseId: string
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  geographicPriorities: InvestmentViewfinderGeographicPriorityInterface
  allocations: InvestmentViewfinderAllocationInterface
  dispatch: Dispatch<GlobalState>
}

interface ResultsState {
  showPortfolioBuilder: boolean
}

class Results extends React.Component<ResultsProps, ResultsState> {
  constructor(props: ResultsProps) {
    super(props)
    this.state = {
      showPortfolioBuilder: false
    }
  }
  public componentDidMount() {
    const { householdFinId } = this.props
    this.props.dispatch(
      portfolioBuilderActions.getPortfolioBuilders(householdFinId)
    )
  }
  public togglePortfolioBuilder = () => {
    const { showPortfolioBuilder } = this.state
    this.setState({ showPortfolioBuilder: !showPortfolioBuilder })
  }
  public openPlatformExplorer = () => {
    const link = window._env_.REACT_APP_IM_PORTAL_URL
    window.open(link)
  }
  public render() {
    const {
      exercises,
      exerciseId,
      householdFinId,
      allocations,
      geographicPriorities
    } = this.props
    const exercise = exercises[householdFinId][exerciseId]
    const targetAllocation = allocations[exercise.selectedAllocation]
    const geographicPriority =
      exercise.global > exercise.domestic
        ? geographicPriorities.Global
        : geographicPriorities.Domestic
    return (
      <Fragment>
        <ContentHeader title='Results' />
        <PriorityResults exercise={exercise} />
        <div className='results-tile-w'>
          <GeographicResults
            geographicPriority={geographicPriority}
            global={exercise.global}
            domestic={exercise.domestic}
          />
          <AllocationResults allocation={targetAllocation} />
        </div>
        <div className='results-tile-w'>
          <Tile>
            <div className='results__button-w'>
              <Button
                onClick={this.openPlatformExplorer}
                style={{ backgroundColor: '#F2F2F2', color: '#255EBA' }}>
                Explore Investment Options
              </Button>
              <Button
                style={{ backgroundColor: '#F2F2F2', color: '#255EBA' }}
                onClick={this.togglePortfolioBuilder}>
                Launch Portfolio Builder
              </Button>
            </div>
          </Tile>
        </div>
        {this.state.showPortfolioBuilder ? (
          <PortfolioBuilderModal
            closeModal={this.togglePortfolioBuilder}
            householdFinId={householdFinId}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    exercises: store.investmentViewfinder.exercises,
    allocations: store.investmentViewfinder.allocations,
    geographicPriorities: store.investmentViewfinder.geographicPriorities
  }
}

export default connect(mapStateToProps)(Results)
