import React from 'react'
import HcEditCombinedActionsRow from '../hcEditCombinedActions'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import handleImage from '../../../../assets/images/icons/ic_handle.png'
import { ContactsInterface } from '../../../../../objects/contact'
import {
  CardOptionsInterface,
  CardObj
} from '../../../../../objects/HonestConversations/card'
import { CardRankingObj } from '../../../../../objects/honestConversations'
import { LifeActionObj } from '../../../../../objects/lifeActions'

interface HonestConversationCombinedCardsProps {
  householdFinId: string
  contacts: ContactsInterface
  cards: CardRankingObj[]
  cardOptions: CardOptionsInterface
  onScoreChange(
    index: number,
    scoreKey: 'primaryScore' | 'secondaryScore',
    score: number
  ): void
  onCommentChange(
    index: number,
    commentKey: 'commentPrimary' | 'commentSecondary',
    comment: string
  ): void
  onModifyAction(index: number, actionList: LifeActionObj[]): void
  onCardSelection(index: number, contactType: string, card: CardObj): void
  onDrag?(result: DropResult): void
  onBlur?(contactType: string, index: number, card: CardObj): void
}

interface HonestConversationCombinedCardsState {
  disableInputs: boolean
}

class HonestConversationNewCombinedCards extends React.Component<
  HonestConversationCombinedCardsProps,
  HonestConversationCombinedCardsState
> {
  constructor(props: HonestConversationCombinedCardsProps) {
    super(props)
    this.state = {
      disableInputs: false
    }
  }

  public setDisableInput = (disableInputs: boolean) => {
    this.setState({
      disableInputs
    })
  }

  public onMouseDown = (providedProps: any) => (event: any) => {
    if (providedProps.dragHandleProps)
      providedProps.dragHandleProps.onMouseDown(event)

    if (event.defaultPrevented) event.currentTarget.focus()
  }

  public honestConversationRows = () => {
    const {
      cards,
      contacts,
      householdFinId,
      onScoreChange,
      cardOptions,
      onCardSelection,
      onCommentChange,
      onModifyAction
    } = this.props

    const { disableInputs } = this.state

    return cards.map((card, index) => {
      const key = card?.card?.id ? `card${card?.card?.id}` : `card${index}`
      return (
        <Draggable key={key} draggableId={key} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                ...provided.draggableProps.style
              }}
              className='hc-draggable-row__wrapper'>
              <div
                className='hc-draggable-row__handle'
                {...provided.dragHandleProps}
                onMouseDown={this.onMouseDown(provided)}>
                <img src={handleImage} alt='' role='presentation' />
              </div>
              <div className='hc-card-row__wrapper'>
                <HcEditCombinedActionsRow
                  displayIndex={index + 1}
                  householdFinId={householdFinId}
                  contacts={contacts}
                  key={key}
                  index={index}
                  card={card}
                  selectedCards={cards}
                  primaryScore={card.primaryScore}
                  commentPrimary={card.commentPrimary}
                  secondaryScore={card.secondaryScore}
                  commentSecondary={card.commentSecondary}
                  cardOptions={cardOptions}
                  onScoreChange={onScoreChange}
                  onCardSelection={onCardSelection}
                  onCommentChange={onCommentChange}
                  onModifyAction={onModifyAction}
                  editRecord={false}
                  setDisableInputs={this.setDisableInput}
                  disableInputs={disableInputs}
                />
              </div>
            </div>
          )}
        </Draggable>
      )
    })
  }

  public onDragEnd = (result: DropResult) => {
    if (!result || !result.destination) {
      return
    }
    this.props.onDrag(result)
  }

  public combinedCards = () => {
    const { contacts } = this.props
    if (contacts) {
      const { primary, secondary } = contacts
      const title = primary.firstName
        ? `${primary.firstName}'s Priorities`
        : 'Primary Priorities'
      return (
        <div>
          <div className='hc-meeting__sub-header'>
            <div className='hc-meeting__sub-header--title'>
              {secondary ? 'Shared Priorities' : title}
            </div>
            {primary && secondary ? (
              <div className='hc-meeting__sub-header--title hc-meeting__sub-header--title-names'>
                <div className='hc-meeting__sub-header--title-name-w'>
                  {secondary.firstName ? secondary.firstName : 'Secondary'}
                </div>
                <div className='hc-meeting__sub-header--title-name-w'>
                  {primary.firstName ? primary.firstName : 'Primary'}
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='combinedCards' direction='vertical'>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {this.props.cards ? this.honestConversationRows() : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )
    }
    return null
  }
  public render() {
    return <div>{this.combinedCards()}</div>
  }
}

export default HonestConversationNewCombinedCards
