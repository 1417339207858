import React from 'react'
import { ReactComponent as FinancialGoalsIcon } from '../../../assets/images/icons/score.svg'
import { ReactComponent as FinancialGoalsLockIcon } from '../../../assets/images/icons/lock.svg'
import { SlimFundedScoreGraph } from '../../../components/slimFundedScoreGraph'
import { ToggleSwitch } from '../../../components/toggleSwitch'
import Tooltip from '../../../components/tooltip'
import { staticText } from '../../../helpers/resources'
import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../../objects/financialGoal'

import TableRow from '../../../components/layout/tableRow'
import { dateFormat } from '../../../helpers'

interface FundedScoreHistoryRowProps {
  index: number
  keyId?: string | number
  planningSoftware: PlanningSoftwareType
  fundedScore: FundedScoreObj
  showhideFunc(fundedScore: FundedScoreObj, includeInGuideBook: boolean): void
  showModal(fundedScore: FundedScoreObj): void
}

class FundedScoreHistoryRow extends React.Component<
  FundedScoreHistoryRowProps
> {
  constructor(props: FundedScoreHistoryRowProps) {
    super(props)
  }

  public showFundedScoreDetailsModal = () => {
    const { showModal, fundedScore } = this.props
    showModal(fundedScore)
  }

  public toggleIncludeInGuidebook = () => {
    const { fundedScore } = this.props
    const includeInGuideBookToggle = !fundedScore.inGuidebook
    this.props.showhideFunc(fundedScore, includeInGuideBookToggle)
  }

  public render() {
    const { index, keyId, fundedScore, planningSoftware } = this.props
    return (
      <TableRow keyId={keyId}>
        <div className='funded-score-history__row'>
          <div className='funded-score-history__row-col-name'>
            <div
              onClick={this.showFundedScoreDetailsModal}
              className={
                index === 0
                  ? 'funded-score-history__row-col-name-icon funded-score-history__row-col-name-icon-active '
                  : 'funded-score-history__row-col-name-icon '
              }>
              <FinancialGoalsIcon />
            </div>

            <div className='funded-score-history__row-col-name-notes'>
              <div className='funded-score-history__display-name'>
                {fundedScore.name}
              </div>
              <div className='funded-score-history__display-notes'>
                {fundedScore.notes}
              </div>
            </div>
          </div>

          <div className='funded-score-history__row-col-graph'>
            <div className='funded-score-history__row-col-graph-content'>
              <SlimFundedScoreGraph
                rangeHigh={fundedScore.rangeHigh}
                rangeLow={fundedScore.rangeLow}
                score={fundedScore.score}
                planningSoftware={planningSoftware}
              />
            </div>
          </div>

          <div className='funded-score-history__row-col-toggle'>
            {index !== 0 ? (
              <ToggleSwitch
                active={fundedScore.inGuidebook}
                handleToggleActive={this.toggleIncludeInGuidebook}
              />
            ) : (
              <div className='funded-score-history__row-col-toggle funded-score-history__row-col-toggle-lock'>
                <span>
                  <Tooltip
                    message={staticText.linkedToCurrentGoal}
                    width={160}
                    position='bottom'
                    multiLine={true}>
                    <FinancialGoalsLockIcon />
                  </Tooltip>
                </span>
              </div>
            )}
          </div>

          <div className='funded-score-history__row-col-updated'>
            <div className='funded-score-history__display-updated'>
              {dateFormat(fundedScore.lastUpdated)}
            </div>
          </div>
        </div>
      </TableRow>
    )
  }
}

export default FundedScoreHistoryRow
