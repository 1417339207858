import React, { Component } from 'react'

import { PerformanceReturnGraphData } from '../../../../../objects/PortfolioAnalytics/analytics'

const performanceReturn = {
  'Current Portfolio': { backgroundColor: '#4D5479' }
}

const labelDataOrder = ['YTD', '1 Year', '3 Year', '5 Year']

const data = {
  labels: labelDataOrder
}

interface PerformanceSummaryProps {
  headerName: string
  barData: PerformanceReturnGraphData
}

class PerformanceSummary extends Component<PerformanceSummaryProps> {
  public performanceReturnMapper = () => {
    const { barData } = this.props
    const dataSet: {
      barPercentage: number
      label: string
      backgroundColor: string
      data: number[]
    }[] = []
    Object.keys(barData).forEach((dataKey: string) => {
      const { ytd, oneYear, threeYear, fiveYear } = barData[dataKey]
      const backgroundColor = performanceReturn[dataKey]
        ? performanceReturn[dataKey].backgroundColor
        : '#80BDEE'

      const dataValues = [ytd, oneYear, threeYear, fiveYear]

      dataValues.reduce((sum: number, num: number) => {
        return sum + num
      }) > 0 &&
        dataSet.push({
          barPercentage: 0.8,
          label: dataKey,
          backgroundColor,
          data: dataValues
        })
    })

    return { ...data, datasets: dataSet }
  }

  public render = () => {
    return <div></div>
  }
}

export default PerformanceSummary
