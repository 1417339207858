import React, { Fragment } from 'react'
import InnerContainer from '../../../components/layout/innerContainer'
import mgpLogo from '../../../assets/images/logos/mgp-logo@2x.png'
import gdxLogo from '../../../assets/images/logos/gdx-360-logo.svg'
import Button from '../../../components/button'
import { history } from '../../../../store'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import ContactsTile from './contactsTile'
import FinancialPlanningTile from './financialPlanningTile'
import ClientAccountInformation from './clientAccountInformation'
import Benchmark from './benchmark'
import { ClientAccountObjState } from '../../../../objects/clientAccount'
import ChangeWarning from './changeWarning'
import { HouseholdObj } from '../../../../objects/household'
import { PlanningSoftwareTypeObj } from '../../../../objects/financialGoal'
import { ContactsInterface } from '../../../../objects/contact'
import {
  OfficeTeamsInterface,
  OfficeTeamObj
} from '../../../../objects/officeTeams'

import * as officeTeamActions from '../../../../actions/officeTeams'
import { addToast } from '../../../../actions/toasts'
import {
  getIndividualHousehold,
  getOfficeTeamByHousehold
} from '../../../../actions/households'

interface AssignedAdvisorObj {
  id: string
  mgpId: string
  firstName: string
  lastName: string
  email: string
}

interface FinancialPlanningProps {
  householdFinId: string
  clientAccounts: ClientAccountObjState
  household: HouseholdObj
  contacts: ContactsInterface
  dispatch: Dispatch<GlobalState>
}

interface FinancialPlanningState {
  next: boolean
}

class FinancialPlanning extends React.Component<
  FinancialPlanningProps,
  FinancialPlanningState
> {
  constructor(props: FinancialPlanningProps) {
    super(props)
    this.state = {
      next: false
    }
  }
  public componentDidMount() {
    const { dispatch, householdFinId } = this.props
    dispatch(officeTeamActions.getOfficeTeams())
    dispatch(getIndividualHousehold(householdFinId))
    dispatch(getOfficeTeamByHousehold(householdFinId))
  }

  public handleBack = () => {
    const { next } = this.state
    const { householdFinId } = this.props
    if (!next) {
      history.push(`/households/${householdFinId}/financialGoals`)
    } else {
      this.setState({
        next: false
      })
    }
  }
  public handleNext = () => {
    this.setState({
      next: true
    })
  }
  public finish = () => {
    const { household, householdFinId } = this.props
    const officeTeam = household.officeTeams.find(
      (team) => team.id === household.officeTeam
    )
    const advisorId = officeTeam && officeTeam.planningSoftwareUserId
    const advisor =
      advisorId &&
      officeTeam.members.find((member) => member.userId === advisorId)
    let url = ''
    switch (household.planningSoftware) {
      case PlanningSoftwareTypeObj.moneyGuidePro:
        url = `${window._env_.REACT_APP_MGP_URL}?v1AdvisorId=${advisor.user &&
          advisor.user.mgpId}&v1InstitutionId=${household.institution &&
          household.institution.mgpId}&v1HouseholdId=${
          household.planningSoftwareId
        }`
        break
      case PlanningSoftwareTypeObj.gdx360:
        url = `${window._env_.REACT_APP_GDX_URL}?v1AdvisorId=${advisor.user &&
          advisor.user.mgpId}&v1HouseholdId=${household.planningSoftwareId}`
    }
    window.open(url)
    history.push(`/households/${householdFinId}/financialGoals`)
  }
  public planningSoftwareUserError = () => {
    this.props.dispatch(
      addToast({
        message: 'Please select an advisor',
        backgroundColor: '#F12938'
      })
    )
  }

  public getAssignedAdvisor = () => {
    const { household } = this.props
    const { officeTeams } = household

    const officeTeamMapped: OfficeTeamsInterface = {}
    if (officeTeams) {
      officeTeams.forEach((officeTeamObj: OfficeTeamObj, index: number) => {
        officeTeamMapped[officeTeamObj.id] = officeTeamObj
      })
    }

    const advisorId =
      officeTeamMapped[household.officeTeam]?.planningSoftwareUserId
    let assignedAdvisor: AssignedAdvisorObj = null
    if (officeTeamMapped[household.officeTeam]) {
      const assignedTeam = officeTeamMapped[household.officeTeam].members
      assignedTeam.forEach((member) => {
        if (member.userId === advisorId) {
          assignedAdvisor = {
            email: member.user.email,
            firstName: member.user.firstName,
            lastName: member.user.lastName,
            id: member.user.id,
            mgpId: member.user.mgpId
          }
        }
      })
    }
    return assignedAdvisor
  }

  public renderHeaderNav = () => {
    const { household } = this.props
    const { next } = this.state
    const assignedAdvisor: AssignedAdvisorObj = this.getAssignedAdvisor()

    let primeBtn = (
      <Button onClick={this.planningSoftwareUserError}>Next</Button>
    )
    if (assignedAdvisor) {
      primeBtn = !next ? (
        <Button primary={true} onClick={this.handleNext}>
          Next
        </Button>
      ) : (
        <Button primary={true} onClick={this.finish}>
          Finish
        </Button>
      )
    }
    const logo =
      household.planningSoftware === PlanningSoftwareTypeObj.moneyGuidePro
        ? mgpLogo
        : household.planningSoftware === PlanningSoftwareTypeObj.gdx360
        ? gdxLogo
        : null
    return (
      <Fragment>
        <div className='financial-planning__header-title'>
          <img src={logo} alt='planning software logo' />
          <div className='financial-planning__header-divider' />
          <h3 className='financial-planning__header-text'>{household.name}</h3>
        </div>
        <div>
          <div className='financial-planning__header-btns-w'>
            <Button primary={false} onClick={this.handleBack}>
              Back
            </Button>
            {primeBtn}
          </div>
        </div>
      </Fragment>
    )
  }
  public renderFirstPage = () => {
    const { contacts } = this.props
    const assignedAdvisor: AssignedAdvisorObj = this.getAssignedAdvisor()
    return (
      <Fragment>
        <FinancialPlanningTile assignedAdvisor={assignedAdvisor} />
        {contacts && <ContactsTile contacts={contacts} />}
      </Fragment>
    )
  }
  public renderSecondPage = () => {
    const { householdFinId, clientAccounts, household } = this.props
    return (
      <Fragment>
        <ChangeWarning />
        {household.planningSoftware !== PlanningSoftwareTypeObj.gdx360 ? (
          <Benchmark householdFinId={householdFinId} household={household} />
        ) : null}
        <ClientAccountInformation
          householdFinId={householdFinId}
          clientAccounts={clientAccounts}
        />
      </Fragment>
    )
  }
  public render() {
    const { next } = this.state
    return (
      <div className='financial-planning'>
        <div className='financial-planning__header'>
          {this.renderHeaderNav()}
        </div>
        <div className='financial-planning__progress-bar-w'>
          <div
            className='financial-planning__progress-bar financial-planning__progress-bar--green'
            style={{ width: !next ? '50%' : '100%' }}
          />
          <div className='financial-planning__progress-bar' />
        </div>
        <InnerContainer>
          {!next ? this.renderFirstPage() : this.renderSecondPage()}
        </InnerContainer>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  return {
    householdFinId,
    household: store.households[householdFinId],
    clientAccounts:
      store.clientAccount[householdFinId] &&
      store.clientAccount[householdFinId].clientAccounts,
    contacts: store.contact[householdFinId]
  }
}

export default connect(mapStateToProps)(FinancialPlanning)
