import React, { Component } from 'react'
import { revokeAndLogout } from '../../helpers'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import Button from '../../components/button'
import Modal from '../../components/layout/modal'

interface TimeoutModalProps {
  meetingMode: {
    meetingMode: boolean
  }
}

interface TimeoutModalState {
  display: boolean
  sessionTimer: any
  modalTime: number
}

class TimeoutModal extends Component<TimeoutModalProps, TimeoutModalState> {
  private timeoutMins =
    parseInt(window._env_.REACT_APP_SESSION_TIMEOUT_MINUTES, 10) * 1000 * 60
  private timeoutMinsMeetingMode =
    parseInt(window._env_.REACT_APP_SESSION_MEETING_MODE_TIMEOUT_MINUTES, 10) *
    1000 *
    60

  private modalTimeoutSec = parseInt(
    window._env_.REACT_APP_SESSION_WARNING_SECONDS,
    10
  )

  private events = [
    'mousemove',
    'wheel',
    'keypress',
    'scroll',
    'click',
    'touchstart'
  ]

  constructor(props: any) {
    super(props)
    this.state = {
      display: false,
      sessionTimer: null,
      modalTime: this.modalTimeoutSec
    }
  }

  public componentDidMount() {
    if (
      (window._env_.REACT_APP_SESSION_TIMEOUT_MINUTES &&
        window._env_.REACT_APP_SESSION_TIMEOUT_MINUTES) ||
      (window._env_.REACT_APP_SESSION_MEETING_MODE_TIMEOUT_MINUTES &&
        window._env_.REACT_APP_SESSION_MEETING_MODE_TIMEOUT_MINUTES)
    ) {
      this.events.forEach((e: string) => {
        const throttleHandler = this.throttled(1000, this.resetTimeout(false))
        const debouncedHandler = this.debounced(400, this.resetTimeout(false))
        if (e === 'mousemove') window.addEventListener(e, throttleHandler)
        else if (e === 'wheel' || e === 'keypress' || e === 'scroll')
          window.addEventListener(e, debouncedHandler)
        else window.addEventListener(e, this.resetTimeout(false))
      })
      this.setTimeout()
    }
  }

  public throttled = (delay: number, cb: any) => {
    let lastCall = 0
    return (...args: any[]) => {
      const now = new Date().getTime()
      if (now - lastCall < delay) return
      lastCall = now
      return cb(...args)
    }
  }

  public debounced = (delay: number, cb: any) => {
    let timerId: any
    return (...args: any[]) => {
      if (timerId) {
        clearTimeout(timerId)
      }
      timerId = setTimeout(() => {
        cb(...args)
        timerId = null
      }, delay)
    }
  }

  public componentWillUnmount() {
    this.events.forEach((e: string) =>
      window.removeEventListener(e, () => null)
    )
    clearTimeout(this.state.sessionTimer)
    clearInterval(this.state.modalTime)
  }

  public setTimeout() {
    const { meetingMode } = this.props
    clearTimeout(this.state.sessionTimer)
    const sessionTimer = setTimeout(
      this.setDisplay,
      meetingMode && meetingMode.meetingMode === true
        ? this.timeoutMinsMeetingMode
        : this.timeoutMins
    )
    this.setState({ sessionTimer })
  }

  public resetTimeout = (closeClick: boolean) => () => {
    if (closeClick) this.setState({ display: false })
    this.setTimeout()
  }

  public setDisplay = () => {
    this.setState({ display: true })
    const modalTimer = setInterval(() => {
      const { modalTime, display } = this.state
      if (modalTime >= 1) {
        this.setState({ modalTime: modalTime - 1 })
      }
      if (modalTime === 0) {
        this.handleLogout()
      } else if (!display) {
        clearInterval(modalTimer)
        this.setState({ modalTime: this.modalTimeoutSec })
      }
    }, 1000)
  }

  public handleLogout = () => {
    sessionStorage.setItem('redirect', window.location.pathname)
    revokeAndLogout()
  }

  public render() {
    const { display, modalTime } = this.state
    if (display) {
      return (
        <Modal
          modalStyle={{ zIndex: 999 }}
          largeHeader
          title='Are you still there?'
          closeModal={this.resetTimeout(true)}>
          <div className='modal__content' style={{ paddingTop: 24 }}>
            <p>
              Due to inactivity, you will automatically be signed out in{' '}
              <span
                style={
                  modalTime <= 10 ? { color: '#DC3545' } : { color: '#000' }
                }>
                {modalTime}
              </span>{' '}
              seconds.
            </p>
            <br />
            <p>
              To continue your session, select <span>Stay signed in</span>. If
              you wish to end your session now, select Sign Out.
            </p>
          </div>
          <div className='modal__buttons modal__buttons--w'>
            <Button onClick={this.handleLogout}>Sign Out</Button>
            <Button primary={true} onClick={this.resetTimeout(true)}>
              Stay Signed In
            </Button>
          </div>
        </Modal>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    meetingMode: store.meetingMode
  }
}

export default connect(mapStateToProps)(TimeoutModal)
