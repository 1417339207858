import React from 'react'
import { UserObj } from '../../../objects/user'

import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg'
import { ReactComponent as MessageIcon } from '../../assets/images/icons/messages.svg'
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/phone.svg'
import { ReactComponent as BriefcaseIcon } from '../../assets/images/icons/briefcase.svg'
import ProfilePicture from '../../components/layout/profilePicture'

interface ShowProfileProps {
  user: UserObj
}

class ShowProfile extends React.Component<ShowProfileProps> {
  constructor(props: ShowProfileProps) {
    super(props)
  }

  public render() {
    if (!this.props.user) {
      return null
    }
    const {
      firstName,
      lastName,
      email,
      title,
      street,
      city,
      state,
      zip,
      home,
      mobile,
      photo
    } = this.props.user
    return (
      <div className='a-profile__info-w'>
        <div className='a-profile__info-main'>
          <div className='a-profile__info-pic'>
            <ProfilePicture
              firstName={firstName}
              lastName={lastName}
              isPrimary={true}
              photo={photo}
              pictureSize={75}
            />
          </div>
          <div className='a-profile__info-name'>
            {firstName} {lastName}
            <div className='a-profile__info-title'>{title}</div>
          </div>
        </div>
        <div className='a-profile__info-details-main'>
          <div>
            <span>
              <MessageIcon />
            </span>
            {email}
          </div>
          <div>
            <span>
              <PhoneIcon />
            </span>
            {home}
          </div>
          <div>
            <span>
              <LocationIcon />
            </span>
            {street || null}
            <br />
            {city || null}
            {city && state ? ', ' : null}
            {state || null} {zip || null}
          </div>
          <div>
            <span>
              <BriefcaseIcon />
            </span>
            {mobile}
          </div>
        </div>
      </div>
    )
  }
}

export default ShowProfile
