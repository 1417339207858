import React, { Component, CSSProperties, ReactNode } from 'react'

import { PortfolioAnalyticsModalProps } from '../../../../../../objects/PortfolioAnalytics/analytics'

interface DisplayProps extends PortfolioAnalyticsModalProps {
  modalStyle?: CSSProperties
  className?: string
  iconPng?: string
  style?: CSSProperties
  headerStyle?: CSSProperties
  contentStyle?: CSSProperties
  modalOverlayClass?: string
  centerModal?: boolean
  children?: ReactNode | JSX.Element | JSX.Element[] | string
  size?: 'S' | 'M' | 'L' | 'XL'
  largeHeader?: boolean
}

export class Display extends Component<DisplayProps> {
  public render = () => {
    const {
      children,
      centerModal,
      modalOverlayClass = '',
      modalStyle,
      className = '',
      size = 'M'
    } = this.props

    const modalBoxW = centerModal
      ? 'modal__box-w modal__box-w--center'
      : 'modal__box-w'

    const overlay = `${className} modal__overlay ${modalOverlayClass}`
    const modalbox = `${className} ${modalBoxW}`

    return (
      <div className={overlay} style={modalStyle}>
        <div
          className={modalbox}
          style={{
            maxWidth:
              {
                S: { width: 430 },
                M: { width: 600 },
                L: { width: 820 },
                XL: { width: 890 }
              }[size].width || 600
          }}>
          {children}
        </div>
      </div>
    )
  }
}

export default Display
