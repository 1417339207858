import * as actions from '../actions/showAlert'

export interface ShowAlertState {
  show: boolean
  message: string
  isSuccess: boolean
}

export const initState: ShowAlertState = {
  show: false,
  message: null,
  isSuccess: false
}

const ShowAlert = (state = initState, action: any): ShowAlertState => {
  switch (action.type) {
    case actions.SHOW_ALERT_MODAL:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }
    case actions.HIDE_ALERT_MODAL:
      return {
        ...state,
        show: action.payload.show
      }
    default:
      return state
  }
}
export default ShowAlert
