import {
  IMStrategiesObj,
  IMClassifiedStrategiesInterface
} from '../../objects/strategies'

import {
  StrategySearchState,
  accountMinimumMap
} from '../../objects/strategySearch'

/**
 *
 * @param numberValue number
 * @param rangeKeys array of keys
 * @return boolean
 *
 */
const withinRange = (numberValue: number, rangeKeys: string[]): boolean => {
  return rangeKeys.some((rangeKey: string) => {
    return (
      accountMinimumMap[rangeKey] &&
      accountMinimumMap[rangeKey].min <= numberValue &&
      numberValue <= accountMinimumMap[rangeKey].max
    )
  })
}
/**
 * Provides Strategies classified objects
 * @param strategySearch StrategySearchState
 * @param strategies IMStrategies
 * @return object of strategies keyed by strategy type managedStrategy | assetClassL1
 *
 */
export const makeClassifiedStrategies = (
  strategySearch: StrategySearchState,
  strategies: IMStrategiesObj[]
) => {
  const {
    assetClassL2,
    managedStrategies: filterManagedStrategies = []
  } = strategySearch.filter

  const classifiedStrategies: IMClassifiedStrategiesInterface = {}
  if (!strategies) {
    return classifiedStrategies
  }

  strategies.forEach((strategy: IMStrategiesObj) => {
    const { assetClassL1, managedStrategies } = strategy
    if (managedStrategies) {
      if (!assetClassL2.length && !classifiedStrategies[managedStrategies]) {
        classifiedStrategies[managedStrategies] = []
      }
      if (classifiedStrategies[managedStrategies]) {
        classifiedStrategies[managedStrategies].push(strategy)
      }
    }

    if (assetClassL1) {
      if (
        !filterManagedStrategies.length &&
        !classifiedStrategies[assetClassL1]
      ) {
        classifiedStrategies[assetClassL1] = []
      }
      if (classifiedStrategies[assetClassL1]) {
        if (!strategy.managedStrategies) {
          classifiedStrategies[assetClassL1].push(strategy)
        } else {
          assetClassL2.length &&
            classifiedStrategies[assetClassL1].push(strategy)
        }
      }
    }
  })

  return classifiedStrategies
}

/**
 * OR within filter group
 * AND across filters
 * @param strategySearch StrategySearchState
 * @return strategy[]
 */
export const filterStrategyState = (strategySearch: StrategySearchState) => {
  const { strategies, filter } = strategySearch
  const someExist = (
    argParam: string | number | string[],
    filterFor: string[]
  ): boolean => {
    switch (typeof argParam) {
      case 'string':
        return filterFor.some((arg) => {
          return arg === argParam
        })
      case 'number':
        return withinRange(argParam, filterFor)
      case 'object':
        return argParam.some((arg) => {
          return filterFor.includes(arg)
        })
      case 'boolean':
        return filterFor.some((arg) => {
          return arg === argParam
        })
      default:
        return false
    }
  }

  return strategies.filter((strategy: IMStrategiesObj) => {
    const filterCriteria: boolean[] = []
    Object.keys(filter)
      .filter((filterName: string) => {
        return filter[filterName]?.length
      })
      .forEach((criteria: string) => {
        filterCriteria.push(
          // true | false are valid filter values for esg filter(reason for specific null/undefined check)
          strategy[criteria] !== null &&
            strategy[criteria] !== undefined &&
            someExist(strategy[criteria], filter[criteria])
        )
      })

    return filterCriteria.every((isTrue: boolean) => isTrue)
  })
}

/**
 *
 * @param searchBarValue
 * string
 * @param strategies
 * IMStragiesObj[]
 * ?= (Positive Lookahead, google it)
 */
export const searchByName = (
  searchBarValue: string,
  strategies: IMStrategiesObj[]
) => {
  if (!searchBarValue.trim().length) return strategies
  return strategies.filter((strategy: IMStrategiesObj) => {
    const { name } = strategy
    const inputPattern = searchBarValue
      .trim()
      .replace(/(?=[^a-zA-Z0-9])/g, '\\')
    return name.match(RegExp(inputPattern, 'gi'))
  })
}
