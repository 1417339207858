import {
  ClientAccountObj,
  staleBalanceClassifications
} from '../../objects/clientAccount'
import moment from 'moment'

export const maskAccountNumber = (accountNumber: string) => {
  let maskedAccountNumber
  if (accountNumber && accountNumber.length > 4) {
    maskedAccountNumber = '*****' + accountNumber.slice(-4)
    return maskedAccountNumber
  } else return accountNumber
}

export const rollupOriginalAllocations = (clientAccount: ClientAccountObj) => {
  const { positions } = clientAccount
  const length = Object.keys(positions).length
  const allocationObj = {
    equity: 0,
    fixed: 0,
    cash: 0,
    alt: 0,
    unclassified: 0
  }
  if (length === 0) {
    allocationObj.unclassified = 100
  } else if (positions && length >= 1) {
    Object.keys(positions).forEach((position) => {
      const pos = positions[position]
      if (pos.equityPercent !== 0) {
        allocationObj.equity += pos.equityPercent
      }
      pos.fixedPercent !== 0
        ? (allocationObj.fixed += pos.fixedPercent)
        : (allocationObj.fixed += 0)
      pos.cashPercent !== 0
        ? (allocationObj.cash += pos.cashPercent)
        : (allocationObj.cash += 0)
      pos.altPercent !== 0
        ? (allocationObj.alt += pos.altPercent)
        : (allocationObj.alt += 0)
      pos.unclassifiedPercent !== 0
        ? (allocationObj.unclassified += pos.unclassifiedPercent)
        : (allocationObj.unclassified += 0)
    })
    Object.keys(allocationObj).forEach(
      (val) => (allocationObj[val] = Math.round(allocationObj[val] / length))
    )
  }
  return allocationObj
}

export const errorMessage = (clientAccount: ClientAccountObj) => {
  const isDatePastDue =
    moment(clientAccount.balanceDate).diff(moment(), 'days') <= -60 &&
    staleBalanceClassifications.includes(clientAccount.guidebookClassification)
  const technicalDifficultyErrorCode = [
    '418',
    '459',
    '508',
    '517',
    '424',
    '425',
    '431',
    '432',
    '426',
    '403',
    '404',
    '413',
    '419',
    '507',
    '601',
    '708',
    '416',
    '401',
    '409',
    '412',
    '415',
    '405',
    '411',
    '510',
    '511',
    '430',
    '417',
    '422',
    '505',
    '414',
    '709',
    '504',
    '811'
  ]
  const recredentialErrorCode = [
    '423',
    '406',
    '407',
    '427',
    '428',
    '429',
    '402',
    '420',
    '410',
    '506',
    '421',
    '512',
    '408',
    '509',
    '518',
    '519',
    '520',
    '521',
    '522',
    '523',
    '524',
    '525',
    '526',
    '433',
    '434',
    '435',
    '436'
  ]
  return clientAccount?.errorCode &&
    technicalDifficultyErrorCode.find(
      (code) => code === clientAccount.errorCode
    )
    ? 'Our aggregation partner is experiencing technical difficulties.'
    : clientAccount?.errorCode &&
      recredentialErrorCode.find((code) => code === clientAccount.errorCode)
    ? 'Credentials must be updated through Yodlee in GuideCenter to re-link this account.'
    : isDatePastDue
    ? 'Balance out of date'
    : 'Requires Attention'
}

export const getUpdatedProps = (prevProps: any, currentProps: any) => {
  const keys = Object.keys(currentProps)
  const updatedProps = {}
  keys.forEach((key) => {
    const itemType = Object.prototype.toString.call(prevProps[key])
    if (['[object Array]', '[object Object]'].includes(itemType)) {
      if (key === 'ownerIds' && prevProps[key] !== currentProps[key]) {
        updatedProps[key] = currentProps[key]
      } else {
        updatedProps[key] = getUpdatedProps(prevProps[key], currentProps[key])
      }
    } else {
      if (itemType === '[object Function]') {
        if (prevProps[key].toString() !== currentProps[key].toString())
          updatedProps[key] = currentProps[key]
      } else {
        if (prevProps[key] !== currentProps[key])
          updatedProps[key] = currentProps[key]
      }
    }
  })
  return updatedProps
}
