import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'

import { GlobalState } from '../../../reducers'

import FilterDropDown from './filterDropdown'
import Button from '../../components/button'
import FilterIcon from '../../assets/images/icons/png/filter_blue_x2.png'

import { TaskFilterObj } from '../../../objects/taskFilter'
import FilterToggleRow from './filterToggleRow'
import { setTaskFilters } from '../../../actions/taskFilter'
import { TaskObj } from '../../../objects/tasks'

export interface TaskFilterProps {
  taskFilter: TaskFilterObj
  tasks: TaskObj[]
  dispatch: Dispatch<GlobalState>
  disableTasks: boolean
  toggleFilterDropdown(): void
  handleFilterToggle(): void
  onApply(): void
}

interface TaskFilterState {
  showLinkedTaskType: boolean
  showNativeTaskType: boolean
  selectedStatuses: string[]
  selectedAssignees: string[]
}

export class TaskFilter extends Component<TaskFilterProps, TaskFilterState> {
  constructor(props: TaskFilterProps) {
    super(props)
    this.state = {
      showLinkedTaskType: props.taskFilter.showLinkedTaskType,
      showNativeTaskType: props.taskFilter.showNativeTaskType,
      selectedStatuses: props.taskFilter.selectedStatuses,
      selectedAssignees: props.taskFilter.selectedAssignees
    }
  }

  public handleFilterToggle = (taskType: string) => {
    const elementName = 'show' + taskType + 'TaskType'
    this.setState((prevState) => ({
      ...prevState,
      [elementName]: !this.state[elementName]
    }))
  }

  public handleCancel = () => {
    const { toggleFilterDropdown } = this.props
    toggleFilterDropdown()
  }

  public renderLeftHeader = () => {
    return (
      <div className='task-filter__header'>
        <div className='task-filter__header-label'>
          <img src={FilterIcon} width={20} alt='filter' />
          <span className='task-filter__header-text'>FILTER</span>
        </div>
        <Button clear={true} onClick={this.resetFilters}>
          Reset
        </Button>
      </div>
    )
  }

  public updateSelectedStatuses = (value: string[]) => {
    this.setState({ selectedStatuses: value })
  }

  public updateSelectedAssignees = (value: string[]) => {
    this.setState({ selectedAssignees: value })
  }

  public resetFilters = () => {
    const { dispatch } = this.props
    dispatch(setTaskFilters(true, true, [], []))
    this.setState({
      showNativeTaskType: true,
      showLinkedTaskType: true,
      selectedAssignees: [],
      selectedStatuses: []
    })
  }

  public handleSubmit = () => {
    const { dispatch, onApply } = this.props

    const {
      showLinkedTaskType,
      showNativeTaskType,
      selectedStatuses,
      selectedAssignees
    } = this.state

    dispatch(
      setTaskFilters(
        showLinkedTaskType,
        showNativeTaskType,
        selectedStatuses,
        selectedAssignees
      )
    )

    onApply()
  }

  public render() {
    const { disableTasks, tasks } = this.props

    const statusList = new Set<string>()
    const assigneeList = new Set<string>()
    let hasLinkedTask = false
    tasks.map((task) => {
      if (!task.crmId || !task.isCrmTask) {
        statusList.add(task.status)
      } else {
        hasLinkedTask = true
        statusList.add(task.status)
      }
      if (task.assigneeNames) {
        assigneeList.add(task.assigneeNames)
      }
    })

    const statusOptions: { name: string; id: string }[] = []
    const assigneeOptions: { name: string; id: string }[] = []
    statusList.forEach((status) => {
      statusOptions.push({ name: status, id: status })
    })
    assigneeList.forEach((assignee) => {
      assigneeOptions.push({ name: assignee, id: assignee })
    })
    const {
      selectedAssignees,
      selectedStatuses,
      showLinkedTaskType,
      showNativeTaskType
    } = this.state

    return (
      <div className='task-filter'>
        <div>{this.renderLeftHeader()}</div>
        <div className='task-filter__body'>
          <div>
            <div className='task-filter-sub-header'>Task Type</div>

            <FilterToggleRow
              label='Native'
              name='Native'
              handleToggle={this.handleFilterToggle}
              active={showNativeTaskType}
              disable={false}
            />

            <FilterToggleRow
              label='Linked'
              name='Linked'
              handleToggle={this.handleFilterToggle}
              active={
                disableTasks || !hasLinkedTask ? false : showLinkedTaskType
              }
              disable={disableTasks}
            />

            <FilterDropDown
              title='Status'
              updateSelectedItems={this.updateSelectedStatuses}
              options={statusOptions}
              selectedOptions={selectedStatuses}
            />

            <FilterDropDown
              title='Assignee'
              updateSelectedItems={this.updateSelectedAssignees}
              options={assigneeOptions}
              selectedOptions={selectedAssignees}
            />
          </div>
          <div className='task-filter-buttons'>
            <Button clear={true} onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button primary onClick={this.handleSubmit}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    taskFilter: store.taskFilter
  }
}
const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskFilter)
