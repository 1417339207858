import React, { Component, CSSProperties, Fragment } from 'react'
import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'

import Holdings from '../holdings'
import {
  ClientAccountObjState,
  ClientAccountObj,
  emptyAccount,
  ClientAccountPositionsInterface
} from '../../../../../../objects/clientAccount'
import { dollarFormat, isObjEmpty } from '../../../../../helpers'
import {
  PortfolioAnalyticsFunc,
  PortfolioAnalyticsStateKeys
} from '../../../../../../objects/PortfolioAnalytics/analytics'

export interface PositionsTileProps {
  title: string
  registrationTypeSum?: number
  accounts?: ClientAccountObjState
  featureFunc?: PortfolioAnalyticsFunc
}

const tileStyle: CSSProperties = {
  border: 'none',
  position: 'static'
}

const contentStyle: CSSProperties = {
  display: 'flex',
  position: 'static',
  flexDirection: 'column',
  background: '#e6ecf3',
  padding: '0px 0px'
}

const headerStyle: CSSProperties = {
  verticalAlign: 'top',
  fontSize: '24px',
  fontWeight: 'normal',
  lineHeight: '32px',
  background: '#e6ecf3',
  borderBottom: 'none',
  padding: '8px 0px 17px 0px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}

export class PositionsTile extends Component<PositionsTileProps> {
  public labelsBar = () => {
    return (
      <div className='im-holding__header'>
        <span className='im-holding__label'>Symbol</span>
        <span className='im-holding__label-w'>Security Description</span>
        <span className='im-holding__label-right im-holding__label-right--tight'>
          Quantity
        </span>
        <span className='im-holding__label-right'>Total Value</span>
      </div>
    )
  }

  public holdingOptions = () => {
    return (
      <div className='im-position__options'>
        <span onClick={() => null}>Edit</span>
        <span onClick={() => null}>Delete</span>
      </div>
    )
  }

  public holdingDisplay = (positions: ClientAccountPositionsInterface) => {
    return !isObjEmpty(positions)
      ? Object.values(positions).map((position, key) => {
          return <Holdings key={key} position={position} />
        })
      : null
  }

  public importPositions = (e: any) => {
    const {
      featureFunc: { toggleModal }
    } = this.props
    const stateKey: PortfolioAnalyticsStateKeys = {
      toggleImportPositions: 'toggleImportPositions',
      clientAccountId: e.currentTarget.id
    }
    toggleModal(stateKey)
  }

  public content = () => {
    const { accounts } = this.props
    const contentDisplay = (account: ClientAccountObj, idx: number) => {
      const {
        name,
        accountNumber,
        guidebookClassification,
        positions,
        source,
        balanceDate,
        totalValue
      } = account

      const positionsArray =
        !Object.is(positions, null) && Object.values(positions)
      const totalPositionsValue =
        positionsArray.reduce((total, { value }) => {
          return total + (isNaN(value) ? 0 : value)
        }, 0) || totalValue

      const classLabel = !isObjEmpty(accounts) ? `Classification` : ''
      const classificationValue = () => {
        if (isObjEmpty(accounts)) {
          return ''
        }
        return guidebookClassification || '-'
      }

      const accountValueLabel = !isObjEmpty(accounts) ? `Account Value` : ''
      const accountValue = !isObjEmpty(accounts)
        ? dollarFormat(totalPositionsValue, 0)
        : ''

      const ImportButton = () => (
        <div
          key='import-button'
          className={
            Object.keys(positions).length === 0
              ? 'im-holding__item im-holding__item-import'
              : 'im-holding__item im-holding__item-update'
          }></div>
      )

      const holdingsDisplay = this.holdingDisplay(positions)
      const balanceDateString = balanceDate?.length ? balanceDate : '---'
      const [year, month, date] = balanceDateString.split('-')

      return (
        <div key={idx} className='im-position__content'>
          <div className='im-position__account-header'>
            <div className='im-position__account-header--left'>
              <div className=' im-position__account-label im-position__account-label--w'>
                <span className='im-position__account-name'>{name || '-'}</span>
                <span className='im-position__account-number'>
                  {accountNumber}
                </span>
              </div>
            </div>

            <div className='im-position__account-header--right'>
              <div className='im-position__account-label'>
                <span className='im-position__account-key'>Balance Date</span>
                <span className='im-position__account-value'>
                  {`${month ? parseInt(month, 0) : '-'}/${date || '-'}/${year ||
                    '-'}`}
                </span>
              </div>

              <div className='im-position__account-label'>
                <span className='im-position__account-key'>{classLabel}</span>
                <span className='im-position__account-value'>
                  {classificationValue()}
                </span>
              </div>

              <div className='im-position__account-label'>
                <span className='im-position__account-key im-position__account-key--w'>
                  {accountValueLabel}
                </span>
                <span className='im-position__account-value'>
                  {accountValue}
                </span>
              </div>
            </div>
          </div>

          {Object.keys(positions).length > 0 && this.labelsBar()}
          {holdingsDisplay}
          {['AdvisorCenter - Manual'].includes(source) && <ImportButton />}
        </div>
      )
    }

    return !isObjEmpty(accounts)
      ? Object.values(accounts).map((account, index: number) => {
          return contentDisplay(account, index)
        })
      : [contentDisplay(emptyAccount, 0)]
  }

  public header = () => {
    const { title = '', registrationTypeSum } = this.props
    return (
      <Fragment>
        <span>{title}</span>
        <span style={{ textAlign: 'right' }}>
          {dollarFormat(registrationTypeSum, 0)}
        </span>
      </Fragment>
    )
  }

  public render = () => {
    return (
      <Tile
        tileStyle={tileStyle}
        contentStyle={contentStyle}
        headerStyle={headerStyle}
        header={this.header()}>
        {this.content()}
      </Tile>
    )
  }
}

export default PositionsTile
