import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { isEqual } from '../../../helpers'
import moment from 'moment'

import {
  mapGoalPreferences,
  sortGoals,
  sortByOptions,
  sortOrderOptions,
  findDateValue
} from '../../../helpers/sort/goalSort'
import {
  setHouseholdPreferences,
  updateHouseholdPreferences
} from '../../../../actions/householdPreferences'
import { GlobalState } from '../../../../reducers'
import {
  PlanningSoftwareType,
  FinancialGoalObj
} from '../../../../objects/financialGoal'
import { HouseholdPreferencesObj } from '../../../../objects/householdPreferences'
import { SortType } from '../../../../objects/preferenceSortSetting'
import { GoalRequestObj } from '../../../../objects/goalRequests'

import PreferenceSortSetting from '../../../components/preferenceSortSetting'
import TableHeader from '../../../components/layout/tableHeader'
import Tile from '../../../components/layout/tile'
import GoalRow from './goalRow'
interface GoalTileProps {
  userId: string
  householdFinId: string
  title: string
  goals: (FinancialGoalObj | GoalRequestObj)[]
  requests?: GoalRequestObj[]
  dispatch: Dispatch<GlobalState>
  planningSoftware: PlanningSoftwareType
  preferences: HouseholdPreferencesObj
  originalPreferences: HouseholdPreferencesObj
  openChangeModalId?: string
}

interface GoalTileState {
  showGoalRequestModal: boolean
  sortedGoals: (FinancialGoalObj | GoalRequestObj)[]
  sortBy: string
  sortingOrder: string
  openChangeModalId?: string
}

class GoalTile extends Component<GoalTileProps, GoalTileState> {
  constructor(props: GoalTileProps) {
    super(props)
    this.state = {
      showGoalRequestModal: false,
      sortedGoals: this.props.goals,
      sortBy: null,
      sortingOrder: 'Ascending',
      openChangeModalId: this.props.openChangeModalId || null
    }
  }

  public componentDidMount() {
    this.setDefaultSorting()
  }

  public componentDidUpdate(prevProps: any) {
    if (!isEqual(prevProps.goals, this.props.goals)) {
      this.setDefaultSorting()
    }
    if (prevProps.openChangeModalId !== this.props.openChangeModalId) {
      this.setState({
        ...this.state,
        openChangeModalId: this.props.openChangeModalId
      })
    }
  }

  public setDefaultSorting = () => {
    const { sortBy, sortingOrder } = this.state
    const { title, preferences } = this.props
    const sortObj = {
      sortBy,
      sortingOrder
    }
    const preferenceName = Object.keys(mapGoalPreferences(title, sortObj))
    const sortByName = Object.values(
      mapGoalPreferences(title, {
        sortBy: preferences && preferences[preferenceName[0]]
      })
    )[0]
    const sortingOrderName = Object.values(
      mapGoalPreferences(title, {
        sortingOrder: preferences && preferences[preferenceName[1]]
      })
    )[0]
    this.setState(
      {
        sortBy: String(sortByName),
        sortingOrder: String(sortingOrderName)
      },
      () => this.calcDefaultSort()
    )
  }

  public calcDefaultSort = () => {
    const { goals } = this.props
    const { sortBy, sortingOrder } = this.state
    const sortObj = {
      sortBy,
      sortingOrder
    }
    return sortBy === 'Period'
      ? this.setState({
          sortedGoals: this.determineIfDate(goals, sortObj)
        })
      : this.setState({
          sortedGoals: sortGoals(goals, sortObj)
        })
  }

  public determineIfDate = (
    goals: (FinancialGoalObj | GoalRequestObj)[],
    sortObj: SortType
  ) => {
    const noneDate: (FinancialGoalObj | GoalRequestObj)[] = []
    const isDate: (FinancialGoalObj | GoalRequestObj)[] = []
    goals.forEach((goal) => {
      const startDate = findDateValue(goal)
      if (moment(startDate).isValid()) {
        isDate.push(goal)
      } else {
        noneDate.push(goal)
      }
    })
    const sortedNoneDates =
      noneDate.length > 0 ? sortGoals(noneDate, sortObj) : []
    const newSortObj = { ...sortObj, type: 'date' }
    const sortedDates = isDate.length > 0 ? sortGoals(isDate, newSortObj) : []
    return sortObj.sortingOrder === 'Descending'
      ? [...sortedDates, ...sortedNoneDates]
      : [...sortedNoneDates, ...sortedDates]
  }

  public renderGoalRows = (
    goalCollection: (FinancialGoalObj | GoalRequestObj)[],
    existingRequest: boolean,
    displayPriorities: boolean
  ) => {
    const {
      householdFinId,
      planningSoftware,
      userId,
      dispatch,
      openChangeModalId
    } = this.props
    let changeModalOpen: boolean = false
    return goalCollection.map((goal: any) => {
      changeModalOpen = Boolean(
        goal && goal.goalRequest && goal.goalRequest.id === openChangeModalId
      )
      if (!changeModalOpen) {
        changeModalOpen = Boolean(
          goal && goal.id && goal.id === openChangeModalId
        )
      }
      return (
        <GoalRow
          key={goal.id}
          userId={userId}
          householdFinId={householdFinId}
          planningSoftware={planningSoftware}
          goal={goal}
          dispatch={dispatch}
          showActionColumn={existingRequest}
          displayPriorities={displayPriorities}
          changeModalOpen={changeModalOpen}
        />
      )
    })
  }

  public toggleGoalChange = () => {
    this.setState({ showGoalRequestModal: !this.state.showGoalRequestModal })
  }

  public changePreference = (options: SortType) => {
    this.setState({ ...this.state, ...options })
  }

  public onSubmitPreferences = () => {
    const { sortBy, sortingOrder } = this.state
    const options = { sortBy, sortingOrder }
    this.updateSortedAccounts()
    this.updatePreferences(options)
  }

  public onCancelPreferences = () => {
    this.setDefaultSorting()
  }

  public onDefaultPreferences = () => {
    this.setState({
      sortBy: 'Name',
      sortingOrder: 'Ascending'
    })
  }

  public updatePreferences = (options: SortType) => {
    const {
      householdFinId,
      dispatch,
      title,
      preferences,
      originalPreferences
    } = this.props
    if (!originalPreferences) {
      const newPreferences: HouseholdPreferencesObj = {
        ...preferences,
        ...mapGoalPreferences(title, options)
      }
      return dispatch(setHouseholdPreferences(householdFinId, newPreferences))
    } else {
      const newPreferences: HouseholdPreferencesObj = {
        ...preferences,
        ...mapGoalPreferences(title, options)
      }
      return dispatch(
        updateHouseholdPreferences(householdFinId, newPreferences)
      )
    }
  }

  public updateSortedAccounts = () => {
    const { sortBy, sortingOrder, sortedGoals } = this.state
    const sortObj = {
      sortBy,
      sortingOrder
    }
    if (sortBy === 'Period') {
      const sortedPeriods = this.determineIfDate(sortedGoals, sortObj)
      this.setState({ sortedGoals: sortedPeriods })
    } else {
      const newSortedGoals = sortGoals(sortedGoals, {
        sortBy,
        sortingOrder
      })
      this.setState({ sortedGoals: newSortedGoals })
    }
  }

  public leftHeader = (displayPriorities: boolean) => {
    const { title, planningSoftware, preferences } = this.props
    const { sortBy, sortingOrder } = this.state
    const showPriorityOption =
      planningSoftware === 'moneyguidepro' && displayPriorities
    const sortObj = { sortBy, sortingOrder }
    const preferenceName = Object.keys(mapGoalPreferences(title, sortObj))
    const sortByName = Object.values(
      mapGoalPreferences(title, {
        sortBy: preferences && preferences[preferenceName[0]]
      })
    )[0]
    const sortingOrderName = Object.values(
      mapGoalPreferences(title, {
        sortingOrder: preferences && preferences[preferenceName[1]]
      })
    )[0]
    const modifiedTitle = title.replace('Goal', '')
    return (
      <div className='client-accounts-tile__header-left'>
        {modifiedTitle}
        <PreferenceSortSetting
          sortByOptions={sortByOptions}
          sortOrderOptions={sortOrderOptions}
          currentSortBy={sortBy}
          currentSortingOrder={sortingOrder}
          persistedSortBy={sortByName}
          persistedSortingOrder={sortingOrderName}
          changePreference={this.changePreference}
          showPriorityOption={showPriorityOption}
          onSubmitPreferences={this.onSubmitPreferences}
          onCancelPreferences={this.onCancelPreferences}
          onDefaultPreferences={this.onDefaultPreferences}
        />
      </div>
    )
  }

  public render() {
    const { planningSoftware, goals, requests } = this.props
    const { sortedGoals } = this.state
    const goalCollection =
      sortedGoals && sortedGoals.length > 0 ? sortedGoals : null
    const displayPriorities =
      (sortedGoals &&
        sortedGoals.filter((goal: any) => goal && goal.needsWantsWishes)
          .length) > 0
    const existingRequests = requests && requests.length > 0
    return (
      <div className={!goals ? 'pdf-exclude' : ''}>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          leftHeader={this.leftHeader(displayPriorities)}>
          <TableHeader>
            <div className='goal-row__table-name goal-row__table-name-header'>
              NAME
            </div>
            <div className='goal-row__table-description' />
            {planningSoftware === 'moneyguidepro' && displayPriorities ? (
              <div className='goal-row__table-priority'>PRIORITY</div>
            ) : null}
            <div className='goal-row__table-period'>PERIOD</div>
            <div className='goal-row__table-amount goal-row__table-amount-header'>
              AMOUNT
            </div>
            {!planningSoftware || (!planningSoftware && existingRequests) ? (
              <div className='goal-row__table-actions'>ACTIONS</div>
            ) : existingRequests ? (
              <div className='goal-row__table-actions' />
            ) : null}
          </TableHeader>
          {goalCollection &&
            this.renderGoalRows(
              goalCollection,
              existingRequests,
              displayPriorities
            )}
        </Tile>
      </div>
    )
  }
}

export default GoalTile
