import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg'
import Button from '../../components/button'
import { addToast } from '../../../actions/toasts'
import { GlobalState } from '../../../reducers'
import { Dispatch, connect } from 'react-redux'
import * as actions from '../../../actions/documentVault'

interface CreateFolderModalProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  closeModal(): void
}

interface CreateFolderModalState {
  folderName: string
}

class CreateFolderModal extends React.Component<
  CreateFolderModalProps,
  CreateFolderModalState
> {
  constructor(props: CreateFolderModalProps) {
    super(props)
    this.state = {
      folderName: null
    }
  }
  public createFolder = async () => {
    const { householdFinId } = this.props
    const { folderName } = this.state
    if (folderName) {
      await this.props.dispatch(
        actions.createFolder(folderName, householdFinId)
      )
      this.props.dispatch(
        addToast({
          icon: FolderIcon,
          message: 'Folder created.',
          backgroundColor: '#D9F2B6'
        })
      )
      this.props.closeModal()
    }
  }
  public updateFolderName = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ folderName: e.currentTarget.value })
  }
  public cancelCreateFolder = () => {
    this.props.closeModal()
  }
  public render() {
    const { closeModal } = this.props
    return (
      <Modal icon={FolderIcon} title='CREATE FOLDER' closeModal={closeModal}>
        <div className='folder-modal__content'>
          Folder Name
          <input
            className='folder-modal__content--input'
            placeholder='e.g. Personal Goals'
            onChange={this.updateFolderName}
          />
          <div className='folder-modal__buttons'>
            <Button clear={true} onClick={this.cancelCreateFolder}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.createFolder}>
              Create
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect()(CreateFolderModal)
