import * as householdPreferenceActions from '../actions/householdPreferences'
import * as householdActions from '../actions/households'
import { HouseholdPreferencesObj } from '../objects/householdPreferences'

export interface HouseholdPreferencesState {
  [key: string]: {
    preferences: HouseholdPreferencesObj
  }
}

const initState: HouseholdPreferencesState = {}

const HouseholdPreferences = (
  state = initState,
  action: any
): HouseholdPreferencesState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${householdActions.FETCH_INDIVIDUAL_HOUSEHOLD}_FULFILLED`:
      const preferences = action.payload.data.preferences
        ? action.payload.data.preferences[0]
        : null
      newState[action.payload.data.id] = {
        preferences: mapPreferences(preferences)
      }
      return newState
    case `${householdPreferenceActions.SET_HOUSEHOLD_PREFERENCES}_FULFILLED`:
      newState[action.payload.data.householdFinId] = {
        preferences: mapPreferences(action.payload.data.preferences)
      }
      return newState
    case `${householdPreferenceActions.UPDATE_HOUSEHOLD_PREFERENCES}_FULFILLED`:
      newState[action.payload.data.householdFinId] = {
        preferences: mapPreferences(action.payload.data.preferences)
      }
      return newState
    default:
      return state
  }
}

const mapPreferences = (preferences: HouseholdPreferencesObj) => {
  if (preferences) {
    const responseToState = { ...preferences }
    if (responseToState.investmentsInvestmentsSortBy === 'Product Name') {
      responseToState.investmentsInvestmentsSortBy = 'Strategy'
    }
    return responseToState
  } else return null
}

export default HouseholdPreferences
