import React, { CSSProperties, FunctionComponent } from 'react'
import ProfilePicture from '../../../../components/layout/profilePicture'

const profileIconOverlap: CSSProperties = {
  position: 'relative',
  top: 0,
  left: '0px',
  zIndex: 3
}

const setAvatar = (
  source: string,
  firstName: string,
  lastName: string,
  isPrimary: boolean,
  style?: CSSProperties
) => {
  return (
    <div className='results-summary__contact-avatar' style={style}>
      <ProfilePicture
        firstName={firstName}
        lastName={lastName}
        photo={source}
        isPrimary={isPrimary}
        pictureSize={32}
      />
    </div>
  )
}

export const TaskDescriptionItem: FunctionComponent<{
  index: number
  longDescription: string
  avatars: {
    source: string
    firstName: string
    lastName: string
    isPrimary: boolean
  }[]
}> = ({ index, longDescription, avatars }) => {
  return (
    <div
      className='results-summary__action-task'
      style={
        index > 0
          ? {
              marginTop: '7px'
            }
          : null
      }>
      <div className='results-summary__action-task-description'>
        {longDescription}
      </div>
      <div className='results-summary__action-task-contacts'>
        {avatars &&
          avatars[0] &&
          setAvatar(
            avatars[0].source,
            avatars[0].firstName,
            avatars[0].lastName,
            avatars[0].isPrimary,
            {
              ...profileIconOverlap,
              left: avatars[1] ? '5px' : '0px'
            }
          )}

        {avatars &&
          avatars[1] &&
          setAvatar(
            avatars[1].source,
            avatars[1].firstName,
            avatars[1].lastName,
            avatars[1].isPrimary,
            profileIconOverlap
          )}
      </div>
    </div>
  )
}
