import React, { useState, useEffect } from 'react'
import { Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { HouseholdObj } from '../../../objects/household'
import { toggleOptionalFeature } from '../../../actions/households'

import Tooltip from '../../components/tooltip'
import Button from '../../components/button'
import ToggleSwitch from '../../components/toggleSwitch'
import Modal from '../../components/layout/modal'

import HelpIcon from '../../assets/images/icons/help.png'

export interface SettingsOptionalFeaturesProps {
  dispatch: Dispatch<GlobalState>
  householdId: string
  household: HouseholdObj
}

const SettingsOptionalFeatures = ({
  dispatch,
  householdId,
  household
}: SettingsOptionalFeaturesProps) => {
  const [showModal, setShowModal] = useState(false)
  const [currentFeature, setCurrentFeature] = useState(null)
  const {
    showHonestConversations,
    showInvestments,
    showEmoneyClientPortal,
    showAllStrategies
  } = household

  useEffect(() => {
    if (currentFeature === null) return
    void handleToggleOptionalFeature()
  }, [currentFeature])

  const handleToggleOptionalFeature = async (confirmed?: boolean) => {
    if (!household[currentFeature] && !confirmed) {
      setShowModal(true)
      return
    }

    try {
      await dispatch(
        toggleOptionalFeature(householdId, {
          [currentFeature]: !household[currentFeature]
        })
      )
    } catch {}

    resetState()
  }

  const resetState = () => {
    setShowModal(false)
    setCurrentFeature(null)
  }
  return (
    <div className='c-settings__optionality'>
      <h1 className='c-settings__optionality-h'>Optionality Settings</h1>
      <p className='c-settings__optionality-text'>
        The elements listed below can be turned on or off. When an element is
        turned off, it will be hidden from client view on GuideCenter and
        disabled on AdvisorCenter. Before editing these settings, review with
        your supervisor for approval.
      </p>

      <div className='a-settings__row a-settings__row--content'>
        <span className='toggle-caption'>Honest Conversations</span>
        <div className='a-settings__row-toggle-w'>
          <ToggleSwitch
            active={showHonestConversations}
            handleToggleActive={() => {
              setCurrentFeature('showHonestConversations')
            }}
          />
        </div>
      </div>
      <div className='a-settings__row a-settings__row--content'>
        <span className='toggle-caption'>Investment Viewfinder</span>
        <div className='a-settings__row-toggle-w'>
          <ToggleSwitch
            active={showInvestments}
            handleToggleActive={() => {
              setCurrentFeature('showInvestments')
            }}
          />
        </div>
      </div>
      {(household.planningSoftware === 'emoney' ||
        household.planningSoftware === 'emoney_enterprise') && (
        <div className='a-settings__row a-settings__row--content'>
          <span className='toggle-caption'>eMoney SSO</span>
          <div className='a-settings__row-toggle-w'>
            <ToggleSwitch
              active={showEmoneyClientPortal}
              handleToggleActive={() => {
                setCurrentFeature('showEmoneyClientPortal')
              }}
            />
          </div>
        </div>
      )}
      <div className='a-settings__row a-settings__row--content'>
        <span className='toggle-caption'>
          Portfolio Builder - Show all strategies
          <Tooltip
            message='The strategy types available in limited mode are "Active Managed" and "Strategic Core"'
            width={200}
            position='right'
            multiLine={true}>
            <img
              src={HelpIcon}
              width={16}
              height={16}
              alt='helper tooltip'
              style={{ marginLeft: 4 }}
            />
          </Tooltip>
        </span>
        <div className='a-settings__row-toggle-w'>
          <ToggleSwitch
            active={showAllStrategies}
            handleToggleActive={() => {
              setCurrentFeature('showAllStrategies')
            }}
          />
        </div>
      </div>
      {showModal && (
        <Modal size='M' title='Optionality Settings' closeModal={resetState}>
          <div className='c-settings__optionality-modal'>
            <p className='c-settings__optionality-modal-text'>
              Do you have approval from your supervisor to enable?
            </p>
            <div className='c-settings__optionality-btns'>
              <Button clear={true} onClick={resetState}>
                Cancel
              </Button>
              <Button
                primary={true}
                onClick={() => {
                  void handleToggleOptionalFeature(true)
                }}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default SettingsOptionalFeatures
