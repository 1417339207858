import * as React from 'react'
import Modal from '../../components/layout/modal'
import Button from '../../components/button'

export interface ConfirmationModalProps {
  confirmationText: any
  name: string
  closeModal(): void
  confirm(): void
  title: string
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { confirmationText, closeModal, confirm, name, title } = props
  const close = (e: any) => {
    e.stopPropagation()
    closeModal()
  }

  return (
    <Modal title={`ENABLE ${title} ITEM`} closeModal={closeModal}>
      <div className='modal__content marketplace__confirmation'>
        Please confirm that your client is eligible for {name}, based on the
        following criteria:
        <ul>
          {confirmationText.map((text: string, index: number) => {
            return <li key={index}>{text}</li>
          })}
        </ul>
        <div className='modal__buttons'>
          <span onClick={close} className='btn btn__clear'>
            Cancel
          </span>
          <Button primary={true} onClick={confirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}
