import React, { FC, ChangeEvent, Fragment } from 'react'

interface SelectStrategyTypeProps {
  selectedStrategyType: string
  selectedStrategyTypeChange(e: ChangeEvent<HTMLInputElement>): void
}

const SelectStrategyType: FC<SelectStrategyTypeProps> = ({
  selectedStrategyType,
  selectedStrategyTypeChange
}) => {
  const managedStrategiesChecked = selectedStrategyType === 'Managed Strategies'
  const assetClassSelected = selectedStrategyType === 'Asset Class'
  return (
    <Fragment>
      <label
        className={
          managedStrategiesChecked
            ? 'strategy-search__type-option strategy-search__type-option--checked'
            : 'strategy-search__type-option'
        }>
        <input
          onChange={selectedStrategyTypeChange}
          type='radio'
          name='strategyType'
          value='Managed Strategies'
          checked={managedStrategiesChecked}
          aria-checked={managedStrategiesChecked}
        />
        Managed Strategies
      </label>
      <label
        className={
          assetClassSelected
            ? 'strategy-search__type-option strategy-search__type-option--checked'
            : 'strategy-search__type-option'
        }>
        <input
          onChange={selectedStrategyTypeChange}
          type='radio'
          name='strategyType'
          value='Asset Class'
          checked={assetClassSelected}
          aria-checked={assetClassSelected}
        />
        Asset Class
      </label>
    </Fragment>
  )
}

export default SelectStrategyType
