import React from 'react'

export default function disclosure(props: { routerLocation?: string }) {
  const { routerLocation } = props
  const currentPathArr = routerLocation?.split('/')
  const currentPathLast = currentPathArr?.[currentPathArr.length - 1]
  const currentPathSecond = currentPathArr?.[currentPathArr.length - 2]
  // this fixes the issues with pages that follow completely different internal layouts
  // GB preview, Portfolio Proposal Preview, portfolio builder
  const noDisclosure =
    currentPathLast === 'PortfolioProposal' ||
    currentPathLast === 'guideBookPage' ||
    currentPathSecond === 'portfolioBuilder'

  const isIvf = routerLocation?.includes('investmentViewfinder')

  const currentYear = new Date().getFullYear()

  const ivfDisclosure = (
    <div className='disclosure__body-w'>
      ©{currentYear} | United Capital Financial Advisors, LLC (“United
      Capital”). All Rights Reserved. | FinLife Partners is a d/b/a of United
      Capital Financial Advisors, LLC
    </div>
  )

  const defaultDisclosure = (
    <div className='disclosure__body-w'>
      ©{currentYear} | United Capital Financial Advisors, LLC (“United
      Capital”). All Rights Reserved. | FinLife Partners is a d/b/a of United
      Capital Financial Advisors, LLC |{' '}
      <a
        href={window._env_.REACT_APP_UC_DISCLOSURE}
        target='_blank'
        rel='noopener noreferrer'>
        Site Disclosures
      </a>{' '}
      |{' '}
      <a
        href={window._env_.REACT_APP_PRIVACY_POLICY_URL}
        target='_blank'
        rel='noopener noreferrer'>
        Privacy Policy
      </a>
    </div>
  )

  if (noDisclosure) {
    return null
  } else if (isIvf) {
    return ivfDisclosure
  } else {
    return defaultDisclosure
  }
}
