import React, { ReactNode } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import LoaderIcon from '../../assets/images/loader.gif'
import ErrorIcon from '../../assets/images/icons/error_loader.png'
import SuccessIcon from '../../assets/images/icons/success_loader.png'
import SuccessIconV2 from '../../assets/images/icons/loader_success.png'
import { ReactComponent as ErrorIconV2 } from '../../assets/images/icons/loader_error.svg'
import { iconType } from '../../../objects/timedLoader'
interface LoaderProps {
  children?: ReactNode
  loadingType?: string
  closeFunc?(): void
  stacked?: boolean
  iconType?: iconType
  loadingMessage?: string
}

const closeIconStyle: Record<string, string> = {
  textAlign: 'right',
  cursor: 'pointer'
}

const LoadingIcons = {
  loading: LoaderIcon,
  error: ErrorIcon,
  success: SuccessIcon,
  successV2: SuccessIconV2,
  errorV2: ErrorIconV2
}
export const Loader: React.FC<LoaderProps> = ({
  closeFunc,
  children,
  loadingType,
  stacked,
  iconType = '',
  loadingMessage = ''
}) => {
  const renderLoader = () => {
    const Icon = iconType ? LoadingIcons[iconType] : LoaderIcon
    return (
      <div className='loader__overlay'>
        <div className='loader__syncloader'>
          <div className='loader__syncloader-spinner'>
            {typeof Icon == 'string' ? (
              <img src={Icon} alt='loading' />
            ) : (
              <Icon />
            )}
          </div>
          <div className='loader__syncloader-loader'>
            <p className='loader__syncloader-loader-action'>
              {loadingType || 'Loading'}
            </p>
            <p className='loader__syncloader-loader-description'>
              {loadingMessage || children}
            </p>
          </div>
          <div className='loader__syncloader-close'>
            <CloseIcon onClick={closeFunc} style={closeIconStyle} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      {!stacked ? (
        <div className='loader__container'>{renderLoader()}</div>
      ) : (
        renderLoader()
      )}
    </>
  )
}
