import {
  FinancialGoalsInterface,
  FundedScoreObj
} from '../objects/financialGoal'
import * as actions from '../actions/financialGoals'
import * as householdActions from '../actions/households'
import { dollarFormat } from '../v3/helpers'
import { Goal } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

export interface FinancialGoalsState {
  [key: string]: {
    goals: FinancialGoalsInterface
    fundedScores: FundedScoreObj[]
  }
}

const initState: FinancialGoalsState = {}

const formatGoalAmount = (goal: Goal.Response) => {
  if (
    goal.amountType &&
    goal.amountType.toLowerCase() === 'dollar' &&
    goal.amount
  ) {
    return dollarFormat(goal.amount, 0)
  } else if (
    goal.amountType &&
    goal.amountType.toLowerCase() === 'percent' &&
    goal.amount
  ) {
    return goal.amount + '%'
  } else return goal.amount
}

const mapFinancialGoal = (goal: any) => {
  if (goal.type && goal.includeInGuideBook && goal.type !== 'Portfolios') {
    return {
      id: goal.id,
      name: goal.name,
      type: goal.type,
      owner: goal.goalOwnerId,
      startDate: goal.startOfGoal,
      endDate: goal.endOfGoal,
      amount: formatGoalAmount(goal),
      amountType: goal.amountType,
      annualIncrease: goal.annualIncrease,
      needsWantsWishes: goal.needsWantsWishes
    }
  } else return null
}

const mapFinancialGoals = (goals: Goal.Response[]) => {
  const financialGoalObj = {}
  goals.forEach((goal) => {
    financialGoalObj[goal.id] = mapFinancialGoal(goal)
    if (!financialGoalObj[goal.id]) {
      delete financialGoalObj[goal.id]
    }
  })
  return financialGoalObj
}

const mapFundedScores = (household: any) => {
  const fundedScores: FundedScoreObj[] = []
  household.fundedScores
    .sort((a: any, b: any) => a.createdDate < b.createdDate)
    .forEach((fundedScore: any) => {
      fundedScores.push(mapFundedScore(fundedScore))
    })
  return fundedScores
}

const mapFundedScore = (fundedScore: any) => {
  return {
    id: fundedScore && fundedScore.id ? fundedScore.id : '',
    name: fundedScore && fundedScore.name ? fundedScore.name : '',
    notes: fundedScore && fundedScore.notes ? fundedScore.notes : '',
    inGuidebook:
      fundedScore && fundedScore.inGuidebook ? fundedScore.inGuidebook : false,
    rangeLow: fundedScore && fundedScore.target ? fundedScore.target.low : '',
    rangeHigh: fundedScore && fundedScore.target ? fundedScore.target.high : '',
    score: fundedScore ? fundedScore.score : '',
    lastUpdated: fundedScore ? fundedScore.scoreDate : ''
  }
}

const FinancialGoals = (state = initState, action: any) => {
  const newState = JSON.parse(JSON.stringify(state))
  let newGoals = null
  switch (action.type) {
    case `${actions.FETCH_FINANCIAL_GOALS}_FULFILLED`:
      newState[action.payload.data.householdFinId] = {
        ...newState[action.payload.data.householdFinId],
        goals: {
          ...mapFinancialGoals(action.payload.data)
        }
      }
      return newState
    case `${householdActions.FETCH_HOUSEHOLDS}_FULFILLED`:
      action.payload.data.forEach((household: any) => {
        newState[household.id] = newState[household.id]
          ? newState[household.id]
          : {}
        newState[household.id].fundedScores = mapFundedScores(household)
      })
      return newState
    case `${householdActions.FETCH_INDIVIDUAL_HOUSEHOLD}_FULFILLED`:
      newState[action.payload.data.id] = newState[action.payload.data.id]
        ? newState[action.payload.data.id]
        : {}
      newState[action.payload.data.id].fundedScores = mapFundedScores(
        action.payload.data
      )
      return newState
    case `${actions.CREATE_FUNDED_SCORE}_FULFILLED`:
      const householdId = action.payload.data.household
      newState[householdId] = newState[householdId] ? newState[householdId] : {}
      const fundedScore = mapFundedScore(action.payload.data)
      newState[householdId].fundedScores = [fundedScore].concat(
        newState[householdId].fundedScores
      )
      return newState
    case `${actions.UPDATE_FUNDED_SCORE}_FULFILLED`:
      const updatedFundedScore =
        action.payload.data && mapFundedScore(action.payload.data)
      newState[action.payload.data.household].fundedScores = newState[
        action.payload.data.household
      ].fundedScores.map((mappedFundedScore: any) => {
        if (
          mappedFundedScore &&
          mappedFundedScore.id === updatedFundedScore.id
        ) {
          return { ...mappedFundedScore, ...updatedFundedScore }
        } else {
          return mappedFundedScore
        }
      })
      return newState
    case `${actions.CREATE_FINANCIAL_GOAL}_FULFILLED`:
      newGoals = newState[action.payload.data.householdFinId].goals
      newGoals[action.payload.data.id] = mapFinancialGoal(action.payload.data)
      newState[action.payload.data.householdFinId] = {
        ...newState[action.payload.data.householdFinId],
        goals: newGoals
      }
      return newState
    case `${actions.EDIT_FINANCIAL_GOAL}_FULFILLED`:
      newGoals = newState[action.payload.data.householdFinId].goals
      newGoals[action.payload.data.id] = mapFinancialGoal(action.payload.data)
      newState[action.payload.data.householdFinId] = {
        ...newState[action.payload.data.householdFinId],
        goals: newGoals
      }
      return newState
    case `${actions.DELETE_FINANCIAL_GOAL}_FULFILLED`:
      newGoals = newState[action.payload.data.householdFinId].goals
      delete newGoals[action.payload.data.goalFinId]
      newState[action.payload.data.householdFinId] = {
        ...newState[action.payload.data.householdFinId],
        goals: newGoals
      }
      return newState
    default:
      return state
  }
}

export default FinancialGoals
