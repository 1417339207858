import React from 'react'
import Button from '../../../components/button'
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'
import { history } from '../../../../store'

const HonestConversationNewScoreButton = ({
  householdFinId,
  exerciseId
}: {
  householdFinId: string
  exerciseId: string
}) => {
  const nav = () => {
    history.push(
      `/households/${householdFinId}/honestConversations/newScore/${exerciseId}`
    )
  }

  return (
    <Button primary={true} onClick={nav}>
      <PlusIcon /> Add Score
    </Button>
  )
}

export default HonestConversationNewScoreButton
