import React, { Fragment } from 'react'
import {
  DisplayableNotification,
  FileNotification,
  NotificationContent,
  CompleteTaskNotification,
  NewTaskAssignedNotification,
  TaskCompleteComponentProps,
  NotificationTypes
} from '../../../objects/notifications'

export const MessageNotification: React.FC<{ firstName: string }> = (props) => {
  const { firstName } = props
  return (
    <div className='header__notification-message'>
      New message from
      <span className='header__notification-dropdown-name'> {firstName}.</span>
    </div>
  )
}

export const NewYmmNotfication: React.FC<{ firstName: string }> = (props) => {
  const { firstName } = props
  return (
    <div className='header__notification-message'>
      <span className='header__notification-dropdown-name'>{firstName} </span>
      completed MoneyMind<sup>&reg;</sup>.
    </div>
  )
}

export const HonestConversationNotification: React.FC<{
  firstName: string
  lastName: string
  title: string
}> = (props) => {
  const { firstName, lastName, title } = props

  return (
    <div className='header__notification-message'>
      <span className='header__notification-dropdown-name'>
        {firstName} {lastName}
      </span>{' '}
      {title
        ? `${title}.`
        : `has completed their individual HonestConversations
            <sup>&reg;</sup> cards.`}
    </div>
  )
}

export const GoalChangeNotification: React.FC<{ firstName: string }> = (
  props
) => {
  const { firstName } = props
  return (
    <div className='header__notification-message'>
      <span className='header__notification-dropdown-name'>{firstName}</span>{' '}
      has requested to modify a plan input.
    </div>
  )
}

export const CompleteTaskNotifications: React.FC<{
  firstName: string
  batchSize: number
  data: CompleteTaskNotification
}> = (props: TaskCompleteComponentProps) => {
  const { firstName, batchSize, data: { taskName = '' } = {} } = props
  const batchView = () => (
    <span>
      has completed
      <span className='header__notification-dropdown-name'>
        {` ${batchSize} tasks`}
      </span>
      .
    </span>
  )
  const singleView = () => (
    <span>
      has completed the task
      <span className='header__notification-dropdown-name'>{` ${taskName}`}</span>
      .
    </span>
  )
  return (
    <div className='header__notification-message'>
      <span className='header__notification-dropdown-name'>
        {`${firstName} `}
      </span>
      {batchSize > 1 ? batchView() : singleView()}
    </div>
  )
}

export const NewVaultFileNotification: React.FC<{
  read: boolean
  firstName: string
  lastName: string
  batchSize: number
  data: FileNotification
}> = (props) => {
  const {
    firstName,
    lastName,
    batchSize,
    data: { folderName, fileName }
  } = props

  const batchView = () => (
    <span>
      uploaded{' '}
      <span className='header__notification-dropdown-name'>
        {batchSize} new files
      </span>{' '}
      to{' '}
      <span className='header__notification-dropdown-name'>{folderName}</span>.
    </span>
  )

  const singleView = () => (
    <span>
      uploaded{' '}
      <span className='header__notification-dropdown-name'>{fileName}</span> to{' '}
      <span className='header__notification-dropdown-name'>{folderName}.</span>
    </span>
  )

  return (
    <div className='header__notification-message'>
      <span className='header__notification-dropdown-name'>
        {firstName} {lastName}&nbsp;
      </span>

      {batchSize > 1 ? batchView() : singleView()}
    </div>
  )
}

export const NewTaskAssignedView: React.FC<{
  batchSize: number
  data: NewTaskAssignedNotification
}> = ({ batchSize, data }) => {
  if (data) {
    const { taskSubject } = data
    return (
      <div className='header__notification-message'>
        {batchSize > 1 ? (
          <>
            You have been assigned{' '}
            <span className='header__notification-dropdown-name'>
              {batchSize} Tasks.
            </span>
          </>
        ) : (
          <>
            You have been assigned the task{' '}
            <span className='header__notification-dropdown-name'>
              {taskSubject}.
            </span>
          </>
        )}
      </div>
    )
  }
  return <div></div>
}

export const NewAccountNotification: React.FC<{
  title: string
  batchSize: number
  householdName: string
}> = (props) => {
  const { title = '', batchSize, householdName } = props
  return (
    <div className='header__notification-message'>
      <span className='header__notification-dropdown-name'>
        {householdName}{' '}
      </span>
      {batchSize === 1
        ? title
        : batchSize > 1
        ? `added ${batchSize} accounts`
        : ''}
    </div>
  )
}

export const NotificationItemDisplay: React.FC<{
  notification: DisplayableNotification<NotificationTypes>
}> = (props) => {
  const {
    notificationByNotification: { content, subject, title },
    batchSize = null,
    contact = null,
    read
  } = props.notification

  const householdName = props.notification?.household?.name

  const { data } = content as NotificationContent

  const isRead = read
  const notificationMessage = {
    MESSAGE: <MessageNotification firstName={contact?.firstName} />,
    NEW_YMM: <NewYmmNotfication firstName={contact?.firstName} />,
    NEW_HC: (
      <HonestConversationNotification
        firstName={contact?.firstName}
        lastName={contact?.lastName}
        title={title}
      />
    ),
    GOAL_CHANGE: <GoalChangeNotification firstName={contact?.firstName} />,
    NEW_PDV_FILE: (
      <NewVaultFileNotification
        read={isRead}
        firstName={contact?.firstName}
        lastName={contact?.lastName}
        batchSize={batchSize || 0}
        data={data as FileNotification}
      />
    ),
    NEW_ACCOUNT_LINKED: (
      <NewAccountNotification
        title={title}
        batchSize={batchSize}
        householdName={householdName}
      />
    ),
    COMPLETE_TASK: (
      <CompleteTaskNotifications
        firstName={contact?.firstName}
        batchSize={batchSize || 0}
        data={data as CompleteTaskNotification}
      />
    ),
    NEW_TASK_ASSIGNED: (
      <NewTaskAssignedView
        batchSize={batchSize || 0}
        data={data as NewTaskAssignedNotification}
      />
    )
  }

  return notificationMessage[subject] || <Fragment />
}
