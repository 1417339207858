import React from 'react'

interface ImToastProps {
  index: number
  removeToast(index: number): void
}

interface ImToastState {
  timeout: NodeJS.Timer
  show: boolean
}

class ImToast extends React.Component<ImToastProps, ImToastState> {
  constructor(props: any) {
    super(props)
    this.state = {
      timeout: null,
      show: false
    }
  }
  public componentDidMount() {
    const timeout = setTimeout(() => {
      this.removeToast()
    }, 50000)
    this.setState({ timeout, show: true })
  }
  public componentWillUnmount() {
    clearTimeout(this.state.timeout)
  }
  public removeToast = () => {
    this.props.removeToast(this.props.index)
  }

  public render() {
    return <div>{this.props.children}</div>
  }
}

export default ImToast
