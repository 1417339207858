import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { InvResponse } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
import { InvestmentViewfinderAnswer } from '../objects/investmentViewfinder'

export const CREATE_VIEWFINDER_EXERCISE: string = 'CREATE_VIEWFINDER_EXERCISE'
export const FETCH_VIEWFINDER_EXERCISES: string = 'FETCH_VIEWFINDER_EXERCISES'
export const UPDATE_VIEWFINDER_EXERCISE: string = 'UPDATE_VIEWFINDER_EXERCISE'
export const DELETE_VIEWFINDER_EXERCISES: string = 'DELETE_VIEWFINDER_EXERCISES'
export const CREATE_VIEWFINDER_ANSWER: string = 'CREATE_VIEWFINDER_ANSWER'
export const UPDATE_VIEWFINDER_ANSWER: string = 'UPDATE_VIEWFINDER_ANSWER'
export const FETCH_VIEWFINDER_QUESTIONS: string = 'FETCH_VIEWFINDER_QUESTIONS'
export const FETCH_VIEWFINDER_ALLOCATIONS: string =
  'FETCH_VIEWFINDER_ALLOCATIONS'
export const FETCH_VIEWFINDER_PRIORITIES: string = 'FETCH_VIEWFINDER_PRIORITIES'
export const FETCH_VIEWFINDER_GEOGRAPHIC_PRIORITIES: string =
  'FETCH_VIEWFINDER_GEOGRAPHIC_PRIORITIES'

interface InvestmentViewfinderResponse extends InvResponse.Response {
  householdFinId: string
}

export const createViewfinderExercise = (householdFinId: string) => {
  return {
    type: CREATE_VIEWFINDER_EXERCISE,
    payload: api()
      .post(`/households/${householdFinId}/viewfinder`, {})
      .then((response) => {
        return response
      })
  }
}

export const getViewfinderExercises = (
  householdFinId: string
): {
  type: string
  payload: Promise<AxiosResponse<InvestmentViewfinderResponse>>
} => {
  return {
    type: FETCH_VIEWFINDER_EXERCISES,
    payload: api()
      .get(`/households/${householdFinId}/viewfinder`)
      .then((response: AxiosResponse<InvestmentViewfinderResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateViewfinderPriority = (
  householdFinId: string,
  exerciseId: string,
  taxPreference: number,
  performancePreference: number,
  protectionPreference: number,
  costPreference: number
) => {
  const requestBody = {
    taxPreference,
    performancePreference,
    protectionPreference,
    costPreference
  }
  return {
    type: UPDATE_VIEWFINDER_EXERCISE,
    payload: api()
      .put(
        `/households/${householdFinId}/viewfinder/${exerciseId}`,
        requestBody
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateViewfinderGeographicPriority = (
  householdFinId: string,
  exerciseId: string,
  priority: string
) => {
  const requestBody = {
    domesticPreference: priority === 'Domestic' ? 100 : 0,
    globalPreference: priority === 'Global' ? 100 : 0
  }
  return {
    type: UPDATE_VIEWFINDER_EXERCISE,
    payload: api()
      .put(
        `/households/${householdFinId}/viewfinder/${exerciseId}`,
        requestBody
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateViewfinderAllocation = (
  householdFinId: string,
  exerciseId: string,
  name: string,
  stocks: number,
  bonds: number
) => {
  const requestBody = {
    selectedAssetAllocation: name,
    selectedAssetAllocationBonds: bonds,
    selectedAssetAllocationStock: stocks
  }
  return {
    type: UPDATE_VIEWFINDER_EXERCISE,
    payload: api()
      .put(
        `/households/${householdFinId}/viewfinder/${exerciseId}`,
        requestBody
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const deleteViewfinderExercise = (
  householdFinId: string,
  viewfinderId: string
): { type: string; payload: Promise<AxiosResponse<InvResponse.Response>> } => {
  return {
    type: DELETE_VIEWFINDER_EXERCISES,
    payload: api()
      .delete(`/households/${householdFinId}/viewfinder/${viewfinderId}`)
      .then((response: AxiosResponse<InvResponse.Response>) => {
        return response
      })
  }
}

export const createViewfinderAnswer = (
  householdFinId: string,
  exerciseId: string,
  questionId: string,
  tax: number,
  performance: number,
  protection: number,
  cost: number,
  answer: InvestmentViewfinderAnswer
) => {
  const requestBody = {
    value: `${tax},${performance},${protection},${cost}`,
    questionId,
    response: answer,
    step: 'Priorities Analysis'
  }
  return {
    type: CREATE_VIEWFINDER_ANSWER,
    payload: api()
      .post(
        `/households/${householdFinId}/viewfinder/${exerciseId}/answers`,
        requestBody
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateViewfinderAnswer = (
  householdFinId: string,
  exerciseId: string,
  answerId: string,
  questionId: string,
  tax: number,
  performance: number,
  protection: number,
  cost: number,
  answer: InvestmentViewfinderAnswer
) => {
  const requestBody = {
    value: `${tax},${performance},${protection},${cost}`,
    questionId,
    response: answer,
    step: 'Priorities Analysis'
  }
  return {
    type: UPDATE_VIEWFINDER_ANSWER,
    payload: api()
      .put(
        `/households/${householdFinId}/viewfinder/${exerciseId}/answers/${answerId}`,
        requestBody
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const getViewfinderQuestions = () => {
  return {
    type: FETCH_VIEWFINDER_QUESTIONS,
    payload: api()
      .get(`/viewfinder/questions`)
      .then((response: AxiosResponse<InvResponse.Response>) => {
        return response
      })
  }
}

export const getAllocations = () => {
  return {
    type: FETCH_VIEWFINDER_ALLOCATIONS,
    payload: api()
      .get(`/viewfinder/allocations`)
      .then((response: AxiosResponse<InvResponse.Response>) => {
        return response
      })
  }
}

export const getPriorities = () => {
  return {
    type: FETCH_VIEWFINDER_PRIORITIES,
    payload: api()
      .get(`/viewfinder/priorities`)
      .then((response: AxiosResponse<InvResponse.Response>) => {
        return response
      })
  }
}

export const getGeographicPriorities = () => {
  return {
    type: FETCH_VIEWFINDER_GEOGRAPHIC_PRIORITIES,
    payload: api()
      .get(`/viewfinder/priorities/geography`)
      .then((response: AxiosResponse<InvResponse.Response>) => {
        return response
      })
  }
}
