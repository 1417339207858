import React, { ReactElement, MouseEvent } from 'react'
import { ReactComponent as ProgressIcon } from '../../assets/images/icons/progress.svg'
import { ReactComponent as CompleteIcon } from '../../assets/images/icons/check.svg'
import { Progress } from '../../../objects/household'
import { history } from '../../../store'

export interface HouseholdProgressProps {
  progress: Progress
  className: string
  to?: string
}

class HouseholdProgress extends React.Component<HouseholdProgressProps> {
  public onClick = (e: MouseEvent) => {
    const { to } = this.props
    if (e.stopPropagation) {
      e.stopPropagation()
    }
    history.push(to)
  }
  public render() {
    let button: ReactElement
    switch (this.props.progress) {
      case 'not started':
        button = <div className='household-progress__start-btn'>Start</div>
        break
      case 'in progress':
        button = (
          <div className='household-progress__icon'>
            <ProgressIcon />
          </div>
        )
        break
      case 'completed':
        button = (
          <div className='household-progress__icon'>
            <CompleteIcon />
          </div>
        )
        break
      default:
        button = <div className='household-progress__start-btn'>Start</div>
    }
    return (
      <div
        className={`households__column ${this.props.className}`}
        onClick={this.onClick}>
        {button}
      </div>
    )
  }
}

export default HouseholdProgress
