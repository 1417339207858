import React from 'react'
import Modal from '../../components/layout/modal'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import * as actions from '../../../actions/insurance'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import { addToast } from '../../../actions/toasts'

interface ConfirmDeleteAccountProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  insuranceId: string
  closeModal(): void
}

class ConfirmDeleteAccountModal extends React.Component<
  ConfirmDeleteAccountProps
> {
  public handleDeactiveTask = async () => {
    const { householdFinId, insuranceId, dispatch, closeModal } = this.props
    await dispatch(actions.isDeleted(householdFinId, insuranceId))
    dispatch(
      addToast({
        icon: DeleteIcon,
        message: 'Policy Deleted.',
        backgroundColor: '#D9F2B6'
      })
    )
    dispatch(actions.getInsurance(householdFinId))
    closeModal()
  }

  public render() {
    const { closeModal } = this.props
    return (
      <Modal icon={DeleteIcon} title='Delete Account' closeModal={closeModal}>
        <div className='pal-confirm-modal'>
          <div className='pal-confirm-modal__content'>
            <p>Are you sure you want to delete this account?</p>
          </div>
          <div className='pal-confirm-modal__buttons'>
            <span className='btn btn__clear' onClick={closeModal}>
              Cancel
            </span>
            <span className='btn btn__prime' onClick={this.handleDeactiveTask}>
              Delete
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    priorityActions: store.priorityActions[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(ConfirmDeleteAccountModal))
