import React from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg'
import { FileObj, FolderObj } from '../../../objects/folder'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import Tile from '../../components/layout/tile'
import TableHeader from '../../components/layout/tableHeader'
import File from './file'
import { ReactComponent as PreviousIcon } from '../../assets/images/icons/previous.svg'
import { history } from '../../../store'
import { withRouter } from 'react-router'
import * as actions from '../../../actions/documentVault'
import UploadFile from './uploadFile'
import api from '../../helpers/api'
import PendingFile from './pendingFile'
import { v4 } from 'uuid'
import InnerContainer from '../../components/layout/innerContainer'
import NullDocVault from './nullView'
import { isObjEmpty } from '../../helpers'
import DeleteModal from './deleteModal'
import EditModal from './editModal'
import { addToast } from '../../../actions/toasts'
import AlertMessageToast from '../../components/alertMessageToast'
import * as alertMessageActions from '../../../actions/showAlert'
import { noneArchivedFilesSelector } from '../../../selectors/v3/documentVault'

export interface FolderDetailProps {
  householdFinId: string
  folder: FolderObj
  folderId: string
  showAlert: boolean
  message: string
  isSuccess: boolean
  dispatch: Dispatch<GlobalState>
}

interface TempFileObj {
  name: string
  uploadProgress: number
}

interface FolderDetailState {
  pendingUploads: {
    [key: string]: TempFileObj
  }
  showDeleteModal: boolean
  fileFormatError: boolean
  showUploadFileModal: boolean
  fileUploadPending: boolean
  showEditModal: boolean
  currentFileId: string
  currentFileName: string
}

export class FolderDetail extends React.Component<
  FolderDetailProps,
  FolderDetailState
> {
  constructor(props: FolderDetailProps) {
    super(props)
    this.state = {
      pendingUploads: {},
      showDeleteModal: false,
      showEditModal: false,
      fileFormatError: false,
      fileUploadPending: false,
      showUploadFileModal: false,
      currentFileId: '',
      currentFileName: ''
    }
  }
  public componentDidMount() {
    const { dispatch, showAlert, householdFinId } = this.props
    dispatch(actions.getFolders(householdFinId))
    showAlert && dispatch(alertMessageActions.hideSyncModal())
  }

  public fileSuccess = (_response: any, uuid: string) => {
    const { pendingUploads } = this.state
    const { folderId, householdFinId, dispatch } = this.props
    const compState = { ...pendingUploads }
    delete compState[uuid]
    this.setState({
      showUploadFileModal: false,
      fileUploadPending: false,
      fileFormatError: false,
      pendingUploads: compState
    })
    dispatch(actions.getFolder(householdFinId, folderId))
    dispatch(
      addToast({
        icon: FolderIcon,
        message: 'File uploaded.',
        backgroundColor: '#D9F2B6'
      })
    )
  }
  public fileFailure = (uuid: string) => {
    const { pendingUploads } = this.state
    const compState = { ...pendingUploads }
    delete compState[uuid]
    this.setState({
      pendingUploads: compState,
      fileUploadPending: false,
      fileFormatError: true
    })
  }

  public onUploadProgress = (progressEvent: any, uuid: string) => {
    const { pendingUploads } = this.state
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    pendingUploads[uuid].uploadProgress = percentCompleted
    this.setState({
      pendingUploads
    })
  }
  /**
   * 30MB = 31457280(binary) / 1024 / 1024
   * 30000000 = decimal
   * 31457280 = binary
   * 75mb = 78643200(binary) / 1024 / 1024
   * 75000000 = decimal
   * 78643200 = binary
   * 200mb = 209715200(binary) / 1024 / 1024
   * 200000000 = decimal
   * 209715200 = binary
   */
  public validateFileSizeLimit = (
    rawFile: any,
    uuid: string,
    maxFileSize: number
  ) => {
    const { pendingUploads } = this.state
    const fileLimit =
      window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE * 1024 * 1024
    if (fileLimit && rawFile?.size > fileLimit) {
      const compState = { ...pendingUploads }
      delete compState[uuid]
      this.setState({
        fileFormatError: true,
        fileUploadPending: false,
        pendingUploads: compState
      })
      return true
    }
    return false
  }

  public uploadFiles = (files: any[]) => {
    const { householdFinId, folderId } = this.props
    const { pendingUploads } = this.state
    files.forEach((rawFile) => {
      const uuid = v4()
      const fileName = rawFile.name.replace(/ /g, '_').toLowerCase()
      pendingUploads[uuid] = {
        name: fileName,
        uploadProgress: 0
      }
      this.setState({
        pendingUploads,
        fileUploadPending: true,
        fileFormatError: false
      })

      if (
        this.validateFileSizeLimit(
          rawFile,
          uuid,
          window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
        )
      ) {
        return
      }
      const formData = new FormData()
      formData.append('file', rawFile, fileName)

      api()
        .post(
          `/households/${householdFinId}/pdv/v2/folders/${folderId}/files`,
          formData,
          {
            onUploadProgress: (progressEvent) =>
              this.onUploadProgress(progressEvent, uuid),
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 0
          }
        )
        .then(
          (_response) => this.fileSuccess(_response, uuid),
          () => this.fileFailure(uuid)
        )
    })
  }

  toggleUploadFileModal = () => {
    this.setState({
      showUploadFileModal: !this.state.showUploadFileModal,
      fileFormatError: false,
      fileUploadPending: false
    })
  }

  public pendingUploads = () => {
    const { pendingUploads } = this.state
    return Object.keys(pendingUploads).map((key, index) => {
      const pendingFile = pendingUploads[key]
      return (
        <PendingFile
          key={index}
          name={pendingFile.name}
          uploadProgress={pendingFile.uploadProgress}
        />
      )
    })
  }
  public toggleDeleteModal = (id: string) => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      currentFileId: id
    })
  }
  public deleteFn = async (id: string, archived: boolean) => {
    const { dispatch, householdFinId, folderId } = this.props
    try {
      await dispatch(
        actions.updateArchivedFile(householdFinId, folderId, id, archived)
      )
      this.toggleDeleteModal(id)
      dispatch(
        alertMessageActions.showSyncModal('File successfully deleted.', true)
      )
    } catch (_) {
      this.toggleDeleteModal('')
      dispatch(
        alertMessageActions.showSyncModal(
          'There was an error deleting the file.',
          false
        )
      )
    }
  }

  public undoDelete = async () => {
    const { dispatch, householdFinId, folderId } = this.props
    const { currentFileId } = this.state
    try {
      await dispatch(
        actions.updateArchivedFile(
          householdFinId,
          folderId,
          currentFileId,
          false
        )
      )
      dispatch(alertMessageActions.hideSyncModal())
      dispatch(actions.getFolder(householdFinId, folderId))
    } catch (_) {
      dispatch(alertMessageActions.hideSyncModal())
    }
  }

  public toggleEditModal = (id: string, name: string) => {
    this.setState({
      showEditModal: !this.state.showEditModal,
      currentFileId: id,
      currentFileName: name
    })
  }

  public editFn = async (id: string, name: string) => {
    const { dispatch, householdFinId, folderId } = this.props
    const fileName = name.replace(/ /g, '_')
    await dispatch(actions.editFile(householdFinId, folderId, id, fileName))
    dispatch(actions.getFolder(householdFinId, folderId))
    dispatch(
      addToast({
        icon: FolderIcon,
        message: 'File name updated.',
        backgroundColor: '#D9F2B6'
      })
    )
    this.toggleEditModal('', '')
  }
  public files = () => {
    const { folderId, householdFinId, folder } = this.props
    return folder?.files?.map((file: FileObj, index) => {
      return (
        <File
          key={index}
          file={file}
          folderId={folderId}
          householdFinId={householdFinId}
          deleteModal={this.toggleDeleteModal}
          editModal={this.toggleEditModal}
        />
      )
    })
  }
  public nullFiles = () => {
    return (
      <div>
        <NullDocVault text='There are no files here yet.' />
      </div>
    )
  }
  public render() {
    const { folderId, showAlert, isSuccess, message, folder } = this.props
    const {
      pendingUploads,
      fileFormatError,
      showUploadFileModal,
      fileUploadPending,
      showDeleteModal,
      showEditModal
    } = this.state
    if (folderId) {
      return (
        <InnerContainer>
          <div
            className='folder-detail__btn--back'
            onClick={this.navigateToVault}>
            <PreviousIcon />
            <div>Back to Document Vault</div>
          </div>
          <div>
            <ContentHeader
              Icon={FolderIcon}
              title={folder.name}
              rightHeader={
                <UploadFile
                  fileUploadPending={fileUploadPending}
                  showUploadFileModal={showUploadFileModal}
                  toggleUploadFileModal={this.toggleUploadFileModal}
                  fileFormatError={fileFormatError}
                  uploadFiles={this.uploadFiles}
                  folderId={folderId}
                />
              }
            />
            <Tile>
              <div>
                <TableHeader>
                  <div className='folder-detail__name'>NAME</div>
                  <div className='folder-detail__date-created'>
                    DATE CREATED
                  </div>
                  <div className='folder-detail__actions'>ACTIONS</div>
                </TableHeader>
                {this.pendingUploads()}
                {this.files()}
                {folder?.files?.length === 0 && isObjEmpty(pendingUploads)
                  ? this.nullFiles()
                  : null}
              </div>
            </Tile>
          </div>
          {showDeleteModal ? (
            <DeleteModal
              type='file'
              id={this.state.currentFileId}
              deleteFn={this.deleteFn}
              closeModal={this.toggleDeleteModal}
            />
          ) : null}
          {showEditModal ? (
            <EditModal
              type='file'
              id={this.state.currentFileId}
              name={this.state.currentFileName}
              editFn={this.editFn}
              closeModal={this.toggleEditModal}
            />
          ) : null}

          {showAlert && (
            <AlertMessageToast
              icon={isSuccess ? 'success' : 'error'}
              title={isSuccess ? 'Success' : 'Failure'}
              message={message}
              bottomBtn={isSuccess ? 'Undo' : ''}
              bottomBtnFunc={this.undoDelete}
              stacked={false}
            />
          )}
        </InnerContainer>
      )
    } else return null
  }
  private navigateToVault = (): void => {
    const { householdFinId } = this.props
    history.push(`/households/${householdFinId}/documentVault`)
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdFinId = match.params.householdFinId
  const folderId = match.params.folderFinId
  return {
    folder: noneArchivedFilesSelector(
      store,
      householdFinId,
      match.params.folderFinId
    ),
    householdFinId,
    folderId,
    showAlert: store.showAlert.show,
    message: store.showAlert.message,
    isSuccess: store.showAlert.isSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => {
  return {
    dispatch
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FolderDetail)
)
