import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import * as HCExerciseActions from '../../../actions/hcExercises'
import { getContacts } from '../../../actions/contacts'
import { getGoalActions } from '../../../actions/financialGoalActions'

import { history } from '../../../store'

import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'
import {
  ContactsInterface,
  ContactObj,
  SelectedContactObj
} from '../../../objects/contact'
import { HouseholdObj, HouseholdInstitution } from '../../../objects/household'
import { UserInterface } from '../../../objects/user'

import InnerContainer from '../../components/layout/innerContainer'
import HcPageHeader from './display/pageHeader'
import HcPageFooter from './display/pageFooter'
import SharedPriorities from './sharedPriorities'
import SatisfactionScore from './satisfactionScore'
import HCPersonalPriorityTile from './hcPersonalPriorityTile'
import HonestConversationsNullRedo from './hcNullStateRedo'
import HcNoCombinedCards from './hcNoCombinedCards'
import HcSendLinkModal from './hcSendLinkModal'
import HcScoringEmailModal from './hcScoringEmailModal'
import { isObjEmpty } from '../../helpers'
import { getTopFiveCards } from '../../helpers/honestConversations'
import HcPrioritizationEmailModal from './hcPrioritizationEmailModal'
import DisabledView from './disabledView'

interface HonestConversationsProps {
  dispatch?: Dispatch<GlobalState>
  match: any
  householdFinId: string
  hcExercise: HonestConversationsExerciseObj
  contacts: ContactsInterface
  household: HouseholdObj
  userId: string
  users: UserInterface
  institution: HouseholdInstitution
  showHonestConversations: boolean
}

interface HonestConversationsState {
  showSendLinkModal: boolean
  showScoringEmailModal: boolean
  showPrioritizationEmailModal: boolean
  emailType: string
  contactType: string
  selectedContact: {
    value: ContactObj
    label: string
  }
}

export class HonestConversations extends Component<
  HonestConversationsProps,
  HonestConversationsState
> {
  constructor(props: HonestConversationsProps) {
    super(props)
    this.state = {
      showSendLinkModal: false,
      showScoringEmailModal: false,
      showPrioritizationEmailModal: false,
      emailType: null,
      contactType: null,
      selectedContact: {
        value: null,
        label: ''
      }
    }
  }

  public async componentDidMount() {
    const { dispatch, householdFinId, contacts } = this.props
    await dispatch(getContacts())
    dispatch(HCExerciseActions.getHonestConversationExercises(householdFinId))
    dispatch(getGoalActions(householdFinId))
    this.setState({
      selectedContact: {
        ...this.state.selectedContact,
        value: contacts && {
          ...contacts.primary,
          isPrimary: true
        },
        label:
          contacts &&
          `${contacts.primary?.firstName} ${contacts.primary?.lastName}`
      }
    })
  }

  public async componentDidUpdate(prevProps: HonestConversationsProps) {
    const { dispatch, household, contacts } = this.props
    if (household.hcExerciseId !== prevProps.household.hcExerciseId) {
      dispatch(HCExerciseActions.getHonestConversationExercises(household.id))
    }
    if (contacts && contacts.primary && !this.state.selectedContact.label) {
      this.setState({
        selectedContact: {
          ...this.state.selectedContact,
          value: contacts && {
            ...contacts.primary,
            isPrimary: true
          },
          label:
            contacts &&
            `${contacts.primary?.firstName} ${contacts.primary?.lastName}`
        }
      })
    }
  }

  public handleSendLinkSelectChange = () => (
    selectedContact: SelectedContactObj
  ) => {
    this.setState({ selectedContact })
  }

  public handleSendLinkButtonPress = (
    contact: ContactObj,
    isPrimary: boolean
  ) => {
    this.setState({
      selectedContact: {
        ...this.state.selectedContact,
        value: { ...contact, isPrimary },
        label: `${contact.firstName} ${contact.lastName}`
      }
    })
  }

  public updateByClientId = (clientId: string) => {
    const { contacts, hcExercise } = this.props
    const today = new Date()
    if (contacts.primary && contacts.primary.id === clientId) {
      return { ...hcExercise, emailSentPrimary: today }
    } else if (contacts.secondary && contacts.secondary.id === clientId) {
      return { ...hcExercise, emailSentSecondary: today }
    } else return hcExercise
  }

  public emailHonestConversations = async (clientId: string) => {
    const { householdFinId, dispatch, hcExercise, institution } = this.props
    await dispatch(
      HCExerciseActions.sendHonestConversationLinkEmail(
        householdFinId,
        clientId,
        institution.photoUrl,
        this.state.emailType
      )
    )
    const body = await this.updateByClientId(clientId)
    dispatch(
      HCExerciseActions.updateHonestConversationExercise(
        householdFinId,
        hcExercise.id,
        body
      )
    )
    this.setState({
      showSendLinkModal: false,
      showScoringEmailModal: false,
      showPrioritizationEmailModal: false
    })
  }

  public generateActiveExerciseWithToken = async (householdFinId: string) => {
    const { hcExercise, dispatch } = this.props
    const client = this.state.selectedContact.value
    await dispatch(
      HCExerciseActions.getOrCreateClientLoginToken(
        householdFinId,
        hcExercise.id,
        client.id,
        client.isPrimary
      )
    )
  }

  public showModalView = (type: string, emailType?: string) => async () => {
    const { householdFinId, hcExercise, dispatch } = this.props
    if (emailType) {
      this.setState({ emailType })
    }
    switch (type) {
      case 'sendLink':
        if (!hcExercise) {
          await dispatch(
            HCExerciseActions.createHonestConversationExercise(householdFinId)
          )
        }
        this.setState({ showSendLinkModal: true })
        break
      case 'sendScoringEmail':
        this.generateActiveExerciseWithToken(householdFinId)
        this.setState({ showScoringEmailModal: true })
        break
      case 'sendPrioritizationEmail':
        this.generateActiveExerciseWithToken(householdFinId)
        this.setState({ showPrioritizationEmailModal: true })
        break
      case 'launch':
        history.push(
          `/households/${householdFinId}/honestConversations/v3/exercise/new`
        )
        break
      case 'close':
        this.setState({
          showSendLinkModal: false,
          showScoringEmailModal: false,
          showPrioritizationEmailModal: false
        })
    }
  }

  public renderView = (honestConversation: HonestConversationsExerciseObj) => {
    const { householdFinId, contacts, showHonestConversations } = this.props
    if (!contacts) {
      return null
    }
    if (!showHonestConversations) {
      return <DisabledView householdFinId={householdFinId} />
    }
    const primaryId = contacts.primary ? contacts.primary.id : null
    const secondaryId = contacts.secondary ? contacts.secondary.id : null
    const primaryPersonalCards = honestConversation
      ? getTopFiveCards(honestConversation?.clientCards[primaryId])
      : []
    const secondaryPersonalCards = honestConversation
      ? getTopFiveCards(honestConversation?.clientCards[secondaryId])
      : []
    if (honestConversation) {
      if (!isObjEmpty(honestConversation.householdCards)) {
        return (
          <Fragment>
            <SatisfactionScore
              showSendLinkModal={this.showModalView('sendLink', 'scoring')}
              householdFinId={householdFinId}
              honestConversation={honestConversation}
              contacts={contacts}
            />
            <SharedPriorities
              householdFinId={householdFinId}
              honestConversation={honestConversation}
              contacts={contacts}
            />
            {contacts.secondary && secondaryPersonalCards.length !== 0 && (
              <HCPersonalPriorityTile
                contact={contacts.secondary}
                cards={secondaryPersonalCards}
                isPrimary={false}
              />
            )}
            {primaryPersonalCards.length !== 0 && (
              <HCPersonalPriorityTile
                contact={contacts.primary}
                cards={primaryPersonalCards}
                isPrimary={true}
              />
            )}
            <HcPageFooter householdFinId={householdFinId} />
          </Fragment>
        )
      } else {
        return (
          <HcNoCombinedCards
            contacts={contacts}
            sendEmailModal={this.showModalView('sendLink', 'prioritization')}
            handleSendLinkButtonPress={this.handleSendLinkButtonPress}
            primaryPersonalCards={primaryPersonalCards}
            secondaryPersonalCards={secondaryPersonalCards}
            honestConversation={honestConversation}
          />
        )
      }
    } else {
      return (
        <HonestConversationsNullRedo
          contacts={contacts}
          householdFinId={householdFinId}
          showSendLinkModal={this.showModalView('sendLink', 'prioritization')}
          launchHCExercise={this.showModalView('launch')}
        />
      )
    }
  }

  public render() {
    const {
      contacts,
      institution,
      userId,
      users,
      hcExercise,
      householdFinId,
      household,
      showHonestConversations
    } = this.props

    const {
      showSendLinkModal,
      showScoringEmailModal,
      emailType,
      selectedContact,
      showPrioritizationEmailModal
    } = this.state
    return (
      <InnerContainer>
        <HcPageHeader
          showHonestConversations={showHonestConversations}
          householdFinId={householdFinId}
          exerciseId={household.hcExerciseId}
        />
        {/* handles specific HC views */}
        {this.renderView(hcExercise)}

        {/*handles certain modals below */}
        {showSendLinkModal && (
          <HcSendLinkModal
            contacts={contacts}
            closeModal={this.showModalView('close')}
            sendEmailModal={this.showModalView(
              emailType === 'scoring'
                ? 'sendScoringEmail'
                : 'sendPrioritizationEmail'
            )}
            handleSelectChange={this.handleSendLinkSelectChange}
            selectedContact={selectedContact}
          />
        )}
        {showScoringEmailModal && (
          <HcScoringEmailModal
            institution={institution}
            user={users[userId]}
            contact={selectedContact.value}
            saveModal={this.emailHonestConversations}
            closeModal={this.showModalView('close')}
          />
        )}
        {showPrioritizationEmailModal && (
          <HcPrioritizationEmailModal
            institutionPhotoUrl={institution.photoUrl}
            user={users[userId]}
            contact={selectedContact.value}
            saveModal={this.emailHonestConversations}
            closeModal={this.showModalView('close')}
          />
        )}
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId } = match.params
  const household = store.households[householdFinId]
  const hcExercises = store.hcExercises[householdFinId] || null
  const hcExercise =
    hcExercises && hcExercises[household.hcExerciseId]
      ? hcExercises[household.hcExerciseId]
      : null
  return {
    userId: store.user.userId,
    users: store.user.users,
    householdFinId,
    institution: store.households[householdFinId].institution,
    household,
    contacts: store.contact[householdFinId],
    hcExercise,
    showHonestConversations:
      store.households[match.params.householdFinId]?.showHonestConversations
  }
}

export default withRouter(connect(mapStateToProps)(HonestConversations))
