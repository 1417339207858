import React, { FunctionComponent, SVGProps } from 'react'
import FilterIcon from '../../assets/images/icons/png/filter_blue_x2.png'

const ContentHeader = ({
  Icon,
  title,
  rightHeader = null,
  iconPng,
  hasFilter,
  isFooter = false,
  onFilterClick
}: {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string | React.ReactNode[]
  rightHeader?: React.ReactNode
  iconPng?: string
  hasFilter?: boolean
  isFooter?: boolean
  onFilterClick?(): void
}) => {
  return (
    <div className={`content-header__w${isFooter ? '-footer' : ''}`}>
      <div className='content-header__w--left'>
        {Icon && !iconPng && (
          <div className='content-header__icon--w'>
            <Icon className='content-header__icon--icon' />
          </div>
        )}
        {iconPng && !Icon && (
          <div className='content-header__icon--w'>
            <img
              src={iconPng}
              className='content-header__icon--icon'
              alt=''
              role='presentation'
            />
          </div>
        )}
        <div className='content-header__title'>{title}</div>
        {hasFilter && (
          <div
            onClick={onFilterClick}
            className='content-header__icon-filter content-header__w--left'>
            <img src={FilterIcon} width={20} />
          </div>
        )}
      </div>
      {rightHeader ? (
        <div className='content-header__right'>{rightHeader}</div>
      ) : null}
    </div>
  )
}

export default ContentHeader
