import { OfficeTeamObj } from '../../objects/officeTeams'
import { UserObj, UsersObj } from '../../objects/user'
import { HouseholdObj } from '../../objects/household'

interface CoverPageInro {
  title: string
  clientName: string
  selectedAdvisor: UserObj
  defaultAdvisor: UserObj
  logo: string | null
  users: UsersObj
}

export const coverPageInfoSetter = (
  coverPageInfoState: CoverPageInro,
  household: HouseholdObj
) => {
  let teamAssigned: OfficeTeamObj
  let defautOfficeTeamMember: UserObj = coverPageInfoState.selectedAdvisor
  const teamMembers = {}

  if (household.officeTeams) {
    household.officeTeams.forEach(
      (officeTeamObj: OfficeTeamObj, index: number) => {
        if (officeTeamObj.id === household.officeTeam) {
          teamAssigned = officeTeamObj
        }
      }
    )
    teamAssigned &&
      teamAssigned.members.forEach((member) => {
        const { user } = member
        teamMembers[user.id] = user
        if (
          teamAssigned.planningSoftwareUserId === member.userId &&
          !defautOfficeTeamMember
        ) {
          defautOfficeTeamMember = teamMembers[user.id]
        }
      })
    if (!defautOfficeTeamMember) {
      const defaultSelection = Object.keys(teamMembers).filter(
        (key, index: number) => {
          return index === 0 ? key : null
        }
      )
      defautOfficeTeamMember = teamMembers[defaultSelection[0]]
    }
  }

  return {
    ...coverPageInfoState,
    defaultAdvisor: defautOfficeTeamMember,
    selectedAdvisor: defautOfficeTeamMember,
    users: teamMembers,
    teamAssigned
  }
}
