export const STOP_LOADER: string = 'STOP_LOADER'
export const START_LOADER: string = 'START_LOADER'

export const stopLoader = () => {
  return {
    type: STOP_LOADER
  }
}

export const startLoader = () => {
  return {
    type: START_LOADER
  }
}
