import React from 'react'
import Modal from '../../components/layout/modal'
import { ContactsInterface } from '../../../objects/contact'
import { ReactComponent as HonestConversationsIcon } from '../../assets/images/icons/svg/honestconversations.svg'
import sendIcon from '../../assets/images/icons/svg/ic_send.svg'
import Select from 'react-select'

interface HcSendLinkModalProps {
  contacts: ContactsInterface
  selectedContact: any
  handleSelectChange(): any
  closeModal(): void
  sendEmailModal(): void
}

export class HcSendLinkModal extends React.Component<HcSendLinkModalProps> {
  public handleEmailClick = () => {
    this.props.closeModal()
    this.props.sendEmailModal()
  }

  public generateSelectOptions = () => {
    const { contacts } = this.props
    const options: any = []
    if (contacts && contacts.primary) {
      options.push({
        value: { ...contacts.primary, isPrimary: true },
        label: `${contacts.primary.firstName} ${contacts.primary.lastName}`
      })
    }
    if (contacts && contacts.secondary) {
      options.push({
        value: { ...contacts.secondary, isPrimary: false },
        label: `${contacts.secondary.firstName} ${contacts.secondary.lastName}`
      })
    }
    return options
  }

  public render() {
    const {
      closeModal,
      contacts,
      handleSelectChange,
      selectedContact
    } = this.props
    const selectOptions = this.generateSelectOptions()
    const isValidOption = selectedContact.value || selectedContact.label
    const defaultSelection =
      contacts && contacts.primary
        ? {
            value: { ...contacts.primary, isPrimary: true },
            label: `${contacts.primary.firstName} ${contacts.primary.lastName}`
          }
        : selectedContact
    return (
      <Modal
        icon={HonestConversationsIcon}
        title={'SEND LINK'}
        closeModal={closeModal}>
        <div className='hc-send-link-modal'>
          <div className='hc-send-link-modal__dropdown-w'>
            <Select
              value={isValidOption ? selectedContact : defaultSelection}
              onChange={handleSelectChange()}
              options={selectOptions}
            />
          </div>
          <span className='hc-send-link-modal__link-text'>
            How would you like to send the link?
          </span>
          <div
            className='hc-send-link-modal__content-w'
            style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='hc-send-link-modal__button-w'>
              <div
                className='hc-send-link-modal__button'
                onClick={this.handleEmailClick}>
                <img
                  className='hc-send-link-modal__button-icon'
                  src={sendIcon}
                  alt='email'
                />
                <p className='hc-send-link-modal__button-text'>Send email</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default HcSendLinkModal
