/***
 * This file is created to move the logout logic version (V1 | V2 | V3) Free
 * The reference files for the logic is the helper file in each version and all version share the same logic
 * Reference: "./v<==VERSION_NUMBER==>/helpers/index"
 * The test case was failing as the middleware authentication can only using the one logout function instance and it could be v1/2/3
 * There is condition to load the v1/v2/v3 codebase based on the insititution version
 * In order to remove the dependency of the these Functions i created this shared helper file and folder
 */

import qs from 'query-string'

export const getLoginURL = (): string => {
  const url: string = `${window.location.protocol}//${window.location.host}`
  const authQS = qs.stringify({
    response_type: 'code',
    client_id: window._env_.REACT_APP_CLIENT_KEY,
    redirect_uri: url + '/auth/callback',
    expid: 'AC'
  })
  return `${window._env_.REACT_APP_OAUTH_URL_BASE}/services/oauth2/authorize/expid_AC?${authQS}`
}

export const logout = async () => {
  const loginURL = getLoginURL()
  localStorage.removeItem('accessToken')
  const logoutUrl = `${
    window._env_.REACT_APP_OAUTH_URL_BASE
  }/secur/logout.jsp?startURL=${encodeURIComponent(loginURL)}`
  window.location.href = logoutUrl
}
