import React, { Component } from 'react'
import logo_hc from '../../../assets/images/logos/logo_hc.svg'
import { GlobalState } from '../../../../reducers'
import { withRouter } from 'react-router'
import {
  HonestConversationsExerciseInterface,
  HouseholdCardObj
} from '../../../../objects/honestConversations'
import { HouseholdObj } from '../../../../objects/household'
import { connect } from 'react-redux'
import { getCardIcon } from '../../../helpers'
import { getActiveHouseholdCards } from '../../../helpers/honestConversations'

interface HonestConversationProps {
  hcExercises: HonestConversationsExerciseInterface
  household: HouseholdObj
}

class HonestConversation extends Component<HonestConversationProps> {
  public hcCardsDisplay = (hcCards: HouseholdCardObj[]) => {
    return hcCards.map((card: HouseholdCardObj, index: number) => {
      return (
        <div
          key={`${card.id}-${index}`}
          className={`honest-conversation__boxes honest-conversation__boxes-${card.card?.category?.toLowerCase()}`}>
          <p>{card.card.title}</p>
          <div className='honest-conversation__boxes-img'>
            <img src={getCardIcon(card.card.category)} alt='no' />
          </div>
        </div>
      )
    })
  }

  public render() {
    const { household, hcExercises } = this.props
    const hcCards: HouseholdCardObj[] = getActiveHouseholdCards(
      household,
      hcExercises
    )
    if (!hcCards || !hcCards.length) {
      return (
        <div>
          <div className='no-result'>
            <img className='no-result' src={logo_hc} alt='no' />
            <div className='no-result__heading'>No results yet</div>
            <div className='no-result__sub-heading'>
              Understand your client’s priorities and build a better portfolio
              proposal with Honest Conversations
              <sup>&reg;</sup>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='honest-conversation'>
          <div className='hc-components__row'>
            {this.hcCardsDisplay(hcCards)}
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdId = match?.params?.householdFinId
  return {
    investments: store.investments[householdId],
    household: store.households[householdId],
    hcExercises: store.hcExercises[householdId]
  }
}
export default withRouter(connect(mapStateToProps)(HonestConversation))
