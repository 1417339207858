import React, { Fragment } from 'react'

export const ScoreGraphLegend: React.FunctionComponent<{
  firstName: string
  secondaryStyle: boolean
}> = ({ firstName, secondaryStyle }) => {
  const lineStyle = secondaryStyle
    ? `hc-components__line hc-components__line--secondary`
    : `hc-components__line hc-components__line--primary`

  return (
    <Fragment>
      <div className='hc-components__legend'>
        <div className={lineStyle} />
      </div>
      <div>{firstName}</div>
    </Fragment>
  )
}
