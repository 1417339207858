import React from 'react'
import Modal from '../../components/layout/modal'

import TextArea from '../../components/TextArea'
import { ReactComponent as EditIconSvg } from '../../assets/images/icons/svg/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'

interface EditRecentGoalCommentProp {
  recentGoalComment: string
  saveFunc(notes: string): void
  closeModal(): void
}

interface EditRecentGoalCommentState {
  notes: string
  isBlank: boolean
}

class EditRecentGoalComment extends React.Component<
  EditRecentGoalCommentProp,
  EditRecentGoalCommentState
> {
  constructor(props: EditRecentGoalCommentProp) {
    super(props)
    this.state = {
      notes: props.recentGoalComment || '',
      isBlank: false
    }
  }

  public saveScoreComment = () => {
    this.props.saveFunc(this.state.notes)
  }

  public deleteScoreComment = () => {
    // pass null
    this.props.saveFunc(null)
  }

  public setInputState = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget
    this.setState({
      notes: value
    })
  }

  public render() {
    const { closeModal, recentGoalComment } = this.props
    const { isBlank } = this.state

    return (
      <Modal icon={EditIconSvg} title='EDIT COMMENT' closeModal={closeModal}>
        <div className='funded-score-edit-comment'>
          <div className='funded-score-edit-comment__content'>
            <p className='funded-score-edit-comment_label'>
              <label>Notes for client</label>
            </p>
            <TextArea
              controlFunc={this.setInputState}
              value={this.state.notes}
              name='notes'
              maxlength={300}
              noresize={true}
              error={isBlank}
            />
          </div>
          <div className='funded-score-edit-comment__buttons'>
            <div>
              {recentGoalComment ? (
                <span
                  className='btn btn__action'
                  onClick={this.deleteScoreComment}>
                  <DeleteIcon /> Remove Note
                </span>
              ) : null}
            </div>
            <div>
              <span className='btn btn__clear' onClick={closeModal}>
                Cancel
              </span>
              <span className='btn btn__prime' onClick={this.saveScoreComment}>
                Save
              </span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default EditRecentGoalComment
