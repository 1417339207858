import React from 'react'
import qs from 'query-string'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import api from '../../helpers/api'
import { getLoginURL, logout } from '../../helpers'
import { history } from '../../../store'
import { setAccessToken } from '../../../actions/user'

interface LoginProps {
  dispatch: Dispatch<GlobalState>
}

const OAUTH_ERROR = 'OAUTH_APP_ACCESS_DENIED'

class Login extends React.Component<LoginProps> {
  public async componentDidMount() {
    const grantToken = qs.parse(window.location.search).code
    const oauthError = qs.parse(window.location.search).error
    const loginURL = getLoginURL()
    if (oauthError === OAUTH_ERROR) {
      logout()
    } else if (!grantToken) {
      sessionStorage.setItem('redirect', window.location.pathname)
      window.location.href = loginURL
    } else {
      const data: string = qs.stringify({
        grantToken,
        redirectUri: `${window.location.protocol}//${window.location.host}/auth/callback`
      })
      try {
        const response = await api().post('/auth/grantToken', data, {
          headers: { Authorization: '' }
        })
        const accessToken = response.data.accessToken
        this.props.dispatch(setAccessToken(accessToken))
        let from = sessionStorage.getItem('redirect')
        if (from?.indexOf('logout') !== -1) {
          from = '/'
        }
        history.push(from)
      } catch (e) {
        logout()
      }
    }
  }
  public componentWillUnmount() {
    sessionStorage.removeItem('redirect')
  }
  public render() {
    return <div />
  }
}

export default connect()(Login)
