import React, { Component } from 'react'

import Checkbox from '../../../../components/checkbox'
import { dollarFormat } from '../../../../helpers'
import { PortfolioBuilderAccountObj } from '../../../../../objects/portfolioBuilder'

interface CombineListRowProps {
  account: PortfolioBuilderAccountObj
  accountIdList: string[]
  selectedAccountId: string
  toggleCheckbox(account: PortfolioBuilderAccountObj): () => void
}

export class CombineListRow extends Component<CombineListRowProps, {}> {
  public render() {
    const {
      account,
      accountIdList,
      toggleCheckbox,
      selectedAccountId
    } = this.props
    const {
      accountNickname,
      accountNumber,
      registrationType,
      accountValue,
      id
    } = account
    return (
      <div className='combine__table-row'>
        <div className='combine__table-row-section'>
          <Checkbox
            noToggleAllowed={selectedAccountId === account.id}
            checked={accountIdList.includes(id)}
            checkedBorder={true}
            onChange={toggleCheckbox(account)}
          />
        </div>
        <div className='combine__table-row-section'>
          <div className='combine__table-row-account--name'>
            {accountNickname}
          </div>
          <div className='combine__table-row-account--number'>
            {accountNumber}
          </div>
        </div>
        <div className='combine__table-row-section'>
          <div
            className={
              registrationType ? 'combine__table-row-account--type' : ''
            }>
            <span>{registrationType}</span>
          </div>
        </div>
        <div className='combine__table-row-section'>
          <div className='combine__table-row-account--amount'>
            {dollarFormat(accountValue, 0)}
          </div>
        </div>
      </div>
    )
  }
}

export default CombineListRow
