import api from '../v3/helpers/api'
import {
  PortfolioProposalObj,
  PortfolioProposalPdfObj
} from '../objects/portfolioProposal'
import { AxiosResponse } from 'axios'

export const FETCH_PORTFOLIOPROPOSAL = 'FETCH_PORTFOLIOPROPOSAL'
export const CREATE_PORTFOLIOPROPOSAL = 'CREATE_PORTFOLIOPROPOSAL'
export const DELETE_PORTFOLIOPROPOSAL = 'DELETE_PORTFOLIOPROPOSAL'
export const UPLOAD_PORTFOLIOPROPOSAL_PDF = 'UPLOAD_PORTFOLIOPROPOSAL_PDF'
export const DELETE_PORTFOLIOPROPOSAL_PDF = 'DELETE_PORTFOLIOPROPOSAL_PDF'

export const getPortfolioProposals = (householdFinId: string) => {
  return {
    type: FETCH_PORTFOLIOPROPOSAL,
    payload: api()
      .get(`/households/${householdFinId}/portfolioproposals`)
      .then((response: AxiosResponse) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createPortfolioProposal = (
  householdFinId: string,
  request: PortfolioProposalObj
) => {
  return {
    type: CREATE_PORTFOLIOPROPOSAL,
    payload: api()
      .post(`/households/${householdFinId}/portfolioproposals`, request)
      .then((response: AxiosResponse) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const deletePortfolioProposal = (
  householdFinId: string,
  portfolioProposalFinId: string
) => {
  return {
    type: DELETE_PORTFOLIOPROPOSAL,
    payload: api()
      .delete(
        `/households/${householdFinId}/portfolioproposals/${portfolioProposalFinId}`
      )
      .then((response: AxiosResponse) => {
        response.data = {
          householdFinId,
          portfolioProposalFinId
        }
        return response
      })
  }
}

export const uploadPortfolioProposalPdf = (
  householdFinId: string,
  portfolioProposalFinId: string,
  request: PortfolioProposalPdfObj
) => {
  const uploadMaxBytes = 65 * 1024 * 1024 // 65MB - Axios config is in bytes
  const uploadTimeOut = window._env_.REACT_APP_PP_UPLOAD_TIMEOUT || 90000
  return {
    type: UPLOAD_PORTFOLIOPROPOSAL_PDF,
    payload: api()
      .post(
        `/households/${householdFinId}/portfolioproposals/${portfolioProposalFinId}/pdf`,
        request,
        { maxContentLength: uploadMaxBytes, timeout: uploadTimeOut }
      )
      .then((response: AxiosResponse) => {
        response.data.householdFinId = householdFinId
        response.data.portfolioProposalFinId = portfolioProposalFinId
        return response
      })
  }
}
