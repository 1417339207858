import api from '../v3/helpers/api'
import { AxiosResponse } from 'axios'
import { OfficeTeamMemberObj } from '../objects/officeTeams'

export const FETCH_OFFICE_TEAMS = 'FETCH_OFFICE_TEAMS'
export const FETCH_ELIGIBLE_USERS = 'FETCH_ELIGIBLE_USERS'
export const CREATE_OFFICE_TEAM = 'CREATE_OFFICE_TEAM'
export const EDIT_OFFICE_TEAM = 'EDIT_OFFICE_TEAM'
export const DELETE_OFFICE_TEAM = 'DELETE_OFFICE_TEAM'
export const ADD_OFFICE_TEAM_MEMBERS = 'ADD_OFFICE_TEAM_MEMBERS'
export const EDIT_OFFICE_TEAM_MEMBER = 'EDIT_OFFICE_TEAM_MEMBER'
export const DELETE_OFFICE_TEAM_MEMBERS = 'DELETE_OFFICE_TEAM_MEMBERS'

export const getOfficeTeams = (): {
  type: string
  payload: Promise<AxiosResponse<any>>
} => {
  return {
    type: FETCH_OFFICE_TEAMS,
    payload: api()
      .get('/officeteams')
      .then((response) => {
        return response
      })
  }
}

export const getEligibleUsers = (
  id: string
): {
  type: string
  payload: Promise<AxiosResponse<any>>
} => {
  return {
    type: FETCH_ELIGIBLE_USERS,
    payload: api()
      .get(`/officeteams/${id}/eligibleUsers`)
      .then((response) => {
        response.data.eligibleUsers = response.data
        response.data.id = id
        return response
      })
  }
}
export const createOfficeTeam = (
  name: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: CREATE_OFFICE_TEAM,
    payload: api()
      .post('/officeteams', { name })
      .then((response) => {
        return response
      })
  }
}

export const editOfficeTeam = (
  id: string,
  name: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: EDIT_OFFICE_TEAM,
    payload: api()
      .put(`/officeteams/${id}`, { name })
      .then((response) => {
        return response
      })
  }
}

export const updatePlanningSoftwareUser = (
  id: string,
  userId: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: EDIT_OFFICE_TEAM,
    payload: api()
      .put(`/officeteams/${id}`, { planningSoftwareUserId: userId })
      .then((response) => {
        return response
      })
  }
}

export const deleteOfficeTeam = (
  id: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: DELETE_OFFICE_TEAM,
    payload: api()
      .delete(`/officeteams/${id}`)
      .then((response) => {
        response.data = {}
        response.data.id = id
        return response
      })
  }
}

export const addOfficeTeamMembers = (
  id: string,
  members: OfficeTeamMemberObj[]
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: ADD_OFFICE_TEAM_MEMBERS,
    payload: api()
      .post(`/officeteams/${id}/members`, { members })
      .then((response) => {
        response.data.members = response.data
        response.data.id = id
        return response
      })
  }
}

export const editOfficeTeamMember = (
  id: string,
  member: OfficeTeamMemberObj
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  const { officeTeamId, userId, showInGuidecenter } = member
  return {
    type: EDIT_OFFICE_TEAM_MEMBER,
    payload: api()
      .put(`/officeteams/${id}/members`, {
        officeTeamId,
        userId,
        showInGuidecenter
      })
      .then((response) => {
        return response
      })
  }
}

export const deleteOfficeTeamMembers = (
  id: string,
  userIds: string[]
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  return {
    type: DELETE_OFFICE_TEAM_MEMBERS,
    payload: api()
      .delete(`/officeteams/${id}/members?userIds=${userIds.join(',')}`)
      .then((response) => {
        response.data = {}
        response.data.id = id
        response.data.userIds = userIds
        return response
      })
  }
}
