import * as actions from '../actions/households'
import { OpportunitiesObj } from '../objects/opportunities'

export interface OpportunitiesState {
  [householdFinId: string]: OpportunitiesObj[]
}

export const initState: OpportunitiesState = {}

const Opportunities = (state = initState, action: any): OpportunitiesState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_OPPORTUNITIES + '_FULFILLED':
      const { householdFinId, opportunities } = action.payload.data
      newState[householdFinId] = opportunities
      return newState
    default:
      return state
  }
}

export default Opportunities
