//

import React, { Fragment } from 'react'
import {
  ClientEmailNotifications,
  ClientMobileNotifications
} from '../../../objects/settings/clientAdvisor'

export const NotificationSettings: React.FC<{
  title: string
  subTitle: React.ReactElement
  disableSettings: boolean
}> = (props) => {
  const { title, subTitle, disableSettings } = props
  return (
    <Fragment>
      <div className='a-settings__row'>
        <h2 className='a-settings__heading'>
          {title}
          {subTitle}
          {disableSettings && (
            <p className='c-settings__gc-error-text'>
              You do not have access to modify this client's settings.
            </p>
          )}
        </h2>
      </div>
      <div className='a-settings__row a-settings__row--content a-settings__row--header'>
        <span>NOTIFICATIONS TYPE</span>
        <div className='a-settings__row-toggle-w'>
          <span className='a-settings__header-label'>
            {ClientMobileNotifications?.length > 0 ? 'Mobile' : ''}
          </span>

          <span className='a-settings__header-label'>
            {ClientEmailNotifications?.length > 0 ? 'Email' : ''}
          </span>

          <span className='a-settings__header-label'>Web</span>
        </div>
      </div>

      {props.children}
    </Fragment>
  )
}
