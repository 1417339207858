import React, { Component } from 'react'
import GifPlayer from 'react-gif-player'
import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import Modal from '../../../../components/layout/modal'
import { ContactsInterface } from '../../../../../objects/contact'
import thirdStepSingleInstruction from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-3-single.gif'
import thirdStepSingleInstructionStill from '../../../../assets/images/honestConversations/hcInstructions/tutorial-03-step-3-single_still.jpg'

interface InstructionsProps {
  contacts?: ContactsInterface
  data: any
  closeModal(): void
}

interface InstructionsState {
  currentInstructionIndex: number
  playing: boolean
}
export class Instructions extends Component<
  InstructionsProps,
  InstructionsState
> {
  public state = {
    currentInstructionIndex: 0,
    playing: true
  }

  public delay = async (time: any) => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }

  public async componentDidMount() {
    this.delay(12800).then(() => {
      this.setState({
        playing: false
      })
    })
  }

  public handleTogglePlay = (playing: boolean) => {
    this.setState({
      playing
    })
    this.delay(12800).then(() => {
      this.setState({
        playing: false
      })
    })
  }

  public handleCurrentInstructionIndexChange = () => {
    const { currentInstructionIndex } = this.state
    if (currentInstructionIndex < 3) {
      this.setState({
        currentInstructionIndex: this.state.currentInstructionIndex + 1
      })
    }
    if (currentInstructionIndex === 3) {
      this.props.closeModal()
    }
  }

  public gotItButton = () => {
    return (
      <div className='hc-pick-shared-card__button-w'>
        <Button
          btnStyle={{ padding: '10px 34px' }}
          onClick={
            Boolean(this.props.contacts?.secondary)
              ? this.handleCurrentInstructionIndexChange
              : this.props.closeModal
          }>
          Got It!
        </Button>
      </div>
    )
  }

  public sliderDots = (index: number, id: number) => {
    const currentClass =
      index === id
        ? 'hc-pick-shared-card__bottom-dot hc-pick-shared-card__bottom-dot--active'
        : 'hc-pick-shared-card__bottom-dot'
    return <div className={currentClass} />
  }

  public renderGif(index: number) {
    return <img src={this.props.data[index].gif} alt={`step ${index}`} />
  }
  public render() {
    const { currentInstructionIndex, playing } = this.state
    const { contacts, data } = this.props
    return (
      <Modal
        largeHeader={false}
        headerStyle={{ display: 'none' }}
        contentStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '581px',
          padding: '24px',
          position: 'relative',
          justifyContent: 'space-around'
        }}>
        <div className='hc-pick-shared-card__instructions-gif'>
          {Boolean(contacts?.secondary) ? (
            this.renderGif(currentInstructionIndex)
          ) : playing ? (
            <GifPlayer
              key={1}
              gif={thirdStepSingleInstruction}
              still={thirdStepSingleInstructionStill}
              onTogglePlay={this.handleTogglePlay}
              autoplay={playing}
            />
          ) : (
            <GifPlayer
              key={2}
              gif={thirdStepSingleInstruction}
              still={thirdStepSingleInstructionStill}
              onTogglePlay={this.handleTogglePlay}
              autoplay={playing}
            />
          )}
        </div>
        {Boolean(contacts?.secondary) && (
          <div className='hc-pick-shared-card__instructions-text'>
            {data[currentInstructionIndex].text}
          </div>
        )}
        {this.gotItButton()}
        {Boolean(contacts?.secondary) && (
          <div className='hc-pick-shared-card__bottom-dots-w'>
            {this.sliderDots(currentInstructionIndex, 0)}
            {this.sliderDots(currentInstructionIndex, 1)}
            {this.sliderDots(currentInstructionIndex, 2)}
            {this.sliderDots(currentInstructionIndex, 3)}
          </div>
        )}
      </Modal>
    )
  }
}

export default Instructions
