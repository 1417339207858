import React from 'react'
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg'
import CreateFolderModal from './createFolderModal'
import Button from '../../components/button'

interface CreateFolderProps {
  householdFinId: string
}

interface CreateFolderState {
  createFolderModalOpen: boolean
}

class CreateFolder extends React.Component<
  CreateFolderProps,
  CreateFolderState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      createFolderModalOpen: false
    }
  }
  public openCreateFolderModal = () => {
    this.setState({ createFolderModalOpen: true })
  }
  public closeCreateFolderModal = (): void => {
    this.setState({ createFolderModalOpen: false })
  }
  public render() {
    const { householdFinId } = this.props
    const { createFolderModalOpen } = this.state
    return (
      <div>
        <Button primary={true} onClick={this.openCreateFolderModal}>
          <FolderIcon />
          <div className='vault__btn--text'>Create Folder</div>
        </Button>
        {createFolderModalOpen ? (
          <CreateFolderModal
            householdFinId={householdFinId}
            closeModal={this.closeCreateFolderModal}
          />
        ) : null}
      </div>
    )
  }
}

export default CreateFolder
