import React, { CSSProperties, FunctionComponent, SVGProps } from 'react'
import { Link } from 'react-router-dom'

export interface TileProps {
  children?: React.ReactNode | string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  iconPng?: string
  to?: string
  headerBorder?: boolean
  rightHeader?: React.ReactNode | string
  leftHeader?: React.ReactNode | string
  middleHeader?: React.ReactNode | string
  anchorBorder?: boolean
  style?: CSSProperties
  contentStyle?: CSSProperties
  headerStyle?: CSSProperties
  largeHeader?: boolean
}

class Tile extends React.Component<TileProps> {
  public leftHeader = () => {
    const { leftHeader, largeHeader, iconPng } = this.props
    const Icon = this.props.icon
    return (
      <div
        className={
          largeHeader
            ? 'tile__header-left tile__header--large-text'
            : 'tile__header-left'
        }>
        {Icon ? <Icon className='tile__header-icon' /> : null}
        {iconPng ? (
          <img
            src={iconPng}
            alt='Png Image'
            className='tile__header-icon--png'
          />
        ) : null}
        {leftHeader}
      </div>
    )
  }

  public middleHeader = () => {
    const { middleHeader } = this.props
    return <div className='tile__header-middle'>{middleHeader}</div>
  }

  public rightHeader = () => {
    const { rightHeader } = this.props
    return <div className='tile__header-right'>{rightHeader}</div>
  }

  public stlyeInclude = () => {
    const { headerBorder, anchorBorder } = this.props
    if (headerBorder && !anchorBorder) {
      return 'tile__header tile__header--border'
    } else if (!headerBorder && anchorBorder) {
      return 'tile__header tile__header--anchor'
    } else if (headerBorder && anchorBorder) {
      return 'tile__header tile__header--border tile__header--anchor'
    } else {
      return 'tile__header'
    }
  }

  public header = () => {
    const {
      rightHeader,
      leftHeader,
      middleHeader,
      headerStyle,
      to
    } = this.props
    if (leftHeader || rightHeader || middleHeader) {
      if (to) {
        return (
          <Link to={to} className={this.stlyeInclude()} style={headerStyle}>
            {leftHeader ? this.leftHeader() : null}
            {middleHeader ? this.middleHeader() : null}
            {rightHeader ? this.rightHeader() : null}
          </Link>
        )
      } else {
        return (
          <div className={this.stlyeInclude()} style={headerStyle}>
            {leftHeader ? this.leftHeader() : null}
            {middleHeader ? this.middleHeader() : null}
            {rightHeader ? this.rightHeader() : null}
          </div>
        )
      }
    } else {
      return null
    }
  }

  public render() {
    const { style, contentStyle, children } = this.props
    return (
      <div className='tile-w' style={style}>
        {this.header()}
        <div className='tile__content' style={contentStyle}>
          {children}
        </div>
      </div>
    )
  }
}

export default Tile
