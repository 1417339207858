import React, { useState, useEffect } from 'react'
import HonestConversationEditCardRow from '../hcEditCardRow'
import { ContactsInterface, ContactObj } from '../../../../../objects/contact'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import handleImage from '../../../../assets/images/icons/ic_handle.png'
import { CardObj } from '../../../../../objects/HonestConversations/card'
import { CardRankingObj } from '../../../../../objects/honestConversations'

interface HonestConversationEditIndividualCardsProps {
  contacts: ContactsInterface
  householdFinId: string
  primaryContactCards: CardRankingObj[]
  secondaryContactCards: CardRankingObj[]
  onCommentChange(index: number, contactType: string, comment: string): void
  onCardSelection(index: number, contactType: string, card: CardObj): void
  onDrag?(result: DropResult): void
  onBlur?(contactType: string, index: number, card: CardObj): void
}
const HonestConversationEditIndividualCards: React.FC<HonestConversationEditIndividualCardsProps> = (
  props
) => {
  const [initialComments, setinitialComments] = useState({})
  const [disableInputs, setDisableInputs] = useState(false)
  const {
    contacts,
    primaryContactCards,
    secondaryContactCards,
    onCardSelection,
    onCommentChange,
    householdFinId
  } = props

  // Logic for undo feature in comment section
  // This code is keeping the cards initial comment in component state
  const cardsInfo = {}
  useEffect(() => {
    ;['secondaryContactCards', 'primaryContactCards'].forEach(
      (type: string) => {
        cardsInfo[type] = {}
        props?.[type]?.map((card: CardRankingObj) => {
          cardsInfo[type][card?.card?.id] = card.comment || ''
        })
      }
    )
    setinitialComments(cardsInfo)
  }, [])

  const onMouseDown = (providedProps: any) => (event: any) => {
    if (providedProps.dragHandleProps)
      providedProps.dragHandleProps.onMouseDown(event)

    if (event.defaultPrevented) event.currentTarget.focus()
  }

  const honestConversationRows = (
    contactType: string,
    cards: CardRankingObj[]
  ) => {
    if (!cards) {
      return null
    }

    return cards.map((card, index) => {
      const key = card?.card?.id ? `card${card?.card?.id}` : `card${index}`
      return (
        <Draggable key={key} draggableId={key} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                ...provided.draggableProps.style
              }}
              className='hc-draggable-row__wrapper'>
              <div
                className='hc-draggable-row__handle'
                {...provided.dragHandleProps}
                onMouseDown={onMouseDown(provided)}>
                <img src={handleImage} alt='' role='presentation' />
              </div>

              <div className='hc-card-row__wrapper'>
                <HonestConversationEditCardRow
                  householdFinId={householdFinId}
                  key={key}
                  index={index}
                  displayIndex={index + 1}
                  contactType={contactType}
                  card={card}
                  selectedCards={cards}
                  initialComment={
                    initialComments?.[contactType]?.[card?.card?.id]
                  }
                  onCardSelection={onCardSelection}
                  onBlur={props.onBlur}
                  onCommentChange={onCommentChange}
                  setDisableInputs={setDisableInputs}
                  disableInputs={disableInputs}
                />
              </div>
            </div>
          )}
        </Draggable>
      )
    })
  }

  const onDragEnd = (result: DropResult) => {
    if (!result || !result.destination) {
      return
    }
    props.onDrag(result)
  }

  const contactCards = (
    contactType: string,
    contact: ContactObj,
    cards: CardRankingObj[]
  ) => {
    return (
      <div>
        <div className='hc-meeting__sub-header'>
          {contact && contact.firstName && (
            <div className='hc-meeting__sub-header--title'>
              {contact.firstName}'s Individual Results
            </div>
          )}
        </div>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={contactType} direction='vertical'>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {honestConversationRows(contactType, cards)}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }

  return (
    <div>
      {contacts &&
        contacts.primary &&
        contactCards(
          'primaryContactCards',
          contacts.primary,
          primaryContactCards
        )}
      {contacts &&
        contacts.secondary &&
        contactCards(
          'secondaryContactCards',
          contacts.secondary,
          secondaryContactCards
        )}
    </div>
  )
}

export default HonestConversationEditIndividualCards
