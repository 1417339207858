import React, { Component } from 'react'
import BookIcon from '../../assets/images/icons/png/il-book.png'
import Tile from '../../components/layout/tile'
import { formatPhoneNumber } from '../../helpers'

interface SuccessfulOrderProps {
  quantity: number
  recipientInfo: any
  template: string
  orderNumber: string
  totalPrice: string
  shippingSelectedName: string
}

class SuccessfulOrder extends Component<SuccessfulOrderProps> {
  public render() {
    const {
      quantity,
      recipientInfo,
      template,
      orderNumber,
      totalPrice,
      shippingSelectedName
    } = this.props
    return (
      <div className='mimeo__section-w'>
        <Tile
          iconPng={null}
          leftHeader={'ORDER PLACED SUCCESSFULLY'}
          style={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '20px'
          }}>
          <div className='mimeo__section'>
            <div className='mimeo__section-left mimeo__section-left--column'>
              <div className='mimeo-shipping__info-details'>
                <span>Order Number</span>
                <div>{orderNumber}</div>
              </div>
            </div>
          </div>
        </Tile>
        <br />
        <Tile
          iconPng={null}
          leftHeader={'SHIPPING INFO'}
          style={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '20px'
          }}>
          <div className='mimeo__section'>
            <div className='mimeo__section-left mimeo-shipping__title-w'>
              <div className='mimeo-shipping__title'>
                <img src={BookIcon} alt='book icon' />
                Guidebook
              </div>
            </div>
            <div className='mimeo__section-right mimeo__section-right--end'>
              <div className='mimeo-shipping__details'>
                <span>QUANTITY</span>
                {quantity}
              </div>
              <div className='mimeo-shipping__details'>
                <span>PRICE</span>
                <div>{totalPrice}</div>
              </div>
              <div className='mimeo-shipping__details'>
                <span>TEMPLATE</span>
                <div>{template}</div>
              </div>
            </div>
          </div>
          <div className='mimeo__section'>
            <div className='mimeo__section-left mimeo__section-left--column'>
              <div className='mimeo-shipping__info-details'>
                <span>NAME</span>
                <div>
                  {recipientInfo.firstName + ' ' + recipientInfo.lastName}
                </div>
              </div>
              {recipientInfo.street && (
                <div className='mimeo-shipping__info-details'>
                  <span>ADDRESS</span>
                  <div>
                    {recipientInfo.street +
                      ', ' +
                      recipientInfo.addressLine2 +
                      ' ' +
                      recipientInfo.city +
                      ', ' +
                      recipientInfo.state +
                      ' ' +
                      recipientInfo.postalCode}
                  </div>
                </div>
              )}
              {recipientInfo.phone && (
                <div className='mimeo-shipping__info-details'>
                  <span>PHONE</span>
                  <div>{formatPhoneNumber(recipientInfo.phone)}</div>
                </div>
              )}
              <div className='mimeo-shipping__info-details'>
                <span>SHIPPING METHOD</span>
                <div>{shippingSelectedName}</div>
              </div>
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

export default SuccessfulOrder
