import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { ContactsInterface } from '../../../../../objects/contact'
import { isObjEmpty } from '../../../../helpers'
import { ClientAccountInterface } from '../../../../../objects/clientAccount'
import {
  PortfolioAnalyticsFunc,
  PortfolioAnalyticsStateKeys
} from '../../../../../objects/PortfolioAnalytics/analytics'

export interface PortfolioHeaderProps {
  householdFinId: string
  contact: ContactsInterface
  clientAccounts: ClientAccountInterface
  featureFunc: PortfolioAnalyticsFunc
}

export class PortfolioHeader extends Component<PortfolioHeaderProps> {
  public combinedName = () => {
    const { contact } = this.props
    const primary = 'primary' in contact && contact.primary
    const secondary = 'secondary' in contact && contact.secondary

    if (!primary) {
      return null
    }

    if (!secondary) {
      return `${primary.firstName} ${primary.lastName}`
    }

    return (
      (secondary.lastName.toLowerCase() === primary.lastName.toLowerCase() &&
        `${primary.firstName} & ${secondary.firstName} ${secondary.lastName}`) ||
      `${primary.firstName} ${primary.lastName} & ${secondary.firstName} ${secondary.lastName}`
    )
  }

  public addAccount = () => {
    const {
      featureFunc: { toggleModal }
    } = this.props
    const stateKey: PortfolioAnalyticsStateKeys = {
      toggleAddAccount: 'toggleAddAccount'
    }
    toggleModal(stateKey)
  }

  public render = () => {
    return <div></div>
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  const { contact, clientAccount = {} } = store
  return {
    householdFinId,
    contact: contact[householdFinId],
    clientAccounts:
      !isObjEmpty(clientAccount) && clientAccount[householdFinId].clientAccounts
  }
}

export default connect(mapStateToProps)(PortfolioHeader)
