import React, { Component } from 'react'
import CombineListRow from './combineListRow'
import { PortfolioBuilderAccountObj } from '../../../../../objects/portfolioBuilder'

interface CombineListProps {
  currentAccounts: any
  accountIdList: string[]
  activeSortToggle: boolean
  activeSortName: string
  selectedAccount: PortfolioBuilderAccountObj
  toggleCheckbox(account: PortfolioBuilderAccountObj): () => void
}

export class CombineList extends Component<CombineListProps> {
  public sortByName = () => {
    const {
      currentAccounts,
      selectedAccount,
      activeSortToggle,
      activeSortName,
      toggleCheckbox,
      accountIdList
    } = this.props
    return Object.keys(currentAccounts)
      .sort((a, b) =>
        activeSortToggle
          ? currentAccounts[a][activeSortName] >
            currentAccounts[b][activeSortName]
            ? 1
            : currentAccounts[b][activeSortName] >
              currentAccounts[a][activeSortName]
            ? -1
            : 0
          : currentAccounts[b][activeSortName] >
            currentAccounts[a][activeSortName]
          ? 1
          : currentAccounts[a][activeSortName] >
            currentAccounts[b][activeSortName]
          ? -1
          : 0
      )
      .map((account, i) => {
        if (account !== selectedAccount.id) {
          return (
            <CombineListRow
              key={i}
              account={currentAccounts[account]}
              toggleCheckbox={toggleCheckbox}
              accountIdList={accountIdList}
              selectedAccountId={selectedAccount.id}
            />
          )
        } else {
          return null
        }
      })
  }

  public renderRow() {
    return this.sortByName()
  }

  public render() {
    return <div className='combine__table-wrapper'>{this.renderRow()}</div>
  }
}

export default CombineList
