export const MEETINGMODE_TRUE: string = 'MEETINGMODE_TRUE'
export const MEETINGMODE_FALSE: string = 'MEETINGMODE_FALSE'

export const meetingModeFalse = (): { type: string } => {
  return {
    type: MEETINGMODE_FALSE
  }
}

export const meetingModeTrue = (
  id: string
): { type: string; payload: string } => {
  return {
    type: MEETINGMODE_TRUE,
    payload: id
  }
}
