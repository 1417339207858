// pre-defined ordered list of strategies to display
export const STRATEGY_LIST = [
  'Strategic Core',
  'Active Managed',
  'Personalized Series',
  'Public Equity',
  'Fixed Income',
  'Cash, Deposits & Money Market Funds',
  'Alternative Investments',
  'Other Investments'
]

export interface StrategiesObj {
  id: string
  name: string
  value: number
  type: string
  url: string
  shortName: string
  bulletPoint: string
  factSheetBullets: []
  disclaimer: string
  attribute:
    | 'Protection'
    | 'Performance'
    | 'Tax Minimization'
    | 'Low Cost Tracking'
    | 'Pre-Blended Mixes'
  yield: number
  duration: number
  expenseRatio: number
  maturity: number
  equity: number
  cash: number
  investmentMinimum: number
  taxStatus: string
  fixed: number
  alt: number
  fees: number // value of "flpFees" or "pfmFees" based on the user's institution
  createdDate: string
  scores: {
    tax: number
    cost: number
    performance: number
    protection: number
    global: number
    domestic: number
    stocks: number
    bonds: number
  }
  maxDefensivePosture: {
    equity: number
    cash: number
    fixed: number
    alt: number
  }
  sectorWeightings: {}
  geographicWeightings: {}
  creditWeightings: {}
  ucfaFactSheetUrl: {}
  finlifeFactSheetUrl: {}
  restrictedFromQualifiedAccounts: boolean
  altWarning: boolean
}

export const accountType = {
  protection: 'Protection',
  performance: 'Performance',
  taxMinimization: 'Tax Minimization',
  lowCostTracking: 'Low Cost Tracking'
}

export interface StrategiesInterface {
  [strategyId: string]: StrategiesObj
}

export interface CustodianObj {
  id: string
  strategyId?: string
  name?: string
  custodian?: string
  breakpoint: number
  crmId?: string
}

export interface CollateralObj {
  id: string
  strategyId?: string
  name: string
  filename: string
  url?: string
  collateralUrl?: string
  rankOrder?: number
}

export interface IMStrategiesObj extends StrategiesObj {
  maxOffensivePosture?: {
    equity: number
    cash: number
    fixed: number
    alt: number
  }
  sfId: string
  investmentMinimum: number
  taxStatus: string | any
  managerFee: number
  operationalFee: number
  collateral: CollateralObj[]
  custodians: CustodianObj[]
  assetClassL1: string
  assetClassL2: string
  strategyHighlight: string
  managedStrategies: string
  managementStyle: string
  manager: string
  pfmStrategy: boolean
  flStrategy: boolean
  tdAmeritrade: boolean
  fidelity: boolean
  charlesSchwab: boolean
  custodianSelection?: string[]
}

export interface IMClassifiedStrategiesInterface {
  [assetClassL1: string]: IMStrategiesObj[]
}

export interface IMClassificationTypeInterface {
  [subClass: string]: IMStrategiesObj[]
}

export const custodianKeyMap = {
  'Charles Schwab & Co.': 'charlesSchwab',
  'Fidelity IWS': 'fidelity',
  'TD Ameritrade': 'tdAmeritrade'
}
