import * as userActions from '../../actions/user'
import * as householdActions from '../../actions/households'
import { getContactsLastLogin } from '../../actions/contacts'
import * as syncDisplayActions from '../../actions/syncDisplay'
import { getClientAccounts } from '../../actions/clientAccounts'

import { Dispatch } from 'react-redux'
import { GlobalState } from '../../reducers'

const syncHelper = (dispatch: Dispatch<GlobalState>) => {
  const getUserHouseholds = async (householdFinId?: string) => {
    dispatch(syncDisplayActions.showSyncModal())
    await dispatch(userActions.getUser())
    await dispatch(householdActions.getHouseholds())
    await dispatch(getContactsLastLogin())

    if (householdFinId) {
      await dispatch(householdActions.getIndividualHousehold(householdFinId))
      await dispatch(getClientAccounts(householdFinId))
    }

    dispatch(syncDisplayActions.hideSyncModal())
  }
  const refresh = (notificationItem: {
    subject: string
    content: { status: string }
  }): void => {
    if (notificationItem && !notificationItem.content) {
      switch (notificationItem.subject) {
        case 'SYNC':
          getUserHouseholds()
          break
        default:
      }
    }
  }

  return { refresh, getUserHouseholds }
}

export default syncHelper
