import React, { Component } from 'react'

import CommitmentIcon from '../../assets/images/icons/png/hc-commitment-white.png'
import ProtectionIcon from '../../assets/images/icons/png/hc-protection-white.png'
import HappinessIcon from '../../assets/images/icons/png/hc-happiness-white.png'
import { CardObj } from '../../../objects/HonestConversations/card'

interface HonestConverstionCardProps {
  card: CardObj
}

class HonestConversationCard extends Component<HonestConverstionCardProps> {
  public cardAttributes = () => {
    const category = (this.props.card && this.props.card.category) || null
    switch (category) {
      case 'Commitment':
        return {
          icon: CommitmentIcon,
          color: '#0068AE'
        }
      case 'Happiness':
        return {
          icon: HappinessIcon,
          color: '#F49500'
        }
      case 'Protection':
        return {
          icon: ProtectionIcon,
          color: '#A7D500'
        }
      default:
        return {
          icon: null,
          color: '#F4F4F4'
        }
    }
  }

  public render() {
    const { card } = this.props
    const title = card.title
    const { icon, color } = this.cardAttributes()
    return (
      <div className={'hc__card'} style={{ backgroundColor: color }}>
        <div className='hc__card-title'>
          {title}
          {icon ? (
            <span className='hc__card-icon'>
              <img src={icon} alt='' role='presentation' />
            </span>
          ) : null}
        </div>
      </div>
    )
  }
}

export default HonestConversationCard
