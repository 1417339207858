import React, { Component } from 'react'
import Display from './display'

interface ModalProps {
  size?: 'S' | 'M' | 'L' | 'XL'
}

export class PositionsModal extends Component<ModalProps> {
  public render = () => {
    const { children, size } = this.props
    return <Display size={size}>{children}</Display>
  }
}

export default PositionsModal
