import React, { Component, Fragment } from 'react'
import Button from '../button'
import Tooltip from '../tooltip'
import { ClipLoader } from 'react-spinners'

interface MimeoButtonsProps {
  error?: any
  createOrder?(): void
  missingFields?(): void
  prevPage?(): void
  nextPage?(): void
  submitOrder?(): void
  closeModal?(): void
}

interface MimeoButtonState {
  isBusy: boolean
}

class MimeoButtons extends Component<MimeoButtonsProps, MimeoButtonState> {
  constructor(props: any) {
    super(props)
    this.state = {
      isBusy: false
    }
  }

  public render() {
    const {
      nextPage,
      prevPage,
      submitOrder,
      error,
      createOrder,
      closeModal
    } = this.props
    const { isBusy } = this.state

    const onclickFn = async () => {
      if (nextPage && createOrder) {
        this.setState({
          isBusy: true
        })
        await createOrder()
        this.setState({
          isBusy: false
        })
        nextPage()
      } else if (nextPage && !createOrder) {
        nextPage()
      } else {
        this.setState({
          isBusy: true
        })
        await submitOrder()
        this.setState({
          isBusy: false
        })
      }
    }
    const loadingSubmitOrder = (
      <Fragment>
        <ClipLoader sizeUnit={'px'} size={20} color={'#fff'} loading={true} />
        <span style={{ marginRight: '10px' }} />
        Loading
      </Fragment>
    )
    return (
      <div className='mimeo__btn-w'>
        {prevPage ? (
          <Button onClick={prevPage} clear>
            Back
          </Button>
        ) : null}
        {!closeModal ? (
          <Tooltip
            message='Upload a PDF to service may take a few minutes.'
            width={160}
            position='top-left'
            hideTooltip={!nextPage}
            multiLine>
            <Button
              onClick={
                (!submitOrder && !isBusy && !error.missingField) ||
                (submitOrder && !isBusy && error.shippingSelected)
                  ? onclickFn
                  : null
              }
              primary={
                (!submitOrder && !isBusy && !error.missingField) ||
                (submitOrder && error.shippingSelected)
              }>
              {isBusy ? loadingSubmitOrder : nextPage ? 'Next' : 'Submit Order'}
            </Button>
          </Tooltip>
        ) : (
          <Button primary onClick={closeModal}>
            Finish
          </Button>
        )}
      </div>
    )
  }
}

export default MimeoButtons
