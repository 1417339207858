import React from 'react'
import Tile from '../../components/layout/tile'
import { ReactComponent as ChartIcon } from '../../assets/images/icons/ic_chart.svg'
import { InvestmentViewfinderAllocationObj } from '../../../objects/investmentViewfinder'
import AllocationGraph from './allocationGraph'

interface AllocationResultsProps {
  allocation: InvestmentViewfinderAllocationObj
}

class AllocationResults extends React.Component<AllocationResultsProps> {
  public render() {
    const { allocation } = this.props
    return (
      <Tile
        icon={ChartIcon}
        leftHeader='YOUR ALLOCATIONS'
        headerBorder={true}
        headerStyle={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: '#FAFAFA',
          justifyContent: 'left'
        }}
        style={{ marginLeft: 8 }}>
        <div className='results__allocation-w'>
          <div className='results__allocation-title'>Preference / Target</div>
          <AllocationGraph
            stocks={allocation.stocks}
            bonds={allocation.bonds}
          />
          <div className='results__allocation-legend-w'>
            <div className='results__allocation-legend'>
              <div className='results__allocation-legend-circle results__allocation-legend-circle--stocks' />
              Stocks
            </div>
            <div className='results__allocation-legend'>
              <div className='results__allocation-legend-circle results__allocation-legend-circle--bonds' />
              Bonds
            </div>
          </div>
        </div>
      </Tile>
    )
  }
}

export default AllocationResults
