import * as positionActions from '../actions/positions'
import { PositionsResponseObj } from '../objects/positions'
export interface PositionsState {
  toast: any[]
}

const initState: PositionsState = {
  toast: []
}

const errorMessageParser = (
  requestData: any,
  requestFields: string[],
  errors: any[]
): any[] => {
  const errorMessageArray: any[] = []
  errors.forEach((error: any) => {
    const {
      status,
      type,
      detail,
      source: { fragment },
      message
    } = error
    const coord = fragment.replace('#cell=', '').split(',')
    const cell =
      requestData[
        coord[0] - 2 < requestData.length
          ? coord[0] - 2
          : requestData.length - (coord[0] - requestData.length)
      ]
    cell &&
      errorMessageArray.push({
        status,
        type,
        message,
        detail: `Error for Position: ${
          cell.Symbol ? cell.Symbol : `Line ${coord[0]}`
        }, ${detail}`
      })
  })
  return errorMessageArray
}

const errorParser = (toast: any[], { data: { meta, errors } }: any) => {
  const newToast = toast
  const defaultError: PositionsResponseObj = {
    status: meta.status,
    type: ['/errors/unparseable-request-body'],
    message: 'Request body was not parseable as the provided Content-Type.',
    detail: 'Expected the request to be parseable as text/csv.',
    errorItems: null
  }
  const partialErrorsMsg = () => {
    const errorMsgs: string[] = []
    if (errors?.length) {
      const { requestDataJson } = meta
      if (requestDataJson) {
        const {
          data: requestData = null,
          headers: requestFields = null
        } = requestDataJson
        errorMessageParser(requestData, requestFields, errors).forEach(
          (errorItem: any) => {
            errorMsgs.push(`${errorItem.detail}`)
          }
        )
      }
    }

    return errorMsgs
  }

  const partialErrors = partialErrorsMsg()
  if (partialErrors.length) {
    newToast.push({ ...defaultError, errorItems: partialErrors })
  }

  if (errors.length) {
    newToast.push(...errors)
  }

  return [...newToast]
}

const successParser = (toast: any, payload: any) => {
  const newToast = toast
  const { data, errors = null, meta } = payload
  const lineItemCount = meta?.requestDataJson?.data?.length || data.length

  const partialErrorsMsg = () => {
    const errorMsgs: string[] = []
    if (errors?.length) {
      const {
        requestDataJson: {
          data: requestData = null,
          headers: requestFields = null
        } = null
      } = meta || null
      errorMessageParser(requestData, requestFields, errors).forEach(
        (errorItem: any) => {
          errorMsgs.push(`${errorItem.detail}`)
        }
      )
    }
    return errorMsgs
  }

  newToast.push({
    status: 200,
    message: 'Your file has been uploaded!',
    detail: `${data.length} of ${lineItemCount} positions successfully imported.`,
    errorItems: partialErrorsMsg()
  })

  return [...newToast]
}

const Positions = (state = initState, action: any): PositionsState => {
  const toast = state.toast.slice(0)
  switch (action.type) {
    case `${positionActions.UPLOAD_POSITION}_FULFILLED`:
      return { ...state, toast: successParser(toast, action.payload) }
    case `${positionActions.UPLOAD_POSITION}_REJECTED`:
      return { ...state, toast: errorParser(toast, action.payload.response) }
    case `${positionActions.ADD_IM_POSITIONS_ERROR_TOAST}`:
      return { ...state, toast: errorParser(toast, action.payload.response) }
    case `${positionActions.REMOVE_IM_TOAST}`:
      toast.splice(action.payload, 1)
      return { ...state, toast }
    default:
      return state
  }
}

export default Positions
