import React, { Component, CSSProperties, Fragment, ReactNode } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import SharedPriorityComments from './sharedPriorityComments'
import PriorityGraph from './display/priorityGraph'
import { ContactsInterface } from '../../../objects/contact'
import {
  HonestConversationsExerciseObj,
  HouseholdCardObj,
  CardScoreObj
} from '../../../objects/honestConversations'
import { getIndividualScoreDataSet } from '../../components/graphs/honestConversations/scoreHistoryData'
import { getGraphOptions } from '../../components/graphs/honestConversations/scoreHistoryOptions'
import { ScoreGraphLegend } from '../../components/graphs/honestConversations/scoreGraphLegend'
export interface SharedPriorityGraphsProps {
  contacts: ContactsInterface
  honestConversation: HonestConversationsExerciseObj
  selectedCard: HouseholdCardObj
}
interface SharedPriorityGraphsState {
  graphData: {}
  graphOptions: {}
}
const colStyle: CSSProperties = {
  padding: '5px 10px 5px 22px'
}
const nameLabelStyle: CSSProperties = {
  display: 'flex',
  width: 'auto',
  flexGrow: 1,
  justifyContent: 'flex-end'
}
const commentWrapperStyle: CSSProperties = {
  width: '50%'
}
export class SharedPriorityGraphs extends Component<
  SharedPriorityGraphsProps,
  SharedPriorityGraphsState
> {
  constructor(props: SharedPriorityGraphsProps) {
    super(props)
    this.state = {
      graphData: null,
      graphOptions: null
    }
  }
  public componentDidMount() {
    const { honestConversation, contacts, selectedCard } = this.props
    this.setState({
      graphData: getIndividualScoreDataSet(
        honestConversation,
        selectedCard,
        contacts
      ),
      graphOptions: getGraphOptions()
    })
  }
  public getClientScoringSession = (
    contactId: string,
    scoringSessionId: string
  ) => {
    const { honestConversation } = this.props
    if (
      contactId &&
      scoringSessionId &&
      honestConversation.clientScoringSessions[contactId] &&
      Object.keys(honestConversation.clientScoringSessions).length > 0
    ) {
      return honestConversation.clientScoringSessions[contactId][
        scoringSessionId
      ]
    } else {
      return null
    }
  }
  public householdContactLabel = (contactId: string): ReactNode => {
    if (!contactId) {
      return null
    }
    const { contacts } = this.props
    const firstName =
      contacts.secondary?.id === contactId
        ? contacts.secondary.firstName
        : contacts.primary?.firstName
    let secondaryStyle = !(
      contacts.secondary && contacts.primary?.id === contactId
    )
    if (contacts.secondary?.id === contactId) {
      secondaryStyle = true
    }
    return (
      <ScoreGraphLegend firstName={firstName} secondaryStyle={secondaryStyle} />
    )
  }
  public renderGraphKey = () => {
    const { contacts } = this.props
    if (contacts?.secondary?.id) {
      return (
        <div className='hc-components__col hc-components__col-end'>
          <div style={nameLabelStyle}>
            {this.householdContactLabel(contacts?.secondary?.id)}
            {this.householdContactLabel(contacts?.primary?.id)}
          </div>
        </div>
      )
    }
    return null
  }
  public render() {
    const { contacts, selectedCard, honestConversation } = this.props
    // null state
    if (!contacts) {
      return null
    }
    const graphDataSet = getIndividualScoreDataSet(
      honestConversation,
      selectedCard,
      contacts
    )
    const graphDataOptions = getGraphOptions()
    let secondaryScoringSession = null
    if (contacts.secondary && honestConversation) {
      secondaryScoringSession = this.getClientScoringSession(
        contacts.secondary.id,
        honestConversation.activeScoringSessionSecondary
      )
    }
    const secondaryScoringSesssionDate =
      secondaryScoringSession && secondaryScoringSession.createdDate
    const secondaryCardScores =
      secondaryScoringSession && secondaryScoringSession.cardScores
    const primaryScoringSession = this.getClientScoringSession(
      contacts.primary?.id,
      honestConversation.activeScoringSessionPrimary
    )
    const primaryScoringSessionDate =
      primaryScoringSession && primaryScoringSession.createdDate
    const primaryCardScores =
      primaryScoringSession && primaryScoringSession.cardScores
    let primaryCardScore: CardScoreObj
    primaryCardScores &&
      Object.keys(primaryCardScores).map((key) => {
        if (
          primaryCardScores[key].householdCard &&
          primaryCardScores[key].householdCard.id === selectedCard.id
        ) {
          primaryCardScore = primaryCardScores[key]
        }
      })
    let secondaryCardScore: CardScoreObj
    secondaryCardScores &&
      Object.keys(secondaryCardScores).forEach((key) => {
        if (
          secondaryCardScores[key].householdCard &&
          secondaryCardScores[key].householdCard.id === selectedCard.id
        ) {
          secondaryCardScore = secondaryCardScores[key]
        }
      })
    if (!selectedCard) {
      return null
    }
    return (
      <Fragment>
        {selectedCard?.commentPrimary ? (
          <div className='hc-components__combined-comment'>
            {selectedCard?.commentPrimary}
          </div>
        ) : null}
        <div className='hc-components__row'>
          <div className='hc-components__col' style={colStyle}>
            <div
              className='hc-components__row'
              style={{ paddingTop: '25px', paddingBottom: '10px' }}>
              <div className='hc-components__col hc-components__col-start'>
                <div>Score History</div>
              </div>
              {this.renderGraphKey()}
            </div>
            <PriorityGraph
              graphDataSet={graphDataSet}
              graphDataOptions={graphDataOptions}
            />
          </div>
          <div className='hc-components__col' style={commentWrapperStyle}>
            {contacts.secondary && (
              <SharedPriorityComments
                contact={contacts.secondary}
                cardScore={secondaryCardScore}
                createdDate={secondaryScoringSesssionDate}
                isPrimary={false}
              />
            )}
            <SharedPriorityComments
              contact={contacts.primary}
              cardScore={primaryCardScore}
              createdDate={primaryScoringSessionDate}
              isPrimary={true}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (
  store: GlobalState,
  { householdFinId, contacts, honestConversation }: any
) => {
  return {
    householdFinId,
    honestConversations: store.hcExercises[householdFinId],
    honestConversation,
    contacts
  }
}
export default connect(mapStateToProps)(SharedPriorityGraphs)
