import * as actions from '../actions/loader'

export interface LoaderState {
  showLoader: boolean
}

const initState: LoaderState = {
  showLoader: false
}

const Loader = (state = initState, action: any): LoaderState => {
  switch (action.type) {
    case actions.START_LOADER:
      return { ...state, showLoader: true }
    case actions.STOP_LOADER:
      return { ...state, showLoader: false }
    default:
      return state
  }
}

export default Loader
