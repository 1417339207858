import React from 'react'
import Tile from '../../../components/layout/tile'
import ClientAccounts from './clientAccounts'
import TableHeader from '../../../components/layout/tableHeader'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/clientAccounts'
import { isObjEmpty } from '../../../helpers'
import { ClientAccountObjState } from '../../../../objects/clientAccount'
import arrowRight from '../../../assets/images/icons/png/ic_left.png'
import arrowLeft from '../../../assets/images/icons/png/ic_right.png'

interface ClientAccountInformationProps {
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  clientAccounts: ClientAccountObjState
}

interface ClientAccountInformationState {
  currentPage: number
  currentItems: string[]
}

class ClientAccountInformation extends React.Component<
  ClientAccountInformationProps,
  ClientAccountInformationState
> {
  private ITEMS_PER_PAGE = 8
  constructor(props: ClientAccountInformationProps) {
    super(props)
    this.state = {
      currentPage: 1,
      currentItems: []
    }
  }
  public componentDidMount() {
    this.props.dispatch(actions.getClientAccounts(this.props.householdFinId))
  }
  public handlePageArrowRight = () => {
    if (this.state.currentPage < this.renderPageNumbers.length) {
      this.setState({
        currentPage: this.state.currentPage + 1
      })
    }
  }

  public handlePageArrowLeft = () => {
    if (this.state.currentPage >= 2) {
      this.setState({
        currentPage: this.state.currentPage - 1
      })
    }
  }
  public handlePaginationClick = (event: React.FormEvent<HTMLElement>) => {
    this.setState({
      currentPage: Number(event.currentTarget.id)
    })
  }
  public currentItems = () => {
    const { currentPage } = this.state
    const accountKeys = !isObjEmpty(this.props.clientAccounts)
      ? Object.keys(this.props.clientAccounts)
      : []
    const indexOfLastItem = currentPage * this.ITEMS_PER_PAGE
    const indexOfFirstItem = indexOfLastItem - this.ITEMS_PER_PAGE
    return accountKeys.length
      ? accountKeys.slice(indexOfFirstItem, indexOfLastItem)
      : null
  }
  public renderPageNumbers = () => {
    const { currentPage } = this.state
    const pageNumbers = []
    let accounts: any = []
    if (!isObjEmpty(this.props.clientAccounts)) {
      accounts = Object.keys(this.props.clientAccounts)
      for (
        let i = 1;
        i <= Math.ceil(accounts.length / this.ITEMS_PER_PAGE);
        i += 1
      ) {
        pageNumbers.push(i)
      }
    }
    return pageNumbers.map((num: number) => {
      return (
        <li
          style={{
            backgroundColor: currentPage === num ? '#255EBA' : '#FFFFFF',
            color: currentPage === num ? '#FFFFFF' : '#231F20'
          }}
          key={num}
          id={`${num}`}
          onClick={this.handlePaginationClick}>
          {num}
        </li>
      )
    })
  }
  public renderPagination = () => {
    if (
      !isObjEmpty(this.props.clientAccounts) &&
      Object.keys(this.props.clientAccounts).length > 8
    ) {
      return (
        <div className='financial-planning__pagination'>
          <span>
            <img
              src={arrowRight}
              alt='leftArrow'
              onClick={this.handlePageArrowLeft}
            />
          </span>
          {this.renderPageNumbers()}
          <span>
            <img
              src={arrowLeft}
              alt='rightArrow'
              onClick={this.handlePageArrowRight}
            />
          </span>
        </div>
      )
    } else return null
  }

  public render() {
    return (
      <div>
        {/* {this.allocationWarning()} */}
        <div className='financial-planning__tile-w'>
          <Tile
            leftHeader={'CONFIRM ACCOUNT INFORMATION'}
            headerBorder={false}
            rightHeader={''}
            headerStyle={{ backgroundColor: '#FAFAFA' }}>
            <div className='financial-planning__tile-content'>
              <TableHeader>
                <div className='financial-planning__content-header financial-planning__content-header--send'>
                  Send to planning software
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--acnumber'>
                  account number
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--acnickname'>
                  account nickname
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--owner'>
                  owner
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--actype'>
                  account type
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--taxstatus'>
                  tax status
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--regtype'>
                  Registration Type
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--classification'>
                  Classification
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--cost'>
                  cost basis
                </div>
                <div className='financial-planning__content-header financial-planning__content-header--totalvalue'>
                  Manual Total Value
                </div>
              </TableHeader>
              <ClientAccounts
                accounts={this.props.clientAccounts}
                currentItems={this.currentItems()}
              />
            </div>
          </Tile>
        </div>
        {this.renderPagination()}
      </div>
    )
  }
}

export default connect()(ClientAccountInformation)
