import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import Button from '../../components/button'
import Input from '../../components/Input'

interface EditModalProps {
  id: string
  name: string
  type: string
  editFn(id: string, name: string, folderId?: string): void
  closeModal(id: string, name: string): void
}

interface EditModalState {
  name: string
}

class EditModal extends React.Component<EditModalProps, EditModalState> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: props.name
    }
  }

  public editFn = () => {
    const { id, editFn } = this.props
    const { name } = this.state
    editFn(id, name)
  }

  public closeFn = () => {
    this.props.closeModal('', '')
  }

  public editName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ name: event.currentTarget.value })
  }

  public render() {
    const { name } = this.state
    return (
      <Modal
        icon={EditIcon}
        title={this.props.type === 'folder' ? 'RENAME FOLDER' : 'RENAME FILE'}
        closeModal={this.closeFn}>
        <div className='vault-modal__content'>
          <Input
            content={name || ''}
            title={this.props.type === 'folder' ? 'Folder name' : 'File name'}
            inputType='text'
            name='vaultname'
            controlFunc={this.editName}
            placeholder={
              this.props.type === 'folder'
                ? 'Enter a folder name'
                : 'Enter a file name'
            }
          />
          <div className='vault-modal__buttons'>
            <Button clear={true} onClick={this.closeFn}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.editFn}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default EditModal
