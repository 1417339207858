import moment from 'moment'
import {
  ProfileImgClients,
  FinancialGoalActionObj,
  GoalType
} from '../../../objects/financialGoalActions'
import { GoalActionsPreferences } from '../../../objects/householdPreferences'

const formatSortByString = (sortVal: string): string => {
  const caseFormat = sortVal.charAt(0).toLowerCase() + sortVal.slice(1)
  return caseFormat && caseFormat.replace(/\s+/g, '')
}

// Convert number of valid clients to number for comparison
export const contactCount = (client: ProfileImgClients): number => {
  if (
    client?.hasOwnProperty('primary') &&
    client?.hasOwnProperty('secondary')
  ) {
    return 3
  } else if (client?.hasOwnProperty('secondary')) {
    return 2
  } else if (client?.hasOwnProperty('primary')) {
    return 1
  } else {
    return 0
  }
}

// Convert status string into number to sort by specific value order
const statusConversion = (status: string): number => {
  const statusOptions: string[] = [
    'Immediate Action Needed',
    'Improvement Needed',
    'Optimal Position',
    'NA | Pending Review'
  ]
  return statusOptions.indexOf(status)
}

// Convert type into number to sort by specific value order
const typeConversion = (type: GoalType): number => {
  const goalTypes: GoalType[] = ['both', 'financial', 'life', null]
  return goalTypes.indexOf(type)
}

export const formatComparisonValue = (
  pref: string,
  goal: FinancialGoalActionObj
) => {
  return pref === 'owner' || pref === 'client'
    ? contactCount(goal.client)
    : pref === 'status'
    ? statusConversion(goal.status)
    : pref === 'type'
    ? typeConversion(goal.type)
    : goal[pref]
}

export const handleSort = (
  sortBy: string,
  a: FinancialGoalActionObj,
  b: FinancialGoalActionObj
): number => {
  let sortA: any = ''
  let sortB: any = ''
  if (sortBy === 'name') {
    return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
  } else if (sortBy === 'dueDate') {
    // 1. if both dates are valid, sort by chronological order
    if (a.dueDate && b.dueDate) {
      return moment(a.dueDate || 0).diff(moment(b.dueDate || 0))
      // 2. if both dates are null, secondary sort by status
    } else if (!a.dueDate && !b.dueDate) {
      return formatComparisonValue('status', a) >
        formatComparisonValue('status', b)
        ? 1
        : formatComparisonValue('status', a) <
          formatComparisonValue('status', b)
        ? -1
        : 0
      // 3. if a.dueDate has a value and b does not, a has priority, else b has priority
    } else return a.dueDate ? -1 : 1
  } else {
    sortA = formatComparisonValue(sortBy, a)
    sortB = formatComparisonValue(sortBy, b)
    return sortA > sortB ? 1 : sortA < sortB ? -1 : 0
  }
}

export const sortGoals = (
  goals: FinancialGoalActionObj[],
  preferences: GoalActionsPreferences | undefined
): FinancialGoalActionObj[] => {
  if (goals?.length > 1) {
    const prefSortBy = preferences?.goalActionsSortBy
      ? formatSortByString(preferences?.goalActionsSortBy)
      : 'dueDate'
    // Default sortBy order: preference > check-in date > status > name > owner
    return goals.sort(
      (a: FinancialGoalActionObj, b: FinancialGoalActionObj) => {
        if (prefSortBy && handleSort(prefSortBy, a, b)) {
          return handleSort(prefSortBy, a, b)
        }
        if (prefSortBy !== 'type' && handleSort('type', a, b)) {
          return handleSort('type', a, b)
        }
        if (prefSortBy !== 'dueDate' && handleSort('dueDate', a, b)) {
          return handleSort('dueDate', a, b)
        }
        if (prefSortBy !== 'status' && handleSort('status', a, b)) {
          return handleSort('status', a, b)
        }
        if (prefSortBy !== 'name' && handleSort('name', a, b)) {
          return handleSort('name', a, b)
        }
        if (prefSortBy !== 'owner' && handleSort('owner', a, b)) {
          return handleSort('owner', a, b)
        } else return -1
      }
    )
  } else {
    return goals
  }
}
