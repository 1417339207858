import { assetStructureMap } from '../../objects/strategySearch'

export const childrenAllChecked = (
  strategyList: string[],
  strategyName: string
) =>
  assetStructureMap[strategyName]?.every((strategy: string) =>
    strategyList?.includes(strategy)
  )

export const childrenSomeChecked = (
  strategyList: string[],
  strategyName: string
) =>
  assetStructureMap[strategyName]?.some((strategy: string) =>
    strategyList?.includes(strategy)
  )
