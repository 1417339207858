import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import Button from '../../components/button'
import Input from '../../components/Input'

interface FundedScoreModalProps {
  closeModal(): void
  saveModal(score: number): void
}

interface FundedScoreModalState {
  score: number
}

class FundedScoreModal extends React.Component<
  FundedScoreModalProps,
  FundedScoreModalState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      score: null
    }
  }

  public handleInputChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value.trim()
    const score = value || value === '0' ? parseInt(value, 10) : null
    if (!score || !isNaN(score)) {
      this.setState({ score })
    }
  }

  public addFundedScore = () => {
    if (this.state.score || this.state.score === 0) {
      this.props.saveModal(this.state.score)
    }
  }

  public render() {
    const { closeModal } = this.props
    const { score } = this.state
    return (
      <Modal icon={PlusIcon} title='ADD FUNDED SCORE' closeModal={closeModal}>
        <div className='funded-score-modal__body'>
          <Input
            inputType='text'
            name='fundedscore'
            mandatory={true}
            title='Funded Score'
            controlFunc={this.handleInputChange}
            content={score || score === 0 ? score : ''}
          />
        </div>
        <div className='funded-score-modal__btns-w'>
          <Button clear={true} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={!score && score !== 0}
            primary={true}
            onClick={this.addFundedScore}>
            Add
          </Button>
        </div>
      </Modal>
    )
  }
}

export default FundedScoreModal
