import React from 'react'
import InnerContainer from '../../components/layout/innerContainer'
import { ReactComponent as MessagesIcon } from '../../assets/images/icons/messages.svg'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import MessagePane from '../../components/messages/messagePane'
import MessageBody from '../../components/messages/messageBody'
import { GlobalState } from '../../../reducers'
import { Dispatch, connect } from 'react-redux'
import { UserObj, UsersObj } from '../../../objects/user'
import { history } from '../../../store'
import GQLClient from '../../helpers/gqlClient'
import { GetAdvisorMessageThreadQuery } from '../../helpers/queries'
import { MessageThreadInterface } from '../messages/messageThreadList'
import moment from 'moment'
import { ContactState } from '../../../reducers/contacts'
import { withRouter } from 'react-router'

export interface AdvisorMessagesProps {
  userId: string
  threadId?: string
  user: UserObj
  users: UsersObj
  contacts: ContactState
  dispatch: Dispatch<GlobalState>
}

interface AdvisorMessagesState {
  householdFinId: string
  activeThreadId: string
  error: boolean
}

export class AdvisorMessages extends React.Component<
  AdvisorMessagesProps,
  AdvisorMessagesState
> {
  constructor(props: AdvisorMessagesProps) {
    super(props)
    this.state = {
      householdFinId: null,
      activeThreadId: props?.threadId,
      error: false
    }
  }

  public componentDidMount() {
    const { userId } = this.props
    const { activeThreadId } = this.state
    if (!activeThreadId) {
      GQLClient.getClient()
        ?.query({
          query: GetAdvisorMessageThreadQuery,
          variables: { userId }
        })
        .then((res: any) => {
          let flatThreadList: any = []

          // take each household message thread array and merge into a single array
          res?.data?.allAdvisers?.nodes[0]?.householdsByHouseholdToAdviserAdviserAndHousehold?.nodes.forEach(
            (object: any) => {
              flatThreadList.push(object.messagesByHousehold.nodes)
            }
          )

          // flatten and sort threads in descending order
          flatThreadList = flatThreadList
            .flat()
            .sort((a: MessageThreadInterface, b: MessageThreadInterface) => {
              const aDate =
                a.messagesByParent.nodes.length > 0
                  ? a.messagesByParent.nodes[0]?.createdDate
                  : a.createdDate
              const bDate =
                b.messagesByParent.nodes.length > 0
                  ? b.messagesByParent.nodes[0]?.createdDate
                  : b.createdDate
              return moment(bDate).diff(moment(aDate))
            })

          // grab the latest thread and set it for active view if exists
          const latestThread = flatThreadList[0]
          latestThread &&
            this.setMessageThread(latestThread?.id, latestThread?.household)
        })
        .catch((err: any) => {
          this.setState({ error: true })
        })
    }
  }

  public setMessageThread = (threadId: string, householdFinId: string) => {
    this.setState({ activeThreadId: threadId, householdFinId })
    history.push(`/advisor-messages/${threadId}`)
  }

  public render() {
    const { activeThreadId, error, householdFinId } = this.state
    const { dispatch, userId, user, users, contacts } = this.props
    const activeContacts = householdFinId && contacts[householdFinId]
    return (
      <div className='advisor-messages'>
        <InnerContainer>
          <ContentHeader Icon={MessagesIcon} title='Messages' />
          <Tile>
            <div className='messages-w'>
              <MessagePane
                isAdvisorMessages={true}
                selectMessageThread={this.setMessageThread}
                householdFinId={householdFinId}
                userId={userId}
                activeThreadId={activeThreadId}
              />
              <MessageBody
                dispatch={dispatch}
                threadId={activeThreadId}
                householdFinId={householdFinId}
                contacts={activeContacts}
                users={users}
                userId={userId}
                userEmail={user ? user.email : ''}
                error={error}
              />
            </div>
          </Tile>
        </InnerContainer>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    threadId: match.params.threadId,
    userId: store.user.userId,
    user: store.user.userId ? store.user.users[store.user.userId] : null,
    users: store.user.users,
    contacts: store.contact
  }
}

export default withRouter(connect(mapStateToProps)(AdvisorMessages))
