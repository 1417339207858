import React, { FunctionComponent } from 'react'
import { PlanningSoftwareType } from '../../objects/financialGoal'

interface DefinedGraphValues {
  underfunded: number
  ideal: number
  overfunded: number
}
export interface SlimFundedScoreGraphProps {
  rangeLow: number
  rangeHigh: number
  score: number
  planningSoftware: PlanningSoftwareType
  className?: string
}

// const definedGraphs: string[] = ['gdx360', 'moneyguidepro']
const gradientGraphs: string[] = ['emoney']

const definedGraphHelper = (
  planningSoftware: PlanningSoftwareType,
  rangeHigh: number,
  rangeLow: number
): DefinedGraphValues => {
  const minScore = planningSoftware === 'gdx360' || !rangeLow ? 75 : rangeLow
  const maxScore = planningSoftware === 'gdx360' || !rangeHigh ? 90 : rangeHigh

  return {
    underfunded: (minScore - 50) * (100 / 50),
    ideal: (maxScore - minScore) * (100 / 50),
    overfunded: (100 - maxScore) * (100 / 50)
  }
}

const scoreMarker = (rawScore: number, className: string): JSX.Element => {
  const score = rawScore && Math.round(rawScore)
  const markerPosition = score ? (score - 50) * 2 : 50
  const markerStyle =
    score < 50
      ? { left: 0 }
      : markerPosition > 95
      ? { right: `${100 - markerPosition}%` }
      : { left: `${markerPosition}%` }

  const markerClass =
    markerPosition > 95
      ? `${className}__marker--right-line`
      : `${className}__marker--left-line`
  // ternary else condition is unreachable
  return (
    <div className={`${className}__marker ${markerClass}`} style={markerStyle}>
      {score || score === 0 ? score : '?'}
    </div>
  )
}

const definedGraph = (
  rangeLow: number,
  rangeHigh: number,
  score: number,
  planningSoftware: PlanningSoftwareType,
  className: string
): JSX.Element => {
  const { underfunded, ideal, overfunded } = definedGraphHelper(
    planningSoftware,
    rangeHigh,
    rangeLow
  )

  return (
    <div className={`${className}__graph-w`}>
      <div
        className={`${className}__section--underfunded`}
        style={{ width: `${underfunded}%` }}>
        <div className={`${className}__bar-w ${className}__bar-w--underfunded`}>
          <div className={`${className}__bar ${className}__bar--underfunded`} />
        </div>
        <div className={`${className}__label`}>Underfunded</div>
      </div>
      <div
        className={`${className}__section--ideal`}
        style={{ width: `${ideal}%` }}>
        <div className={`${className}__bar-w ${className}__bar-w--ideal`}>
          <div className={`${className}__bar ${className}__bar--ideal`} />
        </div>
        <div className={`${className}__label`}>Ideal</div>
      </div>
      <div
        className={`${className}__section--overfunded`}
        style={{ width: `${overfunded}%` }}>
        <div className={`${className}__bar-w ${className}__bar-w--overfunded`}>
          <div className={`${className}__bar ${className}__bar--overfunded`} />
        </div>
        <div className={`${className}__label`}>Overfunded</div>
      </div>
    </div>
  )
}

const gradientGraph = (className: string): JSX.Element => {
  return (
    <div className={`${className}__graph-w`}>
      <div className={`${className}__section`}>
        <div className={`${className}__bar-w ${className}__bar-w--gradient`}>
          <div className={`${className}__bar ${className}__gradient`} />
        </div>
        <div className={`${className}__label-w`}>
          <div className={`${className}__label`}>Underfunded</div>
          <div className={`${className}__label ${className}__label--ideal`}>
            Ideal
          </div>
          <div
            className={`${className}__label ${className}__label--overfunded`}>
            Overfunded
          </div>
        </div>
      </div>
    </div>
  )
}

const graphType = (
  rangeLow: number,
  rangeHight: number,
  score: number,
  planningSoftware: PlanningSoftwareType,
  className?: string
): JSX.Element => {
  return !planningSoftware || gradientGraphs.indexOf(planningSoftware) >= 0
    ? gradientGraph(className)
    : // TODO: This is a not reachable code as planningSoftware is required and have defined value only
      definedGraph(rangeLow, rangeHight, score, planningSoftware, className)
}

export const SlimFundedScoreGraph: FunctionComponent<SlimFundedScoreGraphProps> = ({
  rangeLow,
  rangeHigh,
  score,
  planningSoftware,
  className
}) => (
  <div className={className ? `${className}__w` : 'slim-funded-score__w'}>
    {scoreMarker(score, className || 'slim-funded-score')}
    {graphType(
      rangeLow,
      rangeHigh,
      score,
      planningSoftware,
      className || 'slim-funded-score'
    )}
  </div>
)
