import React, { Component } from 'react'

class RowNullState extends Component {
  public render() {
    const getRandomInt = (min: number, max: number): number => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
    const opacities = [1, 0.9, 0.5, 0.4, 0.3, 0.159]
    return opacities.map((num: number, index: number) => {
      return (
        <div
          className='goal-tiles__placeholder-w'
          style={{ opacity: num }}
          key={index}>
          <div className='goal-tiles__placeholder-item goal-tiles__placeholder-item-column'>
            <span style={{ width: getRandomInt(60, 200) }} />
            <span />
          </div>
          <div className='goal-tiles__placeholder-item goal-tiles__placeholder-item--row'>
            <div className='goal-tiles__placeholder-period' />
            <div className='goal-tiles__placeholder-amount' />
            <div className='goal-tiles__placeholder-actions' />
          </div>
        </div>
      )
    })
  }
}

export default RowNullState
