import React, { Component } from 'react'
import Button from '../../../components/button'
import TasksIconPng from '../../../assets/images/icons/png/il-tasks.png'
import plusIcon from '../../../assets/images/icons/png/plus-whit.png'
import plusIconBlue from '../../../assets/images/icons/ic_plus.svg'
import { GlobalState } from '../../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { SelectableTile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { updateGlobalNavigation } from '../../../../actions/globalSettings'
import { FinancialGoalActionObj } from '../../../../objects/financialGoalActions'
import {
  HouseholdCardObj,
  HonestConversationsExerciseObj
} from '../../../../objects/honestConversations'

import ToggleSwitch from '../../../components/toggleSwitch'
import {
  setGoalIdActions,
  updateSideBarState
} from '../../../../actions/financialGoalActions'
import {
  filterGoals,
  getTileConfig,
  sortByDefault,
  filterGoalsByDate
} from '../../../helpers/goals'
import goalActionsSelector from '../../../../selectors/v3/goals'

interface SharedPriorityActionsProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  selectedCard: HouseholdCardObj
  selectedCardId: string
  selectedActionCardId: string
  goalId: string
  sideBarState: boolean
  goals: FinancialGoalActionObj[]
  collapsedState: boolean
  actionBtnPosition?: 'top' | 'bottom'
  showNoDataView: boolean
  componentClass: string
  globalNavigationState: boolean
  enableToggleGoal: boolean // This prop is to show the toggle to hide/show the previous goals
}

interface SharedPriorityActionsState {
  globalNavState: boolean
  showPreviousGoals: boolean
}

class SharedPriorityActions extends Component<
  SharedPriorityActionsProps,
  SharedPriorityActionsState
> {
  constructor(props: SharedPriorityActionsProps) {
    super(props)
    this.state = {
      globalNavState: this.props?.globalNavigationState,
      showPreviousGoals: false
    }
  }

  // Setting the default values for some props
  static defaultProps: Pick<
    SharedPriorityActionsProps,
    | 'enableToggleGoal'
    | 'actionBtnPosition'
    | 'showNoDataView'
    | 'componentClass'
    | 'goals'
  > = {
    enableToggleGoal: false,
    actionBtnPosition: 'top',
    showNoDataView: true,
    componentClass: '',
    goals: []
  }

  componentDidMount = () => {
    this.resetSidebar()
  }

  componentDidUpdate = (prevProps: SharedPriorityActionsProps) => {
    const { selectedCardId, sideBarState, globalNavigationState } = this.props
    const { globalNavState } = this.state
    if (globalNavigationState !== globalNavState && !sideBarState) {
      this.setState({
        globalNavState: globalNavigationState
      })
    }
    if (prevProps.selectedCardId !== selectedCardId && sideBarState === true) {
      this.resetSidebar()
    }
  }

  public handleShowHideGoals = () => {
    this.setState({ showPreviousGoals: !this.state.showPreviousGoals })
  }

  public resetSidebar = async () => {
    const { householdFinId, dispatch } = this.props
    const { globalNavState } = this.state
    dispatch(setGoalIdActions({ goalId: null, householdFinId }))
    await dispatch(updateGlobalNavigation(globalNavState))
    householdFinId &&
      dispatch(
        updateSideBarState({ sideBarState: false, householdId: householdFinId })
      )
  }

  public renderNoActions = () => {
    return (
      <div className='shared-priority-actions__no-action-w'>
        <img src={TasksIconPng} alt='task icon' />
        <p className='shared-priority-actions__no-action-text'>No goals yet</p>
        <p className='shared-priority-actions__no-action-text-2'>
          There are no goals connected to this card.
        </p>
      </div>
    )
  }

  public onAddNewGoal = () => {
    const isNewGoalExist = this.props.goals.find((goal) => goal.id === 'NEW')
    const {
      householdFinId: householdId,
      dispatch,
      selectedCard,
      selectedCardId
    } = this.props
    if (!isNewGoalExist && selectedCardId) {
      dispatch({
        type: 'CREATE_GOAL_ACTIONS',
        payload: {
          data: {
            householdId,
            cards: selectedCard?.card ? [selectedCard?.card] : []
          }
        }
      })
      dispatch(updateGlobalNavigation(true))
      dispatch(updateSideBarState({ sideBarState: true, householdId }))
    }
  }

  public onSelectedGoal = (goalId: string) => {
    const { householdFinId, dispatch, selectedCardId } = this.props
    dispatch(setGoalIdActions({ goalId, householdFinId, selectedCardId }))
    dispatch(updateGlobalNavigation(true))
    dispatch(
      updateSideBarState({ sideBarState: true, householdId: householdFinId })
    )
  }

  // This method will return the filtered/non-filtered based on "DATE" data specific to the selectedCard
  public getGoalsData = () => {
    const { showPreviousGoals } = this.state
    const {
      goals,
      selectedCardId,
      enableToggleGoal,
      honestConversation
    } = this.props

    const filteredGoals = sortByDefault(
      getTileConfig(filterGoals(goals, selectedCardId), honestConversation)
    )
    const defaultData = {
      filteredGoals,
      hasPreviousGoals: Boolean(filteredGoals?.length)
    }

    // Check if toggle is enabled to hide the previous goals
    if (enableToggleGoal) {
      // return the default data for the selected card
      if (showPreviousGoals) return defaultData

      // return the goals of today only
      return filterGoalsByDate(filteredGoals, 'TODAY')
    } else {
      // return the default data for the selected card
      return defaultData
    }
  }

  public render() {
    const {
      goals,
      goalId,
      sideBarState,
      selectedCardId,
      selectedActionCardId,
      actionBtnPosition,
      showNoDataView,
      componentClass,
      enableToggleGoal
    } = this.props
    const { showPreviousGoals } = this.state

    // get the goals data to show in the tiles component and to hide the toggle if no previous goals exist

    const { filteredGoals, hasPreviousGoals } = this.getGoalsData()
    const activeTileId = selectedCardId === selectedActionCardId ? goalId : ''
    return (
      <>
        <div className={`shared-priority-actions${componentClass}`}>
          {/* 
          ***********  START of GOALS HEADER with TOGGLE   ************************
          This feature is aligning with the Add Button if position is "TOP" */}
          <div className='shared-priority-actions__goals shared-priority-actions__goals-mb20'>
            {enableToggleGoal ? (
              <>
                <div className='shared-priority-actions__goals-title'>
                  Goals
                </div>
                {/* START OF SHOW PREVIOUS TOGGLE */}
                {hasPreviousGoals ? (
                  <div className='shared-priority-actions__goals'>
                    <span className='shared-priority-actions__goals-toggle-title shared-priority-actions__goals--mr12'>
                      Show previous
                    </span>
                    {
                      <ToggleSwitch
                        active={showPreviousGoals}
                        handleToggleActive={this.handleShowHideGoals}
                      />
                    }
                  </div>
                ) : null}
                {/* END OF SHOW PREVIOUS TOGGLE */}
              </>
            ) : null}
            {/* START of ADD GOAL BUTTON | POSITION TOP */}
            {actionBtnPosition === 'top' && (
              <div className='shared-priority-actions__add-action-button-w'>
                <Button
                  primary={true}
                  onClick={this.onAddNewGoal}
                  style={{ padding: '6px', paddingRight: '10px' }}>
                  <img
                    className='shared-priority-actions__add-action-plus-icon'
                    src={plusIcon}
                    alt='plus icon'
                  />
                  Add Goal
                </Button>
              </div>
            )}
            {/* END of ADD GOAL BUTTON */}
          </div>

          {/* // **************  END of GOALS HEADER with TOGGLE   *********************  */}
          <div className='shared-priority-actions__action-w'>
            {goals?.length && filteredGoals?.length ? (
              <SelectableTile
                activeTileId={activeTileId}
                data={filteredGoals}
                resetSelection={sideBarState}
                onSelected={this.onSelectedGoal}
              />
            ) : (
              showNoDataView && this.renderNoActions()
            )}
          </div>
          {actionBtnPosition === 'bottom' && (
            <div
              className='record-form__add-action-button-w'
              onClick={this.onAddNewGoal}>
              <img
                className='record-form__plus-icon'
                src={plusIconBlue}
                alt='plus-icon'
              />
              <p className='record-form__add-action-button-text'>Add Goal</p>
            </div>
          )}
        </div>
      </>
    )
  }

  public componentWillUnmount() {
    this.resetSidebar()
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const { householdFinId, meetingId } = match?.params
  const goalActions = store.goalActions[householdFinId]
  const honestConversations = store.hcExercises[householdFinId]
  const honestConversation = honestConversations
    ? honestConversations[meetingId]
    : {}
  return {
    householdFinId,
    honestConversation,
    goals: goalActionsSelector(store, householdFinId),
    goalId: (goalActions && goalActions.goalRequestId) || null,
    collapsedState: store.globalSettings.globalNavigation.collapsed,
    sideBarState: (goalActions && goalActions.sideBarState) || false,
    selectedActionCardId: (goalActions && goalActions.selectedCardId) || '',
    globalNavigationState: store?.globalSettings?.globalNavigation?.collapsed
  }
}

export default withRouter(connect(mapStateToProps)(SharedPriorityActions))
