import * as actions from '../actions/goalRequests'
import { GoalRequestObjState, GoalRequestObj } from '../objects/goalRequests'

export interface GoalRequestObjInterface {
  [key: string]: {
    requests: GoalRequestObjState
  }
}

const initState: GoalRequestObjInterface = {}

const GoalRequests = (
  state = initState,
  action: any
): GoalRequestObjInterface => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_GOAL_REQUESTS}_FULFILLED`:
      newState[action.payload.data.householdFinId] = {
        requests: mapGoalRequests(action.payload.data)
      }
      return newState
    case `${actions.UPDATE_GOAL_REQUEST}_FULFILLED`:
      const newGoalRequests =
        newState[action.payload.data.householdFinId].requests
      delete newGoalRequests[action.payload.data.id]
      newState[action.payload.data.householdFinId] = {
        requests: newGoalRequests
      }
      return newState
    default:
      return state
  }
}

const mapGoalRequests = (goalRequests: GoalRequestObj[]) => {
  const requestObj = {}
  goalRequests.forEach((goalRequest: GoalRequestObj) => {
    requestObj[goalRequest.id] = mapGoalRequest(goalRequest)
  })
  return requestObj
}

const mapGoalRequest = (goalRequest: GoalRequestObj) => {
  return {
    id: goalRequest.id,
    existingId: goalRequest.existingId,
    reviewedBy: goalRequest.reviewedBy,
    comment: goalRequest.comment,
    requestType: goalRequest.requestType,
    status: goalRequest.status,
    lastModifiedDate: goalRequest.lastModifiedDate,
    proposedName: goalRequest.proposedName,
    proposedGoalType: goalRequest.proposedGoalType,
    proposedAmount: goalRequest.proposedAmount,
    proposedAmountType: goalRequest.proposedAmountType,
    proposedAnnualIncrease: goalRequest.proposedAnnualIncrease,
    proposedGoalOwnerId: goalRequest.proposedGoalOwnerId,
    proposedStartOfGoal: goalRequest.proposedStartOfGoal,
    proposedEndOfGoal: goalRequest.proposedEndOfGoal
  }
}

export default GoalRequests
