import moment from 'moment'
import { ClientAccountObjState } from '../../objects/clientAccount'
import qs from 'query-string'
import CommitmentIcon from '../assets/images/icons/png/hc-commitment-white.png'
import HappinessIcon from '../assets/images/icons/png/hc-happiness-white.png'
import ProtectionIcon from '../assets/images/icons/png/hc-protection-white.png' // Investments Login
import ameritrade from '../assets/images/logos/partnerLogos/ameritrade.png'
import blackDiamond from '../assets/images/logos/partnerLogos/black-diamond.png'
import charlesSchwab from '../assets/images/logos/partnerLogos/charles-schwab.png'
import emoney from '../assets/images/logos/partnerLogos/emoney.png'
import folioClient from '../assets/images/logos/partnerLogos/folio-client.png'
// import envestnetSso from '../assets/images/logos/partnerLogos/envestnet-sso.png'
import envestnetTamarac from '../assets/images/logos/partnerLogos/envestnet-tamarac.png'
import envestnet from '../assets/images/logos/partnerLogos/envestnet.png'
import fidelity from '../assets/images/logos/partnerLogos/fidelity.png'
import moneyguidepro from '../assets/images/logos/partnerLogos/moneyguidepro.png'
import orion from '../assets/images/logos/partnerLogos/orion.png'
import pershing from '../assets/images/logos/partnerLogos/pershing.png'
import { store } from '../../store'
import { revokeAccessToken } from '../../actions/user'
import { CardType } from '@unitedcapitalfinancialadvisors/finlife-component-library'

export const getCookieValue = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  return match ? match[2] : null
}

// Format number input into string output with dollar sign: (1000 --> '$1,000')
export const dollarFormat = (
  value: number,
  precision: number = 2,
  parentheses?: boolean
): string => {
  if (
    (value && typeof value === 'number') ||
    (value === 0 && typeof value === 'number')
  ) {
    const sign = value < 0 ? '-' : ''
    const dollars = Math.abs(value)
      .toFixed(precision)
      .replace(/(\B)(?=(\d{3})+(?!\d))/g, ',')
    let dollarString = sign + '$' + dollars
    if (parentheses) {
      dollarString = `($${dollars})`
    }
    return dollarString
  } else {
    return null
  }
}

// Format string of a number input into currency formatted string output: ('10000' --> '10,000')
export const formatCurrencyInput = (input: string) => {
  const formatInputString = (n: string) =>
    n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (input === '' || typeof input !== 'string') return ''
  if (input && input.indexOf('.') >= 0) {
    const decimalPos = input.indexOf('.')
    const leftSide = formatInputString(input.substring(0, decimalPos))
    const rightSide = formatInputString(input.substring(decimalPos)).substring(
      0,
      2
    )
    return `${leftSide || 0}.${rightSide}`
  } else return formatInputString(input || '')
}

// Format number input into string output: (1000 --> '1,000')
export const numberFormat = (value: number, precision: number = 2): string => {
  if (
    (value && typeof value === 'number') ||
    (value === 0 && typeof value === 'number')
  ) {
    const sign = value < 0 ? '-' : ''
    return (
      sign +
      Math.abs(value)
        .toFixed(precision)
        .replace(/(\B)(?=(\d{3})+(?!\d))/g, ',')
    )
  } else return null
}

// Removes special characters and white space from string and returns number: ('$1,000' --> 1000)
export const sanitizeCurrency = (input: string) => {
  if (input && typeof input === 'string') {
    const sanitize = parseFloat(input.replace(/[^0-9-.]/g, ''))
    if (sanitize) {
      return Math.round(sanitize)
    } else return 0
  } else return null
}

export const formatPhoneNumber = (phoneNum: string | number) => {
  if (!phoneNum) {
    return null
  }
  if (typeof phoneNum === 'number') {
    phoneNum = phoneNum.toString()
  }

  //split between phone number and opt extension.  Keep only digits from each
  const [phone, ext] = phoneNum
    .split(/ext/g)
    .map((str) => str.replace(/\D/g, ''))

  // we've only been asked to format if 7, 10, 11, 12+
  if (phone.length < 9 && phone.length !== 7) {
    return phoneNum
  }

  const phoneNumbers = phone.split('')
  const takeRight = (c: number) =>
    phoneNumbers.splice(Math.max(phoneNumbers.length - c, 0)).join('')
  const [line, exchange, areaCode, intCode] = [
    4,
    3,
    3,
    phoneNumbers.length
  ].map(takeRight)

  // +111 (222) 333-4444 ext. 123
  return `${intCode.length ? `+${intCode} ` : ''}${
    areaCode.length ? `(${areaCode}) ` : ''
  }${exchange.length ? `${exchange}-` : ''}${line}${
    ext && ext.length ? ` ext. ${ext}` : ''
  }`
}

export const defaultTodayDate = () => {
  const todayDate = new Date()
  return `${todayDate.getMonth() + 1},${
    todayDate.getDate() < 10 ? '0' + todayDate.getDate() : todayDate.getDate()
  },${todayDate.getFullYear()}`.split(',')
}

export const dateFormat = (date: string | Date) => {
  if (date) {
    return moment(date).format('M/DD/YY')
  } else return null
}

export const simpleDateFormat = (date: string) => {
  if (date && moment(date).isValid()) {
    return moment(date)
      .utc()
      .format('M/DD/YY')
  } else return null
}

export const timeFormat = (date: string) => {
  return moment(date).format('h:mm A')
}

export const timeElapsed = (mils: number, limit: number): boolean => {
  const nowTime = new Date()
  const requestTime = new Date(mils)
  requestTime.setSeconds(requestTime.getSeconds() + limit)

  return moment(nowTime).isAfter(moment(requestTime))
}

export const getInvestmentFromClientAccounts = (
  clientAccounts: ClientAccountObjState
) => {
  const filteredAccounts = {}
  Object.keys(clientAccounts)
    .filter((key: string) => {
      const clientAccount = clientAccounts[key]
      return clientAccount.investment === true
    })
    .forEach((key: string) => {
      filteredAccounts[key] = clientAccounts[key]
    })
  return filteredAccounts
}

export const getNetWorthFromClientAccounts = (
  clientAccounts: ClientAccountObjState
): { netWorth: number; netAssets: number; netLiabilities: number } => {
  const hasClientAccounts =
    clientAccounts && Object.keys(clientAccounts).length > 0
  if (hasClientAccounts) {
    const assets = Object.keys(clientAccounts).filter((key) => {
      const clientAccount = clientAccounts[key]
      return clientAccount.type === 'Assets' && clientAccount.includeInGuidebook
    })
    const liabilities = Object.keys(clientAccounts).filter((key) => {
      const clientAccount = clientAccounts[key]
      return (
        clientAccount.type === 'Liabilities' && clientAccount.includeInGuidebook
      )
    })
    const netAssets =
      assets &&
      assets.reduce((pre: number, nextKey: string): number => {
        return pre + clientAccounts[nextKey].totalValue
      }, 0)
    const netLiabilities =
      liabilities &&
      liabilities.reduce((pre: number, nextKey: string): number => {
        return pre + clientAccounts[nextKey].totalValue
      }, 0)
    const netWorth = netAssets - netLiabilities
    return { netWorth, netAssets, netLiabilities }
  } else {
    return { netWorth: null, netAssets: null, netLiabilities: null }
  }
}

export const getLoginURL = (): string => {
  const url: string = `${window.location.protocol}//${window.location.host}`
  const authQS = qs.stringify({
    response_type: 'code',
    client_id: window._env_.REACT_APP_CLIENT_KEY,
    redirect_uri: url + '/auth/callback',
    expid: 'AC'
  })
  return `${window._env_.REACT_APP_OAUTH_URL_BASE}/services/oauth2/authorize/expid_AC?${authQS}`
}

export const revokeAndLogout = async () => {
  // We await this action because we don't want
  // the connection interrupted before the token can be revoked
  await store.dispatch(revokeAccessToken())
  logout()
}

export const logout = async () => {
  const loginURL = getLoginURL()
  const logoutUrl = `${
    window._env_.REACT_APP_OAUTH_URL_BASE
  }/secur/logout.jsp?startURL=${encodeURIComponent(loginURL)}`
  window.location.href = logoutUrl
}

export const getIdealLifeIndexColor = (
  idealLifeIndex: number,
  benchmark: number
) => {
  const scoringColors = {
    green: '#A7D500',
    orange: '#F7CB02',
    red: '#B21E28'
  }
  return idealLifeIndex >= benchmark
    ? scoringColors.green
    : idealLifeIndex >= benchmark / 2
    ? scoringColors.orange
    : scoringColors.red
}

export const getCardIcon = (cardType: CardType | string) => {
  if (cardType) {
    switch (cardType) {
      case 'Protection':
        return ProtectionIcon
      case 'Commitment':
        return CommitmentIcon
      case 'Happiness':
        return HappinessIcon
      default:
        return HappinessIcon
    }
  } else {
    return ''
  }
}

// Investments Partner
export const ONLINE_PORTAL_LIST = [
  'Black Diamond',
  'Black Diamond - Advus',
  'Emoney',
  //  'Envestnet SSO',
  'Fidelity',
  'Folio Client',
  'Money Guide Pro',
  'Pershing',
  'Envestnet',
  'Schwab',
  'TD Ameritrade',
  'Tamarac',
  'Tamarac - SWM',
  'Tamarac - DiNuzzo',
  'Orion'
]
const onlinePortalItem = (onlinePortal: string) => {
  switch (onlinePortal) {
    case 'Emoney':
      return {
        img: emoney,
        name: onlinePortal
      }
    case 'Money Guide Pro':
      return {
        img: moneyguidepro,
        name: onlinePortal
      }
    case 'Fidelity':
      return {
        img: fidelity,
        name: onlinePortal
      }
    case 'Folio Client':
      return {
        img: folioClient,
        name: onlinePortal
      }
    case 'Pershing':
      return {
        img: pershing,
        name: onlinePortal
      }
    case 'Envestnet':
      return {
        img: envestnet,
        name: onlinePortal
      }
    case 'Schwab':
      return {
        img: charlesSchwab,
        name: onlinePortal
      }
    case 'TD Ameritrade':
      return {
        img: ameritrade,
        name: onlinePortal
      }
    case 'Black Diamond':
      return {
        img: blackDiamond,
        name: onlinePortal
      }
    case 'Black Diamond - Advus':
      return {
        img: blackDiamond,
        name: onlinePortal
      }
    case 'Tamarac':
      return {
        img: envestnetTamarac,
        name: onlinePortal
      }
    case 'Tamarac - SWM':
      return {
        img: envestnetTamarac,
        name: onlinePortal
      }
    case 'Tamarac - DiNuzzo':
      return {
        img: envestnetTamarac,
        name: onlinePortal
      }
    case 'Orion':
      return {
        img: orion,
        name: onlinePortal
      }
    // case 'envestnet sso':
    //   return {
    //     img: envestnetSso,
    //     name: onlinePortal
    //   }
    default:
      return null
  }
}
export const onlinePortalObj = (onlinePortals: string[]) => {
  const onlinePortalList: any = []
  if (onlinePortals !== null) {
    onlinePortals.map((onlinePortal) => {
      onlinePortalList.push(onlinePortalItem(onlinePortal))
    })
  }
  return onlinePortalList
}
export const isValidEmail = (email: string) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(email)
}

export const isObjEmpty = (obj: object) => !obj || Object.keys(obj).length < 1

export const isStringEmpty = (stringValue: string | null) => {
  return !stringValue || stringValue.replace(/\s/g, '').length === 0
}

export interface SelectInterface<T> {
  value: T
  label: string
}

export const getSelectOptionByValue = <T>(
  options: SelectInterface<T>[],
  value: T
): SelectInterface<T> => {
  let selectedOption
  options.forEach((option) => {
    if (option.value === value) {
      selectedOption = option
    }
  })
  return selectedOption
}

export const formatDate = (dateString: string): string => {
  return moment(dateString).isValid()
    ? moment(dateString).format('M/D/YY')
    : dateString
}

export const formatGoalAmount = (amount: number, amountType: string) => {
  if (amountType && amountType.toLowerCase() === 'dollar' && amount) {
    return dollarFormat(amount, 0)
  } else if (amountType && amountType.toLowerCase() === 'percent' && amount) {
    return amount + '%'
  } else return amount
}

// TODO: BUG: percentageInput value is not used in next line to check charAt condition
export const valdatedMaxFloat = (
  inputValue: string,
  max: string | number
): { validated: boolean; value: string | number } => {
  const maxExpression = '(^' + max + '([.]0{1,2})?)$|(^\\d{1,2}([.]\\d{1,2})?)$'
  const aumFloat = new RegExp(maxExpression)
  let percentageInput = inputValue.replace(/[^0-9.]/g, '')
  percentageInput = inputValue.charAt(0) === '.' ? '0' + inputValue : inputValue
  const validate = (): boolean => {
    return (
      aumFloat.test(percentageInput) ||
      percentageInput.length === 0 ||
      (inputValue.charAt(inputValue.length - 1) === '.' &&
        (inputValue.match(/\./g) || []).length <= 1)
    )
  }

  return { validated: validate(), value: percentageInput }
}

export const isEqual = (value: any, other: any) => {
  // Get the value type
  const type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items

  const compare = (item1: any, item2: any) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false

      // Otherwise, do a simple comparison
    } else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
    return true
  }

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}
export const deepEqualComparison = (a: object, b: object) => {
  if (a === b) {
    return true
  }
  if (
    a === null ||
    typeof a !== 'object' ||
    b === null ||
    typeof b !== 'object'
  ) {
    return false
  }
  let propsInA = 0
  let propsInB = 0
  Object.keys(a).forEach((propA) => {
    propsInA += 1
    Object.keys(b).forEach((propB) => {
      propsInB += 1
      if (!(a[propA] in a) || !deepEqualComparison(a[propA], b[propB])) {
        return false
      } else return null
    })
  })
  return propsInA === propsInB
}

export const dollarsOrBlankSpace = (item: any) => {
  return !item || item === 0 ? '' : dollarFormat(item)
}

export const nullStringFormat = (dataString: string) => {
  if (
    !dataString ||
    dataString.trim().length === 0 ||
    ['null', 'undefined'].indexOf(dataString) >= 0
  ) {
    return ''
  }
  return dataString
}

export const setCookie = (cookieValue: string) => {
  // Set cookie at top most domain
  let i = 0
  let domain = document.domain
  const parts = domain.split('.')
  //  4 hour expiration time
  const expiration = 14400
  if (domain === 'localhost') {
    document.cookie = `${cookieValue}; domain=${domain}; path=/; Max-Age=${expiration}`
  }
  while (i < parts.length - 1 && document.cookie.indexOf(cookieValue) === -1) {
    i = i + 1
    domain = parts.slice(-1 - i).join('.')
    document.cookie = `${cookieValue}; domain=${domain}; path=/; Max-Age=${expiration}`
  }
}

/**Check if object exists, ALL provided fields exist on object and if they are defined and not null
 * @param obj - any valid object that we want to check, if no valid object provided function returns false
 * @param fieldsArr - array of strings representing fields to be checked,if no array provided or if array is empty returns false
 * @return - boolean
 */
export const checkObjFields = (
  obj: Record<string, unknown>,
  fieldsArr: string[]
) => {
  if (Object.prototype.toString.call(obj) !== '[object Object]') return false
  if (!Array.isArray(fieldsArr) || fieldsArr.length === 0) return false

  return !fieldsArr.some(
    (el: string): boolean =>
      !Object.prototype.hasOwnProperty.call(obj, el) ||
      typeof obj[el] === 'undefined' ||
      obj[el] === null
  )
}

/**
 *
 * @param min - Initial number to start from
 * @param max - Max number to return
 * @returns random number withing this limit
 */
export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
