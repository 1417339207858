import React, { Component } from 'react'
import * as reactAccordion from 'react-accessible-accordion'
import ClientTabs from './clientTabs'

class ClientProfile extends Component {
  constructor(props: {}) {
    super(props)
  }

  public render() {
    return (
      <div>
        <reactAccordion.Accordion>
          <reactAccordion.AccordionItem expanded={true}>
            <reactAccordion.AccordionItemTitle>
              <h3 className='u-position-relative title-text'>
                CLIENT PROFILE
                <div className='accordion__arrow' role='presentation' />
              </h3>
            </reactAccordion.AccordionItemTitle>
            <reactAccordion.AccordionItemBody>
              <ClientTabs />
            </reactAccordion.AccordionItemBody>
          </reactAccordion.AccordionItem>
        </reactAccordion.Accordion>
      </div>
    )
  }
}

export default ClientProfile
