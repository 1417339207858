import {
  InvestmentViewfinderInterface,
  InvestmentViewfinderAnswerObj,
  InvestmentViewfinderExerciseObj,
  InvestmentViewfinderQuestionObj,
  InvestmentViewfinderPriorityInterface,
  InvestmentViewfinderAllocationInterface,
  InvestmentViewfinderGeographicPriorityInterface
} from '../objects/investmentViewfinder'
import * as actions from '../actions/investmentViewfinder'
import { InvResponse } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'

export interface InvestmentViewfinderState {
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  questions: {
    [questionNumber: string]: InvestmentViewfinderQuestionObj
  }
  priorities: InvestmentViewfinderPriorityInterface
  geographicPriorities: InvestmentViewfinderGeographicPriorityInterface
  allocations: InvestmentViewfinderAllocationInterface
}

const initState: InvestmentViewfinderState = {
  exercises: {},
  questions: {},
  priorities: {
    performance: {
      color: null,
      label: null,
      description: null,
      benefits: [],
      drawbacks: []
    },
    protection: {
      color: null,
      label: null,
      description: null,
      benefits: [],
      drawbacks: []
    },
    taxes: {
      color: null,
      label: null,
      description: null,
      benefits: [],
      drawbacks: []
    },
    cost: {
      color: null,
      label: null,
      description: null,
      benefits: [],
      drawbacks: []
    }
  },
  geographicPriorities: {
    Domestic: {
      name: null,
      definition: null,
      benefits: [],
      drawbacks: []
    },
    Global: {
      name: null,
      definition: null,
      benefits: [],
      drawbacks: []
    }
  },
  allocations: {}
}

const mapInvestmentViewfinderAnswers = (
  viewfinderAnswers: InvResponse.Answers[]
): { [step: string]: InvestmentViewfinderAnswerObj } => {
  const obj = {}
  viewfinderAnswers.forEach((answer) => {
    obj[`${answer.questionId}`] = answer
  })
  return obj
}

const mapInvestmentViewfinderExercise = (
  viewfinderExercise: InvResponse.Response
): InvestmentViewfinderExerciseObj => {
  return {
    id: viewfinderExercise && viewfinderExercise.id,
    createdDate: viewfinderExercise && viewfinderExercise.createdDate,
    taxes:
      viewfinderExercise && viewfinderExercise.taxPreference
        ? viewfinderExercise.taxPreference
        : 8,
    performance:
      viewfinderExercise && viewfinderExercise.performancePreference
        ? viewfinderExercise.performancePreference
        : 8,
    protection:
      viewfinderExercise && viewfinderExercise.protectionPreference
        ? viewfinderExercise.protectionPreference
        : 8,
    cost:
      viewfinderExercise && viewfinderExercise.costPreference
        ? viewfinderExercise.costPreference
        : 8,
    global: viewfinderExercise && viewfinderExercise.globalPreference,
    domestic: viewfinderExercise && viewfinderExercise.domesticPreference,
    globalScore: viewfinderExercise && viewfinderExercise.globalScore,
    domesticScore: viewfinderExercise && viewfinderExercise.domesticScore,
    selectedAllocation:
      viewfinderExercise && viewfinderExercise.selectedAssetAllocation,
    answers:
      viewfinderExercise &&
      mapInvestmentViewfinderAnswers(viewfinderExercise.answers)
  }
}

const mapInvestmentViewfinderExercises = (
  viewfinderExercises: InvResponse.Response[]
): InvestmentViewfinderInterface => {
  const obj = {}
  viewfinderExercises.forEach((exercise) => {
    obj[exercise.id] = mapInvestmentViewfinderExercise(exercise)
  })
  return obj
}

const mapInvestmentViewfinderPriorities = (
  exercise: InvestmentViewfinderExerciseObj,
  answer: any
) => {
  const values = answer.value.split(',')
  const obj = {}
  obj[answer.questionId] = answer
  return {
    taxes: exercise.taxes + Number(values[0]),
    performance: exercise.performance + Number(values[1]),
    protection: exercise.protection + Number(values[2]),
    cost: exercise.cost + Number(values[3]),
    answers: { ...exercise.answers, ...obj }
  }
}

const mapInvestmentViewfinderQuestion = (
  question: any
): InvestmentViewfinderQuestionObj => {
  return {
    id: question.id,
    questionNumber: question.order,
    priority1: question.category,
    priority2: question.offset
  }
}

const mapInvestmentViewfinderQuestions = (questions: any[]) => {
  const obj = {}
  questions.forEach((question) => {
    if (question.order) {
      obj[question.order] = mapInvestmentViewfinderQuestion(question)
    }
  })
  return obj
}

const mapAllocationPerformance = (
  performance: { name: string; value: number; quality: string; unit: string }[]
) => {
  const obj = {}
  performance.forEach((metric) => {
    obj[metric.name] = {
      name: metric.name,
      value: metric.value,
      quality: metric.quality,
      unit: metric.unit
    }
  })
  return obj
}

const mapInvestmentViewfinderAllocations = (allocations: any[]) => {
  const obj = {}
  allocations.forEach((allocation) => {
    obj[allocation.name] = {
      stocks: allocation.stocks,
      bonds: 100 - allocation.stocks,
      performance: mapAllocationPerformance(allocation.performance)
    }
  })
  return obj
}

const mapInvestmentViewfinderPrioritiesCMS = (priorities: any[]) => {
  const obj = {}
  priorities.forEach((priority) => {
    obj[priority.name] = priority.json
  })
  return obj
}

const mapInvestmentViewfinderGeographicPrioritiesCMS = (priorities: any[]) => {
  const obj = {}
  priorities.forEach((priority) => {
    obj[priority.name] = priority.json
    obj[priority.name].image = priority.image
  })
  return obj
}

const Investments = (
  state = initState,
  action: any
): InvestmentViewfinderState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_VIEWFINDER_EXERCISES}_FULFILLED`:
      newState.exercises[
        action.payload.data.householdFinId
      ] = mapInvestmentViewfinderExercises(action.payload.data)
      return newState
    case `${actions.CREATE_VIEWFINDER_EXERCISE}_FULFILLED`:
    case `${actions.UPDATE_VIEWFINDER_EXERCISE}_FULFILLED`:
      newState.exercises[action.payload.data.household][
        action.payload.data.id
      ] = mapInvestmentViewfinderExercise(action.payload.data)
      newState.exercises[
        action.payload.data.household
      ].newViewfinder = mapInvestmentViewfinderExercise(action.payload.data)
      return newState
    case `${actions.CREATE_VIEWFINDER_ANSWER}_FULFILLED`:
    case `${actions.UPDATE_VIEWFINDER_ANSWER}_FULFILLED`:
      const exercise =
        newState.exercises[action.payload.data.householdFinId][
          action.payload.data.invResponse
        ]
      newState.exercises[action.payload.data.householdFinId][
        action.payload.data.invResponse
      ] = {
        ...exercise,
        ...mapInvestmentViewfinderPriorities(exercise, action.payload.data)
      }
      return newState
    case `${actions.FETCH_VIEWFINDER_QUESTIONS}_FULFILLED`:
      newState.questions = mapInvestmentViewfinderQuestions(action.payload.data)
      return newState
    case `${actions.FETCH_VIEWFINDER_ALLOCATIONS}_FULFILLED`:
      newState.allocations = mapInvestmentViewfinderAllocations(
        action.payload.data
      )
      return newState
    case `${actions.FETCH_VIEWFINDER_GEOGRAPHIC_PRIORITIES}_FULFILLED`:
      newState.geographicPriorities = mapInvestmentViewfinderGeographicPrioritiesCMS(
        action.payload.data
      )
      return newState
    case `${actions.FETCH_VIEWFINDER_PRIORITIES}_FULFILLED`:
      newState.priorities = mapInvestmentViewfinderPrioritiesCMS(
        action.payload.data
      )
      return newState
    default:
      return state
  }
}

export default Investments
