import React, { Fragment } from 'react'
import FundedScoreGraph from '../../components/fundedScoreGraph'
import Tile from '../../components/layout/tile'
import { ReactComponent as EMoneyIcon } from '../../assets/images/logos/emoney-logo-coolgray.svg'
import { ReactComponent as GDX360Icon } from '../../assets/images/logos/gdx-360-logo.svg'
import MoneyGuideProIcon from '../../assets/images/logos/mgp-logo.png'
import RefreshIcon from '../../assets/images/icons/png/refresh.png'
import plusIcon from '../../assets/images/icons/png/plus.png'
import { ReactComponent as FinancialGoalsIcon } from '../../assets/images/icons/score.svg'

import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../objects/financialGoal'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { dateFormat } from '../../helpers'
import SelectPlanningSoftwareModal from '../financialGoals/selectPlanningSoftwareModal'
import QuickActionLinks from '../../components/quickActionLinks'
import FundedScoreModal from './fundedScoreModal'
import FundedScoreDetailsModal from './fundedScoreDetailsModal'
import { history } from '../../../store'
import * as actions from '../../../actions/financialGoals'
import * as toastActions from '../../../actions/toasts'
import { startLoader, stopLoader } from '../../../actions/loader'
import { HouseholdInstitution, HouseholdObj } from '../../../objects/household'
import {
  setPlanningSoftware,
  getIndividualHousehold
} from '../../../actions/households'
import RecentScoreComment from './recentScoreComment'
import HistoricalLineChart from '../../components/graphs/historicalLineChart'
import FundedScoreHistoryComments from './fundedScoreHistoryComments'
import RefreshConfirmationModal from './refreshConfirmationModal'

interface FundedScoreTileProps {
  household: HouseholdObj
  fundedScores: FundedScoreObj[]
  lastUpdated: string
  planningSoftware: PlanningSoftwareType
  animate?: boolean
  dispatch: Dispatch<GlobalState>
  institution: HouseholdInstitution
}

interface FundedScoreTileState {
  fundedScoreObj: FundedScoreObj
  showMoneyGuideProSoftware: boolean
  showFinanceawareSoftware: boolean
  showSelectPlanningSoftwareModal: boolean
  showFundedScoreModal: boolean
  showFundedScoreDetailsModal: boolean
  showRefreshConfirmationModal: boolean
}

const emptyFundedScoreObj = {
  id: '',
  name: '',
  inGuidebook: false,
  rangeLow: 0,
  rangeHigh: 0,
  score: 0,
  lastUpdated: '',
  notes: ''
}

class FundedScoreTile extends React.Component<
  FundedScoreTileProps,
  FundedScoreTileState
> {
  constructor(props: FundedScoreTileProps) {
    super(props)
    this.state = {
      fundedScoreObj: emptyFundedScoreObj,
      showMoneyGuideProSoftware: false,
      showFinanceawareSoftware: false,
      showSelectPlanningSoftwareModal: false,
      showFundedScoreDetailsModal: false,
      showFundedScoreModal: false,
      showRefreshConfirmationModal: false
    }
  }

  public componentDidMount = () => {
    const noFundedScores =
      this.props.planningSoftware === null &&
      this.props.fundedScores.length === 0
    this.setState({
      showSelectPlanningSoftwareModal: noFundedScores
    })
  }

  public updatePlanningSoftware = async (
    planningSoftware: PlanningSoftwareType
  ) => {
    const { dispatch } = this.props
    const { household } = this.props

    await dispatch(setPlanningSoftware(household.id, planningSoftware))
    dispatch(toastActions.addToast({ message: 'Settings have been saved.' }))
    this.toggleSelectPlanningSoftwareModal()
  }

  public getPlanningSoftwareIcon = (planningSoftware: PlanningSoftwareType) => {
    switch (planningSoftware) {
      case 'emoney':
      case 'emoney_enterprise':
        return <EMoneyIcon />
      case 'moneyguidepro':
        return <img src={MoneyGuideProIcon} alt='moneyguidepro' />
      case 'gdx360':
        return <GDX360Icon />
      default:
        return null
    }
  }

  public openFundedScoreDetailsModal = (fundedScoreDetail?: FundedScoreObj) => {
    const setTileState = async () => {
      await this.setState({
        fundedScoreObj: fundedScoreDetail,
        showFundedScoreDetailsModal: !this.state.showFundedScoreDetailsModal
      })
    }

    setTileState()
  }

  public openFundedScoreModal = () => {
    this.setState({
      showFundedScoreModal: !this.state.showFundedScoreModal
    })
  }

  public toggleSelectPlanningSoftwareModal = () => {
    this.setState({
      showSelectPlanningSoftwareModal: !this.state
        .showSelectPlanningSoftwareModal
    })
  }

  public toggleRefreshConfirmationModal = () => {
    this.setState({
      showRefreshConfirmationModal: !this.state.showRefreshConfirmationModal
    })
  }

  public addFundedScore = (score: number) => {
    const { household } = this.props
    this.props.dispatch(actions.addFundedScore(score, household.id))
    this.openFundedScoreModal()
    this.props.dispatch(
      toastActions.addToast({ message: 'Funded Score Added' })
    )
  }

  public launch = () => {
    const { household } = this.props
    history.push(`/households/${household.id}/financialGoals/financialPlanning`)
  }

  public gotoFinGoalHistory = () => {
    const { household } = this.props
    history.push(`/households/${household.id}/financialGoals/history`)
  }

  public updateFundedScore = async (updatedFundedScore: FundedScoreObj) => {
    const { household } = this.props
    const { showFundedScoreDetailsModal } = this.state
    await this.props.dispatch(
      actions.updatedFundedScore(
        updatedFundedScore.id,
        updatedFundedScore,
        household.id
      )
    )

    if (showFundedScoreDetailsModal) {
      this.setState({
        showFundedScoreDetailsModal: false
      })
    }

    this.props.dispatch(
      toastActions.addToast({
        message: 'Successfully updated.',
        backgroundColor: '#D9F2B6'
      })
    )
  }

  public mgpRefresh = async () => {
    const { household } = this.props
    this.refresh(actions.refreshMGP(household.id))
  }

  public gdx360Refresh = async () => {
    const { household } = this.props
    this.refresh(actions.refreshGDX360(household.id))
  }

  public emoneyRefresh = async () => {
    const { household } = this.props
    if (household.emoneyId) {
      try {
        await this.refresh(
          actions.refreshEmoney(household.id, household.emoneyId)
        )
      } catch (e) {
        this.props.dispatch(
          toastActions.addToast({
            message: 'Unable to refresh Financial Plan',
            backgroundColor: '#F12938'
          })
        )
        throw e
      }
    } else {
      this.props.dispatch(
        toastActions.addToast({
          message: 'Please set an eMoney Id in Client Settings',
          backgroundColor: '#F12938'
        })
      )
    }
  }

  public refresh = async (refreshAction: any) => {
    const { household, dispatch } = this.props
    try {
      dispatch(startLoader())
      await dispatch(refreshAction)
    } catch (e) {
      throw e
    } finally {
      dispatch(stopLoader())
    }
    await this.props.dispatch(getIndividualHousehold(household.id))
    this.props.dispatch(actions.getFinancialGoals(household.id))
    this.props.dispatch(
      toastActions.addToast({ message: 'Refresh Successful' })
    )
  }

  public doAccountsRefresh = () => {
    const { planningSoftware } = this.props
    this.setState(
      {
        showRefreshConfirmationModal: false
      },
      () => {
        switch (planningSoftware) {
          case 'gdx360':
            this.gdx360Refresh()
            break
          case 'moneyguidepro':
            this.mgpRefresh()
            break
          case 'emoney':
          case 'emoney_enterprise':
            this.emoneyRefresh()
            break
        }
      }
    )
  }

  public isEmoneySoftwareActive = () => {
    const { planningSoftware } = this.props
    return Boolean(
      planningSoftware &&
        (planningSoftware === 'emoney_enterprise' ||
          planningSoftware === 'emoney')
    )
  }

  public rightHeader = () => {
    const { planningSoftware } = this.props
    const defaultActions = {
      text: planningSoftware ? 'Refresh' : 'Add Funded Score',
      icon: planningSoftware ? RefreshIcon : plusIcon,
      onClick: planningSoftware
        ? this.toggleRefreshConfirmationModal
        : this.openFundedScoreModal
    }
    return (
      <QuickActionLinks
        customQuickAction={[defaultActions]}
        historyAction={this.gotoFinGoalHistory}
        launchAction={!this.isEmoneySoftwareActive() ? this.launch : null}
      />
    )
  }

  public leftHeader = () => {
    return (
      <div className='funded-score-tile__header-w'>
        <div className='funded-score-tile__title'>FUNDED SCORE</div>
        {this.renderPlanningSoftwareHeader()}
      </div>
    )
  }

  public renderPlanningSoftwareHeader = () => {
    const { planningSoftware } = this.props
    const selectPlanningSoftwareAction = [
      {
        text: 'Select Planning Software',
        onClick: this.toggleSelectPlanningSoftwareModal
      }
    ]
    if (planningSoftware) {
      return (
        <div className='funded-score-tile__icon pdf-exclude'>
          {this.getPlanningSoftwareIcon(planningSoftware)}
        </div>
      )
    } else {
      return (
        <div className='funded-score-tile__default-icon pdf-exclude'>
          <FinancialGoalsIcon />
          <QuickActionLinks customQuickAction={selectPlanningSoftwareAction} />
        </div>
      )
    }
  }

  public nullState = () => {
    return (
      <div className='funded-score__w funded-score__w--placeholder'>
        <div className='funded-score__graph-w'>
          <div className='funded-score__section--underfunded funded-score__section--underfunded-placeholder'>
            <div className='funded-score__bar-w funded-score__bar-w--underfunded'>
              <div className='funded-score__bar funded-score__bar--underfunded' />
            </div>
            <div className='funded-score__label funded-score__label-underfunded--placeholer' />
          </div>
          <div className='funded-score__section--ideal funded-score__section--ideal-placeholder'>
            <div className='funded-score__bar-w funded-score__bar-w--ideal'>
              <div className='funded-score__bar funded-score__bar--ideal' />
            </div>
            <div className='funded-score__label funded-score__label-ideal--placeholer' />
          </div>
          <div className='funded-score__section--overfunded funded-score__section--overfunded-placeholder'>
            <div className='funded-score__bar-w funded-score__bar-w--overfunded'>
              <div className='funded-score__bar funded-score__bar--overfunded' />
            </div>
            <div className='funded-score__label funded-score__label-overfunded--placeholer' />
          </div>
        </div>
      </div>
    )
  }
  public render() {
    const { planningSoftware, lastUpdated, animate } = this.props
    const {
      showSelectPlanningSoftwareModal,
      showRefreshConfirmationModal
    } = this.state
    const latestFundedScore = this.props.fundedScores
      ? this.props.fundedScores[0]
      : null
    const rangeLow = latestFundedScore ? latestFundedScore.rangeLow : null
    const rangeHigh = latestFundedScore ? latestFundedScore.rangeHigh : null
    const score =
      latestFundedScore?.score && Math.round(latestFundedScore.score)

    return (
      <Fragment>
        <Tile
          leftHeader={this.leftHeader()}
          headerBorder={true}
          rightHeader={this.rightHeader()}
          headerStyle={{ backgroundColor: '#FAFAFA' }}>
          <HistoricalLineChart
            id='latest-funded-score'
            limit={4}
            planningSoftware={planningSoftware}
            fundedScores={this.props.fundedScores}
            animate={animate}
            showModal={this.openFundedScoreDetailsModal}
          />
          <div className='funded-score-header-w'>
            <div className='pdf-only'>
              {this.getPlanningSoftwareIcon(planningSoftware)}
            </div>
            {lastUpdated ? (
              <div className='funded-score-tile__last-updated'>
                Last Updated on {lastUpdated}
              </div>
            ) : null}
          </div>
          {latestFundedScore ? (
            <FundedScoreGraph
              rangeLow={rangeLow}
              rangeHigh={rangeHigh}
              score={score}
              planningSoftware={planningSoftware}
            />
          ) : (
            this.nullState()
          )}

          <RecentScoreComment
            latestFundedScore={latestFundedScore}
            householdFinId={this.props.household.id}
          />
          <div className='pdf-only'>
            <FundedScoreHistoryComments
              fundedScores={this.props.fundedScores}
            />
          </div>
        </Tile>

        {this.state.showFundedScoreDetailsModal ? (
          <FundedScoreDetailsModal
            rangeLow={rangeLow}
            rangeHigh={rangeHigh}
            planningSoftware={planningSoftware}
            fundedScoreObj={this.state.fundedScoreObj}
            saveFunc={this.updateFundedScore}
            closeModal={this.openFundedScoreDetailsModal}
          />
        ) : null}

        {this.state.showFundedScoreModal ? (
          <FundedScoreModal
            saveModal={this.addFundedScore}
            closeModal={this.openFundedScoreModal}
          />
        ) : null}
        {showSelectPlanningSoftwareModal ? (
          <SelectPlanningSoftwareModal
            defaultPlanningSoftware={this.props.planningSoftware}
            saveModal={this.updatePlanningSoftware}
            closeModal={this.toggleSelectPlanningSoftwareModal}
          />
        ) : null}
        {planningSoftware && showRefreshConfirmationModal ? (
          <RefreshConfirmationModal
            refreshFunc={this.doAccountsRefresh}
            closeFunc={this.toggleRefreshConfirmationModal}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdId = match.params.householdFinId
  const getLastUpdated = (
    fundedScoresArray: FundedScoreObj[]
  ): string | null => {
    if (!fundedScoresArray || fundedScoresArray.length === 0) {
      return null
    }

    return dateFormat(fundedScoresArray[0].lastUpdated)
  }

  return {
    lastUpdated: getLastUpdated(
      (store.financialGoals[householdId] &&
        store.financialGoals[householdId].fundedScores) ||
        null
    ),
    household: store.households[householdId],
    fundedScores:
      store.financialGoals[householdId] &&
      store.financialGoals[householdId].fundedScores,
    institution: store.households[householdId].institution
  }
}

export default withRouter(connect(mapStateToProps)(FundedScoreTile))
