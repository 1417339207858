import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

import { HouseholdObj } from '../../../../objects/household'

import guidebookicon from '../../../assets/images/icons/householdNav/active_guidebook.png'
import downloadicon from '../../../assets/images/icons/ic_downloadx2.png'
import vaultIcon from '../../../assets/images/icons/push_to_vault.png'
import CloseIcon from '../../../assets/images/icons/ic_close.png'
import { ClipLoader } from 'react-spinners'
import { history } from '../../../../store'

export interface PpaProps {
  household: HouseholdObj
  isLoading: boolean
  isVaultLoading: boolean
  downloader(uploadToDocVault?: boolean): void
  portfolioBuilderId: string
}
class PortfolioProposalActions extends Component<PpaProps, {}> {
  public goBackToProposalHistory = () => {
    history.push(
      `/households/${this.props.household.id}/portfolioBuilder/${this.props.portfolioBuilderId}/PortfolioProposal/history`
    )
  }

  public render() {
    const { isLoading, downloader, isVaultLoading } = this.props
    return (
      <div className='gb-actions'>
        <div className='gb-actions__left-w'>
          <img
            className='gb-actions__logo'
            src={guidebookicon}
            alt='Portfolio Proposal Icon'
          />
          <span className='gb-actions__logo-text'>
            Portfolio Proposal Builder
          </span>
          <div className='gb-actions__divider' />
          <span className='gb-actions__client-name'>
            {this.props.household.name}
          </span>
        </div>
        <div className='gb-actions__right-w'>
          <div className='gb-actions__item'>
            <a
              data-tip={
                !isVaultLoading ? 'Push the Portfolio Proposal to your client’s vault' : 'Push the Portfolio Proposal to your client’s vault disabled during load'
              }>
              <ReactTooltip place='bottom' type='dark' effect='solid' />
              {isVaultLoading ? (
                <ClipLoader
                  sizeUnit={'px'}
                  size={18}
                  color={'#4a78cf'}
                  loading={isVaultLoading}
                />
              ) : (
                  <img
                    onClick={() => downloader(true)}
                    src={vaultIcon}
                    alt='Download Icon'
                  />
                )}
            </a>
          </div>
          <div className='gb-actions__item__divider' />
          <div className='gb-actions__item'>
            <a
              data-tip={
                !isLoading ? 'Download as PDF' : 'Download disabled during load'
              }>
              <ReactTooltip place='bottom' type='dark' effect='solid' />
              {isLoading ? (
                <ClipLoader
                  sizeUnit={'px'}
                  size={18}
                  color={'#4a78cf'}
                  loading={isLoading}
                />
              ) : (
                  <img
                    onClick={() => downloader()}
                    src={downloadicon}
                    alt='Download Icon'
                  />
                )}
            </a>
          </div>
          <div className='gb-actions__item__divider' />
          <div
            onClick={this.goBackToProposalHistory}
            className='gb-actions__item'>
            <a data-tip='Back to Proposal History'>
              <ReactTooltip place='bottom' type='dark' effect='solid' />
              <img src={CloseIcon} alt='Close Icon' />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default PortfolioProposalActions
