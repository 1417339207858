import {
  HonestConversationsExerciseObj,
  HouseholdCardObj,
  ClientScoringSession,
  CardScoreInterface
} from '../../../../objects/honestConversations'

import {
  dataSetTemplate,
  PRIMARY_LINE_COLOR,
  SECONDARY_LINE_COLOR
} from '../../../../objects/lineChart'
import { ContactsInterface } from '../../../../objects/contact'
import moment from 'moment'

interface GraphScoresDisplay {
  scoreNumbers: number[]
  scoreDots: number[]
}

const POINT_RADIUS_SIZE: number = 4
const MAX_SHARED_PRIORITY_GRAPH_DISPLAY: number = 4

const setPointColors = (pointArray: number[]) => {
  const pointColors: string[] = []
  pointArray.forEach((point) => {
    pointColors.push(point >= 7 ? '#A7D500' : '#FFBE1A')
  })
  return pointColors
}

const sortCardScoresByDate = (
  scoringSession: ClientScoringSession,
  maxReturned?: number
): CardScoreInterface[] => {
  const sortedByDate: CardScoreInterface[] =
    scoringSession &&
    Object.keys(scoringSession)
      .sort((scoreA: string, scoreB: string) => {
        return moment(scoringSession[scoreA].createdDate).isBefore(
          scoringSession[scoreB].createdDate
        )
          ? -1
          : 1
      })

      .map((scoreA) => {
        return scoringSession[scoreA].cardScores
      })

  if (maxReturned && sortedByDate?.length > maxReturned) {
    return sortedByDate.splice(Math.max(sortedByDate?.length - maxReturned, 0))
  }
  return sortedByDate
}

const getCardScores = (
  selectedCardId: string,
  cardScores: CardScoreInterface[]
): GraphScoresDisplay => {
  const scoreNumbers: number[] = []
  const scoreDots: number[] = []

  cardScores
    ?.filter((cardScore) => cardScore !== undefined || null)
    .forEach((cardScore: CardScoreInterface, index: number) => {
      // empty score sessions
      if (cardScore) {
        if (!Object.keys(cardScore)?.length) {
          scoreNumbers.push(0)
          scoreDots.push(POINT_RADIUS_SIZE)
        }
        Object.keys(cardScore).forEach((key) => {
          if (cardScore[key]?.householdCard?.id === selectedCardId) {
            scoreNumbers.push(cardScore[key].score || 0)
            scoreDots.push(POINT_RADIUS_SIZE)
          }
        })
      }
    })

  return { scoreNumbers, scoreDots }
}

export const getIndividualScoreDataSet = (
  honestConversation: HonestConversationsExerciseObj,
  selectedCard: HouseholdCardObj,
  contacts: ContactsInterface
) => {
  const data: { labels: string[]; datasets: any[] } = {
    labels: [],
    datasets: []
  }
  if (!honestConversation || !contacts) {
    return data
  }

  const { clientScoringSessions } = honestConversation

  if (Object.keys(clientScoringSessions).length === 0) {
    return data
  }

  const primaryScoringSessions = clientScoringSessions[contacts.primary?.id]
  const primaryCardScores: CardScoreInterface[] =
    primaryScoringSessions &&
    sortCardScoresByDate(
      primaryScoringSessions,
      MAX_SHARED_PRIORITY_GRAPH_DISPLAY
    )

  const primaryScoresObj: GraphScoresDisplay =
    primaryCardScores && getCardScores(selectedCard.id, primaryCardScores)
  const primaryScores: number[] = primaryScoresObj?.scoreNumbers || []
  const primaryPointRadius: number[] = primaryScoresObj?.scoreDots || []

  const secondaryScoringSessions =
    clientScoringSessions[contacts?.secondary?.id]

  const secondaryCardScores: CardScoreInterface[] =
    secondaryScoringSessions &&
    sortCardScoresByDate(
      secondaryScoringSessions,
      MAX_SHARED_PRIORITY_GRAPH_DISPLAY
    )

  const secondaryScoresObj: GraphScoresDisplay =
    secondaryCardScores && getCardScores(selectedCard.id, secondaryCardScores)
  const secondaryScores: number[] = secondaryScoresObj?.scoreNumbers || []
  const secondaryPointRadius: number[] = secondaryScoresObj?.scoreDots || []

  // set the length of labels on the longest score length
  // this gives the end to end line look
  const labelCount =
    secondaryScores.length > primaryScores.length
      ? secondaryScores.length
      : primaryScores.length
  data.labels = Array<string>(labelCount).fill('')

  const primaryLineColor = !contacts.secondary
    ? SECONDARY_LINE_COLOR
    : PRIMARY_LINE_COLOR

  const primaryDataSet = {
    ...dataSetTemplate,
    label: 'Primary Score History',
    backgroundColor: primaryLineColor,
    borderColor: primaryLineColor,
    pointRadius: primaryPointRadius,
    pointBackgroundColor: setPointColors(primaryScores),
    data: primaryScores
  }
  data.datasets.push(primaryDataSet)

  if (secondaryScores.length > 0) {
    data.datasets.push({
      ...dataSetTemplate,
      label: 'Secondary Score History',
      backgroundColor: SECONDARY_LINE_COLOR,
      borderColor: SECONDARY_LINE_COLOR,
      pointRadius: secondaryPointRadius,
      pointBackgroundColor: setPointColors(secondaryScores),
      data: secondaryScores
    })
  }

  return data
}
