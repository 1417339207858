import React from 'react'
import { PlanningSoftwareType } from '../../objects/financialGoal'

interface FundedScoreGraphProps {
  rangeLow: number
  rangeHigh: number
  score: number
  planningSoftware: PlanningSoftwareType
  style?: any
}

class FundedScoreGraph extends React.Component<FundedScoreGraphProps> {
  public gradientGraph = () => {
    return (
      <div className='funded-score__graph-w'>
        <div className='funded-score__section'>
          <div className='funded-score__bar-w funded-score__bar-w--gradient'>
            <div className='funded-score__bar funded-score__gradient' />
          </div>
          <div className='funded-score__label-w'>
            <div className='funded-score__label'>Underfunded</div>
            <div className='funded-score__label funded-score__label--ideal'>
              Ideal
            </div>
            <div className='funded-score__label funded-score__label--overfunded'>
              Overfunded
            </div>
          </div>
        </div>
      </div>
    )
  }
  public definedGraph = () => {
    const minScore =
      this.props.planningSoftware === 'gdx360' || !this.props.rangeLow
        ? 75
        : this.props.rangeLow
    const maxScore =
      this.props.planningSoftware === 'gdx360' || !this.props.rangeHigh
        ? 90
        : this.props.rangeHigh

    const underfunded: number = (minScore - 50) * (100 / 50)
    const ideal: number = (maxScore - minScore) * (100 / 50)
    const overfunded: number = (100 - maxScore) * (100 / 50)

    return (
      <div className='funded-score__graph-w'>
        <div
          className='funded-score__section--underfunded'
          style={{ width: `${underfunded}%` }}>
          <div className='funded-score__bar-w funded-score__bar-w--underfunded'>
            <div className='funded-score__bar funded-score__bar--underfunded' />
          </div>
          <div className='funded-score__label'>Underfunded</div>
        </div>
        <div
          className='funded-score__section--ideal'
          style={{ width: `${ideal}%` }}>
          <div className='funded-score__bar-w funded-score__bar-w--ideal'>
            <div className='funded-score__bar funded-score__bar--ideal' />
          </div>
          <div className='funded-score__label'>Ideal</div>
        </div>
        <div
          className='funded-score__section--overfunded'
          style={{ width: `${overfunded}%` }}>
          <div className='funded-score__bar-w funded-score__bar-w--overfunded'>
            <div className='funded-score__bar funded-score__bar--overfunded' />
          </div>
          <div className='funded-score__label funded-score__label--overfunded'>
            Overfunded
          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { planningSoftware, style } = this.props
    const score = this.props.score && Math.round(this.props.score)
    let graph
    switch (planningSoftware) {
      case 'gdx360':
        graph = this.definedGraph()
        break
      case 'emoney':
        graph = this.gradientGraph()
        break
      case 'moneyguidepro':
        graph = this.definedGraph()
        break
      default:
        graph = this.gradientGraph()
    }

    const markerPosition = score ? (score - 50) * 2 : 50
    const markerStyle =
      score < 50
        ? { left: 0 }
        : markerPosition > 95
        ? { right: `${100 - markerPosition}%` }
        : { left: `${markerPosition}%` }

    const markerClass =
      markerPosition > 95
        ? 'funded-score__marker--right-line'
        : 'funded-score__marker--left-line'

    return (
      <div className='funded-score__w' style={style}>
        <div
          className={`funded-score__marker ${markerClass}`}
          style={markerStyle}>
          {score || score === 0 ? score : '?'}
        </div>
        {graph}
        <div className='funded-score__w-disclosure pdf-only'>
          <p>
            See Disclaimers section in the back of this Guidebook to see how
            your funding score was determined.
          </p>
        </div>
      </div>
    )
  }
}

export default FundedScoreGraph
