import React, { Component, ChangeEvent, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { updateIMStrategyFilter } from '../../../../../actions/strategySearch'
import {
  assetStructureMap,
  StrategiesFilterObj
} from '../../../../../objects/strategySearch'
import SelectStrategyType from './selectStrategyType'
import ManagedStrategies from './managedStrategies'
import AssetClass from './assetClass'
import {
  childrenAllChecked,
  childrenSomeChecked
} from '../../../../helpers/strategySearch'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow_right.svg'

export interface FilterStrategiesProps {
  dispatch: Dispatch<GlobalState>
  filters: StrategiesFilterObj
  strategyFilterTypeSelected: string
  toggleFilterDropdown(label?: string): void
  toggleStrategyFilterTypeSelected(selected: string): void
}

interface FilterStrategiesState {
  isFilterChanges: boolean
  filters: {
    assetClassL2: string[]
    managedStrategies: string[]
  }
}

export class FilterStrategies extends Component<
  FilterStrategiesProps,
  FilterStrategiesState
> {
  constructor(props: FilterStrategiesProps) {
    super(props)
    this.state = {
      isFilterChanges: false,
      filters: {
        assetClassL2: this.props.filters.assetClassL2,
        managedStrategies: this.props.filters.managedStrategies
      }
    }
  }

  public toggleStrategyFilterType = async (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const value: string = e.currentTarget.value
    const { dispatch } = this.props
    const { filters } = this.state
    await this.props.toggleStrategyFilterTypeSelected(value)
    if (value === 'Managed Strategies') {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...filters,
          assetClassL2: []
        }
      }))
      dispatch(updateIMStrategyFilter('assetClassL2', []))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...filters,
          managedStrategies: []
        }
      }))
      dispatch(updateIMStrategyFilter('managedStrategies', []))
    }
  }

  public toggleL1Checkbox = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { filters } = this.state
    const value = e.currentTarget.value
    if (
      !childrenAllChecked(filters.assetClassL2, value) &&
      !childrenSomeChecked(filters.assetClassL2, value)
    ) {
      this.setState((prevState) => ({
        ...prevState,
        isFilterChanges: true,
        filters: {
          ...filters,
          assetClassL2: [...filters.assetClassL2, ...assetStructureMap[value]]
        }
      }))
    } else {
      const tempState = [...filters.assetClassL2].filter((item: string) => {
        return !assetStructureMap[value].includes(item)
      })
      this.setState((prevState) => ({
        ...prevState,
        isFilterChanges: true,
        filters: {
          ...filters,
          assetClassL2: tempState
        }
      }))
    }
  }

  public toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { filters } = this.state
    const value = e.currentTarget.value
    const filterType: string = e.currentTarget.name
    if (!filters[filterType]?.some((filter: string) => filter === value)) {
      this.setState((prevState) => ({
        ...prevState,
        isFilterChanges: true,
        filters: {
          ...filters,
          [filterType]: [...filters[filterType], value]
        }
      }))
    } else {
      const tempState = [...filters[filterType]]
      tempState.forEach((item: string, i: number) => {
        if (value === item) {
          tempState.splice(i, 1)
        }
      })
      this.setState((prevState) => ({
        ...prevState,
        isFilterChanges: true,
        filters: { ...filters, [filterType]: tempState }
      }))
    }
  }

  public handleCancel = () => {
    const { filters } = this.state
    const filterType =
      this.props.strategyFilterTypeSelected === 'Managed Strategies'
        ? 'managedStrategies'
        : 'assetClassL2'
    this.setState((prevState) => ({
      ...prevState,
      isFilterChanges: true,
      filters: {
        ...filters,
        [filterType]: []
      }
    }))
    this.props.toggleFilterDropdown()
  }

  public handleApply = () => {
    const { filters, isFilterChanges } = this.state
    const { dispatch, strategyFilterTypeSelected } = this.props
    if (isFilterChanges) {
      if (strategyFilterTypeSelected === 'Managed Strategies') {
        this.props.dispatch(
          updateIMStrategyFilter('managedStrategies', filters.managedStrategies)
        )
      } else {
        dispatch(updateIMStrategyFilter('assetClassL2', filters.assetClassL2))
      }
    }
    this.props.toggleFilterDropdown()
  }

  public filterButton = (
    label: string,
    isOpen: boolean,
    activeFiltersCount: number,
    dropDownFilterElement?: React.ReactElement
  ) => {
    const toggleFilterHandler = () => this.props.toggleFilterDropdown(label)
    const hasActiveFilters = activeFiltersCount > 0
    return (
      <div
        onClick={toggleFilterHandler}
        className={`${
          hasActiveFilters
            ? 'strategy-search__filter strategy-search__filter--active-filters'
            : 'strategy-search__filter'
        } ${isOpen &&
          'strategy-search__filter--open strategy-search__filter--open-paddleft'}`}>
        {label}
        {hasActiveFilters && (
          <div className='strategy-search__filter-count'>
            ({activeFiltersCount})
          </div>
        )}
        <ArrowRightIcon
          width='15px'
          height='15px'
          viewBox='0 -2 9 20'
          className={`${
            hasActiveFilters
              ? 'strategy-search__filter-arrow strategy-search__filter-arrow--active-filters'
              : 'strategy-search__filter-arrow'
          }
            ${isOpen && 'strategy-search__filter-arrow--open'}`}
        />
        {dropDownFilterElement && isOpen ? dropDownFilterElement : null}
      </div>
    )
  }

  public strategiesClearButton = () => {
    const { filters, dispatch } = this.props
    const handleClear = async () => {
      await dispatch(updateIMStrategyFilter('assetClassL2', []))
      await dispatch(updateIMStrategyFilter('managedStrategies', []))
      this.setState((prevState) => ({
        ...prevState,
        isFilterChanges: true,
        filters: {
          ...filters,
          assetClassL2: [],
          managedStrategies: []
        }
      }))
    }
    const count =
      filters.managedStrategies?.length + filters.assetClassL2?.length
    const counter = (
      <div className='strategy-search__filter-buttons-counter'>{count}</div>
    )
    if (count > 0) {
      return (
        <div
          onClick={handleClear}
          className='strategy-search__filter-buttons-clear'>
          Clear
          {counter}
        </div>
      )
    } else return null
  }

  public render() {
    const { filters, isFilterChanges } = this.state
    const selectedTypeManaged =
      this.props.strategyFilterTypeSelected === 'Managed Strategies'
    const selectedTypeAsset =
      this.props.strategyFilterTypeSelected === 'Asset Class'
    return (
      <Fragment>
        <div className='strategy-search__filter-header'>
          <div className='strategy-search__filters-w'>
            {/* <div className='strategy-search__filter strategy-search__filter--spacer' />
             */}
            {this.filterButton(
              'Select Strategies',
              true,
              this.props.filters.managedStrategies?.length +
                this.props.filters.assetClassL2?.length
            )}
          </div>
          <div className='strategy-search__filter-buttons-w'>
            {this.strategiesClearButton()}
            <div className='strategy-search__filter-buttons-cancel-apply-w strategy-search__filter-buttons-cancel-apply-w--popup'>
              <div
                onClick={this.handleCancel}
                className='strategy-search__filter-buttons-cancel'>
                Cancel
              </div>
              <div
                onClick={this.handleApply}
                className={
                  isFilterChanges
                    ? 'strategy-search__filter-buttons-apply'
                    : 'strategy-search__filter-buttons-disabled'
                }>
                Apply
              </div>
            </div>
          </div>
        </div>
        <div className='strategy-search__filter-strategy strategy-search__filter-strategy--open'>
          <div className='strategy-search__type-w'>
            <p className='strategy-search__type-text'>Types</p>
            <SelectStrategyType
              selectedStrategyType={this.props.strategyFilterTypeSelected}
              selectedStrategyTypeChange={this.toggleStrategyFilterType}
            />
          </div>
          <div className='strategy-search__checkbox-w'>
            {selectedTypeManaged ? (
              <ManagedStrategies
                toggleCheckbox={this.toggleCheckbox}
                managedStrategyFilters={filters.managedStrategies}
              />
            ) : null}
            {selectedTypeAsset ? (
              <AssetClass
                toggleL1Checkbox={this.toggleL1Checkbox}
                assetClassFilters={this.state.filters.assetClassL2}
                toggleL2Checkbox={this.toggleCheckbox}
              />
            ) : null}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    filters: store.strategySearch.filter
  }
}

export default connect(mapStateToProps)(FilterStrategies)
