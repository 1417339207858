import React, { Fragment } from 'react'
import Button from '../../components/button'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import CreateClientAccountModal from './createClientAccountModal'
import { addToast } from '../../../actions/toasts'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import * as actions from '../../../actions/clientAccounts'
import { getIndividualHousehold } from '../../../actions/households'

interface CreateClientAccountProps {
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  disableManualAccount: boolean
}

interface CreateClientAccountState {
  createAcctModalShow: boolean
}

class CreateClientAccount extends React.Component<
  CreateClientAccountProps,
  CreateClientAccountState
> {
  constructor(props: CreateClientAccountProps) {
    super(props)
    this.state = {
      createAcctModalShow: false
    }
  }

  public toggleCreateClientAccountModal = () => {
    this.setState({ createAcctModalShow: !this.state.createAcctModalShow })
  }

  public createClientAccount = async (request: any) => {
    const { householdFinId } = this.props
    await this.props.dispatch(
      actions.createClientAccount(householdFinId, request)
    )
    await this.props.dispatch(getIndividualHousehold(householdFinId))
    this.props.dispatch(
      addToast({
        icon: PlusIcon,
        message: 'Account created.',
        backgroundColor: '#D9F2B6'
      })
    )
    this.toggleCreateClientAccountModal()
  }

  public render() {
    const { createAcctModalShow } = this.state
    const { disableManualAccount } = this.props
    return (
      <Fragment>
        {disableManualAccount ? null : (
          <Button primary={true} onClick={this.toggleCreateClientAccountModal}>
            <div className='client-account__btn--text'>
              <PlusIcon /> Add Account
            </div>
          </Button>
        )}

        {createAcctModalShow ? (
          <CreateClientAccountModal
            householdFindId={this.props.householdFinId}
            dispatchFunc={this.createClientAccount}
            closeModal={this.toggleCreateClientAccountModal}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    householdFinId
  }
}

export default connect(mapStateToProps)(CreateClientAccount)
