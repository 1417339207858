import React, { ChangeEvent } from 'react'
import Button from '../../components/button'
import Search from '../../components/search'
import MessageThreadList from '../../containers/messages/messageThreadList'
import NewMessageModal from '../../containers/messages/newMessageModal'
import AdvisorMessageThreadList from '../../containers/advisorMessages/advisorMessageThreadList'
import NewAdvisorMessageModal from '../../containers/advisorMessages/newAdvisorMessageModal'
import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'
import { HouseholdsState } from '../../../reducers/households'

interface MessagesPaneProps {
  households: HouseholdsState
  userId: string
  householdFinId: string
  activeThreadId: string
  isAdvisorMessages?: boolean
  selectMessageThread(threadId: string, householdFinId?: string): void
}

interface MessagesPaneState {
  searchFilter: string
  showMessageModal: boolean
}

class MessagesPane extends React.Component<
  MessagesPaneProps,
  MessagesPaneState
> {
  constructor(props: MessagesPaneProps) {
    super(props)
    this.state = {
      searchFilter: '',
      showMessageModal: false
    }
  }
  public updateFilter = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchFilter: e.currentTarget.value.toLocaleLowerCase() })
  }
  public openMessageModal = () => {
    this.setState({ showMessageModal: true })
  }
  public closeMessageModal = () => {
    this.setState({ showMessageModal: false })
  }

  public renderNewMessageModal = () => {
    const {
      selectMessageThread,
      householdFinId,
      userId,
      households,
      isAdvisorMessages
    } = this.props
    const household = households[householdFinId]
    return isAdvisorMessages ? (
      <NewAdvisorMessageModal
        closeModal={this.closeMessageModal}
        userId={userId}
        updateMessageThread={selectMessageThread}
      />
    ) : (
      <NewMessageModal
        closeModal={this.closeMessageModal}
        householdFinId={householdFinId}
        userId={userId}
        household={household}
        updateMessageThread={selectMessageThread}
      />
    )
  }

  public render() {
    const { searchFilter, showMessageModal } = this.state
    const {
      selectMessageThread,
      householdFinId,
      households,
      activeThreadId,
      isAdvisorMessages,
      userId
    } = this.props
    const household = households[householdFinId]
    return (
      <div className='message-pane-w'>
        <Button
          primary={true}
          style={{ margin: 8, padding: 15 }}
          onClick={this.openMessageModal}>
          New Message
        </Button>
        <div className='message-pane__search-w'>
          <Search onChange={this.updateFilter} placeholder='Search Messages' />
        </div>
        {isAdvisorMessages ? (
          <AdvisorMessageThreadList
            userId={userId}
            searchFilter={searchFilter}
            selectMessageThread={selectMessageThread}
            households={households}
            activeThreadId={activeThreadId}
          />
        ) : (
          householdFinId && (
            <MessageThreadList
              searchFilter={searchFilter}
              selectMessageThread={selectMessageThread}
              householdFinId={householdFinId}
              household={household}
              activeThreadId={activeThreadId}
            />
          )
        )}
        {showMessageModal && this.renderNewMessageModal()}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    households: store.households
  }
}

export default connect(mapStateToProps)(MessagesPane)
