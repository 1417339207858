import React, { Component, CSSProperties, Fragment } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../../reducers'
import { withRouter } from 'react-router'
import {
  Tile,
  Card
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  HonestConversationsExerciseInterface,
  HonestConversationsExerciseObj,
  ClientCardObj,
  HouseholdCardObj,
  CardScoreInterface
} from '../../../../../objects/honestConversations'
import { ContactsInterface } from '../../../../../objects/contact'
import {
  CardObj,
  CardCategoryType
} from '../../../../../objects/HonestConversations/card'
import ScoreGraph from '../../../../components/graphs/honestConversations/scoreGraph'
import PageHeading from '../pageHeading'
import Actions from './resultsActions'
import TaskDescriptionModal from './TaskDescriptionModal'
import { HouseholdObj } from '../../../../../objects/household'
import { FinancialGoalActionObj } from '../../../../../objects/financialGoalActions'
import { filterGoals } from '../../../../helpers/goals'
import couple from '../../../../assets/images/honestConversations/couple.png'
import {
  getTopFiveCards,
  sortHouseholdCards
} from '../../../../helpers/honestConversations'
import goalActionsSelector from '../../../../../selectors/v3/goals'

interface ResultsSummaryProps {
  household: HouseholdObj
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  goalActions: FinancialGoalActionObj[]
}

interface ResultsSummaryState {
  showCardActionTask: boolean
  combinedExerciseCard: CardObj
}

interface HouseholdCardsData {
  combinedExerciseCards: CardObj[]
  primaryScores: number[]
  secondaryScores: number[]
  goalActions: FinancialGoalActionObj[]
}

const tileStyle: CSSProperties = {
  maxWidth: '960px',
  margin: '0px auto',
  padding: '0px '
}

const contentStyle: CSSProperties = {
  padding: '0px',
  background: '#F5F5F5',
  height: 'auto'
}

const modalContentTitleStyle: CSSProperties = {
  height: '50px',
  width: '100%',
  marginTop: '-15px',
  textAlign: 'left',
  textTransform: 'uppercase',
  fontSize: '11px',
  fontWeight: 500,
  lineHeight: '50px',
  verticalAlign: 'middle'
}

class ResultsSummary extends Component<
  ResultsSummaryProps,
  ResultsSummaryState
> {
  constructor(props: ResultsSummaryProps) {
    super(props)
    this.state = {
      showCardActionTask: false,
      combinedExerciseCard: null
    }
  }

  public getCardScores = (
    householdCard: HouseholdCardObj,
    cardScores: CardScoreInterface
  ) => {
    let score: number = null
    Object.keys(cardScores).forEach((key) => {
      if (householdCard.id === cardScores[key].householdCard.id) {
        score = cardScores[key].score
      }
    })
    return score
  }

  public mapHouseholdCardsData = () => {
    const { honestConversation, contacts, goalActions } = this.props
    const { clientScoringSessions } = honestConversation
    const householdCardsData: HouseholdCardsData = {
      combinedExerciseCards: [],
      primaryScores: [],
      secondaryScores: [],
      goalActions: []
    }
    const sortedHouseholdCards = sortHouseholdCards(
      honestConversation.householdCards
    )
    sortedHouseholdCards.forEach(
      (householdCard: HouseholdCardObj, index: number) => {
        // set householdCards
        householdCardsData.combinedExerciseCards.push({
          ...householdCard,
          title: householdCard.card.title,
          category: householdCard.card.category,
          id: index.toString()
        })
        // set primaryScores
        householdCardsData.primaryScores.push(
          contacts?.primary &&
            clientScoringSessions[contacts.primary.id][
              honestConversation.activeScoringSessionPrimary
            ] &&
            this.getCardScores(
              householdCard,
              clientScoringSessions[contacts.primary.id][
                honestConversation.activeScoringSessionPrimary
              ].cardScores
            )
        )
        // set secondaryScores
        householdCardsData.secondaryScores.push(
          contacts?.secondary &&
            this.getCardScores(
              householdCard,
              clientScoringSessions[contacts.secondary.id][
                honestConversation.activeScoringSessionSecondary
              ].cardScores
            )
        )
        // set ActionsList
        const includedGuidecenterGoals = goalActions.filter(
          (goal: FinancialGoalActionObj) => goal.inGuidecenter
        )
        const filterList = filterGoals(
          includedGuidecenterGoals,
          householdCard?.card?.id
        )
        householdCardsData.goalActions[index] = filterList
      }
    )
    return householdCardsData
  }

  public setCardResultModal = <T extends CardObj>(card?: T) => {
    this.setState({
      showCardActionTask: !this.state.showCardActionTask,
      combinedExerciseCard: card
    })
  }

  public renderCard = (card: CardObj, index: number, isShared: boolean) => {
    const cardCategoryType = (card.category.charAt(0).toUpperCase() +
      card.category.slice(1).toLowerCase()) as CardCategoryType

    return (
      <div className='results-summary__card-w'>
        <Card
          key={index}
          category={cardCategoryType}
          title={card.title}
          cardStyle={{ marginBottom: '0px', marginTop: '0px' }}
        />
        <span>
          <div className='results-summary__card-shared'>
            {isShared ? (
              <img
                src={couple}
                role='presentation'
                alt=''
                className='results-summary__card-shared-icon'
              />
            ) : null}
          </div>
        </span>
      </div>
    )
  }

  public renderResultsSummaryTableHeader = () => {
    const { contacts } = this.props
    const primaryContactName = contacts?.primary?.firstName || 'primary'
    const secondaryContactName = contacts?.secondary
      ? contacts.secondary.firstName || 'secondary'
      : null

    const actionCss: string = secondaryContactName
      ? 'results-summary__header-item--wider'
      : 'results-summary__header-item--single-contact'

    return (
      <div className='results-summary__header'>
        <div className='results-summary__header-empty'>{''}</div>

        {secondaryContactName ? (
          <div className='results-summary__header-item'>
            {secondaryContactName.toUpperCase()}
          </div>
        ) : null}
        <div className='results-summary__header-item'>
          {primaryContactName.toUpperCase()}
        </div>
        <div className={`results-summary__header-item ${actionCss}`}>GOALS</div>
      </div>
    )
  }

  public renderResultSummaryTable = () => {
    const { contacts, honestConversation } = this.props

    // check for setting shared card icon
    const primaryCards: ClientCardObj[] =
      contacts?.primary?.id &&
      honestConversation?.clientCards[contacts.primary.id]
        ? getTopFiveCards(honestConversation.clientCards[contacts.primary.id])
        : null
    const secondaryCards: ClientCardObj[] =
      contacts?.secondary?.id &&
      honestConversation?.clientCards[contacts.secondary.id]
        ? getTopFiveCards(honestConversation.clientCards[contacts.secondary.id])
        : null
    const householdCardsData = this.mapHouseholdCardsData()
    const combinedExerciseCards = householdCardsData.combinedExerciseCards
    const primaryScores = householdCardsData.primaryScores
    const secondaryScores = householdCardsData.secondaryScores
    const goalActions = householdCardsData.goalActions
    return (
      <div className='results-summary__col'>
        {combinedExerciseCards.map((card: CardObj, index: number) => {
          let isShared = false
          if (
            primaryCards &&
            card &&
            primaryCards.find((x) => x.cardId === card.id) !== undefined
          ) {
            if (
              secondaryCards &&
              secondaryCards.find((x) => x.cardId === card.id) !== undefined
            ) {
              isShared = true
            }
          }

          return (
            <div className='results-summary__row' key={index}>
              {this.renderCard(card, index, isShared)}

              <div className='results-summary__score-w'>
                {secondaryCards ? (
                  <ScoreGraph score={secondaryScores[index]} />
                ) : null}
              </div>
              <div className='results-summary__score-w'>
                <ScoreGraph score={primaryScores[index]} />
              </div>
              <Actions
                index={index}
                card={card}
                lifeActions={goalActions}
                modalFunc={this.setCardResultModal}
                contacts={contacts}
              />
            </div>
          )
        })}
      </div>
    )
  }

  public render() {
    const { householdFinId, goalActions } = this.props
    const { combinedExerciseCard, showCardActionTask } = this.state
    return (
      <Fragment>
        <div>
          <PageHeading text='Summary' />
        </div>
        <Tile
          tileStyle={tileStyle}
          contentStyle={contentStyle}
          header={<Fragment />}>
          <div id='results-summary'>
            <div className='results-summary__col pdf-w'>
              <div className='results-summary__title results-summary__for-pdf'>
                Your Priorities
              </div>
              {this.renderResultsSummaryTableHeader()}
              {this.renderResultSummaryTable()}
              <div className='disclosure-footer pdf-only'>
                <span>
                  ©{new Date().getFullYear()} | United Capital Financial
                  Advisors, LLC (“United Capital”). All Rights Reserved. |
                  FinLife Partners is a d/b/a of United Capital Financial
                  Advisors, LLC
                </span>
                <br />
                <br />
                <span>
                  All names, logos, and slogans identifying United Capital and
                  United Capital’s products and services (including, without
                  limitation, HonestConversations®, MoneyMind®, FinLife®,
                  FlexScore®, Financial Control Scorecard®, FCS®, One Best
                  Financial Life®, United Capital Private Wealth Counseling®,
                  Ideal Life Index, and FinLife Partners Powered by United
                  Capital, United Capital Financial Life Management, Financial
                  Years of Freedom) are trademarks and service marks or
                  registered trademarks and service marks of United Capital or
                  its affiliates in the United States and/or other countries.
                </span>
                <br />
                <br />
                <span>
                  Any investment advisory services are provided pursuant to the
                  terms of your agreement with United Capital or an affiliate or
                  a third party investment advisor, as applicable. United
                  Capital is an investment advisor registered with the SEC. The
                  information contained in this material is for general
                  informational purposes only and does not constitute an offer,
                  solicitation, invitation or recommendation to buy or sell any
                  financial instruments or to provide any investment advice or
                  service of any kind.
                </span>
              </div>
            </div>
          </div>
        </Tile>
        {showCardActionTask ? (
          <TaskDescriptionModal
            householdFinId={householdFinId}
            exceriseCard={combinedExerciseCard}
            lifeActions={goalActions}
            modalCloseFunc={this.setCardResultModal}>
            <div style={modalContentTitleStyle}>Goals</div>
          </TaskDescriptionModal>
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  const household = store.households[householdFinId]
  const honestConversations: HonestConversationsExerciseInterface =
    store.hcExercises[householdFinId]
  const honestConversation: HonestConversationsExerciseObj = honestConversations
    ? honestConversations[household.hcExerciseId]
    : null
  const houseContacts: ContactsInterface = store.contact[householdFinId]
    ? store.contact[householdFinId]
    : null
  return {
    household,
    householdFinId,
    honestConversations,
    honestConversation,
    contacts: houseContacts,
    goalActions: goalActionsSelector(store, householdFinId)
  }
}

export default withRouter(connect(mapStateToProps)(ResultsSummary))
