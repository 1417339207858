import React, { CSSProperties, Component } from 'react'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'

import { history } from '../../../store'

import {
  Tile,
  Button
} from '@unitedcapitalfinancialadvisors/finlife-component-library'

import scoreHistoryIcon from '../../assets/images/icons/png/activity.png'
import sendIcon from '../../assets/images/icons/png/send.png'
import plusIcon from '../../assets/images/icons/png/plus-whit.png'
import graphGreen from '../../assets/images/honestConversations/graph__green.png'
import graphRed from '../../assets/images/honestConversations/graph__red.png'
import nullGraph from '../../assets/images/honestConversations/nullGraph.png'
import { isObjEmpty, dateFormat } from '../../helpers'
import ScoreGraph from '../../components/graphs/honestConversations/scoreGraph'
import ProfilePicture from '../../components/layout/profilePicture'

interface SatisfactionScoreProps {
  householdFinId?: string
  contacts: ContactsInterface
  honestConversation: HonestConversationsExerciseObj
  showSendLinkModal(): void
}

interface SatisfactionScoreState {
  activateNextButton: boolean
}

const tileStyle: CSSProperties = {
  marginBottom: '24px',
  alignSelf: 'center',
  padding: 0
}
const btnStyle: CSSProperties = {
  padding: '6px 17px'
}

const contentStyle: CSSProperties = {
  backgroundColor: '#FAFAFA',
  padding: '24px',
  display: 'flex',
  justifyContent: 'center'
}

class SatisfactionScore extends Component<
  SatisfactionScoreProps,
  SatisfactionScoreState
> {
  constructor(props: SatisfactionScoreProps) {
    super(props)
    this.state = {
      activateNextButton: false
    }
  }

  public getTotalScore = (scores: number[]) => {
    return scores?.reduce((perviouseValue, currentValue) => {
      return (perviouseValue += currentValue)
    }, 0)
  }

  public getCurrentScoreAndScoringDate = (
    clientScoringSessions: any,
    scoringSessionId: string
  ) => {
    if (!clientScoringSessions) {
      return null
    }
    const recentScoreSession = clientScoringSessions[scoringSessionId]

    if (!recentScoreSession) {
      return { singleTotalScore: null, currentScoringDate: null }
    }

    const currentScore =
      recentScoreSession.cardScores &&
      Object.keys(recentScoreSession.cardScores).map((key: any) => {
        return recentScoreSession.cardScores[key].score
      })

    const singleTotalScore = this.getTotalScore(currentScore)
    const currentScoringDate = recentScoreSession.createdDate

    return {
      singleTotalScore,
      currentScoringDate
    }
  }

  public getPreviousScore = (
    clientScoringSessions: any,
    scoringSessionId: string
  ) => {
    if (!clientScoringSessions) {
      return null
    }
    const currentScoringDate = this.getCurrentScoreAndScoringDate(
      clientScoringSessions,
      scoringSessionId
    ).currentScoringDate
    const createdDates: string[] = []
    Object.keys(clientScoringSessions).forEach((key) => {
      if (clientScoringSessions[key].createdDate !== currentScoringDate) {
        createdDates.push(clientScoringSessions[key].createdDate)
      }
    })

    let recentDate = createdDates[0]
    createdDates.filter((date) => {
      if (date > recentDate) recentDate = date
    })
    let previousSession: any
    Object.keys(clientScoringSessions).forEach((key) => {
      if (clientScoringSessions[key].createdDate === recentDate) {
        previousSession = clientScoringSessions[key]
      }
    })
    if (previousSession?.cardScores?.length > 0) {
      const previouScores: number[] = []
      Object.keys(previousSession.cardScores).forEach((key: any) => {
        previouScores.push(previousSession.cardScores[key].score)
      })
      return this.getTotalScore(previouScores)
    } else return null
  }

  public tileHeader = () => {
    return (
      <div className='hc-satisfaction__header'>
        <div className='hc-satisfaction__header-left'>SATISFACTION SCORE</div>
        {this.tileHeaderRight()}
      </div>
    )
  }

  public gotoAddScore = () => {
    const { honestConversation, householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/honestConversations/newScore/${honestConversation.id}`
    )
  }

  public gotoScoreHistory = () => {
    const { householdFinId, honestConversation } = this.props
    if (honestConversation) {
      history.push(
        `/households/${householdFinId}/honestConversations/meetings/${honestConversation.id}/scores`
      )
    }
  }

  public tileHeaderRight = () => {
    return (
      <div className='hc-satisfaction__header-right'>
        <div
          className='hc-satisfaction__header-icon'
          onClick={this.gotoScoreHistory}>
          <img src={scoreHistoryIcon} alt='score history' />
          Score History
        </div>
        <div
          className='hc-satisfaction__header-icon'
          onClick={this.props.showSendLinkModal}>
          <img src={sendIcon} alt='resend' />
          Resend
        </div>
        <Button btnStyle={btnStyle} onClick={this.gotoAddScore}>
          <img src={plusIcon} alt='add score' />
          Add Score
        </Button>
      </div>
    )
  }

  public renderGraph = (score: number) => {
    return (
      <div className='hc-satisfaction__content-score-graph'>
        {score ? (
          <ScoreGraph score={score} />
        ) : (
          <img src={nullGraph} alt='scores' />
        )}
      </div>
    )
  }

  public renderContactCard = (
    contact: ContactObj,
    avgScore: number,
    prevAvgScore: number,
    scoringDate: Date,
    primary: boolean
  ) => {
    if (!isObjEmpty(contact)) {
      return (
        <div className='hc-satisfaction__content-card'>
          <div className='hc-wrapper--row'>
            <ProfilePicture
              firstName={contact.firstName}
              lastName={contact.lastName}
              photo={contact.photo}
              isPrimary={primary}
              pictureSize={48}
            />
            <div className='hc-wrapper--col'>
              <p className='hc-satisfaction__content-name'>
                {contact.firstName + ' ' + contact.lastName}
              </p>
              <p className='hc-satisfaction__content-date'>
                {avgScore ? dateFormat(scoringDate) : 'No scores added'}
              </p>
            </div>
            <div className='hc-satisfaction__content-icon'>
              {avgScore && prevAvgScore ? (
                <img
                  src={avgScore >= prevAvgScore ? graphGreen : graphRed}
                  alt='score average'
                />
              ) : null}
            </div>
          </div>
          {this.renderGraph(avgScore)}
        </div>
      )
    } else return null
  }

  public render() {
    const { honestConversation, contacts } = this.props
    const {
      activeScoringSessionPrimary,
      activeScoringSessionSecondary
    } = honestConversation
    const primaryContactCurrentScoreAndSoringDate = this.getCurrentScoreAndScoringDate(
      honestConversation.clientScoringSessions[contacts?.primary?.id],
      activeScoringSessionPrimary
    )
    const primaryContactPrevScore = this.getPreviousScore(
      honestConversation.clientScoringSessions[contacts?.primary?.id],
      activeScoringSessionPrimary
    )
    const secondaryContactCurrentScoreAndSoringDate = contacts?.secondary
      ? this.getCurrentScoreAndScoringDate(
          honestConversation.clientScoringSessions[contacts?.secondary?.id],
          activeScoringSessionSecondary
        )
      : null
    const secondaryContactPrevScore = contacts?.secondary
      ? this.getPreviousScore(
          honestConversation.clientScoringSessions[contacts?.secondary?.id],
          activeScoringSessionSecondary
        )
      : null

    return (
      <Tile
        tileStyle={tileStyle}
        header={this.tileHeader()}
        contentStyle={contentStyle}>
        <div className='hc-satisfaction__content'>
          {this.renderContactCard(
            contacts && contacts.secondary,
            secondaryContactCurrentScoreAndSoringDate &&
              Math.round(
                secondaryContactCurrentScoreAndSoringDate.singleTotalScore / 5
              ),
            Math.round(secondaryContactPrevScore / 5),
            secondaryContactCurrentScoreAndSoringDate &&
              secondaryContactCurrentScoreAndSoringDate.currentScoringDate,
            false
          )}
          {this.renderContactCard(
            contacts?.primary,
            primaryContactCurrentScoreAndSoringDate &&
              Math.round(
                primaryContactCurrentScoreAndSoringDate.singleTotalScore / 5
              ),
            Math.round(primaryContactPrevScore / 5),
            primaryContactCurrentScoreAndSoringDate &&
              primaryContactCurrentScoreAndSoringDate.currentScoringDate,
            true
          )}
        </div>
      </Tile>
    )
  }
}

export default SatisfactionScore
