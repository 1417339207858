import React, { Component, Fragment } from 'react'

import Button from '../../components/button'
import GQLClient from '../../helpers/gqlClient'
import {
  GetAdvisorNotificationSettingsQuery,
  SetAdvisorNotificationSettingsQuery
} from '../../helpers/queries'

import {
  AllSetting,
  allSettings,
  allEmailSettings,
  allMobileSettings,
  dependantWebNotificationSettings
} from '../../../objects/settings/clientAdvisor'
import { ToggleRow } from '../../components/settings/toggleRow'
import CheckBox from '../../components/checkbox'
import TableHeader from '../../components/layout/tableHeader'

interface AlertsNotificationsProps {
  userId: string
}
interface AlertsNotificationsState {
  desktop: AllSetting[]
  email: AllSetting[]
  mobile: AllSetting[]
  isNotificationSummaryChecked: boolean
}

export class AlertsNotifications extends Component<
  AlertsNotificationsProps,
  AlertsNotificationsState
> {
  constructor(props: AlertsNotificationsProps) {
    super(props)
    this.state = {
      desktop: [],
      email: [],
      mobile: [],
      isNotificationSummaryChecked: false
    }
  }

  public componentDidMount() {
    this.getCurrentNotificationSettings()
  }

  public getCurrentNotificationSettings = () => {
    const { userId } = this.props
    GQLClient.getClient()
      ?.query({
        query: GetAdvisorNotificationSettingsQuery,
        variables: { userId }
      })
      .then((res: any) => {
        const { nodes } = res.data.allAdvisers
        if (nodes.length > 0) {
          ;['desktop', 'email', 'mobile'].forEach((key) => {
            const data = nodes?.[0]?.[`${key}Subject`]
            if (data && Array.isArray(data)) {
              this.setState(({
                [key]: data
              } as unknown) as Pick<AlertsNotificationsState, keyof AlertsNotificationsState>)
              if (key === 'email' && data.includes('NOTIFICATION_SUMMARY')) {
                this.setState({ isNotificationSummaryChecked: true })
              }
            }
          })
        }
      })
  }

  public toggleNotificationSummary = (assigneeId: string, checked: boolean) => {
    let { email } = this.state
    // If check is false, remove Notification Summary from array
    if (checked === false) {
      email = email.filter((item) => item !== 'NOTIFICATION_SUMMARY')
      // Else set Notification summary
    } else {
      email.push('NOTIFICATION_SUMMARY')
    }
    this.setState({ isNotificationSummaryChecked: checked, email })
  }

  public toggleSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: setting } = event.currentTarget
    const type = setting.startsWith('EMAIL_')
      ? 'email'
      : setting.startsWith('MOBILE_')
      ? 'mobile'
      : 'desktop'
    const settingName = setting
      .replace('EMAIL_', '')
      .replace('MOBILE_', '') as AllSetting

    if (this.state[type].includes(settingName)) {
      if (type === 'desktop' && dependantWebNotificationSettings[settingName]) {
        this.setState((prevState) => {
          const { email: emailState, mobile: mobileState } = prevState
          return {
            desktop: [...prevState[type].filter((s) => !(s === settingName))],
            email: [...emailState.filter((r) => !(r === settingName))],
            mobile: [...mobileState.filter((m) => !(m === settingName))]
          }
        })
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [type]: [...prevState[type].filter((s) => !(s === settingName))]
        }))
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], settingName]
      }))
    }
  }

  public reset = () => {
    this.getCurrentNotificationSettings()
  }

  public save = () => {
    const { userId } = this.props
    const { desktop, email, mobile } = this.state
    GQLClient.getClient()?.mutate({
      mutation: SetAdvisorNotificationSettingsQuery,
      variables: {
        desktopSettings: desktop,
        emailSettings: email,
        mobileSettings: mobile,
        userId
      },
      refetchQueries: [
        {
          query: GetAdvisorNotificationSettingsQuery,
          variables: { userId }
        }
      ]
    })
    // TODO add a toast or something that the save was successful
  }

  public settingsRow = (setting: AllSetting) => {
    const { desktop, email, mobile } = this.state
    return (
      <ToggleRow
        className='a-settings__row--content'
        key={setting}
        disabled={false}
        handler={this.toggleSetting}
        settingName={setting}
        desktopActive={desktop.includes(setting)}
        emailActive={email.includes(setting)}
        emailSupported={allEmailSettings.includes(setting)}
        mobileActive={mobile.includes(setting)}
        mobileSupported={allMobileSettings.includes(setting)}
      />
    )
  }

  public renderNotificationSummarySetting = () => {
    const { isNotificationSummaryChecked } = this.state
    return (
      <div className='a-settings__checkbox-w'>
        <CheckBox
          style={{ width: 'auto' }}
          checkedBorder={true}
          checked={isNotificationSummaryChecked}
          onChange={this.toggleNotificationSummary}
        />
        <span className='a-settings__summary-text'>
          I want to receive a Daily Notification Summary by email
        </span>
      </div>
    )
  }

  public render() {
    return (
      <Fragment>
        <p className='a-settings__header-text'>Alerts and Notifications</p>
        <p className='a-settings__second-header-text'>
          Choose your in-app notification preferences.
        </p>
        {window._env_.REACT_APP_NOTIFICATION_SUMMARY_SETTING_ENABLED &&
          this.renderNotificationSummarySetting()}
        <TableHeader>
          <div className='a-settings__header-w'>
            <div>
              <span>NOTIFICATION TYPE</span>
            </div>
            <div className='a-settings__row-toggle-w'>
              <span>MOBILE</span>
              <span>EMAIL</span>
              <span>WEB</span>
            </div>
          </div>
        </TableHeader>
        {allSettings.map((setting) => {
          return this.settingsRow(setting)
        })}

        <div className='a-settings__row--btns'>
          <Button clear={true} onClick={this.reset}>
            Cancel
          </Button>
          <Button primary={true} onClick={this.save}>
            Save
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default AlertsNotifications
