import React, { Component, MouseEvent } from 'react'
import onClickOutside from 'react-onclickoutside'
import { statusType } from '../../../objects/lifeActions'
import { getStatusIcon } from '../../helpers/goals'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg'

export interface StatusSelectProps {
  currentStatus: string
  handleStatusSelect(status: string): void
}

interface StatusSelectState {
  currentStatus: string
}

export class StatusSelect extends Component<
  StatusSelectProps,
  StatusSelectState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      currentStatus: this.props.currentStatus
    }
  }

  public handleClickOutside = () => {
    this.props.handleStatusSelect(this.state.currentStatus)
  }

  public statusSelect = (e: MouseEvent<HTMLElement>) => {
    const statusId = e && e.currentTarget && e.currentTarget.id
    this.setState({ currentStatus: statusId })
    this.props.handleStatusSelect(statusId)
  }

  public renderListItem = (option: string, i: number) => {
    const { currentStatus } = this.state
    return (
      <li
        key={`${option}${i}`}
        onClick={this.statusSelect}
        id={statusType[option]}
        className={
          currentStatus === statusType[option]
            ? 'status-select__menu-item status-select__menu-item--active'
            : 'status-select__menu-item'
        }>
        <div
          className={`status-select__icon ${getStatusIcon(statusType[option])}`}
        />
        <label>{statusType[option]}</label>
        {currentStatus === statusType[option] ? <CheckIcon /> : null}
      </li>
    )
  }

  public render() {
    const statusList = Object.keys(
      statusType
    ).map((option: string, i: number) => this.renderListItem(option, i))
    return (
      <div className='status-select__menu'>
        <ul>{statusList}</ul>
      </div>
    )
  }
}

export default onClickOutside(StatusSelect)
