import * as React from 'react'
import Tile from '../../components/layout/tile'
import Offer from './offer'
import { GlobalState } from '../../../reducers'
import {
  marketplaceOfferRequestInterface,
  marketplaceOfferObj
} from '../../../objects/marketplaceOffers'
import { withRouter } from 'react-router'
import { connect, Dispatch } from 'react-redux'

import * as marketplaceActions from '../../../actions/marketplaceOffers'
import InnerContainer from '../../components/layout/innerContainer'
import NullState from '../../components/nullState'
import { ContentHeader } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import resourcesNullState from '../../assets/images/nullStates/ResourcesNullstate960.png'
import resourcesNullStateSm from '../../assets/images/nullStates/ResourcesNullstate400.png'

export interface MarketplaceProps {
  dispatch?: Dispatch<GlobalState>
  marketplaceOffers: marketplaceOfferRequestInterface
  householdFinId: string
  meetingMode: boolean
}

export class Marketplace extends React.Component<MarketplaceProps> {
  public componentDidMount() {
    const { marketplaceOffers, householdFinId, dispatch } = this.props

    if (!marketplaceOffers || Object.keys(marketplaceOffers).length === 0) {
      // Get offers only on first call
      dispatch(marketplaceActions.getMarketplaceOffers(householdFinId))
    }
  }

  public getDisplayableOffers = () => {
    const { marketplaceOffers, meetingMode } = this.props

    if (!marketplaceOffers || Object.keys(marketplaceOffers).length === 0) {
      return null
    }

    const offers = Object.keys(marketplaceOffers).reduce(
      (acc: marketplaceOfferObj[], offerId: string) => {
        if (meetingMode) {
          return marketplaceOffers[offerId]?.enabled
            ? [...acc, marketplaceOffers[offerId]]
            : acc
        } else {
          return [...acc, marketplaceOffers[offerId]]
        }
      },
      []
    )

    return offers.map((offer: marketplaceOfferObj) => (
      <Offer
        key={offer.id}
        offer={offer}
        householdFinId={this.props.householdFinId}
        brandTitle='RESOURCES'
      />
    ))
  }

  public nullElement = () => {
    const nullText = (
      <span>
        No Resource offers.
        <br /> There are no offers available.
      </span>
    )
    const nullImage = (
      <picture style={{ width: '100%' }}>
        <source srcSet={resourcesNullStateSm} media='(max-width: 400px)' />
        <source srcSet={resourcesNullState} />
        <img alt='No resource offers' src={resourcesNullState} />
      </picture>
    )
    return <NullState text={nullText} multiImageSrc={nullImage} />
  }

  public nullState = () => {
    return (
      <Tile largeHeader={true} headerBorder={false}>
        {this.nullElement()}
      </Tile>
    )
  }

  // "Marketplace" title has been renamed "Resources"
  // but keeping the object name Marketplace
  public render() {
    const displayableOffers = this.getDisplayableOffers()

    return (
      <InnerContainer>
        <ContentHeader title='Resources' />
        {displayableOffers?.length > 0 ? (
          <div className='marketplace__w'>{displayableOffers}</div>
        ) : (
          this.nullState()
        )}
      </InnerContainer>
    )
  }
}
const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    marketplaceOffers: store.marketplaceOffers[match.params.householdFinId],
    meetingMode: store.meetingMode.meetingMode
  }
}

export default withRouter(connect(mapStateToProps)(Marketplace))
