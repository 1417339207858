import React from 'react'
import LoaderIcon from '../assets/images/loader.gif'

export interface LoaderModalProps {
  centerText?: string
}

class LoaderModal extends React.Component<LoaderModalProps> {
  public render() {
    const { centerText } = this.props
    const centerTextDisplay = centerText || 'Syncing'

    return (
      <div className='modal__overlay'>
        <div className='modal__box-w'>
          <div className='modal__graphic'>
            <div className='modal__loader'>
              <div className='modal__loader-center-text'>
                {centerTextDisplay}
              </div>
              <div>
                <img src={LoaderIcon} alt='loading' />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoaderModal
