import React from 'react'
import ReactTooltip from 'react-tooltip'

interface DotOvalProps {
  backgroundColor: string
  title: string
  value: string
  display?: string
  toolTipLabel?: string
}

export const DotOval = ({
  title,
  value,
  backgroundColor,
  display,
  toolTipLabel
}: DotOvalProps) => {
  return (
    <div className='dot-oval'>
      <div style={{ backgroundColor, display }} className='dot-oval__circle' />
      <div className='dot-oval__keys' data-tip={toolTipLabel}>
        {title}
      </div>
      <div className='dot-oval__values'>{value}</div>
      {toolTipLabel && <ReactTooltip />}
    </div>
  )
}

export const dotOvalList = (ovalColors: string[], ovalData: {}) => {
  const list: React.ReactElement[] = []
  const sorted = Object.keys(ovalData).sort()
  sorted.map((value: any, key: any) => {
    if (isNaN(ovalData?.[value])) {
      ovalData[value] = 0
    }
    list.push(
      <DotOval
        backgroundColor={ovalColors[key]}
        key={key}
        title={value}
        value={`${ovalData[value]}%`}
      />
    )
  })

  return list
}
