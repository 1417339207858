import React, { Component, Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import FilterCustodians from './filterStrategies/filterCustodians'
import FilterMinimum from './filterStrategies/filterMinimum'
import FilterManagers from './filterStrategies/filterManagers'
import { clearAllFilters } from '../../../../actions/strategySearch'
import {
  StrategySearchFilterObj,
  FilterDropdownTypes
} from '../../../../objects/strategySearch'
import { IMStrategiesObj } from '../../../../objects/strategies'
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/icons/arrow_right.svg'
import FilterAccountType from './filterStrategies/filterAccountType'
import FilterESG from './filterStrategies/filterESG'
import { InstitutionObj } from '../../../../objects/institution'

interface StrategyFiltersProps {
  dispatch: Dispatch<GlobalState>
  institution: InstitutionObj
  filters: StrategySearchFilterObj
  strategies: IMStrategiesObj[]
  filterOpenObj: any
  toggleFilterDropdown(label?: string): void
}

export class StrategyFilters extends Component<StrategyFiltersProps> {
  public filterButton = (
    label: FilterDropdownTypes,
    isOpen: boolean,
    activeFiltersCount: number,
    dropDownFilterElement?: React.ReactElement
  ) => {
    const toggleFilterHandler = () => this.props.toggleFilterDropdown(label)
    const hasActiveFilters = activeFiltersCount > 0
    return (
      <div
        onClick={toggleFilterHandler}
        className={`${
          hasActiveFilters
            ? 'strategy-search__filter strategy-search__filter--active-filters'
            : 'strategy-search__filter'
        } ${isOpen && 'strategy-search__filter--open'}`}>
        {label}
        {hasActiveFilters && (
          <div className='strategy-search__filter-count'>
            ({activeFiltersCount})
          </div>
        )}
        <ArrowRightIcon
          width='15px'
          height='15px'
          viewBox='0 -2 9 20'
          className={`${
            hasActiveFilters
              ? 'strategy-search__filter-arrow strategy-search__filter-arrow--active-filters'
              : 'strategy-search__filter-arrow'
          }
            ${isOpen && 'strategy-search__filter-arrow--open'}`}
        />
        {dropDownFilterElement && isOpen ? dropDownFilterElement : null}
      </div>
    )
  }

  public globalClearButton = () => {
    const { filters } = this.props
    const handleClearAll = () => {
      this.props.dispatch(clearAllFilters())
      this.props.toggleFilterDropdown()
    }

    const count = () => {
      let total = 0
      Object.keys(filters).forEach((filter: string) => {
        total = total + filters[filter]?.length
      })
      return total
    }

    const counter = (
      <div className='strategy-search__filter-buttons-counter'>{count()}</div>
    )
    if (count() > 0) {
      return (
        <div
          onClick={handleClearAll}
          className='strategy-search__filter-buttons-clear'>
          Clear
          {counter}
        </div>
      )
    } else return null
  }

  public render() {
    const { filterOpenObj, filters } = this.props
    const { strategiesFilterOpen } = filterOpenObj
    return (
      <Fragment>
        <div className='strategy-search__filter-header'>
          <div className='strategy-search__filters-w'>
            {this.filterButton(
              'Select Custodians',
              filterOpenObj.custodiansFilterOpen,
              filters.custodianSelection?.length,
              <FilterCustodians
                toggleFilterDropdown={this.props.toggleFilterDropdown}
              />
            )}
            {this.filterButton(
              'Select Strategies',
              strategiesFilterOpen,
              filters.managedStrategies?.length + filters.assetClassL2?.length
            )}
            {this.filterButton(
              'Select Managers',
              filterOpenObj.managersFilterOpen,
              filters.manager?.length,
              <FilterManagers
                toggleFilterDropdown={this.props.toggleFilterDropdown}
              />
            )}
            {this.filterButton(
              'Account Minimum',
              filterOpenObj.accountFilterOpen,
              filters.investmentMinimum?.length,
              <FilterMinimum
                toggleFilterDropdown={this.props.toggleFilterDropdown}
              />
            )}
            {this.filterButton(
              'Account Type',
              filterOpenObj.taxStatusFilterOpen,
              filters.taxStatus?.length,
              <FilterAccountType
                toggleFilterDropdown={this.props.toggleFilterDropdown}
              />
            )}
            {this.filterButton(
              'ESG',
              filterOpenObj.esgFilterOpen,
              filters.esg?.length,
              <FilterESG
                toggleFilterDropdown={this.props.toggleFilterDropdown}
              />
            )}
          </div>
          <div className='strategy-search__filter-buttons-w'>
            {this.globalClearButton()}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    institution: store.institution,
    filters: store.strategySearch.filter,
    strategies: store.strategySearch.strategies
  }
}

export default connect(mapStateToProps)(StrategyFilters)
