import React, { Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { addToast } from '../../../../actions/toasts'
import {
  editFinancialGoal,
  addFinancialGoal
} from '../../../../actions/financialGoals'
import { updateGoalRequest } from '../../../../actions/goalRequests'
import {
  FinancialGoalObj,
  PlanningSoftwareType
} from '../../../../objects/financialGoal'
import { GoalRequestObj } from '../../../../objects/goalRequests'

import TableRow from '../../../components/layout/tableRow'
import ActionDropdown from '../../../components/actionDropdown'
import DeleteGoalModal from './deleteGoalModal'
import GoalModal from './goalModal'
import GoalChange from './goalChange'
import {
  PreviousGoalName,
  DisplayGoalName,
  PreviousGoalType,
  Description,
  PreviousGoalDate,
  DisplayDate,
  PreviousAmount,
  DisplayAmount
} from './goalDisplay'

import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg'
import { isEqual } from '../../../helpers'

interface GoalRowProps {
  userId: string
  householdFinId: string
  dispatch: Dispatch<GlobalState>
  planningSoftware: PlanningSoftwareType
  goal: FinancialGoalObj
  showActionColumn: boolean
  displayPriorities: boolean
  changeModalOpen: boolean
}

interface GoalRowState {
  editModalOpen: boolean
  deleteModalOpen: boolean
  changeModalOpen: boolean
  goalDisplayObj: FinancialGoalObj
  goalRequest: GoalRequestObj
  requestType: string
}

class GoalRow extends Component<GoalRowProps, GoalRowState> {
  constructor(props: GoalRowProps) {
    super(props)
    this.state = {
      editModalOpen: false,
      deleteModalOpen: false,
      changeModalOpen: props.changeModalOpen,
      goalDisplayObj: props.goal,
      goalRequest: props.goal.goalRequest ? props.goal.goalRequest : null,
      requestType: null
    }
  }

  public componentDidMount() {
    this.mapGoal()
  }

  public componentDidUpdate(prevProps: any) {
    if (!isEqual(prevProps?.goal, this.props?.goal)) {
      this.mapGoal()
    }
    if (prevProps.changeModalOpen !== this.props.changeModalOpen) {
      this.setState({
        ...this.state,
        changeModalOpen: this.props.changeModalOpen
      })
    }
  }

  public mapGoal = () => {
    const { goal } = this.props
    if (goal.goalRequest && Object.keys(goal.goalRequest).length > 0) {
      this.mapGoalRequest()
    } else {
      this.setState({
        goalDisplayObj: goal
      })
    }
    if (Object.keys(goal).indexOf('requestType') >= 0) {
      this.mapGoalRequest(goal)
    }
  }

  public mapGoalRequest = (newGoal?: any) => {
    const { goal } = this.props
    const goalRequest = newGoal || goal.goalRequest
    if (goalRequest) {
      const displayAmount = this.formatAmount(
        goalRequest.proposedAmount
          ? goalRequest.proposedAmount.toString()
          : '0',
        goalRequest.proposedAmountType
      )
      const goalSetter: FinancialGoalObj = {
        id: goalRequest.id,
        name: goalRequest.proposedName,
        type: goalRequest.proposedGoalType,
        owner: goalRequest.proposedGoalOwnerId,
        startDate: goalRequest.proposedStartOfGoal,
        endDate: goalRequest.proposedEndOfGoal,
        amount: displayAmount,
        amountType: goalRequest.proposedAmountType,
        annualIncrease: goalRequest.proposedAnnualIncrease,
        comment: goalRequest.comment,
        needsWantsWishes: goalRequest.needsWantsWishes
      }
      this.setState({
        goalDisplayObj: goalSetter,
        goalRequest,
        requestType: goalRequest.requestType
      })
    }
  }

  public toggleEditModal = () => {
    this.setState({ editModalOpen: !this.state.editModalOpen })
  }

  public toggleDeleteModal = () => {
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen })
  }

  public editGoal() {
    return (
      <Fragment>
        <EditIcon />
        <span className='action-dropdown__item-label'>Edit Plan Input</span>
      </Fragment>
    )
  }

  public deleteGoal() {
    return (
      <Fragment>
        <DeleteIcon />
        <span className='action-dropdown__item-label'>Delete Plan Input</span>
      </Fragment>
    )
  }

  public submitGoal = async (goal: FinancialGoalObj, request: any) => {
    const { householdFinId, dispatch, userId } = this.props
    const completeRequest = {
      ...request,
      status: 'COMPLETED',
      reviewedBy: userId
    }

    if (completeRequest?.requestType === 'CREATE') {
      await dispatch(
        updateGoalRequest(householdFinId, completeRequest.id, completeRequest)
      )
      await dispatch(addFinancialGoal(goal, householdFinId))
    } else if (completeRequest?.requestType === 'EDIT') {
      await dispatch(
        updateGoalRequest(householdFinId, completeRequest.id, completeRequest)
      )
      await dispatch(editFinancialGoal(goal, householdFinId))
    } else {
      await dispatch(editFinancialGoal(goal, householdFinId))
    }

    this.toggleEditModal()
    addToast({
      message: 'Goal successfully edited',
      backgroundColor: '#D9F2B6'
    })
  }

  public toggleGoalChange = () => {
    this.setState({
      changeModalOpen: !this.state.changeModalOpen
    })
  }

  public displayPendingButton = (): JSX.Element => {
    const { goalRequest } = this.state
    if (goalRequest && goalRequest.requestType) {
      return (
        <div
          className='goal-row__tag goal-row__tag-pending-btn'
          onClick={this.toggleGoalChange}>
          Pending
        </div>
      )
    } else return null
  }

  public renderPriorityTag = (): JSX.Element => {
    const { goal } = this.props
    if (goal && goal.needsWantsWishes) {
      const tagType = goal.needsWantsWishes.toLowerCase()
      const formatTag = goal.needsWantsWishes.slice(0, 4)
      return (
        <div
          className={`goal-row__tag goal-row__tag-priority goal-row__tag-priority--${tagType}`}>
          {formatTag}
        </div>
      )
    } else {
      return <div className='goal-row__tag goal-row__tag-priority' />
    }
  }

  public isEditType = (goal: FinancialGoalObj): boolean => {
    return goal.goalRequest && goal.goalRequest.requestType !== 'DELETE'
  }

  public formatAmount = (amount: string, amountType: string) => {
    const amountTypeFormat = {
      Dollar: (value: string) => {
        return value.replace(/\$/g, '')
      },
      Percent: (value: string) => {
        return value.replace(/\% ?/g, '')
      }
    }
    return amount && amountType
      ? amountTypeFormat[amountType](amount, amountType)
      : ''
  }

  public toastDispatch = (message: string) => {
    const { dispatch } = this.props
    dispatch(
      addToast({
        icon: EditIcon,
        message,
        backgroundColor: '#D9F2B6'
      })
    )
  }

  public completeRequest = async (request: GoalRequestObj) => {
    const { householdFinId, dispatch, userId } = this.props
    request.reviewedBy = userId
    await dispatch(updateGoalRequest(householdFinId, request.id, request))
    this.toggleGoalChange()
    this.toastDispatch('Plan input change request completed')
  }

  public deleteStyle = (cssType: string): string => {
    const isDelete = this.state.requestType === 'DELETE'
    let style = cssType
    if (isDelete) {
      style +=
        cssType === 'goal-row__tag'
          ? ' goal-row__pending-change-type'
          : ' goal-row__pending-change'
    }
    return style
  }

  public render() {
    const {
      goal,
      planningSoftware,
      showActionColumn,
      displayPriorities
    } = this.props
    const { goalDisplayObj } = this.state
    const dropdownActions = [
      {
        element: this.editGoal(),
        onClick: this.toggleEditModal
      },
      {
        element: this.deleteGoal(),
        onClick: this.toggleDeleteModal
      }
    ]
    return (
      <TableRow>
        <div className='goal-row__table-name'>
          <PreviousGoalName
            goal={goal}
            mappedGoal={goalDisplayObj}
            className='goal-row__tag goal-row__pending-change'
          />
          <PreviousGoalType
            goal={goal}
            mappedGoal={goalDisplayObj}
            className='goal-row__tag goal-row__pending-change-type'
          />
          <DisplayGoalName
            goal={goalDisplayObj}
            goalRequest={this.state.goalRequest}
            className='goal-row__table-name--text'
          />
        </div>
        <Description goal={goalDisplayObj} />
        {planningSoftware === 'moneyguidepro' && displayPriorities ? (
          <div className='goal-row__table-priority'>
            <div className='goal-row__table-buttons-pending'>
              {this.renderPriorityTag()}
            </div>
          </div>
        ) : null}
        <div className='goal-row__table-period'>
          <PreviousGoalDate goal={goal} mappedGoal={goalDisplayObj} />
          <DisplayDate
            goal={goalDisplayObj}
            goalRequest={this.state.goalRequest}
          />
        </div>
        <div className='goal-row__table-amount'>
          <PreviousAmount goal={goal} mappedGoal={goalDisplayObj} />
          <DisplayAmount
            goal={goalDisplayObj}
            goalRequest={this.state.goalRequest}
          />
        </div>
        {showActionColumn || !planningSoftware ? (
          <div className='goal-row__table-actions'>
            {showActionColumn ? (
              <div className='goal-row__table-actions-pending'>
                {this.displayPendingButton()}
              </div>
            ) : null}
            {!planningSoftware ? (
              <div className='goal-row__table-actions-pending'>
                <ActionDropdown actions={dropdownActions} />
              </div>
            ) : null}
          </div>
        ) : null}
        {this.renderGoalModals()}
      </TableRow>
    )
  }

  public renderGoalModals = (): JSX.Element => {
    const { householdFinId, goal, userId, dispatch } = this.props
    const {
      name,
      startDate,
      endDate,
      amount,
      type,
      owner,
      amountType
    } = this.state.goalDisplayObj
    const {
      editModalOpen,
      deleteModalOpen,
      changeModalOpen,
      goalRequest
    } = this.state

    if (changeModalOpen) {
      return (
        <GoalChange
          name={name}
          startDate={startDate}
          endDate={endDate}
          amount={amount}
          type={type}
          owner={owner}
          amountType={amountType}
          goalRequest={goalRequest}
          goal={this.props.goal}
          mappedGoal={this.state.goalDisplayObj}
          closeModal={this.toggleGoalChange}
          completeRequest={this.completeRequest}
        />
      )
    }
    if (editModalOpen) {
      return (
        <GoalModal
          title='Edit Plan Input'
          submitText='Save'
          submit={this.submitGoal}
          closeModal={this.toggleEditModal}
          goal={goal}
          goalObj={this.state.goalDisplayObj}
        />
      )
    }
    if (deleteModalOpen) {
      return (
        <DeleteGoalModal
          householdFinId={householdFinId}
          userId={userId}
          closeModal={this.toggleDeleteModal}
          dispatch={dispatch}
          goal={this.props.goal}
        />
      )
    }
    return null
  }
}

export default GoalRow
