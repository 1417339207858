import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import Button from '../../components/button'
import { FileObj } from '../../../objects/folder'

export interface RestoreModalProps {
  file: FileObj
  restoreFn(id: string, archived: boolean): void
  closeModal(file?: FileObj): void
}

class RestoreFileModal extends React.Component<RestoreModalProps> {
  constructor(props: any) {
    super(props)
  }

  public restoreFn = () => {
    const { file, restoreFn } = this.props
    restoreFn(file.id, false)
  }

  public closeFn = () => {
    this.props.closeModal(null)
  }

  public render() {
    return (
      <Modal icon={DeleteIcon} title='RESTORE FILE' closeModal={this.closeFn}>
        <div className='vault-modal__content'>
          Are you sure you want to restore this file?
          <div className='vault-modal__buttons'>
            <Button clear={true} onClick={this.closeFn}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.restoreFn}>
              Restore
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default RestoreFileModal
