import React, { Component, SyntheticEvent } from 'react'
import onClickOutside from 'react-onclickoutside'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow_right.svg'
import FilterDropdownRow from './filterDropdownRow'

import { Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'

interface FilterDropdownProps {
  dispatch?: Dispatch<GlobalState>
  title: string
  updateSelectedItems(selectedItems: string[]): void
  options: { name: string; id: string }[]
  selectedOptions: string[]
}

interface FilterDropdownState {
  filterOpen: boolean
}

class FilterDropdown extends Component<
  FilterDropdownProps,
  FilterDropdownState
> {
  constructor(props: FilterDropdownProps) {
    super(props)
    this.state = {
      filterOpen: false
    }
  }
  public handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  public toggleFilterDropdown = () => {
    this.setState({ filterOpen: !this.state.filterOpen })
  }

  public handleClickOutside = () => {
    this.setState({ filterOpen: false })
  }

  public handleCheckboxToggle = (selectedOption?: string) => () => {
    const { selectedOptions, updateSelectedItems } = this.props
    const newSelectedOptions = [...selectedOptions]

    if (newSelectedOptions.includes(selectedOption)) {
      newSelectedOptions.splice(newSelectedOptions.indexOf(selectedOption), 1)
    } else {
      newSelectedOptions.push(selectedOption)
    }
    updateSelectedItems(newSelectedOptions)
  }

  public dropDownList = (
    propArray: { name: string; id: string }[],
    propStateList: string | string[]
  ) => {
    return (
      <div
        onClick={this.handleStopPropagation}
        className='task-filter-dropdown__selection__dropdown-filter'>
        <div className='task-filter-dropdown__selection__dropdown-filter-options--sm'>
          {propArray.map((el: { name: string; id: string }, index: number) => {
            const isChecked = propStateList && propStateList.includes(el.id)
            return (
              <div key={index}>
                <FilterDropdownRow
                  key={index}
                  isChecked={isChecked}
                  toggleBox={this.handleCheckboxToggle}
                  option={el}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  public render() {
    const isOpen = this.state.filterOpen
    const { options, title, selectedOptions } = this.props
    const selectedInstitutionLabels = selectedOptions.map((selectedOption) => {
      return options.find((option) => option.id === selectedOption)?.name
    })
    const dropDownLabels = selectedInstitutionLabels.toString()
    const hasSelections = selectedOptions.length > 0
    return (
      <div>
        <div className='task-filter-dropdown__list-body'>
          <div className='task-filter-sub-header'>{title}</div>
          <div
            onClick={this.toggleFilterDropdown}
            id='task-filter-dropdown'
            className='task-filter-dropdown__selection__filter task-filter-dropdown__selection'>
            <div
              className={`${'task-filter-dropdown__selection__header'} ${isOpen &&
                'task-filter-dropdown__selection__header--open'}`}>
              {hasSelections ? dropDownLabels : 'Select'}
            </div>
            <ArrowRightIcon
              width='10px'
              height='10px'
              viewBox='0 -2 9 20'
              className={`task-filter-dropdown__selection__arrow`}
            />
            {isOpen ? this.dropDownList(options, selectedOptions) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default onClickOutside(FilterDropdown)
