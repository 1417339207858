import React, { Component } from 'react'
// TODO Remove this hideous package
import * as reactTabs from 'react-tabs'
import HonestConversations from './honestConversation'
import MoneyMind from './moneyMind'
import ViewFinder from './viewFinder'

class ClientTabs extends Component {
  constructor(props: {}) {
    super(props)
  }

  public render() {
    return (
      <div>
        <reactTabs.Tabs>
          <reactTabs.TabList>
            <reactTabs.Tab>Viewfinder</reactTabs.Tab>
            <reactTabs.Tab>HonestConversations</reactTabs.Tab>
            <reactTabs.Tab>MoneyMind</reactTabs.Tab>
          </reactTabs.TabList>

          <reactTabs.TabPanel>
            <ViewFinder />
          </reactTabs.TabPanel>
          <reactTabs.TabPanel>
            <HonestConversations />
          </reactTabs.TabPanel>
          <reactTabs.TabPanel>
            <MoneyMind />
          </reactTabs.TabPanel>
        </reactTabs.Tabs>
      </div>
    )
  }
}

export default ClientTabs
