import * as actions from '../actions/taskFilter'
import { TaskFilterObj } from '../objects/taskFilter'

const initState: TaskFilterObj = {
  showLinkedTaskType: true,
  showNativeTaskType: true,
  selectedStatuses: [],
  selectedAssignees: [],
  statuses: [],
  assignees: []
}

const TaskFilter = (state = initState, action: any): TaskFilterObj => {
  switch (action.type) {
    case actions.SET_TASK_FILTERS:
      const {
        showLinkedTaskType,
        showNativeTaskType,
        selectedStatuses,
        selectedAssignees
      } = action.payload
      return {
        ...state,
        showLinkedTaskType,
        showNativeTaskType,
        selectedStatuses,
        selectedAssignees
      }
    default:
      return state
  }
}

export default TaskFilter
