import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import * as actions from '../../../../actions/financialGoals'
import {
  FundedScoreObj,
  PlanningSoftwareType
} from '../../../../objects/financialGoal'
import FundedScoreHistoryRow from './fundedScoreHistoryRow'

interface FundedScoreHistoryListProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  planningSoftware: PlanningSoftwareType
  fundedScores: FundedScoreObj[]
  showModal(fundedScore: FundedScoreObj): void
}

interface FundedScoreHistoryListState {
  tmp: string
}

class FundedScoreHistoryList extends Component<
  FundedScoreHistoryListProps,
  FundedScoreHistoryListState
> {
  constructor(props: FundedScoreHistoryListProps) {
    super(props)
    this.state = {
      tmp: ''
    }
  }

  public showHideGuideBook = async (
    fundedScore: FundedScoreObj,
    inGuidebook: boolean
  ) => {
    const { householdFinId } = this.props
    await this.props.dispatch(
      actions.updatedFundedScore(
        fundedScore.id,
        { ...fundedScore, inGuidebook },
        householdFinId
      )
    )
  }

  public renderRows = (): JSX.Element[] => {
    const { fundedScores, planningSoftware, showModal } = this.props
    const rows: JSX.Element[] = []
    fundedScores.forEach((fundedScore, index) => {
      rows.push(
        fundedScore && (
          <FundedScoreHistoryRow
            index={index}
            key={`${index}-funded-history-row`}
            keyId={`${index}-funded-history`}
            planningSoftware={planningSoftware}
            fundedScore={fundedScore}
            showhideFunc={this.showHideGuideBook}
            showModal={showModal}
          />
        )
      )
    })

    return rows
  }

  public render() {
    return <div>{this.renderRows()}</div>
  }
}

export default connect()(FundedScoreHistoryList)
