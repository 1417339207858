import api from '../v3/helpers/api'
import { excludeUndefined } from '../v3/helpers/netWorth'
import { ClientAccount } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
import { AxiosResponse } from 'axios'
import { ClientAccountObj } from '../objects/clientAccount'

export const FETCH_CLIENT_ACCOUNTS: string = 'FETCH_CLIENT_ACCOUNTS'
export const INITIALIZE_CLIENT_ACCOUNT: string = 'INITIALIZE_CLIENT_ACCOUNT'
export const UPDATE_CLIENT_ACCOUNT: string = 'UPDATE_CLIENT_ACCOUNT'
export const FETCH_INVESTMENT_ACCOUNTS: string = 'FETCH_INVESTMENT_ACCOUNTS'
export const CREATE_CLIENT_ACCOUNT: string = 'CREATE_CLIENT_ACCOUNT'
export const DELETE_CLIENT_ACCOUNT: string = 'DELETE_CLIENT_ACCOUNT'
export const FETCH_YODLEE_CLIENT_ACCOUNTS: string =
  'FETCH_YODLEE_CLIENT_ACCOUNTS'
export const GET_CLIENT_ACCOUNTS_ERROR_EMAIL: string =
  'GET_CLIENT_ACCOUNTS_ERROR_EMAIL'
export const SEND_CLIENT_ACCOUNTS_ERROR_EMAIL: string =
  'SEND_CLIENT_ACCOUNTS_ERROR_EMAIL'
export const SET_YODLEE_SYNC_ATTEMPT = 'SET_YODLEE_SYNC_ATTEMPT'
export const MERGE_CLIENT_ACCOUNTS = 'MERGE_CLIENT_ACCOUNTS'
export const UNMERGE_CLIENT_ACCOUNTS = 'UNMERGE_CLIENT_ACCOUNTS'
interface ClientAccountResponse extends ClientAccount.Response {
  householdFinId: string
}

interface MergeRequest {
  accountsToBeMerged: string[]
  visibleAccount: string
}
export const initializeClientAccountObj = (request: any) => {
  const fieldNames = request.fieldNames.toString()
  return {
    initialize: `${fieldNames}`
  }
}

export const clientAccountUpdateInvestDetail = (request: any) => {
  return {
    productName: `${request.productName}`,
    textCustodian: `${request.textCustodian}`,
    yield: `${request.accountYield}`,
    managed: `${request.managed}`,
    isDeletedConnected: `${request.isDeletedConnected}`
  }
}

export const clientOverrideAllocation = (request: ClientAccountObj): any => {
  return {
    accountNickname: `${request.accountNickname}`,
    stocksManual: !isNaN(request.discreteAllocation.equity)
      ? request.discreteAllocation.equity
      : 0,
    bondsManual: !isNaN(request.discreteAllocation.fixed)
      ? request.discreteAllocation.fixed
      : 0,
    cashManual: !isNaN(request.discreteAllocation.cash)
      ? request.discreteAllocation.cash
      : 0,
    altManual: !isNaN(request.discreteAllocation.alt)
      ? request.discreteAllocation.alt
      : 0,
    unclassifiedManual: !isNaN(request.discreteAllocation.unclassified)
      ? request.discreteAllocation.unclassified
      : 0
  }
}

export const clientAccountInformationUpdate = (request: ClientAccountObj) => {
  return {
    accountNickname: `${request.accountNickname}`,
    accountNumberFull: `${request.accountNumber}`,
    totalValueManual: request.totalValueManual,
    taxStatus: `${request.taxStatus}`,
    accountType: `${request.accountType}`,
    type: `${request.registrationType}`,
    guidebookClassification: `${request.guidebookClassification}`,
    balanceDate: request.balanceDate ? `${request.balanceDate}` : null,
    description: `${request.description}`,
    pssSelected: `${request.pssSelected}`,
    sendToPlanningSoftware: request.sendToPlanningSoftware,
    includeInGuidebook: `${request.includeInGuidebook}`,
    productName: `${request.productName}`,
    textCustodian: `${request.textCustodian}`,
    yield: request.accountYield,
    managed: `${request.managed}`,
    isDeletedConnected: `${request.isDeletedConnected}`,
    ownerIds: request.ownerIds
  }
}

export const clientAccountCreate = (request: ClientAccountObj) => {
  let guidebookClassification =
    request.guidebookClassification.length === 0
      ? undefined
      : request.guidebookClassification
  switch (guidebookClassification) {
    case 'Personal Assets':
      guidebookClassification = 'Other - Personal Assets'
      break
    case 'Business Assets':
      guidebookClassification = 'Other - Business Assets'
      break
    default:
  }

  const pssSelected = !Object.is(request.pssSelected, null)
    ? { pssSelected: request.pssSelected }
    : null
  const includeInGuidebook = !Object.is(request.includeInGuidebook, null)
    ? { includeInGuidebook: request.includeInGuidebook }
    : null

  const createObj = {
    accountNickname: `${request.accountNickname}`,
    accountNumberFull: `${request.accountNumberFull}`,
    totalValueManual: `${request.totalValueManual}`,
    taxStatus: `${request.taxStatus}`,
    accountType: `${request.accountType}`,
    type: `${request.type}`,
    guidebookClassification,
    ownerIds: request.ownerIds,
    balanceDate: request.balanceDate ? `${request.balanceDate}` : null,
    source: 'AdvisorCenter - Manual',
    strategyReporting: true,
    ...pssSelected,
    ...includeInGuidebook
  }

  return createObj
}

export const getClientAccounts = (
  householdFinId: string
): {
  type: string
  payload: Promise<AxiosResponse<ClientAccount.Response>>
} => {
  return {
    type: FETCH_CLIENT_ACCOUNTS,
    payload: api()
      .get(`/households/${householdFinId}/clientaccounts`)
      .then((response: AxiosResponse<ClientAccountResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const getInvestmentAccounts = (): {
  type: string
  payload: Promise<string>
} => {
  return {
    type: FETCH_INVESTMENT_ACCOUNTS,
    payload: api()
      .get('/investments')
      .then((response) => {
        return response.data
      })
  }
}

export const getInitializeClientAccount = (
  clientAccountFinId: string,
  householdFinId: string,
  request: any
): { type: string; payload: Promise<object> } => {
  const fieldNames: string = JSON.stringify(initializeClientAccountObj(request))
  return {
    type: INITIALIZE_CLIENT_ACCOUNT,
    payload: api()
      .get(
        `households/${householdFinId}/clientaccounts/${clientAccountFinId}?initialize=` +
          fieldNames
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response.data
      })
  }
}

export const updateClientAccount = (
  clientAccountFinId: string,
  householdFinId: string,
  request: any
): {
  type: string
  payload: Promise<AxiosResponse<ClientAccount.Response>>
} => {
  const updateObj = excludeUndefined(clientAccountInformationUpdate(request))
  return {
    type: UPDATE_CLIENT_ACCOUNT,
    payload: api()
      .put(
        `households/${householdFinId}/clientaccounts/${clientAccountFinId}`,
        updateObj
      )
      .then((response: AxiosResponse<ClientAccountResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateOverrideAllocation = (
  clientAccountFinId: string,
  householdFinId: string,
  request: any
): {
  type: string
  payload: Promise<AxiosResponse<ClientAccount.Response>>
} => {
  // const excludeUndefined = NetWortHelper.excludeUndefined;
  const discreteAllocation = clientOverrideAllocation(request)
  return {
    type: UPDATE_CLIENT_ACCOUNT,
    payload: api()
      .put(
        `households/${householdFinId}/clientaccounts/${clientAccountFinId}`,
        discreteAllocation
      )
      .then((response: AxiosResponse<ClientAccountResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const updateInvestmentDetail = (
  clientAccountFinId: string,
  householdFinId: string,
  request: any
): {
  type: string
  payload: Promise<AxiosResponse<ClientAccount.Response>>
} => {
  const updateObj = excludeUndefined(clientAccountUpdateInvestDetail(request))
  return {
    type: UPDATE_CLIENT_ACCOUNT,
    payload: api()
      .put(
        `households/${householdFinId}/clientaccounts/${clientAccountFinId}`,
        updateObj
      )
      .then((response: AxiosResponse<ClientAccountResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const createClientAccount = (
  householdFinId: string,
  request: ClientAccountObj
): {
  type: string
  payload: Promise<AxiosResponse<ClientAccount.Response>>
} => {
  const newClientAccountObj = excludeUndefined(clientAccountCreate(request))
  return {
    type: CREATE_CLIENT_ACCOUNT,
    payload: api()
      .post(`households/${householdFinId}/clientaccounts`, newClientAccountObj)
      .then((response: AxiosResponse<ClientAccountResponse>) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const deleteClientAccount = (
  clientAccountFinId: string,
  householdFinId: string
) => {
  return {
    type: DELETE_CLIENT_ACCOUNT,
    payload: api()
      .delete(
        `households/${householdFinId}/clientaccounts/${clientAccountFinId}`
      )
      .then((response: AxiosResponse<ClientAccountResponse>) => {
        return response
      })
  }
}

export const getYodleeAccounts = (householdId: string) => {
  return {
    type: FETCH_YODLEE_CLIENT_ACCOUNTS,
    payload: api()
      .post(`households/${householdId}/syncClientAccounts`)
      .then((response) => {
        return response
      })
  }
}

export const mergeClientAccounts = (
  householdId: string,
  accountsToMerge: MergeRequest
) => {
  return {
    type: MERGE_CLIENT_ACCOUNTS,
    payload: api()
      .post(`households/${householdId}/clientaccounts/merge`, accountsToMerge)
      .then((response) => {
        response.data.householdFinId = householdId
        return response
      })
  }
}

export const unmergeClientAccounts = (
  householdFinId: string,
  clientAccountFinId: string
) => {
  return {
    type: UNMERGE_CLIENT_ACCOUNTS,
    payload: api()
      .put(
        `households/${householdFinId}/clientaccounts/${clientAccountFinId}/unmerge`
      )
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const setYodleeSyncAttempt = (householdId: string, value: boolean) => {
  return {
    type: SET_YODLEE_SYNC_ATTEMPT,
    payload: { householdId, value }
  }
}

export const getClientAccountsErrorEmail = (
  householdFinId: string,
  clientId: string
) => {
  return {
    type: GET_CLIENT_ACCOUNTS_ERROR_EMAIL,
    payload: api()
      .post(`households/${householdFinId}/clientaccounts/previewemail`, {
        clientId
      })
      .then((response) => {
        response.data.householdFinId = householdFinId
        return response
      })
  }
}

export const sendClientAccountsErrorEmail = (
  householdFinId: string,
  clientId: string
) => {
  return {
    type: SEND_CLIENT_ACCOUNTS_ERROR_EMAIL,
    payload: api()
      .post(`households/${householdFinId}/clientaccounts/sendemail`, {
        clientId
      })
      .then((response) => response)
  }
}
