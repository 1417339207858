import {
  SortSettingProperty,
  SortType
} from '../../objects/preferenceSortSetting'
import {
  ClientAccountObjState,
  ClientAccountObj
} from '../../objects/clientAccount'
import * as SortHelper from './householdPreferences'

export const sortOrderOptions: SortSettingProperty[] = [
  {
    value: 'ascending',
    label: 'Ascending'
  },
  {
    value: 'descending',
    label: 'Descending'
  }
]

export const sortByOptions: SortSettingProperty[] = [
  {
    value: 'name',
    label: 'Account Name'
  },
  {
    value: 'accountNumber',
    label: 'Account Number'
  },
  {
    value: 'totalValue',
    label: 'Amount'
  },
  {
    value: 'guidebookClassification',
    label: 'Classification'
  },
  {
    value: 'registrationType',
    label: 'Registration Type'
  }
]

export const groupByOptions: SortSettingProperty[] = [
  {
    value: 'guidebookClassification',
    label: 'Classification'
  },
  {
    value: 'registrationType',
    label: 'Registration Type'
  }
]

export const sortNetWorthAccounts = (
  clientAccounts: ClientAccountObjState | ClientAccountObj[],
  optionValues: SortType
) => {
  let clientAccountsCollection: ClientAccountObjState = clientAccounts as ClientAccountObjState
  if (Array.isArray(clientAccounts)) {
    clientAccountsCollection = {}
    clientAccounts.forEach((clientAccount) => {
      clientAccountsCollection[clientAccount.id] = clientAccount
    })
  }

  const groupBy = SortHelper.mapValueToOption(
    groupByOptions,
    optionValues.groupBy
  )
  const groupByVal = groupBy && groupBy.value ? groupBy.value : ''
  const sortBy = SortHelper.mapValueToOption(sortByOptions, optionValues.sortBy)
  const sortByVal = sortBy && sortBy.value ? sortBy.value : ''
  const sortingOrder = SortHelper.mapValueToOption(
    sortOrderOptions,
    optionValues.sortingOrder
  )
  const sortingOrderVal =
    sortingOrder && sortingOrder.value ? sortingOrder.value : ''
  const descending = sortingOrderVal === 'descending'
  const accountsToSort = SortHelper.sanitizeSortFieldValues(
    clientAccountsCollection,
    {
      groupBy: groupByVal,
      sortBy: sortByVal
    }
  )

  const sortedAccounts: ClientAccountObj[] = []

  if (accountsToSort) {
    Object.keys(accountsToSort)
      .sort((a, b) => {
        const acctGroupA = accountsToSort[a][groupByVal]
        const acctGroupB = accountsToSort[b][groupByVal]
        const acctSortA = accountsToSort[a][sortByVal]
        const acctSortB = accountsToSort[b][sortByVal]
        let grouped
        let sorted

        if (groupByVal === 'guidebookClassification') {
          grouped = SortHelper.compareStringField(
            acctGroupA,
            acctGroupB,
            descending
          )
        }
        if (groupByVal === 'registrationType') {
          grouped = SortHelper.compareStringField(
            acctGroupA,
            acctGroupB,
            descending
          )
        }
        if (sortByVal === 'totalValue') {
          sorted = SortHelper.compareNumericField(
            acctSortA,
            acctSortB,
            descending
          )
        }
        if (sortByVal === 'name') {
          sorted = SortHelper.compareStringField(
            acctSortA,
            acctSortB,
            descending
          )
        }
        if (sortByVal === 'accountNumber') {
          sorted = SortHelper.compareStringField(
            acctSortA,
            acctSortB,
            descending
          )
        }
        if (sortByVal === 'guidebookClassification') {
          grouped = SortHelper.compareStringField(
            acctSortA,
            acctSortB,
            descending
          )
        }
        if (sortByVal === 'registrationType') {
          grouped = SortHelper.compareStringField(
            acctSortA,
            acctSortB,
            descending
          )
        }
        return grouped || sorted
      })
      .forEach((key: string) => {
        sortedAccounts.push(accountsToSort[key])
      })
  }
  return sortedAccounts
}
