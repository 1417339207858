// import * as React from 'react'
import React, { FunctionComponent, SVGProps } from 'react'
import { getPriorityIcon } from '../../../components/priorityIcons'
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg'

interface PriorityAnswerProp {
  index: number
  questionNumber: any
  adverb: string
  prefer: string
  over: string
  question: any
  updateFunc(answerId: string): void
}

class PriorityAnswerItem extends React.Component<PriorityAnswerProp> {
  public priorityStatement = (): string => {
    const { adverb, prefer, over } = this.props
    return `I ${adverb} prefer ${prefer} over ${over}`
  }

  public updateAnswer = () => {
    const { question } = this.props
    this.props.updateFunc(question)
  }

  public render() {
    const { prefer, index } = this.props

    const Icon: FunctionComponent<SVGProps<SVGSVGElement>> = getPriorityIcon(
      prefer
    )

    return (
      <div className='ivf-summary__answer'>
        <div className='ivf-summary__answer-number'>{index}.</div>
        <div className='ivf-summary__answer-icon'>{<Icon />}</div>
        <div className='ivf-summary__answer-statement'>
          {this.priorityStatement()}
        </div>

        <div className='ivf-summary__answer-update' onClick={this.updateAnswer}>
          <EditIcon />
          Update
        </div>
      </div>
    )
  }
}

export default PriorityAnswerItem
