import * as actions from '../actions/honestConversationsV1'
import {
  HonestConversationsInterface,
  HonestConversationScoreInterface,
  CardScoreInterface
} from '../objects/honestConversationsV1'

export interface HonestConversationsState {
  [key: string]: HonestConversationsInterface
}

const initState: HonestConversationsState = {}

const getTypeFromTitle = (cardCommentWithTypes: string) => {
  if (cardCommentWithTypes && cardCommentWithTypes.includes('(c)')) {
    return 'commitment'
  } else if (cardCommentWithTypes && cardCommentWithTypes.includes('(f)')) {
    return 'protection'
  } else if (cardCommentWithTypes && cardCommentWithTypes.includes('h')) {
    return 'happiness'
  } else return null
}

const removeTypeFromTitle = (cardCommentWithTypes: string) => {
  if (cardCommentWithTypes && cardCommentWithTypes.includes('(c)')) {
    return cardCommentWithTypes.replace('(c)', '').trim()
  } else if (cardCommentWithTypes && cardCommentWithTypes.includes('(f)')) {
    return cardCommentWithTypes.replace('(f)', '').trim()
  } else if (cardCommentWithTypes && cardCommentWithTypes.includes('h')) {
    return cardCommentWithTypes.replace('(h)', '').trim()
  } else return null
}

const mapCards = (responseCards: any) => {
  const obj = {}
  Object.keys(responseCards).forEach((key) => {
    const card = responseCards[key]
    obj[key] = {
      cardType: getTypeFromTitle(card.card),
      title: removeTypeFromTitle(card.card),
      comment: card.comments
    }
  })
  return obj
}

const mapSpouseScore = (spouseResult: any): CardScoreInterface => {
  const obj = {}
  Object.keys(spouseResult.scores).map((key) => {
    const score = spouseResult.scores[key]
    const comment = spouseResult.comments[key]
    obj[key] = { score, comment }
  })
  return obj
}

const mapHonestConversationScore = (score: any) => {
  return {
    id: score.id,
    createdDate: score.createdDate,
    dateOfScore: score.dateOfScore,
    primaryContact: mapSpouseScore(score.spouse1),
    secondaryContact: mapSpouseScore(score.spouse2)
  }
}

const mapHonestConversationScores = (scores: any[]) => {
  const obj: HonestConversationScoreInterface = {}
  scores.map((score) => {
    obj[score.id] = mapHonestConversationScore(score)
  })
  return obj
}

const mapHonestConversation = (honestConversation: any) => {
  return {
    id: honestConversation.id,
    householdId: honestConversation.householdId,
    createdDate: honestConversation.createdDate,
    conversationDate: honestConversation.conversationDate,
    isDeleted: honestConversation.isDeleted,
    combinedTarget: honestConversation.combinedTarget,
    activeScore: honestConversation.activeScore,
    scores: mapHonestConversationScores(honestConversation.scores),
    combinedCards: {
      ...mapCards(honestConversation.combinedCards)
    },
    primaryContact: {
      name: honestConversation.scores[0]
        ? honestConversation.scores[0].spouse1.name
        : null,
      idealLifeIndex: honestConversation.scores[0]
        ? honestConversation.scores[0].spouse1.idealLifeIndex
        : null,
      cards: honestConversation.spouse1Cards
        ? {
            ...mapCards(honestConversation.spouse1Cards)
          }
        : null
    },
    secondaryContact: {
      name: honestConversation.scores[0]
        ? honestConversation.scores[0].spouse1.name
        : null,
      idealLifeIndex: honestConversation.scores[0]
        ? honestConversation.scores[0].spouse1.idealLifeIndex
        : null,
      cards: honestConversation.spouse2Cards
        ? {
            ...mapCards(honestConversation.spouse2Cards)
          }
        : null
    }
  }
}

const mapHonestConversations = (
  householdFinId: string,
  honestConversations: any[]
) => {
  const honestConversationList: HonestConversationsInterface = {}
  honestConversations.forEach((honestConversation) => {
    honestConversationList[honestConversation.id] = mapHonestConversation(
      honestConversation
    )
  })
  const obj = {}
  obj[householdFinId] = honestConversationList
  return obj
}

const HonestConversations = (
  state = initState,
  action: any
): HonestConversationsState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_HONEST_CONVERSATION_FULFILLED:
      const payloadDataLength =
        action.payload && action.payload.data && action.payload.data.length
      const honestConversations =
        payloadDataLength > 0
          ? mapHonestConversations(
              action.payload.data[0].householdId,
              action.payload.data
            )
          : null

      return { ...state, ...honestConversations }
    case `${actions.CREATE_HONEST_CONVERSATION_MEETING}_FULFILLED`:
    case `${actions.CREATE_AND_SET_ACTIVE_HONEST_CONVERSATION_MEETING}_FULFILLED`:
    case actions.CREATE_NEW_HONEST_CONVERSATION_SCORE_FULFILLED:
      if (!newState[action.payload.data.householdId]) {
        newState[action.payload.data.householdId] = {}
        if (
          !newState[action.payload.data.householdId][action.payload.data.id]
        ) {
          newState[action.payload.data.householdId][action.payload.data.id] = {}
        }
      }
      newState[action.payload.data.householdId][
        action.payload.data.id
      ] = mapHonestConversation(action.payload.data)
      return newState
    case actions.UPDATE_HONEST_CONVERSATION_SCORE_FULFILLED:
      newState[action.payload.data.householdId][
        action.payload.data.id
      ] = mapHonestConversation(action.payload.data)
      return newState
    case actions.DELETE_HONEST_CONVERSATION_MEETING_FULFILLED:
      if (
        newState[action.payload.data.householdFinId] &&
        newState[action.payload.data.householdFinId][
          action.payload.data.meetingFinId
        ]
      ) {
        delete newState[action.payload.data.householdFinId][
          action.payload.data.meetingFinId
        ]
      }
      return newState
    case actions.DELETE_HONEST_CONVERSATION_SCORE_FULFILLED:
      if (
        newState[action.payload.data.householdFinId] &&
        newState[action.payload.data.householdFinId][
          action.payload.data.meetingFinId
        ]
      ) {
        delete newState[action.payload.data.householdFinId][
          action.payload.data.meetingFinId
        ].scores[action.payload.data.scoreFinId]
      }
      return newState
    case actions.UPDATE_HONEST_CONVERSATION_MEETING_FULFILLED:
      if (
        newState[action.payload.data.householdId] &&
        newState[action.payload.data.householdId][action.payload.data.id]
      ) {
        newState[action.payload.data.householdId][
          action.payload.data.id
        ] = mapHonestConversation(action.payload.data)
      }
      return newState
    case actions.SET_ACTIVE_HONEST_CONVERSATION_SCORE_FULFILLED:
      if (
        newState[action.payload.data.householdId] &&
        newState[action.payload.data.householdId][action.payload.data.id]
      ) {
        newState[action.payload.data.householdId][
          action.payload.data.id
        ] = mapHonestConversation(action.payload.data)
      }
      return newState
    case actions.UPDATE_HC_COMMENT_FULFILLED:
      if (
        newState[action.payload.data.householdId] &&
        newState[action.payload.data.householdId][action.payload.data.id]
      ) {
        newState[action.payload.data.householdId][
          action.payload.data.id
        ] = mapHonestConversation(action.payload.data)
      }
      return newState
    default:
      return state
  }
}

export default HonestConversations
