import React, { ReactNode, FunctionComponent, CSSProperties } from 'react'
import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { setCookie } from '../../../../helpers'
import { HouseholdObj } from '../../../../../objects/household'

export const HcWelcomeTitle: FunctionComponent<{
  children?: ReactNode
  className?: string
}> = ({ children, className }) =>
  children ? (
    <p className={`hcw__welcome hcw__welcome_messege-title ${className}`}>
      {children}
    </p>
  ) : null

export const HcParagraph: FunctionComponent<{
  className: string
  children?: ReactNode
}> = ({ className, children }) => (
  <p className={`hcw__welcome ${className}`}>{children}</p>
)

export const HcWelcomeDiv: FunctionComponent<{
  styleCss?: CSSProperties
  children?: ReactNode
  className?: string
}> = ({ children, className, styleCss }) => (
  <div className={`hcw__welcome ${className}`} style={styleCss}>
    {children}
  </div>
)

const launchExercise = (link: string, accessToken: string) => () => {
  const cookie = `acToken=${accessToken}`
  setCookie(cookie)
  window.location.href = link
}

export const LaunchHonestConvesations: FunctionComponent<{
  name: string
  clientId: string
  exerciseId: string
  accessToken: string
  exerciseStatus: string
  household: HouseholdObj
}> = ({
  name,
  clientId,
  exerciseId,
  accessToken,
  exerciseStatus,
  household
}) => {
  if (!(name && clientId && exerciseId && household)) {
    return null
  }

  const page = household?.secondaryContact ? 'start' : 'reveal'
  const redirect_uri = `${window.location.protocol}//${window.location.host}/households/${household?.id}/honestconversations/v3/exercise/${exerciseId}/${page}`
  setCookie(`redirectUrl=${redirect_uri}`)
  const hcLink = `${window._env_.REACT_APP_HC_URL_V2}/pick/fifteen?exerciseId=${exerciseId}&clientId=${clientId}`

  return (
    <HcWelcomeDiv className='hcw__welcome_messege-col'>
      <div>
        <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-med hcw__welcome_messege-body-med-name'>
          {name.toUpperCase()}
        </HcParagraph>
      </div>
      {exerciseStatus?.length > 0 && (
        <div>
          <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-status'>
            {exerciseStatus}
          </HcParagraph>
        </div>
      )}
      <div>
        <HcParagraph className='hcw__welcome_messege-body hcw__welcome_messege-body-code'>
          <Button onClick={launchExercise(hcLink, accessToken)}>
            Start Exercise
          </Button>
        </HcParagraph>
      </div>
    </HcWelcomeDiv>
  )
}

export const HcWelcomeContainer: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => <div className='hcw__container'>{children}</div>
