import React, { Component } from 'react'
import Modal from './layout/modal'
import Button from './button'
import { ReactComponent as WarningIcon } from '../assets/images/icons/svg/warning_red.svg'

interface AlternativeStrategyModalProps {
  acknowledgeAltStrategy(): void
  closeModal(): void
}

class AlternativeStrategyModal extends Component<
  AlternativeStrategyModalProps
> {
  public render() {
    const { closeModal, acknowledgeAltStrategy } = this.props
    return (
      <Modal
        icon={WarningIcon}
        title='Warning'
        closeModal={closeModal}
        headerStyle={{
          backgroundColor: '#fafafa',
          fontSize: 16,
          lineHeight: 0
        }}>
        <div className='altstrategies-modal__w'>
          <p>
            I acknowledge that this product is suitable for this client, that
            the client is eligible (e.g., an accredited investor), and that this
            is not a retirement / tax-qualified account (e.g., IRA,
            tax-qualified plan, Keogh plan, ERISA Account, Coverdell ESA).
          </p>
          <div className='altstrategies-modal__btn-w'>
            <Button onClick={closeModal} style={{ backgroundColor: '#dddddd' }}>
              Cancel
            </Button>
            <Button onClick={acknowledgeAltStrategy} primary>
              I acknowledge
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default AlternativeStrategyModal
