import React, { Component, ChangeEvent } from 'react'
import CoverPage from './coverPage'
import GbNavigation from './gbNavigation'
import Guidebookactions from './guidebookactions'
import api from '../../../../v3/helpers/api'
import { UserObj, UsersObj } from '../../../../objects/user'
import {
  HouseholdObj,
  HouseholdInstitution
} from '../../../../objects/household'
import {
  getIndividualHousehold,
  getOfficeTeamByHousehold
} from '../../../../actions/households'

import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { withRouter } from 'react-router'
import { getLogo } from '../../../helpers/guidebook'
import { OfficeTeamObj } from '../../../../objects/officeTeams'
import { ContactsInterface } from '../../../../objects/contact'
import { history } from '../../../../store'
import * as GuidebookActions from '../../../../actions/guidebook'
import { addTimedLoader } from '../../../../actions/timedLoader'
import {
  GuidebookObj,
  GuidebookPdfRequestObj
} from '../../../../objects/guidebook'
import PdfRenderer from './pdfRenderer'
import { coverPageInfoSetter } from '../../../helpers/householdOfficeTeams'
import Disclosures from '../../../components/layout/disclosure'

interface GuidebookProps {
  user: UserObj
  users: UsersObj
  officeTeam: OfficeTeamObj
  household: HouseholdObj
  householdFinId: string
  contacts: ContactsInterface
  institution: HouseholdInstitution
  currentGuidebookId: string
  currentGuidebook: GuidebookObj
  dispatch: Dispatch<GlobalState>
}
interface GuidebookState {
  pdfData: any
  coverPageInfo: {
    title: string
    clientName: string
    selectedAdvisor: UserObj
    defaultAdvisor: UserObj
    logo: string | null
    users: UsersObj
    teamAssigned: OfficeTeamObj
  }
  gbSections: {
    checkedDigitalOnlyFormat: boolean
    checkedcover: boolean
    checkedProcessFlow: boolean
    checkedMoneyMind: boolean
    checkedHonestConversations: boolean
    checkedProfileCover: boolean // this is for the "where are we today?" section
    checkedNetWorth: boolean
    checkedInvestments: boolean
    checkedFinancialGoals: boolean
    checkedInsurance: boolean
    checkedGoals: boolean
    checkedTasks: boolean
    checkedAdditionalDocs0: boolean
    checkedAdditionalDocs1: boolean
    checkedAdditionalDocs2: boolean
    checkedAdditionalDocs3: boolean
    checkedAdditionalDocs4: boolean
  }
  isDownloadEnabled: boolean
  isVaultEnabled: boolean
  files: any
  uploadToDocVault: boolean
}

interface SelectInterface {
  label: string
  value: UserObj
}

class Guidebook extends Component<GuidebookProps, GuidebookState> {
  constructor(props: GuidebookProps) {
    super(props)
    const primaryName =
      this.props.contacts && this.props.contacts.primary
        ? this.props.contacts.primary.firstName
        : ''
    const secondaryName =
      this.props.contacts && this.props.contacts.secondary
        ? this.props.contacts.secondary.firstName + ' '
        : ''
    const clientName =
      primaryName && secondaryName
        ? primaryName + ' and ' + secondaryName
        : primaryName || secondaryName
    this.state = {
      pdfData: [],
      uploadToDocVault: false,
      coverPageInfo: {
        title: 'GuideBook',
        clientName,
        selectedAdvisor: null,
        defaultAdvisor: null,
        logo: '',
        users: this.props.users,
        teamAssigned: null
      },
      gbSections: {
        checkedDigitalOnlyFormat: false,
        checkedcover: true,
        checkedProcessFlow: true,
        checkedMoneyMind: true, // MoneyMind is not optional - true always
        checkedHonestConversations: true, // HonestConversations is not optional - true always
        checkedProfileCover: true, // this is for the "where are we today?" section
        checkedNetWorth: true, // NetWorth is not optional - true always
        checkedInvestments: true,
        checkedFinancialGoals: true, // FinancialGoals is not optional - true always
        checkedInsurance: true,
        checkedGoals: true, // PriorityActionList is not optional - true always
        checkedTasks: true,
        checkedAdditionalDocs0: false,
        checkedAdditionalDocs1: false,
        checkedAdditionalDocs2: false,
        checkedAdditionalDocs3: false,
        checkedAdditionalDocs4: false
      },
      isDownloadEnabled: true,
      isVaultEnabled: true,
      files: []
    }
  }

  public handleChange = (event: any) => {
    this.setState({
      files: event
    })
  }

  public async componentDidMount() {
    const { householdFinId, household } = this.props
    await this.props.dispatch(getIndividualHousehold(householdFinId))
    await this.props.dispatch(getOfficeTeamByHousehold(householdFinId))
    if (household?.institution) {
      const logo = await getLogo(household.institution.photoUrl)
      const coverPageInfo = { ...this.state.coverPageInfo }
      coverPageInfo.logo = logo
      this.setState({ coverPageInfo })
    }
  }

  public componentDidUpdate(
    prevProps: GuidebookProps,
    prevState: GuidebookState
  ) {
    const { currentGuidebookId, householdFinId } = this.props
    const { isDownloadEnabled, isVaultEnabled } = this.state
    if (
      currentGuidebookId &&
      currentGuidebookId !== prevProps.currentGuidebookId
    ) {
      this.uploadPdf()
    }
    if (!currentGuidebookId && (!isDownloadEnabled || !isVaultEnabled)) {
      history.push(`/households/${householdFinId}/Guidebook`)
    }
    if (
      !prevState.coverPageInfo.teamAssigned &&
      prevProps.household.officeTeams &&
      Object.keys(prevProps.household.officeTeams).length > 0 &&
      prevProps.household.officeTeam
    ) {
      this.updateCoverPageState(prevState.coverPageInfo)
    }
  }

  public updateCoverPageState = async (
    currentCoverPage: GuidebookState['coverPageInfo']
  ) => {
    const { household } = this.props
    const coverPageInfo: GuidebookState['coverPageInfo'] = currentCoverPage

    if (household.institution) {
      coverPageInfo.logo = await getLogo(household.institution.photoUrl)
    }
    const coverPageInfoState = coverPageInfoSetter(coverPageInfo, household)

    this.setState({
      coverPageInfo: coverPageInfoState
    })
  }

  public coverPageDataUpdate = (inputField: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const coverPageInfo = { ...this.state.coverPageInfo }
    coverPageInfo[inputField] = event.currentTarget.value
    this.setState({ coverPageInfo })
  }

  public coverPageAdvisorUpdate = (selected: SelectInterface) => {
    const coverPageInfo = this.state.coverPageInfo
    coverPageInfo.selectedAdvisor = selected.value
    this.setState({ coverPageInfo })
  }

  public toggleMe = (section: string) => () => {
    const gbSections = this.state.gbSections
    gbSections[section] = !this.state.gbSections[section]
    this.setState({ gbSections })
  }

  public createGuidebook = async (uploadToDocVault: boolean = false) => {
    const { isDownloadEnabled, isVaultEnabled, gbSections } = this.state
    if (!isDownloadEnabled && uploadToDocVault === false) return
    if (!isVaultEnabled && uploadToDocVault === true) return

    // Setting the state to capture the request to upload the document to vault
    // Re-setting the state to false in the uploadPdf Fn when the upload is done
    this.setState({ uploadToDocVault })

    const fileNames: string[] = []
    const validFileNames: string[] = [
      'MoneyMind',
      'HonestConversations',
      'NetWorth',
      'Investments',
      'Insurance',
      'FinancialGoals',
      'Goals',
      'Tasks'
    ]
    Object.keys(gbSections).forEach((key: any) => {
      if (gbSections[key] && key.split('checked')[1] !== 'cover') {
        const fileName = key.split('checked')[1]
        if (validFileNames.includes(fileName)) {
          fileNames.push(fileName)
        }
      }
    })
    const guidebook: GuidebookObj = {
      name: 'Guidebook',
      sections: fileNames
    }
    await this.props.dispatch(
      GuidebookActions.createGuidebook(this.props.householdFinId, guidebook)
    )
  }

  public uploadPdf = async () => {
    const {
      isDownloadEnabled,
      coverPageInfo,
      gbSections,
      uploadToDocVault,
      isVaultEnabled
    } = this.state
    const maxVaultFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
    const {
      dispatch,
      institution,
      household,
      householdFinId,
      contacts,
      currentGuidebookId
    } = this.props
    if (!isDownloadEnabled && uploadToDocVault === false) return
    if (!isVaultEnabled && uploadToDocVault === true) return
    uploadToDocVault
      ? this.setState({ isVaultEnabled: false })
      : this.setState({ isDownloadEnabled: false })

    // Cover Page

    await api().post(
      `/households/${householdFinId}/guidebookgenerator/guidebookcover`,
      {
        coverPageInfo: this.state.coverPageInfo,
        id: 'cover',
        contactId: this.props.householdFinId
      }
    )

    // Gen all other pages
    const fileNames: string[] = []
    Object.keys(gbSections).forEach((key: any) => {
      if (gbSections[key] && key.toString() !== 'checkedDigitalOnlyFormat') {
        fileNames.push(key.split('checked')[1])
      }
    })

    const mergeParams: GuidebookPdfRequestObj = {
      fileNames,
      additionalDocsPDF: window.event,
      id: householdFinId,
      customContact: coverPageInfo?.clientName ? coverPageInfo?.clientName : '',
      contact: {
        firstName: contacts?.primary ? contacts?.primary?.firstName : '',
        lastName: contacts?.primary ? contacts?.primary?.lastName : ''
      },
      secondaryContact: {
        firstName: contacts?.secondary ? contacts?.secondary?.firstName : '',
        lastName: contacts?.secondary ? contacts?.secondary?.lastName : ''
      },
      planningSoftware: household?.planningSoftware
        ? household?.planningSoftware
        : '',
      logo: coverPageInfo.logo,
      isFinLifeInstitution: institution?.isFinlifeInstitution,
      removeBlankPages: gbSections.checkedDigitalOnlyFormat
    }

    // This is needed so we have access to res.data.pdfUrl to open the created GB
    const response = GuidebookActions.uploadPdf(
      householdFinId,
      currentGuidebookId,
      mergeParams,
      uploadToDocVault
    )
    try {
      await response.payload.then((res) => {
        if (res?.data?.pdfUrl && !uploadToDocVault) {
          window.open(res.data.pdfUrl)
        }
        if (uploadToDocVault) {
          // Show success message on successful upload
          dispatch(
            addTimedLoader({
              iconType: 'success',
              loadingMessage: 'File successfully uploaded to the vault.',
              loadingType: 'Success'
            })
          )
        }
        this.setState({ uploadToDocVault: false })
      })
      dispatch(response)
    } catch (e) {
      this.setState({ uploadToDocVault: false })
      if (uploadToDocVault) {
        // Show error message in case if upload failed
        dispatch(
          addTimedLoader({
            iconType: 'error',
            loadingMessage: `Unable to upload. File exceeds ${maxVaultFileSize} MB in size.`,
            loadingType: 'Error'
          })
        )
      }
      uploadToDocVault
        ? this.setState({ isVaultEnabled: true })
        : this.setState({ isDownloadEnabled: true })
    }
  }

  public setPdfData = (filename: string, content: any) => {
    const pdfData = this.state.pdfData
    pdfData[filename] = content
    this.setState(pdfData)
  }

  public render() {
    const { household, householdFinId } = this.props
    const {
      coverPageInfo,
      gbSections,
      isDownloadEnabled,
      isVaultEnabled,
      pdfData
    } = this.state
    return (
      <div className='gb-container'>
        <Guidebookactions
          household={household}
          downloader={this.createGuidebook}
          isLoading={!isDownloadEnabled}
          isVaultLoading={!isVaultEnabled}
        />
        <div className='gb-container__main'>
          <div className='gb-container__nav'>
            <GbNavigation
              coverPageInfo={coverPageInfo}
              officeTeam={coverPageInfo.teamAssigned}
              coverPageDataUpdate={this.coverPageDataUpdate}
              coverPageAdvisorUpdate={this.coverPageAdvisorUpdate}
              checkGbSections={gbSections}
              toggleMe={this.toggleMe}
              fileChanges={this.handleChange}
              household={household}
              setPdfData={this.setPdfData}
            />
          </div>
          <div className='gb-container__pdfPreview'>
            <CoverPage coverPageInfo={coverPageInfo} />
            <PdfRenderer
              id={householdFinId}
              pdfSections={gbSections}
              setPdfData={this.setPdfData}
              pdfData={pdfData}
            />
            <Disclosures />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId],
    user: store.user.userId ? store.user.users[store.user.userId] : null,
    users: store.user.users,
    officeTeam:
      store.officeTeams[
        store.households[match.params.householdFinId].officeTeam
      ],
    officeTeams: store.officeTeams,
    householdFinId: match.params.householdFinId,
    contacts: store.contact[match.params.householdFinId],
    institution: store.households[match.params.householdFinId].institution,
    currentGuidebookId:
      store.guidebook && store.guidebook[match.params.householdFinId]
        ? store.guidebook[match.params.householdFinId].currentGuidebookId
        : '',
    currentGuidebook:
      store.guidebook && store.guidebook[match.params.householdFinId]
        ? store.guidebook[match.params.householdFinId].guidebooks
        : ''
  }
}

export default withRouter(connect(mapStateToProps)(Guidebook))
