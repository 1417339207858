export interface QueryParams {
  [k: string]: string | number | null
}

export const queryBuilder = (params: QueryParams, prefix?: boolean): string => {
  const queryString =
    Object.keys(params)
      .map((key) => {
        return [
          encodeURIComponent(key),
          ...(params[key] ? ['='] : []),
          ...(params[key] ? [encodeURIComponent(params[key])] : [])
        ].join('')
      })
      .join('&') || ''

  return prefix ? `${queryString ? `?${queryString}` : ''}` : queryString
}
