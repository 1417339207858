import React from 'react'
import Modal from '../../components/layout/modal'
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg'
import Button from '../../components/button'

interface EmptyTrashModalProps {
  emptyFn(): void
  closeModal(id: string): void
}

class EmptyTrashModal extends React.Component<EmptyTrashModalProps> {
  constructor(props: any) {
    super(props)
  }

  public emptyFn = () => {
    this.props.emptyFn()
  }

  public closeFn = () => {
    this.props.closeModal('')
  }

  public render() {
    return (
      <Modal icon={DeleteIcon} title={'EMPTY TRASH'} closeModal={this.closeFn}>
        <div className='vault-modal__content'>
          Emptying the trash will delete all files from the trash, and you will
          no longer be able to access these files. Are you sure you want to
          empty the trash?
          <div className='vault-modal__buttons'>
            <Button clear={true} onClick={this.closeFn}>
              Cancel
            </Button>
            <Button primary={true} onClick={this.emptyFn}>
              Empty
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default EmptyTrashModal
