import React, { CSSProperties, FunctionComponent, ReactNode } from 'react'
import {
  Button,
  Tile
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  HcWelcomeContainer,
  HcParagraph,
  HcWelcomeTitle,
  HcWelcomeDiv
} from './hcWelcomeHelper'

const lineOne = `HonestConversations is an interactive workshop created to help you
articulate what’s truly important in your life.`
const lineTwo = `We designed this exercise so we can understand what really matters to
you and help you make even better decisions. Because life isn’t just
about having money, it’s about living richly.`

export const WelcomeContent: FunctionComponent<{
  buttonStyle: CSSProperties
  tileStyle: CSSProperties
  children?: ReactNode
  onClickFunc?(): void
}> = ({ buttonStyle, onClickFunc, tileStyle, children }) => (
  <Tile tileStyle={tileStyle}>
    <HcWelcomeContainer>
      <HcWelcomeDiv className='hcw__welcome-image' />
      <HcWelcomeDiv className='hcw__welcome_messege-row'>
        <HcWelcomeDiv className='hcw__welcome_messege-col'>
          {children}
          <HcWelcomeTitle>Welcome</HcWelcomeTitle>
          <HcParagraph className='hcw__welcome_messege-body'>
            {lineOne}
          </HcParagraph>
          <HcParagraph className='hcw__welcome_messege-body'>
            {lineTwo}
          </HcParagraph>
          <Button btnStyle={buttonStyle} onClick={onClickFunc}>
            Got It!
          </Button>
        </HcWelcomeDiv>
      </HcWelcomeDiv>
    </HcWelcomeContainer>
  </Tile>
)
