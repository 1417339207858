import React, { Component } from 'react'
import Modal from '../layout/modal'
import Button from '../button'

export interface MeetingModeModalProps {
  confirmMeetingModeOff(): void
  closeModal(): void
}

class MeetingModeModal extends Component<MeetingModeModalProps> {
  public render() {
    const { closeModal, confirmMeetingModeOff } = this.props
    return (
      <Modal title='Meeting Mode' closeModal={this.props.closeModal}>
        <div className='meetingmode-modal__w'>
          <p>
            You are about to turn off meeting mode. This means you'll be able to
            access Client List, and view other Clients. Are you sure you want to
            exit?
          </p>
          <div className='meetingmode-modal__btn-w'>
            <Button onClick={closeModal} clear>
              No, never mind
            </Button>
            <Button onClick={confirmMeetingModeOff} primary>
              Yes, exit Meeting Mode
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default MeetingModeModal
