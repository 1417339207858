import React, { FC } from 'react'
import Modal from '../../layout/modal'
import Button from '../../button'

export interface RestoreGoalModalProps {
  toggleRestoreModal(): void
  restoreSuggestedGoal(): void
  clearSuggestedGoal(): void
}

export const RestoreGoalModal: FC<RestoreGoalModalProps> = ({
  toggleRestoreModal,
  restoreSuggestedGoal,
  clearSuggestedGoal
}) => {
  return (
    <Modal title='Restore History' closeModal={toggleRestoreModal}>
      <div className='restore-goal-modal__text'>
        This goal was previously selected for this client. Would you like to
        restore old data or start fresh?
      </div>
      <div className='restore-goal-modal__button-w'>
        <Button clear={true} onClick={clearSuggestedGoal}>
          Clear History
        </Button>
        <Button primary={true} onClick={restoreSuggestedGoal}>
          Restore
        </Button>
      </div>
    </Modal>
  )
}

export default RestoreGoalModal
