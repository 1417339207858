import React, { Component, Fragment } from 'react'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import * as actions from '../../../actions/insurance'

import ToggleSwitch from '../../components/toggleSwitch'

interface ToggleSwitchInputProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  id: string
  inGuidebook: boolean
  handleToggle?(active: boolean): any
}

export class InGuideBookSwitch extends Component<ToggleSwitchInputProps> {
  public toggle = () => {
    if (this.props.inGuidebook) {
      this.props.dispatch(
        actions.activeInsurance(this.props.householdFinId, this.props.id, false)
      )
    } else {
      this.props.dispatch(
        actions.activeInsurance(this.props.householdFinId, this.props.id, true)
      )
    }
  }

  public render() {
    return (
      <Fragment>
        <ToggleSwitch
          active={this.props.inGuidebook}
          handleToggleActive={this.toggle}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    insurances: store.insurances[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(InGuideBookSwitch))
