import React from 'react'
import Modal from '../../components/layout/modal'
import { MoneyMindInterface, MoneyMindState } from '../../../objects/moneymind'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import { ReactComponent as PrintIcon } from '../../assets/images/icons/print.svg'
import Button from '../../components/button'
import { ymmBaseUrl } from '../../helpers/ymmQuizURL'
import { ymmQuizIsNewVersion } from '../../helpers/ymmQuizIsNewVersion'
import { queryBuilder } from '../../helpers/queryBuilder'

interface PrintMoneyMindModalProps {
  contacts: ContactsInterface
  moneyminds: MoneyMindInterface
  closeModal(): void
}

class PrintMoneyMindModal extends React.Component<PrintMoneyMindModalProps> {
  constructor(props: any) {
    super(props)
  }

  public handlePrimaryPrint = () => {
    this.printDetails(
      this.props.contacts.primary,
      this.props.moneyminds.primary
    )
    this.props.closeModal()
  }

  public handleSecondaryPrint = () => {
    this.printDetails(
      this.props.contacts.secondary,
      this.props.moneyminds.secondary
    )
    this.props.closeModal()
  }

  public printDetails = (contact: ContactObj, moneyminds: MoneyMindState) => {
    const moneymind = moneyminds[contact.activeYmmId]
    const results = {
      protection: moneymind.protection,
      happiness: moneymind.happiness,
      commitment: moneymind.commitment
    }
    const sortedResults = Object.keys(results)
    sortedResults.sort((a, b) => results[b] - results[a])
    const params: Record<string, string | null | number> = {}
    let path = ''

    if (ymmQuizIsNewVersion()) {
      params.mid = contact.activeYmmId
      params.print = null
      path = 'results'
    } else {
      params.firstname = contact.firstName
      params.lastname = contact.lastName
      params.hpercent = moneymind.happiness
      params.fpercent = moneymind.protection
      params.cpercent = moneymind.commitment
      params.sorted = sortedResults.toString()

      path = 'resultsprint'
    }

    const url = `${ymmBaseUrl()}${path}${queryBuilder(params, true)}`

    window.open(url, '_blank')
  }

  public printAccountBtn = (isPrimary: boolean, contact: ContactObj) => {
    return (
      <div className='moneymind-modal__btn-w'>
        <Button
          primary={true}
          onClick={
            isPrimary ? this.handlePrimaryPrint : this.handleSecondaryPrint
          }>
          <span className='moneymind-modal__btn-text'>
            Print {contact.firstName}'s MoneyMind&reg;
          </span>
        </Button>
      </div>
    )
  }

  public render() {
    const { contacts, moneyminds, closeModal } = this.props
    return (
      <Modal
        icon={PrintIcon}
        title='PRINT MONEYMIND&reg;'
        closeModal={closeModal}>
        <div className='moneymind-modal'>
          <div className='moneymind-modal__content'>
            <div className='moneymind-modal__account-type'>
              <div className='moneymind-modal__btn'>
                {contacts &&
                contacts.primary &&
                contacts.primary.activeYmmId &&
                moneyminds &&
                moneyminds.primary &&
                moneyminds.primary[contacts.primary.activeYmmId]
                  ? this.printAccountBtn(true, contacts.primary)
                  : null}
                {contacts &&
                contacts.secondary &&
                contacts.secondary.activeYmmId &&
                moneyminds &&
                moneyminds.secondary &&
                moneyminds.secondary[contacts.secondary.activeYmmId]
                  ? this.printAccountBtn(false, contacts.secondary)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PrintMoneyMindModal
