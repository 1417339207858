import React, { Fragment } from 'react'
import {
  ClientDesktopNotifications,
  ClientEmailNotifications,
  ClientMobileNotifications
} from '../../../objects/settings/clientAdvisor'
import { ToggleRow } from './toggleRow'

export const ClientToggleRows: React.FC<{
  disabled: boolean
  className: string
  desktopSettings: string[]
  emailSettings: string[]
  mobileSettings: string[]
  handler(event: React.ChangeEvent<HTMLInputElement>): void
}> = (props) => {
  const {
    disabled,
    className,
    handler,
    desktopSettings,
    emailSettings,
    mobileSettings
  } = props

  return (
    <Fragment>
      {ClientDesktopNotifications.map((setting: string) => (
        <ToggleRow
          disabled={disabled}
          key={setting}
          settingName={setting}
          className={className}
          handler={handler}
          desktopActive={desktopSettings.some(
            (desktopSetting) => desktopSetting === setting
          )}
          emailActive={emailSettings.some(
            (emailSetting) => emailSetting === setting
          )}
          emailSupported={ClientEmailNotifications.some(
            (emailSupport) => emailSupport === setting
          )}
          mobileActive={mobileSettings.some(
            (mobileSetting) => mobileSetting === setting
          )}
          mobileSupported={ClientMobileNotifications.some(
            (mobileSupport) => mobileSupport === setting
          )}
        />
      ))}
    </Fragment>
  )
}
