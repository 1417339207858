import React, { Component, CSSProperties, Fragment } from 'react'
import PDF from 'react-pdf-js'
import Modal from '../../../../components/layout/modal'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import api from '../../../../helpers/api'
import { downloadBlob } from '../../../../helpers/download'

export interface PrintPreviewProps {
  id: string
  printResults(): Promise<void>
  closeModal(): void
}

interface PrintPreviewState {
  renderingPdf: boolean
  summary: {
    page: number
    pages: number
  }
  windowHeight: number
  pdfData: any
}

const style: CSSProperties = {
  width: '610px'
}
const modalStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}
class PrintPreview extends Component<PrintPreviewProps, PrintPreviewState> {
  constructor(props: PrintPreviewProps) {
    super(props)
    this.state = {
      renderingPdf: true,
      summary: {
        page: null,
        pages: null
      },
      windowHeight: window.innerHeight,
      pdfData: null
    }
  }

  public async componentDidMount() {
    const { printResults } = this.props
    window.addEventListener('resize', this.handleResize)
    await printResults()
    await this.renderResultsPdf()
    this.setState({ renderingPdf: false })
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  public renderResultsPdf = async () => {
    const householdFinId = this.props.id
    const pdfFilePath = `hc/${householdFinId}-results-summary.pdf`
    const encodedPath = encodeURIComponent(pdfFilePath)

    await api()
      .get(
        `/households/${householdFinId}/guidebookgenerator/renderpdf?pdfFilePath=${encodedPath}`,
        {
          timeout: 600000,
          responseType: 'blob'
        }
      )
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/pdf'
        })
        this.setState({
          pdfData: window.URL.createObjectURL(blobData)
        })
      })
  }

  public handleResize = () => {
    this.setState({ windowHeight: window.innerHeight })
  }

  public getContentStyle = () => {
    const { windowHeight } = this.state
    const contentStyle: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      height: '680px',
      padding: '0 28px',
      overflow: 'hidden'
    }
    if (windowHeight < 740) {
      contentStyle.height = '620px'
    }
    if (windowHeight < 700) {
      contentStyle.height = '580px'
    }
    if (windowHeight < 650) {
      contentStyle.height = '540px'
    }
    if (windowHeight < 600) {
      contentStyle.height = '500px'
    }
    return contentStyle
  }

  public onDocumentComplete = (pdfName: string) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: 1,
      // tslint:disable-next-line: radix
      pages: parseInt(e.toString())
    }
    this.setState(state)
  }

  public handlePrevious = (pdfName: string) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: this.state[pdfName].page - 1,
      pages: this.state[pdfName].pages
    }

    this.setState(state)
  }
  public handleNext = (pdfName: any) => (e: object) => {
    const state = this.state
    state[pdfName] = {
      page: this.state[pdfName].page + 1,
      pages: this.state[pdfName].pages
    }
    this.setState(state)
  }

  public renderPagination = (pdfName: string, page: number, pages: number) => {
    let previousButton = (
      <li className='btn btn__prime' onClick={this.handlePrevious(pdfName)}>
        Previous
      </li>
    )
    if (page === 1) {
      previousButton = <li className='btn btn__default'>Previous</li>
    }
    let nextButton = (
      <li className='btn btn__prime' onClick={this.handleNext(pdfName)}>
        Next
      </li>
    )
    if (page === pages) {
      nextButton = <li className='btn btn__default'>Next</li>
    }
    return (
      <div
        className='gb-container__next-prev-w'
        style={{ paddingRight: '10px', margin: '0px', marginBottom: '10px' }}>
        {previousButton}
        {nextButton}
      </div>
    )
  }

  public onPrintPdf = () => {
    downloadBlob(this.state.pdfData, 'HonestConversationsResult.pdf')
  }

  public modalIcon = () => {
    return <PreviewIcon />
  }

  public render() {
    const { closeModal } = this.props
    const { summary, renderingPdf, pdfData } = this.state
    return (
      <Modal
        style={style}
        contentStyle={this.getContentStyle()}
        title={'PRINT PREVIEW'}
        modalStyle={modalStyle}
        icon={this.modalIcon}
        closeModal={closeModal}>
        {!renderingPdf ? (
          <Fragment>
            <div className='results-summary__print'>
              <PDF
                file={pdfData}
                onDocumentComplete={this.onDocumentComplete('summary')}
                page={summary?.page}
                scale={0.9}
              />
              <br />
              {summary?.pages > 1
                ? this.renderPagination('summary', summary.page, summary.pages)
                : null}
            </div>
            <div className='results-summary__print-buttons'>
              <Button type='clear' onClick={closeModal}>
                Cancel
              </Button>
              <Button onClick={this.onPrintPdf}>Print</Button>
            </div>
          </Fragment>
        ) : null}
      </Modal>
    )
  }
}

export default PrintPreview
