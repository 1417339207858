import React, { Fragment, Component, ChangeEvent } from 'react'
import Tile from '../../../components/layout/tile'
import CheckBox from '../../../components/checkbox'
import uploadIcon from '../../../assets/images/icons/ic_upload_blue.png'
import api from '../../../../v3/helpers/api'
import ReactTooltip from 'react-tooltip'
import { OfficeTeamObj } from '../../../../objects/officeTeams'
import Select from 'react-select'
import { UserObj, UsersObj } from '../../../../objects/user'
import { HouseholdObj } from '../../../../objects/household'

interface GbNavigationProps {
  setPdfData(filename: string, content: any): void
  coverPageInfo: {
    title: string
    clientName: string
    selectedAdvisor: UserObj
    defaultAdvisor: UserObj
    users: UsersObj
  }
  household: HouseholdObj
  officeTeam: OfficeTeamObj
  checkGbSections: {
    checkedDigitalOnlyFormat: boolean
    checkedMoneyMind: boolean
    checkedHonestConversations: boolean
    checkedNetWorth: boolean
    checkedInvestments: boolean
    checkedInsurance: boolean
    checkedFinancialGoals: boolean
    checkedAdditionalDocs0: boolean
    checkedAdditionalDocs1: boolean
    checkedAdditionalDocs2: boolean
    checkedAdditionalDocs3: boolean
    checkedAdditionalDocs4: boolean
    checkedGoals: boolean
    checkedTasks: boolean
  }
  // householdFinId: any
  fileChanges: any
  coverPageDataUpdate(
    inputField: string
  ): (e: ChangeEvent<HTMLInputElement>) => void
  coverPageAdvisorUpdate(selected: any): void
  toggleMe(section: string): any
}

interface CustomPdfStates {
  files: string[]
  fileNames: string[]
  checked?: boolean
  display: boolean
  counter: number
}

interface SelectInterface {
  label: string
  value: UserObj
}

class GbNavigation extends Component<GbNavigationProps, CustomPdfStates> {
  constructor(props: GbNavigationProps) {
    super(props)
    this.state = {
      files: [],
      fileNames: [],
      display: false,
      counter: 0
    }
    this.handleChange = this.handleChange.bind(this)
  }

  public checkBoxItem = (
    noToggleAllowed: boolean,
    toggleName: string,
    id: string,
    label: string
  ) => {
    const { toggleMe, checkGbSections } = this.props
    return (
      <div className='gb-container__nav-checkbox-w'>
        <CheckBox
          noToggleAllowed={noToggleAllowed}
          onChange={toggleMe(toggleName)}
          id={id}
          checked={checkGbSections[toggleName]}
          checkedBorder={true}
          label={label}
          style={{ padding: '20px 16px' }}
        />
      </div>
    )
  }

  public optionalRenderCheckbox = () => {
    const { toggleMe, checkGbSections } = this.props
    return (
      <div>
        {this.state.display ? (
          <div>
            {this.state.files.map((item: any, i: number) => {
              return (
                <div key={i} className='gb-container__nav-checkbox-w'>
                  <CheckBox
                    noToggleAllowed={null}
                    onChange={toggleMe('checkedAdditionalDocs' + i)}
                    id={'checkedAdditionalDocs' + i}
                    checked={checkGbSections['checkedAdditionalDocs' + i]}
                    checkedBorder={true}
                    label={this.state.fileNames[i]}
                    style={{ padding: '20px 16px' }}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const file = this.state.files
    file.push(URL.createObjectURL(event.currentTarget.files[0]))
    const newFileNames = this.state.fileNames
    newFileNames.push(event.currentTarget.files[0].name)
    this.setState({
      display: true,
      files: file,
      fileNames: newFileNames
    })
    this.props.fileChanges(URL.createObjectURL(event.currentTarget.files[0]))

    const reader = new FileReader()
    const counter = this.state.counter

    const householdFinId = this.props.household.id
    reader.addEventListener('loadend', () => {
      api().post(
        `/households/${householdFinId}/guidebookgenerator/additionaldocs`,
        {
          contactId: householdFinId,
          additionalDocsPDF: reader.result,
          number: counter
        }
      )
    })
    this.setState({ counter: counter + 1 })
    reader.readAsDataURL(event.currentTarget.files[0])
    this.props.setPdfData(
      'AdditionalDocs' + counter,
      URL.createObjectURL(event.currentTarget.files[0])
    )
  }

  public inputBoxItem = (
    label: string,
    placeholder: string,
    value: string,
    inputField: string
  ) => {
    return (
      <div className='gb-container__nav-input-w'>
        <label>
          <span>{label}</span>
          <input
            onChange={this.props.coverPageDataUpdate(inputField)}
            placeholder={placeholder}
            value={value}
          />
        </label>
      </div>
    )
  }

  public dropDown = (label: string) => {
    const { users, selectedAdvisor } = this.props.coverPageInfo
    const officeTeam = this.props.officeTeam
    let advisorOptions: SelectInterface[] = []

    if (officeTeam && officeTeam.members) {
      advisorOptions = officeTeam.members
        .filter((member) => users[member.userId])
        .map((member) => {
          return {
            value: users[member.userId],
            label: `${users[member.userId].firstName} ${
              users[member.userId].lastName
            }`
          }
        })
    }
    advisorOptions = advisorOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
      return 0
    })

    let defaultValue = advisorOptions[0]
    let placeHolder = ''

    if (advisorOptions.length > 0) {
      placeHolder = `${advisorOptions[0].value.firstName} ${advisorOptions[0].value.lastName}`
      advisorOptions.forEach((advisor, index: number) => {
        if (selectedAdvisor && selectedAdvisor.id === advisor.value.id) {
          defaultValue = advisor
          placeHolder = `${advisor.value.firstName} ${advisor.value.lastName}`
        }
      })
    }
    return (
      <div className='gb-container__nav-select-w'>
        <label>
          <span>{label}</span>

          <Select
            placeholder={placeHolder || null}
            defaultValue={defaultValue}
            options={advisorOptions}
            onChange={this.props.coverPageAdvisorUpdate}
            className='react-select-container'
            classNamePrefix='react-select'
          />
        </label>
      </div>
    )
  }

  public downloadFormat = () => {
    return (
      <Tile
        style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
        headerStyle={{
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #d2d2d2'
        }}
        leftHeader='DOWNLOAD FORMAT'>
        {this.checkBoxItem(
          false,
          'checkedDigitalOnlyFormat',
          'DigitalOnlyCopy',
          'Digital Only'
        )}
      </Tile>
    )
  }

  public render() {
    return (
      <Fragment>
        {this.downloadFormat()}
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='COVER PAGE'>
          {this.dropDown('Advisor Name')}
          {this.inputBoxItem(
            'Client Name',
            'Client',
            this.props.coverPageInfo.clientName,
            'clientName'
          )}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='INTRO'>
          {this.checkBoxItem(
            false,
            'checkedProcessFlow',
            'ProcessFlow',
            'Process Flow'
          )}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='HOW DO I THINK/FEEL ABOUT MONEY?'>
          {this.checkBoxItem(
            true,
            'checkedMoneyMind',
            'MoneyMind',
            'MoneyMind'
          )}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='WHAT MATTERS TO ME?'>
          {this.checkBoxItem(
            true,
            'checkedHonestConversations',
            'HonestConversations',
            'Honest Conversations'
          )}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='WHERE AM I TODAY?'>
          {this.checkBoxItem(false, 'checkedNetWorth', 'NetWorth', 'Net Worth')}
          {this.checkBoxItem(
            false,
            'checkedInvestments',
            'Investments',
            'Investments'
          )}
          {this.checkBoxItem(
            true,
            'checkedFinancialGoals',
            'FinancialGoals',
            'Financial Plan'
          )}
          {this.checkBoxItem(
            false,
            'checkedInsurance',
            'Insurance',
            'Insurance'
          )}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='HOW DO I GET WHERE I NEED TO GO?'>
          {this.checkBoxItem(true, 'checkedGoals', 'Goals', 'Goals')}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='AM I ON COURSE?'>
          {this.checkBoxItem(false, 'checkedTasks', 'Tasks', 'Tasks')}
        </Tile>
        <Tile
          style={{ borderRadius: 0, borderLeft: 0, borderTop: 0 }}
          headerStyle={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #d2d2d2'
          }}
          leftHeader='CUSTOM SECTIONS'>
          {this.optionalRenderCheckbox()}
          <div className='gb-container__nav-custom'>
            {this.state.counter < 5 ? (
              <label className='gb-container__nav-custom--label'>
                <input
                  type='file'
                  onChange={this.handleChange}
                  style={{ display: 'none', width: '100%', height: '100%' }}
                />
                <img src={uploadIcon} alt='uploader' /> Upload
              </label>
            ) : (
              <label
                className='gb-container__nav-custom--label gb-container__nav-custom--label-null'
                data-tip='Limit of 5 documents can be loaded in GuideBook'>
                <ReactTooltip place='bottom' type='dark' effect='solid' />
              </label>
            )}
            <br />
            <p>PDF Files Only</p>
          </div>
        </Tile>
      </Fragment>
    )
  }
}

export default GbNavigation
