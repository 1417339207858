import React from 'react'
import { ContactObj } from '../../../../objects/contact'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { dateFormat } from '../../../helpers'

interface ContactRowProps {
  contact: ContactObj
  contactType: 'Primary' | 'Secondary'
  dispatch: Dispatch<GlobalState>
}

class ContactRow extends React.Component<ContactRowProps> {
  public constructor(props: ContactRowProps) {
    super(props)
  }
  public render() {
    const { contact, contactType } = this.props
    return (
      <div className='financial-planning__content-row'>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--fname'>
          {contact.firstName}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--lname'>
          {contact.lastName}
        </div>
        <div className='financial-planning__content-row__item'>
          {contactType}
        </div>
        <div className='financial-planning__content-row__item'>
          {contact.gender}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--birthdate'>
          {dateFormat(contact.dateOfBirth)}
        </div>
        <div className='financial-planning__content-row__item'>
          {contact.maritalStatus}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--employment'>
          {contact.employmentStatus}
        </div>
        <div className='financial-planning__content-row__item'>
          {contact.state}
        </div>
      </div>
    )
  }
}

export default connect()(ContactRow)
